<template>
    <cosmos-grid id="MarkupContractCustomerList"
                 :searchFilters="filters"
                 :source="url"
                 sourceType="url"
                 :show-export="false"
                 :show-add="false"
                 :auto-generate="false"
                 :show-header="consignmentID === -1000"
                 :show-operation="false">
        <pv-grid-column field="auditLogType" header="Log Type" style="width: 2.5rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em; font-weight: 500">
            <template #body="slotProps">
                <span :style="[{ 'color': slotProps.data.auditLogType == 100 ? 'var(--text-color)': 'var(--text-info)'}] ">{{ $vm.$enums.auditlogtype.filter(x => x.id === slotProps.data.auditLogType)[0].code }}</span>
            </template>
        </pv-grid-column>
        <pv-grid-column field="source" header="Source" style="width: 1.5rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em; font-weight: 500">
            <template #body="slotProps">
                <span :style="[{ 'color': slotProps.data.source == 1 ? 'var(--text-color)': 'var(--text-danger)'}] ">{{ $vm.$enums.source.filter(x => x.id === slotProps.data.source)[0].code }}</span>
            </template>
        </pv-grid-column>
        <pv-grid-column field="transactionID" header="TransactionID" style="width: 1.5rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <router-link :to="{ name: 'consignment-code-edit', params: { code: slotProps.data.transactionID  }}" style="font-weight:bold">{{slotProps.data.transactionID}}</router-link>
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference_01" header="Reference 1" style="width: 1.5rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{ slotProps.data.reference_01 }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference_02" header="Reference 2" style="width: 1.5rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{ slotProps.data.reference_02 }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference_03" header="Reference 3" style="width: 1.5rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{ slotProps.data.reference_03 }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="fileRequest" header="Request Logs" style="width: 1.5rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <pv-button @click="downloadURL(`${cdn}/${tenant.code.toLowerCase()}/logs/${(slotProps.data.auditLogType === 100 ? 'api' : 'carrier')}/${slotProps.data.fileRequest}`,`${slotProps.data.fileRequest}`)" icon="fas fa-download fa-fw" label="Download Request" class="p-button-text" />
            </template>
        </pv-grid-column>
        <pv-grid-column field="fileResponse" header="Response Logs" style="width: 1.5rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <pv-button @click="downloadURL(`${cdn}/${tenant.code.toLowerCase()}/logs/${(slotProps.data.auditLogType === 100 ? 'api' : 'carrier')}/${slotProps.data.fileResponse}`,`${slotProps.data.fileResponse}`)" icon="fas fa-download fa-fw" label="Download Response" class="p-button-text" />
            </template>
        </pv-grid-column>
        <pv-grid-column field="fileDeveloper"  :class="[{'p-field-hidden': user.userType !== 0 }]" header="Developer Logs" style="width: 1.5rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <pv-button @click="downloadURL(`${cdn}/${tenant.code.toLowerCase()}/logs/developer/${slotProps.data.fileDeveloper}`,`${slotProps.data.transactionID ?? slotProps.data.fileDeveloper.split('_')[0]}_${slotProps.data.fileDeveloper.split('_')[1]}.log`)" icon="fas fa-download fa-fw" label="Download Developer" class="p-button-text" />
            </template>
        </pv-grid-column>
        <pv-grid-column field="remarks" header="Remarks" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{ slotProps.data.remarks }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="updateDate" header="Transaction Date" style="width: 1.5rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{ $filters.toDateFullTimeDisplay(slotProps.data.updateDate) }}
            </template>
        </pv-grid-column>
    </cosmos-grid>
</template>
<script>
    import { mapGetters } from 'vuex';
    import config from '@/assets/lib/cosmos/_js/config.js';
    export default {
        name: 'additionalFeeGrid',
        props: {
            consignmentID: { type: Number, default: -1000 }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            tenant() { return config.common.$tenant().filter(x => x.id === 1)[0]; },
            cdn() { return `${config.config.endpoint.cdn}`; },
            url() { return `/services/facility/auditlog/PagedList?type=${this.consignmentID}` },
            filters() {  return ['transactionID', 'reference_01', 'reference_02', 'reference_03'] }
        },
        methods: { downloadURL(url, filename) { config.common.$downloadFileURL(url, `text/log`, filename); } },
        created() { this.activeKeys = sessionStorage.getItem('activekeys'); }
    }
</script>