<template>
    <cosmos-grid id="ReturnAddressGrid" sourceType="array" :source="source" :showHeader="false" :showOperation="false" :showCheckbox="false">
        <pv-grid-column field="addressType" header="Address Type" headerStyle="min-width: 13rem; max-width: 13rem; width: 13rem" style="min-width: 13rem; max-width: 13rem; width: 13rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; ">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ addresstypes.filter(x => x.id === slotProps.data.addressType)[0].text }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="name" header="Company Details" headerStyle="min-width: 13rem; max-width: 30rem; width: 13rem" style="min-width: 13rem; max-width: 30rem; width: 13rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; ">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data.code }} {{ slotProps.data.name }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="location" header="Full Address">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ address(slotProps.data) }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="phone" header="Phone" headerStyle="min-width: 13rem; max-width: 20rem; width: 13rem" style="min-width: 13rem; max-width: 20rem; width: 13rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.phone }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
    </cosmos-grid>
</template>
<script>
    export default {
        name: 'ReturnAddressGridTemplate',
        props: {
            parentID: { type: Number },
        },
        data() {
            return {
                source: []
            }
        },
        computed: {
            addresstypes() { return this.$vm.$enums.returnaddresstype; },
        },
        watch: {
            parentID() {
                this.getSource()
            }
        },
        methods: {
            getSource() {
                this.$store.dispatch("returns/getURL", { url: `/services/tms/return/${this.parentID}/addresses` })
                    .then((response) => {
                        this.source = response?.result ?? [];
                    });
            },
            address(data) {
                var text = `${(data?.company ?? '')}, ${(data?.buildingNumber ?? '')} ${(data?.address1 ?? '')}, ${(data?.address2 ?? '')}, ${(data?.address3 ?? '')}, ${(data?.city ?? '')}, ${(data?.neighborhood ?? '')}, ${(data?.state ?? '')}, ${(data?.postalCode ?? '')} , ${(data?.country ?? '')}`;
                return text
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',');
            }
        },
        async created() {
            this.getSource()
        }
    }
</script>