<template>
    <form-object :id="id" :autoFit="autoFit" :label="label" :floatLabel="floatLabel && (!disabled || objBlank)" :hideLabel="hideLabel" :v$="v$" :required="required" :conditional="conditional">
        <template #leftSide="slotProps">
            <slot v-if="showRight" name="prepend">
                <div style="border: 1px solid var(--border-color); border-right: 0 !important; border-radius: 4px 0 0 4px;">
                    <span v-if="objType === 'checkbox' || objType === 'switch'" :class="[{'p-inputgroup-addon': true}, {'p-invalid': slotProps.data}, {'p-inputgroup-prepend': true }]">
                        <pv-checkbox v-if="objType === 'checkbox'"
                                     :ref="id"
                                     :id="id"
                                     :name="id"
                                     :disabled="disabled"
                                     :binary="true"
                                     v-model="model.value" />
                        <pv-switch v-else-if="objType === 'switch'"
                                   :ref="id"
                                   :id="id"
                                   :name="id"
                                   :disabled="disabled"
                                   :onIcon="onIcon"
                                   :offIcon="offIcon"
                                   :onLabel="onText"
                                   :offLabel="offText"
                                   :style="[{ 'margin-right': '5px' }, { 'border-radius': 0 }, { 'border-top-left-radius': '4px' }, { 'border-bottom-left-radius': '4px' }]"
                                   class="p-inputgroup-prepend" 
                                   v-model="model.value" />
                    </span>
                    <pv-toggle v-else-if="objType === 'toggle'" 
                               :ref="id"
                               :id="id"
                               :name="id"
                               :disabled="disabled"
                               :onIcon="onIcon"
                               :offIcon="offIcon"
                               :onLabel="onText"
                               :offLabel="offText"
                               :style="[{ 'width': width }, { 'border-radius': '4px' }, {'max-width': width }]"
                               class="p-inputgroup-prepend"
                               v-model="model.value" ></pv-toggle>
                    <pv-selectbutton v-else 
                                     :ref="id"
                                     :id="id"
                                     :name="id"
                                     :class="'p-inputgroup-prepend is-value-' + (model.value.value ? 'on' : 'off') +(disabled ? ' p-disabled' : '')"
                                     :style="[{ 'border-radius': 0 }, { 'border-left-right-radius': '4px' }, { 'border-left-right-radius': '4px' }, {'width': width}, 'height: 2.85rem']"
                                     :disabled="disabled"
                                     :options="options"
                                     dataKey="name"
                                     v-model="model.value"
                                     @click="onSync(model.value)">
                        <template #option="slotProps">
                            <div :style="[{'width': width}, {'font-weight': '800'}]" :class="'is-value-' + (slotProps.option.value ? 'on' : 'off')">
                                <i v-if="showIcon" :class="slotProps.option.icon"></i>
                                <span v-if="showText" style="margin-left: 5px;">{{slotProps.option.name }}</span>
                            </div>
                        </template>
                    </pv-selectbutton>
                </div>
            </slot>
        </template>
        <template v-if="!hideLabel" #label>
            &nbsp;
        </template>
        <template #default>
            <span class="p-inputtext" :style="'padding: 0.75rem 0.75rem;  width: 100%; border-radius: 4px; ' + (showRight ? 'border-top-left-radius: 0px; border-bottom-left-radius: 0px;border-left: 0 !important;': 'border-top-right-radius: 0px; border-bottom-right-radius: 0px;border-right: 0 !important;') " v-html="`${label ?? $filters.titleize(id)}`"></span>
        </template>
        <template #rightSide="slotProps">
            <slot v-if="!showRight" name="append">
                <div class="is-append" style="border: 1px solid var(--border-color); border-left: 0 !important; border-radius: 0 4px 4px 0;">
                    <span v-if="objType === 'checkbox' || objType === 'switch'" :class="[{'p-inputgroup-addon': true}, {'p-invalid': slotProps.data}, {'p-inputgroup-append': true }]">
                        <pv-checkbox v-if="objType === 'checkbox'"
                                        :ref="id"
                                        :id="id"
                                        :disabled="disabled"
                                        :binary="true"
                                        v-model="model.value" />
                        <pv-switch v-else-if="objType === 'switch'" v-model="model.value" :style="[{ 'margin-right': '5px' }, { 'border-radius': 0 }, { 'border-top-left-radius': '4px' }, { 'border-bottom-left-radius': '4px' }]" class="p-inputgroup-prepend" :disabled="disabled" :onIcon="onIcon" :offIcon="offIcon" :onLabel="onText" :offLabel="offText"></pv-switch>
                    </span>
                    <pv-toggle v-else-if="objType === 'toggle'" v-model="model.value" :style="[{ 'width': width }, { 'border-radius': '4px' }, {'max-width': width }]" class="p-inputgroup-append" :disabled="disabled" :onIcon="onIcon" :offIcon="offIcon" :onLabel="onText" :offLabel="offText"></pv-toggle>
                    <pv-selectbutton v-else v-model="model.value" :class="'p-inputgroup-append is-value-' + (model.value.value ? 'on' : 'off') +(disabled ? ' p-disabled' : '')" :style="[{ 'border-radius': 0 }, { 'border-left-right-radius': '4px' }, { 'border-left-right-radius': '4px' }, {'width': width}, 'height: 2.85rem']" :disabled="disabled" :options="options" dataKey="name" @click="onSync(model.value)">
                        <template #option="slotProps">
                            <div :style="[{'width': width }, {'font-weight': '800'}]" :class="'is-value-' + (slotProps.option.value ? 'on' : 'off')">
                                <i v-if="showIcon" :class="slotProps.option.icon"></i>
                                <span v-if="showText" style="margin-left: 5px;">{{slotProps.option.name }}</span>
                            </div>
                        </template>
                    </pv-selectbutton>
                </div>
            </slot>
        </template>
    </form-object>
</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/core-mixins.js';
    export default {
        name: 'FormBooleanField',
        mixins: [mixins.COMPONENT],
        props: {
            type: { type: String, default: "select", validator: (value) => ['toggle', 'checkbox', 'switch', 'select'].indexOf(value.toLowerCase()) !== -1 },
            value: { type: Boolean, default: null },
            modelValue: { type: Boolean, default: null },
            width: { type: String, default: '100px' },
            textPosition: { type: String, default: "right", validator: (value) => ['right', 'left'].indexOf(value.toLowerCase()) !== -1 },
            onIcon: { type: String, default: 'pi pi-fw pi-check' },
            offIcon: { type: String, default: 'pi pi-fw pi-times' },
            onText: { type: String, default: 'YES' },
            offText: { type: String, default: 'NO' },
            showIcon: { type: Boolean, default: true },
            showText: { type: Boolean, default: true }
        },
        computed: {
            showRight() { return this.textPosition.toLowerCase() == 'right'; },
            options() {
                return [{ icon: this.offIcon, value: false, name: this.offText },
                { icon: this.onIcon, value: true, name: this.onText }]
            }
        },
        watch: {
            modelValue() {
                this.model.value = this.modelValue ?? false;
                if (this.objType === 'select')
                    this.model.value = this.options.filter((x) => x.value === (this.modelValue ?? false))[0];
                this.onSync()
            },
            value() {
                this.model.value = this.value ?? false;
                if (this.objType === 'select')
                    this.model.value = this.options.filter((x) => x.value === (this.value ?? false))[0];
                this.onSync()
            }
        },
        methods: {
            onSync() {
                if (this.objType === 'select') {
                    if (typeof (this.model.value) === "object") {
                        let data = {
                            value: this.model.value.value,
                            text: this.model.value.text,
                            record: this.model.value
                        };
                        this.$emit('is-sync', data);
                        this.$emit('update:modelValue', data.value);
                    }
                    else {
                        this.$emit('is-sync', this.model);
                        this.$emit('update:modelValue', this.model);
                    }
                }
                else {
                    this.$emit('update:modelValue', this.model);
                    this.$emit('is-sync', this.model);
                }
            }
        },
        created() {
            this.model.value = this.modelValue ?? this.value ?? false;
            if (this.objType === 'select')
                this.model.value = this.options.filter((x) => x.value === (this.modelValue ?? this.value ?? false))[0];
        }
    }
</script>