<template>
    <print-form :formData="dialog?.print?.model" :isBatchPrint="dialog?.print?.isBatch" :selectedItems="selectedItems" module="collection" :showDialog="dialog.print.show" :documentType="dialog.print.type" @is-confirm="{dialog.print.show = false; dialog.print.model = null; dialog.print.type = ''; dialog.print.isBatch = false;}" @is-cancel="{dialog.print.show = false; dialog.print.model = null; dialog.print.type = '';}"></print-form>
    <is-dialog header="DISPATCH" :visible="dialog.dispatch" @is-confirm="onConfirmDispatch()" @is-cancel="this.dialog.dispatch = false">
        <div class="confirmation-content p-d-flex">
            <div>
                <i class="pi pi-info-circle p-mr-3" style="font-size: 2rem" />
            </div>
            <div>
                You are about to dispatch selected collections
                <form-datetime id="dispatchDate" :required="true" :value="dispatchDate" type="date" @is-sync="dispatchDate = $event.value" label="Dispatch Date" style="margin-top:20px;" :v$="v$"></form-datetime>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
    <form-wrapper :title="router.title" :sub-title="router.subtitle">
        <cosmos-grid id="CollectionList" sourceType="url" :source="gridUrl" :multiLevel="true" :searchFilters="searchFields" :pageSize="15" :filters="activeFilters" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showExport="true" :forceRefresh="forceRefresh" :showOperation="false" :selectedReset="selectedReset" @is-selected="onSelected($event)" @is-source="onSource($event)" style="position: relative;">
                <template #toolbar>
                    <div class="p-d-flex p-jc-between p-ai-center">
                        <span>
                            <pv-button v-if="showDispatch" class="p-button-success" icon="mdi mdi-truck" label="Dispatch" :disabled="(selectedItems?.filter(x => x?.status === 400).length ?? 0) === 0" style="margin-right: 5px;" @click="onClickDispatch"></pv-button>
                        </span>
                        <div class="p-d-flex right">
                            <span style="min-width:240px; max-width: 250px; width: 100%; margin-right:10px;">
                                <form-lookup id="statusDropDown" type="enum" source="collectionstatustype" :sorted="false" :value="statusFilter" :hideLabel="true" @is-sync="statusFilter = $event?.value ?? 1;  forceRefresh = true;" style="margin:0; padding:0;" /> <!--objectFilter.enumFilter = $event.value; syncFilter();-->
                            </span>
                            <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                                <i class="pi pi-search" />
                                <pv-input v-model="activeFilters['global'].value" placeholder="Keyword Search" style="width: 100%;" @keyup="onForceFilter" />
                            </span>
                        </div>

                    </div>
                </template>
                <pv-grid-column field="mask" :headerStyle="`min-width: 3.5rem; max-width: 3rem; width: 3rem`" style="`min-width: 3.5rem; max-width: 3rem; border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;`" :frozen="true" :alignFrozen="'right'">
                    <template #body="slotProps">
                        <div class="p-d-flex" :style="`width:3rem;`">
                            <div class="p-d-flex">
                                <pv-button icon="pi pi-print" iconPos="right" @click="onPrint(slotProps.data,'collectionsummary')" class="p-button-warning" v-tooltip.top="{value: 'Print Document'}" />
                            </div>
                        </div>
                    </template>
                </pv-grid-column>
                <pv-grid-column field="customerName" header="Customer" headerStyle="min-width: 15rem" style="min-width: 15rem; text-align: center; object-position: center;">
                    <template #body="slotProps">
                        <div class="p-d-flex">
                            <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left;">
                                {{slotProps.data.customerName}}
                            </div>
                        </div>
                    </template>
                </pv-grid-column>
                <pv-grid-column field="code" header="Transaction ID" headerStyle="min-width: 15rem" style="min-width: 15rem; text-align: center; object-position: center;">
                    <template #body="slotProps">
                        <div class="p-d-flex">
                            <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left;">
                                <router-link style="font-size: 1rem; font-weight:700;" :to="{ name: 'collection-edit', params: { mask: slotProps.data.mask }}">{{slotProps.data.code}}</router-link>
                            </div>
                        </div>
                    </template>
                </pv-grid-column>
                <pv-grid-column field="name" header="Collection" headerStyle="min-width: 25rem" style="min-width: 25rem; text-align: center; object-position: center; ">
                    <template #body="slotProps">
                        <div class="p-d-flex">
                            <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; overflow: hidden; text-overflow: ellipsis;">
                             <i :class="`flag flag-${$vm.$countries.filter((x) => x.id == slotProps.data?.pickup_CountryID)[0]?.code.toLowerCase()}`" v-tooltip.top="{value : ($vm.$countries.filter(x => x.id === slotProps.data.pickup_CountryID)[0]?.code) + ', '+($vm.$countries.filter(x => x.id === slotProps.data.pickup_CountryID)[0]?.text)}" style="margin-top: -0.15rem"></i>&nbsp; 
                            <span v-tooltip.top="{value: `${ slotProps.data.pickup_City ? slotProps.data.pickup_City + ',' : '' } ${ slotProps.data.pickup_State ? slotProps.data.pickup_State + ',' : '' } ${ slotProps.data.pickup_PostalCode ? slotProps.data.pickup_PostalCode + ',' : '' } ${ $vm.$countries.filter(x => x.id === slotProps.data.pickup_CountryID)[0]?.text}`}">{{ slotProps.data.pickup_City ? slotProps.data.pickup_City + ',' : '' }} {{ slotProps.data.pickup_State ? slotProps.data.pickup_State + ',' : '' }} {{ slotProps.data.pickup_PostalCode ? slotProps.data.pickup_PostalCode + ',' : '' }} {{ $vm.$countries.filter(x => x.id === slotProps.data.pickup_CountryID)[0]?.text}}</span>
                            </div>
                        </div>
                    </template>
                </pv-grid-column>
                <pv-grid-column :class="[{'p-field-hidden': !(statusFilter === 1 || statusFilter > 100) }]" field="name" header="Reference" headerStyle="min-width: 25rem" style="min-width: 25rem; text-align: left; object-position: center; text-overflow: ellipsis; white-space: nowrap;">
                    <template #body="slotProps">
                        <div class="p-d-flex">
                            <div v-if="slotProps.data?.serviceProviderID > 0" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                                <span><img v-tooltip.top="{value : `${$vm.$providers.filter((x) => x.id == slotProps.data?.serviceProviderID)[0]?.name}`}" style="vertical-align: middle; width: 35px; height: 20px; max-height: 20px; max-width: 35px; object-fit: contain;" :src="`/_assets/_integration/${$vm.$providers.filter((x) => x.id == slotProps.data?.serviceProviderID)[0]?.mask}.png`" onerror="this.onerror=null; this.src = '/_assets/img/no-logo.png'" /> {{ (slotProps.data?.name ?? slotProps.data?.code) }}</span>
                            </div>
                        </div>
                    </template>
                </pv-grid-column>
                <pv-grid-column field="pickup_Date" header="Pickup Date" headerStyle="min-width: 7rem" style="min-width: 7rem; text-align: left; object-position: center; white-space: nowrap; ">
                    <template #body="slotProps">
                        <div class="p-d-flex">
                            <div v-if="slotProps.data?.pickup_Date" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                                {{$filters.formatDateUTCFormat(slotProps.data?.pickup_Date,'DD/MMM/YYYY')}}
                            </div>
                        </div>
                    </template>
                </pv-grid-column>
                <pv-grid-column field="pickup_CutOffTime" header="Cut Off Time" headerStyle="min-width: 7rem" style="min-width: 7rem; text-align: left; object-position: center; white-space: nowrap; ">
                    <template #body="slotProps">
                        <div class="p-d-flex">
                            <div v-if="slotProps.data?.pickup_CutOffTime" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                                {{$filters.formatDateUTCFormat(slotProps.data?.pickup_CutOffTime,"HH:mm")}}
                            </div>
                        </div>
                    </template>
                </pv-grid-column>
                <pv-grid-column field="pickup_ReadyTime" header="Ready Time" headerStyle="min-width: 7rem" style="min-width: 7rem; text-align: left; object-position: center; white-space: nowrap; ">
                    <template #body="slotProps">
                        <div class="p-d-flex">
                            <div v-if="slotProps.data?.pickup_ReadyTime" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                                {{$filters.formatDateUTCFormat(slotProps.data?.pickup_ReadyTime,"HH:mm")}}
                            </div>
                        </div>
                    </template>
                </pv-grid-column>
                <pv-grid-column field="pickup_CollectionTime" header="Collection Time" headerStyle="min-width: 7rem" style="min-width: 7rem; text-align: left; object-position: center; white-space: nowrap; ">
                    <template #body="slotProps">
                        <div class="p-d-flex">
                            <div v-if="slotProps.data?.pickup_CollectionTime" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                                {{$filters.formatDateUTCFormat(slotProps.data?.pickup_CollectionTime,"HH:mm")}}
                            </div>
                        </div>
                    </template>
                </pv-grid-column>
                <pv-grid-column field="pickup_ClosingTime" header="Closing Time" headerStyle="min-width: 7rem" style="min-width: 7rem; text-align: left; object-position: center; white-space: nowrap; ">
                    <template #body="slotProps">
                        <div class="p-d-flex">
                            <div v-if="slotProps.data?.pickup_ClosingTime" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                                {{$filters.formatDateUTCFormat(slotProps.data?.pickup_ClosingTime,"HH:mm")}}
                            </div>
                        </div>
                    </template>
                </pv-grid-column>
                <pv-grid-column field="pickup_AfterCloseTime" header="After Close Time" headerStyle="min-width: 7rem" style="min-width: 7rem; text-align: left; object-position: center; white-space: nowrap; ">
                    <template #body="slotProps">
                        <div class="p-d-flex">
                            <div v-if="slotProps.data?.pickup_AfterCloseTime" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                                {{$filters.formatDateUTCFormat(slotProps.data?.pickup_AfterCloseTime,"HH:mm")}}
                            </div>
                        </div>
                    </template>
                </pv-grid-column>
                <pv-grid-column field="milestone_DocumentPrintDate" header="Print Date" headerStyle="min-width: 7rem" style="min-width: 7rem; text-align: left; object-position: center; white-space: nowrap; ">
                    <template #body="slotProps">
                        <div class="p-d-flex">
                            <div v-if="slotProps.data?.milestone_DocumentPrintDate" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                                {{$filters.toDateDisplay(slotProps.data?.milestone_DocumentPrintDate,'DD/MMM/YYYY')}}
                            </div>
                        </div>
                    </template>
                </pv-grid-column>
                <pv-grid-column field="milestone_DispatchDate" header="Dispatch Date" headerStyle="min-width: 7rem" style="min-width: 7rem; text-align: left; object-position: center; white-space: nowrap; ">
                    <template #body="slotProps">
                        <div class="p-d-flex">
                            <div v-if="slotProps.data?.milestone_DispatchDate" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                                {{$filters.toDateDisplay(slotProps.data?.milestone_DispatchDate,'DD/MMM/YYYY')}}
                            </div>
                        </div>
                    </template>
                </pv-grid-column>
                <pv-grid-column field="status" header="Status" headerStyle="min-width: 10rem; max-width: 20rem;" style="min-width: 10rem; max-width: 20rem; text-align: center; object-position: center; white-space: nowrap; opacity: 1 !important;" :frozen="true" :alignFrozen="'right'">
                    <template #body="slotProps">
                        <div class="p-d-flex">
                            <div style="width:100%;padding:0 !important; margin: 0 !important; text-align:center;">
                                <div><span :class="`customer-badge status-${getStatus(slotProps.data.status)}`">{{$vm.$enums['collectionstatustype'].filter((x) => x.id == slotProps.data.status)[0]?.text}}</span></div>
                            </div>
                        </div>
                    </template>
                </pv-grid-column>
                <template #expansion="slotProps">
                    <div class="p-fluid p-grid">
                        <div class="p-col-12">
                            <consignment-grid :readOnly="true" :foreignkeyid="slotProps?.data?.id"></consignment-grid>
                        </div>
                    </div>
                </template>
            </cosmos-grid>
</form-wrapper>

</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/mixin.js';
    import { FilterMatchMode } from 'primevue/api';
    import consignmentGrid from '@/components/transaction/tms/consignment/record/grid-form.vue';
    import printform from '@/components/transaction/tms/consignment/record/print-form.vue';
    export default {
        name: 'CollectionListTemplate',
        mixins: [mixins.GRID],
        components: {
            'consignment-grid': consignmentGrid,
            'print-form': printform,
        },
        data() {
            return {
                type: -1,
                filter: {},
                columns: [],
                deleteDisplay: ["code", "name", "customerName",  "customerCode"],
                searchFields: ["code", "name", "customerName", "customerCode"],
                forceRefresh: false,
                selectedReset: false,
                statusFilter: 1,
                source: { Object },
                selectedItems: [],
                dialog: {
                    print: {
                        show: false,
                        type: '',
                        model: null,
                        isBatch: false
                    },
                    dispatch: false,
                    pod: false
                },
                dispatchDate: new Date(),
            }
        },
        computed: {
            gridUrl() {
                return `${this.$config.config.endpoint.api}/services/tms/collection` + (this.statusFilter > 1 ? '?status=' + this.statusFilter : '') + '';
            },
            activeFilters() {
                var filter = {};
                if (this.filters)
                    filter = this.filters;
                filter["global"] = { value: "", matchMode: FilterMatchMode.CONTAINS };
                return filter;
            },
            showDispatch() {
                return this.statusFilter == 400;
            }
          
        },
        methods: {
            onPrint(e, type) {
                this.dialog.print.model = e;
                this.dialog.print.show = true;
                this.dialog.print.isBatch = true;
                this.dialog.print.type = type ?? 'label';
            },
            onForceFilter(e) {
                if (e.keyCode === 13 || this.activeFilters["global"].value === '') {
                    this.onRefresh();
                }
            },
            onRefresh() {
                this.forceRefresh = false;
                this.$nextTick(() => { this.forceRefresh = true; });
            },
            onSource(e) {
                this.source = e;
            },
            onSelected(e) {
                this.selectedReset = false;
                this.selectedItems = e;
            },
            getStatus(e) {
                let status = "new";
                switch (e) {
                    case 100:
                        status = "new";
                        break;
                    case 200:
                        status = "proposal";
                        break;
                    case 300:
                        status = "renewal";
                        break;
                    case 400:
                        status = "qualified";
                        break;
                    case 500:
                        status = "new";
                        break;
                    case 600:
                        status = "qualified";
                        break;
                    case 700:
                        status = "qualified";
                        break;
                    default:
                        status = "new";
                        break;
                }
                return status.toLowerCase();
            },
            onClickDispatch() {
                if (this.selectedItems.length === 0 || (this.selectedItems?.filter(x => x.status === 400).length ?? 0) === 0) {
                    this.$toast.add({ severity: 'error', summary: 'Please Select Record', detail: "Record selection is required", life: 6000 });
                }
                else {

                    this.dialog.dispatch = true;
                }
            },
            async onConfirmDispatch() {
                let _self = this;
                for (const item of this.selectedItems?.filter(x => x.status === 400)) {
                    await this.onCommitDispatch(item.mask, _self.$filters.formatDateWithFormat(_self.dispatchDate, 'yyyyMMDD')).then(() => {
                        _self.onRefresh();
                        this.selectedItems = [];
                        this.selectedReset = true;
                    })
                }
                this.dialog.dispatch = false;
                this.$toast.add({ severity: 'success', summary: 'Collection Dispatched Successfully', detail: "The collection successfully dispatched", life: 6000 });
            },
            async onCommitDispatch(mask, dispatchDate) {
                return await this.$store.dispatch("collection/getURL", { url: '/services/tms/collection/' + mask + '/dispatch/' + dispatchDate });
            },
        }
        , async created() {
            this.statusFilter = Number(this.$router?.currentRoute?._value?.params?.status ?? this.statusFilter ?? 1);
        },
        async mounted() {
            this.statusFilter = Number(this.$router?.currentRoute?._value?.params?.status ?? this.statusFilter ?? 1);
        }
    }
</script>