<template>
    <pv-card>
        <template v-slot:title>
            Product Service Details
        </template>
        <template v-slot:subtitle>
            Please assign product services.
        </template>
        <template v-slot:content>
            <product-form :serviceGroupID="model?.id" :formData="model" @is-sync="isSync($event)" @is-option="isOption($event)"></product-form>
        </template>
        <template v-slot:footer>
            <div class="p-grid p-nogutter p-justify-between">
                <pv-button label="Back" @click="prevPage()" icon="pi pi-angle-left" />
                <pv-button label="Next" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" :disabled="!isValid" />
            </div>
        </template>
    </pv-card>
</template>
<script>
    //const config = require("@/assets/_js/cosmos-config.js").default;
    import { useVuelidate } from '@vuelidate/core';
    import productForm from '@/components/ruleengine/product-form.vue';
   // import validator from '@/assets/_js/cosmos-validators.js';
   // import { required, minLength, maxLength } from '@vuelidate/validators';
    export default {
        name: 'ServiceGroupProductTemplate',
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'product-form': productForm
        },
        data() {
            return {
                model: null,
                items: [],
                productOptions: [],
            }
        },
        computed: {
            isValid() {
                return ((this.items.length ?? 0) > 0);
            },
        },
        methods: {
            nextPage() {
                //this.$emit('next-page', { formData: this.model, pageIndex: 1 });
                if (this.isValid) {
                    this.$emit('next-page', { formData: { products: this.items, productOptions: this.productOptions }, pageIndex: 1 });
                }
            },
            prevPage() {
                this.$emit('prev-page', { formData: this.model, pageIndex: 1 });
            },
            async isSync(e) {
                this.$nextTick(() => {
                    this.items = e.model;
                });
            },
            async isOption(e) {
                this.$nextTick(() => {
                    this.productOptions = e.model;
                });
            }
          
        },
        async mounted() {
           this.$nextTick(() => {
               this.model = this.$attrs.formData;
            });
          
        },
        async updated() {
           
        }
    }
</script>
<style>
    #divsgpform > div.p-picklist.p-component > .p-picklist-buttons.p-picklist-target-controls {
        display: inline-flex !important;
        margin: auto;
    }
</style>