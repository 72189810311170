<template>
    <cosmos-grid :id="`MarkupRulesGrid_${parentID}`" :multiLevel="true" :source="source" sourceType="array" :showOperation="false" :showHeader="false">
        <pv-grid-column field="text" header="Name">
            <template #body="slotProps">
                <span v-html="slotProps.data.text?.replaceAll('&lsqb;', '&lt;b&gt;[').replaceAll('&rsqb;', ']&lt;/b&gt;')"></span>
            </template>
        </pv-grid-column>
        <pv-grid-column field="mask" :headerStyle="`min-width: 7rem; max-width: 7rem; width: 7rem`" style="border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-d-flex" style="width: 2rem;">
                    <div class="p-col">
                        <pv-button icon="pi pi-pencil" iconPos="right" class="p-button-info" @click="onEdit(slotProps.data)" title="Edit Record" />
                    </div>
                    <div class="p-col">
                        <pv-button :disabled="slotProps.data.recordStatus !== 0" icon="pi pi-trash" iconPos="right" class="p-button-danger" @click="onDelete(slotProps.data)" title="Delete Record" />
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <template #expansion="slotProps">
            <markup-rulecondition-grid :providerRuleID="slotProps.data.id" :loadKey="internalLoadKey" />
        </template>
    </cosmos-grid>
    <!-- Operation Dialog -->
    <!-- Edit Dialog -->
    <keep-alive>
        <is-dialog :header="(model?.id ?? 0) === 0 ? 'Add New Record' : 'Edit Record'" :breakpoints="{'2500px': '85vw', '960px': '75vw', '640px': '100vw'}" style="width: 50vw; min-width: 400px;" :visible="dialog.edit" @is-confirm="onConfirmEdit(model)" @is-cancel="onCancel" :confirmDisabled="confirmRuleDisabled">
            <markup-ruledetail-form :source="model"
                                    :parentID="parentID"
                                    :accountID="accountID"
                                    :currencyCode="currencyCode"
                                    :countryCode="countryCode"
                                    :providerID="model?.serviceProviderID ?? providerID"
                                    :currencyID="model?.contractCurrencyID ?? currencyID"
                                    :countryID="model?.contractCountryID ?? countryID"
                                    :zones="sources.zones"
                                    @is-sync="this.model = $event" />
        </is-dialog>
    </keep-alive>
    <!-- Delete Dialog -->
    <keep-alive>
        <is-dialog header="Delete Record?" :visible="dialog.delete" @is-confirm="onConfirmDelete(model.mask)" @is-cancel="onCancel()">
            <div class="confirmation-content p-d-flex">
                <div><i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" /></div>
                <div>
                    You are about to delete this record:
                    <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                        <tr>
                            <th style="text-align:left; vertical-align: top; width: 4rem;">Rule</th>
                            <th style="vertical-align: top; width: 2rem;">:</th>
                            <td><span v-html="this.model['text']"></span></td>
                        </tr>
                    </table>
                    Please be aware of the following:
                    <ul>
                        <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                        <li>For records that has activity, the record will only be disabled and archived.</li>
                        <li>Also deleting a record may be irreversible action.</li>
                    </ul>
                    Are you sure you still want to proceed?
                </div>
            </div>
        </is-dialog>
    </keep-alive>
</template>
<script>
    import markupruledetailform from '@/components/facility/rates/markup/markup-ruledetail-form';
    import markupruledetailgrid from '@/components/facility/rates/markup/markup-rulecondition-grid';
    export default {
        name: 'MarkupRulesGrid',
        components: {
            'markup-ruledetail-form': markupruledetailform,
            'markup-rulecondition-grid': markupruledetailgrid
        },
        props: {
            parentID: { type: Number },
            accountID: { type: Number },
            loadKey: { type: Number },
            providerID: { type: Number }, // Markup Contract Account ID
            currencyID: { type: Number },  // Customer Account ID
            countryID: { type: Number },  // Customer Account ID
            currencyCode: { type: String },
            countryCode: { type: String }
        },
        data() {
            return {
                internalLoadKey: null,
                model: null,
                dialog: {
                    delete: false,
                    edit: false,
                    rule: false
                },
                sources: {
                    grid: [],
                    zones: []
                }
            }
        },
        computed: {
            source() {
                return this.sources?.grid ?? [];
            }
        },
        watch: {
            loadKey() {
                this.rebindSource();
                this.internalLoadKey = this.loadKey;
            }
        },
        methods: {
            onCancel() {
                this.dialog.delete = false;
                this.dialog.edit = false;
                this.dialog.rule = false;
            },
            onDelete(data) {
                this.model = this.$filters.init(data);
                this.dialog.delete = true;
            },
            onEdit(data) {
                this.model = this.$filters.init(data);
                console.log(data);
                this.dialog.edit = true;
            },
            onConfirmDelete(mask) {
                this.$store.dispatch("markupcontractrule/deleteRecordByMask", mask)
                    .then((response) => {
                        if (response.success) {
                            this.sources.grid = this.source.filter(x => x.mask !== mask);
                            this.$toast.add({ severity: 'success', summary: 'Rule Detail', detail: "The record was deleted successfully!", life: 3000 });
                            this.onCancel();
                        }
                        else if ((response.errors ?? []).length > 0)
                            this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: response.errors[0]?.message, life: 3000 });
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
            onConfirmEdit(data) {
                let model = this.$filters.init(data);
                this.$store.dispatch("markupcontractrule/updateRecord", model)
                    .then((response) => {
                        if (response.success) {
                            let index = this.sources.grid.findIndex(x => x.id == response.result.id);
                            this.sources.grid[index] = response.result;
                            this.model = null;
                            this.$toast.add({ severity: 'success', summary: 'Surcharge Rule', detail: "The record was added successfully!", life: 3000 });
                            this.onCancel();
                        }
                        else if ((response.errors ?? []).length > 0)
                            this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: response.errors[0]?.message, life: 3000 });
                    }).catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
            rebindSource() {
                this.$store.dispatch("markupcontractrule/getURL", { url: `/services/facility/markupcontractrule/bymarkupaccount/id/${this.parentID}` })
                    .then((response) => {
                        this.sources.grid = [];
                        if (response.success)
                            this.sources.grid = response?.result ?? [];
                    })
                    .catch(() => {
                        this.sources.grid = [];
                    });
                if (this.providerID && this.countryID) {
                    setTimeout(() => {
                        this.$store.dispatch("markuprateconditiontype/getURL", { url: `/logics/settings/zone/getbyprovider/${this.providerID}/country/${this.countryID}` })
                            .then((response) => { this.sources.zones = response ?? []; });
                    }, 500);
                }            }
        },
        created() {
            this.rebindSource();
        }
    }
</script>
<style>
    .grid-rule-conditions {
        margin: 0.5rem 0 0 0 ;
        padding: 0 inherit;
    }
    .grid-rule-conditions > li {
        padding: 0.25rem 0;
    }

</style>