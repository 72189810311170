export default {
    api: 'https://cosmos-api-demo.integro360.com',
    cdn: 'https://cdn.integro360.com/cosmos/demo',
    secret: 'F78E592A4262D6D40EC54A10AE21CA5C',
    subscriptions: {
        ipgeolocator: {
            url: 'https://api.ipgeolocation.io/ipgeo?ip={0}&amp;apiKey={1}',
            key: '452e2da98ab740599944d2331677aad2'
        },
        googlemap: {
            url: 'https://www.google.com/maps/embed/v1/view?key={0}&amp;center={1},{2}&amp;zoom=17&amp;maptype=satellite',
            key: 'AIzaSyALrSTy6NpqdhIOUs3IQMfvjh71td2suzY'
        },
        fetchify: {
            key: 'd0bdf-216db-63c6f-02897'
        }
    },
    themes: [
        { text: 'Dark Theme', value: 100 },
        { text: 'Light Theme', value: 200 }
    ],
    menus: [
        { text: 'Standard', value: 100 },
        { text: 'Minified', value: 200 }
    ],
    display: [
        { text: 'Tiny', value: 10 },
        { text: 'Small', value: 12 },
        { text: 'Normal', value: 14 },
        { text: 'Large', value: 16 },
        { text: 'Huge', value: 18 },
    ]
}