<template>
    <div>
        <pv-grid-column :field="column.field" :header="column.header" :key="column.field"></pv-grid-column>
    </div>
</template>

<script>
    export default {
        name: 'ColumnTemplate',
        props: {
            column: { type: Object },
            field: { type: String, required: true, validator: (value) => /^[a-zA-Z0-9_]*$/.test(value) },
            header: { type: String },
            autoGenerate: { type: Boolean, default: true }
        },
        created() {
            console.log(this.field);
        }
    }
</script>
