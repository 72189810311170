<template>
    <form-wrapper title="Carrier Surcharges" sub-title="List of available surcharges">
        <pv-grid :value="dsSurcharges" v-model:expandedRows="expandedRows" dataKey="provider.id"
                 autoLayout
                 showGridlines
                 stripedRows
                 @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" responsiveLayout="scroll"
                 :rows="10"
                 :totalRecords="(dsTimeInTransit ?? []).length"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                 :paginator="true"
                 :alwaysShowPaginator="true"
                 :paginatorPosition="'bottom'"
                 :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'"
                 :pageLinkSize="3">
            <template #header>
                <div class="table-header-container">
                    <pv-button icon="pi pi-plus" label="Expand All" @click="expandAll" class="mr-2" style="margin-right: 0.5rem;" />
                    <pv-button icon="pi pi-minus" label="Collapse All" @click="collapseAll" />
                </div>
            </template>
            <pv-grid-column :expander="true" headerStyle="width: 3rem; padding: 10px;" style="padding: 5px 10px;" />
            <pv-grid-column field="provider.code" header="Provider Code" />
            <pv-grid-column field="provider.name" header="Provider Name" />
            <pv-grid-column field="providerCarrier.code" header="Carrier Code" />
            <pv-grid-column field="providerCarrier.name" header="Carrier Name" />
            <template #expansion="slotProps">
                <div class="orders-subtable" style="margin: -10px -10px -10px calc(3rem - 10px);">
                    <pv-grid :value="slotProps.data.surcharges" responsiveLayout="scroll"
                             autoLayout
                             showGridlines
                             stripedRows
                             :rows="10"
                             :totalRecords="(dsTimeInTransit ?? []).length"
                             currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                             :paginator="true"
                             :alwaysShowPaginator="true"
                             :paginatorPosition="'bottom'"
                             :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'"
                             :pageLinkSize="3"
                             groupRowsBy="groupingName"
                             rowGroupMode="subheader">
                        <template #groupheader="slotProps">
                            <span class="image-text" style="text-transform: uppercase; font-weight: 700 !important">{{slotProps.data.groupingName ?? "General" }}</span>
                        </template>
                        <pv-grid-column field="code" header="Code" headerStyle="width: 10rem" />
                        <pv-grid-column field="name" header="Name" headerStyle="width: 20rem" />
                        <pv-grid-column field="description" header="Description">
                            <template #body="slotProps">
                                <div><span v-html="slotProps.data?.description?.replace(/\\r\\n/g, '<br />')"></span></div>
                            </template>
                        </pv-grid-column>
                        <pv-grid-column field="groupingName" header="Group Name" />
                        <!-- Row Messages -->
                        <template #empty>
                            No records found.
                        </template>
                        <template #loading>
                            Retrieving records from database. Please wait...
                        </template>
                        <!-- Pagination -->
                        <template #paginatorRight>
                            <pv-button type="button" icon="pi pi-refresh" class="p-button-rounded p-button-text" @click="onRefresh" />
                        </template>
                    </pv-grid>
                </div>
            </template>
            <!-- Row Messages -->
            <template #empty>
                No records found.
            </template>
            <template #loading>
                Retrieving records from database. Please wait...
            </template>
            <!-- Pagination -->
            <template #paginatorRight>
                <pv-button type="button" icon="pi pi-refresh" class="p-button-rounded p-button-text" @click="onRefresh" />
            </template>
        </pv-grid>
    </form-wrapper>
</template>
<script>
    export default {
        name: "SurchargeGrid",
        data() {
            return {
                surcharges: [],
                expandedRows: [],
                expandedRows2: [],
                columns: [
                    { field: 'provider.name', header: 'Provider', width: 20 },
                    { field: 'code', header: 'Code', width: 20 },
                    { field: 'name', header: 'Name' }
                ],
            }
        },
        computed: {
            dsSurcharges() { return this.surcharges; },
        },
        watch: {
            integrationMask() {
                this.getSource();
            }
        },
        methods: {
            expandAll() { this.expandedRows = this.dsSurcharges.filter(p => p.provider.id); },
            collapseAll() { this.expandedRows = null; },
            getSource() {
                this.$store.dispatch("providersurcharge/getURL", { url: `/services/settings/serviceprovidersurcharge` }).then((response) => {
                    if (response && response.success) {
                        let ds = response.result;
                        let items = [];
                        let providers = [... new Set(ds.map(({ ...rest }) => { return `[${rest.provider.code}] ${rest.provider.name}`; }))];
                        providers.forEach((data) => {
                            let surcharges = ds.filter(x => `[${x.provider.code}] ${x.provider.name}` === data);
                            let item = { provider: surcharges[0].provider, surcharges: surcharges };
                            items.push(item);
                        });
                        this.surcharges = items;
                    }
                }).catch((ex) => console.log(ex));
            }
        },
        created() {
            this.getSource();
        }
    }
</script>