<template>
    <!--:showGuideHelper="true"-->
    <form-record :model="model" :modelState="v$" @is-reset="resetRecord" :viewOnly="true">
        <guide-form v-if="helper.guided" :formData="model" :activeIndex="activeIndex" :toggleTicker="guidedTicker" @is-sync="onSync($event)"></guide-form>
        <!--<nonguide-form v-if="!helper.guided" :formData="model" :activeIndex="activeIndex" :toggleTicker="nonguidedTicker" @is-sync="onSync($event)"></nonguide-form>-->
    </form-record>
</template>
<script>
   // const config = require("@/assets/_js/cosmos-config.js").default;
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
   // import validator from '@/assets/_js/cosmos-validators.js';
    import { useVuelidate } from '@vuelidate/core';
   // import { required, minLength, maxLength } from '@vuelidate/validators';
    import guideForm from '@/components/transaction/tms/consignment/guide-form.vue';
   //import nonguideForm from '@/components/transaction/tms/consignment/nonguide-form.vue';

    const moduleName = 'consignment';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                guidedModel: null,
                nonGuidedModel: null,
                guidedTicker: null,
                nonguidedTicker: null
            }
        },
        components: {
            'guide-form': guideForm
            //,'nonguide-form': nonguideForm,

        },
        computed: {
            initModel() {
                return this.init;
            },
            isDomestic() {
                return ((this.model?.shipFrom_CountryId ?? 0) > 0 && (this.model?.shipTo_CountryId ?? 0) > 0) ? (this.model?.shipFrom_CountryId ?? 0) === (this.model?.shipTo_CountryId ?? this.model?.shipFrom_CountryId) : true;
            },
            activeIndex() {
                return this.isValidCollection + this.isValidRecipient + (!this.isDomestic ? this.isValidInternationForms : 0) + this.isValidOrderPackage + this.isValidAllocation;
            },
            isValidCollection() {
                return ((this.model?.shipFrom_FirstName?.length ?? 0) > 0) && ((this.model?.shipFrom_Address1?.length ?? 0) > 0) &&
                    ((this.model?.shipFrom_City?.length ?? 0) > 0) && ((this.model?.shipFrom_PostalCode?.length ?? 0) > 0) && ((this.model?.shipFrom_Email?.length ?? 0) > 0)
                    && ((this.model?.customerId ?? 0) > 0) ? 1 : 0;
            },
            isValidRecipient() {
                return ((this.model?.shipTo_FirstName?.length ?? 0) > 0) && ((this.model?.shipTo_Address1?.length ?? 0) > 0) &&
                    ((this.model?.shipTo_City?.length ?? 0) > 0) && ((this.model?.shipTo_PostalCode?.length ?? 0) > 0) && ((this.model?.shipTo_Email?.length ?? 0) > 0) ? 1 : 0;
            },
            isValidInternationForms() {
                return ((this.model?.shipmentType ?? 0) > 0) && ((this.model?.incoterm ?? 0) > 0) && ((this.model?.exportReason ?? 0) > 0)  ? 1 : 0;
            },
            isValidOrderPackage() {
                if ((this.model?.isHazardous) && ((this.model?.customerReference?.length ?? 0) > 0) && ((this.model?.packages?.length ?? 0) > 0)) {
                    return ((this.model?.dG_EmergencyContact?.length ?? 0) > 0) && ((this.model?.dG_EmergencyTelephone?.length ?? 0) > 0) ? 1 : 0;
                }
                return ((this.model?.customerReference?.length ?? 0) > 0) && ((this.model?.packages?.length ?? 0) > 0) ? 1 : 0;
            },
            isValidAllocation() {
                return ((this.model?.productCode !== null && this.model?.productCode !== "" && this.model?.productCode !== undefined) ||
                    (this.model?.serviceGroupCode !== null && this.model?.serviceGroupCode !== "" && this.model?.serviceGroupCode !== undefined)) ? 1 : 0;
            }

        },
        watch: {
            'helper.guided': {
                handler() {
                    this.$nextTick(() => {
                        if (this.helper.guided) {
                            this.guidedTicker = Math.random();
                        }
                        else {
                            this.nonguidedTicker = Math.random();
                        }
                        try {
                            this.model.model = null;
                        }
                        catch { /**/ }
                    }).then(() => {
                        if (!this.helper.guided) {
                            this.guidedModel = this.model;
                        }
                        else {
                            this.nonGuidedModel = this.model;
                        }
                    })
                     ;

                }, deep: true
            }
        },
        methods: {
            onSync(e) {
             
                this.$nextTick(() => {
                    this.model = this.init;
                    for (let field in e) {
                        try {
                            this.model[field] = e[field];
                        }
                        catch { /**/}
                        
                    }
                    try {
                        this.model.model = null;
                    }
                    catch { /**/ }
                });
                
            },
            onSyncNonGuided(e) {
                if (!this.helper.guided) {
                    this.$nextTick(() => {
                        for (let field in e.model) {
                            this.model[field] = e.model[field];
                        }
                    });
                }
            }
        },
        created() {
            this.$router.push({ name: this.router.module + '-details' });
            this.model = this.init;
            this.model.packages = [];
            this.model.resetPackage = true;
            this.guidedModel = this.init;
            this.guidedModel.packages = [];
            this.nonGuidedModel = this.init;
        },
        updated() {
            setTimeout(() => {
                if (this.$route.query?.type) {
                    this.$router.push({ name: this.router.module + '-details' });
                }        
            },0)
            
        },
        mounted() {
            this.model = this.init;
            this.model.packages = [];
            this.model.resetPackage = true;
            //this.model = this.initModel;
            this.guidedModel = this.init;
            this.guidedModel.packages = [];
            this.guidedModel.resetPackage = true;
            this.nonGuidedModel = this.init;
        },
        unmounted() {
            this.model = null;
        }

    }
</script>
