import SecureLS from 'secure-ls';
import config from '../../assets/_js/cosmos-config.js';
const ls = new SecureLS({ encryptionSecret: config.secret });
export default {
    namespaced: true,
    state: {
        user: null,
        menu: null,
        errors: null,
        sessionkey: null,
        configuration: null
    },
    getters: {
        loggedIn: state => !!state.user,
        currentMenu: state => state.menu,
        currentUser: state => typeof (state.user) === "object" ? state.user : null,
        currentTheme: state => typeof (state.user) === "object" ? state.user?.defaults?.theme ?? 100 : null,
        currentScale: state => typeof (state.user) === "object" ? state.user?.defaults?.theme ?? 14 : null,
        currentUserType: state => typeof (state.user) === "object" ? state.user?.userTypeName ?? "Guest" : null,
        errorMessages: state => state.errors,
        sessionKey: state => state.sessionkey,
        configuration: state => state.configuration,
        session: state => {
            let sessionKey = atob(state.sessionkey).split('|');
            return {
                tenant: sessionKey[0],
                environment: sessionKey[1],
                warehouse: sessionKey[2],
                customer: sessionKey[3],
                currentUser: sessionKey[4],
                executiveUser: sessionKey[5],
            };
        }
    },
    mutations: {
        SET_USER_TOKEN: (state, data) => {
            state.user = data?.user;
            state.menu = data?.menu;
            ls.set("x-cosmos-token", data?.session ?? "");
            ls.set("x-cosmos-session", data?.user ?? "");
            ls.set("x-cosmos-sitemap", data?.menu ?? "");
            sessionStorage.setItem("session-expiration", data?.session?.expiryDate);
            sessionStorage.setItem("session-id", data?.session?.ssid);
            sessionStorage.setItem("session-theme", data?.user?.defaults?.theme ?? "dark");
        },
        REMOVE_USER_TOKEN: (state) => {
            state.user = null;
            state.menu = null;
            state.errors = null;
            sessionStorage.removeItem("session-id");
            sessionStorage.removeItem("session-expiration");
            sessionStorage.removeItem("session-theme");
            sessionStorage.clear();
            ls.removeAll();
            localStorage.clear();
        },
        SET_USER_MENU: (state, data) => {
            state.menu = data;
            ls.set("x-cosmos-sitemap", data);
        },
        REMOVE_USER_MENU: (state) => {
            state.menu = null;
            ls.removeItem("x-cosmos-sitemap");
        },
        SET_ERROR: (state, errors) => {
            state.user = null;
            state.menu = null;
            state.errors = errors.errors ? errors.errors : errors;
        },
        REMOVE_ERROR: (state) => {
            state.error = null;
        },
        SET_SESSION_KEY: (state, keys) => {
            state.sessionkey = keys;
        },
        SET_SESSION_SETTING: (state, config) => {
            state.configuration = config;
        }
    },
    actions: {
        async login({ commit }, form) {
            var data = null;
            commit("REMOVE_USER_TOKEN");
            await this.$axios.post(`${config.api}/services/account/token`, form, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': null
                }
            })
            .then((user) => {
                data = user.data;
                if (user !== null && user !== undefined && user?.data !== null)
                    commit("SET_USER_TOKEN", user.data.result);
                if (data.errors)
                    commit("SET_ERROR", data.errors);
            })
            .catch((ex) => {
                data = ex?.data ?? ex;
                if (ex.status === 401)
                    commit("SET_ERROR", ex.data);
                else
                    commit("SET_ERROR", ex);
            });
            return data;
        },
        async logout({ commit }) {
            await this.$axios.delete(`${config.api}/services/account/token`)
                .then(() => { commit("REMOVE_USER_TOKEN"); });
        },
        async refreshToken({ commit }) {
            const token = ls.get("x-cosmos-token");
            if (token) {
                await this.$axios.put(`${config.api}/services/account/token`, null)
                    .then((user) => {
                        if (user !== null && user !== undefined && user.data != null)
                            commit("setToken", user.data.result);
                        else
                            commit("removeToken");
                    })
                    .catch((ex) => {
                        if (ex.status === 401)
                            commit("setError", ex.data);
                        else
                            commit("setError", ex);
                    });
            }
        },
        async getSitemap({ commit }) {
            const token = ls.get("x-cosmos-token");
            if (token) {
                await this.$axios.get(`${config.api}/services/account/sitemap`)
                    .then((menu) => {
                        if (menu !== null && menu !== undefined && menu.data != null)
                            commit("SET_USER_MENU", menu.data);
                        else
                            commit("REMOVE_USER_MENU");
                    })
                    .catch((ex) => {
                        if (ex.status === 401)
                            commit("SET_ERROR", ex.data);
                        else
                            commit("SET_ERROR", ex);
                    });
            }
        }
    }
};