<template>
    <form-wrapper title="Invoice Reconcile" sub-title="List of all invoice reconcile requests.">
        <cosmos-grid id="InvoiceReconcileList" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showOperation="false" :showDelete="true" :showAdd="false" :showExport="false" :operationWidth="7" :forceRefresh="forceRefresh" :key="refreshKey">
            <template #toolbar>
                <div class="p-d-flex p-jc-between p-ai-center p-grid" style="padding: 0 !important; margin: 0 !important;">
                    <div class="p-col-12 p-md-9  p-d-flex" style="padding:0 !important; margin:0; padding-right:0.2rem">
                        <div class="p-grid p-col-6" style="padding: 0 !important; width: calc(100% - 30rem); display: inline-block;">
                            <form-lookup id="profile" :source="sources.profiles" sourceType="array" :value="model.importKey" @is-sync="{model.importKey = $event.value; model.templateName = $event.record?.templateName}" :required="true" style="margin: 0;" :hideLabel="true" placeholder="--- Select Import Profile ---" :sorted="true" />
                        </div>
                        <div v-show="model.importKey" class="p-grid p-col-3" style="padding: 1rem;">
                            <pv-file id="customTemplate"
                                     ref="customTemplate"
                                     name="files"
                                     mode="basic"
                                     chooseIcon="fa fa-upload fa-fw"
                                     chooseLabel="Upload File..."
                                     accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                     auto="true"
                                     :url="uploadURL"
                                     :multiple="false"
                                     :maxFileSize="maxFileSizeInBytes"
                                     :customUpload="true"
                                     @uploader="onTemplateUpload" class="p-button" />
                        </div>
                    </div>
                    <div v-if="model.importKey" class="p-d-flex p-jc-end p-grid p-col">
                        <div class="p-col-12 p-md-12" style="padding: 0.2rem;">
                            <div class="p-d-flex p-jc-end"><a :href="`https://cdn.integro360.com/cosmos/common/documents/templates/import/${model.templateName}.xlsx?${Math.random()}`" target="_blank" class="p-button p-component" style="margin-right: 0.25rem; margin-bottom: 0.25rem;"><i class="fa fa-download fa-fw" style="margin-right: 0.25rem;"></i> Download Template</a></div>
                        </div>
                    </div>
                </div>
            </template>
            <pv-grid-column field="importProfileCode" header="PROFILE" headerStyle="width: 12rem" />
            <pv-grid-column field="filename" header="File">
                <template #body="slotProps">
                    <div v-if="slotProps.data.completeDate"><a :href="`https://cdn.integro360.com/cosmos/${slotProps.data.filePath}?${Math.random()}`" target="_blank" style="margin-right: 0.25rem; margin-bottom: 0.25rem;"><i class="fa fa-download fa-fw" style="margin-right: 0.25rem;"></i> {{slotProps.data.filename}}</a></div>
                    <div v-else> {{slotProps.data.filename}} </div>    
                </template>
            </pv-grid-column>
            <pv-grid-column field="summary" header="SUMMARY" />
            <pv-grid-column field="statusName" header="STATUS" headerStyle="width: 14rem">
                <template #body="slotProps">
                    <span :class="`import-status-${slotProps.data.status}`">{{ slotProps.data.statusName }} </span>
                </template>
            </pv-grid-column>
            <pv-grid-column field="startDate" header="START DATE" headerStyle="width: 12rem">
                <template #body="slotProps">
                    {{ $filters.toDateFullTimeDisplay(slotProps.data.startDate) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="completeDate" header="COMPLETED DATE" headerStyle="width: 12rem">
                <template #body="slotProps">
                    {{ $filters.toDateFullTimeDisplay(slotProps.data.completeDate) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="sourceName" header="SOURCE" headerStyle="width: 7rem" />
            <!--<pv-grid-column field="code" header="REPORT" headerStyle="width: 10.5rem">
                <template #body="slotProps">
                    <pv-button v-if="slotProps.data.status >= 1000" label="View Report" icon="fa fa-file-lines" @click="showKey(slotProps.data)" />
                </template>
            </pv-grid-column>-->
        </cosmos-grid>
    </form-wrapper>
    <is-dialog header="Template Validation Errors" :visible="dialog.alert" :showConfirm="false" @is-cancel="dialog.alert = false" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{ width: '75vw'}">
        <div class="confirmation-content p-d-flex">
            <div><i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" /></div>
            <div style="text-align: justify;">
                <div v-html="`${dialog.alertMessage?.replace('headers:', 'headers: <div class=\'p-grid p-nogutter\' style=\'margin: 1rem 2rem 0 0;\'>').replaceAll('], [', '] [').replaceAll('[', '<div class=\'p-col-12 p-md-4 p-lg-3\' style=\'padding: 0.25rem 1rem; background: var(--surface-c); border-bottom: 3px solid var(--surface-a); border-right: 3px solid var(--surface-a); border-left: 3px solid var(--surface-d); font-weight: bold;\'>').replaceAll(']', '</div>') }</div>`"></div>
            </div>
        </div>
    </is-dialog>
    <is-dialog header="Import Report" :visible="dialog.report" :showConfirm="false" @is-cancel="dialog.report = false" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{ width: '75vw'}">
        <div class="confirmation-content p-d-flex">
            <div style="text-align: justify; width: 100%; background: white;">
                <iframe :src="dialog.alertMessage" style="width: 100% !important; height: 65vh; border: 0;" />
            </div>
        </div>
    </is-dialog>
</template>
<script>
    import config from '@/assets/lib/cosmos/_js/config.js';
    export default {
        name: 'InvoiceReconcileGrid',
        data() {
            return {
                forceRefresh: false,
                refreshKey: 1,
                model: {
                    importKey: null,
                    file: null,
                    templateName: null
                },
                sources: {
                    profiles: []
                },
                columns: [
                    { field: 'topicName', header: 'TOPIC NAME', width: 20 },
                    { field: 'code', header: 'CODE', width: 15 },
                    { field: 'name', header: 'NAME' },
                ],
                searchFields: ['code', 'name'],
                dialog: {
                    alert: false,
                    report: false,  
                    alertMessage: null
                }
            }
        },
        computed: {
            uploadURL() { return `${config.config.endpoint.api}/file/upload/temp`; },
            columnURL() { return `${config.config.endpoint.api}/services/facility/invoicereconcile/upload`; },
            includeTopics() {
                let reconcile = 3;
                return [reconcile];
            }
        },
        methods: {
            getProfiles() {
                var profiles = []
                this.$store.dispatch("importprofile/getAll", { sort: null, where: null })
                    .then((response) => {
                        (response.result ?? []).filter(x => (x.id < 1000 || x.isValidMapping) && this.includeTopics.includes(x.topicID)).forEach(x => {
                            if (x.serviceProviderName !== undefined)
                                profiles.push({ id: x.mask, text: `<div class='topic-${x.topicName.replace(" Import", "").replaceAll(" ", "-").toLowerCase()}' style='text-transform: uppercase; font-weight: bold; width: 10rem; display: inline-block;'>${x.topicName.replace(" Import", "")}</div> ${x.text.replace("[GENERIC] ", "")} (${x.serviceProviderName?.replace("[GENERIC] ", "")})<span style="position: absolute; right: 3rem; font-weight: bold;">${(x.customerName ?? "COSMOS")}</span>`, templateName: x.templateName + (x.id < 1000 ? "_GENERIC" : "") });
                            else
                                profiles.push({ id: x.mask, text: `<div class='topic-${x.topicName.replace(" Import", "").replaceAll(" ", "-").toLowerCase()}' style='text-transform: uppercase; font-weight: bold; width: 10rem; display: inline-block;'>${x.topicName.replace(" Import", "")}</div> ${x.text.replace("[GENERIC] ", "")} <span style="position: absolute; right: 3rem; font-weight: bold;">${(x.customerName ?? "COSMOS")}</span>`, templateName: x.templateName + (x.id < 1000 ? "_GENERIC" : "") });
                        });
                        this.sources.profiles = profiles;
                    })
                    .catch(() => { this.sources.profiles = []; });
            },
            onIconCorrection() {
                var fileUpload = document.querySelector(".p-fileupload-choose.p-button");
                var icon = fileUpload.querySelector(".p-button-icon.pi");
                if (icon) {
                    icon.classList.remove("pi")
                    icon.classList.remove("pi-plus");
                    icon.classList.add("fa");
                    icon.classList.add("fa-upload");
                    setTimeout(this.onIconCorrection(), 100);
                }
            },
            onTemplateUpload(event) {
                var formData = new FormData();
                formData.append("image", event.files[0]);
                this.$axios.post(this.uploadURL, formData, { headers: { 'Content-Type': "multipart/form-data" } }).then((file) => {
                    if ((file.data.errors ?? []).length > 0)
                        this.$toast.add({ severity: 'error', summary: 'File Validation Error', detail: file.data.errors[0].general/*, life: 3000*/ });
                    else
                    {
                        this.model.file = file.data;
                        this.forceRefesh = false;
                        this.$axios.post(this.columnURL, this.model).then((mapping) => {
                            if (!mapping.data.success && mapping.data.errors.length > 0) {
                                this.dialog.alertMessage = mapping.data.errors[0].message;
                                this.dialog.alert = true;
                            }
                            else if (mapping.data.success && mapping.data.result.indexOf("Successfully queued the file") !== -1)
                            {
                                this.$toast.add({ severity: 'success', summary: 'File Import', detail: `Successfully queued the file '<b>${this.model.file.filename}</b>'.`, life: 3000 });
                                this.model.file = null;
                                this.$nextTick(() => {
                                    this.refreshKey = Math.random();
                                    this.forceRefresh = true;
                                });
                                setTimeout(() => {
                                    this.forceRefesh = false;
                                }, 1000);
                            }
                        });
                    }
                })
                event = null;
                this.$refs["customTemplate"].files = null;
            },
            showKey(data) {
                const tenant = config.common.$tenant().filter(x => x.id === (data.tenantID ?? 1))[0];
                this.dialog.alertMessage = `${config.config.endpoint.cdn}/${tenant.code.toLowerCase()}/invoice/documents/${data.mask.toUpperCase()}.html?${Math.random()}`;
                this.dialog.report = true;
            }
        },
        created() {
            this.getProfiles();
        },
        mounted() {
            setTimeout(() => {
                this.onIconCorrection();
            }, 0);
        }
    }
</script>
<style>
    .import-status-100 { font-weight: bold; text-transform: uppercase; }
    .import-status-200 { color: var(--text-info); font-weight: bold; text-transform: uppercase; }
    .import-status-1000 { color: var(--text-danger); font-weight: bold; text-transform: uppercase; }
    .import-status-1500 { color: var(--text-help); font-weight: bold; text-transform: uppercase; }
    .import-status-2000 { color: var(--text-warning); font-weight: bold; text-transform: uppercase; }
    .import-status-2500 { color: var(--text-success); font-weight: bold; text-transform: uppercase; }
    .topic-consignment, .topic-CONSIGNMENT { color: var(--text-info) }
    .topic-address-book, .topic-ADDRESSBOOK { color: var(--text-help) }
    .topic-invoicereconcile, .topic-INVOICERECONCILE { color: var(--text-warning) }
</style>