<template>
    <pv-accordion id="RateConditions" :multiple="true" :activeIndex="[conditions.filter(x => x.parentID === null || x.parentID === undefined).length - 1]">
        <pv-accordion-tab v-for="(condition, index) in conditions.filter(x => x.parentID === null || x.parentID === undefined)" :key="condition.tempParentID">
            <template #header>
                <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                    <div class="p-col-fixed p-as-start" style="width: 1.5rem"><i class="pi pi-bars" :style="`color: var(--text-${condition.logicType === 100 && condition.sortOrder > 1 ? 'danger' : condition.logicType === 200 ? 'info' : '' });`"></i></div>
                    <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" :title="(condition?.summary ?? condition?.text)?.replaceAll('<b>', '')?.replaceAll('</b>', '').replace('{{subcondition}}', `${conditions.filter(x => (x.parentID !== null && x.parentID !== undefined) && (x.parentID === condition.id || x.tempParentID === condition.tempParentID)).map(x => x.summary).join(' ')?.replaceAll('<b>', '')?.replaceAll('</b>', '').replace('{{subcondition}}', '')}`.replaceAll('  ', ' '))">
                        <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; <span v-html="`${(condition?.summary ?? condition?.text ?? '')?.substring(0, (((condition?.summary ?? condition?.text  ?? '')?.length ?? 0) > 150 ? 150 : (condition?.summary ?? condition?.text  ?? '')?.length ?? 0 )).replace('{{subcondition}}', `${conditions.filter(x => (x.parentID !== null && x.parentID !== undefined) && (x.parentID === condition.id || x.tempParentID === condition.tempParentID)).map(x => x.summary).join(' ')?.replaceAll('<b>', '')?.replaceAll('</b>', '').replace('{{subcondition}}', '')}`)}`"></span></div>
                    </div>
                    <div class="p-col-fixed p-as-end" style="width: 1.5rem; top: 0.1rem; right: 4rem; position: absolute;"><pv-button icon="pi pi-trash" class="p-button p-button-danger" @click="onDelete(condition.tempParentID)"></pv-button></div>
                </div>
            </template>
            <markup-ruleconditiondetail-form :countryCode="countryCode"
                                             :currencyCode="currencyCode"
                                             :source="condition"
                                             :categories="sources.categories"
                                             :condition="sources.conditions"
                                             :operator="operators"
                                             :tradebloc="sources.tradebloc"
                                             :postalbloc="sources.postalbloc"
                                             :postalregex="sources.postalRegexs"
                                             :region="sources.region"
                                             :subregion="sources.subregion"
                                             :subSource="conditions.filter(x => (x.parentID !== null && x.parentID !== undefined) && (x.parentID === condition.id || x.tempParentID === condition.tempParentID))"
                                             :index="index"
                                             :selectedProducts="selectedProducts"
                                             :carrierProducts="carrierProducts"
                                             :zones="sources.zones"
                                             @is-sync="onSync(condition.tempParentID, $event)"
                                             @is-sync-subcondition="onSyncChild(condition.tempParentID, $event.tempChildID, $event)"
                                             @is-add-subcondition="onSyncChild(condition.tempParentID, $event.tempChildID, $event)"
                                             @is-delete-subcondition="onDeleteSubCodition($event)" />
        </pv-accordion-tab>
    </pv-accordion>
    <div class="p-d-flex">
        <div class="p-col-12">
            <pv-button label="Add Condition" icon="pi pi-plus" @click="onAdd" style="width: 100%"></pv-button>
        </div>
    </div>
</template>
<script>
    import markupruleconditiondetailform from '@/components/facility/rates/markup/markup-ruleconditiondetail-form.vue';
    export default {
        name: 'MarkupRuleConditionsAccordion',
        components: { 'markup-ruleconditiondetail-form': markupruleconditiondetailform },
        props: {
            parentID: { type: Number, default: null },
            countryCode: { type: String },
            currencyCode: { type: String },
            providerID: { type: Number }, // Markup Contract Account ID
            currencyID: { type: Number },  // Customer Account ID
            countryID: { type: Number },  // Customer Account ID
            selectedProducts: { type: Array, default: () => [] },
            carrierProducts: { type: Array, default: () => [] }
        },
        data() {
            return {
                conditions: [],
                sources: {
                    categories: [],
                    conditions: [],
                    operators: [],
                    tradeBlocs: [],
                    postalBlocs: [],
                    postalRegexs: [],
                    regions: [],
                    subRegions: [],
                    zones: []
                }
            }
        },
        computed: { operators() { return this.$vm.$enums.rateoperatortype; } },
        methods: {
            onAdd() {
                this.conditions.push({
                    id: 0,
                    templateID: 100,
                    markupContractRuleID: 0,
                    parentID: null,
                    markupRateCategoryID: 1,
                    markupRateCategoryText: null,
                    rateConditionID: 1,
                    rateConditionText: null,
                    dataType: 100,
                    category1: "0.00",
                    category2: "0.00",
                    rateType: 100,
                    rateApplied: 0,
                    operatorType: 650,
                    rateChargeType: 100,
                    referenceTypeID: 100,
                    referenceID: null,
                    referenceText: null,
                    logicType: 100,
                    logicTypeText: 'And',
                    tempParentID: `${Math.floor(Math.random() * 999999999999)}`,
                    tempChildID: null,
                    sortOrder: (this.conditions ?? []).length + 1,
                    summary: null
                });
            },
            onDelete(index) {
                let conditions = this.conditions.filter(x => x.tempParentID !== index);
                this.conditions = conditions;
                this.$nextTick(() => {
                    this.$emit("is-sync", this.conditions);
                });
            },
            onSync(tempParentID, e) {
                var index = this.conditions.findIndex(x => (x.parentID === null || x.parentID === undefined) && x.tempParentID == tempParentID);
                if (index >= 0) {
                    this.conditions[index] = e;
                    this.$nextTick(() => {
                        this.$emit("is-sync", this.conditions);
                    });
                }
            },
            onSyncChild(tempParentID, tempChildID, e) {
                var index = this.conditions.findIndex(x => (x.parentID !== null && x.parentID !== undefined)  && x.tempParentID === tempParentID && x.tempChildID === tempChildID);
                if (index >= 0)
                    this.conditions[index] = e;
                else
                    this.conditions.push(e);
                this.$nextTick(() => {
                    this.$emit("is-sync", this.conditions);
                });
            },
            onDeleteSubCodition(e) {
                var index = this.conditions.findIndex(x => x.id === e.id && x.tempParentID === e.tempParentID && x.tempChildID === e.tempChildID);
                if (index >= 0)
                    this.conditions.splice(index, 1);
                this.$nextTick(() => {
                    this.$emit("is-sync", this.conditions);
                });
            },
            onSourceBind() {
                this.$store.dispatch("markupratecategorytype/getAll", { sort: null, where: null })
                    .then((response) => { this.sources.categories = response?.result ?? []; });
                this.$store.dispatch("markuprateconditiontype/getAll", { sort: null, where: null })
                    .then((response) => { this.sources.conditions = response?.result ?? []; });
                this.$store.dispatch("markuprateconditiontype/getURL", { url: '/services/facility/servicegrouprulecondition/tradeblocandcountry' })
                    .then((response) => { this.sources.tradebloc = response?.result ?? []; });
                this.$store.dispatch("markuprateconditiontype/getURL", { url: '/services/facility/rulepostalbloc' })
                    .then((response) => { this.sources.postalbloc = response?.result ?? []; });
                this.$store.dispatch("markuprateconditiontype/getURL", { url: '/services/facility/rulepostalbloc/postalregex' })
                    .then((response) => {
                        var responseData = response?.result ?? [];
                        var data = [];
                        responseData.map(x => x.description).filter((value, index, array) => array.indexOf(value) === index).forEach((country) => {
                            let items = [];
                            responseData.filter(x => x.description === country).filter((value, index, array) => array.indexOf(value) === index).forEach((item) => { items.push(item); });
                            data.push({ label: country.replace("></span>", " style='zoom: 70%; margin-right: 0.75rem;'></span>"), items: items });
                        })
                        this.sources.postalRegexs = data;
                    });
                this.$store.dispatch("markuprateconditiontype/getURL", { url: '/services/referential/region' })
                    .then((response) => { this.sources.region = response?.result ?? []; });
                this.$store.dispatch("markuprateconditiontype/getURL", { url: '/services/referential/subregion' })
                    .then((response) => { this.sources.subregion = response?.result ?? []; });
                if (this.providerID && this.countryID) {
                    setTimeout(() => {
                        this.$store.dispatch("markuprateconditiontype/getURL", { url: `/logics/settings/zone/getbyprovider/${this.providerID}/country/${this.countryID}` })
                            .then((response) => { this.sources.zones = response ?? []; });
                    }, 500);
                }
            },
            onRuleBind() {
                if (this.parentID)
                    this.$store.dispatch("markupcontractrulecondition/getURL", { url: `/services/facility/markupcontractrulecondition/byrule/id/${this.parentID}` }).then((response) => {
                        this.conditions = [];
                        for (let i = 0; i < response.result.length; i++)
                            this.conditions.push(response.result[i]);
                        for (let i = 0; i < this.conditions.length; i++)
                            this.conditions[i].sortOrder = i + 1;
                    });
            }
        },
        created() {
            this.onSourceBind();
            this.$nextTick(() => {
                this.onRuleBind();
            })
        },
        mounted() {
            this.$nextTick(() => {
                this.onRuleBind();
            });
            var buttons = document.getElementsByClassName('p-inputnumber-button');
            for (var i = 0; i < buttons.length; i++)
                buttons[i].tabIndex = -1;
        }
    }
</script>
<style>
    #RateConditions .p-accordion-header-link { padding: 0.75rem 1rem; border-bottom: 1px solid var(--gray-200); }
</style>
