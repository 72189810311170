<template>
    <cosmos-grid id="RateRuleConditionGrid" :source="source" sourceType="array" :searchFilters="['code', 'name']" :autoGenerate="false" :columns="columns" :deleteDisplayFields="deleteDisplay" :showOperation="false" :showHeader="false">
        <pv-grid-column field="text" header="Conditions">
            <template #body="slotProps">
                <span v-html="`${slotProps.data.text}`"></span>
            </template>
        </pv-grid-column>
        <pv-grid-column field="mask" :headerStyle="`min-width: 4rem; max-width: 4rem; width: 4rem`" style="border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-d-flex" style="width: 2rem;">
                    <div class="p-col">
                        <pv-button :disabled="slotProps.data.recordStatus !== 0" icon="pi pi-trash" iconPos="right" class="p-button-danger" @click="onDelete(slotProps.data)" title="Delete Record" />
                    </div>
                </div>
            </template>
        </pv-grid-column>
    </cosmos-grid>
    <!-- Operation Dialog -->
    <is-dialog header="Delete Record?" :visible="dialog.delete" @is-confirm="onConfirmDelete(model.mask)" @is-cancel="onCancel()">
        <div class="confirmation-content p-d-flex">
            <div><i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" /></div>
            <div>
                You are about to delete this record:
                <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                    <tr>
                        <th style="text-align:left;">Condition</th>
                        <td>:</td>
                        <td><span v-html="this.model['text']"></span></td>
                    </tr>
                </table>
                Please be aware of the following:
                <ul>
                    <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                    <li>For records that has activity, the record will only be disabled and archived.</li>
                    <li>Also deleting a record may be irreversible action.</li>
                </ul>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
</template>

<script>
    export default {
        name: "RateRuleConditionGrid",
        props: {
            providerRuleID: { type: Number },
            loadKey: { type: Number }
        },
        data() {
            return {
                model: null,
                source: [],
                dialog: {
                    delete: false,
                    edit: false
                }
            }
        },
        watch: { loadKey() { this.rebindSource(); } },
        methods: {
            onEdit(e) {
                this.model = this.$filters.init(e);
                this.dialog.edit = true;
            },
            onDelete(e) {
                this.model = e;
                this.dialog.delete = true;
            },
            onCancel() {
                this.dialog.delete = false;
                this.dialog.edit = false
            },
            onConfirmDelete(e) {
                this.$store.dispatch("markupcontractrulecondition/deleteRecordByMask", e)
                    .then((response) => {
                        if (response.success) {
                            this.source = this.source.filter(x => x.mask !== e);
                            this.$toast.add({ severity: 'success', summary: 'Rule Condition Detail', detail: "The record was deleted successfully!", life: 3000 });
                            this.onCancel();
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
            rebindSource() {
                this.$store.dispatch("markupcontractrulecondition/getURL", { url: `/services/facility/markupcontractrulecondition/byrule/id/${this.providerRuleID}` }).then((response) => {
                    this.source = (response?.result ?? []).filter(x => x.parentID === null || x.parentID === undefined);
                });
            }
        },
        created() { this.rebindSource(); }
    }
</script>