<template>
    <pv-card>
        <template v-slot:title>
            International Forms Details
        </template>
        <template v-slot:subtitle>
            Please provide your international forms detail.
        </template>
        <template v-slot:content>
            <internationalforms-form :formData="model" :recordformData="formData" @is-sync="onSync($event)"></internationalforms-form>
        </template>
        <template v-slot:footer>
            <div class="p-grid p-nogutter p-justify-between">
                <pv-button label="Previous" @click="prevPage()" icon="pi pi-angle-left" />
                <pv-button label="Next" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" :disabled="!isValid"/>
            </div>
        </template>
    </pv-card>
</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/consignment-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    import internationalformsFrom from '@/components/transaction/tms/consignment/internationalforms-form.vue';
    //import validator from '@/assets/_js/cosmos-validators.js';
    //import { required, minLength, maxLength, email } from '@vuelidate/validators';
    export default {
        name: 'InternationalFormsTemplate',
        mixins: [mixins.INTERNATIONALFORMS.STEPPER],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'internationalforms-form': internationalformsFrom
        },
        data() {
            return {
                mainModel: null
            }
        },
        computed: {
            isValid() {
                return !this.v$.$invalid;
            }
        },
        methods: {
            nextPage() {
               // this.model.warehouseId = this.$vm.$appSession.warehouse.id;
               // this.model.environmentId = this.$vm.$appSession.environment.id;
                if (this.isValid) {
                    this.$nextTick(() => {
                        this.$emit('next-page', { formData: this.model, pageIndex: 1 });
                    })
                }
            },
            prevPage() {
                this.$emit('prev-page', { formData: this.model, pageIndex: 1 });
            },
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>