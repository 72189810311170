<template>
    <form-object :id="id" class="w-full" :autoFit="true" :label="label" :floatLabel="false" :hideLabel="false" :v$="v$" :required="required"  :conditional="conditional" style="width:100% !important;">
        <template #default>
            <pv-file v-if="!disabled" :id="id"
                     :ref="id"
                     name="files"
                     :url="uploadURL"
                     :mode="type"
                     :multiple="multiple"
                     :accept="accept"
                     :auto="false"
                     :maxFileSize="maxFileSizeInBytes"
                     :chooseLabel="((activeFiles.length === 0 && multiple) || !multiple) ? chooseButton : chooseLabel"
                     :customUpload="false"
                     :showUploadButton="true"
                     :value="activeFiles"
                     @upload="myUploaderAdd($event)"
                     @uploader="myUploaderAdd($event)"
                     :style="[{ 'border': '1px solid var(--gray-300)'}] ">
                <template #empty>
                    <div class="p-d-flex p-justify-center">
                        <div><i class="fas fax fa-cloud-arrow-up fa-4x" /></div>
                    </div>
                    <div class="p-d-flex p-justify-center">
                        <div><p class="mt-4 mb-0">Drag and drop files to here to upload.</p></div>
                    </div>
                </template>
            </pv-file>
            <slot name="preview" slotProps="files"  v-if="files.length > 0" style="width: 100% !important;">
                <div class="p-object-group" style="margin-bottom: 1rem; width: 100% !important;">
                    <div v-for="(file, index) in activeFiles" :key="file.file + '' + index" class="p-fileupload-files" :style="[{ 'border': '1px solid var(--gray-300)'}] ">
                        <div class="p-fileupload-row">
                            <div class="p-code-text p-jc-lg-start" style="white-space: nowrap !important; text-overflow: ellipsis !important;">
                                <i v-if="file.contentType.includes('pdf')" class="fas fa-fw fa-file-pdf"></i>
                                <i v-else-if="file.contentType.includes('image')" class="fas fa-fw fa-file-image"></i>
                                <i v-else-if="file.contentType.includes('word')" class="fas fa-fw fa-file-word"></i>
                                <i v-else-if="file.contentType.includes('spreadsheet')" class="fas fa-fw fa-file-excel"></i>
                                <i v-else class="fas fa-fw fa-file"></i>
                                {{file.filename}}
                            </div>
                            <div v-if="!disabled">
                                <pv-button class="p-button-danger p-component p-button-icon-only p-jc-end" v-if="files.length > 0" icon="fa fa-trash" iconPos="right" @click="onRemoveFile(index)" />
                            </div>
                        </div>
                    </div>
                </div>
            </slot>
        </template>
        
    </form-object>

</template>
<script>
    import config from '@/assets/lib/cosmos/_js/config.js';
    export default {
        emits: ['is-sync'],
        props: {
            id: { type: String, required: true, validator: (value) => /^[a-zA-Z0-9_]*$/.test(value) },
            type: { type: String, default: 'basic', validator: (value) => /^(basic|advanced)*$/.test((value ?? 'basic').toLowerCase()) },
            fileType: { type: String, default: '*' },
            value: { type: Array, default: null },
            modelValue: { type: Array, default: null },
            // Label Related
            label: { type: String },
            chooseLabel: { type: String, default: 'Browse' },
            altLabel: { type: String, default: 'Replace' },
            multiple: { type: Boolean, default: false },
            viewAsFile: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false },
            maxFileSizeInMB: { type: Number, default: 10 },
            //fileLimit: { type: Number, default: 10 },
            accept: { type: String, default: null },
            required: { type: Boolean, default: false },
            theme: { type: String, default: 'none' },
            height: { type: String, default: '10rem' }
        },
        data() {
            return {
                files: []
          
            }
        },
        computed: {
            chooseButton() { return this.files.length === 0 ? this.chooseLabel + '...' : this.altLabel + '...'; },
            uploadURL() { return `${config.config.endpoint.api}/file/upload/temp`; },
            downloadURL() { return `${config.config.endpoint.api}/file/download/temp`; },
            maxFileSizeInBytes() { return this.maxFileSizeInMB * 1024 * 1024; },
            background() { return this.theme.toLowerCase() === 'dark' ? '#222222' : (this.theme.toLowerCase() === 'light' ? '#EFEFEF' : this.theme.toLowerCase() === 'document' ? '#FFFFFF' : 'transparent'); },
            activeFiles() {
                return this.files;
            },
            cdn() { return `${config.config.endpoint.cdn}`; }
        },
        watch: {
            value() {
                this.files = this.value ?? [];
            },
            modelValue() {
                this.files = this.modelValue ?? [];
            }
        },
        methods: {
            myUploaderAdd(event) {
                let files = [];
                let terminate = 0;
                for (let i = 0; i < (event.files ?? []).length; i++) {
                    var formData = new FormData();
                    formData.append("image", event.files[i]);
                    this.$axios.post(this.uploadURL, formData, { headers: { 'Content-Type': "multipart/form-data" } }).then((response) => {
                        files.push(response.data);
                        this.files.push(response.data);
                    }).finally(() => {
                        terminate++;
                        if (terminate === (event.files ?? []).length) {
                            event = null;
                            this.$emit("is-sync", files);
                            this.$emit("update:modelValue", files);
                            this.$refs[this.id].files = null;
                        }
                    });
                }
            },
            myUploader(event) {
                for (let i = 0; i < (event.files ?? []).length; i++) {
                    var formData = new FormData();
                    formData.append("image", event.files[i]);
                    this.$axios.post(this.uploadURL, formData, { headers: { 'Content-Type': "multipart/form-data" } }).then((response) => {
                        this.files[i] = response.data;
                    })
                }
                event = null;
                this.$emit("is-sync", this.files);
                this.$emit("update:modelValue", this.files);
                this.$refs[this.id].files = null;
            },
            dynamicData(data) {
                let file = data;
                if (file.file)
                    return `url('data:${file.contentType};base64, ${file.file}')`;
                else
                    return `url('${this.cdn}/${file.url}?${Math.random()}')`;
            },
            onRemoveFile(index) {
                this.$emit("is-delete", this.files[index]);
                this.files.splice(index, 1);
                this.$emit("update:modelValue", this.files);
            }
        },
        created() {
            this.files = this.modelValue ?? this.value ?? [];
        }
    }
</script>
<style>
    .p-fileupload.p-component {
        width: 100% !important;
        display: inline-block;
        min-width:25rem;
    }
</style>