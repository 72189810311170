<template>
    <cosmos-grid :id="id" :searchFilter="['code', 'name']" :source="sources.carriers" sourceType="array" :paged="false" :autoGenerate="false" :deleteDisplayFields="deleteDisplay" :showAdd="false" :showDelete="false" :showExport="false" :showOperation="false" :pageSize="5">
        <pv-grid-column field="code" header="" headerStyle="width: 10rem">
            <template #body="slotProps">
                <div style="text-align: center">
                    <img :src="`/_assets/_carrier/${slotProps.data.mask}.png?${ticks}`" style="object-fit: contain; max-height: 3rem; max-width: 9rem; object-position: center; align-content: center;" onerror="this.onerror=null; this.src = '/_assets/img/no-logo.png'" />
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="code" header="Code" headerStyle="width: 15rem" />
        <pv-grid-column field="name" header="Name" />
    </cosmos-grid>
</template>
<script>
    export default {
        name: "CarrierLink",
        props: {
            id: { type: String },
            code: { type: String, default: null },
            provider: { type: String, default: null }
        },
        data() {
            return {
                sources: {
                    carriers: []
                }
            }
        },
        computed: {
            ticks() { return this.$moment.utc().format("YYYYMMDD"); }
        },
        watch: {
            provider() {
                this.getSource();
            }
        },
        methods: {
            getSource() {
                this.sources.carriers = [];
                this.$nextTick(() => {
                    if (this.provider) {
                        this.$store.dispatch("providercarrier/getURL", { url: `/services/settings/serviceprovidercarrier/byprovider/${this.provider}` })
                            .then((response) => {
                                if (response && response.success) {
                                    this.sources.carriers = response?.result ?? response ?? [];
                                }
                            });
                    }
                })
            }
        },
        created() { this.getSource(); }
    }
</script>
<style>
    .p-indicator {
        padding: 5px 10px;
        color: var(--surface-b) !important;
        width: max-content !important;
        font-size: 0.8rem;
        text-transform: uppercase;
        border-radius: 4px;
        font-weight: bold !important;
        border: 1px solid var(--surface-d);
        margin-left: 5px;
    }

    .p-indicator.definite-100 { background: var(--bg-warning) !important; color: #212529 !important; }
    .p-indicator.definite-200 { background: var(--bg-help) !important; }
    .p-indicator.definite-DOCUMENT { background: var(--bg-danger) !important; }
    .p-indicator.definite-NON-DOCUMENT { background: var(--bg-info) !important; }
    .p-indicator.definite-NON-DOCUMENT { background: var(--bg-info) !important; }
    .p-indicator.definite-NON-DOCUMENT { background: var(--bg-info) !important; }
    .p-text-indicator {
        width: max-content !important;
        text-transform: uppercase;
        font-weight: bold !important;
        margin-left: 5px;
    }
    .p-text-indicator.definite-DOOR-TO-DOOR {
        color: var(--text-success);
    }
        .p-text-indicator.definite-OPTIONS {
            color: var(--text-info);
        }
        .p-text-indicator.definite-ACCESS {
            color: var(--text-danger);
        }
            .p-text-indicator.definite-ACCESS+.p-text-indicator.definite-DOOR-TO-DOOR {
                visibility: hidden;
            }
    .p-multiselect.p-component.p-inputwrapper.p-fluid.p-multiselect-custom {
        min-height: 40px;
    }
</style>