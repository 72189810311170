<template>
    <div class="card">
        <div class="p-grid">
            <div class="p-col-12 p-md-3">
                <h6>Initial State</h6>
                <form-address id="Collection" :value="model.address" :useCompany="true"/>
            </div>
            <div class="p-col-12 p-md-3">
                <h6>Initial Disabled State</h6>
                <h6>PER FIELD</h6>
                <form-address id="Collection" :value="model.address" :useCompany="true" :disabled="true" />
                <h6>PER LINE</h6>
                <form-address id="Collection" :value="model.address" :useCompany="true" :disabled="true" disabledView="perline" />
                <h6>SINGLE LINE</h6>
                <form-address id="Collection" :value="model.address" :useCompany="true" :disabled="true" disabledView="singleline" />
            </div>
            <div class="p-col-12 p-md-3">
                <h6>Transaction State</h6>
                <form-contact id="Collection" :value="model.contact" :useRegistrationNumber="true" />
            </div>
            <div class="p-col-12 p-md-3">
                <h6>Disabled State</h6>
                <form-contact :disabled="true" id="Collection" :value="model.contact" :useRegistrationNumber="true" />
                <form-contact :disabled="true" id="Collection" :value="model.contact" :useRegistrationNumber="true" disabledView="perline"/>
                <form-contact :disabled="true" id="Collection" :value="model.contact" :useRegistrationNumber="true" disabledView="singleline" />
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'FormCustomComponents',
        data() {
            return {
                model: {
                    address: {
                        companyName: "Underground Developer",
                        address1: "Blk 29 Lot 21 Dumaguete St.,",
                        address2: "South City Homes Subdivision",
                        address3: "Brgy. Sto. Tomas (CALABUSO)",
                        city: "Binan City",
                        state: "Laguna",
                        postalCode: "4024",
                        country: null,
                        countryId: 176,
                        establishmentType: 100
                    },
                    contact: {
                        firstName: "Shinji",
                        lastName: "Ikari",
                        email: "shinji.ikari@integro360.com",
                        mobile: "+639998811651",
                        telephone: "+63495452338",
                        eori: "GB1234567890123",
                        ioss: "IOSS123456789087654",
                        vat: "217-639-221-0000",
                        idNumber: "1234579779879",
                        idType: 300
                    }
                }
            }
        }
    }
</script>

