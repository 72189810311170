<template>
    <form-wrapper :title="router.title" :sub-title="router.subtitle">
        <printjob-grid></printjob-grid>
    </form-wrapper>
</template>
<script>
    import printjobgrid from "@/components/facility/printjob/printjob-grid.vue";
    import mixins from '@/assets/lib/cosmos/_js/mixin.js';
    export default {
        name: "PrintJobIndex",
        mixins: [mixins.GRID],
        components: {
            'printjob-grid': printjobgrid
        }
    }
</script>