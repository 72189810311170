<template>
    <form-object :id="id" :autoFit="autoFit" :label="label" :floatLabel="floatLabel && (!disabled || objBlank)" :hideLabel="hideLabel" :v$="v$" :required="required" :conditional="conditional">
        <template #default="slotProps">
            <pv-calendar v-if="!disabled"
                         :ref="id"
                         :id="id"
                         :name="id"
                         :class="[{'p-fluid': autoFit}, {'p-invalid': slotProps.data}]"
                         :touchUI="touchDevice || isMobile"
                         :showIcon="showIcon && !disabled"
                         :icon="objType === 'time' ? 'pi pi-fw pi-clock' : 'pi pi-fw pi-calendar'"
                         :style="[{ 'border-radius': 0 }, { 'border-right': (postLabel || postIcon) ? ((postLabel || postIcon) ? '0' : '1px solid inherit') : false }, { 'border-top-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-bottom-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-top-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'border-bottom-right-radius': ((postLabel || postIcon) ? '0' : '4px')}]"
                         :showButtonBar="showButton"
                         :dateFormat="'dd/M/yy'"
                         :hourFormat="`${timeFormat}`"
                         :minDate="min"
                         :maxDate="max"
                         :monthNavigator="true"
                         :selectOtherMonths="true"
                         :yearNavigator="true"
                         :yearRange="yearRange"
                         :showTime="showTime"
                         :timeOnly="timeOnly"
                         :selectionMode="mode"
                         :maxDateCount="maxCount"
                         :manualInput="allowInput"
                         :showSeconds="showSeconds"
                         :stepMinute="stepMinute"
                         :hideOnDateTimeSelect="true"
                         :placeholder="phText"
                         :disabled="disabled"
                         :disabledDates="disabledDates"
                         @date-select="selected"
                         @today-click="selected"
                         @clear-click="selected"
                         @hide="selected"
                         v-model="model.value" />
            <div v-else :title="`${(model?.value ? model.value : '')}`" class="p-component p-inputtext p-input-icon-right" :style="[{ 'border-radius': 0 }, { 'border-right': (postLabel || postIcon) ? ((postLabel || postIcon) ? '0' : '1px solid inherit') : false }, { 'border-top-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-bottom-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-top-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'border-bottom-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'overflow': 'hidden' }, { 'text-overflow': 'ellipsis' }, { 'background': 'var(--surface-c)' }, { 'cursor': 'not-allowed' }, { 'white-space': 'nowrap' }]">
                <span class="p-code-text" style="font-size: 0.85rem; font-weight: 300; white-space: nowrap; text-wrap: normal; overflow: hidden; text-overflow: ellipsis;" v-html="`${model?.text ?? '&nbsp;'}`"></span>
                <i v-if="showCopy" class="pi pi-copy" style="cursor: pointer;" @click="onCopy"></i>
            </div>
        </template>
        <template #leftSide="slotProps">
            <slot name="prepend" data="slotProps">
                <span v-if="preIcon || preLabel" :class="[{'is-prepend': true}, {'p-inputgroup-addon': true}, {'p-invalid': slotProps.data}]" :style="[{'background-color': 'var(--surface-f)'}, {'font-weight': 500 }] ">
                    <i v-if="preIcon" :class="preIcon"></i>
                    <span v-if="preLabel" :class="[{'is-label': true}, {'required': model.state.required}, {'optional': model.state.optional}]" :style="preCSS" :title="preLabel ?? label ?? $filters.titleize(id)" v-html="preLabel"></span>
                </span>
            </slot>
        </template>
        <template #rightSide>
            <slot name="append">
                <span v-if="postIcon || postLabel" class="is-append p-inputgroup-addon" :style="[{'background-color': 'var(--surface-f)'}, {'font-weight': 500 }]">
                    <i v-if="postIcon" :class="postIcon"></i>
                    <span v-if="postLabel" class="is-label" :style="postCSS" :title="postLabel" v-html="postLabel"></span>
                </span>
            </slot>
        </template>
    </form-object>
</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/core-mixins.js';
    import moment from 'moment';
    export default {
        name: "FormDateTimeField",
        mixins: [mixins.COMPONENT],
        emits: ["is-reset", "is-send", "update", "update:modelValue", "update:modelUTC", "update:modelLocal"],
        props: {
            type: { type: String, default: "datetime", validator: (value) => ['date', 'datetime', 'time'].indexOf(value.toLowerCase()) !== -1 },
            modelValue: { type: [String, Date, Array, Object] },
            modelUTC: { type: Date },
            modelLocal: { type: Date },
            value: { type: [String, Date, Array, Object] },
            min: { type: Date, default: new Date("2018-01-01") },
            max: { type: Date, default: new Date((new Date().getFullYear() + (new Date().getMonth() === 11 ? 1 : 0)) + "-12-31T23:59:59") },
            showIcon: { type: Boolean, default: true },
            showButton: { type: Boolean, default: true },
            showCopy: { type: Boolean, default: true },
            allowInput: { type: Boolean, default: false },
            maxCount: { type: Number, default: 1 },
            mode: { type: String, default: "single", validator: (value) => ['single', 'multiple', 'range'].indexOf(value.toLowerCase()) !== -1 },
            stepMinute: { type: Number, default: 15 },
            showMonths: { type: Number, default: 1, validator: (value) => Number(value) > 0 && Number(value) <= 12 },
            showSeconds: { type: Boolean, default: false },
            timeFormat: { type: Number, default: 12, validator: (value) => Number(value) === 12 || Number(value) === 24 },
            disabledDates: {type: Array, default: null}
        },
        computed: {
            yearRange() { return this.min.getFullYear() + ':' + this.max.getFullYear(); },
            showTime() { return this.objType === "time" || this.objType === "datetime" },
            timeOnly() { return this.objType === "time" },
            dateValue() { return new Date(this.model.value); }
        },
        watch: {
            "model.temp": {
                handler() {
                    this.model.value = this.$filters.formatDate(this.model.temp);
                },
                deep: true
            },
            "value": {
                handler() {
                    let objValue = this.modelValue ?? this.value;
                    if (objValue)
                        this.model.value = this.objType === 'date' ? this.toLocal(new Date(moment.utc(objValue).format("YYYY-MM-DDT00:00:00Z"))) : this.toUTC(this.toLocal(new Date(moment.utc(objValue).format("2018-01-01THH:mm:ss"))));
                    this.onSync();
                },
                deep: true
            },
            "modelValue": {
                handler() {
                    let objValue = this.modelValue ?? this.value;
                    if (objValue)
                        this.model.value = this.objType === 'date' ? this.toLocal(new Date(moment.utc(objValue).format("YYYY-MM-DDT00:00:00Z"))) : this.toUTC(this.toLocal(new Date(moment.utc(objValue).format("2018-01-01THH:mm:ss"))));
                    this.onSync();
                },
                deep: true
            }
        },
        methods: {
            selected() {
                this.onSync();
            },
            onSync() {
                let date = this.model.value;
                var objValue = this.model.value;
                if (date) {
                    switch (this.objType) {
                        case "date":
                            objValue = moment(this.toLocal(this.toUTC(date))).format('YYYY-MM-DDT00:00:00') + "Z";
                            break;
                        case "time":
                            objValue = moment(this.toLocal(this.toUTC(date))).format('2018-01-01THH:mm:ss') + "Z";
                            break;
                        default:
                            objValue = moment(this.toLocal(this.toUTC(date))).format('YYYY-MM-DDTHH:mm:ss') + "Z";
                            break;
                    }
                }
                this.$nextTick(() => this.$emit('update:modelValue', objValue));
                var data = { value: objValue, text: null, record: { UTC: null, Local: null } };
                if (objValue) {
                    switch (this.objType) {
                        case "date":
                            data = { value: objValue, text: this.$filters.$shortDateUTC(date), record: { utc: objValue, local: this.toUTC(date) } };
                            break;
                        case "time":
                            data = { value: objValue, text: this.$filters.$shortTime24HUTC(this.toLocal(date)), record: { utc: objValue, local: this.toLocal(date) } };
                            break;
                        default:
                            data = { value: objValue, text: this.$filters.$dateTime24HUTC(this.toLocal(date)), record: { utc: objValue, local: this.toLocal(this.toLocal(date)) } };
                            break;
                    }
                }
                this.$emit("is-sync", data);
            },
            toUTC(date) {
                if (date) {
                    const tzoffset = date.getTimezoneOffset() * 60000;
                    const withoutTimezone = new Date(date.valueOf() + tzoffset)
                        .toISOString()
                        .slice(0, -1);
                    return moment.utc(withoutTimezone + "Z").toDate();
                }
                return null;
            }, 
            toLocal(date) {
                if (date) {
                    const tzoffset = date.getTimezoneOffset() * 60000;
                    const withoutTimezone = new Date(date.valueOf() - tzoffset)
                        .toISOString()
                        .slice(0, -1);
                    return moment.utc(withoutTimezone + "Z").toDate();
                }
                return null;
            }
        },
        async created() {
            let objValue = this.modelValue ?? this.value;
            if (objValue) {
                switch (this.objType) {
                    case "date":
                        this.model.value = this.toLocal(new Date(moment.utc(objValue).format("YYYY-MM-DDT00:00:00Z")));
                        break;
                    case "time":
                        this.model.value = this.toUTC(this.toLocal(new Date(moment.utc(objValue).format("2018-01-01THH:mm:ss"))));
                        break;
                    default:
                        var data = moment(this.toLocal(new Date(moment.utc(objValue).format("YYYY-MM-DD")))).format("YYYY-MM-DD") + "T" + moment(this.toUTC(this.toLocal(new Date(moment.utc(objValue).format("2018-01-01THH:mm:ss"))))).format("HH:mm:ss") + "Z";
                        this.model.value = this.toUTC(this.toLocal(new Date(moment.utc(data).format("YYYY-MM-DDTHH:mm:ss"))));
                        break;
                }
            }
            this.onSync();
        }
    }
</script>