<template>
    <div class="p-col-12" id="divtbc">
        <pv-orderlist v-if="viewOnly" v-model="filteredTarget" listStyle="height:342px" dataKey="id" @move-to-target="moveToTarget()" @move-all-to-target="moveAllToTarget" @move-to-source="moveToSource()" @move-all-to-source="moveAllToSource" v-model:selection="selection">
            <template #header>
                <div class="p-d-flex" style="width: 100%;">
                    <div class="p-col-8" style="padding-top: 10px;">
                        
                    </div>
                    <div class="p-col-4" style=" margin: 0; padding: 0;">
                        <pv-input style="margin: 0 5px; width: 100%;" v-model="filter.target" placeholder="Type your keyword..." />
                    </div>
                </div>
            </template>
            <template #item="slotProps">
                <div class="picklist-item">
                    <div class="picklist-list-detail" style="margin: 0; padding: 0;">
                        <div v-if="activity.loading" class="p-field p-col-12 p-md-12" style="width: 100%; padding: 0; margin: 0;">
                            <div class="custom-skeleton">
                                <ul class="p-m-0 p-p-0">
                                    <li>
                                        <div class="p-d-flex">
                                            <div style="flex: 1">
                                                <pv-skeleton width="100%" class="p-mb-2"></pv-skeleton>
                                                <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-else class="p-d-flex" style="width: 100%; padding: 0;">
                            <div style="width: 100%; padding: 0; margin: 0;">
                                <div class="p-d-flex p-jc-between" style="width: 100%; z-index: 100;">
                                    <div><h5 style="margin-bottom: 5px; margin-top: 0; font-size: 1.2rem;"> <span :class="`flag flag-${slotProps.item.code.toLowerCase()}`" style="width: 30px; height: 20px;"></span> <span> {{slotProps.item.altName ?? slotProps.item.name  }} </span></h5></div>
                                    <div class="p-as-end"><h5 class="p-code-text">[{{ slotProps.item.iso2}}]</h5></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </pv-orderlist>
        <pv-picklist v-if="!viewOnly" v-model="items" listStyle="height:342px" dataKey="id" @move-to-target="moveToTarget" @move-all-to-target="moveAllToTarget" @move-to-source="moveToSource" @move-all-to-source="moveAllToSource">
            <template #sourceHeader>
                <div class="p-d-flex" style="width: 100%;">
                    <div class="p-col-2" style="padding-top: 10px;">
                        Available
                    </div>
                    <div class="p-col-10" style=" margin: 0; padding: 0;">
                        <pv-input style="margin: 0 10px; width: 100%;" v-model="filter.source" placeholder="Type your keyword..." />
                    </div>
                </div>
            </template>
            <template #targetHeader>
                <div class="p-d-flex" style="width: 100%;">
                    <div class="p-col-2" style="padding-top: 10px;">
                        Selected
                    </div>
                    <div class="p-col-10" style=" margin: 0; padding: 0;">
                        <pv-input style="margin: 0 10px; width: 100%;" v-model="filter.target" placeholder="Type your keyword..." />
                    </div>
                </div>
            </template>
            <template #item="slotProps">
                <div class="picklist-item">
                    <div class="picklist-list-detail" style="margin: 0; padding: 0;">
                        <div v-if="activity.loading" class="p-field p-col-12 p-md-12" style="width: 100%; padding: 0; margin: 0;">
                            <div class="custom-skeleton">
                                <ul class="p-m-0 p-p-0">
                                    <li>
                                        <div class="p-d-flex">
                                            <div style="flex: 1">
                                                <pv-skeleton width="100%" class="p-mb-2"></pv-skeleton>
                                                <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-else class="p-d-flex" style="width: 100%; padding: 0;">
                            <div style="width: 100%; padding: 0; margin: 0;">
                                <div class="p-d-flex p-jc-between" style="width: 100%; z-index: 100;">
                                    <div><h5 style="margin-bottom: 5px; margin-top: 0; font-size: 1.2rem;"> <span :class="`flag flag-${slotProps.item.code.toLowerCase()}`" style="width: 30px; height: 20px;"></span> <span> {{slotProps.item.altName ?? slotProps.item.name  }} </span></h5></div>
                                    <div class="p-as-end"><h5 class="p-code-text">[{{ slotProps.item.iso2}}]</h5></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </pv-picklist>
    </div>
</template>
<script>
    const config = require('@/assets/lib/cosmos/_js/config.js').default.config;
    export default {
        name: 'TradeBlocCountryTemplateForm',
        emits: ["is-sync"],
        props: {
            parentID: { type: Number, default: -1 },
            formData: { type: Object },
            submoduleName: { type: String, default: "ruletradebloccountry"}
        },
        data() {
            return {
                model: [],
                available: [],
                assigned: [],
                activity: {
                    loading: true
                },
                filter: {
                    source: null,
                    target: null
                },
            }
        },
        computed: {
            viewOnly() {
                return this.$props?.formData?.id > 0 && this.$props?.formData?.tenantID == null && this.$props?.formData?.environmentID == null;
            },
            countryDS() { return this.$store.getters["country/ds"]; },
            companyDS() { return this.$store.getters["company/ds"]; },
            warehouseDS() { return this.companyDS.data.filter((x) => x.type === 8); },
            source() {
                return this.countryDS?.data;
            },
            filteredSource() {
                if (this.filter.source && this.filter.source.trim() !== "" && this.filter.source.trim().length >= 2) {
                    return this.available.filter(x => x.code?.toLowerCase().includes(this.filter.source.toLowerCase()) || x.name?.toLowerCase().includes(this.filter.source.toLowerCase()));
                }
                return this.available;
            },
            filteredTarget() {
                if (this.filter.target && this.filter.target.trim() !== "" && this.filter.target.trim().length >= 2) {
                    return this.assigned.filter(x => x.code?.toLowerCase().includes(this.filter.target.toLowerCase()) || x.name?.toLowerCase().includes(this.filter.target.toLowerCase()));
                }
                return this.assigned;
            },
            items() {
                return [this.filteredSource, this.filteredTarget];
            },
            record() {
                return this.$store.getters[this.$props.submoduleName + "/record"]
            }
        },
        watch: {
            'model': {
                handler() {
                    this.$emit("is-sync", { model: this.model });
                }, deep: true
            },
            'formData': {
                handler() {
                    if (this.$props?.parentID > 0 && this.available.length === 0 && this.assigned.length === 0) {
                        this.getAssigned(this.$props?.parentID ?? 0).then(() => {
                            this.activity.loading = false;
                        });
                    }
                    //else if (this.available.length === 0 && this.assigned.length === 0) {
                    //    this.available = this.source;
                    //}
                }, deep: true
            },
        },
        methods: {
            async getCountry() {
                return this.$store.dispatch("country/getAll", {sort : null, where: null});
            },
            async postProduct(_model) {
                let _self = this;
                await this.$store.dispatch(_self.$props?.submoduleName + "/addRecord", _model).then(() => {
                    let index = _self.model.findIndex(f => f.countryID === _model.countryID);
                    if (index !== -1)
                        _self.model[index] = _self.record;
                })
                .catch((ex) => {
                    this.errors = ex.data;
                });
            },
            async deleteProduct(_model) {
                let _self = this;
                await this.$store.dispatch(this.$props?.submoduleName + "/removeRecord", _model.mask).then(() => {
                    let index = _self.model.findIndex(f => f.countryID === _model.countryID);
                    if (index !== -1) {
                        _self.model.splice(index, 1);
                    }
                    })
                    .catch((ex) => {
                        _self.errors = ex.data;
                    });
            },
            async moveToTarget(e) {
                e.items.forEach(x => {
                    this.assigned.push(x);
                    let index = this.available.findIndex(f => f.id === x.id);
                    if (index !== -1) {
                        this.available.splice(index, 1);
                    }
                    this.populateModel(x,true);
                });
            },
            async moveAllToTarget(e) {
                e.items.forEach(x => {
                    this.assigned.push(x);
                    let index = this.available.findIndex(f => f.id === x.id);
                    if (index !== -1) {
                        this.available.splice(index, 1);
                    }
                    this.populateModel(x, true);
                });
               
            },
            async moveToSource(e) {
                e.items.forEach(x => {
                    this.available.push(x);
                    let index = this.assigned.findIndex(f => f.id === x.id);
                    if (index !== -1) {
                        this.assigned.splice(index, 1);
                    }
                    this.populateModel(x, false);
                    
                });
            },
            async moveAllToSource(e) {
                e.items.forEach(x => {
                    this.available.push(x);
                    let index = this.assigned.findIndex(f => f.id === x.id);
                    if (index !== -1) {
                        this.assigned.splice(index, 1);
                    }
                    this.populateModel(x, false);
                });
            },
            async reorder(e) {
                console.log(e)
            },
            async getAssigned() {
                await this.$axios
                    .get(config.endpoint.api + `/services/facility/` + this.$props?.submoduleName + `/tradebloc/id/${this.$props?.parentID ?? 0}`.replaceAll("//", "/"))
                    .then((response) => {
                        if (this.model.length === 0) {
                            this.model = response.data.result ?? [];
                            let ids = []
                            ids = this.model.map(x => x.countryID);
                            this.available = this.source.filter(x => ids.indexOf(x.id) === -1);
                            this.assigned = this.source.filter(x => ids.indexOf(x.id) !== -1);
                        }
                        
                    })
                    .catch((ex) => {
                        console.log(ex);
                        this.available = this.source;
                        this.errors = ex.data;
                    });
            },
            async populateModel(x, toTarget) {
                let index = this.model.findIndex(f => f.countryID === x.id);
                if (toTarget) {
                    if (index === -1) {
                        var tempCode = "TC" + Math.random().toString().replace(".", "");
                        this.model.push({
                            name: tempCode.substring(0,20),
                            code: tempCode.substring(0, 20),
                            ruleTradeBlocID: this.$props?.parentID > 0 ? this.$props?.parentID : 0,
                            countryID: x.id,
                            tenantId: null,
                            environmentId: null,
                            id: 0,
                            id2: null,
                            mask: null
                        });
                    }
                    if (this.$props?.parentID > 0) {
                        this.postProduct(this.model.find(f => f.countryID === x.id));
                    }
                }
                else {
                    if (this.$props?.parentID > 0) {
                        this.deleteProduct(this.model.find(f => f.countryID === x.id));
                    }
                    else {
                        if (index !== -1)
                            this.model.splice(index,1);
                    }
                }
            }
        },
        async mounted() {
            await this.getCountry().then(() => {
                if (this.$props?.parentID <= 0 &&this.available.length === 0 && this.assigned.length === 0) {
                        this.available = this.source;
                }
                this.activity.loading = false;
            });
        }

    }
</script>
<style scoped>
    .p-picklist-header {
        padding: 0 10px !important;
    }

    .p-orderlist-controls,
    #divtbc > .p-picklist-buttons.p-picklist-source-controls,
    #divtbc > .p-picklist-buttons.p-picklist-target-controls {
        display: none !important;
    }
    .custom-skeleton {
        border: 1px solid var(--surface-d);
        border-radius: 4px;
    }

    .custom-skeleton ul {
        list-style: none;
    }

    .custom-skeleton ul > li {
        padding: 0.75rem;
    }
</style>