<template>
    <div>
        <pv-listbox v-if="disabled" :disabled="disabled" :options="model" listStyle="max-height:450px" optionLabel="displayName">
            <template #option="slotProps">
                <div class="p-d-flex p-jc-between" style="width: 100%; margin: 0;margin-right: 1rem !important;">
                    <div class="flex-1 flex flex-column gap-2">
                        <span style="opacity: 0.7;font-weight: 700;">{{ fields.filter(x => x.id === slotProps.option.baseFieldID)[0]?.displayName }} - </span><span style="font-weight: normal; font-style: italic; color: var(--text-primary);">{{ slotProps.option.displayName }}</span>
                    </div>
                    <div class="font-bold text-code" style="text-transform: uppercase; ">
                        <span :style="`font-size: 0.7rem; opacity: 0.5; margin-right: 1.25rem;`">{{ fields.filter(x => x.id === slotProps.option.baseFieldID)[0]?.category }}</span>
                        <i v-if="model[model.findIndex(x => x.baseFieldID == slotProps.option.baseFieldID)]" :class="`${getIcon(model[model.findIndex(x => x.baseFieldID == slotProps.option.baseFieldID)].sortDirection)} is-sort-indicator`" style="font-size: 1.5rem !important; position:absolute; right: 0.25rem; top: 0.5rem !important; z-index: 99999;" v-tooltip.bottom="getTooltip(model[model.findIndex(x => x.baseFieldID == slotProps.option.baseFieldID)].sortDirection)"></i>
                    </div>
                </div>
            </template>
        </pv-listbox>
        <pv-grid v-else
                 :value="model" autoLayout
                 showGridlines
                 stripedRows
                 class="is-customized"
                 :reorderableColumns="false"
                 scrollable
                 scrollHeight="420px"
                 @columnReorder="onColReorder"
                 @rowReorder="onRowReorder"
                 tableStyle="min-width: 50rem;"
                 style="min-height: 425px; border: 1px solid var(--surface-d)">
            <template #header v-if="!disabled && parentID > 0">
                <div class="flex justify-content-between">
                    <pv-button type="button" icon="fa fa-bars" label="Manage Fields" outlined @click="dialog.add = true" />
                </div>
            </template>
            <pv-grid-column rowReorder headerStyle="width: 3rem" style="width: 3rem;" :reorderableColumn="false" />
            <pv-grid-column header="Selected Field" style="width: 100%;">
                <template #body="slotProps">
                    <div style="width: 100%; position: relative; padding: 0;">
                        <form-input v-if="model[model.findIndex(x => x.baseFieldID == slotProps.data.baseFieldID)]" class="is-customized" :id="`field${slotProps.data.baseFieldID}`" v-model="model[model.findIndex(x => x.baseFieldID == slotProps.data.baseFieldID)].displayName" :pre-label="getFieldName(slotProps.data.baseFieldID)" :ph="getFieldName(slotProps.data.baseFieldID)" :hideLabel="true" :floatLabel="false" :maxlength="255" />
                        <i v-if="model[model.findIndex(x => x.baseFieldID == slotProps.data.baseFieldID)]" :class="`${getIcon(model[model.findIndex(x => x.baseFieldID == slotProps.data.baseFieldID)].sortDirection)} is-sort-indicator`" style="font-size: 1.5rem !important; position:absolute; right: 1rem; top: 0 !important; z-index: 99999;" @click="onSortDirectionUpdate(model.findIndex(x => x.baseFieldID == slotProps.data.baseFieldID))" v-tooltip.bottom="getTooltip(model[model.findIndex(x => x.baseFieldID == slotProps.data.baseFieldID)].sortDirection)"></i>
                    </div>
                </template>
            </pv-grid-column>
            <template #empty>
                <slot name="empty">
                    No selected field found.
                </slot>
            </template>
            <template #loading>
                <slot name="loading">
                    Retrieving records from database. Please wait...
                </slot>
            </template>
        </pv-grid>
        <is-dialog header="Manage Report Fields" :breakpoints="{'2500px': '85vw', '960px': '75vw', '640px': '100vw'}" :visible="dialog.add" @is-confirm="onConfirm(activeData)" @is-cancel="onCancel()" :confirmDisabled="confirmDisabled">
            <field-form v-model="activeData" :parentID="parentID" :disabled="disabled" />
        </is-dialog>
    </div>
</template>

<script>
    import fieldForm from '@/components/facility/report/field-form.vue';
    export default {
        name: "DynamicReportFieldCustomizationForm",
        emits: ["update", "update:modelValue", "isSync"],
        components: {
            'field-form': fieldForm
        },
        props: {
            parentID: { type: Number, default: 0 },
            modelValue: { type: Array, default: () => [] },
            disabled: { type: Boolean, default: false }
        },
        data() {
            return {
                model: [],
                activeData: [],
                sources: {
                    base: []
                },
                dialog: {
                    add: false
                }
            }
        },
        computed: {
            fields() { return this.$vm.$columns; },
        },
        watch: {
            modelValue() {
                this.model = this.modelValue ?? [];
                this.onSync();
            }
        },
        methods: {
            onSync() {
                (this.model ?? []).forEach((value, index) => {
                    let baseIndex = this.sources.base.map(x => x.id).indexOf(value.baseFieldID);
                    if (baseIndex > -1) {
                        this.sources.base[baseIndex].sortOrder = index + 1;
                        this.sources.base[baseIndex].listIndex = 1;
                    }
                });
                this.activeData = [...this.model];
                this.$emit("update:modelValue", this.model);
            },
            onRowReorder(event) {
                event.value.forEach((value, index) => {
                    value.sortOrder = index + 1;
                })
                this.model = event.value;
                this.onSync();
            },
            onSortDirectionUpdate(data) {
                let model = this.$filters.init(this.model[data]);
                let sortDirection = (model.sortDirection ?? 0);
                model.sortDirection = (++sortDirection % 3);
                this.model[data] = model;
                this.onSync();
            },
            getIcon(data) {
                switch (data) {
                    case 1: return 'far fa-sort-alpha-asc';
                    case 2: return 'far fa-sort-alpha-desc';
                    default: return 'fas fa-sort';
                }
            },
            getTooltip(data) {
                switch (data) {
                    case 1: return 'Sort Ascending';
                    case 2: return 'Sort Descending';
                    default: return 'Unsort';
                }
            },
            getFieldName(baseFieldID) {
                let field = this.fields.filter(x => x.id == baseFieldID)[0];
                return field.displayName;
            },
            onCancel() {
                this.onSync();
                this.dialog.add = false;
            },
            onConfirm() {
                this.model = [...this.activeData];
                this.$nextTick(() => this.onSync());
                this.dialog.add = false;
            }
        },
        mounted() {
            if (this.modelValue) {
                this.model = this.modelValue ?? [];
                this.activeData = [...this.model];
            }
        }
    }
</script>
<style>
    .is-customized .p-inputtext { padding: 0.25rem 0.75rem !important; max-height: 2.4rem !important; min-height: 2.4rem !important; }
    .is-customized.p-field { margin-bottom: -1rem !important; margin-top: -1rem !important; }
    .is-container.is-customized .is-prepend.p-inputgroup-addon { background-color: var(--surface-d) !important; min-width: 300px !important; max-width: 300px !important; max-height: 2.4rem !important; min-height: 2.4rem !important; }
    .is-sort-indicator { opacity: 0.5; transition: all 0.5s ease-out; }
    .is-sort-indicator:hover { opacity: 1; transition: all 0.5s ease-in; }
</style>