<template>
    <!--<is-loader v-if="activity.parentLoading"></is-loader>-->
    <div class="content-section header">
        <div class="feature-intro">
            <slot name="header">
                <h1><span>SEARCH CONSIGNMENT</span></h1>
            </slot>
        </div>
    </div>
    <div class="content-section body">
        <div class="card">
            <div class="p-grid p-nogutter">
                <div class="p-col-12 p-lg-6 p-nogutter">
                    <div class="p-grid p-nogutter">
                        <div class="p-col-12 p-md-6">
                            <form-input id="searchText" :value="model.searchText" @is-sync="model.searchText = $event.value" :required="true" Label="Search Text" placeholder="Customer/Carrier/Invoice/Collection Reference" @keyup="onKeyUp"></form-input>
                        </div>
                        <div class="p-col-12 p-md-6">
                            <form-boolean id="includeArchived" :value="model.includeArchived" type="toggle" label="Include Archived?" textPosition="left" @is-sync="model.includeArchived = $event.value"></form-boolean>
                        </div>
                    </div>

                    <div class="p-grid p-nogutter">
                        <div class="p-col-12 p-md-6">
                            <form-lookup id="customerID" label="Customer" :source="customers" :value="isCustomer ? customers[0]?.id : model.customerID " :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onCustomerChange($event)" :disabled="isCustomer" disabledType="object" />
                        </div>
                        <div class="p-col-12 p-md-6">
                            <form-lookup id="warehouseID" label="Warehouse" :source="warehouses" :value="model.warehouseID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onWarehouseChange($event)" />
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-lg-6 p-nogutter">
                    <div class="p-grid p-nogutter">
                        <div class="p-col-12 p-md-6">
                            <form-datetime id="createDateFrom" :required="true" :showButton="false" :value="model.createDateFromValue" type="date" @is-sync="onCreateDateFrom($event)" label="Create Date From" :v$="v$"></form-datetime>
                        </div>
                        <div class="p-col-12 p-md-6">
                            <form-datetime id="createDateTo" :required="true" :showButton="false" :value="model.createDateToValue" type="date" @is-sync="onCreateDateTo($event)" label="Create Date To" :v$="v$"></form-datetime>
                        </div>
                    </div>
                    <div class="p-grid p-nogutter">
                        <div class="p-col-12 p-md-6">
                            <form-datetime id="allocateDateFrom" :value="model.allocateDateFromValue" type="date" @is-sync="onAllocateDateFrom($event)" label="Allocation Date From" :v$="v$"></form-datetime>
                        </div>
                        <div class="p-col-12 p-md-6">
                            <form-datetime id="allocateDateTo" :value="model.allocateDateToValue" type="date" @is-sync="onAllocateDateTo($event)" label="Allocation Date To" :v$="v$"></form-datetime>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="p-d-flex p-jc-end">
                <pv-button :class="[{ 'p-mr-2': true}]" label="Apply Search Filter" :disabled="(model.searchText ?? '').trim().length < 1 || activity.export || activity.binding" icon="pi pi-filter" @click="onFilter()"></pv-button>
            </div>
            <div class="p-grid" style="margin-top: 1rem;">
                <div class="p-col-12">
                    <pv-tab style="margin: 0 -14px !important;">
                        <pv-tabpanel header="RESULT">
                            <report-grid :formData="model" :filterKey="filterKey" :isAdmin="isAdmin" @is-sync="onUpdate($event)"></report-grid>
                        </pv-tabpanel>
                    </pv-tab>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    const moduleName = 'report';
    import reportGrid from '@/views/facility/search/search-grid.vue';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'report-grid': reportGrid
        },
        data() {
            return {
                model: {
                    searchText: null,
                    includeArchived: false,
                    warehouse: null,
                    warehouseID: null,
                    customer: null,
                    customerID: null,
                    createDateFrom: null,
                    createDateFromValue: null,
                    createDateTo: null,
                    createDateToValue: null,
                    allocateDateFrom: null,
                    allocateDateFromValue: null,
                    allocateDateTo: null,
                    allocateDateToValue: null,
                },
                reportType: 200,
                activity: {
                    parentLoading: true,
                    loading: false,
                    export: false,
                    binding: false,
                    dirty: true
                },
                keys: null,
                session: {
                    tenant: null,
                    environment: null,
                    warehouse: null,
                    customer: null,
                    currentUser: null,
                    executiveUser: null
                },
                reportScopes: null,
                sources: {
                    accounts: []
                },
                filterKey: null
            }
        },
        validations() {
            return {
                model: {
                    searchText: { $autoDirty: true },
                    includeArchived: { $autoDirty: true },
                    warehouseID: { $autoDirty: true },
                    customerID: { $autoDirty: true },
                    createDateFrom: { $autoDirty: true },
                    createDateFromValue: { $autoDirty: true },
                    createDateTo: { $autoDirty: true },
                    createDateToValue: { $autoDirty: true },
                    allocateDateFrom: { $autoDirty: true },
                    allocateFromValue: { $autoDirty: true },
                    allocateDateTo: { $autoDirty: true },
                    allocateToValue: { $autoDirty: true }
                }
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            apiData() {
                return {
                    ServiceHeader: {
                        Username: "TEMP",
                        Password: "TESTESTEDET",
                        ClientID: "TESTTESTTESTTESTTEST",
                        ClientSecret: "TESTEDREDREDTETEDEDETEREDEDEREDE"
                    },
                    Request: {
                        DC: this.model?.warehouseID > 0 ? this.warehouses.filter(x => x.id === this.model.warehouseID)[0]?.code : null,
                        Customer: this.model?.customerID > 0 ? this.customers.filter(x => x.id === this.model.customerID)[0]?.code : null,
                        ScopeDetail: {
                            IncludeArchive: this.model?.includeArchived,
                            ExportType: this?.reportType === 100 ? "AR" : "CR",
                            CreateDate: {
                                From: this.$filters.formatDateWithFormat(this.model.collectionDateFrom ?? new Date(), "YYYY-MM-DD"),
                                To: this.$filters.formatDateWithFormat(this.model.collectionDateTo ?? new Date(), "YYYY-MM-DD")
                            },
                            AllocationDate: {
                                From: this.model.manifestDateFrom !== undefined && this.model.manifestDateFrom !== null ? this.$filters.formatDateWithFormat(this.model.manifestDateFrom ?? new Date(), "YYYY-MM-DD") : null,
                                To: this.model.manifestDateTo !== undefined && this.model.manifestDateTo !== null ? this.$filters.formatDateWithFormat(this.model.manifestDateTo ?? new Date(), "YYYY-MM-DD") : null
                            }
                        }
                    }
                }
            },
            warehouses() { return (this.reportScopes?.warehouses ?? []).filter(wh => (this.model.customerID ?? 0) === 0 || ((this.model.customerID ?? 0) > 0 && (wh.customers ?? []).filter(cust => cust === (this.model.customerID ?? 0)).length > 0)) ?? []; },
            customers() { return (this.reportScopes?.customers ?? []).filter(cust => (this.model.warehouseID ?? 0) === 0 || ((this.model.warehouseID ?? 0) > 0 && (cust.warehouses ?? []).filter(wh => wh === (this.model.warehouseID ?? 0)).length > 0)) ?? []; }
        },
        watch: {
            $route(to, from) {
                if ((to?.params?.q ?? from?.params?.q)) {
                    this.model.searchText = to?.params?.q ?? from.params?.q ?? "";
                    this.$nextTick(() => { this.onFilter(); });
                }
            },
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            'model': {
                handler() {
                    if (this.filterKey || this.v$.$anyDirty)
                        this.$nextTick(() => { this.activity.dirty = true; });
                }, deep: true
            }
        },
        methods: {
            onKeyUp(e) {
                if (e.keyCode === 13)
                    this.onFilter();
            },
            onFilter() {
                this.$nextTick(() => { this.filterKey = Math.random(); });
                this.v$.$reset();
                this.$nextTick(() => { this.activity.dirty = false; });
            },
            onUpdate(e) {
                this.$nextTick(() => { this.activity.binding = e.loading; });
                this.$nextTick(() => { this.activity.export = e.download; });
            },
            onCreateDateFrom(e) {
                this.$nextTick(() => {
                    this.model.createDateFromValue = e.value;
                    this.model.createDateFrom = (e.record.data ?? new Date());
                });
            },
            onCreateDateTo(e) {
                this.$nextTick(() => {
                    this.model.createDateToValue = e.value;
                    this.model.createDateTo = (e.record.data ?? new Date());
                });
            },
            onAllocateDateFrom(e) {
                this.$nextTick(() => {
                    this.model.allocateFromValue = e.value;
                    this.model.allocateDateFrom = e.record.data;
                });
            },
            onAllocateDateTo(e) {
                this.$nextTick(() => {
                    this.model.allocateDateToValue = e.value;
                    this.model.allocateDateTo = e.record.data;
                });
            },
            onReset() { this.$router.push({ name: 'dashboard-index' }); },
            getReportScopes() {
                this.reportScopes = null;
                this.$store.dispatch("provideraccount/getURL", { url: `/services/settings/user/reportscope` })
                    .then((response) => {
                        this.reportScopes = response;
                        this.sources.warehouses = response?.warehouses;
                        this.sources.customers = response?.customers;
                        this.sources.providers = response?.providers;
                        this.sources.accounts = response?.accounts;
                    });
            },
            onWarehouseChange(e) {
                this.model.warehouseID = e?.value;
                this.getSources();
            },
            onCustomerChange(e) {
                this.model.customerID = e?.value;
                this.getSources();
            },
            getSources()
            {
                this.sources.accounts = (this.accounts ?? []).filter(x => this.model.serviceProviderID === 0 || (this.model.serviceProviderID > 0 && this.model.serviceProviderID === x.serviceProviderID)) ?? [];
            }
        },
        async created() {
            this.loading = false;
            let startDate = new Date();
            startDate.setDate(1);
            startDate.setMonth(startDate.getMonth() - 2);
            this.model.createDateFromValue = startDate;
            this.model.createDateToValue = new Date();
        },
        mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            if (this.$router?.currentRoute?._value?.params?.q) {
                this.model.searchText = this.$router?.currentRoute?._value?.params?.q;
                this.$nextTick(() => { this.onFilter(); });
            }
            this.keys = sessionStorage.getItem('activekeys');
            this.loading = false;
            this.model.customerID = this.isCustomer;
            this.model.reportType = 200;
            let startDate = new Date();
            startDate.setDate(1);
            startDate.setMonth(startDate.getMonth() - 2);
            this.model.createDateFromValue = startDate;
            this.model.createDateToValue = new Date();
            this.$nextTick(() => this.v$.$reset());
            this.getReportScopes();
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }

    .p-orderlist-controls,
    .p-picklist-buttons.p-picklist-source-controls,
    .p-picklist-buttons.p-picklist-source-controls, .p-picklist-buttons.p-picklist-target-controls {
        display: none !important;
    }

    .picklist-item {
        display: flex;
        align-items: center;
        padding: .2rem;
        width: 100%;
    }

    img#is-img {
        width: 75px;
        /*   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);*/
        margin-right: 1rem;
    }

    .picklist-list-detail {
        flex: 1 1 0;
    }

    .picklist-list-action {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .picklist-category-icon {
        vertical-align: middle;
        margin-right: .5rem;
        font-size: .875rem;
    }

    .picklist-category {
        vertical-align: middle;
        line-height: 1;
        font-size: .875rem;
    }

    .custom-skeleton {
        border: 1px solid var(--surface-d);
        border-radius: 4px;
    }

    .custom-skeleton ul {
        list-style: none;
    }

        .custom-skeleton ul > li {
            padding: 0.75rem;
        }
/*    .p-disabled, .p-component:disabled, .p-disabled > * {
        opacity: 0.70;
        color: black !important;
    }*/
</style>
