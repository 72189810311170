<template>
    <form-wrapper :title="router.title" :sub-title="router.subtitle">
        <cosmos-grid id="InvoiceList" sourceType="storename" source="invoice" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showExport="false" :baseRecordID="0" :showCheckbox="false">
            <pv-grid-column field="customerID" header="Customer" style="min-width: 100%; max-width: 100%; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
                <template #body="slotProps">
                    <div class="p-d-flex">
                        <div style="width:100%">
                            <div class="p-d-flex p-jc-between" style="min-width: 10rem; margin-bottom: 5px;">
                                <div><span>{{companyDS.filter(x => x.id == slotProps.data.customerID)[0]?.text}} </span></div>
                            </div>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="code" header="Transaction ID" headerStyle="min-width: 13rem;" style="min-width: 13rem; text-align: left; object-position: center; text-overflow: ellipsis; white-space: nowrap;">
                <template #body="slotProps">
                    <div class="p-d-flex">
                        <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left;">
                            <router-link style="font-size: 1rem; font-weight:700;" :to="{ name: 'invoice-edit', params: { mask: slotProps.data.mask }}">{{slotProps.data.code}}</router-link>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="name" header="Invoice Number" headerStyle="min-width: 13rem;" style="min-width: 13rem; text-align: left; object-position: center; text-overflow: ellipsis; white-space: nowrap;">
                <template #body="slotProps">
                    <div class="p-d-flex">
                        <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left;">
                            <router-link style="font-size: 1rem; font-weight:700;" :to="{ name: 'invoice-edit', params: { mask: slotProps.data.mask }}">{{slotProps.data.name}}</router-link>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="Status" header="Status" headerStyle="min-width: 13rem;" style="min-width: 13rem; text-align: left; object-position: center; text-overflow: ellipsis; white-space: nowrap;">
                <template #body="slotProps">
                    <div class="p-d-flex">
                        <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left;">
                            {{slotProps.data.statusText}}
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="invoiceDate" header="Invoice Date" headerStyle="min-width: 13rem;" style="min-width: 13rem; text-align: left; object-position: center; text-overflow: ellipsis; white-space: nowrap;">
                <template #body="slotProps">
                    <div class="p-d-flex">
                        <div v-if="slotProps.data?.invoiceDate" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                            {{$filters.toDateDisplay(slotProps.data?.invoiceDate)}}
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="invoiceDueDate" header="Due Date" headerStyle="min-width: 13rem;" style="min-width: 13rem; text-align: left; object-position: center; text-overflow: ellipsis; white-space: nowrap;">
                <template #body="slotProps">
                    <div class="p-d-flex">
                        <div v-if="slotProps.data?.invoiceDueDate" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                            {{$filters.toDateDisplay(slotProps.data?.invoiceDueDate)}}
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="confirmDate" header="Confirmed Date" headerStyle="min-width: 13rem;" style="min-width: 13rem; text-align: left; object-position: center; text-overflow: ellipsis; white-space: nowrap;">
                <template #body="slotProps">
                    <div class="p-d-flex">
                        <div v-if="slotProps.data?.confirmDate" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                            {{$filters.toDateDisplay(slotProps.data?.confirmDate)}}
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="lastSentDate" header="Last Sent Date" headerStyle="min-width: 13rem;" style="min-width: 13rem; text-align: left; object-position: center; text-overflow: ellipsis; white-space: nowrap;">
                <template #body="slotProps">
                    <div class="p-d-flex">
                        <div v-if="slotProps.data?.lastSentDate" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                            {{$filters.toDateDisplay(slotProps.data?.lastSentDate)}}
                        </div>
                    </div>
                </template>
            </pv-grid-column>
        </cosmos-grid>
    </form-wrapper>
</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/mixin.js';
    export default {
        name: 'InvoiceTemplate',
        mixins: [mixins.GRID],
        data() {
            return {
                type: -1,
                filter: {},
                columns: [],
                deleteDisplay: ["code", "name"],
                searchFields: ["code", "name"],

            }
        },
        computed: {
            source() {
                return this.$vm.$enums["module"];
            },
            companyDS() { return this.$store.getters["company/ds"]?.data; },
        },
        methods: {
            async getCompanyList() {
                return this.$store.dispatch("company/getAll", {sort:null,where:null});
            },
            modules(slotProps) {
                return this.source.filter(x => this.$filters.getFlags(slotProps.data.modules).indexOf(x.id) !== -1).map(x => '<div class="p-chip" style="padding: 2px 10px; border-radius: 4px;">' + x.text + '</div>').join(', ');
            }
        },
        async created() {
            await this.getCompanyList();
        },
        async mounted() {
            await this.getCompanyList();
        },
        async updated() { /*this.setType();*/ }
    }
</script>
