<template>
    <pv-dialog :header="'Address Lookup'" :visible="showDialog" :modal="true" :closable="true" :closeOnEscape="true" :draggable="true">
        <div class="confirmation-content">
            <slot>
                <div class="p-field p-grid" style="width: 500px;">
                    <div class="p-col-12">
                        <form-input id="AddressLookupSearchText" name="AddressLookupSearchText" :value="Search" placeholder="Enter address parts (postal code, city, etc.)" v-focus />
                    </div>
                </div>
            </slot>
        </div>
        <template #footer>
            <pv-button label="Close" :icon="cancelIcon" @click="Cancel" class="p-button" />
        </template>
    </pv-dialog>
</template>
<script>

    export default {
        name: 'DialogTemplate',
        emits: ['is-selected', 'is-cancel'],
        props: {
            country: { type: String },
            searchText: { type: String },
        },
        data() {
            return {
                showDialog: true,
                Search: null
            }
        },
        methods: {
            Confirm() {
                this.$emit("is-confirm");
            },
            Cancel() {
                this.showDialog = false;
                this.$emit("is-cancel");
            }
        },
        watch: {
            visible() {
                this.showDialog = this.visible;
            }
        },
        async mounted() {
            this.Search = this.searchText;
            const fetchify = this.$fetchify;
            fetchify.attach({
                search: 'AddressLookupSearchText',
            }, { onResultSelected: function (c2a, elements, address) { this.$emit("is-selected", address); } });
            this.$nextTick(() => {
                setTimeout(() => {
                    fetchify.selectCountry('usa'); /// must be lowecase
                }, 200);

            });
            //var _self = this;
            //var fetchify = new clickToAddress({
            //    accessToken: 'd0bdf-216db-63c6f-02897', // Replace this with your access token
            //    getIpLocation: false,
            //    gfxMode: 1,
            //    domMode: 'id',
            //    countryMatchWith: 'iso_2',
            //    showLogo: false,
            //    transliterate: false,
            //    countryLanguage: 'en',
            //    countrySelector: false,
            //    defaultCountry: _self.country.toLowerCase(),
            //    style: {
            //        ambient: 'dark',
            //        accent: 'indigo'
            //    }
            //});
            //fetchify.attach({ search: 'AddressLookupSearchText' },
            //    { onResultSelected: function (c2a, elements, address) { _self.$emit("is-selected", address); } });

        },
        updated() {
            this.Search = this.searchText;
        }

    }
</script>
<!--<style>
    .p-dialog .p-dialog-content {
        padding: 2rem 1.25rem !important;
    }
    div.p-dialog-header, div.p-dialog-footer {
        background-color: var(--surface-a) !important;
        padding: 0.75rem 1rem !important;
    }
    div.p-dialog-header {
        border-bottom: 1px solid var(--surface-d) !important;
    }
    div.p-dialog-footer {
        border-top: 1px solid var(--surface-d) !important;
    } 
    div.p-dialog-header > .p-dialog-title {
        font-size: 1rem !important;
    }
</style>-->