<template>
    <cosmos-grid :id="id" :searchFilter="['code', 'name']" :source="sources.integrations" sourceType="array" :paged="false" :autoGenerate="false" :deleteDisplayFields="deleteDisplay" :showAdd="false" :showDelete="false" :showExport="false" :showOperation="false" :pageSize="5">
        <pv-grid-column field="code" header="Code" headerStyle="width: 15rem" />
        <pv-grid-column field="name" header="Name" />
    </cosmos-grid>
</template>
<script>
    export default {
        name: "IntegrationGrid",
        props: {
            id: { type: String },
            code: { type: String, default: null },
            provider: { type: String, default: null }
        },
        data() {
            return {
                sources: {
                    integrations: []
                }
            }
        },
        watch: {
            provider() {
                this.getSource();
            }
        },
        methods: {
            getSource() {
                this.sources.integrations = [];
                this.$nextTick(() => {
                    if (this.provider) {
                        this.$store.dispatch("providerintegration/getURL", { url: `/services/settings/serviceproviderintegration/byprovider/${this.provider}` })
                            .then((response) => {
                                if (response && response.success) {
                                    this.sources.integrations = response?.result ?? response ?? [];
                                }
                            });
                    }
                })
            }
        },
        created() { this.getSource(); }
    }
</script>