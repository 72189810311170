<template>
    <is-dialog header="Delete Record?" :visible="dialog.delete" @is-confirm="onConfirmDelete(model?.mask)" @is-cancel="onCancel()">
        <div class="confirmation-content p-d-flex">
            <div><i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" /></div>
            <div>
                You are about to delete this record:
                <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                    <tr>
                        <th style="text-align:left;">Country</th>
                        <td>:</td>
                        <td> {{ model.text }} </td>
                    </tr>
                </table>
                Please be aware of the following:
                <ul>
                    <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                    <li>For records that has activity, the record will only be disabled and archived.</li>
                    <li>Also deleting a record may be irreversible action.</li>
                </ul>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
    <is-dialog :header="`${ model?.id === 0 ? 'Add' : 'Update' } Zone Mapping`" :visible="dialog.add" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{ width: '50vw' }" @is-confirm="onSave()" @is-cancel="onCancel()">
        <form-lookup id="zoneCountryID" disabledType="object" :value="model?.zoneCountryID?.toString()" label="Country" :source="zoneCountries" :required="true" sourceType="array" :v$="v$" @is-sync="{ this.model.zoneCountryID = $event?.value; }" :sorted="false" textField="text" valueField="value">
            <template #value="slotProps">
                <div class="country-item" style="margin: 1px 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; display: inline-flex; flex: 1 1 auto; max-width: 480px !important; ">
                    <span :class="`flag flag-${[...sources?.zoneCountries].filter(x => x.value === slotProps.data.value)[0].cosmosData.code.toLowerCase()}`" style="width: 30px; height: 20px;"></span>
                    <div class="p-ml-2" style="margin: 1px 0; overflow: hidden; white-space: pre-wrap; "><span v-html="`${[...sources?.zoneCountries].filter(x => x.value === slotProps.data.value)[0].text}`"></span></div>
                </div>
            </template>
            <template #option="slotProps">
                <div class="country-item" style="margin: 1px 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; display: inline-flex; flex: 1 1 auto; max-width: 480px !important; ">
                    <span :class="`flag flag-${slotProps.data.option.cosmosData.code.toLowerCase()}`" style="width: 30px; height: 20px;"></span>
                    <div class="p-ml-2" style="margin: 1px 0; overflow: hidden; white-space: pre-wrap; "><span v-html="`${slotProps.data.option.text}`"></span></div>
                </div>
            </template>
        </form-lookup>
        <div class="p-grid" v-if="export_zoneNames.length > 0 || export_zoneGroups.length > 0">
            <div class="p-col-12 p-md-2">
                <div style="margin-top: 1.25rem; font-weight: 600;">Export</div>
            </div>
            <div class="p-col-12 p-md-5">
                <form-lookup id="export_ZoneNameID" sourceType="array" :source="export_zoneNames" label="Zone Name" :value="model?.export_ZoneNameID" @is-sync="model.export_ZoneNameID = $event.value" :required="true" :sorted="false" disabledType="object" :disabled="export_zoneNames.length === 0 || export_zoneGroups.length > 0" />
            </div>
            <div class="p-col-12 p-md-5">
                <form-lookup id="export_ZoneGroupID" sourceType="array" :source="export_zoneGroups" label="Zone Group" :value="model?.export_ZoneGroupID" @is-sync="model.export_ZoneGroupID = $event.value" :required="true" :sorted="false" disabledType="object" :disabled="export_zoneGroups.length === 0" textField="name" />
            </div>
        </div>
        <div class="p-grid" v-if="import_zoneNames.length > 0 || import_zoneGroups.length > 0">
            <div class="p-col-12 p-md-2">
                <div style="margin-top: 1.25rem; font-weight: 600;">Import</div>
            </div>
            <div class="p-col-12 p-md-5">
                <form-lookup id="import_ZoneNameID" sourceType="array" :source="import_zoneNames" label="Zone Name" :value="model?.import_ZoneNameID" @is-sync="model.import_ZoneNameID = $event.value" :required="true" :sorted="false" disabledType="object" :disabled="import_zoneNames.length === 0 || import_zoneGroups.length > 0" />
            </div>
            <div class="p-col-12 p-md-5">
                <form-lookup id="import_ZoneGroupID" sourceType="array" :source="import_zoneGroups" label="Zone Group" :value="model?.import_ZoneGroupID" @is-sync="model.import_ZoneGroupID = $event.value" :required="true" :sorted="false" disabledType="object" :disabled="import_zoneGroups.length === 0" textField="name" />
            </div>
        </div>
        <div class="p-grid" v-if="thirdCountry_zoneNames.length > 0 || thirdCountry_zoneGroups.length > 0">
            <div class="p-col-12 p-md-2">
                <div style="margin-top: 1.25rem; font-weight: 600;">Third-Country</div>
            </div>
            <div class="p-col-12 p-md-5">
                <form-lookup id="thirdCountry_ZoneNameID" sourceType="array" :source="thirdCountry_zoneNames" label="Zone Name" :value="model?.thirdCountry_ZoneNameID" @is-sync="model.thirdCountry_ZoneNameID = $event.value" :required="true" :sorted="false" disabledType="object" :disabled="thirdCountry_zoneNames.length === 0 || thirdCountry_zoneGroups.length > 0" />
            </div>
            <div class="p-col-12 p-md-5">
                <form-lookup id="thirdCountry_ZoneGroupID" sourceType="array" :source="thirdCountry_zoneGroups" label="Zone Group" :value="model?.thirdCountry_ZoneGroupID" @is-sync="model.thirdCountry_ZoneGroupID = $event.value" :required="true" :sorted="false" disabledType="object" :disabled="thirdCountry_zoneGroups.length === 0" textField="name" />
            </div>
        </div>
    </is-dialog>
    <pv-grid :value="sources.zoneMappings" responsiveLayout="scroll" showGridlines
             :rows="10"
             :totalRecords="(sources.zoneMappings ?? []).length"
             :paginator="true"
             currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
             :alwaysShowPaginator="true"
             :paginatorPosition="'bottom'"
             :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'"
             :pageLinkSize="3">
        <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
                <span>
                    <pv-button icon="pi pi-fw pi-plus" label="Add New Zone Mapping" style="margin-right: 5px;" @click="onCreate"></pv-button>
                </span>
            </div>
        </template>
        <pv-grid-column-group type="header">
            <pv-grid-row>
                <pv-grid-column header="Country Details" :colspan="6" />
                <pv-grid-column header="EXPORT" :colspan="2" />
                <pv-grid-column header="IMPORT" :colspan="2" />
                <pv-grid-column header="THIRD-COUNTRY" :colspan="2" />
                <pv-grid-column :rowspan="2" headerStyle="min-width: 8rem; max-width: 8rem; width: 8rem;" />
            </pv-grid-row>
            <pv-grid-row>
                <pv-grid-column field="country" header="Country" />
                <pv-grid-column field="categoryName" header="Category" />
                <pv-grid-column field="countryCity" header="City" />
                <pv-grid-column field="countryState" header="State" />
                <pv-grid-column field="countryPostalCodeFROM" header="Postal Code FROM" />
                <pv-grid-column field="countryPostalCodeTO" header="Postal Code TO" />
                <pv-grid-column field="exportZoneName" header="Zone Name" />
                <pv-grid-column field="exportZoneGroup" header="Zone Group" />
                <pv-grid-column field="importZoneName" header="Zone Name" />
                <pv-grid-column field="importZoneGroup" header="Zone Group" />
                <pv-grid-column field="thirdCountryZoneName" header="Zone Name" />
                <pv-grid-column field="thirdCountryZoneGroup" header="Zone Group" />
            </pv-grid-row>
        </pv-grid-column-group>
        <pv-grid-column field="country" header="Country">
            <template #body="slotProps">
                <span :class="`flag flag-${ slotProps.data?.countryDetail.code?.toLowerCase() }`" style="width: 30px; height: 20px; margin-right: 5px;"></span>
                {{ slotProps.data?.countryDetail.name }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="categoryName" header="Category" headerStyle="min-width: 5rem;" />
        <pv-grid-column field="countryCity" header="City" headerStyle="min-width: 5rem;" />
        <pv-grid-column field="countryState" header="State" headerStyle="min-width: 5rem;" />
        <pv-grid-column field="countryPostalCodeFROM" header="Postal Code FROM" headerStyle="min-width: 5rem;" />
        <pv-grid-column field="countryPostalCodeTO" header="Postal Code TO" headerStyle="min-width: 5rem;" />
        <pv-grid-column field="exportZoneName" header="Export Zone Name">
            <template #body="slotProps">
                {{ slotProps.data?.exportZoneName?.name }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="exportZoneGroup" header="Export Zone Group">
            <template #body="slotProps">
                {{ slotProps.data?.exportZoneGroup?.name }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="importZoneName" header="Import Zone Name">
            <template #body="slotProps">
                {{ slotProps.data?.importZoneName?.name }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="importZoneGroup" header="Import Zone Group">
            <template #body="slotProps">
                {{ slotProps.data?.importZoneGroup?.name }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="thirdCountryZoneName" header="Third-Country Zone Name">
            <template #body="slotProps">
                {{ slotProps.data?.thirdCountryZoneName?.name }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="thirdCountryZoneGroup" header="Third-Country Zone Group">
            <template #body="slotProps">
                {{ slotProps.data?.thirdCountryZoneGroup?.name }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="mask" headerStyle="min-width: 8rem; max-width: 8rem; width: 8rem;" style="min-width: 2rem; max-width: 2rem; border-left: 1px solid var(--surface-d); padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div class="p-col">
                        <pv-button icon="pi pi-pencil" iconPos="right" class="p-button-info" @click="onEdit(slotProps.data)" />
                    </div>
                    <div class="p-col">
                        <pv-button icon="pi pi-trash" iconPos="right" class="p-button-danger" @click="onDelete(slotProps.data)" />
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <!-- Row Messages -->
        <template #empty>
            No records found.
        </template>
        <template #loading>
            Retrieving records from database. Please wait...
        </template>
        <!-- Pagination -->
        <template #paginatorRight>
            <pv-button type="button" icon="pi pi-refresh" class="p-button-rounded p-button-text" @click="onRefresh" />
        </template>
    </pv-grid>
</template>
<script>
    export default {
        name: 'ZoneMappingForm',
        props: {
            id: { type: String },
            zoneID: { type: String },
            formData: { type: Object, default: null }
        },
        data() {
            return {
                dialog: {
                    add: false,
                    delete: false
                },
                edit: false,
                model: {
                    id: 0,
                    zoneID: this.zoneID,
                    zoneCountryID: null,
                    export_ZoneNameID: null,
                    export_ZoneGroupID: null,
                    import_ZoneNameID: null,
                    import_ZoneGroupID: null,
                    thirdCountry_ZoneNameID: null,
                    thirdCountry_ZoneGroupID: null,
                },
                sources: {
                    zoneMappings: [],
                    zoneCountries: [],
                    zoneNames: [],
                    zoneGroups: []
                },
                columns: [
                    { field: 'code', header: 'Code', width: 20 },
                    { field: 'name', header: 'Name' },
                    { field: 'name', header: 'Name' }
                ],
            }
        },
        computed: {
            export_zoneNames() { return [...this.sources?.zoneNames].filter(x => (x.tradeType & 1) === 1); },
            export_zoneGroups() { return [...this.sources?.zoneGroups].filter(x => (x.tradeType & 1) === 1); },
            import_zoneNames() { return [...this.sources?.zoneNames].filter(x => (x.tradeType & 2) === 2); },
            import_zoneGroups() { return [...this.sources?.zoneGroups].filter(x => (x.tradeType & 2) === 2); },
            thirdCountry_zoneNames() { return [...this.sources?.zoneNames].filter(x => (x.tradeType & 4) === 4); },
            thirdCountry_zoneGroups() { return [...this.sources?.zoneGroups].filter(x => (x.tradeType & 4) === 4); },
            activeRecord() { return Number(this.model?.zoneCountryID); },
            selectedCountries() { return this.sources?.zoneMappings.map(x => Number(x.zoneCountryID)) },
            zoneCountries() { return [...[...this.sources?.zoneCountries].filter(x => this.selectedCountries.indexOf(x.id) === -1 || x.id === this.activeRecord)]; },
            confirmDisabled() {
                if (this.edit) {
                    let notselected = (this.zoneGroups ?? []).filter(x => x.columnPosition !== this.model.columnPosition)
                    return !(this.model.code
                        && this.model.name
                        && this.model.tradeType > 0
                        && (notselected ?? []).filter(x => x.code.toUpperCase() == this.model?.code?.toUpperCase()).length === 0
                        && (notselected ?? []).filter(x => x.name.toUpperCase() == this.model?.name?.toUpperCase()).length === 0
                    );
                }
                return !(
                    this.model.code
                    && this.model.name
                    && this.model.tradeType > 0
                    && (this.zoneGroups ?? []).filter(x => x.code.toUpperCase() == this.model?.code?.toUpperCase()).length === 0
                    && (this.zoneGroups ?? []).filter(x => x.name.toUpperCase() == this.model?.name?.toUpperCase()).length === 0
                );
            }
        },
        watch: {
            'data': { handler() { this.model = this.data; }, deep: true },
            'zoneID': {
                handler() {
                    this.getSource();
                }, deep: true
            },
            'sources.zoneMappings': { handler() { this.$emit("is-sync", { zoneMappings: this.sources.zoneMappings }) }, deep: true }
        },
        methods: {
            onText(e) {
                this.model.export_ZoneNameID = e.value;
                console.log(e);
            },
            onCreate() {
                this.model = {
                    id: 0,
                    zoneID: this.zoneID,
                    zoneCountryID: null,
                    export_ZoneNameID: null,
                    export_ZoneGroupID: null,
                    import_ZoneNameID: null,
                    import_ZoneGroupID: null,
                    thirdCountry_ZoneNameID: null,
                    thirdCountry_ZoneGroupID: null,
                    environmentID: null
                };
                this.dialog.add = true;
            },
            onEdit(e) {
                this.model = e;
                this.dialog.add = true;
            },
            onSave() {
                if (this.model.id === 0) {
                    this.$store.dispatch("providerzonemapping/addRecord", this.model).then((response) => {
                        if (response.success) {
                            this.$toast.add({ severity: 'success', summary: 'Zone Mapping', detail: "The record was added successfully!", life: 3000 });
                            this.onCancel();
                            this.getZoneMappings();
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
                }
                else {
                    this.$store.dispatch("providerzonemapping/updateRecord", this.model).then((response) => {
                        if (response.success) {
                            this.$toast.add({ severity: 'success', summary: 'Zone Mapping', detail: "The record was updated successfully!", life: 3000 });
                            this.onCancel();
                            this.getZoneMappings();
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
                }
                this.dialog.add = false;
            },
            onDelete(e) {
                this.model = e;
                this.dialog.delete = true;
            },
            onConfirmDelete(e) {
                this.$store.dispatch("providerzonemapping/deleteRecordByMask", e)
                    .then((response) => {
                        if (response.success) {
                            this.$toast.add({ severity: 'success', summary: 'Zone Mapping', detail: "The record was deleted successfully!", life: 3000 });
                            this.onCancel();
                            this.forceRefresh = true;
                            this.$nextTick(() => this.forceRefresh = false);
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
            onCancel() {
                this.dialog.add = false;
                this.dialog.delete = false;
                this.model = {};
            },
            getZoneMappings() {
                if (this.zoneID) {
                    this.$store.dispatch("providerzonemapping/getURL", { url: `/services/settings/serviceproviderzonemapping/byzone/id/${this.zoneID}` }).then((response) => {
                        if (response && response.success)
                            this.sources.zoneMappings = response.result;
                    }).catch((ex) => console.log(ex));
                }
            },
            getSource() {
                if (this.zoneID) {
                    this.$store.dispatch("providerzone/getURL", { url: `/services/settings/serviceprovidercountry/byprovider/id/${this.formData.provider.id}` }).then((response) => {
                        if (response && response.success)
                            this.sources.zoneCountries = response.result;
                    }).catch((ex) => console.log(ex));
                    this.$store.dispatch("providerzonemapping/getURL", { url: `/services/settings/serviceproviderzonemapping/byzone/id/${this.zoneID}` }).then((response) => {
                        if (response && response.success)
                            this.sources.zoneMappings = response.result;
                    }).catch((ex) => console.log(ex));
                    this.$store.dispatch("providerzone/getURL", { url: `/services/settings/serviceproviderzonename/byzone/id/${this.zoneID}` }).then((response) => {
                        if (response && response.success)
                            this.sources.zoneNames = response.result;
                    }).catch((ex) => console.log(ex));
                    this.$store.dispatch("providerzone/getURL", { url: `/services/settings/serviceproviderzonegroup/byzone/id/${this.zoneID}` }).then((response) => {
                        if (response && response.success)
                            this.sources.zoneGroups = response.result;
                    }).catch((ex) => console.log(ex));
                }

            }
        },
        created() {
            this.getSource();
        }

    }
</script>
