<template>
    <form-record :model="model" :modelState="v$" :saveOnly="true" :viewBack="false" @is-reset="resetRecord" @is-send="sendRecord" title="profile">
        <div class="p-grid">
            <div class="p-col-12 p-md-4">
                <form-input id="username" :value="model.username" :v$="v$" @is-sync="model.username = $event.value" v-lowercase v-focus />
                <form-input id="firstName" :value="model.firstName" :v$="v$" @is-sync="model.firstName = $event.value" />
                <form-input id="lastName" :value="model.lastName" :v$="v$" @is-sync="model.lastName = $event.value" />
                <form-input id="email" :disabled="true" :value="model.email" :v$="v$" @is-sync="model.email = $event.value?.toLowerCase()" v-lowercase :max-length="100" />
                <form-input id="licenseKey" :disabled="true" :value="model.code" :v$="v$" :max-length="100" />
                <form-input v-show="(model.type == 1 && model.id > 0) || model.type != 1" id="password" :disabled="model.id > 0 && model.type == 1" :type="model.type == 1 ? 'text' : 'password'" :value="model.password" placeholder="(Leave blank to auto-generate)" :v$="v$" @is-sync="model.password = $event.value" :max-length="1000" />
                <form-input v-show="(model.type == 1 && model.id > 0) || model.type != 1" id="confirmPassword" :disabled="model.id > 0 && model.type == 1" :type="model.type == 1 ? 'text' : 'password'" :value="model.confirmPassword" placeholder="(Leave blank to auto-generate)" :v$="v$" @is-sync="model.confirmPassword = $event.value" :max-length="1000" />
            </div>
            <div class="p-col-12 p-md-4">
                <form-lookup id="companyID" :disabled="true" :showCopy="false" label="Company" :source="companyDS" :value="model.companyID" :v$="v$" :textField="'name'" :valueField="'id'" />
                <form-lookup id="defaultWarehouse" label="Default Warehouse" :required="true" :source="warehouses" :value="model.defaultWarehouseID" :v$="v$" @is-sync="model.defaultWarehouseID = $event.value" :textField="'name'" :valueField="'id'" />
                <form-lookup v-if="model.type != 1" id="theme" type="enum" source="theme" :v$="v$" :value="model.theme" @is-sync="model.theme = $event.value"></form-lookup>
                <form-lookup v-if="model.type != 1" id="displayScale" source="displaysize" type="enum" :value="model.displayScale ?? 14" @is-sync="{ model.displayScale = $event.value; model.display = $event.value }" :v$="v$" :required="true"></form-lookup>
                <!--<form-lookup v-if="model.type != 1" id="menuView" type="enum" source="menuview" :value="model.menuView" @is-sync="model.menuView = $event.value" :v$="v$"></form-lookup>-->
                <form-numeric v-if="model.type != 1" id="GridRecords" type="integer" minValue="1" maxValue="100" :suffix="recordDisplay" :value="model.pageSize" :min="1" :max="100" @is-sync="model.pageSize = $event.value" :v$="v$"></form-numeric>
                <form-boolean id="autoConfirm" v-model="model.autoConfirm" v-if="model.verificationConfirmDate === null || model.type == 1" :value="model.autoConfirm" onText="YES" offText="NO" :label="autoConfirmMessage" width="120px" textPosition="left" @is-sync="model.autoConfirm = $event.value" :v$="v$"></form-boolean>

                <form-lookup v-if="model.type != 1 && false" :disabled="true" :showCopy="false" type="flag" :value="model.permission" id="permission" label="Permission" :source="'userpermission'" @is-sync="model.permission = $event.value"></form-lookup>

            </div>
            <div class="p-col-12 p-md-4">
                <form-datetime v-if="model && model.mask" id="VerificationSentDate" :value="model.verificationSentDate" :disabled="true" :hideLabel="true" preLabel="Verification Sent Date" :showButton="false" labelWidth="200px" type="datetime" :showCopy="false" />
                <form-datetime v-if="model && model.mask" id="ConfirmationDate" :value="model.verificationConfirmDate" :disabled="true" :hideLabel="true" preLabel="Confirm Date" :showButton="false" labelWidth="200px" type="datetime" :showCopy="false"/>
                <form-datetime v-if="model && model.mask" id="LastLoginDate" :value="model.lastLoginDate" :disabled="true" :hideLabel="true" preLabel="Last Login Date" :showButton="false" labelWidth="200px" type="datetime" :showCopy="false"/>
                <form-input v-if="model && model.mask" id="ActiveSessionID" :value="model.sessionID" :disabled="true" :hideLabel="true" preLabel="Session ID" :showButton="false" labelWidth="200px" :showCopy="false"/>
                <form-input v-if="model && model.mask" id="ActiveSessionIP" :value="model.sessionIP" :disabled="true" :hideLabel="true" preLabel="Session IP" :showButton="false" labelWidth="200px" :showCopy="false"/>
            </div>
        </div>
        
        <div class="p-grid" style="margin: 0 -20px;" v-if="model.id != 0">
            <div class="p-col-12">
                <pv-tab>
                    <pv-tabpanel header="SETTINGS" v-if="model.id > 0">
                        <div style="padding: 1rem;">
                            <user-general-setting :showSetting="true" :formData="model" :printerSource="printerSource" @is-sync="sync_generalSetting($event)" @is-initial-sync="sync_generalSetting($event)" @is-mounted="isMounted($event)" />
                        </div>
                    </pv-tabpanel>
                    <pv-tabpanel header="SCOPES" v-if="model.type != 2">
                        <user-scope :is_parentID="parentID" :disabled="true" :is_parentMask="parentMask"></user-scope>
                    </pv-tabpanel>
                    <!--<pv-tabpanel header="PERMISSIONS">
        <div class="p-col-4">
        </div>
    </pv-tabpanel>-->
                </pv-tab>
            </div>
        </div>
    </form-record>
</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength, email, sameAs } from '@vuelidate/validators';
    import generalsetting from "@/views/settings/defaults/user/generalsetting.vue";
    import scope from "@/views/settings/defaults/user/scope.vue";
    export default {
        name: 'UserRecord',
        setup: () => ({ v$: useVuelidate() }),
        mixins: [mixins.RECORD],
        components: {
            'user-general-setting': generalsetting,
            'user-scope': scope

        },
        data() {
            return {
                departmentDS: [],
                teamDS: [],
                isChildMounted: false,
                printerSource: []
            }
        },
        computed: {
            parentID() {
                return this.model.id;
            },
            parentMask() {
                return this.model.mask;
            },
            ds() { return this.$store.getters["company/ds"]; },
            type() { return this.$route.meta.typeID ?? 0; },
            companyDS() {
                return this.ds.data.filter((x) => this.model.type === 5 ? x.type === 16 : (x.type === 1 || x.type === 2));
            },
            disabledDepartment() {
                return !(this.departmentDS && this.departmentDS.length > 0);
            },
            disabledTeam() {
                return !(this.teamDS && this.teamDS.length > 0) || this.disabledDepartment;
            },
            recordDisplay() {
                return this.$filters.quantifiedDisplay("record", this.model.pageSize) + " per page";
            },
            autoConfirmMessage() {
                //`{{(model.type == 1 ? "Approve API?" :"Auto-Confirm Email?")}}`
                return this.model.type == 1 ? "Approved Credential?" : "Auto-Confirm Email?";
            },
            warehouses() { return this.$config.common.$warehouse(this.model.environmentID, this.model); },
            title() {
                if (this.model?.firstName || this.model?.lastName)
                    return `${this.model?.firstName ?? ""} ${this.model?.lastName ?? ""}`;
                return this.$route.title;
            }
        },
        methods: {
            async getCompanyList() {
                return this.$store.dispatch("company/getAll", { sort: null, filter: null, where: null });
            },
            filterDepartment() {
                return this.ds.data.filter((x) => x.type === 64 && x.environmentID === this.model.companyID)
            },
            filterTeam() {
                return this.ds.data.filter((x) => x.type === 128 && x.companyID === this.model.companyID && x.departmentID === this.model.departmentID)
            },
            onUpload(e) {
                var image = JSON.parse(e.xhr.responseText);
                this.model.mask = image.file;
            },
            async isMounted(value) {
                this.isChildMounted = value;
                this.v$.$reset();
               
            },
            async sync_generalSetting(model) {
                this.model.generalSetting = model;
                if (this.model.generalSetting.userID === null)
                    this.model.generalSetting.userID = this.model.id;
                if (this.isChildMounted)
                    this.v$.$touch();
            },
            getPrinters() {
                if (this.$route.params.mask) {
                    this.$axios
                        .get(this.$config.config.endpoint.api + `/services/settings/companyprinter/user/${this.$route.params.mask}/active`.replaceAll("//", "/"))
                        .then((response) => {
                            this.printerSource = response.data.result;
                            this.$emit("is-initial-sync", this.model);
                        })
                        .catch((ex) => { this.errors = ex.data; });
                }
            }

        },
        watch: {
            'model.companyID':
                {
                handler() {
                        this.$nextTick(() => {
                            this.departmentDS = this.filterDepartment();
                            this.teamDS = this.filterTeam();
                        })
                        this.model.environmentID = this.$vm.$appSession.environment.id;
                },
                deep: true
            },
            companyID() {
                this.filterDepartment();
                this.filterTeam();
            },
            departmentID() {
                this.filterTeam();
            }
        },
        validations() {
            return {
                model: {
                    username: { required, minLength: minLength(1), maxLength: maxLength(20), $autoDirty: true },
                    password: { required, maxLength: maxLength(50), $autoDirty: true },
                    confirmPassword: { required, maxLength: maxLength(50), sameAsPassword: sameAs(this.model.password), $autoDirty: true },
                    email: { required, email, maxLength: maxLength(100), $autoDirty: true },
                    firstName: { required, maxLength: maxLength(35), $autoDirty: true },
                    lastName: { required, maxLength: maxLength(35), $autoDirty: true },
                    companyID: { required, $autoDirty: true },
                    pageSize: { $autoDirty: true },
                    /*display: { required, $autoDirty: true },*/
                    theme: { required, $autoDirty: true },
                    menuView: { required, $autoDirty: true },
                    permission: { $autoDirty: true },
                    autoConfirm: { $autoDirty: true }
                }
            }
        },
        async created() {
            await this.getCompanyList().then(() => {
                this.departmentDS = this.filterDepartment();
                this.teamDS = this.filterTeam();
                switch (this.$router.currentRoute.value.meta.type.toLowerCase()) {
                    case "sysadmins":
                        this.model.type = 2;
                        break;
                    case "admins":
                        this.model.type = 3;
                        break;
                    case "agents":
                        this.model.type = 4;
                        break;
                    case "customers":
                        this.model.type = 5;
                        break;
                    case "retailers":
                        this.model.type = 7;
                        break;
                    case "apis":
                        this.model.type = 1;
                        this.model.companyID = this.$vm.$appSession.environment.id;
                        if (this.mode.id === 0) {
                            this.model.password = "TEMPXXX" + new Date().getMilliseconds().toString();
                            this.model.lastName = "API"
                        }
                        break;
                }
            });
            this.getPrinters();
        },
        async mounted() {
            await this.getCompanyList();
            this.getPrinters();
            this.model.confirmPassword = this.model.password;
        },
        updated() {
        
            /*MAXIMUM RECURSIVE REACH ISSUE REPLACE CONDITION FOR this.$router */
            //switch (this.$router.currentRoute.value.meta.type.toLowerCase()) {
            //    case "sysadmins":
            //        this.model.type = 2;
            //      /*  this.model.companyId = this.$vm.$appSession.environment.id;*/
            //        break;
            //    case "admins":
            //        this.model.type = 3;
            //        break;
            //    case "agents":
            //        this.model.type = 4;
            //        break;
            //    case "customers":
            //        this.model.type = 5;
            //        break;
            //    case "retailers":
            //        this.model.type = 7;
            //        break;
            //    case "apis":
            //        this.model.type = 1;
            //      /*  this.model.companyId = this.$vm.$appSession.environment.id;*/
            //        break;
            //}
        }
    }
</script>
