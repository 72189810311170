<template>
    <pv-tab style="margin: -10px -15px !important; ">
        <pv-tabpanel header="Items">
            <return-item-grid :parentID="parentID"></return-item-grid>
        </pv-tabpanel>
        <pv-tabpanel header="Addresses">
            <return-address-grid :parentID="parentID"></return-address-grid>
        </pv-tabpanel>
        <pv-tabpanel header="Threads">
            <return-thread-grid :parentID="parentID"></return-thread-grid>
        </pv-tabpanel>
        <pv-tabpanel header="Documents">
            <return-document-grid :parentID="parentID"></return-document-grid>
        </pv-tabpanel>
    </pv-tab>
</template>
<script>
    import ReturnItemGrid from '@/components/transaction/tms/returns/returns-item-grid.vue';
    import ReturnAddressGrid from '@/components/transaction/tms/returns/returns-address-grid.vue';
    import ReturnThreadGrid from '@/components/transaction/tms/returns/returns-thread-grid.vue';
    import ReturnDocumentGrid from '@/components/transaction/tms/returns/returns-document-grid.vue';
    const config = require('@/assets/lib/cosmos/_js/config.js').default;
    export default {
        name: 'ReturnItemGridTemplate',
        components: {
            'return-item-grid': ReturnItemGrid,
            'return-address-grid': ReturnAddressGrid,
            'return-thread-grid': ReturnThreadGrid,
            'return-document-grid': ReturnDocumentGrid
        },
        props: {
            parentID: { type: Number },
        },
        data() {
            return {
                type: -1,
                filters: {},
                sources: {
                    items: [],
                    addresses: [],
                    threads: [],
                    documents: []
                },
                deleteDisplay: ["code", "name"],
                searchFields: ["details"],
                forceRefresh: false
            }
        },
        computed: {
            documenttypes() { return this.$vm.$enums.documenttype; },
            addresstypes() { return this.$vm.$enums.returnaddresstype; },
            tenants() { return config.common.$tenant(); },
            config() { return config.config; }
        },
        methods: {
            getSource() {
                this.$store.dispatch("returns/getURL", { url: `/services/tms/return/${this.parentID}/items` })
                    .then((response) => {
                        this.sources.items = response?.result ?? [];
                    });
                this.$store.dispatch("returns/getURL", { url: `/services/tms/return/${this.parentID}/addresses` })
                    .then((response) => {
                        this.sources.addresses = response?.result ?? [];
                    });
                this.$store.dispatch("returns/getURL", { url: `/services/tms/return/${this.parentID}/threads` })
                    .then((response) => {
                        this.sources.threads = response?.result ?? [];
                    });
                this.$store.dispatch("returns/getURL", { url: `/services/tms/return/${this.parentID}/documents` })
                    .then((response) => {
                        this.sources.documents = response?.result ?? [];
                    });
            },
            address(data) {
                var text = `${(data?.company ?? '')}, ${(data?.buildingNumber ?? '')} ${(data?.address1 ?? '')}, ${(data?.address2 ?? '')}, ${(data?.address3 ?? '')}, ${(data?.city ?? '')}, ${(data?.neighborhood ?? '')}, ${(data?.state ?? '')}, ${(data?.postalCode ?? '')} , ${(data?.country ?? '' )}`;
                return text
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',');
            }
        },
        async created() {
            this.getSource()
        },
        async mounted() {
        }
    }
</script>