<template>
    <cosmos-grid id="reportGrid" sourceType="array" :source="source?.data ?? []" :showCheckbox="true" :sourcePaged="true" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :showExport="false" :showOperation="false" :showHeader="false" @is-selected="onSelected($event)" :selectedReset="selectedReset" :forceRefresh="forceRefresh" style="position: relative;">
        <pv-grid-column field="collectionDate" header="DATE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ $filters.toDateDisplay(slotProps.data?.collectionDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="dc" header="WAREHOUSE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.dc}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customer" header="CUSTOMER" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.customer}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="code" header="TRANSACTION ID" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <a :href="`/track/${slotProps.data?.code}`" target="_self" style="font-weight: bold;">{{slotProps.data.code}}</a>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference" header="REFERENCE 1" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left;">
                        <router-link style="font-size: 1rem; font-weight:700;" :to="{ name: 'consignment-edit', params: { mask: slotProps.data.mask }}">{{slotProps.data.customerReference}}</router-link>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="carrier" header="CARRIER" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.carrier}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="product" header="SERVICE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.product}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference" header="REFERENCE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <a :href="`${$filters.formatString($vm.$providers.filter((x) => x.id == slotProps.data?.serviceProviderID)[0]?.trackingURL, slotProps.data.reference,slotProps.data.shipTo_PostalCode)}`" target="_blank" style="font-weight: bold;"> {{slotProps.data.reference}} </a>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="shipperCost" header="CONTRACT COST" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatCurrency(slotProps.data.shipperCost ?? 0.00,slotProps.data.shipperCostCurrencyCode?.toUpperCase(),slotProps.data.contractCountryCode,2)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="carrierCost" header="CUSTOMER COST" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatCurrency(slotProps.data.carrierCost ?? 0.00,slotProps.data.carrierCostCurrencyCode?.toUpperCase(),slotProps.data.contractCountryCode,2)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="totalParcel" header="TOTAL PACKAGE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatNumber(slotProps.data.totalParcel ?? 0.00,0,slotProps.data.contractCountryCode)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="totalWeight" header="TOTAL WEIGHT (KG)" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatNumber(slotProps.data.totalWeight ?? 0.00,3,slotProps.data.contractCountryCode)}}
                </div>
            </template>
        </pv-grid-column>
    </cosmos-grid>

</template>
<script>
    import { mapGetters } from 'vuex';
    import { useVuelidate } from '@vuelidate/core';
    export default {
        name: 'reportGrid',
        setup: () => ({ v$: useVuelidate() }),
        props: {
            formData: { type: Object }
        },
        data() {
            return {
                type: -1,
                filter: {},
                columns: [],
                deleteDisplay: ["code", "name"],
                searchFields: ["code", "name"],
                activity: { loading: true },
                forceRefresh: false,
                selectedItems: [],
                selectedReset: false,
                source: {
                    total: 0,
                    data: []
                },
                modelState: null,
                initialBind: false

            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            scopeCustomerDS() {
                return this.$config.common.$customer(this.user?.environmentID ?? -100, this.$vm.$appSession.warehouse.id ?? this.user.warehouseID, this.user);
            },
            scopeWarehouseDS() {
                return this.$config.common.$warehouse(this.user?.environmentID ?? -100, this.user);
            },
            apiData() {
                return {
                    ServiceHeader: {
                        Username: "TEMP",
                        Password: "TESTESTEDET",
                        ClientID: "TESTTESTTESTTESTTEST",
                        ClientSecret: "TESTEDREDREDTETEDEDETEREDEDEREDE"
                    },
                    Request: {
                        DC: this.formData?.warehouseID > 0 ? this.scopeWarehouseDS.filter(x => x.id === this.formData.warehouseID)[0]?.code : null,
                        Customer: this.formData?.customerID > 0 ? this.scopeCustomerDS.filter(x => x.id === this.formData.customerID)[0]?.code : null,
                        Account: this.formData?.serviceProviderAccountCode,
                        Carrier: this.formData?.serviceProviderID > 0 ? this.$vm.$providers.filter(y => y.id === this.formData.serviceProviderID)[0]?.code : null,
                        ScopeDetail: {
                            IncludeArchive: this.formData?.includeArchived,
                            ExcludeWithInvoice : true,
                            CollectionDate: {
                                From: this.$filters.formatDateWithFormat(this.formData.collectionDateFrom ?? new Date(), "YYYY-MM-DD"),
                                To: this.$filters.formatDateWithFormat(this.formData.collectionDateTo ?? new Date(), "YYYY-MM-DD")
                            },
                            ManifestDate: {
                                From: this.formData.manifestDateFrom !== undefined && this.formData.manifestDateFrom !== null ? this.$filters.formatDateWithFormat(this.formData.manifestDateFrom ?? new Date(), "YYYY-MM-DD") : null,
                                To: this.formData.manifestDateTo !== undefined && this.formData.manifestDateTo !== null ? this.$filters.formatDateWithFormat(this.formData.manifestDateTo ?? new Date(), "YYYY-MM-DD") : null
                            },
                            DispatchDate: {
                                From: this.formData.dispatchDateFrom !== undefined && this.formData.dispatchDateFrom !== null ? this.$filters.formatDateWithFormat(this.formData.dispatchDateFrom ?? new Date(), "YYYY-MM-DD") : null,
                                To: this.formData.dispatchDateTo !== undefined && this.formData.dispatchDateTo !== null ? this.$filters.formatDateWithFormat(this.formData.dispatchDateTo ?? new Date(), "YYYY-MM-DD") : null
                            }
                        }
                    }
                }

            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            'formData': {
                handler() {
                    this.$nextTick(() => {
                        this.selectedReset = true;
                        this.onRefresh();
                        this.$nextTick(() => { this.getSource(); });
                    });
                }, deep: true
            },
            'source.data': {
                handler() {
                    this.$nextTick(() => {
                        this.$emit("is-sync", { consignments: this.source.data ?? [] });
                    });
                }, deep: true
            },
            'selectedItems': {
                handler() {
                    this.$nextTick(() => {
                        this.$emit("is-selected", { consignments: this.selectedItems ?? [] });
                    });
                }, deep: true
            },

        },
        methods: {
            onRefresh() {
                this.forceRefresh = false;
                this.$nextTick(() => { this.forceRefresh = true; });
            },
            async getSource() {
                this.$nextTick(() => {
                    this.$axios.post(this.$config.config.endpoint.api + `/services/report/consignment`.replaceAll("//", "/"), this.apiData)
                        .then((response) => {
                            this.source.data = response?.data?.result ?? [];
                            this.source.total = this.source.data.length;
                        })
                        .catch(() => {
                            this.source.data = [];
                            this.source.total = 0;
                        });
                });

            },
            onSelected(e) {
                this.selectedReset = false;
                this.selectedItems = e;
            },
        },
        async created() {
            this.loading = false;
        },
        async mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            this.loading = false;
           
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>