<template>
    <div class="content-section header">
        <div class="feature-intro">
            <h1><span>{{ router?.title }}Collections</span></h1>
            <p>{{ router?.subtitle }}List of collections.</p>
        </div>
    </div>
    <div class="content-section body">
      <div class="card">
          <form-grid id="CollectionsList"
                         :paged="true"
                     :url="'notavail'"
                     :showAdd="false"
                     :showDispatch="type === 400"
                     :filter="filter" :columns="columns" :autoGenerate="false"
                     :enumFilterValue="type" enumFilterName="collectionstatustype" showEnumFilter="true" :deleteDisplayFields="deleteDisplay" @is-changefilter="changeFilter($event)">
              <pv-grid-column field="customerName" header="Customer" headerStyle="min-width: 15rem" style="min-width: 15rem; text-align: center; object-position: center;">
                  <template #body="slotProps">
                      <div class="p-d-flex p-col-12">
                          <div class="p-grid" style="text-align: center;">
                              <div class="p-col">
                                  {{slotProps.data.customerDisplayName}}
                              </div>
                          </div>
                      </div>
                  </template>
              </pv-grid-column>
              <pv-grid-column field="code" header="Transaction ID" headerStyle="min-width: 15rem" style="min-width: 15rem; text-align: center; object-position: center;">
                  <template #body="slotProps">
                      <div class="p-grid" style="text-align: center;">
                          <div class="p-col">
                              {{slotProps.data.code}}
                          </div>
                      </div>
                  </template>
              </pv-grid-column>
              <pv-grid-column field="name" header="Collection" headerStyle="min-width: 25rem" style="min-width: 25rem; text-align: center; object-position: center; ">
                  <template #body="slotProps">
                      <div class="p-d-flex p-col-12">
                          <div style="width:100%">
                              <div class="p-d-flex p-jc-between" style="z-index: 100;">
                                  <div v-if="slotProps.data?.serviceProviderID">
                                      <h5><span>{{ slotProps.data?.name ?? slotProps.data?.code }}</span></h5>
                                  </div>
                                  <div class="p-as-end" v-if="slotProps.data?.serviceProviderID" style="margin-right:5px;">
                                      <img style="width:35px; height:20px; max-height: 20px; max-width: 35px; object-fit: contain; object-position: top;" :title="`${$vm.$providers.filter((x) => x.id == slotProps.data?.serviceProviderID)[0]?.text}`" :src="`/_assets/_integration/${$vm.$providers.filter((x) => x.id == slotProps.data?.serviceProviderID)[0]?.mask}.png`" />
                                  </div>
                              </div>
                              <div class="p-d-flex">
                                  <div style="margin-right: 5px;"><i class="pi pi-map-marker pi-fw" style="font-size: 0.8rem" /></div>
                                  <div><p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.8rem">{{ slotProps.data.pickup_Company ? slotProps.data.pickup_Company + ',' : null }} {{ slotProps.data.pickup_City ? slotProps.data.pickup_City + ',' : null }} {{ slotProps.data.pickup_CountryName ?? 'United Kingdom'}}</p></div>
                              </div>
                          </div>
                      </div>
                  </template>
              </pv-grid-column>
              <pv-grid-column field="serviceProviderID" header="Schedule" headerStyle="min-width: 25rem" style="min-width: 25rem; text-align: center; object-position: center;">
                  <template #body="slotProps">
                      <div class="p-d-flex p-col-12">
                              <div class="p-d-flex" style="font-size: 0.8rem" v-if="slotProps.data?.serviceProviderID">
                                  <table style="width:100%; margin: -5px auto auto 5px;">
                                      <tr v-if="slotProps.data?.pickup_Date">
                                          <td style=" text-align:left">Pick-up Date</td>
                                          <td width="5">: </td>
                                          <td style="text-align:left"> {{$filters.toDateDisplay(slotProps.data?.pickup_Date)}}</td>
                                      </tr>
                                      <tr v-if="slotProps.data?.pickup_CutOffType">
                                          <td style="text-align:left">CutOff Time</td>
                                          <td width="5">: </td>
                                          <td style="text-align:left"> {{$filters.formatDateWithFormat((new Date().setHours(slotProps.data?.pickup_CutOffType.hours,slotProps.data?.pickup_CutOffType.minutes,0)),"HH:mm")}}</td>
                                      </tr>
                                      <tr v-if="slotProps.data?.pickup_ReadyTimeType">
                                          <td style=" text-align:left">Ready Time</td>
                                          <td width="5">: </td>
                                          <td style="text-align:left"> {{$filters.formatDateWithFormat((new Date().setHours(slotProps.data?.pickup_ReadyTimeType.hours,slotProps.data?.pickup_ReadyTimeType.minutes,0)),"HH:mm")}}</td>
                                      </tr>
                                      <tr v-if="slotProps.data?.pickup_CollectionTimeType">
                                          <td style="text-align:left">Collection Time</td>
                                          <td width="5">: </td>
                                          <td style="text-align:left"> {{$filters.formatDateWithFormat((new Date().setHours(slotProps.data?.pickup_CollectionTimeType.hours,slotProps.data?.pickup_CollectionTimeType.minutes,0)),"HH:mm")}}</td>
                                      </tr>
                                      <tr v-if="slotProps.data?.pickup_ClosingTimeType">
                                          <td style="text-align:left">Closing Time</td>
                                          <td width="5">: </td>
                                          <td style="text-align:left"> {{$filters.formatDateWithFormat((new Date().setHours(slotProps.data?.pickup_ClosingTimeType.hours,slotProps.data?.pickup_ClosingTimeType.minutes,0)),"HH:mm")}}</td>
                                      </tr>
                                      <tr v-if="slotProps.data?.pickup_AfterCloseTimeType">
                                          <td style=" text-align:left">After Close Time</td>
                                          <td width="5">: </td>
                                          <td style="text-align:left"> {{$filters.formatDateWithFormat((new Date().setHours(slotProps.data?.pickup_AfterCloseTimeType.hours,slotProps.data?.pickup_AfterCloseTimeType.minutes,0)),"HH:mm")}}</td>
                                      </tr>
                                  </table>
                              </div>
                      </div>
                  </template>
              </pv-grid-column>
              <pv-grid-column field="serviceProviderID" header="Milestone" headerStyle="min-width: 25rem" style="min-width: 25rem; text-align: center; object-position: center;">
                  <template #body="slotProps">
                      <div class="p-d-flex p-col-12">
                          <div>
                              <div class="p-d-flex" style="font-size: 0.8rem" v-if="slotProps.data?.serviceProviderID">
                                  <table style="width:100%; margin: -5px auto auto 5px;">
                                      <tr v-if="slotProps.data?.milestone_DocumentPrintDate">
                                          <td style="text-align:left">Print Date</td>
                                          <td width="1">: </td>
                                          <td> {{$filters.toDateDisplay(slotProps.data?.milestone_DocumentPrintDate)}}</td>
                                      </tr>
                                      <tr v-if="slotProps.data?.milestone_DispatchDate">
                                          <td style="text-align:left">Print Date</td>
                                          <td width="1">: </td>
                                          <td> {{$filters.toDateDisplay(slotProps.data?.milestone_DispatchDate)}}</td>
                                      </tr>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </template>
              </pv-grid-column>
              <pv-grid-column field="status" header="Status" headerStyle="min-width: 10rem; max-width:13rem;" style="min-width: 10rem; max-width: 13rem; text-align: center; object-position: center;" :frozen="true" :alignFrozen="'right'">
                  <template #body="slotProps">
                      <div class="p-grid" style="max-width: 13rem; text-align: center; align-content:center;">
                          <div><span :class="`customer-badge status-${getStatus(slotProps.data.status)}`">{{$vm.$enums['collectionstatustype'].filter((x) => x.id == slotProps.data.status)[0]?.text}}</span></div>
                      </div>
                  </template>
              </pv-grid-column>
          </form-grid>
        </div>
    </div>
</template>
<script>
  //  import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
/*    import { FilterMatchMode, FilterOperator } from 'primevue/api';*/
    export default {
        name: 'CollectionTemplate',
//        mixins: [mixins.GRID],
        data() {
            return {
                //type: -1,
                filter: {},
                //filter: { 'status': { value: 400, matchMode: FilterMatchMode.EQUALS } },
                columns: [
                    { field: 'code', header: 'Transaction ID', width: 20 },
                    { field: 'name', header: 'Carrier Reference' }
                ],
                deleteDisplay: ["code", "name",],
                enumStatus : null,
            }
        },
        computed: {
            //filter() {
            //    return {
            //        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            //        'status': { operator: FilterOperator.AND, constraints: [{ value: this.enumStatus < 100 ? null : this.enumStatus, matchMode: FilterMatchMode.EQUALS }] },
            //        'warehouseId': { operator: FilterOperator.AND, constraints: [{ value: this.$vm.$appSession.warehouse.id, matchMode: FilterMatchMode.EQUALS }] }
            //        //'status': { value: this.enumStatus < 400 ? null : this.enumStatus, matchMode: FilterMatchMode.EQUALS },
            //        //'warehouseId': { value: this.$vm.$appSession.warehouse.id, matchMode: FilterMatchMode.EQUALS }
            //    };
            //},
            type() { return Number(this.enumStatus ?? this.$router?.currentRoute?._value?.params?.status ??  1); }
        },
        methods: {
            //changeStatus(e) {
            //    this.enumStatus = e;
            //    this.filter = { 'status': { value: this.enumStatus < 400 ? null : this.enumStatus, matchMode: FilterMatchMode.EQUALS } };
            //},
            async changeFilter(e) {
               
                this.enumStatus = e.data.enumFilter;
                //this.filter = { 'status': { value: this.enumStatus < 100 ? null : this.enumStatus, matchMode: FilterMatchMode.EQUALS } };
            },
            getStatus(e) {
                let status = "new";
                switch (e) {
                    case 100:
                        status = "new";
                        break;
                    case 200:
                        status = "proposal";
                        break;
                    case 300:
                        status = "renewal";
                        break;
                    case 400:
                        status = "qualified";
                        break;
                    case 500:
                        status = "new";
                        break;
                    case 600:
                        status = "qualified";
                        break;
                    case 700:
                        status = "qualified";
                        break;
                    default:
                        status = "new";
                        break;
                }
                return status.toLowerCase();
            }
        },
        created() {
           // this.$store.dispatch("addressbook/getAll");  
        },
        //mounted() {

        //},
        async updated() { /*this.setType();*/ }
    }
</script>

<style>
   /* .p-progressbar.p-component.p-progressbar-determinate {
        height: 10px;
        width: 150px;
    }*/
</style>