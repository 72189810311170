// Required Imports
const config = require('@/assets/lib/cosmos/_js/config.js').default;
const axios = require("axios");
// Function Neodynamics Detection Tools
const setib7b = (data) => {
    if (window.chrome) {
        var a = document.getElementById("ib7b");
        a.href = 'webclientprintiv:' + data;
        var evObj = document.createEvent('MouseEvents');
        evObj.initEvent('click', true, true);
        a.dispatchEvent(evObj)
    } else {
        var iframe = document.getElementById("ib7b");
        iframe.src = 'webclientprintiv:' + data;
    }
};
const appVersion = (sid, fncWCPP, wcpp_count) => {
    const wcppPingTimeoutStep_ms = 100;
    const wcppPingTimeout_ms = 1000;
    if (wcpp_count <= wcppPingTimeout_ms) {
        axios.get(`${config.config.endpoint.api}/api/tools/processrequest?sid=${sid}`, { _: Math.random() })
            .then((response) => {
                if (response.data.length > 0) {
                    clearInterval(fncWCPP);
                    detectionSuccess(response.data);
                }
            });
        wcpp_count += wcppPingTimeoutStep_ms;
    } else {
        clearInterval(fncWCPP);
        detectionFailed();
    }
};
const detectionSuccess = (data) => {
    sessionStorage.setItem("wcpp_ver", data);
    console.log("Neodynamics is detected successfully.")
};
const detectionFailed = () => {
    // sessionStorage.removeItem("wcpp_ver");
    console.log("Neodynamics is not detected! Download Here: https://ics-files.integro360.com/kbase/wcpp-4.0.18.109-win.exe")
};
// Exportable Methods
const init = () => {
    const body = document.getElementsByTagName("body")[0];
    if (window.chrome) {
        const a = document.createElement("a");
        a.id = "ib7b";
        a.name = "ib7b";
        body.appendChild(a);
    }
    else {
        const iframe = document.createElement("iframe");
        iframe.name = 'ib7b';
        iframe.id = 'ib7b';
        iframe.width = '1';
        iframe.height = '1';
        iframe.style = 'visibility:hidden;position:absolute';
        body.appendChild(iframe);
    }
};
const ping = (sid) => {
    const wcppPingTimeoutStep_ms = 100;
    const wcppPingDelay_ms = 5000;
    setib7b(`${config.config.endpoint.api}/api/tools/processrequest?sid=${sid}&wcppping`);
    var delay_ms = (typeof wcppPingDelay_ms === 'undefined') ? 0 : wcppPingDelay_ms;
    if (delay_ms > 0) {
        setTimeout(function () {
            axios.get(`${config.config.endpoint.api}/api/tools/processrequest?sid=${sid}`, { _: Math.random() })
                .then((response) => {
                    if (response.data.length > 0) {
                        detectionSuccess(response.data);
                    } else {
                        detectionFailed();
                    }
                });
        }, delay_ms);
    } else {
        var fncWCPP = setInterval(appVersion, wcppPingTimeoutStep_ms);
        var wcpp_count = 0;
        appVersion(sid, fncWCPP, wcpp_count);
    }
}

// Function Neodynamics Web Client Print
const setA = (data) => {
    const body = document.getElementsByTagName("body")[0];
    var e_id = 'id_' + new Date().getTime();
    if (window.chrome) {
        const link = document.createElement("a");
        link.id = e_id;
        link.name = e_id;
        body.appendChild(link);

        var a = document.getElementById(e_id);
        a.href = 'webclientprintiv:' + data;
        var evObj = document.createEvent('MouseEvents');
        evObj.initEvent('click', true, true);
        a.dispatchEvent(evObj)
    }
    else {
        const frame = document.createElement("iframe");
        frame.name = e_id;
        frame.id = e_id;
        frame.width = '1';
        frame.height = '1';
        frame.style = 'visibility:hidden;position:absolute';
        body.appendChild(frame);

        var iframe = document.getElementById(e_id);
        iframe.src = 'webclientprintiv:' + data;
    }
    setTimeout(function () {
        document.getElementById(e_id).remove();
    }, 5000);
};
const clientPrinters = (sid, fncGetPrinters, wcpp_count) => {
    const wcppGetPrintersTimeoutStep_ms = 10;
    const wcppGetPrintersTimeout_ms = 1000;
    if (wcpp_count <= wcppGetPrintersTimeout_ms) {
        axios.get(`${config.config.endpoint.api}/api/tools/processrequest?getPrinters&sid=${sid}`, { _: Math.random() })
            .then((response) => {
                if (response.data.length > 0) {
                    clearInterval(fncGetPrinters);
                    printerSuccess(response.data);
                }
            });
        wcpp_count += wcppGetPrintersTimeoutStep_ms;
    } else {
        clearInterval(fncGetPrinters);
        printerFailed();
    }
};
const clientPrintersInfo = (sid, fncGetPrintersInfo, wcpp_count) => {
    const wcppGetPrintersTimeoutStep_ms = 10;
    const wcppGetPrintersTimeout_ms = 5000;
    if (wcpp_count <= wcppGetPrintersTimeout_ms) {
        axios.get(`${config.config.endpoint.api}/api/tools/processrequest?getPrintersInfo&sid=${sid}`, { _: Math.random() })
            .then((response) => {
                if (response.data.length > 0) {
                    clearInterval(fncGetPrintersInfo);
                    printerSuccess(response.data);
                }
            });
        wcpp_count += wcppGetPrintersTimeoutStep_ms;
    } else {
        clearInterval(fncGetPrintersInfo);
        printerFailed();
    }
};
const clientVersion = (sid, fncWCPP, wcpp_count) => {
    const wcppGetVerTimeoutStep_ms = 10;
    const wcppGetVerTimeout_ms = 5000;
    if (wcpp_count <= wcppGetVerTimeout_ms) {
        axios.get(`${config.config.endpoint.api}/api/tools/processrequest?getWcppVersion&sid=${sid}`, { _: Math.random() })
            .then((response) => {
                if (response.data.length > 0) {
                    clearInterval(fncWCPP);
                    versionSuccess(response.data);
                }
            });
        wcpp_count += wcppGetVerTimeoutStep_ms;
    }
    else {
        clearInterval(fncWCPP);
        versionFailed()
    }
};
const printerSuccess = (data) => {
    console.log("Neodynamics: Printers detected successfully.");
    sessionStorage.setItem("wcpp_printers", btoa('Use Default Printer|Use Printer Dialog|' + data));
    var printers = ('Use Default Printer|Use Printer Dialog|' + data).split('|');
    var printerList = [];
    for (var i = 0; i < printers.length; i++)
        printerList.push({ id: i + 1, name: printers[i], text: printers[i] });
    return printerList;
};
const printerFailed = (data) => {
    console.log("Neodynamics: Unable to detect printers. " + data);
    sessionStorage.removeItem("wcpp_printers");
    return [];
};
const versionSuccess = (data) => {
    console.log(data);
    console.log("succes");
};
const versionFailed = (data) => {
    console.log(data);
    console.log("failed");
};
// Exportable Methods
const print = (data) => {
    setA(`${config.config.endpoint.api}/api/tools/printfile?clientPrint&` + data);
}
const printers = (sid) => {
    const wcppGetPrintersDelay_ms = 1000;
    const wcppGetPrintersTimeoutStep_ms = 1000;
    setA(`-getPrinters:${config.config.endpoint.api}/api/tools/processrequest?sid=${sid}`);
    var delay_ms = (typeof wcppGetPrintersDelay_ms === 'undefined') ? 0 : wcppGetPrintersDelay_ms;
    if (delay_ms > 0) {
        return new Promise((resolve) => {
            setTimeout(function () {
                resolve(axios.get(`${config.config.endpoint.api}/api/tools/processrequest?getPrinters&sid=${sid}`).then((response) => {
                    if (response.data.length > 0)
                        return printerSuccess(response.data);
                    else
                        return detectionFailed(response);
                }));
            }, delay_ms);
        });
    }
    else {
        var fncGetPrinters = setInterval(clientPrinters, wcppGetPrintersTimeoutStep_ms);
        var wcpp_count = 0;
        clientPrinters(sid, fncGetPrinters, wcpp_count);
    }
};
const printersInfo = (sid) => {
    const wcppGetPrintersDelay_ms = 1000;
    const wcppGetPrintersTimeoutStep_ms = 1000;
    setA(`-getPrintersInfo:${config.config.endpoint.api}/api/tools/processrequest?sid=${sid}`);
    var delay_ms = (typeof wcppGetPrintersDelay_ms === 'undefined') ? 0 : wcppGetPrintersDelay_ms;
    if (delay_ms > 0) {
        return new Promise((resolve) => {
            setTimeout(function () {
                resolve(axios(`${config.config.endpoint.api}/api/tools/processrequest?getPrintersInfo&sid=${sid}`)
                    .then((response) => {
                        if (response.data.length > 0)
                            return printerSuccess(response.data);
                        else
                            return printerFailed(response);
                    }));
            }, delay_ms);
        });
    }
    else {
        var fncGetPrintersInfo = setInterval(clientPrintersInfo, wcppGetPrintersTimeoutStep_ms);
        var wcpp_count = 0;
        clientPrintersInfo(sid, fncGetPrintersInfo, wcpp_count);
    }
};
const version = (sid) => {
    const wcppGetVerTimeoutStep_ms = 10;
    const wcppGetVerDelay_ms = 5000;
    setA(`-getWcppVersion:${config.config.endpoint.api}/api/tools/processrequest?sid=${sid}`);
    var delay_ms = (typeof wcppGetVerDelay_ms === 'undefined') ? 0 : wcppGetVerDelay_ms;
    if (delay_ms > 0) {
        return new Promise((resolve) => {
            setTimeout(function () {
                resolve(axios(`${config.config.endpoint.api}/api/tools/processrequest?getWcppVersion&sid=${sid}`)
                    .then((response) => {
                        if (response.data.length > 0) {
                            versionSuccess(response.data);
                            return response.data;
                        }
                        else {
                            versionFailed(response);
                            return null;
                        }
                    }));
            }, delay_ms);
        });
    }
    else {
        var fncWCPP = setInterval(clientVersion, wcppGetVerTimeoutStep_ms);
        var wcpp_count = 0;
        clientVersion(sid, fncWCPP, wcpp_count);
    }
};

export default { init, ping, print, printers, printersInfo, version };
