<template>

    <pv-grid id="reportGrid" sourceType="array" :value="source?.data ?? []"
             :responsive="'scroll'"
             :totalRecords="(source?.data ?? []).length ?? 0"
             :paginator="true"
             responsiveLayout="scroll"
             :paginatorPosition="'bottom'"
             :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport'"
             :pageLinkSize="5"
             :rows="10"
             :rowsPerPageOptions="[5, 10, 15, 25, 50, 100]"
             :alwaysShowPaginator="true"
             :currentPageReportTemplate="'Showing {first} - {last} of {totalRecords} records'"
             :loading="activity.loading"
             autoLayout
             showGridlines
             stripedRows>
        <pv-grid-column-group type="header">
            <pv-grid-row>
                <pv-grid-column :rowspan="2" frozen />
                <pv-grid-column header="WAREHOUSE" :rowspan="2" frozen />
                <pv-grid-column header="CUSTOMER" :rowspan="2" frozen />
                <pv-grid-column header="STATUS" :rowspan="2" />
                <pv-grid-column header="CONSIGNMENT" :colspan="8" />
                <pv-grid-column header="TOTAL" />
                <pv-grid-column header="SHIPPING CARRIER" :colspan="2" />
                <pv-grid-column header="RETURN CARRIER" :colspan="2" />

                <pv-grid-column header="COLLECTION" :colspan="2" />
                <pv-grid-column header="MANIFEST" />
                <pv-grid-column header="INVOICE" :colspan="2" />
                <pv-grid-column header="MILESTONES" :colspan="8" />
            </pv-grid-row>
            <pv-grid-row>
                <pv-grid-column header="TRANSACTION ID" />
                <pv-grid-column header="REFERENCE 1" />
                <pv-grid-column header="REFERENCE 2" />
                <pv-grid-column header="REFERENCE 3" />
                <pv-grid-column header="REFERENCE 4" />
                <pv-grid-column header="REFERENCE 5" />
                <pv-grid-column header="REFERENCE 6" />
                <pv-grid-column header="REFERENCE 7" />
                <pv-grid-column header="PACKAGES" />

                <pv-grid-column header="REFERENCE" />
                <pv-grid-column header="PACKAGE REFERENCE" />

                <pv-grid-column header="REFERENCE" />
                <pv-grid-column header="PACKAGE REFERENCE" />

                <pv-grid-column header="TRANSACTION ID" />
                <pv-grid-column header="REFERENCE" />

                <pv-grid-column header="LINNWORKS" />

                <pv-grid-column header="TRANSACTION ID" />
                <pv-grid-column header="REFERENCE" />

                <pv-grid-column header="CREATE DATE" />
                <pv-grid-column header="ALLOCATE DATE" />
                <pv-grid-column header="LABEL PRINT DATE" />
                <pv-grid-column header="MANIFEST DATE" />
                <pv-grid-column header="DISPATCH DATE" />
                <pv-grid-column header="OUT FOR DELIVERY" />
                <pv-grid-column header="POD DATE" />
                <pv-grid-column header="ARCHIVE DATE" />
            </pv-grid-row>
        </pv-grid-column-group>
        <pv-grid-column field="mask" style="`min-width: 1rem;border-left: 1px solid var(--surface-d); object-position: center; padding: 0 5px;`" frozen>
            <template #body="slotProps">
                <pv-button v-if="slotProps.data.EnableLinks" icon="pi pi-clone" iconPos="right" @click="{ this.dialog.clone = true; this.clone.mask = slotProps.data?.Consignment.Mask;}" class="p-button-info" title="Clone" v-tooltip.top="{value: 'Clone Consignment'}" />
                <pv-splitbutton v-if="slotProps.data.EnableLinks && (slotProps.data?.ShippingInfo?.ProviderMask && slotProps.data?.ShippingInfo?.CarrierReference) || (slotProps.data?.ReturnInfo?.ProviderMask && slotProps.data?.ReturnInfo?.CarrierReference)" icon="fas fa-print" @click="onItems(slotProps.data)[0]?.command()" :model="onItems(slotProps.data)" style="margin-left: 0.25rem;" v-tooltip.top="{value: 'Print Labels'}"></pv-splitbutton>
            </template>
        </pv-grid-column>
        <pv-grid-column field="Warehouse.Name" header="Warehouse" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" frozen />
        <pv-grid-column field="Customer.Name" header="Customer" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" frozen />
        <pv-grid-column field="Consignment.Status" header="Status" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="Consignment.TransactionID" header="Transaction ID" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <router-link v-if="slotProps.data.EnableLinks" :to="{ name: 'consignment-track', params: { mask: slotProps.data.Consignment.TransactionID  }}" style="font-weight:bold">{{slotProps.data.Consignment.TransactionID}}</router-link>
                    <div v-else style="font-weight:bold" v-tooltip.bottom="'Consignment is not in the current warehouse.'">{{slotProps.data.Consignment.TransactionID}}</div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="Consignment.Reference1" header="Reference 1" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <router-link v-if="slotProps.data.EnableLinks" :to="{ name: 'consignment-code-edit', params: { code: slotProps.data.Consignment.TransactionID  }}" style="font-weight:bold">{{slotProps.data.Consignment.Reference1 }}</router-link>
                    <div v-else style="font-weight:bold" v-tooltip.bottom="'Consignment is not in the current warehouse.'">{{slotProps.data.Consignment.Reference1 }}</div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="Consignment.Reference2" header="Reference 2" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="Consignment.Reference3" header="Reference 3" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="Consignment.Reference4" header="Reference 4" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="Consignment.Reference5" header="Reference 5" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="Consignment.Reference6" header="Reference 6" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="Consignment.Reference7" header="Reference 7" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="PackageCount" header="Package Count" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; text-align:right; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />

        <pv-grid-column field="ShippingInfo.CarrierReference" header="Carrier Reference" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <img v-if="slotProps?.data?.ShippingInfo?.ProviderMask && slotProps?.data?.ShippingInfo?.CarrierReference" :src="`/_assets/_integration/${slotProps.data.ShippingInfo.ProviderMask}.png`" style="max-height: 2rem; max-width: 2rem; object-fit: contain; margin: -0.5rem 0.75rem -0.5rem 0rem;" />
                    <router-link v-if="false" :to="{ name: 'consignment-code-edit', params: { code: slotProps.data.ShippingInfo.CarrierReference  }}" style="font-weight:bold">{{slotProps.data.ShippingInfo.CarrierReference }}</router-link>
                    <div v-else style="font-weight:bold">{{slotProps.data.ShippingInfo.CarrierReference }}</div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="ShippingInfo.PackageCarrierReference" header="Package References" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <div v-if="packageRefCount(slotProps.data.ShippingInfo.PackageCarrierReference) > 1" style="font-weight:bold;">
                        <div :id="`shipping_${slotProps.data.Consignment.TransactionID}_Button`" @click="onToggleView(`shipping_${slotProps.data.Consignment.TransactionID}`)" data-show="false"><i class='fa fa-square-plus fa-fw'></i> <a>Multiple References</a></div>
                        <div :id="`shipping_${slotProps.data.Consignment.TransactionID}_List`" style="display: none; padding-left: 1.5rem;">
                            <div v-for="reference in packageRefList(slotProps.data.ShippingInfo.PackageCarrierReference)" :key="reference">{{ reference }}</div>
                        </div>
                    </div>
                    <div v-else style="font-weight:bold">{{slotProps.data.ShippingInfo.CarrierReference }}</div>
                    <!--<router-link :to="{ name: 'consignment-code-edit', params: { code: slotProps.data.Consignment.TransactionID  }}" style="font-weight:bold">{{slotProps.data.ShippingInfo.PackageCarrierReference }}</router-link>-->
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="ReturnInfo.CarrierReference" header="Carrier Reference" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0; font-weight: bold;">
                    <img v-if="slotProps?.data?.ReturnInfo?.ProviderMask && slotProps?.data?.ReturnInfo?.CarrierReference" :src="`/_assets/_integration/${slotProps.data.ReturnInfo.ProviderMask}.png`" style="max-height: 2rem; max-width: 2rem; object-fit: contain; margin: -0.5rem 0.75rem -0.5rem 0rem;" />
                    {{slotProps.data.ReturnInfo.CarrierReference }}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="ReturnInfo.PackageCarrierReference" header="Package References" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0; font-weight: bold;">
                    <div v-if="packageRefCount(slotProps.data.ReturnInfo.PackageCarrierReference) > 1" style="font-weight:bold;">
                        <div :id="`return_${slotProps.data.Consignment.TransactionID}_Button`" @click="onToggleView(`return_${slotProps.data.Consignment.TransactionID}`)" data-show="false"><i class='fa fa-square-plus fa-fw'></i> <a>Multiple References</a></div>
                        <div :id="`return_${slotProps.data.Consignment.TransactionID}_List`" style="display: none; padding-left: 1.5rem;">
                            <div v-for="reference in packageRefList(slotProps.data.ReturnInfo.PackageCarrierReference)" :key="reference">{{ reference }}</div>
                        </div>
                    </div>
                    <div v-else style="font-weight:bold">{{slotProps.data.ReturnInfo.CarrierReference }}</div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="Collection.TransactionID" header="Transaction ID" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <router-link v-if="slotProps.data.EnableLinks" :to="{ name: 'collection-edit', params: { mask: slotProps.data.Collection.Mask  }}" style="font-weight:bold">{{slotProps.data.Collection.TransactionID }}</router-link>
                    <div v-else style="font-weight:bold" v-tooltip.bottom="'Collection is not in the current warehouse.'">{{slotProps.data.Collection.TransactionID }}</div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="Collection.CarrierReference" header="Carrier Reference" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <router-link v-if="slotProps.data.EnableLinks" :to="{ name: 'collection-edit', params: { mask: slotProps.data.Collection.Mask  }}" style="font-weight:bold">{{slotProps.data.Collection.CarrierReference }}</router-link>
                    <div v-else style="font-weight:bold" v-tooltip.bottom="'Collection is not in the current warehouse.'">{{slotProps.data.Collection.CarrierReference }}</div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="LinnworksManifest.TransactionID" header="Linnworks Manifest" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="Invoice.TransactionID" header="TransactionID" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <router-link v-if="slotProps.data.InvoiceEnableLinks" :to="{ name: 'invoice-edit', params: { mask: slotProps.data.Invoice.Mask  }}" style="font-weight:bold">{{slotProps.data.Invoice.TransactionID}}</router-link>
                    <div v-else style="font-weight:bold" v-tooltip.bottom="'Current User does not have access to invoice facility.'">{{slotProps.data.Invoice.TransactionID }}</div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="Invoice.CustomerReference" header="Customer Reference" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <router-link v-if="slotProps.data.InvoiceEnableLinks" :to="{ name: 'invoice-edit', params: { mask: slotProps.data.Invoice.Mask  }}" style="font-weight:bold">{{slotProps.data.Invoice.CustomerReference}}</router-link>
                    <div v-else style="font-weight:bold" v-tooltip.bottom="'Current User does not have access to invoice facility.'">{{slotProps.data.Invoice.CustomerReference }}</div>
                </div>
            </template>
        </pv-grid-column>

        <pv-grid-column field="Milestones.CreateDate" header="Create Date" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{  $filters.toDate24HTimeDisplay(slotProps.data?.Milestones.CreateDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="Milestones.AllocatedDate" header="Allocated Date" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{  $filters.toDate24HTimeDisplay(slotProps.data?.Milestones.AllocatedDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="Milestones.LabelPrintDate" header="Label Print Date" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{  $filters.toDate24HTimeDisplay(slotProps.data?.Milestones.LabelPrintDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="Milestones.ManifestDate" header="Manifest Date" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{  $filters.toDate24HTimeDisplay(slotProps.data?.Milestones.ManifestDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="Milestones.DispatchDate" header="Dispatch Date" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{  $filters.toDate24HTimeDisplay(slotProps.data?.Milestones.DispatchDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="Milestones.OutForDeliveryDate" header="Out For Delivery Date" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{  $filters.toDate24HTimeDisplay(slotProps.data?.Milestones.OutForDeliveryDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="Milestones.PODDate" header="POD Date" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{  $filters.toDate24HTimeDisplay(slotProps.data?.Milestones.PODDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="Milestones.ArchivedDate" header="Archive Date" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{  $filters.toDate24HTimeDisplay(slotProps.data?.Milestones.ArchivedDate)}}
                </div>
            </template>
        </pv-grid-column>
        <!-- Row Messages -->
        <template #empty>
            <span v-html="`${(filterKey === null ? 'Click &lt;b&gt;Apply Filter&lt;/b&gt; button to generate result.' : 'No records found.')}`"></span>
        </template>
        <template #loading>
            Retrieving records from database. Please wait...
        </template>
        <template #paginatorLeft>
            <pv-button type="button" icon="pi pi-download" class="p-button-text" @click="onDownload" />
        </template>
        <template #paginatorRight>
            <pv-button type="button" icon="pi pi-refresh" class="p-button-text" @click="getSource" />
        </template>
    </pv-grid>
    <!-- Operation Dialog -->
    <is-dialog header="Clone Record?" :visible="dialog.clone" @is-confirm="confirmClone" @is-cancel="{this.dialog.clone = false; this.clone = { code: null, name: null};}" :confirmDisabled="(clone?.name?.length ?? 0) === 0">
        <div class="confirmation-content p-d-flex">
            <div class="p-col-12">
                <form-input id="name" @keyup="onClone_KeyupEvent" label="Reference" :required="true" :v$="v$" :value="clone.name" @is-sync="clone.name = $event.value" placeholder="Reference" v-focus :max-lenght="20" />
            </div>
        </div>
    </is-dialog>
    <print-form :formData="dialog?.print?.model" :isBatchPrint="dialog?.print?.isBatch" :foreignkeyid="foreignkeyid" :selectedItems="selectedItems ?? source.data ?? []" :showDialog="dialog.print.show" :documentType="dialog.print.type" @is-confirm="{dialog.print.show = false; dialog.print.model = null; dialog.print.type = ''; dialog.print.isBatch = false;   this.$nextTick(() => {
                        if (this.statusFilter === 200)
                        {
                            this.selectedItems = [];
                            this.selectedReset = true;
                            this.onRefresh();
                        }
                        });
                    }" @is-cancel="{dialog.print.show = false; dialog.print.model = null; dialog.print.type = '';}"></print-form>
</template>
<script>
    import { mapGetters } from 'vuex';
    import { useVuelidate } from '@vuelidate/core';
    import printform from '@/components/transaction/tms/consignment/record/print-form.vue';
    export default {
        name: 'reportGrid',
        setup: () => ({ v$: useVuelidate() }),
        props: {
            formData: { type: Object },
            filterKey: { type: Number, default: null },
            isAdmin: { type: Boolean, default: false }
        },
        components: {
            'print-form': printform
        },
        data() {
            return {
                type: -1,
                filter: {},
                columns: [],
                deleteDisplay: ["code", "name"],
                searchFields: ["code", "name"],
                activity: { loading: true, download: false},
                source: {
                    total: 0,
                    data: []
                },
                modelState: null,
                initialBind: false,
                dialog: {
                    print: {
                        show: false,
                        type: '',
                        model: null
                    },
                    clone: false
                },
                clone: {
                    mask: null,
                    code: null,
                    name: null
                }
            }
        },
        computed: {
            ...mapGetters({ user: "auth/currentUser" }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            apiData() {
                return {
                    SearchText: this.formData?.searchText?.trim(),
                    IncludeArchive: this.formData?.includeArchived ?? false,
                    Warehouse: this.formData.warehouse,
                    WarehouseID: this.formData.warehouseID,
                    Customer: this.formData.customer,
                    CustomerID: this.formData.customerID,
                    CreateDate: {
                        From: this.$filters.formatDateWithFormat(this.formData.createDateFrom ?? new Date(), "YYYY-MM-DD"),
                        To: this.$filters.formatDateWithFormat(this.formData.createDateTo ?? new Date(), "YYYY-MM-DD")
                    },
                    AllocateDate: {
                        From: this.formData.allocateDateFrom !== undefined && this.formData.allocateDateFrom !== null ? this.$filters.formatDateWithFormat(this.formData.allocateDateFrom ?? new Date(), "YYYY-MM-DD") : null,
                        To: this.formData.allocateDateTo !== undefined && this.formData.allocateDateTo !== null ? this.$filters.formatDateWithFormat(this.formData.allocateDateTo ?? new Date(), "YYYY-MM-DD") : null
                    }
                }
            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            'filterKey': {
                handler() {
                    this.$nextTick(() => {
                    this.getSource();
                    });
                }, deep: true
            },
            activity: {
                handler() {
                    this.$emit('is-sync', this.activity);
                }, deep: true
            }
        },
        methods: {
            onItems(data) {
                var items = [{
                    label: 'Label',
                    icon: 'fas fa-file fa-fw',
                    command: () => {
                        this.onPrintLabel(data, 'label')
                    }
                }]
                if (data?.ReturnInfo?.ProviderMask && data?.ReturnInfo?.CarrierReference && (data?.HasReturnLabel ?? false))
                    items.push(
                        {
                            label: 'Return Label',
                            icon: 'fas fa-file fa-fw',
                            command: () => {
                                this.onPrintLabel(data, 'return')
                            }
                        }
                    );
                return items;
            },
            onToggleView(obj) {
                var button = document.getElementById(`${obj}_Button`);
                var list = document.getElementById(`${obj}_List`);
                var status = button.getAttribute("data-show");
                if (status == "true") {
                    list.style.display = "none";
                    button.setAttribute("data-show", false);
                    button.innerHTML = "<i class='fa fa-square-plus  fa-fw'></i> <a>Multiple References</a>";
                }
                else {
                    list.style.display = "block";
                    button.setAttribute("data-show", true);
                    button.innerHTML = "<i class='fa fa-square-minus  fa-fw'></i> <a>Hide References</a>";
                }
            },
            async getSource() {
                this.$nextTick(() => { this.activity.loading = true; });
                this.$nextTick(() => {
                    this.$axios.post(this.$config.config.endpoint.api + `/services/tms/consignment/search`.replaceAll("//", "/"), this.apiData)
                        .then((response) => {
                            this.source.data = response?.data?.result ?? [];
                            this.source.total = this.source.data.length;
                            this.$nextTick(() => { this.activity.loading = false; });
                        })
                        .catch((ex) => {
                            this.source.data = [];
                            this.source.total = 0;
                            console.log(ex);
                            this.$nextTick(() => { this.activity.loading = false; });
                        });
                });

            },
            packageRefCount(reference) {
                return (reference ?? "").split(',').filter(x => x != '').length;
            },
            packageRefList(reference) {
                return (reference ?? "").split(',').filter(x => x != '');
            },
            onPrintLabel(e, type) {
                this.dialog.print.model = {
                    mask: e.Consignment.Mask,
                    code: e.Consignment.TransactionID
                };
                this.dialog.print.show = true;
                this.dialog.print.type = type ?? 'label';
            },
            async cloneRecord(mask, model) { return await this.$store.dispatch("consignment/cloneRecord", { mask: mask, record: model }); },
            onClone_KeyupEvent(e) {
                if (e.keyCode === 13 && (this.clone?.name?.length ?? 0) > 0) {
                    this.confirmClone();
                }
                if (e.keyCode === 27) {
                    { this.dialog.clone = false; this.clone = { code: null, name: null }; }
                }
            },
            confirmClone() {
                this.cloneRecord(this.clone.mask, this.clone).then((response) => {
                    if (response.errors) {
                        this.$toast.add({ severity: 'error', summary: 'Record Clone Failed!', detail: response.errors[0].message, life: 3000 });
                        this.dialog.clone = false;
                        this.clone = {
                            mask: null,
                            code: null,
                            name: null
                        };
                    }
                    else {
                        if (response?.data?.result ?? response.result) {
                            this.$router.push({ name: 'consignment-edit', params: { mask: (response?.data?.result ?? response.result ?? this.record)?.mask } });
                            this.$toast.add({ severity: 'success', summary: 'Record Clone Success!', detail: 'The record was cloned successfully. <br/> For International consignments please make sure to change details for the following: <br/> <ul><li>Invoice Details</li><li>Customs Details</li></ul>', life: 20000 });
                        }
                        else {
                            this.$toast.add({ severity: 'error', summary: 'Record Clone Failed!', detail: 'Not Allowed', life: 3000 });
                        }
                        this.dialog.clone = false;
                        this.clone = {
                            mask: null,
                            code: null,
                            name: null
                        };
                    }
                }).catch(() => {
                    this.$toast.add({ severity: 'error', summary: 'Record Clone Failed!', detail: 'Record Clone Failed', life: 3000 });
                });
                this.dialog.clone = false
            },
        },
        async created() {
            this.activity.loading = false;
        },
        async mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            this.loading = false;
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>