<template>
    <form-wrapper title="Audit Logs" sub-title="List of all api and carrier logs.">
        <auditlog-grid></auditlog-grid>
    </form-wrapper>
</template>
<script>
    import auditloggrid from "@/components/facility/auditlogs/auditlog-grid.vue";
    export default {
        name: "ZoneIndex",
        components: {
            'auditlog-grid': auditloggrid 
        }
        //data() {
        //    return {
        //        timeintransit: [],
        //        expandedRows: [],
        //        expandedRows2: [],
        //        columns: [
        //            { field: 'provider.name', header: 'Provider', width: 20 },
        //            { field: 'code', header: 'Code', width: 20 },
        //            { field: 'name', header: 'Name' }
        //        ],
        //    }
        //},
        //computed: {
        //    dsTimeInTransit() { return this.timeintransit; },
        //},
        //watch: {
        //    integrationMask() {
        //        this.getSource();
        //    }
        //},
        //methods: {
        //    expandAll() {
        //        this.expandedRows = this.dsTimeInTransit.filter(p => p.provider.id);
        //        let rows = [];
        //        this.dsTimeInTransit.forEach(p => {
        //            p.products.filter(j => j.product.id).forEach(r => {
        //                rows.push(r);
        //            });
        //        });
        //        this.$nextTick(() => { this.expandedRows2 = rows; });
        //        this.$nextTick(() => { this.expandedRows2 = rows; });
        //    },
        //    collapseAll() {
        //        this.expandedRows = null;
        //        this.expandedRows2 = null;
        //    },
        //    getSource() {
        //        this.$store.dispatch("providertimeintransit/getURL", { url: `/services/settings/serviceprovidertimeintransit` }).then((response) => {
        //            if (response && response.success) {
        //                let ds = response.result;
        //                let items = [];
        //                let providers = [... new Set(ds.map(({ ...rest }) => { return `[${rest.provider.code}] ${rest.provider.name}`; }))];
        //                providers.forEach((data) => {
        //                    let productDS = ds.filter(x => `[${x.provider.code}] ${x.provider.name}` === data);
        //                    let item = { provider: productDS[0].provider, products: null };
        //                    let products = [... new Set(productDS.map(({ ...rest }) => { return `[${rest.providerProduct.code}] ${rest.providerProduct.name}`; }))];
        //                    let productItems = [];
        //                    products.forEach((product) => {
        //                        let leadtimeDS = ds.filter(x => `[${x.provider.code}] ${x.provider.name}` === data
        //                            && `[${x.providerProduct.code}] ${x.providerProduct.name}` === product
        //                        );
        //                        productItems.push({ product: leadtimeDS[0].providerProduct, leadtime: leadtimeDS });
        //                    })
        //                    item.products = productItems;
        //                    items.push(item);
        //                });
        //                this.timeintransit = items;
        //            }
        //        }).catch((ex) => console.log(ex));
        //    }
        //},
        //created() {
        //    this.getSource();
        //}
    }
</script>