<template>
    <form-wrapper :title="router.title" :sub-title="router.subtitle">
        <cosmos-grid id="ServiceGroupList" sourceType="storename" source="servicegroup" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showExport="false" :baseRecordID="0">
            <pv-grid-column field="customerID" header="Customer" style="min-width: 100%; max-width: 100%; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
                <template #body="slotProps">
                    <div class="p-d-flex">
                        <div style="width:100%">
                            <div class="p-d-flex p-jc-between" style="min-width: 10rem; margin-bottom: 5px;">
                                <div><h5 style="margin-bottom: 5px; margin-top: 0;"><span>{{companyDS.filter(x => x.id == slotProps.data.customerID)[0]?.text}} </span></h5></div>
                            </div>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="code" header="Details" style="min-width: 100%; max-width: 100%; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
                <template #body="slotProps">
                    <div class="p-d-flex">
                        <div style="width:100%">
                            <div class="p-d-flex p-jc-between" style="min-width: 35rem; margin-bottom: 5px;">
                                <div><h5 style="margin-bottom: 5px; margin-top: 0;"><span>{{ slotProps.data.name}} </span></h5></div>
                                <div class="p-as-end"><h5 class="p-code-text" style="margin-bottom:0">[{{ slotProps.data.code}}]</h5></div>
                            </div>
                            <div class="p-grid p-d-flex">
                                <div style="margin-left:0.5rem;"><p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.8rem">{{ slotProps.data.description }}</p></div>
                            </div>
                        </div>
                        
                    </div>
                </template>
            </pv-grid-column>
        </cosmos-grid>
    </form-wrapper>


</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/mixin.js';
    export default {
        name: 'ServiceGroupTemplate',
        mixins: [mixins.GRID],
        data() {
            return {
                type: -1,
                filter: {},
                columns: [],
                deleteDisplay: ["code", "name"],
                searchFields: ["code", "name","description"],

            }
        },
        computed: {
            source() {
                return this.$vm.$enums["module"];
            },
            companyDS() { return this.$store.getters["company/ds"]?.data; },
            //customerDS() { return this.companyDS.data.filter((x) => x.type === 16); }
        },
        methods: {
            async getCompanyList() {
                return this.$store.dispatch("company/getAll", {sort:null,where:null});
            },
            modules(slotProps) {
                return this.source.filter(x => this.$filters.getFlags(slotProps.data.modules).indexOf(x.id) !== -1).map(x => '<div class="p-chip" style="padding: 2px 10px; border-radius: 4px;">' + x.text + '</div>').join(', ');
            }
        },
        async created() {
            await this.getCompanyList();
        },
        async mounted() {
            await this.getCompanyList();
        },
        async updated() { /*this.setType();*/ }
    }
</script>
