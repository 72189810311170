<template>
    <div class="content-section header">
        <div class="feature-intro">
            <h1><span>Quote Consignment</span></h1>
            <p>Get pre-qualifed and quoted carrier and service.</p>
        </div>
    </div>
    <div class="content-section body" style="min-height: calc(100vh - 200px);">
        <div class="card" style="padding: 0;">
            <pv-accordion :multiple="true" :activeIndex="allActiveIndex" id="QuoteAccordion" @tab-open="onOpen" @tab-close="onClose">
                <pv-accordion-tab>
                    <template #header>
                        <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                            <div class="p-col-fixed p-as-start"><i class="pi pi-bars"></i></div>
                            <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                                <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; QUOTATION DETAILS</div>
                            </div>
                        </div>
                    </template>
                    <div class="p-grid">
                        <div class="p-col-12 p-md-2">
                            <h6>Collection</h6>
                            <form-lookup id="shipFromCountry" label="Country" source="country" @is-sync="{ model.consignment[0].shipFrom_CountryID = $event.value; onCountryChange('shipFrom', $event.record); }" :value="model.consignment[0].shipFrom_CountryID" :required="true"></form-lookup>
                            <form-input id="shipFromPostalCode" :label="labels.shipFrom.postalCode" :value="model.consignment[0].shipFrom_PostalCode" @is-sync="model.consignment[0].shipFrom_PostalCode = $event.value;" />
                            <form-input id="shipFromCity" :label="labels.shipFrom.city" :value="model.consignment[0].shipFrom_City" @is-sync="model.consignment[0].shipFrom_City = $event.value;" />
                            <form-input id="shipFromState" :label="labels.shipFrom.state" :value="model.consignment[0].shipFrom_State" @is-sync="model.consignment[0].shipFrom_State = $event.value;" />
                            <form-lookup id="shipFromEstablishmentType" label="Establishment Type" type="enum" source="establishmenttype" @is-sync="model.consignment[0].shipFrom_EstablishmentType = $event.value" :value="model.consignment[0].shipFrom_EstablishmentType" :required="true"></form-lookup>
                        </div>
                        <div class="p-col-12 p-md-2">
                            <h6>Delivery</h6>
                            <form-lookup id="shipToCountry" label="Country" source="country" @is-sync="{ model.consignment[0].shipTo_CountryID = $event.value; onCountryChange('shipTo', $event.record); }" :value="model.consignment[0].shipTo_CountryID" :required="true"></form-lookup>
                            <form-input id="shipToPostalCode" :label="labels.shipTo.postalCode" :value="model.consignment[0].shipTo_PostalCode" @is-sync="model.consignment[0].shipTo_PostalCode = $event.value;" />
                            <form-input id="shipToCity" :label="labels.shipTo.city" :value="model.consignment[0].shipTo_City" @is-sync="model.consignment[0].shipTo_City = $event.value;" />
                            <form-input id="shipToState" :label="labels.shipTo.state" :value="model.consignment[0].shipTo_State" @is-sync="model.consignment[0].shipTo_State = $event.value;" />
                            <form-lookup id="shipToEstablishmentType" label="Establishment Type" type="enum" source="establishmenttype" @is-sync="model.consignment[0].shipTo_EstablishmentType = $event.value" :value="model.consignment[0].shipTo_EstablishmentType" :required="true"></form-lookup>
                        </div>
                        <div class="p-col-12 p-md-3">
                            <h6>Account Details</h6>
                            <form-lookup id="customer" :disabled="isCustomer" :source="customers" :value="session?.customer ?? customers[0]?.id ?? -1000" @is-sync="setCustomer($event)" :required="true" textField="name" :sorted="false" :showCopy="false" disabled-type="object" />
                            <form-lookup id="provider" :disabled="providers.length <= 1" :value="selection?.provider?.id ?? -1000" :source="providers" @is-sync="setProvider($event)" :required="true" textField="name" :sorted="false"  disabled-type="object" />
                            <form-lookup id="accounts" :disabled="(selection?.provider?.id ?? -1000) === -1000" :value="model.consignment[0].accountID ?? -1000" @is-sync="model.consignment[0].accountID = $event.value" :sorted="false" :source="sources.accounts" :required="true" textField="name" disabled-type="object" />
                            <form-datetime id="collectionDate" :value="model.consignment[0].collectionDate" @is-sync="model.consignment[0].collectionDate = $event.value;" type="date" :required="true" />
                        </div>
                        <div class="p-col-12 p-md-5">
                            <h6>Configurations</h6>
                            <div class="p-grid">
                                <div class="p-col-12 p-md-6">
                                    <form-lookup id="resultView" label="Result View" :source="[{id: 100, text: 'Summary'}, {id: 200, text: 'Detailed'}]" sourceType="array" @is-sync="{ resultView = $event.value; }" :value="resultView" :v$="v$" :required="true"></form-lookup>
                                    <form-lookup id="currency" label="Currency" type="dropdown" source="currency" :value="model.consignment[0].currencyID" @is-sync="{ model.consignment[0].currencyID = $event.value; units.currency = $event.record?.code; }" :v$="v$" :required="true"></form-lookup>
                                    <form-lookup id="returnCurrency" label="Quote Currency" type="dropdown" source="currency" :value="model.consignment[0].resultCurrencyID" @is-sync="{ model.consignment[0].resultCurrencyID = $event.value; units.resultCurrency = $event.record?.code; }" :v$="v$" :required="true"></form-lookup>
                                    <form-lookup id="weightUnit" type="enum" source="weightunit" :value="model.consignment[0].weightUnit" @is-sync="{ model.consignment[0].weightUnit = $event.value; model.weightUnitText = $event.text }" :required="true" />
                                    <form-lookup id="dimensionUnit" type="enum" source="lengthunit" :value="model.consignment[0].dimensionUnit" @is-sync="{ model.consignment[0].dimensionUnit = $event.value; model.dimensionUnitText = $event.text }" :required="true" />
                                </div>
                                <div class="p-col-12 p-md-6">
                                    <form-lookup id="incoterm" type="enum" source="incotermtype" :value="model.consignment[0].incoterm" @is-sync="model.consignment[0].incoterm = $event.value;" :required="true" />
                                    <form-lookup id="consignmentCategory" type="enum" source="customscategorytype" :allowedFlags="3" :value="model.consignment[0].consignmentCategory" @is-sync="model.consignment[0].consignmentCategory = $event.value;" :required="true" />
                                    <form-lookup id="consignmentType" type="enum" source="serviceproviderproductpackagingtype" :allowedFlags="allowedFlags" :value="model.consignment[0].consignmentType" @is-sync="model.consignment[0].consignmentType = $event.value;" :sorted="false" :required="true" />
                                    <form-lookup id="notificationType" type="flag" source="notificationtype" :value="model.consignment[0].notification" @is-sync="model.consignment[0].notification = $event.value;" :sorted="false" :required="true" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="consignmentDetails && !packageDetails" class="p-d-flex p-jc-end">
                        <pv-button v-if="(quotations ?? []).length === 0" :disabled="activity.quote" :loading="activity.quote" class="p-mr-1" :label="(activity.quote ? 'Calculating...' : 'Quote')" icon="pi pi-search" @click="getQuote()"></pv-button>
                        <pv-button class="p-mr-1" label="Clear" :disabled="activity.quote" :loading="activity.quote" icon="pi pi-refresh" @click="onClear()"></pv-button>
                    </div>
                </pv-accordion-tab>
                <pv-accordion-tab>
                    <template #header>
                        <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                            <div class="p-col-fixed p-as-start"><i class="pi pi-bars"></i></div>
                            <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                                <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; ADDITIONAL DETAILS</div>
                            </div>
                        </div>
                    </template>
                    <div class="p-grid">
                        <div class="p-col-12 p-md-2">
                            <h6>Collection</h6>
                            <form-input id="shipFromName" :value="model.consignment[0].shipFrom_Name" @is-sync="model.consignment[0].shipFrom_Name = $event.value;" />
                            <form-input id="shipFromCompany" :value="model.consignment[0].shipFrom_Company" @is-sync="model.consignment[0].shipFrom_Company = $event.value;" />
                        </div>
                        <div class="p-col-12 p-md-2">
                            <h6>Delivery</h6>
                            <form-input id="shipToName" :value="model.consignment[0].shipTo_Name" @is-sync="model.consignment[0].shipTo_Name = $event.value;" />
                            <form-input id="shipToCompany" :value="model.consignment[0].shipTo_Company" @is-sync="model.consignment[0].shipTo_Company = $event.value;" />
                        </div>
                        <div class="p-col-12 p-md-8">
                            <h6>References</h6>
                            <div class="p-grid">
                                <div class="p-col-12 p-md-6">
                                    <form-input id="reference1" :value="model.consignment[0].reference_01" @is-sync="model.consignment[0].reference_01 = $event.value;" />
                                    <form-input id="reference2" :value="model.consignment[0].reference_02" @is-sync="model.consignment[0].reference_02 = $event.value;" />
                                    <form-input id="reference3" :value="model.consignment[0].reference_03" @is-sync="model.consignment[0].reference_03 = $event.value;" />
                                </div>
                                <div class="p-col-12 p-md-6">
                                    <form-input id="reference4" :value="model.consignment[0].reference_04" @is-sync="model.consignment[0].reference_04 = $event.value;" />
                                    <form-input id="reference5" :value="model.consignment[0].reference_05" @is-sync="model.consignment[0].reference_05 = $event.value;" />
                                    <form-input id="reference6" :value="model.consignment[0].reference_06" @is-sync="model.consignment[0].reference_06 = $event.value;" />
                                    <form-input id="reference7" :value="model.consignment[0].reference_07" @is-sync="model.consignment[0].reference_07 = $event.value;" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="additionalDetails && !packageDetails" class="p-d-flex p-jc-end">
                        <pv-button v-if="(quotations ?? []).length === 0" :disabled="activity.quote" :loading="activity.quote" class="p-mr-1" :label="(activity.quote ? 'Calculating...' : 'Quote')" icon="pi pi-search" @click="getQuote()"></pv-button>
                        <pv-button class="p-mr-1" label="Clear" :disabled="activity.quote" :loading="activity.quote" icon="pi pi-refresh" @click="onClear()"></pv-button>
                    </div>
                </pv-accordion-tab>
                <pv-accordion-tab>
                    <template #header>
                        <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                            <div class="p-col-fixed p-as-start"><i class="fa fa-cubes"></i></div>
                            <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                                <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; PACKAGE DETAILS</div>
                            </div>
                        </div>
                    </template>
                    <div style="overflow-x: auto;padding: 1.25rem 0 0; margin-bottom: 0.25rem;">
                        <quote-parcel :source="model.packages" :header="header" @is-sync="model.packages[$event.id - 1] = $event.model" :units="units"
                                      @is-add="onAdd"
                                      @is-delete="onDelete"
                                      @is-copy-next="onCopyNext"
                                      @is-copy="onCopyValue" />
                    </div>
                    <div v-if="(!consignmentDetails && packageDetails) || (consignmentDetails && packageDetails)" class="p-d-flex p-jc-end">
                        <pv-button v-if="(quotations ?? []).length === 0" :disabled="activity.quote || providers.length <= 1" :loading="activity.quote" class="p-mr-1" :label="(activity.quote ? 'Calculating...' : 'Quote')" icon="pi pi-search" @click="getQuote()"></pv-button>
                        <pv-button class="p-mr-1" :loading="activity.quote" :disabled="activity.quote" label="Clear" icon="pi pi-refresh" @click="onClear()"></pv-button>
                        <!--<pv-button class="p-mr-1" v-if="quotations.length > 0" label="Send" icon="pi pi-send" :disabled="(model.packages.length ?? 0) > 20"></pv-button>
            <pv-button class="p-mr-1" v-if="quotations.length > 0" label="Save" icon="pi pi-save" :disabled="(model.packages.length ?? 0) > 20"></pv-button>-->
                    </div>
                </pv-accordion-tab>
                <pv-accordion-tab v-if="(quotations ?? []).length > 0">
                    <template #header>
                        <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                            <div class="p-col-fixed p-as-start"><i class="fa fa-list"></i></div>
                            <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                                <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; RESULT</div>
                            </div>
                        </div>
                    </template>
                    <div v-if="(!consignmentDetails && !packageDetails && resultDetails)" class="p-d-flex p-jc-end p-nogutter">
                        <div class="p-nogutter">
                            <form-lookup v-if="(!consignmentDetails && !packageDetails && resultDetails)" id="resultView" :hideLabel="true" preLabel="Result View" :source="[{id: 100, text: 'Summary'}, {id: 200, text: 'Detailed'}]" sourceType="array" @is-sync="{ resultView = $event.value; }" :value="resultView" :v$="v$" :required="true" style="width: 20rem;"></form-lookup>
                        </div>
                        <div style="margin: 0.25rem 0.5rem;">
                            <pv-button class="p-ml-1" :loading="activity.quote" label="Clear" icon="pi pi-refresh" @click="onClear()"></pv-button>
                            <!--<pv-button class="p-ml-1" :loading="activity.quote" v-if="quotations.length > 0" label="Send" icon="pi pi-send" :disabled="(model.packages.length ?? 0) > 20"></pv-button>
                <pv-button class="p-ml-1" :loading="activity.quote" v-if="quotations.length > 0" label="Save" icon="pi pi-save" :disabled="(model.packages.length ?? 0) > 20"></pv-button>-->
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col p-md-3 p-lg-4" v-for="quote in quotations" :key="quote.accountProductID" style="width: 450px; background: var(--surface-d); padding: 15px 20px; margin-right: 10px; margin-bottom: 10px; border-radius: 10px; border: 1px solid var(--gray-400)">
                            <quote-result :quote="quote" :resultView="resultView" />
                        </div>
                    </div>
                </pv-accordion-tab>
            </pv-accordion>
        </div>
    </div>
</template>
<script>
    import parcel from '@/components/facility/rates/quote/quote-parcel-form.vue';
    import quote from '@/components/facility/rates/quote/quote-result-form.vue';
    export default {
        name: "QuotationTerminal",
        components: {
            'quote-parcel': parcel,
            'quote-result': quote
        },
        data() {
            return {
                keys: null,
                cleanModel: {
                    consignment: [{
                        id: 1,
                        customerID: 0,
                        warehouseID: 0,
                        accountID: 0,
                        providerProductID: -1000,
                        collectionDate: new Date(this.$moment.utc()),
                        shipFrom_Address1: null,
                        shipFrom_Address2: null,
                        shipFrom_Address3: null,
                        shipFrom_City: null,
                        shipFrom_State: null,
                        shipFrom_PostalCode: null,
                        shipFrom_CountryID: 0,
                        shipFrom_EstablishmentType: 100,
                        shipTo_Address1: null,
                        shipTo_Address2: null,
                        shipTo_Address3: null,
                        shipTo_City: null,
                        shipTo_State: null,
                        shipTo_PostalCode: null,
                        shipTo_CountryID: 0,
                        shipTo_EstablishmentType: 200,
                        currencyID: 3,
                        resultCurrencyID: 3,
                        consignmentType: 4,
                        consignmentCategory: 2,
                        incoterm: 64,
                        notification: 3,
                        weightUnit: 100,
                        dimensionUnit: 100,
                        tradeType: 1,
                        isDomestic: true
                    }],
                    packages: [{
                        id: 1,
                        hazMatID: 0,
                        dangerousGoods: 0,
                        specialHandling: 0,
                        packageCount: 1,
                        declaredValue: 1.00,
                        insuranceValue: 0.00,
                        length: 0,
                        width: 0,
                        height: 0,
                        dimensionUnit: 100,
                        actualWeight: 0.100,
                        weightUnit: 100
                    }],
                    environmentID: null,
                    tenantID: null
                },
                model: {
                    consignment: [{
                        id: 1,
                        customerID: 0,
                        warehouseID: 0,
                        accountID: 0,
                        providerProductID: -1000,
                        collectionDate: new Date(this.$moment.utc()),
                        shipFrom_Name: null,
                        shipFrom_Company: null,
                        shipFrom_Address1: null,
                        shipFrom_Address2: null,
                        shipFrom_Address3: null,
                        shipFrom_City: null,
                        shipFrom_State: null,
                        shipFrom_PostalCode: null,
                        shipFrom_CountryID: 0,
                        shipFrom_EstablishmentType: 100,
                        shipTo_Name: null,
                        shipTo_Company: null,
                        shipTo_Address1: null,
                        shipTo_Address2: null,
                        shipTo_Address3: null,
                        shipTo_City: null,
                        shipTo_State: null,
                        shipTo_PostalCode: null,
                        shipTo_CountryID: 0,
                        shipTo_EstablishmentType: 200,
                        reference_01: null,
                        reference_02: null,
                        reference_03: null,
                        reference_04: null,
                        reference_05: null,
                        reference_06: null,
                        reference_07: null,
                        currencyID: 3,
                        resultCurrencyID: 3,
                        consignmentType: 4,
                        consignmentCategory: 2,
                        incoterm: 64,
                        notification: 3,
                        weightUnit: 100,
                        dimensionUnit: 100,
                        tradeType: 1,
                        isDomestic: true,
                        providerID: null
                    }],
                    packages: [{
                        id: 1,
                        hazMatID: 0,
                        dangerousGoods: 0,
                        specialHandling: 0,
                        packageCount: 1,
                        declaredValue: 1.00,
                        insuranceValue: 0.00,
                        length: 0,
                        width: 0,
                        height: 0,
                        dimensionUnit: 100,
                        actualWeight: 0.100,
                        weightUnit: 100
                    }],
                    environmentID: null,
                    tenantID: null
                },
                resultView: 100,
                labels: {
                    shipFrom: {
                        postalCode: "Postal Code",
                        city: "City",
                        state: "State"
                    },
                    shipTo: {
                        postalCode: "Postal Code",
                        city: "City",
                        state: "State"
                    }
                },
                sources: {
                    customers: [],
                    providers: [],
                    accounts: []
                },
                session: {
                    tenant: null,
                    environment: null,
                    warehouse: null,
                    customer: null,
                    currentUser: null,
                    executiveUser: null
                },
                selection: {
                    customer: null,
                    provider: null,
                    account: null
                },
                quotations: [],
                activeIndex: [0, 2],
                units: {
                    shipFromCountry: "",
                    shipToCountry: "",
                    currency: "GBP",
                    resultCurrency: "GBP"
                },
                activity:
                {
                    quote: false
                },
                reportScopes: null
            }
        },
        computed: {
            allActiveIndex() { return this.activeIndex },
            header() { return this.model.consignment[0]; },
            packages() { return this.model.packages ?? [] },
            sessionKeys() { return this.$store.getters["auth/session"]; },
            user() { return this.$store.getters["auth/currentUser"]; },
            customers() {
                return (this.reportScopes?.customers ?? []).filter(cust => Number(this.session.warehouse) === 0 || (Number(this.session.warehouse) > 0 && (cust.warehouses ?? []).filter(wh => wh === Number(this.session.warehouse)).length > 0)) ?? [];
            },
            providerSource() { return (this.reportScopes?.providers ?? []).filter(provider => ((this.accounts ?? []).map(acct => acct.serviceProviderID) ?? []).filter(acct => acct == provider.id).length > 0) ?? []; },
            providers() { return [({ id: -1000, name: this.providerSource.length > 0 ? "[Any Provider]" : "[No Available Provider]" })].concat(this.providerSource) ?? []; },
            accounts() {
                return (((((this.reportScopes?.accounts ?? [])
                    .filter(acct => Number(this.session.customer) === 0 || (Number(this.session.customer) > 0 && (acct.customers ?? []).filter(cust => cust === Number(this.session.customer)).length > 0)) ?? [])
                    .filter(acct => Number(this.session.warehouse) === 0 || Number(this.session.warehouse) > 0 && ((acct.postingLocations ?? []).length === 0 || (acct.postingLocations ?? []).filter(postLoc => postLoc == Number(this.session.warehouse)).length > 0))) ?? [])
                    .filter(acct => Number(this.session.warehouse) === 0 || (Number(this.session.warehouse) > 0 && (acct.customers ?? []).filter(acctcust => ((this.customers ?? []).map(z => z.id) ?? []).filter(cust => cust === acctcust).length > 0).length > 0)) ?? []);
            },
            providerAccounts() { return this.sources?.accounts ?? []; },
            allowedFlags() {
                if (this.model.consignment[0].consignmentCategory === 1)
                    return 7;
                else
                    return 62;
            },
            consignmentDetails() { return this.activeIndex.findIndex(x => x == 0) !== -1; },
            additionalDetails() { return this.activeIndex.findIndex(x => x == 1) !== -1; },
            packageDetails() { return this.activeIndex.findIndex(x => x == 2) !== -1; },
            resultDetails() { return this.activeIndex.findIndex(x => x == 3) !== -1; },
            isCustomer() {
                return [5, 6, 7].filter(y => y === this.user.userType).length > 0;
            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                    this.model.consignment[0].warehouseID = Number(this.session.warehouse);
                    this.model.consignment[0].customerID = this.session.customer;
                    this.model.environmentID = this.session.environment;
                    this.model.tenantID = this.session.tenant;
                    this.getReportScopes();
                    const warehouse = this.$config.common.$warehouse(this.session.environment, this.user).filter(x => x.id == this.model.consignment[0].warehouseID)[0];
                    if (warehouse) {
                        this.model.consignment[0].shipFrom_CountryID = warehouse.countryID;
                        this.model.consignment[0].shipFrom_PostalCode = warehouse.postalCode;
                        this.model.consignment[0].shipFrom_City = warehouse.city;
                        this.model.consignment[0].shipFrom_State = warehouse.state;
                    }
                });
            },
            model: {
                handler() { this.quotations = []; },
                deep: true
            }
        },
        methods: {
            onCountryChange(source, e) {
                this.units[source + "Country"] = e?.code;
                this.labels[source].postalCode = e?.postalCodeLabel ?? "Postal Code";
                this.labels[source].city = e?.cityLabel ?? "City";
                this.labels[source].state = e?.stateLabel ?? "State";
            },
            onAdd() {
                var itemline = {
                    id: this.model.packages.length + 1,
                    hazMatID: 0,
                    dangerousGoods: 0,
                    specialHandling: 0,
                    packageCount: 1,
                    declaredValue: 1.00,
                    insuranceValue: 0.00,
                    length: 0,
                    width: 0,
                    height: 0,
                    dimensionUnit: 100,
                    actualWeight: 0.100,
                    weightUnit: 100
                };
                this.model.packages.push(itemline);
            },
            onDelete(index) {
                let parcels = this.model.packages.filter(x => x.id !== index).map(x => this.$filters.init(x));
                for (let i = 0; i < parcels.length; i++)
                    parcels[i].id = i + 1;
                this.model.packages = parcels;
            },
            onCopyNext(model) {
                let source = this.$filters.init(model);
                source.id = model.id + 1;
                this.model.packages[model.id] = source;
            },
            onCopyValue(e) {
                let target = e.target - 1;
                if (target < this.model.packages.length) {
                    let source = this.$filters.init(this.model.packages[e.source - 1] ?? this.model.packages[target]);
                    source.id = this.model.packages[target].id;
                    this.model.packages[target] = source;
                }
                else {
                    let source = this.$filters.init(this.model.packages[e.source - 1]);
                    source.id = e.target;
                    this.model.packages.push(source);
                }
            },
            setCustomer(e) {
                this.session.customer = e.value;
                this.selection.customer = e.record;
                this.model.consignment[0].customerID = e.value;
            },
            setProvider(e) {
                this.selection.provider = e.record;
                if (this.selection.customer) {
                    this.model.consignment[0].accountID = -1000;
                }
                if (this.selection.provider) {
                    this.model.consignment[0].providerID = this.selection.provider.id;
                    this.sources.accounts = [({ id: -1000, name: this.providers.length === 0 ? "[No Available Provider]" : "[Any Account]" })].concat(this.accounts.filter(x => x.serviceProviderID == this.selection.provider.id));
                }
            },
            getQuote() {
                this.activity.quote = true;
                this.$store.dispatch("rateconditiontype/postURL", { url: '/services/facility/quote/consignments', payload: this.model })
                    .then((response) => {
                        this.quotations = response ?? [];
                        this.$nextTick(() => {
                            if (this.quotations.length > 0)
                                this.activeIndex = [3];
                        });
                        this.$nextTick(() => {
                            this.activity.quote = false;
                        })
                    })
                    .catch(() => {
                        this.$nextTick(() => {
                            if (this.quotations.length > 0)
                                this.activeIndex = [0, 2];
                        });
                        this.$nextTick(() => {
                            this.activity.quote = false;
                        })
                    })
                    .finally(() => {
                        this.$nextTick(() => {
                            this.activity.quote = false;
                        })
                    });
            },
            onOpen(e) {
                var index = this.activeIndex.findIndex(x => x == e.index);
                if (index === -1)
                    this.activeIndex.push(e.index);
            },
            onClose(e) {
                var index = this.activeIndex.findIndex(x => x == e.index);
                if (index !== -1)
                    this.activeIndex.splice(index, 1);
            },
            onClear() {
                if (this.quotations.length > 0) {
                    this.quotations = [];
                    this.activeIndex = [0, 2];
                }
                else {
                    this.model = this.cleanModel;
                    this.model.consignment[0].shipTo_CountryID = this.$vm.$countries.filter(x => x.code === this.$vm.$location.country)[0].id;
                    this.model.consignment[0].currencyID = this.model.consignment[0].currencyID ?? this.$vm.$currencies.filter(x => x.code === this.$vm.$location.currency && x.type.id === 1)[0].id;
                    this.model.consignment[0].resultCurrencyID = this.model.consignment[0].currencyID ?? this.$vm.$currencies.filter(x => x.code === this.$vm.$location.currency && x.type.id === 1)[0].id;
                    this.model.consignment[0].warehouseID = Number(this.session.warehouse);
                    this.model.consignment[0].customerID = this.session.customer;
                    this.model.environmentID = this.session.environment;
                    this.model.tenantID = this.session.tenant;
                    this.getReportScopes();
                    const warehouse = this.$config.common.$warehouse(this.session.environment, this.user).filter(x => x.id == this.model.consignment[0].warehouseID)[0];
                    if (warehouse) {
                        this.model.consignment[0].shipFrom_CountryID = warehouse.countryID;
                        this.model.consignment[0].shipFrom_PostalCode = warehouse.postalCode;
                        this.model.consignment[0].shipFrom_City = warehouse.city;
                        this.model.consignment[0].shipFrom_State = warehouse.state;
                    }
                }
            },
            getReportScopes() {
                this.reportScopes = null;
                this.$store.dispatch("provideraccount/getURL", { url: `/services/settings/user/reportscope` })
                    .then((response) => {
                        this.reportScopes = response;
                        this.sources.warehouses = response?.warehouses;
                        this.sources.customers = response?.customers;
                        this.sources.providers = response?.providers;
                        this.sources.accounts = [({ id: -1000, name: this.providers.length === 0 ? "[No Available Provider]" : "[Any Account]" })];
                    });
            },

        },
        mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            this.model.consignment[0].shipTo_CountryID = this.model.consignment[0].shipTo_CountryID ?? this.$vm.$countries.filter(x => x.code === this.$vm.$location.country)[0].id;
            this.model.consignment[0].currencyID = this.model.consignment[0].currencyID ?? this.$vm.$currencies.filter(x => x.code === this.$vm.$location.currency && x.type.id === 1)[0].id;
            this.model.consignment[0].resultCurrencyID = this.model.consignment[0].currencyID ?? this.$vm.$currencies.filter(x => x.code === this.$vm.$location.currency && x.type.id === 1)[0].id;
            this.model.collectionDate = new Date(this.$moment.utc(new Date()).format("yyyy-MM-DD"));
            this.getReportScopes();
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    #QuoteAccordion .p-accordion-header-link {
        padding: 0.75rem 1rem;
        border-bottom: 1px solid var(--gray-200);
    }
</style>
