<template>
    <div>
        <pv-picklist v-model="items" class="is-customized" :striped-rows="true" listStyle="height:450px;" dataKey="id" @move-to-target="moveToTarget" @move-all-to-target="moveToTarget" @move-to-source="moveToSource" @move-all-to-source="moveToSource" @reorder="sortTarget">
            <template #sourceHeader>
                <div class="p-d-flex" style="width: 100%;">
                    <div class="p-col-2" style="padding-top: 10px;">
                        Available
                    </div>
                    <div class="p-col-10" style=" margin: 0; padding: 0;">
                        <pv-input style="margin: 0 10px; width: 100%;" v-model="filter.source" placeholder="Type your keyword..." />
                    </div>
                </div>
            </template>
            <template #targetHeader>
                <div class="p-d-flex" style="width: 100%;">
                    <div class="p-col-2" style="padding-top: 10px;">
                        Selected
                    </div>
                    <div class="p-col-10" style=" margin: 0; padding: 0;">
                        <pv-input style="margin: 0 10px; width: 100%;" v-model="filter.target" placeholder="Type your keyword..." />
                    </div>
                </div>
            </template>
            <template #item="slotProps">
                <div class="p-d-flex p-jc-between" style="width: 100%; margin: 0; padding: 0.5rem 0.5rem 0.5rem 1rem; margin-right: 1rem !important;">
                    <div class="flex-1 flex flex-column gap-2">
                        <span style="font-weight: 700 !important;">{{ slotProps.item.displayName }}</span>
                    </div>
                    <div class="font-bold text-code" style="text-transform: uppercase; "><span :style="`font-size: 0.7rem; opacity: 0.5; margin-right: ${(slotProps.item.listIndex ?? 0) === 0 ? '0.5' : '2'}rem;`">{{ slotProps.item.category }}</span> <i v-if="slotProps.item.listIndex === 1" style="position:absolute; top: 0.75rem; right: 0.5rem; z-index: 9999; cursor: grab;" class="fa fa-cog fa-fw px-3" onclick="alert('ako')"></i></div>
                </div>
            </template>
        </pv-picklist>
    </div>
</template>

<script>
    export default {
        name: "DynamicReportFieldSelectorForm",
        emits: ["update", "update:modelValue", "isSync"],
        props: {
            parentID: { type: Number, default: 0 },
            modelValue: { type: Array, default: () => [] },
            disabled: { type: Boolean, default: false }
        },
        data() {
            return {
                model: [],
                sources: {
                    base: []
                },
                filter: { source: null, target: null },
            }
        },
        computed: {
            filteredSource() {
                let ids = (this.model).map(x => x.baseFieldID);
                let source = (this.sources.base ?? []).filter(x => ids.indexOf(x.id) == -1 && x.visibility) ?? [];
                if (this.filter.source && this.filter.source.trim() !== "")
                    source = source.filter(x => x.displayName?.toLowerCase().includes(this.filter.source.toLowerCase()) || x.category?.toLowerCase().includes(this.filter.source.toLowerCase()) || x.field?.toLowerCase().includes(this.filter.source.toLowerCase()));
                return source.sort(this.sort('sortOrder'));
            },
            filteredTarget() {
                let ids = (this.model).map(x => x.baseFieldID);
                let target = (this.sources.base ?? []).filter(x => ids.indexOf(x.id) !== -1 && x.visibility) ?? [];
                if (this.filter.target && this.filter.target.trim() !== "")
                    target = target.filter(x => x.displayName?.toLowerCase().includes(this.filter.target.toLowerCase()) || x.category?.toLowerCase().includes(this.filter.target.toLowerCase()) || x.field?.toLowerCase().includes(this.filter.target.toLowerCase()));
                return target.sort(this.sort('sortOrder'));
            },
            items() { return [this.filteredSource, this.filteredTarget]; }
        },
        watch: {
            modelValue() {
                this.model = this.$props.modelValue ?? [];
                (this.model ?? []).forEach((value, index) => {
                    let baseIndex = this.sources.base.map(x => x.id).indexOf(value.baseFieldID);
                    if (baseIndex > -1) {
                        this.sources.base[baseIndex].sortOrder = index + 1;
                        this.sources.base[baseIndex].listIndex = 1;
                    }
                });
            }
        },
        methods: {
            getSource() {
                this.sources.base = this.$vm.$columns;
                this.sources.base.forEach((value) => {
                    value.sortOrder = 99999999;
                    value.listIndex = 0;
                });
            },
            async moveToTarget(e) {
                let items = [...this.model];
                (e.items ?? []).forEach((value) => {
                    items.push({
                        id: 0,
                        reportID: this.parentID,
                        baseFieldID: value.id,
                        fieldName: value.displayName,
                        displayName: value.displayName,
                        sortOrder: 999999999,
                        sortDirection: 0
                    });
                    let index = this.sources.base.map(x => x.id).indexOf(value.id);
                    if (index > -1) {
                        this.sources.base[index].sortOrder = items.length;
                        this.sources.base[index].listIndex = 1;
                    }
                });
                (items ?? []).forEach((value, index) => {
                    value.sortOrder = index + 1;
                });
                this.model = items.sort(this.sort("sortOrder"));
                this.onSync();
            },
            async moveToSource(e) {
                let items = [...this.model];
                (e.items ?? []).forEach((value) => {
                    let index = items.map(x => x.baseFieldID).indexOf(value.id);
                    if (index > -1) items.splice(index, 1);
                    index = this.sources.base.map(x => x.id).indexOf(value.id);
                    if (index > -1) {
                        this.sources.base[index].sortOrder = 99999999;
                        this.sources.base[index].listIndex = 0;
                    }
                });
                this.model = items.sort(this.sort("id"));
                this.onSync();
            },
            async sortTarget(e) {
                let items = [...this.model];
                (e.value[e.listIndex] ?? []).forEach((value, index) => {
                    const idx = items.map(x => x.baseFieldID).indexOf(value.id);
                    if (idx > -1) items[idx].sortOrder = index + 1;
                    value.sortOrder = index + 1;
                });
                this.model = items.sort(this.sort("sortOrder"));
                this.onSync();
            },
            onSync() {
                this.$emit("update:modelValue", this.model);
                this.$emit("is-sync", this.model);
            },
            sort(property) {
                let sortOrder = 1;
                return (a, b) => {
                    let result = ((a[property] ?? 99999999) < (b[property] ?? 99999999)) ? -1 : ((a[property] ?? 99999999) > (b[property] ?? 99999999)) ? 1 : 0;
                    return result * sortOrder;
                }
            }
        },
        mounted() {
            this.getSource();
            this.model = this.$props.modelValue ?? [];
            (this.model ?? []).forEach((value, index) => {
                let baseIndex = this.sources.base.map(x => x.id).indexOf(value.baseFieldID);
                if (baseIndex > -1) {
                    this.sources.base[baseIndex].sortOrder = index + 1;
                    this.sources.base[baseIndex].listIndex = 1;
                }
            });
        }
    }
</script>
<style>
    .is-customized .p-picklist-list, .listbox-list-wrapper { min-height: 13rem !important; max-height: 23rem !important; }
    .is-customized .p-picklist-list li { padding: 0 !important; border-bottom: 1px solid var(--surface-d) !important; }
    .is-customized .p-picklist-list li:nth-child(even), .p-listbox-item:nth-child(even) { background: var(--surface-c) !important; }
    .is-customized .p-listbox-item { padding: 0.5rem 1rem !important; font-weight: 700; border-bottom: 1px solid var(--surface-d) !important; }
</style>