<template>
    <form-wrapper :title="router.title" :sub-title="router.subtitle" :note="headerNote">
        <cosmos-grid id="CompanyList" @is-source="this.companyList = $event.data;" sourceType="url" :source="url" :searchFilters="searchFields" :pageSize="15" :filters="activeFilters" :forceRefresh="forceRefresh" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showAdd="false">
            <template #toolbar>
                <div class="p-d-flex p-jc-between p-ai-center">
                    <span>
                        <pv-button v-show="!disableAdd" icon="pi pi-fw pi-plus" :label="'Add New ' + $filters.singularize(router.title)" style="margin: 0.20rem;" @click="onCreate" :disabled="disableAdd"></pv-button>

                    </span>
                    <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                        <i class="pi pi-search" />
                        <pv-input v-model="activeFilters['global'].value" placeholder="Keyword Search" style="width: 100%;" @keyup="onForceFilter" />
                    </span>
                </div>
            </template>
            <pv-grid-column field="code" header="Logo" headerStyle="min-width: 17rem" style="min-width: 17rem; text-align: center; object-position: center;">
                <template #body="slotProps">
                    <div class="p-grid" style="width: 12rem; text-align: center;">
                        <div class="p-col" style="text-align: center">
                            <div class="p-fluid" :style="[{'height': '4rem' }, { 'width': '15rem' }, { 'display': 'block' }, { 'padding': '10px !mportant' }, { 'background': `url('${cdn}/${getLogo(slotProps.data)}?${Math.random()}')` }, { 'background-position': 'center !important' }, { 'background-repeat': 'no-repeat !important'}, { 'background-size': 'contain !important' }] ">
                            </div>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="code" :header="`Details`" style="width: 100%;">
                <template #body="slotProps">
                    <div class="p-d-flex" style="width: 100%; padding: 0;">
                        <div style="width: 100%;">
                            <div class="p-d-flex p-jc-between" style="width: 100%; z-index: 100;">
                                <div><h5 style="margin-bottom: 5px; margin-top: 0;"><span>{{ slotProps.data.altName ?? slotProps.data.name }}</span></h5></div>
                                <div class="p-as-end"><h5 class="p-code-text" style="margin-bottom:0;">[{{ slotProps.data.code}}]</h5></div>
                            </div>
                            <div class="p-d-flex" style="width: 100%;">
                                <div style="margin-right: 5px;"><i class="pi pi-map-marker pi-fw" style="font-size: 0.8rem" /></div>
                                <div><p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.8rem">{{ slotProps.data.name }}, {{ slotProps.data.address1 ? slotProps.data.address1 + ',' : null }} {{ slotProps.data.address2 ? slotProps.data.address2 + ',' : null }} {{ slotProps.data.address3 ? slotProps.data.address3 + ',' : null}} {{ slotProps.data.city ? slotProps.data.city + ',' : null }} {{ slotProps.data.state ? slotProps.data.state + ',' : null }} {{ slotProps.data.postalCode ? slotProps.data.postalCode + ',' : null }} {{ $vm.$countries.filter(x => x.id ===slotProps.data.countryID)[0]?.text }}</p></div>
                            </div>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="code" header="Status" headerStyle="min-width: 8rem" style="min-width: 8rem;">
                <template #body="slotProps">
                    <div v-if="slotProps.data.type > 1 && slotProps.data.recordStatus !== 1" class="p-d-flex p-jc-center" style="height: 100%; display: block;">
                        <div class="p-jc-center">
                            <pv-toggle v-model="slotProps.data.isActive" :style="[{'width': '12rem'}, {'object-position': 'bottom'}, {'align-content': 'end' }, {'visibility': ((type <= 0) ? 'hidden' : 'visible') }, {'padding': '0.5rem 1rem'}, {'margin-top': '-0.25rem'}]" class="p-inputgroup-prepend" :onIcon="'pi pi-check'" :offIcon="'pi pi-times'" :onLabel="'ACTIVE'" :offLabel="'INACTIVE'" @change="onDisable(slotProps.data)"></pv-toggle>
                        </div>
                    </div>
                    <div v-else class="p-d-flex p-jc-center" style="height: 100%;">
                        <div style="text-align: center;">
                            <span :class="'product-badge status-'+ (slotProps.data.recordStatus === 0 ? 'instock' : slotProps.data.recordStatus === 1 ? 'lowstock' : 'outofstock')">{{slotProps.data.recordStatus === 0 ? 'active' : slotProps.data.recordStatus === 1 ? 'protected' : 'inactive' }}</span>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
        </cosmos-grid>
    </form-wrapper>
</template>
<script>
    import { mapGetters } from 'vuex';
    import { FilterMatchMode } from 'primevue/api';
    import config from '@/assets/lib/cosmos/_js/config.js';
    import mixins from '@/assets/lib/cosmos/_js/mixin.js';
    export default {
        name: 'CompanyListTemplate',
        mixins: [mixins.GRID],
        data() {
            return {
                filter: {},
                columns: [],
                deleteDisplay: ["code", "name"],
                searchFields: ["code", "name", "altName"],
                forceRefresh: false,
                keys: null,
                session: null,
                generalSetting: null,
                environments: [], 
                companyList: []

            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                    this.environments = this.$config.common.$environment();
                    if (this.$route.name == "warehouse-index") {
                        this.onGetGeneralSetting(this.environments.filter(x => x.id == this.$vm.$appSession?.environment?.id ?? this.user?.environmentID)[0]?.mask);
                    }
                });
            },
            $route(to) {
                if (to.name == "warehouse-index") {
                    this.onGetGeneralSetting(this.environments.filter(x => x.id == this.$vm.$appSession?.environment?.id ?? this.user?.environmentID)[0]?.mask);
                }

            }
          
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            headerNote() {
                if (this.disableAdd) {
                    return `<p><i class="fas fa-info-square fa-fw fa-2x is-text-info"></i> <span class="is-text-info">You have reached the <b>maximum limit for ${this.$filters.singularize(this.router.title).toLowerCase()}</b>. For additional ${this.$filters.singularize(this.router.title).toLowerCase()}, contact account manager.</span></p>`;
                }
                return null;
            },
            sessionKeys() { return this.$store.getters["auth/session"]; },
            type() { return this.$route.meta.typeId ?? 0; },
            tenant() { return config.common.$tenant().filter(x => x.id === 1)[0]; },
            cdn() { return `${config.config.endpoint.cdn}`; },
            url() { return `/services/settings/company?type=${this.setType(this.router.type)}` }
           ,activeFilters() {
                var filter = {};
                if (this.filters)
                    filter = this.filters;
                filter["global"] = { value: "", matchMode: FilterMatchMode.CONTAINS };
                return filter;
            },
            random() {
                return Math.random();
            },
            activeCompany() {
                return (this.companyList ?? []).filter(x => (x.isActive ?? false)) ?? [];
            },
            disableAdd() {
                switch ((this.$route?.name ?? "")) {
                    case "warehouse-index":
                        return (this.generalSetting?.setting_WarehouseLimit ?? 0) > 0 ? (((this.activeCompany ?? [])?.length ?? 0) >= (this.generalSetting?.setting_WarehouseLimit ?? 0)) : false;
                    default: return false;
                }
            }
            
        },
        methods: {
            getLogo(data) {
                var logo = [];
                if (data.lightTheme?.logo)
                    logo = [...logo, ...data.lightTheme?.logo];
                return logo[0]?.url;
            },
            onCreate() { this.$router.push({ name: `${this.$route.meta.type}-add` }); },
            onForceFilter(e) {
                if (e.keyCode === 13 || this.activeFilters["global"].value === '') {
                    this.onRefresh();
                }
            },
            onRefresh() {
                this.forceRefresh = false;
                this.$nextTick(() => { this.forceRefresh = true; });
            },
            setType() {
                switch (this.router.type.toLowerCase()) {
                    case "contract":
                    case "environment":
                        return 2;
                    case "forwarder":
                        return 4;
                    case "warehouse":
                        return 8;
                    case "customer":
                        return 16;
                    case "retailer":
                        return 32;
                    case "department":
                        return 64;
                    case "team":
                        return 128;
                }
            }
            ,onGetGeneralSetting(mask) {
                if (mask) {
                    this.$store.dispatch("companygeneralsetting/getURL", { url: `/services/settings/companygeneralsetting/ByCompany/${(mask)}`.replaceAll("//", "/") })
                        .then((response) => {
                            this.generalSetting = response?.data?.result ?? response?.result ?? [];
                        });
                    
                }
            }
            ,onDisable(company) {
                this.$store.dispatch(this.router.module + "/patchURL", {
                    url: '/services/settings/company/' + company.mask, payload: [
                        {
                            "op": "replace",
                            "path": "/recordStatus",
                            "value": company.isActive ? 0 : 3
                        }
                    ]
                }).then((response) => {
                    if ((response?.success ?? true) && ((response?.id ?? 0) !== 0)) {
                        var status = response.recordStatus === 0 ? "Reinstated" : "Disabled";
                        this.$toast.add({ severity: 'success', summary: 'Record Updated Successfully', detail: 'The record was ' + status.toLowerCase() + ' successfully.', life: 3000 });
                        this.onRefresh();
                        sessionStorage.setItem('companyupdate', Math.random());
                    }
                    else {
                        company.isActive = false;
                        this.$toast.add({ severity: 'error', summary: 'Record Updated Failes', detail: response.errors[0].message, life: 3000 });
                    }                    
                }).catch((err) => { console.log(err); });
            },
        },
        mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        },

    }
</script>