<template>
    <div style="margin: -0.5rem -1.5rem -2.5rem -1.5rem;">
        <div style="min-height: 10rem; padding: 1rem;">
            <h3 style="margin-bottom: 0;">STEP 2: Shop Info</h3>
            <p>This step allows the user to map platform locations to COSMOS warehouses.</p>
            <div class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-4">
                    <instruction-form v-model="model.shopInfo"
                                      v-model:modelPlatform="platform"
                                      v-model:modelAccessScope="accessScope" />
                </div>
                <div class="p-col-12 p-md-6 p-lg-4">
                    <location-form v-model="model.locations"
                                   v-model:modelPlatform="platform"
                                   v-model:modelAccessScope="accessScope"
                                   :customerID="model.customerID" />
                </div>
            </div>
        </div>
        <div class="p-grid p-nogutter p-justify-between" style="border-top: 2px solid var(--surface-d);padding: 0.5rem 1rem; background: var(--surface-c)">
            <pv-button @click="onPrevPage()" :loading="activity.loading" :iconPos="'right'"><i class="fa fa-chevron-left fa-fw" style="margin-right: 0.5rem"></i> <i :class="`${(activity.loading ? 'pi pi-spinner pi-spin' : 'fa fa-pen-field fa-fw')}`" style="margin-right: 0.5rem"></i> Platform</pv-button>
            <pv-button @click="onNextPage()" :loading="activity.loading" :iconPos="'right'"><i :class="`${(activity.loading ? 'pi pi-spinner pi-spin' : 'fa fa-pen-field fa-fw')}`" style="margin-right: 0.5rem"></i> Basic Info <i class="fa fa-chevron-right fa-fw" style="margin-left: 0.5rem"></i></pv-button>
        </div>
        <is-dialog header="Confirm Action" :visible="activity.confirm" @is-confirm="onConfirm()" @is-cancel="activity.confirm = false" :confirmDisabled="confirmDisabled">
            <p>Please note that you need to return to the platform credential page.</p>
            <p>Any progress you made from this step onwards will be reset due to possible updates on the platform information page.</p>
            <p style="color: var(--text-danger); font-weight: bold;" >This action cannot be undone.</p>
            <p>Do you still want to continue?</p>
        </is-dialog>
    </div>
</template>
<script>
    import instructionForm from '@/components/facility/ecommerce/instruction-form.vue';
    import locationForm from '@/components/facility/ecommerce/location-form.vue';
    export default {
        name: "FieldSelectionStep",
        emits: ["update", "update:modelValue", "isSync", "nextPage"],
        components: {
            'instruction-form': instructionForm,
            'location-form': locationForm
        },
        props: {
            modelValue: { type: Object, default: null },
            modelPlatform: { type: Object, default: null },
            modelAccessScope: { type: Object, default: null },
        },
        data() {
            return {
                model: {
                    id: 0,
                    customerID: 0,
                    basicInfo: {
                        code: null,
                        name: null,
                        endSolution: 200,
                        thirdPartySolution: 100,
                        status: 100,
                        verificationDate: null,
                    },
                    defaults: {
                        warehouseID: 0,
                        currencyID: 0,
                        content: null,
                        email: null,
                        telephone: null,
                        packagingType: 4,
                        incoterm: 16,
                        weightUnit: 100,
                        dimensionUnit: 100
                    },
                    credentials: {
                        platformID: 1,
                        credential_01: null,
                        credential_02: null,
                        credential_03: null,
                        credential_04: null,
                        credential_05: null,
                        credential_06: null,
                        credential_07: null,
                        credential_08: null,
                        credential_09: null,
                        credential_10: null,
                        credential_11: null,
                        credential_12: null,
                        credential_13: null,
                        credential_14: null,
                        credential_15: null,
                        tokenIssued: null,
                        tokenExpiry: null
                    },
                    orderAutomation: {
                        type: 100,
                        webhook: 200,
                        fulfillOrder: false,
                        partialFulfillment: false,
                        notifyUser: false,
                        schedule: {
                            hourInterval: 1,
                            lastRunDate: null,
                            nextRunDate: null
                        }
                    },
                    productAutomation: {
                        type: 100,
                        webhook: 200,
                        schedule: {
                            hourInterval: 1,
                            lastRunDate: null,
                            nextRunDate: null
                        }
                    },
                    notification: {
                        failed: null,
                        success: null
                    },
                    shopInfo: {
                        code: null,
                        name: null,
                        address1: null,
                        address2: null,
                        address3: null,
                        city: null,
                        state: null,
                        stateCode: null,
                        postalCode: null,
                        country: null,
                        countryID: null,
                        contact: null,
                        telephone: null,
                        email: null,
                        shopDomain: null,
                        storeDomain: null,
                        timezone: null,
                        ianaTimezone: null,
                        supportedCurrencies: null,
                        defaults: {
                            primaryLocationID: null,
                            currency: null,
                            weightUnit: null
                        },
                        taxConfiguration: {
                            taxInclusive: false,
                            taxShipping: false
                        }
                    },
                    environmentID: null,
                    recordStatus: 0
                },
                platform: null,
                accessScope: null,
                activity: {
                    confirm: false,
                    loading: false
                }
            }
        },
        watch: {
            modelValue() { this.model = this.modelValue; },
            modelPlatform() { this.platform = this.modelPlatform; },
            modelAccessScope() { this.accessScope = this.modelAccessScope; },
            model() { this.onSync(); }
        },
        methods: {
            onPrevPage() {
                this.activity.confirm = true;
            },
            onConfirm() {
                // Map Basic Info
                this.model.basicInfo = {
                    code: null,
                    name: null,
                    endSolution: 200,
                    thirdPartySolution: 100,
                    status: 100,
                    verificationDate: null
                };
                // Map Shop Info
                this.model.shopInfo = {
                    code: null,
                    name: null,
                    address1: null,
                    address2: null,
                    address3: null,
                    city: null,
                    state: null,
                    stateCode: null,
                    postalCode: null,
                    country: null,
                    countryID: null,
                    contact: null,
                    telephone: null,
                    email: null,
                    shopDomain: null,
                    storeDomain: null,
                    timezone: null,
                    ianaTimezone: null,
                    supportedCurrencies: null,
                    defaults: {
                        primaryLocationID: null,
                        currency: null,
                        weightUnit: null
                    },
                    taxConfiguration: {
                        taxInclusive: false,
                        taxShipping: false
                    }
                };
                // Map Defaults
                this.model.defaults = {
                    content: null,
                    currencyID: null,
                    dimensionUnit: 100,
                    email: null,
                    incoterm: 16,
                    packagingType: 4,
                    telephone: null,
                    warehouseID: 0,
                    weightUnit: null
                };
                // Map Locations
                this.model.locations = [];
                // Map Webhooks
                this.model.webhooks = [];
                this.activity.confirm = false;
                this.$emit('prev-page', { formData: this.model, platform: this.platform, pageIndex: 1 });
            },
            onNextPage() { this.$emit('next-page', { formData: this.model, platform: this.platform, pageIndex: 1 }); },
            onSync() {
                this.$emit("update:modelValue", this.model);
                this.$emit("is-sync", this.model);
            }
        },
        created() {
            if (this.modelValue)
                this.model = this.modelValue;
            if (this.modelPlatform)
                this.platform = this.modelPlatform;
            if (this.modelAccessScope)
                this.accessScope = this.modelAccessScope;
        }
    }
</script>