<template>
    <div v-for="item in model" :key="item.id">
        <quote-parcel-line :header="header" :source="source" :item="item" :index="item.id" :itemCount="source.length" :consumed="consumed" :units="units" @is-sync="updaterecord(item?.id ?? -1, $event)"
                           @is-add="onAdd"
                           @is-delete="onDelete($event)"
                           @is-copy-next="onCopyNext($event)"
                           @is-copy="onCopyValue($event)" />
    </div>
</template>
<script>
    import parcelline from '@/components/facility/rates/quote/quote-parcelline-form.vue';
    export default {
        name: 'QuoteParcels',
        components: {
            'quote-parcel-line': parcelline
        },
        props: {
            header: { type: Object },
            source: { type: Array },
            units: { type: Object }
        },
        data() {
            return {
                model: []
            }
        },
        computed: {
            parentHeader() {
                return this.header;
            },
            consumed() {
                let consumed = 0;
                this.model.forEach(item => {
                    consumed += item.packageCount;
                })
                return consumed;
            }
        },
        watch: {
            source() {
                this.model = this.source;
            },
        },
        methods: {
            updaterecord(id, e) {
                this.$nextTick(() => this.$emit("is-sync", { id: id, model: e }));
            },
            onAdd() { this.$emit("is-add"); },
            onDelete(index) { this.$emit("is-delete", index); },
            onCopyNext(index) { this.$emit("is-copy-next", index); },
            onCopyValue(e) { this.$emit("is-copy", e); },
        },
        mounted() {
            this.model = this.source;
        }
    }
</script>

