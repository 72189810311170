<template>
    <div class="card">
        <div class="p-grid">
            <div class="p-col-12 p-md-3">
                <h6>Initial State</h6>
                <form-boolean id="RightSelectInitial" label="Select Button (Right Align)" :value="model.transaction.select" @is-sync="model.transaction.select = $event.value" />
                <form-boolean id="RightSwithIntial" label="Swith Button (Right Align)" type="switch" :value="model.transaction.switch" @is-sync="model.transaction.switch = $event.value" />
                <form-boolean id="RightToggleInitial" label="Toggle Button (Right Align)" type="toggle" :value="model.transaction.toggle" @is-sync="model.transaction.toggle = $event.value" />
                <form-boolean id="RightCheckBoxInitial" label="Check Box (Right Align)" type="checkbox" :value="model.transaction.checkBox" @is-sync="model.transaction.checkBox = $event.value" />
            </div>
            <div class="p-col-12 p-md-3">
                <h6>Initial Disabled State</h6>
                <form-boolean :disabled="true" id="RightSelectInitial" label="Select Button (Right Align)" :value="model.transaction.select" @is-sync="model.transaction.select = $event.value" />
                <form-boolean :disabled="true" id="RightSwithIntial" label="Swith Button (Right Align)" type="switch" :value="model.transaction.switch" @is-sync="model.transaction.switch = $event.value" />
                <form-boolean :disabled="true" id="RightToggleInitial" label="Toggle Button (Right Align)" type="toggle" :value="model.transaction.toggle" @is-sync="model.transaction.toggle = $event.value" />
                <form-boolean :disabled="true" id="RightCheckBoxInitial" label="Check Box (Right Align)" type="checkbox" :value="model.transaction.checkBox" @is-sync="model.transaction.checkBox = $event.value" />
            </div>
            <div class="p-col-12 p-md-3">
                <h6>Transaction State</h6>
                <form-boolean textPosition="left" id="LeftSelectInitial" label="Select Button (Left Align)" :value="model.transaction.select" @is-sync="model.transaction.select = $event.value" />
                <form-boolean textPosition="left" id="LeftSwithIntial" label="Swith Button (Left Align)" type="switch" :value="model.transaction.switch" @is-sync="model.transaction.switch = $event.value" />
                <form-boolean textPosition="left" id="LeftToggleInitial" label="Toggle Button (Left Align)" type="toggle" :value="model.transaction.toggle" @is-sync="model.transaction.toggle = $event.value" />
                <form-boolean textPosition="left" id="LeftCheckBoxInitial" label="Check Box (Left Align)" type="checkbox" :value="model.transaction.checkBox" @is-sync="model.transaction.checkBox = $event.value" />
            </div>
            <div class="p-col-12 p-md-3">
                <h6>Disabled State</h6>
                <form-boolean :disabled="true" textPosition="left" id="LeftSelectInitial" label="Select Button (Left Align)" :value="model.transaction.select" @is-sync="model.transaction.select = $event.value" />
                <form-boolean :disabled="true" textPosition="left" id="LeftSwithIntial" label="Swith Button (Left Align)" type="switch" :value="model.transaction.switch" @is-sync="model.transaction.switch = $event.value" />
                <form-boolean :disabled="true" textPosition="left" id="LeftToggleInitial" label="Toggle Button (Left Align)" type="toggle" :value="model.transaction.toggle" @is-sync="model.transaction.toggle = $event.value" />
                <form-boolean :disabled="true" textPosition="left" id="LeftCheckBoxInitial" label="Check Box (Left Align)" type="checkbox" :value="model.transaction.checkBox" @is-sync="model.transaction.checkBox = $event.value" />
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'FormBooleanComponents',
        data() {
            return {
                model: {
                    transaction: {
                        select: false,
                        switch: false,
                        toggle: false,
                        checkBox: false
                    }
                }
            }
        }
    }
</script>

