<template>
    <div :id="`area${id}`" :class="[{'is-container': true}, {'p-field': true }, {'p-col-12': true }, {'p-fluid': autoFit}]">
        <div :class="[{ 'p-float-label': floatLabel }, { 'p-fixed-label': !floatLabel }]">
            <div :class="[{'p-inputgroup': true}, {'is-disabled': disabled}]">
                <slot name="leftSide" :data="(model.invalid && activity.submitted)"></slot>
                <div :class="[{'p-object-group': true}, {'is-disabled': disabled}]" style="display: inline-flex; width: 100% !important; overflow-x: hidden;">
                    <slot name="default" :data="(model.invalid && activity.submitted)"></slot>
                    <label v-if="!hideLabel" :for="id" :class="[{'p-label-text': true}, {'conditional': conditional}, {'required': required || (id.toLowerCase().indexOf('postalcode') !== -1 ? required : modelState?.required)}]"><slot name="label"><span v-html="`${label ?? $filters.titleize(id)}`"></span></slot></label>
                </div>
                <slot name="rightSide" :data="(model.invalid && activity.submitted)"></slot>
            </div>
        </div>
        <small class="p-error">{{ vError ?? model.error  }}</small>
    </div>
</template>
<script>
    export default {
        name: "EditorTemplate",
        props: {
            id: { type: String, required: true, validator: (value) => /^[a-zA-Z0-9_]*$/.test(value) },
            // Display Type
            autoFit: { type: Boolean, default: true },
            // Label Related
            label: { type: String },
            hideLabel: { type: Boolean, default: false },
            floatLabel: { type: Boolean, default: true },
            // Data
            required: { type: Boolean, default: false },
            conditional: { type: Boolean, default: false },
            nullable: { type: Boolean, default: false },
            v$: { type: Object },
            vID: { type: String },
            vBase: { type: String },
            vError: { type: String, default: null },
            disabled: { type: Boolean, default: false }
        },
        data() {
            return {
                activity: { submitted: false },
                model: {
                    invalid: false,
                    error: null
                },
                modelState: {
                    required: false
                }
            }
        },
        watch: {
            v$: {
                handler() {
                    this.model.error = null;
                    this.setInvalid(false);
                    if (this.$vm.$submitted || this.vID) {
                        try {
                            var vm = this.v$.model;
                            if (this.vBase)
                                vm = this.v$[this.vBase];
                            let model = vm[this.vID ?? this.id];
                            if (model) {
                                var entity = {};
                                Object.keys(model).forEach((key) => { entity[key.replace("$", "")] = typeof (model[key]) === 'boolean' ? model[key] === "on" ? true : model[key] : model[key]; });
                                if (entity.silentErrors && entity.silentErrors.length > 0)
                                    this.model.error = entity.silentErrors[0].$message.replace('Value', this.label ?? this.$filters.titleize(this.id));
                                this.setInvalid(entity.invalid || entity.error);
                            }
                        }
                        catch { /* No Operation */ }
                    }
                    if (this.v$ && this.v$.model) {
                        let model = this.v$.model[this.vID ?? this.id];
                        if (model)
                            this.modelState = { required: model.required ? true : false }
                    }
                },
                deep: true
            }
        },
        methods: { setInvalid(value) { this.model.invalid = value; } },
        async updated() { this.activity.submitted = this.$vm.$submitted; }
    };
</script>