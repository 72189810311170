<template>
    <div>
        <div v-if="model.code">
            <h4 style="margin-bottom: 1.5rem;">SHOP DETAILS</h4>
            <div>
                <div style="padding: 0 1rem;">
                    <h5 style="font-size: 2rem; text-transform: uppercase">{{ model.name }}</h5>
                    <p style="margin-bottom: 0.25rem; margin-left: 1rem;"><i class="fa fa-store fa-fw" style="margin-right: 1rem !important; font-size: 0.9rem !important;"></i> {{ model?.address1 ? model?.address1 + ', ' : '' }}{{ model?.address2 ? model?.address2 + ', ' : '' }}{{ model?.address3 ? model?.address3 + ', ' : '' }}{{ model?.city ? model?.city + ', ' : '' }}{{ model?.state ? model?.state + ', ' : '' }}{{ model?.postalCode ? model?.postalCode + ', ' : '' }}{{ model?.country ? model?.country : '' }}</p>
                    <p style="margin-bottom: 0.25rem; margin-left: 1rem;"><i class="fa fa-globe fa-fw" style="margin-right: 1rem !important; font-size: 0.9rem !important;"></i> <a :href="`https:${model.storeDomain ?? model.shopDomain}`" target="_blank">{{ model.storeDomain ?? model.shopDomain }}</a></p>
                    <h5>CONTACT PERSON</h5>
                    <p style="margin-bottom: 0.25rem; margin-left: 1rem;"><i class="fa fa-user fa-fw" style="margin-right: 1rem !important; font-size: 0.9rem !important;"></i> {{ model.contact }}</p>
                    <p style="margin-bottom: 0.25rem; margin-left: 1rem;"><i class="fa fa-at fa-fw" style="margin-right: 1rem !important; font-size: 0.9rem !important;"></i> <a :href="`mailto:${model.email}`">{{ model.email }}</a></p>
                    <p style="margin-bottom: 0.25rem; margin-left: 1rem;"><i class="fa fa-phone fa-fw mr-5" style="margin-right: 1rem !important; font-size: 0.9rem !important;"></i> {{ model.telephone }}</p>
                </div>
            </div>
        </div>
        <div v-else>
            <h4 style="margin-bottom: 1.5rem;">INSTRUCTIONS</h4>
            <div>
                <p><b>How to acquire credentials?</b></p>
                <p>Visit this link for the detailed instructions: <a style="font-weight: bold;">How to generate {{ platform?.name }} credentials?</a></p>
                <p><b>What do we acquire when verifying your credential?</b></p>
                <p style="text-align: justify">
                    Once you have completed the account details, the store must be authenticated to establish connection between COSMOS and customer's e-Commerce platform. The process will retrieve the following:

                    <ul>
                        <li>The basic store details.</li>
                        <li>The fulfilment location details.</li>
                        <li>The granted permissions.</li>
                        <li>The available webhooks (dependent of platform support and granted permission).</li>
                    </ul>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "PlatformInstructionShopForm",
        props: {
            modelValue: { type: Object, default: null },
            modelPlatform: { type: Object, default: null },
            modelAccessScope: { type: Object, default: null },
        },
        data() {
            return {
                model: {
                    code: null,
                    name: null,
                    address1: null,
                    address2: null,
                    address3: null,
                    city: null,
                    state: null,
                    stateCode: null,
                    postalCode: null,
                    country: null,
                    countryID: null,
                    contact: null,
                    telephone: null,
                    email: null,
                    shopDomain: null,
                    storeDomain: null,
                    timezone: null,
                    ianaTimezone: null,
                    supportedCurrencies: null,
                    defaults: {
                        primaryLocationID: null,
                        currency: null,
                        weightUnit: null
                    },
                    taxConfiguration: {
                        taxInclusive: false,
                        taxShipping: false
                    }
                },
                platform: null,
                credentials: {},
                accessScope: null
            }
        },
        watch: {
            modelValue() {
                this.model = this.modelValue;
            },
            modelPlatform() {
                this.platform = this.modelPlatform;
            },
            modelAccessScope() {
                this.accessScope = this.modelAccessScope;
            }
        },
        created() {
            if (this.modelValue)
                this.model = this.modelValue;
            if (this.modelPlatform)
                this.platform = this.modelPlatform;
            if (this.modelAccessScope)
                this.accessScope = this.modelAccessScope;
        }
    }
</script>