<template>
    <cosmos-grid :id="id" :searchFilter="['code', 'name']" :source="sources.integrations" sourceType="array" :paged="false" :autoGenerate="false" :deleteDisplayFields="deleteDisplay" :showAdd="false" :showDelete="false" :showExport="false" :showOperation="false" :pageSize="5">
        <pv-grid-column field="cosmosData.code" header="CODE" headerStyle="width: 4.7rem; max-width: 4.7rem; padding-left: 15px; padding-top: .8rem !important;" style="width: 4.7rem; max-width: 4.7rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div>{{ slotProps.data?.carrierData?.code ?? slotProps.data?.cosmosData?.code }}</div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="cosmosData.code" header="COUNTRY NAME" headerStyle="padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div><i :class="`flag flag-${slotProps.data?.cosmosData?.code?.toLowerCase()}`"></i> &nbsp; {{ slotProps.data?.cosmosData?.altName ?? slotProps.data?.cosmosData?.name }}</div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="cosmosData.categoryName" header="POSTAL CODE GROUP" headerStyle="width: 14.7rem; max-width: 14.7rem; padding-left: 15px; padding-top: .8rem !important;" style="width: 14.7rem; max-width: 14.7rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;"></pv-grid-column>
        <pv-grid-column field="cosmosData.city" header="CITY" headerStyle="width: 7.7rem; max-width: 7.7rem; padding-left: 15px; padding-top: .8rem !important;" style="width: 7.7rem; max-width: 7.7rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;"></pv-grid-column>
        <pv-grid-column field="cosmosData.state" header="STATE" headerStyle="width: 7.7rem; max-width: 7.7rem; padding-left: 15px; padding-top: .8rem !important;" style="width: 7.7rem; max-width: 7.7rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;"></pv-grid-column>
        <pv-grid-column field="cosmosData.postalCodeFROM" header="POSTAL CODES" headerStyle="width: 9.7rem; max-width: 9.7rem; padding-left: 15px; padding-top: .8rem !important;" style="width: 9.7rem; max-width: 9.7rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem">{{ slotProps.data.cosmosData.postalCodeFROM }} {{ slotProps.data.cosmosData.postalCodeTO }}</p>
        </pv-grid-column>
        <pv-grid-column field="carrierData.name" header="CARRIER PROVIDED NAME" headerStyle="padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;"></pv-grid-column>
        <pv-grid-column field="carrierData.city" header="CITY" headerStyle="width: 7.7rem; max-width: 7.7rem; padding-left: 15px; padding-top: .8rem !important;" style="width: 7.7rem; max-width: 7.7rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;"></pv-grid-column>
        <pv-grid-column field="carrierData.state" header="STATE" headerStyle="width: 7.7rem; max-width: 7.7rem; padding-left: 15px; padding-top: .8rem !important;" style="width: 7.7rem; max-width: 7.7rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;"></pv-grid-column>
        <pv-grid-column field="carrierData.postalCodeFROM" header="POSTAL CODES" headerStyle="width: 9.7rem; max-width: 9.7rem; padding-left: 15px; padding-top: .8rem !important;" style="width: 9.7rem; max-width: 9.7rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem">{{ slotProps.data.carrierData.postalCodeFROM }} {{ slotProps.data.carrierData.postalCodeTO }}</p>
        </pv-grid-column>

    </cosmos-grid>
</template>
<script>
    export default {
        name: "IntegrationGrid",
        props: {
            id: { type: String },
            code: { type: String, default: null },
            provider: { type: String, default: null }
        },
        data() {
            return {
                sources: {
                    integrations: []
                }
            }
        },
        watch: {
            provider() {
                this.getSource();
            }
        },
        methods: {
            getSource() {
                this.sources.integrations = [];
                this.$nextTick(() => {
                    if (this.provider) {
                        this.$store.dispatch("providercountry/getURL", { url: `/services/settings/serviceprovidercountry/byprovider/${this.provider}` })
                            .then((response) => {
                                if (response && response.success) {
                                    this.sources.integrations = response?.result ?? response ?? [];
                                }
                            });
                    }
                })
            }
        },
        created() { this.getSource(); }
    }
</script>