<template>
    <editor-template :id="id" :floatLabel="false" :hideLabel="true" :preText="showPreText" :postText="showPostText" :validation="errors" :required="required">
        <template v-if="showPreText" #preText>
            <i v-if="preIcon" :class="preIcon"></i>
            <span v-else v-html="preText" :class="[{ 'required': required }]"></span>
        </template>
        <!--<label>{{ label ?? $filters.titleize(id) }}</label>-->
        <div class="p-inputtext p-component p-fluid" style="width: 100%;">
            <div class="p-grid">
                <div class="p-col-12" style="text-align: center; padding-top: 10px;">
                    <img v-if="valueInput" :src="`data:${valueInput.contentType};base64, ${valueInput.file}`" style="height: 150px; width: 150px; object-fit: cover; border-radius: 100px; background-color: var(--surface-d)" />
                    <img v-else src="/_assets/img/dp-noimage.png" style="height: 150px; width: 150px; object-fit: cover; border-radius: 100px; background-color: var(--surface-d)" />
                </div>
                <div :class="[{ 'p-col-6': (valueInput ? true : false) }, { 'p-col-12': (valueInput ? false : true) }]" :style="[{ 'text-align': (valueInput ? 'right' : 'center') }]">
                    <pv-file id="image" mode="basic" :chooseLabel="labelData" accept="image/*" :auto="true" url="https://cosmos-api.localhost.com/file/upload" @upload="onUpload" :multiple="false"></pv-file>
                </div>
                <div :class="[{ 'p-col-6': valueInput }]">
                    <pv-button v-if="valueInput" label="Remove Image" icon="pi pi-fs pi-trash" @click="onDelete"></pv-button>
                </div>
            </div>
        </div>
        <template v-if="!hideLabel" #label>
            {{ label ?? $filters.titleize(id) }}
        </template>
        <template v-if="showPostText" #postIcon>
            <i v-if="postIcon" :class="postIcon"></i>
            <span v-else v-html="postText"></span>
        </template>
    </editor-template>
</template>

<script>
    export default {
        name: 'ImageUploader',
        emits: ["is-sync"],
        props: {
            id: { type: String, required: true, validator: (value) => /^[a-zA-Z0-9_]*$/.test(value) },
            value: { type: Object },
            hideLabel: { type: Boolean, default: false },
            floatLabel: { type: Boolean, default: false },
            label: { type: String },
            placeholder: { type: String },
            tooltip: { type: String },
            preIcon: { type: String },
            postIcon: { type: String },
            preText: { type: String },
            postText: { type: String },
            fluid: { type: Boolean, default: true },
            filled: { type: Boolean, default: false },
            // Password Configuration
            showPasswordMeter: { type: Boolean, default: true },
            // Formatted Text
            format: { type: String, default: null },
            // Text Area
            rows: { type: Number, default: 5 },
            autoHeight: { type: Boolean, default: false },
            errors: { type: Object }
        },
        data() {
            return {
                valueInput: {
                    file: null,
                    contentType: null,
                }
                , maxLength: -1, required: false
                }
            },
        computed: {
            labelData() {
                if (this.valueInput)
                    return "Change Image";
                return "Select Image"
            },
            //maxLength() {
            //    //let model = this.errors.model[this.id];
            //    //var entity = {};
            //    //Object.keys(model.maxLength).forEach((key) => { entity[key.replace("$", "")] = typeof (model[key]) === 'boolean' ? model[key] === "on" ? true : model[key] : model[key]; });
            //    //console.log(entity);
            //    //if (entity.params.max)
            //    //    return entity.maxLength.params.max;
            //    return -1;
            //},
            showPreText() {
                if (this.preIcon || this.preText)
                    return true;
                return false;
            },
            showPostText() {
                if (this.postIcon || this.postText)
                    return true;
                return false;
            }
        },
        watch: {
            valueInput() {
                this.$emit('is-sync', this.valueInput);
            },
            errors() {
                try {
                    let model = this.errors.model[this.id];
                    var entity = {};
                    Object.keys(model).forEach((key) => { entity[key.replace("$", "")] = typeof (model[key]) === 'boolean' ? model[key] === "on" ? true : model[key] : model[key]; });
                    if (model.maxLength)
                        this.maxLength = entity.maxLength.$params.max;
                    if (model.required)
                        this.required = true;
                }
                catch {
                    // No Operation
                }
            }
        },
        methods: {
            onUpload(e) {
                var image = JSON.parse(e.xhr.responseText);
                this.valueInput = image;
            },
            onDelete() {
                this.valueInput = null;
            }
        },
        created() {
            this.valueInput = this.value;
        },
        updated() {
            this.valueInput = this.value;
        }
    }
</script>