<template>
    <div class="card">
        <div class="p-grid">
            <div class="p-col-12 p-md-3">
                <h6>Initial State</h6>
                <form-numeric id="IntegerInitial" :value="model.blank.integer" @is-sync="model.blank.integer = $event.value" label="Integer" />
                <form-numeric id="DecimalInitial" type="decimal" :value="model.blank.decimal" @is-sync="model.blank.decimal = $event.value" label="Decimal" :decimalDigit="4" />
                <form-numeric id="CurrencyInitial" type="currency" :value="model.blank.currency" @is-sync="model.blank.currency = $event.value" label="Currency" />
            </div>
            <div class="p-col-12 p-md-3">
                <h6>Initial Disabled State</h6>
                <form-numeric :disabled="true" id="IntegerInitial" :value="model.blank.integer" @is-sync="model.blank.integer = $event.value" label="Integer" />
                <form-numeric :disabled="true" id="DecimalInitial" type="decimal" :value="model.blank.decimal" @is-sync="model.blank.decimal = $event.value" label="Decimal" :decimalDigit="4" />
                <form-numeric :disabled="true" id="CurrencyInitial" type="currency" :value="model.blank.currency" @is-sync="model.blank.currency = $event.value" label="Currency" />

            </div>
            <div class="p-col-12 p-md-3">
                <h6>Transaction State</h6>
                <form-numeric id="IntegerTransaction" :value="model.transaction.integer" @is-sync="model.transaction.integer = $event.value" label="Integer" />
                <form-numeric id="DecimalTransaction" type="decimal" :value="model.transaction.decimal" @is-sync="model.transaction.decimal = $event.value" label="Decimal" :decimalDigit="4" />
                <form-numeric id="CurrencyTransaction" type="currency" :value="model.transaction.currency" @is-sync="model.transaction.currency = $event.value" label="Currency" />

                <form-numeric id="Integer" :value="model.numeric.integer" quantifiedSuffix="unit" @is-sync="model.numeric.integer = $event.value" label="With Quantified Suffix" />
                <form-numeric id="Decimal" type="decimal" :value="model.numeric.decimal" @is-sync="model.numeric.decimal = $event.value" :decimalDigit="4" suffix="kg/unit" label="With Fixed Suffix" />
                <form-numeric id="Currency" type="currency" :value="model.numeric.currency" @is-sync="model.numeric.currency = $event.value" currency="GBP" prefix="Additional" suffix="/consignment" label="With Prefix and Suffix" />
            </div>
            <div class="p-col-12 p-md-3">
                <h6>Disabled State</h6>
                <form-numeric :disabled="true" id="IntegerInitial" :value="model.transaction.integer" @is-sync="model.transaction.integer = $event.value" label="Integer" />
                <form-numeric :disabled="true" id="DecimalInitial" type="decimal" :value="model.transaction.decimal" @is-sync="model.transaction.decimal = $event.value" label="Decimal" :decimalDigit="4" />
                <form-numeric :disabled="true" id="CurrencyInitial" type="currency" :value="model.transaction.currency" @is-sync="model.transaction.currency = $event.value" label="Currency" />

                <form-numeric :disabled="true" id="Integer" :value="model.numeric.integer" quantifiedSuffix="unit" @is-sync="model.numeric.integer = $event.value" label="With Quantified Suffix" />
                <form-numeric :disabled="true" id="Decimal" type="decimal" :value="model.numeric.decimal" @is-sync="model.numeric.decimal = $event.value" :decimalDigit="4" suffix="kg/unit" label="With Fixed Suffix" />
                <form-numeric :disabled="true" id="Currency" type="currency" :value="model.numeric.currency" @is-sync="model.numeric.currency = $event.value" currency="GBP" prefix="Additional" suffix="/consignment" label="With Prefix and Suffix" />

            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'FormNumericComponents',
        data() {
            return {
                model: {
                    numeric: {
                        integer: 123456,
                        decimal: 0.1258,
                        currency: 12.25
                    },
                    blank: {
                        integer: null,
                        decimal: null,
                        currency: null
                    },
                    transaction: {
                        integer: 1234567,
                        decimal: 12.2458,
                        currency: 1259.86,
                    }

                }
            }
        }
    }
</script>

