<template>
    <div class="content-section header">
        <div class="feature-intro">
            <h1><span v-html="`${title}`"></span></h1>
            <p>{{ subTitle }}</p>
            <div v-if="note"><span v-html="`${note}`"></span></div>
        </div>
    </div>
    <div class="content-section body" style="min-height: calc(100vh - 200px);">
        <div class="card" style="padding: 0;">
            <slot name="default" />
        </div>
    </div>
</template>
<script>
    export default {
        name: 'FormWrapperLayout',
        props: {
            title: { type: String },
            subTitle: { type: String },
            note: { type: String }
        }
    }
</script>