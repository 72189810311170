<template>
    <form-wrapper title="Carrier Accounts" sub-title="List of configured service provider accounts">
        <cosmos-grid id="ProviderAccountID" :searchFilters="searchField" :deleteColumns="deleteDisplay" :autoGenerate="false" :columns="columns" :deleteDisplayFields="deleteDisplay" :showExport="false" :operationWidth="7" groupBy="providerCode">
            <template #groupheader="slotProps">
                <span class="image-text" style="text-transform: uppercase; font-weight: 700 !important">[{{ slotProps.data.providerCode }}] {{ providers.filter(x => x.id === slotProps.data.serviceProviderID)[0].name }}</span>
            </template>
            <pv-grid-column field="code" header="Code" headerStyle="width: 25rem;" />
            <pv-grid-column field="name" header="Name" />
            <pv-grid-column field="accountNumber" header="Account Number" headerStyle="width: 10rem;" />
            <pv-grid-column field="name" header="Contract Country"  headerStyle="width: 15rem;">
                <template #body="slotProps">
                    <i :class="`flag flag-${countries.filter(x => x.id === slotProps.data.contractCountryID)[0].iso2.toLowerCase()}`"></i> &nbsp;
                    {{ countries.filter(x => x.id === slotProps.data.contractCountryID)[0].altName ?? countries.filter(x => x.id === slotProps.data.contractCountryID)[0].name }}
                </template>
            </pv-grid-column>
        </cosmos-grid>
    </form-wrapper>
</template>
<script>
    export default {
        name: 'ServiceProviderGrid',
        data() {
            return {
                searchField: ['code', 'name'],
                deleteDisplay: ['code', 'name']
            }
        },
        computed: {
            providers() { return this.$vm.$providers },
            countries() { return this.$vm.$countries },
            currencies() { return this.$vm.currencies }
        },
        mounted() { this.loading = false; },
    }
</script>
<style>
    .tradetype { margin-right: 5px !important; padding: 0 !important; }
</style>