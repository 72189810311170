<template>
    <is-loader v-if="activity.loading.form"></is-loader>
    <form method="post" autocomplete="off" @submit.prevent="onSubmit">
        <div class="content-section header">
            <div class="feature-intro">
                <slot name="header">
                    <h1><span>{{ title ?? router.title }}</span></h1>
                    <p>{{ subTitle ?? router.subtitle }}</p>
                </slot>
            </div>
        </div>
        <div class="content-section body" style="position:relative;">
            <form-toolbar :viewBack="viewBack" :saveOnly="saveOnly" :isDirty="isModelDirty || modelState.$anyDirty || modelState.$isDirty" :modelState="modelState" :identity="model?.mask" :viewOnly="(model?.viewOnly ?? viewOnly ?? false)" :showGuideHelper="showGuideHelper ?? false" back="agents" @is-reset="onResetForm" @is-send="onSendForm" @is-guided="onGuidedForm($event)" @is-clone="onClone($event)" :showCloneButton="showCloneButton" :showSendButton="showSendButton"></form-toolbar>
            <div class="card" style="position:relative; right: 0;">
                <slot :data="modelState"></slot>
            </div>
        </div>
    </form>
</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    export default {
        name: 'RecordFormTemplate',
        mixins: [mixins.FORM],
        watch: {
            model: {
                handler() {
                    try {
                        this.modelState.model.$touch();
                    }
                    catch {
                        this.modelState.$touch();
                    }
                    this.isModelDirty = true;
                },
                deep: true
            },
            validation() {
                this.modelState = null;
                this.update(false);
                if (this.submitted) {
                    let model = this.validation[this.id];
                    if (model) {
                        var entity = {};
                        Object.keys(model).forEach((key) => { entity[key.replace("$", "")] = typeof (model[key]) === 'boolean' ? model[key] === "on" ? true : model[key] : model[key]; });
                        if (entity.silentErrors && entity.silentErrors.length > 0)
                            this.error = entity.silentErrors[0].$message.replace('Value', this.$filters.titleize(this.id));
                        this.update(entity.invalid);
                    }
                }
            }
        },
        validations() {
            return this.validators;
        }
    }
</script>
<style>
    .p-tooltip-text {
        font-size: 0.75rem !important;
    }
</style>