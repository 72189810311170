<template>
    <cosmos-grid id="packageCountryGrid" sourceType="array" :source="source?.data ?? []" :showCheckbox="false" :sourcePaged="true" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :showExport="false" :showOperation="false" :showHeader="false" @is-refresh="getSource()">
        <pv-grid-column v-if="showCountry" field="collectionCountry" header="COLLECTION" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <i :class="`flag flag-${$vm.$countries.filter((x) =>  slotProps.data?.collectionCountry.startsWith(x.code))[0]?.code.toLowerCase()}`" style="margin-right: 0.10rem"></i>
                    {{ slotProps.data.collectionCountry}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !showCountry }]" field="destinationCountry" header="DELIVERY" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <i :class="`flag flag-${$vm.$countries.filter((x) =>  slotProps.data?.destinationCountry.startsWith(x.code))[0]?.code.toLowerCase()}`" style="margin-right: 0.10rem"></i>
                    {{ slotProps.data.destinationCountry}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !showYearWeek }]" field="parcelYear" header="YEAR" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.parcelYear}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !showYearWeek }]" field="parcelWeek" header="WEEK" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.parcelWeek}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !showTransit }]" field="parcelTransit" header="TRANSIT DAYS(S)" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.parcelTransit}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !showProvider }]" field="carrierName" header="CARRIER" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.carrierName}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !showProduct }]" field="serviceName" header="SERVICE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.serviceName}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !showWeight }]" field="totalWeight" header="TOTAL WEIGHT (KG)" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.totalWeight}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !showCBM }]" field="cbm" header="TOTAL CBM" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.cbm}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="parcelCount" header="PACKAGE COUNT" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.parcelCount}}
                </div>
            </template>
        </pv-grid-column>

    </cosmos-grid>
</template>
<script>
    import { mapGetters } from 'vuex';
    import { useVuelidate } from '@vuelidate/core';
    export default {
        name: 'reportGrid',
        setup: () => ({ v$: useVuelidate() }),
        props: {
            formData: { type: Object }
        },
        data() {
            return {
                id: 'Rei',
                filename: 'export',
                filter: {},
                searchFilter: ['collectionCountry', 'destinationCountry', 'carrierName','serviceName'],
                activity: { loading: true },
                source: {
                    total: 0,
                    data: []
                }
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            scopeWarehouseDS() {
                return this.$config.common.$warehouse(this.user?.environmentID ?? -100, this.user);
            },
            showCountry() {
                return this.$filters.getFlags(this.formData.handledBy ?? 0).findIndex(x => x === 1) != -1;
            },
            showYearWeek() {
                return this.$filters.getFlags(this.formData.handledBy ?? 0).findIndex(x => x === 2) != -1;
            },
            showTransit() {
                return this.$filters.getFlags(this.formData.handledBy ?? 0).findIndex(x => x === 4) != -1;
            },
            showWeight() {
                return this.$filters.getFlags(this.formData.additionalType ?? 0).findIndex(x => x === 1) != -1;
            },
            showCBM() {
                return this.$filters.getFlags(this.formData.additionalType ?? 0).findIndex(x => x === 2) != -1;
            },
            showProvider() {
                return this.$filters.getFlags(this.formData.additionalType ?? 0).findIndex(x => x === 4) != -1;
            },
            showProduct() {
                return this.$filters.getFlags(this.formData.additionalType ?? 0).findIndex(x => x === 8) != -1;
            },
            apiData() {
                // this.$filters.formatDateWithFormat(this.model.request_CollectionDate, "YYYY-MM-DD")
                return {
                    ServiceHeader: {
                        Username: "TEMP",
                        Password: "TESTESTEDET",
                        ClientID: "TESTTESTTESTTESTTEST",
                        ClientSecret: "TESTEDREDREDTETEDEDETEREDEDEREDE"
                    },
                    Request: {
                        DC: this.formData?.warehouseID > 0 ? this.scopeWarehouseDS.filter(x => x.id === this.formData.warehouseID)[0]?.code : null,
                        ScopeDetail: {
                            CollectionDate: {
                                From: this.$filters.formatDateWithFormat(this.formData.collectionDateFrom ?? new Date(), "YYYY-MM-DD"),
                                To: this.$filters.formatDateWithFormat(this.formData.collectionDateTo ?? new Date(), "YYYY-MM-DD")
                            },
                            ByCountry: this.showCountry,
                            ByWeek: this.showYearWeek,
                            ByTransit: this.showTransit,
                            PerWeight: this.showWeight,
                            PerCBM: this.showCBM,
                            PerCarrier: this.showProvider,
                            PerService: this.showProduct
                        }
                    }
                }

            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            'formData': {
                handler() {
                    this.$nextTick(() => {
                        this.getSource();
                    });
                }, deep: true
            }
        },
        methods: {
            async getSource() {
                   await this.$axios.post(this.$config.config.endpoint.api + `/services/report/kpi/parcelcountry`.replaceAll("//", "/"), this.apiData)
                        .then((response) => {
                            this.source.data = response?.data?.result ?? [];
                            this.source.total = this.source.data.length;
                        })
                        .catch((ex) => {
                            this.source.data = [];
                            this.source.total = 0;
                            console.log(ex);
                        });
                

            },
        },
        async created() {
            this.loading = false;
          
        },
        async mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            this.loading = false;
           
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>