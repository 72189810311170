<template>
    <div style="width: 100%">
        <h4 style="margin-bottom: 2rem;">DEFAULTS</h4>
        <div v-if="oneColumn" class="p-grid">
            <div class="p-col-12">
                <form-lookup id="Warehouse" :disabled="disabled" disabledType="object" :source="warehouses" :defaultValue="warehouses[0]?.id" v-model="model.warehouseID" :required="true" :v$="v$" />
                <form-lookup id="Currency" :disabled="disabled" disabledType="object" :source="$vm.$currencies" :defaultValue="3" v-model="model.currencyID" :required="true" :v$="v$" vID="currencyID" />
                <form-input id="Content" v-model="model.content" :disabled="disabled" :required="true" :v$="v$" vID="content" ph="Men's shirt and accessories" :showCopy="false" />
                <form-input id="Email" v-model="model.email" :disabled="disabled" :required="true" :v$="v$" vID="email" ph="username@domain.com" :showCopy="false" />
                <form-input id="Telephone" v-model="model.telephone" :disabled="disabled" :required="true" :v$="v$" vID="telephone" ph="4472100001" :showCopy="false" />
                <form-lookup id="PackagingType" type="enum" source="serviceproviderproductpackagingtype" v-model="model.packagingType" :nullable="false" :disabled="disabled" disabledType="object" :v$="v$" vID="packagingType" />
                <form-lookup id="Incoterm" type="enum" source="incotermtype" v-model="model.incoterm" :nullable="false" :disabled="disabled" disabledType="object" :v$="v$" vID="incoterm" />
                <form-lookup id="WeightUnit" type="enum" source="weightunit" v-model="model.weightUnit" :nullable="false" :disabled="disabled" disabledType="object" :v$="v$" vID="weightUnit" />
                <form-lookup id="LengthUnit" type="enum" source="lengthunit" v-model="model.dimensionUnit" :nullable="false" :disabled="disabled" disabledType="object" :v$="v$" vID="dimensionUnit" />
            </div>
        </div>
        <div v-else class="p-grid">
            <div class="p-col-6">
                <form-lookup id="Warehouse" :disabled="disabled" disabledType="object" :source="warehouses" :defaultValue="warehouses[0]?.id" v-model="model.warehouseID" :required="true" :v$="v$" />
                <form-lookup id="Currency" :disabled="disabled" disabledType="object" :source="$vm.$currencies" :defaultValue="3" v-model="model.currencyID" :required="true" :v$="v$" vID="currencyID" />
                <form-input id="Content" v-model="model.content" :disabled="disabled" :required="true" :v$="v$" vID="content" ph="Men's shirt and accessories" :showCopy="false" />
                <form-input id="Email" v-model="model.email" :disabled="disabled" :required="true" :v$="v$" vID="email" ph="username@domain.com" :showCopy="false" />
                <form-input id="Telephone" v-model="model.telephone" :disabled="disabled" :required="true" :v$="v$" vID="telephone" ph="i.e. 4472100001" :showCopy="false" />
            </div>
            <div class="p-col-6">
                <form-lookup id="PackagingType" type="enum" source="serviceproviderproductpackagingtype" v-model="model.packagingType" :nullable="false" :disabled="disabled" disabledType="object" :v$="v$" vID="packagingType" />
                <form-lookup id="Incoterm" type="enum" source="incotermtype" v-model="model.incoterm" :nullable="false" :disabled="disabled" disabledType="object" :v$="v$" vID="incoterm" />
                <form-lookup id="WeightUnit" type="enum" source="weightunit" v-model="model.weightUnit" :nullable="false" :disabled="disabled" disabledType="object" :v$="v$" vID="weightUnit" />
                <form-lookup id="LengthUnit" type="enum" source="lengthunit" v-model="model.dimensionUnit" :nullable="false" :disabled="disabled" disabledType="object" :v$="v$" vID="dimensionUnit" />
            </div>
        </div>

    </div>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    import { required, maxLength, minLength, helpers, email } from '@vuelidate/validators';
    /** CUSTOM VALIDATOR **/
    const regex = (param) => helpers.withParams({ type: 'regex', value: param }, (value) => value.match(param) != null);
    export default {
        name: "StoreDefaultsForm",
        setup: () => ({ v$: useVuelidate({ $scope: true }) }),
        props: {
            customerID: { type: Number, default: null },
            modelValue: { type: Object, default: () => { } },
            modelAccessScope: { type: Object, default: null },
            modelInvalid: { type: Boolean, default: false },
            oneColumn: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false }
        },
        data() {
            return {
                model: {
                    warehouseID: 0,
                    currencyID: 0,
                    content: null,
                    email: null,
                    telephone: null,
                    packagingType: 4,
                    incoterm: 16,
                    weightUnit: 100,
                    dimensionUnit: 100
                },
                accessScope: null,
            };
        },
        watch: {
            modelValue() { this.model = this.modelValue ?? { failed: null, success: null } },
            modelAccessScope() { this.accessScope = this.modelAccessScope; },
            model() { this.onSync(); },
            v$() { this.$emit("update:modelInvalid", this.v$.$error || this.v$.$invalid); }
        },
        computed: {
            warehouses() { return this.accessScope.warehouses.filter(x => x.customers.filter(y => y === this.customerID).length > 0) ?? []; }
        },
        validations() {
            /** DYNAMIC VALIDATION **/
            let state = {
                model: {
                    warehouseID: { $autoDirty: true, required },
                    currencyID: { $autoDirty: true, required },
                    content: { $autoDirty: true, required, minLength: minLength(5), maxLength: maxLength(255) },
                    email: { $autoDirty: true, email, required },
                    telephone: { $autoDirty: true, required, minLength: minLength(7), maxLength: maxLength(15), regex: helpers.withMessage("The field only accepts numeric values without whitespace. Please ensure that at least 1 number is non-zero.", regex("^([0-9]*[1-9][0-9]*){0,15}$"))  },
                    packagingType: { $autoDirty: true, required },
                    incoterm: { $autoDirty: true, required },
                    weightUnit: { $autoDirty: true, required },
                    dimensionUnit: { $autoDirty: true, required }
                }
            };
            return state;
        },
        methods: {
            onSync() {
                this.$emit("update:modelValue", this.model);
                this.$emit("is-sync", this.model);
            }
        },
        created() {
            if (this.modelValue)
                this.model = this.modelValue;
            if (this.modelAccessScope)
                this.accessScope = this.modelAccessScope;
        }
    }
</script>