<template>
    <pv-picklist v-model="items" listStyle="height:342px" dataKey="id" @move-to-target="moveToTarget" @move-all-to-target="moveToTarget" @move-to-source="moveToSource" @move-all-to-source="moveToSource">
        <template #sourceHeader>
            <div class="p-d-flex" style="width: 100%;">
                <div class="p-col-2" style="padding-top: 10px;">
                    Available
                </div>
                <div class="p-col-10" style=" margin: 0; padding: 0;">
                    <pv-input style="margin: 0 10px; width: 100%;" v-model="filter.source" placeholder="Type your keyword..." />
                </div>
            </div>
        </template>
        <template #targetHeader>
            <div class="p-d-flex" style="width: 100%;">
                <div class="p-col-2" style="padding-top: 10px;">
                    Selected
                </div>
                <div class="p-col-10" style=" margin: 0; padding: 0;">
                    <pv-input style="margin: 0 10px; width: 100%;" v-model="filter.target" placeholder="Type your keyword..." />
                </div>
            </div>
        </template>
        <template #item="slotProps">
            <div class="picklist-item">
                <div class="picklist-list-detail" style="margin: 0; padding: 0;">
                    <div v-if="activity.loading" class="p-field p-col-12 p-md-12" style="width: 100%; padding: 0; margin: 0;">
                        <div class="custom-skeleton">
                            <ul class="p-m-0 p-p-0">
                                <li>
                                    <div class="p-d-flex">
                                        <div style="flex: 1">
                                            <pv-skeleton width="100%" class="p-mb-2"></pv-skeleton>
                                            <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                            <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                            <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                            <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-else class="p-d-flex" style="width: 100%; padding: 0;">
                        <div style="width: 100%; padding: 0; margin: 0;">
                            <div class="p-d-flex p-jc-between" style="width: 100%; z-index: 100;">
                                <div class="p-d-flex">
                                    <div class="p-d-flex p-jc-end" style="text-align: center; margin-right: 1rem; width: 10rem;">
                                        <div class="p-d-flex p-ai-center p-jc-center" style="height: 100%; vertical-align: middle;">
                                            <img :src="`${cdn}/${tenant.code.toLowerCase()}/company/${slotProps.item.mask?.toUpperCase()}_logo_light.png?${Math.random()}`" style="object-fit: cover; max-height: calc(12rem / 3); max-width: 9rem; object-position: center center;" onerror="this.src = '/_assets/img/no-image.png'" />
                                        </div>
                                    </div>
                                    <div>
                                        <h5 style="margin-bottom: 5px; margin-top: 0; font-size: 1.2rem;"><span>{{ slotProps.item.text }}</span></h5>
                                        <div><p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.8rem">{{ slotProps.item.name }}, {{ slotProps.item.address1 ? slotProps.item.address1 + ',' : null }} {{ slotProps.item.address2 ? slotProps.item.address2 + ',' : null }} {{ slotProps.item.address3 ? slotProps.item.address3 + ',' : null}} {{ slotProps.item.city ? slotProps.item.city + ',' : null }} {{ slotProps.item.state ? slotProps.item.state + ',' : null }} {{ slotProps.item.postalCode ? slotProps.item.postalCode + ',' : null }} {{ $vm.$countries.filter(x => x.id ===slotProps.item.countryID)[0]?.text }}</p></div>
                                    </div>
                                </div>
                                <div class="p-as-start" style="text-align: right; margin-left: 1rem;">
                                    <h5 class="p-code-text" style="text-wrap: none; white-space: nowrap;">[{{ slotProps.item.code}}]</h5>
                                    <span :class="'product-badge status-'+ (slotProps.item.typeText != 'WAREHOUSE' ? 'instock' : 'outofstock')">{{slotProps.item.typeText}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </pv-picklist>
</template>
<script>
    import config from '@/assets/lib/cosmos/_js/config.js';
    export default {
        name: 'CustomerForm',
        emits: ["is-sync"],
        props: {
            accountID: { type: Number, default: -1 },
            formData: { type: Object }
        },
        data() {
            return {
                model: [],
                companies: [],
                filter: {
                    source: null,
                    target: null
                },
                activity: {
                    loading: true
                }
            }
        },
        computed: {
            filteredSource() {
                let ids = []
                if (this.accountID != -1)
                    ids = (this.formData.customers ? this.formData.customers : []).map(x => x.companyID);
                else
                    ids = this.model.map(x => x.companyID);
                let source = this.companies.filter(x => ids.indexOf(x.id) === -1);
                if (this.filter.source && this.filter.source.trim() !== "")
                    source = source.filter(x => x.code?.toLowerCase().includes(this.filter.source.toLowerCase()) || x.name?.toLowerCase().includes(this.filter.source.toLowerCase()));
                return source;
            },
            filteredTarget() {
                let ids = []
                if (this.accountID != -1)
                    ids = (this.formData.customers ? this.formData.customers : []).map(x => x.companyID);
                else
                    ids = this.model.map(x => x.companyID);
                let target = this.companies.filter(x => ids.indexOf(x.id) !== -1);
                if (this.filter.target && this.filter.target.trim() !== "")
                    target = target.filter(x => x.code?.toLowerCase().includes(this.filter.target.toLowerCase()) || x.name?.toLowerCase().includes(this.filter.target.toLowerCase()));
                return target;
            },
            items() {
                return [this.filteredSource, this.filteredTarget];
            },
            cdn() { return `${config.config.endpoint.cdn}`; },
            tenant() { return config.common.$tenant().filter(x => x.id === 1)[0]; },
        },
        watch: {
            'model': { handler() { this.$emit("is-sync", { model: this.model }); }, deep: true },
            'serviceProviderID': { handler() { this.rebind() }, deep: true },
            'formData': {
                handler() {
                    if (this.accountID === -1)
                        this.rebind();
                    else
                        this.model = this.$props.formData.customers ?? [];
                }, deep: true
            },
            'formData.serviceProviderID': { handler() { this.rebind() }, deep: true }
        },
        methods: {
            rebind() {
                this.activity.loading = true;
                this.$store.dispatch("company/getURL", { url: `/services/settings/company` }).then((response) => {
                    this.companies = response.result;
                    this.companies = this.companies.filter(x => (x.environmentID ?? -1) === this.$vm.$appSession.environment.id && x.type === 16).sort(this.$config.tools.dynamicSort("text"));
                    this.activity.loading = false;
                });
            },
            moveToTarget(e) {
                let model = [...this.model];
                this.model = [];
                e.items.forEach((value) => {
                    model.push({
                        accountCompanyID: -1,
                        companyID: value.id,
                        name: value.text,
                        address: (value.address1 ? value.address1 + ',' : "") + " "
                            + (value.address2 ? value.address2 + ',' : "") + " "
                            + (value.address3 ? value.address3 + ',' : "") + " "
                            + (value.city ? value.city + ',' : "") + " "
                            + (value.state ? value.state + ',' : "") + " "
                            + (value.postalCode ? value.postalCode + ',' : "") + " "
                            + (this.$vm.$countries.filter(x => x.id === value.countryID)[0].text)
                    })
                });
                this.model = model;
            },
            moveToSource(e) {
                let model = [...this.model];
                this.model = [];
                e.items.forEach((value) => {
                    const index = model.map(x => x.companyID).indexOf(value.id);
                    if (index > -1) model.splice(index, 1);
                });
                this.model = model;
            },
        },
        mounted() {
            this.rebind();
        }
    }
</script>
<style>
    .p-picklist-header { padding: 0 10px !important; }
    .custom-skeleton { border: 1px solid var(--surface-d); border-radius: 4px; }
    .custom-skeleton ul { list-style: none; }
    .custom-skeleton ul > li { padding: 0.75rem; }
</style>