<template>
    <div>
        <pv-grid id="DownloadProductVariantGrid"
                 :value="source ?? []"
                 :totalRecords="(source ?? []).length ?? 0"
                 :responsive="'scroll'"
                 :alwaysShowPaginator="true"
                 :paginator="true"
                 :responsiveLayout="'scroll'"
                 :paginatorPosition="'bottom'"
                 :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport'"
                 :pageLinkSize="5"
                 :rows="10"
                 :rowsPerPageOptions="[5, 10, 15, 25, 50, 100]"
                 :currentPageReportTemplate="'Showing {first} - {last} of {totalRecords} records'"
                 :loading="activity.loading"
                 autoLayout
                 showGridlines
                 stripedRows>
            <pv-grid-column field="platform.sku" header="SKU" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; padding-top: 1em;" />
            <pv-grid-column field="platform.gtin" header="GTIN" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; padding-top: 1em;" />
            <pv-grid-column field="platform.attribute" header="Attribute" style="border-right: 2px solid var(--surface-d); text-overflow: ellipsis; overflow: hidden;  white-space: nowrap; padding-top: 1em;" />
            <pv-grid-column field="system.sku" header="SKU" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; padding-top: 1em;" />
            <pv-grid-column field="system.gtin" header="GTIN" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; padding-top: 1em;" />
            <pv-grid-column field="system.attribute" header="Attribute" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; padding-top: 1em;" />
            <pv-grid-column field="system.processedDate" header="Process Date" style="width: 14rem; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; padding-top: 1em;">
                <template #body="slotProps">
                    <div class="p-d-flex p-col-12" style="padding: 0;">
                        {{  $filters.toDateFullTimeDisplay(slotProps.data?.system?.processedDate)}}
                    </div>
                </template>
            </pv-grid-column>
            <!-- Column Headers -->
            <pv-grid-column-group type="header">
                <pv-grid-row>
                    <pv-grid-column header="PLATFORM DATA" :colspan="3" headerStyle="border-right: 2px solid var(--surface-d); padding-left: 6rem;" />
                    <pv-grid-column header="COSMOS DATA" :colspan="5" />
                </pv-grid-row>
                <pv-grid-row>
                    <pv-grid-column header="SKU" headerStyle="min-width: 10rem" />
                    <pv-grid-column header="GTIN" headerStyle="min-width: 10rem" />
                    <pv-grid-column header="Attribute" headerStyle="width: 100%;border-right: 2px solid var(--surface-d); " />

                    <pv-grid-column header="SKU" headerStyle="min-width: 10rem" />
                    <pv-grid-column header="GTIN" headerStyle="min-width: 10rem" />
                    <pv-grid-column header="Attribute" headerStyle="width: 100%" />
                    <pv-grid-column header="Process Date" headerStyle="min-width: 10rem" />
                </pv-grid-row>
            </pv-grid-column-group>
            <!-- Row Messages -->
            <template #empty>
                <span v-html="`No records found.`"></span>
            </template>
            <template #loading>
                Retrieving records from database. Please wait...
            </template>
            <template #paginatorRight>
                <pv-button type="button" icon="pi pi-refresh" class="p-button-text" @click="onRefresh" />
            </template>
        </pv-grid>
    </div>
</template>
<script>
    export default {
        name: 'ProductVariantDownloadGrid',
        emits: ['is-refresh'],
        props: {
            source: { type: Array, default: () => [] }
        },
        data() {
            return {
                activity: { loading: true },
            }
        },
        methods: {
            onRefresh() {
                this.activity.loading = true;
                this.$nextTick(() => { this.$emit('is-refresh') });
                this.activity.loading = false;
            }
        },
        async created() {
            this.activity.loading = false;
        },
        async mounted() {
            this.activity.loading = false;
        }
    }
</script>
<style>
    #DownloadProductVariantGrid.p-datatable .p-datatable-tbody > tr > td { padding: 0.5rem !important; }
    #DownloadProductVariantGrid.p-datatable .p-datatable-thead > tr > th { padding: 0.5rem !important; }
</style>