<template>
    <!--<is-loader v-if="activity.parentLoading"></is-loader>-->
    <div class="content-section header">
        <div class="feature-intro">
            <slot name="header">
                <h1><span>CONSIGNMENT LIST</span></h1>
                <p>{{ this.$props?.subtitle ?? router.subtitle }}</p>
                <p><i class="fas fa-info-square fa-fw fa-2x is-text-info"></i> <span class="is-text-info">Please be aware that the page will only display the result after <b>Apply Filter</b> button is clicked.</span></p>
            </slot>
        </div>
    </div>
    <div class="content-section body">
        <div class="card">
            <div class="p-d-flex p-grid">
                <div class="p-col-12 p-md-3">
                    <form-lookup id="warehouseID" label="Warehouse" :source="warehouses" :value="model.warehouseID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onWarehouseChange($event)" />
                    <form-lookup id="customerID" label="Customer" :source="customers" :value="isCustomer ? customers[0]?.id : model.customerID " :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onCustomerChange($event)" :disabled="isCustomer" disabledType="object" />
                </div>
                <div class="p-col-12 p-md-3">
                    <form-lookup id="serviceProviderID" label="Carrier" :source="providers" :value="model.serviceProviderID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onProviderChange($event)" />
                    <form-lookup id="serviceProviderAccountID" disabledType="object" :disabled="providerAccounts.length === 0" label="Account" :source="providerAccounts" :value="model.serviceProviderAccountID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onAccountChange($event)" />
                </div>
                <div class="p-col-12 p-md-3">
                    <form-datetime id="collectionDateFrom" :value="model.collectionDateFromValue" type="date" @is-sync="onCollectionDateFrom($event)" label="Collection Date From" :v$="v$"></form-datetime>
                    <form-datetime id="collectionDateTo" :value="model.collectionDateToValue" type="date" @is-sync="onCollectionDateTo($event)" label="Collection Date To" :v$="v$"></form-datetime>
                </div>
                <div class="p-col-12 p-md-3">
                    <form-lookup id="statusFilter" label="Status" source="consignmentflagstatustype" :sorted="false" type="flag" selection="multiple" @is-sync="onStatusChange($event)" :value="model.statusFilter ?? 32832" :v$="v$" />
                </div>
            </div>
            <div class="p-d-flex p-grid p-col-12 p-jc-end">
                <pv-button class="p-mr-1" label="Inspector Cost" icon="pi pi-search" :disabled="activity.export || activity.binding" @click="onInspectorCost()"></pv-button>
                <pv-button :class="[{ 'p-mr-2': true}, {'p-button-warning': v$.$anyDirty || activity.dirty }]" label="Apply Filter" :disabled="!activity.dirty || activity.export || activity.binding" icon="pi pi-filter" @click="onFilter()"></pv-button>
                <pv-button class="p-mr-1" label="Cancel" icon="pi pi-refresh" :disabled="activity.export || activity.binding" @click="onBack()"></pv-button>
                <pv-button :class="[{ 'p-mr-2': true}, {'p-button-success': !v$.$anyDirty && !activity.dirty }]" label="Create Invoice" icon="pi pi-excel" :disabled="(activity.dirty || activity.export || activity.binding) ? true : ((consignmentToProcess?.length ?? 0) == 0)" @click="onCreateInvoice()"></pv-button>
            </div>
            <div class="p-grid" style="margin-top: 1rem;">
                <div class="p-col-12">
                    <pv-tab style="margin: 0 -14px !important;">
                        <pv-tabpanel header="RESULT">
                            <consignment-grid :formData="model" :filterKey="filterKey" :excludeWithInvoice="true" :isAdmin="isAdmin" @is-sync="onUpdate($event)" @is-selected="onConsignmentSelected($event)" @is-source="onConsignments($event)"></consignment-grid>
                        </pv-tabpanel>
                    </pv-tab>
                </div>
            </div>
        </div>
    </div>
    <is-dialog header="INVOICE DETAILS" :visible="dialog.invoice" @is-confirm="onConfirmCreateInvoice()" :confirm="(dialog.processing ? 'Processing' : 'Confirm')" :confirmDisabled="dialog.processing" :confirmLoading="dialog.processing" :cancelDisabled="dialog.processing" @is-cancel="this.dialog.invoice = false" :breakpoints="{'360px': '30vw', '340px': '100vw'}">
        <div class="card p-col-12">
            <form-input id="invoiceCustomer" :disabled="true" :required="true" :value="invoice.customer" @is-sync="invoice.customer = $event.value" label="Customer" />
            <form-input id="invoiceCollectionRange" :disabled="true" :required="true" :value="invoice.collectionDateRange" @is-sync="invoice.collectionDateRange = $event.value" label="Collection Date" />
            <hr />
            <p class="p-code-text">Please provide your company invoice details. Please note that these details can be updated later.</p>
            <form-input id="invoiceNumber" :required="true" :value="invoice.name" @is-sync="invoice.name = $event.value" label="Invoice Number" :v$="v$" v-focus :max-length="100"/>
            <form-datetime id="invoiceDate" :value="invoice.dateValue" type="date" @is-sync="onInvoiceDate($event)" label="Invoice Date" :v$="v$"></form-datetime>
            <form-input id="invoicePaymentTerms" :required="true" :value="invoice.paymentTerms" @is-sync="invoice.paymentTerms = $event.value" label="Payment Terms" :v$="v$" />
            <form-datetime id="invoiceDueDate" :value="invoice.dueDateValue" type="date" :min="minDate" @is-sync="onInvoiceDueDate($event)" label="Due Date" :v$="v$"></form-datetime>
            <form-lookup id="invoiceCurrency" :required="true" label="Currency" source="currency" :value="invoice.currencyID" @is-sync="invoice.currencyID = $event?.value;" />
            <form-lookup id="invoiceRateCardType" :required="true" label="Rate Card Type" type="enum" source="ratecardtype" :value="invoice.rateAppliedType" @is-sync="invoice.rateAppliedType = $event?.value;" />
        </div>
    </is-dialog>
</template>
<script>
    import { mapGetters } from 'vuex';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import validator from '@/assets/_js/cosmos-validators.js';
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from '@vuelidate/validators';
    const moduleName = 'invoice';
    import reportGrid from '@/components/facility/invoice/v2/consignment-grid.vue';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'consignment-grid': reportGrid
        },
        data() {
            return {
                model: {
                    warehouseID: 0,
                    customerID: 0,
                    serviceProviderID: 0,
                    serviceProviderAccountID: 0,
                    serviceProviderAccountCode: null,
                    collectionDateFrom: null,
                    collectionDateFromValue: null,
                    collectionDateTo: null,
                    collectionDateToValue: null,
                    manifestDateFrom: null,
                    manifestDateTo: null,
                    manifestDateFromValue: null,
                    manifestDateToValue: null,
                    dispatchDateFrom: null,
                    dispatchDateTo: null,
                    dispatchDateFromValue: null,
                    dispatchDateToValue: null,
                    includeArchived: false,
                    reportType: 200,
                    statusFilter: null
                },
                invoice: {
                    customer: null,
                    code: null,
                    name: null,
                    date: null,
                    paymentTerms: null,
                    vatRate: 0,
                    vatRateType: 100,
                    dueDate: null,
                    currecyID: 3,
                    rateAppliedType: 100
                },
                activity: {
                    parentLoading: true,
                    loading: false,
                    export: false,
                    binding: false,
                    dirty: true
                },
                keys: null,
                session: {
                    tenant: null,
                    environment: null,
                    warehouse: null,
                    customer: null,
                    currentUser: null,
                    executiveUser: null
                },
                reportScopes: null,
                sources: {
                    accounts: []
                },
                filterKey: null,
                accountDS: [],
                customerAccountDS: [],
                dialog: {
                    invoice: false,
                    processing: false,
                    icon: "pi pi-pw pi-check",
                    confirmLabel: "Confirm"
                },
                consignments: {
                    grid: [],
                    selected:[]
                }
            }
        },
        validations() {
            return {
                model: {
                    code: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },
                    name: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },
                }
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            environment() {
                return this.$config.common.$environment().filter(x => x.id == this.user?.environmentID ?? this.$vm.$appSession.environment.id)[0];
            },
            scopeCustomerDS() {
                return this.$config.common.$customer(this.user?.environmentID ?? -100, this.$vm.$appSession.warehouse.id ?? this.user.warehouseID, this.user);
            },
            scopeWarehouseDS() {
                return this.$config.common.$warehouse(this.user?.environmentID ?? -100, this.user);
            },
            customer() {
                let customer = this.scopeCustomerDS.filter(x => x.id == this.model.customerID)[0];
                return customer?.altName ?? customer.name ?? "";
            },
            companySettingRecord() {
                return this.$store.getters["companygeneralsetting/record"];
            },
            serviceProviderDS() {
                let ds = [];
                if (this.model.customerID > 0) {
                    this.customerAccountDS?.forEach((x) => {
                        let provider = this.$vm.$providers.filter(y => y.id === x.serviceProvider.id)[0];
                        if (ds.length === 0) {
                            ds.push({ id: provider.id, name: '[' + provider.code + '] ' + provider.name });
                        }
                        else if (ds?.findIndex(y => y.id === x.serviceProvider.id) === -1) {
                            ds.push({ id: provider.id, name: '[' + provider.code + '] ' + provider.name });
                        }
                    });
                }
                else {
                    this.serviceAccounts?.data?.forEach((x) => {
                        let provider = this.$vm.$providers.filter(y => y.id === x.serviceProviderID)[0];
                        if (ds.length === 0) {
                            ds.push({ id: provider.id, name: '[' + provider.code + '] ' + provider.name });
                        }
                        else if (ds?.findIndex(y => y.id === x.serviceProviderID) === -1) {
                            ds.push({ id: provider.id, name: '[' + provider.code + '] ' + provider.name });
                        }

                    });
                }
                return ds;
            },
            serviceProviderAccountDS() {
                let ds = [];
                if (this.model.serviceProviderID > 0) {
                    if (this.model.customerID > 0) {
                        if (this.accountDS.length > 0) {
                            this.customerAccountDS?.forEach((x) => {
                                if (x.serviceProvider.id === this.model.serviceProviderID) {
                                    let account = this.serviceAccounts?.data?.filter(y => y.id === x.accountDetail.id)[0];
                                    if (ds.length === 0) {
                                        ds.push({ id: account.id, name: '[' + account.accountNumber + '] ' + x.name, code: account.code });
                                    }
                                    else if (ds?.findIndex(y => y.id === x.accountDetail.id) === -1) {
                                        ds.push({ id: account.id, name: '[' + account.accountNumber + '] ' + x.name, code: account.code });
                                    }
                                }

                            });
                        }

                    }
                    else {
                        this.accountDS?.forEach((x) => {
                            if (ds.length === 0) {
                                ds.push({ id: x.id, name: '[' + x.accountNumber + '] ' + x.name, code: x.code });
                            }
                            else if (ds?.findIndex(y => y.id === x.id) === -1) {
                                ds.push({ id: x.id, name: '[' + x.accountNumber + '] ' + x.name, code: x.code });
                            }
                        });
                    }

                }
                return ds;
            },
            reportTypeDS() {
                return [{ id: 100, name : 'Shipper Report' }, { id: 200, name : 'Customer Report' }]
            },
            serviceAccounts() { return this.$store.getters["provideraccount/ds"]; },
            apiData() {
                return {
                    ServiceHeader: {
                        Username: "TEMP",
                        Password: "TESTESTEDET",
                        ClientID: "TESTTESTTESTTESTTEST",
                        ClientSecret: "TESTEDREDREDTETEDEDETEREDEDEREDE"
                    },
                    Request: {
                        DC: this.model?.warehouseID > 0 ? this.scopeWarehouseDS.filter(x => x.id === this.model.warehouseID)[0]?.code : null,
                        Customer: this.model?.customerID > 0 ? this.scopeCustomerDS.filter(x => x.id === this.model.customerID)[0]?.code : null,
                        Account: this.model?.serviceProviderAccountCode,
                        Carrier: this.model?.serviceProviderID > 0 ? this.$vm.$providers.filter(y => y.id === this.model?.serviceProviderID)[0]?.code : null,
                        ScopeDetail: {
                            IncludeArchive: this.model?.includeArchived,
                            ExportType: this?.model?.reportType === 100? "AR": "CR",
                            CollectionDate: {
                                From: this.$filters.formatDateWithFormat(this.model.collectionDateFrom ?? new Date(), "YYYY-MM-DD"),
                                To: this.$filters.formatDateWithFormat(this.model.collectionDateTo ?? new Date(), "YYYY-MM-DD")
                            },
                            ManifestDate: {
                                From: this.model.manifestDateFrom !== undefined && this.model.manifestDateFrom !== null ? this.$filters.formatDateWithFormat(this.model.manifestDateFrom ?? new Date(), "YYYY-MM-DD") : null,
                                To: this.model.manifestDateTo !== undefined && this.model.manifestDateTo !== null ? this.$filters.formatDateWithFormat(this.model.manifestDateTo ?? new Date(), "YYYY-MM-DD") : null
                            },
                            DispatchDate: {
                                From: this.model.dispatchDateFrom !== undefined && this.model.dispatchDateFrom !== null ? this.$filters.formatDateWithFormat(this.model.dispatchDateFrom ?? new Date(), "YYYY-MM-DD") : null,
                                To: this.model.dispatchDateTo !== undefined && this.model.dispatchDateTo !== null ? this.$filters.formatDateWithFormat(this.model.dispatchDateTo ?? new Date(), "YYYY-MM-DD") : null
                            }
                        }
                    }
                }

            },
            consignmentToProcess() {
                let consignmentCodes = [];
                if ((this.consignments.selected ?? []).length > 0 && (this.consignments.grid ?? []).length > 0) {
                    for (let i = 0; i < (this.consignments.selected ?? []).length; i++) {
                        if (((this.consignments.grid ?? []).filter(x => x.id === this.consignments.selected[i].id).length ?? 0) > 0)
                            consignmentCodes.push(this.consignments.selected[i].code ?? "");
                    }
                }
                else {
                    for (let i = 0; i < (this.consignments.grid ?? []).length; i++) {
                        consignmentCodes.push(this.consignments.grid[i].code ?? "");
                    }
                }
                return consignmentCodes;
            },
            invoiceFormModel() {
                return {
                    id: null,
                    code: 'TEMP-' + Math.random().toString().replace(".", "").substring(0, 15),
                    name: this.invoice.name,
                    invoiceDate: this.invoice.date,
                    paymentTerms: this.invoice.paymentTerms,
                    invoiceDueDate: this.invoice.dueDate,
                    currencyID: this.invoice.currencyID,
                    customerID: this.model.customerID,
                    rateCardType: this.invoice.rateCardType,
                    vatRate: this.invoice.vatRate,
                    vatRateType: this.invoice.vatRateType,
                    environmentID: this.user?.environmentID ?? this.$vm.$appSession.environment.id,
                    tenantID: null,
                    consignmentIDs: this.consignmentToProcess ?? []
                }
            },
            invoiceErrors() { return this.$store.getters["invoice/errors"]; },
            packageRecord() { return this.$store.getters["invoice/record"]; },
            minDate() { return new Date(); },


            warehouses() {
                return (this.reportScopes?.warehouses ?? [])
                    .filter(wh => (this.model.customerID ?? 0) === 0 || ((this.model.customerID ?? 0) > 0 && (wh.customers ?? []).filter(cust => cust === (this.model.customerID ?? 0)).length > 0)) ?? [];
            },
            customers() { return (this.reportScopes?.customers ?? []).filter(cust => (this.model.warehouseID ?? 0) === 0 || ((this.model.warehouseID ?? 0) > 0 && (cust.warehouses ?? []).filter(wh => wh === (this.model.warehouseID ?? 0)).length > 0)) ?? []; },
            providers() { return (this.reportScopes?.providers ?? []).filter(provider => ((this.accounts ?? []).map(acct => acct.serviceProviderID) ?? []).filter(acct => acct == provider.id).length > 0) ?? []; },
            accounts() {
                return (((((this.reportScopes?.accounts ?? [])
                    .filter(acct => (this.model.customerID ?? 0) === 0 || ((this.model.customerID ?? 0) > 0 && (acct.customers ?? []).filter(cust => cust === (this.model.customerID ?? 0)).length > 0)) ?? [])
                    .filter(acct => (this.model.warehouseID ?? 0) === 0 || ((this.model.warehouseID ?? 0) > 0 && ((acct.postingLocations ?? []).length === 0 || (acct.postingLocations ?? []).filter(postLoc => postLoc == this.model.warehouseID).length > 0))) ?? [])
                    .filter(acct => (this.model.warehouseID ?? 0) === 0 || ((this.model.warehouseID ?? 0) > 0 && (acct.customers ?? []).filter(acctcust => ((this.customers ?? []).map(z => z.id) ?? []).filter(cust => cust === acctcust).length > 0).length > 0)) ?? [])
                    .filter(acct => (this.model.serviceProviderID ?? 0) === 0 || ((this.model.serviceProviderID ?? 0) > 0 && acct.serviceProviderID === (this.model.serviceProviderID ?? 0))) ?? []);
            },
            providerAccounts() { return this.sources?.accounts ?? []; },
            isCustomer() {
                return [5, 6, 7].filter(y => y === this.user.userType).length > 0;
            },
            isAdmin() {
                return [0, 2, 3].filter(y => y === this.user.userType).length > 0;
            },
            consignmentStatus() {
                return [
                    {
                        "id": 1,
                        "text": "Pending",
                        "code": "PENDING"
                    },
                    {
                        "id": 2,
                        "text": "Allocated",
                        "code": "ALLOCATED"
                    },
                    {
                        "id": 4,
                        "text": "Awaiting Collection Instruction",
                        "code": "READYTOMANIFEST"
                    },
                    {
                        "id": 8,
                        "text": "Awaiting to Dispatch",
                        "code": "AWAITINGTODISPATCH"
                    },
                    {
                        "id": 16,
                        "text": "Dispatched",
                        "code": "DISPATCHED"
                    },
                    {
                        "id": 32,
                        "text": "Out for Delivery",
                        "code": "INTRANSIT"
                    },
                    {
                        "id": 64,
                        "text": "Delivered",
                        "code": "DELIVERED"
                    },
                    {
                        "id": 128,
                        "text": "Failed Delivery",
                        "code": "UNDELIVERED"
                    },
                    {
                        "id": 32768,
                        "text": "Archived",
                        "code": "ARCHIVED"
                    }
                ];
            }

        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            'model': {
                handler() {
                    if (this.filterKey || this.v$.$anyDirty)
                        this.$nextTick(() => { this.activity.dirty = true; });
                }, deep: true
            }
        },
        methods: {
            async insertRecord() { return await this.$store.dispatch(moduleName + "/addRecord", this.invoiceFormModel); },
            async getByCode() {
                return await this.$axios.get(this.$config.config.endpoint.api + `/services/facility/invoice/code/` + this.invoice.code + ``.replaceAll("//", "/"));
            },
            buttonConfirmStatus() {
                this.dialog.processing = true;
                this.dialog.icon = "fa-duotone fa-spinner-third";
                this.dialog.confirmLabel = "Processing...";
            },
            buttonConfirmReset() {
                this.dialog.processing = false;
                this.dialog.icon = "pi pi-pw pi-check";
                this.dialog.confirmLabel = "Confirm";
            },
            onProvider(e) {
                this.model.serviceProviderID = e?.value;
                this.getAccounts();
            },
            onAccount(e) {
                this.model.serviceProviderAccountID = e?.value;
                this.model.serviceProviderAccountCode = e?.record?.code;
            },
            onCustomer(e) {
                this.model.customerID = e?.value;
                this.getCustomerAccounts();
                
            },
            onCollectionDateFrom(e) {
                this.$nextTick(() => {
                    this.model.collectionDateFromValue = e.value;
                    this.model.collectionDateFrom = (e.record.data ?? new Date());
                });
            },
            onCollectionDateTo(e) {
                this.$nextTick(() => {
                    this.model.collectionDateToValue = e.value;
                    this.model.collectionDateTo = (e.record.data ?? new Date());
                });
            },
            onInvoiceDate(e) {
                this.$nextTick(() => {
                    this.invoice.dateValue = e.value;
                    this.invoice.date = (e.record.data ?? new Date());
                });
            },
            onInvoiceDueDate(e) {
                this.$nextTick(() => {
                    this.invoice.dueDateValue = e.value;
                    this.invoice.dueDate = (e.record?.data);
                });
            },
            onInspectorCost() {
                this.$toast.add({ severity: 'warn', summary: 'UNDERDEVELOPMENT!', detail: 'Module is on development', life: 8000 });
            },
            onReset() {
                this.$toast.add({ severity: 'warn', summary: 'UNDERDEVELOPMENT!', detail: 'Module is on development', life: 8000 });
            },
            onBack() {
                this.$router.push({ name: this.router.module + '-index' });
            },
            onCreateInvoice() {
                if (this.model.customerID != 0 && this.model.customerID != null && this.model.customerID != undefined) {
                    this.getCompanySetting().then(() => {
                        this.dialog.invoice = true;
                        this.invoice.date = new Date();
                        this.invoice.dateValue = new Date();
                        this.invoice.customer = this.customer;
                        this.invoice.currencyID = this.companySettingRecord?.default_InvoiceCurrencyID ?? 3;
                        this.invoice.vatRate = this.companySettingRecord?.default_InvoiceVATRate ?? 0;
                        this.invoice.vatRateType = this.companySettingRecord?.default_InvoiceVATRateType ?? 100;
                        this.invoice.collectionDateRange = this.$filters.formatDateWithFormat(this.model.collectionDateFrom, 'DD/MMM/yyyy') + ' to ' + this.$filters.formatDateWithFormat(this.model.collectionDateTo, 'DD/MMM/yyyy');
                    });
                    
                }
                else {
                    this.$toast.add({ severity: 'error', summary: 'Failed!', detail: 'Please select customer to proceed', life: 8000 });
                }
                

            },
            onConfirmCreateInvoice() {
                let _self = this;
                this.buttonConfirmStatus();
                this.insertRecord().then((invoiceResponse) => {
                    if (this.invoiceErrors) {                           
                        let errorMessage = "<ul>";                        
                        (Object.keys(this.invoiceErrors) ?? []).forEach(key => {
                            errorMessage += "<li>" + ((this.invoiceErrors[key]).message ?? (this.invoiceErrors[key])).toString().replace("Name", "Invoice Number") + "</li>";
                        });
                        errorMessage += "</ul>";
                        this.$toast.add({ severity: 'error', summary: 'Create Invoice Failed!', detail: errorMessage, life: 8000 });                        
                    }
                    else if (invoiceResponse.success) {
                        this.invoice.code = invoiceResponse?.result?.code
                        this.getByCode().then((getResponse) => {
                            if (getResponse.status === 200) {
                                let recordMask = getResponse.data.result.mask;
                                this.get(recordMask);
                                _self.$router.push({ name: _self.router.module + '-edit', params: { mask: recordMask } });
                                _self.resetModelState();
                                setTimeout(() => _self.$emit("is-reset"), 500);
                            }
                        });
                    }
                }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: 'Create Invoice Failed!', detail: err, life: 8000 });
                   
                }).finally(() => {
                    this.buttonConfirmReset();
                });
            },
            onConsignments(e) {
                this.consignments.grid = e?.consignments ?? this.consignments.grid;
            },
            onConsignmentSelected(e) {
                this.consignments.selected = e ?? [];
            },
            async getProviderAccounts() {
                await this.$store.dispatch("provideraccount/getAll", { sort: null, where: null }).then(() => {
                });
            },
            async getAccounts() {
                if (this.model.serviceProviderID > 0) {
                    await this.$store.dispatch("provideraccount/getURL", { url: `/services/admin/serviceprovideraccount/byprovider/id/${this.model.serviceProviderID}` }).then((response) => {
                        this.accountDS = response.result;
                    });
                }
                else {
                    this.accountDS = [];
                }
            },
            async getCustomerAccounts() {
                if (this.model.customerID > 0) {
                    await this.$store.dispatch("provideraccountcustomer/getURL", { url: `/services/admin/serviceprovideraccountcustomer/handlerbyaccount/id/${this.model.customerID}` }).then((response) => {
                        this.customerAccountDS = response.result;
                    });
                }
                else {
                    this.customerAccountDS = [];
                }
            },
            async getCompanySetting() {
                //let customer = this.scopeCustomerDS.filter(x => x.id == this.user?.environmentID ?? this.$vm.$appSession.environment.id)[0];
                if (this.environment?.mask !== null && this.environment.mask !== undefined) {
                    return this.$store.dispatch("companygeneralsetting/getURL", { url: `/services/settings/companygeneralsetting/ByCompany/${this.environment?.mask}`.replaceAll("//", "/") });
                }    
            }

            //--NEW IMPLEMENTATION
            ,onFilter() {
                this.$nextTick(() => { this.filterKey = Math.random(); });
                this.v$.$reset();
                this.$nextTick(() => { this.activity.dirty = false; });
            }
            ,onUpdate(e) {
                this.$nextTick(() => { this.activity.binding = e.loading; });
                this.$nextTick(() => { this.activity.export = e.download; });
            }
            ,getReportScopes() {
                this.reportScopes = null;
                this.$store.dispatch("provideraccount/getURL", { url: `/services/settings/user/reportscope` })
                    .then((response) => {
                        this.reportScopes = response;
                        this.sources.warehouses = response?.warehouses;
                        this.sources.customers = response?.customers;
                        this.sources.providers = response?.providers;
                        this.sources.accounts = response?.accounts;
                    });
            },
            onWarehouseChange(e) {
                this.model.warehouseID = e?.value;
                this.getSources();
            },
            onCustomerChange(e) {
                this.model.customerID = e?.value;
                this.getSources();
            },
            onProviderChange(e) {
                this.model.serviceProviderID = e?.value;
                this.getSources();
            },
            onAccountChange(e) {
                this.model.serviceProviderAccountID = e?.value;
                this.model.serviceProviderAccountCode = e?.record?.code;
            },
            onStatusChange(e) {
                this.model.statusFilter = e?.value;
            },
            getSources() {
                this.sources.accounts = (this.accounts ?? []).filter(x => this.model.serviceProviderID === 0 || (this.model.serviceProviderID > 0 && this.model.serviceProviderID === x.serviceProviderID)) ?? [];
            }

        },
        async created() {
            this.loading = false;
            let startDate = new Date();
            startDate.setDate(1);
            this.model.collectionDateFromValue = startDate;
            this.model.collectionDateFrom = startDate;
            this.model.collectionDateToValue = new Date();
            this.model.collectionDateTo = new Date();
            this.getCompanySetting();
        },
        mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            this.loading = false;
            this.model.reportType = 200;
            let startDate = new Date();
            startDate.setDate(1);
            this.model.collectionDateFromValue = startDate;
            this.model.collectionDateFrom = startDate;
            this.model.collectionDateToValue = new Date();
            this.model.collectionDateTo = new Date();
            //this.getProviderAccounts();
            this.getCompanySetting();
            this.getReportScopes();
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }

    .p-orderlist-controls,
    .p-picklist-buttons.p-picklist-source-controls,
    .p-picklist-buttons.p-picklist-source-controls, .p-picklist-buttons.p-picklist-target-controls {
        display: none !important;
    }

    .picklist-item {
        display: flex;
        align-items: center;
        padding: .2rem;
        width: 100%;
    }

    img#is-img {
        width: 75px;
        /*   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);*/
        margin-right: 1rem;
    }

    .picklist-list-detail {
        flex: 1 1 0;
    }

    .picklist-list-action {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .picklist-category-icon {
        vertical-align: middle;
        margin-right: .5rem;
        font-size: .875rem;
    }

    .picklist-category {
        vertical-align: middle;
        line-height: 1;
        font-size: .875rem;
    }

    .custom-skeleton {
        border: 1px solid var(--surface-d);
        border-radius: 4px;
    }

        .custom-skeleton ul {
            list-style: none;
        }

            .custom-skeleton ul > li {
                padding: 0.75rem;
            }
</style>
