<template>
    <pv-picklist v-model="items" listStyle="height:342px" dataKey="id" @move-to-target="moveToTarget" @move-all-to-target="moveAllToTarget" @move-to-source="moveToSource" @move-all-to-source="moveAllToSource">
        <template #sourceHeader>
            <div class="p-d-flex" style="width: 100%;">
                <div class="p-col-2" style="padding-top: 10px;">
                    Available
                </div>
                <div class="p-col-10" style=" margin: 0; padding: 0;">
                    <pv-input style="margin: 0 10px; width: 100%;" v-model="filter.source" placeholder="Type your keyword..." />
                </div>
            </div>
        </template>
        <template #targetHeader>
            <div class="p-d-flex" style="width: 100%;">
                <div class="p-col-2" style="padding-top: 10px;">
                    Selected
                </div>
                <div class="p-col-10" style=" margin: 0; padding: 0;">
                    <pv-input style="margin: 0 10px; width: 100%;" v-model="filter.target" placeholder="Type your keyword..." />
                </div>
            </div>
        </template>
        <template #item="slotProps">
            <div class="picklist-item">
                <div class="picklist-list-detail" style="margin: 0; padding: 0;">
                    <div v-if="activity.loading" class="p-field p-col-12 p-md-12" style="width: 100%; padding: 0; margin: 0;">
                        <div class="custom-skeleton">
                            <ul class="p-m-0 p-p-0">
                                <li>
                                    <div class="p-d-flex">
                                        <div style="flex: 1">
                                            <pv-skeleton width="100%" class="p-mb-2"></pv-skeleton>
                                            <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                            <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                            <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                            <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-else class="p-d-flex" style="width: 100%; padding: 0;">
                        <div style="width: 100%; padding: 0; margin: 0;">
                            <div v-if="slotProps.item.providerCarrierID > 0" style="vertical-align: middle;">
                                <img :src="`/_assets/_carrier/${$vm.$carriers.filter(x => x.id === slotProps.item.providerCarrierID)[0]?.mask}.png`" style="object-fit: contain; max-height: 3rem; object-position: center;" />
                                <div class="p-code-text" style="display: inline-block; font-weight: bold; position: absolute; top: 1.5rem; margin-left: 1rem;">{{ $vm.$carriers.filter(x => x.id === slotProps.item.providerCarrierID)[0]?.name }}</div>
                            </div>
                            <div class="p-d-flex p-jc-between" style="width: 100%; z-index: 100;">
                                <div><h5 style="margin-bottom: 5px; margin-top: 0; font-size: 1.2rem;"><span>{{ slotProps.item.name }}</span></h5></div>
                                <div class="p-as-end"><h5 class="p-code-text">[{{ slotProps.item.code}}]</h5></div>
                            </div>
                            <div>
                                <div class="p-grid" style="z-index: 100; width: 100% !important;">
                                    <div class="p-col-12 p-md-5">
                                        <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Maximum Package: </b>  {{ slotProps.item.maxParcelCount }} per consignment</p>
                                        <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Weight Range: </b>  {{ slotProps.item.minWeight?.toFixed(3) }} - {{ slotProps.item.maxWeight?.toFixed(3) }} {{ slotProps.item.weightUnitName?.toLowerCase() }}</p>
                                        <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Max Dimension: </b>  {{ slotProps.item.maxLength }}{{ slotProps.item.lengthUnitName?.toLowerCase() }} x {{ slotProps.item.maxWidth }}{{ slotProps.item.lengthUnitName?.toLowerCase() }} x {{ slotProps.item.maxHeight }}{{ slotProps.item.lengthUnitName?.toLowerCase() }}</p>
                                    </div>
                                    <div class="p-col-12 p-md-5">
                                        <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Maximum Transit Days: </b> {{ slotProps.item.maxDeliveryDay }} {{ $filters.quantifiedDisplay("days", slotProps.item.maxDeliveryDay) }}</p>
                                        <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Estimated Time: </b>  {{ slotProps.item.deliveryHourTimeSpan?.hours === 23 ? "Within the day" : $filters.padLeft(slotProps.item.deliveryHourTimeSpan?.hours ?? 0 - ( slotProps.item.deliveryHourTimeSpan?.hours ?? 0 > 12 ? 12 : 0), 2, '0') + ":" + $filters.padLeft(slotProps.item.deliveryHourTimeSpan?.minutes ?? 0, 2, '0') + (slotProps.item.deliveryHourTimeSpan?.hours >= 12 ? " PM" : " AM") }}</p>
                                        <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem">
                                            <b class="p-code-text">Delivery Type: </b><span v-for="item in slotProps.item.usageTypeName" :key="item" :class="`p-code-text p-text-indicator definite-${item.toUpperCase()}`">{{ item }}</span>
                                        </p>
                                    </div>
                                    <div class="p-col-12 p-md-2">
                                        <div style="text-align: right; object-position: right">
                                            <h5 style="margin-top: 0 !important;"><span :class="`p-code-text p-indicator definite-${slotProps.item.timeDefiniteType}`" :title="slotProps.item?.timeDefiniteTypeName">{{ slotProps.item?.timeDefiniteTypeName?.substring(0,1)}}</span></h5>
                                            <h5 style="margin-top: 0 !important;">
                                                <span v-for="item in slotProps.item.productContentTypeName" :key="item" :class="`p-code-text p-indicator definite-${item.toUpperCase()}`" :title="item">{{ item?.substring(0,1) }}</span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </pv-picklist>
</template>
<script>
    export default {
        name: 'ProductForm',
        emits: ["is-sync"],
        props: {
            accountID: { type: Number, default: -1 },
            serviceProviderID: { type: Number, default: -1 },
            integrationID: { type: Number, default: -1 },
            formData: { type: Object }
        },
        data() {
            return {
                model: [],
                products: [],
                filter: {
                    source: null,
                    target: null
                },
                activity: {
                    loading: true
                }
            }
        },
        computed: {
            filteredSource() {
                let ids = []
                if (this.accountID != -1)
                    ids = (this.formData.products ? this.formData.products : []).map(x => x.providerProductID);
                else
                    ids = this.model.map(x => x.providerProductID);
                let source = this.products.filter(x => ids.indexOf(x.id) === -1);
                if (this.filter.source && this.filter.source.trim() !== "")
                    source = source.filter(x => x.code?.toLowerCase().indexOf(this.filter.source.toLowerCase()) !== -1 || x.name?.toLowerCase().indexOf(this.filter.source.toLowerCase()) !== -1);
                return source;
            },
            filteredTarget() {
                let ids = []
                if (this.accountID != -1)
                    ids = (this.formData.products ? this.formData.products : []).map(x => x.providerProductID);
                else
                    ids = this.model.map(x => x.providerProductID);
                let target = this.products.filter(x => ids.indexOf(x.id) !== -1);
                if (this.filter.target && this.filter.target.trim() !== "")
                    target = target.filter(x => x.code?.toLowerCase().indexOf(this.filter.target.toLowerCase()) !== -1 || x.name?.toLowerCase().indexOf(this.filter.target.toLowerCase()) !== -1);
                return target;
            },
            items() {
                return [this.filteredSource, this.filteredTarget];
            },
            providerServices() {
                return (this.$vm.$products ?? []).filter(x => x.serviceProviderID == this.serviceProviderID) ?? [];
            }
        },
        watch: {
            'model': { handler() { this.$emit("is-sync", { model: this.model }); }, deep: true },
            'serviceProviderID': { handler() { this.rebind() }, deep: true },
            'formData': {
                handler() {
                    if (this.accountID === -1)
                        this.rebind();
                    else
                        this.model = this.$props.formData.products;
                }, deep: true
            },
            'formData.serviceProviderID': { handler() { this.rebind() }, deep: true }
        },
        methods: {
            rebind() {
                this.activity.loading = true;
                this.products = [];
                this.$store.dispatch("providerproduct/getURL", { url: `/services/settings/serviceproviderproduct/byprovider/id/${this.serviceProviderID ?? this.formData.serviceProviderID ?? 0}` }).then((response) => {
                    if (response && response.success)
                        this.products = (response.result ?? []).filter(x => (x.integrationID === undefined || x.integrationID == null) || x.integrationID == this.integrationID);
                    this.activity.loading = false;
                });
                this.activity.loading = false;
            },
            moveToTarget(e) {
                let model = [...this.model];
                this.model = [];
                e.items.forEach((value) => { model.push({ accountProductID: -1, providerProductID: value.id, name: value.text }); });
                this.model = model;
            },
            moveToSource(e) {
                let model = [...this.model];
                this.model = [];
                e.items.forEach((value) => {
                    const index = model.map(x => x.providerProductID).indexOf(value.id);
                    if (index > -1) model.splice(index, 1);
                });
                this.model = model;
            },
            moveAllToTarget(e) {
                let model = [...this.model];
                this.model = [];
                this.activity.loading = true;
                e.items.forEach((value) => { model.push({ accountProductID: -1, providerProductID: value.id, name: value.text }); });
                setTimeout(() => {
                    this.model = model;
                    this.activity.loading = false;
                 }, 100, this);
            },
            moveAllToSource(e) {
                let model = [...this.model];
                this.model = [];
                this.activity.loading = true;
                e.items.forEach((value) => {
                    const index = model.map(x => x.providerProductID).indexOf(value.id);
                    if (index > -1) model.splice(index, 1);
                });
                setTimeout(() => {
                    this.model = model;
                    this.activity.loading = false;
                }, 100, this);
            },
        },
        mounted() {
            if (this.accountID === -1)
                this.rebind();
            else
                this.model = this.$props.formData.products;
        }
    }
</script>
<style>
    .p-picklist-header { padding: 0 10px !important; }
    .custom-skeleton { border: 1px solid var(--surface-d); border-radius: 4px; }
    .custom-skeleton ul { list-style: none; }
    .custom-skeleton ul > li { padding: 0.75rem; }
    .p-picklist-buttons.p-picklist-target-controls {
        display: inline-flex !important;
        margin: auto;
    }

</style>