<template>
    <div class="p-grid">
        <!--SETTINGS-->
        <div class="p-d-flex p-grid p-col-12" style="margin: 20px auto">
            <div class="p-col-12 p-md-4">
                <form-input :disabled="limitedAccess" id="firstName" label="First Name" :v$="v$" :vError="v$.model?.firstName?.$silentErrors[0]?.$message ?? v$.model?.firstName?.$errors[0]?.$message" :value="model.firstName" @is-sync="model.firstName = $event.value" v-focus />
                <form-input :disabled="limitedAccess" id="lastName" label="Last Name" :v$="v$" :vError="v$.model?.lastName?.$silentErrors[0]?.$message ?? v$.model?.lastName?.$errors[0]?.$message" :value="model.lastName" @is-sync="model.lastName = $event.value" />
                <form-input :disabled="limitedAccess" id="email" label="Email" :v$="v$" :vError="v$.model?.email?.$silentErrors[0]?.$message ?? v$.model?.email?.$errors[0]?.$message" :value="model.email" @is-sync="model.email = $event.value?.toLowerCase()" v-lowercase :max-length="100"></form-input>
                <form-input :disabled="limitedAccess" id="telephone" :max-length="25" label="Telephone" :v$="v$" :vError="v$.model?.telephone?.$silentErrors[0]?.$message ?? v$.model?.telephone?.$errors[0]?.$message" :value="model.telephone" @is-sync="model.telephone = $event.value"></form-input>
                <form-input :disabled="limitedAccess" id="mobile" :max-length="25" label="Mobile" :v$="v$" :vError="v$.model?.mobile?.$silentErrors[0]?.$message ?? v$.model?.mobile?.$errors[0]?.$message" :value="model.mobile" @is-sync="model.mobile = $event.value"></form-input>
            </div>
            <div class="p-col-12 p-md-4">
                <form-input :disabled="limitedAccess" id="registrationNumber" label="Registration Number" :v$="v$" :value="model.registrationNumber" @is-sync="model.registrationNumber = $event.value"></form-input>
                <form-input :disabled="limitedAccess" id="businessNumber" label="Business Number" :v$="v$" :value="model.businessNumber" @is-sync="model.businessNumber = $event.value"></form-input>
                <form-input :disabled="limitedAccess" id="vatNo" label="VAT Number" :v$="v$" :vError="v$.model?.vatNo?.$silentErrors[0]?.$message ?? v$.model?.vatNo?.$errors[0]?.$message" :value="model.vatNo" @is-sync="model.vatNo = $event.value"></form-input>
            </div>
            <div class="p-col-12 p-md-4">
                <form-input :disabled="limitedAccess" id="eori" label="EORI" :v$="v$" :value="model.eori" :vError="v$.model?.eori?.$silentErrors[0]?.$message ?? v$.model?.eori?.$errors[0]?.$message" @is-sync="model.eori = $event.value"></form-input>
                <form-input :disabled="limitedAccess" id="eoriNorthernIreland" label="EORI (Northern Ireland)" :v$="v$" :vError="v$.model?.eoriNorthernIreland?.$silentErrors[0]?.$message ?? v$.model?.eoriNorthernIreland?.$errors[0]?.$message" :value="model.eoriNorthernIreland" @is-sync="model.eoriNorthernIreland = $event.value" ph="XI123456789012345"></form-input>
                <form-input :disabled="limitedAccess" id="eoriEuropean" label="EORI (European)" :v$="v$" :vError="v$.model?.eoriEuropean?.$silentErrors[0]?.$message ?? v$.model?.eoriEuropean?.$errors[0]?.$message" :value="model.eoriEuropean" @is-sync="model.eoriEuropean = $event.value"></form-input>
                <form-input :disabled="limitedAccess" id="iossNumber" label="Import One-Stop Shop (IOSS)" :v$="v$" :vError="v$.model?.iossNumber?.$silentErrors[0]?.$message ?? v$.model?.iossNumber?.$errors[0]?.$message" :value="model.iossNumber" @is-sync="model.iossNumber = $event.value" ph="IM1234567890"></form-input>
                <form-input :disabled="limitedAccess" id="ukimsNumber" label="UK Internal Market Scheme (UKIMS)" :v$="v$" :vError="v$.model?.ukimsNumber?.$silentErrors[0]?.$message ?? v$.model?.ukimsNumber?.$errors[0]?.$message" :value="model.ukimsNumber" @is-sync="model.ukimsNumber = $event.value" :ph="`GBUKIM12345789012345${$filters.$dateTimeCodeUTC(new Date()).replaceAll('-', '').replaceAll(':', '').replaceAll('T', '').replaceAll('Z', '').substring(0, 14)}`"></form-input>
                <form-input :disabled="limitedAccess" id="identityNumber" label="PID Number" :v$="v$" :vError="v$.model?.identityNumber?.$silentErrors[0]?.$message ?? v$.model?.identityNumber?.$errors[0]?.$message" :value="model.identityNumber" @is-sync="model.identityNumber = $event.value"></form-input>
                <form-lookup :disabled="limitedAccess" id="identityNumberType" :source="$vm.$enums['identitynumbertype']" :value="model.identityNumberType" label="PID Type" @is-sync="model.identityNumberType = $event.value" :v$="v$" :vError="v$.model?.identityNumberType?.$silentErrors[0]?.$message ?? v$.model?.identityNumberType?.$errors[0]?.$message"></form-lookup>
            </div>
        </div>

    </div>
    <pre></pre>

</template>
<script>
    import { mapGetters } from 'vuex';
    const config = require("@/assets/_js/cosmos-config.js").default;
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, email, maxLength, helpers } from '@vuelidate/validators';
    /** CUSTOM VALIDATOR **/
    const regex = (param) => helpers.withParams({ type: 'regex', value: param }, (value) => value.match(param) != null);

    const moduleName = 'companyprofile';

    export default {
        name: moduleName + 'Record',
       // mixins: [mixins.FORMRECORD],
        props: ["is_parentMask"],
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                schema: null,
                model: null,
                filter: null,
                columns: null,
                errors: null,
                data: null,
                ds: {
                    total: 0,
                    data: []
                },
                invalid: false
            }
        },
        validations() {
            /** DYNAMIC VALIDATION **/
            let validator = {
                model: {
                    firstName: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                    lastName: { maxLength: maxLength(35), $autoDirty: true },
                    email: { required, email, minLength: minLength(1), maxLength: maxLength(100), $autoDirty: true },
                    mobile: { required, $autoDirty: true, maxLength: maxLength(25), regex: helpers.withMessage(`The Mobile field only accepts numeric values.`, regex('^[0-9]*$')) },
                    telephone: { $autoDirty: true, maxLength: maxLength(25) },
                    eori: { $autoDirty: true },
                    eoriNorthernIreland: { $autoDirty: true },
                    eoriEuropean: { $autoDirty: true },
                    iossNumber: { $autoDirty: true },
                    ukimsNumber: { $autoDirty: true },
                    vatNo: { $autoDirty: true, maxLength: maxLength(35) },
                    identityNumber: { $autoDirty: true },
                    identityNumberType: { $autoDirty: true }
                }
            };
            if ((this.model.telephone?.length ?? 0) > 0)
                validator.model.telephone = { $autoDirty: true, maxLength: maxLength(25), regex: helpers.withMessage('The Telephone field only accepts numeric values.', regex('^[0-9]*$')) };
            if ((this.model.iossNumber?.length ?? 0) > 0)
                validator.model.iossNumber = { maxLength: maxLength(15), $autoDirty: true, regex: helpers.withMessage(`The IOSS must follow this format: IM9999999999.`, regex('^IM[0-9a-zA-Z]{10,13}$')) }
            if ((this.model.ukimsNumber?.length ?? 0) > 0)
                validator.model.ukimsNumber = { maxLength: maxLength(35), $autoDirty: true, regex: helpers.withMessage(`The UKIMS must follow this format: XXUKIMZZZZZZZZZZZZZZZYYYYMMDDHHmmss, where XX - ISO2 Either GB or XI, Z - 13-15 alphanumeric character (EORI), YYYYMMDDHHmmss - approval date.`, regex('^(GB|XI)(UKIM)([a-zA-Z0-9]{12,15})(2[0-9]{3})((01|03|05|07|08|10|12)(0[1-9]|1[0-9]|2[0-9]|3[0-1])|(04|06|09|11)(0[1-9]|1[0-9]|2[0-9]|30)|02(0[1-9]|1[0-9]|2[0-9]))(([0-1][0-9])|2[0-3])([0-5][0-9])([0-5][0-9])$')) }
            if ((this.model.eori?.length ?? 0) > 0)
                validator.model.eori = { maxLength: maxLength(15), $autoDirty: true, regex: helpers.withMessage(`Invalid EORI format. Format: UUAAAAAAAAAAA, where UU - ISO2, and A - alphanumeric characters`, regex('^(XI[a-zA-Z0-9]{12,15}|GB[a-zA-Z0-9]{12,15}|(AT|BE|BG|CY|CZ|DE|DK|EE|EL|ES|FI|FR|HR|HU|IE|IT|LT|LU|LV|MT|NL|PL|PT|RO|SE|SI|SK)[a-zA-Z0-9]{8,15})$')) }
            if ((this.model.eoriNorthernIreland?.length ?? 0) > 0)
                validator.model.eoriNorthernIreland = { maxLength: maxLength(15), $autoDirty: true, regex: helpers.withMessage(`Invalid EORI format. Format: XIAAAAAAAAAAA, where XI - is fixed, and A - alphanumeric characters`, regex('^XI[a-zA-Z0-9]{12,15}$')) }
            if ((this.model.eoriEuropean?.length ?? 0) > 0)
                validator.model.eoriEuropean = { maxLength: maxLength(15), $autoDirty: true, regex: helpers.withMessage(`Invalid EU EORI format. Format: UUAAAAAAAAAAA, where UU - ISO2, and A - alphanumeric characters`, regex('^(AT|BE|BG|CY|CZ|DE|DK|EE|EL|ES|FI|FR|HR|HU|IE|IT|LT|LU|LV|MT|NL|PL|PT|RO|SE|SI|SK)[a-zA-Z0-9]{8,15}$')) }

            return validator;
        },
        watch: {
            model:
            {
                handler() {
                    this.$emit('is-sync', this.model);
                    this.markAsInvalid();
                },
                deep: true
            },
            invalid:
            {
                handler() {
                    this.$emit('is-validation-status', this.invalid);
                },
                deep: true
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            init() {
                var model = this.$store.getters[moduleName + "/init"];
                return this.$filters.init(model);
            },
            currencyDS() { return this.$store.getters["currency/ds"]; },
            limitedAccess() {
                return ((this.user?.userType ?? 0) === this.userTypes.ENVIRONMENT_USER) || ((this.user?.userType ?? 0) === this.userTypes.CUSTOMER_USER) || ((this.user?.userType ?? 0) === this.userTypes.RETAILER_USER);
            },
            userTypes() {
                return {
                    GLOBAL_ADMINISTRATOR: 0,
                    SYSTEM_ADMINISTRATOR: 2,
                    ENVIRONMENT_ADMINISTRATOR: 3,
                    ENVIRONMENT_USER: 4,
                    CUSTOMER_ADMINISTRATOR: 5,
                    CUSTOMER_USER: 6,
                    RETAILER_USER: 7
                }
            }
        },
        methods: {
            async getSetting(mask) {
                await this.$axios
                    .get(config.api + `/services/settings/companyprofile/ByCompany/${mask}`.replaceAll("//", "/"))
                    .then((response) => {
                        this.model = response.data.result;
                        this.$emit("is-initial-sync", this.model);
                    
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            markAsInvalid() {
                this.invalid = (this.v$.model?.firstName?.$silentErrors[0]?.$message ?? this.v$.model?.firstName?.$errors[0]?.$message ??
                    this.v$.model?.lastName?.$silentErrors[0]?.$message ?? this.v$.model?.lastName?.$errors[0]?.$message ??
                    this.v$.model?.email?.$silentErrors[0]?.$message ?? this.v$.model?.email?.$errors[0]?.$message ??
                    this.v$.model?.telephone?.$silentErrors[0]?.$message ?? this.v$.model?.telephone?.$errors[0]?.$message ??
                    this.v$.model?.mobile?.$silentErrors[0]?.$message ?? this.v$.model?.mobile?.$errors[0]?.$message ??
                    this.v$.model?.vatNo?.$silentErrors[0]?.$message ?? this.v$.model?.vatNo?.$errors[0]?.$message ??
                    this.v$.model?.eori?.$silentErrors[0]?.$message ?? this.v$.model?.eori?.$errors[0]?.$message ??
                    this.v$.model?.eoriNorthernIreland?.$silentErrors[0]?.$message ?? this.v$.model?.eoriNorthernIreland?.$errors[0]?.$message ??
                    this.v$.model?.eoriEuropean?.$silentErrors[0]?.$message ?? this.v$.model?.eoriEuropean?.$errors[0]?.$message ??
                    this.v$.model?.iossNumber?.$silentErrors[0]?.$message ?? this.v$.model?.iossNumber?.$errors[0]?.$message ?? "" !== "");
            }
        },
        created() {
            this.model = this.init;
            this.markAsInvalid();
           
        },
        mounted() {
            if (this.$route.params.mask ?? this.is_parentMask)
                this.getSetting(this.$route.params.mask ?? this.is_parentMask,true).then(() => {
                    this.$emit("is-mounted", true);
                });
            this.v$.$touch();
            this.markAsInvalid();
        },
        updated() {
           // this.$emit("is-sync", this.model);
        }
    }
</script>
