<template>
    <keep-alive>
        <is-dialog id="accountsubcription-form"  header="Carrier Account" :breakpoints="{'2500px': '85vw', '960px': '75vw', '640px': '100vw'}" :style="[{width: '50vw'}, {'overflow-x': 'hidden'}]" :visible="dialog.account" @is-confirm="onConfirm" @is-cancel="onClose" :confirmDisabled="confirmDisabled">
            <accountsubcription-form :accountID="account?.data?.accountDetail?.id ?? -1" :customerID="customerID" :environmentID="environmentID" :formData="account" :subscriptions="subscriptions" @is-sync="model = this.$filters.init($event)"/>
        </is-dialog>
    </keep-alive>
    <keep-alive>
        <is-dialog header="Delete Record?" :visible="dialog.delete" @is-confirm="DELETE_onClick" @is-cancel="{this.dialog.deletekey = null; this.dialog.delete = false; this.account = null; }">
            <div class="confirmation-content p-d-flex">
                <div>
                    <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
                </div>
                <div>
                    You are about to delete this record:
                    <div style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                        <div class="p-grid">
                            <div class="p-col-fixed" style="width: 100px;">Code</div>
                            <div class="p-col p-code-text">: {{account.data.code}}</div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-fixed" style="width: 100px;">Name</div>
                            <div class="p-col p-code-text">: {{account.data.name ?? account.data.serviceProvider.name}}</div>
                        </div>
                    </div>
                    Please be aware of the following:
                    <ul>
                        <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                        <li>For records that has activity, the record will only be disabled and archived.</li>
                        <li>Also deleting a record may be irreversible action.</li>
                    </ul>
                    <br />Are you sure you still want to proceed?
                </div>
            </div>
        </is-dialog>
    </keep-alive>
    <div class="card" style="margin: -25px -10px !important; background: transparent; border: 0 !important">
        <pv-treetable :value="accounts" :paginator="true" :rows="5" style="border: 0;" class="p-treetable-sm" :scrollable="true" stripedRows>
            <template #header>
                <div class="p-d-flex p-jc-between">
                    <div>
                        <pv-button v-if="!limitedAccess" type="Button" icon="pi pi-plus" label="Add Subscriptions" class="p-mr-2" @click="{ this.account = null;    this.dialog.account = true;}"></pv-button>
                    </div>
                    <div class="p-as-end">
                        <div class="p-text-right">
                            <div class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <pv-input placeholder="Global Search" size="50" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <pv-grid-column field="code" header="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Code" :expander="true" style="width: 250px; max-width: 250px" />
            <pv-grid-column field="serviceProvider.name" header="Service Provider" style="min-width: 300px " />
            <pv-grid-column field="accountDetail.accountNumber" header="Account Number" style="max-width: 200px " />
            <pv-grid-column field="contractOwner.name" header="Contract Owner" style="max-width: 300px " />
            <pv-grid-column field="contractCountry.name" header="Contract Country" style="max-width:300px;">
                <template #body="slotProps">
                    <span v-if="slotProps.node.data.contractCountry.code !== ''" :class="`flag flag-${slotProps?.node?.data?.contractCountry?.code?.toLowerCase()}`" style="width: 30px; height: 20px; max-width: 30px; max-height: 20px; border-radius: 4px; margin-right: 5px;"></span>
                    {{ slotProps?.node?.data?.contractCountry?.name }}
                </template>
            </pv-grid-column>
            <pv-grid-column header="Options" style="width: 100px; max-width:100px" alignFrozen="right" :frozen="true">
                <template #body="slotProps">
                    <pv-button :disabled="limitedAccess" v-if="!slotProps.node.data.isSubDetails" type="Button" icon="pi pi-pencil" class="p-mr-2" @click="onEdit(`${slotProps?.node?.key}`)"></pv-button>
                    <pv-button :disabled="limitedAccess" v-if="!slotProps.node.data.isSubDetails" type="Button" icon="pi pi-trash" class="p-mr-2 p-button-danger" @click="onDelete(`${slotProps?.node?.key}`)"></pv-button>
                </template>
            </pv-grid-column>
            <template #empty>
                No records found.
            </template>
            <template #loading>
                Retrieving records from database. Please wait...
            </template>
        </pv-treetable>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import accountsubcription from "@/components/admin/provideraccount/accountsubscription-form.vue";
    export default {
        name: 'CustomerAccountGrid',
        emits: ["is-sync"],
        props: {
            customerID: { type: Number, default: -1 },
            environmentID: { type: Number, default: -1000 }
        },
        components: {
            'accountsubcription-form': accountsubcription
        },
        data() {
            return {
                model: null,
                account: null,
                accounts: [],
                dialog: {
                    account: false,
                    delete: false,
                    deletekey: null
                }
            }
        },
        
        methods: {
            rebind() {
                this.$store.dispatch("provideraccountcustomer/getURL", { url: `/services/admin/serviceprovideraccountcustomer/handlerbyaccount/id/${this.customerID}` }).then((response) => {
                    let accounts = response?.result ?? [];
                    let storedAccounts = [];
                    accounts.forEach((data) => {
                        data.accountDetail.accountNumber = data.accountDetail.code.split('_').splice(1, 1).join();
                        let products = [];
                        (data.products ?? []).forEach((product) => {
                            product.serviceProvider = { id: product?.serviceProvider.id, name: product?.serviceProvider.name };
                            product.accountDetail = { accountNumber: "" };
                            product.contractOwner = { name: "" };
                            product.contractCountry = { code: "", name: "" };
                            product.isSubDetails = true;
                            products.push(this.$filters.init({
                                key: product.id,
                                data: this.$filters.init(product)
                            }));
                        });
                        data.products = products;
                        storedAccounts.push(this.$filters.init({
                            key: data.id,
                            data: this.$filters.init(data),
                            children: products
                        }));
                    });
                    this.accounts = storedAccounts;
                });
            },
            delete(key) {
                this.$store.dispatch("provideraccountcustomer/deleteRecordByID", key).then(() => {
                    this.rebind();
                });
            },
            onEdit(key) {
                console.log(this.accounts);
                this.account = this.$filters.init(this.accounts.filter(x => x.key === Number(key))[0]);
                this.dialog.account = true;
            },
            onDelete(key) {
                this.account = this.accounts.filter(x => x.key === Number(key))[0];
                this.dialog.deletekey = key;
                this.dialog.delete = true;
            },
            DELETE_onClick() {
                this.delete(this.dialog.deletekey);
                this.dialog.deletekey = null;
                this.dialog.delete = false;
            },
            async onConfirm() {
                this.model.children = this.model.children.filter(x => x.data.serviceProvider.id !== null);
                this.$store.dispatch("provideraccountcustomer/postURL",
                    {
                        url: "/services/admin/serviceprovideraccountcustomer/addtocustomer",
                        payload: {
                            id: this.model.key < -1 ? 0 : this.model.key,
                            code: this.model.code ?? this.model.data.code,
                            accountID: this.model.data.accountDetail.id,
                            customerID: this.customerID,
                            products: this.model.children.map(x => x.data.serviceProvider.id),
                            excludeArchivedLabel: this.model.data.excludeArchivedLabel,
                            arrangeOutboundCollection: this.model.data.arrangeOutboundCollection,
                            returnReferenceOnly: this.model.data.returnReferenceOnly,
                            standardLabelType: this.model.data.standardLabelType,
                            returnLabelType: this.model.data.returnLabelType,
                            schedule_StrictCutOff: this.model.data.schedule_StrictCutOff,
                            schedule_CutOffTime: this.model.data.schedule_CutOffTime,
                            setting_AutoCollect: this.model.data.setting_AutoCollect,
                            setting_DaysToAutoCollect: this.model.data.setting_DaysToAutoCollect,
                            setting_TimeToAutoCollect: this.model.data.setting_TimeToAutoCollect,
                            attribute_01: this.model.data.attribute_01,
                            attribute_02: this.model.data.attribute_02,
                            attribute_03: this.model.data.attribute_03,
                            attribute_04: this.model.data.attribute_04,
                            attribute_05: this.model.data.attribute_05,
                            attribute_06: this.model.data.attribute_06,
                            attribute_07: this.model.data.attribute_07,
                            attribute_08: this.model.data.attribute_08,
                            attribute_09: this.model.data.attribute_09,
                            attribute_10: this.model.data.attribute_10
                        }
                    })
                    .then((response) => {
                        if (response.success) {
                            this.rebind();
                            this.model = null;
                            this.account = null;
                            this.dialog.account = false;
                        }
                        else {
                            let errorMessage = "<ul>";
                            if (response.errors !== undefined && response.errors != null) {
                                if (response.errors.error !== undefined && response.errors.error !== null)
                                    (response.errors.error ?? []).forEach(err => {
                                        errorMessage += "<li>" + err + "</li>";
                                        });
                            }
                            errorMessage += "</ul>";
                            this.$toast.add({ severity: 'error', summary: 'Record Failed!', detail: errorMessage, life: 30000 });
                        }
                        
                    })
                    .catch(() => { });
            },
            async onClose() {
                this.model = null;
                this.account = null;
                this.dialog.account = false;
            },
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            limitedAccess() {
                return ((this.user?.userType ?? 0) === this.userTypes.ENVIRONMENT_USER) || ((this.user?.userType ?? 0) === this.userTypes.CUSTOMER_USER) || ((this.user?.userType ?? 0) === this.userTypes.RETAILER_USER);
            },
            userTypes() {
                return {
                    GLOBAL_ADMINISTRATOR: 0,
                    SYSTEM_ADMINISTRATOR: 2,
                    ENVIRONMENT_ADMINISTRATOR: 3,
                    ENVIRONMENT_USER: 4,
                    CUSTOMER_ADMINISTRATOR: 5,
                    CUSTOMER_USER: 6,
                    RETAILER_USER: 7
                }
            },
            subscriptions() { return this.accounts; },
            confirmDisabled() {
                if (this.model)
                    return this.model.children.filter(x => x.data.id !== null).length === 0;
                return false;
            }
        },
        watch: {
            'customerID': { handler() { this.rebind(); }, deep: true },
        },
        async created() {
            this.rebind();
        },
        async mounted() {
            this.rebind();
        }
    }
</script>
<style>
    .p-picklist-header { padding: 0 10px !important; }
    .custom-skeleton { border: 1px solid var(--surface-d); border-radius: 4px; }
    .custom-skeleton ul { list-style: none; }
    .custom-skeleton ul > li { padding: 0.75rem; }
    #accountsubcription-form .p-dialog-content {
        overflow: hidden !important;
    }
</style>