<template>
    <cosmos-grid id="ReturnThreadGrid" sourceType="array" :source="source" :showHeader="false" :showOperation="false" :showCheckbox="false">
        <pv-grid-column field="actionDate" header="Action Date" headerStyle="min-width: 13rem; max-width: 13rem; width: 13rem" style="min-width: 13rem; max-width: 13rem; width: 13rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; ">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ $filters.toDateFullTimeDisplay(slotProps.data?.actionDate) }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="location" header="Location" headerStyle="min-width: 13rem; max-width: 25rem; width: 13rem" style="min-width: 13rem; max-width: 25rem; width: 13rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.location }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="description" header="Details">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.description }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="source" header="Source" headerStyle="min-width: 13rem; max-width: 20rem; width: 13rem" style="min-width: 13rem; max-width: 20rem; width: 13rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.source }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
    </cosmos-grid>
</template>
<script>
    export default {
        name: 'ReturnThreadGridTemplate',
        props: {
            parentID: { type: Number },
        },
        data() {
            return {
                source: []
            }
        },
        watch: {
            parentID() {
                this.getSource()
            }
        },
        methods: {
            getSource() {
                this.$store.dispatch("returns/getURL", { url: `/services/tms/return/${this.parentID}/threads` })
                    .then((response) => {
                        this.source = response?.result ?? [];
                    });
            }
        },
        async created() {
            this.getSource()
        }
    }
</script>