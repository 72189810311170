<template>
    <cosmos-grid :id="id" :searchFilter="['code', 'name']" :source="sources.products" sourceType="array" :paged="false" :autoGenerate="false" :deleteDisplayFields="deleteDisplay" :showAdd="false" :showDelete="false" :showExport="false" :showOperation="false" :pageSize="5">
        <pv-grid-column field="Code" header="DETAILS" headerStyle="padding-left: 15px; padding-top: .8rem !important; padding-bottom: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; display: block;  padding-top: 0 !important; padding-bottom: 0 !important">
            <template #body="slotProps">
                <div class="p-grid" style="padding: 0; margin: 0; ">
                    <div class="p-col-12 p-md-8" style="padding-bottom: 0 !important;">
                        <div class="p-d-flex" style="width: 100%; z-index: 100; margin-bottom: 0 !important; ">
                            <div><h5 style="margin-top: 0;"><span>{{slotProps.data.name }}</span></h5></div>
                        </div>
                        <div>
                            <div class="p-grid" style="z-index: 100; width: 100% !important; margin-bottom: 0 !important; padding-bottom: 0 !important">
                                <div class="p-col-12 p-md-6">
                                    <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Maximum Package: </b>  {{ slotProps.data.maxParcelCount }} per consignment</p>
                                    <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Weight Range: </b>  {{ slotProps.data.minWeight?.toFixed(3) }} - {{ slotProps.data.maxWeight?.toFixed(3) }} {{ slotProps.data.weightUnitName?.toLowerCase() }}</p>
                                    <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Max Dimension: </b>  {{ slotProps.data.maxLength }}{{ slotProps.data.lengthUnitName?.toLowerCase() }} x {{ slotProps.data.maxWidth }}{{ slotProps.data.lengthUnitName?.toLowerCase() }} x {{ slotProps.data.maxHeight }}{{ slotProps.data.lengthUnitName?.toLowerCase() }}</p>
                                </div>
                                <div class="p-col-12 p-md-6">
                                    <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Maximum Transit Days: </b> {{ slotProps.data.maxDeliveryDay }} {{ $filters.quantifiedDisplay("days", slotProps.data.maxDeliveryDay) }}</p>
                                    <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Estimated Time: </b>  {{ slotProps.data.deliveryHourTimeSpan.split(':').map(x => Number(x))[0] === 23 ? "Within the day" : $filters.padLeft(slotProps.data.deliveryHourTimeSpan.split(':').map(x => Number(x))[0] - ( slotProps.data.deliveryHourTimeSpan.split(':').map(x => Number(x))[0] > 12 ? 12 : 0), 2, '0') + ":" + $filters.padLeft(slotProps.data.deliveryHourTimeSpan.split(':').map(x => Number(x))[1], 2, '0') + (slotProps.data.deliveryHourTimeSpan.split(':').map(x => Number(x))[0] >= 12 ? " PM" : " AM") }}</p>
                                    <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem">
                                        <b class="p-code-text">Delivery Type: </b><span v-for="item in slotProps.data.usageTypeName" :key="item" :class="`p-code-text p-text-indicator definite-${item.toUpperCase()}`">{{ item }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-4" style="text-align: right; object-position: right !important">
                        <div style="text-align: right; object-position: right">
                            <h5><span class="p-code-text">[{{ slotProps.data.code }}]</span></h5>
                            <h5 style="margin-top: 0 !important;"><span :class="`p-code-text p-indicator definite-${slotProps.data.timeDefiniteType}`">{{ slotProps.data.timeDefiniteTypeName}}</span></h5>
                            <h5 style="margin-top: 0 !important;">
                                <span v-for="item in slotProps.data.productContentTypeName" :key="item" :class="`p-code-text p-indicator definite-${item.toUpperCase()}`">{{ item }}</span>
                            </h5>
                        </div>
                    </div>
                </div>
            </template>
        </pv-grid-column>
    </cosmos-grid>
</template>
<script>
    export default {
        name: "ProductGrid",
        props: {
            id: { type: String },
            code: { type: String, default: null },
            provider: { type: String, default: null }
        },
        data() {
            return {
                sources: {
                    products: []
                }
            }
        },
        computed: {
            ticks() { return this.$moment.utc().format("YYYYMMDD"); }
        },
        watch: {
            provider() {
                this.getSource();
            }
        },
        methods: {
            getSource() {
                this.sources.products = [];
                this.$nextTick(() => {
                    if (this.provider) {
                        this.$store.dispatch("providerproduct/getURL", { url: `/services/settings/serviceproviderproduct/byprovider/${this.provider}` })
                            .then((response) => {
                                if (response && response.success) {
                                    this.sources.products = response?.result ?? response ?? [];
                                }
                            });
                    }
                })
            }
        },
        created() { this.getSource(); }
    }
</script>
<style>
    .p-indicator {
        padding: 5px 10px;
        color: var(--surface-b) !important;
        width: max-content !important;
        font-size: 0.8rem;
        text-transform: uppercase;
        border-radius: 4px;
        font-weight: bold !important;
        border: 1px solid var(--surface-d);
        margin-left: 5px;
    }

        .p-indicator.definite-100 {
            background: var(--bg-warning) !important;
            color: #212529 !important;
        }

        .p-indicator.definite-200 {
            background: var(--bg-help) !important;
        }

        .p-indicator.definite-DOCUMENT {
            background: var(--bg-danger) !important;
        }

        .p-indicator.definite-NON-DOCUMENT {
            background: var(--bg-info) !important;
        }

        .p-indicator.definite-NON-DOCUMENT {
            background: var(--bg-info) !important;
        }

        .p-indicator.definite-NON-DOCUMENT {
            background: var(--bg-info) !important;
        }

    .p-text-indicator {
        width: max-content !important;
        text-transform: uppercase;
        font-weight: bold !important;
        margin-left: 5px;
    }

        .p-text-indicator.definite-STANDARD {
            color: var(--text-success);
        }

        .p-text-indicator.definite-OPTIONS {
            color: var(--text-info);
        }

        .p-text-indicator.definite-ACCESS {
            color: var(--text-danger);
        }

        .p-text-indicator.definite-RETURNS {
            color: var(--text-help);
        }

    .p-multiselect.p-component.p-inputwrapper.p-fluid.p-multiselect-custom {
        min-height: 40px;
    }
</style>