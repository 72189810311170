<template>
    <form-wrapper title="Shipping Zones" sub-title="List of available rate shipping zones.">
        <cosmos-grid id="ServiceProviderList" :searchFilters="['code', 'name']" :deleteColumns="['code', 'name']" :autoGenerate="true" :columns="columns" :deleteDisplayFields="deleteDisplay" :showDelete="true" :showAdd="true" :showExport="false" :operationWidth="7" :baseRecordID="0">
            <pv-grid-column field="code" header="CODE" headerStyle="width: 14.7rem; max-width: 14.7rem; padding-left: 15px; padding-top: .8rem !important;" style="width: 14.7rem; max-width: 14.7rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;"></pv-grid-column>
            <pv-grid-column field="name" header="NAME" headerStyle="padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;"></pv-grid-column>
            <pv-grid-column field="provider.name" header="PROVIDER" headerStyle="padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;"></pv-grid-column>
            <pv-grid-column field="providerCarrier.name" header="CARRIER" headerStyle="padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;"></pv-grid-column>
        </cosmos-grid>
    </form-wrapper>
</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/mixin.js';
    import { FilterMatchMode } from 'primevue/api';
    export default {
        name: "ZoneIndex",
        mixins: [mixins.GRID],
        data() {
            return {
                columns: [
                    { field: 'code', header: 'Code', width: 15 },
                    { field: 'name', header: 'Name', width: 20 },
                    { field: 'provider.name', header: 'Provider', width: 20 },
                    { field: 'providerCarrier.name', header: 'Carrier' }
                ],
            }
        },
        computed: {
            filters() {
                return {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
                };
            }
        },
        mounted() { this.loading = false; },
    }
</script>
