<template>
    <div class="p-fluid p-grid">
        <div class="p-col-12 p-md-8">
            <div style="border-bottom: 2px solid var(--surface-d); font-size: 1.25rem; text-transform: uppercase; padding-left: 5px; margin-bottom: 20px;">Account Details</div>
            <div class="p-d-flex p-grid">
                <div class="p-col-12 p-md-6" style="height: 100%">
                    <form-input id="code" :value="model.code" :disabled="true" @is-sync="model.code = $event.value" v-uppercase :max-length="100"></form-input>
                    <form-input id="name" :value="model.name" @is-sync="model.name = $event.value" :v$="v$" :maxLength="50" v-focus></form-input>
                    <form-lookup id="serviceProviderID" :show-copy="false" :disabled="model.id > 0" :value="model.serviceProviderID" label="Service Provider" :source="providers" @is-sync="setIntegration($event)" :v$="v$">
                        <template #option="slotProps">
                            <div class="p-d-flex" style="width:100%">
                                <div class="picklist-list-detail">
                                    <div class="p-d-flex" style="padding: 0;">
                                        <div style="padding:0;width:100%">
                                            <div class="p-d-flex" style=" z-index: 100;">
                                                <div style="margin-right:5px">
                                                    <img style="width: 35px; height: 20px; max-height: 20px; max-width: 35px; object-fit: contain; object-position: center;" :src="`/_assets/_integration/${slotProps.data.option.mask}.png`" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" />
                                                </div>
                                                <div class="p-d-flex p-jc-between" style="width:calc(100%)">
                                                    <span><span class="p-code-text" style="font-weight: 600;">[{{ slotProps.data.option.code }}] </span>{{ slotProps.data.option.name }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </form-lookup>
                    <form-lookup id="integrationID" :show-copy="false" label="Integration Version" :disabled="model.id > 0|| sources.integrations.length <= 1" :value="model.integrationID" :source="sources.integrations" @is-sync="setVersion($event)" :v$="v$" placeholder="Use Latest Version"></form-lookup>
                    <form-lookup id="contractCountryID" label="Country of Contract" type="dropdown" source="country" @is-sync="model.contractCountryID = $event.value; model.contractCountry = { code: $event.record.code, name: $event.record.altName ?? $event.record.name}" :value="model.contractCountryID" :v$="v$"></form-lookup>
                    <form-lookup id="contractCurrencyID" label="Contract Currency" type="dropdown" source="currency" @is-sync="model.contractCurrencyID = $event.value; model.contractCurrency = { code: $event.record.code, name: $event.record.name }" :value="model?.contractCurrencyID" :v$="v$"></form-lookup>
                    <form-boolean id="useQuotationAPI" v-if="((sources?.methods ?? []).filter(x => x === 'Rate Capability Tools').length > 0)" label="Use Quotation API" textPosition="left" width="150px" @is-sync="model.useQuotationAPI = $event.value;" :value="model?.useQuotationAPI" />
                </div>
                <div class="p-col-12 p-md-6">
                    <form-lookup id="tradeType" v-if="sources.integrations.length > 0" :disabled="sources.integrations.length === 0" :allowedFlags="selected.tradeType" label="Trade Types" type="flag" source="tradetype" @is-sync="model.tradeType = $event?.value" :value="model.tradeType" :v$="v$"></form-lookup>
                    <div v-if="model.serviceProviderID" style="padding: 0 10px;">
                        <label class="p-fixed-label" style="font-size: 0.9rem;">Supported Method(s)</label>
                        <div id="areaname" class="is-container p-field p-col-12 p-fluid" max-width="400px" style="position: relative;">
                            <div v-for="item in sources.methods" :key="item" class="selected-item p-code-text" style="font-size: 0.85rem; font-weight: 300; white-space: nowrap; text-wrap: normal; overflow: hidden; text-overflow: ellipsis;" v-html="`<i class='pi pi-chevron-right' style='font-size: 0.8rem; margin-right: 5px;'></i>${item ?? '&nbsp;'}`"></div>
                        </div>
                    </div>
                    <div v-if="sources.integrations.length > 0" style="border-bottom: 2px solid var(--surface-d); font-size: 1.25rem; text-transform: uppercase; padding-left: 5px; margin-bottom: 20px;">PRIORITY LABEL</div>
                    <form-lookup id="standardTypes" v-if="sources.integrations.length > 0 && (selected?.integration?.standardLabelTypes ?? 0) !== 0" :allowedFlags="selected?.integration?.standardLabelTypes" :defaultValue="selected?.integration?.defaultStandardLabelType" label="Standard Label Type" type="enum" source="labeltype" @is-csync="model.standardLabelType = ($event?.value ?? selected.defaultStandardLabelType)" v-model="model.standardLabelType" :v$="v$" :required="true"></form-lookup>
                    <form-lookup id="returnTypes" v-if="sources.integrations.length > 0 && (selected?.integration?.returnLabelTypes ?? 0) !== 0" :allowedFlags="selected?.integration?.returnLabelTypes" :defaultValue="selected?.integration?.defaultReturnLabelType" label="Return Label Type" type="enum" source="labeltype" @is-sycnc="model.returnLabelType = ($event?.value ?? selected.defaultReturnLabelType)" v-model="model.returnLabelType" :v$="v$" :required="true"></form-lookup>
                </div>
            </div>
        </div>
        <div class="p-col-12 p-md-4" v-if="model.integrationID != 0">
            <div style="border-bottom: 2px solid var(--surface-d); font-size: 1.25rem; text-transform: uppercase; padding-left: 5px; margin-bottom: 20px;">Credentials</div>
            <form-lookup id="accountType" disabled-type="object" :disabled="!companySettingRecord.setting_AllowTest && model.accountType === 100" label="Account Type" type="enum" source="serviceprovideraccounttype" @is-sync="model.accountType = $event.value" :value="model.accountType ?? 100" :v$="v$" :required="true"></form-lookup>
            <div v-for="grouping in [...new Set(selected.integration?.credentialFields?.map(x => x.group ?? 'default'))]" :key="grouping">
                <div v-if="(grouping !== 'default')" style="text-transform: uppercase; font-size: 1.25rem; font-weight: 800; margin-bottom: 1.5rem;">{{ grouping }}</div>
                <div v-for="credential in selected.integration?.credentialFields?.filter(x => grouping === 'default' ? x.group === undefined : x.group === grouping) ?? []" :key="credential.property">
                    <form-lookup v-if="(credential.dataSource ?? []).length > 0" :id="$filters.decamelize(credential.field)" :label="credential.label" :source="credential.dataSource" :value="model[$filters.decamelize(credential.field)] ?? credential.default" @is-sync="model[$filters.decamelize(credential.field)] = $event.value" valueField="value" :required="true" />
                    <form-input v-else :id="$filters.decamelize(credential.field)" :label="credential.label" :value="model[$filters.decamelize(credential.field)]" :placeholder="credential.placeholder" :required="credential.validation.required" :maxLength="credential.validation.maxLength" @is-sync="model[$filters.decamelize(credential.field)] = $event.value"></form-input>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from '@vuelidate/validators';
    export default {
        name: "CredentialForm",
        setup: () => ({ v$: useVuelidate() }),
        emits: ["is-sync"],
        props: {
            accountID: { type: Number, default: -1 },
            data: { type: Object }
        },
        data() {
            return {
                model: {
                    code: null,
                    name: null,
                    serviceProviderID: 0,
                    integrationID: 0,
                    tradeType: 0,
                    accountType: 100,
                    contractCountryID: null,
                    contractCurrencyID: null,
                    credential_01: null,
                    credential_02: null,
                    credential_03: null,
                    credential_04: null,
                    credential_05: null,
                    credential_06: null,
                    credential_07: null,
                    credential_08: null,
                    credential_09: null,
                    credential_10: null,
                    credential_11: null,
                    credential_12: null,
                    credential_13: null,
                    credential_14: null,
                    credential_15: null,
                    credential_16: null,
                    credential_17: null,
                    credential_18: null,
                    credential_19: null,
                    credential_20: null,
                    companyID: 0,
                    standardLabelType: null,
                    returnLabelType: null
                },
                sources: {
                    integrations: [],
                    tradeTypes: [],
                    methods: []
                },
                selected: {
                    integration: null,
                    tradeType: 0
                }
            }
        },
        computed: {
            credential() {
                let credentials = { AccountType: this.model.accountType === 100 ? "PRODUCTION" : "STAGING" };
                if (this.selected.integration) {
                    this.selected.integration.credentialFields.forEach((credential) => {
                        if (this.model[this.$filters.decamelize(credential.field)] && credential)
                            credentials[credential.property] = this.model[this.$filters.decamelize(credential.field)];
                    });
                }
                return credentials;
            },
            providers() {
                return this.$vm.$providers;
            },
            companySettingRecord() {
                return this.$store.getters["auth/configuration"];
            },
        },
        watch: {
            'credential': { handler() { this.$emit("is-credential", this.credential); }, deep: true },
            'data': { handler() { this.model = this.data; } },
            'model.credential_01': { handler() { this.updateCode(); }, deep: true },
            'model.credential_02': { handler() { this.updateCode(); }, deep: true },
            'model.credential_03': { handler() { this.updateCode(); }, deep: true },
            'model.credential_04': { handler() { this.updateCode(); }, deep: true },
            'model.credential_05': { handler() { this.updateCode(); }, deep: true },
            'model.credential_06': { handler() { this.updateCode(); }, deep: true },
            'model.credential_07': { handler() { this.updateCode(); }, deep: true },
            'model.credential_08': { handler() { this.updateCode(); }, deep: true },
            'model.credential_09': { handler() { this.updateCode(); }, deep: true },
            'model.credential_10': { handler() { this.updateCode(); }, deep: true },
            'model.credential_11': { handler() { this.updateCode(); }, deep: true },
            'model.credential_12': { handler() { this.updateCode(); }, deep: true },
            'model.credential_13': { handler() { this.updateCode(); }, deep: true },
            'model.credential_14': { handler() { this.updateCode(); }, deep: true },
            'model.credential_15': { handler() { this.updateCode(); }, deep: true },
            'model.credential_16': { handler() { this.updateCode(); }, deep: true },
            'model.credential_17': { handler() { this.updateCode(); }, deep: true },
            'model.credential_18': { handler() { this.updateCode(); }, deep: true },
            'model.credential_19': { handler() { this.updateCode(); }, deep: true },
            'model.credential_20': { handler() { this.updateCode(); }, deep: true },
            model: {
                handler() {
                    if (this.model.id === 0)
                        this.$emit("is-sync", { model: this.$filters.init(this.model), fields: this.selected?.integration?.credentialFields ?? [] });
                    else
                        this.$nextTick(() => this.$emit("is-sync", { model: this.$filters.init(this.model), fields: this.selected?.integration?.credentialFields ?? [] }));
                }, deep: true
            }
        },
        methods: {
            setIntegration(event) {
                if (event === null && event.value === null) {
                    this.model.serviceProviderID = null;
                    this.model.code = this.model.serviceProviderCode = null;
                    this.model.tradeType = 0;
                    this.sources.integrations = [];
                    this.selected.integration = null;
                }
                else if (event && event.record && event.record.id) {
                    this.model.serviceProviderName = "[" + event.record.code + "] " + event.record.name;
                    this.model.serviceProviderID = Number(event.record.id);
                    this.model.code = this.model.serviceProviderCode = event.record.code;
                    this.model.tradeType = (this.model?.tradeType ?? 0) > 0 ? this.model.tradeType : event.record.tradeType;
                    this.selected.tradeType = event.record.tradeType;
                    this.sources.methods = event.record.supportedMethods;
                    this.code = event.record.code;
                    this.sources.integrations = [];

                    
                    this.sources.integrations = (this.$vm.$integrations ?? []).filter(x => x.serviceProviderID === event.record.id);
                    if (this.sources.integrations.length > 0) {
                        this.selected.integration = this.sources.integrations.filter(x => x.id === this.model?.integrationID)[0] ?? this.sources.integrations[0];
                        this.model.integrationID = this.selected.integration.id;
                        this.model.integrationName = this.selected.integration.name;
                    }
                    this.updateCode();
                }
            },
            setVersion(event) {
                if ((this.sources.integrations ?? []).length > 0 && event.value) {
                    this.selected.integration = this.sources.integrations.filter(x => x.id === event.value)[0];
                    this.model.integrationID = this.selected.integration.id;
                    this.model.integrationCode = this.selected.integration.code;
                    this.model.integrationName = this.selected.integration.name;
                    this.updateCode();
                }
            },
            updateCode() {
                if (this.selected.integration) {
                    let newCode = this.selected.integration.code === "STANDARD" ? this.code : this.selected.integration.code;
                    let lastIndex = 0;
                    this.selected.integration.credentialFields.forEach((credential, index) => {
                        lastIndex = index + 1;
                        this.model[this.$filters.decamelize(credential.field) + 'Label'] = credential.label;
                        if (this.model[this.$filters.decamelize(credential.field)] && credential.isAccountNumber)
                            newCode += '_' + this.model[this.$filters.decamelize(credential.field)];
                    });
                    for (let index = lastIndex+1; index <= 20; index++)
                        this.model["credential_" + this.$filters.padLeft(index, 2, '0')] = null;
                    this.model.code = newCode.toUpperCase();
                }
            }
        },
        validations() {
            return {
                model: {
                    accountType: { required, $autoDirty: true },
                    tradeType: { $autoDirty: true },
                    code: { required, minLength: minLength(1), maxLength: maxLength(255), $autoDirty: true },
                    name: { required, minLength: minLength(1), maxLength: maxLength(50), $autoDirty: true },
                    serviceProviderID: { required, $autoDirty: true },
                    integrationID: { required, $autoDirty: true },
                    contractCountryID: { required, $autoDirty: true },
                    contractCurrencyID: { required, $autoDirty: true },
                }
            }
        },
        mounted() {
            this.model.contractCountryID = this.model.contractCountryID ?? this.$vm.$countries.filter(x => x.code === this.$vm.$location.country)[0].id;
            this.model.contractCurrencyID = this.model.contractCurrencyID ?? this.$vm.$currencies.filter(x => x.code === this.$vm.$location.currency)[0].id;
        }
    }
</script>