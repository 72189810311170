<template>
    <cosmos-grid id="reportGrid" sourceType="array" :source="source?.data ?? []" :showCheckbox="false" :sourcePaged="true" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :showExport="false" :showOperation="false" :showHeader="false">
        <pv-grid-column field="collectionDate" header="COLLECTION DATE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" :frozen="true">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ $filters.toDateDisplay(slotProps.data?.collectionDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="code" header="TRANSACTION ID" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <a :href="`/track/${slotProps.data?.code}`" target="_self" style="font-weight: bold;">{{slotProps.data.code}}</a>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference" header="REFERENCE 1" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <a :href="`/transaction/consignment/${slotProps.data?.mask}`" target="_self" style="font-weight: bold;">{{slotProps.data.customerReference}}</a>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference2" header="REFERENCE 2" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.customerReference2}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference3" header="REFERENCE 3" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.customerReference3}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="carrier" header="CARRIER" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.carrier}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="product" header="SERVICE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.product}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference" header="REFERENCE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <a :href="`${$filters.formatString($vm.$providers.filter((x) => x.id == slotProps.data?.serviceProviderID)[0]?.trackingURL, slotProps.data.reference,slotProps.data.shipToPostalCode)}`" target="_blank" style="font-weight: bold;"> {{slotProps.data.reference}} </a>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="exchangeRate" header="EXCHANGE RATE" headerStyle="min-width: 9.5rem;" style="min-width: 9.5rem; text-align: right !important;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatNumber(slotProps.data.exchangeRate ?? 0.00,8,slotProps.data.contractCountryCode)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="shipperCost" header="SHIPPER COST" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;">
                    <div class="p-d-flex p-ac-between">
                        <div v-if="(slotProps.data.shipperCost ?? 0.00) > 0.00" class="p-col">
                            <i class="fa fa-external-link" style="color: #2196f3; cursor: pointer;" title="View Quotation Computation" @click="getJson(slotProps.data?.code)"></i>
                        </div>
                        <div class="p-col" style="text-align: right !important;">
                            {{$filters.formatCurrency(slotProps.data.shipperCost ?? 0.00,slotProps.data.shipperCostCurrencyCode?.toUpperCase(),slotProps.data.contractCountryCode,2)}}
                        </div>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="carrierCost" header="CUSTOMER COST" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatCurrency(slotProps.data.carrierCost ?? 0.00,slotProps.data.carrierCostCurrencyCode?.toUpperCase(),slotProps.data.contractCountryCode,2)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="dc" header="WAREHOUSE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.dc}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customer" header="CUSTOMER" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.customer}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="shipFrom" header="COLLECTION ADDRESS" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.shipFrom}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="shipFromPostalCode" header="COLLECTION POSTAL CODE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.shipFromPostalCode}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="shipTo" header="DELIVERY ADDRESS" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.shipTo}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="shipToPostalCode" header="DELIVERY POSTAL CODE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.shipToPostalCode}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="totalParcel" header="TOTAL PARCEL" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatNumber(slotProps.data.totalParcel ?? 0.00,0,slotProps.data.contractCountryCode)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="totalWeight" header="TOTAL WEIGHT (KG)" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatNumber(slotProps.data.totalWeight ?? 0.00,3,slotProps.data.contractCountryCode)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="totalCBM" header="TOTAL CBM" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatNumber(slotProps.data.totalCBM ?? 0.00,4,slotProps.data.contractCountryCode)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="content" header="CONTENT" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.content}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="declaredValue" header="DECLARED VALUE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatCurrency(slotProps.data.declaredValue ?? 0.00,slotProps.data.consignmentCurrencyCode?.toUpperCase(),slotProps.data.contractCountryCode,2)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customsValue" header="CUSTOMS VALUE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatCurrency(slotProps.data.customsValue ?? 0.00,slotProps.data.consignmentCurrencyCode?.toUpperCase(),slotProps.data.contractCountryCode,2)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="insuranceValue" header="INSURANCE VALUE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatCurrency(slotProps.data.insuranceValue ?? 0.00,slotProps.data.consignmentCurrencyCode?.toUpperCase(),slotProps.data.contractCountryCode,2)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="manifestDate" header="MANIFEST DATE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{  $filters.toDateDisplay(slotProps.data?.manifestDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="dispatchDate" header="DISPATCH DATE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{  $filters.toDateDisplay(slotProps.data?.dispatchDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="outForDeliveryDate" header="OUT FOR DELIVERY DATE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{  $filters.toDateDisplay(slotProps.data?.outForDeliveryDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="podDate" header="POD DATE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{  $filters.toDateDisplay(slotProps.data?.podDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="accountNumber" header="ACCOUNT NUMBER" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.accountNumber}}
                </div>
            </template>
        </pv-grid-column>
    </cosmos-grid>
    <!-- Operation Dialog -->
    <is-dialog header="Detailed Computation" :visible="quote || quoteShow" @is-cancel="quoteShow = false; quote = null" :showConfirm="false">
        <div class="confirmation-content" style="margin: -2rem 0 !important">
            <quote-result :quote="quote" :resultView="200" />
        </div>
    </is-dialog>
</template>
<script>
    import { mapGetters } from 'vuex';
    import { useVuelidate } from '@vuelidate/core';
    import quote from '@/components/facility/rates/quote/quote-result-form.vue';
    export default {
        name: 'reportGrid',
        setup: () => ({ v$: useVuelidate() }),
        props: {
            formData: { type: Object }
        },
        components: {
            'quote-result': quote
        },
        data() {
            return {
                type: -1,
                filter: {},
                columns: [],
                deleteDisplay: ["code", "name"],
                searchFields: ["code", "name"],
                activity: { loading: true },
                source: {
                    total: 0,
                    data: []
                },
                modelState: null,
                initialBind: false,
                quote: null,
                quoteShow: false
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            scopeCustomerDS() {
                return this.$config.common.$customer(this.user?.environmentID ?? -100, this.$vm.$appSession.warehouse.id ?? this.user.warehouseID, this.user);
            },
            scopeWarehouseDS() {
                return this.$config.common.$warehouse(this.user?.environmentID ?? -100, this.user);
            },
            apiData() {
                return {
                    ServiceHeader: {
                        Username: "TEMP",
                        Password: "TESTESTEDET",
                        ClientID: "TESTTESTTESTTESTTEST",
                        ClientSecret: "TESTEDREDREDTETEDEDETEREDEDEREDE"
                    },
                    Request: {
                        DC: this.formData?.warehouseID > 0 ? this.scopeWarehouseDS.filter(x => x.id === this.formData.warehouseID)[0]?.code : null,
                        Customer: this.formData?.customerID > 0 ? this.scopeCustomerDS.filter(x => x.id === this.formData.customerID)[0]?.code : null,
                        Account: this.formData?.serviceProviderAccountCode,
                        Carrier: this.formData?.serviceProviderID > 0 ? this.$vm.$providers.filter(y => y.id === this.formData.serviceProviderID)[0]?.code : null,
                        ScopeDetail: {
                            IncludeArchive: this.formData?.includeArchived,
                            CollectionDate: {
                                From: this.$filters.formatDateWithFormat(this.formData.collectionDateFrom ?? new Date(), "YYYY-MM-DD"),
                                To: this.$filters.formatDateWithFormat(this.formData.collectionDateTo ?? new Date(), "YYYY-MM-DD")
                            },
                            ManifestDate: {
                                From: this.formData.manifestDateFrom !== undefined && this.formData.manifestDateFrom !== null ? this.$filters.formatDateWithFormat(this.formData.manifestDateFrom ?? new Date(), "YYYY-MM-DD") : null,
                                To: this.formData.manifestDateTo !== undefined && this.formData.manifestDateTo !== null ? this.$filters.formatDateWithFormat(this.formData.manifestDateTo ?? new Date(), "YYYY-MM-DD") : null
                            },
                            DispatchDate: {
                                From: this.formData.dispatchDateFrom !== undefined && this.formData.dispatchDateFrom !== null ? this.$filters.formatDateWithFormat(this.formData.dispatchDateFrom ?? new Date(), "YYYY-MM-DD") : null,
                                To: this.formData.dispatchDateTo !== undefined && this.formData.dispatchDateTo !== null ? this.$filters.formatDateWithFormat(this.formData.dispatchDateTo ?? new Date(), "YYYY-MM-DD") : null
                            }
                        }
                    }
                }

            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            'formData': {
                handler() {
                    this.$nextTick(() => {

                        this.getSource();
                    });
                }, deep: true
            }
        },
        methods: {
            async getSource() {
                this.$nextTick(() => {
                    this.$axios.post(this.$config.config.endpoint.api + `/services/report/consignment`.replaceAll("//", "/"), this.apiData)
                        .then((response) => {
                            this.source.data = response?.data?.result ?? [];
                            this.source.total = this.source.data.length;
                        })
                        .catch((ex) => {
                            this.source.data = [];
                            this.source.total = 0;
                            console.log(ex);
                        });
                });

            },

            getJson(transactionID) {
                this.$axios.get(this.$config.config.endpoint.api + `/services/facility/quote/consignments/${transactionID}/json`.replaceAll("//", "/"))
                    .then((response) => {
                        this.quote = JSON.parse(response.data);
                    })
                    .catch((ex) => {
                        this.quote = null;
                        this.quoteShow = true;
                        console.log(ex);
                    });

            }
        },
        async created() {
            this.loading = false;
          
        },
        async mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            this.loading = false;
           
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>