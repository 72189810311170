<template>
    <div class="p-d-flex p-ai-center p-jc-center" style="width: 100%; text-align:center !important; object-position:center !important;">
        <div style="width: 80rem; text-align:center !important">
            <pv-chart type="pie" :data="chartData" :options="lightOptions" :height="30" :width="100" />
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import { useVuelidate } from '@vuelidate/core';
    export default {
        name: 'reportChart',
        setup: () => ({ v$: useVuelidate() }),
        props: {
            formData: { type: Object }
        },
        data() {
            return {
                model: {
                    label: [],
                    data: [],
                    bgColor:[]
                },
                lightOptions: {
                    plugins: {
                        legend: {
                            position: 'right',
                            labels: {
                                color: '#495057'
                            }
                        }
                    }
                }
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            scopeWarehouseDS() {
                return this.$config.common.$warehouse(this.user?.environmentID ?? -100, this.user);
            },
            showCountry() {
                return this.$filters.getFlags(this.formData.handledBy ?? 0).findIndex(x => x === 1) != -1;
            },
            showYearWeek() {
                return this.$filters.getFlags(this.formData.handledBy ?? 0).findIndex(x => x === 2) != -1;
            },
            showTransit() {
                return this.$filters.getFlags(this.formData.handledBy ?? 0).findIndex(x => x === 4) != -1;
            },
            showWeight() {
                return this.$filters.getFlags(this.formData.additionalType ?? 0).findIndex(x => x === 1) != -1;
            },
            showCBM() {
                return this.$filters.getFlags(this.formData.additionalType ?? 0).findIndex(x => x === 2) != -1;
            },
            showProvider() {
                return this.$filters.getFlags(this.formData.additionalType ?? 0).findIndex(x => x === 4) != -1;
            },
            showProduct() {
                return this.$filters.getFlags(this.formData.additionalType ?? 0).findIndex(x => x === 8) != -1;
            },
            apiData() {
                // this.$filters.formatDateWithFormat(this.model.request_CollectionDate, "YYYY-MM-DD")
                return {
                    ServiceHeader: {
                        Username: "TEMP",
                        Password: "TESTESTEDET",
                        ClientID: "TESTTESTTESTTESTTEST",
                        ClientSecret: "TESTEDREDREDTETEDEDETEREDEDEREDE"
                    },
                    Request: {
                        DC: this.formData?.warehouseID > 0 ? this.scopeWarehouseDS.filter(x => x.id === this.formData.warehouseID)[0]?.code : null,
                        ScopeDetail: {
                            CollectionDate: {
                                From: this.$filters.formatDateWithFormat(this.formData.collectionDateFrom ?? new Date(), "YYYY-MM-DD"),
                                To: this.$filters.formatDateWithFormat(this.formData.collectionDateTo ?? new Date(), "YYYY-MM-DD")
                            },
                            ByCountry: this.showCountry,
                            ByWeek: this.showYearWeek,
                            ByTransit: this.showTransit,
                            PerWeight: this.showWeight,
                            PerCBM: this.showCBM,
                            PerCarrier: this.showProvider,
                            PerService: this.showProduct
                        }
                    }
                }

            },
            chartData() {
                return {
                    labels: this.model.label,
                    datasets: [
                        {
                            data: this.model.data,
                            backgroundColor: this.model.bgColor,
                            hoverBackgroundColor: this.model.bgColor
                        }
                    ]
                };
            },
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            'formData': {
                handler() {
                    this.$nextTick(() => {
                        this.getSource();
                    });
                }, deep: true
            }
        },
        methods: {
            async getSource() {
                this.$nextTick(() => {
                    this.$axios.post(this.$config.config.endpoint.api + `/services/report/kpi/parcelcountry/chart`.replaceAll("//", "/"), this.apiData)
                        .then((response) => {
                            
                            let label = [];
                            let data = [];
                            let bgColor = []
                            //Math.floor(Math.random()*16777215).toString(16);
                            label = response.data.result.map(y => y.category);
                            data = response.data.result.map(y => y.value);
                            bgColor = response.data.result.map(() => "#" + Math.floor(Math.random() * 16777215).toString(16));
                            this.model.label = label;
                            this.model.data = data;
                            this.model.bgColor = bgColor;
                            
                        })
                        .catch((ex) => {
                            //this.source.data = [];
                            //this.source.total = 0;
                            console.log(ex);
                        });
                });

            },
        },
        async created() {
            this.loading = false;
          
        },
        async mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            this.loading = false;
           
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>