<template>
    <p style="color: var(--text-gray-60); margin-top: 10px;"><b>STEP 3 (Optional): </b> Zone group are secondary zone name use to map route to rate zones, skip the step if not applicable.</p>
    <zonematrix-form :formData="this.$attrs.formData" :zoneID="this.$props.zoneID" @is-sync="onSync($event)" style="margin-top: 20px;" />
    <div class="p-grid p-nogutter p-justify-between" style="border-top: 2px solid var(--surface-d); margin: 10px -20px -10px -20px; padding: 20px 20px 0 20px; ">
        <pv-button label="Back" @click="prevPage()" icon="pi pi-angle-left" />
        <pv-button label="Next" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" :disabled="activity.loading || blockNext" />
    </div>
</template>
<script>
    import zonematrixform from '@/components/settings/zone/zonematrix-form.vue';
    import { useVuelidate } from '@vuelidate/core';
    export default {
        name: 'StepZoneGroup',
        emits: ["next-page"],
        setup: () => ({ v$: useVuelidate() }),
        components: { 'zonematrix-form': zonematrixform },
        props: {
            id: { type: String },
            zoneID: { type: String }
        },
        data() {
            return {
                credentialFields: [],
                model: {
                    id: 0,
                    code: null,
                    name: null,
                    tradeType: 3,
                    columnPosition: 1
                },
                zoneMatrix: [],
                credential: {},
                activity: {
                    loading: false
                }
            }
        },
        computed: {
            nextIcon() {
                if (this.activity.loading)
                    return "pi pi-spin pi-spinner";
                return "pi pi-angle-right";
            },
            nextLabel() {
                if (this.activity.loading)
                    return "Processing...";
                return "Next";
            },
            nextPosition() {
                if (this.activity.loading)
                    return "left";
                return "right";
            },
            blockNext() {
                return false;
            }
        },
        methods: {
            onSync(event) {
                this.zoneMatrix = event?.zoneMatrix ?? [];
            },
            nextPage() {
                this.$emit('next-page', { formData: { zoneMatrix: this.zoneMatrix }, pageIndex: 3 });
            },
            prevPage() {
                this.$emit('prev-page', { formData: { zoneMatrix: this.zoneMatrix }, pageIndex: 3 });
            }
        }
    }
</script>
