<template>
    <div class="p-d-flex p-grid p-col-12" style="margin: 20px auto;">
        <div class="p-col-12 p-md-12">
            <pv-orderlist v-if="showOrderList" v-model="assigned" listStyle="height:342px" dataKey="id" @move-to-target="moveToTarget" @move-all-to-target="moveAllToTarget" @move-to-source="moveToSource" @move-all-to-source="moveAllToSource">
                <template #header>
                    Assigned Companies
                </template>
                <template #item="slotProps">
                    <div class="picklist-item">
                        <div class="image-container">
                            <div class="p-d-flex p-jc-end" style="text-align: center; margin-right: 1rem; width: 10rem;">
                                <div class="p-d-flex p-ai-center p-jc-center" style="height: 100%; vertical-align: middle;">
                                    <img :src="`${cdn}/${tenant.code.toLowerCase()}/company/${slotProps.item.mask?.toUpperCase()}_logo_light.png?${Math.random()}`" style="object-fit: cover; max-height: calc(12rem / 3); max-width: 9rem; object-position: center center;" onerror="this.src = '/_assets/img/no-image.png'" />
                                </div>
                            </div>
                        </div>
                        <div class="picklist-list-detail">
                            <div class="p-d-flex p-col-12" style="padding: 0;">
                                <div>
                                    <div class="p-d-flex p-jc-between" style="width: calc(37rem - 30px); z-index: 100;">
                                        <div><h5 style="margin-bottom: 5px; margin-top: 0;"><span>{{ slotProps.item.altName ?? slotProps.item.name }}</span></h5></div>
                                    </div>
                                    <div class="p-d-flex">
                                        <div style="margin-right: 5px;"><i class="pi pi-map-marker pi-fw" style="font-size: 0.8rem" /></div>
                                        <div><p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.8rem">{{ slotProps.item.name }}, {{ slotProps.item.address1 ? slotProps.item.address1 + ',' : null }} {{ slotProps.item.address2 ? slotProps.item.address2 + ',' : null }} {{ slotProps.item.address3 ? slotProps.item.address3 + ',' : null}} {{ slotProps.item.city ? slotProps.item.city + ',' : null }} {{ slotProps.item.state ? slotProps.item.state + ',' : null }} {{ slotProps.item.postalCode ? slotProps.item.postalCode + ',' : null }} {{ slotProps.item.countryName ?? 'United Kingdom'}}</p></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="picklist-list-action">
                            <div class="p-as-end"><h5 class="p-code-text">[{{ slotProps.item.code}}]</h5></div>
                            <span :class="'product-badge status-'+ (slotProps.item.typeText != 'WAREHOUSE' ? (slotProps.item.typeText != 'ENVIRONMENT' ? 'lowstock' : 'instock') : 'outofstock')">{{slotProps.item.typeText}}</span>
                        </div>
                    </div>
                </template>
            </pv-orderlist>
            <pv-picklist v-if="showPickList" v-model="items" listStyle="height:342px" dataKey="id" @move-to-target="moveToTarget" @move-all-to-target="moveAllToTarget" @move-to-source="moveToSource" @move-all-to-source="moveAllToSource">
                <template #sourceHeader>
                    Available
                </template>
                <template #targetHeader>
                    Assigned
                </template>
                <template #item="slotProps">
                    <div class="picklist-item">
                        <div class="image-container">
                            <div class="p-d-flex p-jc-end" style="text-align: center; margin-right: 1rem; width: 10rem;">
                                <div class="p-d-flex p-ai-center p-jc-center" style="height: 100%; vertical-align: middle;">
                                    <img :src="`${cdn}/${tenant.code.toLowerCase()}/company/${slotProps.item.mask?.toUpperCase()}_logo_light.png?${Math.random()}`" style="object-fit: cover; max-height: calc(12rem / 3); max-width: 9rem; object-position: center center;" onerror="this.src = '/_assets/img/no-image.png'" />
                                </div>
                            </div>
                        </div>
                        <div class="picklist-list-detail">
                            <div class="p-d-flex  p-col-12" style="padding: 0;">
                                <div>
                                    <div class="p-d-flex p-jc-between" style="z-index: 100;">
                                        <div><h5 style="margin-bottom: 5px; margin-top: 0;"><span>{{ slotProps.item.altName ?? slotProps.item.name }}</span></h5></div>
                                    </div>
                                    <div class="p-d-flex">
                                        <div style="margin-right: 5px;"><i class="pi pi-map-marker pi-fw" style="font-size: 0.8rem" /></div>
                                        <div><p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.8rem">{{ slotProps.item.name }}, {{ slotProps.item.address1 ? slotProps.item.address1 + ',' : null }} {{ slotProps.item.address2 ? slotProps.item.address2 + ',' : null }} {{ slotProps.item.address3 ? slotProps.item.address3 + ',' : null}} {{ slotProps.item.city ? slotProps.item.city + ',' : null }} {{ slotProps.item.state ? slotProps.item.state + ',' : null }} {{ slotProps.item.postalCode ? slotProps.item.postalCode + ',' : null }} {{ slotProps.item.countryName ?? 'United Kingdom'}}</p></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="picklist-list-action">
                            <div class="p-as-end"><h5 class="p-code-text">[{{ slotProps.item.code}}]</h5></div>
                            <span :class="'product-badge status-'+ (slotProps.item.typeText != 'WAREHOUSE' ? (slotProps.item.typeText != 'ENVIRONMENT' ? 'lowstock' : 'instock') : 'outofstock')">{{slotProps.item.typeText}}</span>
                        </div>
                    </div>
                </template>
            </pv-picklist>
        </div>
    </div>
    
</template>
<script>
    import config from '@/assets/lib/cosmos/_js/config.js';
    const moduleName = 'userscope';

    export default {
        name: moduleName + 'Record',
        props: {
            is_parentID: { type: Number },
            is_parentMask: { type: String },
            disabled: { type: Boolean, default: false }
        },
        data() {
            return {
                model: null,
                items: null,
                available: [],
                assigned: [],
                dsByUser: [],
                actionUser: null
            }
        },
        computed: {
            showPickList() { return !this.disabled; },
            showOrderList() { return this.disabled; },
            init() {
                var model = this.$store.getters[moduleName + "/init"];
                return this.$filters.init(model);
            },
            user() { return this.actionUser; },
            cdn() { return `${config.config.endpoint.cdn}`; },
            tenant() { return config.common.$tenant().filter(x => x.id === 1)[0]; },
        },
        methods: {
            async getUser(mask) {
                await this.$axios
                    .get(this.$config.config.endpoint.api + `/services/settings/user/${mask}`.replaceAll("//", "/"))
                    .then((response) => {
                        this.actionUser = response.data.result;
                    })
                    .catch(() => { }); },
            async add(model) { return await this.$store.dispatch(moduleName + "/addRecord", model); },
            async remove(model) { return await this.$store.dispatch(moduleName + "/deleteRecordByMask", model.mask); },
            async moveToTarget(e) {
                e.items.forEach(x => {
                    this.model = this.init;
                    this.model.companyID = x.id;
                    this.model.type = x.type;
                    this.model.userID = this.is_parentID;
                    this.model.createuserID = this.model.updateuserID = this.user.id;
                    this.add(this.model).then(() => {
                        this.getAllByUser();
                    });
                });
            },
            async moveAllToTarget(e) {
                let commit = this;
                e.items.forEach(x => {
                    let userscope = commit.dsByUser.filter(ds => ds.companyID === x.id)[0];
                    if (userscope === null || userscope === undefined) {
                        let model = this.init;
                        model = commit.init;
                        model.companyID = x.id;
                        model.type = x.type;
                        model.userID = commit.is_parentID;
                        model.createuserID = model.updateuserID = commit.user.id;
                        commit.add(model).then(() => {
                            commit.getAllByUser();
                        });
                    }
                });
               
            },
            async moveToSource(e) {
                e.items.forEach(x => {
                    let userscope = this.dsByUser.filter(ds => ds.companyID === x.id)[0];
                    if (userscope !== null && userscope !== undefined) {
                        this.remove(userscope).then(() => { this.getAllByUser(); });
                    }
                });
            },
            async moveAllToSource(e) {
                e.items.forEach(x => {
                    let userscope = this.dsByUser.filter(ds => ds.companyID === x.id)[0];
                    if (userscope !== null && userscope !== undefined) {
                        this.remove(userscope).then(() => { this.getAllByUser(); });
                    }
                });
            },
            async getAvailable(mask) {
                await this.$axios
                    .get(this.$config.config.endpoint.api + `/services/settings/` + moduleName + `/Available/${mask}`.replaceAll("//", "/"))
                    .then((response) => {
                        this.available = response.data.result;
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            async getAssigned(mask) {
                await this.$axios
                    .get(this.$config.config.endpoint.api + `/services/settings/` + moduleName + `/Assigned/${mask}`.replaceAll("//", "/"))
                    .then((response) => {
                        this.assigned = response.data.result;
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            async getAllByUser(mask) {
                    this.$axios
                        .get(this.$config.config.endpoint.api + `/services/settings/` + moduleName + `/ByUser/${mask ?? this.$route.params.mask ?? this.is_parentMask}`.replaceAll("//", "/"))
                    .then((response) => {
                        this.dsByUser = response.data.result;
                    })
                    .catch(() => { });
            }
        },
        async mounted() {
            if (this.$route.params.mask ?? this.is_parentMask) {
                this.getAvailable(this.$route.params.mask ?? this.is_parentMask).then(() => {
                    this.items = [this.available, this.assigned];
                });
                this.getAssigned(this.$route.params.mask ?? this.is_parentMask).then(() => {
                    this.items = [this.available, this.assigned];
                });
                this.getAllByUser(this.$route.params.mask ?? this.is_parentMask);
            }
            if (this.$store.getters["auth/currentUser"] !== null) {
                this.getUser(this.$store.getters["auth/currentUser"].mask);
            }

        }

    }
</script>
<style scoped>
    .picklist-item {
        display: flex;
        align-items: center;
        padding: .5rem;
        width: 100%;
    }

    img {
        margin-right: 1rem;
    }

    .picklist-list-detail {
        flex: 1 1 0;
    }

    .picklist-list-action {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .picklist-category-icon {
        vertical-align: middle;
        margin-right: .5rem;
        font-size: .875rem;
    }

    .picklist-category {
        vertical-align: middle;
        line-height: 1;
        font-size: .875rem;
    }

   
</style>
<style>
    .p-orderlist-controls,
    .p-picklist-buttons.p-picklist-source-controls,
    .p-picklist-buttons.p-picklist-source-controls, .p-picklist-buttons.p-picklist-target-controls {
        display: none;
    }
    .picklist-badge {
        padding: 3px 3px 3px 3px;
    }
    .picklist-badge.status-WAREHOUSE {
        background-color: darkred;
        font-weight: bold;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
</style>