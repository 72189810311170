const config = {
    local: 'GB',
    security: { secret: 'F78E592A4262D6D40EC54A10AE21CA5C' },
    endpoint: {
        portal: 'https://cosmos-demo.integro360.com',
        api: 'https://cosmos-api-demo.integro360.com',
        polaris: 'https://cosmos-api-demo.integro360.com/api/polaris/1.0',
        cdn: 'https://cdn.integro360.com/cosmos/demo'
    },
    subscription: {
        fetchify: { key: 'd0bdf-216db-63c6f-02897' },
        googlemap: {
            url: 'https://www.google.com/maps/embed/v1/view?key={0}&amp;center={1},{2}&amp;zoom=17&amp;maptype=satellite',
            key: 'AIzaSyALrSTy6NpqdhIOUs3IQMfvjh71td2suzY'
        },
        ipapi: { url: "https://ipapi.co/{0}/json" },
        ipgeolocator: {
            url: 'https://api.ipgeolocation.io/ipgeo?ip={0}&amp;apiKey={1}',
            key: '452e2da98ab740599944d2331677aad2'
        },
        ipify: { url: "https://api.ipify.org?callback=?" },
    }
};
const httpClient = {
    get(url, json) {
        var client = new XMLHttpRequest();
        client.open("GET", (url.startsWith("http") ? url : config.endpoint.api + url.trimLeft('/')), false);
        client.send(null);
        return json ? JSON.parse(client.responseText) : client.responseText;
    }
};
const common = {
    $tenant() {
        let result = httpClient.get("/services/settings/tenant", true).result;
        let tenants = [];
        (result ?? []).forEach(x => {
            tenants.push({
                id: x.id,
                mask: x.mask,
                code: x.code,
                name: x.name,
                url: x.portalURL
            });
        });
        return tenants;
    },
    $environment() {
        let result = httpClient.get("/services/settings/company?type=2&bpf=1&activeOnly=true", true).result;
        let environments = [];
        (result ?? []).forEach(x => {
            environments.push({
                id: x.id,
                mask: x.mask,
                code: x.code,
                name: (x.altName ?? x.name),
                sortKey: (x.altName ?? x.name)?.toUpperCase(),
                environmentID: x.environmentID,
                tenantID: x.tenantID,
                address1: x.address1,
                address2: x.address2,
                address3: x.address3,
                city: x.city,
                state: x.state,
                postalCode: x.postalCode,
                countryID: x.countryID
            });
        });
        return environments.sort(tools.dynamicSort("sortKey"));
    },
    $warehouse(environmentID, user) {
        let result = httpClient.get("/services/settings/company?type=8&bpf=1&activeOnly=true", true).result;
        if (user !== null && user !== undefined) {
            if (([0/*Global Admin*/, 2 /*System Admin*/].findIndex(x => x == (user?.userType ?? 0))) === -1)
                result = httpClient.get("/services/settings/userscope/assigned/" + user.mask, true).result;
        }

        let warehouses = [];
        (result ?? []).forEach(x => {
            if (x.type === 8) {
                warehouses.push({
                    id: x.id,
                    mask: x.mask,
                    code: x.code,
                    name: x.altName !== null ? (x.altName ?? x.name) : x.name,
                    environmentID: x.environmentID,
                    sortKey: (x.altName !== null ? (x.altName ?? x.name) : x.name)?.toUpperCase(),
                    address1: x.address1,
                    address2: x.address2,
                    address3: x.address3,
                    city: x.city,
                    state: x.state,
                    postalCode: x.postalCode,
                    countryID: x.countryID
                });
            }
        });
        return warehouses.filter(x => x.environmentID == environmentID || x.environmentID === environmentID || x.environmentID === null).sort(tools.dynamicSort("sortKey"));
    },
    $customer(environmentID, warehouseID, user) {
        let result = httpClient.get("/services/settings/company?type=16&bpf=1&activeOnly=true", true).result;
        if (user !== null && user !== undefined) {
            if (([0/*Global Admin*/, 2 /*System Admin*/].findIndex(x => x == (user?.userType ?? 0))) === -1)
                result = httpClient.get("/services/settings/userscope/assigned/" + user.mask + "?type=" + (user?.userType ?? 0), true).result;
        }

        let customers = [];
        (result ?? []).forEach(x => {
            if (x.type === 16) {
                customers.push({
                    id: x.id,
                    mask: x.mask,
                    code: x.code,
                    name: x.altName !== null ? (x.altName ?? x.name) : x.name,
                    environmentID: x.environmentID,
                    sortKey: (x.altName !== null ? (x.altName ?? x.name) : x.name)?.toUpperCase(),
                    address1: x.address1,
                    address2: x.address2,
                    address3: x.address3,
                    city: x.city,
                    state: x.state,
                    postalCode: x.postalCode,
                    countryID: x.countryID
                });
            }
        });
        return customers.filter(x => (x.environmentID === environmentID || x.environmentID == environmentID || x.environmentID === null)).sort(tools.dynamicSort("sortKey"));
    },
    $customerFiltered(environmentID, warehouseID, user) {
        let result = httpClient.get("/services/settings/company?type=16&&bpf=1&activeOnly=true", true).result;
        let userCustomers = [];
        let warehouseCustomers = [];
        let filterByWarehouse = false;

        if (user !== null && user !== undefined)
            if (([0/*Global Admin*/, 2 /*System Admin*/].findIndex(x => x == (user?.userType ?? 0))) === -1) {
                result = httpClient.get("/services/settings/userscope/assigned/" + user.mask + "?type=" + (user?.userType ?? 0), true).result;
                userCustomers = result;
                if (([3, 4].findIndex(x => x == (user?.userType ?? 0))) !== -1)
                    filterByWarehouse = true;
            }
            else {
                filterByWarehouse = true;
            }
        else
            filterByWarehouse = true;
        if (filterByWarehouse && (user !== null && user !== undefined)) {
            if (warehouseID != null && warehouseID != undefined) {
                let warehouse = this.$warehouse(environmentID, user).filter(x => x.id == warehouseID)[0];
                if (warehouse != null) {
                    result = httpClient.get("/services/settings/companyscope/assigned/" + warehouse?.mask, true).result;
                    warehouseCustomers = result;
                }
            }
        }
        let customers = [];
        if ((userCustomers ?? []).filter(x => x.type == 16).length > 0 && (warehouseCustomers ?? []).filter(x => x.type == 16) > 0) {
            (warehouseCustomers ?? []).forEach(x => {
                if (x.type === 16 && ((userCustomers ?? []).filter(y => y.id == x.id && y.type == 16).length > 0)) {
                    customers.push({
                        id: x.id,
                        mask: x.mask,
                        code: x.code,
                        name: x.altName !== null ? (x.altName ?? x.name) : x.name,
                        environmentID: x.environmentID,
                        sortKey: (x.altName !== null ? (x.altName ?? x.name) : x.name)?.toUpperCase(),
                        address1: x.address1,
                        address2: x.address2,
                        address3: x.address3,
                        city: x.city,
                        state: x.state,
                        postalCode: x.postalCode,
                        countryID: x.countryID
                    });
                }
            });
        }
        else {
            (result ?? []).forEach(x => {
                if (x.type === 16) {
                    customers.push({
                        id: x.id,
                        mask: x.mask,
                        code: x.code,
                        name: x.altName !== null ? (x.altName ?? x.name) : x.name,
                        environmentID: x.environmentID,
                        sortKey: (x.altName !== null ? (x.altName ?? x.name) : x.name)?.toUpperCase(),
                        address1: x.address1,
                        address2: x.address2,
                        address3: x.address3,
                        city: x.city,
                        state: x.state,
                        postalCode: x.postalCode,
                        countryID: x.countryID
                    });
                }
            });
        }

        return customers.filter(x => (x.environmentID === environmentID || x.environmentID == environmentID || x.environmentID === null)).sort(tools.dynamicSort("sortKey"));
    },
    $accessScope(mask) {
        let result = httpClient.get(`/services/settings/user/scope/${mask}`, true);
        return result;
    },
    $integration() { return httpClient.get("/services/settings/serviceprovider", true).result; },
    $integrationtypes() { return httpClient.get("/services/settings/serviceproviderintegration", true).result; },
    $integrationcarriers() { return httpClient.get("/services/settings/serviceprovidercarrier", true).result; },
    $integrationproducts() { return httpClient.get("/services/settings/serviceproviderproduct", true).result; },
    $dynamicreportbasefields() { return httpClient.get("/services/facility/dynamicreportbasefield?", true).result; },
    $tz() { return httpClient.get("/timezone", true); },
    $currency() { return httpClient.get("/services/referential/currency", true).result; },
    $country() { return httpClient.get("/services/referential/country", true).result; },
    $state() { return httpClient.get("/services/referential/state", true).result; },
    $location() { return httpClient.get(config.subscription.ipapi.replaceAll('{0}', httpClient.get(config.subscription.ipify, true).result), true).result; },
    $language() { return httpClient.get("/services/referential/language", true).result; },
    $cartplatform() { return httpClient.get("/services/facility/storeplatform", true).result; },
    $fileToBlob(base64data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(base64data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++)
                byteNumbers[i] = slice.charCodeAt(i);

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    },
    $downloadFile(base64data, contentType, filename) {
        const blob = this.$fileToBlob(base64data, contentType);
        const blobUrl = URL.createObjectURL(blob);
        const fileLink = document.createElement('a');
        fileLink.href = blobUrl;
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);
        fileLink.click();
    },
    $downloadFileURL(url, contentType, filename) {
        var client = new XMLHttpRequest();
        client.open("GET", url, false);
        client.send(null);
        var responseData = client.responseText;
        let base64data = null;
        try {
            base64data = btoa(responseData);
        }
        catch {
            base64data = btoa(unescape(encodeURIComponent(responseData)));
        }
        const blob = this.$fileToBlob(base64data, contentType);
        const blobUrl = URL.createObjectURL(blob);
        const fileLink = document.createElement('a');
        fileLink.href = blobUrl;
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);
        fileLink.click();
    },
    $printers(data) {
        var printers = atob(data).split('|');
        var printerList = [];
        for (var i = 0; i < printers.length; i++)
            printerList.push({ id: i + 1, name: printers[i], text: printers[i] });
        return printerList;
    }
};
const constant = {
    location: {
        PH: {
            id: 176,
            country: "PH",
            country_name: "Philippines",
            country_code: "PH",
            country_code_iso3: "PHL",
            country_capital: "Manila",
            country_tld: ".ph",
            continent_code: "AS",
            in_eu: false,
            timezone: "Asia/Manila",
            utc_offset: "+0800",
            country_calling_code: "+63",
            currency: "PHP",
            currency_name: "Peso",
            languages: "tl,en-PH,fil,ceb,tgl,ilo,hil,war,pam,bik,bcl,pag,mrw,tsg,mdh,cbk,krj,sgd,msb,akl,ibg,yka,mta,abx",
            asn: "AS9299"
        },
        GB: {
            id: 238,
            country: "GB",
            country_name: "United Kingdom",
            country_code: "GB",
            country_code_iso3: "GBR",
            country_tld: ".uk",
            continent_code: "EU",
            in_eu: false,
            timezone: "Europe/London",
            utc_offset: "+0100",
            country_calling_code: "+44",
            currency: "GBP",
            currency_name: "Pound",
            languages: "en-GB,cy-GB,gd",
            asn: "AS786"
        }
    }
}
const tools = {
    $allEnums() {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.open("GET", config.endpoint.api + "/services/common/enums", false);
        xmlHttp.send(null);
        var source = JSON.parse(xmlHttp.responseText);
        source["displaysize"] = [
            { text: 'Tiny', code: 'TINY', id: 10 },
            { text: 'Small', code: 'SMALL', id: 12 },
            { text: 'Normal', code: 'NORMAL', id: 14 },
            { text: 'Large', code: 'LARGE', id: 16 },
            { text: 'Huge', code: 'HUGE', id: 18 },
        ]
        return source;
    },
    $currencies() {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.open("GET", config.endpoint.api + "/services/referential/currency", false);
        xmlHttp.send(null);
        var source = JSON.parse(xmlHttp.responseText);
        return source.result;
    },
    dynamicSort(property) {
        let sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return (a, b) => {
            let result = (a[property]?.toLowerCase() < b[property]?.toLowerCase()) ? -1 : (a[property]?.toLowerCase() > b[property]?.toLowerCase()) ? 1 : 0;
            return result * sortOrder;
        }
    },
    dynamicNumericSort(property) {
        let sortOrder = 1;
        return (a, b) => {
            let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
}
export default { config, tools, common, constant, httpClient };