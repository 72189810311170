<template>
    <pv-dialog :id="id" :header="header" :visible="showDialog" :modal="true" :closable="closable" :closeOnEscape="false" :draggable="false" style="overflow-y: hidden;">
        <div class="confirmation-content">
            <slot>
                Please be informed that this module is currently not available.
            </slot>
        </div>
        <template #footer>
            <slot name="footer">
                <pv-button v-if="showConfirm" :label="confirm" :icon="confirmIcon" @click="Confirm" class="p-button" :loading="confirmLoading" :disabled="confirmDisabled" autofocus />
                <pv-button :label="cancel" :icon="cancelIcon" @click="Cancel" class="p-button-outlined" :loading="confirmLoading" :disabled="cancelDisabled || confirmLoading" />
            </slot>
        </template>
    </pv-dialog>
</template>
<script>
    export default {
        name: 'DialogTemplate',
        emits: ['is-confirm', 'is-cancel'],
        props: {
            id: { type: String, default: 'dialog' },
            visible: { type: Boolean, default: false },
            header: { type: String },
            closable: { type: Boolean, default: false },
            showConfirm: { type: Boolean, default: true },
            confirm: { type: String, default: "Confirm" },
            cancel: { type: String, default: "Cancel" },
            confirmIcon: { type: String, default: 'pi pi-pw pi-check' },
            cancelIcon: { type: String, default: 'pi pi-pw pi-times' },
            confirmLoading: { type: Boolean, default: false },
            confirmDisabled: { type: Boolean, default: false },
            cancelDisabled: { type: Boolean, default: false }
        },
        data() {
            return {
                showDialog: false
            }
        },
        methods: {
            Confirm() {
                this.$emit("is-confirm");
            },
            Cancel() {
                this.showDialog = false;
                this.$emit("is-cancel");
            }
        },
        watch: {
            visible() {
                this.showDialog = this.visible;
            }
        }
    }
</script>
<style>
    .p-dialog .p-dialog-content {
        padding: 2rem 1.25rem !important;
        /* background-color: var(--surface-b) !important;*/
    }

    div.p-dialog-header, div.p-dialog-footer {
        background-color: var(--surface-a) !important;
        padding: 0.75rem 1rem !important;
    }

    div.p-dialog-header {
        border-bottom: 1px solid var(--surface-d) !important;
    }

    div.p-dialog-footer {
        border-top: 1px solid var(--surface-d) !important;
    }

    div.p-dialog-header > .p-dialog-title {
        font-size: 1rem !important;
    }
</style>