<template>
    <!-- https://cosmos.localhost.com:8080/transaction/consignment/create -->
    <!--<div style="position: fixed; bottom: 0; right: 1rem; z-index: 10000">
        <pv-speeddial :model="speeddial" :radius="120" direction="up" :transitionDelay="80" buttonClass="p-button" showIcon="pi pi-bars" hideIcon="pi pi-times" style="position: relative; bottom: 3rem; right: 1rem;" :tooltipOptions="{ position: 'left'}" />
    </div>-->
    <!--<pv-dock :model="dock" style="position: fixed; bottom: 0;">
        <template #item="{ item }">
            <a href="#" class="p-dock-action" v-tooltip.top="item.label" @click="onDockItemClick($event, item)">
                <img :alt="item.label" :src="item.icon" style="width: 100%; height: 100%;">
            </a>
        </template>
    </pv-dock>-->
    <cosmos-content title="Dashboard" :bg="false">
        <template #body>
            <div v-if="!globalAccess" class="p-grid">
                <div class="p-col-12 p-md-4">
                    <!--<button v-on:click="doPrintPDF">Print ZPL commands (Zebra printer required)...</button>&nbsp;-->
                    <cosmos-task :source="tasks"></cosmos-task>
                </div>
                <div class="p-col-12 p-md-8">
                    <div class="card" style="padding:0.75rem !important;">
                        <div>
                            <div class="p-d-flex p-grid p-col-12 p-jc-end" style="padding: 0 !important; margin: 0 !important; margin-bottom:0.50rem !important">
                                <div class="p-col-9"  style="padding: 0 !important; margin: 0 !important;"></div>
                                <div class="p-jc-end">
                                    <form-datetime :hideLabel="true" preLabel="End Date" id="scope" :required="true" :value="chartSource?.scope ?? new Date()" type="date" @is-sync="{this.chartSource.scope = ($event?.record?.data ?? new Date());}" label="End Date" :v$="v$"  style="padding: 0 !important; margin: 0 !important;"></form-datetime>
                                </div>
                            </div>
                            <div>
                                <pv-splitter style="width: calc(100%); height: calc(100%)" layout="vertical">
                                    <pv-split-panel>
                                        <pv-splitter style="width: calc(100%);">
                                            <pv-split-panel class="p-d-flex p-ai-center p-jc-center" style="width:calc(100%);">
                                                <div style="width: calc(100% - 1rem);">
                                                    <pv-chart type="bar" :data="chartSource?.daily" :options="stackedOptions_Daily" :height="320"  :width="auto"/>
                                                </div>
                                            </pv-split-panel>
                                            <pv-split-panel class="p-d-flex p-ai-center p-jc-center">
                                                <div style="width: calc(100% - 1rem);">
                                                    <pv-chart type="bar" :data="chartSource?.weekly" :options="stackedOptions_Weekly" :height="320" :width="auto" />
                                                </div>
                                            </pv-split-panel>
                                            <pv-split-panel class="p-d-flex p-ai-center p-jc-center">
                                                <div style="width: calc(100% - 1rem);">
                                                    <pv-chart type="bar" :data="chartSource?.monthly" :options="stackedOptions_Monthly" :height="320" :width="auto" />
                                                </div>
                                            </pv-split-panel>
                                        </pv-splitter>
                                    </pv-split-panel>
                                    <pv-split-panel>
                                        <pv-splitter>
                                            <pv-split-panel class="p-d-flex p-ai-center p-jc-center">
                                                <div style="width: calc(100% - 1rem); ">
                                                    <pv-chart type="bar" :data="chartSource?.customer" :options="chart5Options_Customer" :height="320" :width="auto" />
                                                </div>
                                            </pv-split-panel>
                                            <pv-split-panel class="p-d-flex p-ai-center p-jc-center">
                                                <div style="width: calc(100% - 1rem);">
                                                    <pv-chart type="doughnut" :data="chartSource?.provider" :options="chartOptions_CarrierDonut" :height="320" :width="auto" />
                                                </div>
                                            </pv-split-panel>
                                        </pv-splitter>
                                    </pv-split-panel>
                                </pv-splitter>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="p-grid">
                <div class="p-col-12 p-md-4">
                    <!--<button v-on:click="doPrintPDF">Print ZPL commands (Zebra printer required)...</button>&nbsp;-->
                    <cosmos-task :source="globalTasks"></cosmos-task>
                </div>
                <div class="p-col-12 p-md-8">
                    <div class="card">
                        <!--<form-lookup id="rei" :hideLabel="true" preLabel="Select Printer" sourceType="array" :source="printers" :value="selected_printerID" @is-sync="selected_printerID =  $event?.value ?? 1; selected_printer =$event.text" :sorted="false" :nullable="false"></form-lookup>
            <pv-button icon="fa fa-print" label="Print" @click="onPrint" />-->
                        <pv-splitter style="height: 50rem" layout="vertical">
                            <pv-split-panel>
                                <pv-splitter>
                                    <pv-split-panel class="p-d-flex p-ai-center p-jc-center">
                                        <pv-chart type="bar" :data="stackedData" :options="stackedOptions" :height="300" :width="370" />
                                    </pv-split-panel>
                                    <pv-split-panel class="p-d-flex p-ai-center p-jc-center">
                                        <pv-chart type="doughnut" :data="chartData" :options="lightOptions" :height="30" :width="275" />
                                    </pv-split-panel>
                                    <pv-split-panel class="p-d-flex p-ai-center p-jc-center">
                                        <pv-chart type="pie" :data="chartData" :options="lightOptions" :height="30" :width="275" />
                                    </pv-split-panel>
                                </pv-splitter>
                            </pv-split-panel>
                            <pv-split-panel>
                                <pv-splitter>
                                    <pv-split-panel class="p-d-flex p-ai-center p-jc-center" :size="40">
                                        <pv-chart type="line" :data="lineStylesData" :options="basicOptions" :height="320" :width="500" />
                                    </pv-split-panel>
                                    <pv-split-panel class="p-d-flex p-ai-center p-jc-center" :size="60">
                                        <pv-chart type="bar" :data="chart5Data" :options="chart5Options" :height="320" :width="700" />
                                    </pv-split-panel>
                                </pv-splitter>
                            </pv-split-panel>
                        </pv-splitter>
                    </div>
                </div>

            </div>
        </template>
    </cosmos-content>
</template>
<script>
    //import * as JSPM from 'jsprintmanager'
    import { mapGetters } from 'vuex';
    const _config = require('@/assets/lib/cosmos/_js/config.js');
    import SecureLS from "secure-ls";
    const ls = new SecureLS({ encryptionSecret: _config.secret })
    export default {
        name: 'Dashboard',
        emits: ['is-sync'],
        data() {
            return {
                //filename: "21121600000000001029.html",
                //filename: "18060400000000000947.epl",
                filename: "22112300000000006165.pdf",
                printers: [],
                selected_printerID: 1,
                selected_printer: "Brother DCP-T710W Printer",
                keys: null,
                model: {
                    input: {
                        floating: null,
                        fixed: null,
                        string: 'This is a plain string',
                        color: '#FF8999',
                        multiline: 'This is a multiline text.\r\nIt is really applicable to use textarea.\r\nPlease bare with us.',
                        password: 'sample'
                    },
                    numeric: {
                        decimal: 0.1258,
                        number: 123456,
                        currency: 12.25,
                        digit: 12,
                    },
                    boolean: {
                        switch: false,
                        toggle: true,
                        check: true,
                        select: true
                    },
                    lookup: {
                        number: 1,
                        array: [],
                        autocomplete: null,
                        flag: 164
                    },
                    number: null,
                    string: null,
                    password: null,
                    color: null,
                    textarea: null,
                    date: new Date()
                },
                session: {
                    tenantID: null,
                    environmentID: null,
                    warehouseID: null,
                    customerID: null
                },
                ds: [{ id: 1, text: "Hello" }, { id: 2, text: "Hi" }, { id: 3, text: "Thank You" }, { id: 4, text: "Welcome" }, { id: 5, text: "I am fine" }, { id: 6, text: "This is a plain string This is a plain string LOVE LOVE LOVE" }],
                config: {
                    toolbar: {
                        toolbarViewerLeft: { findbar: false },
                        toolbarViewerRight: { print: false, openFile: false, viewBookmark: false }
                    }
                },
                pdf: "https://example.com/sample.pdf",
                speeddial: [

                    //{
                    //    label: 'Update',
                    //    icon: 'pi pi-refresh',
                    //    command: () => {
                    //        this.$toast.add({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
                    //    }
                    //},
                    //{
                    //    label: 'Delete',
                    //    icon: 'pi pi-trash',
                    //    command: () => {
                    //        this.$toast.add({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
                    //    }
                    //},
                    {
                        label: 'Import File',
                        icon: 'pi pi-upload',
                        command: () => {
                            this.$router.push("/facility/import/requests");
                        }
                    },
                    {
                        label: 'Quote Consignment',
                        icon: 'pi pi-calculator',
                        command: () => {
                            this.$router.push("/facility/rates/quote");
                        }
                    },
                    {
                        label: 'New Consignment',
                        icon: 'pi pi-file',
                        command: () => {
                            this.$router.push("/transaction/consignment/create");
                        }
                    }
                ],
                dock: [
                    {
                        label: 'Ship',
                        icon: 'https://www.freeiconspng.com/uploads/truck-icon-30.png'
                    },
                    {
                        label: 'Messages',
                        icon: 'https://www.freeiconspng.com/uploads/message-icon-png-12.png',
                        //icon: () => <img alt="Photos" src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" style="width: 100%" />
                        //    command: () => {
                        //        this.$toast.add({ severity: 'info', summary: 'Empty Trash', life: 3000 });
                        //    }
                    },
                    {
                        label: 'Quote',
                        icon: 'https://www.freeiconspng.com/uploads/price-icon-30.png'
                    },
                    {
                        label: 'Settings',
                        icon: 'https://www.freeiconspng.com/uploads/gears-motion-motor-engine-3-clip-art-at-clkerm--vector-clip-art--15.png'
                    }
                ],
                chartData: {
                    labels: ['A', 'B'],
                    datasets: [
                        {
                            data: [300, 50],
                            backgroundColor: ["#42A5F5", "#66BB6A"],
                            hoverBackgroundColor: ["#64B5F6", "#81C784"]
                        }
                    ]
                },
                comboData: {
                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                    datasets: [{
                        type: 'line',
                        label: 'Dataset 1',
                        borderColor: '#42A5F5',
                        borderWidth: 2,
                        fill: false,
                        data: [
                            50,
                            25,
                            12,
                            48,
                            56,
                            76,
                            42
                        ]
                    }, {
                        type: 'bar',
                        label: 'Dataset 2',
                        backgroundColor: '#66BB6A',
                        data: [
                            21,
                            84,
                            24,
                            75,
                            37,
                            65,
                            34
                        ],
                        borderColor: 'white',
                        borderWidth: 2
                    }, {
                        type: 'bar',
                        label: 'Dataset 3',
                        backgroundColor: '#FFA726',
                        data: [
                            41,
                            52,
                            24,
                            74,
                            23,
                            21,
                            32
                        ]
                    }]
                }
                , comboData2: {
                    labels: ['January', 'February'],
                    datasets: [{
                        type: 'line',
                        label: 'Dataset 1',
                        borderColor: '#42A5F5',
                        borderWidth: 2,
                        fill: false,
                        data: [
                            1,
                            1

                        ]
                    }, {
                        type: 'bar',
                        label: 'Dataset 2',
                        backgroundColor: '#66BB6A',
                        data: [
                            1,
                            1

                        ],
                        borderColor: 'white',
                        borderWidth: 2
                    }, {
                        type: 'bar',
                        label: 'Dataset 3',
                        backgroundColor: '#FFA726',
                        data: [
                            1,
                            1

                        ]
                    }]
                }
                ,
                lightOptions: {
                    plugins: {
                        legend: {
                            labels: {
                                color: '#495057'
                            }
                        }
                    }
                },
                stackedData: {
                    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
                    datasets: [{
                        type: 'bar',
                        label: 'Dataset 1',
                        backgroundColor: '#42A5F5',
                        data: [50, 25, 12, 48, 90, 76]
                    }, {
                        type: 'bar',
                        label: 'Dataset 2',
                        backgroundColor: '#66BB6A',
                        data: [21, 84, 24, 75, 37, 65]
                    }, {
                        type: 'bar',
                        label: 'Dataset 3',
                        backgroundColor: '#FFA726',
                        data: [41, 52, 24, 74, 23, 21]
                    }]
                },
                comboOptions: {
                    plugins: {

                        legend: {
                            labels: {
                                color: '#495057'
                            }
                        }
                    },
                    scales: {
                        x: {
                            stacked: true,
                            ticks: {
                                color: '#495057'
                            },
                            grid: {
                                color: '#ebedef'
                            }
                        },
                        y: {
                            stacked: true,
                            ticks: {
                                color: '#495057'
                            },
                            grid: {
                                color: '#ebedef'
                            }
                        }
                    }
                },
                stackedOptions: {
                    title: {
                        display: true,
                        text: 'Daily Consignments',
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        tooltips: {
                            mode: 'index',
                            intersect: false
                        },
                        legend: {
                            labels: {
                                color: '#495057'
                            }
                        }
                    },
                    scales: {
                        x: {
                            stacked: true,
                            ticks: {
                                color: '#495057'
                            },
                            grid: {
                                color: '#ebedef'
                            }
                        },
                        y: {
                            stacked: true,
                            ticks: {
                                color: '#495057'
                            },
                            grid: {
                                color: '#ebedef'
                            }
                        }
                    }
                },
                chart5Data: {
                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                    datasets: [{
                        type: 'line',
                        label: 'Dataset 1',
                        borderColor: '#42A5F5',
                        borderWidth: 2,
                        fill: false,
                        data: [50, 25, 12, 48, 56, 76, 42]
                    }, {
                        type: 'bar',
                        label: 'Dataset 2',
                        backgroundColor: '#66BB6A',
                        data: [21, 84, 24, 75, 37, 65, 34],
                        borderColor: 'white',
                        borderWidth: 2
                    }, {
                        type: 'bar',
                        label: 'Dataset 3',
                        backgroundColor: '#FFA726',
                        data: [41, 52, 24, 74, 23, 21, 32]
                    }]
                },
                chart5Options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        legend: {
                            labels: {
                                color: '#495057'
                            }
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: '#495057'
                            },
                            grid: {
                                color: '#ebedef'
                            }
                        },
                        y: {
                            ticks: {
                                color: '#495057'
                            },
                            grid: {
                                color: '#ebedef'
                            }
                        }
                    }
                },
                lineStylesData: {
                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                    datasets: [
                        {
                            label: 'First Dataset',
                            data: [65, 59, 80, 81, 56, 55, 40],
                            fill: false,
                            tension: .4,
                            borderColor: '#42A5F5'
                        },
                        {
                            label: 'Second Dataset',
                            data: [28, 48, 40, 19, 86, 27, 90],
                            fill: false,
                            borderDash: [5, 5],
                            tension: .4,
                            borderColor: '#66BB6A'
                        },
                        {
                            label: 'Third Dataset',
                            data: [12, 51, 62, 33, 21, 62, 45],
                            fill: true,
                            borderColor: '#FFA726',
                            tension: .4,
                            backgroundColor: 'rgba(255,167,38,0.2)'
                        }
                    ]
                },
                basicOptions: {
                    maintainAspectRatio: false,
                    responsive: true,
                    stacked: true,
                    plugins: {
                        legend: {
                            labels: {
                                color: '#495057'
                            }
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: '#495057'
                            },
                            grid: {
                                color: '#ebedef'
                            }
                        },
                        y: {
                            ticks: {
                                color: '#495057'
                            },
                            grid: {
                                color: '#ebedef'
                            }
                        }
                    }
                },
                chartOptions: {
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        legend: {
                            labels: {
                                color: '#495057'
                            }
                        }
                    },
                    scales: {
                        r: {
                            grid: {
                                color: '#ebedef'
                            }
                        }
                    }
                },
                chartSource: {
                    scope: new Date(),
                    daily: null,
                    weekly: null,
                    monthly: null,
                    provider: null,
                    customer: null
                }
                ,stackedOptions_Daily: {
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Daily Consignments',
                        },
                        tooltips: {
                            mode: 'index',
                            intersect: false
                        },
                        legend: {
                            labels: {
                                color: '#495057'
                            }
                        }
                    },
                    scales: {
                        x: {
                            stacked: true,
                            ticks: {
                                color: '#495057'
                            },
                            grid: {
                                color: '#ebedef'
                            }
                        },
                        y: {
                            stacked: true,
                            ticks: {
                                color: '#495057'
                            },
                            grid: {
                                color: '#ebedef'
                            }
                        }
                    }
                }
                ,stackedOptions_Weekly: {
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Weekly Consignments',
                        },
                        tooltips: {
                            mode: 'index',
                            intersect: false
                        },
                        legend: {
                            labels: {
                                color: '#495057'
                            }
                        }
                    },
                    scales: {
                        x: {
                            stacked: true,
                            ticks: {
                                color: '#495057'
                            },
                            grid: {
                                color: '#ebedef'
                            }
                        },
                        y: {
                            stacked: true,
                            ticks: {
                                color: '#495057'
                            },
                            grid: {
                                color: '#ebedef'
                            }
                        }
                    }
                }
                ,stackedOptions_Monthly: {
                    maintainAspectRatio: false,
                    responsive: true,
                    //indexAxis: 'y',
                    plugins: {
                        title: {
                            display: true,
                            text: 'Monthly Consignments',
                        },
                        tooltips: {
                            mode: 'index',
                            intersect: false
                        },
                        legend: {
                            labels: {
                                color: '#495057'
                            }
                        }
                    },
                    scales: {
                        x: {
                            stacked: true,
                            ticks: {
                                color: '#495057'
                            },
                            grid: {
                                color: '#ebedef'
                            }
                        },
                        y: {
                            stacked: true,
                            ticks: {
                                color: '#495057'
                            },
                            grid: {
                                color: '#ebedef'
                            }
                        }
                    }
                }
                ,chart5Options_Customer: {
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Customer Distribution',
                        },
                        legend: {
                            labels: {
                                color: '#495057'
                            }
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: '#495057'
                            },
                            grid: {
                                color: '#ebedef'
                            }
                        },
                        y: {
                            ticks: {
                                color: '#495057'
                            },
                            grid: {
                                color: '#ebedef'
                            }
                        }
                    }
                }
                , chartOptions_Carrier: {
                    indexAxis: 'y',
                    cutout: '60%',
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Carrier Distribution',
                        },
                        legend: {
                            position: 'left',
                            labels: {
                                color: '#495057'
                            }
                        }
                    },
                    scales: {
                        r: {
                            grid: {
                                color: '#ebedef'
                            }
                        }
                    }
                }
                , chartOptions_CarrierDonut: {
                    cutout: '40%',
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Carrier Distribution',
                        },
                        legend: {
                            position: 'left',
                            labels: {
                                color: '#495057'
                            }
                        }
                    }
                   
                },
                activity: {
                    binding: false
                }

            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser",
            }),
            globalAccess() {
                return ((this.user?.userType ?? 0) === this.userTypes.GLOBAL_ADMINISTRATOR);
            },
            limitedAccess() {
                return ((this.user?.userType ?? 0) === this.userTypes.ENVIRONMENT_USER) || ((this.user?.userType ?? 0) === this.userTypes.CUSTOMER_USER) || ((this.user?.userType ?? 0) === this.userTypes.RETAILER_USER);
            },
            userTypes() {
                return {
                    GLOBAL_ADMINISTRATOR: 0,
                    SYSTEM_ADMINISTRATOR: 2,
                    ENVIRONMENT_ADMINISTRATOR: 3,
                    ENVIRONMENT_USER: 4,
                    CUSTOMER_ADMINISTRATOR: 5,
                    CUSTOMER_USER: 6,
                    RETAILER_USER: 7
                }
            },
            consignmentDS() { return this.$store.getters["consignment/ds"]; },
            collectionDS() { return this.$store.getters["collection/ds"]; },
            tasks() { return this.generateTask(); },
            globalTasks() {
                return [{
                    module: "Tasks",
                    options: []
                }
                ];
            },
            sessionID() { return sessionStorage.getItem("session-id"); },
            chartAPIRequest() {
                return {
                    ServiceHeader: {
                        Username: "TEMP",
                        Password: "TESTESTEDET",
                        ClientID: "TESTTESTTESTTESTTEST",
                        ClientSecret: "TESTEDREDREDTETEDEDETEREDEDEREDE"
                    },
                    Request: {
                        Date: this.$filters.formatDateWithFormat(this.chartSource.scope ?? new Date(), "YYYY-MM-DD"),
                    }
                };
            }
        },
        watch: {
            keys() { this.$nextTick(() => { this.onRefreshKeys(); }); },
            "chartSource.scope": {
                handler() {
                    this.$nextTick(() => {                        
                            this.generateChart();
                    });
                }, deep: true
            }
        },
        methods: {
            generateChart() {
                this.onRetrieveChartData();
            },
            generateTask() {
                var consignments = this.consignmentDS.data.map(m => ({ status: m.status, warehouseID: m.warehouseID }));
                var collectionDS = this.collectionDS.data.map(m => ({ status: m.status, warehouseID: m.warehouseID }));
                var tasks = [
                    {
                        module: "Shipping",
                        options: [{
                            label: this.$filters.quantifiedDisplay("Consignment", consignments.filter((x) => x.status === 100 && x.warehouseID === this.session.warehouseID).length ?? 0) + " awaiting instruction",
                            severity: "primary",
                            count: consignments.filter((x) => x.status === 100 && x.warehouseID === this.session.warehouseID)?.length ?? 0,
                            route: { name: 'consignment-index', params: { status: 100 } },
                        },
                        {
                            label: this.$filters.quantifiedDisplay("Consignment", consignments.filter((x) => x.status === 200 && x.warehouseID === this.session.warehouseID).length ?? 0) + " awaiting labels to be printed",
                            severity: "info",
                            count: consignments.filter((x) => x.status === 200 && x.warehouseID === this.session.warehouseID)?.length ?? 0,
                            route: { name: 'consignment-index', params: { status: 200 } },
                        },
                        {
                            label: this.$filters.quantifiedDisplay("Consignment", consignments.filter((x) => x.status === 300 && x.warehouseID === this.session.warehouseID).length ?? 0) + " awaiting collection instruction",
                            severity: "warning",
                            count: consignments.filter((x) => x.status === 300 && x.warehouseID === this.session.warehouseID).length ?? 0,
                            route: { name: 'consignment-index', params: { status: 300 } },
                        },
                        {
                            label: this.$filters.quantifiedDisplay("Collection", collectionDS.filter((x) => x.status === 400 && x.warehouseID === this.session.warehouseID).length ?? 0) + " awaiting dispatch",
                            severity: "danger",
                            count: collectionDS.filter((x) => x.status === 400 && x.warehouseID === this.session.warehouseID).length ?? 0,
                            route: { name: 'collection-index', params: { status: 400 } },
                        },
                        {
                            label: this.$filters.quantifiedDisplay("Consignment", consignments.filter((x) => x.status === 500 && x.warehouseID === this.session.warehouseID).length ?? 0) + " in transit",
                            severity: "success",
                            count: consignments.filter((x) => x.status === 500 && x.warehouseID === this.session.warehouseID).length ?? 0,
                            route: { name: 'consignment-index', params: { status: 500 } },
                        },
                        {
                            label: this.$filters.quantifiedDisplay("Consignment", consignments.filter((x) => x.status === 600 && x.warehouseID === this.session.warehouseID).length ?? 0) + " out for delivery",
                            severity: "info",
                            count: consignments.filter((x) => x.status === 600 && x.warehouseID === this.session.warehouseID).length ?? 0,
                            route: { name: 'consignment-index', params: { status: 600 } },
                        }

                        ]
                    }
                ];
                if (this.user && this.user.userType === 0)
                    tasks.push({
                        module: "Special Task",
                        options: [{
                            label: "API access awaiting approval",
                            severity: "info",
                            count: "0",
                            route: { name: 'dashboard-index' },
                        },
                        {
                            label: "Rate upload awaiting approval",
                            severity: "danger",
                            count: "0",
                            route: { name: 'dashboard-index' },
                        }]
                    });

                return tasks;
            },
            async getConsignments() {
                await this.$store.dispatch("consignment/getAll", { sort: null, where: null });

            },
            async getCollections() { await this.$store.dispatch("collection/getAll", { sort: null, where: null }); },
            onRefreshKeys() {
                let activeKeys = atob(this.keys).split('|');
                this.session = {
                    tenantID: Number(activeKeys[0]),
                    environmentID: Number(activeKeys[1]),
                    warehouseID: Number(activeKeys[2]),
                    customerID: Number(activeKeys[3])
                };
                this.getConsignments();
                this.getCollections();
                this.generateChart();
            },
            onPrint() {
                this.$print(this.sessionID, this.selected_printer, this.filename);
            }
            , async onRetrieveChartData() {
                if (!this.activity.binding) {
                    this.activity.binding = true;
                    const urlsToFetch = [
                        this.$config.config.endpoint.api + `/services/chart/dashboard/daily`.replaceAll("//", "/"),
                        this.$config.config.endpoint.api + `/services/chart/dashboard/weekly`.replaceAll("//", "/"),
                        this.$config.config.endpoint.api + `/services/chart/dashboard/monthly`.replaceAll("//", "/"),
                        this.$config.config.endpoint.api + `/services/chart/dashboard/carrierdistribution`.replaceAll("//", "/"),
                        this.$config.config.endpoint.api + `/services/chart/dashboard/customerdistribution`.replaceAll("//", "/"),
                    ];
                    const fetchPromises = urlsToFetch.map(url =>
                        fetch(url, {
                            method: 'POST',
                            headers: {
                                'Authorization': 'Bearer ' + ls.get("x-cosmos-token").token,
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'X-Cosmos-Activekeys': sessionStorage.getItem("activekeys"),
                                'x-cosmos-appid': 'cosmos',
                                'Access-Control-Allow-Origin': "*",
                                'x-session-id': sessionStorage.getItem("session-id")

                            },
                            body: JSON.stringify(this.chartAPIRequest)
                        })
                            .then(response => response.json())
                    );
                    Promise.all(fetchPromises)
                        .then(responses => {
                            const responseData = responses.map(response => response);
                            for (var i = 0; i < (responseData?.length ?? 0); i++) {
                                const result = responseData[i];
                                switch (i) {
                                    case 0:
                                        this.chartSource.daily = result?.result ?? null;
                                        break;
                                    case 1:
                                        this.chartSource.weekly = result?.result ?? null;
                                        break;
                                    case 2:
                                        this.chartSource.monthly = result?.result ?? null;
                                        break;
                                    case 3:
                                        this.chartSource.provider = result?.result ?? null;
                                        break;
                                    case 4:
                                        this.chartSource.customer = result?.result ?? null;
                                        break;


                                }
                            }

                        })
                        .catch(error => console.error('Error fetching data:', error))
                        .finally(() => this.activity.binding = false);
                        
                }
            }
            , async onGetDailyChart() {
                this.$axios.post(this.$config.config.endpoint.api + `/services/chart/dashboard/daily`.replaceAll("//", "/"), this.chartAPIRequest)
                    .then((result) => {
                        console.log('daily');
                        console.log(result?.data.result);
                        this.chartSource.daily = result?.data.result ?? null;
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed Chart Daily Retrieval', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            }
            , async  onGetWeeklyChart() {
                this.$axios.post(this.$config.config.endpoint.api + `/services/chart/dashboard/weekly`.replaceAll("//", "/"), this.chartAPIRequest)
                    .then((result) => {
                        this.chartSource.weekly = result?.data.result ?? null;
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed Chart Weekly Retrieval', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            }
            , async onGetMonthlyChart() {
                this.$axios.post(this.$config.config.endpoint.api + `/services/chart/dashboard/monthly`.replaceAll("//", "/"), this.chartAPIRequest)
                    .then((result) => {
                        this.chartSource.monthly = result?.data.result ?? null;
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed Chart Monthly Retrieval', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            }
            , async onGetProviderChart() {
                this.$axios.post(this.$config.config.endpoint.api + `/services/chart/dashboard/carrierdistribution`.replaceAll("//", "/"), this.chartAPIRequest)
                    .then((result) => {
                        this.chartSource.provider = result?.data.result ?? null;
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed Chart Carrier Retrieval', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            }
            , async onGetCustomerChart() {
                this.$axios.post(this.$config.config.endpoint.api + `/services/chart/dashboard/customerdistribution`.replaceAll("//", "/"), this.chartAPIRequest)
                    .then((result) => {
                        this.chartSource.customer = result?.data.result ?? null;
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed Chart Customer Retrieval', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            }
        },
        created() {
            if (this.printers.length <= 2)
            {
                try {
                    this.printers = this.$config.common.$printers(sessionStorage.getItem("wcpp_printers") ?? btoa("Use Default Printers|Use Printer�Dialog"));
                } catch { /* NO CATCH */ }
            }
            
        },
        mounted() {
            if (!this.user) {
                this.$emit('is-sync', false);
                this.$router.push({ path: '/account/login' });
            }
            else {
                this.$vm.$authenticated = true;
                this.$emit('is-sync', true);
            }
            this.getConsignments();
            this.getCollections();
            this.keys = sessionStorage.getItem('activekeys');
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
        },
        unmounted() {
            try {
                var session = JSON.parse(sessionStorage.getItem(`dt-state-${this.id}`));
                session.first = 0;
                sessionStorage.setItem(`dt-state-${this.id}`, JSON.stringify(session));
                window.removeEventListener('setItem');
            } catch { /* This is a test */ }
        }
    }



</script>
<style>
    .c2a_footer { display: none; }
    ul.c2a_results li > * { font-family: Roboto, sans-serif !important; font-size: 0.9rem; }

</style>