const config = require('./config.js').default.config;
import vm from '@/assets/_js/cosmos.js';
vm.init(vm);
const store = (namespace, area, controller, model, schema) => {
    return {
        namespaced: true,
        state: {
            schema: schema ? schema : vm.$schema[namespace],
            model: model ? model : vm.$model[namespace],
            filter: null,
            columns: null,
            errors: null,
            data: null,
            source: { total: 0, data: [] },
            // This states will hold data for custom requests
            recordData: null,
            sourceData: { total: 0, data: [] }
        },
        getters: {
            schema: state => state.schema,
            init: state => state.model,
            ds: state => state.source,
            filter: state => state.filter,
            columns: state => state.columns,
            record: state => state.data,
            errors: state => state.errors,
        },
        mutations: {
            INSERT_RECORD_DATA: (state, record) => {
                state.errors = null;
                state.data = record.result;
                state.source.data = Array.isArray(record.result) ? record.result : [record.result];
                state.source.data.unshift(record.result);
                state.source.total = state.source.data.length;
            },
            UPDATE_RECORD_DATA: (state, record) => {
                state.errors = null;
                state.record = record.result;
                const index = (state?.source?.data ?? []).findIndex(data => data.id === record.result.id);
                if (index !== -1)
                    state.source.data.splice(index, 1, record.result);
            },
            DELETE_RECORD_BY_MASK: (state, mask) => {
                state.errors = null;
                state.record = null;
                state.source.data = state.source.data.filter(data => data.mask !== mask);
                state.source.total = state.source.data.length;
            },
            DELETE_RECORD_BY_ID: (state, id) => {
                state.errors = null;
                state.record = null;
                state.source.data = state.source.data.filter(data => data.id !== id);
                state.source.total = state.source.data.length;
            },
            SET_DATASOURCE: (state, records) => {
                state.errors = null;
                state.source.data = records.result?.data ?? records.result ?? [];
                state.source.total = records.result?.total ?? (records.result ?? []).length;
            },
            SET_DATASOURCE_DYNAMIC: (state, records) => {
                state.errors = null;
                state.sourceData.data = records.result.data;
                state.sourceData.total = records.result.total;
            },
            SET_RECORD_DATA: (state, record) => {
                state.errors = null;
                state.data = record?.result ? record?.result : record;
            },
            SET_RECORD_DATA_DYNAMIC: (state, record) => {
                state.errors = null;
                state.data = record.result ? record.result : record;
            },
            SET_ERROR_DATA: (state, errors) => {
                if (errors !== undefined)
                    state.errors = errors.errors ? errors.errors : errors;
            }
        },
        actions: {
            // Dynamic URL Methods
            async postURL({ commit }, { url, payload }) {
                var data = null;
                await this.$axios
                    .post(config.endpoint.api + `/${url}`.replaceAll("//", "/"), payload)
                    .then((response) => {
                        data = response?.data;
                        if (data && data.success)
                            commit("INSERT_RECORD_DATA", data);
                        if (!data?.success)
                            commit("SET_ERROR_DATA", data);
                        try {
                            data.result = (data?.result ?? []).filter(x => !Array.isArray(x)) ?? [];
                        }
                        catch {/**/}
                        
                    })
                    .catch((ex) => {
                        data = "EXCEPTION:" + ex?.data ?? ex;
                        if (data)
                            commit("SET_ERROR_DATA", ex.data);
                    });
                return data;
            },
            async putURL({ commit }, { url, payload }) {
                var data = null;
                await this.$axios
                    .put(config.endpoint.api + `/${url}`.replaceAll("//", "/"), payload)
                    .then((response) => {
                        data = response.data;
                        if (data?.success)
                            commit("UPDATE_RECORD_DATA", data);
                        else
                            commit("SET_ERROR_DATA", data);
                    })
                    .catch((ex) => {
                        data = ex?.data;
                        if (data)
                            commit("SET_ERROR_DATA", data);
                    });

                return data;

            },
            async patchURL({ commit }, { url, payload }) {
                var data = null;
                await this.$axios
                    .patch(config.endpoint.api + `/${url}`.replaceAll("//", "/"), payload)
                    .then((response) => {
                        data = response.data;
                        if (data?.success)
                            commit("UPDATE_RECORD_DATA", data);
                        else
                            commit("SET_ERROR_DATA", data);
                    })
                    .catch((ex) => {
                        data = ex?.data;
                        if (data)
                            commit("SET_ERROR_DATA", data);
                    });
                return data;

            },
            async deleteURL({ commit }, { url, payload }) {
                var data = null;
                await this.$axios
                    .delete(config.endpoint.api + `/${url}`.replaceAll("//", "/"), { data: payload })
                    .then((response) => {
                        data = response.data;
                    })
                    .catch((ex) => {
                        data = ex.data;
                        commit("SET_ERROR_DATA", ex.data)
                    });
                return data;
            },
            async getURL({ commit }, { url }) {
                var data = null;
                await this.$axios
                    .get(config.endpoint.api + `/${url}`.replaceAll("//", "/"))
                    .then((response) => {
                        data = response.data;
                        commit("SET_RECORD_DATA_DYNAMIC", data);
                    })
                    .catch((ex) => {
                        data = ex.data;
                        commit("SET_ERROR_DATA", data)
                    });
                return data;
            },
            async getAllURL({ commit }, { url }) {
                var data = null;
                await this.$axios
                    .get(config.endpoint.api + `/${url}`.replaceAll("//", "/"))
                    .then((response) => {
                        data = response.data;
                        commit("SET_DATASOURCE_DYNAMIC", response.data);
                    })
                    .catch((ex) => {
                        data = ex.data;
                        commit("SET_ERROR_DATA", data)
                    });
                return data;
            },
            async getPagedURL({ commit }, { url, page, pageSize, sort, where, fields }) {
                var data = null;
                await this.$axios
                    .get(config.endpoint.api + `/${url}?page=${page}&pageSize=${pageSize}`.replaceAll("//", "/"), { headers: { sort: JSON.stringify(sort ?? ['id']), filter: where?.global?.value ?? "", fields: JSON.stringify(fields ?? []) } })
                    .then((response) => {
                        data = response.data;
                        commit("SET_DATASOURCE_DYNAMIC", response.data);
                    })
                    .catch((ex) => {
                        data = ex.data;
                        commit("SET_ERROR_DATA", data)
                    });
                return data;
            },
            // Standard CRUD Methods
            async addRecord({ commit }, record) {
                var data = null;
                await this.$axios
                    .post(config.endpoint.api + `/services/${area}/${controller}`.replaceAll("//", "/"), record)
                    .then((response) => {
                        data = response.data;
                        if (data?.success)
                            commit("INSERT_RECORD_DATA", data);
                        else
                            commit("SET_ERROR_DATA", data);
                    })
                    .catch((ex) => {
                        data = ex?.data;
                        if (data)
                            commit("SET_ERROR_DATA", data);
                    });
                return data;
            },
            async modifyRecord({ commit }, record) {
                var data = null;
                await this.$axios
                    .put(config.endpoint.api + `/services/${area}/${controller}/${record.mask}`.replaceAll("//", "/"), record)
                    .then((response) => {
                        data = response.data;
                        if (data?.success)
                            commit("UPDATE_RECORD_DATA", data);
                        else
                            commit("SET_ERROR_DATA", data);
                    })
                    .catch((ex) => {
                        data = ex?.data;
                        if (data)
                            commit("SET_ERROR_DATA", data);
                    });
                return data;
            },
            async updateRecord({ commit }, record) {
                var data = null;
                await this.$axios
                    .put(config.endpoint.api + `/services/${area}/${controller}/${record.mask}`.replaceAll("//", "/"), record)
                    .then((response) => {
                        data = response.data;
                        if (data?.success)
                            commit("UPDATE_RECORD_DATA", data);
                        else
                            commit("SET_ERROR_DATA", data);
                    })
                    .catch((ex) => {

                        data = ex?.data;
                        if (data)
                            commit("SET_ERROR_DATA", data);
                    });
                return data;
            },
            async cloneRecord({ commit }, { mask, record}) {
                var data = null;
                await this.$axios
                    .post(config.endpoint.api + `/services/${area}/${controller}/${mask}/clone`.replaceAll("//", "/"), record)
                    .then((response) => {
                        data = response.data;
                        if (data?.success)
                            commit("INSERT_RECORD_DATA", data);
                        else
                            commit("SET_ERROR_DATA", data);
                    })
                    .catch((ex) => {
                        data = ex?.data;
                        if (data)
                            commit("SET_ERROR_DATA", data);
                    });
                return data;
            },
            async sendRecord({ commit },mask) {
                var data = null;
                await this.$axios
                    .get(config.endpoint.api + `/services/${area}/${controller}/send/${mask}/`.replaceAll("//", "/"))
                    .then((response) => {
                        data = response.data;
                        if (!data?.success)
                            commit("INSERT_RECORD_DATA", data);
                        else
                            commit("SET_ERROR_DATA", data);
                    })
                    .catch((ex) => {
                        data = ex?.data;
                        if (data)
                            commit("SET_ERROR_DATA", data);
                    });
                return data;
            },
            async removeRecord({ commit }, mask) {
                var data = null;
                await this.$axios
                    .delete(config.endpoint.api + `/services/${area}/${controller}/${mask}`.replaceAll("//", "/"))
                    .then((response) => {
                        data = response.data;
                        commit("DELETE_RECORD_BY_MASK", mask);
                    })
                    .catch((ex) => {
                        data = ex.data;
                        commit("SET_ERROR_DATA", ex.data)
                    });
                return data;
            },
            async deleteRecordByMask({ commit }, mask) {
                var data = null;
                await this.$axios
                    .delete(config.endpoint.api + `/services/${area}/${controller}/${mask}`.replaceAll("//", "/"))
                    .then((response) => {
                        data = response.data;
                        commit("DELETE_RECORD_BY_MASK", mask);
                    })
                    .catch((ex) => {
                        data = ex.data;
                        commit("SET_ERROR_DATA", ex.data)
                    });
                return data;
            },
            async deleteRecordByID({ commit }, id) {
                await this.$axios
                    .delete(config.endpoint.api + `/services/${area}/${controller}/id/${id}`.replaceAll("//", "/"))
                    .then(() => commit("DELETE_RECORD_BY_ID", id))
                    .catch((ex) => commit("SET_ERROR_DATA", ex.data));
            },
            async getAll({ commit }, { sort, where, fields }) {
                var data = null;
                await this.$axios
                    .get(config.endpoint.api + `/services/${area}/${controller}`.replaceAll("//", "/"), { headers: { sort: JSON.stringify(sort ?? ['id']), filter: where?.global?.value ?? "", fields: JSON.stringify(fields ?? []) } })
                    .then((response) => {
                        data = response.data;
                        commit("SET_DATASOURCE", data);
                    })
                    .catch((ex) => {
                        data = ex.data;
                        commit("SET_ERROR_DATA", data)
                    });
                return data;
            },
            async getRecord({ commit }, mask) {
                var data = null;
                await this.$axios
                    .get(config.endpoint.api + `/services/${area}/${controller}/${mask}`.replaceAll("//", "/"))
                    .then((response) => {
                        data = response.data;
                        commit("SET_RECORD_DATA", data);
                    })
                    .catch((ex) => {
                        data = ex.data;
                        commit("SET_ERROR_DATA", data)
                    });
                return data;
            },
            async getPaged({ commit }, { page, pageSize, sort, where, fields }) {
                var data = null;
                await this.$axios
                    .get(config.endpoint.api + `/services/${area}/${controller}/pagedList?page=${page}&pageSize=${pageSize}`.replaceAll("//", "/"), { headers: { sort: JSON.stringify(sort ?? ['id']), filter: where?.global?.value ?? "", fields: JSON.stringify(fields ?? []) } })
                    .then((response) => {
                        data = response.data;
                        commit("SET_DATASOURCE", data);
                    })
                    .catch((ex) => {
                        data = ex.data;
                        commit("SET_ERROR_DATA", data)
                    });
                return data;
            },
        }
    }
}
export default store;