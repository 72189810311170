<template>
    <div style="margin: -0.5rem -1.5rem -2.5rem -1.5rem;">
        <div style="min-height: 10rem; padding: 2rem 1rem 1rem 1rem;">
            <div class="p-grid">
                <div class="p-col-12 p-md-6">
                    <h3 style="margin-bottom: 0.25rem;">STEP 3A</h3>
                    <p style="margin-bottom:1rem;">On this step, you may customize the column name that will appear on your report.</p>
                    <form-input id="name" v-model="model.configuration.basicInfo.name" label="Report Name" :required="true" />
                    <form-input id="description" type="textarea" v-model="model.configuration.basicInfo.description" label="Report Description" />
                    <form-lookup id="privacy" v-model="model.configuration.basicInfo.privacy" type="enum" source="dynamicreportprivacy" :nullable="false" />
                    <form-lookup id="status" v-model="model.configuration.basicInfo.status" type="enum" source="dynamicreportstatus" :disabled="parentID === -1000" :nullable="false" disabledType="object" />
                </div>
                <div class="p-col-12 p-md-6">
                    <h3 style="margin-bottom: 0.25rem;">STEP 3B</h3>
                    <p style="margin-bottom:1rem;">On this step, you may customize your dynamic report viewer filters.</p>
                    <viewer-form v-model="model.configuration.viewer" :parentID="model.id" />
                </div>
            </div>
        </div>
        <div class="p-grid p-nogutter p-justify-between" style="border-top: 2px solid var(--surface-d);padding: 0.5rem 1rem; background: var(--surface-c)">
            <pv-button @click="onPrevPage()" :loading="activity.loading" :iconPos="'right'"><i class="fa fa-chevron-left fa-fw" style="margin-right: 0.5rem"></i> <i :class="`${(activity.loading ? 'pi pi-spinner pi-spin' : 'fa fa-pen-field fa-fw')}`" style="margin-right: 0.5rem"></i> Customization</pv-button>
            <pv-button @click="onNextPage()" :loading="activity.loading" :disabled="!model.configuration.basicInfo.name" :iconPos="'right'"><i :class="`${(activity.loading ? 'pi pi-spinner pi-spin' : 'fa fa-file-text fa-fw')}`" style="margin-right: 0.5rem"></i> Summary <i class="fa fa-chevron-right fa-fw" style="margin-left: 0.5rem"></i></pv-button>
        </div>
    </div>
</template>
<script>
    import viewerForm from '@/components/facility/report/viewer-form.vue';
    export default {
        name: "FieldSelectionStep",
        emits: ["update", "update:modelValue", "isSync"],
        components: {
            'viewer-form': viewerForm
        },
        props: {
            parentID: { type: Number, default: -1000 },
            modelValue: { type: Object, default: null }
        },
        data() {
            return {
                model: {
                    basicInfo: {
                        code: null,
                        name: null,
                        description: null,
                        privacy: 100,
                        status: 100,
                    },
                    viewer: {
                        useCreateDateFilter: false,
                        useCollectionDateFilter: false,
                        useWarehouseFilter: false,
                        useCustomerFilter: false,
                        useCarrierFilter: false,
                        useAccountFilter: false
                    }
                },
                activity: {
                    loading: false
                }
            }
        },
        watch: {

        },
        methods: {
            onPrevPage() {
                this.$emit('prev-page', { formData: this.model, pageIndex: 2 });
            },
            onNextPage() {
                this.$emit('next-page', { formData: this.model, pageIndex: 2 });
            }
        },
        created() {
            if (this.modelValue)
                this.model = this.modelValue;
        }

    }
</script>
<style>
    .is-customized .p-selectbutton.p-buttonset > div {
        padding: 0 1rem !important;
    }
    .is-customized-boolean.p-field {
        margin-bottom: -0.5rem !important;
        margin-top: -0.5rem !important;
    }

    .p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled.p-disabled ~ label.p-label-text {
        padding-left: 0 !important;
    }
</style>