<template>
    <form-wrapper :title="router.title" :sub-title="router.subtitle">
        <cosmos-grid id="AddressBookList" sourceType="storename" source="addressbook" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields"  :showExport="false" :baseRecordID="0">
            <pv-grid-column field="licenseKey" header="Barcode" headerStyle="min-width: 7rem; max-width: 3rem;" style="min-width: 7rem;text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; ">
                <template #body="slotProps">
                    <div class="p-d-flex" style="width: 70px; ">
                        <div style="width: 6rem;">
                            <div class="p-d-flex p-jc-between" style="width: 70px;">
                                <qr-code :value="slotProps.data.code" :size="70" level="H" :background="'black'" foreground="#FFFFFF" style="border: 1px solid gray;" />
                            </div>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="code" header="Details" style="min-width: 100%; max-width: 100%; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
                <template #body="slotProps">
                    <div class="p-d-flex">
                        <div style="width:100%">
                            <div class="p-d-flex p-jc-between" style="min-width: 35rem; margin-bottom: 5px;">
                                <div><h5 style="margin-bottom: 5px; margin-top: 0;"><span>{{ slotProps.data.firstName}} {{ slotProps.data.lastName}}</span></h5></div>
                                <div class="p-as-end"><h5 class="p-code-text" style="margin-bottom:0">[{{ slotProps.data.code}}]</h5></div>
                            </div>
                            <div class="p-grid p-d-flex">
                                <div style="margin-right: 5px;"><i class="pi pi-map-marker pi-fw" style="font-size: 0.8rem" /></div>
                                <div><p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.8rem">{{ slotProps.data.firstName}} {{ slotProps.data.lastName}}, {{ slotProps.data.address1 ? slotProps.data.address1 + ',' : null }} {{ slotProps.data.address2 ? slotProps.data.address2 + ',' : null }} {{ slotProps.data.address3 ? slotProps.data.address3 + ',' : null}} {{ slotProps.data.city ? slotProps.data.city + ',' : null }} {{ slotProps.data.state ? slotProps.data.state + ',' : null }} {{ slotProps.data.postalCode ? slotProps.data.postalCode + ',' : null }} {{ slotProps.data.countryName ?? 'United Kingdom'}}</p></div>
                            </div>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
        </cosmos-grid>
    </form-wrapper>
</template>
<script>
        import mixins from '@/assets/lib/cosmos/_js/mixin.js';
        export default {
        name: 'AddressBookListTemplate',
        mixins: [mixins.GRID],
        data() {
            return {
            type: -1,
            filter: { },
            columns: [],
                deleteDisplay: ["code", "name"],
                searchFields: ["code", "lastName", "firstName","city", "postalCode", "state"],

            }
        },
        computed: {
            source() {
                return this.$vm.$enums["module"];
            }
        },
        methods: {
            modules(slotProps) {
                return this.source.filter(x => this.$filters.getFlags(slotProps.data.modules).indexOf(x.id) !== -1).map(x => '<div class="p-chip" style="padding: 2px 10px; border-radius: 4px;">' + x.text + '</div>').join(', ');
            }
        }
    }
</script>