<template>
    <div>
        <div class="p-d-flex p-jc-start p-nogutter">
            <div class="p-col p-col-fixed p-nogutter">
                <div class="p-d-flex p-jc-start p-nogutter" style="display: inline-flex; margin: 0; padding:0">
                    <div class="p-col p-col-fixed p-nogutter p-mr-sm-1"  style="width: 3rem">
                        <form-numeric id="volumetricWeight"
                                      :value="item.parcelID"
                                      :disabled="true"
                                      :showButton="false"
                                      label="#"
                                      :hideLabel="index > 1" />
                    </div>
                    <div class="p-col p-col-fixed  p-nogutter"  style="width: 12rem">
                        <form-numeric id="declaredWeight"
                                      type="decimal"
                                      :decimalDigit="header.weightUnit === 200 ? 0 : 3"
                                      :max="99995.000 * (header.weightUnit === 200 ? 100 : 1)"
                                      :suffix="$filters.quantifiedDisplay(header?.weightUnitText ?? 'kg', header.declaredWeight)"
                                      :value="item.declaredWeight"
                                      @is-sync="model.declaredWeight = $event.value"
                                      :hideLabel="index > 1" />
                    </div>
                </div>
            </div>
            <div class="p-col p-col-fixed p-nogutter" style="width: 31rem">
                <div class="p-d-flex p-jc-start p-nogutter">
                    <div class="p-col p-col-fixed p-nogutter p-mx-sm-1"  style="width: 10rem">
                        <form-numeric id="length"
                                      type="decimal"
                                      :decimalDigit="header.dimensionUnit === 200 ? 0 : 2"
                                      :max="9999.000"
                                      :suffix="header.dimensionUnitText ?? 'cm'"
                                      :hideLabel="index > 1"
                                      :value="item.length"
                                      @is-sync="model.length = $event.value" />
                    </div>
                    <div class="p-col p-col-fixed p-nogutter p-mr-sm-1"  style="width: 10rem">
                        <form-numeric id="width" type="decimal"
                                      :decimalDigit="header.dimensionUnit === 200 ? 0 : 2"
                                      :max="9999.000"
                                      :suffix="header.dimensionUnitText ?? 'cm'"
                                      :hideLabel="index > 1"
                                      :value="item.width"
                                      @is-sync="model.width = $event.value" />
                    </div>
                    <div class="p-col p-col-fixed p-nogutter p-mr-sm-1"  style="width: 10rem">
                        <form-numeric id="height" type="decimal"
                                      :decimalDigit="header.dimensionUnit === 200 ? 0 : 2"
                                      :max="9999.000"
                                      :suffix="header.dimensionUnitText ?? 'cm'"
                                      :hideLabel="index > 1"
                                      :value="item.height"
                                      @is-sync="model.height = $event.value" />
                    </div>
                </div>
            </div>
            <div class="p-col-2 p-nogutter">
                <div class="p-d-flex p-jc-start p-nogutter">
                    <div class="p-col  p-nogutter">
                        <form-numeric id="volumetricWeight"
                                      :value="volumetricWeight ?? 0.001"
                                      :disabled="true"
                                      type="decimal"
                                      :decimalDigit="header.weightUnit === 200 ? 0 : 3"
                                      :min="0"
                                      :max="99995.000"
                                      :suffix="$filters.quantifiedDisplay(weightUnitText, volumetricWeight)"
                                      :hideLabel="index > 1" />
                    </div>
                    <div class="p-col  p-nogutter p-mx-sm-1">
                        <form-numeric id="chargeableWeight"
                                      :value="chargeableWeight ?? 0"
                                      :disabled="true"
                                      type="decimal"
                                      :min="0"
                                      :decimalDigit="header.weightUnit === 200 ? 0 : 3"
                                      :max="99995.000"
                                      :suffix="$filters.quantifiedDisplay(weightUnitText, chargeableWeight)"
                                      :hideLabel="index > 1" />
                    </div>
                </div>
            </div>
            <div class="p-col-2 p-nogutter">
                <div class="p-d-flex p-jc-start p-nogutter">
                    <div class="p-col  p-nogutter">
                        <form-numeric id="declaredValue" type="currency"
                                      :currency="header.currencyCode"
                                      :hideLabel="index > 1"
                                      :value="item.declaredValue"
                                      @is-sync="model.declaredValue = $event.value" />
                    </div>
                    <div class="p-col  p-nogutter p-mx-sm-1">
                        <form-numeric id="insuranceValue" type="currency"
                                      :currency="header.currencyCode"
                                      :hideLabel="index > 1"
                                      :value="item.insuranceValue"
                                      @is-sync="model.insuranceValue = $event.value" />
                    </div>
                    <!--<div class="p-col  p-nogutter p-mx-sm-1">
                        <form-numeric id="dutyValue" type="currency" />
                    </div>
                    <div class="p-col  p-nogutter p-mx-sm-1">
                        <form-numeric id="taxValue" type="currency" />
                    </div>-->

                </div>
            </div>
            <div class="p-col p-nogutter">
                <form-lookup id="dangerousGoods" :source="[{ id: 100, text: 'No Dangerous Goods' }, { id: 200, text: 'Dry Ice [UN1845]' }, { id: 300, text: 'Lithium Battery (Sec. II) [PI965-970]' }, { id: 400, text: 'Hazardous Materials' }]"
                             @is-sync="model.dangerousGoods = $event.value"
                             :value="item.dangerousGoods"
                             :hideLabel="index > 1"
                             :showClear="false"
                             :sorted="false" />
            </div>
            <div class="p-col-1 p-nogutter  p-mx-sm-1">
                <form-numeric id="parcelCount"
                              :min="1"
                              :max="99"
                              :hideLabel="index > 1"
                              @is-sync="model.parcelCount = $event.value"
                              :value="item.parcelCount" />
            </div>
            <div class="p-col p-col-fixed p-nogutter" style="width: 10rem">
                <div class="p-d-flex  p-nogutter p-ml-sm-2 p-pt-sm-1">
                    <div v-if="itemCount !== index" class="p-col p-col-fixed p-nogutter p-mx-sm-1" style="width: 2rem">
                        <pv-button class="p-button p-button-rounded p-button-raised" icon="pi pi-arrow-down" title="Copy to next item..." @click="onCopyNext(this.model.parcelID)"></pv-button>
                    </div>
                    <div v-else class="p-col p-col-fixed p-nogutter p-mx-sm-1" style="width: 2rem">
                        <pv-button class="p-button p-button-rounded p-button-raised p-button-info" icon="pi pi-plus" title="Add New Package" @click="onAdd()"></pv-button>
                    </div>
                    <div v-if="itemCount > 1" class="p-col p-col-fixed p-nogutter p-mx-sm-1" style="width: 2rem">
                        <pv-button class="p-button p-button-rounded p-button-raised" icon="pi pi-copy" title="Copy to ..." @click="onBeforeCopy('copy')"></pv-button>
                    </div>
                    <div v-if="itemCount > 1" class="p-col p-col-fixed p-nogutter p-mx-sm-1" style="width: 2rem">
                        <pv-button class="p-button p-button-rounded p-button-raised" icon="pi mdi mdi-file-replace-outline" title="Paste from ..." @click="onBeforeCopy('paste')"></pv-button>
                    </div>
                    <div v-if="itemCount > 1" class="p-col p-col-fixed p-nogutter p-mx-sm-1" style="width: 2rem">
                        <pv-button class="p-button p-button-rounded p-button-raised p-button-danger" icon="pi pi-trash" title="Delete Current Package" @click="dialog.delete = true"></pv-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <is-dialog :header="(copy.type === 'copy' ? 'Copy To...' : 'Paste From...')" :style="{width: '50vw'}" :visible="dialog.copy" @is-confirm="onCopyValue(item.parcelID, copy.selected)" @is-cancel="onCancel" :confirmDisabled="copy.selected <= 0">
            <div class="confirmation-content p-d-flex">
                <div>
                    <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
                </div>
                <div style="width: 50vw">
                    Copy this record to the package number to the right:
                    <div style="margin: 10px 0; padding: 10px; background-color: var(--surface-d);">
                        <div class="p-grid">
                            <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Package #</div>
                            <div class="p-col p-code-text">: {{ model.parcelID }}</div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Declare Weight</div>
                            <div class="p-col p-code-text">: {{ model.declaredWeight.toFixed(3) }} {{ $filters.quantifiedDisplay(weightUnitText, model.declaredWeight.toFixed(3))  }}</div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Dimension (L x H x W)</div>
                            <div class="p-col p-code-text">: {{ model.length.toFixed(3) }} {{ header.dimensionUnitText ?? 'cm' }} x {{ model.height.toFixed(3) }} {{ header.dimensionUnitText ?? 'cm' }} x {{ model.width.toFixed(3) }} {{ header.dimensionUnitText ?? 'cm' }}</div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Volumetric Weight</div>
                            <div class="p-col p-code-text">: {{ volumetricWeight.toFixed(3) }} {{ $filters.quantifiedDisplay(weightUnitText, volumetricWeight.toFixed(3))  }}</div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Chargeable Weight</div>
                            <div class="p-col p-code-text">: {{ chargeableWeight.toFixed(3) }} {{ $filters.quantifiedDisplay(weightUnitText, chargeableWeight.toFixed(3))  }}</div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Parcel Count</div>
                            <div class="p-col p-code-text">: {{ model.parcelCount }}</div>
                        </div>
                    </div>
                    <div style="margin-top: 20px;">
                        <form-lookup id="parcelSource"
                                     :label="(copy.type === 'copy' ? 'Copy To...' : 'Will be replaced with...')"
                                     :source="packageNumbers"
                                     :hideLabel="index > 1"
                                     :showClear="false"
                                     :sorted="false"
                                     :value="copy.selected"
                                     @is-sync="select($event.value)" />
                        <div v-if="(copy.selected > 0 && copy.selected <= this.itemCount)" class="p-col" style="margin: 10px 0; padding: 10px; background-color: var(--surface-d);">
                            <div class="p-grid">
                                <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Package #</div>
                                <div class="p-col p-code-text">: {{ copy.target?.parcelID }}</div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Declare Weight</div>
                                <div class="p-col p-code-text">: {{ copy.target?.declaredWeight.toFixed(3) }} {{ $filters.quantifiedDisplay(weightUnitText, copy.target?.declaredWeight.toFixed(3))  }}</div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Dimension (L x H x W)</div>
                                <div class="p-col p-code-text">: {{ copy.target?.length.toFixed(3) }} {{ header.dimensionUnitText ?? 'cm' }} x {{ copy.target?.height.toFixed(3) }} {{ header.dimensionUnitText ?? 'cm' }} x {{ copy.target?.width.toFixed(3) }} {{ header.dimensionUnitText ?? 'cm' }}</div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Volumetric Weight</div>
                                <div class="p-col p-code-text">: {{ volumetricWeight.toFixed(3) }} {{ $filters.quantifiedDisplay(weightUnitText, volumetricWeight.toFixed(3))  }}</div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Chargeable Weight</div>
                                <div class="p-col p-code-text">: {{ chargeableWeight.toFixed(3) }} {{ $filters.quantifiedDisplay(weightUnitText, chargeableWeight.toFixed(3))  }}</div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Parcel Count</div>
                                <div class="p-col p-code-text">: {{ copy.target?.parcelCount }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </is-dialog>
        <is-dialog header="Delete Record?" :visible="dialog.delete" :style="{width: '30vw'}" @is-confirm="onDelete(this.model.parcelID)" @is-cancel="onCancel">
            <div class="confirmation-content p-d-flex" :style="{width: '25vw'}" >
                <div>
                    <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
                </div>
                <div>
                    You are about to delete this record:
                    <div style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 25vw;">
                        <div class="p-grid">
                            <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Package #</div>
                            <div class="p-col p-code-text">: {{ model.parcelID }}</div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Declare Weight</div>
                            <div class="p-col p-code-text">: {{ model.declaredWeight.toFixed(3) }} {{ $filters.quantifiedDisplay(weightUnitText, model.declaredWeight.toFixed(3))  }}</div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Dimension (L x H x W)</div>
                            <div class="p-col p-code-text">: {{ model.length.toFixed(3) }} {{ header.dimensionUnitText ?? 'cm' }} x {{ model.height.toFixed(3) }} {{ header.dimensionUnitText ?? 'cm' }} x {{ model.width.toFixed(3) }} {{ header.dimensionUnitText ?? 'cm' }}</div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Volumetric Weight</div>
                            <div class="p-col p-code-text">: {{ volumetricWeight.toFixed(3) }} {{ $filters.quantifiedDisplay(weightUnitText, volumetricWeight.toFixed(3))  }}</div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Chargeable Weight</div>
                            <div class="p-col p-code-text">: {{ chargeableWeight.toFixed(3) }} {{ $filters.quantifiedDisplay(weightUnitText, chargeableWeight.toFixed(3))  }}</div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Parcel Count</div>
                            <div class="p-col p-code-text">: {{ model.parcelCount }}</div>
                        </div>
                    </div>
                    <br />Are you sure you still want to proceed?
                </div>
            </div>
        </is-dialog>
</template>

<script>
    export default {
        name: 'QuoteParcelLineTemplate',
        props: {
            header: { type: Object },
            itemCount: { type: Number, default: 0 },
            index: { type: Number, default: 0 },
            item: { type: Object }
        },
        data() {
            return {
                dialog: {
                    delete: false,
                    copy: false
                },
                copy: {
                    type: null,
                    selected: 0,
                    target: null
                },
                model: {
                    parcelID: 0,
                    declaredWeight: 1.00,
                    length: 20,
                    width: 20,
                    height: 20,
                    declaredValue: 1.00,
                    insuranceValue: 0.00,
                    dutyValue: 0.00,
                    taxValue: 0.00,
                    dangerousGoods: 100,
                    volumetricWeight: 0.00,
                    chargeableWeight: 0.00,
                    parcelCount: 1
                }
            }
        },
        computed: {
            packageNumbers() {
                var parcels = [];
                for (let i = 1; i <= this.itemCount; i++) {
                    if (i !== this.index)
                        parcels.push({ id: i, text: i });
                }
                if (this.copy.type === "copy")
                    parcels.push({ id: this.itemCount + 1, text: "New Package..." });
                return parcels;
            },
            parentHeader() {
                return this.header;
            },
            weightUnitText() {
                return this.parentHeader?.weightUnitText ?? 'kg';
            },
            volumetricWeight() {
                return (((this.model?.length ?? 0) * (this.model?.width ?? 0) * (this.model?.height ?? 0)) / 5000) ?? 0;
            },
            chargeableWeight() {
                return (this.volumetricWeight ?? 0.00) > (this.model?.declaredWeight ?? 0.00) ? (this.volumetricWeight ?? 0.00) : (this.model?.declaredWeight ?? 0.00);
            }
        },
        watch: {
            model: {
                handler() {
                    if (this.model.parcelID ===  0)
                        this.model.parcelID = this.itemCount;
                    this.$emit("is-sync", this.model);
                },
                deep: true
            }
        },
        methods: {
            onBeforeCopy(type) {
                this.copy.type = type;
                this.dialog.copy = true;
            },
            select(id) {
                this.copy.selected = id;
                this.copy.target = this.parentHeader.parcels[id - 1];
            },
            onAdd() { this.$emit("is-add"); },
            onCancel() {
                this.dialog.delete = false;
                this.dialog.copy = false;
                this.copy.type = null;
                this.copy.selected = 0;
                this.copy.target = null;
            },
            onDelete(index) { this.$emit("is-delete", index); },
            onCopyNext(index) { this.$emit("is-copy-next", index); },
            onCopyValue(source, target) {
                if (this.copy.type === "copy")
                    this.$emit("is-copy", { source, target });
                else
                    this.$emit("is-copy", { source: target, target: source });
                this.onCancel();
            },
        },
        mounted() {
            this.model = this.$filters.init(this.item);
        }
    }
</script>

