<template>
    <form-wrapper title="Carrier Lead Time" sub-title="List of available carrier lead time. If not provided, the lead time will be assigned automatically on the shipment based on carrier capability services.">
        <pv-grid :value="dsTimeInTransit" v-model:expandedRows="expandedRows" dataKey="provider.id"
                 autoLayout
                 showGridlines
                 stripedRows
                 @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" responsiveLayout="scroll"
                 :rows="10"
                 :searchFilter="['provider.code', 'provider.name']"
                 :totalRecords="(dsTimeInTransit ?? []).length"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                 :paginator="true"
                 :alwaysShowPaginator="true"
                 :paginatorPosition="'bottom'"
                 :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'"
                 :pageLinkSize="3">
            <template #header>
                <div class="table-header-container">
                    <pv-button icon="pi pi-plus" label="Expand All" @click="expandAll" class="mr-2" style="margin-right: 0.5rem;" />
                    <pv-button icon="pi pi-minus" label="Collapse All" @click="collapseAll" />
                </div>
            </template>
            <pv-grid-column :expander="true" headerStyle="width: 3rem; padding: 10px;" style="padding: 5px 10px;" />
            <pv-grid-column field="provider.code" header="Provider Code" />
            <pv-grid-column field="provider.name" header="Provider Name" />
            <pv-grid-column field="providerCarrier.code" header="Carrier Code" />
            <pv-grid-column field="providerCarrier.name" header="Carrier Name" />
            <template #expansion="slotProps">
                <div class="orders-subtable" style="margin: -10px -10px -10px calc(3rem - 10px);">
                    <pv-grid :value="slotProps.data.products" responsiveLayout="scroll" v-model:expandedRows="expandedRows2" dataKey="product.id"
                             autoLayout
                             showGridlines
                             stripedRows
                             :rows="10"
                             :totalRecords="(dsTimeInTransit ?? []).length"
                             currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                             :paginator="true"
                             :alwaysShowPaginator="true"
                             :paginatorPosition="'bottom'"
                             :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'"
                             :pageLinkSize="3">
                        <pv-grid-column :expander="true" headerStyle="width: 3rem; padding: 10px;" style="padding: 5px 10px;" />
                        <pv-grid-column field="product.code" header="Code" />
                        <pv-grid-column field="product.name" header="Name" />
                        <template #expansion="slotProps">
                            <div class="orders-subtable" style="margin: -10px -10px -10px calc(3rem - 10px);">
                                <pv-grid :value="slotProps.data.leadtime" responsiveLayout="scroll"
                                         autoLayout
                                         showGridlines
                                         stripedRows
                                         :rows="10"
                                         :totalRecords="(slotProps.data.leadtime ?? []).length"
                                         :paginator="true"
                                         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                         :alwaysShowPaginator="true"
                                         :paginatorPosition="'bottom'"
                                         :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'"
                                         :pageLinkSize="3">
                                    <pv-grid-column field="providerCountry.name" header="Country Origin/Destination">
                                        <template #body="slotProps">
                                            <i :class="`flag flag-${slotProps.data?.providerCountry?.code?.toLowerCase()}`"></i> &nbsp;
                                            [<strong>{{ slotProps.data.providerCountry?.code}}</strong>] {{ slotProps.data.providerCountry?.name}}
                                        </template>
                                    </pv-grid-column>
                                    <pv-grid-column field="text" header="Transit Days" headerStyle="width: 10rem" style="text-align: right;" />
                                    <pv-grid-column field="deliveryTime" header="Delivery Time" headerStyle="width: 8rem" style="text-align: right;" />
                                    <!-- Row Messages -->
                                    <template #empty>
                                        No records found.
                                    </template>
                                    <template #loading>
                                        Retrieving records from database. Please wait...
                                    </template>
                                    <!-- Pagination -->
                                    <template #paginatorRight>
                                        <pv-button type="button" icon="pi pi-refresh" class="p-button-rounded p-button-text" @click="onRefresh" />
                                    </template>
                                </pv-grid>
                            </div>
                        </template>
                        <!-- Row Messages -->
                        <template #empty>
                            No records found.
                        </template>
                        <template #loading>
                            Retrieving records from database. Please wait...
                        </template>
                        <!-- Pagination -->
                        <template #paginatorRight>
                            <pv-button type="button" icon="pi pi-refresh" class="p-button-rounded p-button-text" @click="onRefresh" />
                        </template>
                    </pv-grid>
                </div>
            </template>
            <!-- Row Messages -->
            <template #empty>
                No records found.
            </template>
            <template #loading>
                Retrieving records from database. Please wait...
            </template>
            <!-- Pagination -->
            <template #paginatorRight>
                <pv-button type="button" icon="pi pi-refresh" class="p-button-rounded p-button-text" @click="onRefresh" />
            </template>
        </pv-grid>
    </form-wrapper>
</template>
<script>
    export default {
        name: "ZoneIndex",
        data() {
            return {
                timeintransit: [],
                expandedRows: [],
                expandedRows2: [],
                columns: [
                    { field: 'provider.name', header: 'Provider', width: 20 },
                    { field: 'code', header: 'Code', width: 20 },
                    { field: 'name', header: 'Name' }
                ],
            }
        },
        computed: {
            dsTimeInTransit() { return this.timeintransit; },
        },
        watch: {
            integrationMask() {
                this.getSource();
            }
        },
        methods: {
            expandAll() {
                this.expandedRows = this.dsTimeInTransit.filter(p => p.provider.id);
                let rows = [];
                this.dsTimeInTransit.forEach(p => {
                    p.products.filter(j => j.product.id).forEach(r => {
                        rows.push(r);
                    });
                });
                this.$nextTick(() => { this.expandedRows2 = rows; });
                this.$nextTick(() => { this.expandedRows2 = rows; });
            },
            collapseAll() {
                this.expandedRows = null;
                this.expandedRows2 = null;
            },
            getSource() {
                this.$store.dispatch("providertimeintransit/getURL", { url: `/services/settings/serviceprovidertimeintransit` }).then((response) => {
                    if (response && response.success) {
                        let ds = response.result;
                        let items = [];
                        let providers = [... new Set(ds.map(({ ...rest }) => { return `[${rest.provider.code}] ${rest.provider.name}`; }))];
                        providers.forEach((data) => {
                            let productDS = ds.filter(x => `[${x.provider.code}] ${x.provider.name}` === data);
                            let item = { provider: productDS[0].provider, products: null };
                            let products = [... new Set(productDS.map(({ ...rest }) => { return `[${rest.providerProduct.code}] ${rest.providerProduct.name}`; }))];
                            let productItems = [];
                            products.forEach((product) => {
                                let leadtimeDS = ds.filter(x => `[${x.provider.code}] ${x.provider.name}` === data
                                    && `[${x.providerProduct.code}] ${x.providerProduct.name}` === product
                                );
                                productItems.push({ product: leadtimeDS[0].providerProduct, leadtime: leadtimeDS });
                            })
                            item.products = productItems;
                            items.push(item);
                        });
                        this.timeintransit = items;
                    }
                }).catch((ex) => console.log(ex));
            }
        },
        created() {
            this.getSource();
        }
    }
</script>