<template>
    <form-wrapper title="Customer Markup Rates" sub-title="These are the customer's negotiated rates.">
        <cosmos-grid id="MarkupContractCustomerList"
                     :multiLevel="true"
                     :forceRefresh="action.refresh"
                     :autoGenerate="false"
                     :showOperation="false"
                     @is-source="sources.grid = $event?.data ?? $event ?? []">
            <pv-grid-column field="customerDetail.code" header="Customer Detail" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
                <template #body="slotProps">
                    <div>[<b>{{ slotProps.data.customerDetail.code }}</b>] {{ slotProps.data.customerDetail.name}}</div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="rateCardType" header="Rate Applied Type" headerStyle="width: 7rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
                <template #body="slotProps">
                    <div style="width: 10rem !important; text-align: center;" :class="`p-indicator definite-${(slotProps.data.rateCardType == 100 ? 'DOCUMENT' : 'NON-DOCUMENT')}`">{{ $vm.$enums.ratecardtype.filter(x => x.id === slotProps.data.rateCardType)[0].text }}</div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="rateApplied" header="Markup Rate" headerStyle="width: 10rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em; text-align: right;">
                <template #body="slotProps">
                    <div v-if="slotProps.data.rateCardType === 100" style="font-weight: 700">{{ slotProps.data.rateApplied.toFixed(2) }} {{ slotProps.data.rateType === 200 ? '%' : ''}}</div>
                </template>
            </pv-grid-column>
            <!-- Operation Column -->
            <pv-grid-column field="mask" :headerStyle="`min-width: 7rem; max-width: 7rem; width: 7rem`" style="border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
                <template #body="slotProps">
                    <div class="p-d-flex" style="width: 2rem;">
                        <div class="p-col">
                            <pv-button icon="pi pi-pencil" iconPos="right" class="p-button-info" @click="onEdit(slotProps.data)" title="Edit Record" />
                        </div>
                        <div class="p-col">
                            <pv-button :disabled="slotProps.data.recordStatus !== 0" icon="pi pi-trash" iconPos="right" class="p-button-danger" @click="onDelete(slotProps.data)" title="Delete Record" />
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <!-- Sub Grid -->
            <template #expansion="slotProps">
                <markup-account-grid v-if="!slotProps.data.useProviderRate" :parentID="slotProps.data.id" :customerID="slotProps.data.customerID" :parentRateCardType="slotProps.data.rateCardType" />
                <div v-else style="background-color: var(--bg-warning); color: var(--gray-800) ">
                    <div class="p-d-flex">
                        <div style="padding: 0.5rem;">
                            <i class="fa fa-info-circle fa-2x"></i>
                        </div>
                        <div style="padding: 0.75rem 1rem; font-size: 1.1rem;">
                            The customer's rate card type is <strong>PROVIDER</strong>, account level configuration is not allowed. If you have account level configurations, all settings will be disabled and will be available once the rate card type is set to <strong>MARKUP</strong> again.
                        </div>
                    </div>
                </div>
            </template>
            <!-- Toolbar -->
            <template #toolbar>
                <div class="p-d-flex p-jc-between p-ai-center">
                    <span>
                        <pv-button icon="pi pi-fw pi-plus" label="Add New Record" style="margin-right: 5px;" @click="onCreate"></pv-button>
                    </span>
                    <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                        <i class="pi pi-search" />
                        <pv-input placeholder="Keyword Search" style="width: 100%;" />
                    </span>
                </div>
            </template>
        </cosmos-grid>
    </form-wrapper>
    <!-- Dialog Boxes -->
    <!-- Delete Dialog -->
    <is-dialog header="Delete Record?" :visible="dialog.delete" @is-confirm="onConfirmDelete(model.mask)" @is-cancel="onCancel()">
        <div class="confirmation-content p-d-flex">
            <div><i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" /></div>
            <div>
                You are about to delete this record:
                <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                    <tr>
                        <th style="text-align:left;">Customer Detail</th>
                        <td>:</td>
                        <td> <b>[{{ this.model.customerDetail.code }}]</b> {{ this.model.customerDetail.name }}</td>
                    </tr>
                </table>
                Please be aware of the following:
                <ul>
                    <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                    <li>For records that has activity, the record will only be disabled and archived.</li>
                    <li>Also deleting a record may be irreversible action.</li>
                </ul>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
    <!-- Create / Edit Dialog -->
    <is-dialog :header="(model?.id ?? 0) === 0 ? 'Add New Record' : 'Edit Record'" style="width: 25vw; min-width: 400px;" :visible="dialog.edit" @is-confirm="onConfirmEdit(model)" @is-cancel="onCancel()" :confirmDisabled="confirmDisabled">
        <div class="confirmation-content p-d-flex">
            <div class="p-col p-md-12">
                <form-lookup id="customer" :disabled="(this.model?.id ?? 0 ) > 0" sourceType="array" :source="customers" :value="model.customerID" @is-sync="{ model.customerID = $event.value; }" :required="true" :show-copy="false" />
                <form-lookup id="rateCardType" type="enum" source="ratecardtype" :value="model.rateCardType" @is-sync="model.rateCardType = ($event?.value ?? 100)" :required="true" />
                <form-lookup v-if="model.rateCardType === 100" id="markupType" type="enum" source="ratetype" :value="model.rateType" @is-sync="model.rateType = $event.value" :required="true" />
                <form-numeric v-if="model.rateCardType === 100" id="rateApplied" :value="model.rateApplied" @is-sync="model.rateApplied = $event.value" type="decimal" :suffix="suffix" :required="true" />
                <div v-if="model.rateType === 100" style="background: var(--bg-info); padding: 1rem; text-align: justify; color: var(--button-text);">
                    <h5 style="text-transform: uppercase;">Markup Currency</h5>
                    <div style="max-height: 18rem; overflow-y: auto; margin: -1rem; padding: 1rem;">
                        The flat rate currency will always be based on the currency of the configured carrier account.<br /><br />For example, if the shipper's country currency is in <b>GBP</b> but the carrier account is using <b>EUR</b>, then the markup declared will use <b>EUR</b>.
                    </div>
                </div>
            </div>
        </div>
    </is-dialog>
</template>
<script>
    import markupAccountGrid from '@/components/facility/rates/markup/markup-account-grid.vue';
    export default {
        name: 'MarkupContractCustomer',
        components: {
            markupAccountGrid
        },
        data() {
            return {
                model: null,
                page: {
                    current: 0,
                    first: 0,
                    size: 10
                },
                action: {
                    refresh: false
                },
                dialog: {
                    edit: false,
                    delete: false
                },
                sources: {
                    customers: [],
                    grid: []
                },
                activeKeys: null
            }
        },
        computed: {
            customers() {
                let environmentID = Number(atob(this.activeKeys ?? sessionStorage.getItem('activekeys')).split('|')[1]);
                if ((this.model?.id ?? 0) === 0)
                    return this.sources.customers.filter(x => this.sources.grid.findIndex(y => y.customerID === x.id) === -1 && x.environmentID === environmentID);
                return this.sources.customers.filter(x => x.environmentID === environmentID);
            },
            confirmDisabled() {
                var disabled = false;
                if (!this.model?.customerID)
                    return true;
                if (this.model?.rateCardType === 100) {
                    if (this.model?.rateApplied === 0.00)
                        return true;
                }
                return disabled;
            },
            suffix() {
                return this.model.rateType === 200 ? '%' : '';
            }
        },
        watch: {
            activeKeys() {
                this.onDataBind();
            }
        },
        methods: {
            onCreate() {
                this.model = {
                    customerID: 0,
                    rateType: 200,
                    rateApplied: 0.00,
                    useProviderRate: false
                };
                this.dialog.edit = true;
            },
            onCancel() {
                this.dialog.edit = false;
                this.dialog.delete = false;
            },
            onDelete(data) {
                this.model = this.$filters.init(data);
                this.dialog.delete = true;
            },
            onEdit(data) {
                this.model = this.$filters.init(data);
                this.dialog.edit = true;
            },
            onConfirmDelete(mask) {
                this.$store.dispatch("markupcontract/deleteRecordByMask", mask)
                    .then((response) => {
                        if (response.success) {
                            this.$nextTick(() => this.action.refresh = true);
                            this.$toast.add({ severity: 'success', summary: 'Customer Markup Detail', detail: "The record was deleted successfully!", life: 3000 });
                            this.onCancel();
                            setTimeout(() => this.action.refresh = false, 200);
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
            onConfirmEdit(data) {
                let index = this.sources.grid.findIndex(x => x.mask === data.mask);
                var model = this.$filters.init(data);
                if (index >= 0) {
                    this.$store.dispatch("markupcontract/updateRecord", model)
                        .then((response) => {
                            if (response.success) {
                                this.$toast.add({ severity: 'success', summary: 'Customer Markup Detail', detail: "The record was updated successfully!", life: 3000 });
                                this.dialog.edit = false;
                                this.action.refresh = true;
                                this.$nextTick(() => {
                                    this.action.refresh = false;
                                });
                                this.onCancel();
                            }
                            else if ((response.errors ?? []).length > 0)
                                this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: response.errors[0]?.message, life: 3000 });
                        })
                        .catch(ex => {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        });
                }
                else {
                    this.$store.dispatch("markupcontract/addRecord", model)
                        .then((response) => {
                            if (response.success) {
                                this.$toast.add({ severity: 'success', summary: 'Customer Markup Detail', detail: "The record was added successfully!", life: 3000 });
                                this.action.refresh = true;
                                this.$nextTick(() => {
                                    this.action.refresh = false;
                                });
                                this.onCancel();
                            }
                            else if ((response.errors ?? []).length > 0)
                                this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: response.errors[0]?.message, life: 3000 });
                        })
                        .catch(ex => {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        });
                }
            },
            onDataBind() {
                this.$store.dispatch("markupcontractaccount/getURL", { url: `/services/settings/company?type=16` })
                    .then((response) => {
                        this.sources.accounts = [];
                        if (response.success) {
                            this.sources.customers = response.result;
                            this.loading = false;
                        }
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        this.loading = false;
                    });
            }
        },
        created() {
            this.activeKeys = sessionStorage.getItem('activekeys');
            this.onDataBind();
        },
        mounted() {
            window.addEventListener('setItem', () => {
                this.activeKeys = sessionStorage.getItem('activekeys');
            });
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>