<template>
    <pv-card class=" is-summary">
        <template v-slot:subtitle>
            Confirm your account details
        </template>
        <template v-slot:content>
            <div class="card" style="background:var(--surface-c); border-radius: 4px; margin-bottom: 2rem;">
                <div class="p-grid">
                    <div class="p-col-12 p-md-4">
                        <h5>Account Details</h5>
                        <div class="card">
                            <div class="p-grid">
                                <div class="p-col-fixed" style="width:130px; font-weight: bold;">Code</div>
                                <div class="p-col">: <span class="p-code-text">{{model?.code}}</span></div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-fixed" style="width: 130px; font-weight: bold;">Name</div>
                                <div class="p-col">: <span class="p-code-text">{{model?.name}}</span></div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-fixed" style="width: 130px; font-weight: bold;">Service Provider</div>
                                <div class="p-col">: <span class="p-code-text">{{model?.serviceProviderName}}</span></div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-fixed" style="width: 130px; font-weight: bold;">Integration Type</div>
                                <div class="p-col">: <span class="p-code-text">{{model?.integrationName}}</span></div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-fixed" style="width: 130px; font-weight: bold;">Contract Country</div>
                                <div class="p-col">: <span class="p-code-text">{{model?.contractCountry?.name ?? $vm.$location.country_name }}</span></div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-fixed" style="width: 130px; font-weight: bold;">Trade Types</div>
                                <div class="p-col">: <span class="p-code-text">{{ tradeTypes }}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-8">
                        <h5>Credential Details</h5>
                        <div class="card">
                            <div class="p-grid">
                                <div class="p-col-fixed" style="width: 200px; font-weight: bold;">Account Type</div>
                                <div class="p-col">: <span class="p-code-text">{{model?.accountType === 100 ? "Production Account" : "On-Boarding Account" }}</span></div>
                            </div>
                            <div v-for="credential in credentialFields" :key="credential.label" class="p-grid">
                                <div class="p-col-fixed" style="width: 200px; font-weight: bold;">{{credential.label}}</div>
                                <div class="p-col">: <span class="p-code-text">{{credential.value}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" style="background:var(--surface-c); border-radius: 4px; margin-bottom: 0;">
                <div class="p-grid">
                    <div class="p-col-12 p-md-4">
                        <h5>Subscribed Products</h5>
                        <pv-scroll-panel style="width: 100%; height: 200px;">
                            <ul class="display-list">
                                <li v-for="item in model?.products ?? []" :key="item.id">
                                    {{ item?.name }}
                                </li>
                            </ul>
                        </pv-scroll-panel>
                    </div>
                    <div class="p-col-12 p-md-4">
                        <h5>Posting Locations</h5>
                        <pv-scroll-panel style="width: 100%; height: 200px;">
                            <ul class="display-list">
                                <li v-for="item in model?.locations ?? []" :key="item.companyID">
                                    <h4>{{ item?.name }}</h4>
                                    <span class="p-code-text">{{ item?.address }}</span>
                                </li>
                            </ul>
                        </pv-scroll-panel>
                    </div>
                    <div class="p-col-12 p-md-4">
                        <h5>Subscribed Companies</h5>
                        <pv-scroll-panel style="width: 100%; height: 200px;">
                            <ul class="display-list">
                                <li v-for="item in model?.customers ?? []" :key="item.companyID">
                                    {{ item?.name }}
                                </li>
                            </ul>
                        </pv-scroll-panel>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="p-grid p-nogutter p-justify-between">
                <pv-button label="Previous" @click="prevPage()" icon="pi pi-angle-left" />
                <pv-button label="Complete" @click="complete()" icon="pi pi-check" iconPos="right" class="p-button-success" />
            </div>
        </template>
    </pv-card>

</template>
<script>
    export default {
        name: 'ServiceProviderAccountSummary',
        props: {
            id: { type: String },
            integrationMask: { type: String }
        },
        data() {
            return {
                model: null,
            }
        },
        computed: {
            credentialFields() {
                let credentials = []
                if (this.model) {
                    for (var index = 1; index <= 20; index++) {
                        let i = this.$filters.padLeft(index, 2, '0');
                        if (this.model["credential_" + i + "Label"]) {
                            credentials.push({
                                label: this.model["credential_" + i + "Label"],
                                value: this.model["credential_" + i]
                            });
                        }
                    }
                }
                return credentials;
            },
            tradeTypes() {
                var tradeTypes = this.$vm.$enums["tradetype"];
                if (this.model) {
                    tradeTypes = tradeTypes.filter(x => this.$filters.getFlags(this.model.tradeType).indexOf(x.id) !== -1).map(x => x.text);
                }
                return tradeTypes.join();
            }
        },
        methods: {
            prevPage() {
                this.$emit('prev-page', { pageIndex: 4 });
            },
            complete() {
                this.$emit('complete', this.model);
            }
        },
        beforeCreate() { this.model = this.$attrs.formData },
        mounted() { this.model = this.$attrs.formData },
    }
</script>
