<template>
    <keep-alive>
        <is-dialog header="PRINTER" :visible="dialog.delete && (dialog.edit || dialog.add)" @is-confirm="onManage(model)" :confirmDisabled="v$.$invalid" @is-cancel="this.dialog.delete = false;this.dialog.edit = false;this.dialog.add = false" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '25vw'}">
            <div class="confirmation-content p-fluid p-grid">
                <div class="p-grid p-col-12">
                    <div class="p-col-12">
                        <form-input id="code" label="Code" :required="true" :v$="v$" :value="model.code" vBase="model" vID="code" @is-sync="model.code = $event.value" placeholder="Printer Code." v-focus :max-length="30" />
                        <form-lookup id="type" :required="true" type="enum" source="companyprintertype" :value="model.type" label="Type" @is-sync="model.type = $event.value" :v$="v$"></form-lookup>
                    </div>
                </div>
            </div>
        </is-dialog>
    </keep-alive>
    <keep-alive>
        <is-dialog header="DEVICE KEY" :visible="dialog.key" :showConfirm="false" :cancel="'Close'" @is-cancel="this.dialog.key = false;this.model = null" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '25vw'}">
            <div class="confirmation-content p-fluid p-grid">
                <div class="p-grid p-col-12">
                    <div class="p-col-12">
                        <form-input id="mask" label="Client Key" :hideLabel="true" :disabled="true" :v$="v$" :value="model.mask" @is-sync="model.mask = $event.value" :showCopy="true" />
                    </div>
                </div>
            </div>
        </is-dialog>
    </keep-alive>

    <is-dialog header="DISCONNECT PRINTER" :visible="dialog.disconnect" @is-confirm="onConfirmDisconnect()" :confirmLoading="activity.processing" :confirmDisabled="activity.processing" @is-cancel="this.dialog.disconnect = false;this.model = null" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '25vw'}">
        <div class="confirmation-content p-d-flex">
            <div>
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            </div>
            <div class="p-grid" style="width: calc(100% - 35px) !important;">
                You are about to disconnect this printer:
                <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                    <tr v-for="data in disconectDisplay" :key="data.id">
                        <th style="text-align:left;">{{ $filters.titleize(data) == "Name" ? "Computer Name" : $filters.titleize(data) }}</th>
                        <td>:</td>
                        <td> {{ forDisconnect[data] }}</td>
                    </tr>
                </table>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>

    <is-dialog header="Delete Record?" :visible="dialog.delete && !dialog.edit && !dialog.add" @is-confirm="onManage(model)" @is-cancel="this.dialog.delete = false; this.dialog.add = false; this.dialog.edit = false;">
        <div class="confirmation-content p-d-flex">
            <div>
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            </div>
            <div>
                You are about to delete this record:
                <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                    <tr v-for="data in deleteDisplay" :key="data.id">
                        <th style="text-align:left;">{{ $filters.titleize(data) == "TypeText" ? "Type" : $filters.titleize(data) }}</th>
                        <td>:</td>
                        <td> {{ forDelete[data] }}</td>
                    </tr>
                </table>

                Please be aware of the following:
                <ul>
                    <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                    <li>For records that has activity, the record will only be disabled and archived.</li>
                    <li>Also deleting a record may be irreversible action.</li>
                </ul>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>

    <is-dialog header="Download Application by Integro360 Limited" :visible="dialog.application" :showConfirm="false" :cancel="'Close'" @is-cancel="this.dialog.application = false;" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '25vw'}">
        <div class="confirmation-content p-fluid p-grid">
            <div class="p-grid p-col-12">
                <div class="p-col-12">
                    <p><b>.NET Framework 4.8 Runtime</b> is required before the installation of Application by Integro360 Limited into your machine.</p>
                    <p>Please follow the following steps:</p>
                    <ul style="list-style: none;">
                        <li style="margin-bottom: 0.25rem;"><a :href="`https://cdn.integro360.com/kbase/wiki/I360AppInstallationGuide.pdf?${Math.random()}`" target="_blank" style="margin-right: 0.25rem; " download><i class="fa fa-download fa-fw" style="margin-right: 0.25rem;"></i> Download Integro360 Printer App Installation Guide.</a></li>
                        <li style="margin-bottom: 0.25rem;"><a :href="`https://cdn.integro360.com/kbase/installer/microsoft/NDP48-x86-x64-AllOS-ENU.exe?${Math.random()}`" target="_blank" style="margin-right: 0.25rem; " download><i class="fa fa-download fa-fw" style="margin-right: 0.25rem;"></i> Download .NET Framework 4.8 Installer</a></li>
                        <li style="margin-bottom: 0.25rem;"><a :href="`https://cdn.integro360.com/kbase/installer/integro360/${osBuild}/Integro360AppSetup-${osBuild}.msi?${Math.random()}`" target="_blank" style="margin-right: 0.25rem; " download><i class="fa fa-download fa-fw" style="margin-right: 0.25rem;"></i> Download Desktop Application</a></li>
                    </ul>
                    <p>The configuration guide is available in the application.</p>
                </div>
            </div>
        </div>
    </is-dialog>
    <cosmos-grid id="printers" sourceType="array" :source="source?.data ?? []" :sourcePaged="true" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showExport="false" :showDelete="false" :showEdit="false" :showHeader="true" :showOperation="false" @is-refresh="getSource()">
        <template #toolbar>
            <div class="p-d-flex p-jc-start p-ai-center">
                <span>
                    <pv-button v-if="!(limitedAccess)" icon="pi pi-fw pi-plus" label="Add Printer" style="margin-right: 5px;" @click="onCreate"></pv-button>
                </span>
                <span v-if="osType === 'WINDOWS'" class="p-d-flex p-jc-end p-button p-component" style="margin-right: 0.25rem; " @click="dialog.application = true"><i class="fa fa-download fa-fw" style="margin-right: 0.5rem;"></i> Download Desktop Application</span>
            </div>
        </template>
        <pv-grid-column field="code" header="CODE">
            <template #body="slotProps">
                {{ slotProps.data.code}}
            </template>
        </pv-grid-column>
        <pv-grid-column field="type" header="Type">
            <template #body="slotProps">
                {{ slotProps.data.typeText}}
            </template>
        </pv-grid-column>
        <pv-grid-column field="printer" header="Printer Name">
            <template #body="slotProps">
                {{ slotProps.data.printer}}
            </template>
        </pv-grid-column>
        <pv-grid-column field="name" header="Computer Name">
            <template #body="slotProps">
                {{ slotProps.data.name}}
            </template>
        </pv-grid-column>
        <pv-grid-column field="macAddress" header="MAC Address">
            <template #body="slotProps">
                {{ slotProps.data.macAddress}}
            </template>
        </pv-grid-column>
        <pv-grid-column field="contactPerson" header="Contact">
            <template #body="slotProps">
                {{ slotProps.data.contactPerson}}
            </template>
        </pv-grid-column>
        <pv-grid-column field="statusText" header="Status" style="min-width:5rem;max-width:7rem; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-jc-center">
                    <pv-button @click="onLoadDisconnect(slotProps.data)" :icon="slotProps.data.isActive ? 'fas fa-sharp fa-solid fa-check' : 'fas fa-sharp fa-solid fa-ban'" :label="slotProps.data.isActive ? 'CONNECTED' : 'DISCONNECTED'" :class="slotProps.data.isActive ? 'p-button-success': 'p-button-danger'" :style="[{'width': '12rem'}, {'object-position': 'bottom'}, {'align-content': 'end' }, {'padding': '0.5rem 1rem'}, {'margin-top': '-0.25rem'}]"></pv-button>
                </div>
                <!--<pv-toggle v-if="disconnectAccess && slotProps.data.isActive" v-model="slotProps.data.isActive" :style="[{'width': '10rem'}, {'object-position': 'bottom'}, {'align-content': 'end' }, {'visibility': ((type <= 0) ? 'hidden' : 'visible') }, {'padding': '0.5rem 1rem'}, {'margin-top': '-0.25rem'}]" class="p-inputgroup-prepend" :onIcon="'pi pi-check'" :offIcon="'pi pi-times'" :onLabel="'CONNECTED'" :offLabel="'DISCONNECTED'"></pv-toggle>-->
                <!--<pv-button v-else  icon="fas fa-sharp fa-solid fa-error" label="'DISCONNECTED'" class="p-button-error"></pv-button>-->
            </template>
        </pv-grid-column>
        <pv-grid-column field="mask" headerStyle="min-width: 4rem; max-width: 4rem; width: 4rem" style="min-width: 4rem; max-width: 4rem; width: 4rem; border-left: 1px solid var(--surface-d); border-right: none; text-align: center; object-position: center; padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-d-flex" style="width: 2rem;">
                    <div class="p-col">
                        <i class="fa-solid "></i>
                        <pv-button :disabled="slotProps.data.status > 100" icon="fas fa-sharp fa-solid fa-key" class="p-button-warning" @click="showDeviceKey(slotProps.data)" v-tooltip.bottom="'Device Key'"></pv-button>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="mask" headerStyle="min-width: 7rem; max-width: 7rem; width: 7rem" style="`min-width: 7rem; max-width: 7rem; border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-d-flex" style="width: 2rem;">
                    <div class="p-col">
                        <pv-button :disabled="limitedAccess || slotProps.data.status > 100" icon="pi pi-pencil" iconPos="right" @click="onEdit(slotProps.data)" class="p-button-info" />
                    </div>
                    <div class="p-col">
                        <pv-button :disabled="limitedAccess || slotProps.data.status > 100" icon="pi pi-trash" iconPos="right" @click="onDelete(slotProps.data)" class="p-button-danger" />
                    </div>
                    <slot name="operation" :data="slotProps.data">
                    </slot>
                </div>
            </template>
        </pv-grid-column>
    </cosmos-grid>
</template>
<style>
</style>
<script>
    
    import { mapGetters } from 'vuex';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    const moduleName = 'companyprinter';
    /** CUSTOM VALIDATOR **/
    import { required, maxLength, helpers} from '@vuelidate/validators';
    const regex = (param) => helpers.withParams({ type: 'regex', value: param }, (value) => value.match(param) != null);
    
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        props: {
            formData: { type: Object },
        },
        data() {
            return {
                id: "printertemplate",
                deleteDisplay: ["code", "typeText"],
                forDelete: null,
                disconectDisplay: ["code", "name", "printer","macAddress"],
                forDisconnect: null,
                activity: { loading: true, processing: false },
                dialog: {
                    header: "Delete Record?",
                    add: false,
                    edit: false,
                    delete: false,
                    key: false,
                    disconnect: false,
                    application: false
                },
                source: {
                    total: 0,
                    data: []
                }
            }
        },
        watch: {
            v$: {
                handler() { this.modelState = this.v$ }, deep: true
            },
            'formData.mask': {
                handler() {
                    
                }, deep: true
            }
        },
        validations() {
            let validator = {
            };
            if (this.dialog.add || this.dialog.edit) {
                validator = {
                    model: {
                        code: { required, $autoDirty: true },
                        type: { required,$autoDirty: true },
                      

                    }
                }
                if ((this.model.code ?? "").length > 0)
                    validator.model.code = { required, maxLength: maxLength(30), $autoDirty: true, regex: helpers.withMessage(`Invalid Code`, regex('^[a-zA-Z0-9#_./\\|-]{0,30}$')) };
            }
            return validator;
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            limitedAccess() {
                return ((this.user?.userType ?? 0) === this.userTypes.ENVIRONMENT_USER) || ((this.user?.userType ?? 0) === this.userTypes.CUSTOMER_USER) || ((this.user?.userType ?? 0) === this.userTypes.RETAILER_USER);
            },
            disconnectAccess() {
                return ((this.user?.userType ?? 0) === this.userTypes.GLOBAL_ADMINISTRATOR) || ((this.user?.userType ?? 0) === this.userTypes.SYSTEM_ADMINISTRATOR) || ((this.user?.userType ?? 0) === this.userTypes.ENVIRONMENT_ADMINISTRATOR);
            },
            userTypes() {
                return {
                    GLOBAL_ADMINISTRATOR: 0,
                    SYSTEM_ADMINISTRATOR: 2,
                    ENVIRONMENT_ADMINISTRATOR: 3,
                    ENVIRONMENT_USER: 4,
                    CUSTOMER_ADMINISTRATOR: 5,
                    CUSTOMER_USER: 6,
                    RETAILER_USER: 7
                }
            },
            printerRecord() { return this.$store.getters["companyprinter/record"]; },
            printerErrors() { return this.$store.getters["companyprinter/errors"]; },
            osBuild() {
                if (navigator.userAgent.indexOf("WOW64") != -1 ||
                    navigator.userAgent.indexOf("Win64") != -1) {
                    return "x64";
                } else {
                    return "x86";
                }
            },
            osType() {
                var OSName = "Unknown OS";
                if (navigator.appVersion.indexOf("Win") != -1) OSName = "WINDOWS"; 
                if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MACOS"; 
                if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX"; 
                if (navigator.appVersion.indexOf("Linux") != -1) OSName = "LINUX"; 
                return OSName;
            }
        },
        methods: {
            async update(modulename, model) { return await this.$store.dispatch(modulename + "/modifyRecord", model); },
            async insert(modulename, model) { return await this.$store.dispatch(modulename + "/addRecord", model); },
            async delete(modulename, mask) { return await this.$store.dispatch(modulename + "/deleteRecordByMask", mask); },
            async getEntity(modulename, mask) { return await this.$store.dispatch(modulename + "/getRecord", mask); },
            async getSource() {
                if (this.$props.formData?.id > 0) {
                    await this.$axios
                        .get(this.$config.config.endpoint.api + `/services/settings/` + moduleName + `/company/${this.$props.formData?.mask}`.replaceAll("//", "/"))
                        //.get(config.endpoint.api + `/services/settings/` + moduleName + ``.replaceAll("//", "/"))
                        .then((response) => {
                            this.source.data = response.data.result;
                            this.source.total = response.data.result.length;
                        })
                        .catch((ex) => { this.errors = ex.data; });
                }
            },
            showDeviceKey(e) { 
                this.model = this.$filters.init(e);
                this.dialog.key = true;
            },
            onCreate() {
                this.model = {
                    id:0,
                    temporaryID: Number(Math.random().toString().replace(".", "")),
                    companyID : this.$props.formData.id,
                    code : null,
                    type: 1,
                    environmentID: this.$props.formData.environmentID,
                    tenantID: this.$props.formData.tenantID
                };
                this.dialog.delete = true;
                this.dialog.add = true;
            },
            onEdit(e) {
                this.model = this.$filters.init(e);
                this.dialog.delete = true;
                this.dialog.edit = true;
            },
            onDelete(data) {
                this.model = this.$filters.init(data);
                this.dialog.delete = true;
                this.forDelete = this.source.data.filter(x => this.model.mask != null ? x.id === this.model.id : x.temporaryID === this.model.temporaryID)[0];
            },
            onManage(e) {
                var location = this.$filters.init(e);
                var index = -1;
                if (location.id > 0)
                    index = this.source.data.findIndex(x => x.id === location.id);
                else
                    index = this.source.data.findIndex(x => x.temporaryID === location.temporaryID);
                if (index === -1) {
                    if (this.$route.params.mask ?? this.formData.mask) {
                        this.insert("companyprinter", this.model).then(() => {
                            if (this.printerErrors)
                                this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: this.printerErrors[0].message, life: 3000 });
                            else {
                                this.source.data.push(this.$filters.init(e));
                                index = this.source.data.findIndex(x => x.temporaryID === location.temporaryID);
                                this.getEntity("companyprinter", this.printerRecord.mask).then(() => {
                                    this.source.data[index] = this.$filters.init(this.printerRecord);
                                    this.source.total = this.source.data.length;
                                });
                            }
                        }).catch((err) => {
                            this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: err.xhr, life: 3000 });
                        }).finally(() => {
                            this.source.total = this.source.data.length;
                        });
                    }
                }
                else {
                    if (this.dialog.edit || this.dialog.add) {
                        if (location.mask !== null && location.mask !== "") {
                            this.update("companyprinter", this.model).then(() => {
                                if (this.printerErrors)
                                    this.$toast.add({ severity: 'error', summary: 'Record Update Failed!', detail: this.printerErrors[0].message, life: 3000 });
                                else
                                    this.source.data[index] = this.$filters.init(e);
                            }).catch((err) => {
                                err;
                                //this.$toast.add({ severity: 'error', summary: 'Record Update Failed!', detail: err.xhr, life: 3000 });
                            }).finally(() => {
                                this.source.total = this.source.data.length;
                            });
                        }
                    }
                    else {
                        if (location.mask !== null && location.mask !== "") {
                            this.delete("companyprinter", location.mask).then(() => {
                                if (this.printerErrors)
                                    this.$toast.add({ severity: 'error', summary: 'Record Delete Failed!', detail: this.printerErrors[0].message, life: 3000 });
                                else
                                    this.source.data.splice(index, 1);
                            }).catch((err) => {
                                this.$toast.add({ severity: 'error', summary: 'Record Delete Failed!', detail: err.xhr, life: 3000 });
                            }).finally(() => {
                                this.source.total = this.source.data.length;
                            });
                        }
                    }
                }
                this.model = {
                    id: 0,
                    temporaryID: null
                };
                this.dialog.delete = false;
                this.dialog.edit = false;
                this.dialog.add = false;
                this.dialog.header = "Delete Record?";
            },
            onLoadDisconnect(data) {
                if (data.isActive && this.disconnectAccess) {
                    this.model = this.$filters.init(data);
                    this.dialog.disconnect = true;
                    this.forDisconnect = this.source.data.filter(x => this.model.mask != null ? x.id === this.model.id : x.temporaryID === this.model.temporaryID)[0];
                }
                else if (data.isActive && !this.disconnectAccess) {
                    this.$toast.add({ severity: 'error', summary: 'Permission Denied', detail: "Disconnect printer is now allowed to your access", life: 5000 });
                }
                else {
                    this.$toast.add({ severity: 'info', summary: 'Printer Application', detail: "Connect the printer using the printer application", life: 5000 });
                }
            },
            onConfirmDisconnect() {
                if (this.activity.processing == false) {
                    this.activity.processing = true;
                    this.$axios.delete(this.$config.config.endpoint.api + `/api/application/printer/` + this.model.mask)
                        .then((response) => {
                            if (response.data.Success)
                            {
                                this.getEntity("companyprinter",this.model.mask).then(() => {
                                    let record = this.printerRecord;
                                    let location = this.$filters.init(record);
                                    let index = -1;
                                    index = this.source.data.findIndex(x => x.id === location.id);
                                    this.source.data[index] = this.$filters.init(location);
                                    this.model = null;
                                    this.dialog.disconnect = false;
                                    this.activity.processing = false;
                                });
                                
                                
                            }
                        });
                }
                
            }
        },
        created() {
            
        },
        mounted() {
            this.getSource();
        }

    }
</script>

<style>

    div.image-container > img {
        width: 75px !important;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        margin-right: 1rem;
    }
    .image-center {
        display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;
        width: 50% !important;
    }
</style>