import notfound from "@/components/shared/errors/error404.vue";
import unauthorized from "@/components/shared/errors/error401.vue";
import internalservererror from "@/components/shared/errors/error500.vue";
import Login from "@/components/account/login.vue";
import Dashboard from "@/views/home/dashboard-temporary.vue";
import ObjectTester from "@/views/home/component-custom.vue";
import Activate from "@/components/account/activate.vue";



/*** SETTINGS ***/
// Service Provider
import ServiceProviderGrid from "@/views/settings/provider/grid.vue";
import ServiceProviderRecord from "@/views/settings/provider/record.vue";
// Environment
import ContractGrid from "@/views/settings/defaults/contract/grid.vue";
import ContractRecord from "@/views/settings/defaults/contract/record.vue";
// Tenants
import TenantGrid from "@/views/settings/defaults/tenant/grid.vue";
import TenantRecord from "@/views/settings/defaults/tenant/record.vue";
// Companies
import CompanyGrid from "@/views/settings/defaults/company/grid.vue";
import CompanyRecord from "@/views/settings/defaults/company/record.vue";
// Users
import UserGrid from "@/views/settings/defaults/user/grid.vue";
import UserRecord from "@/views/settings/defaults/user/record.vue";
import UserProfile from "@/views/settings/defaults/user/profile.vue";
// Deleted User
import DeletedUserGrid from "@/views/settings/defaults/user/deleted.vue";

// Custom Messags
import CustomMessageGrid from "@/views/settings/custommessage/grid.vue";
import CustomMessageRecord from "@/views/settings/custommessage/record.vue";

/********** TRANSACTIONS **********/
// Consignment
//import ConsignmentGrid from "@/views/transaction/consignment/grid.vue";
//import ConsignmentRecord from "@/views/transaction/consignment/record.vue";
import ConsignmentEdit from "@/views/transaction/consignment/edit-form.vue";
import ConsignmentCodeEdit from "@/views/transaction/consignment/edit-form-code.vue";
import ConsignmentGridREI from "@/views/transaction/consignment/grid-form-rei.vue";
// V2
import ConsignmentTrack from "@/views/transaction/consignment/track.vue";
import ConsignmentGridV2 from "@/views/transaction/consignment/grid-form.vue";
import ConsignmentRecordV2 from "@/views/transaction/consignment/v2/record.vue";
import ConsignmentAddressStep1 from "@/views/transaction/consignment/v2/addressstep1.vue";
//import ConsignmentCollectionStep1 from "@/views/transaction/consignment/v2/collectionstep1.vue";
//import ConsignmentRecipientStep2 from "@/views/transaction/consignment/v2/recipientstep2.vue";
import ConsignmentPackageStep3 from "@/views/transaction/consignment/v2/packagestep3.vue";
import ConsignmentInternationalStep4 from "@/views/transaction/consignment/v2/internationalstep4.vue";
import ConsignmentAllocationStep5 from "@/views/transaction/consignment/v2/allocationstep5.vue";
import ConsignmentSummaryStep6 from "@/views/transaction/consignment/v2/summarystep6.vue";
// Collection
import CollectionGrid from "@/views/transaction/collection/grid.vue";
import CollectionGridV2 from "@/views/transaction/collection/grid-form.vue";
import CollectionRecord from "@/views/transaction/collection/record.vue";
// Return
import ReturnRecord from "@/views/transaction/return/record.vue";




/********** REPORTS **********/
import ReportConsignmentReport from "@/views/report/consignment/report.vue";
import ReportConsignmentReport_V2 from "@/views/report/consignment/v2/report.vue";
import ReportConsignmentSummary from "@/views/report/consignment/v2/summary.vue";
import ReportReturnsReport from "@/views/report/consignment/returns.vue";
import ReportKPIByPackage from "@/views/report/kpi/bypackage.vue";
import ReportKPIByPackageCountry from "@/views/report/kpi/bypackagecountry.vue";



/********** FACILITIES **********/
// Rate Engine
import ProviderZoneGrid from "@/views/settings/zone/grid.vue";
import ProviderZoneRecord from "@/views/settings/zone/record.vue";
import ProviderZoneCreate from "@/views/settings/zone/create.vue";
import ProviderZoneSTEP1 from "@/views/settings/zone/zone.vue";
import ProviderZoneSTEP2 from "@/views/settings/zone/zonename.vue";
import ProviderZoneSTEP3 from "@/views/settings/zone/zonegroup.vue";
import ProviderZoneSTEP4 from "@/views/settings/zone/zonematrix.vue";
import ProviderZoneSTEP5 from "@/views/settings/zone/confirmation.vue";
import TimeInTransitGrid from "@/views/settings/timeintransit/grid.vue";
import SurchargeGrid from "@/views/settings/surcharge/grid.vue";
import ContractRateGrid from "@/views/facility/rates/contract/grid.vue";
import QuoteTerminal from "@/views/facility/rates/quote/quote.vue";
import MarkupRateGrid from "@/views/facility/rates/markup/grid.vue";
import SurchargeRateGrid from "@/views/facility/rates/surcharge/grid.vue";
import ExchangeRateGrid from "@/views/facility/rates/forex/grid.vue";
// Rule Engine
import ServiceGroupGrid from "@/views/facility/ruleengine/servicegroup/grid.vue";
import ServiceGroupRecord from "@/views/facility/ruleengine/servicegroup/record.vue";
import ServiceGroupEdit from "@/views/facility/ruleengine/servicegroup/edit.vue";
import ServiceGroupStep1 from "@/views/facility/ruleengine/servicegroup/step1.vue";
import ServiceGroupStep2 from "@/views/facility/ruleengine/servicegroup/step2.vue";
import ServiceGroupStep3 from "@/views/facility/ruleengine/servicegroup/step3.vue";
import ServiceGroupStep4 from "@/views/facility/ruleengine/servicegroup/step4.vue";
import ServiceGroupSimulate from "@/views/facility/ruleengine/servicegroup/simulate.vue";
import RuleEngineTradeBlocGrid from "@/views/facility/ruleengine/tradebloc/grid.vue";
import RuleEngineTradeBlocRecord from "@/views/facility/ruleengine/tradebloc/record.vue";
import RuleEnginePostalBlocGrid from "@/views/facility/ruleengine/postalbloc/grid.vue";
import RuleEnginePostalBlocRecord from "@/views/facility/ruleengine/postalbloc/record.vue";
import RuleEngineHolidayGrid from "@/views/facility/ruleengine/holiday/grid.vue";
import RuleEngineHolidayRecord from "@/views/facility/ruleengine/holiday/record.vue";
// INVOICE ENGINE
import InvoiceGrid from "@/views/facility/invoice/grid.vue";
import InvoiceRecord from "@/views/facility/invoice/record.vue";
import InvoiceRecord2 from "@/views/facility/invoice/v2/record.vue";
import InvoiceView from "@/views/facility/invoice/edit-form.vue";
import InvoiceReconcileGrid from "@/views/facility/invoice/reconcile/grid.vue";
// IMPORT ENGINE
import ImportProfileGrid from "@/views/facility/import/profile/grid.vue";
import ImportProfileRecord from "@/views/facility/import/profile/record.vue";
import ImportRequestGrid from "@/views/facility/import/request/grid.vue";
// DYNAMIC REPORTS
import DynamicReportGrid from "@/views/facility/report/grid.vue";
import DynamicReportCreate from "@/views/facility/report/create.vue";
import DynamicReportRecord from "@/views/facility/report/record.vue";
import DynamicReportSTEP1 from "@/views/facility/report/steps/field-selection.vue";
import DynamicReportSTEP2 from "@/views/facility/report/steps/customization.vue";
import DynamicReportSTEP3 from "@/views/facility/report/steps/configuration.vue";
import DynamicReportSTEP4 from "@/views/facility/report/steps/confirmation.vue";
// DYNAMIC REPORTS
import StoreGrid from "@/views/facility/ecommerce/store/grid.vue";
import StoreCreate from "@/views/facility/ecommerce/store/create.vue";
import StoreRecord from "@/views/facility/ecommerce/store/record.vue";
import StoreOrder from "@/views/facility/ecommerce/store/order.vue";
import StoreProduct from "@/views/facility/ecommerce/store/product.vue";
import StoreSTEP1 from "@/views/facility/ecommerce/store/steps/platform-info.vue";
import StoreSTEP2 from "@/views/facility/ecommerce/store/steps/shop-info.vue";
import StoreSTEP3 from "@/views/facility/ecommerce/store/steps/basic-info.vue";
import StoreSTEP4 from "@/views/facility/ecommerce/store/steps/summary.vue";


import SalesOrderGrid from "@/views/facility/ecommerce/salesorder/grid.vue";
import SalesOrderRecord from "@/views/facility/ecommerce/salesorder/record.vue";




// AUDIT LOGS
import AuditLogGrid from "@/views/facility/auditlog/grid.vue";

// PRINT JOBS
import PrintJobGrid from "@/views/facility/printjob/grid.vue";

// SEARCH
import SearchGrid from "@/views/facility/search/search.vue";

/********** ADMIN **********/
// Address Book
import AddressBookGrid from "@/views/admin/addressbook/grid.vue";
import AddressBookRecord from "@/views/admin/addressbook/record.vue";
// Accounts
import ProviderAccountGrid from "@/views/admin/provideraccount/grid.vue";
import ProviderAccountRecord from "@/views/admin/provideraccount/record.vue";
import ProviderAccountCreate from "@/views/admin/provideraccount/create.vue";
import ProviderAccountSTEP1 from "@/views/admin/provideraccount/credential.vue";
import ProviderAccountSTEP2 from "@/views/admin/provideraccount/product.vue";
import ProviderAccountSTEP3 from "@/views/admin/provideraccount/postinglocation.vue";
import ProviderAccountSTEP4 from "@/views/admin/provideraccount/customer.vue";
import ProviderAccountSTEP5 from "@/views/admin/provideraccount/confirm.vue";
// Conversion Matrix
import ConversionMatrixGrid from "@/views/admin/conversionmatrix/grid.vue";




export default {
    notfound,
    unauthorized,
    internalservererror,
    Activate,
    Login,
    Dashboard,
    ObjectTester,




    /*** SETTINGS ***/
    ServiceProviderGrid,
    ServiceProviderRecord,
    // Tenant
    TenantGrid,
    TenantRecord,
    // Environment
    ContractGrid,
    ContractRecord,
    // Company
    CompanyGrid,
    CompanyRecord,
    // User
    UserGrid,
    UserRecord,
    UserProfile,
    // DeletedUser
    DeletedUserGrid,

    // Custom Messags
    CustomMessageGrid,
    CustomMessageRecord,

    /********** TRANSACTIONS **********/
    //ConsignmentGrid,
    //ConsignmentRecord,
    ConsignmentEdit,
    ConsignmentCodeEdit,
    ConsignmentGridREI,
    //V2 Consignment
    ConsignmentTrack,
    ConsignmentGridV2,
    ConsignmentRecordV2,
    ConsignmentAddressStep1,
    //ConsignmentCollectionStep1,
    //ConsignmentRecipientStep2,
    ConsignmentPackageStep3,
    ConsignmentInternationalStep4,
    ConsignmentAllocationStep5,
    ConsignmentSummaryStep6,
    // Collection
    CollectionGrid,
    CollectionGridV2,
    CollectionRecord,
    // Return
    ReturnRecord,


    

    /********** FACILITY **********/
    // Service Provider Zone
    ProviderZoneGrid,
    ProviderZoneRecord,
    ProviderZoneCreate,
    ProviderZoneSTEP1,
    ProviderZoneSTEP2,
    ProviderZoneSTEP3,
    ProviderZoneSTEP4,
    ProviderZoneSTEP5,
    // Rate Facility
    TimeInTransitGrid,
    SurchargeGrid,
    ExchangeRateGrid,
    ContractRateGrid,
    MarkupRateGrid,
    SurchargeRateGrid,
    QuoteTerminal,
    // Rule Engine
    ServiceGroupGrid,
    ServiceGroupRecord,
    ServiceGroupEdit,
    ServiceGroupStep1,
    ServiceGroupStep2,
    ServiceGroupStep3,
    ServiceGroupStep4,
    ServiceGroupSimulate,
    RuleEngineTradeBlocGrid,
    RuleEngineTradeBlocRecord,
    RuleEnginePostalBlocGrid,
    RuleEnginePostalBlocRecord,
    RuleEngineHolidayGrid,
    RuleEngineHolidayRecord,
    // Invoice Engine
    InvoiceGrid,
    InvoiceRecord,
    InvoiceRecord2,
    InvoiceView,
    InvoiceReconcileGrid,
    // Import Facility
    ImportProfileGrid,
    ImportProfileRecord,
    ImportRequestGrid,
    // Dynamic Report
    DynamicReportGrid,
    DynamicReportCreate,
    DynamicReportRecord,
    DynamicReportSTEP1,
    DynamicReportSTEP2,
    DynamicReportSTEP3,
    DynamicReportSTEP4,
    // Store
    StoreGrid,
    StoreOrder,
    StoreProduct,
    StoreCreate,
    StoreRecord,
    StoreSTEP1,
    StoreSTEP2,
    StoreSTEP3,
    StoreSTEP4,
    // Store Sales Order
    SalesOrderGrid,
    SalesOrderRecord,
    // Audit Logs
    AuditLogGrid,
    //Print Job,
    PrintJobGrid,
    // Search
    SearchGrid,



    /********** REPORTS **********/
    ReportConsignmentReport,
    ReportConsignmentReport_V2,
    ReportConsignmentSummary,
    ReportReturnsReport,
    ReportKPIByPackage,
    ReportKPIByPackageCountry,



    /********** ADMIN **********/
    // Address Book
    AddressBookGrid,
    AddressBookRecord,
    // Carrier Accounts
    ProviderAccountGrid,
    ProviderAccountRecord,
    ProviderAccountCreate,
    ProviderAccountSTEP1,
    ProviderAccountSTEP2,
    ProviderAccountSTEP3,
    ProviderAccountSTEP4,
    ProviderAccountSTEP5,
    // Conversion Matrix
    ConversionMatrixGrid
}