var config = require("./cosmos-config.js").default;
export default {
    init(item) {
        let self = this;
        this.$schema = {};
        this.$model = {};
        this.$entities = {};
        this.$entities = this.$tools.httpClient("GET", "/services/common/entities");
        Object.keys(this.$entities).forEach((key) => {
            this.$tools.registerModel(item, key, self.$entities[key].model);
            this.$tools.registerSchema(item, key, self.$entities[key].schema);
        })
        this.$enums = this.$tools.httpClient("GET", "/services/common/entities");
    },
    $schema: {},
    $model: {},
    $entities: {},
    $enums: {},
    $tools: {
        registerSchema: (item, module, schema) => { item.$schema[module] = schema },
        registerModel: (item, module, model) => { item.$model[module] = model },
        httpClient: (method, url) => {
            var xmlHttp = new XMLHttpRequest();
            xmlHttp.open(method, url.startsWith("http") ? url : config.api + url.trim('/'), false);
            xmlHttp.send(null);
            return JSON.parse(xmlHttp.responseText);
        },
        dynamicSort: (property) => {
            let sortOrder = 1;
            if (property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return (a, b) => {
                /* next line works with strings and numbers, 
                 * and you may want to customize it to your needs
                 */
                let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        }
    }
}