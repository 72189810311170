<template>
    <pv-card>
        <template #content>
            <credential-form @is-sync="onSync($event)" @is-credential="credential = $event"/>
        </template>
        <template #footer>
            <div class="p-grid p-nogutter p-justify-between">
                <i></i>
                <pv-button :label="nextLabel" @click="nextPage()" :icon="nextIcon" :iconPos="nextPosition" :disabled="activity.loading || blockNext || model.serviceProviderID === 0" />
            </div>
        </template>
    </pv-card>
</template>
<script>
    import credentialform from '@/components/admin/provideraccount/credential-form.vue';
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from '@vuelidate/validators';
    export default {
        name: 'StepCredential',
        emits:["next-page"],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'credential-form': credentialform
        },
        props: {
            id: { type: String },
            integrationMask: { type: String }
        },
        data() {
            return {
                credentialFields: [],
                model: {
                    code: null,
                    name: null,
                    serviceProviderID: 0,
                    integrationID: 0,
                    tradeType: 0,
                    accountType: 200,
                    contractCountryID: null,
                    credential_01: null,
                    credential_02: null,
                    credential_03: null,
                    credential_04: null,
                    credential_05: null,
                    credential_06: null,
                    credential_07: null,
                    credential_08: null,
                    credential_09: null,
                    credential_10: null,
                    credential_11: null,
                    credential_12: null,
                    credential_13: null,
                    credential_14: null,
                    credential_15: null,
                    credential_16: null,
                    credential_17: null,
                    credential_18: null,
                    credential_19: null,
                    credential_20: null,
                    standardLabelType: null,
                    returnLabelType: null,
                    companyID: 0,
                    sharedKey: null,
                    sharedSecret: null,
                    environmentID: 0,
                    tenantID: null,
                    createUserID: null,
                    createDate: null,
                    updateUserID: null,
                    updateDate: null,
                    disableUserID: null,
                    disableDate: null,
                    deleteUserID: null,
                    deleteDate: null,
                    archiveUserID: null,
                    archiveDate: null,
                    recordStatus: 0,
                    id: 0,
                    id2: null,
                    mask: null
                },
                credential: {},
                activity: {
                    loading: false
                }
            }
        },
        validations() {
            return {
                model: {
                    accountType: { required, $autoDirty: true },
                    tradeType: { $autoDirty: true },
                    code: { required, minLength: minLength(1), maxLength: maxLength(255), $autoDirty: true },
                    name: { required, maxLength: maxLength(50), $autoDirty: true },
                    serviceProviderID: { required, $autoDirty: true },
                    integrationID: { required, $autoDirty: true },
                    contractCountryID: { required, $autoDirty: true },
                }
            }
        },
        computed: {
            nextIcon() {
                if (this.activity.loading)
                    return "pi pi-spin pi-spinner";
                return "pi pi-angle-right";
            },
            nextLabel() {
                if (this.activity.loading)
                    return "Processing...";
                return "Next";
            },
            nextPosition() {
                if (this.activity.loading)
                    return "left";
                return "right";
            },
            blockNext() {
                let block = false;
                if (this.model.code === null) return true;
                if (this.model.name === null) return true;
                if (this.model.serviceProviderID === null) return true;
                if (this.model.integrationID === null) return true;
                if (this.model.contractCountryID === null) return true;
                if (this.model.tradeType === 0) return true;

                this.credentialFields.forEach((credential) => {
                    if(credential.validation.required && this.model[this.$filters.decamelize(credential.field)] === null)
                        block = true;
                });
                return block;
            }
        },
        methods: {
            onSync(event) {
                this.credentialFields = event.fields;
                for (let field in event.model)
                    this.model[field] = event.model[field];
            },
            nextPage() {
                this.activity.loading = true;
                this.$axios.post(this.$config.config.endpoint.api + "/api/polaris/tools/credential/carrier", {
                    Request: {
                        AccountType: this.model.accountType == 200 ? "STAGING" : "PRODUCTION",
                        CarrierCode: this.model.serviceProviderCode,
                        IntegrationCode: this.model.integrationCode,
                        Country: this.model.contractCountry.code,
                        Currency: this.model.contractCurrency.code,
                        Credentials: this.credential
                    }
                })
                .then((response) => {
                    if (response.data.Status === "SUCCESS") {
                        this.submitted = true;
                        if (response.data.Response.MetaData != null) {
                            let keys = Object.keys(response.data.Response.MetaData);
                            for (let i = 0; i < keys.length; i++)
                                this.model[keys[i].toLowerCase()] = response.data.Response.MetaData[keys[i]];
                        }
                        this.$emit('next-page', { formData: this.model, pageIndex: 0 });
                    }
                    else {
                        let errorDetails = '';
                        response.data.Errors.forEach((x) => { errorDetails += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`; })
                        this.$toast.add({ severity: 'error', summary: 'Credential Validation', detail: errorDetails, life: 10000 });
                    }
                    this.activity.loading = false;
                })
                .catch(() => {
                    this.activity.loading = false;
                    this.$toast.add({ severity: 'error', summary: 'Request Failed', detail: "An error occured when submitting request. Please ensure that you have an active session.", life: 10000 });
                });
            }
        }
    }
</script>
