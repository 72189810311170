<template>
    <div>
        <pv-grid id="ProductDownloadGrid"
                 :value="source ?? []"
                 :totalRecords="(source ?? []).length ?? 0"
                 v-model:filters="filters"
                 v-model:selection="selected"
                 v-model:expandedRows="expandedRows"
                 :responsive="'scroll'"
                 :paginator="true"
                 responsiveLayout="scroll"
                 :paginatorPosition="'bottom'"
                 :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport'"
                 :pageLinkSize="5"
                 :rows="10"
                 :rowsPerPageOptions="[5, 10, 15, 25, 50, 100]"
                 :alwaysShowPaginator="true"
                 :currentPageReportTemplate="'Showing {first} - {last} of {totalRecords} records'"
                 :loading="activity.loading"
                 :globalFilterFields="['code', 'name', 'platform.sku', 'platform.gtin']"
                 autoLayout
                 showGridlines
                 stripedRows>
            <template #header>
                <div class="p-d-flex p-jc-between">
                    <div>
                        <pv-button @click="getSource" style="margin-right: 1rem; padding: 0.75rem;" label="Load Products" icon="fa fa-table fa-fw" :disabled="parameters.store === '00000000000000000000000000000000'" :loading="activity.loading"></pv-button>
                        <pv-button @click="onDownloadSelected" style="margin-right: 0.25rem; padding: 0.75rem;" label="Download Selected" v-if="(source ?? []).length > 0" icon="fa fa-download fa-fw" :disabled="parameters.store === '00000000000000000000000000000000' || (selected ?? []).length === 0" :loading="activity.downloading"></pv-button>
                        <pv-button @click="onDownloadAll" style="margin-right: 1rem; padding: 0.75rem;" label="Download All" v-if="(source ?? []).length > 0" icon="fa fa-download fa-fw" :disabled="parameters.store === '00000000000000000000000000000000'" :loading="activity.downloading"></pv-button>
                    </div>
                    <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                        <i class="pi pi-search" />
                        <pv-input v-model="filters['global'].value" placeholder="Keyword Search" style="width: 100%;" />
                    </span>
                </div>
            </template>
            <pv-grid-column expander headerStyle="max-width: 2rem;" style="max-width: 2rem;" />
            <pv-grid-column field="identity" selectionMode="multiple" headerStyle="width: 3rem" />
            <pv-grid-column field="platform.name" style="width: 100%; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
            <pv-grid-column field="platform.code" header="Code" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
            <pv-grid-column field="platform.status" header="Status" style="width: 14rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
            <pv-grid-column field="platform.publishStatus" header="Published Status" style="width: 14rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
            <pv-grid-column field="platform.createDate" header="Create Date" style="border-right: 2px solid var(--surface-d); width: 14rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
                <template #body="slotProps">
                    <div class="p-d-flex p-col-12" style="padding: 0;">
                        {{  $filters.toDateFullTimeDisplay(slotProps.data?.platform?.createDate)}}
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="system.processedDate" header="Processed Date" style="width: 14rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
                <template #body="slotProps">
                    <div class="p-d-flex p-col-12" style="padding: 0;">
                        {{  $filters.toDateFullTimeDisplay(slotProps.data?.system?.processedDate)}}
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="system.code" header="Published Status" style="width: 14rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
                <template #body="slotProps">
                    <div v-if="slotProps.data?.system?.code" class="p-d-flex p-col-12" style="padding: 0; font-weight: bold;">
                        <router-link :to="`/facility/store/product/${slotProps.data.system.mask}`">{{ slotProps.data.system.code }}</router-link>
                    </div>
                </template>
            </pv-grid-column>
            <template #expansion="slotProps">
                <div style="margin: -0.75rem; overflow-x: auto;">
                    <product-variant :source="slotProps.data.variants ?? []" @is-refresh="getSource" />
                </div>
            </template>
            <!-- Column Headers -->
            <pv-grid-column-group type="header">
                <pv-grid-row>
                    <pv-grid-column header="PLATFORM DATA" :colspan="7" headerStyle="padding-left: 7rem !important; border-right: 2px solid var(--surface-d); " />
                    <pv-grid-column header="COSMOS DATA" :colspan="3" />
                </pv-grid-row>
                <pv-grid-row>
                    <pv-grid-column headerStyle="max-width: 2rem;"></pv-grid-column>
                    <pv-grid-column field="identity" selectionMode="multiple" headerStyle="width: 3rem"></pv-grid-column>
                    <pv-grid-column header="Name" />
                    <pv-grid-column header="Code" headerStyle="min-width: 8rem" />
                    <pv-grid-column header="Status" headerStyle="min-width: 8rem" />
                    <pv-grid-column header="Publish" headerStyle="min-width: 8rem" />
                    <pv-grid-column header="Create Date" headerStyle="min-width: 8rem;border-right: 2px solid var(--surface-d); " />
                    <pv-grid-column header="Processed Date" headerStyle="min-width: 8rem" />
                    <pv-grid-column header="Code" headerStyle="min-width: 8rem" />
                </pv-grid-row>
            </pv-grid-column-group>
            <!-- Row Messages -->
            <template #empty>
                <span v-html="`No records found.`"></span>
            </template>
            <template #loading>
                Retrieving records from database. Please wait...
            </template>
            <template #paginatorRight>
                <pv-button type="button" icon="pi pi-refresh" class="p-button-text" @click="getSource" />
            </template>
        </pv-grid>
        <is-dialog id="download-form" :header="`DOWNLOAD ${dialog.downloadAll ? 'ALL' : 'SELECTED'} PRODUCTS`" :breakpoints="{'2500px': '50vw', '640px': '75vw'}" :visible="dialog.downloadAll || dialog.downloadSelected" @is-confirm="onConfirm(dialog.identities)" @is-cancel="dialog.downloadAll = dialog.downloadSelected = false" :confirmLoading="activity.downloading" :confirmDisabled="confirmDisabled">
            <p>Do you want to download <span style="font-weight: bold; color: var(--text-danger)">{{dialog.downloadAll ? 'ALL' : (dialog.identities ?? []).length }}</span> product{{ (dialog.identities ?? []).length > 1 ? 's' : '' }}?</p>
            <p style="font-weight:bold; color: var(--text-danger)">Please be aware that the request will be queued for download and may run in the background and should appear after some time.</p>
            <p>Do you still want to proceed with download?</p>
        </is-dialog>
    </div>
</template>
<script>
    import productVariant from '@/components/facility/ecommerce/downloadvariant-grid.vue';
    import { FilterMatchMode } from 'primevue/api';
    export default {
        name: 'ProductDownloadGrid',
        emits: ['is-loading'],
        components: {
            'product-variant': productVariant
        },
        props: {
            parameters: { type: Object, default: () => {} }
        },
        data() {
            return {
                filters: {
                    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    code: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'platform.sku': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'platform.gtin': { value: null, matchMode: FilterMatchMode.CONTAINS }
                },
                selected: null,
                expandedRows: null,
                source: [],
                dialog: {
                    downloadAll: false,
                    downloadSelected: false,
                    identities: []
                },
                activity: {
                    loading: true,
                    downloading: false
                },
            }
        },
        computed: {
            parameter() {
                return {
                    product_status: this.parameters?.filters?.status ?? "any",
                    published_status: this.parameters?.filters?.publishStatus ?? "any",
                    created_from: this.parameters.date.from,
                    created_to: this.parameters.date.to
                };
            },
        },
        watch: {
            'parameters': {
                handler() {
                    this.$nextTick(() => {
                        this.source = [];
                        this.$emit("is-reset");
                    });
                }, deep: true, immediate: true
            }
        },
        methods: {
            onConfirm(data) {
                this.activity.downloading = true;
                this.$store.dispatch("store/postURL", { url: `/services/facility/store/${this.parameters.store}/product/download`, payload: data })
                    .then((response) => {
                        if (response.success) {
                            this.$toast.add({ severity: 'success', summary: 'Download Product!', detail: response.result, life: 5000 });
                            setTimeout(() => { this.getSource(); }, 5000);
                        }
                        this.activity.downloading = false;
                        this.dialog.downloadAll = this.dialog.downloadSelected = false;
                        this.$emit("is-loading", this.activity.loading || this.activity.downloading);
                    })
                    .catch(() => {
                        this.activity.downloading = false;
                        this.dialog.downloadAll = this.dialog.downloadSelected = false;
                        this.$emit("is-loading", this.activity.loading || this.activity.downloading);
                    });
            },
            onDownloadAll() {
                this.dialog.identities = [... new Set((this.source ?? []).map(x => x.identity) ?? [])];
                this.dialog.downloadAll = true;
            },
            onDownloadSelected() {
                this.dialog.identities = [... new Set((this.selected ?? []).map(x => x.identity) ?? [])];
                this.dialog.downloadSelected = true;
            },
            getSource() {
                this.activity.loading = true;
                this.$emit("is-loading", this.activity.loading);
                this.$store.dispatch("store/postURL", { url: `/services/facility/store/${this.parameters.store}/products`, payload: this.parameter })
                    .then((response) => {
                        this.source = response?.result ?? [];
                        if (Array.isArray(this.source[0]))
                            this.source.splice(0, 1);
                        this.selected = null;
                        this.activity.loading = false;
                        this.activity.downloading = false;
                        this.dialog.downloadAll = this.dialog.downloadSelected = false;
                        this.$emit("is-loading", this.activity.loading || this.activity.downloading);
                    })
                    .catch(() => {
                        this.activity.loading = false;
                        this.$emit("is-loading", this.activity.loading || this.activity.downloading);
                    });
            }
        },
        async created() {
            this.activity.loading = false;
        },
        async mounted() {
            this.activity.loading = false;
        }
    }
</script>
<style>
    #download-form .p-dialog-content {
        overflow: hidden !important;
    }
</style>    