<template>
    <form-object :id="id" :autoFit="autoFit" :label="label" :floatLabel="floatLabel && (!disabled || objBlank)" :hideLabel="hideLabel" :v$="v$" :vID="vID" :vBase="vBase" :vError="vError" :required="required" :conditional="conditional">
        <template #default="slotProps">
            <pv-numeric v-if="!disabled && objType === 'currency'"
                        :ref="id"
                        :id="id"
                        :class="[{'p-fluid': autoFit}, {'p-invalid': slotProps.data}]"
                        :disabled="disabled"
                        :mode="'currency'"
                        :format="true"
                        :min="min"
                        :max="max"
                        :minFractionDigits="2"
                        :maxFractionDigits="2"
                        :useGrouping="useGrouping"
                        :showButtons="showButton && !disabled"
                        :buttonLayout="layout"
                        :incrementButtonIcon="plusIcon"
                        :incrementButtonClass="plusCSS"
                        :decrementButtonIcon="minusIcon"
                        :decrementButtonClass="minusCSS"
                        :locale="currentLocale"
                        :localeMatcher="localeMatcher"
                        :currency="currencyCode"
                        :currencyDisplay="currencyDisplay"
                        :prefix="prefixText"
                        :suffix="suffixText"
                        v-model="model.value"
                        :allowEmpty="false"
                        onfocus="this.select()" />
            <pv-numeric v-else-if="!disabled"
                        :ref="id"
                        :id="id"
                        :class="[{'p-fluid': autoFit}, {'p-invalid': slotProps.data}]"
                        :disabled="disabled"
                        :mode="'decimal'"
                        :format="true"
                        :min="min"
                        :max="max"
                        :minFractionDigits="decimalPart"
                        :maxFractionDigits="decimalPart"
                        :useGrouping="useGrouping"
                        :showButtons="showButton && !disabled"
                        :buttonLayout="layout"
                        :incrementButtonIcon="plusIcon"
                        :incrementButtonClass="plusCSS"
                        :decrementButtonIcon="minusIcon"
                        :decrementButtonClass="minusCSS"
                        :locale="currentLocale"
                        :localeMatcher="localeMatcher"
                        :prefix="prefixText"
                        :suffix="suffixText"
                        :allowEmpty="false"
                        v-model="model.value"
                        onfocus="this.select()" />
            <div v-if="disabled" :title="`${(model?.value ? model.value : '')}`" :id="id" class="p-component p-inputtext p-input-icon-right is-disabled" :style="[{ 'border-radius': 0 }, { 'border-right': (postLabel || postIcon) ? ((postLabel || postIcon) ? '0' : '1px solid inherit') : false }, { 'border-top-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-bottom-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-top-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'border-bottom-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'overflow': 'hidden' }, { 'text-overflow': 'ellipsis' }, { 'background': 'var(--surface-c)' }, { 'cursor': 'not-allowed' }, { 'white-space': 'nowrap' },{ 'text-align': 'right' }]">
                <span class="p-code-text" style="font-size: 0.9rem; font-weight: 600; white-space: nowrap; text-wrap: normal; overflow: hidden; text-overflow: ellipsis;" v-html="`${disabledText}`"></span>
                <i v-if="showCopy" class="pi pi-copy" style="cursor: pointer;" @click="onCopy"></i>
            </div>
        </template>
        <template #leftSide="slotProps">
            <slot name="prepend" data="slotProps">
                <span v-if="preIcon || preLabel" :class="[{'is-prepend': true}, {'p-inputgroup-addon': true}, {'p-invalid': slotProps.data}]" :style="[{'background-color': 'var(--surface-f)'}, {'font-weight': 500 }] ">
                    <i v-if="preIcon" :class="preIcon"></i>
                    <span v-if="preLabel" :class="[{'is-label': true}, {'required': model.state.required}, {'optional': model.state.optional}]" :style="preCSS" :title="preLabel ?? label ?? $filters.titleize(id)" v-html="preLabel"></span>
                </span>
            </slot>
        </template>
        <template #rightSide>
            <slot name="append">
                <span v-if="postIcon || postLabel" class="is-append p-inputgroup-addon" :style="[{'background-color': 'var(--surface-f)'}, {'font-weight': 500 }]">
                    <i v-if="postIcon" :class="postIcon"></i>
                    <span v-if="postLabel" class="is-label" :style="postCSS" :title="postLabel" v-html="postLabel"></span>
                </span>
            </slot>
        </template>
    </form-object>
</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/core-mixins.js';
    export default {
        name: "FormNumericField",
        mixins: [mixins.COMPONENT],
        props: {
            type: { type: String, default: "integer", validator: (value) => ['integer', 'decimal', 'currency'].indexOf(value.toLowerCase()) !== -1 },
            value: { type: Number, default: null },
            modelValue: { type: Number, default: null },
            decimalDigit: { type: Number, default: Number(2) },
            useGrouping: { type: Boolean, default: true },
            min: { type: Number, default: 0 },
            max: { type: Number, default: 999999999999.999999 },
            showButton: { tyep: Boolean, default: true },
            showCopy: { tyep: Boolean, default: true },
            layout: { type: String, default: 'stacked', validator: (value) => ['stacked', 'horizontal', 'vertical'].indexOf(value.toLowerCase()) !== -1 },
            plusIcon: { type: String, default: 'pi pi-angle-up' },
            minusIcon: { type: String, default: 'pi pi-angle-down' },
            plusCSS: { type: String },
            minusCSS: { type: String },
            locale: { type: String, default: null },
            localeMatcher: { type: String, default: 'best fit', validator: (value) => ['lookup', 'best fit'].indexOf(value.toLowerCase()) !== -1 },
            prefix: { type: String },
            suffix: { type: String },
            quantifiedSuffix: { type: String},
            /* Use Currency */
            currency: { type: String, validator: (value) => /^[a-zA-Z]{3}$/.test(value) },
            currencyDisplay: { type: String, default: 'symbol', validator: (value) => ['symbol', 'code', 'name'].indexOf(value.toLowerCase()) !== -1 }
        },
        computed: {
            decimalPart() {
                if (this.objType === "currency")
                    return 2;
                else if (this.objType === "integer")
                    return 0;
                else
                    return Number(this.decimalDigit);
            },
            prefixText() {
                if (this.prefix)
                    return this.prefix + ' ';
                return null;
            },
            suffixText() {
                if (this.suffix)
                    return ' ' + this.suffix;
                if (this.quantifiedSuffix)
                    return ' ' + this.$filters.quantifiedDisplay(this.quantifiedSuffix, this.model.value);
                return null;
            },
            currencyCode() {
                if (this.currency && /^[a-zA-Z]{3}$/.test(this.currency))
                    return this.currency.toUpperCase();
                return this.$vm.$location ? this.$vm.$location.currency : 'USD';
            },
            currencySymbol() {
                if (this.objType == "currency")
                    return this.$vm.$currencies.filter(x => x.code === this.currencyCode)[0]?.symbol;
                return null;
            },
            currentLocale() {
                if (this.locale)
                    return this.locale;
                return this.language;
            },
            disabledText() {
                return `${this.prefixText ?? ''} ${this.model?.value ? (this.currencySymbol ?? '') : ''}${(this.model?.value ? Number(Number(this.model?.value ?? 0).toFixed(this.decimalPart)).toLocaleString(undefined, { minimumFractionDigits: this.decimalPart, maximumFractionDigits: this.decimalPart }) : Number(0).toFixed(this.decimalPart))} ${this.suffixText ?? ''}`.replace('  ', ' ').trim()
            }
        },
        methods: {
            onSync() {
                let data = { value: this.model.value, text: null, record: null };
                data = {
                    value: this.model?.value ?? 0,
                    text: `${this.prefixText ?? ''} ${this.model?.value ? (this.currencySymbol ?? '') : ''}${(this.model?.value ? Number(Number(this.model?.value).toFixed(this.decimalPart)).toLocaleString(undefined, { minimumFractionDigits: this.decimalPart, maximumFractionDigits: this.decimalPart }) : '&nbsp;')} ${ this.suffixText ?? '' }`.replace('  ', ' ').trim()
                };
                this.model.value = data.value;
                this.model.text = data.text;
                this.model.record = {
                    text: data.text,
                    value: data.value
                };
                this.$emit("is-sync", data);
                this.$emit("update:modelValue", data.value);
            }
        },
        async created() {
            this.model.value = this.modelValue ?? this.value ?? 0;
            this.onSync();
        },
        mounted() {
            const kbButtons = document.querySelectorAll(".p-button.p-component");
            kbButtons.forEach(elem => {
                elem.setAttribute('tabindex', '-1')
            });
        }
    }
</script>
