<template>
    <div id="maintoolbar" class="is-toolbar p-d-flex p-jc-between" style="position:sticky !important; top:35px; width:100%; z-index:1;">
        <div class="p-d-flex">
            <pv-button v-if="viewBack" :label="Back" icon="pi pi-fw pi-arrow-left" :loading="loading.back" @click="onBack"></pv-button>
            <div v-if="viewBack">&nbsp;</div>
            <div class="p-buttonset">
                <pv-button :label="Save" v-if="!viewOnly" type="submit" icon="pi pi-fw pi-save" :loading="loading.save" :disabled="FormDirty" @click="onSave"></pv-button>
                <pv-button :label="Reset" v-if="!viewOnly" icon="pi pi-fw pi-refresh" :loading="loading.refresh" :disabled="FormDirty" @click="onRefresh"></pv-button>
                <pv-button v-if="identity && !saveOnly && showCloneButton" :label="Clone" icon="pi pi-fw pi-clone" :loading="loading.clone" :disabled="!FormDirty" @click="onClone"></pv-button>
                <pv-button v-if="identity && !viewOnly && !saveOnly" :label="Delete" icon="pi pi-fw pi-trash" :loading="loading.delete" @click="onDelete"></pv-button>
                <pv-button v-if="identity && !saveOnly && showSendButton" :label="Send" icon="pi pi-fw pi-send" :loading="loading.send" @click="onSend"></pv-button>
            </div>
        </div>
        <div class="p-buttonset">
            <div v-if="showGuideHelper" style="border: 1px solid var(--border-color); border-radius: 4px 4px 4px 4px; float:left; padding: 0; margin-right:5px; height:33px;">
                <span style="float:left; margin:8px 10px">
                    Guided Step?
                </span>
                <pv-toggle onLabel="Yes"
                           offLabel="No"
                           onIcon="pi pi-fw pi-check"
                           offIcon="pi pi-fw pi-times"
                           class="p-inputgroup-prepend"
                           :style="[{ 'padding':'0'  }, { 'border-radius': '4px' }, { 'width': '80px' }, { 'height': '28px !important' }]"
                           v-model="helper.guided"></pv-toggle>
            </div>
            <pv-splitbutton :label="FAQ" icon="pi pi-question-circle" @click="onFAQ" :model="Support"></pv-splitbutton>
        </div>
    </div>
    <is-dialog header="Leave Page?" :visible="dialog.dirty" @is-confirm="confirmLeave" @is-cancel="closeConfirmation">
        <div class="confirmation-content p-d-flex">
            <div>
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            </div>
            <div>
                <span>Changes you made may not be saved.<br /><br />Are you sure you want to leave?</span>
            </div>
        </div>
    </is-dialog>
    <is-dialog header="Delete Record?" :visible="dialog.delete" @is-confirm="confirmDelete" @is-cancel="this.dialog.delete = false">
        <div class="confirmation-content p-d-flex">
            <div>
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            </div>
            <div>
                You are about to delete this record, please be aware of the following:
                <ul>
                    <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                    <li>For records that has activity, the record will only be disabled and archived.</li>
                    <li>Also deleting a record may be irreversible action.</li>
                </ul>
                <br />Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
    <is-dialog header="Having Trouble?" :visible="dialog.support" @is-confirm="this.dialog.support = false" @is-cancel="this.dialog.support = false">
        <div class="confirmation-content p-d-flex">
            <div>
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            </div>
            <div>
                <span>Changes you made may not be saved.<br /><br />Are you sure you want to leave?</span>
            </div>
        </div>
    </is-dialog>
    <is-dialog header="Clone Record?" :visible="dialog.clone" @is-confirm="confirmClone" @is-cancel="{this.dialog.clone = false; this.clone = { code: null, name: null};}" :confirmDisabled="(clone?.name?.length ?? 0) === 0" >
        <div class="confirmation-content p-d-flex">
            <div class="p-col-12">
                <form-input @keyup="onClone_KeyupEvent" id="name" label="Reference" :required="true" :v$="v$" :value="clone.name" @is-sync="clone.name = $event.value" placeholder="Reference" v-focus :max-length="20" />
            </div>
        </div>
    </is-dialog>
</template>
<script>
    export default {
        name: 'OperationMenu',
        props: {
            identity: { type: String, default: null },
            viewOnly: { type: Boolean, default: false },
            back: { type: String, default: 'dashboard' },
            disabled: { type: Boolean, default: false },
            isDirty: { type: Boolean, default: false },
            saveOnly: { type: Boolean, default: false },
            viewBack: { type: Boolean, default: true },
            showGuideHelper: { type: Boolean, default: false },
            showCloneButton: { type: Boolean, default: false },
            showSendButton: { type: Boolean, default: false },

        },
        data() {
            return {
                width: 1000,
                module: {
                    name: null,
                    type: null
                },
                loading: {
                    back: false,
                    save: false,
                    refresh: false,
                    clone: false,
                    delete: false,
                    send:false
                },
                dialog: {
                    dirty: false,
                    support: false,
                    delete: false,
                    clone: false,
                },
                support: {
                    url: null,
                    module: null,
                    category: null,
                    details: null,
                    attachment: null
                },
                helper: {
                    guided: true
                },
                clone: {
                    code: null,
                    name: null
                }
            }
        },
        watch: {
            "helper.guided": {
                handler() {
                    try {
                        this.onGuided();
                    }
                    catch { /* This is catch */ }
                },
                deep: true
            }
        },
        computed: {
            record() { return this.$store.getters[this.module.name + "/record"]; },
            errors() { return this.$store.getters[this.module.name + "/error"]; },
            FormDirty() {
                return !this.isDirty;
            },
            Back() {
                if (this.width > 450)
                    return "Back";
                return null;
            },
            Save() {
                if (this.width > 450)
                    return this.identity ? "Update" : "Save";
                return null;
            },
            Reset() {
                if (this.width > 450)
                    return "Reset";
                return null;
            },
            Clone() {
                if (this.width > 450)
                    return "Clone";
                return null;
            },
            Delete() {
                if (this.width > 450)
                    return "Delete";
                return null;
            },
            Send() {
                if (this.width > 450)
                    return "Send";
                return null;
            },
            FAQ() {
                if (this.width > 450)
                    return "FAQ";
                return null;
            },
            Support() {
                return [{
                    label: 'Support',
                    icon: 'pi pi-fw pi-ticket',
                    command: () => {
                        this.showSupport = true;
                    }
                }]

            }
        },
        methods: {
            async delete(mask) { return await this.$store.dispatch(this.module.name + "/removeRecord", mask); },
            async cloneRecord(mask, model) { return await this.$store.dispatch(this.module.name + "/cloneRecord", {mask : mask, record : model }); },
            closeConfirmation() {
                this.dialog.dirty = false;
            },
            confirmLeave() {
                this.$router.push({ name: (this.module.type ? this.module.type : this.module.name) + '-index' })
            },
            onBack() {
                this.module.name = this.$router.currentRoute.value.meta.module;
                this.module.type = this.$router.currentRoute.value.meta.type;
                this.dialog.dirty = true;
                if (!this.FormDirty)
                    this.dialog.dirty = true;
                else
                    this.$router.push({ name: (this.module.type ? this.module.type : this.module.name) + '-index' })
            },
            onSave() {
                this.loading.save = true;
                setTimeout(() => this.loading.save = false, 200, this);
            },
            onRefresh() {
                this.loading.refresh = true;
                this.$emit("is-reset");
                setTimeout(() => this.loading.refresh = false, 200, this);
            },
            onClone() {
                //this.$toast.add({ severity: 'warn', summary: 'Under Development', detail: 'This module is under development', life: 3000 });
                this.dialog.clone = true;
                //this.loading.clone = true;
                //this.$emit("is-clone");
                //setTimeout(() => this.loading.clone = false, 200, this);
            },
            onClone_KeyupEvent(e) {
                if (e.keyCode === 13 && (this.clone?.name?.length ?? 0) > 0) {
                    this.confirmClone();
                }
                if (e.keyCode === 27) {
                    { this.dialog.clone = false; this.clone = { code: null, name: null }; }
                }
            },
            onDelete() {
                this.loading.delete = true;
                this.dialog.delete = true;
                setTimeout(() => this.loading.delete = false, 200, this);
            },
            onSend() {
                this.loading.send = true;
                this.$emit("is-send");
                setTimeout(() => this.loading.send = false, 200, this);
            },
            confirmDelete() {
                this.delete(this.$route.params.mask).then(() => {
                    if (!this.errors) {
                        this.$router.push({ name: (this.module.type ? this.module.type : this.module.name) + '-index' });
                        this.$toast.add({ severity: 'success', summary: 'Record Delete', detail: 'Record Delete Successful', life: 3000 });
                    }
                }).catch(() => {
                    this.$toast.add({ severity: 'error', summary: 'Record Delete', detail: 'Record Delete Failed', life: 3000 });
                });
                this.dialog.delete = false
            },
            confirmClone() {
                this.cloneRecord(this.$route.params.mask, this.clone).then((response) => {
                    if (response.errors)
                        this.$toast.add({ severity: 'error', summary: 'Record Clone Failed!', detail: response.errors[0].message, life: 3000 });
                    else {
                        if (response?.data?.result ?? response.result) {
                            this.$emit("is-clone", response?.data?.result ?? response.result);
                        }
                        else {
                            this.$toast.add({ severity: 'error', summary: 'Record Clone Failed!', detail: 'Not Allowed', life: 3000 });
                        }
                        this.dialog.clone = false;
                        this.clone = {
                            code: null,
                            name: null
                        };
                    }
                }).catch(() => {
                    this.$toast.add({ severity: 'error', summary: 'Record Clone Failed!', detail: 'Record Clone Failed', life: 3000 });
                });
                this.dialog.clone = false
            },
            onFAQ() {
                this.$toast.add({ severity: 'success', summary: 'Updated', detail: 'FAQ Executed', life: 3000 });
            },
            onGuided() {
                this.$emit("is-guided", this.helper.guided);
            },
            resizing() {
                this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            }
        },
        created() {
            this.module.name = this.$router.currentRoute.value.meta.module;
            this.module.type = this.$router.currentRoute.value.meta.type;
            this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            window.addEventListener("resize", this.resizing);
        },
        updated() {
            this.module.name = this.$router.currentRoute.value.meta.module;
            this.module.type = this.$router.currentRoute.value.meta.type;
        },
        mounted() {
            this.$nextTick(() => {
                if (this.showGuideHelper) {
                    this.onGuided();
                }
            });
        },
        beforeUnmount() {
            window.removeEventListener("resize", this.resizing);
        }
    }
</script>
<style>
    #maintoolbar .p-buttonset button.p-button:only-child {border-radius: 4px;}
</style>