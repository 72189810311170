<template>
    <form-wrapper title="Contract Rates" sub-title="List of available carrier lead time. If not provided, the lead time will be assigned automatically on the shipment based on carrier capability services.">
        <cosmos-grid id="ContractProviderRates" :multi-level="true" :auto-generate="false" :base-record-ID="0" :show-operation="false" @is-source="sources.ratecards = $event.data" :force-refresh="activity.refresh">
            <template #toolbar>
                <div class="p-d-flex p-jc-between p-ai-center">
                    <span>
                        <pv-button v-if="accounts.length > 1 || (accounts.length === 1 && accounts[0]?.id != model?.accountDetail.id)" icon="pi pi-fw pi-plus" label="Add New Record" style="margin-right: 5px;" @click="onCreate"></pv-button>
                    </span>
                    <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                        <i class="pi pi-search" />
                        <pv-input placeholder="Keyword Search" style="width: 100%;" />
                    </span>
                </div>
            </template>
            <pv-grid-column field="serviceProvider.code" header="Code" headerStyle="width: 10rem; max-width: 10rem; padding-left: 15px; padding-top: .8rem !important;" style="min-width: 10rem; width: 10rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
            <pv-grid-column field="accountDetail.name" header="Account Name" />
            <pv-grid-column field="accountNumber" header="Account Number" headerStyle="width: 10rem; max-width: 10rem; padding-left: 15px; padding-top: .8rem !important;" style="min-width: 20rem; width: 10rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
            <pv-grid-column field="contractCountry.name" header="Contract Country" headerStyle="width: 10rem; max-width: 10rem; padding-left: 15px; padding-top: .8rem !important;" style="min-width: 20rem; width: 10rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
                <template #body="slotProps">
                    <div><i :class="`flag flag-${slotProps.data?.contractCountry?.code?.toLowerCase()}`"></i> &nbsp; {{ slotProps.data?.contractCountry?.name }}</div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="contractCurrency.name" header="Currency" headerStyle="width: 10rem; max-width: 10rem; padding-left: 15px; padding-top: .8rem !important;" style="min-width: 20rem; width: 10rem !important; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
                <template #body="slotProps">
                    <div>[<strong>{{slotProps.data?.contractCurrency?.code}}</strong>] {{ slotProps.data?.contractCurrency?.name }}</div>
                </template>
            </pv-grid-column>
            <!-- Operation Column -->
            <pv-grid-column field="mask" headerStyle="min-width: 7rem; max-width: 7; width: 7rem" style="width: 7rem; border-left: 0; padding: 0 !important;" :frozen="true" :alignFrozen="'right'">
                <template #body="slotProps">
                    <div class="p-d-flex" style="max-width: 7rem; min-width: 7rem; margin: inherit 0;">
                        <div class="p-col">
                            <pv-button icon="pi pi-pencil" iconPos="right" class="p-button-info" @click="onEdit(slotProps.data)" />
                        </div>
                        <div class="p-col">
                            <pv-button :disabled="slotProps.data.recordStatus !== 0 || slotProps.data.id < baseRecordID || (slotProps.data?.viewOnly ?? false)" icon="pi pi-trash" iconPos="right" class="p-button-danger" @click="onDelete(slotProps.data)" />
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <!-- Expansion Template -->
            <template #expansion="slotProps">
                <effectivedate-grid :parentID="slotProps.data?.id"
                                    :providerID="slotProps.data?.serviceProvider?.id"
                                    :accountID="slotProps.data?.accountDetail?.id"
                                    :currencyID="slotProps.data?.contractCurrency?.id"
                                    :currencyCode="slotProps.data?.contractCurrency?.code"
                                    :countryID="slotProps.data?.contractCountry?.id"
                                    :countryCode="slotProps.data?.contractCountry?.code"
                                    :tradeType="slotProps.data?.tradeType"
                                    />
            </template>
        </cosmos-grid>
    </form-wrapper>
    <keep-alive>
        <is-dialog header="Delete Record?" :visible="dialog.delete" @is-confirm="onConfirmDelete(model.mask)" @is-cancel="onCancel()">
            <div class="confirmation-content p-d-flex">
                <div><i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" /></div>
                <div>
                    You are about to delete this record:
                    <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                        <tr v-for="data in deleteDisplayFields" :key="data.id">
                            <th style="text-align:left;">{{ $filters.titleize(data.indexOf('.') !== -1 ? data.split('.')[1] : data) }}</th>
                            <td>:</td>
                            <td> {{ data.indexOf('.') !== -1 ? model[data.split('.')[0]][data.split('.')[1]] : model[data] }}</td>
                        </tr>
                    </table>
                    Please be aware of the following:
                    <ul>
                        <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                        <li>For records that has activity, the record will only be disabled and archived.</li>
                        <li>Also deleting a record may be irreversible action.</li>
                    </ul>
                    Are you sure you still want to proceed?
                </div>
            </div>
        </is-dialog>
    </keep-alive>
    <keep-alive>
        <is-dialog :header="(model?.id ?? 0) === 0 ? 'Add New Record' : 'Edit Record'" style="width: 25vw; min-width: 400px;" :visible="dialog.edit" @is-confirm="onConfirmEdit(model)" @is-cancel="onCancel()" :confirmDisabled="confirmDisabled">
            <div class="confirmation-content p-d-flex">
                <div class="p-col p-md-12">
                    <form-lookup id="providerAccount" label="Provider Account" :source="accounts" sourceType="array" :value="model?.providerAccountID ?? model?.accountDetail?.id" @is-sync="model.providerAccountID = $event.value" :required="true" />
                </div>
            </div>
        </is-dialog>
    </keep-alive>
</template>
<script>
    import effectiveGrid from '@/components/facility/rates/contract/effectivedate-grid.vue';
    export default {
        name: "ShipperContractGrid",
        components: {
            'effectivedate-grid': effectiveGrid
        },
        data() {
            return {
                deleteDisplayFields: ['serviceProvider.code', 'accountDetail.name'],
                model: null,
                sources: {
                    accounts: [],
                    ratecards: []
                },
                activity: {
                    refresh: false
                },
                dialog: {
                    edit: false,
                    delete: false
                }
            }
        },
        computed: {
            dataSource() { return this.sources.ratecards; },
            accounts() { return this.sources.accounts.filter(x => (this.sources.ratecards.findIndex(y => y.accountDetail?.id === x.id) === -1 && x.serviceProviderID === (this.model?.serviceProvider?.id ?? x.serviceProviderID)) || x.id === this.model?.accountDetail?.id); }
        },
        methods: {
            onCreate() {
                this.model = {
                    serviceProviderID: null,
                    providerCarrierID: null,
                    accountID: -1000
                };
                this.dialog.edit = true;
            },
            onCancel() {
                this.model = null;
                this.dialog.edit = false;
                this.dialog.delete = false;
            },
            onEdit(e) {
                this.model = this.$filters.init(e);
                this.dialog.edit = true;
            },
            onConfirmEdit(e) {
                var model = this.$filters.init(e);
                if (model.id >= 0) {
                    this.$store.dispatch("providercontract/updateRecord", model).then((response) => {
                        if (response.success) {
                            this.$toast.add({ severity: 'success', summary: 'Rate Card Effective Date', detail: "The record was updated successfully!", life: 3000 });
                            this.dialog.edit = false;
                            this.onCancel();
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: response.errors[0]?.message, life: 3000 });
                        }

                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
                }
                else {
                    this.$store.dispatch("providercontract/addRecord", model).then((response) => {
                        if (response && response.success) {
                            this.$toast.add({ severity: 'success', summary: 'Contract Rate Record', detail: "The record was added successfully!", life: 3000 });
                            this.onCancel();
                            this.activity.refresh = true;
                            this.$nextTick(() => { this.activity.refresh = false; });
                        }
                        else if (response && (response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
                }
            },
            onDelete(e) {
                this.model = this.$filters.init(e);
                this.dialog.delete = true;
            },
            onConfirmDelete(e) {
                this.$store.dispatch("providercontract/deleteRecordByMask", e)
                    .then((response) => {
                        if (response && response.success) {
                            this.$toast.add({ severity: 'success', summary: 'Contract Rate Record', detail: "The record was deleted successfully!", life: 3000 });
                            this.getSource();
                            this.activity.refresh = true;
                            this.$nextTick(() => { this.activity.refresh = false; });
                            this.onCancel();
                        }
                        else if (response && (response.errors ?? []).length > 0)
                            this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: response.errors[0]?.message, life: 3000 });
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
            getSource() {
                this.sources.accounts = [];
                this.$store.dispatch("providercontract/getURL", { url: '/services/admin/serviceprovideraccount' })
                    .then((response) => {
                        if (response && response.success)
                            this.sources.accounts = response.result;
                    });
            }
        },
        created() {
            this.getSource();
        }
    }
</script>   