<template>
    <form-record :model="model" :modelState="v$" :validation="v$" :viewOnly="true" @is-reset="resetRecord" :showSend="false" :showClone="false" :title="model.id > 0 ? `[${model.code}] ${model.name}` : `Add New Import Profile`" :subTitle="model.id > 0 ? `` : `Create a new customer-specific profile.`">
        <div class="p-grid p-d-flex">
            <div class="p-col-4">
                <form-input id="code" :value="model.code" @is-sync="model.code = $event.value" :validator="v$" :disabled="true" required v-uppercase v-focus :showCopy="false" />
                <form-input id="name" label="Description" :value="model.name" @is-sync="model.name = $event.value" :disabled="true" :validator="v$" :showCopy="false" />
                <form-input id="dbName" label="Database" :value="model.dbName" @is-sync="model.dbName = $event.value" :disabled="true" :validator="v$" :showCopy="false" />
            </div>
            <div class="p-col-4 tenant-custom">
                <form-boolean id="Protocol" :value="model.requireSSL" onIcon="pi pi-fw pi-lock" onText="HTTPS" offIcon="pi pi-fw pi-lock-open" offText="HTTP" width="150px" @is-sync="model.requireSSL = $event.value" :validator="v$" textPosition="left" :disabled="true" :showCopy="false" />
                <form-input id="portalURL" label="Portal URL" :value="model.portalURL" @is-sync="model.portalURL = $event.value" :validator="v$" :preIcon="model.requireSSL ? 'pi pi-fw pi-lock' : 'pi pi-fw pi-lock-open'" placeholder="cosmos.localhost.com" :preText="model.requireSSL ? 'https://' : 'http://'" :disabled="true" :showCopy="false" />
                <form-input id="apiurl" label="API Endpoint" :value="model.apiURL" @is-sync="model.apiURL = $event.value" :validator="v$" :preIcon="model.requireSSL ? 'pi pi-fw pi-lock' : 'pi pi-fw pi-lock-open'" placeholder="cosmos-api.localhost.com" :disabled="true" :showCopy="false" />
            </div>
            <div class="p-col-4">
                <form-lookup id="modules" label="Subscriptions" :value="model.modules" type="flag" source="module" :disabled="true" :validator="v$" :showCopy="false" />
            </div>
        </div>
    </form-record>
</template>

<script>
    import validator from '@/assets/_js/cosmos-validators.js';
    const moduleName = 'Tenant';
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from "@vuelidate/validators";
    export default {
        name: moduleName + 'Record',
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                model: null
            }
        },
        validations() {
            return {
                model: {
                    code: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },
                    name: { required, maxLength: maxLength(50), $autoDirty: true },
                    dbName: { required, $autoDirty: true },
                    requireSSL: { $autoDirty: true },
                    portalURL: { required, url: validator.url, $autoDirty: true },
                    apiURL: { required, url: validator.url, $autoDirty: true },
                    modules: { $autoDirty: true }
                }
            }
        },
        methods: {
            async get(mask) { return await this.$store.dispatch("tenant/getRecord", mask); },
        },
        beforeCreate() {
            this.model = this.$store.getters["tenant/init"];
        },
        async created() {
            this.model = this.$store.getters["tenant/init"];
            if (this.$route.params.mask) {
                await this.$store.dispatch("tenant/getRecord", this.$route.params.mask).then((response) => {
                    this.model = response.result;
                    this.$nextTick(() => {
                        this.v$.$reset();
                    }) });
            }
        }
    }
</script>
<style>
    .tenant-custom .is-container .is-prepend.p-inputgroup-addon {
        min-width: 3rem !important;
        max-width: 3rem !important;
    }
</style>