<template>
    <form-record :model="model" :modelState="v$" :validation="v$" :viewOnly="viewOnly" @is-reset="resetRecord" :showSend="false" :showClone="false" :title="model.id > 0 ? `[${model.code}] ${model.name}` : `Add New Import Profile`" :subTitle="model.id > 0 ? `` : `Create a new customer-specific profile.`">
        <div style="position: relative;">
            <div class="p-grid">
                <div class="p-col-12 p-md-4">
                    <form-lookup id="topicID" label="Topic" sourceType="array" :source="sources.topics" textField="name" :disabled="model.id > 0" :showCopy="false" :value="model.topicID" @is-sync="setImportTopic($event)" :required="true" :v$="v$" />
                    <form-lookup v-if="(model.id >= 1000 || model.id == 0) && selectedTopic?.code !== 'INVOICERECONCILE'" id="customer" sourceType="array" :source="sources.customers" textField="name" ph="Select Customer" :disabled="model.id > 0" :showCopy="false" :value="model.customerID" @is-sync="model.customerID = (model.id < 1000 && model.id > 0) ? null : $event.value ?? model.customerID ?? sources.customers[0]?.id" :v$="v$" :required="true" />
                    <form-lookup v-if="(model.id >= 1000 || model.id == 0) && selectedTopic?.code === 'INVOICERECONCILE'" id="serviceprovider" sourceType="array" :source="sources.serviceProviders" textField="name" ph="Select Provider" :disabled="model.id > 0" :showCopy="false" :value="model.serviceProviderID" @is-sync="{model.serviceProviderID = (model.id < 1000 && model.id > 0) ? null : $event.value; onServiceProviderSelect($event)}" label="Service Provider" :v$="v$" />
                </div>
                <div class="p-col-12 p-md-4">
                    <form-input id="code" :disabled="model.id < 1000 && model.id > 0" :value="model.code" @is-sync="model.code = $event.value" v-uppercase v-focus :v$="v$" :showCopy="false" />
                    <form-input v-if="model.id >= 1000 || model.id == 0" id="name" :disabled="model.id < 1000 && model.id > 0" :value="model.name" @is-sync="model.name = $event.value" :v$="v$" :showCopy="false" />
                </div>
                <div class="p-col-12 p-md-4">
                    <form-boolean v-if="model.id >= 1000" id="IsAutomated" type="toggle" :value="model.isAutomated" @is-sync="setAutomation(model.id >= 1000 ? $event.value : false);" :v$="v$" />
                    <form-input v-if="model.id < 1000 && model.id > 0" id="name" :disabled="model.id < 1000" :value="model.name" @is-sync="model.name = $event.value" :v$="v$" :showCopy="false" />
                </div>
            </div>
        </div>
        <div class="p-grid" style="margin: 0 -1.5rem;">
            <div class="p-col-12">
                <pv-tab :scrollable="true" :activeIndex="activeIndex">
                    <pv-tabpanel header="MAPPING">
                        <profile-mappings :profileID="model?.id" :source="model?.mappings ?? []" :sourceDefaults="model?.defaults ?? []" :fields="sources.fields ?? []" :defaults="sources?.defaults ?? []" :referentials="sources" :v$="v$" @is-sync="model.mappings = $event" :template="templateName" :viewOnly="viewOnly" />
                    </pv-tabpanel>
                    <pv-tabpanel header="AUTOMATION" v-if="model.isAutomated" style="padding: 20px;">
                        <div class="card" style="position: relative; padding-top:0.20rem;">
                            <div class="p-col-12">
                                <p><i class="fas fa-info-square fa-fw fa-2x is-text-info"></i> <span class="is-text-info">Please ensure that the FTP credentials have full access control to avoid any potential issues with file permissions or accessibility..</span></p>
                                <div class="p-grid">
                                    <div class="p-col-12 p-md-4">
                                        <div class="is-container p-field p-col-12 p-fluid" style="position: relative; padding-bottom:0.20rem;"><h1 style="font-size: 1.1rem; text-transform: uppercase">{{$vm.$enums['downloadprotocol'].filter(x => x.id === this.model?.ftpProtocolType ?? 2)[0]?.code ?? "FTP"}} Credential</h1></div>
                                        <form-lookup id="ftpProtocolType" label="Type" type="enum" source="downloadprotocol" :value="model.ftpProtocolType" @is-sync="model.ftpProtocolType = ($event.value ?? model.ftpProtocolType ?? 2); ftpUpdated()" :required="true" :v$="v$" />
                                        <form-input id="ftpHost" label="Host" :value="model.ftpHost" @is-sync="model.ftpHost = $event.value; ftpUpdated()" :required="true" :v$="v$" :max-length="255" />
                                        <form-input id="ftpUser" label="Username" :value="model.ftpUser" @is-sync="model.ftpUser = $event.value; ftpUpdated()" :required="true" :v$="v$" :max-length="255" />
                                        <form-input type="password" id="ftpPass" label="Password" :value="model.ftpPass" @is-sync="model.ftpPass = $event.value; ftpUpdated()" :required="true" :v$="v$" :showMeter="false" :max-length="255" />
                                        <form-numeric id="ftpPort" label="Port" :value="model.ftpPort" @is-sync="model.ftpPort = $event.value; ftpUpdated()" :required="true" :v$="v$" :max-length="255" />
                                        <form-input id="ftpFolder" label="Folder" :value="model.ftpFolder" @is-sync="model.ftpFolder = $event.value; ftpUpdated()" :required="true" :v$="v$" :max-length="255" />
                                        <div style="position: relative; margin-bottom: 1rem;">
                                            <form-input id="ftpFile" label="Filename <small class='is-text-info right'>(case-sensitive)</small>" :value="model.ftpFile" @is-sync="model.ftpFile = $event.value" :required="false" :v$="v$" :max-length="255" />
                                            <div v-if="model.ftpFile === '' || model.ftpFile === null" style="margin-top: -1rem; text-align: justify; padding: 0 0.5rem 0.5rem;"><i class="fas fa-info-square fa-fw is-text-danger"></i> <span class="is-text-danger">Leave the file name blank to process all files in the directory.</span></div>
                                        </div>
                                        <div class="full-w" style="text-align: right;">
                                            <pv-button class="right" :disabled="model.isValidFTP || (!model.ftpHost && !model.ftpUser && !model.ftpPass)" label="Test Connection" icon="fa fa-exchange" @click="validateFTP" :loading="loading.ftp" />
                                        </div>

                                    </div>
                                    <div class="p-col-12 p-md-4">
                                        <div class="is-container p-field p-col-12 p-fluid" style="position: relative; padding-bottom:0.20rem;"><h1 style="font-size: 1.1rem; text-transform: uppercase">Schedule</h1></div>
                                        <div style="position: relative; margin-bottom: 1rem;">
                                            <form-lookup id="runOccurence" source="scheduleoccurence" type="enum" :value="model.runOccurence" label="Occurence" @is-sync="model.runOccurence = $event.value" :sorted="false" :v$="v$" :required="true" />
                                            <div v-if="model.runOccurence == 1000" style="margin-top: -1rem; text-align: justify; padding: 0 0.5rem 0.5rem;"><i class="fas fa-info-square fa-fw is-text-danger"></i> <span class="is-text-danger">There is no specific time to run the process as it depends on server load, but it will download files multiple times per hour.</span></div>
                                        </div>
                                        <form-datetime-utc id="startDate" type="date" :value="model.startDate" @is-sync="model.startDate = $event.value" :required="true" :v$="v$" />
                                        <form-datetime-utc id="endDate" type="date" :value="model.endDate" @is-sync="model.endDate = $event.value" :v$="v$" />
                                        <form-datetime-utc id="runTime" :allowInput="true" :stepMinute="5" :value="model.runTime" type="time" @is-sync="model.runTime = $event.value" :timeFormat="24" label="Run Time" :v$="v$" :required="true" :showCopy="false"></form-datetime-utc>
                                        <form-input id="notification" label="Nofication Email" :value="model.notification" @is-sync="model.notification = $event.value" :required="false" :v$="v$" :max-length="1000" placeholder="useremail@domain.com For multiple recipients user semi-colon(;) or comma(,) as separator" v-tooltip.top="{value : 'user@domain.com For multiple recipients user semi-colon(;) or comma(,) as separator'}" />
                                        <!--<form-datetime id="runTime" type="time" :value="model.runTime" @is-sync="model.runTime = $event.value"  :v$="v$" />-->
                                        <div class="is-container p-field p-col-12 p-fluid" style="position: relative; padding-bottom:0.20rem;"><h1 style="font-size: 1.1rem; text-transform: uppercase">AUTO PRINTING</h1></div>
                                        <form-lookup id="automation_LabelPrinterID" :value="model.automation_LabelPrinterID" :source="labelPrinters" label="Label Printer" @is-sync="{labelPrinters.length > 0 ? model.automation_LabelPrinterID = $event.value : model.automation_LabelPrinterID = model.automation_LabelPrinterID;}"></form-lookup>
                                        <form-lookup id="automation_ReturnsLabelPrinterID" :value="model.automation_ReturnsLabelPrinterID" :source="labelPrinters" label="Returns Label Printer" @is-sync="{labelPrinters.length > 0 ? model.automation_ReturnsLabelPrinterID = $event.value : model.automation_ReturnsLabelPrinterID = model.automation_ReturnsLabelPrinterID;}"></form-lookup>
                                        <form-lookup id="automation_DocumentPrinterID" :value="model.automation_DocumentPrinterID" :source="documentPrinters" label="Document Printer" @is-sync="{documentPrinters.length > 0 ? model.automation_DocumentPrinterID = $event.value : model.automation_DocumentPrinterID = model.automation_DocumentPrinterID;} "></form-lookup>
                                    </div>
                                    <div class="p-col-12 p-md-4">
                                        <div class="is-container p-field p-col-12 p-fluid" style="position: relative; padding-bottom:0.20rem;"><h1 style="font-size: 1.1rem; text-transform: uppercase">&nbsp;</h1></div>
                                        <form-lookup v-if="model.runOccurence === 200" id="days" source="dayofweek" type="flag" :value="model.days" label="Days In The Week" @is-sync="model.days = $event.value" :sorted="false" :v$="v$"></form-lookup>
                                        <form-lookup v-if="model.runOccurence === 300" id="months" source="monthofyear" type="flag" :value="model.months" label="Months In The Year " @is-sync="model.months = $event.value" :sorted="false" :v$="v$"></form-lookup>
                                        <form-numeric v-if="model.runOccurence === 300" :disabled="model.months == 0" id="day" :min=1 :max="32" :value="model.day" :required="true" @is-sync="model.day = $event.value" :showCopy="false" :v$="v$" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </pv-tabpanel>
                    <pv-tabpanel header="DEFAULTS" v-if="sources.defaults.length > 0">
                        <profile-defaults :profileID="model?.id" :customerID="model?.customerID" :warehouseDS="sources.warehouses" :source="model?.defaults ?? []" :fields="sources?.defaults ?? []" :v$="v$" @is-sync="model.defaults = $event;" :viewOnly="viewOnly" />
                    </pv-tabpanel>
                </pv-tab>
            </div>
        </div>
    </form-record>
</template>
<script>
    import config from '@/assets/lib/cosmos/_js/config.js';
    import profileMappings from "@/views/facility/import/profile/mappings.vue";
    import profileDefaults from "@/views/facility/import/profile/defaults.vue";
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import validator from '@/assets/_js/cosmos-validators.js';
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from '@vuelidate/validators';
    export default {
        name: 'ImportProfileRecord',
        setup: () => ({ v$: useVuelidate() }),
        mixins: [mixins.RECORD],
        components: {
            'profile-mappings': profileMappings,
            'profile-defaults': profileDefaults
        },
        data() {
            return {
                activeIndex: 0,
                templateName: "template_CONSIGNMENT",
                selectedTopic: null,
                reportScopes: null,
                sources: {
                    loading: false,
                    // Lookup Data Sources
                    customers: [],
                    warehouses: [],
                    servicegroups: [],
                    serviceProviders:[],
                    // Display Purposes
                    topics: [],
                    fields: [],
                    defaults: []
                },
                automatedValidation: {
                    model: {
                        $autoDirty: true,
                        topicID: { required, $autoDirty: true },
                        code: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },
                        name: { required, minLength: minLength(1), maxLength: maxLength(50), $autoDirty: true },
                        customerID: { required, $autoDirty: true },
                        ftpProtocolType: { required, $autoDirty: true },
                        ftpHost: { required, $autoDirty: true },
                        ftpUser: { required, $autoDirty: true },
                        ftpPass: { required, $autoDirty: true },
                        ftpPort: { required, $autoDirty: true },
                        ftpFolder: { required, $autoDirty: true },
                        ftpFile: {  $autoDirty: true },
                        runOccurence: { required, $autoDirty: true },
                        startDate: { required, $autoDirty: true },
                        runTime: { required,  $autoDirty: true },
                        endDate: { $autoDirty: true },
                        days: { $autoDirty: true },
                        months: { $autoDirty: true },
                        day: { $autoDirty: true },
                        mappings: [{ id: { $autoDirty: true }, fieldID: { $autoDirty: true }, headerName: { $autoDirty: true }, header: { $autoDirty: true } }]
                    },
                    sources: {
                        items: { $autoDirty: true }
                    }
                },
                standardValidation: {
                    model: {
                        $autoDirty: true,
                        topicID: { required, $autoDirty: true },
                        code: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },
                        name: { required, minLength: minLength(1), maxLength: maxLength(50), $autoDirty: true },
                        customerID: { required, $autoDirty: true },
                        mappings: [{ id: { $autoDirty: true }, fieldID: { $autoDirty: true }, headerName: { $autoDirty: true }, header: { $autoDirty: true } }]
                    },
                    sources: {
                        items: { $autoDirty: true }
                    }
                },
                validation: {
                    model: {
                        $autoDirty: true,
                        topicID: { required, $autoDirty: true },
                        code: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },
                        name: { required, minLength: minLength(1), maxLength: maxLength(50), $autoDirty: true },
                        customerID: { required, $autoDirty: true },
                        mappings: [{ id: { $autoDirty: true }, fieldID: { $autoDirty: true }, headerName: { $autoDirty: true }, header: { $autoDirty: true } }]
                    },
                    sources: {
                        items: { $autoDirty: true }
                    }
                },
                loading: {
                    ftp: false
                },
                printerSource: []
            }
        },
        computed: {
            viewOnly() { return this.model.id > 0 && this.model.id < 1000; },
            groupNames() { return this.sources.groupings; },
            fields() { return this.sources.fields; },
            fieldItems() { return this.sources.items },
            ticks() { return this.$moment.utc().format("YYYYMMDD"); },
            companyDS() { return this.$store.getters["company/ds"]; },
            customerDS() { return this.companyDS.data.filter((x) => x.type === 16); },
            sessionKeys() { return this.$store.getters["auth/session"]; },
            headers() {
                return this.sources.headers;
            },
            validateFTPURL() { return `${config.config.endpoint.api}/ftp/validate`; },
            warehouses() {
                return (this.reportScopes?.warehouses ?? [])
                    .filter(wh => (this.model.customerID ?? 0) === 0 || ((this.model.customerID ?? 0) > 0 && (wh.customers ?? []).filter(cust => cust === (this.model.customerID ?? 0)).length > 0)) ?? [];
            },
            customers() { return (this.reportScopes?.customers ?? []); },
            requiredMapping() {
                return ((this.sources?.fields ?? []).filter(x => x.configuration && (x.configuration.required || x.configuration.conditional)
                    && x.code !== "CUSTCODE"
                    && (this.model.mappings ?? []).filter(a => a.headerName).map(y => y.fieldID).filter(z => z === x.id).length === 0).map(x => x.headerName))
            },
            labelPrinters() { return (this.printerSource ?? []); },
            documentPrinters() { return (this.printerSource ?? []).filter(x => x.type == 2); },
        },
        validations() {
            return this.validation;
        },
        watch: {
            'model.mappings': {
                handler() {
                    if (this.model)
                        this.model.isValidMapping = this.requiredMapping.length == 0;
                },
                deep: true,
                immediate: true
            },
            'model.customerID': {
                handler() {
                    this.sources.warehouses = this.warehouses.map(x => ({ id: x.id, text: x.name, value: x.id }));
                    this.getPrinters();
                },
                deep: true,
                immediate:true
            },
            'warehouses': {
                handler() {
                    this.sources.warehouses = this.warehouses.map(x => ({ id: x.id, text: x.name, value: x.id }));
                },
                deep: true,
                immediate: true
            },
            'sources.fields': {
                handler() {
                    if (this.model)
                        this.model.isValidMapping = this.requiredMapping.length == 0;
                },
                deep: true,
                immediate: true
            },
            'sources.customers': {
                handler() {
                    this.getPrinters();
                },
                deep: true,
                immediate: true
            }
        },
        methods: {
            setImportTopic(topic) {
                this.model.topicID = topic.value ?? this.model.topicID;
                this.templateName = topic.record?.templateName ?? "template_CONSIGNMENT";
                setTimeout(() => {
                    this.sources.fields = topic?.record?.topicFields ?? [];
                    this.sources.defaults = topic?.record?.topicDefaults ?? [];
                    this.selectedTopic = topic?.record
                }, 100);

            },
            getImportTopics() {
                this.sources.topics = [];
                this.$store.dispatch("importprofile/getURL", { url: `/services/referential/importtopic` })
                    .then((response) => { this.sources.topics = response.result; })
                    .catch(() => { this.sources.topics = []; });
            },
            getCustomers() {
                this.$store.dispatch("importprofile/getURL", { url: '/services/settings/company?type=8' })
                    .then((response) => { this.sources.warehouses = response.result; });
                this.$store.dispatch("importprofile/getURL", { url: '/services/facility/servicegroup' })
                    .then((response) => { this.sources.servicegroups = response.result; });
                this.customers = [];
                this.$store.dispatch("importprofile/getURL", { url: '/services/settings/company?type=16' })
                    .then((response) => { this.sources.customers = response.result; });
                this.$store.dispatch("provideraccount/getURL", { url: `/services/settings/user/reportscope` })
                    .then((response) => {
                        this.reportScopes = response;
                        this.sources.customers = this.customers;
                        
                    });

                return this.customers;
            },
            onServiceProviderSelect(e) {
                if (e.value !== null && (this.model.id == 0 || this.model.id == null || this.model.id == undefined)) {
                    this.sources.fields = this.selectedTopic.topicFields.filter(x => x.code !== "CARRIERCODE");                    
                }
            },
            async getProviderAccounts() {
                await this.$store.dispatch("provideraccount/getAll", { sort: null, where: null }).then((response) => {
                    let ds = [];
                    var data = (response.result ?? []);
                    for (var i = 0; i < data.length; i++) {
                        let provider = this.$vm.$providers.filter(y => y.id === data[i].serviceProviderID)[0];
                        if (ds.length === 0) {
                            ds.push({ id: provider.id, name: '[' + provider.code + '] ' + provider.name });
                        }
                        else if (ds?.findIndex(y => y.id === data[i].serviceProviderID) === -1) {
                            ds.push({ id: provider.id, name: '[' + provider.code + '] ' + provider.name });
                        }
                    }
                    this.sources.serviceProviders = ds; 
                });
            },
            setAutomation(value) {
                this.model.isAutomated = (this.model.id >= 1000 ? value : false);
                //this.v$.reset();
                if (this.model.isAutomated) {
                    this.validation = this.automatedValidation;
                    this.activeIndex = 1;
                }
                else {
                    this.validation = this.standardValidation;
                    this.activeIndex = 0;
                }
                this.v$.$touch();
            },
            validateFTP() {
                this.loading.ftp = true;
                let ftpProtocolType = this.$vm.$enums['downloadprotocol'].filter(x => x.id === this.model?.ftpProtocolType ?? 2)[0]?.code ?? "FTP";
                this.$axios.post(this.validateFTPURL, {
                    Protocol: ftpProtocolType,
                    Host: this.model.ftpHost,
                    UserID: this.model.ftpUser,
                    EnableSSL: false,
                    Password: this.model.ftpPass,
                    Port: this.model.ftpPort,
                    Directory: this.model.ftpFolder
                }).then((response) => {
                    if (response && response.data) {
                        if (!response.data.success && response.data.errors.length > 0)
                            this.$toast.add({ severity: 'error', summary: ftpProtocolType + ' Authentication Error', detail: response.data.errors[0].message, life: 3000 });
                        else
                            this.$toast.add({ severity: 'success', summary: ftpProtocolType + ' Authentication Error', detail: response.data.result, life: 3000 });
                        this.model.isValidFTP = response.data.success;
                    }
                    this.loading.ftp = false;
                }).catch(() => {
                    this.loading.ftp = false;
                });
            },
            ftpUpdated() {
                this.model.isValidFTP = false;
            },
            logConsole(e) {
                console.log(e)
            },
            getPrinters() {
                if (this.model?.customerID) {
                    if ((this.sources?.topics ?? []).filter(x => x.id == this.model.topicID)[0]?.code == "CONSIGNMENT") {
                        if ((this.sources?.customers ?? []).filter(x => x.id === this.model.customerID)[0].mask) {
                            this.$axios
                                .get(this.$config.config.endpoint.api + `/services/settings/companyprinter/company/${this.sources.customers.filter(x => x.id === this.model.customerID)[0].mask}/active`.replaceAll("//", "/"))
                                .then((response) => {
                                    this.printerSource = response.data.result;
                                })
                                .catch((ex) => { this.errors = ex.data; });
                        }
                        else {
                            this.printerSource = [];
                        }
                    }
                }
                
            }
        },
        created() {
            this.getImportTopics();
            this.getCustomers();
            this.getProviderAccounts();
            this.getPrinters();
            this.model.mappings = [];
        },
        mounted() {
            if(!this.model.mappings)
                this.model.mappings = [];
            this.model.isValidMapping = false;
            //this.model.isValidMapping = ((this.sources?.fields ?? []).filter(x => x.configuration && (x.configuration.required || x.configuration.conditional)
            //    && x.code !== "CUSTCODE"
            //    && (this.model.mappings ?? []).filter(a => a.headerName).map(y => y.fieldID).filter(z => z === x.id).length === 0).map(x => x.headerName)).length == 0;

        }
    }
</script>
<style>
    .tradetype { margin-right: 5px !important; padding: 0 !important; }
    .drag-el { border: 3px solid #666; background-color: #ddd; border-radius: .5em; padding: 10px; cursor: move; }
    li.dragging { background-color: navy; }
    .is-draggable { background-color: lightgray; padding: 5px 10px; margin-bottom: 2px; z-index: 1000; cursor: grab; cursor: -moz-grab; cursor: -webkit-grab; }
    .drag-el:active { cursor: grabbing; cursor: -moz-grabbing; cursor: -webkit-grabbing; }
    .field-groupname { margin-bottom: 1rem !important; }
    .field-groupname:last-child { margin-bottom: 0 !important; }
</style>