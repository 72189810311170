<template>
    <div class="p-fluid p-grid">
        <div class="p-col-12 p-md-3">
            <form-lookup id="customerID" label="Customer" :required="true" :source="scopeCustomerDS" :value="customerSelectedID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onEventCustomerChange($event)" />
        </div>
        <div class="p-col-12 p-md-3">
            <form-lookup id="tradeType" label="Trade Type" :required="true" :source="tradeTypeDS ?? []" :value="tradeTypeSelectedID" :v$="v$" :textField="'text'" :valueField="'id'" @is-sync="onEventTradeTypeChange($event)" />
        </div>        
    </div>
    <div class="p-fluid p-grid">
        <div class="p-col">
            <h4>SENDER DETAIL</h4>
            <div class="card" :style="`background: var(--surface-c)`">
                <div class="p-fluid p-grid">
                    <div class="p-col-12 p-md-6">
                        <form-address id="collectionAddress" :value="collectionAddress" :validateState="companySettingRecord?.setting_CheckState ?? true" :validatePostalCode="companySettingRecord?.setting_CheckPostalCode ?? true" :v$="v$" @is-sync="populateCollectionAddress($event)" :useCompany="true" :forceEnableCompany="true" :disabled="disablecollectionAddress" :showLookup="!disablecollectionAddress" :showCopy="!disablecollectionAddress" />
                        <form-lookup id="shipFrom_EstablishmentType" :required="true" type="enum" source="establishmenttype" :value="model?.shipFrom_EstablishmentType" label="Establishment" @is-sync="model.shipFrom_EstablishmentType = $event.value" :v$="v$" :disabled="disablecollectionAddress" :showCopy="false"></form-lookup>
                        <form-boolean id="shipFrom_AtRisk" :value="model?.shipFrom_AtRisk" type="toggle" label="At Risk?" textPosition="left" @is-sync="model.shipFrom_AtRisk = $event.value"></form-boolean>
                        <form-boolean id="shipFrom_SaveAddressBook" v-show="!disablecollectionAddress" :value="model?.shipFrom_SaveAddressBook" type="toggle" label="Save collection address?" textPosition="left" @is-sync="model.shipFrom_SaveAddressBook = $event.value"></form-boolean>
                    </div>
                    <div class="p-col-12 p-md-6">
                        <form-contact :disabled="model?.status > 100" id="collectionContact" :value="collectionContact" :useRegistrationNumber="true" :v$="v$" @is-sync="populateCollectionContact($event)" :showDirectory="true" :directoryFilterID="model.customerID" />
                    </div>
                </div>
            </div>

        </div>
        <div v-if="tradeTypeSelectedID == 4" class="p-col" style="max-width: 4rem; margin: 0; margin-top: 2.55rem;">
            <div class="p-d-flex">
                <pv-button icon="fa-solid fa-arrow-right-arrow-left" style="min-width: 40px; max-height: 40px;" v-tooltip.top="{value: 'Switch Address'}" :iconOnly="true" @click="switchAddress()" />
            </div>
            <div class="p-d-flex">
                <pv-button icon="fa-solid fa-trash-can-xmark" style="min-width: 40px; max-height: 40px; margin-top: 5px" v-tooltip.top="{value: 'Clear'}" :iconOnly="true" @click="onClearContactAddress()" />
            </div>    
        </div>
        <div class="p-col">
            <h4>RECIPIENT DETAIL</h4>
            <div class="card" :style="`background: var(--surface-c)`">
                <div class="p-fluid p-grid">
                    <div class="p-col-12 p-md-6">
                        <form-address id="deliveryAddress" :value="deliveryAddress" :validateState="companySettingRecord?.setting_CheckState ?? true" :validatePostalCode="companySettingRecord?.setting_CheckPostalCode ?? true" :v$="v$" @is-sync="populateDeliveryAddress($event)" :useCompany="true" :forceEnableCompany="true" :disabled="disabledeliveryAddress" :showLookup="!disabledeliveryAddress" />
                        <form-lookup id="shipTo_EstablishmentType" :required="true" type="enum" source="establishmenttype" :value="model.shipTo_EstablishmentType" label="Establishment" @is-sync="model.shipTo_EstablishmentType = $event.value" :v$="v$" :disabled="disabledeliveryAddress" :showCopy="false"></form-lookup>
                        <form-boolean id="shipTo_AtRisk" :value="model?.shipTo_AtRisk" type="toggle" label="At Risk?" textPosition="left" @is-sync="model.shipTo_AtRisk = $event.value"></form-boolean>
                        <form-boolean id="shipTo_SaveAddressBook" :value="model.shipTo_SaveAddressBook" type="toggle" label="Save recipient address?" textPosition="left" @is-sync="model.shipTo_SaveAddressBook = $event.value" v-show="!disabledeliveryAddress"></form-boolean>
                    </div>
                    <div class="p-col-12 p-md-6">
                        <form-contact :disabled="model.status > 100" id="deliveryContact" :value="deliveryContact" :useRegistrationNumber="true" :v$="v$" @is-sync="populateDeliveryContact($event)" :showDirectory="true" :directoryFilterID="this.model?.customerID ?? this.customerID" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/consignment-mixins.js';
    //import validator from '@/assets/_js/cosmos-validators.js';
    import { useVuelidate } from '@vuelidate/core';
    //import { required, minLength, maxLength, between, decimal } from '@vuelidate/validators';

    export default {
        name: 'GuideTemplate',
        mixins: [mixins.ADDRESSES.FORM],
        setup: () => ({ v$: useVuelidate() }),
        validations() {
            return {
                model: {
                  
                }
            }
        },
        mounted() {
            //let _self = this;
            //this.$nextTick(() => {
            //    _self.getCompanyList().then(() => {
            //        _self.model.customerID = _self.scopeCustomerDS[0]?.id;
            //    })
            //});
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>