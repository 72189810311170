<template>
    <form-record :model="model" :modelState="v$"  :validation="v$" :validators="validators" @is-reset="resetRecord">
        <div class="p-d-flex p-grid p-col-12">
            <div class="p-col-12 p-md-4">
                <form-input v-show="model.id > 0" id="code" :v$="v$" v-code v-uppercase :value="model.code" @is-sync="model.code = (model.id > 0 ? $event.value : 'TEMP')" />
                <form-input id="name" label="Description" :required="true" :v$="v$" v-focus :value="model.name" @is-sync="model.name = $event.value" :max-length="1000" />
                <form-lookup id="operatortype" :required="true" type="enum" source="operatortype" :value="model.operatortype" label="Operator" @is-sync="model.operatortype = $event?.value ?? 700" :v$="v$"></form-lookup>
                <form-input id="message" label="Message" :v$="v$" :value="model.message" @is-sync="model.message = $event.value" :max-length="1000" />
                <form-input id="remarks" label="Remarks" :v$="v$" :value="model.remarks" @is-sync="model.remarks = $event.value" :max-length="1000"/>
            </div>
        </div>
    </form-record>
</template>

<script>
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    const moduleName = 'CustomMessage';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                test: false
            }
        },
        computed: {
            
        },
        methods: {
            
        },
        created() {
           
           
        },
        mounted() {
           
        },
        updated() {
            switch (this.$router.currentRoute.value.meta.type.toLowerCase()) {
                case "customerror":
                    if ((this.model?.id ?? 0) <= 0)
                        this.model.type = (this.model?.id ?? 0) <= 0 ? 1 : this.model.type;
                    break;
                case "customintransit":
                    if ((this.model?.id ?? 0) <= 0)
                        this.model.type = (this.model?.id ?? 0) <= 0 ? 8 : this.model.type;
                    break;
                case "customoutfordelivery":
                    if ((this.model?.id ?? 0) <= 0)
                        this.model.type = (this.model?.id ?? 0) <= 0 ? 4 : this.model.type;
                    break;
                case "customdelivered":
                    if ((this.model?.id ?? 0) <= 0)
                        this.model.type = (this.model?.id ?? 0) <= 0 ? 2 : this.model.type;
                    break;

                
            }
        }
    }
</script>
