<template>
    <div style="margin: -0.5rem -1.5rem -2.5rem -1.5rem;">
        <div style="min-height: 10rem; padding: 1rem;">
            <h3>STEP 1</h3>
            <field-form v-model="model.fields" :parentID="-1000" />
        </div>
        <div class="p-grid p-nogutter p-justify-between" style="border-top: 2px solid var(--surface-d);padding: 0.5rem 1rem; background: var(--surface-c)">
            <i></i>
            <pv-button @click="onNextPage()" :loading="activity.loading" :iconPos="'right'" :disabled="(model?.fields ?? []).length === 0"><i :class="`${(activity.loading ? 'pi pi-spinner pi-spin' : 'fa fa-pen-field fa-fw')}`" style="margin-right: 0.5rem"></i> Customization <i class="fa fa-chevron-right fa-fw" style="margin-left: 0.5rem"></i></pv-button>
        </div>
    </div>
</template>
<script>
    import fieldForm from '@/components/facility/report/field-form.vue';
    export default {
        name: "FieldSelectionStep",
        emits: ["update", "update:modelValue", "isSync", "nextPage"],
        components: {
            'field-form': fieldForm
        },
        props: {
            modelValue: { type: Array, default: () => [] }
        },
        data() {
            return {
                model: {
                    fields: []
                },
                activity: {
                    loading: false
                }
            }
        },
        watch: {
            model() {
                this.onSync();
            }
        },
        methods: {
            onNextPage() {
                this.$emit('next-page', { formData: this.model, pageIndex: 0 });
            },
            onSync() {
                this.$emit("update:modelValue", this.model);
                this.$emit("is-sync", this.model);
            },
        },
        created() {
            if (this.modelValue)
                this.model = this.modelValue;
        }
    }
</script>