<template>
    <cosmos-grid id="reportGrid" sourceType="array" :source="source?.data ?? []" :showCheckbox="false" :sourcePaged="true" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :showExport="false" :showOperation="false" :showHeader="false">
        <pv-grid-column field="customerName" header="Customer" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.customerName }}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="warehouseName" header="Warehouse" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.warehouseName }}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="transactionID" header="Consignment ID" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <a :href="`/transaction/consignment/${slotProps.data?.consignmentMask}`" target="_blank" style="font-weight: bold;">{{ slotProps.data?.transactionID }}</a>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="returnDate" header="Return Date" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ $filters.toDateFullTimeDisplay(slotProps.data?.returnDate) }}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="serviceProviderName" header="Provider Name" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.serviceProviderName }}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="serviceType" header="Service Type" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.serviceType }}
                </div>
            </template>
        </pv-grid-column>

        <pv-grid-column field="reference1" header="Reference 1" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <a :href="`/transaction/return/${slotProps.data?.mask}`" target="_blank" style="font-weight: bold;">{{ slotProps.data?.reference1 }}</a>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference2" header="Reference 2" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.reference2 }}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference3" header="Reference 3" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.reference3 }}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference4" header="Reference 4" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.reference4 }}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference5" header="Reference 5" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.reference5 }}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference6" header="Reference 6" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.reference6 }}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference7" header="Reference 7" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.reference7 }}
                </div>
            </template>
        </pv-grid-column>

        <pv-grid-column field="orderAction" header="Order Action" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.orderAction }}
                </div>
            </template>
        </pv-grid-column>

        <pv-grid-column field="carrier" header="Carrier" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.carrier }}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="carrierService" header="Carrier Service" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.carrierService }}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="carrierReference" header="Carrier Reference" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0; font-weight: bold;">
                    <a :href="`${slotProps.data?.trackingURL}`" target="_blank">{{ slotProps.data?.carrierReference }}</a>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="collectionReference" header="Collection Reference" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.collectionReference }}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="awbNumber" header="AWB Number" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.awbNumber }}
                </div>
            </template>
        </pv-grid-column>

        <pv-grid-column field="receivingWarehouseCode" header="Receiving Warehouse Code" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.receivingWarehouseCode }}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="receivingWarehouseName" header="Receiving Warehouse Name" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.receivingWarehouseName }}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="receivingWarehouseMAWB" header="Receiving Warehouse MAWB" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.receivingWarehouseMAWB }}
                </div>
            </template>
        </pv-grid-column>



        <pv-grid-column field="destinationWarehouseCode" header="Destination Warehouse Code" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.destinationWarehouseCode }}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="destinationWarehouseName" header="Destination Warehouse Name" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.destinationWarehouseName }}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="destinationWarehouseMAWB" header="Destination Warehouse MAWB" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.destinationWarehouseMAWB }}
                </div>
            </template>
        </pv-grid-column>


        <pv-grid-column field="customValue" header="Custom Value" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-align: right; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{ $filters.formatCurrency(slotProps.data?.customValue ?? 0, slotProps.data?.customValueCurrencyCode, null, 2) }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="shippingPrice" header="Shipping Price" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-align: right; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{ $filters.formatCurrency(slotProps.data?.shippingPrice ?? 0, slotProps.data?.shippingPriceCurrencyCode, null, 2) }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="returnPrice" header="Return Price" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-align: right; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{ $filters.formatCurrency(slotProps.data?.returnPrice ?? 0, slotProps.data?.returnPriceCurrencyCode, null, 2) }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="returnCost" header="Return Cost" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-align: right; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{ $filters.formatCurrency(slotProps.data?.returnCost ?? 0, slotProps.data?.returnCostCurrencyCode, null, 2) }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="labelCost" header="Label Cost" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-align: right; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{ $filters.formatCurrency(slotProps.data?.labelCost ?? 0, slotProps.data?.labelCostCurrencyCode, null, 2) }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="refundDeductionCost" header="Refund Deduction Cost" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-align: right; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{ $filters.formatCurrency(slotProps.data?.refundDeductionCost ?? 0, slotProps.data?.refundDeductionCostCurrencyCode, null, 2) }}
            </template>
        </pv-grid-column>


        <pv-grid-column field="lastActivityDate" header="Last Activity Date" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ $filters.toDateFullTimeDisplay(slotProps.data?.lastActivityDate) }}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="lastActivityLocation" header="Last Activity Location" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.lastActivityLocation }}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="lastActivityDetail" header="Last Activity Detail" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data?.lastActivityDetail }}
                </div>
            </template>
        </pv-grid-column>

    </cosmos-grid>
  
</template>
<script>
    import { mapGetters } from 'vuex';
    import { useVuelidate } from '@vuelidate/core';
    export default {
        name: 'reportGrid',
        setup: () => ({ v$: useVuelidate() }),
        props: {
            formData: { type: Object }
        },
        data() {
            return {
                type: -1,
                filter: {},
                columns: [],
                deleteDisplay: ["code", "name"],
                searchFields: ["code", "name"],
                activity: { loading: true },
                source: {
                    total: 0,
                    data: []
                },
                modelState: null,
                initialBind: false

            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            scopeCustomerDS() {
                return this.$config.common.$customer(this.user?.environmentID ?? -100, this.$vm.$appSession.warehouse.id ?? this.user.warehouseID, this.user);
            },
            scopeWarehouseDS() {
                return this.$config.common.$warehouse(this.user?.environmentID ?? -100, this.user);
            },
            apiData() {
                return {
                    DC: this.formData?.warehouseID > 0 ? this.scopeWarehouseDS.filter(x => x.id === this.formData.warehouseID)[0]?.code : null,
                    Customer: this.formData?.customerID > 0 ? this.scopeCustomerDS.filter(x => x.id === this.formData.customerID)[0]?.code : null,
                    Account: this.formData?.serviceProviderAccountCode,
                    Carrier: this.formData?.serviceProviderID > 0 ? this.$vm.$providers.filter(y => y.id === this.formData?.serviceProviderID)[0]?.code : null,
                    ScopeDetail: {
                        IncludeArchive: this.formData?.includeArchived,
                        CollectionDate: {
                            From: this.$filters.formatDateWithFormat(this.formData.returnDateFrom ?? new Date(), "YYYY-MM-DD"),
                            To: this.$filters.formatDateWithFormat(this.formData.returnDateTo ?? new Date(), "YYYY-MM-DD")
                        },
                    }
                }
            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            'formData': {
                handler() {
                    this.$nextTick(() => {

                        this.getSource();
                    });
                }, deep: true
            }
        },
        methods: {
            async getSource() {
                this.$nextTick(() => {
                    this.$axios.post(this.$config.config.endpoint.api + `/services/report/thirdparty/return`.replaceAll("//", "/"), this.apiData)
                        .then((response) => {
                            this.source.data = response?.data?.result ?? [];
                            this.source.total = this.source.data.length;
                        })
                        .catch((ex) => {
                            this.source.data = [];
                            this.source.total = 0;
                            console.log(ex);
                        });
                });

            }
        },
        async created() {
            this.loading = false;
          
        },
        async mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            this.loading = false;
           
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>