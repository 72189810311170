let router = {
    router: {
        name: null,
        module: null,
        title: null,
        subtitle: null,
        model: null,
        schema: null
    }
};
export default {
    FORM: {
        emits: ["is-reset", "is-sync"],
        props: {
            model: { type: Object },
            modelState: { type: Object },
            router: { type: Object }
        },
        data() {
            return {
                activity: {
                    loading: true,
                    submitted: false
                }
            }
        }
    },
    TOOLBAR: {
        emits: ["is-reset"],
        props: {
            model: { type: Object },
            modelState: { type: Object },
            showSave: { type: Boolean, default: true },
            showClone: { type: Boolean, default: false },
            showRefresh: { type: Boolean, default: true },
            showDelete: { type: Boolean, default: true },
            showFAQ: { type: Boolean, default: true },
        },
        data() {
            return {
                display: { width: 1000 },
                activity: {
                    loading: {
                        back: false,
                        save: false,
                        refresh: false,
                        clone: false,
                        delete: false
                    },
                    submitted: false
                },
                dialog: {
                    dirty: false,
                    refresh: false,
                    clone: false,
                    delete: false,
                    support: false
                },
                ...router
            //    router: {
            //        name: null,
            //        module: null,
            //        title: null,
            //        subtitle: null,
            //        model: null,
            //        schema: null
            //    },
            }
        },
        computed: {
            record() { return this.$store.getters[this.router.module + "/record"]; },
            errors() { return this.$store.getters[this.router.module + "/errors"]; },
            backLabel() { return (this.display.width > 450) ? "Back" : null; },
            saveLabel() { return (this.display.width > 450) ? (this.mask ? "Update" : "Save") : null; },
            resetLabel() { return (this.display.width > 450) ? "Reset" : null; },
            printLabel() { return (this.display.width > 450) ? "Print" : null; },
            cloneLabel() { return (this.display.width > 450) ? "Clone" : null; },
            deleteLabel() { return (this.display.width > 450) ? "Delete" : null; },
            faqLabel() { return (this.display.width > 450) ? "FAQ" : null; },
            faqMenu() { return [{ label: 'Support', icon: 'pi pi-fw pi-ticket', command: () => { this.dialog.support = true; this.$toast.add({ severity: 'warn', summary: 'Feature Unavailable', detail: 'The feature is currently under development.', life: 3000 }); } }] },
            isDirty() { return this.modelState.$isDirty || this.modelState.$anyDirty; }
        },
        methods: {
            onBackClick() {
                if (this.isDirty)
                    this.dialog.dirty = true;
                else
                    this.onBackConfirmClick();
            },
            onBackConfirmClick() { this.$router.push({ name: this.router.name + '-index' }); },
            onSaveClick() {

            },
            onRefreshClick() {

            }
        }
    },
    RECORD: {
        data() {
            return {
                activity: {
                    loading: true,
                    submitted: false
                },
                model: null,
                modelState: null,
                router: {
                    name: null,
                    module: null,
                    title: null,
                    subtitle: null,
                    model: null,
                    schema: null
                }
            }
        },
        computed: {
            language() { return navigator.language; },
            touchDevice() { return navigator.maxTouchPoints > 0; },
            isMobile() { return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0, 4)) },
            user() { return this.$store.getters["auth/currentUser"]; },
            init() {
                console.log(this.router);
                var model = this.$store.getters[this.router.module + "/init"];
                return this.$filters.init(model);
            },
            record() { return this.$store.getters[this.router.module + "/record"]; },
        },
        watch: {
            record() {
                this.model = this.record;
                this.activity.loading = false;
            },
            v$()
            {
                this.modelState = this.v$;
            }
        },
        methods: {
            async get(mask) { return await this.$store.dispatch(this.router.name + "/getRecord", mask); },
            getRouter() {
                const router = this.$route;
                const meta = this.$router.currentRoute.value.meta;
                this.router.name = router.name.replace("-index", "").replace("-add", "").replace("-edit", "");
                this.router.module = meta.module;
                this.router.model = meta.model;
                this.router.schema = meta.schema;
                this.router.title = meta.title;
                this.router.subtitle = meta.subtitle;
            }
        },
        async created() {
            this.getRouter();
            this.model = this.init;
            if (this.$route.params.mask)
                this.get(this.$route.params.mask);
            this.activity.submitted = this.$vm.$submitted = false;
        },
        async mounted() { this.activity.loading = false; },
        async updated() {
            this.getRouter();
            this.activity.submitted = this.$vm.$submitted;
        },
        async beforeUnmount() { this.activity.loading = true; },
        async unmount() { this.activity.loading = true; }
    },
    GRID: {
        data() {
            return {
                records: {
                    data: [],
                    total: 0
                },
                activity: {
                    loading: true,
                    submitted: false
                },
                selected: null,
                router: {
                    name: null,
                    module: null,
                    type: null,
                    title: null,
                    subtitle: null,
                    model: null,
                    schema: null
                }
            }
        },
        computed: {
            language() { return navigator.language; },
            touchDevice() { return navigator.maxTouchPoints > 0; },
            isMobile() { return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0, 4)) },
            user() { return this.$store.getters["auth/currentUser"]; },
        },
        methods: {
            getRouter() {
                const router = this.$router.currentRoute._value;
                const meta = router.meta;
                this.router.name = router.name.replace("-index", "").replace("-add", "").replace("-edit", "");
                this.router.module = meta.module;
                this.router.type = meta.type;
                this.router.model = meta.model;
                this.router.schema = meta.schema;
                this.router.title = meta.title;
                this.router.subtitle = meta.subtitle;
            }
        },
        created() {
            this.getRouter();
            this.activity.submitted = this.$vm.$submitted = false;
        },
        updated() {
            this.getRouter();
            this.activity.submitted = this.$vm.$submitted;
        },
    },

}
