<template>
    <div v-if="!disabled || (disabled && disabledView.toLowerCase() === 'field')">
        <form-lookup :id="`${id}_Country`" type="autocomplete" :disabled="disabled" :required="true" source="country" :label="header.country" :postalCode="model.postalCode" :v$="v$" :showDirectory="showDirectory" :showLookup="showLookup" :value="model.countryID" @is-sync="onCountrySelect($event)" />
        <form-input :id="`${id}_PostalCode`" :disabled="disabled" :required="true" :value="model.postalCode" :label="header.postalCode" :v$="v$" :vID="(validation.requirePostalCode ? 'postalCode' : null)" @is-sync="model.postalCode = $event.value" />
        <form-input v-if="showAddress" :id="`${id}_Address1`" :disabled="disabled" :value="model.address1" :label="header.address1" :v$="v$" vID="address1" @is-sync="model.address1 = $event.value" />
        <form-input v-if="showAddress" :id="`${id}_Address2`" :disabled="disabled" :value="model.address2" :label="header.address2" :v$="v$" @is-sync="model.address2 = $event.value" />
        <form-input v-if="showAddress" :id="`${id}_Address3`" :disabled="disabled" :value="model.address3" :label="header.address3" :v$="v$" @is-sync="model.address3 = $event.value" />
        <form-input :id="`${id}_City`" :disabled="disabled" :required="true" :value="model.city" :label="header.city" :v$="v$" vID="city" @is-sync="model.city = $event.value" />
        <form-lookup v-if="useState" :id="`${id}_State`" :disabled="disabled" :label="header.state" :source="stateRecords" :value="model.state" @is-sync="model.state = $event.text; model.stateCode = $event.record?.code" :required="true" valueField="name" textField="name" />
        <form-input v-else :id="`${id}_State`" :disabled="disabled" :value="model.state" :label="header.state" :v$="v$" :vID="stateVID" @is-sync="model.state = $event.value" />
        <form-input v-if="useCompany" :disabled="disabled" :id="`${id}_Company`" :value="model.companyName" :label="companyLabel" :v$="v$" @is-sync="model.companyName = $event.value" />
    </div>
    <div v-else style="margin-bottom: 10px;">
        <div style="font-size: 1.2rem; font-weight: 600; margin-bottom: 5px;" v-if="useCompany">{{model.companyName}}</div>
        <span class="p-code-text" v-html="`${fullAddress}`"></span>
    </div>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from '@vuelidate/validators';
    import mixins from '@/assets/lib/cosmos/_js/core-mixins.js';
    export default {
        name: 'FormAddressField',
        mixins: [mixins.COMPONENT],
        setup: () => ({ v$: useVuelidate() }),
        props: {
            value: { type: Object },
            useCompany: { type: Boolean, default: false },
            companyLabel: { type: String, default: "Company"},
            useEstablishmentType: { type: Boolean, default: false },
            showAddress: { type: Boolean, default: true },
            showDirectory: { type: Boolean, default: false },
            showLookup: { type: Boolean, default: false },
            disabledView: { type: String, default: "field", validator: (value) => ["field", "perline", "singleline"].indexOf((value ?? "field").toLowerCase()) !== -1 }
        },
        data() {
            return {
                header: {
                    address1: "Address Line 1",
                    address2: "Address Line 2",
                    address3: "Address Line 3",
                    city: "City",
                    state: "State",
                    postalCode: "Postal Code",
                    country: "Country"
                },
                model: {
                    id: 0,
                    temporaryId: null,
                    companyName: null,
                    address1: null,
                    address2: null,
                    address3: null,
                    city: null,
                    state: null,
                    stateCode: null,
                    postalCode: null,
                    countryRecord: null,
                    countryID: 238,
                    establishmentType: 100
                },
                validation: {
                    pattern: null,
                    rei: 'state',
                    requirePostalCode: false,
                    requireState: false
                }
            }
        },
        computed: {
            stateVID() {
                return this.validation.requireState ? 'state' : null;
            },
            regexPattern() {
                return this.validation.pattern;
            },
            useState() {
                return (this.model.countryRecord?.states ?? []).length > 0
            },
            stateRecords() {
                return this.model.countryRecord.states;
            },
            fullAddress() {
                let separator = this.disabledView == "perline" ? "<br />" : ", ";
                let country = this.$vm.$countries.filter(x => x.id == this.model.countryID)[0];
                let data = ((this.model.address1 ?? "").trim().length > 0 ? ((this.model.address1 ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') + separator : "") + " " +
                    ((this.model.address2 ?? "").trim().length > 0 ? ((this.model.address2 ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') + separator : "") + " " +
                    ((this.model.address3 ?? "").trim().length > 0 ? ((this.model.address3 ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') + separator : "") + " " +
                    ((this.model.city ?? "").trim().length > 0 ? ((this.model.city ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') + separator : "") + " " +
                    ((this.model.postalCode ?? "").trim().length > 0 ? ((this.model.postalCode ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') + separator : "") + " " +
                    ((this.model.state ?? "").trim().length > 0 ? ((this.model.state ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') + separator : "") + " " +
                    ((country?.text ?? "").trim().length > 0 ? ((country?.text ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') : "") + " "
                return data;
            }
        },
        validations() {
            return {
                test: { $autoDirty: true },
                model: {
                    city: {  maxLength: maxLength(35), $autoDirty: true },
                    state: {  maxLength: maxLength(35), $autoDirty: true },
                    postalCode: {  maxLength: maxLength(15), $autoDirty: true },
                    countryID: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                }
            }
        },
        watch: {
            model: {
                handler(e) {
                    this.onSync(e);
                },
                deep: true
            }
            ,
            value() {
                this.model = this.value;
            }
        },
        methods: {
            onCountrySelect(e) {
                this.model.countryID = e.value;
                this.model.countryRecord = this.$filters.init(e.record);
                this.header.postalCode = e?.record?.postalCodeLabel ?? "Postal Code";
                this.header.city = e?.record?.cityLabel ?? "City";
                this.header.state = e?.record?.stateLabel ?? "State";
                this.validation.requireState = e?.record?.stateRequired ?? false;
                this.validation.requirePostalCode = e?.record?.postalCodeRequired ?? false;
                this.validation.pattern = e?.record?.postalCodeRegExPattern ?? null
                this.v$.$touch();
            },
            onSync() {
                let data = {
                    id: this.model.id,
                    temporaryId: this.model.temporaryId,
                    companyName: this.model.companyName,
                    address1: (this.model.address1 ?? "").trim().length > 0 ? (this.model.address1 ?? "").trim() : null,
                    address2: (this.model.address2 ?? "").trim().length > 0 ? (this.model.address2 ?? "").trim() : null,
                    address3: (this.model.address3 ?? "").trim().length > 0 ? (this.model.address3 ?? "").trim() : null,
                    city: (this.model.city ?? "").trim().length > 0 ? (this.model.city ?? "").trim() : null,
                    state: (this.model.state ?? "").trim().length > 0 ? (this.model.state ?? "").trim() : null,
                    stateCode: null,
                    postalCode: (this.model.postalCode ?? "").trim().length > 0 ? (this.model.postalCode ?? "").trim() : null,
                    countryID: this.model.countryID,
                    country: this.$vm.$countries.filter(x => x.Id == this.model.countryID)[0],
                    establishmentType: (this.model.companyName ?? "").trim().length > 0 ? 100 : 200
                };
                this.model.countryRecord = this.$vm.$countries.filter(x => x.Id == this.model.countryID)[0];
                if (this.model.countryRecord && this.model.countryRecord.useStateCode)
                    data.stateCode = this.model.stateCode;
                this.$emit("is-sync", data);
            }
        },
        created() {
            this.model = this.value;
        }
    }
</script>