<template>
    <div class="p-d-flex p-flex-wrap p-flex-row p-flex-md-wrap p-flex-md-row ">
        <div class="p-col" style="min-width: 500px">
            <form-lookup id="surchargeType" :source="sources.surcharges" sourceType="array" :required="true" :sorted="false" :value="model?.surchargeID" @is-sync="model.surchargeID = this.sources.surcharges.length > 0 ? $event.value : model.surchargeID; surchargeDetails = $event.record?.description" />
            <form-lookup v-if="model?.surchargeID" id="account" :source="sources.accounts" sourceType="array" :sorted="false" :value="model?.accountID" @is-sync="model.accountID = this.sources.surcharges.length > 0 ? $event.value : model.accountID" placeholder="Any Account" />
            <form-object v-if="model?.surchargeID" :id="id" :autoFit="autoFit" label="Services" :floatLabel="floatLabel && (!disabled || objBlank)">
                <pv-multiselect v-if="model?.surchargeID" v-model="model.services" :options="services" optionLabel="text" placeholder="All Services" optionValue="id" dataKey="id" display="chip" />
            </form-object>
            <div v-if="model?.surchargeID" class="p-d-flex p-nogutter">
                <div class="p-col">
                    <form-datetime-utc id="effectiveDateFrom" type="date" :value="model?.effectiveDateFrom" @is-sync="model.effectiveDateFrom = $event?.value ?? new Date($moment.utc())" :required="true" />
                </div>
                <div class="p-col">
                    <form-datetime-utc id="effectiveDateTo" type="date" :value="model?.effectiveDateTo" @is-sync="model.effectiveDateTo = $event?.value" />
                </div>
            </div>
            <div v-if="model?.surchargeID" class="p-d-flex p-nogutter">
                <div class="p-col-fixed" style="width:10rem">
                    <form-lookup id="rateType" source="ratetype" type="enum" :required="true" :sorted="false" :value="model?.rateType" @is-sync="onRateTypeChange($event, 'base')" />
                </div>
                <div class="p-col">
                    <form-numeric id="rateApplied" type="decimal" :max="model?.rateType == 200 ? 100 : 9999999999.99" :required="true" :value="model?.rateApplied" @is-sync="model.rateApplied = $event.value" />
                </div>
                <div class="p-col-fixed" style="width:11rem">
                    <form-lookup id="chargePer" label="Charge Per" source="surchargechargingtype" type="enum" :required="true" :sorted="false" :value="model?.rateChargeType" @is-sync="{ model.rateChargeType = $event.value;  model.minRateChargeType = ($event.value === 300 ? 100 : $event.value); model.rateChargeTypeWeightUnit = ($event.value === 300 ? 100 : null); }" />
                </div>
                <div v-if="model.rateChargeTypeWeightUnit" class="p-col-fixed" style="width:7rem">
                    <form-lookup id="chargePerWeightUnit" label="Weight Unit" source="weightunit" type="enum" :required="true" :sorted="false" :value="model?.rateChargeTypeWeightUnit" @is-sync="model.rateChargeTypeWeightUnit = $event.value;" />
                </div>
            </div>
            <div v-if="model?.rateType === 200" class="p-d-flex p-nogutter">
                <div class="p-col">
                    <form-lookup id="percentageBasedFrom" source="percentilebase" type="enum" :required="true" :sorted="false" :value="model?.ratePercentageBase" @is-sync="model.ratePercentageBase = $event.value" />
                </div>
            </div>
            <div v-if="model?.surchargeID && model.rateApplied > 0" class="p-d-flex p-nogutter">
                <div class="p-col-fixed" style="width:10rem">
                    <form-lookup id="rateType" source="ratetype" type="enum" :required="true" :sorted="false" :showCopy="false" :value="model?.minRateType" :disabled="true" @is-sync="onRateTypeChange($event, 'min')" />
                </div>
                <div class="p-col">
                    <form-numeric id="minimumCharge" type="decimal" :max="model?.minRateType == 200 ? 100 : 9999999999.99" :value="model?.minRateApplied" @is-sync="model.minRateApplied = $event.value" />
                </div>
                <!--<div class="p-col-fixed" style="width:11rem">
                    <form-lookup id="rateType" label="Charge Per" source="ratechargingtype" type="enum" :required="true" :sorted="false" :value="model?.minRateChargeType"  />
                </div>-->
            </div>
            <!--<div v-if="model?.surchargeID && model.rateApplied > 0" class="p-d-flex p-nogutter">
                <div class="p-col-fixed" style="width:10rem">
                    <form-lookup id="rateType" source="ratetype" type="enum" :required="true" :sorted="false" :showCopy="false" :value="model?.maxRateType" :disabled="true" @is-sync="onRateTypeChange($event, 'max')" />
                </div>
                <div class="p-col">
                    <form-numeric id="maximumCharge" type="decimal" :max="model?.maxRateType == 200 ? 100 : 9999999999.99" :value="model?.maxRateApplied" @is-sync="model.maxRateApplied = $event.value" />
                </div>
                <div class="p-col-fixed" style="width:11rem">
                    <form-lookup id="services" label="Charge Per" source="ratechargingtype" type="enum" :required="true" :sorted="false" :value="model?.maxRateChargeType" @is-sync="model.maxRateChargeType = $event.value" />
                </div>
            </div>-->
            <div v-if="surchargeDetails" style="background: var(--bg-info); padding: 1rem; text-align: justify; color: var(--button-text);">
                <h5 style="text-transform: uppercase;">Surcharge Details</h5>
                <div style="max-height: 18rem; overflow-y: auto; margin: -1rem; padding: 1rem;">
                    <span v-html="surchargeDetails?.replaceAll('\\r\\n','<br />')"></span>
                </div>
            </div>
        </div>
        <div class="p-col" style="min-width: 400px; max-height: 70vh; overflow-y: auto">
            <markup-ruleconditions-form :parentID="model?.id"
                                        :source="conditions"
                                        :currencyCode="currencyCode"
                                        :countryCode="countryCode"
                                        @is-sync="model.conditions = $event" />
        </div>
    </div>
</template>
<script>
    import markupruleconditionsform from '@/components/facility/rates/surcharge-rates/ruleconditions-form.vue';
    export default {
        name: 'SurchargeRuleDetailForm',
        components: { 'markup-ruleconditions-form': markupruleconditionsform },
        props: {
            source: { type: Object }, // This should only have value for
            serviceProviderID: { type: Number, default: null }, // Service Provider Root
            surchargeProviderID: { type: Number, default: null },
            currencyCode: { type: String },
            countryCode: { type: String }
        },
        data() {
            return {
                model: {
                    id: 0,
                    surchargeProviderID: this.source?.surchargeProviderID,
                    serviceProviderID: this.serviceProviderID,
                    providerCarrierID: null,
                    accountID: null,
                    surchargeID: 0,
                    effectiveDateFrom: new Date(this.$filters.toDateDisplay(new Date())),
                    effectiveDateTo: null,
                    rateType: 100,
                    rateApplied: 0,
                    rateChargeType: 100,
                    ratePercentageBase: 100,
                    minRateType: 100,
                    minRateApplied: 0,
                    minRateChargeType: 100,
                    minRatePercentageBase: 100,
                    maxRateType: 100,
                    maxRateApplied: 0,
                    maxRateChargeType: 100,
                    maxRatePercentageBase: 100,
                    conditions: [],
                    services: []
                },
                sources: {
                    surcharges: [],
                    accounts: [],
                    services: [],
                    accountServices: []
                }
            }
        },
        computed: {
            services() {
                if (this.model.accountID)
                    return this.sources.accountServices.filter(x => x.accountID == this.model.accountID);
                return this.sources.services;
            },
            conditions() { return this.model?.conditions ?? []; }
        },
        watch: { model: { handler() { this.$emit("is-sync", this.model); }, deep: true } },
        methods: {
            onRateTypeChange(e, type) {
                switch (type) {
                    case "min":
                        this.model.minRateType = e.value;
                        if (this.model.minRateType === 200 && this.model.minRateApplied > 100)
                            this.model.minRateApplied = 100;
                        break;
                    case "max":
                        this.model.maxRateType = e.value;
                        if (this.model.maxRateType === 200 && this.model.maxRateApplied > 100)
                            this.model.maxRateApplied = 100;
                        break;
                    default:
                        this.model.rateType = e.value;
                        if (this.model.rateType === 200 && this.model.rateApplied > 100)
                            this.model.rateApplied = 100;
                        break;
                }
            },
            onSourceRebind() {
                if (this.serviceProviderID) {
                    this.$store.dispatch("rateconditiontype/getURL", { url: `/services/admin/serviceprovideraccount/byprovider/id/${this.serviceProviderID}` }).then((response) => {
                        this.sources.accounts = response.result;
                    });
                    this.$store.dispatch("rateconditiontype/getURL", { url: `/services/settings/serviceprovidersurcharge/byprovider/id/${this.serviceProviderID}` }).then((response) => {
                        this.sources.surcharges = response.result;
                        if (this.model.surchargeID === 0 || this.model.surchargeID === null || this.model.surchargeID === undefined)
                            this.model.surchargeID = (this.sources?.surcharges ?? []).filter(x => x.code === 'FUEL')[0]?.id;
                    });
                    this.$store.dispatch("rateconditiontype/getURL", { url: `/services/settings/serviceproviderproduct/byprovider/id/${this.serviceProviderID}` }).then((response) => {
                        this.sources.services = response.result;
                    });
                    this.$store.dispatch("rateconditiontype/getURL", { url: `/services/admin/serviceprovideraccountproduct` }).then((response) => {
                        this.sources.accountServices = response.result.map(m => ({ id: m.productID, text: m.text, accountID: m.accountID }));
                    });
                    if ((this.model?.id ?? 0) > 0)
                        this.$store.dispatch("providersurchargerulecondition/getURL", { url: `/services/facility/providersurchargerulecondition/byrule/id/${this.model.id}` })
                            .then((response) => {
                                this.model.conditions = [];
                                if (response.success)
                                    this.model.conditions = [...response.result];
                            });
                }
            }
        },
        created() {
            this.model = this.$filters.init(this.source ?? {
                id: 0,
                surchargeProviderID: this.modelValue?.surchargeProviderID,
                serviceProviderID: this.serviceProviderID,
                providerCarrierID: null,
                accountID: null,
                surchargeID: 0,
                effectiveDateFrom: new Date(this.$filters.toDateDisplay(new Date())),
                effectiveDateTo: null,
                rateType: 100,
                rateApplied: 0,
                rateChargeType: 100,
                ratePercentageBase: 100,
                minRateType: 100,
                minRateApplied: 0,
                minRateChargeType: 100,
                minRatePercentageBase: 100,
                maxRateType: 100,
                maxRateApplied: 0,
                maxRateChargeType: 100,
                maxRatePercentageBase: 100,
                currencyCode: this.currencyCode,
                countryCode: this.countryCode,
                conditions: [],
                services: []
            });
            if (this.source)
                this.model.services = this.source.services;
            this.$nextTick(() => {
                this.onSourceRebind();
            });
        },
        mounted() {
            this.$nextTick(() => {
                this.onSourceRebind();
            });
        }
    }
</script>