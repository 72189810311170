<template>
    <cosmos-grid id="ReturnDocumentGrid" sourceType="array" :source="source" :showHeader="false" :showOperation="false" :showCheckbox="false">
        <pv-grid-column field="documentType" header="DocumentType" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ documenttypes.filter(x => x.id === Number(slotProps.data?.documentType))[0]?.text }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="fileName" header="Filename" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important; font-weight: bold;">
                        <a :href="`${config.endpoint.cdn}/${tenants.filter(x => x.id == slotProps.data.tenantID)[0].code.toLowerCase()}/return/documents/${slotProps.data?.fileName}`" target="_blank">{{ slotProps.data?.fileName }}</a>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="contentType" header="Content Type" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.contentType }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="carrierReference" header="Issued Reference">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.carrierReference }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
    </cosmos-grid>
</template>
<script>
    const config = require('@/assets/lib/cosmos/_js/config.js').default;
    export default {
        name: 'ReturnDocumentGridTemplate',
        props: {
            parentID: { type: Number },
        },
        data() {
            return {
                source: []
            }
        },
        computed: {
            documenttypes() { return this.$vm.$enums.documenttype; },
            tenants() { return config.common.$tenant(); },
            config() { return config.config; }
        },
        watch: {
            parentID() {
                this.getSource()
            }
        },        methods: {
            getSource() {
                this.$store.dispatch("returns/getURL", { url: `/services/tms/return/${this.parentID}/documents` })
                    .then((response) => {
                        this.source = response?.result ?? [];
                    });
            }
        },
        async created() {
            this.getSource()
        }
    }
</script>