<template>
    <pv-card>
        <template #title>
            Service Group
        </template>
        <template #subtitle>
            Please provide service group details.
        </template>
        <template #content>
            <div class="p-d-flex p-grid p-col-12">
                <div class="p-col-12 p-md-4">
                    <form-input id="code" :v$="v$" v-focus v-uppercase :required="true" :value="model.code" @is-sync="model.code = $event.value" :max-length="20"></form-input>
                    <form-input id="name" :v$="v$" :value="model.name" :required="true" @is-sync="model.name = $event.value" :max-length="100"></form-input>
                    <form-input id="description" type="textarea" label="Description" :v$="v$" :value="model.description" @is-sync="model.description = $event.value ?? model.description" :max-length="255" />
                    <form-lookup id="customerID" label="Customer" :required="true" :source="customers" :value="model.customerID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="model.customerID = $event.value" />
                </div>
            </div>
        </template>
        <template #footer>
            <div class="p-grid p-nogutter p-justify-between">
                <i></i>
                <pv-button label="Next" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" :disabled="!isValid" />
            </div>
        </template>
    </pv-card>
</template>

<script>
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from '@vuelidate/validators';
    import { mapGetters } from 'vuex';
    //import validator from '@/assets/_js/cosmos-validators.js';
    //import { max } from 'moment';
    export default {
        name: 'Step1Recorder',
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                model: {
                    code: null,
                    name: null,
                    description: null,
                    customerID: null,
                    accessScopes: [],
                    keys: null,
                    session: null
                }
            }
        },
        validations() {
            return {
                model: {
                    code: { required, minLength: minLength(1), maxLength: maxLength(20), $autoDirty: true },
                    name: { required, minLength: minLength(1), maxLength: maxLength(255), $autoDirty: true },
                    customerID: { required, $autoDirty: true }
                }
            }
        },
        watch: {
            $route(to) {
                if (to.name === "servicegroup-details") {
                    if (this.user?.mask)
                        this.accessScopes = this.$config.common.$accessScope(this.user?.mask);
                }

            },
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                    if (this.user?.mask)
                        this.accessScopes = this.$config.common.$accessScope(this.user?.mask);
                    
                });
            }

        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            customers() { return (this.accessScopes?.customers ?? []) },
            warehouses() {
                return (this.accessScopes?.warehouses ?? [])
                    .filter(wh => (this.model.customerID ?? 0) === 0 || ((this.model.customerID ?? 0) > 0 && (wh.customers ?? []).filter(cust => cust === (this.model.customerID ?? 0)).length > 0)) ?? [];
            },
            isValid() {
                return !this.v$.$invalid;
            }
        },
        methods: {
            nextPage() {
                
                if (!this.v$.$invalid) {
                    this.$emit('next-page', { formData: this.model, pageIndex: 0 });
                }
            },
            prevPage(event) {
                this.$router.push(this.items[event.pageIndex - 1].to);
            }
        },
        created() {
            if (this.user?.mask)
                this.accessScopes = this.$config.common.$accessScope(this.user?.mask);
        },
        mounted() {
            let _self = this;
            if (this.user?.mask)
                this.accessScopes = this.$config.common.$accessScope(this.user?.mask);
            _self.model.customerID = _self.customers[0]?.id;
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
        
    }
</script>
