<template>
    <div v-for="item in model" :key="item?.parcelID">
        <quote-parcel-line :header="parentHeader" :item="item" :index="item?.parcelID" :itemCount="model.length" @is-sync="updaterecord(item?.parcelID ?? -1, $event)"
                           @is-add="onAdd"
                           @is-delete="onDelete($event)"
                           @is-copy-next="onCopyNext($event)"
                           @is-copy="onCopyValue($event)"
                           />
    </div>
</template>

<script>
    import parcelline from '@/components/facility/rates/quote-parcelline-form.vue';
    export default {
        name: 'QuoteParcels',
        components: {
            'quote-parcel-line': parcelline
        },
        props: {
            header: { type: Object },
            source: { type: Array }
        },
        data() {
            return {
                model: []
            }
        },
        computed: {
            parentHeader() {
                return this.header;
            }
        },
        watch: {
            source() {
                this.model = this.source;
            },
        },
        methods: {
            updaterecord(parcelID, e) {
                //console.log(parcelID);
                //console.log(e);
                //this.model[parcelID] = this.$filters.init(e);
                //this.model[parcelID - 1] = e;
                this.$nextTick(() => this.$emit("is-sync", { parcelID: parcelID, model: e }));
            },
            onAdd() { this.$emit("is-add"); },
            onDelete(index) {
                this.$emit("is-delete", index);
            },
            onCopyNext(index) { this.$emit("is-copy-next", index); },
            onCopyValue(e) { this.$emit("is-copy", e); },
        },
        mounted() {
            this.model = this.source;
        }
    }
</script>

