<template>
    <form-record :model="model" :title="(model?.configuration?.basicInfo?.code ?? '').indexOf('TEMP_') !== -1 ? 'Edit Dynamic Report' : model?.configuration?.basicInfo?.code" :modelState="v$" @is-reset="resetRecord" :viewOnly="model.disabled">
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <form-input id="name" v-model="model.configuration.basicInfo.name" label="Report Name" :disabled="model.disabled" :required="true" :showCopy="false" />
                <form-input id="description" type="textarea" v-model="model.configuration.basicInfo.description" :disabled="model.disabled" label="Report Description" :showCopy="false" />
            </div>
            <div class="p-col-12  p-md-6">
                <form-lookup id="privacy" v-model="model.configuration.basicInfo.privacy" :disabled="model.disabled" type="enum" source="dynamicreportprivacy" :nullable="false" disabledType="object" />
                <form-lookup id="status" v-model="model.configuration.basicInfo.status" :disabled="model.disabled" type="enum" source="dynamicreportstatus" :nullable="false" disabledType="object" />
                <div v-if="model.disabled"><i class="fa fa-lock fa-fw"></i> The report is shared but does not allow change except the author.</div>
            </div>
        </div>
        <pv-tab style="margin: 0 -1rem;">
            <pv-tabpanel>
                <template #header>
                    <div class="p-d-flex p-ai-center gap-2">
                        <i class="fa fa-bars fa-fw mx-2"></i>
                        <span style="font-weight: 700; white-space: nowrap; margin-left: 0.5rem;">Fields</span>
                    </div>
                </template>
                <customization-form v-model="model.fields" :parentID="model.id" :disabled="model.disabled" />
            </pv-tabpanel>
            <pv-tabpanel>
                <template #header>
                    <div class="p-d-flex p-ai-center gap-2">
                        <i class="fa fa-table fa-fw mx-2"></i>
                        <span style="font-weight: 700; white-space: nowrap; margin-left: 0.5rem;">Viewer</span>
                    </div>
                </template>
                <div style="padding: 1.5rem 1rem 0 1rem;">
                    <p style="margin-bottom: 1rem; font-weight: 700;">For dynamic report portal viewer, here are the available filters:</p>
                    <viewer-form v-model="model.configuration.viewer" :parentID="model.id" :disabled="model.disabled" />
                </div>
            </pv-tabpanel>
            <pv-tabpanel>
                <template #header>
                    <div class="p-d-flex p-ai-center gap-2">
                        <i class="fa fa-cogs fa-fw mx-2"></i>
                        <span style="font-weight: 700; white-space: nowrap; margin-left: 0.5rem;">Automation</span>
                    </div>
                </template>
                <div style="padding: 1.5rem 1rem 0 1rem;">
                    <p style="margin-bottom: 1rem; font-weight: 700;">Here are the list of automated export:</p>
                    <pre>
LIST OF AUTOMATED CONFIGURATION SHOULD SHOW HERE.
UNDER DEVELOPMENT
</pre>
                </div>
            </pv-tabpanel>
        </pv-tab>
    </form-record>
</template>
<script>
    import customForm from '@/components/facility/report/custom-form.vue';
    import viewerForm from '@/components/facility/report/viewer-form.vue';
    import { useVuelidate } from '@vuelidate/core';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    export default {
        name: "",
        setup: () => ({ v$: useVuelidate() }),
        mixins: [mixins.RECORD],
        components: {
            'customization-form': customForm,
            'viewer-form': viewerForm
        },
        data() {
            return {
                dialog: {
                    add: false
                }
            }
        },
        watch: {
            'model.id': {
                handler() {
                    if (this.model.fields.length === 0)
                        this.onGetFields(this.model.id);
                }
            }
        },
        methods: {
            onGetFields(parentID) {
                if (parentID > 0)
                    this.$store.dispatch("dynamicreport/getURL", { url: `/services/facility/dynamicreportfield/${parentID ?? this.model.id}/report`.replaceAll("//", "/") })
                        .then((response) => {
                            this.model.fields = response?.result;
                        });
            },
            onRowReorder(event) {
                event.value.forEach((value, index) => {
                    value.sortOrder = index + 1;
                })
                this.model.fields = event.value;
            }
        },
        mounted() {
            if (this.model.fields.length === 0)
                this.onGetFields(this.model.id);

        }
    }
</script>
<style>
    .is-customized.p-datatable.p-component.p-datatable-scrollable tr > td:not(:first-child),
    .is-customized.p-datatable.p-component.p-datatable-scrollable tr > td:not(:first-child) {
        width: 100% !important;
    }

    .is-customized.p-datatable.p-component.p-datatable-scrollable tr > td:first-child,
    .is-customized.p-datatable.p-component.p-datatable-scrollable tr > th:first-child {
        max-width: 2rem !important;
    }
    .is-customized.p-datatable.p-component.p-datatable-scrollable tr.p-datatable-emptymessage > td {
        max-width: 100% !important;
    }
    .is-customized .p-datatable.p-component.p-datatable-scrollable tr td:not(:first-child) {
        width: 100% !important;
        flex: none;
    }
    .is-customized .p-datatable.p-component.p-datatable-scrollable tr th:not(:first-child) {
        width: 100% !important;
        flex: none;
    }
</style>