<template>
    <is-dialog header="Delete Record?" :visible="dialog.delete && !dialog.edit && !dialog.add" @is-confirm="onManageAlertScope(model)" @is-cancel="this.dialog.delete = false; this.dialog.add = false; this.dialog.edit = false;">
        <div class="confirmation-content p-d-flex">
            <div>
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            </div>
            <div>
                You are about to delete this record:

                <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                    <tr v-for="data in deleteDisplay" :key="data.id">
                        <th style="text-align:left;">{{ $filters.titleize(data).replace("Text","") }}</th>
                        <td>:</td>
                        <td> {{ forDeleteAlert[data] }}</td>
                    </tr>
                </table>

                Please be aware of the following:
                <ul>
                    <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                    <li>For records that has activity, the record will only be disabled and archived.</li>
                    <li>Also deleting a record may be irreversible action.</li>
                </ul>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>

    <div v-if="!manageCountry">
        <!-- sourceType="array" :source="(source?.data ?? []).filter(x => (x?.parentID ?? 0) <= 0)" -->
        <cosmos-grid id="CompanyAlertScopeList" sourceType="url" :source="`/services/settings/companyalertscope/alert/id/${((this.$props.formData?.parentID ?? 0 > 0) ? this.$props.formData?.parentID : this.$props.formData?.id)}/`" @is-source="onSyncSource($event)" :forceRefresh="forceRefresh" :showCheckbox="false" :sourcePaged="true" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showExport="false" :showDelete="false" :showEdit="false" :showHeader="false" :showOperation="false">
            <pv-grid-column field="countryName" header="Country Name" headerStyle="min-width: 5rem; max-width: 13rem; width: 5rem" style="min-width: 5rem; max-width: 13rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
                <template #body="slotProps">
                    <div class="p-d-flex">
                        <div>
                            <div class="p-d-flex p-jc-between" style="min-width: 7rem; margin-bottom: 5px;">
                                <div v-if="slotProps.data.countryID"><span :class="`flag flag-${slotProps.data?.countryCode?.toLowerCase()}`" style="width: 30px; height: 20px;"></span> <span> {{slotProps.data?.countryCode  }}, {{slotProps.data?.countryName  }} </span></div>
                                <div v-else>{{ slotProps.data?.countryName }}</div>
                            </div>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="templateID" header="Template" headerStyle="min-width: 5rem; max-width: 13rem; width: 5rem" style="min-width: 5rem; max-width: 13rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
                <template #body="slotProps">
                    <div class="p-d-flex" style="padding:0 !important; margin:0 !important;">
                        <div class="p-col-12" style="width:100%;padding:0 !important; margin: 0 !important;">
                            <div class="p-col">
                                <div v-if="!slotProps.data.countryID">
                                    <div style="padding:1rem;">{{ slotProps.data?.templateName }} </div>
                                </div>
                                <div v-else>
                                    <grid-lookup id="`templateID_${slotProps.data?.id}`" :required="true" sourceType="array" :source="sourceData.filter(x => x.parentID)" :textField="'templateName'" :valueField="'id'" :value="slotProps.data.templateID" :hideLabel="true" @is-sync="onChangeTemplate($event,slotProps.data)" :v$="v$" :sorted="false"></grid-lookup>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="isActive" header="Status" headerStyle="min-width: 6rem; max-width: 6rem; width: 6rem" style="width: 6rem; min-width: 6rem; max-width: 6rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; ">
                <template #body="slotProps">
                    <div class="p-d-flex" style="padding:0 !important; margin:0 !important;">
                        <div class="p-col-12" style="min-width:6rem;width:100%;padding:0 !important; margin: 0 !important;">
                            <div class="p-col">
                                <pv-toggle v-model="slotProps.data.isActive" @change="onSetStatus($event,slotProps?.data)" :style="[{'width': '8rem'}, {'object-position': 'bottom'}, {'align-content': 'end' }]" class="p-inputgroup-prepend" :onIcon="'pi pi-check'" :offIcon="'pi pi-times'" :onLabel="'ACTIVE'" :offLabel="'INACTIVE'"></pv-toggle>
                            </div>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="mask" headerStyle="min-width: 3.5rem; max-width: 3.5rem; width: 3.5rem" style="`min-width: 3.5rem; max-width: 3.5rem; border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px; " :frozen="true" :alignFrozen="'right'">
                <template #body="slotProps">
                    <div class="p-d-flex" style="min-width: 3.5rem;">
                        <div v-if="slotProps.data.countryID" class="p-col">
                            <pv-button icon="pi pi-trash" iconPos="right" @click="onAlertScopeDelete(slotProps.data)" class="p-button-danger" />
                        </div>
                    </div>
                </template>
            </pv-grid-column>
        </cosmos-grid>
    </div>
    <div v-else>
        <pv-picklist v-if="manageCountry" v-model="items" listStyle="height:342px" dataKey="id" @move-to-target="moveToTarget" @move-all-to-target="moveAllToTarget" @move-to-source="moveToSource" @move-all-to-source="moveAllToSource">
            <template #sourceHeader>
                <div class="p-d-flex" style="width: 100%;">
                    <div class="p-col-4" style="padding-top: 10px;">
                        Available Countries
                    </div>
                    <div class="p-col-8" style=" margin: 0; padding: 0;">
                        <pv-input style="margin: 0 10px; width: 100%;" v-model="filter.source" placeholder="Type your keyword..." />
                    </div>
                </div>
            </template>
            <template #targetHeader>
                <div class="p-d-flex" style="width: 100%;">
                    <div class="p-col-4" style="padding-top: 10px;">
                        Selected Countries
                    </div>
                    <div class="p-col-8" style=" margin: 0; padding: 0;">
                        <pv-input style="margin: 0 10px; width: 100%;" v-model="filter.target" placeholder="Type your keyword..." />
                    </div>
                </div>
            </template>
            <template #item="slotProps">
                <div class="picklist-item">
                    <div class="picklist-list-detail" style="margin: 0; padding: 0;">
                        <div v-if="activity.loading" class="p-field p-col-12 p-md-12" style="width: 100%; padding: 0; margin: 0;">
                            <div class="custom-skeleton">
                                <ul class="p-m-0 p-p-0">
                                    <li>
                                        <div class="p-d-flex">
                                            <div style="flex: 1">
                                                <pv-skeleton width="100%" class="p-mb-2"></pv-skeleton>
                                                <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-else class="p-d-flex" style="width: 100%; padding: 0;">
                            <div style="width: 100%; padding: 0; margin: 0;">
                                <div class="p-d-flex p-jc-between" style="width: 100%; z-index: 100;">
                                    <div><h5 style="margin-bottom: 5px; margin-top: 0; font-size: 1.2rem;"> <span :class="`flag flag-${slotProps.item.code.toLowerCase()}`" style="width: 30px; height: 20px;"></span> <span> {{slotProps.item.altName ?? slotProps.item.name  }} </span></h5></div>
                                    <div class="p-as-end"><h5 class="p-code-text">[{{ slotProps.item.iso2}}]</h5></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </pv-picklist>
    </div>
</template>
<style>
</style>
<script>
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
   
    import { FilterMatchMode } from 'primevue/api';
    const moduleName = 'companyalertscope';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        props: {
            formData: { type: Object },
            sourceData: { type: Object },
            manageCountry: { type: Boolean, default: false }
        },
        data() {
            return {
                expandedRows: [],
                editingRows: [],
                originalRows: null,
                filename: 'export',
               // filter: {},
                filters: {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                },
                searchFilter: ['code', 'name'],
                forDelete: null,
                selectedItems: [],
                activity: { loading: true },
                dialog: {
                    header: "Delete Record?",
                    add: false,
                    edit: false,
                    delete: false
                },
                forceRefresh: false,
                forDeleteAlert: null,
                forDeleteItem: null,
                deleteDisplay: ["template", "country"],
                itemDialog: {
                    header: "Delete Record?",
                    add: false,
                    edit: false,
                    delete: false
                },
                source: {
                    total: 0,
                    data: []
                },
                available: [],
                assigned: [],
                filter: {
                    source: null,
                    target: null
                }
                

            }
        },
        watch: {
            v$: {
                handler() { this.modelState = this.v$ }, deep: true
            },
            'formData': {
                handler() {
                    //if (this.source.data.length === 0 && this.$props?.formData?.id > 0) {
                    //    this.getAlertScopes();
                    //}
                }, deep: true
            },
            sourceData: {
                handler() {
                    //this.$nextTick(() => {
                    //      this.getAlertScopes();
                    //});
                   
                }, deep: true
            },
            manageCountry: {
                handler() {
                    //this.$nextTick(() => {
                    //    this.getAlertScopes();
                    //});

                }, deep: true
            }
        },
        validations() {
            return {
                model: {
                    /*   code: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },*/
                    //code: { required, minLength: minLength(1), maxLength: maxLength(50), $autoDirty: true },
                    //name: { required, minLength: minLength(1), maxLength: maxLength(50), $autoDirty: true },
                    //weight: { required, decimal, between: between(0.001, 9999999999.999), $autoDirty: true },
                    //consignmentValue: { required, decimal, between: between(0.01, 9999999999.99), $autoDirty: true },
                    //length: { decimal, between: between(0.00, 9999999999.99), $autoDirty: true },
                    //width: { decimal, between: between(0.00, 9999999999.99), $autoDirty: true },
                    //height: { decimal, between: between(0.00, 9999999999.99), $autoDirty: true }
                }
            }
        },
        computed: {
            modelInit() {
                return {
                    id: 0,
                    companyAlertID: null,
                    templateID:null,
                    countryID: null,
                    isActive: false,
                    customerID: null,
                    code: null,
                    name: null,
                    mask: null,
                    environmentID: null
                };
            },
            alertRecord() { return this.$store.getters["companyalertscope/record"]; },
            alertErrors() { return this.$store.getters["companyalertscope/errors"]; },
            countrySource() {
                return this.$vm.$countries;
            },
            filteredSource() {
                let _available = this.available;
                if (this.filter.source && this.filter.source.trim() !== "" && this.filter.source.trim().length >= 2) {
                    _available = _available.filter(x => x.code?.toLowerCase().includes(this.filter.source.toLowerCase()) || x.name?.toLowerCase().includes(this.filter.source.toLowerCase()));
                }
                return _available;
            },
            filteredTarget() {
                let _assigned = this.assigned;
                if (this.filter.target && this.filter.target.trim() !== "" && this.filter.target.trim().length >= 2) {
                    _assigned = _assigned.filter(x => x.code?.toLowerCase().includes(this.filter.target.toLowerCase()) || x.name?.toLowerCase().includes(this.filter.target.toLowerCase()));
                }
                return _assigned;
            },
            items() {
                return [this.filteredSource, this.filteredTarget];
            },

            
        },
        methods: {
            async update(modulename, model) { return await this.$store.dispatch(modulename + "/modifyRecord", model); },
            async insert(modulename, model) { return await this.$store.dispatch(modulename + "/addRecord", model); },
            async delete(modulename, mask) { return await this.$store.dispatch(modulename + "/deleteRecordByMask", mask); },
            async getEntity(modulename, mask) { return await this.$store.dispatch(modulename + "/getRecord", mask); },
            async getAlertScopes() {
                if (this.$props.formData?.id > 0) {
                    if (((this.$props.formData?.parentID ?? 0 > 0) ? this.$props.formData?.parentID : this.$props.formData?.id) > 0) {
                        await this.$axios
                            //.get((this.$config.config.endpoint.api + `/services/settings/` + moduleName + `/` + (!this.manageCountry ? 'alert' : 'template') + `/id/${this.$props.formData?.id}?` + Math.random().toString()).replaceAll("//", "/"))
                            .get((this.$config.config.endpoint.api + `/services/settings/` + moduleName + `/alert/id/${((this.$props.formData?.parentID ?? 0 > 0) ? this.$props.formData?.parentID : this.$props.formData?.id)}`).replaceAll("//", "/"))
                            .then((response) => {
                                this.source.data = response.data.result;
                                this.source.total = response.data.result.length;
                                this.$emit('is-sync', this.source.data);
                                if (this.manageCountry) {
                                    let ids = [];
                                    let excludeIDs = [];
                                    ids = this.source.data.filter(x => x.templateID === this.$props.formData?.id).map(x => x.countryID); // COUNTRY ASSIGN TO TEMPLATE
                                    excludeIDs = this.source.data.filter(x => x.templateID !== this.$props.formData?.id).map(x => x.countryID); //  COUNTRY ASSIGN TO OTHER TEMPLATE
                                    this.available = this.countrySource.filter(x => ids.indexOf(x.id) === -1 && excludeIDs.indexOf(x.id) === -1);
                                    this.assigned = this.countrySource.filter(x => ids.indexOf(x.id) !== -1);
                                    this.activity.loading = false;
                                }

                            })
                        //.catch((ex) => {
                        //    this.errors = ex.data;
                        //});
                    }
                }  
            },
            onSyncSource(data) {
                this.source = data;
                console.log(data);
            },
            onRowExpand() {
            },
            onRowCollapse() {
            },
            expandAll() {
                this.expandedRows = this.source.data.filter(p => p.id);
            },
            collapseAll() {
                this.expandedRows = null;
            },
            onRowEditInit(event) {
                this.originalRows[event.index] = { ...this.source.data[event.index] };
            },
            onRowEditCancel(event) {
                this.source.data[event.index] = this.originalRows[event.index];
            },
            onExport() {

            },
            rowClick(e) {
                if (this.isDisabled)
                    e.preventDefault();
                this.onAlertEdit(e.data);
            },
            onAlertScopeDelete(data) {
                this.model = this.$filters.init(data);
                this.dialog.delete = true;
                this.forDeleteAlert = this.model;
                this.forDeleteAlert.country = '[' + this.model.countryCode + '] ' + this.model.countryName;
                this.forDeleteAlert.template = this.model.templateName;
            },
            onManageAlertScope(e) {
                var location = this.$filters.init(e);
                var index = -1;
                if (location.id > 0)
                    index = this.source.data.findIndex(x => x.id === location.id);
                else
                    index = this.source.data.findIndex(x => x.temporaryID === location.temporaryID);
                if (index === -1) {
                    this.source.data.push(this.$filters.init(e));
                    if (this.$route.params.mask ?? this.formData.mask) {
                        this.insert("companyalertscope", this.model).then(() => {
                            if (this.alertErrors) {
                                this.source.data.splice(index, 1);
                                this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: this.alertErrors[0].message, life: 3000 });
                            }
                            else {
                                index = this.source.data.findIndex(x => x.temporaryID === location.temporaryID);
                                this.getEntity("companyalertscope", this.alertRecord.mask).then(() => {
                                    this.source.data[index] = this.$filters.init(this.alertRecord);
                                    this.source.total = this.source.data.length;
                                });
                                this.$toast.add({ severity: 'success', summary: 'Record Insert Success!', detail: 'The record was added successfully.', life: 3000 });
                            }
                        }).catch((err) => {
                            this.source.data.splice(index, 1);
                            this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: err.xhr, life: 3000 });
                        });
                    }
                }
                else {
                    if (this.dialog.edit || this.dialog.add) {
                        this.source.data[index] = this.$filters.init(e);
                        if (location.mask !== null && location.mask !== "") {
                            this.update("companyalertscope", this.model).then(() => {
                                if (this.errors)
                                    this.$toast.add({ severity: 'error', summary: 'Record Update Failed!', detail: this.errors[0].message, life: 3000 });
                                
                            }).catch((err) => {
                                this.$toast.add({ severity: 'error', summary: 'Record Update Failed!', detail: err.xhr, life: 3000 });
                            });
                        }
                    }
                    else {
                        this.source.data.splice(index, 1);
                        if (location.mask !== null && location.mask !== "") {
                            this.delete("companyalertscope", location.mask).then(() => {
                                if (this.errors)
                                    this.$toast.add({ severity: 'error', summary: 'Record Delete Failed!', detail: this.errors[0].message, life: 3000 });

                                this.forceRefresh = true;
                               
                            }).catch((err) => {
                                this.$toast.add({ severity: 'error', summary: 'Record Delete Failed!', detail: err.xhr, life: 3000 });
                            });
                        }
                    }
                }
                this.source.total = this.source.data.length;
                this.model = this.modelInit;
                this.dialog.delete = false;
                this.dialog.edit = false;
                this.dialog.add = false;
                this.dialog.header = "Delete Record?";
                this.forceRefresh = false;
            },
            //onAlertPreview(e) {
            //    console.log(e);
            //},
            //onAddLanguage(parentAlert) {
            //    this.dialog.header = "Add Default Package";
            //    this.model = {
            //        id: 0,
            //        temporaryID: Number(Math.random().toString().replace(".", "")),
            //        alertType: 100,
            //        parentID: parentAlert.temporaryID > 0 ? parentAlert.temporaryID : parentAlert.id,
            //        parentLanguageID: parentAlert.languageID,
            //        languageID: 1,
            //        countryID: null,
            //        senderName: parent.senderName,
            //        senderEmail: parent.senderEmail,
            //        subject: parentAlert.subject,
            //        body: parentAlert.body,
            //        isActive: false,
            //        includeAttachment: false,
            //        customerID: this.$props.formData.id,
            //        code: ('TEMP' + Math.random().toString().replace(".", "")).slice(0, 20),
            //        name: ('TEMP' + Math.random().toString().replace(".", "")).slice(0, 20),
            //        mask: null
            //    };

            //    this.model.languageID = this.languageFilter[0]?.id ?? 1;
            //    this.dialog.delete = true;
            //    this.dialog.add = true;
            //},
            async onChangeTemplate(event, source) {
                if (event) {
                    if (event.value != null) {
                        if (source.templateID != event.value) {
                            source.templateID = event.value;
                            this.update("companyalertscope", source);
                        }
                    }
                }
            },
            async onSetStatus(event, source) {
                this.$nextTick(() => {
                    if (event) {
                        if (event.target != null) {
                            console.log('dumaan');
                            this.update("companyalertscope", source);
                        }
                    }
                });   
            },
            async moveToTarget(e) {
                e.items.forEach(x => {
                    this.assigned.push(x);
                    let index = this.available.findIndex(f => f.id === x.id);
                    if (index !== -1) {
                        this.available.splice(index, 1);
                    }
                    this.populateModel(x, true);
                });
            },
            async moveAllToTarget(e) {
                e.items.forEach(x => {
                    this.assigned.push(x);
                    let index = this.available.findIndex(f => f.id === x.id);
                    if (index !== -1) {
                        this.available.splice(index, 1);
                    }
                    this.populateModel(x, true);
                });

            },
            async moveToSource(e) {
                e.items.forEach(x => {
                    this.available.push(x);
                    let index = this.assigned.findIndex(f => f.id === x.id);
                    if (index !== -1) {
                        this.assigned.splice(index, 1);
                    }
                    this.populateModel(x, false);

                });
            },
            async moveAllToSource(e) {
                e.items.forEach(x => {
                    this.available.push(x);
                    let index = this.assigned.findIndex(f => f.id === x.id);
                    if (index !== -1) {
                        this.assigned.splice(index, 1);
                    }
                    this.populateModel(x, false);
                });
            }, 
            async postRecord(_model) {
                await this.$store.dispatch("companyalertscope/addRecord", _model).then(() => {
                    let index = this.source.data.findIndex(f => f.countryID === _model.countryID);
                    if (index !== -1)
                        this.source.data[index] = this.record;
                })
                    .catch((ex) => {
                        this.errors = ex.data;
                    });
            },
            async deleteRecord(_model) {
                let _self = this;
                await this.$store.dispatch("companyalertscope/deleteRecordByMask", _model.mask).then(() => {
                    let index = this.source.data.findIndex(f => f.countryID === _model.countryID);
                    if (index !== -1) {
                        this.model.splice(index, 1);
                    }
                    })
                    .catch((ex) => {
                        _self.errors = ex.data;
                    });
            },
            async populateModel(x, toTarget) {
                let index = this.source.data.findIndex(f => f.countryID === x.id);
                if (toTarget) {
                    if (index === -1) {
                        let tempCode = Math.random().toString().replace(".", "");
                        let entity = {
                            name: tempCode.substring(0, 20),
                            code: tempCode.substring(0, 20),
                            companyAlertID: this.formData?.parentID ?? this.formData?.id,
                            templateID: this.formData?.id,
                            countryID: x.id,
                            tenantId: this.formData?.tenantID ?? null,
                            environmentId: this.formData?.environmentID ?? null,
                            id: 0,
                            id2: null,
                            mask: null
                        };
                        this.source.data.push(this.$filters.init(entity));
                        this.source.total = this.source.data.length;
                    }
                    if (this.$props?.formData?.id ?? 0 > 0) {
                        console.log(this.source.data);
                        console.log(this.source.data.filter(f => f.countryID === x.id)[0]);
                        this.postRecord(this.source.data.filter(f => f.countryID === x.id)[0]);
                    }
                }
                else {
                    if (this.$props?.formData?.id ?? 0 > 0) {
                        this.deleteRecord(this.source.data.filter(f => f.countryID === x.id)[0]);
                    }
                    else {
                        if (index !== -1)
                            this.source.data.splice(index, 1);
                    }
                }
            }
        },
        async created() {
        },
        async mounted() {
            this.getAlertScopes();
        }

    }
</script>

<style>

    div.image-container > img {
        width: 75px !important;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        margin-right: 1rem;
    }
    .image-center {
        display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;
        width: 50% !important;
    }
</style>