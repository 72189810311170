<template>
    <!-- Group Header Template -->
    <!--<cosmos-grid :id="`RateCardProductList_${parentID}`" :source="`/services/facility/providercontractproduct/byeffectivedate/id/${parentID}`" sourceType="url" :multiLevel="true" :searchFilters="['code', 'name']" :sourcePaged="false" :autoGenerate="false" :deleteDisplayFields="deleteDisplay" :showOperation="false" :showAdd="true" :showExport="false" @is-source="records = $event" :force-refresh="activity.refresh">-->
    <pv-grid :id="id"
             :ref="id"
             :value="ds?.data ?? []"
             :totalRecords="ds?.total ?? 0"
             :alwaysShowPaginator="true"
             :paginator="true"
             :paginatorPosition="'bottom'"
             :pageLinkSize="5"
             :rowsPerPageOptions="[5, 10, 25, 50, 100]"
             :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport'"
             :currentPageReportTemplate="'Showing {first} - {last} of {totalRecords} records'"
             :first="page.first"
             :rows="page.size"
             :lazy="true"
             :loading="activity.loading"
             :editMode="'row'"
             :dataKey="'id'"
             :scrollWidth="'flex'"
             :scrolHeight="'flex'"
             :responsive="'scroll'"
             :columnResizeMode="'expand'"
             :stateStorage="'session'"
             :stateKey="`dt-state-${id}`"
             v-model:expandedRows="expanded.level1"
             autoLayout
             showGridlines
             stripedRows
             @page="onChangePage">
        <pv-grid-column-group type="header">
            <pv-grid-row>
                <pv-grid-column header="" :colspan="4" />
                <pv-grid-column header="Copy Rates From Other Products..." :colspan="5" />
                <pv-grid-column header="Quotation Rules..." :colspan="2" />
                <pv-grid-column field="minChargePerConsignment" :rowspan="2" header="Min. Charge Per Consignment" style="min-width: 8rem;text-align:center;" />
                <pv-grid-column header="" :rowspan="2" style="min-width: 7rem; max-width: 7rem; width: 7rem" />
            </pv-grid-row>
            <pv-grid-row>
                <pv-grid-column field="copyMinimumRevenueRateID" header="" />
                <pv-grid-column field="copyMinimumRevenueRateID" header="Product" />
                <pv-grid-column field="copyMinimumRevenueRateID" header="Template" style="min-width: 10rem;" />
                <pv-grid-column field="copyMinimumRevenueRateID" header="Zone" style="min-width: 8rem;" />
                <pv-grid-column field="copyBaseRateID" header="Base" style="min-width: 8rem;" />
                <pv-grid-column field="copyMultiplierRateID" header="Multiplier" style="min-width: 8rem;" />
                <pv-grid-column field="copyExcessRateID" header="Excess" style="min-width: 8rem;" />
                <pv-grid-column field="copyPremiumRateID" header="Premium" style="min-width: 8rem;" />
                <pv-grid-column field="copyMinimumRevenueRateID" header="Min. Revenue" style="min-width: 8rem;" />
                <pv-grid-column field="computedByDomestic" header="Domestic" style="min-width: 8rem;" />
                <pv-grid-column field="computedByInternational" header="International" style="min-width: 8rem;" />
            </pv-grid-row>
        </pv-grid-column-group>
        <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
                <span>
                    <pv-button icon="pi pi-fw pi-plus" label="Add New Record" style="margin-right: 5px;" @click="onCreate"></pv-button>
                </span>
                <!--<span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                <i class="pi pi-search" />
                <pv-input v-model="activeFilters['global'].value" placeholder="Keyword Search" style="width: 100%;" @keyup="onForceFilter" />
            </span>-->
            </div>
        </template>
        <pv-grid-column :expander="true" headerStyle="width: 3rem; max-width: 3rem; padding: 10px;" style="max-width: 3rem; padding: 5px 10px; border-right: 0;" :frozen="true" />
        <pv-grid-column field="serviceProviderProduct.code" header="Product Details" style="width: 100%;">
            <template #body="slotProps">
                <div>[<b>{{slotProps.data.serviceProviderProduct.code}}</b>] {{slotProps.data.serviceProviderProduct.name}}</div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="templateTypeName" header="Rate Template" headerStyle="width: 10rem;"></pv-grid-column>
        <pv-grid-column field="serviceProviderProduct.name" header="Zone Name">
            <template #body="slotProps">
                <div v-if="slotProps.data.zone?.id" v-tooltip="`[<b>${slotProps.data.zone?.code}</b>] ${slotProps.data.zone?.name}`">[<b>{{slotProps.data.zone?.code}}</b>]</div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="copyBaseRateID" header="Copy Base From...">
            <template #body="slotProps">
                <div v-if="slotProps.data.copyBaseRate?.id" v-tooltip.top="`[<b>${slotProps.data.copyBaseRate?.code ?? slotProps.data.copyBaseRate?.serviceProviderProduct?.code }</b>] ${slotProps.data.copyBaseRate?.name ?? slotProps.data.copyBaseRate?.serviceProviderProduct?.name}`">[<b>{{slotProps.data.copyBaseRate?.code ?? slotProps.data.copyBaseRate?.serviceProviderProduct?.code }}</b>]</div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="copyMultiplierRateID" header="Copy Multiplier From...">
            <template #body="slotProps">
                <div v-if="slotProps.data.copyMultiplierRate?.id" v-tooltip.top="`[<b>${slotProps.data.copyMultiplierRate?.code ?? slotProps.data.copyMultiplierRate?.serviceProviderProduct?.code }</b>] ${slotProps.data.copyMultiplierRate?.name ?? slotProps.data.copyMultiplierRate?.serviceProviderProduct?.name}`">[<b>{{slotProps.data.copyMultiplierRate?.code ?? slotProps.data.copyMultiplierRate?.serviceProviderProduct?.code }}</b>]</div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="copyExcessRateID" header="Copy Excess From...">
            <template #body="slotProps">
                <div v-if="slotProps.data.copyExcessRate?.id" v-tooltip.top="`[<b>${slotProps.data.copyExcessRate?.code ?? slotProps.data.copyExcessRate?.serviceProviderProduct?.code}</b>] ${slotProps.data.copyExcessRate?.name ?? slotProps.data.copyExcessRate?.serviceProviderProduct?.name}`">[<b>{{slotProps.data.copyExcessRate?.code ?? slotProps.data.copyExcessRate?.serviceProviderProduct?.code}}</b>]</div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="copyPremiumRateID" header="Copy Premium From...">
            <template #body="slotProps">
                <div v-if="slotProps.data.copyPremiumRate?.id" v-tooltip.top="`[<b>${slotProps.data.copyPremiumRate?.code ?? slotProps.data.copyPremiumRate?.serviceProviderProduct?.code}</b>] ${slotProps.data.copyPremiumRate?.name ?? slotProps.data.copyPremiumRate?.serviceProviderProduct?.name}`">[<b>{{slotProps.data.copyPremiumRate?.code ?? slotProps.data.copyPremiumRate?.serviceProviderProduct?.code}}</b>]</div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="copyMinimumRevenueRateID" header="Copy Min Rev. From...">
            <template #body="slotProps">
                <div v-if="slotProps.data.copyMinimumRevenueRate?.id" v-tooltip.top="`[<b>${slotProps.data.copyMinimumRevenueRate?.code ?? slotProps.data.copyMinimumRevenueRate?.serviceProviderProduct?.code }</b>]`">[<b>{{slotProps.data.copyMinimumRevenueRate?.code ?? slotProps.data.copyMinimumRevenueRate?.serviceProviderProduct?.code }}</b>]</div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="computedByDomestic" header="Compute Domestic">
            <template #body="slotProps">
                <div v-if="slotProps.data.computedByDomesticName">{{slotProps.data.computedByDomesticName}}</div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="computedByInternational" header="Compute International">
            <template #body="slotProps">
                <div v-if="slotProps.data.computedByInternationalName">{{slotProps.data.computedByInternationalName}}</div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="minChargePerConsignment" header="Min. Charge Consignment" style="text-align: right;">
            <template #body="slotProps">
                {{ $filters.formatCurrency(slotProps.data.minChargePerConsignment, currencyCode, countryCode) }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="mask" :headerStyle="`min-width: 7rem; max-width: 7rem; width: 7rem`" style="border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-d-flex" style="width: 2rem;">
                    <div class="p-col">
                        <pv-button icon="pi pi-pencil" iconPos="right" class="p-button-info" @click="onEdit(slotProps.data)" />
                    </div>
                    <div class="p-col">
                        <pv-button :disabled="slotProps.data.recordStatus !== 0" icon="pi pi-trash" iconPos="right" class="p-button-danger" @click="onDelete(slotProps.data)" />
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <template #toolbar>
            <div class="p-d-flex p-jc-between p-ai-center">
                <span>
                    <pv-button v-if="availableServices.length > 0" icon="pi pi-fw pi-plus" label="Add New Product" style="margin-right: 5px;" @click="onCreate"></pv-button>
                </span>
                <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                    <i class="pi pi-search" />
                    <pv-input placeholder="Keyword Search" style="width: 100%;" />
                </span>
            </div>
        </template>
        <!-- Row Messages -->
        <template #empty>
            No records found.
        </template>
        <template #loading>
            Retrieving records from database. Please wait...
        </template>
        <!-- Pagination -->
        <template #paginatorLeft>
            <pv-button type="button" icon="pi pi-download" class="p-button-text" @click="onDownload" />
        </template>
        <template #paginatorRight>
            <pv-button type="button" icon="pi pi-refresh" class="p-button-text" @click="onRefresh" />
        </template>
        <template #expansion="slotProps">
            <rate-tabs :template="slotProps.data.templateType"
                       :zoneID="slotProps.data.zoneID"
                       :effectiveDateID="parentID"
                       :parentID="slotProps.data.id"
                       :providerID="providerID"
                       :baseRateID="slotProps.data.copyBaseRateFromID"
                       :excessRateID="slotProps.data.copyExcessRateFromID"
                       :premiumRateID="slotProps.data.copyPremiumRateFromID"
                       :tradeType="tradeType"
                       :weightUnit="weightUnit"
                       :currencyCode="currencyCode"
                       :countryCode="countryCode" />
        </template>
    </pv-grid>

    <!--</cosmos-grid>-->
    <is-dialog header="Delete Record?" :visible="dialog.delete" @is-confirm="onConfirmDelete(model.mask)" @is-cancel="onCancel()">
        <div class="confirmation-content p-d-flex">
            <div><i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" /></div>
            <div>
                You are about to delete this record:
                <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                    <tr v-for="data in deleteDisplayFields" :key="data.id">
                        <th style="text-align:left;">Product</th>
                        <td>:</td>
                        <td> {{ this.model[data] }}</td>
                    </tr>
                </table>
                Please be aware of the following:
                <ul>
                    <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                    <li>For records that has activity, the record will only be disabled and archived.</li>
                    <li>Also deleting a record may be irreversible action.</li>
                </ul>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
    <is-dialog :header="(model?.id ?? 0) === 0 ? 'Add New Record' : 'Edit Record'" :style="{width: '75vw'}" :visible="dialog.edit" @is-confirm="onConfirmEdit(model.mask)" @is-cancel="onCancel()" :confirmDisabled="confirmDisabled">
        <div class="confirmation-content p-grid">
            <div class="p-col p-col-60">
                <h6 class="p-text-bold" style="padding: 0.5rem !important">General</h6>
                <form-lookup id="providerProduct" label="Product" :disabled="(model?.id ?? -1000) > 0" :hideLabel="true" preLabel="Product" :value="model?.accountProductID ?? 100" @is-sync="model.accountProductID = $event.value; model.providerProductID = $event.record?.productDetail?.id" :source="availableServices" sourceType="array" :required="true" :show-copy="false" />
                <form-lookup id="templateType" type="enum" source="ratetemplatetype" :value="model?.templateType ?? 100" @is-sync="{ model.templateType = $event.value; model.zoneID = null; }" :hideLabel="true" preLabel="Template" :required="true" />
                <form-lookup v-if="(model?.templateType ?? 100) === 200 || (model?.templateType ?? 100) === 400" id="carrierZone" label="Zone" :source="lookup.zone" :hideLabel="true" preLabel="Zone" sourceType="array" :value="model.zoneID" @is-sync="model.zoneID = $event.value" :required="true" />
                <h6 class="p-text-bold" style="padding: 0.5rem !important">Quotation Rule</h6>
                <form-lookup id="computedByDomestic" type="enum" source="ratechargingtype" preLabel="Domestic" :hideLabel="true" :value="model.computedByDomestic" @is-sync="{ model.computedByDomestic = $event.value; }" :required="true" />
                <form-lookup id="computedByInternational" type="enum" source="ratechargingtype" preLabel="International" :hideLabel="true" :value="model.computedByInternational" @is-sync="{ model.computedByInternational = $event.value; }" :required="true" />
                <form-numeric id="minChargePerConsignment" type="currency" preLabel="Min. Charge/Consignment" :currency="currencyCode" :hideLabel="true" :max="9999999999.99" :value="model.minChargePerConsignment" @is-sync="model.minChargePerConsignment = $event.value" style="margin-top: -1rem;" />
            </div>
            <div class="p-col p-col-6">
                <h6 class="p-text-bold" style="padding: 0.5rem !important">Copy Different Rates From...</h6>
                <form-lookup id="copyBaseRateFrom" preLabel="Base Rate" :hideLabel="true" :value="model.copyBaseRateFromID" @is-sync="model.copyBaseRateFromID = $event.value; model.copyBaseRate = $event.record" :source="copyAllowedBase" sourceType="array" />
                <form-lookup id="copyAllowedMultiplier" preLabel="Multiplier Rate" :hideLabel="true" :value="model.copyMultiplierRateFromID" @is-sync="model.copyMultiplierRateFromID = $event.value; model.copyMultiplierRate = $event.record" :source="copyAllowedMultiplier" sourceType="array" />
                <form-lookup id="copyExcessRateFrom" preLabel="Excess Rate" :hideLabel="true" :value="model.copyExcessRateFromID" @is-sync="{ model.copyExcessRateFromID = $event.value; model.copyExcessRate = $event.record }" :source="copyAllowedExcess" sourceType="array" />
                <form-lookup id="copyPremiumRateFrom" preLabel="Premium Rate" :hideLabel="true" :value="model.copyPremiumRateFromID" @is-sync="{ model.copyPremiumRateFromID = $event.value; model.copyPremiumRate = $event.record }" :source="copyAllowedPremium" sourceType="array" />
                <form-lookup id="copyMinimumRevenueRate" preLabel="Minimum Revenue Rate" :hideLabel="true" :value="model.copyMinimumRevenueRateFromID" @is-sync="{ model.copyMinimumRevenueRateFromID = $event.value; model.copyMinimumRevenueRate = $event.record }" :source="copyAllowedMinimumRevenue" sourceType="array" />
            </div>
        </div>
    </is-dialog>
</template>
<script>
    import productratetabs from '@/components/facility/rates/contract/productrate-tabs.vue';
    export default {
        name: 'RateContract',
        props: {
            parentID: { type: Number }, // Effective Date ID
            contractID: { type: Number }, // Contract Provider ID
            accountID: { type: Number }, // Account ID
            providerID: { type: String },
            tradeType: { type: Number },
            weightUnit: { type: Number, default: 100 },
            countryID: { type: Number },
            countryCode: { type: String },
            currencyCode: { type: String }
        },
        components: {
            'rate-tabs': productratetabs
        },
        data() {
            return {
                model: null,
                deleteDisplayFields: ["text"],
                records: { data: [], total: 0 },
                page: {
                    current: 0,
                    first: 0,
                    size: 10
                },
                loading: true,
                dialog: {
                    delete: false,
                    edit: false
                },
                lookup: {
                    zones: [],
                    services: []
                },
                activity: {
                    refresh: false
                },
                expanded: {
                    level1: null,
                    level2: null,
                    level3: null,
                    level4: null,
                    level5: null
                },

            }
        },
        watch: {
            accountID: {
                handler() {
                    if (this.accountID) {
                        this.$store.dispatch("providerzone/getURL", { url: `/services/admin/serviceprovideraccountproduct/byaccount/id/${this.accountID}` }).then((response) => {
                            this.lookup.services = response.result;
                        }).catch((ex) => console.log(ex));
                    }
                },
                deep: true
            },
            providerID: {
                handler() {
                    if (this.providerID) {
                        this.$store.dispatch("providerzone/getURL", { url: `/services/settings/serviceproviderzone/byprovider/id/${this.providerID}` }).then((response) => {
                            this.lookup.zone = response.result.filter(x => x.contractCountry.id === this.countryID);
                        }).catch((ex) => console.log(ex));
                    }
                }
            }
        },
        computed: {
            ds() {
                return (this.records ?? { data: [], total: 0 });
            },
            dataSource() {
                return (this.records ?? { data: [], total: 0 }).data;
            },
            availableServices() {
                let ids = this.dataSource.map(m => m.providerProductID);
                if ((this.model?.id ?? -1000) > 0)
                    return this.lookup.services;
                else
                    return this.lookup.services.filter(x => ids.findIndex(y => y === x.productDetail.id) === -1);
            },
            copyAllowedBase() {
                let ids = this.dataSource.filter(x => x.templateType === (this.model?.templateType ?? 100) && (x.zoneID ?? -1000) === (this.model?.zoneID ?? -1000) && x.copyBaseRateFromID == null).map(m => m.id);
                let services = this.dataSource.filter(x => ids.findIndex(y => y === x.id) > -1);
                if ((this.model?.id ?? -1000) > 0)
                    services = services.filter(x => x.id !== this.model.id);
                return services;
            },
            copyAllowedMultiplier() {
                let ids = this.dataSource.filter(x => x.templateType === (this.model?.templateType ?? 100) && (x.zoneID ?? -1000) === (this.model?.zoneID ?? -1000) && x.copyMultiplierRateFromID == null).map(m => m.id);
                let services = this.dataSource.filter(x => ids.findIndex(y => y === x.id) > -1);
                if ((this.model?.id ?? -1000) > 0)
                    services = services.filter(x => x.id !== this.model.id);
                return services;
            },
            copyAllowedExcess() {
                let ids = this.dataSource.filter(x => x.templateType === (this.model?.templateType ?? 100) && (x.zoneID ?? -1000) === (this.model?.zoneID ?? -1000) && x.copyExcessRateFromID == null).map(m => m.id);
                let services = this.dataSource.filter(x => ids.findIndex(y => y === x.id) > -1);
                if ((this.model?.id ?? -1000) > 0)
                    services = services.filter(x => x.id !== this.model.id);
                return services;
            },
            copyAllowedPremium() {
                let ids = this.dataSource.filter(x => x.templateType === (this.model?.templateType ?? 100) && (x.zoneID ?? -1000) === (this.model?.zoneID ?? -1000) && x.copyPremiumRateFromID == null).map(m => m.id);
                let services = this.dataSource.filter(x => ids.findIndex(y => y === x.id) > -1);
                if ((this.model?.id ?? -1000) > 0)
                    services = services.filter(x => x.id !== this.model.id);
                return services;
            },
            copyAllowedMinimumRevenue() {
                let ids = this.dataSource.filter(x => x.templateType === (this.model?.templateType ?? 100) && (x.zoneID ?? -1000) === (this.model?.zoneID ?? -1000) && x.copyMinimumRevenueRateFromID == null).map(m => m.id);
                let services = this.dataSource.filter(x => ids.findIndex(y => y === x.id) > -1);
                if ((this.model?.id ?? -1000) > 0)
                    services = services.filter(x => x.id !== this.model.id);
                return services;
            }
        },
        methods: {
            onCreate() {
                this.model = {
                    accountProductID: null,
                    contractProviderID: this.contractID,
                    rateContractEffectiveDateID: this.parentID,
                    templateType: 100,
                    templateTypeName: 'Basic',
                    zoneID: null,
                    copyBaseRateFromID: null,
                    copyBaseRate: null,
                    copyExcessRateFromID: null,
                    copyExcessRate: null,
                    copyPremiumRateFromID: null,
                    copyPremiumRate: null
                }
                this.dialog.edit = true;
            },
            onEdit(e) {
                this.model = this.$filters.init(e);
                this.dialog.edit = true;
            },
            onDelete(e) {
                this.model = this.$filters.init(e);
                this.dialog.delete = true;
            },
            onCancel() {
                this.model = null;
                this.dialog.edit = false;
                this.dialog.delete = false;
            },
            onConfirmDelete(e) {
                this.$store.dispatch("providercontractproduct/deleteRecordByMask", e)
                    .then((response) => {
                        if (response === undefined  || (response && response.success)) {
                            this.records.data = this.records.data.filter(x => x.mask !== e);
                            this.$toast.add({ severity: 'success', summary: 'Rate Card Product', detail: "The record was deleted successfully!", life: 3000 });
                            this.onCancel();
                            this.activity.refresh = true;
                            this.$nextTick(() => { this.activity.refresh = false });

                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
            onConfirmEdit(e) {
                let index = this.dataSource.findIndex(x => x.mask === e);
                var model = this.$filters.init(this.model);
                if (index >= 0) {
                    this.$store.dispatch("providercontractproduct/updateRecord", model).then((response) => {
                        if (response.success) {
                            this.$toast.add({ severity: 'success', summary: 'Rate Card Product', detail: "The record was updated successfully!", life: 3000 });
                            this.dialog.edit = false;
                            this.records[index] = model;
                            this.onCancel();
                            this.activity.refresh = true;
                            this.$nextTick(() => { this.activity.refresh = false });
                            this.onRefresh();
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: response.errors[0]?.message, life: 3000 });
                        }

                    })
                        .catch(ex => {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        });
                }
                else {
                    this.$store.dispatch("providercontractproduct/addRecord", model).then((response) => {
                        if (response.success) {
                            var data = this.$filters.init(response.result)
                            this.$store.dispatch("providercontractproduct/getRecord", data.mask).then((response2) => {
                                if (response2.success) {
                                    this.$toast.add({ severity: 'success', summary: 'Rate Card Product', detail: "The record was added successfully!", life: 3000 });
                                    this.onCancel();
                                    this.activity.refresh = true;
                                    this.$nextTick(() => { this.activity.refresh = false });
                                    this.onRefresh();
                                }
                                else if ((response2.errors ?? []).length > 0) {
                                    this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: response2.errors[0]?.message, life: 3000 });
                                }
                            })
                            .catch(ex => {
                                this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                            });
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    })
                        .catch(ex => {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        });
                }
            },
            onChangePage(e) {
                this.page.current = e.page;
                this.page.first = e.first;
                this.page.size = e.rows;
                this.onRefresh();
            },
            onRefresh() {
                this.activity.loading = true;
                this.records = { data: [], total: 0 };
                this.getSource().then((response) => {
                    this.records = response;
                    this.$nextTick(() => { this.activity.loading = false; });
                });
            },
            async getSource() {
                this.activity.loading = true;
                let dataSource = { total: 0, data: [] };
                await this.$store.dispatch("providercontractproductrate/getURL", { url: `/services/facility/providercontractproduct/byeffectivedate/id/${this.parentID}` })
                    .then((response) => {
                        dataSource = { data: response.result, total: response.result.length };
                        this.records = dataSource = { total: (response.result ?? []).length, data: [...(response.result ?? [])]?.slice(this.page.first) };
                        this.activity.loading = false;
                    })
                    .catch(() => {
                        this.source = [];
                        this.records = dataSource = { total: 0, data: [] }
                        this.activity.loading = false;
                    });
                return dataSource;
            },
        },
        created() {
            if (this.accountID) {
                this.$store.dispatch("providerzone/getURL", { url: `/services/admin/serviceprovideraccountproduct/byaccount/id/${this.accountID}` }).then((response) => {
                    this.lookup.services = response.result;
                }).catch((ex) => console.log(ex));
            }

            

            if (this.providerID) {
                this.$store.dispatch("providerzone/getURL", { url: `/services/settings/serviceproviderzone/byprovider/id/${this.providerID}` }).then((response) => {
                    this.lookup.zone = response.result.filter(x => x.contractCountry.id === this.countryID);
                }).catch((ex) => console.log(ex));
            }
            if (this.zoneID) {
                this.$store.dispatch("providersurcharge/getCustom", `/services/settings/serviceproviderzonename/byzone/id/${this.zoneID}`).then((response) => {
                    let columns = [];
                    var cols = response.result.filter(x => x.tradeType === this.tradeType || x.tradeType === (this.tradeType < 4 ? 3 : 4)).map(x => ({ field: null, header: x.name }));
                    for (let index = 0; index < cols.length; index++) {
                        cols[index]['field']= 'rate_' + this.$filters.padLeft(index + 1, 2, '0');
                    }
                    columns.push(cols);
                    this.columns = columns[0];
                }).catch((ex) => console.log(ex));
            }
            //this.records = this.source;
            if (this.parentID) {
                this.$store.dispatch("providerzone/getURL", { url: `/services/facility/providercontractproduct/byeffectivedate/id/${this.parentID}` }).then((response) => {
                    this.records.data = response.result;
                    this.records.total = response.result.length;
                    console.log(response.result.length);
                }).catch((ex) => console.log(ex));
            }
        }
    }
</script>

