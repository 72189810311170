<template>
    <form-wrapper title="Surcharge Rates" sub-title="These are the surcharge rates by carrier.">
        <cosmos-grid id="RateSurchargeGrid" :multiLevel="true" :searchFilters="['code', 'name']" :autoGenerate="false" :columns="columns" :deleteDisplayFields="deleteDisplay" :showOperation="false" :showDelete="false" :showExport="false" :operationWidth="4" @is-source="dataSource = $event.data" :forceRefresh="forceRefresh">
            <pv-grid-column field="serviceProvider.code" header="Code" headerStyle="width: 14.7rem; max-width: 14.7rem; padding-left: 15px; padding-top: .8rem !important;" style="width: 14.7rem; max-width: 14.7rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;"></pv-grid-column>
            <pv-grid-column field="serviceProvider.name" header="Name"></pv-grid-column>
            <!-- Operation Column -->
            <pv-grid-column field="mask" :headerStyle="`min-width: 4rem; max-width: 4rem; width: 4rem`" style="border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
                <template #body="slotProps">
                    <div class="p-d-flex" style="width: 7rem;">
                        <div class="p-col">
                            <pv-button icon="pi pi-book" iconPos="right" :loading="loading" @click="onCreateRule(slotProps.data.id,slotProps.data.serviceProviderID,slotProps.data.providerCarrierID)" title="Add Rules and Conditions" />
                        </div>
                        <div class="p-col">
                            <pv-button icon="pi pi-trash" class="p-button p-button-danger" iconPos="right" :loading="loading" @click="onDelete(slotProps.data)"/>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <!-- Main Toolbar -->
            <template #toolbar>
                <div class="p-d-flex p-jc-between p-ai-center">
                    <span>
                        <pv-button v-if="visible" icon="pi pi-fw pi-plus" label="Add New Record" style="margin-right: 5px;" @click="onCreate"></pv-button>
                    </span>
                    <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                        <i class="pi pi-search" />
                        <pv-input placeholder="Keyword Search" style="width: 100%;" />
                    </span>
                </div>
            </template>
            <!-- Sub Grid Details -->
            <template #expansion="slotProps">
                <raterule-grid :surchargeProviderID="slotProps.data.id" :loadKey="loadKey" />
            </template>
        </cosmos-grid>
    </form-wrapper>
    <!-- Delete Dialog -->
    <keep-alive>
        <is-dialog header="Delete Record?" :visible="dialog.delete" @is-confirm="onConfirmDelete(model.mask)" @is-cancel="onCancel()">
            <div class="confirmation-content p-d-flex">
                <div><i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" /></div>
                <div>
                    You are about to delete this record:
                    <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                        <tr v-for="data in deleteDisplayFields" :key="data.id">
                            <th style="text-align:left;">{{ $filters.titleize(data) }}</th>
                            <td>:</td>
                            <td> {{ this.model.serviceProvider[data] }}</td>
                        </tr>
                    </table>
                    Please be aware of the following:
                    <ul>
                        <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                        <li>For records that has activity, the record will only be disabled and archived.</li>
                        <li>Also deleting a record may be irreversible action.</li>
                    </ul>
                    Are you sure you still want to proceed?
                </div>
            </div>
        </is-dialog>
    </keep-alive>
    <!-- Add Dialog -->
    <keep-alive>
        <is-dialog :header="(model?.id ?? 0) === 0 ? 'Add New Record' : 'Edit Record'" style="width: 50vw; min-width: 400px;" :visible="dialog.edit" @is-confirm="onConfirmEdit(model.mask)" @is-cancel="onCancel()" :confirmDisabled="confirmDisabled">
            <div class="confirmation-content p-d-flex">
                <div class="p-col p-md-12">
                    <form-lookup id="serviceProvider" :source="serviceProviders" preLabel="Provider" :hideLabel="true" sourceType="array" :value="model.serviceProviderID" @is-sync="model.serviceProviderID = $event.value" :required="true" />
                    <form-lookup v-if="carrierProviders.length > 0" id="providerCarrier" preLabel="Carrier" :hideLabel="true" :source="carrierProviders" sourceType="array" :value="model.providerCarrierID" @is-sync="model.providerCarrierID = $event.value" />
                </div>
            </div>
        </is-dialog>
    </keep-alive>
    <!-- Special Dialog (Rule) -->
    <keep-alive>
        <is-dialog :header="(activeRule?.id ?? 0) === 0 ? 'Add New Record' : 'Edit Record'" :breakpoints="{'2500px': '85vw', '960px': '75vw', '640px': '100vw'}" style="width: 50vw; min-width: 400px;" :visible="dialog.rule" @is-confirm="onConfirmRule" @is-cancel="onCancel()" :confirmDisabled="confirmRuleDisabled">
            <rule-detail-form :source="rule" @is-sync="rule = $event" :service-provider-ID="rule?.serviceProviderID" :surchargeProviderID="rule.surchargeProviderID"></rule-detail-form>
        </is-dialog>
    </keep-alive>
</template>
<script>
    import ruledetailform from '@/components/facility/rates/surcharge-rates/ruledetail-form.vue';
    import raterulegrid from '@/components/facility/rates/surcharge-rates/rate-rules-grid.vue';
    export default {
        name: 'SurchargeRateGrid',
        components: {
            'raterule-grid': raterulegrid,
            'rule-detail-form': ruledetailform
        },
        data() {
            return {
                loadKey: null,
                columns: [],
                rule: null,
                model: null,
                page: {
                    current: 0,
                    first: 0,
                    size: 10
                },
                dialog: {
                    delete: false,
                    edit: false,
                    rule: false
                },
                sources: {
                    accounts: []
                },
                dataSource: [],
                forceRefresh: false,
                deleteDisplayFields: ['code','name']
            }
        },
        computed: {
            visible() { return this.serviceProviders.length > 0; },
            activeRule() { return this.rule; },
            serviceProviders() { return this.$vm.$providers.filter(x => this.sources.accounts.findIndex(y => y.serviceProviderID === x.id) >= 0 && (this.dataSource ?? []).findIndex(y => y.serviceProvider.id === x.id) === -1); },
            carrierProviders() { return this.$vm.$carriers.filter(x => x.serviceProviderID === this.model.serviceProviderID); },
            accounts() { return this.sources.accounts.filter(x => x.serviceProviderID === this.model.serviceProviderID); },
            confirmRuleDisabled() {
                var disabled = false;
                if (!this.activeRule?.surchargeID || this.activeRule?.surchargeID === 0)
                    disabled = true;
                if (!this.activeRule?.rateApplied || this.activeRule?.rateApplied === 0)
                    disabled = true;
                return disabled;
            }
        },
        methods: {
            // Create Surcharge Header
            onCreate() {
                this.model = {
                    serviceProviderID: null,
                    providerCarrierID: null
                };
                this.dialog.edit = true;
            },
            onDelete(e) {
                this.model = this.$filters.init(e);
                this.dialog.delete = true;
            },
            onConfirmEdit(e) {
                let index = (this.dataSource ?? []).findIndex(x => x.mask === e);
                var model = this.$filters.init(this.model);
                if (index >= 0) {
                    this.$store.dispatch("providersurchargerate/updateRecord", model).then((response) => {
                        if (response.success) {
                            this.$toast.add({ severity: 'success', summary: 'Surcharge Provider', detail: "The record was updated successfully!", life: 3000 });
                            this.onCancel();
                            this.records = this.records.sort(this.dynamicSort("effectiveDate"))
                            this.loadKey = Math.floor(Math.random() * 9999999999);
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
                }
                else {
                    this.$store.dispatch("providersurchargerate/addRecord", model).then((response) => {
                        if (response.success) {
                            this.$toast.add({ severity: 'success', summary: 'Surcharge Provider', detail: "The record was added successfully!", life: 3000 });
                            this.onCancel();
                            this.forceRefresh = true;
                            this.$nextTick(() => this.forceRefresh = false);
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
                }
            },
            onConfirmDelete(e) {
                this.$store.dispatch("providersurchargerate/deleteRecordByMask", e)
                    .then((response) => {
                        if (response.success) {
                            this.$toast.add({ severity: 'success', summary: 'Surcharge Rate', detail: "The record was deleted successfully!", life: 3000 });
                            this.onCancel();
                            this.forceRefresh = true;
                            this.$nextTick(() => this.forceRefresh = false);
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
            // Rule Management
            onCreateRule(surchargeProviderID, serviceProviderID, providerCarrierID) {
                this.rule = {
                    id: 0,
                    surchargeProviderID: surchargeProviderID,
                    serviceProviderID: serviceProviderID,
                    providerCarrierID: providerCarrierID,
                    accountID: null,
                    surchargeID: 0,
                    effectiveDateFrom: new Date(this.$moment.utc()),
                    effectiveDateTo: null,
                    rateType: 100,
                    rateApplied: 0,
                    rateChargeType: 100,
                    ratePercentageBase: 100,
                    minRateType: 100,
                    minRateApplied: 0,
                    minRateChargeType: 100,
                    minRatePercentageBase: 100,
                    maxRateType: 100,
                    maxRateApplied: 0,
                    maxRateChargeType: 100,
                    maxRatePercentageBase: 100
                };
                this.dialog.rule = true;
            },
            onConfirmRule() {
                this.$store.dispatch("providersurchargerule/addRecord", this.rule)
                    .then((response) => {
                        if (response.success) {
                            this.$toast.add({ severity: 'success', summary: 'Surcharge Rule', detail: "The record was added successfully!", life: 3000 });
                            this.onCancel();
                            this.loadKey = Math.floor(Math.random() * 9999999999);
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    }).catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
            // General Method
            onCancel() {
                this.dialog.edit = false;
                this.dialog.delete = false;
                this.dialog.rule = false;
                this.model = null;
                this.rule = null;
            }
        },
        created() {
            this.$store.dispatch("providersurchargerate/getURL", { url: '/services/admin/serviceprovideraccount' })
                .then((response) => {
                    this.sources.accounts = response.result;
                });
            this.loadKey = Math.floor(Math.random() * 9999999999);
        }
    }
</script>