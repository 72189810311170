<template>
    <div style="margin: -0.5rem -1.5rem -2.5rem -1.5rem;">
        <div style="min-height: 10rem; padding: 2rem 1rem 1rem 1rem;">
            <h3 style="margin-bottom: 0.25rem;">STEP 2</h3>
            <p style="margin-bottom:1rem;">On this step, you may customize the column name that will appear on your report.</p>
            <customization-form v-model="model.fields" :parentID="model.id" :disabled="false" />
        </div>
        <div class="p-grid p-nogutter p-justify-between" style="border-top: 2px solid var(--surface-d);padding: 0.5rem 1rem; background: var(--surface-c)">
            <pv-button @click="onPrevPage()" :loading="activity.loading" :iconPos="'right'"><i class="fa fa-chevron-left fa-fw" style="margin-right: 0.5rem"></i> <i :class="`${(activity.loading ? 'pi pi-spinner pi-spin' : 'fa fa-bars fa-fw')}`" style="margin-right: 0.5rem"></i> Field Selection</pv-button>
            <pv-button @click="onNextPage()" :loading="activity.loading" :iconPos="'right'"><i :class="`${(activity.loading ? 'pi pi-spinner pi-spin' : 'fa fa-cogs fa-fw')}`" style="margin-right: 0.5rem"></i> Configuration <i class="fa fa-chevron-right fa-fw" style="margin-left: 0.5rem"></i></pv-button>
        </div>
    </div>
</template>
<script>
    import customForm from '@/components/facility/report/custom-form.vue';
    export default {
        name: "FieldSelectionStep",
        emits: ["update", "update:modelValue", "isSync"],
        components: { 'customization-form': customForm },
        props: {
            parentID: { type: Number, default: 0 },
            modelValue: { type: Array, default: () => [] }
        },
        data() {
            return {
                model: null,
                activity: {
                    loading: false
                }
            }
        },
        computed: {
        },
        watch: {
            model() {
                this.onSync();
            }
        },
        methods: {
            onPrevPage() {
                this.$emit('prev-page', { formData: this.model, pageIndex: 1 });
            },
            onNextPage() {
                this.$emit('next-page', { formData: this.model, pageIndex: 1 });
            },
            onSync() {
                this.$emit("update:modelValue", this.model);
                this.$emit("is-sync", this.model);
            }
        },
        created() {
            if (this.modelValue)
                this.model = this.modelValue;
        }
    }
</script>
