<template>
    <div style="margin: 0 -1rem; border-top: 1px solid var(--gray-300); padding: 0.5rem 1rem 0 1rem;">
        <div class="p-grid p-nogutter p-justify-end">
            <pv-button icon="pi pi-fw pi-trash" class="p-button p-button-danger" style="margin-right: 5px;" @click="onDelete(model?.id, model?.tempParentID, model?.tempChildID)" />
        </div>
        <form-lookup id="logic" type="enum" source="logictype" :sorted="false" :required="true" :value="model?.logicType" @is-sync="model.logicType = $event.value ?? 100" />

        <!-- Category Type -->
        <form-lookup id="category" :source="categories" sourceType="array" :sorted="false" :required="true" :value="activeCategory?.id" @is-sync="onChangeCategory" />
        <!-- Condition Type -->
        <form-lookup id="condition" :source="conditions" sourceType="array" :sorted="false  " :required="true" :value="activeCondition?.id" @is-sync="onChangeCondition" />
        <!-- Operator Type -->
        <form-lookup v-if="validOperators.length > 0" id="operator" :source="operators" sourceType="array" :sorted="false" :required="true" :value="activeOperator?.id" @is-sync="onChangeOperator" />

        <!-- Values Templates -->
        <!-- Decimal and Weight Unit (BETWEEN) -->
        <div v-if="activeCondition?.dataType === 300 && activeCondition?.referenceType === 100 && [700,4700,8700].findIndex(x => x === activeOperator?.id) !== -1 " class="p-d-flex p-nogutter">
            <div class="p-col">
                <form-numeric id="minimumWeight" type="decimal" :decimalDigit="(model?.referenceID === 200 ? 0 : 3)" :max="Math.floor(Number(model?.category2 ?? '0')) > 0 ? Math.floor(Number(model?.category2 ?? '0')) : (model.referenceID === 200 ? 9999500 : 99995)" :required="true" :value="Number(model?.category1 ?? '0')" @is-sync="onChangeValue" />
            </div>
            <div class="p-col">
                <form-numeric id="maximumWeight" type="decimal" :decimalDigit="(model?.referenceID === 200 ? 0 : 3)" :min="Number(model?.category1 ?? '0') + (model?.referenceID === 200 ? 1 : 0.001)" :max="(model.referenceID === 200 ? 9999500 : 99995)" :required="true" :value="Number(model?.category2 ?? '0')" @is-sync="onChangeValue2" />
            </div>
            <div class="p-col-fixed" style="width: 14rem;">
                <form-lookup id="weightUnit" type="enum" source="weightunit" :required="true" :sorted="false" :value="[100, 200, 300].indexOf(model?.referenceID ?? 100) !== -1 ? model?.referenceID ?? 100 : 100" @is-sync="onChangeReference" />
            </div>
        </div>
        <!--Decimal and Weight Unit-->
        <div v-else-if="activeCondition?.dataType === 300 && activeCondition?.referenceType === 100" class="p-d-flex p-nogutter">
            <div class="p-col">
                <form-numeric id="value" type="decimal" :decimalDigit="(model?.referenceID === 200 ? 0 : 3)" :max="(model.referenceID === 200 ? 9999500 : 99995)" :required="true" :value="Number(model?.category1)" @is-sync="onChangeValue" />
            </div>
            <div class="p-col-fixed" style="width: 14rem;">
                <form-lookup id="weightUnit" type="enum" source="weightunit" :required="true" :sorted="false" :value="[100, 200, 300].indexOf(model?.referenceID ?? 100) !== -1 ? model?.referenceID ?? 100 : 100" @is-sync="onChangeReference" />
            </div>
        </div>
        <!--Decimal and Length Unit-->
        <div v-else-if="activeCondition?.dataType === 300 && activeCondition?.referenceType === 200" class="p-d-flex p-nogutter">
            <div class="p-col">
                <form-numeric id="value" type="decimal" :decimalDigit="(model?.referenceID === 200 ? 0 : 3)" :max="(model.referenceID === 200 ? 9999500 : 99995)" :required="true" :value="Number(model?.category1)" @is-sync="onChangeValue" />
            </div>
            <div class="p-col-fixed" style="width: 14rem;">
                <form-lookup id="lengthUnit" type="enum" source="lengthunit" :required="true" :sorted="false" :value="[100, 200, 300].indexOf(model?.referenceID ?? 100) !== -1 ? model?.referenceID ?? 100 : 100" @is-sync="onChangeReference" />
            </div>
        </div>
        <!--Decimal and Currency-->
        <div v-else-if="activeCondition?.dataType === 300 && activeCondition?.referenceType === 300" class="p-d-flex p-nogutter">
            <div class="p-col">
                <form-numeric id="value" type="decimal" :max="999999999999.99" :required="true" :value="Number(model?.category1)" @is-sync="onChangeValue" />
            </div>
            <div class="p-col">
                <form-lookup id="currency" :source="$vm.$currencies" sourceType="array" :required="true" :sorted="false" :value="model?.referenceID" @is-sync="onChangeReference" />
            </div>
        </div>
        <!--Standard Decimal-->
        <form-numeric v-else-if="activeCondition?.dataType === 300" id="value" type="decimal" :max="999999999999.99" :required="true" :value="Number(model?.category1)" @is-sync="onChangeValue" />
        <!--Int32 With Country or Trade Bloc-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 400" id="value" type="autocomplete" label="Country / Trade Bloc" :source="tradebloc" sourcesType="array" :isTradeBloc="true" :required="true" :sorted="false" :value="model?.referenceID" @is-sync="onChangeReference" />
        <!--Int32 With Country or Postal Bloc-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 500" id="value" type="autocomplete" label="Postal Bloc" :source="postalbloc" sourcesType="array" :required="true" :sorted="false" :value="model?.referenceID" @is-sync="onChangeReference" />
        <!--Int32 and Region Type-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 600" id="value" :source="region" sourceType="array" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <!-- Int32 and Sub Region Type -->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 700" id="value" type="autocomplete" :source="subregions" sourceType="array" textField="name" :required="true" option-group="label" :value="model?.referenceID" @is-sync="onChangeReference" />
        <!--Int32 and Estalishent Type-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 800" id="value" type="enum" source="establishmenttype" :sorted="false" :value="model?.referenceID" @is-sync="onChangeReference" :required="true" />
        <!--Int32 and Trade Types-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 900 && !isFlag" id="value" type="enum" source="tradetype" :sorted="false" :required="true" :value="model?.referenceID == 0 ? 1 : model?.referenceID ?? 1" @is-sync="onChangeReference" />
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 900 && isFlag" id="value" type="flag" source="tradetype" :sorted="false" :required="true" :value="model?.referenceID == 0 ? 1 : model?.referenceID ?? 1" @is-sync="onChangeReference" />
        <!--Int32 and Incoterms-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1000 && !isFlag" id="value" type="enum" source="incotermtype" :sorted="false" :required="true" :value="model?.referenceID == 0 ? 64 : model?.referenceID ?? 64" @is-sync="onChangeReference" />
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1000 && isFlag" id="value" type="flag" source="incotermtype" :sorted="false" :required="true" :value="model?.referenceID == 0 ? 64 : model?.referenceID ?? 64" @is-sync="onChangeReference" />
        <!--Int32 and Consignment Type-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1100 && !isFlag" id="value" type="enum" source="serviceproviderproductpackagingtype" :sorted="false" :value="model?.referenceID == 0 ? 4 : model?.referenceID ?? 4" @is-sync="onChangeReference" :required="true" />
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1100 && isFlag" id="value" type="flag" source="serviceproviderproductpackagingtype" :sorted="false" :value="model?.referenceID == 0 ? 4 : model?.referenceID ?? 4" @is-sync="onChangeReference" :required="true" />
        <!--Int32 and Consignment Category Type-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1200 && !isFlag" id="value" type="enum" source="customscategorytype" :sorted="false" :value="model?.referenceID == 0 ? 2 : model?.referenceID ?? 2" @is-sync="onChangeReference" :required="true" />
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1200 && isFlag" id="value" type="flag" source="customscategorytype" :sorted="false" :value="model?.referenceID == 0 ? 2 : model?.referenceID ?? 2" @is-sync="onChangeReference" :required="true" />
        <!--Int32 and Notification Type-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1300 && !isFlag" id="value" type="enum" source="notificationtype" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1300 && isFlag" id="value" type="flag" source="notificationtype" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <!--Int32 and IOSS Available-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1400" id="value" type="enum" source="iossavailability" :sorted="false" :required="true" :value="[100, 200].indexOf(model?.referenceID) !== -1 ? model?.referenceID : 100" @is-sync="onChangeReference" />
        <!--Int32 and Dangerous Goods-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1500 && !isFlag" id="value" type="enum" source="dangerousgoodstype" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1500 && isFlag" id="value" type="flag" source="dangerousgoodstype" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <!--Int32 and Special Handling-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1600 && !isFlag" id="value" type="enum" source="packageadditionalhandlingtype" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1600 && isFlag" id="value" type="flag" source="packageadditionalhandlingtype" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <!--Int32 and Day of Week-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1700 && activeOperator.id <= 200" id="value" type="enum" source="dayofweek" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1700 && activeOperator.id >= 200" id="value" type="flag" source="dayofweek" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <!--Int32 and Zone-->
        <div v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1800" class="p-grid p-nogutter">
            <div class="p-col-12">
                <form-lookup id="zone" type="array" :source="zones" :required="true" :sorted="true" :value="Number(model?.category1 ?? source?.category1 ?? 0)" @is-sync="{onChangeValue($event); sources = { export: $event.record?.exportZones ?? [], import: $event.record?.importZones ?? [], thirdCountry: $event.record?.thirdCountryZones ?? [], thirdParty: $event.record?.thirdPartyZones ?? [], returns: $event.record?.returnZones ?? [] } }" />
            </div>
            <div class="p-col">
                <form-lookup id="tradeType" type="enum" source="tradetype" :allowedFlags="model?.markupRateConditionID === 112 ? 13 : 14" :required="true" :sorted="false" :value="Number(model?.category2 ?? (model?.markupRateConditionID === 112 ? '1' : '2')) == 0 ? (model?.markupRateConditionID === 112 ? 1 : 2) : Number(model?.category2 ?? (model?.markupRateConditionID === 112 ? '1' : '2'))" @is-sync="onChangeValue2" />
            </div>
            <div class="p-col">
                <form-lookup id="zone" type="array" :source="zoneNames" :required="true" :sorted="false" :value="Number(model?.referenceID  ?? source?.referenceID ?? -100)" @is-sync="onChangeReference" textField="name" />
            </div>
        </div>
        <!--Int32 and Shipment Type-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1900 && activeOperator.id <= 200" id="value" type="enum" source="documentshippingtype" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1900 && activeOperator.id >= 200" id="value" type="flag" source="documentshippingtype" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <!--Int32 With Postal Regex-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 2000" id="value" type="autocomplete" label="Postal Predefined Regex" :source="postalregex" sourcesType="array" option-group="label" :required="true" :sorted="false" :value="model?.referenceID" @is-sync="onChangeReference" />
        <!--Standard Integer-->
        <form-numeric v-else-if="activeCondition?.dataType === 200" id="value" :required="true" :value="Number(model?.category1 ?? '0')" @is-sync="onChangeValue" />
        <!--String Default-->
        <form-input v-else id="value" :required="true" :value="model?.category1 ?? null" @is-sync="onChangeValue" />
    </div>
</template>
<script>
    export default {
        name: 'MarkupRuleConditionDetailForm',
        emits: ["isSync", "isSyncSubcondition", "isAddSubcondition", "isDeleteSubcondition"],
        props: {
            source: { type: Object }, // Should contain a blank model when new and should contain the source when edit.
            parentID: { type: Number }, // Should hold the parent id of the sub conditions.
            categories: { type: Array }, // Hold allavailable categories
            condition: { type: Array }, // Hold all available conditions regardless of categories
            operator: { type: Array }, // Hold all available operators regardless of categories and conditions
            tradebloc: { type: Array }, // Hold availabe trade bloc to prevent multi retrieval
            postalbloc: { type: Array }, // Hold available postal bloc to prevent multi retrieval
            postalregex: { type: Array }, // Hold available postal regex to prevent multi retrieval
            region: { type: Array }, // Hold available region
            subregion: { type: Array }, // Hold available subregion
            zones: { type: Array }, // Hold available subregion
            countryCode: { type: String },
            currencyCode: { type: String }
        },
        data() {
            return {
                selectedGroupedCity: null,
                model: {
                    id: 0,
                    markupContractRuleID: this.parentID,
                    parentID: null,
                    markupRateCategoryID: 1,
                    markupRateCategoryText: null,
                    markupRateConditionID: 1,
                    rateConditionText: null,
                    dataType: 100,
                    category1: "0.00",
                    category2: "0.00",
                    rateType: 100,
                    rateApplied: 0,
                    operatorType: 650,
                    rateChargeType: 100,
                    referenceTypeID: 100,
                    referenceID: null,
                    referenceText: null,
                    logicType: 100,
                    logicTypeText: 'And',
                    sortOrder: 0,
                    summary: null
                },
                sources: {}
            }
        },
        computed: {
            rateTypes() { return (this.$vm.$enums.markupratetype ?? []).filter(x => x.id === (((this.model?.markupRateConditionID ?? 0) == 5 || (this.model?.markupRateConditionID ?? 0) == 14) ? 300 : x.id)); },
            activeCategory() { return this.categories.filter(x => x.id === this.model?.markupRateCategoryID ?? 1)[0] },
            activeCondition() { return this.conditions.filter(x => x.id == this.model?.markupRateConditionID ?? 1)[0] },
            activeOperator() { return this.operators.filter(x => x.id == this.model?.operatorType)[0] ?? this.operators[0]; },
            validOperators() { return (this.activeCondition?.operators ?? []).length > 0 ? this.activeCondition?.operators ?? [] : this.activeCategory?.operators ?? []; },
            conditions() { return this.condition.filter(x => x.rateCategoryTypeID === this.activeCategory?.id) ?? [] },
            operators() { return (this.operator ?? []).filter(x => this.validOperators.findIndex(y => y === x.id) > -1) ?? [] },
            conditionAndOperatorText() {
                let conditionText = '';
                let operatorText = this.operators.filter(x => x.id === this.model.operatorType)[0]?.text ?? '';
                if (this.model.operatorType >= 4100 && this.model.operatorType <= 8000) {
                    conditionText = this.model.markupRateConditionText?.replace('If <', 'If any <').replace('all <', 'any <');
                    operatorText = (this.operators.filter(x => x.id === this.model.operatorType)[0]?.text ?? '').replace('Any ', '');
                }
                else if (this.model.operatorType >= 8100 && this.model.operatorType <= 12000) {
                    conditionText = this.model.markupRateConditionText?.replace('If <', 'If all <').replace('any <', 'all <');
                    operatorText = (this.operators.filter(x => x.id === this.model.operatorType)[0]?.text ?? '').replace('All ', '');
                }
                else
                    conditionText = `${(this.activeCondition?.text ?? '')} ${operatorText?.toLowerCase()}`;

                if (this.model.operatorType >= 4100 && this.model.operatorType < 8000)
                    conditionText = conditionText?.replace('If <', 'If any <').replace('all <', 'any <');
                else if (this.model.operatorType >= 8100 && this.model.operatorType < 12000)
                    conditionText = conditionText?.replace('If <', 'If all <').replace('any <', 'all <');
                return `${(conditionText ?? '').replaceAll('If If', 'If')}`;
            },
            valueText() {
                let value = '';
                let category1 = ((this.model.category1 ?? "").toString().trim() === "" || (this.model.category1 ?? "").toString().trim() === "NaN") ? "0" : this.model.category1 ?? "0";
                let category2 = ((this.model.category2 ?? "").toString().trim() === "" || (this.model.category2 ?? "").toString().trim() === "NaN") ? "0" : this.model.category2 ?? "0";
                if (this.activeOperator?.id == 700 || this.activeOperator?.id == 4700 || this.activeOperator?.id == 8700)
                    return value = `${this.$filters.formatNumber(category1, (this.model?.referenceID === 200 ? 0 : 3))} ${(this.model?.referenceText ?? '')} and ${this.$filters.formatNumber(category2, (this.model?.referenceID === 200 ? 0 : 3))} ${(this.model?.referenceText ?? '')} ${this.rateAppliedText}`;
                else if (this.model.referenceTypeID >= 400 && this.model.referenceTypeID <= 2000)
                    value = '';
                else if (this.model.referenceTypeID === 100 || this.model.referenceTypeID === 200)
                    return `${this.$filters.formatNumber(this.model.category1 ?? 0, this.model?.referenceID === 200 ? 0 : 3)} ${(this.model?.referenceText ?? '')}`;
                else if (this.model.referenceTypeID === 300)
                    return this.$filters.formatCurrency(this.model.category1 ?? 0, this.$vm.$currencies.filter(x => x.id === this.model.referenceID)[0]?.code);
                else if (this.activeCondition?.dataType === 400)
                    value = this.$filters.toDateDisplay(new Date(this.model.category1));
                else if (this.activeCondition?.dataType === 300)
                    value = this.$filters.formatNumber(this.model.category1 ?? 0, 2);
                else if (this.activeCondition?.dataType === 200)
                    value = this.$filters.formatNumber(this.model.category1 ?? 0, 0);
                else if (this.activeCondition?.dataType == 100)
                    value = "\"" + (this.model.category1 ?? "") + "\"";
                else
                    value = this.model.category1 ?? "";
                value = `${value}${this.model?.referenceText ?? ''}`;
                return value ?? '';
            },
            referenceText() {
                var text = '';
                if (this.model.referenceTypeID === 100 || this.model.referenceTypeID === 200)
                    text = this.$filters.quantifiedDisplay(this.model?.referenceText, this.model.category1 ?? 0);
                return text;
            },
            rateAppliedText() { return ""; },
            summary() { return `${(this.model.logicType === 100 ? 'and ' : 'or')} ${this.conditionAndOperatorText} ${((this.valueText ?? "").length > 0 ? this.valueText : this.model?.referenceText)} ${this.model?.rateAppliedText ?? ''}`.replaceAll('  ', ' '); },
            isFlag() { return [1600, 1700, 5600, 5700, 9600, 9700].indexOf(this.model.operatorType ?? 100) !== -1; },
            subregions() {
                let data = (this.region ?? []).map(m => ({
                    label: m.name,
                    code: m.code,
                    items: (this.subregion ?? []).filter(x => x.regionID === m.id).map(mm => ({
                        name: `[${mm.code}] ${mm.name}`,
                        id: mm.id
                    }))
                }));
                return data;
            },
            appliedOperator() {
                return this.validOperators.filter(x => x === (this.model?.operatorType ?? this.activeCondition.operatorDefault))[0] ?? this.validOperators[0];
            },
            zoneNames() {
                switch (Number(this.model.category2)) {
                    case 1: return this.sources?.export ?? [];
                    case 2: return this.sources?.import ?? [];
                    case 4: return this.sources?.thirdCountry ?? [];
                    case 8: return this.sources?.thirdParty ?? [];
                    case 16: return this.sources?.returns ?? [];
                    default: return []
                }
            }
        },
        watch: {
            source: { handler() { this.$nextTick(() => { this.model = this.source; }) }, deep: true },
            selectedGroupedCity: { handler() { this.$nextTick(() => { this.model.referenceID = this.selectedGroupedCity?.id; }) }, deep: true },
            'model.referenceID': {
                handler() {
                    this.model.referenceText = null;
                    if (this.model.referenceTypeID >= 400 && this.model.referenceTypeID <= 2000 && this.model.referenceTypeID !== 1800) {
                        this.model.category1 = null;
                        this.model.category2 = null;
                    }
                    switch (this.model.referenceTypeID) {
                        case 100:
                            this.model.referenceText = this.$vm.$enums.weightunit.filter(x => x.id === this.model.referenceID)[0]?.text;
                            break;
                        case 200:
                            this.model.referenceText = this.$vm.$enums.lengthunit.filter(x => x.id === this.model.referenceID)[0]?.text;
                            break;
                        case 400:
                            if ((this.tradebloc ?? []).filter(x => x.id === Number(this.model?.referenceID ?? -1000))[0])
                                this.model.referenceText = `[${this.tradebloc.filter(x => x.id === this.model?.referenceID ?? -1000)[0].code}] ${this.tradebloc.filter(x => x.id === this.model?.referenceID ?? -1000)[0].text}`;
                            break;
                        case 500:
                            if (this.postalbloc)
                                this.model.referenceText = this.postalbloc.filter(x => x.id === this.model.referenceID)[0]?.text;
                            break;
                        case 600:
                            if (this.region)
                                this.model.referenceText = this.region.filter(x => x.id === this.model.referenceID)[0]?.text;
                            break;
                        case 700:
                            var filteredSubRegions = (this.subregions ?? []).filter(x => (x.items ?? []).findIndex(y => y.id === this.model.referenceID) !== -1)[0];
                            if (filteredSubRegions)
                                this.model.referenceText = (filteredSubRegions.items ?? []).filter(x => x.id === this.model.referenceID)[0]?.name;
                            break;
                        case 800:
                            this.model.referenceText = this.$vm.$enums.establishmenttype.filter(x => x.id === this.model.referenceID)[0]?.text;
                            break;
                        case 900:
                            if (this.isFlag) {
                                var flags = this.$filters.getFlags(this.model.referenceID);
                                this.model.referenceText = this.$vm.$enums.tradetype.filter(x => flags.indexOf(x.id) !== -1).map(x => ` [${x.code}] ${x.text}`);
                            }
                            else {
                                var tradeType = this.$vm.$enums.tradetype.filter(x => x.id === this.model.referenceID)[0];
                                this.model.referenceText = `[${tradeType?.code}] ${tradeType?.text}`;
                            }
                            break;
                        case 1000:
                            if (this.isFlag) {
                                var incotermFlags = this.$filters.getFlags(this.model.referenceID);
                                this.model.referenceText = this.$vm.$enums.incotermtype.filter(x => incotermFlags.indexOf(x.id) !== -1).map(x => ` ${x.text}`);
                            }
                            else {
                                var incoterm = this.$vm.$enums.incotermtype.filter(x => x.id === this.model.referenceID)[0];
                                this.model.referenceText = `${incoterm?.text}`;
                            }
                            break;
                        case 1100:
                            if (this.isFlag) {
                                var packagingFlags = this.$filters.getFlags(this.model.referenceID);
                                this.model.referenceText = this.$vm.$enums.serviceproviderproductpackagingtype.filter(x => packagingFlags.indexOf(x.id) !== -1).map(x => `[${x.code}] ${x.text}`);
                            }
                            else {
                                var packaging = this.$vm.$enums.serviceproviderproductpackagingtype.filter(x => x.id === this.model.referenceID)[0];
                                this.model.referenceText = `[${packaging?.code}] ${packaging?.text}`;
                            }
                            break;
                        case 1200:
                            if (this.isFlag) {
                                var customCategoryFlags = this.$filters.getFlags(this.model.referenceID);
                                this.model.referenceText = this.$vm.$enums.customscategorytype.filter(x => customCategoryFlags.indexOf(x.id) !== -1).map(x => ` [${x.code}] ${x.text}`);
                            }
                            else {
                                var customCategory = this.$vm.$enums.customscategorytype.filter(x => x.id === this.model.referenceID)[0];
                                this.model.referenceText = `[${customCategory?.code}] ${customCategory?.text}`;
                            }
                            break;
                        case 1300:
                            if (this.isFlag) {
                                var notificationFlags = this.$filters.getFlags(this.model.referenceID);
                                this.model.referenceText = this.$vm.$enums.notificationtype.filter(x => notificationFlags.indexOf(x.id) !== -1).map(x => ` [${x.code}] ${x.text}`);
                            }
                            else {
                                var notifications = this.$vm.$enums.notificationtype.filter(x => x.id === this.model.referenceID)[0];
                                this.model.referenceText = `[${notifications?.code}] ${notifications?.text}`;
                            }
                            break;
                        case 1400:
                            this.model.referenceText = this.$vm.$enums.iossavailability.filter(x => x.id === this.model.referenceID)[0]?.text;
                            break;
                        case 1500:
                            if (this.isFlag) {
                                var dgFlags = this.$filters.getFlags(this.model.referenceID);
                                this.model.referenceText = this.$vm.$enums.dangerousgoodstype.filter(x => dgFlags.indexOf(x.id) !== -1).map(x => ` [${x.code}] ${x.text}`);
                            }
                            else {
                                var dg = this.$vm.$enums.dangerousgoodstype.filter(x => x.id === this.model.referenceID)[0];
                                this.model.referenceText = `[${dg?.code}] ${dg?.text}`;
                            }
                            break;
                        case 1600:
                            if (this.isFlag) {
                                var handlingFlags = this.$filters.getFlags(this.model.referenceID);
                                this.model.referenceText = this.$vm.$enums.packageadditionalhandlingtype.filter(x => handlingFlags.indexOf(x.id) !== -1).map(x => ` [${x.code}] ${x.text}`);
                            }
                            else {
                                var handling = this.$vm.$enums.packageadditionalhandlingtype.filter(x => x.id === this.model.referenceID)[0];
                                this.model.referenceText = `[${handling?.code}] ${handling?.text}`;
                            }
                            break;
                        case 1700:
                            if (this.isFlag) {
                                var dayofweekFlags = this.$filters.getFlags(this.model.referenceID);
                                this.model.referenceText = this.$vm.$enums.dayofweek.filter(x => dayofweekFlags.indexOf(x.id) !== -1).map(x => ` [${x.code}] ${x.text}`);
                            }
                            else {
                                var dayofweek = this.$vm.$enums.dayofweek.filter(x => x.id === this.model.referenceID)[0];
                                this.model.referenceText = `[${dayofweek?.code}] ${dayofweek?.text}`;
                            }
                            break;
                        case 1900:
                            if (this.isFlag) {
                                var shippingFlags = this.$filters.getFlags(this.model.referenceID);
                                this.model.referenceText = this.$vm.$enums.documentshippingtype.filter(x => shippingFlags.indexOf(x.id) !== -1).map(x => ` [${x.code}] ${x.text}`);
                            }
                            else {
                                var shipping = this.$vm.$enums.documentshippingtype.filter(x => x.id === this.model.referenceID)[0];
                                this.model.referenceText = `[${shipping?.code}] ${shipping?.text}`;
                            }
                            break;
                        case 2000:
                            var filtered = (this.postalregex ?? []).filter(x => x.items.findIndex(y => y.id === this.model.referenceID) !== -1)[0];
                            if (filtered)
                                this.model.referenceText = (filtered.items ?? []).filter(x => x.id === this.model.referenceID)[0]?.text;
                            break;
                        default:
                            this.model.referenceText = null;
                            break;
                    }
                }, deep: true
            },
            'model.markupRateConditionID': {
                handler() {
                    this.$nextTick(() => {
                        if (this.model.referenceTypeID === 300 && this.model.id === 0) {
                            this.model.referenceID = 3;
                            this.model.rateType = 100;
                        }
                        else if (this.model.referenceTypeID === 400 && this.model.id === 0) {
                            this.model.referenceID = 0;
                        }
                        if (this.model.rateConditionID === 25)
                            this.model.referenceText = '';
                    })
                }, deep: true
            },
            'model.category1': {
                handler() {
                    if (this.model.category1 === "NaN")
                        this.model.category1 = null;
                }, deep: true
            },
            'model.category2': {
                handler() {
                    if (this.model.category2 === "NaN")
                        this.model.category2 = null;
                }, deep: true
            },
            'summary': { handler() { if (this.model.sortOrder > 0) this.$nextTick(() => { this.onSync(); }) }, deep: true },
            'rateAppliedText': { handler() { if (this.model.sortOrder > 0) this.$nextTick(() => { this.onSync(); }) }, deep: true }
        },
        methods: {
            onSync() {
                this.model.summary = `${(this.model.logicType === 100 ? 'and ' : 'or')} ${this.conditionAndOperatorText} ${((this.valueText ?? "").length > 0 ? this.valueText : this.model?.referenceText)} ${this.model?.rateAppliedText ?? ''}`.replaceAll('  ', ' ');
                this.model.category1 = this.model?.category1?.toString();
                this.model.category2 = this.model?.category2?.toString();
                this.$emit('is-sync', this.model);
            },
            onDelete(id, tempParentID, tempChildID) {
                this.$emit('is-delete-subcondition', { id: id, tempParentID: tempParentID, tempChildID: tempChildID })
            },
            onChangeCategory(e) {
                if (this.model) {
                    this.model.markupRateCategoryID = e?.value ?? this.model?.markupRateCategoryID ?? 1;
                    this.model.markupRateConditionID = e?.record?.conditionDefault ?? this.model?.markupRateConditionID ?? e?.record?.conditionDefault;
                }
            },
            onChangeCondition(e) {
                if (this.model) {
                    this.model.markupRateConditionID = e?.value ?? this.model?.markupRateCategoryID ?? 1;
                    this.model.markupRateConditionText = e?.text;
                    this.model.operatorType = e?.record?.operatorDefault ?? this.model?.operatorType ?? 100;
                    this.model.referenceTypeID = e?.record?.referenceType ?? this.model?.referenceTypeID ?? 100;
                    this.model.referenceID = this.model?.referenceID ?? this.activeCondition?.referenceDefault;
                    if (this.model.referenceTypeID === 300 && this.model.id === 0) {
                        this.model.referenceID = 3;
                        this.model.rateType = 100;
                    }
                    else if (((this.model.referenceType === 400 && this.model.id === 0) || this.activeCondition?.dataType === 400) && this.model.id === 0) {
                        this.model.referenceID = null;
                        this.model.category1 = null;
                        this.model.category2 = null;
                    }
                    else if (!this.model.referenceTypeID) {
                        this.model.referenceText = null;
                    }
                }
            },
            onChangeOperator(e) {
                if (this.model) {
                    this.model.operatorType = e?.value ?? this.activeCategory.operatorDefault;
                    this.model.operatorText = e?.text ?? this.operators.filter(x => x.id === this.model.operatorType)[0]?.text ?? '';
                    if (this.model.operatorType >= 4100 && this.model.operatorType <= 8000) {
                        this.model.rateConditionText = this.model.rateConditionText?.replace('If <', 'If any <').replace('all <', 'any <');
                        this.model.operatorText = (e?.text ?? this.operators.filter(x => x.id === this.model.operatorType)[0]?.text ?? '').replace('Any ', '');
                    }
                    else if (this.model.operatorType >= 8100 && this.model.operatorType <= 12000) {
                        this.model.rateConditionText = this.model.rateConditionText?.replace('If <', 'If all <').replace('any <', 'all <');
                        this.model.operatorText = (e?.text ?? this.operators.filter(x => x.id === this.model.operatorType)[0]?.text ?? '').replace('All ', '');
                    }
                }
            },
            onChangeReference(e) {
                if (this.model) {
                    this.model.referenceID = e?.value ?? this.model.referenceID;
                    this.model.referenceText = e?.text;
                    if (this.model.referenceTypeID === 400 || this.model.referenceTypeID === 2000) {
                        this.model.category1 = null;
                        this.model.category2 = null;
                    }
                    else if (this.model.referenceTypeID === 200)
                        this.model.referenceText = null;
                }
            },
            onChangeValue(e) {
                if (this.model) {
                    if (this.activeCondition?.dataType === 200 && this.activeCondition?.referenceType === 1800)
                        this.$nextTick(() => {
                            this.model.category1 = Number(e?.value ?? this.model?.category1 ?? null);
                        });
                    else
                        this.$nextTick(() => {
                            this.model.category1 = e?.value ?? this.model?.category1 ?? null;
                        });
                }
            },
            onChangeValue2(e) {
                if (this.model)
                    this.model.category2 = e?.value ?? this.model?.category2 ?? null;
            }
        },
        created() {
            this.model = this.$filters.init(this.source ?? {
                id: 0,
                markupContractRuleID: this.parentID,
                parentID: null,
                markupRateCategoryID: 1,
                markupRateCategoryText: null,
                markupRateConditionID: 1,
                rateConditionText: null,
                dataType: 100,
                category1: "0.00",
                category2: "0.00",
                rateType: 100,
                rateApplied: 0,
                operatorType: 650,
                rateChargeType: 100,
                referenceTypeID: 100,
                referenceID: null,
                referenceText: null,
                logicType: 100,
                logicTypeText: 'And',
                sortOrder: 1,
                summary: null
            });

        },
        mounted() {
            var buttons = document.getElementsByClassName('p-inputnumber-button');
            for (var i = 0; i < buttons.length; i++)
                buttons[i].tabIndex = -1;
        }
    }
</script>