<template>
    <form-record :title="dynamicTitle" :model="model" :modelState="v$" @is-reset="resetRecord" :viewOnly="true">
        <div class="p-d-flex" style="border: 0;">
            <div class="p-col-12">
                <pv-steps :model="steps" style="margin: -2.4rem -1.5rem 0.5rem -1.5rem; border-bottom: 1px solid var(--surface-d); padding: 10px; background: var(--surface-c) " />
                <router-view name="stepper" v-slot="{Component}" :formData="model" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="complete($event)">
                    <keep-alive>
                        <component :is="Component" />
                    </keep-alive>
                </router-view>
            </div>
        </div>
    </form-record>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    export default {
        name: 'ProviderZoneCreate',
        setup: () => ({ v$: useVuelidate() }),
        mixins: [mixins.RECORD],
        data() {
            return {
                steps: [{ label: 'Basic Detail', to: '/facility/rates/zone/create' },
                    { label: 'Zone Names', to: '/facility/rates/zone/step/names' },
                    { label: 'Zone Groups', to: '/facility/rates/zone/step/groups' },
                    { label: 'Zone Matrix', to: '/facility/rates/zone/step/matrix' },
                    { label: 'Confirmation', to: '/facility/rates/zone/step/confirmation' }]
            }
        },
        computed: {
            dynamicTitle() {
                if (this.model.code && this.model.name)
                    return `[${this.model.code}] ${this.model.name}`;
                return null;
            }
        },
        methods: {
            nextPage(event) {
                for (let field in event.formData) {
                    this.model[field] = event.formData[field];
                }
                this.$router.push(this.steps[event.pageIndex + 1].to);
            },
            prevPage(event) {
                for (let field in event.formData) {
                    this.model[field] = event.formData[field];
                }
                this.$router.push(this.steps[event.pageIndex - 1].to);
            },
            complete(model) {
                let entity = this.$filters.init(model ?? this.model);
                entity.environmentID = this.$vm.$appSession.environment.id;
                this.insert(entity).then(() => {
                    if (this.errors)
                        this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: this.errors[0].message, life: 3000 });
                    else {
                        this.$router.push({ name: this.router.module + '-edit', params: { mask: this.record.mask } });
                        this.$toast.add({ severity: 'success', summary: 'Record Insert Success!', detail: 'The record was added successfully.', life: 3000 });
                        this.resetModelState();
                        setTimeout(() => this.$emit("is-reset"), 500);
                    }
                }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: err.xhr, life: 3000 });
                });
            },
        },
        created() {
            this.$router.push({ name: this.router.module + '-details' });
        }
    }
</script>
<style>
    .p-steps-number {
        border-radius: 4px !important;
        padding: 3px;
    }
</style>