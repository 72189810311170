<template>
    <cosmos-grid :id="id" :searchFilters="['code', 'name']" :source="sources.surchages" sourceType="array" :paged="false" :autoGenerate="false" :deleteDisplayFields="deleteDisplay" :showAdd="false" :showDelete="false" :showExport="false" :showOperation="false" :pageSize="5" groupBy="groupingName">
        <template #groupheader="slotProps">
            <span class="image-text" style="text-transform: uppercase; font-weight: 700 !important">{{ slotProps.data.groupingName ?? "General" }}</span>
        </template>
        <pv-grid-column field="code" header="Code" headerStyle="width: 10rem" />
        <pv-grid-column field="name" header="Name" headerStyle="width: 20rem" />
        <pv-grid-column field="description" header="Description">
            <template #body="slotProps">
                <span v-html="slotProps.data.description?.replaceAll('\\r\\n', '<br />')"></span>
            </template>
        </pv-grid-column>
    </cosmos-grid>
</template>
<script>
    export default {
        name: "IntegrationGrid",
        props: {
            id: { type: String },
            code: { type: String, default: null },
            provider: { type: String, default: null }
        },
        data() {
            return {
                sources: {
                    surchages: []
                }
            }
        },
        watch: {
            provider() {
                this.getSource();
            }
        },
        methods: {
            getSource() {
                this.sources.surchages = [];
                this.$nextTick(() => {
                    if (this.provider) {
                        this.$store.dispatch("providersurcharge/getURL", { url: `/services/settings/serviceprovidersurcharge/byprovider/${this.provider}` })
                            .then((response) => {
                                if (response && response.success) {
                                    this.sources.surchages = response?.result ?? response ?? [];
                                }
                            });
                    }
                })
            }
        },
        created() { this.getSource(); }
    }
</script>