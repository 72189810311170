<template>
    <form-wrapper :title="router.title" :sub-title="router.subtitle">
        <cosmos-grid id="UserList" sourceType="url" :source="url" :searchFilters="searchFields" :pageSize="15" :filters="activeFilters" :forceRefresh="forceRefresh" :showAdd="false" :autoGenerate="false" :columns="columns" :deleteColumns="deleteDisplay" :showDelete="false" :showOperation="false">
            <template #toolbar>
                <div class="p-d-flex p-jc-between p-ai-center">
                    <span>
                    </span>
                    <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                        <i class="pi pi-search" />
                        <pv-input v-model="activeFilters['global'].value" placeholder="Keyword Search" style="width: 100%;" @keyup="onForceFilter" />
                    </span>
                </div>
            </template>
            <pv-grid-column field="licenseKey" header="Barcode" :frozen="true" headerStyle="min-width: 6rem; max-width: 6rem; width: 6rem;" style="min-width: 6rem; max-width: 6rem; width: 6rem; text-overflow: ellipsis; white-space: nowrap; text-align: center; ">
                <template #body="slotProps">
                    <qr-code :value="slotProps.data.code" :size="50" level="H" :background="'black'" foreground="#FFFFFF" style="border: 1px solid gray;" />
                </template>
            </pv-grid-column>
            <pv-grid-column field="username" header="Login Credentials" headedrStyle="min-width: 20rem;max-width: 20rem; " stdyle="min-width: 20rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; ">
                <template #body="slotProps">
                    <div class="p-d-flex" style="height: 100%;">
                        <div class="p-as-end">
                            <h5 style="margin-bottom: 5px;"><span><i class="pi pi-user pi-fw"></i>  {{ slotProps.data.username}}</span></h5>
                            <p style="margin-bottom: 5px;"><span><i class="pi pi-key pi-fw" v-tooltip.bottom="(slotProps.data.password ? 'Click to view password' : null)" @click="slotProps.data.ako = !slotProps.data.ako"></i>  <span v-if="slotProps.data.password && slotProps.data.ako">{{ slotProps.data.password }}</span><span v-else style="font-size: 1.25rem; font-family: 'Roboto Mono', monospace">**********</span></span></p>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="text" header="User Details" style="width: 100%; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; ">
                <template #body="slotProps">
                    <div class="p-d-flex" style="height: 100%;">
                        <div class="p-as-stretch" style="margin-right: 10px;">
                            <img :src="`https://${cdn}/cosmos/development/assets/_img/users/${slotProps.data.mask.toUpperCase()}.jpg`" style="height: 50px; width: 50px; border-radius: 100%; background-color: var(--surface-f);" :class="`status-${(slotProps.data.sessionID ? 'online': 'offline' )}`" onerror="this.src = '/_assets/img/dp-noimage.png'" v-tooltip="`${(slotProps.data.sessionID ? 'Online' : 'Offline')}`" />
                        </div>
                        <div class="p-d-flex" style="height: 100%;">
                            <div class="p-as-end">
                                <h5 style="margin-top: 5px; margin-bottom: 5px;"><span>{{ slotProps.data.firstName }} {{ slotProps.data.lastName }}</span></h5>
                                <span><i class="pi pi-envelope pi-fw"></i> <a :href="`mailto:${slotProps.data.email}`" class="p-button-link">{{ slotProps.data.email }}</a></span>
                            </div>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="companyID" header="Company">
                <template #body="slotProps">
                    {{ companies.filter(x => x.id == slotProps?.data?.companyID)[0]?.altName ?? companies.filter(x => x.id == slotProps?.data?.companyID)[0]?.name }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="type" header="User Role">
                <template #body="slotProps">
                    {{ $vm.$enums.usertype.filter(x => x.id === slotProps.data.type)[0]?.text }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="deleteDate" header="Delete Info">
                <template #body="slotProps">
                    <h5 style="margin-top: 5px; margin-bottom: 5px;">{{ users.filter(x => x.id == slotProps?.data?.deleteUserID)[0]?.firstName }} {{ users.filter(x => x.id == slotProps?.data?.deleteUserID)[0]?.lastName }}</h5>
                    <span>{{ $filters.toDateTimeDisplay(slotProps.data.deleteDate) }}</span>
                </template>
            </pv-grid-column>
            <pv-grid-column :class="[{'p-field-hidden': (user?.userType ?? -1) > 2 }]" field="mask" headerStyle="min-width: 4rem; max-width: 4rem; width: 4rem" style="min-width: 4rem; max-width: 4rem; width: 4rem; border-left: 1px solid var(--surface-d); border-right: none; text-align: center; object-position: center; padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
                <template #body="slotProps">
                    <div class="p-d-flex" style="width: 2rem;">
                        <div class="p-col">
                            <pv-button v-if="(user?.userType ?? -1) < 3 && slotProps.data.verificationConfirmDate && slotProps.data.recordStatus === 0" icon="fas fa-person-walking-arrow-loop-left fa-fw" class="p-button-warning" @click="showRestoreUser(slotProps.data)" v-tooltip.bottom="'Restore User'"></pv-button>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
        </cosmos-grid>
    </form-wrapper>
    <is-dialog header="Restore User" :visible="dialog.upgrade"  @is-confirm="onRestoreUser(entity)" @is-cancel="onCancel()">
        <div class="confirmation-content p-d-flex">
            <div><i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" /></div>
            <div>
                You are about to restore this record:
                <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                    <tr>
                        <th style="text-align:left;">Name</th>
                        <td>:</td>
                        <td>{{ entity.firstName }} {{ entity.lastName }}</td>
                    </tr>
                    <tr>
                        <th style="text-align:left;">Email</th>
                        <td>:</td>
                        <td><a :href="`mailto:${entity.email}`">{{ entity.email }}</a></td>
                    </tr>
                    <tr>
                        <th style="text-align:left;">Company</th>
                        <td>:</td>
                        <td>{{ companies.filter(x => x.id == entity?.companyID)[0]?.altName ?? companies.filter(x => x.id == entity?.companyID)[0]?.name }}</td>
                    </tr>
                    <tr>
                        <th style="text-align:left;">User Role</th>
                        <td>:</td>
                        <td>{{ $vm.$enums.usertype.filter(x => x.id === entity.type)[0]?.text }}</td>
                    </tr>
                </table>
                Please be aware of the following:
                <ul>
                    <li>The user may be able to access the application again.</li>
                </ul>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
</template>
<script>
    import { FilterMatchMode } from 'primevue/api';
    import config from '@/assets/lib/cosmos/_js/config.js';
    import mixins from '@/assets/lib/cosmos/_js/mixin.js';
    export default {
        name: 'UserListTemplate',
        mixins: [mixins.GRID],
        data() {
            return {
                filter: {},
                columns: [],
                deleteDisplay: ["username", "email", "firstName", "lastName"]
                ,searchFields: ["username", "email", "firstName", "lastName"]
                , forceRefresh: false
                , dialog: { delete: false, upgrade: false }
                , companies: []
                , users: []
                , data: null
            }
        },
        computed: {
            type() { return this.$route.meta.typeId ?? 0; },
            tenant() { return config.common.$tenant().filter(x => x.id === 1)[0]; },
            cdn() { return `${config.config.endpoint.cdn}`.split('/')[2]; },
            url() { return `/services/settings/user?rce=5` },
            activeFilters() {
                var filter = {};
                if (this.filters)
                    filter = this.filters;
                filter["global"] = { value: "", matchMode: FilterMatchMode.CONTAINS };
                return filter;
            }
        },
        methods: {
            onCancel() {
                this.dialog.delete = false;
                this.dialog.upgrade = false;
                this.entity = null;
            },
            showRestoreUser(user) {
                this.dialog.upgrade = true;
                this.data = this.$filters.init(user);
                this.entity = user;
            },
            onRestoreUser(user) {
                this.$store.dispatch(this.router.module + "/patchURL", {
                    url: '/services/settings/user/' + user.mask, payload: [
                        {
                            "op": "replace",
                            "path": "/deleteDate",
                            "value": null
                        },
                        {
                            "op": "replace",
                            "path": "/deleteUserID",
                            "value": null
                        },
                        {
                            "op": "replace",
                            "path": "/recordStatus",
                            "value": 0
                        }
                    ]
                }).then((response) => {
                    console.log(response);
                    if (response && response.id == this.entity.id) {
                        this.$toast.add({ severity: 'success', summary: 'Record Update Sucessfully', detail: 'The user role was successfully updated', life: 3000 });
                        this.onRefresh();
                    }
                    else {
                        this.$toast.add({ severity: 'error', summary: 'Record Updated Failed', detail: this.errors[0].message, life: 3000 });
                    }
                    this.onCancel();
                }).catch((err) => { console.log(err); });
            },
            onForceFilter(e) {
                if (e.keyCode === 13 || this.activeFilters["global"].value === '') {
                    this.onRefresh();
                }
            },
            onRefresh() {
                this.forceRefresh = false;
                this.$nextTick(() => { this.forceRefresh = true; });
            },
            generateTooltip(user) {
                if (!user.isActive)
                    return `<b>LAST LOGIN DATE</b>:<br /> <span style='font-size: 1rem'>${this.$filters.toDateTimeDisplay(user.lastLoginDate)}</span>`
                return null;
            }
        },
        created() {
            this.$store.dispatch("user/getURL", { url: `/services/settings/company` })
                .then((response) => {
                    this.companies = response?.result ?? [];
                })
            this.$store.dispatch("user/getURL", { url: `/services/settings/user` })
                .then((response) => {
                    this.users = response?.result ?? [];
                })
        }
    }
</script>
<style>
    .status-online {
        border: 2px solid var(--bg-success)
    }
    .status-offline {
        border: 2px solid var(--bg-danger)
    }
    #UserList td {
        padding: 0.5rem 0.75rem 0.25rem 0.75rem;
    }
</style>