<!--
    
    This must be used as below:
    <div class="p-grid">
        <div class="p-col-4">
            {{ model.address }}
        </div>
        <div class="p-col-4">
            <form-contact-info id="contact" v-model="model.contact" @is-sync="model.address = $event.address" :useRegistrationNumber="true" />
        </div>
        <div class="p-col-4">
            <form-address-info id="address" v-model="model.address" :useRegistrationNumber="true" />
        </div>
    </div>
-->
<template>
    <div v-if="!disabled || (disabled && disabledView.toLowerCase() === 'field')">
        <form-lookup :id="`${id}_Country`" type="autocomplete" :disabled="disabled" source="country" :label="header.country" :postalCode="model.postalCode" :v$="v$" :showDirectory="showDirectory" :showLookup="false" v-model="model.countryID" @is-sync="onCountrySelect($event)" :required="true" :showCopy="showCopy" />
        <form-input :id="`${id}_PostalCode`" :disabled="disabled" v-model="model.postalCode" :label="header.postalCode" :v$="v$" :vID="(validation.requirePostalCode ? 'postalCode' : null)" v-focus :showLookup="showLookup" @is-fetchify="onPostalLookup($event)" :required="validatePostalCode ? validation.requirePostalCode : validatePostalCode" :showCopy="showCopy" />
        <form-input v-if="useCompany" :disabled="(forceEnableCompany ? false : disabled)" :id="`${id}_Company`" v-model="model.company" :label="companyLabel" :v$="v$" :showCopy="showCopy" />
        <form-input :id="`${id}_Address1`" :disabled="disabled" v-model="model.address1" :label="header.address1" :v$="v$" vID="address1" :showCopy="showCopy" />
        <form-input :id="`${id}_Address2`" :disabled="disabled" v-model="model.address2" :label="header.address2" :v$="v$" :showCopy="showCopy" />
        <form-input :id="`${id}_Address3`" :disabled="disabled" v-model="model.address3" :label="header.address3" :v$="v$" :showCopy="showCopy" />
        <form-input :id="`${id}_City`" :disabled="disabled" v-model="model.city" :label="header.city" :v$="v$" vID="city" :showCopy="showCopy" />
        <form-lookup v-if="useState" :id="`${id}_State`" :disabled="disabled" :label="header.state" :source="stateRecords" v-model="model.state" @is-sync="model.stateCode = $event.record?.code; model.state = $event.record?.code" :required="true" valueField="code" textField="name" :showCopy="showCopy" />
        <form-input v-else :id="`${id}_State`" :disabled="disabled" v-model="model.state" :label="header.state" :v$="v$" :vID="stateVID" :required="(validateState ? validation.requireState: validateState)" :showCopy="showCopy" />
    </div>
    <div v-else style="margin-bottom: 10px;">
        <div style="font-size: 1.2rem; font-weight: 600; margin-bottom: 5px;" v-if="useCompany">{{model.companyName}}</div>
        <span class="p-code-text" v-html="`${fullAddress}`"></span>
    </div>
    <pv-dialog :header="'Address Lookup'" :visible="tools.fetchify" :modal="true" :closable="false" :closeOnEscape="true" :draggable="true">
        <div class="confirmation-content">
            <slot>
                <div class="p-field p-grid" style="width: 500px;">
                    <div class="p-col-12">
                        <form-input :id="`${id}_Search`" :value="model?.postalCode" placeholder="Enter address parts (postal code, city, etc.)" v-focus />
                    </div>
                </div>
            </slot>
        </div>
        <template #footer>
            <pv-button label="Close" :icon="cancelIcon" @click="this.tools.fetchify = false" class="p-button" />
        </template>
    </pv-dialog>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength, helpers } from '@vuelidate/validators';
    /*    import mixins from '@/assets/lib/cosmos/_js/core-mixins.js';*/
    /** CUSTOM VALIDATOR **/
    const regex = (param) => helpers.withParams({ type: 'regex', value: param }, (value) => value.match(param) != null);
    export default {
        name: 'FormAddressField',
        //mixins: [mixins.COMPONENT],
        setup: () => ({ v$: useVuelidate() }),
        props: {
            id: { type: String, required: true, validator: (value) => /^[a-zA-Z0-9_]*$/.test(value) },
            value: { type: Object },
            modelValue: { type: Object },
            useCompany: { type: Boolean, default: false },
            forceEnableCompany: { type: Boolean, default: false },
            companyLabel: { type: String, default: "Company" },
            useEstablishmentType: { type: Boolean, default: false },
            showDirectory: { type: Boolean, default: false },
            showLookup: { type: Boolean, default: false },
            showCopy: { type: Boolean, default: false },
            disabledView: { type: String, default: "field", validator: (value) => ["field", "perline", "singleline"].indexOf((value ?? "field").toLowerCase()) !== -1 },
            validateState: { type: Boolean, default: true },
            validatePostalCode: { type: Boolean, default: true },
        },
        data() {
            return {
                fetchify: null,
                tools: {
                    fetchify: false
                },
                header: {
                    address1: "Address Line 1",
                    address2: "Address Line 2",
                    address3: "Address Line 3",
                    city: "City",
                    state: "State",
                    postalCode: "Postal Code",
                    country: "Country"
                },
                model: {
                    id: null,
                    temporaryID: null,
                    company: null,
                    address1: null,
                    address2: null,
                    address3: null,
                    city: null,
                    state: null,
                    stateCode: null,
                    postalCode: null,
                    countryID: 238,
                    countryData: null,
                    establishmentType: 100
                },
                validation: {
                    pattern: null,
                    rei: 'state',
                    requirePostalCode: false,
                    requireState: false,
                    patternUser: null
                },
            }
        },
        computed: {
            stateVID() {
                return this.validation.requireState ? 'state' : null;
            },
            regexPattern() {
                return this.validation.pattern;
            },
            useState() {
                return (this.model.countryData?.states ?? []).length > 0
            },
            stateRecords() {
                return this.model.countryData.states;
            },
            fullAddress() {
                let separator = this.disabledView == "perline" ? "<br />" : ", ";
                let country = this.$vm.$countries.filter(x => x.id == this.model.countryID)[0];
                let data = ((this.model.address1 ?? "").trim().length > 0 ? ((this.model.address1 ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') + separator : "") + " " +
                    ((this.model.address2 ?? "").trim().length > 0 ? ((this.model.address2 ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') + separator : "") + " " +
                    ((this.model.address3 ?? "").trim().length > 0 ? ((this.model.address3 ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') + separator : "") + " " +
                    ((this.model.city ?? "").trim().length > 0 ? ((this.model.city ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') + separator : "") + " " +
                    ((this.model.postalCode ?? "").trim().length > 0 ? ((this.model.postalCode ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') + separator : "") + " " +
                    ((this.model.state ?? "").trim().length > 0 ? ((this.model.state ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') + separator : "") + " " +
                    ((country?.text ?? "").trim().length > 0 ? ((country?.text ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') : "") + " "
                return data;
            }
        },
        validations() {
            /** DYNAMIC VALIDATION **/
            let state = {
                model: {
                    address1: { required, maxLength: maxLength(35), $autoDirty: true },
                    address2: { maxLength: maxLength(35), $autoDirty: true },
                    address3: { maxLength: maxLength(35), $autoDirty: true },
                    city: { required, maxLength: maxLength(35), $autoDirty: true },
                    countryID: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                }
            };
            if (this.validation.requirePostalCode) {
                state.model.postalCode = { required, maxLength: maxLength(15), $autoDirty: true };
                if (this.validatePostalCode && this.validation.pattern && this.validation.patternUser) {
                    state.model.postalCode = { required, maxLength: maxLength(15), $autoDirty: true, regex: helpers.withMessage(`The postal code must follow this format: ${this.validation.patternUser}.`, regex(this.validation.pattern)) };
                }
            }
            else
                state.model.postalCode = { maxLength: maxLength(15), $autoDirty: true };
            //if (this.validation.pattern)


            if (this.validateState && this.validation.requireState)
                state.model.state = { required, maxLength: maxLength(35), $autoDirty: true };
            else
                state.model.state = { maxLength: maxLength(35), $autoDirty: true };
            return state;
        },
        watch: {
            model: {
                handler(e) {
                    this.onSync(e);
                },
                deep: true
            },
            value() {
                this.model = this.value;
            },
            modelValue() {
                this.model = this.modelValue ?? {
                    id: null,
                    temporaryID: null,
                    company: null,
                    address1: null,
                    address2: null,
                    address3: null,
                    city: null,
                    state: null,
                    stateCode: null,
                    postalCode: null,
                    countryID: 238,
                    countryData: null,
                    establishmentType: 100
                };
                this.$emit("update:modelValue", this.model);

            }

        },
        methods: {
            onCountrySelect(e) {
                this.model.countryData = this.$filters.init(e.record);
                this.header.postalCode = e?.record?.postalCodeLabel ?? "Postal Code";
                this.header.city = e?.record?.cityLabel ?? "City";
                this.header.state = e?.record?.stateLabel ?? "State";
                this.validation.requireState = (e?.record?.stateRequired ?? false);
                this.validation.requirePostalCode = (e?.record?.postalCodeRequired ?? false);
                this.validation.pattern = e?.record?.postalCodeRegExPattern ?? null;
                this.validation.patternUser = e?.record?.postalCodePattern ?? null;
                this.$nextTick(() => this.v$.$touch());
                if (this.showLookup) {
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.fetchify.selectCountry(e?.record?.iso3?.toLowerCase()); /// must be lowecase
                        }, 200);
                    });
                }
            },
            onSync() {
                let data = {
                    company: this.model?.company,
                    address1: (this.model.address1 ?? "").trim().length > 0 ? (this.model.address1 ?? "").trim() : null,
                    address2: (this.model.address2 ?? "").trim().length > 0 ? (this.model.address2 ?? "").trim() : null,
                    address3: (this.model.address3 ?? "").trim().length > 0 ? (this.model.address3 ?? "").trim() : null,
                    city: (this.model.city ?? "").trim().length > 0 ? (this.model.city ?? "").trim() : null,
                    state: (this.model.state ?? "").trim().length > 0 ? (this.model.state ?? "").trim() : null,
                    stateCode: null,
                    postalCode: (this.model.postalCode ?? "").trim().length > 0 ? (this.model.postalCode ?? "").trim() : null,
                    countryID: this.model.countryID,
                    countryData: this.$vm.$countries.filter(x => x.id == this.model.countryID)[0],
                    establishmentType: this.model.establishmentType//(this.model.companyName ?? "").trim().length > 0 ? 100 : 200
                };
                this.model.countryData = this.$vm.$countries.filter(x => x.id == this.model.countryID)[0];
                if (this.model.countryData && this.model.countryData.useStateCode)
                    data.stateCode = this.model.stateCode;
                this.$emit("is-sync", data);
                this.$emit("update:modelValue", this.model);
            },
            onPostalLookup(e) {
                this.tools.fetchify = e;
                const that = this;
                if (this.showLookup) {
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.fetchify.attach({ search: `${this.id}_Search` },
                                {
                                    onResultSelected: function (c2a, elements, address) {
                                        that.model.company = address.company_name;
                                        that.model.address1 = address.line_1;
                                        that.model.address2 = address.line_2;
                                        that.model.address3 = address.line_3;
                                        that.model.city = address.locality;
                                        that.model.postalCode = address.postal_code;
                                        that.model.state = address.province_code ?? address.province_name;
                                        that.model.stateCode = address.province_code;
                                        that.model.establishmentType = address.type.indexOf("Residential") !== -1 ? 200 : 100;
                                        that.$emit("is-selected", address);
                                        that.tools.fetchify = false;
                                    }
                                });
                        }, 200);
                    });
                }
            }
        },
        created() {
            this.model = this.modelValue ?? this.value ?? {
                id: null,
                temporaryID: null,
                company: null,
                address1: null,
                address2: null,
                address3: null,
                city: null,
                state: null,
                stateCode: null,
                postalCode: null,
                countryID: 238,
                countryData: null,
                establishmentType: 100
            };
            this.fetchify = this.$fetchify;
        }
    }
</script>
<style>
    #cc_c2a {
        z-index: 10000 !important;
    }
</style>