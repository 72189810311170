<template>
    <basic-template v-if="template === 100" :effectiveDateID="effectiveDateID" :parentID="parentID" :tradeType="tradeType" :categoryType="categoryType" :weightUnit="weightUnit" :currencyCode="currencyCode" :countryCode="countryCode" :providerID="providerID" />
    <country-template v-else-if="template === 300" :effectiveDateID="effectiveDateID" :parentID="parentID" :tradeType="tradeType" :categoryType="categoryType" :weightUnit="weightUnit" :currencyCode="currencyCode" :countryCode="countryCode" :providerID="providerID" />
    <parcel-template v-else-if="template === 500" :effectiveDateID="effectiveDateID" :parentID="parentID" :tradeType="tradeType" :categoryType="categoryType" :weightUnit="weightUnit" :currencyCode="currencyCode" :countryCode="countryCode" :providerID="providerID" />
    <zone-template v-else-if="template === 200" :effectiveDateID="effectiveDateID" :parentID="parentID" :zoneID="zoneID" :tradeType="tradeType" :categoryType="categoryType" :weightUnit="weightUnit" :currencyCode="currencyCode" :countryCode="countryCode" :providerID="providerID" />
    <zonecountry-template v-else-if="template === 400" :effectiveDateID="effectiveDateID" :parentID="parentID" :zoneID="zoneID" :tradeType="tradeType" :categoryType="categoryType" :weightUnit="weightUnit" :currencyCode="currencyCode" :countryCode="countryCode" :providerID="providerID" />
</template>
<script>
    import basictemplate from '@/components/facility/rates/contract/templates/basictemplate-grid.vue';
    import zonetemplate from '@/components/facility/rates/contract/templates/zonetemplate-grid.vue';
    import countrytemplate from '@/components/facility/rates/contract/templates/countrytemplate-grid.vue';
    import zonecountrytemplate from '@/components/facility/rates/contract/templates/zonecountrytemplate-grid.vue';
    import parceltemplate from '@/components/facility/rates/contract/templates/parceltemplate-grid.vue';
    export default {
        name: "TemplateForms",
        components: {
            'basic-template': basictemplate,
            'zone-template': zonetemplate,
            'country-template': countrytemplate,
            'zonecountry-template': zonecountrytemplate,
            'parcel-template': parceltemplate,
        },
        props: {
            parentID: { type: Number, default: null },
            providerID: { type: Number, default: null },
            effectiveDateID: { type: Number },
            zoneID: { type: Number, default: null },
            template: { type: Number, default: 100 },
            tradeType: { type: Number, default: 1 },
            categoryType: { type: Number, default: 100 },
            weightUnit: { type: Number, default: 100 },
            currencyCode: { type: String },
            countryCode: { type: String }
        }
    }
</script>
