<template>
    <!--<is-loader v-if="activity.parentLoading"></is-loader>-->
    <div class="content-section header">
        <div class="feature-intro">
            <slot name="header">
                <h1><span>SIMULATE RULE</span></h1>
                <p>{{ this.$props?.subtitle ?? router.subtitle }}</p>
            </slot>
        </div>
    </div>
    <div class="content-section body">
        <div class="card">
            <div class="p-d-flex  p-grid p-col-12" style="padding-bottom:0 !important;">
                <div class="p-col-12 p-md-3">
                    <div class="card" style="padding: 0.7rem; min-height: 30.74rem; ">
                        <h4><span>Collection Details</span></h4>
                        <ruleAddress-form id="collectionAddress" :value="collectionAddress" @is-sync="populateCollectionAddress($event)" :showAddress="true" :useCompany="true" />
                        <form-input id="collectionFirstname" label="First Name" :v$="v$" :value="model.collectionContact.firstName" @is-sync="model.collectionContact.firstName = $event.value" />
                        <form-input id="collectionLastname" label="Last Name" :v$="v$" :value="model.collectionContact.lastName" @is-sync="model.collectionContact.lastName = $event.value" />
                    </div>
                </div>
                <div class="p-col-12 p-md-3">
                    <div class="card" style="padding: 0.7rem; min-height: 30.74rem; ">
                        <h4><span>Delivery Details</span></h4>
                        <ruleAddress-form id="deliveryAddress" :value="deliveryAddress" @is-sync="populateDeliveryAddress($event)" :showAddress="true" :useCompany="true" />
                        <form-input id="deliveryFirstname" label="First Name" :v$="v$" :value="model.deliveryContact.firstName" @is-sync="model.deliveryContact.firstName = $event.value" />
                        <form-input id="deliveryLastname" label="Last Name" :v$="v$" :value="model.deliveryContact.lastName" @is-sync="model.deliveryContact.lastName = $event.value" />
                    </div>
                </div>
                <div class="p-col-12 p-md-3">
                    <div class="card" style=" padding: 0.7rem; min-height: 30.74rem; ">
                        <h4><span>Order Details</span></h4>
                        <form-input id="reference" label="Reference 1" :v$="v$" :value="model.order.reference_1" @is-sync="model.order.reference_1 = $event.value" />
                        <form-input id="reference2" label="Reference 2" :v$="v$" :value="model.order.reference_2" @is-sync="model.order.reference_2 = $event.value" />
                        <form-input id="reference3" label="Reference 3" :v$="v$" :value="model.order.reference_3" @is-sync="model.order.reference_3 = $event.value" />
                        <form-input id="reference4" label="Reference 4" :v$="v$" :value="model.order.reference_4" @is-sync="model.order.reference_4 = $event.value" />
                        <form-input id="reference5" label="Reference 5" :v$="v$" :value="model.order.reference_5" @is-sync="model.order.reference_5 = $event.value" />
                        <form-input id="reference6" label="Reference 6" :v$="v$" :value="model.order.reference_6" @is-sync="model.order.reference_6 = $event.value" />
                        <form-input id="reference7" label="Reference 7" :v$="v$" :value="model.order.reference_7" @is-sync="model.order.reference_7 = $event.value" />
                        <form-input id="instruction" label="Delivery Instruction" :v$="v$" :value="model.order.instruction" @is-sync="model.order.instruction = $event.value" />
                    </div>
                </div>
                <div class="p-col-12 p-md-3">
                    <div class="card" style="padding: 0.7rem; min-height: 30.84rem;">
                        <h4><span>Parameters</span></h4>
                        <form-lookup :value="model.currencyID" :required="true" id="currencyID" label="Currency" type="dropdown" source="currency" @is-sync="{model.currencyID = $event.value; model.currencyCode = $event.record?.code ;}"></form-lookup>
                        <form-lookup id="weightUnit" :required="true" type="enum" source="weightunit" :value="model.order.weightUnit" label="Unit Weight" @is-sync="{model.weightUnit = $event.value; model.order.weightUnit = $event.value;model.weightUnitText = $event.text;}" :v$="v$"></form-lookup>
                        <form-lookup id="dimensionUnit" :required="true" type="enum" source="lengthunit" :value="model.order.dimensionUnit" label="Unit Length" @is-sync="{model.dimensionUnit = $event.value; model.order.dimensionUnit = $event.value;  model.dimensionUnitText = $event.text;}" :v$="v$"></form-lookup>
                        <form-lookup id="incoterm" :required="true" type="enum" source="incotermtype" :value="model.order.incoTerms" label="Incoterm" @is-sync="model.incoTerms = $event.value" :v$="v$"></form-lookup>
                        <form-lookup id="customsCategoryType" :required="true" type="enum" source="customscategorytype" :value="model.order.customsCategoryType" label="Customs Category" @is-sync="model.order.customsCategoryType = $event.value" :v$="v$"></form-lookup>
                        <form-lookup id="packageType" :required="true" type="enum" source="serviceproviderproductpackagingtype" :value="model.order.packageType" label="Package Type" @is-sync="model.order.packageType = $event.value" :v$="v$"></form-lookup>
                        <!--<form-lookup id="shipmentType" :required="true" type="enum" source="parcelcategorytype" :value="model.order.shipmentType" label="Shipment Type" @is-sync="model.order.shipmentType = $event.value" :v$="v$"></form-lookup>-->
                        <form-lookup id="customerID" :required="true" label="Customer" :source="scopeCustomerDS" :value="model.order.customerID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="model.order.customerID = $event?.value" />
                        <form-lookup id="serviceGroupID" label="Service Group" :required="true" :source="`services/facility/servicegroup/customer/id/${model.order?.customerID ?? -1000}`" sourceType="url" :value="model.order.serviceGroupID" :v$="v$" :textField="'text'" :valueField="'id'" @is-sync="{model.order.serviceGroupID = $event.value; onSelectServiceGroup($event)}" :sorted="false" />
                        <form-datetime id="collectionDate" :value="collectionDateVal" type="date" @is-sync="model.order.collectionDate = $event.record.data;" label="Collection Date" :v$="v$"></form-datetime>
                    </div>
                </div>
            </div>
            <div class="p-d-flex p-grid p-col-12" style="margin-top:-3rem;">
                <div class="p-d-flex p-col-12">
                    <div class="card">
                        <h3 style="padding-top:0"><span>Package</span></h3>
                        <quote-parcel :source="parcels" :header="model" @is-sync="model.parcels[$event.parcelID - 1] = $event.model"
                                      @is-add="onAdd"
                                      @is-delete="onDelete"
                                      @is-copy-next="onCopyNext"
                                      @is-copy="onCopyValue" />
                    </div>
                </div>
            </div>
            <div class="p-d-flex p-grid p-col-12 p-jc-end">
                <pv-button class="p-mr-1" label="Get Service" icon="pi pi-search" :disabled="(model.parcels?.length ?? 0) > 99 || model?.order?.serviceGroupID <= 0" @click="getService()"></pv-button>
                <pv-button class="p-mr-1" label="Reset" icon="pi pi-refresh" :disabled="(model.parcels?.length ?? 0) > 99" @click="reset()"></pv-button>
            </div>
            <div class="p-d-flex p-grid p-col-12" v-if="activity.loading || (productservice?.length ?? 0) > 0 ">
                <div class="p-col-12 p-md-12">
                    <pv-orderlist v-model="productservice" listStyle="height:342px" dataKey="id" v-model:selection="selection" @selection-change="onServiceSelect($event)">
                        <template #header>
                            Services
                        </template>
                        <template #item="slotProps">
                            <div class="picklist-item">
                                <div v-if="activity.loading" class="p-field p-col-12 p-md-12" style="width: 100%; padding: 0; margin: 0;">
                                    <div class="custom-skeleton">
                                        <ul class="p-m-0 p-p-0">
                                            <li>
                                                <div class="p-d-flex">
                                                    <div style="flex: 1">
                                                        <pv-skeleton width="100%" class="p-mb-2"></pv-skeleton>
                                                        <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                        <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                        <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                        <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div v-else class="p-d-flex p-col">
                                    <div class="p-d-flex" style="width:100%">
                                        <div class="picklist-list-detail">
                                            <div class="p-d-flex" style="padding: 0;">
                                                <div style="padding:0;width:100%">
                                                    <div class="p-d-flex" style=" z-index: 100;">
                                                        <div style="padding-right: 0.25rem; font-weight: 500">
                                                            {{ slotProps.item.Priority }}.
                                                        </div>
                                                        <div style="margin-right:5px">
                                                            <img style="width: 35px; height: 20px; max-height: 20px; max-width: 35px; object-fit: contain; object-position: center;" :src="`${slotProps.item.Logo}`" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" />
                                                        </div>
                                                        <div class="p-d-flex p-jc-between" style="width:calc(100%)">
                                                            <span><span class="p-code-text" style="font-weight: 600;">[{{ slotProps.item.CarrierCode}}] {{ slotProps.item.ServiceCode }} </span>, {{ slotProps.item.ServiceName }} {{ slotProps.item.OptionName}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </pv-orderlist>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    //const config = require('@/assets/lib/cosmos/_js/config.js').default.config;
    import { mapGetters } from 'vuex';
    //const config = require("@/assets/_js/cosmos-config.js").default;
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import validator from '@/assets/_js/cosmos-validators.js';
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from '@vuelidate/validators';
    import addressForm from '@/components/ruleengine/form-simulate-address.vue';
    import parcel from '@/components/facility/rates/quote-parcel-form.vue';
    const moduleName = 'servicegroup';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'ruleAddress-form': addressForm,
            'quote-parcel': parcel
        },
        data() {
            return {
                model: {
                    collectionContact: {
                        firstName: null,
                        lastName: null
                    },
                    collection: {
                        countryID: 238,
                        city: null,
                        state: null,
                        postalCode: null,
                        companyName: null,
                        address1: null,
                        address2: null,
                        address3: null
                    },
                    deliveryContact: {
                        firstName: null,
                        lastName: null
                    },
                    delivery: {
                        countryID: 0,
                        city: null,
                        state: null,
                        postalCode: null,
                        companyName: null,
                        address1: null,
                        address2: null,
                        address3: null
                    },
                    currencyID: 3,
                    weightUnit: 100,
                    dimensionUnit: 100,
                    order: {
                        reference_1: null,
                        reference_2: null,
                        reference_3: null,
                        reference_4: null,
                        reference_5: null,
                        reference_6: null,
                        reference_7: null,
                        currencyID: 3,
                        weightUnit: 100,
                        dimensionUnit: 100,
                        incoTerms: 64,
                        instruction: null,
                        customerID: null,
                        customsCategoryType:2,
                        packageType: 4,
                        shipmentType: 8,
                        serviceGroupID: null,
                        serviceGroupCode: null,
                        serviceGroupText: null,
                        collectionDate: null
                    },
                    parcels: [{
                        parcelID: 1,
                        declaredWeight: 1.00,
                        length: 0,
                        width: 0,
                        height: 0,
                        declaredValue: 1.00,
                        insuranceValue: 0.00,
                        dutyValue: 0.00,
                        taxValue: 0.00,
                        dangerousGoods: 100,
                        volumetricWeight: 0.00,
                        chargeableWeight: 0.00,
                        parcelCount: 1
                    }]
                },
                activity: {
                    parentLoading: true,
                    loading: false
                },
                productservice: [],
                keys: null,
                session: {
                    tenant: null,
                    environment: null,
                    warehouse: null,
                    customer: null,
                    currentUser: null,
                    executiveUser: null
                }
            }
        },
        validations() {
            return {
                model: {
                    code: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },
                    name: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },
                }
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            scopeCustomerDS() {
                return this.$config.common.$customerFiltered(this.user?.environmentID ?? -100, this.$vm.$appSession.warehouse.id ?? this.user.warehouseID, this.user);
            },
            scopeWarehouseDS() {
                return this.$config.common.$warehouse(this.user?.environmentID ?? -100, this.user);
            },
            sessionKeys() { return this.$store.getters["auth/session"]; },
            shipmentTypeDS() {
                return [{ id: 1, code: "SPL" },
                { id: 2, code: "GFT" },
                { id: 4, code: "POS" },
                { id: 8, code: "MDZ" },
                { id: 16, code: "RPR" },
                { id: 32, code: "RPK" },
                { id: 64, code: "MFG" },
                { id: 128, code: "DOC" }
                ];
            },
            intecotermDS() {
                return [{ id: 1, code: "CIF" },
                { id: 2, code: "CIP" },
                { id: 4, code: "CFR" },
                { id: 8, code: "CPT" },
                { id: 16, code: "DAP" },
                { id: 32, code: "DAT" },
                { id: 64, code: "DDP" },
                { id: 128, code: "DDU" },
                { id: 256, code: "EXW" },
                { id: 512, code: "FAS" },
                { id: 1024, code: "FCA" },
                { id: 2048, code: "FOB" }]
            },
            header() { return this.model; },
            parcels() { return this.model?.parcels ?? [] },
            collectionAddress() {
                return {
                    city: this.model?.collection?.city,
                    state: this.model?.collection?.state,
                    postalCode: this.model?.collection?.postalCode,
                    countryID: this.model?.collection?.countryID,
                    companyName: this.model?.collection?.companyName,
                    address1: this.model?.collection?.address1,
                    address2: this.model?.collection?.address2,
                    address3: this.model?.collection?.address3
                };
            },
            deliveryAddress() {
                return {
                    city: this.model?.delivery?.city,
                    state: this.model?.delivery?.state,
                    postalCode: this.model?.delivery?.postalCode,
                    countryID: this.model?.delivery?.countryID,
                    companyName: this.model?.delivery?.companyName,
                    address1: this.model?.delivery?.address1,
                    address2: this.model?.delivery?.address2,
                    address3: this.model?.delivery?.address3
                };
            },
            parentId() {
                return this.model.id;
            },
            parentMask() {
                return this.model.mask;
            },
            companyDS() { return this.$store.getters["company/ds"].data; },
            customerDS() { return this.companyDS.filter((x) => x.type === 16); },
            currentWarehouse() {
                return this.session.warehouse;
            },
            warehouse() {
                return this.companyDS.filter(x => x.id == this.currentWarehouse)[0];
            },
            collectionDateVal() {
                if (this?.model?.order?.collectionDate)
                    return new Date(this.$filters.formatDate(this?.model?.order.collectionDate));
                return new Date();
            },
            productServiceInit() {
                return [{
                    ProviderCode: null,
                    ProviderName: null,
                    ProductCode: null,
                    ProductName: null,
                    Disclaimer: null,
                    Quote: {
                        Description: null,
                        CurrencyCode: null,
                        Value: 0.00,
                        ItemizedCharges: [
                            {
                                Description: null,
                                CurrencyCode: null,
                                Value: 0.00
                            },
                        ]
                    },
                    Schedule: {
                        CollectionSchedule: {
                            CollectionDate: null,
                            CollectionTime: null,
                            CutOffTime: null
                        },
                        EstimatedDelivery: {
                            CollectionDate: null,
                            CollectionTime: null
                        },
                        TimeInTransit: 1
                    }
                }, {
                    ProviderCode: null,
                    ProviderName: null,
                    ProductCode: null,
                    ProductName: null,
                    Disclaimer: null,
                    Quote: {
                        Description: null,
                        CurrencyCode: null,
                        Value: 0.00,
                        ItemizedCharges: [
                            {
                                Description: null,
                                CurrencyCode: null,
                                Value: 0.00
                            },
                        ]
                    },
                    Schedule: {
                        CollectionSchedule: {
                            CollectionDate: null,
                            CollectionTime: null,
                            CutOffTime: null
                        },
                        EstimatedDelivery: {
                            CollectionDate: null,
                            CollectionTime: null
                        },
                        TimeInTransit: 1
                    }
                }, {
                    ProviderCode: null,
                    ProviderName: null,
                    ProductCode: null,
                    ProductName: null,
                    Disclaimer: null,
                    Quote: {
                        Description: null,
                        CurrencyCode: null,
                        Value: 0.00,
                        ItemizedCharges: [
                            {
                                Description: null,
                                CurrencyCode: null,
                                Value: 0.00
                            },
                        ]
                    },
                    Schedule: {
                        CollectionSchedule: {
                            CollectionDate: null,
                            CollectionTime: null,
                            CutOffTime: null
                        },
                        EstimatedDelivery: {
                            CollectionDate: null,
                            CollectionTime: null
                        },
                        TimeInTransit: 1
                    }
                }
                ]
            },
            modelInit() {
                return {
                    collectionContact: {
                        firstName: null,
                        lastName: null
                    },
                    collection: {
                        countryID: 238,
                        city: null,
                        state: null,
                        postalCode: null,
                        companyName: null,
                        address1: null,
                        address2: null,
                        address3: null
                    },
                    deliveryContact: {
                        firstName: null,
                        lastName: null
                    },
                    delivery: {
                        countryID: 0,
                        city: null,
                        state: null,
                        postalCode: null,
                        companyName: null,
                        address1: null,
                        address2: null,
                        address3: null
                    },
                    currencyID: 3,
                    weightUnit: 100,
                    dimensionUnit: 100,
                    order: {
                        reference_1: null,
                        currencyId: 3,
                        weightUnit: 100,
                        dimensionUnit: 100,
                        incoTerms: 64,
                        instruction: null,
                        customerId: null,
                        customsCategoryType: 2,
                        packageType: 4,
                        shipmentType: 8,
                        serviceGroupID: null,
                        serviceGroupCode: null,
                        serviceGroupText: null,
                        collectionDate: null
                    },
                    parcels: [{
                        parcelID: 1,
                        declaredWeight: 1.00,
                        length: 0,
                        width: 0,
                        height: 0,
                        declaredValue: 1.00,
                        insuranceValue: 0.00,
                        dutyValue: 0.00,
                        taxValue: 0.00,
                        dangerousGoods: 100,
                        volumetricWeight: 0.00,
                        chargeableWeight: 0.00,
                        parcelCount: 1
                    }]
                };
            },
            apiData() {
                let packages = [];
                let _self = this;
                this?.parcels?.forEach((x) => {
                    packages.push({
                        Weight: {
                            GrossWeight: x.declaredWeight
                        },
                        WeightUnit: _self.$vm.$enums['weightunit'].filter((xz) => xz.id == _self.model?.weightUnit)[0]?.text?.toUpperCase(),
                        Dimension: {
                            Length: x.length,
                            Height: x.height,
                            Width: x.width
                        },
                        LengthUnit: _self.$vm.$enums['lengthunit'].filter((xz) => xz.id == _self.model?.weightUnit)[0]?.text?.toUpperCase(),
                        CurrencyCode: _self.$vm.$currencies.filter((xz) => xz.id === _self.model?.currencyID)[0]?.code?.toUpperCase(),
                        Declaration: {
                            Declared: x.declaredValue
                        }
                    });

                });
                return {
                    //ServiceHeader: {
                    //    Username: "user-api",
                    //    Password: "aFh4N05HUHZZMko4emVF",
                    //    ClientID: "AY7XSUK8OAJF5WDP4F59",
                    //    ClientSecret: "5241ba541c50c85ba65d46146e9dc35f"
                    //},
                    Request: {
                        CustomerCode: this.companyDS.filter(x => x.id === this?.model?.order?.customerID)[0]?.code,
                        ServiceGroupCode: this.model?.order?.serviceGroupCode,
                        Order: {
                            ShipFrom: {
                                City: this.model?.collection?.city,
                                State: this.model?.collection?.state,
                                PostalCode: this.model?.collection?.postalCode,
                                Country: this.$vm.$countries.filter(x => x.id == this.model?.collection?.countryID)[0]?.code
                            },
                            ShipTo: {
                                City: this.model?.delivery?.city,
                                State: this.model?.delivery?.state,
                                PostalCode: this.model?.delivery?.postalCode,
                                Country: this.$vm.$countries.filter(x => x.id == this.model?.delivery?.countryID)[0]?.code
                            },
                            IsDocument: false,
                            CollectionDate: this.$filters.formatDateWithFormat(this.model?.order.collectionDate ?? new Date(), "YYYY-MM-DD"),
                            Reference: this.model?.order?.reference_1,
                            Instruction: this.model?.order?.instruction,
                            ShipmentType: this.shipmentTypeDS.filter(x => x.id === this.model.order.shipmentType)[0]?.code,
                            Incoterm: this.intecotermDS.filter(x => x.id === this.model.order.incoTerms)[0]?.code
                        },
                        Packages: packages
                    }
                }
            },
            apiDataRequest() {
                let packages = [];
                this?.parcels?.forEach((x) => {
                    packages.push({
                        Weight: x.declaredWeight,
                        Dimension: {
                            Length: x.length,
                            Height: x.height,
                            Width: x.width
                        },
                        Declaration: {
                            DeclaredValue: x.declaredValue
                        }
                    });

                });
                return {
                    Source: "Web",
                    Request: {
                        Customer: this.companyDS.filter(x => x.id === this?.model?.order?.customerID)[0]?.code,
                        Warehouse: this.scopeWarehouseDS.filter(x => x.id === this.$vm.$appSession?.warehouse.id)[0]?.code,
                        ServiceGroup: this.model?.order?.serviceGroupCode,
                        ShippingType: "SHIPPING",
                        Consignment: {
                            Order: {
                                Sender: {
                                    Contact: {
                                        firstName: this.model?.collectionContact?.firstName ?? "XXXJoe",
                                        lastName: this.model?.collectionContact?.lastName ,
                                        email: "integro360@noreply.com"
                                    },
                                    Address: {
                                        Company: this.model?.collection?.companyName,
                                        Address1: this.model?.collection?.address1 ?? "XXX0",
                                        Address2: this.model?.collection?.address2 ?? "",
                                        Address3: this.model?.collection?.address3 ?? "",
                                        City: this.model?.collection?.city,
                                        State: this.model?.collection?.state,
                                        PostalCode: this.model?.collection?.postalCode,
                                        Country: this.$vm.$countries.filter(x => x.id == this.model?.collection?.countryID)[0]?.code
                                    }
                                },
                                Recipient: {
                                    Contact: {
                                        firstName: this.model?.deliveryContact?.firstName ?? "XXXJoe",
                                        lastName: this.model?.deliveryContact?.lastName ,
                                        email: "integro360@noreply.com"
                                    },
                                    Address: {
                                        Address1: this.model?.delivery?.address1 ?? "XXX0",
                                        Address2: this.model?.delivery?.address2 ?? "",
                                        Address3: this.model?.delivery?.address3 ?? "",
                                        City: this.model?.delivery?.city,
                                        State: this.model?.delivery?.state,
                                        PostalCode: this.model?.delivery?.postalCode,
                                        Country: this.$vm.$countries.filter(x => x.id == this.model?.delivery?.countryID)[0]?.code
                                    }
                                },
                                Reference1: this.model?.order?.reference_1,
                                Reference2: this.model?.order?.reference_2,
                                Reference3: this.model?.order?.reference_3,
                                Reference4: this.model?.order?.reference_4,
                                Reference5: this.model?.order?.reference_5,
                                Reference6: this.model?.order?.reference_6,
                                Reference7: this.model?.order?.reference_7,
                                Instruction: this.model?.order?.instruction,
                                PackageType: "PACKAGE",
                                CollectionDate: this.$filters.formatDateWithFormat(this.model?.order.collectionDate ?? new Date(), "YYYY-MM-DD"),
                                WeightUnit: this.$vm.$enums['weightunit'].filter((xz) => xz.id == this.model?.weightUnit)[0]?.text?.toUpperCase() ?? "KG",
                                DimensionUnit: this.$vm.$enums['lengthunit'].filter((xz) => xz.id == this.model?.weightUnit)[0]?.text?.toUpperCase() ?? "CM",
                                Currency: this.$vm.$currencies.filter((xz) => xz.id === this.model?.currencyID)[0]?.code?.toUpperCase(),
                                CustomsCategory: this.$vm.$enums['customscategorytype'].filter((xz) => xz.id == this.model?.customsCategoryType)[0]?.code?.toUpperCase() ?? "SAL",
                                Incoterm: this.$vm.$enums['incotermtype'].filter((xz) => xz.id == this.model?.incoTerms)[0]?.code?.toUpperCase() ?? "DAP"
                                
                            },
                            Packages: packages
                        }
                    }
                }
;
            }

        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                    if (this.warehouse !== undefined) {
                        this.populateCollectionAddress(this.warehouse);
                    }
                    else {
                        this.getCompanyList().then(() => {
                            this.populateCollectionAddress(this.warehouse);
                        });
                    }
                });
            }
        },
        methods: {
            formatCurrency(value, currencyCode) {
                return value.toLocaleString('en-US', { style: 'currency', currency: currencyCode ?? this.currencyDS.data.filter((x) => x.id === this.model?.currencyID)[0]?.code ?? "USD" });
            },
            getDate(data) {
                if (data != null) {
                    return new Date().setFullYear(Number(data.substring(0, 4)), Number(data.substring(4, 6)) - 1, Number(data.substring(6, 8)))
                }
                return new Date();
            },
            getTime(data) {
                if (data != null) {
                    return new Date().setHours(Number(data.substring(0, 2)), Number(data.substring(2, 4)), Number(data.substring(4, 6)))
                }
                return new Date();
            },
            isWithFuelCharge(data) {
                return data?.Quote?.ItemizedCharges?.filter((x) => x.Description === "Fuel Surcharge").length > 0;
            },
            reset() {
                this.model = this.modelInit;
                this.productservice = [];
            },
            async getService() {
                this.activity.loading = true;
                this.productservice = this.productServiceInit;
                let _self = this;
                //this.$axios.post(config.endpoint.api + `/api/polaris/1.0/consignment/services?additionalinfo=quote`.replaceAll("//", "/"), this.apiData)
                //    .then((response) => {
                //        _self.productservice = response?.data?.Result?.Services ?? [];
                //        _self.activity.loading = false;
                //        if (_self.productservice.length === 0) {
                //            _self.$toast.add({ severity: 'warn', summary: 'No Valid Service Result!', detail: 'Consignment detail did not qualify for service group ' + _self.model.order.serviceGroupText, life: 8000 });
                //        }
                //    })
                //    .catch(() => {
                //        this.activity.loading = false;
                //    });
                this.$axios.post(this.$config.config.endpoint.api + `/api/polaris/tools/servicegroup`, this.apiDataRequest)
                    .then((response) => {
                        _self.productservice = response?.data?.Response?.Data ?? [];
                        _self.activity.loading = false;
                        if (_self.productservice.length === 0) {
                            _self.$toast.add({ severity: 'warn', summary: 'No Valid Service Result!', detail: 'Consignment detail did not qualify for service group ' + _self.model.order.serviceGroupText, life: 8000 });
                        }
                    })
                    .catch((xhr) => {
                        console.log(xhr)
                        this.activity.loading = false;
                    });
            },
            async onSelectServiceGroup(event) {
                this.model.order.serviceGroupCode = event?.record?.code ?? null;
                this.model.order.serviceGroupText = event?.record?.text ?? null;
                //this.activity.loading = true;
                // await this.getService();
            },
            populateCollectionAddress(e) {
                this.model.collection.city = e?.city;
                this.model.collection.state = e?.state;
                this.model.collection.postalCode = e?.postalCode;
                this.model.collection.countryID = e?.countryID;
                this.model.collection.companyName = e?.companyName;
                this.model.collection.address1 = e?.address1;
                this.model.collection.address2 = e?.address2;
                this.model.collection.address3 = e?.address3;
                if ((this.model.delivery?.countryID ?? 0) === 0) {
                    this.model.delivery.countryID = e?.countryID;
                }
                this.collectionAddress = {
                    city: this.model?.collection?.city,
                    state: this.model?.collection?.state,
                    postalCode: this.model?.collection?.postalCode,
                    countryID: this.model?.collection?.countryID,
                    companyName: this.model?.collection?.companyName,
                    address1: this.model?.collection?.address1,
                    address2: this.model?.collection?.address2,
                    address3: this.model?.collection?.address3
                };
            },
            populateDeliveryAddress(e) {
                this.model.delivery.city = e?.city;
                this.model.delivery.state = e?.state;
                this.model.delivery.postalCode = e?.postalCode;
                this.model.delivery.countryID = e?.countryID;
                this.model.delivery.companyName = e?.companyName;
                this.model.delivery.address1 = e?.address1;
                this.model.delivery.address2 = e?.address2;
                this.model.delivery.address3 = e?.address3;
                this.deliveryAddress = {
                    city: this.model?.delivery?.city,
                    state: this.model?.delivery?.state,
                    postalCode: this.model?.delivery?.postalCode,
                    countryID: this.model?.delivery?.countryID,
                    companyName: this.model?.delivery?.companyName,
                    address1: this.model?.delivery?.address1,
                    address2: this.model?.delivery?.address2,
                    address3: this.model?.delivery?.address3
                };
            },
            async getCompanyList() {
                return this.$store.dispatch("company/getAll", {sort: null, where:null});
            },
            onAdd() {
                var itemline = {
                    parcelID: this.parcels.length + 1,
                    declaredWeight: 0.00,
                    length: 0,
                    width: 0,
                    height: 0,
                    declaredValue: 1.00,
                    insuranceValue: 0.00,
                    dutyValue: 0.00,
                    taxValue: 0.00,
                    dangerousGoods: 100,
                    volumetricWeight: 0.00,
                    chargeableWeight: 0.00,
                    parcelCount: 1
                };
                this.model.parcels.push(itemline);
            },
            onDelete(index) {
                let parcels = this.parcels.filter(x => x.parcelID !== index).map(x => this.$filters.init(x));
                for (let i = 0; i < parcels.length; i++)
                    parcels[i].parcelID = i + 1;
                this.model.parcels = parcels;
            },
            onCopyNext(index) {
                let source = this.$filters.init(this.model.parcels[index - 1]);
                source.parcelID = index + 1;
                this.model.parcels[index] = source;

            },
            onCopyValue(e) {
                if (e.target < this.model.parcels.length) {
                    let source = this.$filters.init(this.model.parcels[e.source - 1] ?? this.model.parcels[e.target - 1]);
                    source.parcelID = this.model.parcels[e.target - 1].parcelID;
                    this.model.parcels[e.target - 1] = source;
                }
                else {
                    let source = this.$filters.init(this.model.parcels[e.source - 1]);
                    source.parcelID = e.target;
                    this.model.parcels.push(source);
                }
            },
        },
        async created() {
            this.model = this.modelInit;
            this.getCompanyList();
            //this.model.order.customerId = this.order.model.customerId  ?? this.scopeCustomerDS[0]?.id;
            // this.$router.push({ name: 'rule-simulate' });
        },
        async mounted() {
            let _self = this;
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');

            await this.getCompanyList().then(() => {
                _self.model.order.customerID = this.model?.order?.customerID ?? this.scopeCustomerDS[0]?.id;
                this.populateCollectionAddress(this.warehouse);
            });
            this.activity.parentLoading = false;
            this.model = this.modelInit;

        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }

    .p-orderlist-controls,
    .p-picklist-buttons.p-picklist-source-controls,
    .p-picklist-buttons.p-picklist-source-controls, .p-picklist-buttons.p-picklist-target-controls {
        display: none !important;
    }

    .picklist-item {
        display: flex;
        align-items: center;
        padding: .2rem;
        width: 100%;
    }

    img#is-img {
        width: 75px;
        /*   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);*/
        margin-right: 1rem;
    }

    .picklist-list-detail {
        flex: 1 1 0;
    }

    .picklist-list-action {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .picklist-category-icon {
        vertical-align: middle;
        margin-right: .5rem;
        font-size: .875rem;
    }

    .picklist-category {
        vertical-align: middle;
        line-height: 1;
        font-size: .875rem;
    }

    .custom-skeleton {
        border: 1px solid var(--surface-d);
        border-radius: 4px;
    }

    .custom-skeleton ul {
        list-style: none;
    }

        .custom-skeleton ul > li {
            padding: 0.75rem;
        }
</style>
