<template>
    <div class="p-grid p-col-12">
        <!--SETTINGS-->
        <div class="p-col-12 p-md-4" v-if="showSetting">
            <h5> PRINTER </h5>
            <form-boolean id="printer_Auto" :value="model.printer_Auto" onText="ON" offText="OFF" label="Auto Print" width="120px" textPosition="left" @is-sync="model.printer_Auto = $event.value"></form-boolean>
            <form-lookup v-if="model.printer_Auto" id="printer_Label_PrinterID" :value="model.printer_Label_PrinterID" :source="labelPrinters" label="Label Printer" @is-sync="{labelPrinters.length > 0 ? model.printer_Label_PrinterID = $event.value : model.printer_Label_PrinterID = model.printer_Label_PrinterID;}"></form-lookup>
            <form-lookup v-if="model.printer_Auto" id="printer_ReturnsLabel_PrinterID" :value="model.printer_ReturnsLabel_PrinterID" :source="labelPrinters" label="Returns Label Printer" @is-sync="{labelPrinters.length > 0 ? model.printer_ReturnsLabel_PrinterID = $event.value : model.printer_ReturnsLabel_PrinterID = model.printer_ReturnsLabel_PrinterID;}"></form-lookup>
            <form-lookup v-if="model.printer_Auto" id="printer_Document_PrinterID" :value="model.printer_Document_PrinterID" :source="documentPrinters" label="Document Printer" @is-sync="{documentPrinters.length > 0 ? model.printer_Document_PrinterID = $event.value : model.printer_Document_PrinterID = model.printer_Document_PrinterID;} "></form-lookup>
            <form-boolean v-if="model.printer_Auto" id="printer_Document_CN23" :value="model.printer_Document_CN23" onText="ON" offText="OFF" label="Include CN23" width="120px" textPosition="left" @is-sync="model.printer_Document_CN23 = $event.value"></form-boolean>
            <form-boolean v-if="model.printer_Auto" id="printer_Label_CN22" :value="model.printer_Label_CN22" onText="ON" offText="OFF" label="Include CN22" width="120px" textPosition="left" @is-sync="model.printer_Label_CN22 = $event.value"></form-boolean>
            <form-lookup v-if="model.printer_Auto" type="flag" :value="model.printer_Document_PrintType" id="printer_Document_PrintType" label="Print Document if" :sorted=false :source="'printerdocumenttype'" @is-sync="model.printer_Document_PrintType = $event.value"></form-lookup>
        </div>
        <div class="p-col-12 p-md-4"  v-if="showSetting">
            <h5> </h5>
        </div>
    </div>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';  
    const moduleName = 'usergeneralsetting';
    export default {
        name: moduleName + 'Record',
        props: {
            formData: { type: Object },
            showSetting: { type: Boolean, default: false },
            printerSource: { type: Object }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                schema: null,
                model: null,
                filter: null,
                columns: null,
                errors: null,
                data: null,
                ds: {
                    total: 0,
                    data: []
                },
                printerDS: [{ id: -1, text: "Provision Future Development" }],

            }
        },
        validations() {
            return {
                model: {
                }
            }
        },
        watch: {
            model:
            {
                handler() {
                    this.$emit('is-sync', this.model);
                },
                deep: true
            }
        },
        computed: {
            init() {
                var model = this.$store.getters[moduleName + "/init"];
                return this.$filters.init(model);
            },
            labelPrinters() { return (this.printerSource ?? []); },
            documentPrinters() { return (this.printerSource ?? []).filter(x => x.type == 2); },

        },
        methods: {
            async getSetting(mask) {
                await this.$axios
                    .get(this.$config.config.endpoint.api + `/services/settings/` + moduleName +`/ByUser/${mask}`.replaceAll("//", "/"))
                    .then((response) => {
                        this.model = response.data.result;
                        this.$emit("is-initial-sync", this.model);
                    })
                    .catch((ex) => { this.errors = ex.data; });
            }
          
        },
        created() {
            this.model = this.init;
            this.model.printer_Document_PrintType = 3;
        },
        mounted() {
            if (this.$route.params.mask ?? this.formData.mask)
                this.getSetting(this.$route.params.mask ?? this.formData.mask).then(() => {
                    this.$emit("is-mounted", true);
                });
        }
        
    }
</script>
