<template>
    <form-record :model="model" :modelState="v$" @is-reset="resetRecord">
        <div class="p-d-flex">
            <div class="p-col-12">
                <pv-steps :model="items" style="margin: -2.4rem -1.5rem 0.5rem -1.5rem; border-bottom: 1px solid var(--surface-d); padding: 10px; background: var(--surface-c) "/>
                <router-view name="stepper" v-slot="{Component}" :formData="model" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="complete">
                    <keep-alive>
                        <component :is="Component" />
                    </keep-alive>
                </router-view>
            </div>
        </div>
    </form-record>
</template>
<script>
    //const config = require("@/assets/_js/cosmos-config.js").default;
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import validator from '@/assets/_js/cosmos-validators.js';
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from '@vuelidate/validators';
  
    const moduleName = 'servicegroup';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                items: [
                    {
                        label: 'Service Group',
                        to: '/facility/rule/servicegroup/create'
                    },
                    {
                        label: 'Services',
                        to: '/facility/rule/servicegroup/step/service'
                    },
                    {
                        label: 'Rules',
                        to: '/facility/rule/servicegroup/step/rule'
                    },
                    {
                        label: 'Confirmation',
                        to: '/facility/rule/servicegroup/step/confirmation'
                    }
                ]
            }
        },
        validations() {
            return {
                model: {
                    code: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },
                    name: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },
                }
            }
        },
        computed: {
            parentId() {
                return this.model.id;
            },
            parentMask() {
                return this.model.mask;
            },
           
        },
        methods: {
            nextPage(event) {
                for (let field in event.formData) {
                    this.model[field] = event.formData[field];
                }
                this.$router.push(this.items[event.pageIndex + 1].to);
            },
            prevPage(event) {
                for (let field in event.formData) {
                    this.model[field] = event.formData[field];
                }
                this.$router.push(this.items[event.pageIndex - 1].to);
            },
            complete() {
                this.insert(this.model).then(() => {
                    if (!(this.errors)) {
                        let _self = this;
                        this.model.code = this.record.code;
                        _self.get(_self.record.mask).then(() => {
                            _self.$router.push({ name: _self.router.module + '-edit', params: { mask: _self.record.mask } });
                            _self.$toast.add({ severity: 'success', summary: 'Record Insert Success!', detail: 'The record was created successfully.', life: 6000 });
                            _self.resetModelState();
                            setTimeout(() => _self.$emit("is-reset"), 500);
                        })
                        //this.allocate().then((allocResponse) => {
                        //    if (allocResponse.status === 200) {
                        //        if (allocResponse.data.Status !== "FAILED") {
                        //            _self.get(_self.record.mask).then(() => {
                        //                _self.$router.push({ name: _self.router.module + '-edit', params: { mask: _self.record.mask } });
                        //                _self.$toast.add({ severity: 'success', summary: 'Record Allocate Success!', detail: 'The record was allocated successfully.', life: 6000 });
                        //                _self.resetModelState();
                        //                setTimeout(() => _self.$emit("is-reset"), 500);
                        //            })
                        //        }
                        //        else {
                        //            if (_self.record.mask) {
                        //                _self.get(_self.record.mask).then(() => {
                        //                    _self.$router.push({ name: _self.router.module + '-edit', params: { mask: _self.record.mask } });
                        //                    _self.resetModelState();
                        //                    setTimeout(() => _self.$emit("is-reset"), 500);
                        //                })
                        //            }
                        //            this.$toast.add({ severity: 'error', summary: 'Record Allocate Failed!', detail: allocResponse.data.Errors[0].Description, life: 10000 });
                        //        }
                        //    }
                        //}).catch((err) => {
                        //    this.$toast.add({ severity: 'error', summary: 'Record Allocate Failed!', detail: err, life: 6000 });
                        //});
                    }
                    else {
                        for (let err in this.errors) {
                            try {
                                for (let subError in this.errors[err]) {
                                    this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: this.errors[err][subError], life: 10000 });
                                }
                            }
                            catch {
                                this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: this.errors[err], life: 10000 });
                            }
                        }
                    }
                }).catch((err) => {
                    console.log(err);
                    this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: err.xhr, life: 10000 });
                });
            },
        },
        created() {
            this.$router.push({ name: this.router.module + '-details' });
        },
        mounted() {
           
        },
        updated() {
        }
    }
</script>
