<template>
    <pv-card>
        <template #subtitle>
            Select the products based on the account.
            <br />
            Adding products that are not included in your contract or rate card may cause issue on returning the selection.
        </template>
        <template #content>
            <product-form :serviceProviderID="$attrs.formData.serviceProviderID" :integrationID="$attrs.formData.integrationID" :formData="$attrs.formData.products" @is-sync="onSync($event)" />
        </template>
        <template #footer>
            <div class="p-grid p-nogutter p-justify-between">
                <pv-button label="Back" @click="prevPage()" icon="pi pi-angle-left" />
                <pv-button label="Next" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" :disabled="model.length === 0" />
            </div>
        </template>
    </pv-card>
</template>
<script>
    import productform from '@/components/admin/provideraccount/product-form.vue';
    import { useVuelidate } from '@vuelidate/core';
    export default {
        name: 'StepProduct',
        emits: ["next-page"],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'product-form': productform
        },
        props: {
            id: { type: String },
            integrationMask: { type: String }
        },
        data() {
            return { model: [] }
        },
        methods: {
            onSync(event) { this.model = event.model; },
            nextPage() { this.$emit('next-page', { formData: { products: this.model }, pageIndex: 1 }); },
            prevPage() { this.$emit('prev-page', { formData: { products: this.model }, pageIndex: 1 }); },
        }
    }
</script>
