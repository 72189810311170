<template>
    <form-record :model="model" :modelState="v$" :validation="v$" @is-reset="resetRecord" :showSend="false" :showClone="false" :title="model.id > 0 ? `[${model.code}]  ${model.firstName} ${model.lastName}` : `Add New Address Book`" :subTitle="model.id > 0 ? `` : `Create a new address book.`">
        <div class="p-grid p-d-flex">
            <div class="p-col-4">
                <form-address id="address" :value="address" :v$="v$" @is-sync="translate($event)" :useCompany="true" :showLookup="true" />
                <form-lookup id="establishmentType" source="establishmenttype" type="enum" :value="model.establishmentType" label="Establishment" @is-sync="model.establishmentType = $event.value" :validator="v$"></form-lookup>
            </div>
            <div class="p-col-4">
                <form-input id="firstName" label="First Name" :v$="v$" :value="model.firstName" @is-sync="model.firstName = $event.value" />
                <form-input id="lastName" label="Last Name" :v$="v$" :value="model.lastName" @is-sync="model.lastName = $event.value" />
                <form-input id="email" label="Email" :v$="v$" :value="model.email" @is-sync="model.email = $event.value?.toLowerCase()" v-lowercase :max-length="100"></form-input>
                <form-input id="telephone" label="Telephone" :v$="v$" :value="model.telephone" @is-sync="model.telephone = $event.value"></form-input>
                <form-input id="mobile" label="Mobile" :v$="v$" :value="model.mobile" @is-sync="model.mobile = $event.value"></form-input>

            </div>
            <div class="p-col-4">
                <form-lookup id="customerID" :disabled="model.type === 0" :required="true" label="Customer" :source="scopeCustomerDS" :value="model.customerID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="model.customerID = $event?.value" />
                <form-lookup type="flag" :value="model.addressType" id="addressType" label="Type" :source="'addresstype'" @is-sync="model.addressType = $event.value" />

            </div>
        </div>
        <div class="p-grid" style="margin: 0 -20px;">
            <div class="p-col-12">
                <pv-tab>
                    <pv-tabpanel header="REGISTRATIONS">
                        <div class="p-col-12 p-d-flex" style="margin-top:10px;">
                            <div class="p-col-4">
                                <form-input id="registrationNumber" label="Registration Number" :v$="v$" :value="model.registrationNumber" @is-sync="model.registrationNumber = $event.value"></form-input>
                                <form-input id="businessNumber" label="Business Number" :v$="v$" :value="model.businessNumber" @is-sync="model.businessNumber = $event.value"></form-input>
                                <form-input id="vatNo" label="VAT Number" :v$="v$" :value="model.vatNo" @is-sync="model.vatNo = $event.value"></form-input>
                            </div>
                            <div class="p-col-4">
                                <form-input id="eori" label="EORI" :v$="v$" :value="model.eori" @is-sync="model.eori = $event.value"></form-input>
                                <form-input id="eoriNorthernIreland" label="EORI (Northern Ireland)" :v$="v$" :value="model.eoriNorthernIreland" @is-sync="model.eoriNorthernIreland = $event.value" ph="XI123456789012345"></form-input>
                                <form-input id="eoriEuropean" label="EORI (European)" :v$="v$" :value="model.eoriEuropean" @is-sync="model.eoriEuropean = $event.value"></form-input>
                                <form-input id="iossNumber" label="Import One-Stop Shop (IOSS)" :v$="v$" :value="model.iossNumber" @is-sync="model.iossNumber = $event.value" ph="IM1234567890"></form-input>
                                <form-input id="ukimsNumber" label="UK Internal Market Scheme (UKIMS)" :v$="v$" :value="model.ukimsNumber" @is-sync="model.ukimsNumber = $event.value" :ph="`GBUKIM12345789012345${$filters.$dateTimeCodeUTC(new Date()).replaceAll('-', '').replaceAll(':', '').replaceAll('T', '').replaceAll('Z', '').substring(0, 14)}`"></form-input>
                                <form-input id="identityNumber" label="PID Number" :v$="v$" :value="model.identityNumber" @is-sync="model.identityNumber = $event.value"></form-input>
                                <form-lookup id="identityNumberType" :source="$vm.$enums['identitynumbertype']" :value="model.identityNumberType" label="PID Type" @is-sync="model.identityNumberType = $event.value" :v$="v$"></form-lookup>
                            </div>
                        </div>
                    </pv-tabpanel>
                </pv-tab>
            </div>
        </div>
    </form-record>
</template>

<script>
    import { mapGetters } from 'vuex';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, email, maxLength, helpers } from '@vuelidate/validators';
    /** CUSTOM VALIDATOR **/
    const regex = (param) => helpers.withParams({ type: 'regex', value: param }, (value) => value.match(param) != null);
  
    const moduleName = 'AddressBook';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
              test: false
            }
        },
        validations() {
            /** DYNAMIC VALIDATION **/
            let validator = {
                model: {
                    firstName: { required, minLength: minLength(1), maxLength: maxLength(50), $autoDirty: true },
                    lastName: { maxLength: maxLength(50), $autoDirty: true },
                    company: { maxLength: maxLength(50), $autoDirty: true },
                    customerID: { required, $autoDirty: true },
                    telephone: { $autoDirty: true, maxLength: maxLength(25), regex: helpers.withMessage(`The Telephone field only accepts numeric values.`, regex('^[0-9]*$')) },
                    mobile: { required, $autoDirty: true, maxLength: maxLength(25), regex: helpers.withMessage(`The Mobile field only accepts numeric values.`, regex('^[0-9]*$')) },
                    email: { required, email, minLength: minLength(1), maxLength: maxLength(100), $autoDirty: true },

                    eori: { $autoDirty: true },
                    eoriNorthernIreland: { $autoDirty: true },
                    eoriEuropean: { $autoDirty: true },
                    iossNumber: { $autoDirty: true },
                    ukimsNumber: { $autoDirty: true },
                    vatNo: { $autoDirty: true, maxLength: maxLength(35) },
                    identityNumber: { $autoDirty: true },
                    identityNumberType: { $autoDirty: true }
                }
            };
            if ((this.model.telephone?.length ?? 0) > 0)
                validator.model.telephone = { $autoDirty: true, maxLength: maxLength(25), regex: helpers.withMessage('The Telephone field only accepts numeric values.', regex('^[0-9]*$')) };
            if ((this.model.iossNumber?.length ?? 0) > 0)
                validator.model.iossNumber = { maxLength: maxLength(15), $autoDirty: true, regex: helpers.withMessage(`The IOSS must follow this format: IM9999999999.`, regex('^IM[0-9a-zA-Z]{10,13}$')) }
            if ((this.model.ukimsNumber?.length ?? 0) > 0)
                validator.model.ukimsNumber = { maxLength: maxLength(35), $autoDirty: true, regex: helpers.withMessage(`The UKIMS must follow this format: XXUKIMZZZZZZZZZZZZZZZYYYYMMDDHHmmss, where XX - ISO2 Either GB or XI, Z - 13-15 alphanumeric character (EORI), YYYYMMDDHHmmss - approval date.`, regex('^(GB|XI)(UKIM)([a-zA-Z0-9]{12,15})(2[0-9]{3})((01|03|05|07|08|10|12)(0[1-9]|1[0-9]|2[0-9]|3[0-1])|(04|06|09|11)(0[1-9]|1[0-9]|2[0-9]|30)|02(0[1-9]|1[0-9]|2[0-9]))(([0-1][0-9])|2[0-3])([0-5][0-9])([0-5][0-9])$')) }
            if ((this.model.eori?.length ?? 0) > 0)
                validator.model.eori = { maxLength: maxLength(15), $autoDirty: true, regex: helpers.withMessage(`Invalid EORI format. Format: UUAAAAAAAAAAA, where UU - ISO2, and A - alphanumeric characters`, regex('^(XI[a-zA-Z0-9]{12,15}|GB[a-zA-Z0-9]{12,15}|(AT|BE|BG|CY|CZ|DE|DK|EE|EL|ES|FI|FR|HR|HU|IE|IT|LT|LU|LV|MT|NL|PL|PT|RO|SE|SI|SK)[a-zA-Z0-9]{8,15})$')) }
            if ((this.model.eoriNorthernIreland?.length ?? 0) > 0)
                validator.model.eoriNorthernIreland = { maxLength: maxLength(15), $autoDirty: true, regex: helpers.withMessage(`Invalid EORI format. Format: XIAAAAAAAAAAA, where XI - is fixed, and A - alphanumeric characters`, regex('^XI[a-zA-Z0-9]{12,15}$')) }
            if ((this.model.eoriEuropean?.length ?? 0) > 0)
                validator.model.eoriEuropean = { maxLength: maxLength(15), $autoDirty: true, regex: helpers.withMessage(`Invalid EU EORI format. Format: UUAAAAAAAAAAA, where UU - ISO2, and A - alphanumeric characters`, regex('^(AT|BE|BG|CY|CZ|DE|DK|EE|EL|ES|FI|FR|HR|HU|IE|IT|LT|LU|LV|MT|NL|PL|PT|RO|SE|SI|SK)[a-zA-Z0-9]{8,15}$')) }
            return validator;
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            scopeCustomerDS() {
                return this.$config.common.$customer(this.user?.environmentID ?? -100, this.$vm.$appSession.warehouse.id ?? this.user.warehouseID, this.user);
            },
            ds() { return this.$store.getters["addressbook/ds"]; },
            record() { return this.$store.getters["addressbook/record"]; },
            address() {
                return  {
                    address1: this.model.address1,
                    address2: this.model.address2,
                    address3: this.model.address3,
                    city: this.model.city,
                    state: this.model.state,
                    postalCode: this.model.postalCode,
                    countryID: this.model.countryID,
                    companyName: this.model.company
                };
            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            }
        },
        methods: {
            translate(e) {
                this.model.address1 = e.address1;
                this.model.address2 = e.address2;
                this.model.address3 = e.address3;
                this.model.city = e.city;
                this.model.state = e.state;
                this.model.postalCode = e.postalCode;
                this.model.countryID = e.countryID;
                this.model.company = e.companyName;
                this.address = {
                    address1: this.model.address1,
                    address2: this.model.address2,
                    address3: this.model.address3,
                    city: this.model.city,
                    state: this.model.state,
                    postalCode: this.model.postalCode,
                    countryID: this.model.countryID,
                    companyName: this.model.company
                };
            },
            command() {
                //console.log("command");
            }
        },
        async mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');

            this.activity.loading = false;
            if (this.model.id === 0)
                this.model.environmentID = this.$vm.$appSession.environment.id;

            this.model.customerID = this.scopeCustomerDS[0]?.id;
            this.model.countryID = this.model.countryID ?? this.$vm.$countries.filter(x => x.code === this.$vm.$location.country)[0].id;
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
