<template>
    <form-record :model="model" :modelState="v$" @is-reset="resetRecord" :saveOnly="true" :viewOnly="true" :viewBack="true">
        <div class="p-d-flex p-grid">
            <div class="p-col-12">
                <div style="width: calc(100% + 2rem); padding: 0; margin: -2rem -1rem; margin-bottom:-1.5rem;">
                    <pv-accordion :activeIndex="[0,1,2]" :multiple="true" id="InvoiceAccordion">
                        <pv-accordion-tab>
                            <template #header>
                                <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                                    <div class="p-col-fixed p-as-start" style="width: 1.5rem"><i class="fa fa-info-circle"></i></div>
                                    <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" title="SUMMARY DETAILS">
                                        <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; <span v-html="`SUMMARY`"></span></div>
                                    </div>
                                </div>
                            </template>
                            <div style="width: calc(100% + 2rem); padding: 0; margin: -2rem -0.75rem; margin-bottom: 0.5rem;">
                                <div class="p-d-flex p-grid p-col-12">
                                    <div class="p-col-12 p-lg-3">
                                        <h4>SHIPPER DETAILS <i style="padding:0.5rem 0.5rem" :class="`flag flag-${$vm.$countries.filter((x) => x.id == shipperAddress?.countryID)[0]?.code.toLowerCase()}`"></i></h4>
                                        <div class="card" :style="`background: var(--surface-a); min-height: 21rem; height: calc(100% * 1.75rem);`">
                                            <div v-if="shipper" style="padding: 0.75rem 1rem;">
                                                <img :src="`${cdn}/${tenant.code.toLowerCase()}/company/${shipper?.mask?.toUpperCase()}_logo_light.png?${Math.random()}`" style="object-fit: cover; max-height: calc(9rem / 3); max-width: 25rem; object-position: center center;" onerror="this.src = '/_assets/img/no-image.png'" />
                                            </div>
                                            <form-address id="shipperAddress" :value="shipperAddress" :useCompany="true" :disabled="true" disabledView="perline" />
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-lg-3">
                                        <h4>CUSTOMER DETAILS <i :class="`flag flag-${$vm.$countries.filter((x) => x.id == customerAddress?.countryID)[0]?.code.toLowerCase()}`"></i></h4>
                                        <div class="card" :style="`background: var(--surface-a); min-height: 21rem; height: calc(100% * 1.75rem);`">
                                            <div v-if="customer" style="padding: 0.75rem 1rem;">
                                                <img :src="`${cdn}/${tenant.code.toLowerCase()}/company/${customer?.mask?.toUpperCase()}_logo_light.png?${Math.random()}`" style="object-fit: cover; max-height: calc(9rem / 3); max-width: 25rem; object-position: center center; " onerror="this.src = '/_assets/img/no-image.png'" />
                                            </div>
                                            <form-address id="customerAddress" :value="customerAddress" :useCompany="true" :disabled="true" disabledView="perline" />
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-lg-6">
                                        <h4>INVOICE DETAILS</h4>
                                        <div class="card" style="max-height:21rem;">
                                            <div class="p-grid">
                                                <div class="p-col-6">
                                                    <form-input id="code" :required="true" :disabled="true" :value="model.code" @is-sync="model.code = $event.value" label="Transaction ID" :v$="v$" />
                                                    <form-input id="name" :required="true" :disabled="viewOnly" :value="model.name" @is-sync="model.name = $event.value" label="Invoice Number" :v$="v$" v-focus />
                                                    <form-datetime id="invoiceDate" :disabled="viewOnly" :value="model.invoiceDate" type="date" @is-sync="onInvoiceDate($event)" label="Invoice Date" :v$="v$" :showCopy="false"></form-datetime>
                                                    <form-datetime id="invoiceDueDate" :disabled="viewOnly" :value="model.invoiceDueDate" type="date" @is-sync="onInvoiceDueDate($event)" label="Due Date" :v$="v$" :showCopy="false"></form-datetime>

                                                </div>
                                                <div class="p-col-6">
                                                    <form-input id="paymentTerms" :disabled="viewOnly" :required="true" :value="model.paymentTerms" @is-sync="model.paymentTerms = $event.value" label="Payment Terms" :v$="v$" :showCopy="false" />
                                                    <form-lookup id="currencyID" :disabled="true" :required="true"  label="Currency" source="currency" :value="model.currencyID" @is-sync="model.currencyID = $event?.value ?? model.currencyID ;" :showCopy="false" />
                                                    <form-lookup id="rateCardType" :disabled="true" :required="true" label="Rate Card Type" type="enum" source="ratecardtype" :value="model.rateCardType" @is-sync="model.rateCardType = $event?.value;" :showCopy="false" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div class="p-d-flex p-grid p-col-12 p-jc-end" style="margin-top:-1rem">
                                    <pv-button v-if="model.status <= 100" class="p-mr-1" label="Delete" icon="fas fa-trash" @click="onDelete()"  :disabled="dialog.processing"></pv-button>
                                    <pv-button v-if="model.status <= 100" class="p-mr-1" :label="(action.confirm ? 'Processing' : 'Confirm')" icon="fas fa-badge-check" :loading="action.confirm" :disabled="dialog.processing" @click="onConfirm()"></pv-button>
                                    <pv-button v-if="model.status >= 200" class="p-mr-1" label="Preview" icon="fas fa-file-pdf" @click="onPreview()" :disabled="dialog.processing"></pv-button>
                                    <pv-button v-if="model.status >= 200" class="p-mr-1" :label="(action.rollback ? 'Processing' : 'Rollback')" icon="fas fa-arrows-rotate" @click="onRollback()" :loading="action.rollback" :disabled="dialog.processing"></pv-button>
                                    <pv-button v-if="model.status >= 200" class="p-mr-1" label="Send" icon="fas fa-paper-plane" @click="onSend(model)" :disabled="dialog.processing"></pv-button>
                                    <pv-button class="p-mr-1" label="Cancel" icon="fas fa-ban" @click="onBack()" :disabled="dialog.processing"></pv-button>
                                    <pv-button v-if="model.status <= 100" class="p-mr-1" :label="(action.savedraft ? 'Processing' : 'Save As Draft')" icon="fas fa-floppy-disk" @click="onSaveDraft()" :disabled="dialog.processing" :loading="action.savedraft"></pv-button>
                                </div>
                            </div>
                        </pv-accordion-tab>
                        <pv-accordion-tab>
                            <template #header>
                                <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                                    <div class="p-col-fixed p-as-start" style="width: 1.5rem"><i class="fa fa-info-circle"></i></div>
                                    <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" title="CONSIGNMENTS">
                                        <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; <span v-html="`CONSIGNMENTS`"></span></div>
                                    </div>
                                </div>
                            </template>
                            <div style="width: calc(100% + 2rem); padding: 0; margin: -1rem -0.75rem; margin-bottom: 1.45rem;">
                                <div style="margin: -2rem -0.10rem; margin-left:-0.5rem;">
                                    <consignment-grid :formData="model" @is-sync="onConsignments($event)"></consignment-grid>
                                </div>
                            </div>
                        </pv-accordion-tab>
                        <pv-accordion-tab>
                            <template #header>
                                <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                                    <div class="p-col-fixed p-as-start" style="width: 1.5rem"><i class="fa fa-info-circle"></i></div>
                                    <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" title="ADDITIONAL CHARGES">
                                        <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; <span v-html="`ADDITIONAL CHARGES`"></span></div>
                                    </div>
                                </div>
                            </template>
                            <div style="width: calc(100% + 2rem); padding: 0; margin: -1rem -0.75rem; margin-bottom: 1.45rem;">
                                <div style="margin: -2rem -0.10rem; margin-left:-0.5rem;">
                                    <additionalfee-grid :formData="model" @is-sync="onAdditionalFees($event)"></additionalfee-grid>
                                </div>
                            </div>
                        </pv-accordion-tab>
                    </pv-accordion>
                    <div class="p-d-flex p-grid p-col-12" style="margin:-0.75rem 2rem 0 -1rem; padding:0; width:100%">
                        
                            <div class="p-col-9 p-md-9">
                                    <form-input id="disclaimer" :disabled="viewOnly" :required="false" :value="model.disclaimer ?? ''" type="textarea" :rows="5" @is-sync="model.disclaimer = $event.value" :hideLabel="true" preLabel="Disclaimer" :v$="v$" :showCopy="false" />
                                    <attachment-form :formData="model" @is-sync="onAttachmentUpload($event)" :disabled="viewOnly"></attachment-form>
                            </div>
                            <div class="p-col-3 p-md-3">                                
                                    <form-numeric id="totalVATExclusive" :disabled="true" :required="true" type="currency" :preLabel="`TOTAL (Excl. VAT) ${currencySymbol}`" :currency="currencyCode" :hideLabel="true" :max="9999999999.99" :value="totalVATExclusive" :showCopy="false" />
                                    <form-lookup id="vatRateType" v-if="!viewOnly" :disabled="viewOnly" :required="true" preLabel="VAT Type" type="enum" source="ratetype" :value="model.vatRateType" :hideLabel="true" @is-sync="model.vatRateType = $event?.value;" :showCopy="false" style="margin-top:-1.75rem;" />
                                    <form-numeric v-if="model.vatRateType == 100" id="vatRate" :disabled="viewOnly" :required="true" type="decimal" :preLabel="`+ VAT ${currencySymbol}`" :currency="currencyCode" :hideLabel="true" :max="9999999999.99" :value="model.vatRate" @is-sync="model.vatRate = $event?.value;" :showCopy="false" style="margin-top:-1.75rem;" />
                                    <form-numeric v-if="model.vatRateType == 200 & !viewOnly" id="vatRate" :disabled="viewOnly" :required="true" type="decimal" preLabel="VAT Percentage" :currency="currencyCode" :hideLabel="true" :max="9999999999.99" :value="model.vatRate" @is-sync="model.vatRate = $event?.value;" :showCopy="false" style="margin-top:-1.75rem;" suffix="%" />
                                    <form-numeric v-if="model.vatRateType == 200" id="totalVATPercentage" :disabled="viewOnly" :required="true" type="currency" :preLabel="`+ ${model.vatRate}% VAT`" :currency="currencyCode" :hideLabel="true" :max="9999999999.99" :value="totalVATPercentage" :showCopy="false" style="margin-top:-1.75rem;" />
                                    <div style="border-style: double; border-bottom-width: medium; width:97%; margin: -0.75rem 0.5rem 0.05rem"></div>
                                    <form-numeric id="totalGrossVATInclusive" :disabled="true" :required="true" type="currency" :preLabel="`TOTAL (Incl. VAT) ${currencySymbol}`" :currency="currencyCode" :hideLabel="true" :max="9999999999.99" :value="totalGrossVATInclusive" :showCopy="false" />
                            </div>

                        
                        
                    </div>
                </div>
            </div>
        </div>
    </form-record>
    <is-dialog header="Delete Record?" :visible="dialog.delete" @is-confirm="onConfirmDelete()" :confirmDisabled="dialog.processing" :cancelDisabled="dialog.processing" :confirmLoading="action.delete" @is-cancel="onCancel()">
        <div class="confirmation-content p-d-flex">
            <div><i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" /></div>
            <div>
                You are about to delete this record: :
                <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                    <tr v-for="data in deleteDisplayFields" :key="data.id">
                        <th style="text-align:left;">{{$filters.titleize(data.replace('name','Invoice Number')).replace('code','Transaction ID')}}</th>
                        <td>:</td>
                        <td> {{ this.model[data] }}</td>
                    </tr>
                </table>
                Please be aware of the following:
                <ul>
                    <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                    <li>For records that has activity, the record will only be disabled and archived.</li>
                    <li>Also deleting a record may be irreversible action.</li>
                </ul>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
    <print-form :formData="model" :showDialog="dialog.show" module="invoice" area="facility" :documentType="dialog.type" @is-confirm="{this.dialog.show = false; this.dialog.type = 'summary';}" @is-cancel="{this.dialog.show = false; this.dialog.type = 'summary';}"></print-form>
    <send-form :formData="dialog.send.model" :showDialog="dialog.send.show" :showDocumentToggle="false" area="facility" alertMessage="invoice" module="invoice" :documentType="dialog.type" headerDisplay="SEND INVOICE" @is-confirm="onConfirmSend($event)" @is-cancel="onCancelSend($event)"></send-form>

</template>
<script>
    import { mapGetters } from 'vuex';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    import additionalFeeGrid from '@/components/facility/invoice/additionalfees-grid.vue';
    import consignmentGrid from '@/components/facility/invoice/v2/invoiceconsignment-grid.vue';
    import printform from '@/components/transaction/tms/consignment/record/print-form.vue';
    import sendform from '@/components/transaction/tms/consignment/record/send-form.vue';
    import attachmentForm from '@/components/facility/invoice/attachment-form.vue';
    const moduleName = 'invoice';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'additionalfee-grid': additionalFeeGrid,
            'consignment-grid': consignmentGrid,
            'attachment-form': attachmentForm,
            'print-form': printform,
            'send-form': sendform
        //    'consignment-view': consignmentview,
        },
        data() {
            return {
                model: null,
                consignments: [],
                additionalFees: [],
                attachments: [],
                dialog: {
                    delete: false, edit: false, show: false, type: 'label',
                    send: {
                        model: null,
                        show: false
                    },
                    processing: false
                },
                action: {
                    confirm: false,
                    delete:false,
                    rollback: false,
                    savedraft: false

                },
                deleteDisplayFields: ["code","name"]
            }
        },
        validations() {
            return {
                model: {
                }
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            cdn() {
                return `${this.$config.config.endpoint.cdn}`;
            },
            tenant() {
                return this.$config.common.$tenant().filter(x => x.id === 1)[0];
            },
            customer() {
                return this.$config.common.$customer(this.model?.environmentID ?? -100, null, null)?.filter(x => x.id === this.model.customerID)[0];
            },
            customerAddress() {
                return {
                    address1: this.customer?.address1,
                    address2: this.customer?.address2,
                    address3: this.customer?.address3,
                    city: this.customer?.city,
                    state: this.customer?.state,
                    postalCode: this.customer?.postalCode,
                    countryID: this.customer?.countryID,
                    companyName: this.customer?.name,
                    establishmentType: 100
                };
            },
            shipper() {
                return this.$config.common.$environment()?.filter(x => x.id === this.model.environmentID)[0];
            },
            shipperAddress() {
                return {
                    address1: this.shipper?.address1,
                    address2: this.shipper?.address2,
                    address3: this.shipper?.address3,
                    city: this.shipper?.city,
                    state: this.shipper?.state,
                    postalCode: this.shipper?.postalCode,
                    countryID: this.shipper?.countryID,
                    companyName: this.shipper?.name,
                    establishmentType: 100
                };
            },
            currencySymbol() {
                return this.$vm.$currencies.filter(x => x.id === this.model?.currencyID)[0]?.symbolText;
            },
            currencyCode() {
                return this.$vm.$currencies.filter(x => x.id === this.model?.currencyID)[0]?.code
            },
            totalConsignmentCost() {
                let totalCost = 0.00
                for (let i = 0; i < (this.consignments ?? []).length; i++) {
                    let consignment = this.consignments[i];
                    var baseAmount = (consignment.invoiceBaseAmount ?? 0);
                    var markUp = (consignment.invoiceMarkUpRateType == 100 ? (((consignment.invoiceMarkUp ?? 0) / 100) * baseAmount) : (consignment.invoiceMarkUp ?? 0.00));
                    var discount = (consignment.invoiceDiscountRateType == 100 ? (((consignment.invoiceDiscount ?? 0) / 100) * (baseAmount + markUp)) : (consignment.invoiceDiscount ?? 0.00));
                    var exchangeRate = (consignment.invoiceExchangeRate ?? 1.00)
                    totalCost += ((consignment.invoiceAmount ?? 0) > 0 ? (consignment.invoiceAmount ?? 0) :
                                  ((baseAmount + markUp) - discount)) * exchangeRate;
                }

                return totalCost;
            },
            totalAdditionalFees() {
                let totalFees = 0.00;
                // Loop through the array and add each element to the sum
                for (let i = 0; i < (this.additionalFees ?? []).length; i++) {
                    totalFees += this.additionalFees[i].amount ?? 0.00;
                }
                return totalFees;
            },
            totalVATExclusive() { return (this.totalAdditionalFees ?? 0) + (this.totalConsignmentCost ?? 0); },
            totalVATPercentage() {
                if (this.model.vatRateType == 200)
                    return (this.totalVATExclusive * (this.model.vatRate / 100));
                else
                    return (0.00);      
            },
            totalGrossVATInclusive() {
                if (this.model.vatRateType == 100)
                    return (this.totalVATExclusive + this.model.vatRate)?.toFixed(2);
                else
                    return (this.totalVATExclusive + this.totalVATPercentage)?.toFixed(2);
            },
            invoiceErrors() { return this.$store.getters["invoice/errors"]; },
            viewOnly() { return this.model?.status >= 200; }

        },
        methods: {
            onBack() {
                this.$router.push({ name: this.router.module + '-index' });
            },
            async getByCode() {
                return await this.$axios.get(this.$config.config.endpoint.api + `/services/facility/invoice/` + this.model.mask + ``.replaceAll("//", "/"));
            },
            async updateRecord() { return await this.$store.dispatch(this.router.module + "/modifyRecord", this.model); },
            async deleteRecord() { return await this.$store.dispatch(this.router.module + "/deleteRecordByMask", this.model.mask); },
            onSaveDraft() {
                let _self = this;
                this.btnAction('savedraft');
                this.updateRecord().then((invoiceResponse) => {
                    if (this.invoiceErrors) {
                        let errorMessage = "<ul>";
                        (Object.keys(this.invoiceErrors) ?? []).forEach(key => {
                            errorMessage += "<li>" + ((this.invoiceErrors[key]).message ?? (this.invoiceErrors[key])).toString().replace("Name", "Invoice Number") + "</li>";
                        });
                        errorMessage += "</ul>";
                        this.$toast.add({ severity: 'error', summary: 'Update Invoice Failed!', detail: errorMessage, life: 8000 });
                    }
                    else if (invoiceResponse.success) {
                        this.model.code = invoiceResponse?.result?.code
                        _self.resetModelState();
                        setTimeout(() => _self.$emit("is-reset"), 500);
                        this.$toast.add({ severity: 'success', summary: 'Save Success!', detail: "Successfully save as draft", life: 8000 });
                    }
                }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: 'Create Invoice Failed!', detail: err, life: 8000 });
                }).finally(() => {
                    this.btnActionReset();
                });
            },
            onDelete() {
                this.dialog.delete = true;
            },
            onConfirmDelete() {
                let _self = this;
                this.btnAction('delete');
                this.deleteRecord().then((invoiceResponse) => {
                    if (this.invoiceErrors) {
                        let errorMessage = "<ul>";
                        (Object.keys(this.invoiceErrors) ?? []).forEach(key => {
                            errorMessage += "<li>" + ((this.invoiceErrors[key]).message ?? (this.invoiceErrors[key])).toString().replace("Name", "Invoice Number") + "</li>";
                        });
                        errorMessage += "</ul>";
                        this.$toast.add({ severity: 'error', summary: 'Update Invoice Failed!', detail: errorMessage, life: 8000 });
                    }
                    else if (invoiceResponse.success) {
                        this.model.code = invoiceResponse?.result?.code
                        _self.resetModelState();
                        setTimeout(() => _self.$emit("is-reset"), 500);
                        this.$toast.add({ severity: 'success', summary: 'Record Deleted Success!', detail: "Record was successfully deleted.", life: 8000 });
                        this.onBack();
                    }
                }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: 'Create Invoice Failed!', detail: err, life: 8000 });
                }).finally(() => {
                    this.btnActionReset();
                });
            },
            onConfirm() {
                //let _self = this;
                this.btnAction('confirm');
                this.$store.dispatch("invoice/putURL", {
                    url: "/services/facility/invoice/" + this.model.mask + "/confirm",
                    payload: this.model
                }).then((invoiceResponse) => {
                    if (this.invoiceErrors) {
                        let errorMessage = "<ul>";
                        (Object.keys(this.invoiceErrors) ?? []).forEach(key => {
                            errorMessage += "<li>" + ((this.invoiceErrors[key]).message ?? (this.invoiceErrors[key])).toString().replace("Name", "Invoice Number") + "</li>";
                        });
                        errorMessage += "</ul>";
                        this.$toast.add({ severity: 'error', summary: 'Confirm Invoice Failed!', detail: errorMessage, life: 8000 });
                    }
                    else if (invoiceResponse.success) {
                        this.model = invoiceResponse?.result;
                        this.$toast.add({ severity: 'success', summary: 'Confirmed Success!', detail: "Invoice was confirmed successfully", life: 8000 });
                    }
                }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: 'Confirm Invoice Failed!', detail: err, life: 8000 });
                }).finally(() => {
                    this.btnActionReset();
                });
            },
            onRollback() {
                //let _self = this;
                this.btnAction('rollback');
                this.$store.dispatch("invoice/putURL", {
                    url: "/services/facility/invoice/" + this.model.mask + "/rollback"
                }).then((invoiceResponse) => {
                    if (this.invoiceErrors) {
                        let errorMessage = "<ul>";
                        (Object.keys(this.invoiceErrors) ?? []).forEach(key => {
                            errorMessage += "<li>" + ((this.invoiceErrors[key]).message ?? (this.invoiceErrors[key])).toString().replace("Name", "Invoice Number") + "</li>";
                        });
                        errorMessage += "</ul>";
                        this.$toast.add({ severity: 'error', summary: 'Rollback Invoice Failed!', detail: errorMessage, life: 8000 });
                    }
                    else if (invoiceResponse.success) {
                        this.model = invoiceResponse?.result;
                        this.$toast.add({ severity: 'success', summary: 'Rollback Success!', detail: "Invoice was rollbacked successfully", life: 8000 });
                    }
                }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: 'Rollback Invoice Failed!', detail: err, life: 8000 });
                }).finally(() => {
                    this.btnActionReset();
                });
            },
            onPreview() {
                this.dialog.show = true;
                this.dialog.type = "Summary";
            },
            onSend(e) {
                if (e.id > 0) {
                    this.$axios
                        .get(this.$config.config.endpoint.api + `/services/facility/invoice/` + e?.mask + `/send`.replaceAll("//", "/"))
                        .then((response) => {
                            this.dialog.send.model = response.data.result;
                            this.dialog.send.show = true;
                        })
                }
            },
            onConfirmSend() {
                this.dialog.send.show = false;
            },
            onCancelSend() {
                this.dialog.send.show = false;
            },
            onUnderdevelopment() {
                this.$toast.add({ severity: 'warn', summary: 'UNDERDEVELOPMENT!', detail: 'Module is on development', life: 8000 });
            },
            onAttachmentUpload(e) {
                console.log(e);
            },
            onAdditionalFees(e)
            {
                this.additionalFees = e.additionalFees ?? [];   
            },
            onConsignments(e) {
                this.consignments = e.consignments ?? [];
            },
            onInvoiceDate(e) {
                this.$nextTick(() => {
                    this.model.invoiceDateValue = e.value;
                    this.model.invoiceDate = (e.record.data ?? new Date());
                });
            },
            onInvoiceDueDate(e) {
                this.$nextTick(() => {
                    this.model.invoiceDueDateValue = e.value;
                    this.model.invoiceDueDate = e.record?.data;
                });
            },
            btnAction(btnName) {
                this.dialog.processing = true;
                switch (btnName) {
                    case "confirm":
                        this.action.confirm = true;
                        break;
                    case "delete":
                        this.action.delete = true;
                        break;
                    case "rollback":
                        this.action.rollback = true;
                        break;
                    case "savedraft":
                        this.action.savedraft = true;
                        break;
                }
            },
            btnActionReset() {
                this.dialog.processing = false;
                this.action.confirm = false;
                this.action.delete = false;
                this.action.rollback = false;
                this.action.savedraft = false;
            }
        },
        watch: {
        },

    }
</script>
<style>

    #InvoiceAccordion .p-accordion-header-link {
        padding: 0.75rem 0.75rem;
        border-bottom: 1px solid var(--gray-200);
    }

    div#areadisclaimer .is-label {
        margin-top: -3rem !important;
        margin-left:-5px !important;
    }

    div#attachments .p-fileupload-row {
        max-height:2.5rem;
    }
</style>