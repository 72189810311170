<template>     
    <cosmos-grid id="summaryGrid" sourceType="array" :source="source.data ?? []" :showCheckbox="false" :sourcePaged="true" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :showExport="false" :showOperation="false" :showHeader="false">
        <pv-grid-column field="collectionDate" header="COLLECTION DATE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ $filters.toDateDisplay(slotProps.data?.collectionDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="transactionID" header="TRANSACTION ID" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <router-link :to="{ name: 'base-consignment-track', params: { mask: slotProps.data.code  }}" style="font-weight:bold">{{slotProps.data.code}}</router-link>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference" header="REFERENCE 1" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <router-link :to="{ name: 'consignment-code-edit', params: { code: slotProps.data.code  }}" style="font-weight:bold">{{slotProps.data.customerReference}}</router-link>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference2" header="REFERENCE 2" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.customerReference2}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference3" header="REFERENCE 3" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.customerReference3}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference4" header="REFERENCE 4" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.customerReference4}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference5" header="REFERENCE 5" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.customerReference5}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference6" header="REFERENCE 6" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.customerReference6}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference7" header="REFERENCE 7" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.customerReference7}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="carrier" header="CARRIER" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.carrier}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="product" header="SERVICE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.product}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="carrierReference" header="REFERENCE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <a :href="`${$filters.formatString($vm.$providers.filter((x) => x.id == slotProps.data?.serviceProviderID)[0]?.trackingURL, slotProps.data.carrierReference,slotProps.data.shipToPostalCode)}`" target="_blank" style="font-weight: bold;"> {{slotProps.data.carrierReference}} </a>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="dc" header="WAREHOUSE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex" style="padding: 0;">
                    {{ slotProps.data.dc}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customer" header="CUSTOMER" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.customer}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="shipTo" header="SHIP TO NAME" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.shipTo}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="noOfParcel" header="# OF PACKAGES" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatNumber(slotProps.data.noOfParcel ?? 0.00,0,slotProps.data.shipFromCountryCode)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="noOfPallet" header="# OF PALLET" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatNumber(slotProps.data.noOfPallet ?? 0.00,0,slotProps.data.shipFromCountryCode)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="totalPieces" header="TOTAL PIECES" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatNumber(slotProps.data.totalPieces ?? 0.00,0,slotProps.data.shipFromCountryCode)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="manifestDate" header="MANIFEST DATE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{  $filters.toDateDisplay(slotProps.data?.manifestDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="dispatchDate" header="DISPATCH DATE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{  $filters.toDateDisplay(slotProps.data?.dispatchDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="outForDeliveryDate" header="OUT FOR DELIVERY DATE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{  $filters.toDateDisplay(slotProps.data?.outForDeliveryDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="podDate" header="POD DATE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{  $filters.toDateDisplay(slotProps.data?.podDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="accountNumber" header="ACCOUNT NUMBER" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.accountNumber}}
                </div>
            </template>
        </pv-grid-column>
        <template #empty>
            <span v-html="`${(filterKey === null ? 'Click &lt;b&gt;Apply Filter&lt;/b&gt; button to generate result.' : 'No records found.')}`"></span>
        </template>
        <template #paginatorLeft>
            <pv-button type="button" icon="pi pi-download" class="p-button-text" @click="onExportToExcel" />
        </template>
        <template #paginatorRight>
            <pv-button type="button" icon="pi pi-refresh" class="p-button-text" @click="getSource" />
        </template>
    </cosmos-grid>
</template>
<script>
    import { mapGetters } from 'vuex';
    import { useVuelidate } from '@vuelidate/core';
    export default {
        name: 'summaryGrid',
        setup: () => ({ v$: useVuelidate() }),
        props: {
            formData: { type: Object },
            filterKey: { type: Number, default: null },
            isAdmin: { type: Boolean, default: false }
        },
        data() {
            return {
                type: -1,
                filter: {},
                columns: [],
                searchFilter: ['name','code'],
                activity: { loading: true },
                source: {
                    total: 0,
                    data: []
                },
                modelState: null,
                initialBind: false
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            scopeCustomerDS() {
                return this.$config.common.$customer(this.user?.environmentID ?? -100, this.$vm.$appSession.warehouse.id ?? this.user.warehouseId, this.user);
            },
            scopeWarehouseDS() {
                return this.$config.common.$warehouse(this.user?.environmentID ?? -100, this.user);
            },
            apiData() {
                return {
                    ServiceHeader: {
                        Username: "TEMP",
                        Password: "TESTESTEDET",
                        ClientID: "TESTTESTTESTTESTTEST",
                        ClientSecret: "TESTEDREDREDTETEDEDETEREDEDEREDE"
                    },
                    Request: {
                        DC: this.formData?.warehouseID > 0 ? this.scopeWarehouseDS.filter(x => x.id === this.formData.warehouseID)[0]?.code : null,
                        Customer: this.formData?.customerID > 0 ? this.scopeCustomerDS.filter(x => x.id === this.formData.customerID)[0]?.code : null,
                        Account: this.formData?.serviceProviderAccountCode,
                        Carrier: this.formData?.serviceProviderID > 0 ? this.$vm.$providers.filter(y => y.id === this.formData?.serviceProviderID)[0]?.code : null,
                        ScopeDetail: {
                            IncludeArchive: this.formData?.includeArchived,
                            CollectionDate: {
                                From: this.$filters.formatDateWithFormat(this.formData.collectionDateFrom ?? new Date(), "YYYY-MM-DD"),
                                To: this.$filters.formatDateWithFormat(this.formData.collectionDateTo ?? new Date(), "YYYY-MM-DD")
                            },
                            ManifestDate: {
                                From: this.formData.manifestDateFrom !== undefined && this.formData.manifestDateFrom !== null ? this.$filters.formatDateWithFormat(this.formData.manifestDateFrom ?? new Date(), "YYYY-MM-DD") :null,
                                To: this.formData.manifestDateTo !== undefined && this.formData.manifestDateTo !== null ? this.$filters.formatDateWithFormat(this.formData.manifestDateTo ?? new Date(), "YYYY-MM-DD") : null
                            },
                            DispatchDate: {
                                From: this.formData.dispatchDateFrom !== undefined && this.formData.dispatchDateFrom !== null ? this.$filters.formatDateWithFormat(this.formData.dispatchDateFrom ?? new Date(), "YYYY-MM-DD") : null,
                                To: this.formData.dispatchDateTo !== undefined && this.formData.dispatchDateTo !== null ? this.$filters.formatDateWithFormat(this.formData.dispatchDateTo ?? new Date(), "YYYY-MM-DD") : null
                            }
                        }
                    }
                }

            },
            gridSource() {
                return this.source.data;
            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            'filterKey': {
                handler() {
                    this.$nextTick(() => {
                        this.getSource();
                    });
                }, deep: true
            },
            activity: {
                handler() {
                    this.$emit('is-sync', this.activity);
                }, deep: true
            }
        },
        methods: {
            async getSource() {
                this.$nextTick(() => { this.activity.loading = true; });
                this.$nextTick(() => {
                    this.$axios.post(this.$config.config.endpoint.api + `/services/report/consignment/summary`.replaceAll("//", "/"), this.apiData)
                        .then((response) => {
                            this.source.data = response?.data?.result ?? [];
                            this.source.total = this.source.data.length;
                            this.$nextTick(() => { this.activity.loading = false; });
                        })
                        .catch(() => {
                            this.source.data = [];
                            this.source.total = 0;
                            this.$nextTick(() => { this.activity.loading = false; });
                        });
                });

            },
            onExportToExcel() {
                this.$nextTick(() => { this.activity.export = true; });
                this.$axios.post(this.$config.config.endpoint.api + `/services/report/consignment/summary/export`.replaceAll("//", "/"), this.apiData)
                    .then((response) => {
                        this.$config.common.$downloadFile(response.data.result.base64File, response.data.result.contentType, response.data.result.fileName);
                        this.$nextTick(() => { this.activity.export = false; });
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        this.$nextTick(() => { this.activity.export = false; });
                    });
            },

        },
        async created() {
            this.activity.loading = false;
          
        },
        async mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            this.loading = false;
           
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>