<template>
    <keep-alive>
        <is-dialog :header="dialogHeader" :visible="dialog.delete && (dialog.edit || dialog.add)" :confirmDisabled="(model?.name?.length ?? 0) <= 0" @is-confirm="onManageRecord(model)" @is-cancel="this.dialog.delete = false;this.dialog.edit = false;this.dialog.add = false">
            <div class="confirmation-content p-fluid p-grid">
                <div class="p-col-12">
                    <div class="p-grid">
                        <div class="p-col-12">
                            <form-input id="name" label="Postal Code" :required="true" :v$="v$" :value="model.name" @is-sync="model.name = $event.value" v-focus></form-input>
                        </div>
                    </div>
                </div>
            </div>
        </is-dialog>
    </keep-alive>
    <is-dialog header="Delete Record?" :visible="dialog.delete && !dialog.edit && !dialog.add" @is-confirm="onManageRecord(model)" @is-cancel="this.dialog.delete = false; this.dialog.add = false; this.dialog.edit = false;">
        <div class="confirmation-content p-d-flex">
            <div>
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            </div>
            <div>
                You are about to delete this record:

                <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                    <tr v-for="data in deleteDisplay" :key="data.id">
                        <th style="text-align:left;">{{ $filters.titleize("Postal Code") }}</th>
                        <td>:</td>
                        <td> {{ forDeleteRule[data] }}</td>
                    </tr>
                </table>
                <br />
                Please be aware of the following:
                <ul>
                    <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                    <li>For records that has activity, the record will only be disabled and archived.</li>
                    <li>Also deleting a record may be irreversible action.</li>
                </ul>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
    <cosmos-grid id="PostalCodeList" sourceType="array" :source="source?.data ?? []" :sourcePaged="true" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showExport="false" :showDelete="false" :showEdit="false" :showHeader="true" :showOperation="false">
        <template #toolbar>
                <div class="p-d-flex p-jc-between p-ai-center">
                    <span>
                        <pv-button v-if="showAdd" icon="pi pi-fw pi-plus" label="Add New Record" style="margin-right: 5px;" @click="onRecordCreate"></pv-button>

                    </span>
                    <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                        <i class="pi pi-search" />
                        <pv-input v-model="activeFilters['global'].value" placeholder="Keyword Search" style="width: 100%;" @keyup="onForceFilter" />
                    </span>
                </div>
        </template>
        <pv-grid-column field="code" header="Postal Code" style="min-width: 90%; max-width: 90%; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:80%">
                        <div class="p-d-flex p-jc-between" style="min-width: 10rem; margin-bottom: 5px;">
                            {{slotProps.data?.name}}
                        </div>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="mask"  headerStyle="min-width: 7rem; max-width: 7rem; width: 7rem" style="`min-width: 7rem; max-width: 7rem; border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-d-flex" style="width: 2rem;">
                    <div class="p-col">
                        <pv-button icon="pi pi-pencil" iconPos="right" @click="onRecordEdit(slotProps.data)" class="p-button-info" />
                    </div>
                    <div class="p-col">
                        <pv-button icon="pi pi-trash" iconPos="right" @click="onRecordDelete(slotProps.data)" class="p-button-danger" />
                    </div>
                    <slot name="operation" :data="slotProps.data">
                    </slot>
                </div>
            </template>
        </pv-grid-column>
    </cosmos-grid>



    <!--<pv-grid :ref="id"
             :id="id"
             :dataKey="'id'"
             :value="source.data"
             :totalRecords="source.total"
             :lazy="lazyLoading || paged"
             :filters="activeFilters"
             :filterDisplay="'menu'"
             :globalFilterFields="searchFilter"
             :first="page.first"
             :rows="page.size"
             :rowsPerPageOptions="[5,10,20,50,100]"
             :rowHover="true"
             v-model:selection="selectedItems"
             :selectionMode="'multiple'"
             :scrollable="true"
             :scrollWidth="'flex'"
             :scrolHeight="'flex'"
             :responsive="'scroll'"
             :loading="false"
             :loadingIcon="'pi pi-spinner'"
             :paginator="true"
             :alwaysShowPaginator="pagination"
             :paginatorPosition="'bottom'"
             :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'"
             :pageLinkSize="3"
             :currentPageReportTemplate="'Showing {first} to {last} of {totalRecords} records'"
             :columnResizeMode="'expand'"
             :stateStorage="'session'"
             :stateKey="`dt-state-${id}`"
             :groupRowsBy="groupBy"
             rowGroupMode="subheader"
             autoLayout
             showGridlines
             stripedRows
             @page="changePage"
             @row-dblclick="rowClick">
        <template #header>
            <slot name="toolbar">
                <div class="p-d-flex p-jc-between p-ai-center">
                    <span>
                        <pv-button v-if="showAdd" icon="pi pi-fw pi-plus" label="Add New Record" style="margin-right: 5px;" @click="onRecordCreate"></pv-button>

                    </span>
                    <span class="p-input-icon-left" style="max-width: 300px; width: 100%">
                        <i class="pi pi-search" />
                        <pv-input v-model="activeFilters['global'].value" placeholder="Keyword Search" style="width: 100%;" />
                    </span>
                </div>
            </slot>
        </template>
        <pv-grid-column field="code" header="Postal Code">
            <template #body="slotProps">
                <div class="p-grid">
                    <div class="p-col p-d-flex p-col-12">
                        {{slotProps.data?.name}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="mask" headerStyle="min-width: 1rem;" style="max-width: 8rem; min-width: 1rem; border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;" :frozen="true" :alignFrozen="right">
            <template #body="slotProps">
                <div class="p-d-flex p-col-1" style="max-width: 1rem;">
                    <div class="p-col">
                        <pv-button icon="pi pi-pencil" iconPos="right" @click="onRecordEdit(slotProps.data)" class="p-button-info" />
                    </div>
                    <div class="p-col">
                        <pv-button icon="pi pi-trash" iconPos="right" @click="onRecordDelete(slotProps.data)" class="p-button-danger" />
                    </div>
                    <slot name="operation" :data="slotProps.data">
                    </slot>
                </div>
            </template>
        </pv-grid-column>
        <template #empty>
            No records found.
        </template>
        <template #loading>
            Retrieving records from database. Please wait...
        </template>-->
        <!-- Pagination -->
        <!--<template #paginatorRight>
            <pv-button type="button" icon="pi pi-refresh" class="p-button-rounded p-button-text" @click="onRefresh" />
        </template>
    </pv-grid>-->
</template>
<script>
    const config = require('@/assets/lib/cosmos/_js/config.js').default.config;
    import { FilterMatchMode } from 'primevue/api';
    export default {
        name: 'PostalCodeTemplateForm',
        emits: ["is-sync"],
        props: {
            parentID: { type: Number, default: -1 },
            formData: { type: Object },
            moduleName: { type: String, default: "rulepostalblocpostalcode" }
        },
        data() {
            return {
                id: 'PostalCodeList',
                filename: 'export',
                filter: {},
                searchFilter: ['code', 'name'],
                paged: false,
                typed: false,
                typeValue: -1,
                lazyLoading: false,
                pagination: true,
                pageSize: 10,
                multiSelect: true,
                csvSeparator: ',',
                autoGenerate: true,
                columns: [],
                operationWidth: 8,
                showAdd: true,
                showExport: true,
                showEdit: true,
                showDelete: true,
                showOperation: true,
                deleteDisplayFields: [],
                groupBy: null,
                recordSource: [],
                items: null,
                forDelete: null,
                exportFilename: null,
                selectedItems: [],
                activity: { loading: true },
                source: {
                    total: 0,
                    data: []
                },
                page: {
                    first: 0,
                    current: 0,
                    size: 10
                },
                router: {
                    name: null,
                    module: null,
                    title: null,
                    subtitle: null,
                    model: null,
                    schema: null
                },
                dialog: {
                    header: "Delete Record?",
                    add: false,
                    edit: false,
                    delete: false
                },
                forDeleteRule: null,
                forDeleteItem: null,
                deleteDisplay: ["name",],
                itemDialog: {
                    header: "Delete Record?",
                    add: false,
                    edit: false,
                    delete: false
                },
                sourceItem: {
                    total: 0,
                    data: []
                },
                pageItem: {
                    first: 0,
                    current: 0,
                    size: 10
                },
                expandedRows: [],
                model: {
                    id: 0,
                    id2: null,
                    temporaryId: Number(Math.random().toString().replace(".", "")),
                    code: "PC" + Math.random().toString().replace(".", ""),
                    rulePostalBlocID: 0,
                    warehouseId: null,
                    environmentId: null,
                    mask: null,
                },
                modelState: null,
                searchFields: ["code", "name"],
             
            }
        },
        computed: {
            activeFilters() {
                var filter = {};
                if (this.filter)
                    filter = this.filter;
                filter["global"] = { value: null, matchMode: FilterMatchMode.CONTAINS };
                return filter;
            },
            record() {
                return this.$store.getters[this.$props.moduleName + "/record"]
            },
            ruleModel() {
                return this.model;
            },
            dialogHeader() { return this.dialog.header; },
            dialogItemHeader() {
                if (this.itemDialog.add) return "Add Item";
                if (this.itemDialog.edit) return "Edit Item";
                return "Delete Record?"
            },
            companyDS() { return this.$store.getters["company/ds"]; },
            warehouseDS() { return this.companyDS.data.filter((x) => x.type === 8); },
        },
        watch: {
            'source.data': {
                handler() {
                    this.$emit("is-sync", { model: this.source.data });
                }, deep: true
            },
            'formData': {
                handler() {
                    if ((this.source.data?.length ?? 0) === 0 && this.$props?.parentID > 0) {
                        this.getListByParent();
                    }
                }, deep: false
            }
        },
        methods: {
            isSync(e) {
                this.$nextTick(() => {
                    this.model = e.model;
                });
            },
            async postRule(e) {
                let _self = this;
                await this.$store.dispatch(this.$props.moduleName + "/addRecord", this.$filters.init(e)).then(() => {
                    _self.model = _self.record;
                    e.id = _self.model.id;
                    e.id2 = _self.model.id2;
                    e.mask = _self.model.mask;
                    e.code = _self.model.code;
                    let index = _self.source.data.findIndex(f => f.temporaryId === e.temporaryId);
                    if (index !== -1)
                        _self.source.data[index] = this.$filters.init(e);
                    })
                    .catch((ex) => {
                        this.errors = ex.data;
                    });
            },
            async putRule(e) {
                let _self = this;
                await this.$store.dispatch(this.$props.moduleName + "/modifyRecord", this.$filters.init(e)).then(() => {
                    _self.model = _self.record;
                })
                .catch((ex) => {
                    this.errors = ex.data;
                });
            },
            async removeRule(e) {
                let _self = this;
                await this.$store.dispatch(this.$props.moduleName + "/removeRecord", e.mask).then(() => {
                    })
                    .catch((ex) => {
                        _self.errors = ex.data;
                    });
            },
            async getListByParent() {
                if (this.source.data.length === 0) {
                    await this.$axios
                        .get(config.endpoint.api + `/services/facility/rulepostalblocpostalcode/postalbloc/id/${this.$props?.parentID}`.replaceAll("//", "/"))
                        .then((response) => {
                            this.source.data = response.data.result;
                            this.source.total = response.data.result.length;
                        })
                        .catch((ex) => {
                            console.log(ex);
                            this.errors = ex.data;
                        });
                }
                
            },
            async getCompanyList() {
                return this.$store.dispatch("company/getAll");
            },
            onExport() {

            },
            rowClick(e) {
                this.onRecordEdit(e.data);
            },
            onRecordCreate() {
                this.dialog.header = "Add Record";
                this.model = {
                    //id: Number(Math.random().toString().replace(".", "")),
                    id: 0,
                    code: "PC" + Math.random().toString().replace(".", ""),
                    //name: "PC" + Math.random().toString().replace(".", ""),
                    temporaryId: Number(Math.random().toString().replace(".", "")),
                    rulePostalBlocID: this.$props?.parentID > 0 ? this.$props?.parentID ?? 0 : 0,
                    environmentId: null,
                    warehouseId: null,
                    mask: null
                };
                this.dialog.delete = true;
                this.dialog.add = true;
               
            },
            onRecordEdit(e) {
                this.dialog.header = "Edit Record";
                this.model = this.$filters.init(e);
                this.dialog.delete = true;
                this.dialog.edit = true;
            },
            onRecordDelete(data) {
                this.model = this.$filters.init(data);
                this.dialog.delete = true;
                if (this.$props?.parentID > 0)
                    this.forDeleteRule = this.source.data.filter(x => x.id === this.model.id)[0];
                else
                    this.forDeleteRule = this.source.data.filter(x => x.temporaryId === this.model.temporaryId)[0];
            },
            onManageRecord(e) {
                var location = this.$filters.init(e);
                var index = -1;
                if (location.id > 0)
                    index = this.source.data.findIndex(x => x.id === location.id);
                else
                    index = this.source.data.findIndex(x => x.temporaryId === location.temporaryId);
                if (index === -1) {
                    this.source.data.push(this.$filters.init(e));
                    if (this.$props?.parentID > 0) { this.postRule(e); }
                }
                else {
                    if (this.dialog.edit || this.dialog.add) {
                        if (this.$props?.parentID > 0 && e.id > 0 && e.mask !== null) { this.putRule(e); }
                        this.source.data[index] = this.$filters.init(e);
                    }
                    else {
                        if (this.$props?.parentID > 0 && e.id > 0 && e.mask !== null) { this.removeRule(e); }
                        this.source.data.splice(index, 1);
                    }
                }
                this.source.total = this.source.data.length;
                this.model = {
                    id: 0,
                    id2: null,
                    temporaryId: Number(Math.random().toString().replace(".", "")),
                    code: "PC" + Math.random().toString().replace(".", ""),
                    rulePostalBlocID: 0,
                    warehouseId: null,
                    environmentId: null,
                    mask: null,
                },
                this.dialog.delete = false;
                this.dialog.edit = false;
                this.dialog.add = false;
                this.dialog.header = "Delete Record?";
                this.$emit("is-sync", { model : this.source });
            },
         
            async getCurrency() { return this.$store.dispatch("currency/getAll"); },
        },
        async mounted() {
         
            //this.$nextTick(() => {
            //    this.products = this.$props.formData.products;
            //    this.setCarrier();
            //    this.setService();
            //});
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>