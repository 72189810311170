<template>
    <div class="p-grid">
        <div class="p-col-12" style="padding: 2rem;">
            <div class="p-grid">
                <div v-for="column in columns" :key="column" class="p-col-12 p-md-4">
                    <ul style="list-style: none; padding: 0;">
                        <li v-for="group in sources.groupNames.filter(x => x.column === column)" :key="group.groupName">
                            <h3 style="font-size: 1.2rem; margin: 1rem 0 0.5rem 0;">{{ group.groupName }}</h3>
                            <ul style="list-style: none; padding: 1rem 0 0 0;">
                                <li v-for="field in fields.filter(x => x.groupName === group.groupName)" :key="field.id">
                                    <form-numeric v-if="field.configurationDetail.dataType === 300 && field.configurationDetail.decimalSetting"
                                                  type="decimal"
                                                  :id="`_${field.headerName.toLowerCase().replaceAll(' ','')}`"
                                                  :disabled="disabled"
                                                  :showCopy="false"
                                                  :label="field.headerName"
                                                  :min="field.configurationDetail?.decimalSetting?.minValue"
                                                  :max="field.configurationDetail?.decimalSetting?.maxValue"
                                                  :decimalDigit="field.configurationDetail?.decimalSetting?.decimalPlaces ?? 2"
                                                  :value="Number(getValue(field, field.configurationDetail?.decimalSetting?.defaultValue ?? 0.00))"
                                                  @is-sync="setValue($event, field)" />
                                    <form-lookup v-else-if="field.configurationDetail.dataType === 200 && field.configurationDetail.lookupSetting && field.configurationDetail.lookupSetting.lookupType === 'array'" :label="field.headerName" sourceType="array" :source="sources[field.configurationDetail.lookupSetting?.lookupSource]" :required="true" :placeholder="field.configurationDetail.placeholder"
                                                 :id="`_${field.headerName.toLowerCase().replaceAll(' ','')}`"
                                                 :disabled="disabled"
                                                 :showCopy="false"
                                                 :value="Number(getValue(field, field.configurationDetail?.lookupSetting?.defaultValue))"
                                                 @is-sync="setValue($event, field, Number(getValue(field, field.configurationDetail?.lookupSetting?.defaultValue)))" />
                                    <form-lookup v-else-if="field.configurationDetail.dataType === 200 && field.configurationDetail.lookupSetting" :type="field.configurationDetail.lookupSetting.lookupType" :label="field.headerName" :source="field.configurationDetail.lookupSetting.lookupSource" :required="true" :placeholder="field.configurationDetail.placeholder"
                                                 :id="`_${field.headerName.toLowerCase().replaceAll(' ','')}`"
                                                 :disabled="disabled"
                                                 :showCopy="false"
                                                 :value="Number(getValue(field, field.configurationDetail?.lookupSetting?.defaultValue))"
                                                 @is-sync="setValue($event, field)"
                                                 :sorted="false" />
                                    <form-input v-else-if="field.configurationDetail.dataType === 100 && field.configurationDetail.textSetting"
                                                :id="`_${field.headerName.toLowerCase().replaceAll(' ','')}`"
                                                :disabled="disabled"
                                                :label="field.headerName"
                                                :showCopy="false"
                                                :placeholder="field.configurationDetail.placeholder"
                                                :minLenght="field.configurationDetail.textSetting.minLength"
                                                :maxLength="field.configurationDetail.textSetting.maxLength"
                                                :value="getValue(field, field.configurationDetail?.textSetting?.defaultValue)"
                                                @is-sync="setValue($event, field)" />
                                    <form-boolean v-else-if="field.configurationDetail.dataType === 500 && field.configurationDetail.booleanSetting"
                                                  :id="`_${field.headerName.toLowerCase().replaceAll(' ','')}`"
                                                  :disabled="disabled"
                                                  :showCopy="false"
                                                  type="toggle"
                                                  :label="field.headerName"
                                                  text-position="left"
                                                  :value="Boolean(getValue(field, field.configurationDetail?.lookupSetting?.defaultValue) === 'True' ? 1 : 0)"
                                                  @is-sync="setValue($event, field, false)" />
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'ImportProfileDefaults',
        props: {
            profileID: { type: Number, default: 0 },
            customerID: { type: Number, default: 0 },
            source: { type: Array },
            fields: { type: Array, default: null },
            readOnly: { type: Boolean, default: false },
            warehouseDS: { type: Array },
            v$: { type: Object }
        },
        data() {
            return {
                sources: {
                    warehouses: [],
                    servicegroups: [],
                    groupNames: []
                }
            }
        },
        computed: {
            columns() { return [...new Set(this.fields.map(m => m.displayColumn))]; },
            disabled() { return this.profileID > 0 && this.profileID < 1000 },
        },
        watch: {
            warehouseDS: {
                handler() {
                    this.sources.warehouses = this.warehouseDS ?? [];
                },
                deep: true
            },
            source: {
                handler() {
                    this.model = this.source;
                    this.v$.model.code.$touch()
                },
                deep: true
            },
            fields: {
                handler() {
                    this.sources.groupNames = [];
                    let groupName = '';
                    this.fields.forEach(x => {
                        if (x.groupName !== groupName) {
                            this.sources.groupNames.push({ groupName: x.groupName, column: x.displayColumn });
                            groupName = x.groupName;
                        }
                    });
                },
                deep: true
            }
        },
        methods: {
            async getSources() {
                //await this.$store.dispatch("importprofile/getURL", { url: '/services/settings/company?type=8' })
                //    .then((response) => { this.sources.warehouses = response.result; });
                if (this.customerID > 0)
                    await this.$store.dispatch("importprofile/getURL", { url: `/services/facility/servicegroup/customer/id/${this.customerID}` })
                        .then((response) => { this.sources.servicegroups = response.result; });
            },
            generateGroupNames() {
                this.sources.groupNames = [];
                let groupName = '';
                this.fields.forEach(x => {
                    if (x.groupName !== groupName) {
                        this.sources.groupNames.push({ groupName: x.groupName, column: x.displayColumn });
                        groupName = x.groupName;
                    }
                });
            },
            getValue(field, value) {
                var property = this.model.filter(x => x.defaultID === field.id);
                if (this.disabled && property.length > 0 && property[0].id === 0)
                    return null;
                var index = this.model.findIndex(x => x.defaultID === field.id);
                if (index >= 0)
                    return this.model[index].value ?? value;
                return value ? value : null;
            },
            setValue(event, field, oldValue) {
                var index = this.model.findIndex(x => x.defaultID === field.id);
                if (index >= 0)
                    this.model[index].value = (event?.value ?? oldValue)?.toString();
                else
                    this.model.push({ id: 0, defaultID: field.id, value: (event?.value ?? oldValue)?.toString() });
                setTimeout(() => { this.$emit("is-sync", this.model); }, 100);
            }
        },
        created() {
            this.getSources();
            this.generateGroupNames();
            this.model = this.source ?? [];
        }
    }
</script>