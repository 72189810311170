export default {
    templates: {
        emits: ['is-sync'],
        props: {
            id: { type: String, required: true, validator: (value) => /^[a-zA-Z0-9_]*$/.test(value) },
            // Display Type
            autoFit: { type: Boolean, default: true },
            filled: { type: Boolean, default: true },
            // Label Related
            label: { type: String },
            hideLabel: { type: Boolean, default: false },
            floatLabel: { type: Boolean, default: true },
            preLabel: { type: String },
            preIcon: { type: String },
            preLabelStyle: { type: String, default: 'width: 150px' },
            postLabel: { type: String },
            postIcon: { type: String },
            postLabelStyle: { type: String, default: 'width: 50px' },
            // Text Helpers
            placeholder: { type: String },
            ph: { type: String },
            tooltip: { type: String },
            // Validation
            validator: { type: Object },
            // Usage
            disabled: { type: Boolean, default: false },
        },
        data() {
            return {
                activity: {
                    loading: true
                },
                modelValue: null
            }
        },
        computed: {
            placeholderText() {
                if (this.placeholder && this.placeholder.length > 0)
                    return this.placeholder;
                return this.ph;
            }
        },
        watch: {
            modelValue() {
                this.$emit('is-sync', this.modelValue);
            }
        },
        created() {
            this.modelValue = this.value;
        },
        updated() {
            this.modelValue = this.value;
        }
    },
    index: {
        data() {
            return {
                records: {
                    data: [],
                    total: 0
                },
                activity: {
                    loading: true
                },
                module: {
                    name: null,
                    model: null,
                    schema: null,
                    store: null,
                    columns: null,
                    filters: null,
                    title: null,
                    subtitle: null
                },
            }
        },
        computed: {
            originalDS() { return this.$store.getters[this.module.name + "/ds"] },
        },
        methods: {
            setModule(router) {
                const meta = router.currentRoute.value.meta;
                this.module.name = meta.module;
                this.module.store = meta.store;
                this.module.model = meta.model ? meta.module : meta.module;
                this.module.schema = meta.schema ? meta.schema : this.module.model;
                this.module.title = meta.title;
                this.module.subtitle = meta.subtitle;
            }
        },
        beforeCreate() {
        },
        created() {
            this.setModule(this.$router);
        },
        mounted() {
            this.activity.loading = false;
        },
        beforeUpdate() {
            this.setModule(this.$router);
        },
        updated() {

        },
        beforeUnmount() {

        },
        unmount() {

        }
    },
    record: {
        data() {
            return {
                model: null,
                modelState: null,
                activity: {
                    loading: true,
                    submitted: false
                },
                module: {
                    name: null,
                    model: null,
                    schema: null
                }
            }
        },
        computed: {
            user() { return this.$store.getters["auth/currentUser"]; },
            init() { return this.$store.getters[this.module.name + "/init"]; },
            record() { return this.$store.getters[this.module.name + "/record"]; },
        },
        watch: {
            record() {
                this.model = this.record;
                this.activity.loading = false;
            },
            v$() {
                this.modelState = this.validation;
            }
        },
        methods: {
            async get(mask) { return await this.$store.dispatch(this.module.name + "/getRecord", mask); },
        },
        beforeCreate() {
            this.model = this.init;
            //    if (!this.user)
            //        this.$router.push({ path: "/account/login" });
        },
        async created() {
            this.module.name = this.$router.currentRoute.value.meta.module;
            this.module.model = this.$router.currentRoute.value.meta.model;
            this.module.schema = this.$router.currentRoute.value.meta.schema;
            this.model = this.init;
            if (this.$route.params.mask) {
                await this.get(this.$route.params.mask);
                    this.$nextTick(() => {
                        try {
                        this.v$.$reset(); this.modelState.$reset;
                        }
                        catch
                        {
                            // NO reply
                        }
                    });
            }
            this.activity.submitted = this.$vm.$submitted = false;
        },
        async mounted() {
            this.activity.loading = false;
        },
        async updated() {
            this.module.name = this.$router.currentRoute.value.meta.module;
            this.module.model = this.$router.currentRoute.value.meta.model;
            this.module.schema = this.$router.currentRoute.value.meta.schema;
            this.activity.submitted = this.$vm.$submitted;

        },
        async unmounted() {
            this.activity.loading = true;
        }
    },
    form: {
        props: {
            entity: { type: Object },
            title: { type: String },
            subtitle: { type: String },
            validation: { type: Object },
            onBeforeSubmit: { type: Function }
        },
        data() {
            return {
                model: null,
                modelState: null,
                activity: {
                    loading: true,
                    submitted: false
                },
                module: {
                    name: null,
                    title: null,
                    subtitle: null,
                    model: null,
                    schema: null
                }
            }
        },
        computed: {
            init() { return this.$store.getters[this.mode.name + "/init"]; },
            record() { return this.$store.getters[this.mode.name + "/record"]; }
        },
        methods: {
            async add(model) { return await this.$store.dispatch(this.module.name + "/addRecord", model); },
            async update(model) { return await this.$store.dispatch(this.module.name + "/modifyRecord", model); },
            async delete(mask) { return await this.$store.dispatch(this.module.name + "/removeRecord", mask); },
            async get(mask) { return await this.$store.dispatch(this.module.name + "/getRecord", mask); },
        },
        async created() {
            this.module.name = this.$router.currentRoute.value.meta.module;
            this.module.title = this.$router.currentRoute.value.meta.title;
            this.module.subtitle = this.$router.currentRoute.value.meta.subtitle;
            this.module.model = this.$router.currentRoute.value.meta.model;
            this.module.schema = this.$router.currentRoute.value.meta.schema;
            this.model = this.init;
            if (this.$route.params.mask) {
                await this.get(this.$route.params.mask);
                this.$nextTick(() => { this.validation.$reset(); });
            }
            this.activity.submitted = this.$vm.$submitted = false;
        },
        async mounted() {
            this.activity.loading = false;
        },
        async updated() {
            this.module.name = this.$router.currentRoute.value.meta.module;
            this.module.title = this.$router.currentRoute.value.meta.title;
            this.module.subtitle = this.$router.currentRoute.value.meta.subtitle;
            this.module.model = this.$router.currentRoute.value.meta.model;
            this.module.schema = this.$router.currentRoute.value.meta.schema;
            this.activity.submitted = this.$vm.$submitted;
        },
        async unmounted() {
            this.activity.loading = true;
        },
        watch: {
            record() {
                this.model = this.record;
                this.activity.loading = false;
            },
            validation() {
                this.modelState = this.validation;
            }
        }
    },
    menu: {
        props: {
            entity: { type: Object },
            title: { type: String },
            subtitle: { type: String },
            validation: { type: Object }
        },
        data() {
            return {
                model: null,
                modelState: null,
                activity: {
                    loading: true,
                    submitted: false
                },
                module: {
                    name: null,
                    model: null,
                    schema: null
                }
            }
        },
        computed: {
            init() { return this.$store.getters[this.mode.name + "/init"]; },
            record() { return this.$store.getters[this.mode.name + "/record"]; }
        },
        methods: {
            async add(model) { return await this.$store.dispatch(this.module.name + "/addRecord", model); },
            async update(model) { return await this.$store.dispatch(this.module.name + "/modifyRecord", model); },
            async delete(mask) { return await this.$store.dispatch(this.module.name + "/removeRecord", mask); },
            async get(mask) { return await this.$store.dispatch(this.module.name + "/getRecord", mask); },
        },
        async created() {
            this.module.name = this.$router.currentRoute.value.meta.module;
            this.module.model = this.$router.currentRoute.value.meta.model;
            this.module.schema = this.$router.currentRoute.value.meta.schema;
            if (this.$route.params.mask) {
                await this.get(this.$route.params.mask);
                this.$nextTick(() => { this.validation.$reset(); });
            }
            else
                this.model = this.init;
            this.activity.submitted = this.$vm.$submitted = false;
        },
        async updated() {
            this.module.name = this.$router.currentRoute.value.meta.module;
            this.module.model = this.$router.currentRoute.value.meta.model;
            this.module.schema = this.$router.currentRoute.value.meta.schema;
            this.activity.submitted = this.$vm.$submitted;

        },
        watch: {
            record() {
                this.model = this.record;
            },
            validation() {
                this.modelState = this.validation;
            }
        }
    },
}