<template>
    <div class="card">
        <div class="p-d-flex p-grid">
            <div :class="`p-col-12 p-md-${columnNo}`">
                <h4 style="padding: 0.25rem"><span>COLLECTION INFO</span></h4>
                <form-lookup v-show="false" id="pickupType" :required="true" type="enum" source="pickuptype" :value="model.pickupType" label="Collection Type" @is-sync="model.pickupType = $event.value" :v$="v$"></form-lookup>
                <form-datetime id="request_CollectionDateValue" :required="true" :value="model?.request_CollectionDateValue ?? new Date()" type="date" @is-sync="onCollectionDate($event)" label="Collection Date" :v$="v$"></form-datetime>
                <form-datetime id="request_ReadyTimeValue" :value="model?.request_ReadyTimeValue ?? new Date(2018, 1, 1, 16, 0, 0)" type="time" @is-sync="onReadyTime($event)" :timeFormat="24" label="Ready Time" :v$="v$"></form-datetime>
                <form-datetime id="request_ClosingTimeValue" :value="model?.request_ClosingTimeValue ?? new Date(2018, 1, 1, 18, 0, 0)" type="time" @is-sync="onClosingTime($event)" :timeFormat="24" label="Closing Time" :v$="v$"></form-datetime>
            </div>
            <div :class="`p-col-12 p-md-${columnNo}`">
                <h4 style="padding: 0.25rem"><span>SHIPPING INFO</span></h4>
                <!--<form-lookup id="serviceGroupID" label="Service Group" :required="false" :source="`services/facility/servicegroup/customer/id/${this.model?.customerID}`" sourceType="url" :value="source?.shippingInfo?.serviceGroup.serviceGroupID" :v$="v$" :textField="'text'" :valueField="'id'" @is-sync="onSelectServiceGroup($event,false)" :sorted="false" />-->
                <form-lookup id="serviceGroupID" label="Service Group" :required="false" :source="shippingInfoServiceGroupDS ?? []" sourceType="array" :value="source?.shippingInfo?.serviceGroup.serviceGroupID" :v$="v$" :textField="'text'" :valueField="'id'" @is-sync="onSelectServiceGroup($event,false)" :sorted="false" />
                <form-lookup id="serviceProviderAccountID" label="Carrier" :required="false" :source="shippingInfoCarrierDS ?? []" sourceType="array" :value="source?.shippingInfo.carrier?.serviceProviderAccountID" :showLoadingWhenEmpty="processing.shippingInfo.provider" :v$="v$" :textField="'Text'" :valueField="'Id'" @is-sync="onSelectCarrier($event,false)" :sorted="false">
                    <template #option="slotProps">
                        <div class="p-d-flex" style="width:100%">
                            <div class="picklist-list-detail">
                                <div class="p-d-flex" style="padding: 0;">
                                    <div style="padding:0;width:100%">
                                        <div class="p-d-flex" style=" z-index: 100;">
                                            <div style="margin-right:5px">
                                                <img style="width: 35px; height: 20px; max-height: 20px; max-width: 35px; object-fit: contain; object-position: center;" :src="`${slotProps.data.option.Logo}`" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" />
                                            </div>
                                            <div class="p-d-flex p-jc-between" style="width:calc(100%)">
                                                <span><span class="p-code-text" style="font-weight: 600;">[{{ slotProps.data.option.CarrierCode}}] </span>{{ slotProps.data.option.CarrierName }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </form-lookup>
                <form-lookup id="serviceProviderProductAccountID" label="Service" :required="false" :showCopy="false" :disabled="((source?.shippingInfo.carrier?.serviceProviderAccountID ?? 0) == 0)" :source="shippingInfoProductDS ?? []" sourceType="array" :showLoadingWhenEmpty="(source?.shippingInfo.carrier?.serviceProviderAccountID > 0) && processing.shippingInfo.product" :value="source?.shippingInfo?.product?.serviceProviderProductAccountID" :v$="v$" :textField="'Text'" :valueField="'Id'" @is-sync="onSelectProduct($event,false)" :sorted="false">
                    <template #option="slotProps">
                        <div class="p-d-flex" style="width:100%">
                            <div class="picklist-list-detail">
                                <div class="p-d-flex" style="padding: 0;">
                                    <div style="padding:0;width:100%">
                                        <div class="p-d-flex" style=" z-index: 100;">
                                            <div style="margin-right:5px">
                                                <img style="width: 35px; height: 20px; max-height: 20px; max-width: 35px; object-fit: contain; object-position: center;" :src="`${slotProps.data.option.Logo}`" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" />
                                            </div>
                                            <div class="p-d-flex p-jc-between" style="width:calc(100%)">
                                                <span><span class="p-code-text" style="font-weight: 600;">[{{ slotProps.data.option.Code}}] </span>{{ slotProps.data.option.Name }}</span>
                                                <spam class="p-jc-end">
                                                    <span class="p-code-text">
                                                        <span class="product-badge status-instock p-code-text" style="margin-left:0.75rem">{{$filters.formatCurrency(slotProps.data.option?.Quotation?.ShippingCost?.Price?.Amount ?? 0.00,slotProps.data.option?.Quotation?.ShippingCost?.Price?.Currency?.toUpperCase() ?? 'GBP',country?.code?.toUpperCase(),2)}}</span>
                                                        <i class="fa-duotone fa-gas-pump" v-if="slotProps.data.option?.Quotation?.ItemizedCharges?.filter(x => x.Name === 'Fuel Surcharge')[0]?.Name !== null" style="padding-left:0.5rem; font-size: 1rem; --fa-primary-color: gray; --fa-secondary-color: darkred; --fa-secondary-opacity: 1.0;" v-tooltip.top="`With Fuel Surcharge`"></i>
                                                        <i class="fa-duotone fa-gas-pump-slash" v-else style="padding-left: 0.5rem; font-size: 1rem; --fa-primary-color: gray; --fa-secondary-color: darkred; --fa-secondary-opacity: 1.0;" v-tooltip.top="`Without Fuel Surcharge`"></i>
                                                    </span>

                                                </spam>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </form-lookup>
                <form-lookup v-if="(shippingInfoOptionDS ?? []).length > 0 || (source?.shippingInfo?.product?.configuration?.RequireServiceOption ?? false)" :required="(source?.shippingInfo?.product?.configuration?.RequireServiceOption ?? false)" id="serviceProviderOptionAccountID" label="Option" :source="shippingInfoOptionDS ?? []" sourceType="array" :value="source?.shippingInfo.option?.serviceProviderOptionAccountID" :v$="v$" :textField="'Text'" :valueField="'Id'" @is-sync="onSelectOption($event,false)" :sorted="false" />
                <form-boolean v-if="(source?.shippingInfo?.product?.configuration?.GenerateCustomsDataFlag ?? false)" id="isShippingGenerateCustomsData" :value="source.shippingInfo.customization.generateCustomsData" type="toggle" label="Customs Data?" textPosition="left" @is-sync="{source.shippingInfo.customization.generateCustomsData = $event.value; manageAllocationInfo();}"></form-boolean>
                <form-input v-if="(source?.shippingInfo?.product?.configuration?.RequireAdditionalCode ?? false)" :required="(source?.shippingInfo?.product?.configuration?.RequireAdditionalCode ?? false)" id="serviceProviderAdditionalCodeID" label="Additional Code" :v$="v$" :value="source?.shippingInfo.additional?.code" @is-sync="source.shippingInfo.additional.code = $event.value; manageAllocationInfo();" />
                <form-lookup v-if="(source?.shippingInfo?.product?.configuration?.AllowAccessPoint ?? false) && !source.shippingInfo.accesspoint.alternateDelivery.enable" :showLoadingWhenEmpty="(source?.shippingInfo?.product?.configuration?.AllowAccessPoint ?? false) && processing.shippingInfo.accesspoint" :required="(source?.shippingInfo?.product?.configuration?.RequireAccessPoint ?? false)" id="serviceProviderAccessPointAccountID" label="Access Point Location" :source="shipingInfoAccessPointDS ?? []" :value="source?.shippingInfo.accesspoint?.code" :v$="v$" :textField="'FullDescription'" :valueField="'Code'" @is-sync="onSelectAccessPoint($event,false)" :sorted="false" />
                <form-input v-if="(source?.shippingInfo?.product?.configuration?.ReleaseCode ?? false)" id="serviceProviderReleaseCodeID" label="Parcel Release Code" :v$="v$" :value="source?.shippingInfo.release?.code" @is-sync="{source.shippingInfo.release.code = $event.value; manageAllocationInfo();}" />
                <form-boolean v-if="(source?.shippingInfo?.product?.configuration?.AllowAccessPoint ?? false)" id="isShippingAlternateAddress" :value="source.shippingInfo.accesspoint.alternateDelivery.enable" type="toggle" label="Use Alternate Address?" textPosition="left" @is-sync="{source.shippingInfo.accesspoint.alternateDelivery.enable = $event.value; source.shippingInfo.accesspoint.alternateDelivery.address.countryID = this.model?.shipTo_CountryID;}"></form-boolean>
            </div>
            <div :class="`p-col-12 p-md-${(columnNo === '2' ? '3' : columnNo)}`" v-if="source.shippingInfo.accesspoint.alternateDelivery.enable">
                <h4 v-if="source.shippingInfo.accesspoint.alternateDelivery.enable" style="padding: 0.25rem"><span>SHIPPING ALTERNATE ADDRESS</span></h4>
                <form-address id="shippingInfoAlternateAddress" :value="shippingInfoAlternateAddress" :useCompany="true" :showLookup="true" :disabled="!source.shippingInfo.accesspoint.alternateDelivery.enable" @is-sync="onPopulateAlternateAddress($event, false)" disabledView="perline" />
            </div>
            <div v-if="(model?.isReturnable ?? false)" :class="`p-col-12 p-md-${columnNo}`">
                <h4 style="padding: 0.25rem"><span>RETURN INFO</span></h4>
                <!--<form-lookup id="serviceGroupReturnID" label="Service Group" :required="false" :source="`services/facility/servicegroup/customer/id/${this.model?.customerID}`" sourceType="url" :value="source?.returnInfo?.serviceGroup.serviceGroupID" :v$="v$" :textField="'text'" :valueField="'id'" @is-sync="onSelectServiceGroup($event,true)" :sorted="false" />-->
                <form-lookup id="serviceProviderReturnAccountID" label="Carrier" :required="false" :showLoadingWhenEmpty="model.isReturnable && processing.returnInfo.provider" :source="returnInfoCarrierDS ?? []" sourceType="array" :value="source?.returnInfo.carrier?.serviceProviderAccountID" :v$="v$" :textField="'Text'" :valueField="'Id'" @is-sync="onSelectCarrier($event,true)" :sorted="false">
                    <template #option="slotProps">
                        <div class="p-d-flex" style="width:100%">
                            <div class="picklist-list-detail">
                                <div class="p-d-flex" style="padding: 0;">
                                    <div style="padding:0;width:100%">
                                        <div class="p-d-flex" style=" z-index: 100;">
                                            <div style="margin-right:5px">
                                                <img style="width: 35px; height: 20px; max-height: 20px; max-width: 35px; object-fit: contain; object-position: center;" :src="`${slotProps.data.option.Logo}`" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" />
                                            </div>
                                            <div class="p-d-flex p-jc-between" style="width:calc(100%)">
                                                <span><span class="p-code-text" style="font-weight: 600;">[{{ slotProps.data.option.CarrierCode}}] </span>{{ slotProps.data.option.CarrierName }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </form-lookup>
                <form-lookup id="serviceProviderReturnProductAccountID" label="Service" :showLoadingWhenEmpty="(source?.returnInfo.carrier?.serviceProviderAccountID > 0) && processing.returnInfo.product" :required="false" :source="returnInfoProductDS ?? []" sourceType="array" :value="source?.returnInfo?.product?.serviceProviderProductAccountID" :v$="v$" :textField="'Text'" :valueField="'Id'" @is-sync="onSelectProduct($event,true)" :sorted="false">
                    <template #option="slotProps">
                        <div class="p-d-flex" style="width:100%">
                            <div class="picklist-list-detail">
                                <div class="p-d-flex" style="padding: 0;">
                                    <div style="padding:0;width:100%">
                                        <div class="p-d-flex" style=" z-index: 100;">
                                            <div style="margin-right:5px">
                                                <img style="width: 35px; height: 20px; max-height: 20px; max-width: 35px; object-fit: contain; object-position: center;" :src="`${slotProps.data.option.Logo}`" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" />
                                            </div>
                                            <div class="p-d-flex p-jc-between" style="width:calc(100%)">
                                                <span><span class="p-code-text" style="font-weight: 600;">[{{ slotProps.data.option.Code}}] </span>{{ slotProps.data.option.Name }}</span>
                                                <spam class="p-jc-end">
                                                    <span class="p-code-text">
                                                        <span class="product-badge status-instock p-code-text" style="margin-left:0.75rem">{{$filters.formatCurrency(slotProps.data.option?.Quotation?.ShippingCost?.Price?.Amount ?? 0.00,slotProps.data.option?.Quotation?.ShippingCost?.Price?.Currency?.toUpperCase() ?? 'GBP',country?.code?.toUpperCase(),2)}}</span>
                                                        <i class="fa-duotone fa-gas-pump" v-if="slotProps.data.option?.Quotation?.ItemizedCharges?.filter(x => x.Name === 'Fuel Surcharge')[0]?.Name !== null" style="padding-left:0.5rem; font-size: 1rem; --fa-primary-color: gray; --fa-secondary-color: darkred; --fa-secondary-opacity: 1.0;" v-tooltip.top="`With Fuel Surcharge`"></i>
                                                        <i class="fa-duotone fa-gas-pump-slash" v-else style="padding-left: 0.5rem; font-size: 1rem; --fa-primary-color: gray; --fa-secondary-color: darkred; --fa-secondary-opacity: 1.0;" v-tooltip.top="`Without Fuel Surcharge`"></i>
                                                    </span>
                                                </spam>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </form-lookup>
                <form-lookup v-if="(returnInfoOptionDS ?? []).length > 0 || (source?.returnInfo?.product?.configuration?.RequireServiceOption ?? false)" :required="(source?.returnInfo?.product?.configuration?.RequireServiceOption ?? false)" id="serviceProviderReturnOptionAccountID" label="Option" :source="returnInfoOptionDS ?? []" sourceType="array" :value="source?.returnInfo.option?.serviceProviderOptionAccountID" :v$="v$" :textField="'Text'" :valueField="'Id'" @is-sync="onSelectOption($event,true)" :sorted="false" />
                <form-input v-if="(source?.returnInfo?.product?.configuration?.RequireAdditionalCode ?? false)" :required="(source?.returnInfo?.product?.configuration?.RequireAdditionalCode ?? false)" id="serviceProviderReturnAdditionalCodeID" label="Additional Code" :v$="v$" :value="source?.returnInfo.additional?.code" @is-sync="{source.returnInfo.additional.code = $event.value; manageAllocationInfo();}" />
                <!--<form-lookup v-if="(source?.returnInfo?.product?.configuration?.AllowAccessPoint ?? false)" :required="(source?.returnInfo?.product?.configuration?.RequireAccessPoint ?? false)" id="serviceProviderReturnAccessPointAccountID" label="Access Point Location" :source="returnInfoAccessPointDS ?? []" :value="source?.returnInfo.accesspoint?.code" :v$="v$" :textField="'FullDescription'" :valueField="'Code'" @is-sync="onSelectAccessPoint($event,true)" :sorted="false" />
    <form-input v-if="(source?.returnInfo?.product?.configuration?.ReleaseCode ?? false)" id="serviceProviderReturnReleaseCodeID" label="Parcel Release Code" :v$="v$" :value="source?.returnInfo.release?.code" @is-sync="source.returnInfo.release.code = $event.value;" />-->
                <form-boolean v-if="(source?.returnInfo?.product?.configuration?.AllowAccessPoint ?? false)" :showLoadingWhenEmpty="(source?.returnInfo?.product?.configuration?.AllowAccessPoint ?? false) && processing.returnInfo.accesspoint" id="isReturnAlternateAddress" :value="source.returnInfo.accesspoint.alternateDelivery.enable" type="toggle" label="Use Alternate Address?" textPosition="left" @is-sync="{source.returnInfo.accesspoint.alternateDelivery.enable = $event.value; source.returnInfo.accesspoint.alternateDelivery.address.countryID = this.model?.shipTo_CountryID;}"></form-boolean>
            </div>
            <div :class="`p-col-12 p-md-${(columnNo === '2' ? '3' :columnNo)}`" v-if="source.returnInfo.accesspoint.alternateDelivery.enable">
                <h4 v-if="source.returnInfo.accesspoint.alternateDelivery.enable" style="padding: 0.25rem"><span>RETURN ALTERNATE ADDRESS</span></h4>
                <form-address id="returnInfoAlternateAddress" :value="returnInfoAlternateAddress" :useCompany="true" :showLookup="true" :disabled="!source.returnInfo.accesspoint.alternateDelivery.enable" @is-sync="onPopulateAlternateAddress($event, true)" disabledView="perline" />
            </div>
        </div>
    </div>
</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/consignment-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    export default {
        name: 'AllocateTemplate',
        mixins: [mixins.ALLOCATE.FORM],
        setup: () => ({ v$: useVuelidate() })
        
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }

    .p-orderlist-controls,
    .p-picklist-buttons.p-picklist-source-controls,
    .p-picklist-buttons.p-picklist-source-controls, .p-picklist-buttons.p-picklist-target-controls {
        display: none !important;
    }
    .picklist-item {
        display: flex;
        align-items: center;
        padding: .5rem;
        width: 100%;
    }

    img#is-img  {
        width: 75px;
        /*   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);*/
        margin-right: 1rem;
    }

    .picklist-list-detail {
        flex: 1 1 0;
    }

    .picklist-list-action {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .picklist-category-icon {
        vertical-align: middle;
        margin-right: .5rem;
        font-size: .875rem;
    }

    .picklist-category {
        vertical-align: middle;
        line-height: 1;
        font-size: .875rem;
    }

    .custom-skeleton {
        border: 1px solid var(--surface-d);
        border-radius: 4px;
    }

        .custom-skeleton ul {
            list-style: none;
        }

            .custom-skeleton ul > li {
                padding: 0.75rem;
            }
</style>
