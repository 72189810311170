<template>
    <!--<is-loader v-if="activity.parentLoading"></is-loader>-->
    <div class="content-section header">
        <div class="feature-intro">
            <slot name="header">
                <h1><span>TOTAL PACKAGE</span></h1>
                <p>{{ this.$props?.subtitle ?? router.subtitle }}</p>
            </slot>
        </div>
    </div>
    <div class="content-section body">
        <div class="card">
            <div class="p-d-flex  p-grid p-col-12" style="padding-bottom:0 !important;">
                <div class="p-col-12 p-md-4">
                    <h4><span>HANDLED BY</span></h4>
                    <!--<form-lookup id="handledBy" :required="true" type="enum" selection="multiple" :sorted="false" source="incotermtype" :value="model.handledBy" hideLabel="true" @is-sync="model.handledBy = $event.value" :v$="v$"></form-lookup>-->
                    <form-lookup id="handledBy" :required="true" type="flag" :sorted="false" :source="'handledbytypekpi1'" :value="model.handledBy" hideLabel="true" @is-sync="model.handledBy = $event.value" :v$="v$"></form-lookup>
                </div>
                <div class="p-col-12 p-md-4">
                    <h4><span>FILTERED BY</span></h4>
                    <form-lookup id="warehouseID" label="Warehouse" :source="scopeWarehouseDS" :value="model.warehouseID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="model.warehouseID = $event?.value" />
                    <form-datetime id="collectionDateFrom" :value="model.collectionDateFromValue" type="date" @is-sync="onCollectionDateFrom($event)" label="Collection Date From" :v$="v$"></form-datetime>
                    <form-datetime id="collectionDateTo" :value="model.collectionDateToValue" type="date" @is-sync="onCollectionDateTo($event)" label="Collection Date To" :v$="v$"></form-datetime>
                </div>
            </div>
            <div class="p-d-flex p-grid p-col-12 p-jc-end">
                <pv-button class="p-mr-1" label="Export To Excel" icon="pi pi-excel" @click="onExportToExcel()"></pv-button>
            </div>
            <div class="p-grid">
                <div class="p-col-12">
                    <pv-tab style="margin: 0 -14px !important;">
                        <pv-tabpanel  header="RESULT">
                            <report-grid :formData="model"></report-grid>
                        </pv-tabpanel>
                        <pv-tabpanel header="CHART">
                            <report-chart :formData="model"></report-chart>
                        </pv-tabpanel>
                    </pv-tab>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import validator from '@/assets/_js/cosmos-validators.js';
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from '@vuelidate/validators';
    import reportGrid from '@/views/report/kpi/package-grid-form.vue';
    import reportChart from '@/views/report/kpi/package-chart.vue';
    const moduleName = 'bypackage';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'report-grid': reportGrid,
            'report-chart': reportChart
        },
        data() {
            return {
                model: {
                    warehouseID: 0,
                    collectionDateFrom: null,
                    collectionDateFromValue: null,
                    collectionDateTo: null,
                    collectionDateToValue: null,
                    handledBy: 1
                },
                activity: {
                    parentLoading: true,
                    loading: false
                },
                keys: null,
                session: {
                    tenant: null,
                    environment: null,
                    warehouse: null,
                    customer: null,
                    currentUser: null,
                    executiveUser: null
                }
            }
        },
        validations() {
            return {
                model: {
                    code: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },
                    name: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },
                }
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            scopeWarehouseDS() {
                return this.$config.common.$warehouse(this.user?.environmentID ?? -100, this.user);
            },
            showCustomer() {
                return this.$filters.getFlags(this.model.handledBy ?? 0).findIndex(x => x === 1) != -1;
            },
            showProvider() {
                return this.$filters.getFlags(this.model.handledBy ?? 0).findIndex(x => x === 2) != -1;
            },
            showProduct() {
                return this.$filters.getFlags(this.model.handledBy ?? 0).findIndex(x => x === 4) != -1;
            },
            apiData() {
                // this.$filters.formatDateWithFormat(this.model.request_CollectionDate, "YYYY-MM-DD")
                return {
                    ServiceHeader: {
                        Username: "TEMP",
                        Password: "TESTESTEDET",
                        ClientID: "TESTTESTTESTTESTTEST",
                        ClientSecret: "TESTEDREDREDTETEDEDETEREDEDEREDE"
                    },
                    Request: {
                        DC: this.model?.warehouseID > 0 ? this.scopeWarehouseDS.filter(x => x.id === this.model.warehouseID)[0]?.code : null,
                        ScopeDetail: {
                            CollectionDate: {
                                From: this.$filters.formatDateWithFormat(this.model.collectionDateFrom ?? new Date(), "YYYY-MM-DD"),
                                To: this.$filters.formatDateWithFormat(this.model.collectionDateTo ?? new Date(), "YYYY-MM-DD")
                            },
                            PerCustomer: this.showCustomer,
                            PerCarrier: this.showProvider,
                            PerService: this.showProduct
                        }
                    }
                }
            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                   
                });
            }
          
        },
        methods: {
            onCollectionDateFrom(e) {
                this.$nextTick(() => {
                    this.model.collectionDateFromValue = e.value;
                    this.model.collectionDateFrom = (e.record.data ?? new Date());
                });
            },
            onCollectionDateTo(e) {
                this.$nextTick(() => {
                    this.model.collectionDateToValue = e.value;
                    this.model.collectionDateTo = (e.record.data ?? new Date());
                });
            },
            async onExportToExcel() {
                    await this.$axios.post(this.$config.config.endpoint.api + `/services/report/kpi/parcel/export`.replaceAll("//", "/"), this.apiData)
                        .then((response) => {
                            this.$config.common.$downloadFile(response.data.result.base64File, response.data.result.contentType, response.data.result.fileName);
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
        },
        async created() {
            this.loading = false;
        },
        mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            this.loading = false;
            setTimeout(() => {
                let startDate = new Date();
                startDate.setDate(1);
                this.model.collectionDateFromValue = startDate;
                this.model.collectionDateFrom = startDate;
                this.model.collectionDateToValue = new Date();
                this.model.collectionDateTo = startDate;
            }, 100);
            this.model.handledBy = 1;
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }

    .p-orderlist-controls,
    .p-picklist-buttons.p-picklist-source-controls,
    .p-picklist-buttons.p-picklist-source-controls, .p-picklist-buttons.p-picklist-target-controls {
        display: none !important;
    }

    .picklist-item {
        display: flex;
        align-items: center;
        padding: .2rem;
        width: 100%;
    }

    img#is-img {
        width: 75px;
        /*   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);*/
        margin-right: 1rem;
    }

    .picklist-list-detail {
        flex: 1 1 0;
    }

    .picklist-list-action {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .picklist-category-icon {
        vertical-align: middle;
        margin-right: .5rem;
        font-size: .875rem;
    }

    .picklist-category {
        vertical-align: middle;
        line-height: 1;
        font-size: .875rem;
    }

    .custom-skeleton {
        border: 1px solid var(--surface-d);
        border-radius: 4px;
    }

    .custom-skeleton ul {
        list-style: none;
    }

        .custom-skeleton ul > li {
            padding: 0.75rem;
        }
</style>
