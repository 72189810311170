<template>
    <form-wrapper :title="router.title" :sub-title="router.subtitle">
        <consignment-grid />
    </form-wrapper>
</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/mixin.js';
    import consignmentGrid from '@/components/transaction/tms/consignment/record/grid-form.vue';
    export default {
        name: 'ConsignmenttTemplate',
        mixins: [mixins.GRID],
        props: {
            foreignkeyid: { type: Number, default: -1 },
            readOnly: { type: Boolean, default: false }
        },
        components: {
            'consignment-grid': consignmentGrid
        }
        
        
    }
</script>