<template>
    <pv-grid :id="id"
             :ref="id"
             :value="records.data"
             :totalRecords="records.total"
             :alwaysShowPaginator="true"
             :paginator="true"
             :paginatorPosition="'bottom'"
             :pageLinkSize="5"
             :rowsPerPageOptions="[5, 10, 25, 50, 100]"
             :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport'"
             :currentPageReportTemplate="'Showing {first} - {last} of {totalRecords} records'"
             :first="page.first"
             :rows="page.size"
             :lazy="true"
             :loading="activity.loading"
             :editMode="'row'"
             :dataKey="'id'"
             :scrollWidth="'flex'"
             :scrolHeight="'flex'"
             :responsive="'scroll'"
             :columnResizeMode="'expand'"
             :stateStorage="'session'"
             :stateKey="`dt-state-${id}`"
             autoLayout
             showGridlines
             stripedRows
             v-model:editingRows="editingRecords"
             @row-edit-init="onRowEdit"
             @row-edit-save="onRowSave"
             @row-edit-cancel="onRefresh"
             @page="onChangePage">
        <!-- Group Header Template -->
        <pv-grid-column field="name" header="Particulars" :frozen="true">
            <template #body="slotProps">
                {{ slotProps.data.name }}
            </template>
            <template #editor="{ data }">
                <div class="p-editor" style="display: block;">
                    <pv-input v-model="data['name']" :allowEmpty="false" autofocus style="min-width: 0 !important; width:100%" onfocus="this.select()" />
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="amount" :header="`Amount ${currencySymbol}`" headerStyle="width: 7rem;" style="width: 7rem; text-align: right;">
            <template #body="slotProps">
                {{ $filters.formatCurrency(slotProps.data['amount'], globalization.currency, globalization.country) }}
            </template>
            <template #editor="{ data }">
                <div class="p-editor-full" style="display: block;">
                    <pv-numeric v-model="data['amount']" mode="decimal" :min="0" :minFractionDigits="2" :maxFractionDigits="2" :showButtons="true" :format="true" :allowEmpty="false" autofocus style="width: 100%;" onfocus="this.select()" />
                </div>
            </template>
        </pv-grid-column>
        <!-- Inline Edit Command -->
        <pv-grid-column :class="[{'p-field-hidden': (viewOnly) }]" :rowEditor="true" class="p-operator-save" headerStyle="max-width: 7rem; min-width:3rem" style="max-width: 7rem !important; min-width:3rem !important; width: 3rem; text-align: right; border-right: 0;" :frozen="true" />
        <!-- Operation Column -->
        <pv-grid-column :class="[{'p-field-hidden': (viewOnly) },{'p-operator-cancel' : 'true'}] " class="p-operator-cancel" :rowEditor="true" :headerStyle="`min-width: 3rem; max-width: 3rem; width: 3rem`" style="width: 3rem; border-left: 0; text-align: center; object-position: center; padding: 0 5px; " :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <pv-button :disabled="slotProps.data.recordStatus !== 0 || slotProps.data.id < 0 || (slotProps.data?.viewOnly ?? false)" icon="pi pi-trash" iconPos="right" class="p-button-danger" @click="onDelete(`${slotProps.data.id}`)" />
            </template>
        </pv-grid-column>
        <!-- Table Header Template -->
        <template #header>
            <slot name="toolbar">
                <div class="p-d-flex p-jc-between p-ai-center">
                    <span>
                        <pv-button v-if="!viewOnly" icon="pi pi-fw pi-plus" :label="`Add Additional Fees`" style="margin-right: 5px;" @click="onCreate"></pv-button>                        
                    </span>
                    <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                        <i class="pi pi-search" />
                        <pv-input placeholder="Keyword Search" style="width: 100%;" @keyup="rei" />
                    </span>
                </div>
            </slot>
        </template>
        <!-- Empty Record Template -->
        <template #empty>
            No records found.
        </template>
        <!-- Loading Template -->
        <template #loading>
            Retrieving records from database. Please wait...
        </template>
        <!-- Pagination -->
        <template #paginatorLeft>
            <pv-button type="button" icon="pi pi-download" class="p-button-text" @click="onDownload" />
        </template>
        <template #paginatorRight>
            <pv-button type="button" icon="pi pi-refresh" class="p-button-text" @click="onRefresh" />
        </template>
    </pv-grid>

    <is-dialog header="Delete Record?" :visible="dialog.delete" @is-confirm="onConfirmDelete(model.mask)" @is-cancel="onCancel()">
        <div class="confirmation-content p-d-flex">
            <div><i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" /></div>
            <div>
                You are about to delete this record:
                <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                    <tr v-for="data in deleteDisplayFields" :key="data.id">
                        <th style="text-align:left;">{{$filters.titleize(data.replace('name','particulars'))}}</th>
                        <td>:</td>
                        <td> {{ this.model[data] }}</td>
                    </tr>
                </table>
                Please be aware of the following:
                <ul>
                    <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                    <li>For records that has activity, the record will only be disabled and archived.</li>
                    <li>Also deleting a record may be irreversible action.</li>
                </ul>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
    <is-dialog :header="(model?.id ?? 0) === 0 ? 'Add New Additonal Fees' : 'Edit Record'" :style="{width: '50vw'}" :visible="dialog.edit" @is-confirm="onConfirmEdit(model.mask)" @is-cancel="onCancel()" :confirmDisabled="confirmDisabled">
        <div class="confirmation-content p-grid">
            <div class="p-col p-col-60">
                <form-input id="particular" :required="true" :value="model.name" @is-sync="model.name = $event.value" :hideLabel="true" preLabel="Particulars" :v$="v$" v-focus />
                <form-numeric id="amount" :required="true" type="currency" preLabel="Amount" :currency="currencyCode" :hideLabel="true" :max="9999999999.99" :value="model.amount" @is-sync="model.amount = $event.value" style="margin-top: -1rem;" />
            </div>
            
        </div>
    </is-dialog>
</template>
<script>
    import { mapGetters } from 'vuex';
    import { useVuelidate } from '@vuelidate/core';
    export default {
        name: 'additionalFeeGrid',
        setup: () => ({ v$: useVuelidate() }),
        props: {
            formData: { type: Object }},
        data() {
            return {
                editingRecords: [],
                records: { data: [], total: 0 },
                source: [],
                model: null,
                activity: { loading: true },
                dialog: { delete: false, edit: false },
                page: {
                    current: 0,
                    first: 0,
                    size: 10
                },
                type: -1,
                filter: {},
                columns: [],
                deleteDisplayFields: ["name", "amount"],
                searchFields: ["name"],
                selectedItems: [],
                selectedReset: false,
                modelState: null,
                initialBind: false

            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            dataSource() {
                return (this.records ?? { data: [], total: 0 }).data;
            },
            globalization() { return { country: this.shipperCountry?.code ?? "GB", currency: this.currencyCode ?? "USD" } },
            shipper() {
                return this.$config.common.$environment()?.filter(x => x.id === this.formData.environmentID)[0];
            },
            shipperCountry() { return this.$vm.$countries.filter(x => x.id === this.shipper.countryID)[0]?.code },
            currencySymbol() {
                return this.$vm.$currencies.filter(x => x.id === this.formData?.currencyID)[0]?.symbolText;
            },
            currencyCode() {
                return this.$vm.$currencies.filter(x => x.id === this.formData?.currencyID)[0]?.code
            },
            viewOnly() { return this.formData?.status >= 200; }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            'formData.id': {
                handler() {
                    this.$nextTick(() => {
                        this.onRefresh();
                    });
                }, deep: true
            },
            'records.data': {
                handler() {
                    this.$nextTick(() => {
                        this.$emit("is-sync", { additionalFees : this.records.data });
                    });
                }, deep: true
            }
        },
        methods: {
            onCreate() {
                this.model = {
                    name: null,
                    amount: 0.00,
                    invoiceID: this.formData.id,
                    environmentID: this.formData.environmentID
                }
                this.dialog.edit = true;
            },
            onEdit(e) {
                this.model = this.$filters.init(e);
                this.dialog.edit = true;
            },
            onDelete(e) {
                this.model = this.$filters.init(((this.records ?? []).data ?? []).filter(x => x.id == e)[0]);
                //this.model = this.$filters.init(e);
                this.dialog.delete = true;
            },
            onCancel() {
                this.model = null;
                this.dialog.edit = false;
                this.dialog.delete = false;
            },
            onConfirmDelete(e) {
                this.$store.dispatch("invoiceadditionalfee/deleteRecordByMask", e)
                    .then((response) => {
                        if (response === undefined || (response && response.success)) {
                            this.records.data = this.records.data.filter(x => x.mask !== e);
                            //this.$toast.add({ severity: 'success', summary: 'Additional Fee', detail: "The record was deleted successfully!", life: 3000 });
                            this.onCancel();
                            this.activity.refresh = true;
                            this.$nextTick(() => { this.activity.refresh = false });

                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
            onConfirmEdit(e) {
                let index = (this.dataSource ?? []).findIndex(x => x.mask === e);
                var model = this.$filters.init(this.model);
                if (index > 0) {
                    this.$store.dispatch("invoiceadditionalfee/updateRecord", model).then((response) => {
                        if (response.success) {
                            //this.$toast.add({ severity: 'success', summary: 'Additional Fees', detail: "The record was updated successfully!", life: 3000 });
                            this.dialog.edit = false;
                            this.records[index] = model;
                            this.onCancel();
                            this.activity.refresh = true;
                            this.$nextTick(() => { this.activity.refresh = false });
                            this.onRefresh();
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: response.errors[0]?.message, life: 3000 });
                        }

                    })
                        .catch(ex => {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        });
                }
                else {
                    this.$store.dispatch("invoiceadditionalfee/addRecord", model).then((response) => {
                        if (response.success) {
                            var data = this.$filters.init(response.result)
                            this.$store.dispatch("invoiceadditionalfee/getRecord", data.mask).then((response2) => {
                                if (response2.success) {
                                    //this.$toast.add({ severity: 'success', summary: 'Rate Card Product', detail: "The record was added successfully!", life: 3000 });
                                    this.onCancel();
                                    this.activity.refresh = true;
                                    this.$nextTick(() => { this.activity.refresh = false });
                                    this.onRefresh();
                                }
                                else if ((response2.errors ?? []).length > 0) {
                                    this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: response2.errors[0]?.message, life: 3000 });
                                }
                            })
                                .catch(ex => {
                                    this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                                });
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    })
                        .catch(ex => {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        });
                }
            },
            onChangePage(e) {
                this.page.current = e.page;
                this.page.first = e.first;
                this.page.size = e.rows;
                this.onRefresh();
            },
            onRefresh() {
                this.activity.loading = true;
                this.records = { data: [], total: 0 };
                this.getSource().then((response) => {
                    this.records = response;
                    this.$nextTick(() => { this.activity.loading = false; });
                });
            },
            // Row Editting Methods
            //onEdit(e) { this.editingRows.push(e); },
            onRowEdit() {
                setTimeout(() => {
                    const kbButtons = document.getElementsByTagName("button");
                    kbButtons.forEach(elem => {
                        elem.setAttribute('tabindex', '-1')
                    });
                }, 100);
            },
            onRowSave(e) {
                let index = e.index;
                let model = e.data;
                this.$store.dispatch("invoiceadditionalfee/updateRecord", model).then((response) => {
                    if (response.success) {
                        //this.$toast.add({ severity: 'success', summary: `Additonal Fees Updated`, detail: "The record was updated successfully!", life: 3000 });
                        this.source[index] = this.$filters.init(response.result);
                        this.records.data[index] = this.$filters.init(response.result);
                    }
                    else if ((response.errors ?? []).length > 0) {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: response.errors[0]?.message, life: 3000 });
                    }
                })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
            async getSource() {
                this.activity.loading = true;
                let dataSource = { total: 0, data: [] };
                await this.$store.dispatch("invoiceadditionalfee/getURL", { url: `/services/facility/invoiceadditionalfee?foreignkeyid=` + this.formData?.id })
                    .then((response) => {
                        dataSource = { data: response.result, total: response.result.length };
                        this.source = response.result;
                        dataSource = { total: (this.source ?? []).length, data: [...(this.source ?? [])]?.slice(this.page.first) };
                        this.activity.loading = false;
                    })
                    .catch(() => {
                        this.source = [];
                        dataSource = { total: 0, data: [] }
                        this.activity.loading = false;
                    });
                return dataSource;
            }
        },
        async created() {
            this.loading = false;
            this.getSource().then((response) => { this.records = response; })
        },
        async mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            this.loading = false;
           
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
</style>