<template>
    <div class="p-fluid p-grid">
        <div class="p-col-12 p-md-12">
            <div class="p-grid">
                <div class="p-col-12 p-md-4">
                    <form-input id="code" :validator="v$" :value="model.code" :required="true" @is-sync="model.code = $event.value" :maxLength="20" v-code v-focus />
                    <form-input id="name" :validator="v$" :value="model.name" :required="true" @is-sync="model.name = $event.value" :maxLength="50" />
                    <form-lookup id="contractCountry" :value="model.contractCountry?.id" label="Contract Country" source="country" sourceType="enums" :required="true" :v$="v$" @is-sync="model.contractCountry = $event.record"></form-lookup>
                </div>
                <div class="p-col-12 p-md-4">
                    <form-lookup id="providerID" :value="model.provider?.id" label="Provider" :source="$vm.$providers" :required="true" sourceType="array" :v$="v$" @is-sync="model.provider = $event.record"></form-lookup>
                    <form-lookup id="providerCarrierID" :disabled="(dsCarriers ?? []).length === 0" :value="model.providerCarrier?.id" label="Carrier" :source="dsCarriers" :required="true" :v$="v$" @is-sync="model.providerCarrier = $event.record"></form-lookup>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import { useVuelidate } from '@vuelidate/core';
    export default {
        name: 'StepZoneDetail',
        emits: ["next-page"],
        setup: () => ({ v$: useVuelidate() }),
        props: {
            id: { type: String },
            zoneID: { type: Number },
            formData: { type: Object, default: null }
        },
        data() {
            return {
                credentialFields: [],
                model: {
                    code: null,
                    name: null,
                    contractCountry: { id: 0 },
                    provider: { id: 1 },
                    providerCarrier: { id: 0 }
                },
                credential: {},
                activity: { loading: false }
            }
        },
        watch: {
            'data': { handler() { this.model = this.data; }, deep: true },
            model: { handler() { this.$emit("is-sync", { model: this.$filters.init(this.model) }) }, deep: true }
        },
        //validations() {
        //    return {
        //        model: {
        //            accountType: { required, $autoDirty: true },
        //            tradeType: { $autoDirty: true },
        //            code: { required, minLength: minLength(1), maxLength: maxLength(20), $autoDirty: true },
        //            name: { required, maxLength: maxLength(50), $autoDirty: true },
        //            serviceProviderID: { required, $autoDirty: true },
        //            integrationID: { required, $autoDirty: true },
        //            contractCountryID: { required, $autoDirty: true },
        //        }
        //    }
        //},
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            environment() { return this.$config.common.$environment().filter(x => x.id == this.user?.environmentID ?? this.$vm.$appSession?.environment?.id ?? this.sessionKeys.environment)[0]; },
            sessionKeys() { return this.$store.getters["auth/session"]; },
            nextIcon() {
                if (this.activity.loading)
                    return "pi pi-spin pi-spinner";
                return "pi pi-angle-right";
            },
            nextLabel() {
                if (this.activity.loading)
                    return "Processing...";
                return "Next";
            },
            nextPosition() {
                if (this.activity.loading)
                    return "left";
                return "right";
            },
            blockNext() {
                let block = false;
                if (this.model.code === null) return true;
                if (this.model.name === null) return true;
                if (this.model.provider === null || this.model.provider.id === 0) return true;
                if (this.model.contractCountry === null || this.model.contractCountry.id === 0) return true;
                return block;
            }
        },
        methods: {
            onSync(event) {
                this.credentialFields = event.fields;
                for (let field in event.model)
                    this.model[field] = event.model[field];
            },
            nextPage() {
                this.activity.loading = true;
                this.$emit('next-page', { formData: this.model, pageIndex: 0 });
                this.activity.loading = false;
            }
        },
        created() {
            if (this.model.contractCountry.id === 0)
                this.model.contractCountry.id = this.environment?.countryID ?? this.$config.constant.location[this.$config.config.local].id;
        }
    }
</script>
