<template>
    <div>
        TEST
    </div>
</template>
<!--<template>
    <form-record :model="model" :modelState="v$" :validation="v$"  @is-reset="resetRecord" :viewOnly="model.disabled">
        <div class="p-grid">
            <div class="p-col-12 p-md-6 p-lg-4">
                <h4 style="margin-bottom: 2rem;">BASIC INFO</h4>
                <form-lookup id="Customer" v-model="model.customerID" :source="accessScope.customers" :required="true" :disabled="true" disabledType="object" />
                <basicinfo-form v-model="model.basicInfo"
                                v-model:modelInvalid="validation.basicInfo" />

            </div>
            <div class="p-col-12 p-md-6 p-lg-4">
                <h4 style="margin-bottom: 2rem;">PLATFORM</h4>
                <credential-form v-model="model.credentials"
                                 v-model:modelPlatform="platform"
                                 v-model:modelInvalid="validation.credential"
                                 v-model:modelPayload="payload" 
                                 :parentID="model.id"
                                 :disabled="model.basicInfo.status === 200" />
            </div>
            <div class="p-col-12 p-md-6 p-lg-4">
                <instruction-form v-model="model.shopInfo"
                                  v-model:modelPlatform="platform" />
            </div>
        </div>

        <pv-tab style="margin: 0 -1rem;">
            <pv-tabpanel>
                <template #header>
                    <div class="p-d-flex p-ai-center gap-2">
                        <i class="fa fa-bars fa-fw mx-2"></i>
                        <span style="font-weight: 700; white-space: nowrap; margin-left: 0.5rem;">Defaults and Locations<i v-if="validation.defaults || validation.notification" class="ml-2 fas fa-triangle-exclamation fa-fw" style="margin-left: 0.5rem; color: var(--text-danger)"></i></span>
                    </div>
                </template>
                <div class="p-grid" style="margin-top: 1rem; padding: 0 1rem;">
                    <div class="p-col-12 p-md-6 p-lg-4">
                        <defaults-form :customerID="model.customerID"
                                       v-model="model.defaults"
                                       v-model:modelAccessScope="accessScope"
                                       v-model:modelInvalid="validation.defaults"
                                       :oneColumn="true" />
                    </div>
                    <div class="p-col-12 p-md-6 p-lg-4">
                        <notification-form v-model="model.notification"
                                           v-model:modelInvalid="validation.notification"/>
                    </div>
                    <div class="p-col-12 p-md-6 p-lg-4">
                        <location-form v-model="model.locations"
                                       v-model:modelPlatform="platform"
                                       v-model:modelAccessScope="accessScope"
                                       :customerID="model.customerID" />
                    </div>
                </div>
            </pv-tabpanel>
            <pv-tabpanel>
                <template #header>
                    <div class="p-d-flex p-ai-center gap-2">
                        <i class="fa fa-cogs fa-fw mx-2"></i>
                        <span style="font-weight: 700; white-space: nowrap; margin-left: 0.5rem;">Order Automation</span>
                    </div>
                </template>
                <div style="margin-top: 1.5rem; padding: 0 1rem;">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-6 p-xl-4">
                            <orderautomation-form v-model="model.orderAutomation" />
                        </div>
                        <div class="p-col-12 p-md-6 p-xl-4">
                        </div>
                    </div>
                </div>
            </pv-tabpanel>
            <pv-tabpanel>
                <template #header>
                    <div class="p-d-flex p-ai-center gap-2">
                        <i class="fa fa-cogs fa-fw mx-2"></i>
                        <span style="font-weight: 700; white-space: nowrap; margin-left: 0.5rem;">Product Automation</span>
                    </div>
                </template>
                <div style="margin-top: 1.5rem; padding: 0 1rem;">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-6 p-lg-4">
                            <productautomation-form v-model="model.productAutomation" />
                        </div>
                        <div class="p-col-12 p-md-6 p-xl-4">
                        </div>
                    </div>
                </div>
            </pv-tabpanel>
        </pv-tab>
    </form-record>
</template>
<script>
    import credentialForm from '@/components/facility/ecommerce/credential-form.vue';
    import instructionForm from '@/components/facility/ecommerce/instruction-form.vue';
    import locationForm from '@/components/facility/ecommerce/location-form.vue';
    import basicInfo from '@/components/facility/ecommerce/basicinfo-form.vue';
    import defaultForm from '@/components/facility/ecommerce/defaults-form.vue';
    import notificationForm from '@/components/facility/ecommerce/notification-form.vue';
    import orderAutomationForm from '@/components/facility/ecommerce/orderautomation-form.vue';
    import productAutomationForm from '@/components/facility/ecommerce/productautomation-form.vue';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    import { mapGetters } from 'vuex';
    export default {
        name: "StoreEditForm",
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate({ $scope: true }) }),
        components: {
            'credential-form': credentialForm,
            'instruction-form': instructionForm,
            'location-form': locationForm,
            'basicinfo-form': basicInfo,
            'defaults-form': defaultForm,
            'orderautomation-form': orderAutomationForm,
            'productautomation-form': productAutomationForm,
            'notification-form': notificationForm
        },
        data() {
            return {
                payload: null,
                platform: null,
                validation: {
                    credential: false,
                    basicInfo: false,
                    notification: false,
                    defaults: false,
                }
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            common() { return this.$config.common; }
        }, 
        watch: {
            'model.id': {
                handler() {
                    this.getSources();
                }
            }
        },
        methods: {
            getSources() {
                this.$store.dispatch("store/getURL", { url: `/services/facility/storelocation/bystore/${this.model.mask}`.replaceAll("//", "/") })
                    .then((response) => {
                        this.model.locations= response?.result ?? [];
                    });
                this.$store.dispatch("store/getURL", { url: `/services/facility/storewebhook/bystore/${this.model.mask}`.replaceAll("//", "/") })
                    .then((response) => {
                        this.model.webhooks = response?.result ?? [];
                    });
            }
        },
        mounted() {
            if (this.model?.mask)
                this.getSources();
        },
        created() {
            this.accessScope = this.common.$accessScope(this.user.mask);
        }
    }
</script>
<style>
    .is-customized.p-datatable.p-component.p-datatable-scrollable tr > td:not(:first-child),
    .is-customized.p-datatable.p-component.p-datatable-scrollable tr > td:not(:first-child) {
        width: 100% !important;
    }

    .is-customized.p-datatable.p-component.p-datatable-scrollable tr > td:first-child,
    .is-customized.p-datatable.p-component.p-datatable-scrollable tr > th:first-child {
        max-width: 2rem !important;
    }
    .is-customized.p-datatable.p-component.p-datatable-scrollable tr.p-datatable-emptymessage > td {
        max-width: 100% !important;
    }
    .is-customized .p-datatable.p-component.p-datatable-scrollable tr td:not(:first-child) {
        width: 100% !important;
        flex: none;
    }
    .is-customized .p-datatable.p-component.p-datatable-scrollable tr th:not(:first-child) {
        width: 100% !important;
        flex: none;
    }
</style>-->