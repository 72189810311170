<template>
    <cosmos-grid id="ConsignmentThreadList" sourceType="url" :source="`/services/tms/consignmentthread?foreignkeyid=${this.formData?.id ?? 0}`" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showExport="false" :showOperation="false" :showAdd="false" :showCheckbox="false">
        <pv-grid-column field="CreateDate" header="Action Date" style="min-width:3.5rem;max-width: 5rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{$filters.toDateFullTimeDisplay(slotProps.data?.createDate)}}
            </template>
        </pv-grid-column>
        <pv-grid-column field="CreateDate" header="Action By" style="min-width: 3rem; max-width: 5rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em; ">
            <template #body="slotProps">
                {{ slotProps.data?.createdBy }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="details" header="Details" style="min-width: 100%; max-width: 100%; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <span v-html="slotProps.data?.details"></span>
                </div>                
            </template>
        </pv-grid-column>
        <pv-grid-column field="type" header="Activity" style="min-width: 3rem; max-width: 6.5rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                        {{ slotProps.data?.typeText }}
            </template>
        </pv-grid-column>
    </cosmos-grid>
</template>

<script>
    import mixins from '@/assets/lib/cosmos/_js/mixin.js';
    export default {
        name: 'ConsignmentThreadTemplate',
        mixins: [mixins.GRID],
        props: {
            formData: { type: Object },
        },
        data() {
            return {
                type: -1,
                filters: {},
                columns: [],
                deleteDisplay: ["code", "name"],
                searchFields: ["details"],
                forceRefresh: false
              
            }
        },
        computed: {},
        methods: {},
        async created() {
        },
        async mounted() {
        }
    }
</script>