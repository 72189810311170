<template>
    <cosmos-content title="Component Tester" :bg="false">
        <template #body>
            <pv-accordion :activeIndex="-1">
                <pv-accordion-tab>
                    <template #header>
                        <i class="pi pi-bookmark"></i>
                        <div style="margin-left: 10px; font-weight: 700;">COMMON GROUP INPUT: Addresss and Contact</div>
                    </template>
                    <img v-if="files.length > 0" :src="`data:${files[0].contentType};base64, ${files[0].file}`"/>
                    <pv-file mode="basic" name="files" :chooseLabel="(files.length === 0 ? 'Browse...' : 'Replace...')" url="https://cosmos-api.localhost.com/file/upload" :auto="true" accept="image/*" @upload="myUpload" @uploader="myUploader"/>
                </pv-accordion-tab>
                <pv-accordion-tab>
                    <template #header>
                        <i class="pi pi-bookmark"></i>
                        <div style="margin-left: 10px; font-weight: 700;">COMMON TEXT INPUT: Text Box, Password, Text Area, Editor, and Color Picker</div>
                    </template>
                    <component-input></component-input>
                </pv-accordion-tab>
                <pv-accordion-tab>
                    <template #header>
                        <i class="pi pi-bookmark"></i>
                        <div style="margin-left: 10px; font-weight: 700;">COMMON NUMERIC INPUT: Integer, Decimal, and Money</div>
                    </template>
                    <component-numeric></component-numeric>
                </pv-accordion-tab>
                <pv-accordion-tab>
                    <template #header>
                        <i class="pi pi-bookmark"></i>
                        <div style="margin-left: 10px; font-weight: 700;">COMMON DATE INPUT: Date Time, Date, and TimeSpan</div>
                    </template>
                    <component-datetime></component-datetime>
                </pv-accordion-tab>
                <pv-accordion-tab>
                    <template #header>
                        <i class="pi pi-bookmark"></i>
                        <div style="margin-left: 10px; font-weight: 700;">COMMON BOOLEAN INPUT: Toggle, Check Box, Switch, and Select Button</div>
                    </template>
                    <component-boolean></component-boolean>
                </pv-accordion-tab>
                <pv-accordion-tab>
                    <template #header>
                        <i class="pi pi-bookmark"></i>
                        <div style="margin-left: 10px; font-weight: 700;">COMMON LOOKUP INPUT: Dropdown, AutoComplete, MultiSelect, Flag, Enum, Currency and Country</div>
                    </template>
                    <component-lookup></component-lookup>
                </pv-accordion-tab>
                <pv-accordion-tab>
                    <template #header>
                        <i class="pi pi-bookmark"></i>
                        <div style="margin-left: 10px; font-weight: 700;">COMMON GROUP INPUT: Addresss and Contact</div>
                    </template>
                    <component-custom></component-custom>
                </pv-accordion-tab>
            </pv-accordion>
        </template>
    </cosmos-content>
</template>

<script>
    import FormInput from '@/components/home/component-input.vue';
    import FormNumeric from '@/components/home/component-numeric.vue';
    import FormDateTime from '@/components/home/component-datetime.vue';
    import FormBoolean from '@/components/home/component-boolean.vue';
    import FormLookup from '@/components/home/component-lookup.vue';
    import FormSpecial from '@/components/home/component-custom.vue';
    export default {
        name: 'CustomComponents',
        components: {
            'component-input': FormInput,
            'component-numeric': FormNumeric,
            'component-datetime': FormDateTime,
            'component-boolean': FormBoolean,
            'component-lookup': FormLookup,
            'component-custom': FormSpecial,
        },
        data() {
            return {
                files: []

            }
        },
        methods: {
            myUpload(event) {
                this.files = JSON.parse('[' + event.xhr.response + ']');
            },
            myUploader(event) {
                console.log(event);
                //event.files == files to upload
            }
        },
        async created() {

            //await this.$axios({
            //    method: 'GET',
            //    url: "https://cosmos-api.localhost.com/api/polaris/1.0/consignment/quote",
            //    data: {
            //        ServiceHeader: {
            //            Username: "api",
            //            Password: "Y29uZmlkZW50aWFs",
            //            ClientID: "KDM8Y6NECKM1E3O7KVJA",
            //            ClientSecret: "BE128A49D753EE0882F74F56000001DC"
            //        },
            //        Request: {
            //        CustomerCode: "ACME",
            //        Order: {
            //            ShipFrom: {
            //                City: "Egham",
            //                State: "Surrey",
            //                PostalCode: "TW20 8RZ",
            //                Country: "GB"
            //            },
            //            ShipTo: {
            //                City: "Hamburg Neustadt",
            //                State: "Hamburg",
            //                PostalCode: "20354",
            //                Country: "DE"
            //            },
            //            IsDocument: false,
            //            CollectionDate: "2021-10-14"
            //        },
            //        Packages: [
            //            {
            //                Weight: {
            //                    GrossWeight: 12.00
            //                },
            //                WeightUnit: "KG",
            //                Dimension: {
            //                    Length: 10.000,
            //                    Height: 10.000,
            //                    Width: 10.000
            //                },
            //                LengthUnit: "CM",
            //                CurrencyCode: "GBP",
            //                Declaration: {
            //                    Declared: 10.00
            //                }
            //            }
            //        ],
            //        Carrier: {
            //            CarrierCode: "UPS",
            //            ProductCode: "65"
            //        }
            //    }
            //    }
            //}).
            //    then((response) => console.log(response));

            //await this.$axios({
            //    method: 'DELETE',
            //    url: "https://cosmos-api.localhost.com/api/polaris/1.0/consignment/21101200000000001000/deallocate",
            //    data: {
            //        ServiceHeader: {
            //            Username: "api",
            //            Password: "Y29uZmlkZW50aWFs",
            //            ClientID: "KDM8Y6NECKM1E3O7KVJA",
            //            ClientSecret: "BE128A49D753EE0882F74F56000001DC"
            //        },
            //        Request: { Reason: "The customer wants to cancel the order." }
            //    }
            //}).
            //    then((response) => console.log(response));
        //    var data = {
        //        ServiceHeader: {
        //            Username: "api",
        //            Password: "Y29uZmlkZW50aWFs",
        //            ClientID: "KDM8Y6NECKM1E3O7KVJA",
        //            ClientSecret: "BE128A49D753EE0882F74F56000001DC"
        //        },
        //        Request: {
        //            CustomerCode: "ACME",
        //            Order: {
        //                ShipFrom: {
        //                    City: "Egham",
        //                    State: "Surrey",
        //                    PostalCode: "TW20 8RZ",
        //                    Country: "GB"
        //                },
        //                ShipTo: {
        //                    City: "Hamburg Neustadt",
        //                    State: "Hamburg",
        //                    PostalCode: "20354",
        //                    Country: "DE"
        //                },
        //                IsDocument: false,
        //                CollectionDate: "2021-10-14"
        //            },
        //            Packages: [
        //                {
        //                    Weight: {
        //                        GrossWeight: 12.00
        //                    },
        //                    WeightUnit: "KG",
        //                    Dimension: {
        //                        Length: 10.000,
        //                        Height: 10.000,
        //                        Width: 10.000
        //                    },
        //                    LengthUnit: "CM",
        //                    CurrencyCode: "GBP",
        //                    Declaration: {
        //                        Declared: 10.00
        //                    }
        //                }
        //            ],
        //            Carrier: {
        //                CarrierCode: "UPS",
        //                ProductCode: "65"
        //            }
        //        }
        //    };
        //    await this.$axios.post("https://cosmos-api.localhost.com/api/polaris/1.0/consignment/quote", data).then((response) => console.log(response)).catch((err) => console.log(err));
        }
    }
</script>

