<template>
    <form-wrapper :title="router.title" :sub-title="router.subtitle">
        <cosmos-grid id="TenantList" sourceType="storename" source="tenant" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showDelete="false" :showAdd="false" :showExport="false" :operationWidth="4">
            <pv-grid-column field="code" header="Details" headerStyle="min-width: 37rem; max-width: 37rem" style="min-width: 37rem; max-width: 37rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
                <template #body="slotProps">
                    <div class="p-d-flex" style="min-width: 35rem; max-width: 35rem; ">
                        <div>
                            <div class="p-d-flex p-jc-between" style="min-width: 35rem;">
                                <div>
                                    <router-link style="font-size: 1rem" :to="{ name: 'tenant-edit', params: { mask: slotProps.data.mask }}">{{slotProps.data.name}}</router-link>
                                </div>
                                <div class="p-as-end"><h5 class="p-code-text">[{{ slotProps.data.code}}]</h5></div>
                            </div>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="fullPortalURL" header="Endpoints" headerStyle="min-width: 35rem; max-width: 35rem" style="min-width: 35rem; max-width: 35rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
                <template #body="slotProps">
                    <div class="p-d-flex p-flex-row" style="min-width: 33rem; ">
                        <div>
                            <div class="p-d-flex p-jc-between" style="min-width: 33rem; padding: 0;">
                                <div>
                                    <i class="pi pi-fw pi-globe p-mr-2" style="font-size: 0.8rem"></i>
                                    <a style="font-size: 1rem" :href="slotProps.data.fullPortalURL" target="_blank">{{slotProps.data.fullPortalURL}}</a>
                                </div>
                                <div class="p-as-end"><h5 class="p-code-text" style="margin-bottom: 0.25rem; text-decoration: none;">[WEB]</h5></div>
                            </div>
                            <div class="p-d-flex p-jc-between" style="min-width: 33rem; padding: 0">
                                <div>
                                    <i class="pi pi-fw pi-sort-alt p-mr-2" style="font-size: 0.8rem; transform: rotate(90deg);"></i>
                                    <a :href="`${slotProps.data.fullAPIURL}/swagger`" target="_blank">{{slotProps.data.fullAPIURL}}</a>
                                </div>
                                <div class="p-as-end"><h5 class="p-code-text" style="margin-bottom: 0.25rem; text-decoration: none;">[API]</h5></div>
                            </div>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="fullPortalURL" header="Modules">
                <template #body="slotProps">
                    <div class="p-d-flex" style="display: block;">
                        <div>
                            <div class="p-d-flex p-jc-between" style="padding: 0;">
                                <div>
                                    <span v-html="`${modules(slotProps)}`"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
        </cosmos-grid>
    </form-wrapper>
</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/mixin.js';
    export default {
        name: 'TenantListTemplate',
        mixins: [mixins.GRID],
        data() {
            return {
                type: -1,
                filter: {},
                columns: [],
                deleteDisplay: ["code", "name"]
            }
        },
        computed: {
            source() {
                return this.$vm.$enums["module"];
            }
        },
        methods: {
            modules(slotProps) {
                return this.source.filter(x => this.$filters.getFlags(slotProps.data.modules).indexOf(x.id) !== -1).map(x => '<div class="p-chip" style="padding: 2px 10px; border-radius: 4px;">' + x.text + '</div>').join(', ');
            }
        }
    }
</script>