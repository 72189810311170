<template>
    <pv-grid :ref="id"
             :id="id"
             :dataKey="'id'"
             :filterDisplay="'menu'"
             :globalFilterFields="['code','name']"
             :filters="filters"
             :rows="pageSize"
             :rowsPerPageOptions="[5,10,20,50,100]"
             :rowHover="true"
             :paginator="pagination"
             :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'"
             :currentPageReportTemplate="'Showing {first} to {last} of {totalRecords} records'"
             :selectionMode="multiSelect ? 'multiple' : 'single'"
             :selection="selectedRecords"
             :csvSeparator="csvSeparator"
             :exportFilename="filename"
             :responsive="'scroll'"
             :scrollable="true"
             :scrollWidth="'flex'"
             :columnResizeMode="'expand'"
             showGridlines
             :value="originalDS.data"
             :totalRecords="originalDS.total"
             :stateStorage="'session'"
             :stateKey="`dt-state-${id}`">
        <template #header>
            <slot name="toolbar">
                <div class="p-d-flex p-jc-between p-ai-center">
                    <span>
                        <pv-button v-if="showAdd" icon="pi pi-fw pi-plus" label="Add New Record" style="margin-right: 5px;"></pv-button>
                        <pv-button v-if="showExport" icon="pi pi-fw pi-file-excel" label="Export To CSV" :disabled="originalDS.total === 0"></pv-button>
                    </span>
                    <span class="p-input-icon-left" style="max-width: 300px; width: 100%">
                        <i class="pi pi-search" />
                        <pv-input v-model="filters['global'].value" placeholder="Keyword Search" style="width: 100%;" />
                    </span>
                </div>
            </slot>
        </template>
        <pv-grid-column field="id" selectionMode="multiple" headerStyle="width: 3em" style="width: 3em;" :frozen="true"></pv-grid-column>
        <slot>
        </slot>
        <pv-grid-column v-for="col of (autoGenerate ? columns : [])" :field="col.field" :header="col.header" :key="col.field" headerStyle="min-width: 30rem; max-width: 30rem" style="min-width: 30rem; max-width: 30rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; display: block;"></pv-grid-column>
        <pv-grid-column v-if="showOperation" field="mask" :headerStyle="`min-width: ${operationWidth}rem;`" :style="`min-width: ${operationWidth}rem; border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;`" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-d-flex" :style="`max-width: ${(operationWidth - 1)}rem;`">
                    <div v-if="showEdit" class="p-col">
                        <pv-button icon="pi pi-pencil" iconPos="right" class="p-button-info" @click="edit(`${slotProps.data.mask}`)" />
                    </div>
                    <div v-if="showDelete" class="p-col">
                        <pv-button :disabled="slotProps.data.recordStatus !== 0 || slotProps.data.id < 1000 " icon="pi pi-trash" iconPos="right" class="p-button-danger" />
                    </div>
                    <slot name="operation" :data="slotProps.data">
                    </slot>
                </div>
            </template>
        </pv-grid-column>
        <template #empty>
            No record found.
        </template>
        <template #loading>
            Loading records from database. Please wait...
        </template>
        <template #paginatorRight>
            <pv-button type="button" icon="pi pi-refresh" class="p-button-rounded p-button-text" @click="onRefresh" />
        </template>
    </pv-grid>
</template>

<script>
    import { FilterMatchMode, FilterOperator } from 'primevue/api';
    export default {
        name: 'GridTemplate',
        props: {
            id: { type: String, required: true, validator: (value) => /^[a-zA-Z0-9_]*$/.test(value) },
            pagination: { type: Boolean, default: true },
            pageSize: { type: Number, default: 10 },
            multiSelect: { type: Boolean, default: true },
            csvSeparator: { type: String, default: ',' },
            exportFilename: { type: String },
            autoGenerate: { type: Boolean, default: true },
            operationWidth: { type: Number, default: 7 },
            showAdd: { type: Boolean, default: true },
            showExport: { type: Boolean, default: true },
            showEdit: { type: Boolean, default: true },
            showDelete: { type: Boolean, default: true },
            showOperation: { type: Boolean, default: true }
        },
        data() {
            return {
                selectedRecords: null,
                filters: {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'representative': { value: null, matchMode: FilterMatchMode.IN },
                    'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                    'balance': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'activity': { value: null, matchMode: FilterMatchMode.BETWEEN },
                    'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
                },
                columns: [
                    { field: 'name', header: 'Name' },
                    { field: 'fullPortalURL', header: 'URL' },
                    { field: 'fullAPIURL', header: 'API' },
                    { field: 'dbName', header: 'DB Name' },
                    { field: 'requireSSL', header: 'Require SSL' },
                    { field: 'modules', header: 'Modules' }],
            }
        },
        computed: {
            originalDS() {
                return this.$store.getters["tenant/ds"]; },
            filename() {
                let fileName = this.id;
                if (this.exportFilename)
                    fileName = this.exportFilename;
                return fileName + '_' + this.$filters.toDateFileName(new Date()) + ".csv";
            }
        },
        methods: {
            edit(mask) {
                this.$router.push({ name: `tenant-edit`, params: { mask: mask } });
            },
            async getAll() {
                return await this.$store.dispatch("tenant/getAll");
            },
            onRefresh() {
                this.loading = true;
                this.getAll();
                this.loading = false;
            },
            onExport() {
                this.$refs[this.id].exportCSV();
            }
        },
        created() {
            this.getAll();
        }

    }
</script>
