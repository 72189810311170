<template>
    <form-record :model="model" :modelState="v$" @is-reset="resetRecord">
        <div class="p-d-flex p-grid">
            <div class="p-col-12">
                <collection-view :formData="model"></collection-view>
            </div>
        </div>
    </form-record>
</template>
<script>

    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    import collectionview from '@/components/transaction/tms/collection/view-form.vue';
    export default {
        name: 'CollectionRecord',
        setup: () => ({ v$: useVuelidate() }),
        mixins: [mixins.RECORD],
        components: {
            'collection-view': collectionview
        }
    }
</script>

<style scoped>
    span.custom-marker {
        display: flex;
        border-radius: 50%;
        height: 2rem;
        width: 2rem;
        z-index: 1;
        justify-content: center;
        -webkit-box-align: center;
        box-shadow: 1px 1px #888888;
    }

        span.custom-marker > i {
            float: right;
        }

    .p-timeline-left .p-timeline-event-opposite {
        text-align: left;
    }

    .responsive-iframe {
        width: 100%;
        height: 450px;
    }

    .picklist-item {
        display: flex;
        align-items: center;
        padding: .2rem;
        width: 100%;
    }

    .picklist-list-detail {
        flex: 1 1 0;
    }

    .picklist-list-action {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .custom-skeleton {
        border: 1px solid var(--surface-d);
        border-radius: 4px;
    }

        .custom-skeleton ul {
            list-style: none;
        }

            .custom-skeleton ul > li {
                padding: 0.75rem;
            }

    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>
<style>
    .p-orderlist-controls,
    .p-picklist-buttons.p-picklist-source-controls,
    .p-picklist-buttons.p-picklist-source-controls, .p-picklist-buttons.p-picklist-target-controls {
        display: none !important;
    }
</style>