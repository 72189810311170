<template>
    <div class="card">
        <div class="p-grid">
            <div class="p-col-12 p-md-3">
                <h6>Auto Complete Supported Format</h6>
                <form-lookup id="CurrencyAutoComplete" label="form-input type='autocomplete' source='currency'" type="autocomplete" source="currency" :value="model.transaction.currency" @is-sync="model.transaction.currency = $event.value; model.transaction.currencyName = $event.text; model.transaction.currencyRecord = $event.record" />
                <form-lookup id="CountryAutoComplete" label="form-input type='autocomplete' source='country'" type="autocomplete" source="country" :value="model.transaction.country" @is-sync="model.transaction.country = $event.value; model.transaction.countryName = $event.text; model.transaction.countryRecord = $event.record" />
                <form-lookup id="ArrayAutoComplete" label="form-input type='autocomplete' :source='sources.array'" type="autocomplete" :source="sources.array" :value="model.transaction.array" @is-sync="model.transaction.array = $event.value; model.transaction.arrayName = $event.text; model.transaction.arrayRecord = $event.record" />
                <form-lookup id="StoreAutoComplete" label="form-input type='autocomplete' source='user' sourceType='store'" type="autocomplete" source="user" sourceType="store" :value="model.transaction.store" @is-sync="model.transaction.store = $event.value; model.transaction.storeName = $event.text; model.transaction.storeRecord = $event.record" />
                <form-lookup id="URLAutoComplete" label="form-input type='autocomplete' source='user' sourceType='url'" type="autocomplete" source="/services/settings/company?type=2" sourceType="url" :value="model.transaction.url" @is-sync="model.transaction.url = $event.value; model.transaction.urlName = $event.text; model.transaction.urlRecord = $event.record" />
            </div>
            <div class="p-col-12 p-md-3">
                <h6>Auto Complete Disabled State</h6>
                <form-lookup :disabled="true" id="CurrencyAutoComplete" label="form-input type='autocomplete' source='currency'" type="autocomplete" source="currency" :value="model.transaction.currency" @is-sync="model.transaction.currency = $event.value; model.transaction.currencyName = $event.text; model.transaction.currencyRecord = $event.record" />
                <form-lookup :disabled="true" id="CountryAutoComplete" label="form-input type='autocomplete' source='country'" type="autocomplete" source="country" :value="model.transaction.country" @is-sync="model.transaction.country = $event.value; model.transaction.countryName = $event.text; model.transaction.countryRecord = $event.record" />
                <form-lookup :disabled="true" id="ArrayAutoComplete" label="form-input type='autocomplete' :source='sources.array'" type="autocomplete" :source="sources.array" :value="model.transaction.array" @is-sync="model.transaction.array = $event.value; model.transaction.arrayName = $event.text; model.transaction.arrayRecord = $event.record" />
                <form-lookup :disabled="true" id="StoreAutoComplete" label="form-input type='autocomplete' source='user' sourceType='store'" type="autocomplete" source="user" sourceType="store" :value="model.transaction.store" @is-sync="model.transaction.store = $event.value; model.transaction.storeName = $event.text; model.transaction.storeRecord = $event.record" />
                <form-lookup :disabled="true" id="URLAutoComplete" label="form-input type='autocomplete' source='user' sourceType='url'" type="autocomplete" source="/services/settings/company?type=2" sourceType="url" :value="model.transaction.url" @is-sync="model.transaction.url = $event.value; model.transaction.urlName = $event.text; model.transaction.urlRecord = $event.record" />
            </div>
            <div class="p-col-12 p-md-3">
                <h6>Dropdown List Supported Format</h6>
                <form-lookup id="CurrencyDropDown" label="form-input source='currency'" source="currency" :value="model.transaction.currency" @is-sync="model.transaction.currency = $event.value; model.transaction.currencyName = $event.text; model.transaction.currencyRecord = $event.record" />
                <form-lookup id="CountryDropDown" label="form-input source='country'" source="country" :value="model.transaction.country" @is-sync="model.transaction.country = $event.value; model.transaction.countryName = $event.text; model.transaction.countryRecord = $event.record" />
                <form-lookup id="ArrayDropDown" label="form-input :source='sources.array'" :source="sources.array" :value="model.transaction.array" @is-sync="model.transaction.array = $event.value; model.transaction.arrayName = $event.text; model.transaction.arrayRecord = $event.record" />
                <form-lookup id="StoreDropDown" label="form-input source='user' sourceType='store'" source="user" sourceType="store" :value="model.transaction.store" @is-sync="model.transaction.store = $event.value; model.transaction.storeName = $event.text; model.transaction.storeRecord = $event.record" />
                <form-lookup id="URLDropDown" label="form-input type='autocomplete' source='user' sourceType='url'" source="/services/settings/company?type=2" sourceType="url" :value="model.transaction.url" @is-sync="model.transaction.url = $event.value; model.transaction.urlName = $event.text; model.transaction.urlRecord = $event.record"  />
                <form-lookup id="EnumDropDown" type="enum" source="consignmentstatus" :value="model.transaction.enum" @is-sync="model.transaction.enum = $event.value; model.transaction.enumName = $event.text; model.transaction.enumRecord = $event.record" />
                <form-lookup id="FlagDropDown" type="flag" source="tradetype" :value="model.transaction.flag" @is-sync="model.transaction.flag = $event.value; model.transaction.flagName = $event.text; model.transaction.flagRecord = $event.record" />
            </div>
            <div class="p-col-12 p-md-3">
                <h6>Dropdown List Disabled State</h6>
                <form-lookup :disabled="true" id="CurrencyAutoComplete" label="form-input source='currency'" source="currency" :value="model.transaction.currency" @is-sync="model.transaction.currency = $event.value; model.transaction.currencyName = $event.text; model.transaction.currencyRecord = $event.record" />
                <form-lookup :disabled="true" id="CountryAutoComplete" label="form-input source='country'" source="country" :value="model.transaction.country" @is-sync="model.transaction.country = $event.value; model.transaction.countryName = $event.text; model.transaction.countryRecord = $event.record" />
                <form-lookup :disabled="true" id="ArrayAutoComplete" label="form-input :source='sources.array'" :source="sources.array" :value="model.transaction.array" @is-sync="model.transaction.array = $event.value; model.transaction.arrayName = $event.text; model.transaction.arrayRecord = $event.record" />
                <form-lookup :disabled="true" id="StoreAutoComplete" label="form-input source='user' sourceType='store'" source="user" sourceType="store" :value="model.transaction.store" @is-sync="model.transaction.store = $event.value; model.transaction.storeName = $event.text; model.transaction.storeRecord = $event.record" />
                <form-lookup :disabled="true" id="URLDropDown" label="form-input source='/services/settings/company?type=2' sourceType='url'" source="/services/settings/company?type=2" sourceType="url" :value="model.transaction.url" @is-sync="model.transaction.url = $event.value; model.transaction.urlName = $event.text; model.transaction.urlRecord = $event.record" />
                <form-lookup :disabled="true" id="EnumDropDown" type="enum" source="consignmentstatus" :value="model.transaction.enum" @is-sync="model.transaction.enum = $event.value; model.transaction.enumName = $event.text; model.transaction.enumRecord = $event.record" />
                <form-lookup :disabled="true" id="FlagDropDown" type="flag" source="tradetype" :value="model.transaction.flag" @is-sync="model.transaction.flag = $event.value; model.transaction.flagName = $event.text; model.transaction.flagRecord = $event.record" />
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'FormLookupComponents',
        data() {
            return {
                sources: {
                    array: [
                        { text: "Tiny", id: 10 },
                        { text: "Small", id: 12 },
                        { text: "Normal", id: 14 },
                        { text: "Large", id: 16 },
                        { text: "Huge", id: 18 },
                    ]
                },
                model: {
                    
                    blank: {
                        country: null,
                        countryName: null,
                        countryRecord: null,
                        decimal: null,
                        currency: null
                    },
                    transaction: {
                        currency: 3,
                        country: 238,
                        array: 14,
                        enum: 100,
                        flag: 17,
                        store: -3,
                        url: -1
                    }

                }
            }
        },
        methods: {
            tester(event) {
                this.model.transaction.array = event.value;
                this.model.transaction.arrayName = event.text;
                this.model.transaction.arrayRecord = event.record;
                //model.transaction.country = $event.value; model.transaction.countryName = $event.text; model.transaction.countryRecord = $event.record"
            }
        }
    }
</script>

