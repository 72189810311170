<template>
    <div style="width: calc(100% + 2rem); padding: 0; margin: -2rem -1rem; margin-bottom:-1rem;">
        <pv-accordion :activeIndex="[0]" :multiple="true">
            <pv-accordion-tab>
                <template #header>
                    <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                        <div class="p-col-fixed p-as-start" style="width: 1.5rem"><i class="fa-solid fa-memo-circle-info"></i></div>
                        <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" title="SUMMARY DETAILS">
                            <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; <span v-html="`SUMMARY: ${$vm.$enums['consignmentstatustype'].filter(x => x.id === model?.status)[0]?.text?.toUpperCase()}  COLLECTION ${model?.code?.toUpperCase()}`"></span></div>
                        </div>
                    </div>
                </template>
                <div style="width: calc(100% + 2rem); padding: 0; margin: -2rem -0.75rem; margin-bottom: -2rem;">
                    <div class="p-grid p-col-12">

                        <div class="p-col-12 p-md-4">
                            <h4>PICKUP INFO<i :class="`flag flag-${$vm.$countries.filter((x) => x.id == model?.pickup_CountryID)[0]?.code.toLowerCase()}`" style="margin: -1rem 0.5rem"></i> </h4>
                            <div class="card" :style="`background: var(--surface-a); min-height: 34rem; height: calc(100% * 1.75rem);`">
                                <form-contact :disabled="true" id="pickupContact" :value="contact" :useRegistrationNumber="true" disabledView="perline" />
                                <form-address id="pickupAddress" :value="pickUpAddress" :useCompany="true" :disabled="true" disabledView="perline" />
                                <table style="width: calc(100% + 2rem); padding: 0.25rem 0.5rem; margin: 2rem -1rem; margin-bottom: -1rem;" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <th colspan="3" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) ">
                                                <i :class="`fa-solid fa-clipboard-list-check`" /> SCHEDULE 
                                            </th>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Pickup Date</th>
                                            <td style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatDateUTCFormat(model?.pickup_Date,'DD/MMM/YYYY')}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Cutoff Time</th>
                                            <td style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatDateUTCFormat(model?.pickup_CutOffTime, 'HH:mm')}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Ready Time</th>
                                            <td style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatDateUTCFormat(model?.pickup_ReadyTime, 'HH:mm')}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Collection Time</th>
                                            <td style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatDateUTCFormat(model?.pickup_CollectionTime,'HH:mm')}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Closing Time</th>
                                            <td style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatDateUTCFormat(model?.pickup_ClosingTime, 'HH:mm')}`"></div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4">
                            <h4>AFTER HOUR INFO <i :class="`flag flag-${$vm.$countries.filter((x) => x.id == model?.afterHourLocation_CountryID)[0]?.code.toLowerCase()}`" style="margin-top: -0.15rem"></i> </h4>
                            <div class="card" :style="`background: var(--surface-a); min-height: 34rem; height: calc(100% * 1.75rem);` ">
                                <form-contact :disabled="true" id="afterHourContact" :value="contact" :useRegistrationNumber="true" disabledView="perline" />
                                <form-address id="afterHourAddress" :value="afterHourAddress" :useCompany="true" :disabled="true" disabledView="perline" />
                                <table style="width: calc(100% + 2rem); padding: 0.25rem 0.5rem; margin: 2rem -1rem; margin-bottom: -1rem;" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <th colspan="3" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) ">
                                                <i :class="`fa-solid fa-clipboard-list-check`" /> SCHEDULE 
                                            </th>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">After Close Time</th>
                                            <td style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatDateUTCFormat(model?.pickup_AfterCloseTime, 'HH:mm')}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Pick Up Point</th>
                                            <td style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.afterHourLocation_PickupPoint ?? ''}`"></div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4">
                            <h4>COLLECTION INFO</h4>
                            <div class="card" :style="`background:var(--surface-a); min-height: 34rem; `">
                                <table style="width: calc(100% + 2rem); padding: 0.25rem 0.5rem; margin: -2rem -1rem; margin-bottom: -1rem;" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <th colspan="3" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) "><h4 style="margin: -0.25rem -0.5rem">CARRIER INFO</h4></th>
                                        </tr>
                                        <tr>
                                            <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">
                                                <div class="p-d-flex p-col-12">
                                                    <img style="width: 30%; max-height:4rem; object-fit: contain; object-position: center;" :src="`/_assets/_integration/${$vm.$providers.filter((x) => x.id == model?.serviceProviderID)[0]?.mask}.png`" onerror="this.onerror=null; this.src = '/_assets/img/no-logo.png'" />
                                                    <div class="p-col" style="text-align:right">
                                                        <h3 style="word-break: break-word; margin: -0.25rem -0.5rem">{{ model?.name }}</h3>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) ">
                                                <h4 style="margin: -0.25rem -0.5rem;">
                                                    {{model?.customerName}}
                                                </h4>
                                            </th>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Transaction ID</th>
                                            <td style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.code}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Action Date</th>
                                            <td style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatDateUTCFormat(model?.milestone_ManifestDate,'DD/MMM/YYYY')}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Print Date</th>
                                            <td style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatDateUTCFormat(model?.milestone_DocumentPrintDate,'DD/MMM/YYYY')}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Dispatch Date</th>
                                            <td style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatDateUTCFormat(model?.milestone_DispatchDate,'DD/MMM/YYYY')}`"></div></td>
                                        </tr>
                                        <tr>
                                            <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) "><h4 style="margin: -0.25rem -0.5rem">DOCUMENT</h4></th>
                                        </tr>
                                        <tr>
                                            <th colspan="3" class="p-col" style="text-align: left; padding: 0.25rem 0.5rem;">
                                                <pv-splitbutton v-if="(buttonItems?.length ?? 0) > 0" label="Document" icon="pi pi-print" :model="buttonItems" style=" margin-bottom: 5px; " @click="buttonItems[0]?.command()" ></pv-splitbutton>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </pv-accordion-tab>
            <pv-accordion-tab>
                <template #header>
                    <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                        <div class="p-col-fixed p-as-start" style="width: 1.5rem"><i class="fa-duotone fa-truck-fast"></i></div>
                        <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" title="CONSIGNMENTS">
                            <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; <span v-html="`CONSIGNMENTS`"></span></div>
                        </div>
                    </div>
                </template>
                <div style="width: calc(100% + 2.5rem); padding: 0; margin: -2rem -1.3rem; margin-bottom: -1rem;">
                    <consignment-grid v-if="model?.id" :readOnly="true" :foreignkeyid="model?.id"></consignment-grid>
                </div>
            </pv-accordion-tab>
        </pv-accordion>
    </div>
    <print-form :formData="model" :showDialog="dialog.show" module="collection" :documentType="dialog.type" @is-confirm="{this.dialog.show = false; this.dialog.type = 'summary';}" @is-cancel="{this.dialog.show = false; this.dialog.type = 'summary';}"></print-form>
</template>
<script>
    import printform from '@/components/transaction/tms/consignment/record/print-form.vue';
    import consignmentGrid from '@/components/transaction/tms/consignment/record/grid-form.vue';
    export default {
        name: 'ViewTemplate',
        props: {
            formData: { type: Object }
        },
        components: {
            'print-form': printform,
            'consignment-grid': consignmentGrid
        },
        data() {
            return {
                model: null,
                documentTypes: [],
                dialog: {
                    show: false,
                    type: 'label',
                }
            }
        },
        validations() {
            return {
                model: {
                }
            }
        },
        watch: {
            'formData': {
                handler() {
                    this.$nextTick(() => {
                        this.model = this.$props.formData;
                    });
                }, deep: true
            },
            'model.status': {
                handler() {
                    this.$nextTick(() => {
                        if ((this.model?.status ?? 0 > 100)) {
                            this.getCollectionDocument();
                        }
                    });
                },
                deep: true
            }
        }
        ,computed: {
            cardHeight() {
                return 57;
            },
            contact() {
                return {
                    firstName: this.model?.contact_FirstName,
                    lastName: this.model?.contact_LastName,
                    email: this.model?.contact_Email,
                    mobile: this.model?.contact_Mobile,
                    telephone: this.model?.contact_Telephone,
                    eori: null,
                    ioss: null,
                    vat: null,
                    idNumber: null,
                    idType: 300
                };
            },
            pickUpAddress() {
                return {
                    address1: this.model?.pickup_Address1,
                    address2: this.model?.pickup_Address2,
                    address3: this.model?.pickup_Address3,
                    city: this.model?.pickup_City,
                    state: this.model?.pickup_State,
                    postalCode: this.model?.pickup_PostalCode,
                    countryID: this.model?.pickup_CountryID,
                    companyName: this.model?.pickup_Company,
                    establishmentType: this.model?.pickup_EstablimentType
                };
            },
            afterHourContact() {
                return {
                    firstName: this.model?.afterHourContact_FirstName,
                    lastName: this.model?.afterHourContact_LastName,
                    email: this.model?.afterHourContact_Email,
                    mobile: this.model?.afterHourContact_Mobile,
                    telephone: this.model?.afterHourContact_Telephone,
                    eori: null,
                    ioss: null,
                    vat: null,
                    idNumber: null,
                    idType: 300
                };
            },
            afterHourAddress() {
                return {
                    address1: this.model?.afterHourLocation_Address1,
                    address2: this.model?.afterHourLocation_Address2,
                    address3: this.model?.afterHourLocation_Address3,
                    city: this.model?.afterHourLocation_City,
                    state: this.model?.afterHourLocation_State,
                    postalCode: this.model?.afterHourLocation_PostalCode,
                    countryID: this.model?.afterHourLocation_CountryID,
                    companyName: this.model?.afterHourLocation_Company,
                    establishmentType: this.model?.afterHourLocation_EstablimentType
                };
            },
            buttonItems() {
                let _buttonItems = [
                    {
                        label: 'Summary',
                        icon: 'pi pi-file',
                        command: () => {
                            this.dialog.show = true;
                            this.dialog.type = "Summary";
                        }
                    },
                    {
                        label: 'Details',
                        icon: 'pi pi-file',
                        command: () => {
                            this.dialog.show = true;
                            this.dialog.type = "details";
                        }
                    }
                ];

                if (this.documentTypes.findIndex(x => x === 700) === -1) {
                    let index = _buttonItems.findIndex(x => x.label === 'Summary');
                    if (index !== -1) {
                        _buttonItems.splice(index, 1);
                    }
                }
                if (this.documentTypes.findIndex(x => x === 800) === -1) {
                    let index = _buttonItems.findIndex(x => x.label === 'Detailed');
                    if (index !== -1) {
                        _buttonItems.splice(index, 1);
                    }
                }
                return _buttonItems;
            },
        }
        , methods: {
            async getCollectionDocument() {
                if (this.model?.mask) {
                    this.$axios.get(this.$config.config.endpoint.api + `/services/tms/collection/` + this.model.mask + `/document`.replaceAll("//", "/"))
                        .then((response) => {
                            this.documentTypes = (response.data.result ?? []).map(x => x.documentType);
                        })
                        .catch(() => {
                        });
                }
                return [];
            }
        }
        ,created() {
            this.model = this.$props.formData;
        },mounted() {
            this.model = this.$props.formData;
        }
    }
</script>
<style>
  /*  .p-picklist-header {
        padding: 0 10px !important;
    }
    .responsive-iframe {
        width: 100%;
        height: 450px;
    }
    .p-code-text :not(.fa)
    {
        font-family: inherit !important;
    }
    .p-code-text .fa-duotone {
        font-family: "Font Awesome 6 Duotone" !important;
    }
    tr[style='border: 0.01rem;'] th:first-child {
        font-family: Roboto !important;
    }
    tr th > h4 {
        font-family: Roboto !important;
        font-weight: bold;
        font-size: 1.1rem !important;
    }*/
</style>
