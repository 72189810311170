<template>
    <form-wrapper title="Import Profile" sub-title="List of available customer specific import profiles">
        <cosmos-grid id="ProviderList" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showDelete="true" :showAdd="true" :showExport="false" :operationWidth="7" groupBy="customerName">
            <template #groupheader="slotProps">
                <span class="image-text" style="text-transform: uppercase; font-weight: 700 !important">{{ slotProps.data.customerName ?? "COSMOS" }}</span>
            </template>
            <pv-grid-column field="topicName" header="TOPIC NAME" headerStyle="width: 20rem" />
            <pv-grid-column field="code" header="CODE" headerStyle="width: 15rem" />
            <pv-grid-column field="name" header="NAME" />
            <pv-grid-column field="code" header="" headerStyle="width: 15rem">
                <template #body="slotProps">
                    <pv-button style="width: 100%;" v-if="slotProps.data.id > 1000 && slotProps.data.isValidMapping" label="Import Key" icon="fa fa-key" @click="showKey(slotProps.data)" />
                    <div class="is-text-danger" style="text-transform: uppercase; font-weight: bold; " v-if="slotProps.data.id > 1000 && !slotProps.data.isValidMapping"><i class="fa fa-triangle-exclamation"></i> Incomplete Mapping</div>
                </template>
            </pv-grid-column>
        </cosmos-grid>
    </form-wrapper>
    <is-dialog header="Import Profile" :visible="dialog.edit" @is-confirm="onConfirmEdit(model.mask)" @is-cancel="onCancel()" confirm="Copy" confirmIcon="fa fa-copy fa-fw">
        <div class="confirmation-content p-d-flex">
            <div class="p-col p-md-12">
                <form-input id="ImportKey" :value="model.mask" :disabled="true" style="width: 25rem;" />
            </div>
        </div>
    </is-dialog>
</template>
<script>
    export default {
        name: 'ImportProfileGrid',
        data() {
            return {
                model: null,
                columns: [
                    { field: 'topicName', header: 'TOPIC NAME', width: 20 },
                    { field: 'code', header: 'CODE', width: 15 },
                    { field: 'name', header: 'NAME' },
                ],
                searchFields: ['code', 'name'],
                dialog: {
                    edit: false
                }
            }
        },
        computed: { ticks() { return this.$moment.utc().format("YYYYMMDD"); } },
        methods: {
            showKey(data) {
                this.model = data;
                this.dialog.edit = true;
            },
            onCancel() {
                this.model = null;
                this.dialog.edit = false;
            },
            onConfirmEdit(e) {
                navigator.clipboard.writeText(e);
                this.$toast.add({ severity: 'success', summary: 'Import Key', detail: 'Import Key Copied Successful', life: 3000 });
            },
        },
        mounted() { this.loading = false; }
    }
</script>
