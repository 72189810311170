<template>
    <form-wrapper :title="router.title" :sub-title="router.subtitle">
        <cosmos-grid id="UserList" sourceType="url" :source="url" :searchFilters="searchFields" :pageSize="15" :filters="activeFilters" :forceRefresh="forceRefresh" :showAdd="false" :autoGenerate="false" :columns="columns" :deleteColumns="deleteDisplay" :showDelete="this.setType(this.router.type) > 0" :operationWidth="(this.setType(this.router.type) > 0 ? 7 : 4)">
            <template #toolbar>
                <div class="p-d-flex p-jc-between p-ai-center">
                    <span>
                        <pv-button icon="pi pi-fw pi-plus" label="Add New Record" style="margin: 0.20rem;" @click="onCreate"></pv-button>
                    </span>
                    <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                        <i class="pi pi-search" />
                        <pv-input v-model="activeFilters['global'].value" placeholder="Keyword Search" style="width: 100%;" @keyup="onForceFilter" />
                    </span>
                </div>
            </template>
            <pv-grid-column field="licenseKey" header="Barcode" :frozen="true" headerStyle="min-width: 6rem; max-width: 6rem; width: 6rem;" style="min-width: 6rem; max-width: 6rem; width: 6rem; text-overflow: ellipsis; white-space: nowrap; text-align: center; ">
                <template #body="slotProps">
                    <qr-code :value="slotProps.data.code" :size="50" level="H" :background="'black'" foreground="#FFFFFF" style="border: 1px solid gray;" />
                </template>
            </pv-grid-column>
            <pv-grid-column field="username" header="Login Credentials" headedrStyle="min-width: 20rem;max-width: 20rem; " stdyle="min-width: 20rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; ">
                <template #body="slotProps">
                    <div class="p-d-flex" style="height: 100%;">
                        <div class="p-as-end">
                            <h5 style="margin-bottom: 5px;"><span><i class="pi pi-user pi-fw"></i>  {{ slotProps.data.username}}</span></h5>
                            <p style="margin-bottom: 5px;"><span><i class="pi pi-key pi-fw" v-tooltip.bottom="(slotProps.data.password ? 'Click to view password' : null)" @click="slotProps.data.ako = !slotProps.data.ako"></i>  <span v-if="slotProps.data.password && slotProps.data.ako">{{ slotProps.data.password }}</span><span v-else style="font-size: 1.25rem; font-family: 'Roboto Mono', monospace">**********</span></span></p>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="text" header="User Details" style="width: 100%; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; ">
                <template #body="slotProps">
                    <div class="p-d-flex" style="height: 100%;">
                        <div class="p-as-stretch" style="margin-right: 10px;">
                            <img :src="`https://${cdn}/cosmos/development/assets/_img/users/${slotProps.data.mask.toUpperCase()}.jpg`" style="height: 50px; width: 50px; border-radius: 100%; background-color: var(--surface-f);" :class="`status-${(slotProps.data.sessionID ? 'online': 'offline' )}`" onerror="this.src = '/_assets/img/dp-noimage.png'" v-tooltip="`${(slotProps.data.sessionID ? 'Online' : 'Offline')}`" />
                        </div>
                        <div class="p-d-flex" style="height: 100%;">
                            <div class="p-as-end">
                                <h5 style="margin-top: 5px; margin-bottom: 5px;"><span>{{ slotProps.data.firstName }} {{ slotProps.data.lastName }}</span></h5>
                                <span><i class="pi pi-envelope pi-fw"></i> <a :href="`mailto:${slotProps.data.email}`" class="p-button-link">{{ slotProps.data.email }}</a></span>
                            </div>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="companyID" header="Company">
                <template #body="slotProps">
                    {{ companies.filter(x => x.id == slotProps?.data?.companyID)[0]?.altName ?? companies.filter(x => x.id == slotProps?.data?.companyID)[0]?.name }}
                </template>
            </pv-grid-column>
            <pv-grid-column :class="[{'p-field-hidden': !(this.setType() > 0) }]" field="status" header="Status" headedrStyle="min-width: 10rem; max-width: 10rem" stdyle="min-width: 10rem; max-width: 10rem; text-overflow: ellipsis; white-space: nowrap; ">
                <template #body="slotProps">
                    <div v-if="slotProps.data.verificationConfirmDate" class="p-d-flex p-jc-center" style="height: 100%; display: block;">
                        <div class="p-jc-center">
                            <pv-toggle v-model="slotProps.data.isActive" :style="[{'width': '12rem'}, {'object-position': 'bottom'}, {'align-content': 'end' }, {'visibility': ((type <= 0) ? 'hidden' : 'visible') }, {'padding': '0.5rem 1rem'}, {'margin-top': '-0.25rem'}]" class="p-inputgroup-prepend" :onIcon="'pi pi-check'" :offIcon="'pi pi-times'" :onLabel="'ACTIVE'" :offLabel="'INACTIVE'" @change="onDisable(slotProps.data)" v-tooltip.bottom="generateTooltip(slotProps.data)"></pv-toggle>
                        </div>
                    </div>
                    <div v-else class="p-d-flex p-jc-center" style="height: 100%;">
                        <div class="p-as-end p-jc-center">
                            <pv-button label="Send Activation Link" icon="pi pi-send" @click="sendEmail(slotProps.data.mask)"></pv-button>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column :class="[{'p-field-hidden': (user?.userType ?? -1) > 2 || setType() < 2 }]" field="mask" headerStyle="min-width: 4rem; max-width: 4rem; width: 4rem" style="min-width: 4rem; max-width: 4rem; width: 4rem; border-left: 1px solid var(--surface-d); border-right: none; text-align: center; object-position: center; padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
                <template #body="slotProps">
                    <div class="p-d-flex" style="width: 2rem;">
                        <div class="p-col">
                            <i class="fa-solid "></i>
                            <pv-button v-if="(user?.userType ?? -1) < 3 && slotProps.data.verificationConfirmDate && slotProps.data.isActive" icon="fas fa-people-arrows fa-fw" class="p-button-warning" @click="showChangeType(slotProps.data)" v-tooltip.bottom="'Promote / Demote User'"></pv-button>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
        </cosmos-grid>
    </form-wrapper>
    <keep-alive>
        <is-dialog header="Change User Role" :visible="dialog.upgrade" :breakpoints="{'2500px': '50vw', '960px': '75vw', '640px': '100vw'}" @is-confirm="onChangeType(entity)" @is-cancel="onCancel()">
            <div class="p-grid">
                <div class="p-col-6">
                    <h4>CURRENT ROLE</h4>
                    <div style="background: var(--surface-c); height: calc(100% - 3.25rem); padding: 0.25rem; border: 1px solid lightgray !important;">
                        <form-input id="currentRole" pre-label="Role" :hide-label="true" :disabled="true" :showCopy="false" :value="`${$vm.$enums.usertype.filter(x => x.id === data.type)[0]?.text}`" />
                        <form-input id="currentUser" pre-label="Name" :hide-label="true" :disabled="true" :showCopy="false" :value="`${data.firstName} ${data.lastName}`" style="margin-top: -1.5rem;" />
                        <form-input id="currentCompany" pre-label="Company" :hide-label="true" :disabled="true" :showCopy="false" :value="`${companies.filter(x => x.id == data?.companyID)[0]?.altName ?? companies.filter(x => x.id == data?.companyID)[0]?.name}`" style="margin-top: -1.5rem;" />
                    </div>
                </div>
                <div class="p-col-6">
                    <h4>TARGET ROLE</h4>
                    <div style="background: var(--surface-c); height: calc(100% - 3.25rem); padding: 0.25rem; border: 1px solid lightgray !important;">
                        <form-lookup :source="$vm.$enums.usertype?.filter(x => x.id > user.userType && x.id != 1) ?? []" id="targeyRole" pre-label="Role" :hide-label="true" :showCopy="false" :value="entity.type" @is-sync="entity.type = $event.value" :required="true" :sorted="false" />
                        <form-input id="targetUser" pre-label="Name" :hide-label="true" :disabled="true" :showCopy="false" :value="`${entity.firstName} ${entity.lastName}`" style="margin-top: -1.5rem;" />
                        <form-lookup id="targetCompany" :source="(this.entity.type > 4 ? this.companies.filter(x => Number(x.type) == 16) ?? [] : this.companies.filter(x => Number(x.type) == 1 || Number(x.type) == 2) ?? [])" sourceType="array" pre-label="Company" :hide-label="true" :showCopy="false" :value="(this.entity.type > 4 ? this.companies.filter(x => Number(x.type) == 16) ?? [] : this.companies.filter(x => Number(x.type) == 1 || Number(x.type) == 2) ?? []).filter(x => x.id === this.entity.companyID).length > 0 ? this.entity.companyID : (this.entity.type > 4 ? this.companies.filter(x => Number(x.type) == 16) ?? [] : this.companies.filter(x => Number(x.type) == 1 || Number(x.type) == 2) ?? [])[0]?.id" @is-sync="entity.companyID = $event.value" style="margin-top: -1.5rem;" :required="true" />
                    </div>
                </div>
            </div>
            <div class="is-text-info"><i class="fas fa-exclamation-circle fa-fw mr-5"></i> If you need to upgrade the user to <b>System Administrator</b> please contact support.</div>
            <div class="is-text-danger"><i class="fas fa-exclamation-circle fa-fw mr-5"></i> Please review the <b>user scope</b> before informing the user.</div>
        </is-dialog>
    </keep-alive>
</template>
<script>
    import { FilterMatchMode } from 'primevue/api';
    import config from '@/assets/lib/cosmos/_js/config.js';
    import mixins from '@/assets/lib/cosmos/_js/mixin.js';
    export default {
        name: 'UserListTemplate',
        mixins: [mixins.GRID],
        data() {
            return {
                filter: {},
                columns: [],
                deleteDisplay: ["username", "email", "firstName", "lastName"]
                ,searchFields: ["username", "email", "firstName", "lastName"]
                , forceRefresh: false
                , dialog: { delete: false, upgrade: false }
                , companies: []
                , data: null
            }
        },
        computed: {
            type() { return this.$route.meta.typeId ?? 0; },
            tenant() { return config.common.$tenant().filter(x => x.id === 1)[0]; },
            cdn() { return `${config.config.endpoint.cdn}`.split('/')[2]; },
            url() { return `/services/settings/user?type=${this.setType(this.router.type)}` },
            activeFilters() {
                var filter = {};
                if (this.filters)
                    filter = this.filters;
                filter["global"] = { value: "", matchMode: FilterMatchMode.CONTAINS };
                return filter;
            }
        },
        methods: {
            sendEmail(mask) {
                this.$store.dispatch("user/getURL", { url: `/services/settings/user/send/${mask}` })
                    .then((response) => {
                        if (response && response.result) {
                            this.$toast.add({ severity: 'success', summary: 'Email Sent Sucessfully', detail: `The confirmation e-mail was successfully sent to <a href='mailto:${response.result.email}'>${response.result.email}</a>`, life: 3000 });
                        }
                        else {
                            this.$toast.add({ severity: 'error', summary: 'Email Sent Failed', detail: this.errors[0].message, life: 3000 });
                        }
                    })
            },
            onEdit(mask) {
                this.getRouter();
                this.$router.push({ name: `${(this.sourceType.toLowerCase() === 'store' && typeof (this.source) === 'string' ? this.source : this.router.name)}-edit`, params: { mask: mask } });
            },
            onDelete(id) {
                this.entity = this.result.data.filter(x => x.id == id)[0];
                this.dialog.delete = true;
            },
            onConfirm(mask) {
                this.$store.dispatch(this.router.module + "/deleteRecordByMask", mask).then((response) => {
                    if (response && response.success) {
                        this.$toast.add({ severity: 'success', summary: 'Record Deleted Sucessfully', detail: 'The record was successfully deleted', life: 3000 });
                        this.onRefresh();
                    }
                    else {
                        this.$toast.add({ severity: 'error', summary: 'Record Delete Failed', detail: this.errors[0].message, life: 3000 });
                    }
                    this.dialog.delete = false;
                }).catch((err) => { console.log(err); });
            },
            onCancel() {
                this.dialog.delete = false;
                this.dialog.upgrade = false;
                this.entity = null;
            },
            onDisable(user) {
                this.$store.dispatch(this.router.module + "/patchURL", {
                    url: '/services/settings/user/' + user.mask, payload: [
                        {
                            "op": "replace",
                            "path": "/recordStatus",
                            "value": user.isActive ? 0 : 3
                        }
                    ]
                }).then((response) => {
                    var status = response.recordStatus === 0 ? "Reinstated" : "Disabled";
                    this.$toast.add({ severity: 'success', summary: 'Record Updated Successfully', detail: 'The record was ' + status.toLowerCase() + ' successfully.', life: 3000 });
                    this.onRefresh();
                }).catch((err) => { console.log(err); });
            },
            showChangeType(user) {
                this.dialog.upgrade = true;
                this.data = this.$filters.init(user);
                this.entity = user;
            },
            onChangeType(user) {
                this.$store.dispatch(this.router.module + "/patchURL", {
                    url: '/services/settings/user/' + user.mask, payload: [
                        {
                            "op": "replace",
                            "path": "/type",
                            "value": user?.type ?? this.data?.type
                        },
                        {
                            "op": "replace",
                            "path": "/companyID",
                            "value": user?.companyID ?? this.data?.companyID
                        }
                    ]
                }).then((response) => {
                    if (response && response.id == this.entity.id) {
                        this.$toast.add({ severity: 'success', summary: 'Record Update Sucessfully', detail: 'The user role was successfully updated', life: 3000 });
                        this.onRefresh();
                    }
                    else {
                        this.$toast.add({ severity: 'error', summary: 'Record Updated Failed', detail: this.errors[0].message, life: 3000 });
                    }
                    this.onCancel();
                }).catch((err) => { console.log(err); });
            },
            setType() {
                switch (this.router.type.toLowerCase()) {
                    case "globaladmins":
                        return 0;
                    case "sysadmins":
                        return 2;
                    case "admins":
                        return 3;
                    case "agents":
                        return 4;
                    case "custadmins":
                        return 5;
                    case "customers":
                        return 6
                    case "retailers":
                        return 7
                    case "apis":
                        return 1;
                }
            },
            onCreate() { this.$router.push({ name: `${this.$route.meta.type}-add` }); },
            onForceFilter(e) {
                if (e.keyCode === 13 || this.activeFilters["global"].value === '') {
                    this.onRefresh();
                }
            },
            onRefresh() {
                this.forceRefresh = false;
                this.$nextTick(() => { this.forceRefresh = true; });
            },
            generateTooltip(user) {
                if (!user.isActive)
                    return `<b>LAST LOGIN DATE</b>:<br /> <span style='font-size: 1rem'>${this.$filters.toDateTimeDisplay(user.lastLoginDate)}</span>`
                return null;
            },
            onChange(e) {
                this.entity.type = e.value
                this.companyDS = e.type > 4 ? this.companies.filter(x => Number(x.type) == 16) ?? [] : this.companies.filter(x => Number(x.type) == 1 || Number(x.type) == 2) ?? [];
            }
        },
        mounted() {
            this.$store.dispatch("user/getURL", { url: `/services/settings/company` })
                .then((response) => {
                    this.companies = response?.result ?? [];
                    this.companyDS = response?.result ?? [];
                })
        }
    }
</script>
<style>
    .status-online {
        border: 2px solid var(--bg-success)
    }
    .status-offline {
        border: 2px solid var(--bg-danger)
    }
    #UserList td {
        padding: 0.5rem 0.75rem 0.25rem 0.75rem;
    }
</style>