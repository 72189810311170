<template>
    <is-dialog header="Delete Record?" :visible="dialog.delete" @is-confirm="onDelete(forDelete.mask)" @is-cancel="this.dialog.delete = false">
        <div class="confirmation-content p-d-flex">
            <div>
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            </div>
            <div>
                You are about to delete this record:

                <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                    <tr v-for="data in deleteDisplayFields" :key="data.id">
                        <th style="text-align:left;">{{ $filters.titleize(data) }}</th>
                        <td>:</td>
                        <td> {{ forDelete[data] }}</td>
                    </tr>
                </table>

                Please be aware of the following:
                <ul>
                    <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                    <li>For records that has activity, the record will only be disabled and archived.</li>
                    <li>Also deleting a record may be irreversible action.</li>
                </ul>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
    <is-dialog header="DISPATCH" :visible="dialog.dispatch" @is-confirm="onDispatch()" @is-cancel="this.dialog.dispatch = false">
        <div class="confirmation-content p-d-flex">
            <div>
                <i class="pi pi-info-circle p-mr-3" style="font-size: 2rem" />
            </div>
            <div>
                You are about to dispatch selected collections
                <form-datetime id="dispatchDate" :required="true" :value="dispatchDate" type="date" @is-sync="dispatchDate = $event.value" label="Dispatch Date" style="margin-top:20px;" :v$="v$"></form-datetime>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
    <is-dialog header="POD" :visible="dialog.pod" @is-confirm="onPOD()" @is-cancel="this.dialog.pod = false">
        <div class="confirmation-content p-d-flex">
            <div>
                <i class="pi pi-info-circle p-mr-3" style="font-size: 2rem" />
            </div>
            <div>
                You are about to POD selected consignments
                <form-datetime id="podDate" :required="true" :value="podDate" type="date" @is-sync="podDate = $event.value" label="POD Date" style="margin-top:20px;" :v$="v$"></form-datetime>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
    <keep-alive>
        <is-dialog header="BOOK COLLECTION" :visible="dialog.book" icon="mdi mdi-book-variant-multiple" @is-confirm="onBookCollection()" @is-cancel="this.dialog.book = false" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '75vw'}">
            <div class="confirmation-content p-fluid p-grid">
                <div class="p-d-flex p-col-12">
                    <div class="p-md-4">
                        <h6>PICK-UP SCHEDULE</h6>
                        <form-datetime id="collectionDate" :required="true" :value="bookCollectionModel.collectionDate" type="date" @is-sync="bookCollectionModel.collectionDate = $event.value" label="Pick-up Date" :v$="v$"></form-datetime>
                        <form-datetime id="cutOffTime" type="time" :required="true" :value="bookCollectionModel.cutOffTime" @is-sync="bookCollectionModel.cutOffTime = $event.value" :stepMinute="5" :allowInput="true" />
                        <form-datetime id="readyTime" type="time" :required="true" :value="bookCollectionModel.readyTime" @is-sync="bookCollectionModel.readyTime = $event.value" :stepMinute="5" :allowInput="true" />
                        <form-datetime id="collectionTime" type="time" :required="true" :value="bookCollectionModel.collectionTime" @is-sync="bookCollectionModel.collectionTime = $event.value" :stepMinute="5" :allowInput="true" />
                        <form-datetime id="closingTime" type="time" :required="true" :value="bookCollectionModel.closingTime" @is-sync="bookCollectionModel.closingTime = $event.value" :stepMinute="5" :allowInput="true" />
                        <form-datetime id="afterCloseTime" type="time" :required="true" :value="bookCollectionModel.afterCloseTime" @is-sync="bookCollectionModel.afterCloseTime = $event.value" :stepMinute="5" :allowInput="true" />

                    </div>
                    <div class="p-md-4">
                        <h6>AFTER HOUR CONTACT</h6>
                        <form-contact id="contact" :value="contact" :useRegistrationNumber="false" :v$="v$" @is-sync="translateContact($event)" :showDirectory="false" />
                    </div>
                    <div class="p-md-4">
                        <h6>AFTER HOUR LOCATION</h6>
                        <form-address id="address" :value="address" :v$="v$" @is-sync="translateAddress($event)" :useCompany="true" />
                    </div>

                </div>
                <div class="p-d-flex p-col-12">
                    <div class="p-col-12">
                        <pv-grid :ref="id"
                                 :id="id"
                                 :dataKey="'id'"
                                 :value="selectedItems.length > 0 ? selectedItems : source.data" rowGroupMode="subheader" groupRowsBy="groupCollection"
                                 :totalRecords="selectedItems.length > 0 ? selectedItems.length : source.total"
                                 :lazy="lazyLoading || paged"
                                 :filters="selectedItems.length === 0 ? activeFilters : {}"
                                 :globalFilterFields="selectedItems.length === 0 ? searchFilter : {}"
                                 :first="page.first"
                                 :rows="page.size"
                                 :rowsPerPageOptions="[5,10,20,50,100]"
                                 :rowHover="true"
                                 sortMode="single" sortField="groupCollection">
                            <pv-grid-column field="groupCollection" header="groupCollection" style="min-width: 10rem; text-align: center; object-position: center;">
                            </pv-grid-column>
                            <pv-grid-column field="code" header="Transaction ID" style="text-align: center; object-position: center;">
                                <template #body="slotProps">
                                    <div>
                                        <div class="p-d-flex">
                                            {{slotProps.data.code}}
                                        </div>
                                    </div>
                                </template>
                            </pv-grid-column>
                            <pv-grid-column field="shipTo_CountryName" header="Destination" style="min-width: 10rem; text-align: center; object-position: center;">

                                <template #body="slotProps">
                                    <div>
                                        <div class="p-d-flex">
                                            <i :class="`flag flag-${$vm.$countries.filter((x) => x.id == slotProps.data?.shipTo_CountryId)[0]?.code.toLowerCase()}`" style="font-size: 0.8rem; margin-right:5px;" />
                                            {{ slotProps.data.shipTo_City ? slotProps.data.shipTo_City + ',' : null }} {{ slotProps.data.shipTo_CountryName ?? 'United Kingdom'}}
                                        </div>
                                    </div>
                                </template>
                            </pv-grid-column>

                            <pv-grid-column field="primaryCarrierReference" header="Carrier Reference" style="min-width: 10rem;  text-align: center; object-position: center;">
                                <template #body="slotProps">
                                    <div>
                                        <div class="p-d-flex">
                                            {{slotProps.data.primaryCarrierReference}}
                                        </div>
                                    </div>
                                </template>
                            </pv-grid-column>
                            <pv-grid-column field="integrationProductID" header="Service" style="min-width: 10rem; text-align: center; object-position: center;">
                                <template #body="slotProps">
                                    <div class="p-d-flex" style="z-index: 100;">
                                        <div style="margin-right:5px;">
                                            <img style="width:35px; height:20px; max-height: 20px; max-width: 35px; object-fit: contain; object-position: top;" :title="`${$vm.$providers.filter((x) => x.id == slotProps.data?.serviceProviderID)[0]?.text}`" :src="`/_assets/_integration/${$vm.$providers.filter((x) => x.id == slotProps.data?.serviceProviderID)[0]?.mask}.png`" onerror="this.onerror=null; this.src = '/_assets/img/no-logo.png'" />
                                        </div>
                                        {{integrationProductDS?.filter((x) => x.id == slotProps.data?.serviceProviderProductID)[0]?.text}}
                                    </div>
                                </template>
                            </pv-grid-column>
                            <template #groupheader="slotProps" style="min-width: 10rem; text-align: center; object-position: center;">
                                <i :class="`flag flag-${$vm.$countries.filter((x) => x.id == slotProps.data?.shipFrom_CountryId)[0]?.code.toLowerCase()}`" style="font-size: 0.8rem; margin-left:5px;" />
                                <span class="image-text">{{ slotProps.data.shipFrom_Company ? slotProps.data.shipFrom_Company + ',' : null }} {{ slotProps.data.shipFrom_City ? slotProps.data.shipFrom_City + ',' : null }} {{ slotProps.data.shipFrom_CountryName ?? 'United Kingdom'}}</span>
                            </template>
                            <template #groupfooter="slotProps">
                                <td style="width: 60%" colspan="3"></td>
                                <td style="width: 40%">
                                    <table style="width:100%">
                                        <tr>
                                            <td>Total Consignments</td>
                                            <td>{{computeTotalConsignment(slotProps.data.groupCollection)}}</td>
                                        </tr>
                                        <!--<tr>
                                            <td></td>
                                            <td></td>

                                            <td>Total Packages</td>
                                            <td>0</td>
                                        </tr>
                                        <tr>
                                            <td></td>

                                            <td></td>

                                            <td>Total Weight</td>
                                            <td>0</td>
                                        </tr>-->
                                    </table>
                                    <!--<div style="text-align: right; width: 100%">Total Consignments</div>-->
                                </td>
                            </template>
                            <template #empty>
                                No records found.
                            </template>
                            <template #loading>
                                Retrieving records from database. Please wait...
                            </template>
                             Pagination 
                            <template #paginatorRight>
                                <pv-button type="button" icon="pi pi-refresh" class="p-button-rounded p-button-text" @click="onRefresh" />
                            </template>
                        </pv-grid>
                    </div>
                </div>
            </div>
        </is-dialog>
    </keep-alive>
    <pv-grid :ref="id"
             :id="id"
             :dataKey="'id'"
             :value="source?.data ?? []"
             :totalRecords="source?.total ?? 0"
             :lazy="lazyLoading || paged"
             :filters="activeFilters"
             :filterDisplay="'menu'"
             :globalFilterFields="searchFilter"
             :first="page.first"
             :rows="page.size"
             :rowsPerPageOptions="[5,10,20,50,100]"
             :rowHover="true"
             v-model:selection="selectedItems"
             :selectionMode="multiSelect ? 'multiple' : 'single'"
             :scrollable="true"
             :scrollWidth="'flex'"
             :scrolHeight="'flex'"
             :responsive="'scroll'"
             :csvSeparator="csvSeparator"
             :exportFilename="exportFilename"
             :loading="activity.loading"
             :loadingIcon="'pi pi-spinner'"
             :paginator="true"
             :alwaysShowPaginator="pagination"
             :paginatorPosition="'bottom'"
             :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'"
             :pageLinkSize="3"
             :currentPageReportTemplate="'Showing {first} to {last} of {totalRecords} records'"
             :columnResizeMode="'expand'"
             :stateStorage="'session'"
             :stateKey="`dt-state-${id}`"
             :groupRowsBy="groupBy"
             rowGroupMode="subheader"
             autoLayout
             showGridlines
             stripedRows
             @page="changePage"
             @row-dblclick="rowClick">
        <!--@filter="filterRecord"
        @sortRecord="sortRecord"-->
        <!-- Header -->
        <template #header>
            <slot name="toolbar">
                <div class="p-d-flex p-jc-between p-ai-center">
                    <span>
                        <pv-button v-if="showAdd" icon="pi pi-fw pi-plus" label="Add New Record" style="margin-right: 5px;" @click="onCreate"></pv-button>
                        <pv-button v-if="showExport" icon="pi pi-fw pi-file-excel" label="Export To CSV" :disabled="(source?.total ?? 0) === 0" @click="onExport" style="margin-right: 5px;"> </pv-button>
                        <pv-button v-if="showBookCollection" icon="mdi mdi-book-variant-multiple" label="Book Collection" :disabled="(consignmentToProcess?.length ?? 0) === 0" style="margin-right: 5px;" @click="bookCollectionClick"></pv-button>
                        <pv-button v-if="showDispatch" icon="mdi mdi-truck" label="Dispatch" :disabled="(selectedItems?.filter(x => x?.status === 400).length ?? 0) === 0" style="margin-right: 5px;" @click="dispatchClick"></pv-button>
                        <pv-button v-if="showPOD" icon="mdi mdi-mailbox-up-outline" label="POD" :disabled="(selectedItems?.filter(x => x?.status === 500).length ?? 0) === 0" style="margin-right: 5px;" @click="podClick"></pv-button>
                    </span>
                    <div class="p-d-flex">
                        <span style="min-width:200px; max-width: 250px; width: 100%; margin-right:10px;" v-if="showDateFilter">
                            <form-datetime id="dateFilter" type="date" :value="filterHeaderSource.data.dateFilter" @is-sync="objectFilter.dateFilter = $event.value; syncFilter();" :hideLabel="true" style="margin:0; padding:0;" />
                        </span>
                        <!--<span style="min-width:200px; max-width: 250px; width: 100%; margin-right:10px;" v-if="showEnumFilter">
                            <form-lookup id="enumDropDown" type="enum" :source="enumFilterName" :required="true" :sorted="false" :value="enumFilterValue" :hideLabel="true" @is-sync="enumDropdownChange($event.value)" style="margin:0; padding:0;" />
                        </span>-->
                        <span style="min-width:240px; max-width: 250px; width: 100%; margin-right:10px;" v-if="showEnumFilter">
                            <form-lookup id="enumDropDown" type="enum" :source="enumFilterName" :required="true" :sorted="false" :value="filterHeaderSource.data.enumFilter" :hideLabel="true" @is-sync="objectFilter.enumFilter = $event.value;  syncFilter();" style="margin:0; padding:0;" /> <!--objectFilter.enumFilter = $event.value; syncFilter();-->
                        </span>
                        <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                            <i class="pi pi-search" />
                            <pv-input v-model="activeFilters['global'].value" placeholder="Keyword Search" style="width: 100%;" />
                        </span>
                    </div>
                </div>
            </slot>
        </template>
        <!-- Columns -->
        <pv-grid-column field="id" :selectionMode="multiSelect ? 'multiple' : 'single'" headerStyle="width: 3em;min-width: 3em; max-width: 3em;" style="width: 3em;width: 3em;min-width: 3em; max-width: 3em;" :frozen="true"></pv-grid-column>
        <slot v-if="!autoGenerate" name="default"></slot>
        <pv-grid-column v-for="col of (autoGenerate ? columns : [])" :field="col.field" :header="col.header" :key="col.field" :headerStyle="`min-width: ${col.width}rem; max-width: ${col.width}rem`" :style="`min-width: ${col.width}rem; max-width: ${col.width}rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; display: block;`">
            <template #body="slotProps">
                <div style="line-height: 2rem;" v-html=" `${slotProps.data[col.field]}`"></div>
            </template>
        </pv-grid-column>
        <pv-grid-column v-if="showOperation" field="mask" :headerStyle="`min-width: ${operationWidth}rem;max-width: ${operationWidth}rem;`" :style="`min-width: ${operationWidth}rem; max-width: ${operationWidth}rem; border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;`" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-d-flex" :style="`max-width: ${(operationWidth - 1)}rem; min-width: ${(operationWidth - 1)}rem;`">
                    <div v-if="showEdit" class="p-col">
                        <pv-button icon="pi pi-pencil" iconPos="right" class="p-button-info" @click="onEdit(`${slotProps.data.mask}`)" />
                    </div>
                    <div v-if="showDelete" class="p-col">
                        <pv-button :disabled="slotProps.data.recordStatus !== 0 || slotProps.data.id < 1000 || (slotProps.data?.viewOnly ?? false)" icon="pi pi-trash" iconPos="right" class="p-button-danger" @click="deleteClick(`${slotProps.data.id}`)" />
                    </div>
                    <slot name="operation" :data="slotProps.data">
                    </slot>
                </div>
            </template>
        </pv-grid-column>
        <!-- Row Messages -->
        <template #empty>
            No records found.
        </template>
        <template #loading>
            Retrieving records from database. Please wait...
        </template>
        <!-- Pagination -->
        <template #paginatorRight>
            <pv-button type="button" icon="pi pi-refresh" class="p-button-rounded p-button-text" @click="onRefresh" />
        </template>
        <!--Name	Type	Default	Description
            value	array	null	An array of objects to display.
            lazy	boolean	false	Defines if data is loaded and interacted with in lazy manner.

            sortField	string	null	Property name or a getter function of a row data used for sorting by default
            sortOrder	number	null	Order to sort the data by default.
            defaultSortOrder	number	1	Default sort order of an unsorted column.
            multiSortMeta	array	null	An array of SortMeta objects to sort the data by default in multiple sort mode.
            sortMode	string	single	Defines whether sorting works on single column or on multiple columns.
            removableSort	boolean	false	When enabled, columns can have an un-sorted state.

            autoLayout	boolean	false	Whether the cell widths scale according to their content or not. Does not apply to scrollable tables.
            resizableColumns	boolean	false	When enabled, columns can be resized using drag and drop.
            columnResizeMode	string	fit	Defines whether the overall table width should change on column resize, valid values are "fit" and "expand".
            reorderableColumns	boolean	false	When enabled, columns can be reordered using drag and drop.
            expandedRows	array	null	A collection of row data display as expanded.
            expandedRowIcon	string	pi-chevron-down	Icon of the row toggler to display the row as expanded.
            collapsedRowIcon	string	pi-chevron-right	Icon of the row toggler to display the row as collapsed.
            rowGroupMode	string	null	Defines the row group mode, valid options are "subheader" and "rowspan".
            groupRowsBy	string|array	null	One or more field names to use in row grouping.
            expandableRowGroups	boolean	false	Whether the row groups can be expandable.
            expandedRowGroups	array	null	An array of group field values whose groups would be rendered as expanded.
            editMode	string	null	Defines the incell editing mode, valid options are "cell" and "row".
            editingRows	array	null	A collection of rows to represent the current editing data in row edit mode.
            frozenValue	array	null	Items of the frozen part in scrollable DataTable.
        -->
        <template #groupheader="slotProps" v-if="groupBy">
            <slot name="groupheader" :data="slotProps.data"></slot>
        </template>
    </pv-grid>
</template>
<script>
    const config = require("@/assets/lib/cosmos/_js/config.js").default;
    import { FilterMatchMode } from 'primevue/api';
    export default {
        name: "GridTemplate",
        props: {
            id: { type: String, required: true, validator: (value) => /^[a-zA-Z0-9_]*$/.test(value) },
            filename: { type: String, default: 'export' },
            filter: { type: Object },
            searchFilter: { type: Array },
            paged: { type: Boolean, default: false },
            typed: { type: Boolean, default: false },
            typeValue: { type: Number, default: -1 },
            lazyLoading: { type: Boolean, default: false },
            pagination: { type: Boolean, default: true },
            pageSize: { type: Number, default: 10 },
            multiSelect: { type: Boolean, default: true },
            csvSeparator: { type: String, default: ',' },
            autoGenerate: { type: Boolean, default: true },
            columns: { type: Array },
            operationWidth: { type: Number, default: 8 },
            showAdd: { type: Boolean, default: true },
            showExport: { type: Boolean, default: true },
            showEdit: { type: Boolean, default: true },
            showDelete: { type: Boolean, default: true },
            showOperation: { type: Boolean, default: true },
            deleteDisplayFields: { type: Array },
            groupBy: { type: String },
            recordSource: { type: Array, default: null },
            showEnumFilter: { type: Boolean, default: false },
            enumFilterValue: { type: Number, default: null },
            enumFilterName: { type: String, default: 'consignmentstatustype' },
            showDateFilter: { type: Boolean, default: false },
            dateFilterValue: { type: Number, default: new Date() },
            showBookCollection: { type: Boolean, default: false },
            showDispatch: { type: Boolean, default: false },
            showPOD: { type: Boolean, default: false },
            url: { type: String }
        },
        data() {
            return {
                forDelete: null,
                exportFilename: null,
                selectedItems: [],
                activity: { loading: true },
                source: {
                    total: 0,
                    data: []
                },
                page: {
                    first: 0,
                    current: 0,
                    size: 10
                },
                router: {
                    name: null,
                    module: null,
                    title: null,
                    subtitle: null,
                    model: null,
                    schema: null
                },
                dialog: {
                    delete: false,
                    book: false,
                    dispatch: false,
                    pod: false
                },
                type: null,
                objectFilter: {
                    enumFilter: null,
                    dateFilter: null
                },
                dispatchDate: new Date(),
                podDate: new Date(),
                expandedRowGroups: null,
                expandedRows: null,
                bookCollectionModel: {
                    collectionDate: new Date(),
                    collectionTime: new Date('2021-01-01 04:00 PM'),
                    readyTime: new Date('2021-01-01 03:00 PM'),
                    cutOffTime: new Date('2021-01-01 03:00 PM'),
                    closingTime: new Date('2021-01-01 06:00 PM'),
                    afterCloseTime: new Date('2021-01-01 08:00 PM'),
                    firstName: null,
                    lastName: null,
                    telephone: null,
                    mobile: null,
                    email: null,
                    company: null,
                    countryId: 0,
                    address1: null,
                    address2: null,
                    address3: null,
                    city: null,
                    state: null,
                    postalCode: null
                },
                keys: null,
                status: null
            }
        },
        computed: {
            dataSource() { return this.$store.getters[this.router.module + "/ds"]; },
            errors() { return this.$store.getters[this.router.module + "/errors"]; },
            activeFilters() {
                var filter = {};
                if (this.filter)
                    filter = this.filter;
                filter["global"] = { value: null, matchMode: FilterMatchMode.CONTAINS };
                return filter;
            },
            filterHeaderSource() {
                return {
                    data: {
                        enumFilter: this.objectFilter?.enumFilter ?? this.enumFilterValue,
                        dateFilter: this.objectFilter?.dateFilter ?? this.dateFilterValue
                    }
                };
            },
            integrationProductDS() { return this.$store.getters["providerproduct/ds"].data ?? null; },
            contact() {
                return {
                    firstName: this.bookCollectionModel.firstName,
                    lastName: this.bookCollectionModel.lastName,
                    email: this.bookCollectionModel.email,
                    mobile: this.bookCollectionModel.mobile,
                    telephone: this.bookCollectionModel.telephone
                };
            },
            address() {
                return {
                    address1: this.bookCollectionModel.address1,
                    address2: this.bookCollectionModel.address2,
                    address3: this.bookCollectionModel.address3,
                    city: this.bookCollectionModel.city,
                    state: this.bookCollectionModel.state,
                    postalCode: this.bookCollectionModel.postalCode,
                    country: null,
                    countryId: this.bookCollectionModel.countryId,
                    companyName: this.bookCollectionModel.company,
                    establishmentType: 100
                };
            },
            consignmentToProcess() {
                let data = null;
                if (this.selectedItems.length > 0) {
                    data = this.selectedItems.filter(x =>
                        x.status === this.filterHeaderSource.data.enumFilter)
                }
                if ((data?.length ?? 0) === 0 && this.selectedItems.length === 0) {
                    data = this.source.data.filter(x =>
                        x.status === this.filterHeaderSource.data.enumFilter);
                    //    && x.warehouseId === this.activeFilters["warehouseId"].constraints[0].value
                    //    && x.request_CollectionDate.toString().startsWith(this.activeFilters["request_CollectionDate"].constraints[0].value))
                }
                return data;
            },
            apiCollectRequests() {
                let request = [];
                let _self = this;
                let groupedResult = this.groupRecordBy(_self.consignmentToProcess, (c) => c.groupCollection);
                for (let item in groupedResult) {
                    let transactionIDs = [];
                    if (_self.selectedItems.length > 0 || _self.consignmentToProcess.filter(x => !x.request_CollectionDate.toString().startsWith(_self.$filters.formatDateWithFormat(this.bookCollectionModel.collectionDate, 'yyyy-MM-DD')))) {
                        _self.consignmentToProcess.filter(x => x.groupCollection === item).forEach((x) => {
                            transactionIDs.push(x.code);
                        });
                    }
                    request.push({
                        Request: {
                            CollectionSummary: {
                                CollectionSchedule: {
                                    CollectionDate: _self.$filters.formatDateWithFormat(this.bookCollectionModel.collectionDate, 'yyyy-MM-DD'),
                                    ReadyTime: _self.$filters.formatDateWithFormat(this.bookCollectionModel.readyTime, 'HH:mm'),
                                    CollectionTime: _self.$filters.formatDateWithFormat(this.bookCollectionModel.collectionTime, 'HH:mm'),
                                    ClosingTime: _self.$filters.formatDateWithFormat(this.bookCollectionModel.closingTime, 'HH:mm'),
                                    CutOffTime: _self.$filters.formatDateWithFormat(this.bookCollectionModel.cutOffTime, 'HH:mm'),
                                    AfterCloseTime: _self.$filters.formatDateWithFormat(this.bookCollectionModel.afterCloseTime, 'HH:mm')
                                },
                                AfterHoursDetails: {
                                    Contact: {
                                        FirstName: _self.bookCollectionModel.firstName,
                                        LastName: _self.bookCollectionModel.lastName,
                                        Email: _self.bookCollectionModel.email,
                                        Telephone: _self.bookCollectionModel.telephone,
                                        Mobile: _self.bookCollectionModel.mobile,
                                    },
                                    Address: {
                                        Address1: _self.bookCollectionModel.address1,
                                        Address2: _self.bookCollectionModel.address2,
                                        Address3: _self.bookCollectionModel.address3,
                                        City: _self.bookCollectionModel.city,
                                        State: _self.bookCollectionModel.state,
                                        PostalCode: _self.bookCollectionModel.postalCode,
                                        Country: _self.$vm.$countries.filter(x => x.id === _self.bookCollectionModel.countryId)[0]?.code,
                                        CompanyName: _self.bookCollectionModel.company,
                                    }
                                },
                                ConsignmentSummary: {
                                    ProductCode: "string",
                                    PackageCount: 0,
                                    Total: {
                                        GrossWeight: 0,
                                        NetWeight: 0,
                                        ChargeableWeight: 0
                                    },
                                    WeightUnit: "string"
                                },
                                TransactionIDs: transactionIDs
                            }
                        }
                    });
                }
                return request;
            }
        },
        watch: {
            'objectFilter.enumFilter': {
                handler() {
                    this.$nextTick(() => { this.onRefresh(); });
                }
            },
            keys() {
                this.onRefresh();
            },
            recordSource() {
                this.getSource().then((response) => { this.source = response; });
            },
            dataSource() {
                this.source = this.dataSource;
            },
            source() {
                setTimeout(() => { this.activity.loading = false; }, 200);
            },
            type() {
                this.getSource();
            },
            pageSize() {
                this.page.size = this.pageSize()
            }
        },
        methods: {
            generateFilename() {
                var filename = this.id;
                if (this.filename)
                    filename = this.filename;
                filename = filename.replace(/.csv/i, "");
                return filename + "_" + this.$filters.toDateFileName(new Date());
            },
            getRouter() {
                const router = this.$router.currentRoute._value;
                const meta = router.meta;
                this.router.name = router.name.replace("-index", "").replace("-add", "").replace("-edit", "");
                this.router.module = meta.module;
                this.router.model = meta.model;
                this.router.schema = meta.schema;
                this.router.title = meta.title;
                this.router.subtitle = meta.subtitle;
            },
            async deleteRecord(mask) {
                if (this.forDelete) {
                    return this.$store.dispatch(this.router.module + "/removeRecord", mask);
                }
            },
            async getSource() {
                if (this.recordSource) {
                    return { total: this.recordSource.length, data: this.recordSource };
                }

                if (this.paged) {
                    if (this.url) {
                        let response = await this.$axios.get(`${this.$config.config.endpoint.api}/services/tms/` + this.router.module +`/pagedList?page=${(this.page.current + 1)}&pageSize=${(this.page.size)}${(this.objectFilter.enumFilter && this.objectFilter.enumFilter > 1 ? "&status=" + this.objectFilter.enumFilter : "")}${(this.filterHeaderSource.data.dateFilter != null && this.showBookCollection ? "&collectiondate=" + this.$filters.formatDateWithFormat(this.filterHeaderSource.data.dateFilter, 'yyyy-MM-DD') : "")}`);
                        return { total: (response.data?.result ?? []).length, data: (response.data?.result ?? []) }
                    }
                    if (this.typed)
                        return this.$store.dispatch(this.router.module + "/getPagedWithType", { page: this.page.current + 1, pageSize: this.page.size, type: this.typeValue });
                    return this.$store.dispatch(this.router.module + "/getPaged", { page: this.page.current + 1, pageSize: this.page.size });
                }
                let data = []
                if (this.typed) {
                    this.$store.dispatch(this.router.module + "/getAllWithType", { type: this.typeValue }).then((response) => {
                        data = { total: (response.data?.result ?? response?.result ?? []).length, data: (response.data?.result ?? response?.result ?? []) };
                        this.source = data;
                    });
                    return data;
                }
                this.$store.dispatch(this.router.module + "/getAll").then((response) => {
                    data = response ?? this.$store.getters[this.router.module + "/ds"];
                });
                return data;
            },
            async onRefresh() {
                this.activity.loading = true;
                this.source = { data: [], total: 0 };
                this.activity.loading = true;
                this.getSource().then((response) => {
                    if (response !== undefined)
                        this.source = response?.data ?? response;
                });
                //                this.getSource().then((response) => { console.log(response); this.source = { total: (response?.result ?? []).length, data: response?.result ?? [] }; console.log(this.source); });
                //this.getSource().then(() => { this.source = this.dataSource; });


            },
            changePage(e) {
                this.page.current = e.page;
                this.page.first = e.first;
                this.page.size = e.rows;
                this.onRefresh();
                //                this.getSource().then(() => { (response) => { this.source = response; } });
            },
            filterPage(e) {
                this.page.current = e.page;
                this.page.first = e.first;
                this.page.size = e.rows;
                //              this.getSource().then(() => { this.source = this.dataSource; });
            },
            sortPage(e) {
                this.page.current = e.page;
                this.page.first = e.first;
                this.page.size = e.rows;
                //            this.getSource().then(() => { this.source = this.dataSource; });
            },
            rowClick(e) {
                this.selectedItems = this.selectedItems.filter((x) => x.mask !== e.data.mask);
                this.onEdit(e.data.mask);
            },
            deleteClick(id) {
                this.forDelete = this.source.data.filter(x => x.id == id)[0];
                this.dialog.delete = true;
            },
            onCreate() { this.$router.push({ name: `${this.router.name}-add` }); },
            onEdit(mask) { this.$router.push({ name: `${this.router.name}-edit`, params: { mask: mask } }); },
            onDelete(mask) {
                this.deleteRecord(mask).then(() => {
                    if (!this.errors)
                        this.$toast.add({ severity: 'success', summary: 'Record Deleted Sucessfully', detail: 'The record was successfully deleted', life: 3000 });
                    else {
                        this.$toast.add({ severity: 'error', summary: 'Record Delete Failed', detail: this.errors[0].message, life: 3000 });

                    }
                    this.dialog.delete = false;
                }).catch((err) => { console.log(err); });
            },
            onExport() {
                this.exportFilename = this.generateFilename();
                this.$refs[this.id].exportCSV();
            },
            async dispatch(mask, dispatchDate) {
                return await this.$store.dispatch("collection/getCustom", '/services/tms/collection/' + mask + '/dispatch/' + dispatchDate);
            },
            bookCollectionClick() {
                this.dialog.book = true;
                let data = this.consignmentToProcess[0];
                if (data != null) {
                    this.bookCollectionModel.firstName = data.shipFrom_FirstName;
                    this.bookCollectionModel.lastName = data.shipFrom_LastName;
                    this.bookCollectionModel.telephone = data.shipFrom_Telephone;
                    this.bookCollectionModel.mobile = data.shipFrom_Mobile;
                    this.bookCollectionModel.email = data.shipFrom_Email;
                    this.bookCollectionModel.company = data.shipFrom_Company;
                    this.bookCollectionModel.countryId = data.shipFrom_CountryId;
                    this.bookCollectionModel.address1 = data.shipFrom_Address1;
                    this.bookCollectionModel.address2 = data.shipFrom_Address2;
                    this.bookCollectionModel.address3 = data.shipFrom_Address3;
                    this.bookCollectionModel.city = data.shipFrom_City;
                    this.bookCollectionModel.state = data.shipFrom_State;
                    this.bookCollectionModel.postalCode = data.shipFrom_PostalCode;
                }
            },
            async onBookCollection() {
                let _self = this;
                let failed = false;
                for (const item of this.apiCollectRequests) {
                    await this.bookCollection(item).then((response) => {
                        if (response.data.Status !== "FAILED") {
                            _self.getSource();
                        }
                        else {
                            failed = true;
                            this.$toast.add({ severity: 'error', summary: 'Collection Booked Failed', detail: response.data.Errors[0].Description, life: 6000 });
                        }

                    }).catch((ex) => {

                        if (ex?.data?.Status === "FAILED") {
                            failed = true;
                            this.$toast.add({ severity: 'error', summary: 'Collection Booked Failed', detail: ex.data.Errors[0].Description, life: 6000 });
                        }
                        else {
                            failed = true;
                            this.$toast.add({ severity: 'error', summary: 'Collection Booked Failed', detail: "The book collection failed", life: 6000 });
                        }

                    });
                }
                if (!failed) {
                    this.dialog.book = false;
                    this.$toast.add({ severity: 'success', summary: 'Collection Booked Successfully', detail: "The collection successfully booked", life: 6000 });
                    this.selectedItems = [];
                    this.onRefresh();
                }
            },
            computeTotalConsignment(groupCollection) {
                let total = 0;
                if (this.selectedItems.length > 0) {
                    total = this.selectedItems.filter(x => x.groupCollection === groupCollection).length;
                    //for (let item of )
                    //    total++;
                }
                else {
                    
                    total = this.source.data.filter(x =>
                        x.groupCollection === groupCollection
                        && x.status === this.filterHeaderSource.data.enumFilter
                    //   // && x.warehouseId === this.activeFilters["warehouseId"].constraints[0].value
                       //&& x.request_CollectionDate.toString().startsWith(this.activeFilters["request_CollectionDate"].constraints[0].value)
                        && x.request_CollectionDate.toString().startsWith(this.$filters.formatDateWithFormat(this.filterHeaderSource.data.dateFilter, 'yyyy-MM-DD'))

                    ).length;
                }


                return total;
            },
            async bookCollection(request) {
                return await this.$axios.post(config.config.endpoint.api + `/api/polaris/1.0/collection`.replaceAll("//", "/"), request);
            },
            dispatchClick() {

                if (this.selectedItems.length === 0 || (this.selectedItems?.filter(x => x.status === 400).length ?? 0) === 0) {
                    this.$toast.add({ severity: 'error', summary: 'Please Select Record', detail: "Record selection is required", life: 6000 });
                }
                else {

                    this.dialog.dispatch = true;
                }
            },
            async onDispatch() {
                let _self = this;
                for (const item of this.selectedItems?.filter(x => x.status === 400)) {
                    await this.dispatch(item.mask, _self.$filters.formatDateWithFormat(_self.dispatchDate, 'yyyyMMDD')).then(() => {
                         //this.onRefresh();
                        _self.onRefresh();
                        this.selectedItems = [];
                    })
                }

                this.dialog.dispatch = false;
                this.$toast.add({ severity: 'success', summary: 'Collection Dispatched Successfully', detail: "The collection successfully dispatched", life: 6000 });
            },
            async pod(mask, podDate) {
                return await this.$store.dispatch("consignment/getCustom", '/services/tms/consignment/' + mask + '/pod/' + podDate);
            },
            podClick() {
                if (this.selectedItems.length === 0 || (this.selectedItems?.filter(x => x.status === 500).length ?? 0) === 0) {
                    this.$toast.add({ severity: 'error', summary: 'Please Select Record', detail: "Record selection is required", life: 6000 });
                }
                else {
                    this.dialog.pod = true;
                }
            },
            async onPOD() {
                let _self = this;
                for (const item of this.selectedItems?.filter(x => x.status === 500)) {
                    await this.pod(item.mask, _self.$filters.formatDateWithFormat(_self.podDate, 'yyyyMMDD')).then(() => {
                        //_self.getSource();
                        _self.onRefresh();
                        this.selectedItems = [];
                    })
                }
                this.dialog.pod = false;
                this.$toast.add({ severity: 'success', summary: 'Consignment POD Successfully', detail: "The Consignment successfully POD", life: 6000 });
                this.onRefresh();
            },
            async syncFilter() {
                await this.$emit("is-changefilter", this.filterHeaderSource);
            },
            translateContact(e) {
                this.bookCollectionModel.firstName = e.firstName;
                this.bookCollectionModel.lastName = e.lastName;
                this.bookCollectionModel.email = e.email;
                this.bookCollectionModel.mobile = e.mobile;
                this.bookCollectionModel.telephone = e.telephone;
                this.contact = {
                    firstName: this.bookCollectionModel.firstName,
                    lastName: this.bookCollectionModel.lastName,
                    email: this.bookCollectionModel.email,
                    mobile: this.bookCollectionModel.mobile,
                    telephone: this.bookCollectionModel.telephone
                };
            },
            translateAddress(e) {
                this.bookCollectionModel.address1 = e.address1;
                this.bookCollectionModel.address2 = e.address2;
                this.bookCollectionModel.address3 = e.address3;
                this.bookCollectionModel.city = e.city;
                this.bookCollectionModel.state = e.state;
                this.bookCollectionModel.postalCode = e.postalCode;
                this.bookCollectionModel.countryId = e.countryId;
                this.bookCollectionModel.company = e.companyName;
                this.bookCollectionModel.establishmentType = e.establishmentType;
                this.address = {
                    address1: this.bookCollectionModel.address1,
                    address2: this.bookCollectionModel.address2,
                    address3: this.bookCollectionModel.address3,
                    city: this.bookCollectionModel.city,
                    state: this.bookCollectionModel.state,
                    postalCode: this.bookCollectionModel.postalCode,
                    countryId: this.bookCollectionModel.countryId,
                    companyName: this.bookCollectionModel.company,
                    establishmentType: this.bookCollectionModel.establishmentType,
                };
            },
            onRowGroupExpand() {
                //    this.$toast.add({ severity: 'info', summary: 'Row Group Expanded', detail: 'Value: ' + event.data, life: 3000 });
            },
            onRowGroupCollapse() {
                //   this.$toast.add({ severity: 'success', summary: 'Row Group Collapsed', detail: 'Value: ' + event.data, life: 3000 });
            },
            groupRecordBy(xs, f) {
                return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
            }

        },
        beforeCreate() {
            this.type = this.typeValue;
        },
        created() {
            this.exportFilename = this.generateFilename();
            this.getRouter();
            this.$nextTick(() => { this.getSource().then(() => { this.source = this.dataSource; }) });
            this.activity.loading = false;
        },
        updated() {
            this.getRouter();
            this.type = this.typeValue;
        },
        mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            })
        },
        unmounted() {
            try {
                var session = JSON.parse(sessionStorage.getItem(`dt-state-${this.id}`));
                session.first = 0;
                sessionStorage.setItem(`dt-state-${this.id}`, JSON.stringify(session));
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>