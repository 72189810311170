<template>
    <form-record :model="model" :modelState="v$" :validation="v$" @is-reset="resetRecord" :showSend="false" :showClone="false" :title="model.id > 0 ? `[${model.code}]  ${model.name}` : `Add New Postal Code Bloc`" :subTitle="model.id > 0 ? `` : `Create a new postal code bloc.`">
        <div class="p-grid-flex p-d-flex">
            <div class="p-col-4">
                <form-input id="code" :v$="v$" v-focus v-uppercase :value="model.code" @is-sync="model.code = $event.value"></form-input>
                <form-input id="name" label="Name" :v$="v$" :value="model.name" @is-sync="model.name = $event.value"></form-input>
                <form-lookup id="countryID" type="autocomplete" source="country" label="Country" :value="model.countryID" @is-sync="model.countryID = $event.value" />
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12">
                <pv-tab style="margin: 0 -1rem !important;">
                    <pv-tabpanel header="Postal Codes">
                        <postalCode-form :parentID="model?.id" :formData="model" @is-sync="recordSync($event)"></postalCode-form>
                    </pv-tabpanel>
                </pv-tab>
            </div>
        </div>
    </form-record>
</template>
<script>

    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from '@vuelidate/validators';
    import postalCodeForm from '@/components/ruleengine/postalcode-form.vue';
    const moduleName = 'RulePostalBloc';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'postalCode-form': postalCodeForm
        },
        data() {
            return { test: false }
        },
        validations() {
            return {
                model: {
                    code: { required, minLength: minLength(1), maxLength: maxLength(20), $autoDirty: true },
                    name: { maxLength: maxLength(100), $autoDirty: true },
                    
                }
            }
        },
        computed: {
            scopeCustomerDS() {
                return this.$config.common.$customer(this.user?.environmentID ?? -100, this.$vm.$appSession.warehouse.id ?? this.user.warehouseID, this.user);
            },
            ds() { return this.$store.getters["rulepostalbloc/ds"]; },
            record() { return this.$store.getters["rulepostalbloc/record"]; }
            
        },
        methods: {
            async recordSync(e) {
                this.model.postalCodes = e.model;
            },
        },
        created() {
        },  
        async mounted() {
            this.activity.loading = false;
            if (this.model.id === 0)
                this.model.environmentID = this.$vm.$appSession.environment.id;
        }
    }
</script>
