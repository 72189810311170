<template>
    <div id="login-layout" tabindex="0">
        <div id='stars'></div>
        <div id='stars2'></div>
        <div id='stars3'></div>
        <div id="activate-content" class="p-grid">
            <div class="p-col-12" style="padding:0;">
                <div class="p-col p-text-center" style="margin: 15% auto !important;">
                    <div v-if="!processing  && success">
                        <h1 style="padding: 15px 0 0 0;"><span>Congratulations! Your account is now successfully activated!</span></h1>
                        <span>Account credential was sent to your e-mail address [{{email}}]. You may now login to COSMOS.</span>
                    </div>
                    <div v-if="processing">
                        <h1 style="padding: 15px 0 0 0;"><span>Verifying account please wait!</span></h1>
                    </div>
                    <div v-if="!processing  && !success">
                        <h1 style="padding: 15px 0 0 0;"><span>{{errorMessage}}</span></h1>
                        <span>Please contact System Administrator or Support Team.</span>
                    </div>
                    <h5><a href="/account/login" class="logo router-link-active">You will be redirected to Login Page in {{ countdown > 9 ? countdown : `0${countdown}` }}s...</a></h5>
                </div>
                <div class="p-text-right" style="position:absolute; bottom:15%; right:11%;">
                    <img src="@/assets/_img/_logo/dark_Integro360-block.png" style="max-height: 90px; max-width: 100%; object-fit: contain;" />
                </div>
            </div>
           
        </div>
    </div>
   
</template>
<style>
    #login-layout > #activate-content { 
        z-index: 1000; 
        opacity: 1 !important; 
        background-color: black; 
        margin: 1px auto;
        max-width: 100%;
        margin: auto 10%; 
        background: var(--surface-c); border-radius: 0; 
        border: 0.5px solid rgba(255, 255, 255, 0.1) !important; height: 70vh; min-height: 200px; min-width: 320px; }

</style>
<script>
    const config = require("@/assets/_js/cosmos-config.js").default;
    import { mapActions, mapGetters } from 'vuex';
    //import { useVuelidate } from "@vuelidate/core";
    export default {
        name: "AccountActivate",
        emits: ["is-sync"],
        //props: ['route'],
        //setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                processing: true,
                success: false,
                errorStatus: 100,
                errorMessage: "Activation link is invalid or expired.",
                email: null,
                countDownTimer: null,
                countdown: 10,
                flag: 0
            }
        },
        computed: mapGetters({
            user: "auth/currentUser",
            error: "auth/errorMessages"
        }),
        methods: {
            ...mapActions({ login: "auth/login" }),
            async activate(key) {
                if (this.processing) {
                    this.$axios
                        .get(config.api + `/services/settings/user/confirm/${key}`.replaceAll("//", "/"))
                        .then((response) => {
                            this.processing = false;
                            this.success = true;
                            if (response.data.result !== null) {
                                this.email = response.data.result.user.email;
                            }
                            this.countDownTimer = setTimeout(this.countTimer, 1 * 1000);
                            //this.activity.loading = false;
                        })
                        .catch((ex) => {
                            let _self = this;
                            this.processing = false;
                            //this.success = true;
                            this.success = false;
                            console.log(ex);
                            //this.activity.loading = false;
                            if (ex.data !== null) {
                                if (ex.data.errors.activationKey !== null) {
                                    ex.data.errors.activationKey.map(function (value) {
                                        if (value === "Account already verified!")
                                            _self.errorMessage = value;
                                    });
                                }

                            }
                            this.countDownTimer = setTimeout(this.countTimer, 1 * 1000);

                        });
                }
              
            },
            countTimer() {
                clearTimeout(this.countDownTimer);
                if (this.countdown > 1) {
                    this.countdown--;
                }
                else {
                    this.countdown = 0;
                    this.$router.push({ path: '/account/login' });
                   // this.forceLogout();
                }
                this.countDownTimer = setTimeout(this.countTimer, 1 * 1000);
            },
        },
        mounted() {
            if (this.user !== null && this.user !== undefined) {
                this.$emit('is-sync', true);
                this.$vm.$authenticated = true;
            }
            else {
                this.$emit('is-sync', false);
            }
             
            if (this.$route.params.key) {
                 this.activate(this.$route.params.key)       
            }
            else {
                this.processing = false;
                this.success = false;
                this.errorMessage = "Invalid Account Activation Link";
            }
        },
        umounted() {
             clearTimeout(this.countDownTimer);
        }
       
    }
</script>
