<template>
    <form-record :model="model" :modelState="v$" :validation="v$" @is-reset="resetRecord" :showSend="false" :showClone="false" :title="model.id > 0 ? `[${model.code}]  ${model.name}` : `Add New Holiday`" :subTitle="model.id > 0 ? `` : `Create a new holiday.`">
        <div class="p-grid-flex p-d-flex">
            <div class="p-col-4">
                <form-input id="code" :v$="v$" v-focus v-uppercase :value="model?.code" @is-sync="model.code = $event.value"></form-input>
                <form-input id="name" label="Name" :v$="v$" :value="model?.name" @is-sync="model.name = $event.value"></form-input>
                <form-datetime id="dateValue" :value="dateValue" type="date" @is-sync="model.date = $event.record.data;" label="Date" :v$="v$"></form-datetime>
                <form-lookup id="countryID" type="autocomplete" :disabled="disabled" source="country" label="Country" :v$="v$" :value="model.countryID" @is-sync="model.countryID = $event.value" />
                <form-lookup id="type" :required="true" :source="$vm.$enums['ruleholidaytype']" :value="model.type" label="Type" @is-sync="model.type = $event.value" :v$="v$"></form-lookup>
            </div>
        </div>   
    </form-record>
</template>

<script>

    //import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from '@vuelidate/validators';
   // import validator from '@/assets/_js/cosmos-validators.js';
  
    const moduleName = 'RuleHoliday';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return { test: false}
        },
        validations() {
            return {
                model: {
                 /*   code: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },*/
                    code: { required, minLength: minLength(1), maxLength: maxLength(20), $autoDirty: true },
                    name: { required, maxLength: maxLength(50), $autoDirty: true },
                    countryID: { required, $autoDirty: true },
                    type: { $autoDirty: true },
                    date: { required, $autoDirty: true }

                    
                }
            }
        },
        computed: {
            dateValue() {
                if (this?.model?.date)
                    return new Date(this.$filters.formatDate(this?.model?.date));
                return new Date();
            },
            companyDS() { return this.$store.getters["company/ds"]; },
            customerDS() {
                return  this.companyDS.data.filter((x) => (x.type === 16))
            },
            ds() { return this.$store.getters["ruleholiday/ds"]; },
            record() { return this.$store.getters["ruleholiday/record"]; }
            
        },
        methods: {
            async getCompanyList() {
                return await this.$store.dispatch("company/getAll", {sort :null, where: null});
            }
        },
        created() {
           // this.model = this.init;
           // await this.$store.dispatch("addressbook/getAll");   
        },  
        mounted() {
            this.getCompanyList();
            this.activity.loading = false;
            if (this.model?.id === 0) {
                this.model.date = new Date();
                this.model.dateValue = new Date();
            }
            else {
                this.model.dateValue = new Date(this.model.date);
            }
            //if (this.model.id === 0)
            //    this.model.environmentId = this.$vm.$appSession.environment.id;
        }
    }
</script>
