<template>
    <keep-alive>
        <is-dialog header="PACKAGE" :visible="dialog.delete && (dialog.edit || dialog.add)" @is-confirm="onManagePackage(model)" @is-cancel="this.dialog.delete = false;this.dialog.edit = false;this.dialog.add = false" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '75vw'}">
            <div class="confirmation-content p-fluid p-grid">
                <div class="p-col-12">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-6">
                            <h6>PACKAGE DETAILS</h6>
                            <!--<form-input id="code" label="Carton No" :required="true" :v$="v$" :value="model.code" @is-sync="model.code = $event.value" placeholder="Auto number if not provided." v-focus />-->
                            <form-input id="name" label="Content" :required="true" :v$="v$" :value="model.name" @is-sync="model.name = $event.value" placeholder="Short Description for Custom purposes." />
                            <h5>WEIGHT & DIMENSION</h5>
                            <form-numeric id="weight" :value="model.weight" :required="true" :v$="v$" label="Weight" decimalDigit="3" @is-sync="model.weight = $event.value" type="decimal" :suffix="weightSymbol"></form-numeric>
                            <div class="p-grid">
                                <div class="p-md-4">
                                    <form-numeric id="length" :value="model.length" :v$="v$" label="Length" decimalDigit="2" @is-sync="model.length = $event.value" type="decimal" :suffix="dimensionSymbol"></form-numeric>
                                </div>
                                <div class="p-md-4">
                                    <form-numeric id="width" :value="model.width" :v$="v$" label="Width" decimalDigit="2" @is-sync="model.width = $event.value" type="decimal" :suffix="dimensionSymbol"></form-numeric>
                                </div>
                                <div class="p-md-4">
                                    <form-numeric id="height" :value="model.height" :v$="v$" label="Height" decimalDigit="2" @is-sync="model.height = $event.value" type="decimal" :suffix="dimensionSymbol"></form-numeric>
                                </div>
                            </div>
                            <h6>Others</h6>
                            <form-lookup id="additionalHandling" :required="false" :source="$vm.$enums['packageadditionalhandlingtype']" :value="model.additionalHandling" label="Additional Handling" @is-sync="model.additionalHandling = $event.value" :v$="v$"></form-lookup>
                        </div>
                        <div class="p-col-12 p-md-6">
                            <h6>DECLARATIONS</h6>
                            <form-numeric id="consignmentValue" :required="true" :value="model.consignmentValue" label="Declared Value" decimalDigit="2" @is-sync="model.consignmentValue = $event.value" type="currency" :currency="currencyCode" :v$="v$"></form-numeric>
                            <form-numeric id="insuranceValue" :value="model.insuranceValue" label="Insurance Value" decimalDigit="2" @is-sync="model.insuranceValue = $event.value" type="currency" :currency="currencyCode" :v$="v$"></form-numeric>
                            <form-numeric id="dutyValue" :value="model.dutyValue" label="Duty Paid Value" decimalDigit="2" @is-sync="model.dutyValue = $event.value" type="currency" :currency="currencyCode" :v$="v$"></form-numeric>
                            <form-numeric id="taxValue" :value="model.taxValue" label="Tax Paid Value" decimalDigit="2" @is-sync="model.taxValue = $event.value" type="currency" :currency="currencyCode" :v$="v$"></form-numeric>
                        </div>
                    </div>

                </div>
            </div>
        </is-dialog>
    </keep-alive>
    <is-dialog header="Delete Record?" :visible="dialog.delete && !dialog.edit && !dialog.add" @is-confirm="onManagePackage(model)" @is-cancel="this.dialog.delete = false; this.dialog.add = false; this.dialog.edit = false;">
        <div class="confirmation-content p-d-flex">
            <div>
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            </div>
            <div>
                You are about to delete this record:

                <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                    <tr v-for="data in deleteDisplay" :key="data.id">
                        <th style="text-align:left;">{{ $filters.titleize(data) }}</th>
                        <td>:</td>
                        <td> {{ forDeletePackage[data] }}</td>
                    </tr>
                </table>

                Please be aware of the following:
                <ul>
                    <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                    <li>For records that has activity, the record will only be disabled and archived.</li>
                    <li>Also deleting a record may be irreversible action.</li>
                </ul>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>

    <cosmos-grid id="DefaultPackageList" sourceType="array" :source="source?.data ?? []" :sourcePaged="true" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showExport="false" :showDelete="false" :showEdit="false" :showHeader="true" :showOperation="false">
        <template #toolbar>
            <div class="p-d-flex p-jc-between p-ai-center">
                <span>
                    <pv-button v-if="!(limitedAccess)" icon="pi pi-fw pi-plus" label="Add Record" style="margin-right: 5px;" @click="onPackageCreate"></pv-button>
                </span>
                <!--<span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                    <i class="pi pi-search" />
                    <pv-input v-model="activeFilters['global'].value" placeholder="Keyword Search" style="width: 100%;" @keyup="onForceFilter" />
                </span>-->
            </div>
        </template>
        <pv-grid-column field="code" header="Package Detail" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%">
                        <div class="p-grid">
                            <div class="p-d-flex p-jc-between" style="z-index: 100;">
                                <div>
                                    <h5 style="margin-bottom: 5px; margin-top: 0;"><span>{{ slotProps.data.name }}</span></h5>
                                </div>
                            </div>
                            <div class="p-d-flex p-grid p-col-12" style="margin-left:-15px;">
                                <div class="p-col-12 p-md-4">
                                    <table style="width:100%;padding:0;">
                                        <tr>
                                            <td style="font-weight: bold">DIMENSION</td>
                                            <td width="10px">:</td>
                                            <td style="text-align:right;">{{slotProps.data.length}} {{dimensionSymbol}} x {{slotProps.data.width}} {{dimensionSymbol}} x {{slotProps.data.height}} {{dimensionSymbol}}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-weight: bold">WEIGHT</td>
                                            <td width="10px">:</td>
                                            <td style="text-align:right;">{{formatDecimal(slotProps.data.weight,3)}} {{weightSymbol}}</td>
                                        </tr>
                                        <tr v-if="slotProps.data?.additionalHandling">
                                            <td style="font-weight: bold">ADDITIONAL HANDLING</td>
                                            <td width="10px">:</td>
                                            <td style="text-align:right;">{{ this.$vm.$enums['packageadditionalhandlingtype'].filter((x) => x.id == slotProps.data?.additionalHandling)[0]?.text}}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="p-col-12 p-md-4">
                                    <table style="width:100%;padding:0;">
                                        <tr>
                                            <td style="font-weight: bold">DECLARED VALUE</td>
                                            <td width="10px">:</td>
                                            <td style="text-align:right;">{{formatCurrency(slotProps.data.consignmentValue ?? 0)}}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-weight: bold">INSURANCE VALUE</td>
                                            <td width="10px">:</td>
                                            <td style="text-align:right;">{{formatCurrency(slotProps.data.insuranceValue ?? 0)}}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-weight: bold">DUTY PAID VALUE</td>
                                            <td width="10px">:</td>
                                            <td style="text-align:right;">{{formatCurrency(slotProps.data.dutyValue ?? 0)}}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-weight: bold">TAX PAID VALUE</td>
                                            <td width="10px">:</td>
                                            <td style="text-align:right;">{{formatCurrency(slotProps.data.taxValue ?? 0)}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="mask" headerStyle="min-width: 7rem; max-width: 7rem; width: 7rem" style="`min-width: 7rem; max-width: 7rem; border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-d-flex" style="width: 2rem;">
                    <div class="p-col">
                        <pv-button :disabled="limitedAccess" icon="pi pi-pencil" iconPos="right" @click="onPackageEdit(slotProps.data)" class="p-button-info" />
                    </div>
                    <div class="p-col">
                        <pv-button :disabled="limitedAccess" icon="pi pi-trash" iconPos="right" @click="onPackageDelete(slotProps.data)" class="p-button-danger" />
                    </div>
                    <slot name="operation" :data="slotProps.data">
                    </slot>
                </div>
            </template>
        </pv-grid-column>
    </cosmos-grid>
</template>
<style>
</style>
<script>
    import { mapGetters } from 'vuex';
    const config = require('@/assets/lib/cosmos/_js/config.js').default.config;
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    
    import { useVuelidate } from '@vuelidate/core';
   
    import { FilterMatchMode } from 'primevue/api';
    const moduleName = 'companydefaultpackage';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        props: {
            parentID: { type: Number, default: -1 },
            formData: { type: Object },
        },
        data() {
            return {
                id: "pageckagetemplate",
                expandedRows: [],
                editingRows: [],
                originalRows: null,

                filename: 'export',
                filter: {},
                filters: {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                },
                searchFilter: ['code', 'name'],
                paged: false,
                typed: false,
                typeValue: -1,
                lazyLoading: false,
                pagination: true,
                pageSize: 10,
                multiSelect: true,
                csvSeparator: ',',
                autoGenerate: true,
                columns: [],
                operationWidth: 8,
                showAdd: true,
                showExport: true,
                showEdit: true,
                showDelete: true,
                showOperation: true,
                deleteDisplayFields: [],
                groupBy: null,
                recordSource: [],
                items: null,
                forDelete: null,
                exportFilename: null,
                selectedItems: [],
                activity: { loading: true },
                dialog: {
                    header: "Delete Record?",
                    add: false,
                    edit: false,
                    delete: false
                },
                forDeletePackage: null,
                forDeleteItem: null,
                deleteDisplay: ["code", "name",],
                itemDialog: {
                    header: "Delete Record?",
                    add: false,
                    edit: false,
                    delete: false
                },
                source: {
                    total: 0,
                    data: []
                }

            }
        },
        watch: {
            v$: {
                handler() { this.modelState = this.v$ }, deep: true
            },
            //'formData': {
            //    handler() {
            //        if ((this.source?.data ?? []).length === 0 && this.formData?.id > 0) {
            //            this.getPackages();
            //        }
            //    }, deep: true
            //},
        },
        validations() {
            return {
                model: {
                    /*   code: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },*/
                    //code: { required, minLength: minLength(1), maxLength: maxLength(50), $autoDirty: true },
                    //name: { required, minLength: minLength(1), maxLength: maxLength(50), $autoDirty: true },
                    //weight: { required, decimal, between: between(0.001, 9999999999.999), $autoDirty: true },
                    //consignmentValue: { required, decimal, between: between(0.01, 9999999999.99), $autoDirty: true },
                    //length: { decimal, between: between(0.00, 9999999999.99), $autoDirty: true },
                    //width: { decimal, between: between(0.00, 9999999999.99), $autoDirty: true },
                    //height: { decimal, between: between(0.00, 9999999999.99), $autoDirty: true }
                }
                //,
                //itemModel: {
                //    code: { required, minLength: minLength(1), maxLength: maxLength(50), $autoDirty: true },
                //    name: { required, minLength: minLength(1), maxLength: maxLength(50), $autoDirty: true },
                //    weight: { required, decimal, between: between(0.001, 9999999999.999), $autoDirty: true },
                //    consignmentValue: { required, decimal, between: between(0.01, 9999999999.99), $autoDirty: true },
                //    length: { decimal, between: between(0.00, 9999999999.99), $autoDirty: true },
                //    width: { decimal, between: between(0.00, 9999999999.99), $autoDirty: true },
                //    height: { decimal, between: between(0.00, 9999999999.99), $autoDirty: true }
                //}
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            limitedAccess() {
                return ((this.user?.userType ?? 0) === this.userTypes.ENVIRONMENT_USER) || ((this.user?.userType ?? 0) === this.userTypes.CUSTOMER_USER) || ((this.user?.userType ?? 0) === this.userTypes.RETAILER_USER);
            },
            userTypes() {
                return {
                    GLOBAL_ADMINISTRATOR: 0,
                    SYSTEM_ADMINISTRATOR: 2,
                    ENVIRONMENT_ADMINISTRATOR: 3,
                    ENVIRONMENT_USER: 4,
                    CUSTOMER_ADMINISTRATOR: 5,
                    CUSTOMER_USER: 6,
                    RETAILER_USER: 7
                }
            },
            isViewOnly() { return this.consignment.status > 100 },
            countryDS() { return this.$store.getters["country/ds"]; },
            currencyDS() { return this.$store.getters["currency/ds"]; },
            currencySymbol() { return this.$vm.currencies.filter((x) => x.id === this.$props.formData?.generalSetting?.default_CurrencyID ?? 3)[0].symbolText; },
            currencyCode() {
                return this.$vm.$currencies.filter((x) => x.id === this.$props.formData?.generalSetting?.default_CurrencyID ?? 3)[0]?.code;
            },
            weightSymbol() { return this.$vm.$enums['weightunit'].filter((x) => x.id == this.$props.formData?.generalSetting?.default_WeightUnit)[0]?.text },
            dimensionSymbol() { return this.$vm.$enums['lengthunit'].filter((x) => x.id == this.$props.formData?.generalSetting?.default_LengthUnit)[0]?.text },
            packageRecord() { return this.$store.getters["companydefaultpackage/record"]; },
            packageErrors() { return this.$store.getters["companydefaultpackage/errors"]; }
            
        },
        methods: {
            async update(modulename, model) { return await this.$store.dispatch(modulename + "/modifyRecord", model); },
            async insert(modulename, model) { return await this.$store.dispatch(modulename + "/addRecord", model); },
            async delete(modulename, mask) { return await this.$store.dispatch(modulename + "/deleteRecordByMask", mask); },
            async getEntity(modulename, mask) { return await this.$store.dispatch(modulename + "/getRecord", mask); },
            async getPackages() {
                if (this.$props.formData?.id > 0) {
                    await this.$axios
                        .get(config.endpoint.api + `/services/settings/` + moduleName + `/customer/id/${this.$props.formData?.id}`.replaceAll("//", "/"))
                        .then((response) => {
                            this.source.data = response.data.result;
                            this.source.total = response.data.result.length;
                        })
                        .catch((ex) => { this.errors = ex.data; });
                }
                
            },
            onRowExpand() {
            },
            onRowCollapse() {
            },
            expandAll() {
                this.expandedRows = this.source.data.filter(p => p.id);
            },
            collapseAll() {
                this.expandedRows = null;
            },
            onRowEditInit(event) {
                this.originalRows[event.index] = { ...this.source.data[event.index] };
            },
            onRowEditCancel(event) {
                this.source.data[event.index] = this.originalRows[event.index];
            },
            onExport() {

            },
            rowClick(e) {
                if (this.isDisabled)
                    e.preventDefault();
                this.onPackageEdit(e.data);
            },
            onPackageCreate() {
                this.dialog.header = "Add Default Package";
                this.model = {
                    id: 0,
                    temporaryID: Number(Math.random().toString().replace(".", "")),
                    customerID: this.$props.formData.id,
                    consignmentValue: 1,
                    insuranceValue: 0,
                    dutyValue: 0,
                    taxValue: 0,
                    customsValue: 0,
                    weight: 1,
                    netWeight: 0,
                    length: 0,
                    width: 0,
                    height: 0,
                    additionalHandling: null,
                    environmentID: null,
                    code: ('TEMP' + Math.random().toString().replace(".", "")).slice(0,20),
                    name: null,
                    mask: null
                    
                },
                this.dialog.delete = true;
                this.dialog.add = true;
            },
            onPackageEdit(e) {
                this.model = this.$filters.init(e);
                this.dialog.delete = true;
                this.dialog.edit = true;
            },
            onPackageDelete(data) {
                this.model = this.$filters.init(data);
                this.dialog.delete = true;
                this.forDeletePackage = this.source.data.filter(x => this.model.mask != null ? x.id === this.model.id : x.temporaryID === this.model.temporaryID)[0];
            },
            onManagePackage(e) {
                var location = this.$filters.init(e);
                var index = -1;
                if (location.id > 0)
                    index = this.source.data.findIndex(x => x.id === location.id);
                else
                    index = this.source.data.findIndex(x => x.temporaryID === location.temporaryID);
                if (index === -1) {
                    this.source.data.push(this.$filters.init(e));
                    if (this.$route.params.mask ?? this.is_parentMask) {
                        this.insert("companydefaultpackage", this.model).then(() => {
                            if (this.packageErrors)
                                this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: this.packageErrors[0].message, life: 3000 });
                            else {
                                index = this.source.data.findIndex(x => x.temporaryID === location.temporaryID);
                                this.getEntity("companydefaultpackage", this.packageRecord.mask).then(() => {
                                    this.source.data[index] = this.$filters.init(this.packageRecord);
                                    this.source.total = this.source.data.length;
                                });
                                //this.$toast.add({ severity: 'success', summary: 'Record Insert Success!', detail: 'The record was added successfully.', life: 3000 });
                            }
                        }).catch((err) => {
                            this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: err.xhr, life: 3000 });
                        });
                    }
                }
                else {
                    if (this.dialog.edit || this.dialog.add) {
                        this.source.data[index] = this.$filters.init(e);
                        if (location.mask !== null && location.mask !== "") {
                            this.update("companydefaultpackage", this.model).then(() => {
                                if (this.errors)
                                    this.$toast.add({ severity: 'error', summary: 'Record Update Failed!', detail: this.errors[0].message, life: 3000 });
                                
                            }).catch((err) => {
                                err;
                                //this.$toast.add({ severity: 'error', summary: 'Record Update Failed!', detail: err.xhr, life: 3000 });
                            });
                        }
                    }
                    else {
                        this.source.data.splice(index, 1);
                        if (location.mask !== null && location.mask !== "") {
                            this.delete("companydefaultpackage", location.mask).then(() => {
                                if (this.errors)
                                    this.$toast.add({ severity: 'error', summary: 'Record Delete Failed!', detail: this.errors[0].message, life: 3000 });
                               
                            }).catch((err) => {
                                this.$toast.add({ severity: 'error', summary: 'Record Delete Failed!', detail: err.xhr, life: 3000 });
                            });
                        }
                    }
                }
                this.source.total = this.source.data.length;
                this.model = {
                    id: 0,
                    temporaryID: null,
                    customerID: 0,
                    consignmentValue: 0,
                    insuranceValue: 0,
                    dutyValue: 0,
                    taxValue: 0,
                    customsValue: 0,
                    weight: 0,
                    netWeight: 0,
                    length: 0,
                    width: 0,
                    height: 0,
                    additionalHandling: null,
                    environmentID: null,
                    code: null,
                    name: null,
                    mask: null
                },
                this.dialog.delete = false;
                this.dialog.edit = false;
                this.dialog.add = false;
                this.dialog.header = "Delete Record?";
            },
            formatCurrency(value) {
                
                return value.toLocaleString('en-US', { style: 'currency', currency: this.currencyCode ?? "USD" });
            },
            formatDecimal(value, decimalPart) {
                return Number(value ?? 0).toFixed(decimalPart ?? 2);
            },
        },
        created() {
            if ((this.source?.data ?? []).length === 0 && this.formData?.id > 0) {
                this.getPackages();
            }
        },
        mounted() {
            if ((this.source?.data ?? []).length === 0 && this.formData?.id > 0) {
                this.getPackages();
            }
        }

    }
</script>

<style>

    div.image-container > img {
        width: 75px !important;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        margin-right: 1rem;
    }
    .image-center {
        display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;
        width: 50% !important;
    }
</style>