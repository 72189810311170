<template>
    
    <pv-card>
        <template v-slot:title>
            Rules and Conditions
        </template>
        <template v-slot:subtitle>
            Please provide rule details.
        </template>
        <template v-slot:content>
            <rule-form :serviceGroupID="model?.id" :warehouseScopes="warehouses ?? []" :formData="model" @is-sync="isSync($event)"></rule-form>
        </template>
        <template v-slot:footer>
            <div class="p-grid p-nogutter p-justify-between">
                <pv-button label="Back" @click="prevPage()" icon="pi pi-angle-left" />
                <pv-button label="Next" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" :disabled="!isValid" />
            </div>
        </template>
    </pv-card>
</template>
<script>
    //import validator from '@/assets/_js/cosmos-validators.js';
    import { useVuelidate } from '@vuelidate/core';
    //import { required, minLength, maxLength, between, decimal } from '@vuelidate/validators';
    import { required, minLength, maxLength } from '@vuelidate/validators';
    import { mapGetters } from 'vuex';
    
    import ruleForm from '@/components/ruleengine/rule-form.vue';
    export default {
        name: 'ServiceRuleTemplate',
        data() {
            return {
                model:null,
                source: {
                    total: 0,
                    data: []
                },
                accessScopes: []
            }
        },
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'rule-form': ruleForm
        },
        validations() {
            return {
                model: {
                    /*   code: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },*/
                    code: { required, minLength: minLength(1), maxLength: maxLength(50), $autoDirty: true },
                }
                //}
                //,itemModel: {
                //    code: { required, minLength: minLength(1), maxLength: maxLength(50), $autoDirty: true },
                //    name: { required, minLength: minLength(1), maxLength: maxLength(50), $autoDirty: true },
                //    weight: { required, decimal, between: between(0.001, 9999999999.999), $autoDirty: true },
                //    consignmentValue: { required, decimal, between: between(0.01, 9999999999.99), $autoDirty: true },
                //    length: { decimal, between: between(0.00, 9999999999.99), $autoDirty: true },
                //    width: { decimal, between: between(0.00, 9999999999.99), $autoDirty: true },
                //    height: { decimal, between: between(0.00, 9999999999.99), $autoDirty: true },
                //    quantity: { required, between: between(1, 9999999999), $autoDirty: true },
                //}
            }
        },
        watch: {
            v$: { handler() { this.modelState = this.v$ }, deep: true },
            $route(to) {
                if (to.name === "servicegroup-rule") {
                    if (this.user?.mask)
                        this.accessScopes = this.$config.common.$accessScope(this.user?.mask);
                }

            },
        },
        computed: {
            isValid() {
                return ((this.source.data.length ?? 0) > 0);
            },
            ...mapGetters({
                user: "auth/currentUser"
            }),
            warehouses() {
                return (this.accessScopes?.warehouses ?? [])
                    .filter(wh => (this.model.customerID ?? 0) === 0 || ((this.model.customerID ?? 0) > 0 && (wh.customers ?? []).filter(cust => cust === (this.model.customerID ?? 0)).length > 0)) ?? [];
            },
        },
        methods: {
            isSync(e) {
                this.$nextTick(() => {
                    this.source = e.model;
                }); 
            },
            nextPage() {
                if (this.isValid) {
                    this.$emit('next-page', { formData: { rules: this.source.data }, pageIndex: 2 });
                }
            },
            prevPage() {
                this.$emit('prev-page', { pageIndex: 2 });
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.model = this.$attrs.formData;
                if (this.user?.mask)
                    this.accessScopes = this.$config.common.$accessScope(this.user?.mask);
            });

        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>