<template>
    <div class="p-d-flex p-flex-column p-flex-lg-row" id="PackageLines">
        <div class="flex p-nogutter p-mr-2">
            <div class="p-d-flex">
                <div class="flex p-nogutter">
                    <form-numeric id="id" :value="model.id" :disabled="true" :showButton="false" :hideLabel="model.id > 1"
                                  label="#" style="width: 3rem; text-align: center; border-radius: 5px 0 0 5px !important" :show-copy="false" />
                </div>
                <!--<div class="flex p-nogutter">
                    <form-numeric id="chargeableWeight" :label="chargeableWeightLabel" type="decimal" :decimalDigit="3" :hideLabel="model.id > 1" :disabled="true" style="width: 10rem;" :value="chargeableWeight" />
                </div>-->
                <div class="flex p-nogutter">
                    <form-numeric id="actualWeight" :label="actualWeightLabel" type="decimal" :decimalDigit="3"
                                  :min="0.100" :max="99999.999" :hideLabel="model.id > 1"
                                  :value="model.actualWeight" @is-sync="model.actualWeight = $event.value" style="width: 10rem; border-radius: 0 !important" />
                </div>
            </div>
        </div>
        <div class="flex p-nogutter p-mr-2">
            <div class="p-d-flex">
                <div class="flex p-nogutter">
                    <form-numeric id="length" :hideLabel="model.id > 1" :max="9999.999" label="Dimension [L x W x H]" type="decimal" :decimalDigit="3" style="width: 10rem;" :value="model.length ?? 0" @is-sync="model.length = $event.value ?? 0" />
                </div>
                <div class="flex p-nogutter">
                    <form-numeric id="width" :hideLabel="model.id > 1" :max="9999.999" :label="`(<span style='color: var(--text-danger)'>${dimensionUnitText}</span>)`" type="decimal" :decimalDigit="3" style="width: 10rem;" :value="model.width ?? 0" @is-sync="model.width = $event.value ?? 0" />
                </div>
                <div class="flex p-nogutter">
                    <form-numeric id="height" :hideLabel="true" type="decimal" :max="9999.999" :decimalDigit="3" style="width: 10rem;" :value="model.height ?? 0" @is-sync="model.height = $event.value ?? 0" />
                </div>
            </div>
        </div>
        <div class="flex p-nogutter p-mr-2">
            <div class="p-d-flex">
                <div class="flex p-nogutter">
                    <form-numeric id="declaredValue" :hideLabel="model.id > 1" type="currency" :currency="units.currency" :min="1" :value="model.declaredValue < 1 ? 1 : model.declaredValue" @is-sync="model.declaredValue = ($event.value ?? 0) < 1 ? 1 : ($event.value ?? 1) " style="width: 10rem;" />
                </div>
                <div class="flex p-nogutter">
                    <form-numeric id="insuranceValue" :hideLabel="model.id > 1" type="currency" :currency="units.currency" :value="model.insuranceValue"  @is-sync="model.insuranceValue = $event.value ?? 1" style="width: 10rem;" />
                </div>
            </div>
        </div>
        <div class="flex p-nogutter p-mr-2">
            <div class="p-d-flex">
                <div class="flex p-nogutter">
                    <form-lookup id="dangerousGoods" :floatLabel="false" :hideLabel="model.id > 1" type="enum" source="dangerousgoodstype" :value="model.dangerousGoods" @is-sync="{ model.dangerousGoods = $event.value ?? 0; }" style="width: 15rem;" placeholder="No Dangerous Goods" />
                </div>
                <div class="flex p-nogutter">
                    <form-lookup id="specialHandling" :floatLabel="false" :hideLabel="model.id > 1" type="enum" source="packageadditionalhandlingtype" :value="model.specialHandling" @is-sync="{ model.specialHandling = $event.value ?? 0; }" style="width: 13rem;" placeholder="Standard Handling" />
                </div>
            </div>
        </div>
        <div class="flex p-nogutter p-mr-2">
            <form-numeric id="identicalPackage" :hideLabel="model.id > 1" :min="1" :max="maxPackage" :value="model.packageCount <= 0 ? 1 : model.packageCount" @is-sync="model.packageCount = $event.value" style="width: 8.5rem;" />
        </div>
        <div class="flex p-nogutter">
            <div class="p-buttonset" style="height: 2rem; min-width: 12rem;">
                <pv-button v-if="itemCount !== index" class="p-button p-button-lg" icon="pi pi-arrow-down" title="Copy to next item..." @click="onCopyNext(this.model)" style="width: 3rem; border-right: 1px solid var(--surface-f)"></pv-button>
                <pv-button v-else :class="[{'p-button': true }, { 'p-button-lg': true }, { 'p-button-info': true }, { 'p-disabled': disableAdd}]" icon="pi pi-plus" title="Add New Package" @click="onAdd()" style="width: 3rem; border-right: 1px solid var(--surface-f)"></pv-button>
                <pv-button v-if="itemCount > 1" class="p-button p-button-lg " icon="pi pi-copy" title="Copy to ..." @click="onBeforeCopy('copy')" style="width: 3rem; border-right: 1px solid var(--surface-f)"></pv-button>
                <pv-button v-if="itemCount > 1" class="p-button p-button-lg " icon="pi mdi mdi-file-replace-outline" title="Paste from ..." @click="onBeforeCopy('paste')" style="width: 3rem; border-right: 1px solid var(--surface-f)"></pv-button>
                <pv-button v-if="itemCount > 1" class="p-button p-button-lg p-button-danger" icon="pi pi-trash" title="Delete Current Package" @click="dialog.delete = true" style="width: 3rem; border-right: 1px solid var(--surface-f)"></pv-button>
            </div>
        </div>
    </div>
    <is-dialog :header="(copy.type === 'copy' ? 'Copy To...' : 'Paste From...')" :style="{width: '50vw'}" :visible="dialog.copy" @is-confirm="onCopyValue(item.id, copy.selected)" @is-cancel="onCancel" :confirmDisabled="copy.selected <= 0">
        <div class="confirmation-content p-d-flex">
            <div>
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            </div>
            <div style="width: 50vw">
                Copy this record to the package number to the right:
                <div style="margin: 10px 0; padding: 10px; background-color: var(--surface-d);">
                    <div class="p-grid">
                        <div class="p-col-fixed" style="width: 12rem; font-weight: bold;">Package #</div>
                        <div class="p-col p-code-text">: {{ model.id }}</div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-fixed" style="width: 12rem; font-weight: bold;">Declare Weight</div>
                        <div class="p-col p-code-text">: {{ model.actualWeight.toFixed(3) }} {{ $filters.quantifiedDisplay(weightUnitText, model.actualWeight.toFixed(3))  }}</div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-fixed" style="width: 12rem; font-weight: bold;">Dimension (L x H x W)</div>
                        <div class="p-col p-code-text">: {{ model.length.toFixed(3) }} {{ header.dimensionUnitText ?? 'cm' }} x {{ model.height.toFixed(3) }} {{ header.dimensionUnitText ?? 'cm' }} x {{ model.width.toFixed(3) }} {{ header.dimensionUnitText ?? 'cm' }}</div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-fixed" style="width: 12rem; font-weight: bold;">Volumetric Weight</div>
                        <div class="p-col p-code-text">: {{ volumetricWeight.toFixed(3) }} {{ $filters.quantifiedDisplay(weightUnitText, volumetricWeight.toFixed(3))  }}</div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-fixed" style="width: 12rem; font-weight: bold;">Chargeable Weight</div>
                        <div class="p-col p-code-text">: {{ chargeableWeight.toFixed(3) }} {{ $filters.quantifiedDisplay(weightUnitText, chargeableWeight.toFixed(3))  }}</div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-fixed" style="width: 12rem; font-weight: bold;">Parcel Count</div>
                        <div class="p-col p-code-text">: {{ model.packageCount }}</div>
                    </div>
                </div>
                <div style="margin-top: 20px;">
                    <form-lookup id="parcelSource"
                                 :label="(copy?.type === 'copy' ? 'Copy To...' : 'Will be replaced with...')"
                                 :source="packageNumbers"
                                 sourceType="array"
                                 :hideLabel="index > 1"
                                 :showClear="false"
                                 :sorted="false"
                                 :value="copy?.selected" @is-sync="select($event.value)" />
                    <div v-if="(copy.selected > 0 && copy.selected <= this.itemCount && copy.target)" class="p-col" style="margin: 10px 0; padding: 10px; background-color: var(--surface-d);">
                        <div class="p-grid">
                            <div class="p-col-fixed" style="width: 12rem; font-weight: bold;">Package #</div>
                            <div class="p-col p-code-text">: {{ copy.target.id }}</div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-fixed" style="width: 12rem; font-weight: bold;">Declare Weight</div>
                            <div class="p-col p-code-text">: {{ copy.target.actualWeight.toFixed(3) }} {{ $filters.quantifiedDisplay(weightUnitText, copy.target.actualWeight.toFixed(3))  }}</div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-fixed" style="width: 12rem; font-weight: bold;">Dimension (L x H x W)</div>
                            <div class="p-col p-code-text">: {{ copy.target.length.toFixed(3) }} {{ copy.target.dimensionUnitText ?? 'cm' }} x {{ copy.target.height.toFixed(3) }} {{ header.dimensionUnitText ?? 'cm' }} x {{ copy.target.width.toFixed(3) }} {{ header.dimensionUnitText ?? 'cm' }}</div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-fixed" style="width: 12rem; font-weight: bold;">Volumetric Weight</div>
                            <div class="p-col p-code-text">: {{ volumetricWeight.toFixed(3) }} {{ $filters.quantifiedDisplay(weightUnitText, volumetricWeight.toFixed(3))  }}</div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-fixed" style="width: 12rem; font-weight: bold;">Chargeable Weight</div>
                            <div class="p-col p-code-text">: {{ chargeableWeight.toFixed(3) }} {{ $filters.quantifiedDisplay(weightUnitText, chargeableWeight.toFixed(3))  }}</div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-fixed" style="width: 12rem; font-weight: bold;">Parcel Count</div>
                            <div class="p-col p-code-text">: {{ copy.target.packageCount }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </is-dialog>
    <is-dialog header="Delete Record?" :visible="dialog.delete" :style="{width: '32vw'}" @is-confirm="onDelete(this.model.id)" @is-cancel="onCancel">
        <div class="confirmation-content p-d-flex" :style="{width: '27vw'}" >
            <div>
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            </div>
            <div>
                You are about to delete this record:
                <div style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 25vw;">
                    <div class="p-grid">
                        <div class="p-col-fixed" style="width: 12rem; font-weight: bold;">Package #</div>
                        <div class="p-col p-code-text">: {{ model.id }}</div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-fixed" style="width: 12rem; font-weight: bold;">Declare Weight</div>
                        <div class="p-col p-code-text">: {{ model.actualWeight.toFixed(3) }} {{ $filters.quantifiedDisplay(weightUnitText, model.actualWeight.toFixed(3))  }}</div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-fixed" style="width: 12rem; font-weight: bold;">Dimension (L x H x W)</div>
                        <div class="p-col p-code-text">: {{ model.length.toFixed(3) }} {{ header.dimensionUnitText ?? 'cm' }} x {{ model.height.toFixed(3) }} {{ header.dimensionUnitText ?? 'cm' }} x {{ model.width.toFixed(3) }} {{ header.dimensionUnitText ?? 'cm' }}</div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-fixed" style="width: 12rem; font-weight: bold;">Volumetric Weight</div>
                        <div class="p-col p-code-text">: {{ volumetricWeight.toFixed(3) }} {{ $filters.quantifiedDisplay(weightUnitText, volumetricWeight.toFixed(3))  }}</div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-fixed" style="width: 12rem; font-weight: bold;">Chargeable Weight</div>
                        <div class="p-col p-code-text">: {{ chargeableWeight.toFixed(3) }} {{ $filters.quantifiedDisplay(weightUnitText, chargeableWeight.toFixed(3))  }}</div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-fixed" style="width: 12rem; font-weight: bold;">Parcel Count</div>
                        <div class="p-col p-code-text">: {{ model.packageCount }}</div>
                    </div>
                </div>
                <br />Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
</template>

<script>
    export default {
        name: 'QuoteParcelLineTemplate',
        props: {
            header: { type: Object },
            source: { type: Array },
            itemCount: { type: Number, default: 0 },
            index: { type: Number, default: 0 },
            item: { type: Object },
            consumed: { type: Number, default: 1 },
            units: { type: Object }
        },
        data() {
            return {
                dialog: {
                    delete: false,
                    copy: false
                },
                copy: {
                    type: null,
                    selected: 0,
                    target: null
                },
                model: {
                    id: 0,
                    hazMatID: 0,
                    dangerousGoods: 0,
                    specialHandling: 0,
                    packageCount: 1,
                    declaredValue: 1.00,
                    insuranceValue: 0.00,
                    length: 0,
                    width: 0,
                    height: 0,
                    dimensionUnit: 100,
                    actualWeight: 0.100,
                    weightUnit: 100,
                    computed_VolumetricWeight: 0,
                    computed_ChargeableWeight: 0
                },
            }
        },
        computed: {
            packageNumbers() {
                var parcels = [];
                for (let i = 1; i <= this.itemCount; i++) {
                    if (i !== this.index)
                        parcels.push({ id: i, text: i.toString() });
                }
                if (this.copy.type === "copy")
                    parcels.push({ id: this.itemCount + 1, text: "New Package..." });
                return parcels;
            },
            parentHeader() {
                return this.header;
            },
            weightUnitText() {
                return this.header.weightUnit === 100 ? 'kg' : this.header.weightUnit === 200 ? 'g' : 'lb';
            },
            dimensionUnitText() {
                return this.header.dimensionUnit === 100 ? 'cm' : this.header.dimensionUnit === 200 ? 'mm' : 'in';
            },
            chargeableWeightLabel() {
                return `Chargeable Weight (<span style='color: var(--text-danger)'>${this.weightUnitText}</span>)`;
            },
            actualWeightLabel() {
                return `Actual Weight (<span style='color: var(--text-danger)'>${this.weightUnitText}</span>)`;
            },
            volumetricWeight() {
                return (((this.model.length ?? 0) * (this.model.width ?? 0) * (this.model.height ?? 0)) / 5000) ?? 0;
            },
            chargeableWeight() {
                return (this.volumetricWeight ?? 0.00) > (this.model?.actualWeight ?? 0.00) ? (this.volumetricWeight ?? 0.00) : (this.model?.actualWeight ?? 0.00);
            },
            disableAdd() {
                return (20 - this.consumed) <= 0;
            },
            maxPackage() {
                return (20 - this.consumed) + this.model.packageCount;
            }
        },
        watch: {
            item: {
                handler() {
                    this.model = this.item;
                },
                deep: true
            },
            model: {
                handler() {
                    if (this.model.id ===  0)
                        this.model.id = this.itemCount;
                    this.$emit("is-sync", this.model);
                    const kbButtons = document.getElementsByTagName("button");
                    kbButtons.forEach(elem => {
                        elem.setAttribute('tabindex', '-1')
                    });
                },
                deep: true
            }
        },
        methods: {
            onBeforeCopy(type) {
                this.copy.type = type;
                this.dialog.copy = true;
                this.copy.selected = this.itemCount + 1;
            },
            select(id) {
                this.copy.selected = id;
                this.copy.target = this.source[id - 1];
            },
            onAdd() { this.$emit("is-add"); },
            onCancel() {
                this.dialog.delete = false;
                this.dialog.copy = false;
                this.copy.type = null;
                this.copy.selected = 0;
                this.copy.target = null;
            },
            onDelete(index) { this.$emit("is-delete", index); },
            onCopyNext(model) { this.$emit("is-copy-next", model); },
            onCopyValue(source, target) {
                if (this.copy.type === "copy")
                    this.$emit("is-copy", { source, target });
                else
                    this.$emit("is-copy", { source: target, target: source });
                this.onCancel();
            },
        },
        mounted() {
            this.model = this.$filters.init(this.item);
            var buttons = document.getElementsByClassName('p-inputnumber-button');
            for (var i = 0; i < buttons.length; i++)
                buttons[i].tabIndex = -1;
        }
    }
</script>

<style>
    #PackageLines .p-inputtext.p-component.p-inputnumber-input,
    #PackageLines .p-inputnumber-button-group > button,
    #PackageLines .p-dropdown.p-component.p-inputwrapper {
        border-radius: 0 !important;
    }
    #PackageLines #chargeableWeight.is-disabled {
        border-radius: 0 !important;
        border-right: 0;
    }
    #PackageLines #id.is-disabled {
        border-radius: 5px 0 0 5px !important;
        border-right: 0;
    }
    #PackageLines .p-dropdown-label.p-placeholder {
        width: 100% !important;
        padding-right: 0;
    }
</style>