<template>
    <div class="content-section header">
        <div class="feature-intro">
            <slot name="header">
                <h1><span v-html="`${title}`"></span></h1>
                <p><span v-html="`${subtitle}`"></span></p>
            </slot>
        </div>
    </div>
    <div class="content-section body">
        <div :class="[{'card': bg }]">
            <slot name="body"></slot>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'CosmosContentComponent',
        props: {
            title: { type: String, default: '' },
            subtitle: { type: String, default: '' },
            bg: { type: Boolean, default: true }
        }
    }
</script>