<template>
    <form-record :model="model" :modelState="v$" @is-reset="resetRecord" :viewOnly="true">
        <div class="p-d-flex">
            <div class="p-col-12">
                <pv-steps :model="steps" style="margin: -2.4rem -1.5rem 0.5rem -1.5rem; border-bottom: 1px solid var(--surface-d); padding: 10px; background: var(--surface-c) " />
                <router-view name="stepper" v-slot="{Component}" v-model="model" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="complete($event)">
                    <keep-alive>
                        <component :is="Component" />
                    </keep-alive>
                </router-view>
            </div>
        </div>
    </form-record>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    export default {
        name: "",
        setup: () => ({ v$: useVuelidate() }),
        mixins: [mixins.RECORD],
        data() {
            return {
                model: {
                    id: -1000,
                    configuration: {
                        basicInfo: {
                            code: null,
                            name: null,
                            description: null,
                            privacy: 100,
                            status: 100,
                        },
                        viewer: {
                            useWarehouseFilter: false,
                            useCustomerFilter: false,
                            useCarrierFilter: false,
                            useAccountFilter: false,
                            useStatusFilter: false,
                            useIncludeArchive: false,
                            useCreateDateRangeFilter: false,
                            useCollectionDateRangeFilter: false
                        }
                    },
                    fields: [{ id: -1000, fieldID: 1, fieldName: "Trade Type", displayName: null, sortOrder: 1, sortDirection: 0 },
                        { id: -1000, fieldID: 2, fieldName: "Transaction ID", displayName: null, sortOrder: 2, sortDirection: 0 },
                        { id: -1000, fieldID: 3, fieldName: "Reference 3", displayName: null, sortOrder: 3, sortDirection: 0 }]
                },
                steps: [
                    { label: 'Field Selection', to: '/facility/report/create' },
                    { label: 'Customizations', to: '/facility/report/step/customization' },
                    { label: 'Configurations', to: '/facility/report/step/configuration' },
                    { label: 'Confirmation', to: '/facility/report/step/confirmation' }
                ]
            }
        },
        methods: {
            nextPage(event) {
                for (let field in event.formData) {
                    this.model[field] = event.formData[field];
                }
                this.$router.push(this.steps[event.pageIndex + 1].to);
            },
            prevPage(event) {
                for (let field in event.formData) {
                    this.model[field] = event.formData[field];
                }
                this.$router.push(this.steps[event.pageIndex - 1].to);
            },
            complete(model) {
                let entity = this.$filters.init(model ?? this.model);
                this.insert(entity).then((response) => {
                    if (response && (response.errors ?? []).length > 0)
                        this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: this.errors[0].message, life: 3000 });
                    else {
                        this.$router.push({ name: this.router.module + '-edit', params: { mask: response.result.mask } });
                        this.$toast.add({ severity: 'success', summary: 'Record Insert Success!', detail: 'The record was added successfully.', life: 3000 });
                        this.resetModelState();
                        setTimeout(() => this.$emit("is-reset"), 500);
                    }
                }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: err.xhr, life: 3000 });
                });
            },
        },
        created() {
            this.$router.push({ name: this.router.module + '-fields' });
        }
    }
</script>