<template>
    <form-wrapper title="Foreign Exchange Rates" sub-title="These are the rates to be applied when negotiated rates needs to be in other currency.">
        <cosmos-grid id="ExchangeRateList" sourceType="url" :source="url" :searchFilters="searchField" :deleteColumns="deleteDisplay" :autoGenerate="false" :columns="columns" :deleteDisplayFields="deleteDisplay" :showExport="true" :showOperation="false" :sourcePaged="false" @is-refs="refs = $event">
            <template #toolbar>
                <div class="p-d-flex p-jc-between p-ai-center">
                    <span>
                        <pv-button icon="pi pi-fw pi-download" label="Download Latest" :loading="loading.download" style="margin-right: 5px;" @click="onDownload"></pv-button>
                        <pv-button icon="pi pi-fw pi-file-excel" label="Export To CSV" :loading="loading.export" style="margin-right: 5px;" @click="onExport"></pv-button>
                    </span>
                    <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                        <form-datetime type="date" :value="model" preLabel="Date" :hideLabel="true" style="margin: 0;" :max="new Date()" @is-sync="this.model = $event.value" />
                    </span>
                </div>
            </template>
            <pv-grid-column field="currency.id" header="Code" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
                <template #body="slotProps">
                    <div>[<b>{{ slotProps.data.currency.code }}</b>] {{ slotProps.data.currency.name}}</div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="usd" header="USD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['usd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="eur" header="EUR" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['eur'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="gbp" header="GBP" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['gbp'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="aed" header="AED" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['aed'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="afn" header="AFN" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['afn'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="all" header="ALL" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['all'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="amd" header="AMD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['amd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="ang" header="ANG" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['ang'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="aoa" header="AOA" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['aoa'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="ars" header="ARS" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['ars'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="aud" header="AUD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['aud'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="awg" header="AWG" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['awg'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="azn" header="AZN" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['azn'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="bam" header="BAM" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['bam'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="bbd" header="BBD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['bbd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="bdt" header="BDT" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['bdt'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="bgn" header="BGN" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['bgn'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="bhd" header="BHD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['bhd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="bif" header="BIF" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['bif'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="bmd" header="BMD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['bmd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="bnd" header="BND" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['bnd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="bob" header="BOB" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['bob'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="brl" header="BRL" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['brl'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="bsd" header="BSD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['bsd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="btn" header="BTN" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['btn'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="bwp" header="BWP" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['bwp'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="byn" header="BYN" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['byn'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="bzd" header="BZD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['bzd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="cad" header="CAD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['cad'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="cdf" header="CDF" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['cdf'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="chf" header="CHF" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['chf'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="clf" header="CLF" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['clf'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="clp" header="CLP" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['clp'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="cnh" header="CNH" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['cnh'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="cny" header="CNY" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['cny'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="cop" header="COP" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['cop'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="crc" header="CRC" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['crc'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="cuc" header="CUC" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['cuc'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="cup" header="CUP" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['cup'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="cve" header="CVE" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['cve'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="czk" header="CZK" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['czk'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="djf" header="DJF" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['djf'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="dkk" header="DKK" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['dkk'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="dop" header="DOP" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['dop'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="dzd" header="DZD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['dzd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="egp" header="EGP" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['egp'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="ern" header="ERN" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['ern'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="etb" header="ETB" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['etb'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="fjd" header="FJD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['fjd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="fkp" header="FKP" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['fkp'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="gel" header="GEL" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['gel'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="ggp" header="GGP" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['ggp'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="ghs" header="GHS" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['ghs'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="gip" header="GIP" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['gip'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="gmd" header="GMD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['gmd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="gnf" header="GNF" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['gnf'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="gtq" header="GTQ" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['gtq'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="gyd" header="GYD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['gyd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="hkd" header="HKD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['hkd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="hnl" header="HNL" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['hnl'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="hrk" header="HRK" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['hrk'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="htg" header="HTG" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['htg'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="huf" header="HUF" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['huf'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="idr" header="IDR" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['idr'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="ils" header="ILS" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['ils'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="imp" header="IMP" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['imp'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="inr" header="INR" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['inr'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="iqd" header="IQD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['iqd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="irr" header="IRR" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['irr'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="isk" header="ISK" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['isk'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="jep" header="JEP" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['jep'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="jmd" header="JMD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['jmd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="jod" header="JOD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['jod'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="jpy" header="JPY" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['jpy'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="kes" header="KES" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['kes'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="kgs" header="KGS" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['kgs'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="khr" header="KHR" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['khr'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="kmf" header="KMF" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['kmf'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="kpw" header="KPW" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['kpw'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="krw" header="KRW" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['krw'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="kwd" header="KWD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['kwd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="kyd" header="KYD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['kyd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="kzt" header="KZT" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['kzt'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="lak" header="LAK" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['lak'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="lbp" header="LBP" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['lbp'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="lkr" header="LKR" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['lkr'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="lrd" header="LRD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['lrd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="lsl" header="LSL" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['lsl'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="lyd" header="LYD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['lyd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="mad" header="MAD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['mad'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="mdl" header="MDL" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['mdl'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="mga" header="MGA" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['mga'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="mkd" header="MKD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['mkd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="mmk" header="MMK" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['mmk'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="mnt" header="MNT" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['mnt'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="mop" header="MOP" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['mop'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="mru" header="MRU" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['mru'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="mur" header="MUR" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['mur'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="mvr" header="MVR" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['mvr'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="mwk" header="MWK" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['mwk'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="mxn" header="MXN" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['mxn'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="myr" header="MYR" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['myr'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="mzn" header="MZN" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['mzn'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="nad" header="NAD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['nad'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="ngn" header="NGN" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['ngn'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="nio" header="NIO" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['nio'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="nok" header="NOK" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['nok'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="npr" header="NPR" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['npr'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="nzd" header="NZD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['nzd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="omr" header="OMR" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['omr'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="pab" header="PAB" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['pab'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="pen" header="PEN" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['pen'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="pgk" header="PGK" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['pgk'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="php" header="PHP" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['php'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="pkr" header="PKR" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['pkr'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="pln" header="PLN" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['pln'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="pyg" header="PYG" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['pyg'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="qar" header="QAR" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['qar'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="ron" header="RON" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['ron'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="rsd" header="RSD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['rsd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="rub" header="RUB" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['rub'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="rwf" header="RWF" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['rwf'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="sar" header="SAR" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['sar'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="sbd" header="SBD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['sbd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="scr" header="SCR" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['scr'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="sdg" header="SDG" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['sdg'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="sek" header="SEK" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['sek'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="sgd" header="SGD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['sgd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="shp" header="SHP" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['shp'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="sll" header="SLL" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['sll'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="sos" header="SOS" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['sos'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="srd" header="SRD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['srd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="ssp" header="SSP" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['ssp'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="std" header="STD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['std'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="stn" header="STN" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['stn'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="svc" header="SVC" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['svc'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="syp" header="SYP" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['syp'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="szl" header="SZL" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['szl'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="thb" header="THB" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['thb'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="tjs" header="TJS" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['tjs'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="tmt" header="TMT" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['tmt'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="tnd" header="TND" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['tnd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="top" header="TOP" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['top'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="try" header="TRY" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['try'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="ttd" header="TTD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['ttd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="twd" header="TWD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['twd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="tzs" header="TZS" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['tzs'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="uah" header="UAH" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['uah'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="ugx" header="UGX" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['ugx'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="uyu" header="UYU" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['uyu'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="uzs" header="UZS" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['uzs'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="ves" header="VES" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['ves'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="vnd" header="VND" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['vnd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="vuv" header="VUV" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['vuv'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="wst" header="WST" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['wst'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="xaf" header="XAF" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['xaf'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="xcd" header="XCD" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['xcd'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="xof" header="XOF" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['xof'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="xpf" header="XPF" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['xpf'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="yer" header="YER" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['yer'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="zar" header="ZAR" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['zar'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="zmw" header="ZMW" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['zmw'].toFixed(7) }}
                </template>
            </pv-grid-column>
            <pv-grid-column field="zwl" header="ZWL" style="text-align: right">
                <template #body="slotProps">
                    {{ slotProps.data['zwl'].toFixed(7) }}
                </template>
            </pv-grid-column>
        </cosmos-grid>
    </form-wrapper>
</template>
<script>
    export default {
        name: 'ExchangeRateGrid',
        data() {
            return {
                model: null,
                columns: [],
                refs: null,
                loading: {
                    dowload: false,
                    export: false
                },
                page: {
                    current: 0,
                    first: 0,
                    size: 10
                }
            }
        },
        computed: {
            url() {
                let q = this.model.getFullYear() + '-' + this.$filters.padLeft(this.model.getMonth() + 1, 2, '0') + '-' + this.$filters.padLeft(this.model.getDate(), 2 ,'0');
                return `/services/facility/exchangerate?q=${q}`;
            }
        },
        methods: {
            onDownload() {
                this.loading.download = true;
                this.$store.dispatch("exchangerate/getURL", { url: '/services/facility/exchangerate/latest' })
                    .then(() => {
                        this.loading.download = false;
                    })
                    .catch(() => {
                        this.loading.download = false;
                    });
            },
            onExport() {
                this.loading.export = true;
                this.$nextTick(() => { this.refs["ExchangeRateList"].exportCSV(); this.loading.export = false; })
            }
        },
        created() {
            this.model = new Date(this.$moment.utc());
            this.columns = this.$vm.$currencies.filter(x => x.forexSupport && x.currencyType === 1).map(m => ({ field: m.code.toLowerCase(), header: m.code.toUpperCase() })) ?? [];
        }
    }
</script>

