<template>
    <div class="card">
        <div class="p-grid">
            <div class="p-col-12 p-md-3">
                <h6>Initial State</h6>
                <form-input id="TextInitial" label="Standard" :value="model.blank.text" @is-sync="model.blank.text = $event.value" />
                <form-input id="PasswordInitial" type="password" label="Password" :value="model.blank.password" @is-sync="model.blank.password = $event.value" />
                <form-input id="TextAreaInital" type="textarea" label="Text Area" :value="model.blank.textarea" @is-sync="model.blank.textarea = $event.value" />
                <form-input id="ColorInitial" type="color" label="Color Picker" :value="model.blank.color" @is-sync="model.blank.color = $event.value" />
                <form-input id="EditorInitial" type="editor" label="Editor" :value="model.blank.editor" @is-sync="model.blank.editor = $event.value" />
            </div>
            <div class="p-col-12 p-md-3">
                <h6>Initial Disabled State</h6>
                <form-input :disabled="true" id="TextInitial" label="Standard" :value="model.blank.text" @is-sync="model.blank.text = $event.value" />
                <form-input :disabled="true" id="PasswordInitial" type="password" label="Password" :value="model.blank.password" @is-sync="model.blank.password = $event.value" />
                <form-input :disabled="true" id="TextAreaInital" type="textarea" label="Text Area" :value="model.blank.textarea" @is-sync="model.blank.textarea = $event.value" />
                <form-input :disabled="true" id="ColorInitial" type="color" label="Color Picker" :value="model.blank.color" @is-sync="model.blank.color = $event.value" />
                <form-input :disabled="true" id="EditorInitial" type="editor" label="Editor" :value="model.blank.editor" @is-sync="model.blank.editor = $event.value" />
            </div>
            <div class="p-col-12 p-md-3">
                <h6>Transaction State</h6>
                <form-input id="TextEditable" label="Standard" :value="model.transaction.text" @is-sync="model.transaction.text = $event.value" />
                <form-input id="PasswordEditable" type="password" label="Password" :value="model.transaction.password" @is-sync="model.transaction.password = $event.value" />
                <form-input id="TextAreaEditable" type="textarea" label="Text Area" :value="model.transaction.textarea" @is-sync="model.transaction.textarea = $event.value" />
                <form-input id="ColorEditable" type="color" label="Color Picker" :value="model.transaction.color" @is-sync="model.transaction.color = $event.value" />
                <form-input id="EditorEditable" type="editor" label="Editor" :value="model.transaction.editor" @is-sync="model.transaction.editor = $event.value" />
            </div>
            <div class="p-col-12 p-md-3">
                <h6>Disabled State</h6>
                <form-input :disabled="true" id="TextEditable" label="Standard" :value="model.transaction.text" @is-sync="model.transaction.text = $event.value" />
                <form-input :disabled="true" id="PasswordEditable" type="password" label="Password" :value="model.transaction.password" @is-sync="model.transaction.password = $event.value" />
                <form-input :disabled="true" id="TextAreaEditable" type="textarea" label="Text Area" :value="model.transaction.textarea" @is-sync="model.transaction.textarea = $event.value" />
                <form-input :disabled="true" id="ColorEditable" type="color" label="Color Picker" :value="model.transaction.color" @is-sync="model.transaction.color = $event.value" />
                <form-input :disabled="true" id="EditorEditable" type="editor" label="Editor" :value="model.transaction.editor" @is-sync="model.transaction.editor = $event.value" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FormInputComponents',
        data() {
            return {
                model: {
                    blank: {
                        text: null,
                        password: null,
                        textarea: null,
                        color: "#FFFFFF",
                        editor: null
                    },
                    transaction: {
                        text: "This is sa sample string",
                        password: "p5s$W0rD",
                        textarea: 'This is a multiline text.\r\nIt is really applicable to use textarea.\r\nPlease bare with us.',
                        color: '#FF8999',
                        editor: 'This is a <span style="color: red;">multiline text.</span><br/>It is really applicable to use textarea.<br/>Please bare with <strong>us</strong>.'
                    }
                }
            }
        }
    }
</script>

