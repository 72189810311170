<template>
    <form-wrapper title="Integration Providers" sub-title="List of available integrations either a direct carrier or third-party service providers">
        <cosmos-grid id="ProviderList" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteDisplayFields="deleteDisplay" :showDelete="false" :showAdd="false" :showExport="false" :operationWidth="4" groupBy="typeName">
            <template #groupheader="slotProps">
                <span class="image-text" style="text-transform: uppercase; font-weight: 700 !important">{{ $filters.pluralize(slotProps.data.typeName) }}</span>
            </template>
            <pv-grid-column field="code" header="" headerStyle="width: 10rem">
                <template #body="slotProps">
                    <div style="text-align: center">
                        <img :src="`/_assets/_integration/${slotProps.data.mask}.png?${ticks}`" style="object-fit: contain; max-height: 3rem; max-width: 9rem; object-position: center; align-content: center;" onerror="this.onerror=null; this.src = '/_assets/img/no-logo.png'" />
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="code" header="DETAILS">
                <template #body="slotProps">
                    <div class="p-d-flex p-jc-between" style="width: 100%; padding: 0 10px; margin: 0;">
                        <div>
                            <h5 style="margin-bottom: 5px; margin-top: 0;"><span>{{ slotProps.data.altName ?? slotProps.data.name }}</span></h5>
                            <pv-tag v-for="data in slotProps.data.tradeTypeName" :key="data" :severity="`${(data === 'Import' ? 'info' : (data === 'Third-Country' ? 'danger' : (data === 'Third-Party Collection' ? 'warning' : (data === 'Returns' ? 'success' : ''))))}`" style="margin-right: 0.25rem; text-transform: uppercase;"><i class="fa fa-check-square" style="margin-right: 0.5rem" />{{ data }}</pv-tag>
                        </div>
                        <div class="p-as-end"><h5 class="p-code-text">[{{ slotProps.data.code}}]</h5></div>
                    </div>
                </template>
            </pv-grid-column>
        </cosmos-grid>
    </form-wrapper>
</template>
<script>
    export default {
        name: 'ProviderList',
        data() { return { searchFields: ['code', 'name'] } },
        computed: { ticks() { return this.$moment.utc().format("YYYYMMDD"); } },
        mounted() { this.loading = false; }
    }
</script>
