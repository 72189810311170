<template>
    <is-loader v-if="activity.loading"></is-loader>
    <form method="post" autocomplete="off" @submit.prevent="onSubmit">
        <div class="content-section header">
            <div class="feature-intro">
                <slot name="header">
                    <h1><span>{{ this.$props?.title ?? router.title }}</span></h1>
                    <p>{{ this.$props?.subtitle ?? router.subtitle }}</p>
                </slot>
            </div>
        </div>
        <div class="content-section body">
            <is-toolbar :viewBack="viewBack" :saveOnly="saveOnly" :isDirty="validation.$anyDirty || validation.$isDirty" :modelState="validation" :identity="entity?.mask" back="agents" @is-reset="reset" @is-send="send"></is-toolbar>
            <div class="card">
                <slot :data="validation"></slot>
            </div>
        </div>
    </form>
</template>
<script>
    import mixins from '@/assets/_js/cosmos-mixins.js';
    //import menu from '@/components/shared/helpers/menu-template.vue';
    export default {
        name: 'FormTemplate',
        mixins: [mixins.form],
        //components: { 'is-toolbar': menu },
        //props: {
        //    entity: { type: Object },
        //    title: { type: String },
        //    subtitle: { type: String },
        //    validation: { type: Object }
        //},
        //data() {
        //    return {
        //        isLoading: true,
        //        submitted: false,
        //        modelState: null,
        //    }
        //},
        //watch: {
        //    validation() {
        //        this.modelState = null;
        //        this.update(false);
        //        if (this.submitted) {
        //            let model = this.validation[this.id];
        //            if (model) {
        //                var entity = {};
        //                Object.keys(model).forEach((key) => { entity[key.replace("$", "")] = typeof (model[key]) === 'boolean' ? model[key] === "on" ? true : model[key] : model[key]; });
        //                if (entity.silentErrors && entity.silentErrors.length > 0)
        //                    this.error = entity.silentErrors[0].$message.replace('Value', this.$filters.titleize(this.id));
        //                this.update(entity.invalid);
        //            }
        //        }
        //    }
        //},
        computed: {
            init() { return this.$store.getters[this.module.name + "/init"]; },
            ds() { return this.$store.getters[this.module.name + "/ds"]; },
            record() { return this.$store.getters[this.module.name + "/record"]; },
        },
        methods: {
            async add(model) { return await this.$store.dispatch(this.module.name + "/addRecord", model); },
            async update(model) { return await this.$store.dispatch(this.module.name + "/modifyRecord", model); },
            async delete(mask) { return await this.$store.dispatch(this.module.name + "/removeRecord", mask); },
            async get(mask) { return await this.$store.dispatch(this.module.name + "/getRecord", mask); },
            async sendmail(mask) { return await this.$store.dispatch(this.module.name + "/sendRecord", mask); },
            submit() {
                this.validation?.$touch();
                this.$vm.$submitted = true;
                if (!this.validation.$invalid) {
                    if (this.$route.params.mask) {
                        this.update(this.entity).then(() => {
                            this.model = this.record;
                            //this.$nextTick(() => {
                            //    this.validation.$reset();
                            //})
                        })
                    }
                    else {
                        this.add(this.entity).then(() => {
                            //this.$nextTick(() => {
                            //    this.validation.$reset();
                            //})
                            var routerName = this.$route.name.replace("-index", "").replace("-add", "").replace("-edit", "");
                            this.$router.push({ name: routerName + '-edit', params: { mask: this.record.mask } });
                            //this.$router.push({ name: this.module.name + '-edit', params: { mask: this.record.mask } });
                        });
                    }
                }
            },
            reset() {
                this.get(this.record.mask);
                this.$nextTick(() => {
                    this.validation.$reset();
                })
            },
            send() {
                this.sendmail(this.$route.params.mask).then(() => {
                    this.model = this.record;
                    //this.$nextTick(() => {
                    //    this.validation.$reset();
                    //})
                })
            }
        //        Object.keys(this.entity).forEach((key) => {
        //            console.log(_self.validation);
        //            console.log(_self.validation.model[key]);
        //        });
        //        }
        },
        //watch: {
        //    record() {
        //        this.validation.$reset;
        //        this.model = this.record;
        //    }
        //}
    }
</script>
<style>
    .p-tooltip-text {
        font-size: 0.75rem !important;
    }

    .is-toolbar {
        padding: 3px;
        background: var(--surface-e) !important;
        border: 1px solid var(--surface-c);
        width: calc(100% - 250px);
        margin: 0;
        position: fixed !important;
        z-index: 990;
    }

    .is-toolbar .p-buttonset button:not(:last-child) {
        border-right: 1px solid var(--surface-d) !important;
    }

    .is-toolbar + .card {
        border-radius: 0 0 5px 5px;
    }
</style>