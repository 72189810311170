<template>
    <is-dialog header="Manage Zone Matrix?" :visible="dialog.add" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}" :confirmDisabled="false" @is-confirm="onSave()" @is-cancel="onCancel()">
        <div class="p-grid">
            <div class="p-col-12 p-md-12">
                <form-input id="code" :disabled="true" :validator="v$" :value="model.zoneGroup?.code" :required="true" />
                <form-input id="name" :disabled="true" :validator="v$" :value="model.zoneGroup?.name" :required="true" />
                <div v-for="col of columns" :key="col.columnPosition">
                    <form-lookup :id="col.field" :value="model[col.field]?.columnPosition" :hideLabel="true" :preLabel="col.header" :source="zoneLookup" :required="true" @is-sync="UpdateMatrix(model.columnPosition, col.field, $event)" textField="name" valueField="columnPosition"></form-lookup>
                </div>
            </div>
        </div>
    </is-dialog>
    <pv-grid :value="zoneMatrix" responsiveLayout="scroll" showGridlines>
        <pv-grid-column-group type="header">
            <pv-grid-row>
                <pv-grid-column header="Origin Zone" :rowspan="2" />
                <pv-grid-column header="Destination Zone" :colspan="columns.length" />
                <pv-grid-column header="" :rowspan="2" headerStyle="min-width: 4rem; max-width: 4rem; width: 4rem;" style="width: 4rem; min-width: 4rem; max-width: 4rem; border-left: 1px solid var(--surface-d); padding: 0 5px; " />
            </pv-grid-row>
            <pv-grid-row>
                <pv-grid-column v-if="0 < this.columns.length" :field="this.columns[0].field" :header="this.columns[0].header" />
                <pv-grid-column v-if="1 < this.columns.length" :field="this.columns[1].field" :header="this.columns[1].header" />
                <pv-grid-column v-if="2 < this.columns.length" :field="this.columns[2].field" :header="this.columns[2].header" />
                <pv-grid-column v-if="3 < this.columns.length" :field="this.columns[3].field" :header="this.columns[3].header" />
                <pv-grid-column v-if="4 < this.columns.length" :field="this.columns[4].field" :header="this.columns[4].header" />
                <pv-grid-column v-if="5 < this.columns.length" :field="this.columns[5].field" :header="this.columns[5].header" />
                <pv-grid-column v-if="6 < this.columns.length" :field="this.columns[6].field" :header="this.columns[6].header" />
                <pv-grid-column v-if="7 < this.columns.length" :field="this.columns[7].field" :header="this.columns[7].header" />
                <pv-grid-column v-if="8 < this.columns.length" :field="this.columns[8].field" :header="this.columns[8].header" />
                <pv-grid-column v-if="9 < this.columns.length" :field="this.columns[9].field" :header="this.columns[9].header" />
                <pv-grid-column v-if="10 < this.columns.length" :field="this.columns[10].field" :header="this.columns[10].header" />
                <pv-grid-column v-if="11 < this.columns.length" :field="this.columns[11].field" :header="this.columns[11].header" />
                <pv-grid-column v-if="12 < this.columns.length" :field="this.columns[12].field" :header="this.columns[12].header" />
                <pv-grid-column v-if="13 < this.columns.length" :field="this.columns[13].field" :header="this.columns[13].header" />
                <pv-grid-column v-if="14 < this.columns.length" :field="this.columns[14].field" :header="this.columns[14].header" />
                <pv-grid-column v-if="15 < this.columns.length" :field="this.columns[15].field" :header="this.columns[15].header" />
                <pv-grid-column v-if="16 < this.columns.length" :field="this.columns[16].field" :header="this.columns[16].header" />
                <pv-grid-column v-if="17 < this.columns.length" :field="this.columns[17].field" :header="this.columns[17].header" />
                <pv-grid-column v-if="18 < this.columns.length" :field="this.columns[18].field" :header="this.columns[18].header" />
                <pv-grid-column v-if="19 < this.columns.length" :field="this.columns[19].field" :header="this.columns[19].header" />
                <pv-grid-column v-if="20 < this.columns.length" :field="this.columns[20].field" :header="this.columns[20].header" />
                <pv-grid-column v-if="21 < this.columns.length" :field="this.columns[21].field" :header="this.columns[21].header" />
                <pv-grid-column v-if="22 < this.columns.length" :field="this.columns[22].field" :header="this.columns[22].header" />
                <pv-grid-column v-if="23 < this.columns.length" :field="this.columns[23].field" :header="this.columns[23].header" />
                <pv-grid-column v-if="24 < this.columns.length" :field="this.columns[24].field" :header="this.columns[24].header" />
                <pv-grid-column v-if="25 < this.columns.length" :field="this.columns[25].field" :header="this.columns[25].header" />
                <pv-grid-column v-if="26 < this.columns.length" :field="this.columns[26].field" :header="this.columns[26].header" />
                <pv-grid-column v-if="27 < this.columns.length" :field="this.columns[27].field" :header="this.columns[27].header" />
                <pv-grid-column v-if="28 < this.columns.length" :field="this.columns[28].field" :header="this.columns[28].header" />
                <pv-grid-column v-if="29 < this.columns.length" :field="this.columns[29].field" :header="this.columns[29].header" />
            </pv-grid-row>
        </pv-grid-column-group>
        <pv-grid-column field="zoneGroup.name" header="Zone Group" />
        <pv-grid-column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field">
            <template #body="slotProps">
                {{ slotProps.data[col.field]?.name }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="mask" headerStyle="min-width: 4rem; max-width: 4rem; width: 4rem;" style="width: 4rem; min-width: 4rem; max-width: 4rem; border-left: 1px solid var(--surface-d); padding: 0 5px; " :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div class="p-col">
                        <pv-button icon="pi pi-pencil" iconPos="right" class="p-button-info" @click="onEdit(`${slotProps.data.columnPosition ?? slotProps.data.zoneGroup.columnPosition}`)" />
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <template #empty>
            No records found.
        </template>
        <template #loading>
            Retrieving records from database. Please wait...
        </template>
    </pv-grid>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    export default {
        name: 'StepPostingLocation',
        emits: ["next-page"],
        setup: () => ({ v$: useVuelidate() }),
        props: {
            id: { type: String },
            zoneID: { type: String },
            formData: { type: Object, default: null }
        },
        data() {
            return {
                dialog: {
                    add: false
                },
                editingRows: [],
                model: {},
                zoneMatrix: [],
                zn: null,
                zg: null,
            }
        },
        computed: {
            confirmDisabled() {
                return !(this.model.code && this.model.name && this.model.tradeTypes > 0);
            },
            zoneGroups() { return this.zg ?? this.formData.zoneGroups; },
            zoneLookup() { return this.zn ?? this.formData.zoneNames; },
            columns() {
                var columns = []
                    for (let i = 0; i < (this.zoneGroups ?? []).length; i++) {
                        columns.push({
                            field: `zoneName_${this.$filters.padLeft(i + 1, 2, '0')}`,
                            header: this.zoneGroups[i].name,
                            columnPosition: i
                        });
                }
                return columns;
            }
        },
        watch: {
            'data': { handler() { this.model = this.data; }, deep: true },
            'zoneID': {
                handler() {
                    this.$store.dispatch("providerzone/getURL", { url: `/services/settings/serviceproviderzonename/byzone/id/${this.zoneID}` }).then((response) => {
                        this.zn = [];
                        if (response && response.success)
                            this.zn = response.result;
                    }).catch((ex) => console.log(ex));
                    this.$store.dispatch("providerzone/getURL", { url: `/services/settings/serviceproviderzonegroup/byzone/id/${this.zoneID}` }).then((response) => {
                        this.zg = [];
                        if (response && response.success)
                            this.zg = response.result;
                    }).catch((ex) => console.log(ex));
                    this.$store.dispatch("providerzone/getURL", { url: `/services/settings/serviceproviderzonematrix/byzone/id/${this.zoneID}` }).then((response) => {
                        this.zoneMatrix = [];
                        if (response && response.success)
                            this.zoneMatrix = response.result;
                        if ((this.zoneMatrix ?? []).length === 0) {
                            for (let i = 0; i < this.zoneGroups.length; i++) {
                                var zonegroup = {};
                                zonegroup["code"] = this.zoneGroups[i].code;
                                zonegroup["name"] = this.zoneGroups[i].name;
                                zonegroup["zoneGroup"] = this.zoneGroups[i];
                                zonegroup["columnPosition"] = this.zoneGroups[i].columnPosition;
                                zonegroup["zone"] = { id: this.zoneID };
                                for (let j = 0; j < this.columns.length; j++) {
                                    zonegroup[this.columns[j].field] = null;
                                }
                                this.zoneMatrix.push(zonegroup);
                            }
                        }
                    }).catch((ex) => console.log(ex));
                }, deep: true
            },
            zoneMatrix: { handler() { this.$emit("is-sync", { zoneMatrix: this.zoneMatrix }) }, deep: true }

        },
        methods: {
            addItem() {
                this.dialog.add = false;
            },
            onEdit(product) {
                this.model = this.$filters.init(this.zoneMatrix[product - 1]);
                this.dialog.add = true;
            },
            UpdateMatrix(position, col, e) {
                this.model[col] = this.$filters.init(e.record);
            },
            onSave() {
                this.zoneMatrix[this.model.columnPosition - 1] = this.$filters.init(this.model);
                this.model = {};
                this.dialog.add = false;
            },
            onCancel() {

            },
            nextPage() {
                this.$emit('next-page', { formData: { zoneMatrix: this.zoneMatrix }, pageIndex: 3 });
            },
            prevPage() {
                this.$emit('prev-page', { formData: { zoneMatrix: this.zoneMatrix }, pageIndex: 3 });
            },
            getSource() {
                if (this.zoneID) {
                    this.$store.dispatch("providerzone/getURL", { url: `/services/settings/serviceproviderzonename/byzone/id/${this.zoneID}` }).then((response) => {
                        this.zn = [];
                        if (response && response.success)
                            this.zn = response.result;
                    }).catch((ex) => console.log(ex));
                    this.$store.dispatch("providerzone/getURL", { url: `/services/settings/serviceproviderzonegroup/byzone/id/${this.zoneID}` }).then((response) => {
                        this.zg = [];
                        if (response && response.success)
                            this.zg = response.result;
                    }).catch((ex) => console.log(ex));
                    this.$store.dispatch("providerzone/getURL", { url: `/services/settings/serviceproviderzonematrix/byzone/id/${this.zoneID}` }).then((response) => {
                        this.zoneMatrix = [];
                        if (response && response.success)
                            this.zoneMatrix = response.result;
                        if ((this.zoneMatrix ?? []).length === 0) {
                            for (let i = 0; i < this.zoneGroups.length; i++) {
                                var zonegroup = {};
                                zonegroup["code"] = this.zoneGroups[i].code;
                                zonegroup["name"] = this.zoneGroups[i].name;
                                zonegroup["zoneGroup"] = this.zoneGroups[i];
                                zonegroup["columnPosition"] = this.zoneGroups[i].columnPosition;
                                zonegroup["zone"] = { id: this.zoneID };
                                for (let j = 0; j < this.columns.length; j++) {
                                    zonegroup[this.columns[j].field] = null;
                                }
                                this.zoneMatrix.push(zonegroup);
                            }
                        }
                    }).catch((ex) => console.log(ex));
                }
            }

        },
        created() {
            if (this.zoneID === null || this.zoneID === undefined) {
                for (let i = 0; i < this.zoneGroups.length; i++) {
                    var zonegroup = {};
                    zonegroup["code"] = this.zoneGroups[i].code;
                    zonegroup["name"] = this.zoneGroups[i].name;
                    zonegroup["zoneGroup"] = this.zoneGroups[i];
                    zonegroup["columnPosition"] = this.zoneGroups[i].columnPosition;
                    for (let j = 0; j < this.columns.length; j++) {
                        zonegroup[this.columns[j].field] = null;
                    }
                    this.zoneMatrix.push(zonegroup);
                }
                if (this.zoneMatrix)
                    this.$emit("is-sync", { zoneMatrix: this.zoneMatrix })
            }
            else {
                this.getSource();
            }
        }
    }
</script>
