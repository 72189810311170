<template>
    <is-dialog header="Manage Zone Group" :visible="dialog.add" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}" :confirmDisabled="confirmDisabled" @is-confirm="onSave()" @is-cancel="onCancel()">
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <form-input id="code" :validator="v$" :value="model.code" :required="true" @is-sync="model.code = $event.value" :maxLength="20" v-code v-focus />
                <form-input id="name" :validator="v$" :value="model.name" :required="true" @is-sync="model.name = $event.value" :maxLength="50" />
            </div>
            <div class="p-col-12 p-md-6">
                <form-lookup type="flag" id="providerID" :value="model.tradeType" label="Supported Trade Types" source="tradetype" :required="true" sourceType="enums" :v$="v$" @is-sync="{ this.model.tradeType = $event.value; this.model.tradeTypeNames = ($event?.text ?? []).join(', ') }"></form-lookup>
            </div>
        </div>
    </is-dialog>
    <pv-grid :value="zoneGroups" :reorderableColumns="true" @columnReorder="onColReorder" @rowReorder="onRowReorder" responsiveLayout="scroll" showGridlines
             :rows="10"
             :totalRecords="(zoneGroups ?? []).length"
             :paginator="true"
             currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
             :alwaysShowPaginator="true"
             :paginatorPosition="'bottom'"
             :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'"
             :pageLinkSize="3">
        <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
                <span>
                    <pv-button icon="pi pi-fw pi-plus" label="Add New Zone Group" style="margin-right: 5px;" @click="this.dialog.add = true"></pv-button>
                </span>
            </div>
        </template>
        <pv-grid-column :rowReorder="true" headerStyle="width: 2.5rem" :reorderableColumn="false" />
        <pv-grid-column field="code" header="Code" headerStyle="min-width: 10rem; max-width: 10rem; width: 10rem;" style="border-left: 1px solid var(--surface-d); padding: 0 5px;" />
        <pv-grid-column field="name" header="Name" />
        <pv-grid-column field="tradeTypeNames" header="Supported Trade Types" />
        <pv-grid-column field="mask" headerStyle="min-width: 8rem; max-width: 8rem; width: 8rem;" style="min-width: 2rem; max-width: 2rem; border-left: 1px solid var(--surface-d); padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div class="p-col">
                        <pv-button icon="pi pi-pencil" iconPos="right" class="p-button-info" @click="onEdit(`${slotProps.data.columnPosition}`)" />
                    </div>
                    <div class="p-col">
                        <pv-button icon="pi pi-trash" iconPos="right" class="p-button-danger" @click="onDelete(`${slotProps.data.columnPosition}`)" />
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <!-- Row Messages -->
        <template #empty>
            No records found.
        </template>
        <template #loading>
            Retrieving records from database. Please wait...
        </template>
        <!-- Pagination -->
        <template #paginatorRight>
            <pv-button type="button" icon="pi pi-refresh" class="p-button-rounded p-button-text" @click="onRefresh" />
        </template>
    </pv-grid>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    //import { required, minLength, maxLength } from '@vuelidate/validators';
    export default {
        name: 'StepPostingLocation',
        emits: ["next-page"],
        setup: () => ({ v$: useVuelidate() }),
        props: {
            id: { type: String },
            zoneID: { type: String },
            formData: { type: Object, default: null }
        },
        data() {
            return {
                dialog: {
                    add: false
                },
                edit: false,
                model: {
                    id: 0,
                    code: null,
                    name: null,
                    tradeType: 4,
                    columnPosition: 1
                },
                zoneGroups: [],
                columns: [
                    { field: 'code', header: 'Code', width: 20 },
                    { field: 'name', header: 'Name' },
                    { field: 'name', header: 'Name' }
                ],
            }
        },
        computed: {
            confirmDisabled() {
                if (this.edit) {
                    let notselected = (this.zoneGroups ?? []).filter(x => x.columnPosition !== this.model.columnPosition)
                    return !(this.model.code
                        && this.model.name
                        && this.model.tradeType > 0
                        && (notselected ?? []).filter(x => x.code.toUpperCase() == this.model?.code?.toUpperCase()).length === 0
                        && (notselected ?? []).filter(x => x.name.toUpperCase() == this.model?.name?.toUpperCase()).length === 0
                    );
                }
                return !(
                    this.model.code
                    && this.model.name
                    && this.model.tradeType > 0
                    && (this.zoneGroups ?? []).filter(x => x.code.toUpperCase() == this.model?.code?.toUpperCase()).length === 0
                    && (this.zoneGroups ?? []).filter(x => x.name.toUpperCase() == this.model?.name?.toUpperCase()).length === 0
                );
            }
        },
        watch: {
            'data': { handler() { this.model = this.data; }, deep: true },
            'zoneID': {
                handler() {
                    this.getSource();
                }, deep: true

            },
            zoneGroups: { handler() { this.$emit("is-sync", { zoneGroups: this.zoneGroups }) }, deep: true }
        },
        //validations() {
        //    return {
        //        model: {
        //            code: { required, minLength: minLength(1), maxLength: maxLength(20), $autoDirty: true },
        //            name: { required, maxLength: maxLength(50), $autoDirty: true },
        //            tradeTypes: { required, $autoDirty: true }
        //        }
        //    }
        //},
        methods: {
            init() {
                this.model = {
                    id: 0,
                    code: null,
                    name: null,
                    tradeType: 4,
                    columnPosition: this.zoneGroups.length + 1
                };
            },
            onEdit(e) {
                this.edit = true;
                this.model = this.$filters.init(this.zoneGroups[e - 1]);
                this.dialog.add = true;
            },
            onSave() {
                if (this.edit)
                    this.zoneGroups[this.model.columnPosition - 1] = this.$filters.init(this.model);
                else {
                    this.zoneGroups.push(this.model);
                    for (let i = 0; i < this.zoneGroups.length; i++)
                        this.zoneGroups[i].columnPosition = i + 1;
                }
                this.init();
                this.dialog.add = false;
            },
            onDelete(e) {
                this.zoneGroups = (this.zoneGroups ?? []).filter(x => x.columnPosition != e);
                for (let i = 0; i < this.zoneGroups.length; i++)
                    this.zoneGroups[i].columnPosition = i + 1;
                this.init();
            },
            onCancel() {
                this.dialog.add = false;
                this.init();
            },
            onRowReorder(e) {
                const item = this.zoneGroups[e.dragIndex];
                this.zoneGroups.splice(e.dragIndex, 1);
                this.zoneGroups.splice(e.dropIndex, 0, item);
            },
            async getSource() {
                if (this.zoneID) {
                    this.$store.dispatch("providerzone/getURL", { url: `/services/settings/serviceproviderzonegroup/byzone/id/${this.zoneID}` }).then((response) => {
                        if (response && response.success)
                            this.zoneGroups = response.result;
                    }).catch((ex) => console.log(ex));
                }
            }
        },
        created() {
            this.getSource().then(() => {
                if (this.zoneGroups)
                    this.$emit("is-sync", { zoneGroups: this.zoneGroups })
            })
        }

    }
</script>
