<template>
    <pv-scrolltop />
    <pv-toast>
        <template #message="slotProps">
            <span class="p-toast-message-icon pi pi-info-circle"></span>
            <div class="p-toast-message-text">
                <span class="p-toast-summary">{{slotProps.message.summary}}</span>
                <div class="p-toast-detail" style="line-height: 1.5rem" v-html="slotProps.message.detail"></div>
            </div>
        </template>
    </pv-toast>
    <pv-dialog v-model:visible="warningZone" :modal="true" :closable="false" :closeOnEscape="false" :draggable="false">
        <div class="confirmation-content">
            <span style="font-size: 1.5rem">Session will end in 00:{{ countdown > 9 ? countdown : `0${countdown}` }}s</span>
        </div>
        <template #footer>
            <pv-button label="Logout" icon="pi pi-times" @click="forceLogout" class="p-button-text" />
            <pv-button label="Continue session" icon="pi pi-check" @click="reset" autofocus />
        </template>
    </pv-dialog>
    <div v-if="loggedIn" class="layout-wrapper">
        <cosmos-header :theme="theme" @is-sync="theme = $event" @is-logout="forceLogout" :user="user" />
        <div class="layout-content">
            <div>
                <router-view @is-sync="grant" />
            </div>
            <cosmos-footer :theme="theme" />
        </div>
    </div>
    <div v-else>
        <router-view @is-mounted="mounted" />
    </div>
</template>
<script>
    const neodynamics = require('@/assets/lib/cosmos/_js/neodynamics.js');
    import { mapActions, mapGetters } from 'vuex';
    export default {
        name: 'COSMOS',
        data() {
            return {
                time: {
                    utc: null,
                    local: null
                },
                events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
                logoutTimer: null,
                warningTimer: null,
                refreshTimer: null,
                countDownTimer: null,
                warningZone: false,
                countdown: 59
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser",
                error: "auth/errorMessages",
                loggedIn: "auth/loggedIn"
            }),
            environments() {
                return this.$vm.storage.app.source.environment;
            },
            warehouses() {
                return this.$vm.storage.app.source.warehouse;
            },
            theme() {
                if (sessionStorage.getItem("theme"))
                    return sessionStorage.getItem("theme");
                return "dark";
            }
        },
        methods: {
            ...mapActions({
                logout: "auth/logout",
                refresh: "auth/refresh"
            }),
            appMounted() {

            },
            setSlidingTimers() {
                //if (this.isLoggedIn) {
                //    this.warningTimer = setTimeout(this.showIdleMessage, 8 * 60 * 1000);
                //    this.logoutTimer = setTimeout(this.forceLogout, 9 * 60 * 1000);
                //    this.warningZone = false;
                //}
            },
            resetSlidingTimers() {
                //if (this.isLoggedIn && !this.warningZone) {
                //    clearTimeout(this.warningTimer);
                //    clearTimeout(this.logoutTimer);
                //    this.setSlidingTimers();
                //}
            },
            setRefreshTimers() {
                //if (this.isLoggedIn) {
                //    this.refreshTimer = setTimeout(this.refreshToken, 5 * 60 * 1000);
                //}
            },
            resetRefreshTimers() {
                //if (this.isLoggedIn) {
                //    clearTimeout(this.refreshTimer);
                //    this.setRefreshTimers();
                //}
            },
            async refreshToken() {
                let _self = this;
                await this.refresh().then(() => {
                    if (!_self.user) {
                        _self.isLoggedIn = false;
                        _self.$router.push({ path: '/account/login' });
                    }
                    else if (_self.error) {
                        _self.$toast.add({ severity: 'error', summary: 'Authentication Error', detail: 'The session expires.'/*, life: 3000*/ });
                    }
                    else
                        _self.resetRefreshTimers();
                }).catch((err) => { console.log(err); });
            },
            forceLogout() {
                this.warningZone = false;
                this.logout().then(() => {
                    this.isLoggedIn = false;
                    this.$router.push({ path: '/account/login' });
                    const body = document.getElementsByTagName("body")[0];
                    const themeElement = document.getElementById('theme-link');
                    const theme = "dark";
                    themeElement.setAttribute('href', "/_assets/themes/mdc-" + theme + "-indigo/theme.css");
                    body.dataset['theme'] = theme;
                });
            },
            showIdleMessage() {
                ////this.warningZone = true;
                ////this.countDownTimer = setTimeout(this.countTimer, 1 * 1000);
            },
            countTimer() {
                //if (this.warningZone) {
                //    clearTimeout(this.countDownTimer);
                //    if (this.countdown > 0) {
                //        this.countdown--;
                //    }
                //    else {
                //        this.countdown = 59;
                //        this.forceLogout();
                //    }
                //    this.countDownTimer = setTimeout(this.countTimer, 1 * 1000);
                //}
            },
            reset() {
                this.warningZone = false;
                clearTimeout(this.countDownTimer);
                this.countdown = 59;
                this.resetSlidingTimers();
            },
            grant() {
                this.$store.dispatch("auth/getSitemap");
                this.checkLogin();
                this.setSlidingTimers();
                this.setRefreshTimers();
            },
            beforeunloadFn(e) {
                e.preventDefault();
                // ...
            },
            checkLogin() {
                const session = this.$ls.get("x-cosmos-token");
                const user = this.$ls.get("x-cosmos-session");
                const menu = this.$ls.get("x-cosmos-sitemap");
                const body = document.getElementsByTagName("body")[0];
                const themeElement = document.getElementById('theme-link');
                if (!this.loggedIn && this.$store.state.user) {
                    this.$store.commit("auth/SET_USER_TOKEN", {
                        session: session,
                        user: user,
                        menu: menu
                    });
                    const theme = ((user?.defaults?.theme ?? 100) === 100 ? "dark" : "light");
                    sessionStorage.setItem("session-theme", theme);
                    themeElement.setAttribute('href', "/_assets/themes/mdc-" + theme + "-indigo/theme.css");
                    body.dataset['theme'] = theme;
                    var html = document.getElementsByTagName("HTML")[0];
                    var att = document.createAttribute("style");
                    att.value = "font-size: " + (user.defaults?.display ?? 14) + "px !important";
                    html.setAttributeNode(att);
                }
                else {
                    let theme = sessionStorage.getItem("session-theme") ?? "dark";
                    themeElement.setAttribute('href', "/_assets/themes/mdc-" + theme + "-indigo/theme.css");
                    body.dataset['theme'] = theme;
                }
            },
            initNeodynamics() {
                neodynamics.default.ping(this.sessionID);
                neodynamics.default.printers(this.sessionID);
            }
        },
        created() {
            this.checkLogin();
            //this.initNeodynamics();
            //if (this.$store.getters["auth/loggedIn"])
            //    this.$router.go({ to: "/Dashboard" });
            //window.onbeforeunload = function (event) {
            //    this.beforeunloadFn(event);
            //    return confirm("Confirm refresh");
            //};
            //this.$router.go();
        },
        mounted() {
            //window.oncontextmenu = function () {
            //    return false;
            //};

            //document.addEventListener("keydown", function (event) {
            //    var key = event.key || event.keyCode;

            //    if (key == 123) {
            //        return false;
            //    } else if ((event.ctrlKey && event.shiftKey && key == 73) || (event.ctrlKey && event.shiftKey && key == 74)) {
            //        return false;
            //    }
            //}, false);
            this.$store.dispatch("company/getURL", { url: '/services/Settings/Company?type=4' })
                .then((response) => {
                    this.$vm.storage.app.source.warehouse = response.result;
                })
                .catch((a) => { console.log(a); });
            setTimeout(() => {
                const elements = document.getElementsByClassName("loading");
                while(elements.length > 0) {
                elements[0].parentNode.removeChild(elements[0]);
                this.$vm.$location = this.$filters.location();
            }
            }, 1000);
            if (this.user)
                this.isLoggedIn = true;
            this.countdown = 59;
            this.warningZone = false;
            this.events.forEach((event) => {
                window.addEventListener(event, this.resetSlidingTimers);
            }, this);
            window.addEventListener("scroll", function () {
                window.scroll(0, window.pageYOffset)
            }, false);
        //    window.addEventListener('beforeunload', e => this.beforeunloadFn(e), { capture: false });
        },
        umounted() {
            this.resetRefreshTimers();
            this.resetSlidingTimers();
            this.events.forEach((event) => {
                window.removeEventListener(event, this.resetSlidingTimers);
            }, this);
        }
    }
</script>