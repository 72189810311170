<template>
    <div class="card">
        <div class="p-d-flex p-grid p-col-12">
            <div class="p-col-12 p-md-4">
                <h4>RECIPIENT DETAIL</h4>
                <div class="card" :style="`background: var(--surface-c); min-height: 21rem; height: calc(100% * 2rem);`">
                    <form-contact :disabled="true" id="Delivery" :value="deliveryContact" :useRegistrationNumber="true" disabledView="perline" />
                    <form-address id="DeliveryAddress" :value="deliveryAddress" :useCompany="true" :disabled="true" disabledView="perline" />
                </div>
                <h4>SENDER DETAIL</h4>
                <div class="card" :style="`background: var(--surface-c); min-height: 21rem; height: calc(100% * 2rem); ` ">
                    <form-contact :disabled="true" id="Collection" :value="collectionContact" :useRegistrationNumber="true" disabledView="perline" />
                    <form-address id="CollectionAddress" :value="collectionAddress" :useCompany="true" :disabled="true" disabledView="perline" />
                </div>
            </div>
            <div class="p-col-12 p-md-4">
                <h4>ORDER INFO</h4>
                <div class="card" :style="`background:var(--surface-a); min-height: 46rem;`">
                    <table style="width: calc(100% + 2rem); padding: 0.25rem 0.5rem; margin: -2rem -1rem; margin-bottom: -1rem;" cellspacing="0">
                        <tbody>
                            <tr style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Reference 1</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.customerReference ?? '(auto number)'}`"></div></td>
                            </tr>
                            <tr style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Reference 2</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.customerReference2 ?? ''}`"></div></td>
                            </tr>
                            <tr style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Reference 3</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.customerReference3 ?? ''}`"></div></td>
                            </tr>
                            <tr style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Reference 4</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.customerReference4 ?? ''}`"></div></td>
                            </tr>
                            <tr style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Reference 5</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.customerReference5 ?? ''}`"></div></td>
                            </tr>
                            <tr style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Reference 6</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.customerReference6 ?? ''}`"></div></td>
                            </tr>
                            <tr style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Reference 7</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.customerReference7 ?? ''}`"></div></td>
                            </tr>
                            <tr style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Instruction</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.deliveryInstruction ?? ''}`"></div></td>
                            </tr>
                            <tr style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Safe Place</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.safePlace ?? ''}`"></div></td>
                            </tr>
                            <tr style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Package Type</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$vm.$enums['serviceproviderproductpackagingtype'].filter((x) => x.id == model?.packageType)[0]?.text?.toUpperCase()}`"></div></td>
                            </tr>
                            <tr style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Currency</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$vm.$currencies?.filter((x) => x.id == model?.currencyID)[0]?.code?.toUpperCase()}`"></div></td>
                            </tr>
                            <tr style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Weight Unit</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$vm.$enums['weightunit'].filter((x) => x.id == model?.weightUnit)[0]?.text?.toUpperCase()}`"></div></td>
                            </tr>
                            <tr style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Dimension Unit</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$vm.$enums['lengthunit'].filter((x) => x.id == model?.dimensionUnit)[0]?.text?.toUpperCase()}`"></div></td>
                            </tr>
                            <tr style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Notification</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.notification > 0 ? ( model?.notification == 1 ?  'SMS' :  model?.notification == 2 ?  'EMAIL' : 'SMS EMAIL' ) : ''}`"></div></td>
                            </tr>
                            <tr v-if="(model?.isHazardous ?? false)">
                                <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) ">
                                    <h4 style="margin: -0.25rem -0.5rem;">
                                        HAZMAT CONTACT
                                    </h4>
                                </th>
                            </tr>
                            <tr v-if="(model?.isHazardous ?? false)" style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Name</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.dG_EmergencyContact ?? ''}`"></div></td>
                            </tr >
                            <tr v-if="(model?.isHazardous ?? false)" style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Telephone</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.dG_EmergencyTelephone ?? ''}`"></div></td>
                            </tr>
                            <tr v-if="!isDomestic">
                                <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) ">
                                    <h4 style="margin: -0.25rem -0.5rem">
                                        INTERNATIONAL FORMS
                                    </h4>
                                </th>
                            </tr>
                            <tr v-if="!isDomestic" style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Incoterm</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$vm.$enums['incotermtype'].filter((x) => x.id == model?.incoterm)[0]?.text}`"></div></td>
                            </tr>
                            <tr v-if="!isDomestic" style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Customs</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$vm.$enums['customscategorytype'].filter((x) => x.id == model?.customsCategoryType)[0]?.text}`"></div></td>
                            </tr>
                            <tr v-if="!isDomestic" style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Export Reason</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$vm.$enums['exportreasontype'].filter((x) => x.id == model?.exportReason)[0]?.text}`"></div></td>
                            </tr>
                            <tr v-if="!isDomestic" style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">CPC Number</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.cpcNumber ?? ''}`"></div></td>
                            </tr>
                            <tr v-if="!isDomestic" style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Content</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.packageContent ?? ''}`"></div></td>
                            </tr>
                            <tr v-if="!isDomestic">
                                <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) ">
                                    <h4 style="margin: -0.25rem -0.5rem">
                                        {{$vm.$enums['invoicetype'].filter((x) => x.id == model?.invoiceType)[0]?.text.toUpperCase()}}
                                    </h4>
                                </th>
                            </tr>
                            <tr v-if="!isDomestic" style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Invoice Number</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.invoiceNumber ?? ''}`"></div></td>
                            </tr>
                            <tr v-if="!isDomestic" style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Invoice Date</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatDateWithFormat(model?.invoiceDate,'DD/MMM/YYYY')}`"></div></td>
                            </tr>
                            <tr v-if="!isDomestic" style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Invoice Note</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.invoiceNote ?? ''}`"></div></td>
                            </tr>
                            <tr v-if="!isDomestic" style="border-bottom:thin; border:0.01rem;">
                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Shipping Cost</th>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatCurrency(model?.shippingCost ?? 0.00,$vm.$currencies?.filter((x) => x.id == model?.currencyID)[0]?.code?.toUpperCase(),$vm.$countries?.filter((x) => x.id == model?.shipFrom_CountryID)[0]?.code,2)}`"></div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="p-col-12 p-md-4">
                <h4>ALLOCATION INFO</h4>
                <div class="card" :style="`background:var(--surface-a); min-height: 46rem;`">
                    <table style="width: calc(100% + 2rem); padding: 0.25rem 0.5rem; margin: -2rem -1rem; margin-bottom: -1rem;" cellspacing="0">
                        <tr>
                            <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) "><h4 style="margin: -0.25rem -0.5rem">COLLECTION INFO</h4></th>
                        </tr>
                        <!--<tr style="border-bottom:thin; border:0.01rem;">
        <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Type</th>
        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$vm.$enums['pickuptype'].filter((x) => x.id == model?.pickupType)[0]?.text}`"></div></td>
    </tr>-->
                        <tr style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Collection Date</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatDateWithFormat(model?.request_CollectionDate,'DD/MMM/YYYY')}`"></div></td>
                        </tr>
                        <tr style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Ready Time</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatDateWithFormat(model?.request_ReadyTime, 'HH:mm')}`"></div></td>
                        </tr>
                        <tr style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Closing Time</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatDateWithFormat(model?.request_ClosingTime, 'HH:mm')}`"></div></td>
                        </tr>
                        <tr>
                            <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) "><h4 style="margin: -0.25rem -0.5rem">SHIPPING INFO</h4></th>

                        </tr>
                        <tr style="border-bottom:thin; border:0.01rem;" v-if="(model?.allocationDetail?.shippingInfo?.serviceGroup?.text)">
                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Service Group</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.allocationDetail?.shippingInfo?.serviceGroup?.text ?? ''}`"></div></td>
                        </tr>
                        <tr style="border-bottom:thin; border:0.01rem;" v-if="(model?.allocationDetail?.shippingInfo?.carrier?.text)">
                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Carrier</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.allocationDetail?.shippingInfo?.carrier?.text ?? ''}`"></div></td>
                        </tr>
                        <tr style="border-bottom:thin; border:0.01rem;" v-if="(model?.allocationDetail?.shippingInfo?.product?.text)">
                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Service</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.allocationDetail?.shippingInfo?.product?.text ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="(model?.allocationDetail?.shippingInfo?.option?.text)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Option</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.allocationDetail?.shippingInfo?.option?.text ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="(model?.allocationDetail?.shippingInfo?.additional?.code)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Additional Code</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.allocationDetail?.shippingInfo?.additional?.code ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="(model?.allocationDetail?.shippingInfo?.product?.configuration?.GenerateCustomsDataFlag ?? false)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Customs Data</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${(model?.allocationDetail?.shippingInfo.customization?.generateCustomsData ?? false) ? 'Y' : 'N'}`"></div></td>
                        </tr>

                        <tr style="border-bottom:thin; border:0.01rem;" v-if="(model?.allocationDetail?.shippingInfo?.release?.code)">
                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Release Code</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.allocationDetail?.shippingInfo?.release?.code ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="model?.allocationDetail?.shippingInfo?.accesspoint?.text && !(model?.allocationDetail?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Access Point Location</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.allocationDetail?.shippingInfo?.accesspoint?.text ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="(model?.allocationDetail?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Alternate Address</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.allocationDetail?.shippingInfo?.accesspoint?.alternateDelivery?.address?.companyName ?? ''} ${model?.allocationDetail?.shippingInfo?.accesspoint?.alternateDelivery?.address?.fullDescription ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="(model?.isReturnable ?? false)">
                            <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) "><h4 style="margin: -0.25rem -0.5rem">RETURN INFO</h4></th>
                        </tr>
                        <tr v-if="(model?.isReturnable ?? false) && (model?.allocationDetail?.returnInfo?.carrier?.text)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Carrier</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.allocationDetail?.returnInfo?.carrier?.text ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="(model?.isReturnable ?? false)  && (model?.allocationDetail?.returnInfo?.product?.text)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Service</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.allocationDetail?.returnInfo?.product?.text ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="(model?.isReturnable ?? false)  && (model?.allocationDetail?.returnInfo?.option?.text)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Option</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.allocationDetail?.returnInfo?.option?.text ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="(model?.isReturnable ?? false) && (model?.allocationDetail?.returnInfo?.additional?.code)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Additional Code</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.allocationDetail?.returnInfo?.additional?.code ?? ''}`"></div></td>
                        </tr>

                        <!--<tr v-if="(model?.isReturnable ?? false)" style="border-bottom:thin; border:0.01rem;">
        <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Release Code</th>
        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.allocationDetail?.returnInfo?.release?.code ?? ''}`"></div></td>
    </tr>-->
                        <tr v-if="(model?.allocationDetail?.returnInfo?.accesspoint?.alternateDelivery?.enable ?? false)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Alternate Address</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model?.allocationDetail?.returnInfo?.accesspoint?.alternateDelivery?.address?.companyName ?? ''} ${model?.allocationDetail?.returnInfo?.accesspoint?.alternateDelivery?.address?.fullDescription ?? ''}`"></div></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div>
            <div style="width: calc(100% + 2.5rem); padding: 0; margin: -2rem -1.3rem; margin-bottom: -1rem;">
                <pv-tab>
                    <pv-tabpanel header="Packages">
                        <package-grid :parentID="model?.id" :currencyID="model?.currencyID" :weightUnit="model?.weightUnit" :dimensionUnit="model?.dimensionUnit" :isHazardous="model?.isHazardous" :countryID="model?.shipFrom_CountryID" :isDisabled="true" :isInitSource="true" :packageSource="model?.packages"> </package-grid>
                    </pv-tabpanel>
                </pv-tab>
            </div>
        </div>
    </div>
</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/consignment-mixins.js';
    import consignmentpackage from '@/components/transaction/tms/consignment/record/package-formnew.vue';
    export default {
        name: 'ConfirmationTemplate',
        emits: ["is-sync"],
        components: {
            'package-grid': consignmentpackage
        },
        mixins: [mixins.CONFIRMATIONFORM],
        computed: {
            OriginISO2() {
                return this.$vm.$countries.filter(x => x.id === this.model?.shipFrom_CountryID)[0]?.code;
            },
            DestinationISO2() {
                return this.$vm.$countries.filter(x => x.id === this.model?.shipTo_CountryID)[0]?.code;
            },
            IsDomestic() {
                return this.$filters.IsDomestic(this.OriginISO2, this.model?.shipFrom_PostalCode ?? '', this.DestinationISO2, this.model?.shipTo_PostalCode ?? '');
            }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>