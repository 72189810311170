<template>
    <form-wrapper :title="`${this.$router.currentRoute.value.meta.title.toLowerCase()}`" :sub-title="`${this.$router.currentRoute.value.meta.subtitle}`">
        <cosmos-grid id="CustomMessageList" sourceType="url" :source="url" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteDisplayFields="deleteDisplay" :showDelete="true" :showAdd="true" :showExport="false" :operationWidth="7">
            <template #toolbar>
                <div class="p-d-flex p-jc-between p-ai-center">
                    <span>
                        <pv-button icon="pi pi-fw pi-plus" label="Add Record" style="margin: 0.20rem;" @click="onCreate"></pv-button>
                        <pv-button icon="pi pi-fw pi-save" class="p-button-warning" label="Update JSON File" style="margin: 0.20rem;" @click="onUpdateFile"></pv-button>
                    </span>
                    <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                        <i class="pi pi-search" />
                        <pv-input v-model="activeFilters['global'].value" placeholder="Keyword Search" style="width: 100%;" @keyup="onForceFilter" />
                    </span>
                </div>
            </template>
            <pv-grid-column field="code" header="CODE"  headerStyle="min-width: 7rem;max-width: 10rem;" style="min-width: 7rem; max-width: 10rem; text-align: left; object-position: center; text-overflow: ellipsis; white-space: nowrap;">>
                <template #body="slotProps">
                    <div class="p-d-flex p-jc-between" style="width: 100%; padding: 0 10px; margin: 0;">
                        <div class="p-code-text">{{ slotProps.data.code}}</div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="name" header="DESCRIPTION">
                <template #body="slotProps">
                    <div class="p-d-flex p-jc-between" style="width: 100%; padding: 0 10px; margin: 0;">
                        <div class="p-code-text">{{ slotProps.data.name}}</div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="operatorTypeText" header="OPERATOR">
                <template #body="slotProps">
                    <div class="p-d-flex p-jc-between" style="width: 100%; padding: 0 10px; margin: 0;">
                        <div class="p-code-text">{{ slotProps.data.operatorTypeText}}</div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="message" header="MESSAGE">
                <template #body="slotProps">
                    <div class="p-d-flex p-jc-between" style="width: 100%; padding: 0 10px; margin: 0;">
                        <div class="p-code-text">{{ slotProps.data.message}}</div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="remarks" header="CARRIER">
                <template #body="slotProps">
                    <div class="p-d-flex p-jc-between" style="width: 100%; padding: 0 10px; margin: 0;">
                        <div class="p-code-text">{{ slotProps.data.remarks}}</div>
                    </div>
                </template>
            </pv-grid-column>
        </cosmos-grid>
    </form-wrapper>
</template>
<script>
    import { FilterMatchMode } from 'primevue/api';
    export default {
        name: 'CustomMessageList',
        data() { return { searchFields: ['code', 'name', 'remarks', 'message'] } },
        computed: {
            activeFilters() {
                var filter = {};
                if (this.filters)
                    filter = this.filters;
                filter["global"] = { value: "", matchMode: FilterMatchMode.CONTAINS };
                return filter;
            },

            ticks() {
                return this.$moment.utc().format("YYYYMMDD");
            },
            type() { return this.$route.meta.typeId ?? 1; },
            url() { return `/services/settings/custommessage?type=${this.type}` }  
        },
        methods: {
            onForceFilter(e) {
                if (e.keyCode === 13 || this.activeFilters["global"].value === '') {
                    //this.onRefresh();
                }
            },
            onCreate() { this.$router.push({ name: `${this.$route.meta.type}-add` }); },
            onUpdateFile() {
                this.$store.dispatch("custommessage/getURL", { url: `/services/settings/custommessage/updatejson/type/id/${this.type}` }).then(() => {
                    this.$toast.add({ severity: 'success', summary: 'Update Success!', detail: 'The json file was updated successfully.', life: 6000 });
                }).catch(() => {
                    this.$toast.add({ severity: 'error', summary: 'Update Failed!', detail: 'The json file update failed.', life: 6000 });
                });
            },
            setType() {
                switch (this.router.type.toLowerCase()) {
                    case "customerror":
                        return 1;
                    case "customdelivered":
                        return 2;
                    case "customoutfordelivery":
                        return 4;
                    case "customintransit":
                        return 8;
                }
            }
        },
        mounted() { this.loading = false; }
    }
</script>
