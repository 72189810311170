<template>
    <pv-grid :id="id"
             :ref="id"
             :value="source"
             :totalRecords="source.length"
             :alwaysShowPaginator="true"
             :paginator="true"
             :paginatorPosition="'bottom'"
             :pageLinkSize="5"
             :rowsPerPageOptions="[5, 10, 25, 50, 100]"
             :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport'"
             :currentPageReportTemplate="'Showing {first} - {last} of {totalRecords} records'"
             :rows="10"
             :lazy="true"
             :dataKey="'id'"
             :scrollWidth="'flex'"
             :scrolHeight="'flex'"
             :responsive="'scroll'"
             responsiveLayout="scroll"
             :columnResizeMode="'expand'"
             :rowHover="true"
             v-model:expandedRows="expandedRows"
             autoLayout
             showGridlines
             stripedRows>
        <pv-grid-column-group type="header">
            <pv-grid-row>
                <pv-grid-column :rowspan="2" headerStyle="width: 3rem" />
                <pv-grid-column :rowspan="2" headerStyle="width: 3rem" />
                <pv-grid-column :colspan="4" header="General" />
                <pv-grid-column :colspan="7" header="Return References" />
                <pv-grid-column :colspan="6" header="Carrier Details" />
                <pv-grid-column :colspan="3" header="Last Activity Details" />
            </pv-grid-row>
            <pv-grid-row>
                <pv-grid-column field="serviceProviderName" header="Provider" headerStyle="width: 10rem; text-wrap: none; white-space: nowrap;" />
                <pv-grid-column field="serviceType" header="Service Type" headerStyle="width: 10rem; text-wrap: none; white-space: nowrap;" />
                <pv-grid-column field="orderAction" header="Action" headerStyle="width: 10rem; text-wrap: none; white-space: nowrap;" />
                <pv-grid-column field="milestone_ReturnDate" header="Return Date" headerStyle="width: 10rem; text-wrap: none; white-space: nowrap;" />
                <pv-grid-column field="reference1" header="Reference 1" headerStyle="width: 10rem; text-wrap: none; white-space: nowrap;" />
                <pv-grid-column field="reference2" header="Reference 2" headerStyle="width: 10rem; text-wrap: none; white-space: nowrap;" />
                <pv-grid-column field="reference3" header="Reference 3" headerStyle="width: 10rem; text-wrap: none; white-space: nowrap;" />
                <pv-grid-column field="reference4" header="Reference 4" headerStyle="width: 10rem; text-wrap: none; white-space: nowrap;" />
                <pv-grid-column field="reference5" header="Reference 5" headerStyle="width: 10rem; text-wrap: none; white-space: nowrap;" />
                <pv-grid-column field="reference6" header="Reference 6" headerStyle="width: 10rem; text-wrap: none; white-space: nowrap;" />
                <pv-grid-column field="reference7" header="Reference 7" headerStyle="width: 10rem; text-wrap: none; white-space: nowrap;" />
                <pv-grid-column field="carrier" header="Carrier" headerStyle="width: 10rem; text-wrap: none; white-space: nowrap;" />
                <pv-grid-column field="carrierService" header="Service" headerStyle="width: 10rem; text-wrap: none; white-space: nowrap;" />
                <pv-grid-column field="carrierReference" header="Reference" headerStyle="width: 10rem; text-wrap: none; white-space: nowrap;" />
                <pv-grid-column field="collectionReference" header="Collection Reference" headerStyle="width: 10rem; text-wrap: none; white-space: nowrap;" />
                <pv-grid-column field="collectionDate" header="Collection Date" headerStyle="width: 10rem; text-wrap: none; white-space: nowrap;" />
                <pv-grid-column field="awbNumber" header="AWB Number" headerStyle="width: 10rem; text-wrap: none; white-space: nowrap;" />
                <pv-grid-column field="lastActivityDate" header="Date" headerStyle="width: 10rem; text-wrap: none; white-space: nowrap;" />
                <pv-grid-column field="lastActivityLocation" header="Location" headerStyle="width: 10rem; text-wrap: none; white-space: nowrap;" />
                <pv-grid-column field="lastActivity" header="Notes" headerStyle="width: 10rem; text-wrap: none; white-space: nowrap;" />
            </pv-grid-row>
        </pv-grid-column-group>
        <pv-grid-column :expander="true" headerStyle="width: 3rem" />
        <pv-grid-column field="mask" :headerStyle="`min-width: 3rem; max-width: 3rem; width: 3rem`" style="min-width: 3rem; max-width: 3rem; border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-d-flex" :style="`width:3rem;`">
                        <pv-button icon="pi pi-print" iconPos="right" @click="onPrint(slotProps.data,'return')" class="p-button-warning" v-tooltip.top="{value: 'Print Return Label'}" />
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="serviceProviderName" header="Provider" headerStyle="min-width: 5rem; max-width: 13rem; width: 5rem" style="min-width: 5rem; max-width: 13rem;text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.serviceProviderName }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="serviceType" header="Service Type" headerStyle="min-width: 5rem; max-width: 13rem; width: 5rem" style="min-width: 5rem; max-width: 13rem;text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.serviceType }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="orderAction" header="Order Action" headerStyle="min-width: 5rem; max-width: 13rem; width: 5rem" style="min-width: 5rem; max-width: 13rem;text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.orderAction }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="milestone_ReturnDate" header="Return Date" headerStyle="min-width: 5rem; max-width: 13rem; width: 5rem" style="min-width: 5rem; max-width: 13rem;text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{$filters.toDateFullTimeDisplay(slotProps.data?.milestone_ReturnDate)}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference1" header="Reference 1" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        <router-link style="font-size: 1rem; font-weight:700;" :to="{ name: 'returns-edit', params: { mask: slotProps.data.mask }}">{{slotProps.data.reference1}}</router-link>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference2" header="Reference 2" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.reference2 }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference3" header="Reference 3" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.reference3 }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference4" header="Reference 4" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.reference4 }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference5" header="Reference 5" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.reference5 }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference6" header="Reference 6" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.reference6 }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference7" header="Reference 7" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.reference7 }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="carrier" header="Carrier" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.carrier }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="carrierService" header="Service" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.carrierService }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="carrierReference" header="Carrier Reference" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width: 100%; padding: 0 !important; margin: 0 !important; font-weight: bold;">
                        <a :href="slotProps.data?.trackingURL" target="_blank">{{ slotProps.data?.carrierReference }}</a>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="collectionReference" header="Collection Reference" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.collectionReference }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="collectionDate" header="Collection Date" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.collectionDate }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="awbNumber" header="AWB Number" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.awbNumber }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="lastActivityDate" headerStyle="min-width: 5rem; max-width: 13rem; width: 5rem" style="min-width: 5rem; max-width: 13rem;text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ $filters.toDateFullTimeDisplay(slotProps.data?.lastActivityDate) }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="lastActivityLocation" header="Last Activity Location" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 13rem;text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.lastActivityLocation }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="lastActivity" header="Last Activity" headerStyle="min-width: 5rem; max-width: 30rem; width: 5rem" style="min-width: 5rem; max-width: 13rem;text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.lastActivity }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <template #expansion="slotProps">
            <div class="orders-subtable" style="margin: 0; padding: 0;">
                <return-detail-form :parentID="slotProps.data.id"></return-detail-form>
            </div>
        </template>
        <template #empty>
            No records found.
        </template>
        <template #loading>
            Retrieving records from database. Please wait...
        </template>
        <!-- Pagination -->
        <template #paginatorRight>
            <pv-button type="button" icon="pi pi-refresh" class="p-button-text" @click="getSource" />
        </template>
    </pv-grid>
    <print-form :formData="dialog?.print?.model" module="return" :showDialog="dialog.print.show" :documentType="dialog.print.type" @is-confirm="{dialog.print.show = false; dialog.print.model = null; dialog.print.type = ''; dialog.print.isBatch = false}" @is-cancel="{dialog.print.show = false; dialog.print.model = null; dialog.print.type = '';}"></print-form>
</template>
<script>
    import ReturnDetailForm from '@/components/transaction/tms/returns/returns-detail-form.vue';
    import printform from '@/components/transaction/tms/consignment/record/print-form.vue';
    export default {
        name: 'ConsignmentReturnsTemplate',
        components: {
            'return-detail-form': ReturnDetailForm,
            'print-form': printform
        },
        props: {
            parentID: { type: Number },
        },
        data() {
            return {
                type: -1,
                filters: {},
                columns: [
                    { field: 'serviceProviderName', header: 'Provider Name' },
                    { field: 'serviceType', header: 'Service Type' },
                    { field: 'reference1', header: 'Reference 1' },
                    { field: 'reference2', header: 'Reference 2' },
                    { field: 'reference3', header: 'Reference 3' },
                    { field: 'reference4', header: 'Reference 4' },
                    { field: 'orderAction', header: 'Action' },
                    { field: 'milestone_ReturnDate', header: 'ReturnDate' },
                    { field: 'lastActivityDate', header: 'Last Activity Date' },
                    { field: 'lastActivity', header: 'Last Activity Action' },
                    { field: 'lastActivityLocation', header: 'Last Activity Location' },
                ],
                source: [],
                deleteDisplay: ["code", "name"],
                searchFields: ["details"],
                forceRefresh: false,
                expandedRows: [],
                dialog: {
                    print: {
                        show: false,
                        type: '',
                        model: null
                    }
                }

            }
        },
        computed: {},
        methods: {
            getSource() {
                this.source = [];
                this.$store.dispatch("returns/getURL", { url: `/services/tms/return/consignment/${this.parentID}` })
                    .then((response) => {
                        this.source = response?.result ?? [];
                    });
            },
            onPrint(e, type) {
                this.dialog.print.model = e;
                this.dialog.print.model.code = e.carrierReference;
                this.dialog.print.show = true;
                this.dialog.print.type = type ?? 'label';
            }
        },
        async created() {
            this.getSource();
        },
        async mounted() {
        }
    }
</script>