<template>
    <cosmos-grid id="reportGrid" sourceType="array" :source="source?.data ?? []" :showCheckbox="true" :sourcePaged="true" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :showExport="false" :showOperation="false" :showHeader="false" @is-selected="onSelected($event)" :selectedReset="selectedReset" :forceRefresh="forceRefresh" style="position: relative;" :persistent="false">
        <pv-grid-column field="collectionDate" header="DATE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ $filters.toDateDisplay(slotProps.data?.collectionDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="warehouseName" header="WAREHOUSE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.warehouseName}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerName" header="CUSTOMER" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.customerName}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="code" header="TRANSACTION ID" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{slotProps.data.code}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference" header="REFERENCE 1" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left;">
                        {{slotProps.data.customerReference}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference2" header="REFERENCE 2" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left;">
                        {{slotProps.data.customerReference2}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference3" header="REFERENCE 3" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left;">
                        {{slotProps.data.customerReference3}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="providerName" header="CARRIER" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.providerName}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="serviceName" header="SERVICE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ slotProps.data.serviceName}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference" header="REFERENCE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{slotProps.data.carrierReference}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="shipperCost" header="CONTRACT COST" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatCurrency(slotProps.data.contractCost ?? 0.00,slotProps.data.contract_CurrencyCode?.toUpperCase(),slotProps.data.shippingContractCountryCode,2)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="carrierCost" header="CUSTOMER COST" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatCurrency(slotProps.data.customerCost ?? 0.00,slotProps.data.contract_CurrencyCode?.toUpperCase(),slotProps.data.shippingContractCountryCode,2)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="totalParcel" header="TOTAL PACKAGE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatNumber(slotProps.data.total_PackageCount ?? 0.00,0,slotProps.data.shippingContractCountryCode)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="totalWeight" header="TOTAL WEIGHT (KG)" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatNumber(slotProps.data.total_Weight ?? 0.00,3,slotProps.data.shippingContractCountryCode)}}
                </div>
            </template>
        </pv-grid-column>
    </cosmos-grid>
</template>
<script>
    import { mapGetters } from 'vuex';
    import { useVuelidate } from '@vuelidate/core';
    export default {
        name: 'reportGrid',
        setup: () => ({ v$: useVuelidate() }),
        props: {
            formData: { type: Object },
            filterKey: { type: Number, default: null },
            isAdmin: { type: Boolean, default: false },
            excludeWithInvoice: { type: Boolean, default: false }
            
        },
        data() {
            return {
                type: -1,
                filter: {},
                columns: [],
                deleteDisplay: ["code", "name"],
                searchFields: ["code", "name"],
                activity: { loading: true, download: false },
                source: {
                    total: 0,
                    data: []
                },
                modelState: null,
                initialBind: false,
                quote: null,
                quoteShow: false
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            scopeCustomerDS() {
                return this.$config.common.$customer(this.user?.environmentID ?? -100, this.$vm.$appSession.warehouse.id ?? this.user.warehouseID, this.user);
            },
            scopeWarehouseDS() {
                return this.$config.common.$warehouse(this.user?.environmentID ?? -100, this.user);
            },
            apiData() {
                return {
                    ServiceHeader: {
                        Username: "TEMP",
                        Password: "TESTESTEDET",
                        ClientID: "TESTTESTTESTTESTTEST",
                        ClientSecret: "TESTEDREDREDTETEDEDETEREDEDEREDE"
                    },
                    Request: {
                        DC: this.formData?.warehouseID > 0 ? this.scopeWarehouseDS.filter(x => x.id === this.formData.warehouseID)[0]?.code : null,
                        Customer: this.formData?.customerID > 0 ? this.scopeCustomerDS.filter(x => x.id === this.formData.customerID)[0]?.code : null,
                        Account: this.formData?.serviceProviderAccountCode,
                        Carrier: this.formData?.serviceProviderID > 0 ? this.$vm.$providers.filter(y => y.id === this.formData.serviceProviderID)[0]?.code : null,
                        ScopeDetail: {
                            IncludeArchive: this.formData?.includeArchived,
                            ExcludeWithInvoice: this.excludeWithInvoice,
                            Statuses: this.formData?.statusFilter,
                            CollectionDate: {
                                From: this.$filters.formatDateWithFormat(this.formData.collectionDateFrom ?? new Date(), "YYYY-MM-DD"),
                                To: this.$filters.formatDateWithFormat(this.formData.collectionDateTo ?? new Date(), "YYYY-MM-DD")
                            }
                        }
                    }
                }
            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            'filterKey': {
                handler() {
                    this.$nextTick(() => {
                        this.getSource();
                    });
                }, deep: true
            },
            activity: {
                handler() {
                    this.$emit('is-sync', this.activity);
                }, deep: true
            },
            'source.data': {
                handler() {
                    this.$emit('is-source', { consignments: this.source.data ?? [] });
                }, deep: true
            },
            'selectedItems': {
                handler() {
                     this.$emit("is-selected", { consignments: this.selectedItems ?? [] });
                }, deep: true
            },

        },
        methods: {
            async getSource() {
                this.$nextTick(() => { this.activity.loading = true; });
                this.$nextTick(() => {
                    this.$axios.post(this.$config.config.endpoint.api + `/services/facility/invoice/v2/consignment`.replaceAll("//", "/"), this.apiData)
                        .then((response) => {
                            this.source.data = response?.data?.result ?? [];
                            this.source.total = this.source.data.length;
                            this.$nextTick(() => { this.activity.loading = false; });
                        })
                        .catch(() => {
                            this.source.data = [];
                            this.source.total = 0;
                            this.$nextTick(() => { this.activity.loading = false; });
                        });
                });
            },
            onSelected(e) {
                this.selectedReset = false;
                this.selectedItems = e;
            },
        },
        async created() {
            this.activity.loading = false;
        },
        async mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            this.loading = false;
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>
