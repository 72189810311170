<template>
    <div class="card" style="padding: 10px 0;">
        <div class="is-task" v-for="task in source" :key="task.module">
            <h6>{{task.module}}</h6>
            <ul class="task-options">
                <li v-for="option in task.options" :key="option.label" class="p-ripple" @click="onClick(option.route)" v-ripple>
                    <div class="p-d-flex p-jc-between">
                        <div>{{option.label}}</div>
                        <div><pv-badge class='task-count' :severity="`${option.severity}`" :value="`${option.count}`"></pv-badge></div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'DashboardTask',
        props: {
            source: { type: Array }
        },
        methods: {
            onClick(e) { this.$router.push(e); }
        }
    }
</script>