<template>
    <div>
        <form-boolean id="useWarehouseFilter" v-model="model.useWarehouseFilter" class="is-customized-boolean" :disabled="disabled" width="150px" />
        <form-boolean id="useCustomerFilter" v-model="model.useCustomerFilter" class="is-customized-boolean" :disabled="disabled" width="150px" />
        <form-boolean id="useCarrierFilter" v-model="model.useCarrierFilter" class="is-customized-boolean" :disabled="disabled" width="150px" />
        <form-boolean id="useAccountFilter" v-model="model.useAccountFilter" class="is-customized-boolean" :disabled="disabled" width="150px" />
        <form-boolean id="useStatusFilter" v-model="model.useStatusFilter" class="is-customized-boolean" :disabled="disabled" width="150px" />
        <form-boolean id="useIncludeArchive" v-model="model.useIncludeArchive" class="is-customized-boolean" :disabled="disabled" width="150px" />
        <form-boolean id="useCreateDateRangeFilter" v-model="model.useCreateDateRangeFilter" class="is-customized-boolean" :disabled="disabled" width="150px" />
        <form-boolean id="useCollectionDateRangeFilter" v-model="model.useCollectionDateRangeFilter" class="is-customized-boolean" :disabled="disabled" width="150px" />
    </div>
</template>

<script>
    export default {
        name: 'QuoteParcels',
        emits: ["update", "update:modelValue", "isSync"],
        props: {
            parentID: { type: Number, default: 0 },
            modelValue: { type: Array, default: () => [] },
            disabled: { type: Boolean, default: false }
        },
        data() {
            return {
                model: []
            }
        },
        computed: {
            fields() { return this.$vm.$columns; },
        },
        watch: {
            modelValue() {
                this.model = this.modelValue;
                this.onSync();
            }
        },
        methods: {
            onSync() {
                this.$emit("update:modelValue", this.model);
            }
        },
        mounted() {
            if (this.modelValue)
                this.model = this.modelValue;
        }
    }
</script>

