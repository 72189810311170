<template>
    <!--<is-loader v-if="activity.parentLoading"></is-loader>-->
    <div class="content-section header">
        <div class="feature-intro">
            <slot name="header">
                <h1><span>CONSIGNMENT REPORT</span></h1>
                <p>{{ this.$props?.subtitle ?? router.subtitle }}</p>
                <p><i class="fas fa-info-square fa-fw fa-2x is-text-info"></i> <span class="is-text-info">Please be aware that the page will only display the result after <b>Apply Filter</b> button is clicked.</span></p>
            </slot>
        </div>
    </div>
    <div class="content-section body">
        <div class="card">
            <div class="p-grid p-nogutter">
                <div class="p-col-12 p-lg-6 p-nogutter">
                    <div class="p-grid p-nogutter">
                        <div class="p-col-12 p-md-6">
                            <form-lookup id="warehouseID" label="Warehouse" :source="warehouses" :value="model.warehouseID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onWarehouseChange($event)" />
                            <form-lookup id="customerID" label="Customer" :source="customers" :value="isCustomer ? customers[0]?.id : model.customerID " :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onCustomerChange($event)" :disabled="isCustomer" disabledType="object" />
                        </div>
                        <div class="p-col-12 p-md-6">
                            <form-lookup id="serviceProviderID" label="Carrier" :source="providers" :value="model.serviceProviderID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onProviderChange($event)" />
                            <form-lookup id="serviceProviderAccountID" disabledType="object" :disabled="providerAccounts.length === 0" label="Account" :source="providerAccounts" :value="model.serviceProviderAccountID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onAccountChange($event)" />
                        </div>
                    </div>
                    <div class="p-grid p-nogutter">
                        <div class="p-col-12 p-md-6">
                            <form-lookup id="reportType" label="Export Type" required="true" :hideLabel="false" :source="reportTypeDS" :value="reportType" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="reportType = $event?.value" :disabled="!isAdmin" disabledType="object" />
                        </div>
                        <div class="p-col-12 p-md-6">
                            <form-boolean id="includeArchived" :value="model.includeArchived" type="toggle" label="Include Archived?" textPosition="left" @is-sync="model.includeArchived = $event.value"></form-boolean>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-lg-6 p-nogutter">
                    <div class="p-grid p-nogutter">
                        <div class="p-col-12 p-md-6">
                            <form-datetime id="collectionDateFrom" :required="true" :showButton="false" :value="model.collectionDateFromValue" type="date" @is-sync="onCollectionDateFrom($event)" label="Collection Date From" :v$="v$"></form-datetime>
                        </div>
                        <div class="p-col-12 p-md-6">
                            <form-datetime id="collectionDateTo" :required="true" :showButton="false" :value="model.collectionDateToValue" type="date" @is-sync="onCollectionDateTo($event)" label="Collection Date To" :v$="v$"></form-datetime>
                        </div>
                    </div>
                    <div class="p-grid p-nogutter">
                        <div class="p-col-12 p-md-6">
                            <form-datetime id="manifestDateFrom" :value="model.manifestDateFromValue" type="date" @is-sync="onManifestDateFrom($event)" label="Manifest Date From" :v$="v$"></form-datetime>
                        </div>
                        <div class="p-col-12 p-md-6">
                            <form-datetime id="manifestDateTo" :value="model.manifestDateToValue" type="date" @is-sync="onManifestDateTo($event)" label="Manifest Date To" :v$="v$"></form-datetime>
                        </div>
                    </div>
                    <div class="p-grid p-nogutter">
                        <div class="p-col-12 p-md-6">
                            <form-datetime id="dispatchDateFrom" :value="model.dispatchDateFromValue" type="date" @is-sync="onDispatchDateFrom($event)" label="Dispatch Date From" :v$="v$"></form-datetime>
                        </div>
                        <div class="p-col-12 p-md-6">
                            <form-datetime id="dispatchDateTo" :value="model.dispatchDateToValue" type="date" @is-sync="onDispatchDateTo($event)" label="Dispatch Date To" :v$="v$"></form-datetime>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="p-d-flex p-jc-end">
                <pv-button v-if="false" class="p-mr-2" label="Inspector Cost" :disabled="activity.dirty || activity.export || activity.binding" icon="pi pi-search" @click="onInspectorCost()"></pv-button>
                <pv-button class="p-mr-2" label="Export To Excel" :loading="activity.export" :disabled="activity.dirty || activity.export || activity.binding" icon="fas fa-file-excel" @click="onExportToExcel()"></pv-button>
                <pv-button :class="[{ 'p-mr-2': true}, {'p-button-warning': v$.$anyDirty || activity.dirty }]" label="Apply Filter" :disabled="!activity.dirty || activity.export || activity.binding" icon="pi pi-filter" @click="onFilter()"></pv-button>
                <pv-button label="Cancel" icon="pi pi-ban" :disabled="activity.export || activity.binding" @click="onReset()"></pv-button>
            </div>
            <div class="p-grid" style="margin-top: 1rem;">
                <div class="p-col-12">
                    <pv-tab style="margin: 0 -14px !important;">
                        <pv-tabpanel header="RESULT">
                            <report-grid :formData="model" :filterKey="filterKey" :isAdmin="isAdmin" @is-sync="onUpdate($event)"></report-grid>
                        </pv-tabpanel>
                    </pv-tab>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    const moduleName = 'report';
    import reportGrid from '@/views/report/consignment/v2/report-grid.vue';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'report-grid': reportGrid
        },
        data() {
            return {
                model: {
                    warehouseID: null,
                    customerID: null,
                    serviceProviderID: null,
                    serviceProviderAccountID: null,
                    serviceProviderAccountCode: null,
                    collectionDateFrom: null,
                    collectionDateFromValue: null,
                    collectionDateTo: null,
                    collectionDateToValue: null,
                    manifestDateFrom: null,
                    manifestDateTo: null,
                    manifestDateFromValue: null,
                    manifestDateToValue: null,
                    dispatchDateFrom: null,
                    dispatchDateTo: null,
                    dispatchDateFromValue: null,
                    dispatchDateToValue: null,
                    includeArchived: false
                },
                reportType: 200,
                activity: {
                    parentLoading: true,
                    loading: false,
                    export: false,
                    binding: false,
                    dirty: true
                },
                keys: null,
                session: {
                    tenant: null,
                    environment: null,
                    warehouse: null,
                    customer: null,
                    currentUser: null,
                    executiveUser: null
                },
                reportScopes: null,
                sources: {
                    accounts: []
                },
                filterKey: null
            }
        },
        validations() {
            return {
                model: {
                    warehouseID: { $autoDirty: true },
                    customerID: { $autoDirty: true },
                    serviceProviderID: { $autoDirty: true },
                    serviceProviderAccountID: { $autoDirty: true },
                    serviceProviderAccountCode: { $autoDirty: true },
                    collectionDateFrom: { $autoDirty: true },
                    collectionDateFromValue: { $autoDirty: true },
                    collectionDateTo: { $autoDirty: true },
                    collectionDateToValue: { $autoDirty: true },
                    manifestDateFrom: { $autoDirty: true },
                    manifestDateTo: { $autoDirty: true },
                    manifestDateFromValue: { $autoDirty: true },
                    manifestDateToValue: { $autoDirty: true },
                    dispatchDateFrom: { $autoDirty: true },
                    dispatchDateTo: { $autoDirty: true },
                    dispatchDateFromValue: { $autoDirty: true },
                    dispatchDateToValue: { $autoDirty: true },
                    includeArchived: { $autoDirty: true }
                }
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            reportTypeDS() {
                return [{ id: 100, name: 'Shipper Report' }, { id: 200, name: 'Customer Report' }].filter(x => [0, 2, 3].filter(y => y === this.user.userType).length === 0 ? x.id !== 100 : x.id === x.id);
            },
            serviceAccounts() { return this.$store.getters["provideraccount/ds"]; },
            apiData() {
                return {
                    ServiceHeader: {
                        Username: "TEMP",
                        Password: "TESTESTEDET",
                        ClientID: "TESTTESTTESTTESTTEST",
                        ClientSecret: "TESTEDREDREDTETEDEDETEREDEDEREDE"
                    },
                    Request: {
                        DC: this.model?.warehouseID > 0 ? this.warehouses.filter(x => x.id === this.model.warehouseID)[0]?.code : null,
                        Customer: this.model?.customerID > 0 ? this.customers.filter(x => x.id === this.model.customerID)[0]?.code : null,
                        Account: this.model?.serviceProviderAccountCode,
                        Carrier: this.model?.serviceProviderID > 0 ? this.providers.filter(y => y.id === this.model?.serviceProviderID)[0]?.code : null,
                        ScopeDetail: {
                            IncludeArchive: this.model?.includeArchived,
                            ExportType: this?.reportType === 100 ? "AR" : "CR",
                            CollectionDate: {
                                From: this.$filters.formatDateWithFormat(this.model.collectionDateFrom ?? new Date(), "YYYY-MM-DD"),
                                To: this.$filters.formatDateWithFormat(this.model.collectionDateTo ?? new Date(), "YYYY-MM-DD")
                            },
                            ManifestDate: {
                                From: this.model.manifestDateFrom !== undefined && this.model.manifestDateFrom !== null ? this.$filters.formatDateWithFormat(this.model.manifestDateFrom ?? new Date(), "YYYY-MM-DD") : null,
                                To: this.model.manifestDateTo !== undefined && this.model.manifestDateTo !== null ? this.$filters.formatDateWithFormat(this.model.manifestDateTo ?? new Date(), "YYYY-MM-DD") : null
                            },
                            DispatchDate: {
                                From: this.model.dispatchDateFrom !== undefined && this.model.dispatchDateFrom !== null ? this.$filters.formatDateWithFormat(this.model.dispatchDateFrom ?? new Date(), "YYYY-MM-DD") : null,
                                To: this.model.dispatchDateTo !== undefined && this.model.dispatchDateTo !== null ? this.$filters.formatDateWithFormat(this.model.dispatchDateTo ?? new Date(), "YYYY-MM-DD") : null
                            }
                        }
                    }
                }

            },
            warehouses() {
                return (this.reportScopes?.warehouses ?? [])
                    .filter(wh => (this.model.customerID ?? 0) === 0 || ((this.model.customerID ?? 0) > 0 && (wh.customers ?? []).filter(cust => cust === (this.model.customerID ?? 0)).length > 0)) ?? [];
            },
            customers() { return (this.reportScopes?.customers ?? []).filter(cust => (this.model.warehouseID ?? 0) === 0 || ((this.model.warehouseID ?? 0) > 0 && (cust.warehouses ?? []).filter(wh => wh === (this.model.warehouseID ?? 0)).length > 0)) ?? []; },
            providers() { return (this.reportScopes?.providers ?? []).filter(provider => ((this.accounts ?? []).map(acct => acct.serviceProviderID) ?? []).filter(acct => acct == provider.id).length > 0) ?? []; },
            accounts() {
                return (((((this.reportScopes?.accounts ?? [])
                    .filter(acct => (this.model.customerID ?? 0) === 0 || ((this.model.customerID ?? 0) > 0 && (acct.customers ?? []).filter(cust => cust === (this.model.customerID ?? 0)).length > 0)) ?? [])
                    .filter(acct => (this.model.warehouseID ?? 0) === 0 || ((this.model.warehouseID ?? 0) > 0 && ((acct.postingLocations ?? []).length === 0 || (acct.postingLocations ?? []).filter(postLoc => postLoc == this.model.warehouseID).length > 0))) ?? [])
                    .filter(acct => (this.model.warehouseID ?? 0) === 0 || ((this.model.warehouseID ?? 0) > 0 && (acct.customers ?? []).filter(acctcust => ((this.customers ?? []).map(z => z.id) ?? []).filter(cust => cust === acctcust).length > 0).length > 0)) ?? [])
                    .filter(acct => (this.model.serviceProviderID ?? 0) === 0 || ((this.model.serviceProviderID ?? 0) > 0 && acct.serviceProviderID === (this.model.serviceProviderID ?? 0))) ?? []);
            },
            providerAccounts() { return this.sources?.accounts ?? []; },
            isCustomer() {
                return [5, 6, 7].filter(y => y === this.user.userType).length > 0;
            },
            isAdmin() {
                return [0, 2, 3].filter(y => y === this.user.userType).length > 0;
            }

        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            'model': {
                handler() {
                    if (this.filterKey || this.v$.$anyDirty)
                        this.$nextTick(() => { this.activity.dirty = true; });
                }, deep: true
            }
        },
        methods: {
            onFilter() {
                this.$nextTick(() => { this.filterKey = Math.random(); });
                this.v$.$reset();
                this.$nextTick(() => { this.activity.dirty = false; });
            },
            onUpdate(e) {
                this.$nextTick(() => { this.activity.binding = e.loading; });
                this.$nextTick(() => { this.activity.export = e.download; });
            },
            onCollectionDateFrom(e) {
                this.$nextTick(() => {
                    this.model.collectionDateFromValue = e.value;
                    this.model.collectionDateFrom = (e.record.data ?? new Date());
                });
            },
            onCollectionDateTo(e) {
                this.$nextTick(() => {
                    this.model.collectionDateToValue = e.value;
                    this.model.collectionDateTo = (e.record.data ?? new Date());
                });
            },
            onManifestDateFrom(e) {
                this.$nextTick(() => {
                    this.model.manifestFromValue = e.value;
                    this.model.manifestDateFrom = e.record.data;
                });
            },
            onManifestDateTo(e) {
                this.$nextTick(() => {
                    this.model.manifestDateToValue = e.value;
                    this.model.manifestDateTo = e.record.data;
                });
            },
            onDispatchDateFrom(e) {
                this.$nextTick(() => {
                    this.model.dispatchFromValue = e.value;
                    this.model.dispatchDateFrom = e.record.data;
                });
            },
            onDispatchDateTo(e) {
                this.$nextTick(() => {
                    this.model.dispatchDateToValue = e.value;
                    this.model.dispatchDateTo = e.record.data;
                });
            },
            onInspectorCost() { this.$toast.add({ severity: 'warn', summary: 'UNDERDEVELOPMENT!', detail: 'Module is on development', life: 8000 }); },
            onReset() { this.$router.push({ name: 'dashboard-index' }); },
            onExportToExcel() {
                this.$nextTick(() => { this.activity.export = true; });
                this.$axios.post(this.$config.config.endpoint.api + `/services/report/v2/consignment/export`.replaceAll("//", "/"), this.apiData)
                    .then((response) => {
                        this.$config.common.$downloadFile(response.data.result.base64File, response.data.result.contentType, response.data.result.fileName);
                        this.$nextTick(() => { this.activity.export = false; });
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        this.$nextTick(() => { this.activity.export = false; });
                    });
            },
            getReportScopes() {
                this.reportScopes = null;
                this.$store.dispatch("provideraccount/getURL", { url: `/services/settings/user/reportscope` })
                    .then((response) => {
                        this.reportScopes = response;
                        this.sources.warehouses = response?.warehouses;
                        this.sources.customers = response?.customers;
                        this.sources.providers = response?.providers;
                        this.sources.accounts = response?.accounts;
                    });
            },
            onWarehouseChange(e) {
                this.model.warehouseID = e?.value;
                this.getSources();
            },
            onCustomerChange(e) {
                this.model.customerID = e?.value;
                this.getSources();
            },
            onProviderChange(e) {
                this.model.serviceProviderID = e?.value;
                this.getSources();
            },
            onAccountChange(e) {
                this.model.serviceProviderAccountID = e?.value;
                this.model.serviceProviderAccountCode = e?.record?.code;
            },
            getSources()
            {
                this.sources.accounts = (this.accounts ?? []).filter(x => this.model.serviceProviderID === 0 || (this.model.serviceProviderID > 0 && this.model.serviceProviderID === x.serviceProviderID)) ?? [];
            }
        },
        async created() {
            this.loading = false;
            let startDate = new Date();
            startDate.setDate(1);
            this.model.collectionDateFromValue = startDate;
            this.model.collectionDateToValue = new Date();
        },
        mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            this.loading = false;
            this.model.customerID = this.isCustomer;
            this.model.reportType = 200;
            let startDate = new Date();
            startDate.setDate(1);
            this.model.collectionDateFromValue = startDate;
            this.model.collectionDateToValue = new Date();
            this.$nextTick(() => this.v$.$reset());
            this.getReportScopes();
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }

    .p-orderlist-controls,
    .p-picklist-buttons.p-picklist-source-controls,
    .p-picklist-buttons.p-picklist-source-controls, .p-picklist-buttons.p-picklist-target-controls {
        display: none !important;
    }

    .picklist-item {
        display: flex;
        align-items: center;
        padding: .2rem;
        width: 100%;
    }

    img#is-img {
        width: 75px;
        /*   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);*/
        margin-right: 1rem;
    }

    .picklist-list-detail {
        flex: 1 1 0;
    }

    .picklist-list-action {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .picklist-category-icon {
        vertical-align: middle;
        margin-right: .5rem;
        font-size: .875rem;
    }

    .picklist-category {
        vertical-align: middle;
        line-height: 1;
        font-size: .875rem;
    }

    .custom-skeleton {
        border: 1px solid var(--surface-d);
        border-radius: 4px;
    }

    .custom-skeleton ul {
        list-style: none;
    }

        .custom-skeleton ul > li {
            padding: 0.75rem;
        }
/*    .p-disabled, .p-component:disabled, .p-disabled > * {
        opacity: 0.70;
        color: black !important;
    }*/
</style>
