<template>
    <div class="layout-wrapper">
        <div class="layout-content" style="margin: 0; background-color: var(--surface-b); height: 100vh; ">
            <div class="content-section implementation" style="text-align: center;">
                <div class="card" style="height: 100%; text-align:center; overflow: hidden;">
                    <img src="../../../assets/_img/_common/500.png" style="max-height: 0rem; height: 100%; object-fit: contain;" />
                    <h3>Sorry, the application encountered an unhandled error.</h3>
                    <p>The page have encountered an unhandled error possibly due to lack of data provided or other internal server error. For support trace reference, please provide: <a href='mailto:support@integro360.com?subject="Unhandled Error: Refer to ${traceId}"'>{{ traceId }} </a></p>
                    <router-link :to="{ path: '/'}" class="p-button">Go back to Dashboard</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'InternalServerError',
        data() {
            return {
                traceId: 1234589798546544346
            }
        }
    }
</script>