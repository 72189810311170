<template>
    <cosmos-grid :id="`CarrierConversionFactorMatrixList_${providerID}`" :multi-level="true" :base-record-ID="0" sourceType="array" :source="source" :searchFilters="searchFields" :autoGenerate="false" :showOperation="false" :showExport="false" :showAdd="false" :forceRefresh="activity.refresh">
        <pv-grid-column header="" headerStyle="min-width: 12rem; max-width: 12rem;" style="min-width: 12rem; max-width: 12rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding: 0.25rem 0.5rem !important; margin: 0 !important; text-align: center;">
            <template #body="slotProps">
                <img :src="`/_assets/_carrier/${slotProps.data.logo}.png`" style="max-height: 3rem; max-width: 10rem; object-fit: cover; margin: 0 0.5rem -0.25rem 0.25rem;" />
            </template>
        </pv-grid-column>
        <pv-grid-column field="name" header="Carrier Name" style="width: 100%; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding: 0.25rem 0.5rem !important; margin: 0 !important;">
            <template #body="slotProps">
                {{ slotProps.data.name }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="captureType" header="Weight Capture Source" style="min-width: 20rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <span v-html="`${slotProps.data.captureType}`"></span>
            </template>
        </pv-grid-column>
        <pv-grid-column field="formula" header="Volumetric Weight Formula" headerStyle="min-width: 20rem; max-width: 20rem;" style="min-width: 20rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <span v-html="`${slotProps.data.formula}`"></span>
            </template>
        </pv-grid-column>
        <pv-grid-column field="mask" headerStyle="min-width: 3.5rem; max-width: 3.5rem; width: 3.5rem" style="width: 3.5rem; border-left: 0; padding: 0 !important;" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-col">
                    <pv-button icon="pi pi-pencil" iconPos="right" class="p-button-info" @click="onEdit(slotProps.data)" />
                </div>
            </template>
        </pv-grid-column>
        <!-- Expansion Template -->
        <template #expansion="slotProps">
            <product-grid :typeID="200"
                          :providerID="slotProps.data.serviceProviderID"
                          :carrierID="slotProps.data.serviceProviderCarrierID" />
        </template>
    </cosmos-grid>
    <keep-alive>
        <is-dialog :header="model?.name" style="width: 25vw; min-width: 400px;" :visible="dialog.edit" @is-confirm="onConfirmEdit(model)" @is-cancel="onCancel()" :confirmDisabled="confirmDisabled">
            <div class="confirmation-content p-d-flex">
                <div class="p-col p-md-12">
                    <form-lookup id="serviceProviderID" label="Provider" :source="providers" sourceType="array" :value="model?.serviceProviderID" :disabled="true" :showCopy="false" :required="true" />
                    <form-lookup v-if="model?.serviceProviderCarrierID" id="serviceProviderCarrierID" label="Carrier" :source="carriers" sourceType="array" :value="model?.serviceProviderCarrierID" :disabled="true" :showCopy="false" :required="true" />
                    <form-lookup v-if="model?.serviceProviderProductID" id="serviceProviderProductID" label="Product" :source="products" sourceType="array" :value="model?.serviceProviderProductID" :disabled="true" :showCopy="false" :required="true" />
                    <form-lookup id="weightCaptureType" label="Weight Base" source="weightcapturetype" type="enum" :value="model?.weightCaptureType" ph="Inherit From Carrier/Provider" @is-sync="model.weightCaptureType = $event.value" :floatLabel="false" />
                    <div style="font-size: 1.25rem; text-transform: uppercase; font-weight:800; margin-bottom: 1rem;">Volumetric Weight Formula</div>
                    <form-lookup id="volumeUnit" label="Volume Unit" source="volumeunit" type="enum" :value="model?.volumeUnit" @is-sync="model.volumeUnit = $event.value" :required="true" />
                    <form-lookup id="operator" label="Operator" source="factoroperator" type="enum" :value="model?.operator" @is-sync="model.operator = $event.value" :required="true" />
                    <form-numeric type="decimal" decimal-digit="6" id="conversionFactor" label="Conversion Factor" :value="model?.conversionFactor" @is-sync="model.conversionFactor = $event.value" :required="true" />
                    <form-lookup id="weightUnit" label="Weight Unit" source="weightunit" type="enum" :value="model?.weightUnit" @is-sync="model.weightUnit = $event.value" :required="true" />
                </div>
            </div>
        </is-dialog>
    </keep-alive>
</template>
<script>
    import productgrid from '@/components/admin/conversionmatrix/product-grid.vue';
    import mixins from '@/assets/lib/cosmos/_js/mixin.js';
    export default {
        name: 'AddressBookListTemplate',
        mixins: [mixins.GRID],
        components: {
            'product-grid': productgrid
        },
        props: {
            providerID: { type: Number, default: -1000 },
            carrierID: { type: Number, default: -1000 },
            typeID: { type: Number, default: 200 },
        },
        data() {
            return {
                model: null,
                source: [],
                activity: {
                    refresh: false
                },
                dialog: {
                    edit: false,
                    delete: false
                },
                searchFields: ["code", "name", "text"],
            }
        },
        computed: {
            providers() { return this.$vm.$providers; },
            carriers() { return this.$vm.$carriers; },
            products() { return this.$vm.$products; },
        },
        methods: {
            onRebind() {
                this.$store.dispatch("conversionfactormatrix/getURL", { url: `/services/admin/conversionfactormatrix?pid=${this.providerID}&t=${this.typeID}` }).then((response) => {
                    this.source = response.result;
                    this.source.forEach((x) => {
                        let carrier = this.carrier(x.serviceProviderCarrierID);
                        x.code = `${carrier?.code}`;
                        x.name = `${carrier?.name}`;
                        x.text = `[${carrier?.code}] ${carrier?.name}`;
                        x.logo = carrier?.mask;
                    });
                    this.activity.loading = false;
                });
            },
            onCancel() {
                this.model = null;
                this.dialog.edit = false;
                this.dialog.delete = false;
                this.$nextTick(() => this.activity.refresh = true);
                this.$nextTick(() => this.activity.refresh = false);
            },
            onEdit(e) {
                this.model = this.$filters.init(e);
                this.dialog.edit = true;
            },
            onConfirmEdit(e) {
                var model = this.$filters.init(e);
                if (model.id >= 0) {
                    this.$store.dispatch("conversionfactormatrix/updateRecord", model).then((response) => {
                        if (response.success) {
                            this.$toast.add({ severity: 'success', summary: 'Conversion Factor Matrix (Carrier)', detail: "The record was updated successfully!", life: 3000 });
                            this.dialog.edit = false;
                            this.onCancel();
                            let index = this.source.findIndex(x => x.id === model.id);
                            if (index !== -1) {
                                let carrier = this.carrier(response.result.serviceProviderCarrierID);
                                response.result.code = `${carrier?.code}`;
                                response.result.name = `${carrier?.name}`;
                                response.result.text = `[${carrier?.code}] ${carrier?.name}`;
                                response.result.logo = carrier?.mask;
                                this.source[index] = response.result;
                            }
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: response.errors[0]?.message, life: 3000 });
                        }

                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
                }
            },
            provider(id) {
                if (id)
                    return this.$vm.$providers.filter(x => x.id == id)[0];
                return null;
            },
            carrier(id) {
                if (id)
                    return this.$vm.$carriers.filter(x => x.id == id)[0];
                return null;
            },
            product(id) {
                if (id)
                    return this.$vm.$products.filter(x => x.id == id)[0];
                return null;
            }
        },
        mounted() {
            this.onRebind();
        }
    }
</script>
