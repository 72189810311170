<template>
    <pv-accordion id="RateConditions" :multiple="true" :activeIndex="[conditions.filter(x => x.parentID === null || x.parentID === undefined).length - 1]">
        <pv-accordion-tab v-for="(condition, index) in conditions.filter(x => x.parentID === null || x.parentID === undefined)" :key="condition.tempParentID">
            <template #header>
                <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                    <div class="p-col-fixed p-as-start" style="width: 1.5rem"><i class="pi pi-bars" :style="`color: var(--text-${condition.logicType === 100 && condition.sortOrder > 1 ? 'danger' : condition.logicType === 200 ? 'info' : '' });`"></i></div>
                    <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" :title="`${((condition?.summary ?? condition?.text)?.replaceAll('<b>', '')?.replaceAll('</b>', '') + ' ' + conditions.filter(x => (x.parentID !== null && x.parentID !== undefined) && (x.parentID === condition.id || x.tempParentID === condition.tempParentID)).map(x => x.summary).join(' ')?.replaceAll('<b>', '')?.replaceAll('</b>', '')).trim()}.`">
                        <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;">&nbsp; <span v-html="`${((condition?.summary ?? condition?.text)?.replaceAll('<b>', '')?.replaceAll('</b>', '') + ' ' + conditions.filter(x => (x.parentID !== null && x.parentID !== undefined) && (x.parentID === condition.id || x.tempParentID === condition.tempParentID)).map(x => x.summary).join(' ')?.replaceAll('<b>', '')?.replaceAll('</b>', '')).trim()}.`"></span></div>
                    </div>
                    <div class="p-col-fixed p-as-end" style="width: 1.5rem; top: 0.1rem; right: 4rem; position: absolute;"><pv-button icon="pi pi-trash" class="p-button p-button-danger" @click="onDelete(condition.tempParentID)"></pv-button></div>
                </div>
            </template>
            <ruleconditiondetail-form :countryCode="countryCode"
                                      :currencyCode="currencyCode"
                                      :source="condition"
                                      :categories="sources.categories"
                                      :condition="sources.conditions"
                                      :operator="operators"
                                      :tradebloc="sources.tradeBlocs"
                                      :postalbloc="sources.postalBlocs"
                                      :postalregex="sources.postalRegexs"
                                      :region="sources.regions"
                                      :subregion="sources.subRegions"
                                      :subSource="conditions.filter(x => (x.parentID !== null && x.parentID !== undefined) && (x.parentID === condition.id || x.tempParentID === condition.tempParentID))"
                                      :index="index"
                                      @is-sync="onSync(condition.tempParentID, $event)"
                                      @is-sync-subcondition="onSyncChild(condition.tempParentID, $event.tempChildID, $event)"
                                      @is-add-subcondition="onSyncChild(condition.tempParentID, $event.tempChildID, $event)"
                                      @is-delete-subcondition="onDeleteSubCodition($event)" />
        </pv-accordion-tab>
    </pv-accordion>
    <div class="p-d-flex">
        <div class="p-col-12">
            <pv-button label="Add Condition" icon="pi pi-plus" @click="onAdd" style="width: 100%"></pv-button>
        </div>
    </div>
</template>
<script>
    import ruleconditiondetail from '@/components/facility/rates/surcharge-rates/ruleconditiondetail-form.vue';
    export default {
        name: 'MarkupRuleConditionsAccordion',
        components: { 'ruleconditiondetail-form': ruleconditiondetail },
        props: {
            parentID: { type: Number },
            countryCode: { type: String },
            currencyCode: { type: String }
        },
        data() {
            return {
                conditions: [],
                sources: {
                    categories: [],
                    conditions: [],
                    operators: [],
                    tradeBlocs: [],
                    postalBlocs: [],
                    postalRegexs: [],
                    regions: [],
                    subRegions: []
                }
            }
        },
        computed: { operators() { return this.$vm.$enums.rateoperatortype; } },
        methods: {
            onAdd() {
                this.conditions.push({
                    id: 0,
                    parentID: null,
                    rateCategoryID: 1,
                    rateCategoryText: 'Cost, Weight and Measurements',
                    rateConditionID: 1,
                    rateConditionText: 'If &lsqb;CONSIGNMENT_CHARGEABLEWEIGHT&rsqb; is',
                    operatorType: 100,
                    operatorText: 'equal to',
                    dataType: 100,
                    valueGeneral: null,
                    valueTyped: null,
                    valueText: null,
                    referenceType: 100,
                    referenceID: 100,
                    referenceText: 'kg',
                    logicType: 100,
                    logicTypeText: 'And',
                    summary: 'If &lsqb;CONSIGNMENT_CHARGEABLEWEIGHT&rsqb; is equal to 0.00kg',
                    providerSurchargeRuleID: this.providerRuleID,
                    tempParentID: `${Math.floor(Math.random() * 999999999999)}`,
                    tempChildID: null,
                    sortOrder: (this.conditions ?? []).length + 1
                });
            },
            onDelete(index) {
                let conditions = this.conditions.filter(x => x.tempParentID !== index);
                this.conditions = conditions;
                this.$nextTick(() => {
                    this.$emit("is-sync", this.conditions);
                });
            },
            onSync(tempParentID, e) {
                var index = this.conditions.findIndex(x => (x.parentID === null || x.parentID === undefined) && x.tempParentID == tempParentID);
                if (index >= 0) {
                    this.conditions[index] = e;
                    this.$nextTick(() => {
                        this.$emit("is-sync", this.conditions);
                    });
                }
            },
            onSyncChild(tempParentID, tempChildID, e) {
                var index = this.conditions.findIndex(x => x.parentID !== null && x.tempParentID === tempParentID && x.tempChildID === tempChildID);
                if (index >= 0)
                    this.conditions[index] = e;
                else
                    this.conditions.push(e);
                this.$nextTick(() => {
                    this.$emit("is-sync", this.conditions);
                });
            },
            onDeleteSubCodition(e) {
                var index = this.conditions.findIndex(x => x.id === e.id && x.tempParentID === e.tempParentID && x.tempChildID === e.tempChildID);
                if (index >= 0)
                    this.conditions.splice(index, 1);
                this.$nextTick(() => {
                    this.$emit("is-sync", this.conditions);
                });
            },
            onSourceBind() {
                this.$store.dispatch("ratecategorytype/getAll", { sort: null, where: null })
                    .then((response) => { this.sources.categories = response?.result ?? []; });
                this.$store.dispatch("rateconditiontype/getAll", { sort: null, where: null })
                    .then((response) => { this.sources.conditions = response?.result ?? []; });
                this.$store.dispatch("rateconditiontype/getURL", { url: '/services/facility/servicegrouprulecondition/tradeblocandcountry' })
                    .then((response) => { this.sources.tradeBlocs = response?.result ?? []; });
                this.$store.dispatch("rateconditiontype/getURL", { url: '/services/facility/rulepostalbloc' })
                    .then((response) => { this.sources.postalBlocs = response?.result ?? []; });
                this.$store.dispatch("rateconditiontype/getURL", { url: '/services/facility/rulepostalbloc/postalregex' })
                    .then((response) => {
                        var responseData = response?.result ?? [];
                        var data = [];
                        responseData.map(x => x.description).filter((value, index, array) => array.indexOf(value) === index).forEach((country) => {
                            let items = [];
                            responseData.filter(x => x.description === country).filter((value, index, array) => array.indexOf(value) === index).forEach((item) => { items.push(item); });
                            data.push({ label: country.replace("></span>", " style='zoom: 70%; margin-right: 0.75rem;'></span>"), items: items });
                        })
                        this.sources.postalRegexs = data;
                    });
                this.$store.dispatch("rateconditiontype/getURL", { url: '/services/referential/region' })
                    .then((response) => { this.sources.regions = response?.result ?? []; });
                this.$store.dispatch("rateconditiontype/getURL", { url: '/services/referential/subregion' })
                    .then((response) => { this.sources.subRegions = response?.result ?? []; });
            },
            onRuleBind() {
                this.conditions = [];
                if (this.parentID)
                    this.$store.dispatch("rateconditiontype/getURL", { url: `/services/facility/providersurchargerulecondition/byrule/id/${this.parentID}` }).then((response) => {
                        this.conditions = response.result ?? [];
                    });
            }
        },
        created() {
            this.onSourceBind();
            this.$nextTick(() => {
                this.onRuleBind();
            })
        },
        mounted() {
            this.$nextTick(() => {
                this.onRuleBind();
            })
            var buttons = document.getElementsByClassName('p-inputnumber-button');
            for (var i = 0; i < buttons.length; i++)
                buttons[i].tabIndex = -1;
        }
    }
</script>
<style>
    .p-accordion .p-accordion-content {
        background: var(--surface-c) !important;
        padding-top: 2rem;
        margin: 0;
    }

    .p-accordion-tab.p-accordion-tab-active {
        margin-bottom: 0 !important;
    }
    .p-multiselect-token { border-radius: 4px !important; display: block; width: 100%; }
    .p-multiselect.p-multiselect-chip .p-multiselect-token {
        overflow-wrap: normal;
        padding: 0.25rem 0.5rem;
        background-color: var(--primary-color);
        color: var(--primary-color-text);
        border-radius: 4px;
        display: block;
        margin-right: .5rem;
        margin-bottom: 0.1rem;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .p-multiselect-token-icon {
        position: absolute;
        right: 4rem;
        border: 1px solid var(--surface-c);
    }
    .p-multiselect-label { display: inline-flex; flex-wrap: wrap; }
    .p-multiselect-label.p-placeholder { margin: 0.5rem !important; }
    .p-multiselect-label { width: 100%; }
    .p-multiselect-label .selected-item { display: block; width: inherit; }
    #RateConditions .p-accordion-header-link { padding: 0.75rem 1rem; border-bottom: 1px solid var(--gray-200); }
</style>
