<template>
    <div style="margin: -0.5rem -1.5rem -2.5rem -1.5rem;">
        <div style="min-height: 10rem; padding: 2rem 1rem 1rem 1rem;">
            <h3 style="margin-bottom: 0.25rem;">SUMMARY</h3>
            <p>Please check the report to ensure that all configurations are correct.</p>
            <div>

                <div class="p-grid">
                    <div class="p-col-12 p-md-6">
                        <h4>Basic Info</h4>
                        <form-input id="name" v-model="model.configuration.basicInfo.name" label="Report Name" :disabled="true" :show-copy="false" />
                        <form-input id="description" type="textarea" v-model="model.configuration.basicInfo.description" label="Report Description" :disabled="true" :show-copy="false" />
                        <form-lookup id="privacy" v-model="model.configuration.basicInfo.privacy" type="enum" source="dynamicreportprivacy" :nullable="false" :disabled="true" :show-copy="false" />
                        <form-lookup id="status" v-model="model.configuration.basicInfo.status" type="enum" source="dynamicreportstatus" :disabled="true" :show-copy="false" />
                    </div>
                    <div class="p-col-12 p-md-6">
                        <h4>Portal Filter</h4>
                        <viewer-form v-model="model.configuration.viewer" :parentID="model.id" :disabled="true" />
                    </div>
                </div>
                <h4>List of selected fields and display name:</h4>
                <customization-form v-model="model.fields" :parentID="model.id" :disabled="true" />
            </div>
        </div>
        <div class="p-grid p-nogutter p-justify-between" style="border-top: 2px solid var(--surface-d);padding: 0.5rem 1rem; background: var(--surface-c)">
            <pv-button @click="onPrevPage()" :loading="activity.loading" :iconPos="'right'"><i class="fa fa-chevron-left fa-fw" style="margin-right: 0.5rem"></i> <i :class="`${(activity.loading ? 'pi pi-spinner pi-spin' : 'fa fa-cogs fa-fw')}`" style="margin-right: 0.5rem"></i> Configuration</pv-button>
            <pv-button @click="onComplete()" class="p-button-success" :loading="activity.loading" :iconPos="'right'"><i :class="`${(activity.loading ? 'pi pi-spinner pi-spin' : 'fa fa-check-square fa-fw')}`" style="margin-right: 0.5rem"></i> Complete</pv-button>
        </div>
    </div>
</template>
<script>
    import customForm from '@/components/facility/report/custom-form.vue';
    import viewerForm from '@/components/facility/report/viewer-form.vue';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    export default {
        name: "ConfirmationStep",
        emits: ["update", "update:modelValue", "isSync"],
        mixins: [mixins.RECORD],
        components: {
            'customization-form': customForm,
            'viewer-form': viewerForm
        },
        props: {
            modelValue: { type: Array, default: () => [] }
        },
        data() {
            return {
                model: [],
                activity: {
                    loading: false
                }
            }
        },
        watch: {
        },
        methods: {
            onPrevPage() {
                this.$emit('prev-page', { formData: this.model, pageIndex: 3 });
            },
            onComplete() {
                this.$emit('complete', this.model);
            }
        },
        created() {
            if (this.modelValue)
                this.model = this.modelValue;
        }

    }
</script>