<template>
    <cosmos-grid id="ReturnItemGrid" sourceType="array" :source="source" :showHeader="false" :showOperation="false" :showCheckbox="false">
        <pv-grid-column field="lineNumber" header="#" headerStyle="min-width: 5rem; max-width: 5rem; width: 5rem" style="min-width: 5rem; max-width: 5rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.lineNumber }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="code" header="Code" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.code }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="name" header="Name" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.name }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="description" header="Description">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.description }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="weight" header="Weight" headerStyle="min-width: 5rem; max-width: 10rem; width: 10rem" style="min-width: 5rem; max-width: 10rem; text-align: right; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ $filters.formatNumber(slotProps.data?.weight, 3, null) }} kg
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="quantity" header="Quantity" headerStyle="min-width: 5rem; max-width: 5rem; width: 5rem" style="min-width: 5rem; max-width: 5rem; text-align: right; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; ">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.quantity }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="returnReason" header="Return Reason" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.returnReason }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="returnOption" header="Return Option" headerStyle="min-width: 5rem; max-width: 20rem; width: 5rem" style="min-width: 5rem; max-width: 20rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important;">
                        {{ slotProps.data?.returnOption }}
                    </div>
                </div>
            </template>
        </pv-grid-column>

    </cosmos-grid>
</template>
<script>
    export default {
        name: 'ReturnDetailTemplate',
        props: {
            parentID: { type: Number },
        },
        data() {
            return {
                source: []
            }
        },
        watch: {
            parentID() {
                this.getSource()
            }
        },
        methods: {
            getSource() {
                this.$store.dispatch("returns/getURL", { url: `/services/tms/return/${this.parentID}/items` })
                    .then((response) => {
                        this.source = response?.result ?? [];
                    });
            }
        },
        async created() {
            this.getSource()
        }
    }
</script>