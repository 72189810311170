<template>
    <div>Redirecting...</div>
</template>
<script>
    const moduleName = 'consignment';
    export default {
        name: moduleName + 'Record',
        created() {
            this.$store.dispatch("consignment/getURL", { url: '/services/tms/consignment/code/' + this.$route.params.code })
                .then(response => {
                    if (response?.result?.mask)
                        this.$router.push({ name: 'consignment-edit', params: { mask: response?.result?.mask } });
                    else
                        this.$router.push({ name: 'dashboard-index' });
                });
        }
    }
</script>
