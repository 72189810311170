<template>
        
    <div class="p-d-flex p-grid  p-col-12">
        <div :class="`p-col-12 p-md-12`">
            <form-lookup v-show="indexNo > 0" id="logicType" :required="true" :source="$vm.$enums['logictype']" :value="model.logicType" label="Logic" @is-sync="model.logicType = $event.value" :v$="v$"></form-lookup>
            <form-lookup id="categoryTypeID" label="Category" :required="true" source="services/facility/servicegrouprulecondition/category" sourceType="url" :value="model.categoryTypeID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="{model.categoryTypeID = $event.value ?? model.categoryTypeID; setConditionType($event);}" :sorted="false" />
            <form-lookup id="conditionTypeID" label="Condition" :required="true" :source="conditionTypeDS" :value="model.conditionTypeID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="{model.conditionTypeID = $event.value ?? model.conditionTypeID; setOperatorType($event);}" :sorted="false" />
            <form-lookup id="operatorType" label="Operator" :required="true" :source="operatorTypeDS" :value="model.operatorType" :v$="v$" :textField="'text'" :valueField="'id'" @is-sync="model.operatorType =  $event.value ?? model.operatorType" :sorted="false" />
            <form-input v-show="showTextObj" id="textValue" :required="true" label="Value" :v$="v$" :value="model.textValue" @is-sync="model.textValue = $event.value;" v-focus />
            <form-numeric v-show="showNumberObj" id="numericValue" :v$="v$" :value="model.numericValue" label="Value" @is-sync="model.numericValue = $event.value"></form-numeric>
            <form-numeric v-show="showDecimalObj" id="decimalValue" :v$="v$" :value="model.decimalValue" label="Value" decimalDigit="3" @is-sync="model.decimalValue = $event.value" type="decimal"></form-numeric>
            <form-lookup v-show="model.referenceType === 100" id="currencyID" :required="true" label="Currency" source="currency" :value="model.currencyID" @is-sync="model.currencyID = $event?.value ?? model.currencyID;" />
            <form-lookup v-show="model.referenceType === 200" id="weightUnit" :required="true" :source="$vm.$enums['weightunit']" :value="model.weightUnit" label="Unit Weight" @is-sync="model.weightUnit = $event?.value;" :v$="v$"></form-lookup>
            <form-lookup v-show="model.referenceType === 300" id="countryTradeBlocID" label="Country/TradeBloc" type="autocomplete" :required="true" :isTradeBloc="true" :source="tradeblocDS" :value="model.countryTradeBlocID" :v$="v$" :textField="'text'" :filterFields="['iso2', 'text', 'iso3', 'code']" :valueField="'id'" @is-sync="model.countryTradeBlocID = $event.value ?? model.countryTradeBlocID;" :sorted="false" />
            <form-lookup v-show="model.referenceType === 400" id="day" :required="true" :source="$vm.$enums['daytype']" :value="model.day" label="Unit Length" @is-sync="model.day = $event?.valu;"></form-lookup>
            <form-lookup v-show="model.referenceType === 500" id="dimensionUnit" :required="true" :source="$vm.$enums['lengthunit']" :value="model.dimensionUnit" label="Unit Length" @is-sync="model.dimensionUnit = $event?.valu;"></form-lookup>
            <form-lookup v-show="model.referenceType === 600" id="postalBlocID" label="PostalBloc" :required="true" :isTradeBloc="true" :source="postalBlocDS" :value="model.postalBlocID" :v$="v$" :textField="'name'" :filterFields="['text']" :valueField="'id'" @is-sync="model.postalBlocID = $event.value ?? model.countryTradeBlocID;" :sorted="false" />
        </div>
        <div :class="`p-col-12 p-md-12`" v-for="(item,index) in subConditionSource" :key="item.id">
            <subcondition-detail-form :key="item.id" :formData="item" :indexNo="index" @is-sync="isSubSync($event)" @is-delete="isSubDelete($event)"></subcondition-detail-form>
        </div>
        <div class="p-d-flex">
            <div class="p-grid p-nogutter p-justify-end">
                <pv-button icon="pi pi-fw pi-plus" label="Add Sub Condition" style="margin-right: 5px;" @click="addSubCondition"></pv-button>
            </div>
        </div>
    </div>
        
</template>
<script>
    const config = require('@/assets/lib/cosmos/_js/config.js').default.config;
    import subconditiondetailform from '@/components/ruleengine/v2/subcondition-detail-form.vue';
    export default {
        name: 'SubConditionTemplateForm',
        emits: ["is-sync","is-delete"],
        props: {
            indexNo: { type: Number, default: -1 },
            serviceRuleID: { type: Number, default: -1 },
            serviceGroupID: { type: Number, default: -1 },
            formData: { type: Object },
            formSubData: { type: Object }

        },
        components: {
            'subcondition-detail-form': subconditiondetailform
        },
        data() {
            return {
                model: {
                    id: 0,
                    code: ("SC" + Math.random().toString().replace(".", "")).slice(0, 20),
                    name: ("SC" + Math.random().toString().replace(".", "")).slice(0, 20),
                    temporaryID: Math.random(),
                    parentID: 0,
                    temporaryParentID: 0,
                    serviceGroupRuleID: 0,
                    logicType: 100,
                    categoryTypeID: 1,
                    conditionTypeID: 1,
                    dataType: 70,
                    operatorType: 100,
                    referenceID: null,
                    referenceType: 200,
                    textValue: null,
                    decimalValue: 0,
                    countryTradeBlocID: null,
                    currencyID: 3,
                    dimensionUnit: 100,
                    weightUnit: 100,
                    numericValue: 0,
                    warehouseID: null,
                    text: null
                },
                categoryTypes:[],
                conditionTypes: [],
                operatorTypes: [],
                tradeBlocCountries: [],
                postalBlocs:[],
                dataType: null,
                categoryALLTypes: [],
                conditionALLTypes: [],
                operatorALLTypes: [],
                products: [],
                filter: {
                    source: null,
                    target: null
                },
                activity: {
                    loading: true
                },
                dialog: {
                    header: "Delete Record?",
                    add: false,
                    edit: false,
                    delete: false
                },
                testData: [
                    { id: 1, name: 'A', desc: 'A-DESC' },
                    { id: 2, name: 'B', desc: 'B-DESC' }
                ],
                subConditions: []
            }
        },
        computed: {
            subConditionSource() {
                //return this.subConditions?.length > 0 ? (this.subConditions ?? this.$props?.formSubData ?? []) : this.$props?.formSubData ?? [];
                //return (this.subConditions ?? this.$props?.formSubData ?? []);
                return (this.subConditions ?? []);
            },
            companyDS() { return this.$store.getters["company/ds"]; },
            warehouseDS() { return this.companyDS.data.filter((x) => x.type === 8); },
            categoryTypeALLDS() {
                return this.categoryALLTypes;
            },
            conditionTypeALLDS() {
                return this.conditionALLTypes;
            },
            operatorTypeALLDS() {
                return this.operatorALLTypes;
            },
            categoryTypeDS() {
                return this.categoryTypes;
            },
            conditionTypeDS() {
                return this.conditionTypes;
            },
            operatorTypeDS() {
                return this.operatorTypes;
            },
            tradeblocDS() {
                return this.tradeBlocCountries;
            },
            postalBlocDS() {
                return this.postalBlocs;
            },
            conditionSource() {
                return this.testData;
            },
            showTextObj() {
                return this.dataType === undefined || (this.dataType !== 30 && this.dataType !== 70 && (this.model?.referenceType === null || this.model?.referenceType === undefined || this.model?.referenceType === 0));
            },
            showNumberObj() {
                return this.dataType === 30 && (this.model?.referenceType === undefined || this.model?.referenceType === null || this.model?.referenceType === 0 || this.model?.referenceType === 500) ;
            },
            showDecimalObj() {
                return this.dataType === 70;
            },
            conditionTextDisplay() {
                return (this.indexNo > 0 ? (this.$vm.$enums['logictype'].filter((x) => x.id == this.model.logicType)[0]?.text ?? "") + " " : "") + (this.conditionTypeDS.filter(x => x.id === this.model.conditionTypeID)[0]?.text ?? "") + " " +
                    (this.operatorTypeDS.filter(x => x.id === this.model.operatorType)[0]?.text ?? "").toLowerCase() + " " +
                    (this.model.referenceType === 100 && this.model.currencyID > 0 ? " " + (this.$store.getters["currency/ds"].data.filter((x) => x.id == this.model.currencyID)[0]?.symbol ?? ""): "") +
                    (this.showTextObj ? this.model?.textValue ?? "" : "") +
                    (this.showNumberObj ? this.model?.numericValue ?? 0 : "") +
                    (this.showDecimalObj ? this.formatDecimal(this.model?.decimalValue ?? 0,3) : "") +
                    (this.model.referenceType === 200 && this.model.weightUnit > 0 ? " " + (this.$vm.$enums['weightunit'].filter((x) => x.id == this.model.weightUnit)[0]?.text ?? "") : "") +
                    (this.model.referenceType === 300 && this.model.countryTradeBlocID > 0 ? " [" + (this.tradeblocDS.filter((x) => x.id == this.model.countryTradeBlocID)[0]?.code ?? "") + "] " + (this.tradeblocDS.filter((x) => x.id == this.model.countryTradeBlocID)[0]?.altName ?? this.tradeblocDS.filter((x) => x.id == this.model.countryTradeBlocID)[0]?.name ?? "") : "") +
                    (this.model.referenceType === 400 && this.model.day > 0 ? " " + (this.$vm.$enums['daytype'].filter((x) => x.id == this.model.day)[0]?.text ?? "") : "") +
                    (this.model.referenceType === 500 && this.model.dimensionUnit > 0 ? " " + (this.$vm.$enums['lengthunit'].filter((x) => x.id == this.model.dimensionUnit)[0]?.text ?? "") : "") +
                    (this.model.referenceType === 600 && this.model.postalBlocID > 0 ? " [" + (this.postalBlocDS.filter((x) => x.id == this.model.postalBlocID)[0]?.code ?? "") + "] " + (this.postalBlocDS.filter((x) => x.id == this.model.postalBlocID)[0]?.name ?? "") : "");
            }
        },
        watch: {
            'model': {
                handler() {
                    //this.model.text = this.indexNo > 0 ? this.conditionTextDisplay.toLowerCase() : this.conditionTextDisplay; this.$emit("is-sync", { model: this.model });
                    this.model.text = this.onConditionDisplay(); this.$emit("is-sync", { model: this.model });
                }, deep: true
            },
            'formSubData': {
                handler() {
                    this.$nextTick(() => {
                        if (this.subConditions.length === 0)
                            this.subConditions = this.$props.formSubData ?? [];
                    });
                }, deep: true
            },
            
        },
        methods: {
            onDelete() {
                this.dialog.delete = false;
                this.$emit("is-delete", { model: this.model });
            },
            addCondition() {
                this.testData.push({ id: Number(Math.random().toString().replace(".", "")), name: 'C', desc: 'C-DESC' });
            },
            async setConditionType(categoryType) {
                if (categoryType.value !== null && categoryType.value !== undefined) {
                    this.model.categoryTypeID = categoryType.value;
                    await this.$axios
                        .get(config.endpoint.api + `/services/facility/servicegrouprulecondition/conditionbycategoryid/${categoryType.value}`.replaceAll("//", "/"))
                        .then((response) => {
                            this.conditionTypes = response.data.result;
                        })
                        .catch((ex) => { this.errors = ex.data; });
                }
            },
            async setOperatorType(conditionType) {
                if (conditionType.value !== null && conditionType.value !== undefined) {
                    this.dataType = conditionType.record.dataType;
                    this.model.dataType = conditionType.record.dataType;
                    this.model.referenceType = conditionType.record.referenceType;
                    await this.$axios
                        .get(config.endpoint.api + `/services/facility/servicegrouprulecondition/operatorbyconditiontypeid/${conditionType.value}`.replaceAll("//", "/"))
                        .then((response) => {
                            this.operatorTypes = response.data.result;
                        })
                        .catch((ex) => { this.errors = ex.data; });
                }
            },
            async getCategoryTypeList() {
                await this.$axios
                    .get(config.endpoint.api + `/services/facility/servicegrouprulecondition/category/`.replaceAll("//", "/"))
                    .then((response) => {
                        this.categoryTypes = response.data.result;
                        this.categoryALLTypes = response.data.result;

                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            async getConditionTypeList() {
                await this.$axios
                    .get(config.endpoint.api + `/services/facility/servicegrouprulecondition/conditiontype/`.replaceAll("//", "/"))
                    .then((response) => {
                        this.conditionTypes = response.data.result;
                        this.conditionALLTypes = response.data.result;
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            async getOperatorTypeList() {
                await this.$axios
                    .get(config.endpoint.api + `/services/facility/servicegrouprulecondition/operatortype/`.replaceAll("//", "/"))
                    .then((response) => {
                        this.operatorTypes = response.data.result;
                        this.operatorALLTypes = response.data.result;
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            async getTradeBlocCountriesList() {
                await this.$axios
                    .get(config.endpoint.api + `/services/facility/servicegrouprulecondition/TradeBlocAndCountry/`.replaceAll("//", "/"))
                    .then((response) => {
                        this.tradeBlocCountries = response.data.result;
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            async getPostalBlocList() {
                await this.$axios
                    .get(config.endpoint.api + `/services/facility/rulepostalbloc/customer/id/-100`.replaceAll("//", "/"))
                    .then((response) => {
                        this.postalBlocs = response.data.result;
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            formatDecimal(value, decimalPart) {
                return Number(value ?? 0).toFixed(decimalPart ?? 2);
            },
            async getCurrency() { return this.$store.dispatch("currency/getAll", {sort: null, where:null}); },
            isSubSync(e) {
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        var location = this.$filters.init(e.model);
                        var index = -1;
                        if (location.id > 0)
                            index = this.subConditions.findIndex(x => x.id === location.id);
                        else
                            index = this.subConditions.findIndex(x => x.temporaryID === location.temporaryID);
                        if (index !== -1) {
                            this.subConditions[index] = this.$filters.init(e.model);
                            var modelIndex = -1;
                            if (location.id > 0)
                                modelIndex = this.subConditions.findIndex(x => x.id === location.id);
                            else
                                modelIndex = this.subConditions.findIndex(x => x.temporaryID === location.temporaryID);
                            if (modelIndex === -1)
                                this.subConditions.push(this.$filters.init(e.model))
                            else
                                this.subConditions[index] = this.$filters.init(e.model);

                        }
                        this.$emit("is-sync", { model: this.$filters.init(e.model) });
                    });
                    
                    
                });
            },
            isSubDelete(e) {

                this.$emit("is-delete", { model: this.$filters.init(e.model) });
                this.$nextTick(() => {
                    var location = this.$filters.init(e.model);
                    var index = -1;
                    if (location.id > 0)
                        index = this.subConditions.findIndex(x => x.id === location.id);
                    else
                        index = this.subConditions.findIndex(x => x.temporaryID === location.temporaryID);
                    if (index !== -1) {
                        this.subConditions.splice(index, 1);
                    }
                });
            },
            addSubCondition() {
                this.subConditions.push({
                    id: 0,
                    code: ("SC" + Math.random().toString().replace(".", "")).slice(0, 20),
                    name: ("SC" + Math.random().toString().replace(".", "")).slice(0, 20),
                    temporaryID: Number(Math.random().toString().replace(".", "")),
                    parentID: this.model.id > 0 ? this.model.id : 0,
                    temporaryParentID: this.model.id > 0 ? this.model.id : this.model.temporaryID,
                    serviceGroupRuleID: this.model.serviceGroupRuleID,
                    logicType: 100,
                    categoryTypeID: 1,
                    conditionTypeID: 1,
                    dataType: 70,
                    operatorType: 100,
                    referenceID: null,
                    referenceType: 200,
                    textValue: null,
                    decimalValue: 0,
                    currencyID: 3,
                    countryTradeBlocID: null,
                    dimensionUnit: 100,
                    weightUnit: 100,
                    numericValue: 0,
                    warehouseID: null,
                    text: null
                });
            },
            onConditionDisplay() {
               
                return (this.indexNo > 0 ? (this.$vm.$enums['logictype'].filter((x) => x.id == this.model.logicType)[0]?.text ?? "") + " " : "").toLowerCase() + (this.conditionTypeALLDS.filter(x => x.id === this.model.conditionTypeID)[0]?.text ?? "") + " " +
                    (this.operatorTypeALLDS.filter(x => x.id === this.model.operatorType)[0]?.text ?? "").toLowerCase() + " " +
                    (this.model.referenceType === 100 && this.model.currencyID > 0 ? " " + (this.$store.getters["currency/ds"].data.filter((x) => x.id == this.model.currencyID)[0]?.symbol ?? "") : "") +
                    (this.showTextObj ? this.model?.textValue ?? "" : "") +
                    (this.showNumberObj ? this.model?.numericValue ?? 0 : "") +
                    (this.showDecimalObj ? this.formatDecimal(this.model?.decimalValue ?? 0, 3) : "") +
                    (this.model.referenceType === 200 && this.model.weightUnit > 0 ? " " + (this.$vm.$enums['weightunit'].filter((x) => x.id == this.model.weightUnit)[0]?.text ?? "") : "") +
                    (this.model.referenceType === 300 && this.model.countryTradeBlocID > 0 ? " [" + (this.tradeblocDS.filter((x) => x.id == this.model.countryTradeBlocID)[0]?.code ?? "") + "] " + (this.tradeblocDS.filter((x) => x.id == this.model.countryTradeBlocID)[0]?.altName ?? this.tradeblocDS.filter((x) => x.id == this.model.countryTradeBlocID)[0]?.name ?? "") : "") +
                    (this.model.referenceType === 400 && this.model.day > 0 ? " " + (this.$vm.$enums['daytype'].filter((x) => x.id == this.model.day)[0]?.text ?? "") : "") +
                    (this.model.referenceType === 500 && this.model.dimensionUnit > 0 ? " " + (this.$vm.$enums['lengthunit'].filter((x) => x.id == this.model.dimensionUnit)[0]?.text ?? "") : "") +
                    (this.model.referenceType === 600 && this.model.postalBlocID > 0 ? " [" + (this.postalBlocDS.filter((x) => x.id == this.model.postalBlocID)[0]?.code ?? "") + "] " + (this.postalBlocDS.filter((x) => x.id == this.model.postalBlocID)[0]?.name ?? "") : "");

            }

        },
        async mounted() {
            //if (this.accountID === -1)
            //if (this.accountID === -1)
            //    this.rebind();
            //else
            //    this.model = this.$props.formData.products;
            await this.getCurrency();
            await this.getCategoryTypeList();
            await this.getConditionTypeList();
            await this.getOperatorTypeList();
            await this.getTradeBlocCountriesList();
            await this.getPostalBlocList();
            this.$nextTick(() => {
                this.model = this.$filters.init(this.$props.formData);
            });
            
        }
    }
</script>
<style>
    .p-picklist-header { padding: 0 10px !important; }
    .custom-skeleton { border: 1px solid var(--surface-d); border-radius: 4px; }
    .custom-skeleton ul { list-style: none; }
    .custom-skeleton ul > li { padding: 0.75rem; }
</style>