<template>
    <keep-alive>
        <is-dialog :header="dialogHeader" :visible="dialog.delete && (dialog.edit || dialog.add)" @is-confirm="onManageRule(model)" @is-cancel="this.dialog.delete = false;this.dialog.edit = false;this.dialog.add = false" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '75vw'}">
            <div class="confirmation-content p-fluid p-grid">
                <div class="p-col-12">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-6">
                            <h6>RULE</h6>
                            <form-lookup id="clauseID" :required="true" :source="$vm.$enums['ruleclausetype']" :value="model.clauseID" label="Clause" @is-sync="model.clauseID = $event.value" :v$="v$"></form-lookup>
                            <form-lookup id="accountCustomerID" label="Carrier" :required="false" :source="accountCarrierDS" :value="model.accountCustomerID" :v$="v$" :textField="'text'" :valueField="'id'" @is-sync="model.accountCustomerID = $event.value" :sorted="false" />
                            <form-lookup id="accountCustomerProductID" label="Service" :required="false" :source="accountCarrierProductDS" :value="model.accountCustomerProductID" :v$="v$" :textField="'text'" :valueField="'id'" @is-sync="model.accountCustomerProductID = $event.value" :sorted="false" />
                            <form-lookup id="accountCustomerOptionID" v-if="(accountCarrierOptionDS ?? []).length > 0" label="Option" :required="false" :source="accountCarrierOptionDS" :value="model.accountCustomerOptionID" :v$="v$" :textField="'text'" :valueField="'id'" @is-sync="model.accountCustomerOptionID = $event.value" :sorted="false" />
                            <form-lookup id="warehouseID" label="Warehouse" :required="false" :source="warehouseScopes ?? []" :value="model.warehouseID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="model.warehouseID = $event.value" />
                            <form-lookup id="incoTerms" :required="false" type="enum" source="incotermtype" :value="model.incoTerms" label="Incoterm" @is-sync="model.incoTerms = $event.value" :v$="v$"></form-lookup>
                            <!--<form-lookup id="shipmentType" :required="false" :source="$vm.$enums['parcelcategorytype']" :value="model.shipmentType" label="Shipment Type" @is-sync="model.shipmentType = $event.value" :v$="v$"></form-lookup>-->
                            <form-lookup id="customsCategoryType" :required="false" type="enum" source="customscategorytype" :value="model.customsCategoryType" label="Customs Category Type" @is-sync="model.customsCategoryType = $event.value" :v$="v$"></form-lookup>

                        </div>
                        <div class="p-col-12 p-md-6">
                            <h6>CONDITIONS</h6>
                            <condition-form :formData="ruleModel" @is-sync="isSync($event)"></condition-form>
                        </div>
                    </div>
                </div>
            </div>
        </is-dialog>
    </keep-alive>
    <is-dialog header="Delete Record?" :visible="dialog.delete && !dialog.edit && !dialog.add" @is-confirm="onManageRule(model)" @is-cancel="this.dialog.delete = false; this.dialog.add = false; this.dialog.edit = false;">
        <div class="confirmation-content p-d-flex">
            <div>
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            </div>
            <div>
                You are about to delete this record.

                <!--<table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                    <tr v-for="data in deleteDisplay" :key="data.id">
                        <th style="text-align:left;">{{ $filters.titleize(data) }}</th>
                        <td>:</td>
                        <td> {{ forDeleteRule[data] }}</td>
                    </tr>
                </table>-->
                <br />
                Please be aware of the following:
                <ul>
                    <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                    <li>For records that has activity, the record will only be disabled and archived.</li>
                    <li>Also deleting a record may be irreversible action.</li>
                </ul>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
    <cosmos-grid id="RuleFormList" sourceType="array" :source="source?.data ?? []" :sourcePaged="true" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showExport="false" :showDelete="false" :showEdit="false" :showHeader="true" :showOperation="false">
        <template #toolbar>
            <div class="p-d-flex p-jc-between p-ai-center">
                <span>
                    <pv-button v-if="showAdd" icon="pi pi-fw pi-plus" label="Add New Rule" style="margin-right: 5px;" @click="onRuleCreate"></pv-button>

                </span>
                <!--<span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                    <i class="pi pi-search" />
                    <pv-input v-model="activeFilters['global'].value" placeholder="Keyword Search" style="width: 100%;" @keyup="onForceFilter" />
                </span>-->
            </div>
        </template>
        <pv-grid-column field="code" header="RULE"  headerStyle="min-width: 50rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%">
                        <div class="p-grid p-d-flex">
                            <div class="p-col-12">
                                <div>
                                    <div class="p-grid">
                                        <div class="p-col-fixed" style="width: 130px; font-weight: bold;">Clause</div>
                                        <div class="p-col-fixed" style="width: 10px; font-weight: bold;">:</div>
                                        <div class="p-col"> <span class="p-code-text"> {{ $vm.$enums['ruleclausetype'].filter((x) => x.id == slotProps.data?.clauseID)[0]?.text }}</span></div>
                                    </div>
                                    <div class="p-grid">
                                        <div class="p-col-fixed" style="width: 130px; font-weight: bold; ">Carrier</div>
                                        <div class="p-col-fixed" style="width: 10px; font-weight: bold;">:</div>
                                        <div class="p-col"> <span class="p-code-text">{{ accountCarrierDS?.filter((x) => x.id == slotProps.data.accountCustomerID)[0]?.text ?? "All" }}</span></div>
                                    </div>
                                    <div class="p-grid">
                                        <div class="p-col-fixed" style="width: 130px; font-weight: bold; ">Service</div>
                                        <div class="p-col-fixed" style="width: 10px; font-weight: bold;">:</div>
                                        <div class="p-col"> <span class="p-code-text">{{ accountCarrierProductAllDS?.filter((x) => x.id == slotProps.data.accountCustomerProductID)[0]?.text ?? "All" }}</span></div>
                                    </div>
                                    <div class="p-grid" v-if="slotProps.data.accountCustomerOptionID">
                                        <div class="p-col-fixed" style="width: 130px; font-weight: bold; ">Option</div>
                                        <div class="p-col-fixed" style="width: 10px; font-weight: bold;">:</div>
                                        <div class="p-col"> <span class="p-code-text">{{ accountCarrierOptionAllDS?.filter((x) => x.id == slotProps.data.accountCustomerOptionID)[0]?.text ?? "All" }}</span></div>
                                    </div>
                                    <div class="p-grid">
                                        <div class="p-col-fixed" style="width: 130px; font-weight: bold; ">Warehouse</div>
                                        <div class="p-col-fixed" style="width: 10px; font-weight: bold;">:</div>
                                        <div class="p-col"> <span class="p-code-text">{{ warehouseScopes?.filter((x) => x.id == slotProps.data.warehouseID)[0]?.text ?? "All" }}</span></div>
                                    </div>
                                    <div class="p-grid">
                                        <div class="p-col-fixed" style="width: 130px; font-weight: bold; ">Incoterms</div>
                                        <div class="p-col-fixed" style="width: 10px; font-weight: bold;">:</div>
                                        <div class="p-col"> <span class="p-code-text">{{ $vm.$enums['incotermtype'].filter((x) => x.id == slotProps.data.incoTerms)[0]?.text }}</span></div>
                                    </div>
                                    <div class="p-grid">
                                        <div class="p-col-fixed" style="width: 160px; font-weight: bold;">Customs Category Type</div>
                                        <div class="p-col-fixed" style="width: 10px; font-weight: bold;">:</div>
                                        <div class="p-col"> <span class="p-code-text">{{ $vm.$enums['customscategorytype'].filter((x) => x.id == slotProps.data.customsCategoryType)[0]?.text }}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="code" header="CONDITION">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%">
                        <div class="p-d-flex p-jc-between" style="min-width: 10rem; margin-bottom: 5px;">
                            {{conditionTextDisplay(slotProps.data?.conditions ?? [])}}
                        </div>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="mask" headerStyle="min-width: 7rem; max-width: 7rem; width: 7rem" style="`min-width: 7rem; max-width: 7rem; border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-d-flex" style="width: 2rem;">
                    <div class="p-col">
                        <pv-button icon="pi pi-pencil" iconPos="right" @click="onRuleEdit(slotProps.data)" class="p-button-info" />
                    </div>
                    <div class="p-col">
                        <pv-button icon="pi pi-trash" iconPos="right" @click="onRuleDelete(slotProps.data)" class="p-button-danger" />
                    </div>
                    <slot name="operation" :data="slotProps.data">
                    </slot>
                </div>
            </template>
        </pv-grid-column>
    </cosmos-grid>


</template>
<script>
    const config = require('@/assets/lib/cosmos/_js/config.js').default.config;
    import conditionform from '@/components/ruleengine/v2/rulecondition-form.vue';
    export default {
        name: 'RuleTemplateForm',
        emits: ["is-sync"],
        components: {
            'condition-form': conditionform
        },
        props: {
            serviceGroupID: { type: Number, default: -1 },
            formData: { type: Object },
            formProducts: { type: Object },
            formProductOptions: { type: Object },
            warehouseScopes: { type: Object }
        },
        data() {
            return {
                id: 'ServiceRule',
                filename: 'export',
                filter: {},
                searchFilter: ['code', 'name'],
                paged: false,
                typed: false,
                typeValue: -1,
                lazyLoading: false,
                pagination: true,
                pageSize: 10,
                multiSelect: true,
                csvSeparator: ',',
                autoGenerate: true,
                columns: [],
                operationWidth: 8,
                showAdd: true,
                showExport: true,
                showEdit: true,
                showDelete: true,
                showOperation: true,
                deleteDisplayFields: [],
                groupBy: null,
                recordSource: [],
                items: null,
                forDelete: null,
                exportFilename: null,
                selectedItems: [],
                activity: { loading: true },
                source: {
                    total: 0,
                    data: []
                },
                page: {
                    first: 0,
                    current: 0,
                    size: 10
                },
                router: {
                    name: null,
                    module: null,
                    title: null,
                    subtitle: null,
                    model: null,
                    schema: null
                },
                dialog: {
                    header: "Delete Record?",
                    add: false,
                    edit: false,
                    delete: false
                },
                forDeleteRule: null,
                forDeleteItem: null,
                deleteDisplay: ["code", "name",],
                itemDialog: {
                    header: "Delete Record?",
                    add: false,
                    edit: false,
                    delete: false
                },
                sourceItem: {
                    total: 0,
                    data: []
                },
                pageItem: {
                    first: 0,
                    current: 0,
                    size: 10
                },
                expandedRows: [],
                model: {
                    id: 0,
                    code: "SR" + Math.random().toString().replace(".", ""),
                    name: "SR" + Math.random().toString().replace(".", ""),
                    temporaryID: Math.random(),
                    serviceGroupID: 0,
                    clauseID: 100,
                    incoTerms: null,
                    shipmentType: null,
                    accountCustomerID: 0,
                    accountCustomerProductID: 0,
                    warehouseID: null,
                    environmentID: null,
                    mask: null,
                    conditions: [],
                },
                itemModel: {
                    id: 0,
                    packageID: 0,
                    temporaryID: Math.random(),
                    productID: null,
                    barcodeID: null,
                    htsCode: null,
                    gtin: null,
                    sku: null,
                    attribute: null,
                    quantity: 0,
                    weight: 0,
                    length: 0,
                    width: 0,
                    height: 0,
                    consignmentValue: 0,
                    insuranceValue: 0,
                    customsValue: 0,
                    taxRate: 0,
                    taxValue: 0,
                    manufacturerCountryID: null,
                    environmentID: null,
                    code: null,
                    name: null,
                    mask: null
                },
                modelState: null,
                products: [],
                productOptions: [],
                accountCarrier: [],
                accountCarrierProduct: [],
                accountCarrierOption: [],
                lookup: {
                    categoryTypes: [],
                    conditionTypes: [],
                    operatorTypes: [],
                    tradeBlocCountries: [],
                    postalBlocs:[]
                }
            }
        },
        computed: {
            record() {
                return this.$store.getters["servicegrouprule/record"]
            },
            ruleModel() {
                return this.$filters.init(this.model);
            },
            accountCarrierDS() {
                return this.accountCarrier;
            },
            accountCarrierProductDS() {
                return this.accountCarrierProduct.filter(x => x.accountCustomerID === this.model.accountCustomerID);
            },
            
            accountCarrierProductAllDS() {
                return this.accountCarrierProduct;
            },
            accountCarrierOptionDS() {
                return this.accountCarrierOption.filter(x => x.accountCustomerID === this.model.accountCustomerID);
            },
            accountCarrierOptionAllDS() {
                return this.accountCarrierOption;
            },
            categoryTypeDS() {
                return this.lookup.categoryTypes;
            },
            conditionTypeDS() {
                return this.lookup.conditionTypes;
            },
            operatorTypeDS() {
                return this.lookup.operatorTypes;
            },
            tradeblocDS() {
                return this.lookup.tradeBlocCountries;
            },
            postalBlocDS() {
                return this.lookup.postalBlocs;
            },
            dialogHeader() { return this.dialog.header; },
            dialogItemHeader() {
                if (this.itemDialog.add) return "Add Item";
                if (this.itemDialog.edit) return "Edit Item";
                return "Delete Record?"
            },
            //companyDS() { return this.$store.getters["company/ds"]; },
            //warehouseDS() { return this.companyDS.data.filter((x) => x.type === 8); },
        },
        watch: {
            'formData': {
                handler() {
                    if (this.source.data.length === 0 && this.$props?.serviceGroupID > 0) {
                        this.getListByServiceGroup();
                    }
                }, deep: true
            },
            'formProducts': {
                handler() {
                    this.$nextTick(() => {
                        this.accountCarrier = [];
                        this.accountCarrierProduct = [];
                        this.products = this.$props.formProducts;
                        this.setCarrier();
                        this.setService();
                    });
                }, deep: true
            },
            'formProductOptions': {
                handler() {
                    this.$nextTick(() => {
                        this.accountCarrierOption = [];
                        this.productOptions = this.$props.formProductOptions;
                        this.setOption();
                    });
                }, deep: true
            }

        },
        methods: {
            isSync(e) {
                this.$nextTick(() => {
                    this.model = e.model;
                });
            },
            async postRule(e) {
                let _self = this;
                await this.$store.dispatch("servicegrouprule/addRecord", this.$filters.init(e)).then(() => {
                    _self.model = _self.record;
                    e.id = _self.model.id;
                    e.id2 = _self.model.id2;
                    e.mask = _self.model.mask;
                    e.code = _self.model.code;
                    e.name = _self.model.name;
                    e.conditions = _self.model.conditions;
                    let index = _self.source.data.findIndex(f => f.temporaryID === e.temporaryID);
                    if (index !== -1)
                        _self.source.data[index] = this.$filters.init(e);

                    })
                    .catch((ex) => {
                        this.errors = ex.data;
                    });
            },
            async putRule(e) {
                let _self = this;
                await this.$store.dispatch("servicegrouprule/modifyRecord", this.$filters.init(e)).then(() => {
                    _self.model = _self.record;
                    e.conditions = _self.model.conditions;
                })
                .catch((ex) => {
                    this.errors = ex.data;
                });
            },
            async removeRule(e) {
                let _self = this;
                    await this.$store.dispatch("servicegrouprule/removeRecord", e.mask).then(() => {
                    })
                    .catch((ex) => {
                        _self.errors = ex.data;
                    });
            },
            async getListByServiceGroup() {
                await this.$axios
                    .get(config.endpoint.api + `/services/facility/servicegrouprule/getbyservicegroupid/${this.$props?.serviceGroupID}`.replaceAll("//", "/"))
                    .then((response) => {
                        let _result = [];
                        (response.data.result ?? []).forEach((data) => {
                            _result.push(this.$filters.init(data));
                        });
                        this.source.data = _result;
                        this.source.total = _result.length;
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            onExport() {

            },
            rowClick(e) {
                this.onRuleEdit(e.data);
            },
            onRuleCreate() {
                this.dialog.header = "Manage Rule";
                this.model = {
                    //id: Number(Math.random().toString().replace(".", "")),
                    id: 0,
                    code: ("SR" + Math.random().toString().replace(".", "")).slice(0, 20),
                    name: ("SR" + Math.random().toString().replace(".", "")).slice(0, 20),
                    temporaryID: Number(Math.random().toString().replace(".", "")),
                    serviceGroupID: this.$props?.serviceGroupID > 0 ? this.$props?.serviceGroupID ?? 0 : 0,
                    clauseID: 100,
                    incoTerms: null,
                    shipmentType: null,
                    accountCustomerID: null,
                    accountCustomerProductID: null,
                    environmentID: null,
                    warehouseID: null,
                    mask: null,
                    conditions: [],
                },
                this.sourceItem.data = [],
                this.sourceItem.total = 0;
                this.dialog.delete = true;
                this.dialog.add = true;
               
            },
            onRuleEdit(e) {
                this.model = this.$filters.init(e);
                this.dialog.delete = true;
                this.dialog.header = "Edit Rule";
                this.dialog.edit = true;
            },
            onRuleDelete(data) {
                this.model = this.$filters.init(data);
                this.dialog.header = "Delete Rule";
                this.dialog.delete = true;
                if (this.$props?.serviceGroupID > 0)
                    this.forDeleteRule = this.source.data.filter(x => x.id === this.model.id)[0];
                else
                    this.forDeleteRule = this.source.data.filter(x => x.temporaryID === this.model.temporaryID)[0];
            },
            onManageRule(e) {
                var location = this.$filters.init(e);
                var index = -1;
                if (location.id > 0)
                    index = this.source.data.findIndex(x => x.id === location.id);
                else
                    index = this.source.data.findIndex(x => x.temporaryID === location.temporaryID);
                if (index === -1) {
                    this.source.data.push(this.$filters.init(e));
                    if (this.$props?.serviceGroupID > 0) { this.postRule(e); }
                }
                else {
                    if (this.dialog.edit || this.dialog.add) {
                        if (this.$props?.serviceGroupID > 0 && e.id > 0 && e.mask !== null) { this.putRule(e); }
                        this.source.data[index] = this.$filters.init(e);
                    }
                    else {
                        if (this.$props?.serviceGroupID > 0 && e.id > 0 && e.mask !== null) { this.removeRule(e); }
                        this.source.data.splice(index, 1);
                    }
                }
                this.source.total = this.source.data.length;
                this.model = {
                    id: 0,
                    code: "SR" + Math.random().toString().replace(".", ""),
                    name: "SR" + Math.random().toString().replace(".", ""),
                    temporaryID: Math.random(),
                    serviceGroupID: 0,
                    clauseID: 0,
                    incoTerms: null,
                    shipmentType: null,
                    accountCustomerID: null,
                    accountCustomerProductID: null,
                    warehouseID: null,
                    environmentID: null,
                    mask: null,
                    conditions: [],
                    conditionGrid: {
                        id: "SubGrid" + Math.round().toString().replace(".", ""),
                        source: {
                            total: 0,
                            data: []
                        },
                        page: {
                            first: 0,
                            current: 0,
                            size: 10
                        },
                    }
                },
                this.dialog.delete = false;
                this.dialog.edit = false;
                this.dialog.add = false;
                this.dialog.header = "Delete Record?";
                this.$emit("is-sync", { model : this.source });
            },
            async setCarrier() {
                let _self = this;
                let products = this.products;
                products?.forEach((e) => {
                    let index = -1;
                    index = _self.accountCarrier.findIndex(x => x.id === e.accountCustomerID);
                    if (index === -1)
                        _self.accountCarrier.push({ id: e.accountCustomerID, name: e.accountName, text: "[" + e.accountName + "] " + e.serviceProviderName });
                }, this);
            },
            async setService() {
                let _self = this;
                let products = this.products;
                products?.forEach((e) => {
                    let index = -1;
                    index = _self.accountCarrierProduct.findIndex(x => x.id === e.accountCustomerProductID);
                    if (index === -1)
                        _self.accountCarrierProduct.push({ id: e.accountCustomerProductID, accountCustomerID: e.accountCustomerID, code: e.code, name: e.name, text: "[" + e.code + "] " + e.name });
                }, this);
            },
            async setOption() {
                let _self = this;
                let products = this.productOptions;
                products?.forEach((e) => {
                    let index = -1;
                    index = _self.accountCarrierOption.findIndex(x => x.id === e.accountCustomerProductID);
                    if (index === -1)
                        _self.accountCarrierOption.push({ id: e.accountCustomerProductID, accountCustomerID: e.accountCustomerID, code: e.code, name: e.name, text: "[" + e.code + "] " + e.name });
                }, this);
            },
            conditionTextDisplay_old(conditions) {
                let displayText = "";
                let index = 0;

                conditions?.forEach((e) => {        
                    let showTextObj = e.dataType === undefined || (e.dataType !== 30 && e.dataType !== 70 && (e?.referenceType === e || e?.referenceType === undefined || e?.referenceType === 0));
                    let showNumberObj = e.dataType === 30 && (e?.referenceType === null || e?.referenceType === 0 || e?.referenceType === 500);
                    let showDecimalObj = e.dataType === 70;
                    displayText += (index > 0 ? (this.$vm.$enums['logictype'].filter((x) => x.id == e.logicType)[0]?.text ?? "") + " " : "") + (this.conditionTypeDS.filter(x => x.id === e.conditionTypeID)[0]?.text ?? "") + " " +
                        (this.operatorTypeDS.filter(x => x.id === e.operatorType)[0]?.text ?? "").toLowerCase() + " " +
                        (e.referenceType === 100 && e.currencyID > 0 ? " " + (this.$store.getters["currency/ds"].data.filter((x) => x.id == e.currencyID)[0]?.symbol ?? "") : "") +
                        (showTextObj ? e?.textValue ?? "" : "") +
                        (showNumberObj ? e?.numericValue ?? 0 : "") +
                        (showDecimalObj ? this.formatDecimal(e?.decimalValue ?? 0, 3) : "") +
                        (e.referenceType === 200 && e.weightUnit > 0 ? " " + (this.$vm.$enums['weightunit'].filter((x) => x.id == e.weightUnit)[0]?.text ?? "") : "") +
                        (e.referenceType === 300 && e.countryTradeBlocID > 0 ? " [" + (this.tradeblocDS.filter((x) => x.id == e.countryTradeBlocID)[0]?.code ?? "") + "] " + (this.tradeblocDS.filter((x) => x.id == e.countryTradeBlocID)[0]?.name ?? "") : "") +
                        (e.referenceType === 400 && e.day > 0 ? " " + (this.$vm.$enums['daytype'].filter((x) => x.id == e.day)[0]?.text ?? "") : "") +
                        (e.referenceType === 500 && e.dimensionUnit > 0 ? " " + (this.$vm.$enums['lengthunit'].filter((x) => x.id == e.dimensionUnit)[0]?.text ?? "") : "") +
                        (e.referenceType === 600 && e.postalBlocID > 0 ? " [" + (this.postalBlocDS.filter((x) => x.id == e.postalBlocID)[0]?.code ?? "") + "] " + (this.postalBlocDS.filter((x) => x.id == e.postalBlocID)[0]?.name ?? "") : "") + " ";
                    index = index + 1;
                }, this);

                return displayText;
            },
            conditionTextDisplay(conditions) {
                let displayText = "";
                conditions;
                let multiParentCondition = conditions?.filter(y => y.parentID === 0 && y.temporaryParentID === 0).length ?? 0 > 1;
                let index = 0;
                conditions?.filter(y => y.parentID === 0 && y.temporaryParentID === 0)?.forEach((e) => {
                    let parentText = "";
                    let parentLogicText = "";
                    let showTextObj = e.dataType === undefined || (e.dataType !== 30 && e.dataType !== 70 && (e?.referenceType === e || e?.referenceType === undefined || e?.referenceType === 0));
                    let showNumberObj = e.dataType === 30 && (e?.referenceType === null || e?.referenceType === 0 || e?.referenceType === 500);
                    let showDecimalObj = e.dataType === 70;
                    parentLogicText = (index > 0 ? (this.$vm.$enums['logictype'].filter((x) => x.id == e.logicType)[0]?.text ?? "").toLowerCase() + " " : "");
                    parentText += (this.conditionTypeDS.filter(x => x.id === e.conditionTypeID)[0]?.text ?? "") + " " +
                        (this.operatorTypeDS.filter(x => x.id === e.operatorType)[0]?.text ?? "").toLowerCase() + " " +
                        (e.referenceType === 100 && e.currencyID > 0 ? " " + (this.$store.getters["currency/ds"].data.filter((x) => x.id == e.currencyID)[0]?.symbol ?? "") : "") +
                        (showTextObj ? e?.textValue ?? "" : "") +
                        (showNumberObj ? e?.numericValue ?? 0 : "") +
                        (showDecimalObj ? this.formatDecimal(e?.decimalValue ?? 0, 3) : "") +
                        (e.referenceType === 200 && e.weightUnit > 0 ? " " + (this.$vm.$enums['weightunit'].filter((x) => x.id == e.weightUnit)[0]?.text ?? "") : "") +
                        (e.referenceType === 300 && e.countryTradeBlocID > 0 ? " [" + (this.tradeblocDS.filter((x) => x.id == e.countryTradeBlocID)[0]?.code ?? "") + "] " + (this.tradeblocDS.filter((x) => x.id == e.countryTradeBlocID)[0]?.altName ?? this.tradeblocDS.filter((x) => x.id == e.countryTradeBlocID)[0]?.name ?? "") : "") +
                        (e.referenceType === 400 && e.day > 0 ? " " + (this.$vm.$enums['daytype'].filter((x) => x.id == e.day)[0]?.text ?? "") : "") +
                        (e.referenceType === 500 && e.dimensionUnit > 0 ? " " + (this.$vm.$enums['lengthunit'].filter((x) => x.id == e.dimensionUnit)[0]?.text ?? "") : "") +
                        (e.referenceType === 600 && e.postalBlocID > 0 ? " [" + (this.postalBlocDS.filter((x) => x.id == e.postalBlocID)[0]?.code ?? "") + "] " + (this.postalBlocDS.filter((x) => x.id == e.postalBlocID)[0]?.name ?? "") : "") + " ";

                    let subText = ""
                    //conditions?.filter(f => (f.parentID === e.id && f.temporaryParentID === e.temporaryId) || (f.parentID === e.id && f.temporaryParentID === e.id))?.forEach((subE) => {
                   // conditions?.filter(f => f.parentID === e.id && f.temporaryParentID === e.temporaryId) || (e.id > 0 ? f.parentID === e.id && f.temporaryParentID === e.id : false)?.forEach((subE) => {
                    conditions?.filter(f => f.parentID > 0 || f.temporaryParentID > 0)?.forEach((subE) => {

                        if ((subE.parentID === e.id && subE.temporaryParentID === e.temporaryID) || (e.id > 0 ? subE.parentID === e.id && subE.temporaryParentID === e.id : false)) {

                            showTextObj = subE.dataType === undefined || (subE.dataType !== 30 && subE.dataType !== 70 && (subE?.referenceType === e || subE?.referenceType === undefined || subE?.referenceType === 0));
                            showNumberObj = subE.dataType === 30 && (subE?.referenceType === null || subE?.referenceType === 0 || subE?.referenceType === 500);
                            showDecimalObj = subE.dataType === 70;
                            subText += (this.$vm.$enums['logictype'].filter((x) => x.id == subE.logicType)[0]?.text ?? "").toLowerCase() + " " + (this.conditionTypeDS.filter(x => x.id === subE.conditionTypeID)[0]?.text ?? "") + " " +
                                (this.operatorTypeDS.filter(x => x.id === subE.operatorType)[0]?.text ?? "").toLowerCase() + " " +
                                (subE.referenceType === 100 && subE.currencyID > 0 ? " " + (this.$store.getters["currency/ds"].data.filter((x) => x.id == subE.currencyID)[0]?.symbol ?? "") : "") +
                                (showTextObj ? subE?.textValue ?? "" : "") +
                                (showNumberObj ? subE?.numericValue ?? 0 : "") +
                                (showDecimalObj ? this.formatDecimal(subE?.decimalValue ?? 0, 3) : "") +
                                (subE.referenceType === 200 && subE.weightUnit > 0 ? " " + (this.$vm.$enums['weightunit'].filter((x) => x.id == subE.weightUnit)[0]?.text ?? "") : "") +
                                (subE.referenceType === 300 && subE.countryTradeBlocID > 0 ? " [" + (this.tradeblocDS.filter((x) => x.id == subE.countryTradeBlocID)[0]?.code ?? "") + "] " + (this.tradeblocDS.filter((x) => x.id == subE.countryTradeBlocID)[0]?.altName ?? this.tradeblocDS.filter((x) => x.id == subE.countryTradeBlocID)[0]?.name ?? "") : "") +
                                (subE.referenceType === 400 && subE.day > 0 ? " " + (this.$vm.$enums['daytype'].filter((x) => x.id == subE.day)[0]?.text ?? "") : "") +
                                (subE.referenceType === 500 && subE.dimensionUnit > 0 ? " " + (this.$vm.$enums['lengthunit'].filter((x) => x.id == subE.dimensionUnit)[0]?.text ?? "") : "") +
                                (subE.referenceType === 600 && subE.postalBlocID > 0 ? " [" + (this.postalBlocDS.filter((x) => x.id == subE.postalBlocID)[0]?.code ?? "") + "] " + (this.postalBlocDS.filter((x) => x.id == subE.postalBlocID)[0]?.name ?? "") : "") + " ";


                        }

                    }, this);

                    displayText += " " + (parentLogicText + " " + (multiParentCondition && (subText.length > 0) ? "(" : "") + (parentText + " " + subText).trim() + (subText.length > 0 ? ")" : "")).trim();
                    index = index + 1;
                }, this);

                return displayText;
            },
            formatDecimal(value, decimalPart) {
                return Number(value ?? 0).toFixed(decimalPart ?? 2);
            },
            async getCategoryTypeList() {
                await this.$axios
                    .get(config.endpoint.api + `/services/facility/servicegrouprulecondition/category/`.replaceAll("//", "/"))
                    .then((response) => {
                        this.lookup.categoryTypes = response.data.result;
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            async getConditionTypeList() {
                await this.$axios
                    .get(config.endpoint.api + `/services/facility/servicegrouprulecondition/conditiontype/`.replaceAll("//", "/"))
                    .then((response) => {
                        this.lookup.conditionTypes = response.data.result;
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            async getOperatorTypeList() {
                await this.$axios
                    .get(config.endpoint.api + `/services/facility/servicegrouprulecondition/operatortype/`.replaceAll("//", "/"))
                    .then((response) => {
                        this.lookup.operatorTypes = response.data.result;
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            async getTradeBlocCountriesList() {
                await this.$axios
                    .get(config.endpoint.api + `/services/facility/servicegrouprulecondition/TradeBlocAndCountry/`.replaceAll("//", "/"))
                    .then((response) => {
                        this.lookup.tradeBlocCountries = response.data.result;
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            async getPostalBlocList() {
                await this.$axios
                    .get(config.endpoint.api + `/services/facility/rulepostalbloc/`.replaceAll("//", "/"))
                    .then((response) => {
                        this.lookup.postalBlocs = response.data.result;
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            async getCurrency() { return this.$store.dispatch("currency/getAll", {sort: null, where: null}); },
        },
        async mounted() {
            await this.getCurrency();
            await this.getCategoryTypeList();
            await this.getConditionTypeList();
            await this.getOperatorTypeList();
            await this.getTradeBlocCountriesList();
            await this.getPostalBlocList();
            this.$nextTick(() => {
                this.products = this.$props.formData.products;
                this.productOptions = this.$props.formData.productOptions;
                this.setCarrier();
                this.setService();
                this.setOption();
            });
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>