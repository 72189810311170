<template>
    <div style="margin: 0 -1rem; border-top: 1px solid var(--gray-300); padding: 0.5rem 1rem 0 1rem;">
        <div class="p-grid p-nogutter p-justify-end">
            <pv-button icon="pi pi-fw pi-trash" class="p-button p-button-danger" style="margin-right: 5px;" @click="onDelete(model?.id, model?.tempParentID, model?.tempChildID)" />
        </div>
        <form-lookup id="logic" type="enum" source="logictype" :sorted="false" :required="true" :value="model?.logicType" @is-sync="model.logicType = $event?.value ?? 100" />

        <!-- Category Type -->
        <form-lookup id="category" :source="categories" sourceType="array" :sorted="false" :required="true" :value="activeCategory?.id" @is-sync="onChangeCategory" />
        <!-- Condition Type -->
        <form-lookup id="condition" :source="conditions" sourceType="array" :sorted="false  " :required="true" :value="activeCondition?.id" @is-sync="onChangeCondition" />
        <!-- Operator Type -->
        <form-lookup v-if="validOperators.length > 0" id="operator" :source="operators" sourceType="array" :sorted="false" :required="true" :value="activeOperator?.id" @is-sync="onChangeOperator" />

        <!-- Values Templates -->
        <!-- Boolean Type -->
        <form-boolean v-if="activeCondition?.dataType === 500" id="value" :value="Boolean(model?.valueGeneral)" @is-sync="onChangeValue" />
        <form-datetime v-else-if="activeCondition?.dataType === 400" id="value" type="date" :value="Date(model?.valueGeneral ?? '')" @is-sync="onChangeValue" :required="true" />
        <!--Decimal and Weight Unit-->
        <div v-else-if="activeCondition?.dataType === 300 && activeCondition?.referenceType === 100" class="p-d-flex p-nogutter">
            <div class="p-col">
                <form-numeric id="value" type="decimal" :decimalDigit="(model?.referenceID === 200 ? 0 : 3)" :max="(model.referenceID === 200 ? 9999500 : 99995)" :required="true" :value="Number(model?.valueGeneral)" @is-sync="onChangeValue" />
            </div>
            <div class="p-col-fixed" style="width: 14rem;">
                <form-lookup id="weightUnit" type="enum" source="weightunit" :required="true" :sorted="false" :value="[100, 200, 300].indexOf(model?.referenceID ?? 100) !== -1 ? model?.referenceID ?? 100 : 100" @is-sync="onChangeReference" />
            </div>
        </div>
        <!--Decimal and Length Unit-->
        <div v-else-if="activeCondition?.dataType === 300 && activeCondition?.referenceType === 200" class="p-d-flex p-nogutter">
            <div class="p-col">
                <form-numeric id="value" type="decimal" :decimalDigit="(model?.referenceID === 200 ? 0 : 3)" :max="(model.referenceID === 200 ? 9999500 : 99995)" :required="true" :value="Number(model?.valueGeneral)" @is-sync="onChangeValue" />
            </div>
            <div class="p-col-fixed" style="width: 14rem;">
                <form-lookup id="lengthUnit" type="enum" source="lengthunit" :required="true" :sorted="false" :value="[100, 200, 300].indexOf(model?.referenceID ?? 100) !== -1 ? model?.referenceID ?? 100 : 100" @is-sync="onChangeReference" />
            </div>
        </div>
        <!--Decimal and Currency-->
        <div v-else-if="activeCondition?.dataType === 300 && activeCondition?.referenceType === 300" class="p-d-flex p-nogutter">
            <div class="p-col">
                <form-numeric id="value" type="decimal" :max="999999999999.99" :required="true" :value="Number(model?.valueGeneral)" @is-sync="onChangeValue" />
            </div>
            <div class="p-col">
                <form-lookup id="currency" :source="$vm.$currencies" sourceType="array" :required="true" :sorted="false" :value="model?.referenceID" @is-sync="onChangeReference" />
            </div>
        </div>
        <!--Standard Decimal-->
        <form-numeric v-else-if="activeCondition?.dataType === 300" id="value" type="decimal" :max="999999999999.99" :required="true" :value="Number(model?.valueGeneral)" @is-sync="onChangeValue" />
        <!--Int32 With Country or Trade Bloc-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 400" id="value" type="autocomplete" label="Country / Trade Bloc" :source="tradebloc" sourcesType="array" :isTradeBloc="true" :required="true" :sorted="false" :value="model?.referenceID" @is-sync="onChangeReference" />
        <!--Int32 With Postal Bloc-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 500" id="value" type="autocomplete" label="Postal Bloc" :source="postalbloc" sourcesType="array" :required="true" :sorted="false" :value="model?.referenceID" @is-sync="onChangeReference" />
        <!--Int32 and Region Type-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 600" id="value" :source="region" sourceType="array" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <!-- Int32 and Sub Region Type -->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 700" id="value" type="dropdown" :source="subregion" sourceType="array" textField="name" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <!--Int32 and Estalishent Type-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 800" id="value" type="enum" source="establishmenttype" :sorted="false" :value="model?.referenceID" @is-sync="onChangeReference" :required="true" />
        <!--Int32 and Trade Types-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 900 && !isFlag" id="value" type="enum" source="tradetype" :sorted="false" :required="true" :value="model?.referenceID == 0 ? 1 : model?.referenceID ?? 1" @is-sync="onChangeReference" />
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 900 && isFlag" id="value" type="flag" source="tradetype" :sorted="false" :required="true" :value="model?.referenceID == 0 ? 1 : model?.referenceID ?? 1" @is-sync="onChangeReference" />
        <!--Int32 and Incoterms-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1000 && !isFlag" id="value" type="enum" source="incotermtype" :sorted="false" :required="true" :value="model?.referenceID == 0 ? 64 : model?.referenceID ?? 64" @is-sync="onChangeReference" />
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1000 && isFlag" id="value" type="flag" source="incotermtype" :sorted="false" :required="true" :value="model?.referenceID == 0 ? 64 : model?.referenceID ?? 64" @is-sync="onChangeReference" />
        <!--Int32 and Consignment Type-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1100 && !isFlag" id="value" type="enum" source="serviceproviderproductpackagingtype" :sorted="false" :value="model?.referenceID == 0 ? 4 : model?.referenceID ?? 4" @is-sync="onChangeReference" :required="true" />
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1100 && isFlag" id="value" type="flag" source="serviceproviderproductpackagingtype" :sorted="false" :value="model?.referenceID == 0 ? 4 : model?.referenceID ?? 4" @is-sync="onChangeReference" :required="true" />
        <!--Int32 and Consignment Category Type-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1200 && !isFlag" id="value" type="enum" source="customscategorytype" :sorted="false" :value="model?.referenceID == 0 ? 2 : model?.referenceID ?? 2" @is-sync="onChangeReference" :required="true" />
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1200 && isFlag" id="value" type="flag" source="customscategorytype" :sorted="false" :value="model?.referenceID == 0 ? 2 : model?.referenceID ?? 2" @is-sync="onChangeReference" :required="true" />
        <!--Int32 and Notification Type-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1300 && !isFlag" id="value" type="enum" source="notificationtype" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1300 && isFlag" id="value" type="flag" source="notificationtype" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <!--Int32 and IOSS Available-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1400" id="value" type="enum" source="iossavailability" :sorted="false" :required="true" :value="[100, 200].indexOf(model?.referenceID) !== -1 ? model?.referenceID : 100" @is-sync="onChangeReference" />
        <!--Int32 and Dangerous Goods-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1500 && !isFlag" id="value" type="enum" source="dangerousgoodstype" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1500 && isFlag" id="value" type="flag" source="dangerousgoodstype" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <!--Int32 and Special Handling-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1600 && !isFlag" id="value" type="enum" source="packageadditionalhandlingtype" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1600 && isFlag" id="value" type="flag" source="packageadditionalhandlingtype" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <!--Int32 and Day of Week-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1700 && !isFlag" id="value" type="enum" source="dayofweek" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1700 && isFlag" id="value" type="flag" source="dayofweek" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <!--Int32 and Shipping Type-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1900 && !isFlag" id="value" type="enum" source="documentshippingtype" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 1900 && isFlag" id="value" type="flag" source="documentshippingtype" :sorted="false" :required="true" :value="model?.referenceID" @is-sync="onChangeReference" />
        <!--Int32 With Postal Regex-->
        <form-lookup v-else-if="activeCondition?.dataType === 200 && activeCondition?.referenceType === 2000" id="value" type="autocomplete" label="Postal Predefined Regex" :source="postalregex" sourcesType="array" option-group="label" :required="true" :sorted="false" :value="model?.referenceID" @is-sync="onChangeReference" />
        <!--Standard Integer-->
        <form-numeric v-else-if="activeCondition?.dataType === 200" id="value" :required="true" :value="Number(model?.valueGeneral ?? '0')" @is-sync="onChangeValue" />
        <!--String Default-->
        <form-input v-else id="value" :required="true" :value="model?.valueGeneral ?? null" @is-sync="onChangeValue" />
    </div>
</template>
<script>
    export default {
        name: 'MarkupRuleConditionDetailForm',
        emits: ["isSync", "isSyncSubcondition", "isAddSubcondition", "isDeleteSubcondition"],
        props: {
            source: { type: Object }, // Should contain a blank model when new and should contain the source when edit.
            parentID: { type: Number }, // Should hold the parent id of the sub conditions.
            categories: { type: Array }, // Hold allavailable categories
            condition: { type: Array }, // Hold all available conditions regardless of categories
            operator: { type: Array }, // Hold all available operators regardless of categories and conditions
            tradebloc: { type: Array }, // Hold availabe trade bloc to prevent multi retrieval
            postalbloc: { type: Array }, // Hold available postal bloc to prevent multi retrieval
            postalregex: { type: Array }, // Hold available postal regex to prevent multi retrieval
            region: { type: Array }, // Hold available region
            subregion: { type: Array }, // Hold available subregion
            countryCode: { type: String },
            currencyCode: { type: String }
        },
        data() {
            return {
                selectedGroupedCity: null,
                model: {
                    surchargeProviderID: this.parentID,
                    serviceProviderID: 0,
                    providerCarrierID: 0,
                    accountID: null,
                    surchargeID: 0,
                    effectiveDateFrom: null,
                    effectiveDateTo: null,
                    rateType: 100,
                    rateApplied: 0,
                    rateChargeType: 100,
                    ratePercentageBase: 100,
                    minRateType: 100,
                    minRateApplied: 0,
                    minRateChargeType: 100,
                    minRatePercentageBase: 100,
                    maxRateType: 100,
                    maxRateApplied: 0,
                    maxRateChargeType: 100,
                    maxRatePercentageBase: 100,
                    summary: null
                }
            }
        },
        computed: {
            rateTypes() { return (this.$vm.$enums.markupratetype ?? []).filter(x => x.id === (((this.model?.markupRateConditionID ?? 0) == 5 || (this.model?.markupRateConditionID ?? 0) == 14) ? 300 : x.id)); },
            activeLogicalOperator() { return this.model.logicType; },
            activeCategory() { return this.categories.filter(x => x.id === this.model?.rateCategoryID ?? 1)[0] },
            activeCondition() { return this.conditions.filter(x => x.id == this.model?.rateConditionID ?? 1)[0] },
            activeOperator() { return this.operators.filter(x => x.id == this.model?.operatorType)[0] ?? this.operators[0]; },
            validOperators() { return (this.activeCondition?.operators ?? []).length > 0 ? this.activeCondition?.operators ?? [] : this.activeCategory?.operators ?? []; },
            conditions() { return this.condition.filter(x => x.rateCategoryTypeID === this.activeCategory?.id) ?? [] },
            operators() { return (this.operator ?? []).filter(x => this.validOperators.findIndex(y => y === x.id) > -1) ?? [] },
            conditionAndOperatorText() {
                let conditionText = this.activeCondition?.text;
                let operatorText = this.operators.filter(x => x.id === this.model.operatorType)[0]?.text ?? '';
                if (this.model.operatorType >= 4100 && this.model.operatorType <= 8000) {
                    conditionText = this.model.rateConditionText?.replace('If <', 'If any <').replace('all <', 'any <');
                    operatorText = (this.operators.filter(x => x.id === this.model.operatorType)[0]?.text ?? '').replace('Any ', '');
                }
                else if (this.model.operatorType >= 8100 && this.model.operatorType <= 12000) {
                    conditionText = this.model.rateConditionText?.replace('If <', 'If all <').replace('any <', 'all <');
                    operatorText = (this.operators.filter(x => x.id === this.model.operatorType)[0]?.text ?? '').replace('All ', '');
                }
                return `${conditionText} ${operatorText?.toLowerCase()}`;
            },
            valueText() {
                let value = '';
                if (this.model.referenceTypeID === 100 || this.model.referenceTypeID === 200)
                    value = this.$filters.formatNumber(this.model.valueGeneral ?? 0, this.model?.referenceID === 200 ? 0 : 3);
                else if (this.model.referenceTypeID === 300)
                    value = this.$filters.formatCurrency(this.model.valueGeneral ?? 0, this.$vm.$currencies.filter(x => x.id === this.model.referenceID)[0]?.code);
                else if (this.model.referenceTypeID >= 400 && this.model.referenceTypeID <= 2000)
                    value = '';
                else if (this.activeCondition?.dataType === 400)
                    value = this.$filters.toDateDisplay(new Date(this.model.valueGeneral));
                else if (this.activeCondition?.dataType === 300)
                    value = this.$filters.formatNumber(this.model.valueGeneral ?? 0, 2);
                else if (this.activeCondition?.dataType === 200)
                    value = this.$filters.formatNumber(this.model.valueGeneral ?? 0, 0);
                return value ?? '';
            },
            referenceText() {
                var text = '';
                if (this.model.referenceTypeID === 100 || this.model.referenceTypeID === 200)
                    text = this.$filters.quantifiedDisplay(this.model?.referenceText, this.model.valueGeneral ?? 0);
                return text;
            },
            summary() { return `${this.activeLogicalOperator} ${(this.activeCondition?.text ?? '')} ${(this.activeOperator?.text ?? '').toLowerCase()} ${this.model?.valueGeneral ?? ''} ${this.model?.referenceText ?? ''} ${this.model?.category2 ?? ''} ${this.model?.referenceText ?? ''} will be charge with ${this.rateAppliedText}`; },
            isFlag() { return [1600, 1700, 5600, 5700, 9600, 9700].indexOf(this.model.operatorType ?? 100) !== -1; },
            subregions() {
                let data = this.region.map(m => ({
                    label: m.name,
                    code: m.code,
                    items: this.subregion.filter(x => x.regionId === m.id).map(mm => ({
                        label: mm.name,
                        value: mm.id
                    }))
                }));
                return data;
            },
            appliedOperator() {
                return this.validOperators.filter(x => x === (this.model?.operatorType ?? this.activeCondition.operatorDefault))[0] ?? this.validOperators[0];
            }
        },
        watch: {
            source: { handler() { this.$nextTick(() => { this.model = this.source; }) }, deep: true },
            selectedGroupedCity: { handler() { this.$nextTick(() => { this.model.referenceID = this.selectedGroupedCity?.id; }) }, deep: true },
            'model.referenceTypeID': {
                handler() {
                    this.model.referenceTypeID = this.activeCondition?.referenceType;
                }, deep: true
            },
            'model.referenceID': {
                handler() {
                    this.model.referenceText = null;
                    if (this.model.referenceTypeID >= 400 && this.model.referenceTypeID <= 1700) {
                        this.model.valueGeneral = null;
                    }
                    switch (this.model.referenceTypeID) {
                        case 100:
                            this.model.referenceText = this.$vm.$enums.weightunit.filter(x => x.id === this.model.referenceID)[0]?.text;
                            break;
                        case 200:
                            this.model.referenceText = this.$vm.$enums.lengthunit.filter(x => x.id === this.model.referenceID)[0]?.text;
                            break;
                        case 400:
                            if ((this.tradebloc ?? []).filter(x => x.id === Number(this.model?.referenceID ?? -1000))[0])
                                this.model.referenceText = `[${this.tradebloc.filter(x => x.id === this.model?.referenceID ?? -1000)[0].code}] ${this.tradebloc.filter(x => x.id === this.model?.referenceID ?? -1000)[0].text}`;
                            break;
                        case 500:
                            if (this.postalbloc)
                                this.model.referenceText = this.postalbloc.filter(x => x.id === this.model.referenceID)[0]?.text;
                            break;
                        case 600:
                            if (this.region)
                                this.model.referenceText = this.region.filter(x => x.id === this.model.referenceID)[0]?.text;
                            break;
                        case 700:
                            var filteredSubRegions = (this.subregions ?? []).filter(x => (x.items ?? []).findIndex(y => y.id === this.model.referenceID) !== -1)[0];
                            if (filteredSubRegions)
                                this.model.referenceText = (filteredSubRegions.items ?? []).filter(x => x.id === this.model.referenceID)[0]?.name;
                            break;
                        case 800:
                            this.model.referenceText = this.$vm.$enums.establishmenttype.filter(x => x.id === this.model.referenceID)[0]?.text;
                            break;
                        case 900:
                            if (this.isFlag) {
                                var flags = this.$filters.getFlags(this.model.referenceID);
                                this.model.referenceText = this.$vm.$enums.tradetype.filter(x => flags.indexOf(x.id) !== -1).map(x => ` [${x.code}] ${x.text}`);
                            }
                            else {
                                var tradeType = this.$vm.$enums.tradetype.filter(x => x.id === this.model.referenceID)[0];
                                this.model.referenceText = `[${tradeType?.code}] ${tradeType?.text}`;
                            }
                            break;
                        case 1000:
                            if (this.isFlag) {
                                var incotermFlags = this.$filters.getFlags(this.model.referenceID);
                                this.model.referenceText = this.$vm.$enums.incotermtype.filter(x => incotermFlags.indexOf(x.id) !== -1).map(x => ` ${x.text}`);
                            }
                            else {
                                var incoterm = this.$vm.$enums.incotermtype.filter(x => x.id === this.model.referenceID)[0];
                                this.model.referenceText = `${incoterm?.text}`;
                            }
                            break;
                        case 1100:
                            if (this.isFlag) {
                                var packagingFlags = this.$filters.getFlags(this.model.referenceID);
                                this.model.referenceText = this.$vm.$enums.serviceproviderproductpackagingtype.filter(x => packagingFlags.indexOf(x.id) !== -1).map(x => `[${x.code}] ${x.text}`);
                            }
                            else {
                                var packaging = this.$vm.$enums.serviceproviderproductpackagingtype.filter(x => x.id === this.model.referenceID)[0];
                                this.model.referenceText = `[${packaging?.code}] ${packaging?.text}`;
                            }
                            break;
                        case 1200:
                            if (this.isFlag) {
                                var customCategoryFlags = this.$filters.getFlags(this.model.referenceID);
                                this.model.referenceText = this.$vm.$enums.customscategorytype.filter(x => customCategoryFlags.indexOf(x.id) !== -1).map(x => ` [${x.code}] ${x.text}`);
                            }
                            else {
                                var customCategory = this.$vm.$enums.customscategorytype.filter(x => x.id === this.model.referenceID)[0];
                                this.model.referenceText = `[${customCategory?.code}] ${customCategory?.text}`;
                            }
                            break;
                        case 1300:
                            if (this.isFlag) {
                                var notificationFlags = this.$filters.getFlags(this.model.referenceID);
                                this.model.referenceText = this.$vm.$enums.notificationtype.filter(x => notificationFlags.indexOf(x.id) !== -1).map(x => ` [${x.code}] ${x.text}`);
                            }
                            else {
                                var notifications = this.$vm.$enums.notificationtype.filter(x => x.id === this.model.referenceID)[0];
                                this.model.referenceText = `[${notifications?.code}] ${notifications?.text}`;
                            }
                            break;
                        case 1400:
                            this.model.referenceText = this.$vm.$enums.iossavailability.filter(x => x.id === this.model.referenceID)[0]?.text;
                            break;
                        case 1500:
                            if (this.isFlag) {
                                var dgFlags = this.$filters.getFlags(this.model.referenceID);
                                this.model.referenceText = this.$vm.$enums.dangerousgoodstype.filter(x => dgFlags.indexOf(x.id) !== -1).map(x => ` [${x.code}] ${x.text}`);
                            }
                            else {
                                var dg = this.$vm.$enums.dangerousgoodstype.filter(x => x.id === this.model.referenceID)[0];
                                this.model.referenceText = `[${dg?.code}] ${dg?.text}`;
                            }
                            break;
                        case 1600:
                            if (this.isFlag) {
                                var handlingFlags = this.$filters.getFlags(this.model.referenceID);
                                this.model.referenceText = this.$vm.$enums.packageadditionalhandlingtype.filter(x => handlingFlags.indexOf(x.id) !== -1).map(x => ` [${x.code}] ${x.text}`);
                            }
                            else {
                                var handling = this.$vm.$enums.packageadditionalhandlingtype.filter(x => x.id === this.model.referenceID)[0];
                                this.model.referenceText = `[${handling?.code}] ${handling?.text}`;
                            }
                            break;
                        case 1700:
                            if (this.isFlag) {
                                var dayofweekFlags = this.$filters.getFlags(this.model.referenceID);
                                this.model.referenceText = this.$vm.$enums.dayofweek.filter(x => dayofweekFlags.indexOf(x.id) !== -1).map(x => ` [${x.code}] ${x.text}`);
                            }
                            else {
                                var dayofweek = this.$vm.$enums.dayofweek.filter(x => x.id === this.model.referenceID)[0];
                                this.model.referenceText = `[${dayofweek?.code}] ${dayofweek?.text}`;
                            }
                            break;
                        case 1900:
                            if (this.isFlag) {
                                var shippingFlags = this.$filters.getFlags(this.model.referenceID);
                                this.model.referenceText = this.$vm.$enums.documentshippingtype.filter(x => shippingFlags.indexOf(x.id) !== -1).map(x => ` [${x.code}] ${x.text}`);
                            }
                            else {
                                var shipping = this.$vm.$enums.documentshippingtype.filter(x => x.id === this.model.referenceID)[0];
                                this.model.referenceText = `[${shipping?.code}] ${shipping?.text}`;
                            }
                            break;
                        case 2000:
                            var filtered = (this.postalregex ?? []).filter(x => x.items.findIndex(y => y.id === this.model.referenceID) !== -1)[0];
                            if (filtered)
                                this.model.referenceText = (filtered.items ?? []).filter(x => x.id === this.model.referenceID)[0]?.text;
                            break;
                        default:
                            this.model.referenceText = null;
                            break;
                    }
                }, deep: true
            },
            'model.rateConditionID': {
                handler() {
                    this.$nextTick(() => {
                        if (this.model.referenceTypeID === 300 && this.model.id === 0) {
                            this.model.referenceID = 3;
                            this.model.rateType = 100;
                        }
                        else if (this.model.referenceTypeID === 400 && this.model.id === 0) {
                            this.model.referenceID = 0;
                        }
                        if (this.model.rateConditionID === 18)
                            this.model.referenceText = '';
                    })
                }, deep: true
            },
            'model.valueGeneral': {
                handler() {
                    if (this.model.valueGeneral === "NaN")
                        this.model.valueGeneral = null;
                }, deep: true
            },
            'summary': { handler() { if (this.model.sortOrder > 0) this.$nextTick(() => { this.onSync(); }) }, deep: true },
            'rateAppliedText': { handler() { if (this.model.sortOrder > 0) this.$nextTick(() => { this.onSync(); }) }, deep: true }
        },
        methods: {
            onSync() {
                this.model.summary = `${this.model.logicType === 100 ? 'and' : 'or'} ${this.conditionAndOperatorText} ${this.valueText} ${this.model?.referenceText ?? ''}`.replaceAll('  ', ' ').replaceAll('and If', 'and').replaceAll('or If', 'or');
                this.model.valueGeneral = this.model?.valueGeneral?.toString();
                this.$emit('is-sync', this.model);
            },
            onDelete(id, tempParentID, tempChildID) { this.$emit('is-delete-subcondition', { id: id, tempParentID: tempParentID, tempChildID: tempChildID }) },
            onChangeCategory(e) {
                if (this.model) {
                    this.model.rateCategoryID = e?.value ?? this.model?.rateCategoryID ?? 1;
                    this.model.rateConditionID = this.conditions.findIndex(x => x.id === this.model?.rateConditionID) != -1 ? this.model?.rateConditionID : this.conditions.findIndex(x => x.id === e?.record?.conditionDefault) != -1 ? e?.record?.conditionDefault : this.conditions[0].id;
                }
            },
            onChangeCondition(e) {
                if (this.model) {
                    this.model.rateConditionID = e?.value ?? this.model?.rateCategoryID ?? 1;
                    this.model.rateConditionText = e?.text;
                    this.model.operatorType = e?.record?.operatorDefault ?? this.model?.operatorType ?? 100;
                    this.model.referenceTypeID = this.activeCondition?.referenceType ?? e?.record?.referenceTypeID ?? this.model?.referenceTypeID ?? 100;
                    this.model.referenceID = this.model?.referenceID ?? this.activeCondition?.referenceDefault;
                    if (this.model.referenceTypeID === 300 && this.model.id === 0) {
                        this.model.referenceID = 3;
                        this.model.rateType = 100;
                    }
                    else if ((this.model.referenceTypeID === 400 && this.model.id === 0 || this.activeCondition?.dataType === 400) && this.model.id === 0) {
                        this.model.referenceID = null;
                        this.model.valueGeneral = null;
                    }

                }
            },
            onChangeOperator(e) {
                if (this.model) {
                    this.model.operatorType = e?.value ?? this.activeCategory.operatorDefault;
                    this.model.operatorText = e?.text ?? this.operators.filter(x => x.id === this.model.operatorType)[0]?.text ?? '';
                    if (this.model.operatorType >= 4100 && this.model.operatorType <= 8000) {
                        this.model.rateConditionText = this.model.rateConditionText?.replace('If <', 'If any <').replace('all <', 'any <');
                        this.model.operatorText = (e?.text ?? this.operators.filter(x => x.id === this.model.operatorType)[0]?.text ?? '').replace('Any ', '');
                    }
                    else if (this.model.operatorType >= 8100 && this.model.operatorType <= 12000) {
                        this.model.rateConditionText = this.model.rateConditionText?.replace('If <', 'If all <').replace('any <', 'all <');
                        this.model.operatorText = (e?.text ?? this.operators.filter(x => x.id === this.model.operatorType)[0]?.text ?? '').replace('All ', '');
                    }
                }
            },
            onChangeReference(e) {
                if (this.model) {
                    this.model.referenceID = e?.value ?? this.model.referenceID;
                    this.model.referenceText = e?.text;
                    if (this.model.referenceTypeID === 400)
                        this.model.valueGeneral = null;
                    else if (this.model.referenceTypeID === 300)
                        this.model.referenceText = null;
                }
            },
            onChangeValue(e) {
                if (this.model)
                    this.$nextTick(() => {
                        this.model.valueGeneral = e?.value ?? this.model?.valueGeneral ?? null;
                    });
            }
        },
        created() {
            this.model = this.$filters.init(this.source);
        },
    }
</script>