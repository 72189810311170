<template>
    <cosmos-grid id="RateRulesGrid" :multiLevel="true" :source="source" sourceType="array" :searchFilters="['code', 'name']" :autoGenerate="false" :columns="columns" :deleteDisplayFields="deleteDisplay" :showOperation="false" :showHeader="false" groupBy="groupingText">
        <pv-grid-column field="groupingText" header="Code" headerStyle="width: 14.7rem; max-width: 14.7rem; padding-left: 15px; padding-top: .8rem !important;" style="width: 14.7rem; max-width: 14.7rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="text" header="Details">
            <template #body="slotProps">
                <span v-html="slotProps.data.text?.replaceAll('&lsqb;', '[').replaceAll('&rsqb;', ']')"></span>
            </template>
        </pv-grid-column>
        <!-- Operation Column -->
        <pv-grid-column field="mask" :headerStyle="`min-width: 7rem; max-width: 7rem; width: 7rem`" style="border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-d-flex" style="width: 2rem;">
                    <div class="p-col">
                        <pv-button icon="pi pi-pencil" iconPos="right" class="p-button-info" @click="onEdit(slotProps.data)" title="Edit Record" />
                    </div>
                    <div class="p-col">
                        <pv-button :disabled="slotProps.data.recordStatus !== 0" icon="pi pi-trash" iconPos="right" class="p-button-danger" @click="onDelete(slotProps.data)" title="Delete Record" />
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <!-- Group Header -->
        <template #groupheader="slotProps">
            <span class="image-text p-text-info" style="text-transform: uppercase; font-weight: 700 !important; color: var(--text-primary)" v-html="`${ slotProps.data['groupingText']}`"></span>
        </template>
        <!-- Sub Grid -->
        <template #expansion="slotProps">
            <rate-rulecondition-grid :providerRuleID="slotProps.data.id" :loadKey="internalLoadKey" />
        </template>
    </cosmos-grid>
    <!-- Create Dialog -->
    <keep-alive>
        <is-dialog :header="(activeRule?.id ?? 0) === 0 ? 'Add New Record' : 'Edit Record'" :breakpoints="{'2500px': '85vw', '960px': '75vw', '640px': '100vw'}" style="width: 50vw; min-width: 400px;" :visible="dialog.edit" @is-confirm="onSave" @is-cancel="onCancel" :confirmDisabled="confirmRuleDisabled">
            <rule-detail-form :source="model" :service-provider-ID="model?.serviceProviderID" :surchargeProviderID="model.surchargeProviderID" @is-sync="model = $event"></rule-detail-form>
        </is-dialog>
    </keep-alive>
    <!-- Delete Dialog -->
    <keep-alive>
        <is-dialog header="Delete Record?" :visible="dialog.delete" @is-confirm="onConfirmDelete(model.mask)" @is-cancel="onCancel()">
            <div class="confirmation-content p-d-flex">
                <div><i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" /></div>
                <div>
                    You are about to delete this record:
                    <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                        <tr>
                            <th style="text-align:left;">Rule</th>
                            <td>:</td>
                            <td><span v-html="this.model['text']"></span></td>
                        </tr>
                    </table>
                    Please be aware of the following:
                    <ul>
                        <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                        <li>For records that has activity, the record will only be disabled and archived.</li>
                        <li>Also deleting a record may be irreversible action.</li>
                    </ul>
                    Are you sure you still want to proceed?
                </div>
            </div>
        </is-dialog>
    </keep-alive>
</template>

<script>
    import rateruleconditiongrid from '@/components/facility/rates/surcharge-rates/rate-rulecondition-grid.vue';
    import ruledetailform from '@/components/facility/rates/surcharge-rates/ruledetail-form.vue';

    export default {
        name: "RateRulesGrid",
        props: {
            surchargeProviderID: { type: Number },
            loadKey: { type: Number }
        },
        components: {
            'rate-rulecondition-grid': rateruleconditiongrid,
            'rule-detail-form': ruledetailform
        },
        data() {
            return {
                internalLoadKey: null,
                model: null,
                source: [],
                dialog: {
                    delete: false,
                    edit: false
                }
            }
        },
        computed: {
            confirmRuleDisabled() {
                var disabled = false;
                if (!this.model?.surchargeID || this.model?.surchargeID === 0)
                    disabled = true;
                if (!this.model?.rateApplied || this.model?.rateApplied === 0)
                    disabled = true;
                return disabled;
            }
        },
        watch: {
            surchargeProviderID() { this.rebindSource(); },
            loadKey() {
                this.rebindSource();
                this.internalLoadKey = this.loadKey;
            }
        },
        methods: {
            onEdit(e) {
                this.model = this.$filters.init(e);
                this.dialog.edit = true;
            },
            onSave() {
                this.$store.dispatch("providersurchargerule/updateRecord", this.model)
                    .then((response) => {
                        if (response.success) {
                            let index = this.source.findIndex(x => x.id == response.result.id);
                            this.source[index] = response.result;
                            this.model = null;
                            this.$toast.add({ severity: 'success', summary: 'Surcharge Rule', detail: "The record was added successfully!", life: 3000 });
                            this.onCancel();
                            this.internalLoadKey = Math.floor(Math.random() * 9999999999);

                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    }).catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
                this.dialog.edit = false;
            },
            onDelete(e) {
                this.model = e;
                this.dialog.delete = true;
            },
            onConfirmDelete(e) {
                this.$store.dispatch("providersurchargerule/deleteRecordByMask", e)
                    .then((response) => {
                        if (response.success) {
                            this.source = this.source.filter(x => x.mask !== e);
                            this.$toast.add({ severity: 'success', summary: 'Rule Detail', detail: "The record was deleted successfully!", life: 3000 });
                            this.onCancel();
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
            onCancel() {
                this.dialog.delete = false;
                this.dialog.edit = false
            },
            rebindSource() {
                this.$store.dispatch("providersurchargerule/getURL", { url: `/services/facility/providersurchargerule/byprovidersurcharge/id/${this.surchargeProviderID}` }).then((response) => {
                    this.source = (response?.result ?? []);
                });
            }
        },
        created() {
            this.rebindSource();
            this.internalLoadKey = this.loadKey;
        }
    }
</script>