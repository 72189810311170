<template>
    <is-dialog :header="`${headerDisplay} ${model?.code}`" :visible="dialog.show" @is-confirm="onConfirm(model)" confirm="Send" confirmIcon="pi pi-fw pi-send" @is-cancel="onCancel" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}">
        <div class="confirmation-content p-fluid p-grid">
            <div class="p-col-12">
                <form-input id="toRecipient" label="TO" :required="true" :v$="v$" :value="model.toRecipient" @is-sync="model.toRecipient = $event.value?.toLowerCase()" v-lowercase />
                <form-input id="ccRecipient" label="CC" :v$="v$" :value="model.ccRecipient" @is-sync="model.ccRecipient = $event.value?.toLowerCase()" v-lowercase />
                <form-input id="subject" label="SUBJECT" :required="true" :v$="v$" :value="model.subject" @is-sync="model.subject = $event.value" />
            </div>
            <div class="p-col-12 p-grid" style="width:calc(100% + 1rem); margin: -2rem -0.5rem;">
                <div class="p-col-12">
                    <form-input id="body" type="editor" :hideLabel="true" :value="model.body" :rtl="false" :rows="13" :editorContentResize="true" @is-sync="model.body = $event.value" />
                    <form-boolean v-if="showDocumentToggle" id="includeShippingDocument" :value="model.includeShippingDocument" type="toggle" label="Include Other Shipping Documents? (Commercial Invoice, CN22/CN23, etc...)?" textPosition="left" @is-sync="model.includeShippingDocument = $event.value"></form-boolean>
                    <form-boolean v-if="model.isReturnable" id="includeReturnDocument" :value="model.includeReturnDocument" type="toggle" label="Include Other Return Documents? (Commercial Invoice, CN22/CN23, etc...)?" textPosition="left" @is-sync="model.includeReturnDocument = $event.value"></form-boolean>
                </div>
            </div>
        </div>
    </is-dialog>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    import { mapGetters } from 'vuex';
    
    export default {
        name: 'PrintFormTemplate',

        props: {
            formData: { type: Object },
            showDialog: { type: Boolean, default: false },
            headerDisplay: { type: String, default: "SEND LABEL NOTIFICATION" },
            alertMessage: { type: String, default: "Label Notification" },
            fileUrl: { type: String, default: null },
            showDocumentToggle: { type: Boolean, default: true },
            module: { type: String, default: 'consignment' },
            area: { type: String, default: 'tms' }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                dialog: {
                    show  : false
                },
                model: {  
                    code: null,
                    toRecipient: null,
                    ccRecipient: null,
                    subject: null,
                    body: null,
                    includeShippingDocument: false,
                    includeReturnDocument: false,
                }
            }
        },
        validations() {
            return {
                model: {
                }
            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                    
                });
            },

            'formData': {
                handler() {
                    this.$nextTick(() => {
                        this.model = this.$props.formData;
                    });
                }, deep: true
            },
            'showDialog': {
                handler() {
                    this.$nextTick(() => {
                        this.dialog.show = this.$props.showDialog;
                    });
                }, deep: true
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            cdnUrl() {
                return this.$config.config.endpoint.cdn;
            },
            tenantCode() {
                return ((this.$config.common.$tenant() ?? []).filter((x) => x.id === this.model?.tenantID ?? 1)[0]?.code ?? "").toLowerCase();
            },
        },
        methods: {
            onCancel() {
                this.dialog.show = false;
                this.$emit('is-cancel');
            },
            onConfirm() {
                this.$axios
                    .post((this.$config.config.endpoint.api + `/services/${this.$props.area}/${this.$props.module}/${this.model.mask}/send`), this.model)
                    .then(() => {
                        this.model = null;
                        this.$toast.add({ severity: 'success', summary: 'Alert Sent!', detail: 'The '+ this.$props.alertMessage + ' was successfully sent.', life: 3000 });
                        this.dialog.show = false;
                        this.$emit('is-confirm')
                    }).catch(() => {
                        this.$toast.add({ severity: 'error', summary: 'Alert Failed!', detail: 'The alert sent failed.', life: 3000 });
                    });
            }
        },
        async mounted() {
            this.model = this.$props.formData;
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>