<template>
    <cosmos-grid :id="`RateCardEffectiveDateList_${parentID}`" :source="`/services/facility/providercontracteffectivedate/byprovider/id/${parentID}`" sourceType="url" :source-paged="false" :multi-level="true" :searchFilters="['code', 'name']" :autoGenerate="false" :deleteDisplayFields="deleteDisplay" :showOperation="false" :forceRefresh="activity.refresh" @is-source="records = $event.data" :pageSize="3">
        <pv-grid-column field="effectiveDate" header="Effective Date">
            <template #body="slotProps">
                <div>{{ $filters.$shortDateUTC(slotProps.data?.effectiveDate) }}</div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="weightUnit" header="Weight Unit">
            <template #body="slotProps">
                <div>{{ $vm.$enums.weightunit.filter(x => x.id === slotProps.data?.weightUnit)[0]?.text ?? 'kg' }}</div>
            </template>
        </pv-grid-column>
        <!-- Operation Column -->
        <pv-grid-column field="mask" :headerStyle="`min-width: 11rem; max-width: 11rem; width: 11rem`" style="border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-d-flex" style="width: 2rem;">
                    <div class="p-col">
                        <pv-button icon="pi pi-download" iconPos="right" :loading="activity.loading" @click="onExport(`${slotProps.data.mask}`)" />
                    </div>
                    <div class="p-col">
                        <pv-button icon="pi pi-pencil" iconPos="right" class="p-button-info" @click="onEdit(slotProps.data)" />
                    </div>
                    <div class="p-col">
                        <pv-button :disabled="slotProps.data.recordStatus !== 0" icon="pi pi-trash" iconPos="right" class="p-button-danger" @click="onDelete(slotProps.data)" />
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <!-- Templates -->
        <template #toolbar>
            <div class="p-d-flex p-jc-between p-ai-center">
                <span>
                    <pv-button icon="pi pi-fw pi-plus" label="Add New Effective Date" style="margin-right: 5px;" @click="onCreate"></pv-button>
                </span>
                <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                    <i class="pi pi-search" />
                    <pv-input placeholder="Keyword Search" style="width: 100%;" />
                </span>
            </div>
        </template>
        <template #expansion="slotProps">
            <contract-product :parentID="slotProps.data.id"
                              :contractID="parentID"
                              :providerID="providerID"
                              :accountID="accountID"
                              :weightUnit="slotProps.data?.weightUnit"
                              :countryID="countryID"
                              :countryCode="countryCode"
                              :currencyCode="currencyCode"
                              :tradeType="tradeType" />
        </template>
    </cosmos-grid>
    <keep-alive>
        <is-dialog header="Delete Record?" :visible="dialog.delete" @is-confirm="onConfirmDelete(model.mask)" @is-cancel="onCancel()">
            <div class="confirmation-content p-d-flex">
                <div><i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" /></div>
                <div>
                    You are about to delete this record:
                    <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                        <tr v-for="data in deleteDisplayFields" :key="data.id">
                            <th style="text-align:left;">{{ $filters.titleize(data) }}</th>
                            <td>:</td>
                            <td> {{ $filters.toDateDisplay(this.model[data]) }}</td>
                        </tr>
                    </table>
                    Please be aware of the following:
                    <ul>
                        <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                        <li>For records that has activity, the record will only be disabled and archived.</li>
                        <li>Also deleting a record may be irreversible action.</li>
                    </ul>
                    Are you sure you still want to proceed?
                </div>
            </div>
        </is-dialog>
    </keep-alive>
    <keep-alive>
        <is-dialog :header="(model?.id ?? 0) === 0 ? 'Add New Record' : 'Edit Record'" style="width: 25vh; min-width: 600px;" :visible="dialog.edit" @is-confirm="onConfirmEdit(model.mask)" @is-cancel="onCancel()" :confirmDisabled="confirmDisabled">
            <div class="confirmation-content">
                <form-datetime-utc v-if="this.dialog.edit" id="effectiveDate" :value="model?.effectiveDate" type="date" @is-sync="model.effectiveDate = $event.value" :disabledDates="records.filter(x => x.id !== model?.id).map(m => new Date(m.effectiveDate))" :showButton="false" :required="true" />
                <form-lookup v-if="this.dialog.edit" source="weightunit" type="enum" id="weightUnit" :value="model?.weightUnit ?? 100" @is-sync="model.weightUnit = $event.value" :required="true" />
            </div>
        </is-dialog>
    </keep-alive>
</template>
<script>
    import productgrid from '@/components/facility/rates/contract/product-grid.vue';
    export default {
        name: 'RateCardEffectiveDateGrid',
        props: {
            parentID: { type: Number },
            providerID: { type: Number },
            accountID: { type: Number },
            tradeType: { type: Number },
            currencyID: { type: Number },
            currencyCode: { Type: String },
            countryID: { type: Number },
            countryCode: { Type: String }
        },
        components: {
            'contract-product': productgrid
        },
        data() {
            return {
                model: null,
                records: [],
                deleteDisplayFields: ["effectiveDate"],
                page: {
                    current: 0,
                    first: 0,
                    size: 10
                },
                dialog: {
                    delete: false,
                    edit: false
                },
                activity: {
                    loading: false,
                    refresh: false
                }
            }
        },
        watch: {
            source: { handler() { this.records = this.source; } }
        },
        computed: {
            dataSource() { return this.records; },
            confirmDisabled() { return (this.dataSource.filter(x => this.$filters.toDateDisplay(x.effectiveDate) === this.$filters.toDateDisplay(this.model?.effectiveDate) && x.weightUnit === this.model?.weightUnit) ?? []).length > 0; }
        },
        methods: {
            onCreate() {
                this.model = {
                    rateContractProviderID: this.parentID,
                    effectiveDate: new Date()
                }
                this.dialog.edit = true;
            },
            onEdit(e) {
                this.model = this.$filters.init(e);
                this.dialog.edit = true;
            },
            onDelete(e) {
                this.model = this.$filters.init(e);
                this.dialog.delete = true;
            },
            onCancel() {
                this.model = null;
                this.dialog.edit = false;
                this.dialog.delete = false;
            },
            onConfirmDelete(e) {
                this.$store.dispatch("providereffectivedate/deleteRecordByMask", e)
                    .then((response) => {
                        if (response.success) {
                            this.records = this.records.filter(x => x.mask !== e);
                            this.$toast.add({ severity: 'success', summary: 'Rate Card Effective Date', detail: "The record was deleted successfully!", life: 3000 });
                            this.onCancel();
                            this.activity.refresh = true;
                            this.$nextTick(() => { this.activity.refresh = false; });
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
            onConfirmEdit(e) {
                let index = this.dataSource.findIndex(x => x.mask === e);
                var model = this.$filters.init(this.model);
                model.effectiveDate = this.$moment.utc(this.model?.effectiveDate).local();
                if (index >= 0) {
                    this.$store.dispatch("providereffectivedate/updateRecord", model).then((response) => {
                        if (response.success) {
                            this.$toast.add({ severity: 'success', summary: 'Contract Effective Date', detail: "The record was updated successfully!", life: 3000 });
                            this.dialog.edit = false;
                            this.onCancel();
                            this.activity.refresh = true;
                            this.$nextTick(() => { this.activity.refresh = false; });
                        }
                        else if ((response.errors ?? []).length > 0)
                            this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: response.errors[0]?.message, life: 3000 });
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
                }
                else {
                    this.$store.dispatch("providereffectivedate/addRecord", model).then((response) => {
                        if (response.success) {
                            this.$toast.add({ severity: 'success', summary: 'Rate Card Effective Date', detail: "The record was added successfully!", life: 3000 });
                            this.onCancel();
                            this.activity.refresh = true;
                            this.$nextTick(() => { this.activity.refresh = false; });
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
                }
            },
            onExport(e) {
                this.activity.loading = true;
                this.$store.dispatch("providereffectivedate/getURL", { url: `/services/facility/providercontracteffectivedate/${e}/export` })
                    .then((response) => {
                        let user = this.$store.getters["auth/currentUser"];
                        if (response.success)
                            this.$toast.add({ severity: 'info', summary: 'Export Record', detail: "The exported file will be set to <b>" + user.email + "</b>!", life: 3000 });
                        else
                            this.$toast.add({ severity: 'error', summary: 'Failed To Export Record', detail: response.errors[0].message, life: 3000 });

                        this.activity.loading = false;
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Export Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        this.activity.loading = false;
                    });
            },
            getSource() {
                this.$store.dispatch("providercontract/getURL", { url: '/services/admin/serviceprovideraccount' })
                    .then((response) => {
                        if (response && response.success)
                            this.sources.accounts = response.result;
                    });
            }
        }
    }
</script>