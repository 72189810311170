<template>
    <pv-grid :id="id"
             :ref="id"
             :value="records.data"
             :totalRecords="records.total"
             :alwaysShowPaginator="true"
             :paginator="true"
             :paginatorPosition="'bottom'"
             :pageLinkSize="5"
             :rowsPerPageOptions="[5, 10, 25, 50, 100]"
             :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport'"
             :currentPageReportTemplate="'Showing {first} - {last} of {totalRecords} records'"
             :first="page.first"
             :rows="page.size"
             :lazy="true"
             :loading="activity.loading"
             :editMode="'row'"
             :dataKey="'id'"
             responsiveLayout="scroll"
             :scrollable="false"
             :scrollWidth="'flex'"
             :scrolHeight="'flex'"
             :responsive="'scroll'"
             :columnResizeMode="'expand'"
             :stateStorage="'session'"
             :stateKey="`dt-state-${id}`"
             autoLayout
             showGridlines
             stripedRows
             v-model:editingRows="editingRecords"
             @row-edit-init="onRowEdit"
             @row-edit-save="onRowSave"
             @row-edit-cancel="onRefresh"
             @page="onChangePage">
        <!-- Group Header Template -->
        <pv-grid-column field="request_CollectionDate" header="DATE" frozen="true">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="text-align: left;">
                        {{ $filters.toDateDisplay(slotProps.data?.collectionDate)}}
                    </div>
                </div>
            </template>
            <template #editor="{ data }">
                <div class="p-editor-full" style="display: block;">
                    {{ $filters.toDateDisplay(data?.collectionDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="warehouseName" header="WAREHOUSE">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="text-align: left;">
                        {{ slotProps.data.warehouseName}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="code" header="TRANSACTION ID">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="text-align: left;">
                        {{slotProps.data.code}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference" header="REFERENCE 1">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="text-align: left;">
                        {{slotProps.data.customerReference}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference2" header="REFERENCE 2">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="text-align: left;">
                        {{slotProps.data.customerReference2}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference3" header="REFERENCE 3">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="text-align: left;">
                        {{slotProps.data.customerReference3}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="carrierReference" header="SHIPPING INFO">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div v-if="slotProps.data?.customerReference" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                        <span><img v-tooltip.top="{value : `[${slotProps.data?.providerCode}] ${ slotProps.data?.serviceName}`}" style="vertical-align: middle; width: 35px; height: 20px; max-height: 20px; max-width: 35px; object-fit: contain;" :src="`/_assets/_integration/${$vm.$providers.filter((x) => x.code == slotProps.data?.providerCode)[0]?.mask}.png`" onerror="this.onerror=null; this.src = '/_assets/img/no-logo.png'" />{{slotProps.data.carrierReference}} </span>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="totalPackage" header="TOTAL PACKAGE" headerStyle="width: 10rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div>
                    {{$filters.formatNumber(slotProps.data.total_PackageCount ?? 0.00,0,slotProps.data.shippingContractCountryCode)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="totalWeight" header="WEIGHT (KG)" headerStyle="width: 10rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div>
                    {{$filters.formatNumber(slotProps.data.billableWeightKG ?? 0.00,3,slotProps.data.shippingContractCountryCode)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': (viewOnly) }]" field="invoiceBaseAmount" :header="`BASE COST`">
            <template #body="slotProps">
                <div style="text-align:right !important;">
                    {{ $filters.formatCurrency(slotProps.data['invoiceBaseAmount'] ?? 0.00, slotProps.data['contract_CurrencyCode'], globalization.country) }}
                </div>
            </template>
            <template #editor="{ data }">
                <div class="p-editor-full" style="display: block;">
                    <pv-numeric v-model="data['invoiceBaseAmount']" mode="currency" :currency="data['contract_CurrencyCode']" :min="0" :minFractionDigits="2" :maxFractionDigits="2" :showButtons="true" :format="true" :allowEmpty="false" autofocus style="width: 100%;" onfocus="this.select()" />
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': (viewOnly) }]" field="invoiceMarkUpRateType" :header="`MARK-UP TYPE`">
            <template #body="slotProps">
                {{ sources.rateTypes.filter(x => x.id === slotProps.data.invoiceMarkUpRateType ?? 100)[0]?.text }}
            </template>
            <template #editor="{ data }">
                <pv-dropdown :options="sources.rateTypes" v-model="data['invoiceMarkUpRateType']" optionLabel="text" optionValue="id" />
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': (viewOnly) }]" field="invoiceMarkUp" :header="`MARK UP`">
            <template #body="slotProps">
                <div style="text-align:right !important;">
                    <div v-if="slotProps.data['invoiceMarkUpRateType'] == 100">
                        {{ $filters.formatCurrency(slotProps.data['invoiceMarkUp'] ?? 0.00, slotProps.data['contract_CurrencyCode'], globalization.country) }}
                    </div>
                    <div v-else>
                        {{ $filters.formatNumber((slotProps.data['invoiceMarkUp'] ?? 0.00), 2, globalization.country) }}%
                    </div>
                </div>
            </template>
            <template #editor="{ data }">
                <div class="p-editor-full" style="display: block;">

                    <pv-numeric v-model="data['invoiceMarkUp']" :mode="(data.invoiceMarkUpRateType == 100) ? 'currency' : 'decimal'" :suffix="(data.invoiceMarkUpRateType == 100) ? '' : '%'" :min="0" :currency="data.['contract_CurrencyCode']" :minFractionDigits="2" :maxFractionDigits="2" :showButtons="true" :format="true" :allowEmpty="false" autofocus style="width: 100%;" onfocus="this.select()" />
                </div>
            </template>
        </pv-grid-column>

        <pv-grid-column :class="[{'p-field-hidden': (viewOnly) }]" field="invoiceDiscountRateType" :header="`DISCOUNT TYPE`">
            <template #body="slotProps">
                {{ sources.rateTypes.filter(x => x.id === slotProps.data.invoiceDiscountRateType ?? 100)[0]?.text }}
            </template>
            <template #editor="{ data }">
                <pv-dropdown :options="sources.rateTypes" v-model="data['invoiceDiscountRateType']" optionLabel="text" optionValue="id" />
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': (viewOnly) }]" field="invoiceDiscount" :header="`DISCOUNT`">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    <div v-if="slotProps.data['invoiceDiscountRateType'] == 100">
                        {{ $filters.formatCurrency(slotProps.data['invoiceDiscount'] ?? 0.00, slotProps.data['contract_CurrencyCode'], globalization.country) }}
                    </div>
                    <div v-else>
                        {{ $filters.formatNumber((slotProps.data['invoiceDiscount'] ?? 0.00), 2, globalization.country) }}%
                    </div>
                </div>
            </template>
            <template #editor="{ data }">
                <div class="p-editor-full" style="display: block;">
                    <pv-numeric v-model="data['invoiceDiscount']" :mode="(data.invoiceDiscountRateType == 100) ? 'currency' : 'decimal'" :suffix="(data.invoiceDiscountRateType == 100) ? '' : '%'" :min="0" :max="(data.invoiceDiscountRateType != 100) ? 100 : (data.invoiceBaseAmount + (((data['invoiceMarkUpRateType'] ?? 0) !== 100) ? (((data['invoiceMarkUp'] ?? 0) / 100) * (data['invoiceBaseAmount'] ?? 0)) :  (data['invoiceMarkUp'] ?? 0)))" :currency="data.['contract_CurrencyCode']" :minFractionDigits="2" :maxFractionDigits="2" :showButtons="true" :format="true" :allowEmpty="false" autofocus style="width: 100%;" onfocus="this.select()" />
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="invoiceAmount" :header="`SHIPPING COST`">
            <template #body="slotProps">
                <div v-if="(formData?.status ?? 0) > 100" style="width:100%;text-align:right !important;">
                    {{ $filters.formatCurrency(slotProps.data['invoiceAmount'] ?? 0, slotProps.data['contract_CurrencyCode'], globalization.country) }}
                </div>
                <div v-else style="width:100%;text-align:right !important;">
                    {{ $filters.formatCurrency(((slotProps.data['invoiceBaseAmount'] ?? 0) + (((slotProps.data['invoiceMarkUpRateType'] ?? 0) !== 100) ? (((slotProps.data['invoiceMarkUp'] ?? 0) / 100) * (slotProps.data['invoiceBaseAmount'] ?? 0)) :  (slotProps.data['invoiceMarkUp'] ?? 0))) -  (((slotProps.data['invoiceDiscountRateType'] ?? 0) !== 100) ? (((slotProps.data['invoiceDiscount'] ?? 0) / 100) * ((slotProps.data['invoiceBaseAmount'] ?? 0) + (((slotProps.data['invoiceMarkUpRateType'] ?? 0) !== 100) ? (((slotProps.data['invoiceMarkUp'] ?? 0) / 100) * (slotProps.data['invoiceBaseAmount'] ?? 0)) :  (slotProps.data['invoiceMarkUp'] ?? 0)))) :  (slotProps.data['invoiceDiscount'] ?? 0)), slotProps.data['contract_CurrencyCode'], globalization.country) }}
                </div>
            </template>
            <template #editor="{ data }">
                <div class="p-editor-full" style="display: block;">
                    <div style="width:100%;text-align:right !important;">
                        <pv-numeric v-show="false" :v-model="data['invoiceAmount'] = ((data['invoiceBaseAmount'] ?? 0) + (((data['invoiceMarkUpRateType'] ?? 0) !== 100) ? (((data['invoiceMarkUp'] ?? 0) / 100) * (data['invoiceBaseAmount'] ?? 0)) :  (data['invoiceMarkUp'] ?? 0))) -  (((data['invoiceDiscountRateType'] ?? 0) !== 100) ? (((data['invoiceDiscount'] ?? 0) / 100) * ((data['invoiceBaseAmount'] ?? 0) + (((data['invoiceMarkUpRateType'] ?? 0) !== 100) ? (((data['invoiceMarkUp'] ?? 0) / 100) * (data['invoiceBaseAmount'] ?? 0)) :  (data['invoiceMarkUp'] ?? 0))) ) :  (data['invoiceDiscount'] ?? 0))" mode="decimal" :min="0" :minFractionDigits="2" :maxFractionDigits="2" :showButtons="true" :format="true" :allowEmpty="false" autofocus style="width: 100%;" onfocus="this.select()" />
                        {{ $filters.formatCurrency(((data['invoiceBaseAmount'] ?? 0) + (((data['invoiceMarkUpRateType'] ?? 0) !== 100) ? (((data['invoiceMarkUp'] ?? 0) / 100) * (data['invoiceBaseAmount'] ?? 0)) :  (data['invoiceMarkUp'] ?? 0))) -  (((data['invoiceDiscountRateType'] ?? 0) !== 100) ? (((data['invoiceDiscount'] ?? 0) / 100) * ((data['invoiceBaseAmount'] ?? 0) + (((data['invoiceMarkUpRateType'] ?? 0) !== 100) ? (((data['invoiceMarkUp'] ?? 0) / 100) * (data['invoiceBaseAmount'] ?? 0)) :  (data['invoiceMarkUp'] ?? 0)))) :  (data['invoiceDiscount'] ?? 0)), data['contract_CurrencyCode'], globalization.country) }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="invoiceExchangeRate" :header="`EXCHANGE RATE`">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{ $filters.formatNumber((slotProps.data.invoiceExchangeRate ?? 1.00), 7, globalization.country) }}
                </div>
            </template>
            <template #editor="{ data }">
                <div class="p-editor-full" style="display: block;">
                    <div style="width:100%;text-align:right !important;">
                        <pv-numeric v-show="false" :v-model="data['invoiceExchangeRate'] = (data['invoiceExchangeRate'] ?? 1.00)" mode="decimal" :min="0" :minFractionDigits="2" :maxFractionDigits="2" :showButtons="true" :format="true" :allowEmpty="false" autofocus style="width: 100%;" onfocus="this.select()" />
                        {{ $filters.formatNumber((data['invoiceExchangeRate'] ?? 1.00), 7, globalization.country) }}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="invoiceAmount" :header="`SHIPPING COST ${currencySymbol}`">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    <div v-if="(formData?.status ?? 0) > 100" style="width:100%;text-align:right !important;">
                        {{ $filters.formatCurrency(((slotProps.data['invoiceAmount'] ?? 0) * (slotProps.data['invoiceExchangeRate'] ?? 1.00)), slotProps.data['forex_CurrencyCode'], globalization.country) }}
                    </div>
                    <div v-else style="width:100%;text-align:right !important;">
                        {{ $filters.formatCurrency((((slotProps.data['invoiceBaseAmount'] ?? 0) + (((slotProps.data['invoiceMarkUpRateType'] ?? 0) !== 100) ? (((slotProps.data['invoiceMarkUp'] ?? 0) / 100) * (slotProps.data['invoiceBaseAmount'] ?? 0)) :  (slotProps.data['invoiceMarkUp'] ?? 0))) -  (((slotProps.data['invoiceDiscountRateType'] ?? 0) !== 100) ? (((slotProps.data['invoiceDiscount'] ?? 0) / 100) * ((slotProps.data['invoiceBaseAmount'] ?? 0) + (((slotProps.data['invoiceMarkUpRateType'] ?? 0) !== 100) ? (((slotProps.data['invoiceMarkUp'] ?? 0) / 100) * (slotProps.data['invoiceBaseAmount'] ?? 0)) :  (slotProps.data['invoiceMarkUp'] ?? 0)))) :  (slotProps.data['invoiceDiscount'] ?? 0)))  * (slotProps.data['invoiceExchangeRate'] ?? 1.00), $vm.$currencies.filter(x => x.id ===formData.currencyID)[0]?.code, globalization.country) }}
                    </div>
                </div>
            </template>
            <template #editor="{ data }">
                <div class="p-editor-full" style="display: block;">
                    <div v-if="(formData?.status ?? 0) > 100" style="width:100%;text-align:right !important;">
                        {{ $filters.formatCurrency(((data['invoiceAmount'] ?? 0) * (data['invoiceExchangeRate'] ?? 1.00)), $vm.$currencies.filter(x => x.id === formData.currencyID)[0]?.code, globalization.country) }}
                    </div>
                    <div v-else style="width:100%;text-align:right !important;">
                        {{ $filters.formatCurrency((((data['invoiceBaseAmount'] ?? 0) + (((data['invoiceMarkUpRateType'] ?? 0) !== 100) ? (((data['invoiceMarkUp'] ?? 0) / 100) * (data['invoiceBaseAmount'] ?? 0)) :  (data['invoiceMarkUp'] ?? 0))) -  (((data['invoiceDiscountRateType'] ?? 0) !== 100) ? (((data['invoiceDiscount'] ?? 0) / 100) * ((data['invoiceBaseAmount'] ?? 0) + (((data['invoiceMarkUpRateType'] ?? 0) !== 100) ? (((data['invoiceMarkUp'] ?? 0) / 100) * (data['invoiceBaseAmount'] ?? 0)) :  (data['invoiceMarkUp'] ?? 0)))) :  (data['invoiceDiscount'] ?? 0)))  * (data['invoiceExchangeRate'] ?? 1.00), $vm.$currencies.filter(x => x.id ===formData.currencyID)[0]?.code, globalization.country) }}
                    </div>
                </div>
            </template>
        </pv-grid-column>

        <!-- Inline Edit Command -->
        <pv-grid-column :class="[{'p-field-hidden': (viewOnly) }]" :rowEditor="true" class="p-operator-save" headerStyle="max-width: 7rem; min-width:3rem" style="max-width: 7rem !important; min-width:3rem !important; width: 3rem; text-align: right; border-right: 0;" :frozen="true" />
        <!-- Operation Column -->
        <pv-grid-column :class="[{'p-field-hidden': (viewOnly) },{'p-operator-cancel' : 'true'}] " :rowEditor="true" :headerStyle="`min-width: 3rem; max-width: 3rem; width: 3rem`" style="width: 3rem; border-left: 0; text-align: center; object-position: center; padding: 0 5px; " :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <pv-button :disabled="records.total < 2" icon="pi pi-trash" iconPos="right" class="p-button-danger" @click="onDelete(`${slotProps.data.id}`)" />
            </template>
        </pv-grid-column>
        <!-- Table Header Template -->
        <template #header>
            <slot name="toolbar">
                <div class="p-d-flex p-jc-between p-ai-center">
                    <span>
                        <pv-button v-if="!viewOnly" icon="pi pi-fw pi-plus" :label="`Add Consignments`" style="margin-right: 5px;" @click="onCreate"></pv-button>
                        <span v-else class="is-text-danger">The consignment table is linked and not editable</span>
                    </span>
                    <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                        <i class="pi pi-search" />
                        <pv-input placeholder="Keyword Search" style="width: 100%;" @keyup="rei" />
                    </span>
                </div>
            </slot>
        </template>
        <!-- Empty Record Template -->
        <template #empty>
            No records found.
        </template>
        <!-- Loading Template -->
        <template #loading>
            Retrieving records from database. Please wait...
        </template>
        <!-- Pagination -->
        <template #paginatorLeft>
            <pv-button type="button" icon="pi pi-download" class="p-button-text" @click="onDownload" />
        </template>
        <template #paginatorRight>
            <pv-button type="button" icon="pi pi-refresh" class="p-button-text" @click="onRefresh" />
        </template>
    </pv-grid>

    <is-dialog header="Remove Consignment?" :visible="dialog.delete" @is-confirm="onConfirmDelete(model)" @is-cancel="onCancel()">
        <div class="confirmation-content p-d-flex">
            <div><i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" /></div>
            <div>
                You are about to remove this record to invoice {{this.formData.name}} :
                <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                    <tr v-for="data in deleteDisplayFields" :key="data.id">
                        <th style="text-align:left;">{{$filters.titleize(data.replace('name','Reference').replace('code','Transaction ID'))}}</th>
                        <td>:</td>
                        <td> {{ this.model[data] }}</td>
                    </tr>
                </table>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
    <is-dialog :header="(model?.id ?? 0) === 0 ? 'Add Consignments' : 'Edit Record'" :visible="dialog.edit" confirm="Add Consignment" @is-confirm="onConfirmEdit()" @is-cancel="onCancel()" :confirmDisabled="(consignmentToProcess ?? []).length == 0" :breakpoints="{'200px': '50vw', '340px': '50vw'}" :style="{width: '75vw'}">
        <div class="card">
            <p><i class="fas fa-info-square fa-fw fa-2x is-text-info"></i> <span class="is-text-info">Please be aware that the page will only display the result after <b>Apply Filter</b> button is clicked.</span></p>
            <div class="p-d-flex p-grid">
                <div class="p-col-12 p-md-3">
                    <form-lookup id="warehouseID" label="Warehouse" :source="warehouses" :value="lookupModel.warehouseID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onWarehouseChange($event)" />
                    <form-lookup id="customerID" label="Customer" :source="customers" :value="isCustomer ? customers[0]?.id : lookupModel.customerID " :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onCustomerChange($event)" :disabled="true" disabledType="object" />
                </div>
                <div class="p-col-12 p-md-3">
                    <form-lookup id="serviceProviderID" label="Carrier" :source="providers" :value="lookupModel.serviceProviderID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onProviderChange($event)" />
                    <form-lookup id="serviceProviderAccountID" disabledType="object" :disabled="providerAccounts.length === 0" label="Account" :source="providerAccounts" :value="lookupModel.serviceProviderAccountID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onAccountChange($event)" />
                </div>
                <div class="p-col-12 p-md-3">
                    <form-datetime id="collectionDateFrom" :value="lookupModel.collectionDateFromValue" type="date" @is-sync="onCollectionDateFrom($event)" label="Collection Date From" :v$="v$"></form-datetime>
                    <form-datetime id="collectionDateTo" :value="lookupModel.collectionDateToValue" type="date" @is-sync="onCollectionDateTo($event)" label="Collection Date To" :v$="v$"></form-datetime>
                </div>
                <div class="p-col-12 p-md-3">
                    <form-lookup id="statusFilter" label="Status" source="consignmentflagstatustype" :sorted="false" type="flag" selection="multiple" @is-sync="onStatusChange($event)" :value="lookupModel.statusFilter ?? 32832" :v$="v$" />
                </div>
            </div>
            <div class="p-d-flex p-grid p-col-12 p-jc-end" style="margin-top: -0.5rem;">
                <pv-button :class="[{ 'p-mr-2': true}, {'p-button-warning': v$.$anyDirty || activity.dirty }]" label="Apply Filter" :disabled="!activity.dirty || activity.export || activity.binding" icon="pi pi-filter" @click="onFilter()"></pv-button>
            </div>
            <div class="p-grid" style="margin-top: -0.5rem;">
                <div class="p-col-12">
                    <pv-tab style="margin: 0 -14px !important;">
                        <pv-tabpanel header="RESULT">
                            <consignment-grid :formData="lookupModel" :filterKey="filterKey" :excludeWithInvoice="true" :isAdmin="isAdmin" @is-sync="onUpdate($event)" @is-selected="onConsignmentSelected($event)" @is-source="onConsignments($event)"></consignment-grid>
                        </pv-tabpanel>
                    </pv-tab>
                </div>
            </div>
        </div>
    </is-dialog>
</template>
<script>
    import { mapGetters } from 'vuex';
    import { useVuelidate } from '@vuelidate/core';
    //import reportGrid from '@/components/facility/invoice/consignment-grid.vue';
    import reportGrid from '@/components/facility/invoice/v2/consignment-grid.vue';
    export default {
        name: 'additionalFeeGrid',
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'consignment-grid': reportGrid
        },
        props: {
            formData: { type: Object }
        },
        data() {
            return {
                editingRecords: [],
                records: { data: [], total: 0 },
                source: [],
                model: null,
                activity: {
                    loading: true,
                    export: false,
                    binding: false,
                    dirty: true
                },
                dialog: { delete: false, edit: false },
                page: {
                    current: 0,
                    first: 0,
                    size: 10
                },
                type: -1,
                filter: {},
                columns: [],
                deleteDisplayFields: ["code", "name"],
                searchFields: ["name"],
                selectedItems: [],
                selectedReset: false,
                modelState: null,
                initialBind: false,
                sources: {
                    rateTypes: [{ id: 100, text: 'Flat Rate' }, { id: 200, text: 'Percentile' }],
                    accounts: []
                },
                lookupModel: {
                    warehouseID: 0,
                    customerID: 0,
                    serviceProviderID: 0,
                    serviceProviderAccountID: 0,
                    serviceProviderAccountCode: null,
                    collectionDateFrom: null,
                    collectionDateFromValue: null,
                    collectionDateTo: null,
                    collectionDateToValue: null,
                    includeArchived: false,
                    reportType: 200,
                    statusFilter: null,
                },
                keys: null,
                session: {
                    tenant: null,
                    environment: null,
                    warehouse: null,
                    customer: null,
                    currentUser: null,
                    executiveUser: null
                },
                reportScopes: null,
                filterKey: null,
                accountDS: [],
                customerAccountDS: [],
                consignments: {
                    selected: []
                }
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            scopeCustomerDS() {
                return this.$config.common.$customer(this.user?.environmentID ?? -100, this.$vm.$appSession.warehouse.id ?? this.user.warehouseID, this.user);
            },
            scopeWarehouseDS() {
                return this.$config.common.$warehouse(this.user?.environmentID ?? -100, this.user);
            },
            serviceProviderDS() {
                let ds = [];
                if (this.lookupModel.customerID > 0) {
                    this.customerAccountDS?.forEach((x) => {
                        let provider = this.$vm.$providers.filter(y => y.id === x.serviceProvider.id)[0];
                        if (ds.length === 0) {
                            ds.push({ id: provider.id, name: '[' + provider.code + '] ' + provider.name });
                        }
                        else if (ds?.findIndex(y => y.id === x.serviceProvider.id) === -1) {
                            ds.push({ id: provider.id, name: '[' + provider.code + '] ' + provider.name });
                        }
                    });
                }
                else {
                    this.serviceAccounts?.data?.forEach((x) => {
                        let provider = this.$vm.$providers.filter(y => y.id === x.serviceProviderID)[0];
                        if (ds.length === 0) {
                            ds.push({ id: provider.id, name: '[' + provider.code + '] ' + provider.name });
                        }
                        else if (ds?.findIndex(y => y.id === x.serviceProviderID) === -1) {
                            ds.push({ id: provider.id, name: '[' + provider.code + '] ' + provider.name });
                        }

                    });
                }
                return ds;
            },
            dataSource() {
                return (this.records ?? { data: [], total: 0 }).data;
            },
            globalization() { return { country: this.shipperCountry?.code ?? "GB", currency: this.currencyCode ?? "USD" } },
            shipper() {
                return this.$config.common.$environment()?.filter(x => x.id === this.formData.environmentID)[0];
            },
            shipperCountry() { return this.$vm.$countries.filter(x => x.id === this.shipper.countryID)[0]?.code },
            currencySymbol() {
                return this.$vm.$currencies.filter(x => x.id === this.formData?.currencyID)[0]?.symbolText;
            },
            currencyCode() {
                return this.$vm.$currencies.filter(x => x.id === this.formData?.currencyID)[0]?.code
            },
            consignmentToProcess() {
                let consignmentCodes = [];
                if ((this.consignments.selected ?? []).length > 0 && (this.consignments.grid ?? []).length > 0) {
                    for (let i = 0; i < (this.consignments.selected ?? []).length; i++) {
                        if (((this.consignments.grid ?? []).filter(x => x.id === this.consignments.selected[i].id).length ?? 0) > 0)
                            consignmentCodes.push(this.consignments.selected[i].code ?? "");
                    }
                }
                return consignmentCodes;
            },
            viewOnly() { return this.formData?.status >= 200; }

            , warehouses() {
                return (this.reportScopes?.warehouses ?? [])
                    .filter(wh => (this.lookupModel.customerID ?? 0) === 0 || ((this.lookupModel.customerID ?? 0) > 0 && (wh.customers ?? []).filter(cust => cust === (this.lookupModel.customerID ?? 0)).length > 0)) ?? [];
            },
            customers() { return (this.reportScopes?.customers ?? []).filter(cust => (this.lookupModel.warehouseID ?? 0) === 0 || ((this.lookupModel.warehouseID ?? 0) > 0 && (cust.warehouses ?? []).filter(wh => wh === (this.lookupModel.warehouseID ?? 0)).length > 0)) ?? []; },
            providers() { return (this.reportScopes?.providers ?? []).filter(provider => ((this.accounts ?? []).map(acct => acct.serviceProviderID) ?? []).filter(acct => acct == provider.id).length > 0) ?? []; },
            accounts() {
                return (((((this.reportScopes?.accounts ?? [])
                    .filter(acct => (this.lookupModel.customerID ?? 0) === 0 || ((this.lookupModel.customerID ?? 0) > 0 && (acct.customers ?? []).filter(cust => cust === (this.lookupModel.customerID ?? 0)).length > 0)) ?? [])
                    .filter(acct => (this.lookupModel.warehouseID ?? 0) === 0 || ((this.lookupModel.warehouseID ?? 0) > 0 && ((acct.postingLocations ?? []).length === 0 || (acct.postingLocations ?? []).filter(postLoc => postLoc == this.lookupModel.warehouseID).length > 0))) ?? [])
                    .filter(acct => (this.lookupModel.warehouseID ?? 0) === 0 || ((this.lookupModel.warehouseID ?? 0) > 0 && (acct.customers ?? []).filter(acctcust => ((this.customers ?? []).map(z => z.id) ?? []).filter(cust => cust === acctcust).length > 0).length > 0)) ?? [])
                    .filter(acct => (this.lookupModel.serviceProviderID ?? 0) === 0 || ((this.lookupModel.serviceProviderID ?? 0) > 0 && acct.serviceProviderID === (this.lookupModel.serviceProviderID ?? 0))) ?? []);
            },
            providerAccounts() { return this.sources?.accounts ?? []; },
            isCustomer() {
                return [5, 6, 7].filter(y => y === this.user.userType).length > 0;
            },
            isAdmin() {
                return [0, 2, 3].filter(y => y === this.user.userType).length > 0;
            },
            apiData() {
                return {
                    ServiceHeader: {
                        Username: "TEMP",
                        Password: "TESTESTEDET",
                        ClientID: "TESTTESTTESTTESTTEST",
                        ClientSecret: "TESTEDREDREDTETEDEDETEREDEDEREDE"
                    },
                    Request: {
                        DC: null,
                        Customer: null,
                        Account: null,
                        ScopeDetail: {
                            IncludeArchive: this.formData?.includeArchived,
                            ExcludeWithInvoice: false,
                            Statuses: 0,
                            InvoiceID: this.formData?.id
                        }
                    }
                }
            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            'formData.id': {
                handler() {
                    this.$nextTick(() => {
                        this.onRefresh();
                    });
                }, deep: true
            },
            'records.data': {
                handler() {
                    this.$nextTick(() => {
                        this.$emit("is-sync", { consignments: this.records.data });
                    });
                }, deep: true
            },
            'lookupModel': {
                handler() {
                    if (this.filterKey || this.v$.$anyDirty)
                        this.$nextTick(() => { this.activity.dirty = true; });
                }, deep: true
            }
        },
        methods: {
            onCreate() {
                let startDate = new Date();
                startDate.setDate(1);
                this.lookupModel = {
                    warehouseID: null,
                    customerID: this.formData.customerID,
                    collectionDateFromValue: startDate,
                    collectionDateFrom: startDate,
                    collectionDateToValue: new Date(),
                    collectionDateTo: new Date(),
                    serviceProviderID: null,
                    serviceProviderAccountID: null,
                    serviceProviderAccountCode: null,
                    statusFilter: null
                };
                this.dialog.edit = true;
            },
            onEdit(e) {
                this.model = this.$filters.init(e);
                this.dialog.edit = true;
            },
            onDelete(e) {
                this.model = this.$filters.init(((this.records ?? []).data ?? []).filter(x => x.id == e)[0]);
                this.dialog.delete = true;
            },
            onCancel() {
                this.model = null;
                this.dialog.edit = false;
                this.dialog.delete = false;
            },
            onConfirmDelete(e) {
                this.$store.dispatch("invoice/putURL", {
                    url: "/services/facility/invoice/" + this.formData.mask + "/consignment",
                    payload: e
                })
                    .then((response) => {
                        if (response === undefined || (response && response.success)) {
                            this.records.data = this.records.data.filter(x => x.mask !== e.mask);
                            this.records.total = (this.records.data ?? 0).length
                            //this.$toast.add({ severity: 'success', summary: 'Additional Fee', detail: "The record was deleted successfully!", life: 3000 });
                            this.onCancel();
                            this.activity.refresh = true;
                            this.$nextTick(() => { this.activity.refresh = false });
                            this.onRefresh();
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
            onConfirmEdit() {

                if ((this.consignments.selected ?? []).length > 0) {
                    this.$axios.post(this.$config.config.endpoint.api + `/services/facility/invoice/` + this.formData.mask + `/consignment`.replaceAll("//", "/"), (this.consignmentToProcess ?? []))
                        .then((result) => {
                            let response = result?.data;
                            if (response?.success) {
                                let consignments = response?.result ?? [];
                                for (let i = 0; i < (consignments).length; i++) {
                                    this.records.data.push(consignments[i]);
                                }
                                this.records.total = (this.records.data ?? []).length;
                                this.onCancel();
                                this.activity.refresh = true;
                                this.$nextTick(() => { this.activity.refresh = false });
                                this.onRefresh();
                                this.consignments.selected = [];
                            }
                            else if ((response.errors ?? []).length > 0) {
                                this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: response.errors[0]?.message, life: 3000 });
                            }
                        })
                        .catch(ex => {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        });
                }

            },
            onChangePage(e) {
                this.page.current = e.page;
                this.page.first = e.first;
                this.page.size = e.rows;
                this.onRefresh();
            },
            onRefresh() {
                this.activity.loading = true;
                //this.records = { data: [], total: 0 };
                this.getInvoiceConsignmentSource().then(() => {
                    this.$nextTick(() => { this.activity.loading = false; });
                });
            },
            // Row Editting Methods
            //onEdit(e) { this.editingRows.push(e); },
            onRowEdit() {
                setTimeout(() => {
                    const kbButtons = document.getElementsByTagName("button");
                    kbButtons.forEach(elem => {
                        elem.setAttribute('tabindex', '-1')
                    });
                }, 100);
            },
            onRowSave(e) {
                let index = e.index;
                let model = e.data;
                this.$store.dispatch("invoice/postURL", {
                    url: "/services/facility/invoice/" + this.formData.mask + "/consignmentrate",
                    payload: model
                }).then((response) => {
                    if (response.success) {
                        //this.$toast.add({ severity: 'success', summary: `Additonal Fees Updated`, detail: "The record was updated successfully!", life: 3000 });
                        //this.source[index] = this.$filters.init(response.result);
                        this.records.data[index] = this.$filters.init(response.result);
                    }
                    else if ((response.errors ?? []).length > 0) {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: response.errors[0]?.message, life: 3000 });
                    }
                })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
            async getInvoiceConsignmentSource() {
                this.activity.loading = true;
                let dataSource = { total: 0, data: [] };
                if ((this.formData?.id ?? 0 > 0) && (this.records?.data ?? []).length <= 0) {
                    this.$axios.post(this.$config.config.endpoint.api + `/services/facility/invoice/v2/consignment`.replaceAll("//", "/"), this.apiData)
                        .then((response) => {
                            dataSource = { data: response?.data?.result ?? [], total: (response?.data?.result ?? []).length };
                            //this.source = response?.data?.result ?? [];
                            //dataSource = { total: (this.source ?? []).length, data: [...(this.source ?? [])]?.slice(this.page.first) };
                            this.activity.loading = false;
                            this.records = dataSource;
                        })
                        .catch(() => {
                            //this.source = [];
                            dataSource = { total: 0, data: [] }
                            this.activity.loading = false;
                            this.records = dataSource;
                        });
                }
                else {
                    dataSource = { data: this.record?.data ?? [], total: (this?.record?.data ?? []).length };
                    //this.source = this.record?.data ?? [];
                    //dataSource = { total: (this.source ?? []).length, data: [...(this.source ?? [])]?.slice(this.page.first) };
                    this.activity.loading = false;
                }
                return dataSource;
            },
            onConsignmentSelected(e) {
                this.consignments.selected = e ?? [];
            },
            onConsignments(e) {
                this.consignments.grid = e?.consignments ?? this.consignments.grid;
            },
            onCollectionDateFrom(e) {
                this.$nextTick(() => {
                    this.lookupModel.collectionDateFromValue = e.value;
                    this.lookupModel.collectionDateFrom = (e.record.data ?? new Date());
                });
            },
            onCollectionDateTo(e) {
                this.$nextTick(() => {
                    this.lookupModel.collectionDateToValue = e.value;
                    this.lookupModel.collectionDateTo = (e.record.data ?? new Date());
                });
            },
            onProvider(e) {
                this.lookupModel.serviceProviderID = e?.value;
                this.getAccounts();
            },
            onAccount(e) {
                this.lookupModel.serviceProviderAccountID = e?.value;
                this.lookupModel.serviceProviderAccountCode = e?.record?.code;
            },
            onCustomer(e) {
                this.lookupModel.customerID = e?.value;
                this.getCustomerAccounts();
            },
            async getProviderAccounts() {
                await this.$store.dispatch("provideraccount/getAll", { sort: null, where: null }).then(() => {
                });
            },
            async getAccounts() {
                if (this.lookupModel.serviceProviderID > 0) {
                    await this.$store.dispatch("provideraccount/getURL", { url: `/services/admin/serviceprovideraccount/byprovider/id/${this.lookupModel.serviceProviderID}` }).then((response) => {
                        this.accountDS = response.result;
                    });
                }
                else {
                    this.accountDS = [];
                }
            },
            async getCustomerAccounts() {
                if (this.lookupModel.customerID > 0) {
                    await this.$store.dispatch("provideraccountcustomer/getURL", { url: `/services/admin/serviceprovideraccountcustomer/handlerbyaccount/id/${this.lookupModel.customerID}` }).then((response) => {
                        this.customerAccountDS = response.result;
                    });
                }
                else {
                    this.customerAccountDS = [];
                }
            }


            //--NEW IMPLEMENTATION
            , onFilter() {
                this.$nextTick(() => { this.filterKey = Math.random(); });
                this.v$.$reset();
                this.$nextTick(() => { this.activity.dirty = false; });
            }
            , onUpdate(e) {
                this.$nextTick(() => { this.activity.binding = e.loading; });
                this.$nextTick(() => { this.activity.export = e.download; });
            }
            , getReportScopes() {
                this.reportScopes = null;
                this.$store.dispatch("provideraccount/getURL", { url: `/services/settings/user/reportscope` })
                    .then((response) => {
                        this.reportScopes = response;
                        this.sources.warehouses = response?.warehouses;
                        this.sources.customers = response?.customers;
                        this.sources.providers = response?.providers;
                        this.sources.accounts = response?.accounts;
                    });
            },
            onWarehouseChange(e) {
                this.lookupModel.warehouseID = e?.value;
                this.getDropdownSources();
            },
            onCustomerChange(e) {
                this.lookupModel.customerID = e?.value;
                this.getDropdownSources();
            },
            onProviderChange(e) {
                this.lookupModel.serviceProviderID = e?.value;
                this.getDropdownSources();
            },
            onAccountChange(e) {
                this.lookupModel.serviceProviderAccountID = e?.value;
                this.lookupModel.serviceProviderAccountCode = e?.record?.code;
            },
            onStatusChange(e) {
                this.lookupModel.statusFilter = e?.value;
            },
            getDropdownSources() {
                this.sources.accounts = (this.accounts ?? []).filter(x => this.lookupModel.serviceProviderID === 0 || (this.lookupModel.serviceProviderID > 0 && this.lookupModel.serviceProviderID === x.serviceProviderID)) ?? [];
            }
        },
        created() {
            this.loading = false;

        },
        mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            this.loading = false;
            this.getInvoiceConsignmentSource();
            this.getCustomerAccounts();
            this.getReportScopes();
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-paginator .p-paginator-pages button.p-paginator-page.p-paginator-element {
        border: 1px solid var(--border-color-grid);
        margin: 0;
        border-right: 0;
        border-radius: 0 !important;
    }

    .p-paginator .p-paginator-next.p-paginator-element {
        border: 1px solid var(--border-color-grid);
        border-right: 0;
        border-radius: 0;
        margin: 0;
    }

    .p-paginator .p-paginator-prev.p-paginator-element {
        border: 1px solid var(--border-color-grid);
        border-radius: 0;
        border-right: 0;
        margin: 0;
    }

    .p-paginator .p-paginator-last.p-paginator-element {
        border: 1px solid var(--border-color-grid);
        border-radius: 0 5px 5px 0;
        margin: 0;
    }

    .p-paginator .p-paginator-first.p-paginator-element {
        border: 1px solid var(--border-color-grid);
        border-right: 0;
        border-radius: 5px 0 0 5px;
        margin-right: 0;
    }

    .p-paginator .p-highlight.p-paginator-element, .p-paginator .p-paginator-element:hover {
        border: 1px solid var(--border-color-grid) !important;
    }

    .p-paginator.p-component {
        padding: 2px;
    }

    .p-paginator-page.p-paginator-element {
        border-radius: 4px !important;
    }

    .p-paginator .p-paginator-current {
        margin-left: auto;
    }

    .p-paginator .p-paginator-right-content {
        margin-left: 0;
        height: 3rem !important;
        width: 3rem !important;
        line-height: 2.75rem;
    }

    .p-paginator .p-paginator-left-content {
        margin-right: 0;
        margin-left: 3px;
        height: 3rem !important;
        width: 3rem !important;
        line-height: 2.75rem;
    }

    .p-field-hidden {
        visibility: hidden;
        display: none;
    }
</style>
