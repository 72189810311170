<template>
    <form-record :model="model" :title="model.reference1" :modelState="v$" @is-reset="resetRecord" :viewOnly="true" :showSend="false" :showClone="false">
        <div class="p-grid p-col-12">
            <div :class="`p-col-12 p-md-4`">
                <h6>ORDER INFO</h6>
                <table style="width:100%; margin: -5px auto auto 5px;">
                    <tr>
                        <td style="font-weight: bold; width: 40%;">Consignment ID</td>
                        <td class="p-code-text" style="font-size: 0.9rem;"> : <router-link style="font-size: 1rem; font-weight:700;" :to="{ name: 'consignment-edit', params: { mask: model.consignmentMask }}">{{model.transactionID}}</router-link>
                            
                        </td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold; width: 40%;">Reference 1</td>
                        <td class="p-code-text">: {{ model.reference1 }}</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold; width: 40%;">Reference 2</td>
                        <td class="p-code-text">: {{ model.reference2 }}</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold; width: 40%;">Reference 3</td>
                        <td class="p-code-text">: {{ model.reference3 }}</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold; width: 40%;">Reference 4</td>
                        <td class="p-code-text">: {{ model.reference4 }}</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold; width: 40%;">Reference 5</td>
                        <td class="p-code-text">: {{ model.reference5 }}</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold; width: 40%;">Reference 6</td>
                        <td class="p-code-text">: {{ model.reference6 }}</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold; width: 40%;">Reference 7</td>
                        <td class="p-code-text">: {{ model.reference7 }}</td>
                    </tr>
                    <tr v-if="model?.serviceType">
                        <th colspan="2" style=" text-align: center; background: var(--surface-d); padding: 0.25rem;">INTERNATIONAL FORMS</th>
                    </tr>
                    <tr>
                        <td style="font-weight: bold">Incoterms</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: {{ model.incoterms ?? '[DAP] Delivered At Place' }}</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold">Customs Value</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: <div style="text-align: right; display: inline-block; width: calc(100% - 1.5rem)">{{ $filters.formatCurrency(model.customsValue ?? 0.00, $vm.$currencies.filter(x => x.id === model.customsValue_CurrencyID)[0]?.code ?? "GBP", null, 2)}}</div></td>
                    </tr>

                    <tr v-if="model?.customsDescription">
                        <td style="font-weight: bold">Customs Description</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: {{ model.customsDescription }}</td>
                    </tr>
                    <tr v-if="model?.cpcNumber">
                        <td style="font-weight: bold">CPC Number</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: {{ model.cpcNumber }}</td>
                    </tr>
                    <tr v-if="model?.lpn">
                        <td style="font-weight: bold">LPN</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: {{ model.lpn }}</td>
                    </tr>
                    <tr v-if="model?.serviceType">
                        <th colspan="2" style=" text-align: center; background: var(--surface-d); padding: 0.25rem;">ADDITIONAL DETAILS</th>
                    </tr>
                    <tr v-if="model?.milestone_ReturnDate">
                        <td style="font-weight: bold">Return Date</td>
                        <td class="p-code-text">: {{ $filters.toDateFullTimeDisplay(model.milestone_ReturnDate) }}</td>
                    </tr>
                    <tr v-if="model?.milestone_ReturnReceiveDate">
                        <td style="font-weight: bold">Receive Date</td>
                        <td class="p-code-text">: {{ $filters.toDateFullTimeDisplay(model.milestone_ReturnReceiveDate) }}</td>
                    </tr>
                    <tr v-if="model?.category">
                        <td style="font-weight: bold">Category</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: {{ model.category }}</td>
                    </tr>
                    <tr v-if="model?.itemDescription">
                        <td style="font-weight: bold">Item Description</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: {{ model.itemDescription }}</td>
                    </tr>
                    <tr v-if="model?.returnsGoodsRelief">
                        <td style="font-weight: bold">Goods Relief</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: {{ model.returnsGoodsRelief }}</td>
                    </tr>
                    <tr v-if="model?.cancellationReason">
                        <td style="font-weight: bold">Cancellation Reason</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: {{ model.cancellationReason }}</td>
                    </tr>

                </table>
            </div>
            <div :class="`p-col-12 p-md-4`">
                <h6>CARRIER INFO</h6>
                <table style="width:100%; margin: -5px auto auto 5px;">
                    <tr v-if="model?.serviceType">
                        <th colspan="2" style=" text-align: center; background: var(--surface-d); padding: 0.25rem;">SERVICE PROVIDER</th>
                    </tr>
                    <tr>
                        <td style="font-weight: bold; width: 40%;">Third Party Provider</td>
                        <td class="p-code-text">: {{ model.serviceProviderName }}</td>
                    </tr>
                    <tr v-if="model?.serviceType">
                        <td style="font-weight: bold">Service Type</td>
                        <td class="p-code-text">: {{ model.serviceType }}</td>
                    </tr>
                    <tr v-if="model?.orderAction">
                        <td style="font-weight: bold">Order Action</td>
                        <td class="p-code-text">: {{ model.orderAction }}</td>
                    </tr>
                    <tr>
                        <th colspan="2" style=" text-align: center; background: var(--surface-d); padding: 0.25rem;">RETURN CARRIER</th>
                    </tr>
                    <tr>
                        <td style="font-weight: bold">Carrier</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: {{ model.carrier }}</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold">Carrier Service</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: {{ model.carrierService }}</td>
                    </tr>
                    <tr v-if="model?.carrierReference">
                        <td style="font-weight: bold">Carrier Reference</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: <a :href="model.trackingURL" target="_blank" class="p-code-text" style="font-size: 0.9rem; font-weight:bold;">{{ model.carrierReference }}</a></td>
                    </tr>
                    <tr v-if="model?.collectionReference">
                        <th colspan="2" style=" text-align: center; background: var(--surface-d); padding: 0.25rem;">COLLECTION</th>
                    </tr>
                    <tr v-if="model?.collectionReference">
                        <td style="font-weight: bold">Collection Reference</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: {{model.collectionReference}}</td>
                    </tr>
                    <tr v-if="model?.collectionDate">
                        <td style="font-weight: bold">Collection Date</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: {{ $filters.toDateOnly(model?.collectionDate)}}</td>
                    </tr>
                    <tr v-if="model?.awbNumber">
                        <td style="font-weight: bold">AWB Number</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: {{model.awbNumber}}</td>
                    </tr>
                    <tr v-if="model?.lastActivityDate">
                        <th colspan="2" style=" text-align: center; background: var(--surface-d); padding: 0.25rem;">COSTS AND FEES</th>
                    </tr>
                    <tr>
                        <td style="font-weight: bold">Cash On Delivery</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: {{ model.codFlag ? 'YES' : 'NO' }}</td>
                    </tr>
                    <tr v-if="model?.shippingPrice_CurrencyID">
                        <td style="font-weight: bold">Shipping Price</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: <div style="text-align: right; display: inline-block; width: calc(100% - 1.5rem)">{{ $filters.formatCurrency(model.shippingPrice, $vm.$currencies.filter(x => x.id === model.shippingPrice_CurrencyID)[0]?.code, null, 2)}}</div></td>
                    </tr>
                    <tr v-if="model?.returnPrice_CurrencyID">
                        <td style="font-weight: bold">Return Price</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: <div style="text-align: right; display: inline-block; width: calc(100% - 1.5rem)">{{ $filters.formatCurrency(model.returnPrice, $vm.$currencies.filter(x => x.id === model.returnPrice_CurrencyID)[0]?.code, null, 2)}}</div></td>
                    </tr>
                    <tr v-if="model?.returnCost_CurrencyID">
                        <td style="font-weight: bold">Return Cost</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: <div style="text-align: right; display: inline-block; width: calc(100% - 1.5rem)">{{ $filters.formatCurrency(model.returnCost, $vm.$currencies.filter(x => x.id === model.returnCost_CurrencyID)[0]?.code, null, 2)}}</div></td>
                    </tr>
                    <tr v-if="model?.labelCost_CurrencyID">
                        <td style="font-weight: bold">Label Cost</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: <div style="text-align: right; display: inline-block; width: calc(100% - 1.5rem)">{{ $filters.formatCurrency(model.labelCost, $vm.$currencies.filter(x => x.id === model.labelCost_CurrencyID)[0]?.code, null, 2)}}</div></td>
                    </tr>
                    <tr v-if="model?.refundDeductionFee_CurrencyID">
                        <td style="font-weight: bold">Refund Deduction Fee</td>
                        <td class="p-code-text" style="font-size: 0.9rem;">: <div style="text-align: right; display: inline-block; width: calc(100% - 1.5rem)">{{ $filters.formatCurrency(model.refundDeductionFee, $vm.$currencies.filter(x => x.id === model.refundDeductionFee_CurrencyID)[0]?.code, null, 2)}}</div></td>
                    </tr>
                    <tr v-if="model?.lastActivityDate">
                        <th colspan="2" style=" text-align: center; background: var(--surface-d); padding: 0.25rem;">LAST ACTIVITY DETAILS</th>
                    </tr>
                    <tr v-if="model?.lastActivityDate">
                        <td class="p-code-text" colspan="2">
                            <div style="font-weight: bold; padding: 1rem 0.5rem 0 0.5rem; font-size: 1.25rem; font-family: inherit;">{{model.lastActivity}}</div>
                            <div style="padding: 0 0.5rem; font-size: 0.9rem; font-family: inherit;">{{model.lastActivityLocation ?? 'location' }}</div>
                            <div style="padding: 0 0.5rem; font-size: 0.9rem; font-family: inherit;">{{$filters.toDateFullTimeDisplay(model?.lastActivityDate)}}</div>
                        </td>
                    </tr>
                </table>
            </div>
            <div :class="`p-col-12 p-md-4`">
                <h6>WAREHOUSE INFO</h6>
                <table style="width:100%; margin: -5px auto auto 5px;">
                    <tr  v-if="model?.newExchangeAddress">
                        <td style="font-weight: bold; width: 40%;">New Exchange Address</td>
                        <td class="p-code-text">: {{ address.newExchangeAddress }}</td>
                    </tr>
                    <tr  v-if="model?.warehouseLocation">
                        <td style="font-weight: bold; width: 40%;">Warehouse Location</td>
                        <td class="p-code-text">: {{ address.warehouseLocation }}</td>
                    </tr>
                </table>
                <div v-for="address in source.filter(x => x.addressType >= 600)" style="margin-bottom: 1rem;" :key="address.id">
                    <table style="width:100%; margin: -5px auto auto 5px;">
                        <tr v-if="model?.serviceType">
                            <th colspan="2" style=" text-align: center; background: var(--surface-d); padding: 0.25rem;">{{ address.addressType === 600 ? 'RECEIVING' : 'DESTINATION' }} WAREHOUSE</th>
                        </tr>
                        <tr>
                            <td style="font-weight: bold; width: 40%;">Code</td>
                            <td class="p-code-text">: {{ address.code }}</td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold">Name</td>
                            <td class="p-code-text">: {{ address.name }}</td>
                        </tr>
                        <tr v-if="address?.awbNumber">
                            <td style="font-weight: bold">Carrier Service</td>
                            <td class="p-code-text">: {{ address.awbNumber }}</td>
                        </tr>
                        <tr>
                            <th colspan="2" style=" text-align: center; background: var(--surface-d); padding: 0.25rem;">ADDRESS</th>
                        </tr>
                        <tr v-if="address?.company">
                            <td style="font-weight: bold">Company</td>
                            <td class="p-code-text" style="font-size: 0.9rem;">: {{ address?.company }}</td>
                        </tr>
                        <tr v-if="address?.houseNumber">
                            <td style="font-weight: bold">Building Number</td>
                            <td class="p-code-text" style="font-size: 0.9rem;">: {{ address?.houseNumber }}</td>
                        </tr>
                        <tr v-if="address?.address1">
                            <td style="font-weight: bold">Address 1</td>
                            <td class="p-code-text" style="font-size: 0.9rem;">: {{ address?.address1 }}</td>
                        </tr>
                        <tr v-if="address?.address2">
                            <td style="font-weight: bold">Address 2</td>
                            <td class="p-code-text" style="font-size: 0.9rem;">: {{ address?.address2 }}</td>
                        </tr>
                        <tr v-if="address?.address3">
                            <td style="font-weight: bold">Address 3</td>
                            <td class="p-code-text" style="font-size: 0.9rem;">: {{ address?.address3 }}</td>
                        </tr>
                        <tr v-if="address?.city">
                            <td style="font-weight: bold">City</td>
                            <td class="p-code-text" style="font-size: 0.9rem;">: {{ address?.city }}</td>
                        </tr>
                        <tr v-if="address?.neighborhood">
                            <td style="font-weight: bold">Neighborhood</td>
                            <td class="p-code-text" style="font-size: 0.9rem;">: {{ address?.neighborhood }}</td>
                        </tr>
                        <tr v-if="address?.state">
                            <td style="font-weight: bold">State</td>
                            <td class="p-code-text" style="font-size: 0.9rem;">: {{ address?.state }}</td>
                        </tr>
                        <tr v-if="address?.postalCode">
                            <td style="font-weight: bold">Postal Code</td>
                            <td class="p-code-text" style="font-size: 0.9rem;">: {{ address?.postalCode }}</td>
                        </tr>
                        <tr v-if="address?.country">
                            <td style="font-weight: bold">Country</td>
                            <td class="p-code-text" style="font-size: 0.9rem;">: {{ address?.postalCode }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12">
                <pv-tab style="margin: 0 -15px !important;">
                    <pv-tabpanel header="Items">
                        <return-item-grid :parentID="model?.id"></return-item-grid>
                    </pv-tabpanel>
                </pv-tab>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12">
                <pv-tab style="margin: 0 -15px !important;">
                    <pv-tabpanel header="Documents">
                        <return-document-grid :parentID="model?.id"></return-document-grid>
                    </pv-tabpanel>
                </pv-tab>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12">
                <pv-tab style="margin: 0 -15px !important;">
                    <pv-tabpanel header="Threads">
                        <return-thread-grid :parentID="model?.id"></return-thread-grid>
                    </pv-tabpanel>
                </pv-tab>
            </div>
        </div>
    </form-record>
</template>

<script>
    import { mapGetters } from 'vuex';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    import ReturnItemGrid from '@/components/transaction/tms/returns/returns-item-grid.vue';
    import ReturnThreadGrid from '@/components/transaction/tms/returns/returns-thread-grid.vue';
    import ReturnDocumentGrid from '@/components/transaction/tms/returns/returns-document-grid.vue';

    const moduleName = 'consignment';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'return-item-grid': ReturnItemGrid,
            'return-thread-grid': ReturnThreadGrid,
            'return-document-grid': ReturnDocumentGrid
        },
        data() {
            return {
                source:[]
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            tenantCode() {
                return ((this.tenants ?? this.$config.common.$tenant()).filter((x) => x.id === this.model.tenantID)[0]?.code ?? "").toLowerCase();
            },
            scopeCustomerDS() {
                return this.$config.common.$customer(this.user?.environmentID ?? -100, this.$vm.$appSession.warehouse.id ?? this.user.warehouseID, this.user);
            },
            cdnUrl() {
                return this.$config.config.endpoint.cdn;
            },
            collectionContact() {
                return {
                    firstName: this.model?.shipFrom_FirstName,
                    lastName: this.model?.shipFrom_LastName,
                    email: this.model?.shipFrom_Email,
                    mobile: this.model?.shipFrom_Mobile,
                    telephone: this.model?.shipFrom_Telephone,
                    eori: this.model?.shipFrom_EORI,
                    ioss: this.model?.shipFrom_IOSSNumber,
                    vat: this.model?.shipFrom_TaxId,
                    idNumber: this.model?.shipFrom_IdentityNumber,
                    idType: this.model?.shipFrom_IdentityNumberType ?? 300
                };
            },
            collectionAddress() {
                return {
                    address1: this.model?.shipFrom_Address1,
                    address2: this.model?.shipFrom_Address2,
                    address3: this.model?.shipFrom_Address3,
                    city: this.model?.shipFrom_City,
                    state: this.model?.shipFrom_State,
                    postalCode: this.model?.shipFrom_PostalCode,
                    countryID: this.model?.shipFrom_CountryID,
                    companyName: this.model?.shipFrom_Company,
                    establishmentType: this.model?.shipFrom_EstablimentType
                };
            },
            deliveryContact() {
                return {
                    firstName: this.model?.shipTo_FirstName,
                    lastName: this.model?.shipTo_LastName,
                    email: this.model?.shipTo_Email,
                    mobile: this.model?.shipTo_Mobile,
                    telephone: this.model?.shipTo_Telephone,
                    eori: this.model?.shipTo_EORI,
                    ioss: this.model?.shipTo_IOSSNumber,
                    vat: this.model?.shipTo_TaxId,
                    idNumber: this.model?.shipTo_IdentityNumber,
                    idType: this.model?.shipTo_IdentityNumberType ?? 300
                };
            },
            deliveryAddress() {
                return {
                    address1: this.model?.shipTo_Address1,
                    address2: this.model?.shipTo_Address2,
                    address3: this.model?.shipTo_Address3,
                    city: this.model?.shipTo_City,
                    state: this.model?.shipTo_State,
                    postalCode: this.model?.shipTo_PostalCode,
                    countryID: this.model?.shipTo_CountryID,
                    companyName: this.model?.shipTo_Company,
                    establishmentType: this.model?.shipTo_EstablimentType
                };
            },
            companyDS() { return this.$store.getters["company/ds"].data; },
            customerDS() { return this.companyDS.filter((x) => x.type === 16); },
            customer() {
                return this.customerDS.filter(x => x.id == this.model?.customerID)[0];
            },
            serviceProviderReturnProductDS() { return this.serviceProviderReturnProduct; },
            serviceProviderProductDS() { return this.serviceProviderProduct; },
            apiAllocate() {
                if ((this.model.serviceGroupCode !== null && this.model.serviceGroupCode !== "" && this.model.serviceGroupCode !== undefined) &&
                    (this.model.productCode === null || this.model.productCode === "" || this.model.productCode === undefined)
                ) {
                    return {
                        Request: {
                            TransactionID: this.model.code,
                            AllocationDetail: {
                                ServiceGroupCode: (this.model.serviceGroupCode !== null && this.model.serviceGroupCode !== "" && this.model.serviceGroupCode !== undefined) ? this.model.serviceGroupCode : null,
                                AccessPointLocationCode: (this.model.accessPointLocationCode !== null && this.model.accessPointLocationCode !== "" && this.model.accessPointLocationCode !== undefined) ? this.model.accessPointLocationCode : null,
                                ParcelReleaseCode: (this.model.parcelReleaseCode !== null && this.model.parcelReleaseCode !== "" && this.model.parcelReleaseCode !== undefined) ? this.model.parcelReleaseCode : null,
                                PushReturnEntry: this?.model?.pushReturns ?? false,
                                CollectionDetail: {
                                    CollectionDate: this.$filters.formatDateWithFormat(this.model.request_CollectionDate, "YYYY-MM-DD"),
                                    ReadyTime: "15:00",
                                    CollectionTime: "16:00",
                                    ClosingTime: "18:00",
                                    CutOffTime: "15:00"
                                },
                                Document: {
                                    Invoice: {
                                        Type: "COMMERCIAL",
                                        Date: this.$filters.formatDateWithFormat((this.model.invoiceDate != null ? this.model.invoiceDate : new Date()), "YYYY-MM-DD"),
                                        Reference: this.model.invoiceNumber,
                                        Notes: this.model.invoiceNote
                                    }
                                }
                            }
                        }
                    };

                }

                return {
                    Request: {
                        TransactionID: this.model.code,
                        AllocationDetail: {
                            ServiceGroupCode: (this.model.serviceGroupCode !== null && this.model.serviceGroupCode !== "" && this.model.serviceGroupCode !== undefined) ? this.model.serviceGroupCode : null,
                            CarrierDetail: {
                                CarrierCode: this.model.carrierCode,
                                ProductCode: this.model.productCode,
                                ReturnProductCode: this.model.returnProductCode,
                                CarrierServiceCode: this.model.carrierAPICode
                            },
                            AccessPointLocationCode: (this.model.accessPointLocationCode !== null && this.model.accessPointLocationCode !== "" && this.model.accessPointLocationCode !== undefined) ? this.model.accessPointLocationCode : null,
                            ParcelReleaseCode: (this.model.parcelReleaseCode !== null && this.model.parcelReleaseCode !== "" && this.model.parcelReleaseCode !== undefined) ? this.model.parcelReleaseCode : null,
                            PushReturnEntry: this?.model?.pushReturns ?? false,
                            CollectionDetail: {
                                CollectionDate: this.$filters.formatDateWithFormat(this.model.request_CollectionDate, "YYYY-MM-DD"),
                                ReadyTime: "15:00",
                                CollectionTime: "16:00",
                                ClosingTime: "18:00",
                                CutOffTime: "15:00"
                            },
                            Document: {
                                Invoice: {
                                    Type: "COMMERCIAL",
                                    Date: this.$filters.formatDateWithFormat((this.model.invoiceDate != null ? this.model.invoiceDate : new Date()), "YYYY-MM-DD"),
                                    Reference: this.model.invoiceNumber,
                                    Notes: this.model.invoiceNote
                                }
                            }
                        }
                    }
                };
                //return {
                //    Request: {
                //        TransactionID: this.model.code,
                //        AllocationDetail: {
                //            ServiceGroupCode: null,
                //            CarrierDetail: {
                //                CarrierCode: this.model.carrierCode,
                //                ProductCode: this.model.productCode
                //            },
                //            AccessPointLocationCode: null,
                //            CollectionDetail: {
                //                CollectionDate: this.$filters.formatDateWithFormat(this.model.request_CollectionDate, "YYYY-MM-DD"),
                //                ReadyTime: "15:00",
                //                CollectionTime: "16:00",
                //                ClosingTime: "18:00",
                //                CutOffTime: "15:00"
                //            }
                //        }
                //    }
                //};
            },
            apiDeallocate() {
                return {
                    Request: {
                        Reason: this.dialog.reason
                    }
                };
            },
            apiService() {
                let packages = [];
                let _self = this;
                this?.packages?.forEach((x) => {
                    packages.push({
                        Weight: {
                            GrossWeight: x.weight
                        },
                        WeightUnit: _self.$vm.$enums['weightunit'].filter((xz) => xz.id == _self.model?.weightUnit)[0]?.text?.toUpperCase(),
                        Dimension: {
                            Length: x.length,
                            Height: x.height,
                            Width: x.width
                        },
                        LengthUnit: _self.$vm.$enums['lengthunit'].filter((xz) => xz.id == _self.model?.weightUnit)[0]?.text?.toUpperCase(),
                        CurrencyCode: _self.$vm.$currencies.filter((xz) => xz.id === _self.model.currencyID)[0]?.code?.toUpperCase(),
                        Declaration: {
                            Declared: x.consignmentValue
                        }
                    });

                });
                return {
                    Request: {
                        CustomerCode: this.customerDS.filter(x => x.id === this?.model?.customerID)[0]?.code,
                        //ServiceGroupCode: this.model.serviceGroupCode != null ? this.model.serviceGroupCode : null,
                        Carrier: ((this.model?.carrierCode?.length ?? 0)) > 0 ? {
                            CarrierCode: this.model.carrierCode
                        } : null,
                        Order: {
                            ShipFrom: {
                                City: this.model?.shipFrom_City,
                                State: this.model?.shipFrom_State,
                                PostalCode: this.model?.shipFrom_PostalCode,
                                Country: this.$vm.$countries.filter(x => x.id == this.model?.shipFrom_CountryID)[0]?.code
                            },
                            ShipTo: {
                                City: this.model?.shipTo_City,
                                State: this.model?.shipTo_State,
                                PostalCode: this.model?.shipTo_PostalCode,
                                Country: this.$vm.$countries.filter(x => x.id == this.model?.shipTo_CountryID)[0]?.code
                            },
                            IsDocument: false,
                            CollectionDate: this.$filters.formatDateWithFormat(this.model?.request_CollectionDate ?? new Date())
                        },
                        Packages: packages
                    }
                }
            },
            apiAccessPoint() {
                return {
                    Request: {
                        CustomerCode: this.companyDS.filter(x => x.id === this?.model?.customerID)[0]?.code,
                        ProviderCode: this.model?.carrierCode,
                        MaxDistance: 100,
                        MaxResult: 100,
                        DistanceUnit: "MI",
                        Address: {
                            City: this.model?.shipTo_City,
                            State: this.model?.shipTo_State,
                            PostalCode: this.model?.shipTo_PostalCode,
                            Country: this.$vm.$countries.filter(x => x.id === this?.model?.shipTo_CountryID)[0]?.code
                        }
                    }
                };
            },
            collectionDate() {
                if (this?.model?.request_CollectionDate)
                    return new Date(this.$filters.formatDate(this?.model?.request_CollectionDate));
                return new Date();
            },
            productServiceInit() {
                return [{
                    ProviderCode: null,
                    ProviderName: null,
                    ProductCode: null,
                    ProductName: null,
                    Disclaimer: null,
                    Quote: {
                        Description: null,
                        CurrencyCode: null,
                        Value: 0.00,
                        ItemizedCharges: [
                            {
                                Description: null,
                                CurrencyCode: null,
                                Value: 0.00
                            },
                        ]
                    },
                    Schedule: {
                        CollectionSchedule: {
                            CollectionDate: null,
                            CollectionTime: null,
                            CutOffTime: null
                        },
                        EstimatedDelivery: {
                            CollectionDate: null,
                            CollectionTime: null
                        },
                        TimeInTransit: 1
                    }
                }, {
                    ProviderCode: null,
                    ProviderName: null,
                    ProductCode: null,
                    ProductName: null,
                    Disclaimer: null,
                    Quote: {
                        Description: null,
                        CurrencyCode: null,
                        Value: 0.00,
                        ItemizedCharges: [
                            {
                                Description: null,
                                CurrencyCode: null,
                                Value: 0.00
                            },
                        ]
                    },
                    Schedule: {
                        CollectionSchedule: {
                            CollectionDate: null,
                            CollectionTime: null,
                            CutOffTime: null
                        },
                        EstimatedDelivery: {
                            CollectionDate: null,
                            CollectionTime: null
                        },
                        TimeInTransit: 1
                    }
                }, {
                    ProviderCode: null,
                    ProviderName: null,
                    ProductCode: null,
                    ProductName: null,
                    Disclaimer: null,
                    Quote: {
                        Description: null,
                        CurrencyCode: null,
                        Value: 0.00,
                        ItemizedCharges: [
                            {
                                Description: null,
                                CurrencyCode: null,
                                Value: 0.00
                            },
                        ]
                    },
                    Schedule: {
                        CollectionSchedule: {
                            CollectionDate: null,
                            CollectionTime: null,
                            CutOffTime: null
                        },
                        EstimatedDelivery: {
                            CollectionDate: null,
                            CollectionTime: null
                        },
                        TimeInTransit: 1
                    }
                }
                ]
            },
            isDomestic() {
                return ((this.model?.shipFrom_CountryID ?? 0) > 0 && (this.model?.shipTo_CountryID ?? 0) > 0) ? (this.model?.shipFrom_CountryID ?? 0) === (this.model?.shipTo_CountryID ?? this.model?.shipFrom_CountryID) : true;
            },
            companySettingRecord() {
                return this.$store.getters["companygeneralsetting/record"];
            },
            buttonItems() {
                let _buttonItems = [
                    {
                        label: 'Commercial Invoice',
                        icon: 'pi pi-file',
                        command: () => {
                            this.dialog.invoice = true;
                        }
                    },
                    {
                        label: 'Proforma Invoice',
                        icon: 'pi pi-file',
                        command: () => {
                            this.dialog.performa = true;
                        }
                    },
                    {
                        label: 'CN22',
                        icon: 'pi pi-file',
                        command: () => {
                            this.dialog.cn22 = true;
                        }
                    },
                    {
                        label: 'CN23',
                        icon: 'pi pi-file',
                        command: () => {
                            this.dialog.cn23 = true;
                        }
                    }
                ];

                if (this.documentTypes.findIndex(x => x === 300) === -1) {
                    let index = _buttonItems.findIndex(x => x.label === 'CN22');
                    if (index !== -1) {
                        _buttonItems.splice(index, 1);
                    }
                }
                if (this.documentTypes.findIndex(x => x === 400) === -1) {
                    let index = _buttonItems.findIndex(x => x.label === 'CN23');
                    if (index !== -1) {
                        _buttonItems.splice(index, 1);
                    }
                }
                if (this.documentTypes.findIndex(x => x === 500) === -1) {
                    let index = _buttonItems.findIndex(x => x.label === 'Commercial Invoice');
                    if (index !== -1) {
                        _buttonItems.splice(index, 1);
                    }
                }
                if (this.documentTypes.findIndex(x => x === 600) === -1) {
                    let index = _buttonItems.findIndex(x => x.label === 'Proforma Invoice');
                    if (index !== -1) {
                        _buttonItems.splice(index, 1);
                    }
                }
                return _buttonItems;
            }
            
        },
        watch: {
            'model.id': {
                handler() {
                    this.getSource();
                    this.getConsignment();
                }
            }
        },
        methods: {
            getSource() {
                this.$store.dispatch("returns/getURL", { url: `/services/tms/return/${this.model?.id}/addresses` })
                    .then((response) => {
                        this.source = response?.result ?? [];
                    });
            },
            getConsignment() {
                if (this.model?.id > 0) {
                    this.$store.dispatch("returns/getURL", { url: `/services/tms/consignment/id/${this.model?.consignmentID}` })
                        .then((response) => {
                            var consignment = response?.result;
                            if (consignment) {
                                this.model.transactionID = consignment.code;
                                this.model.consignmentMask = consignment.mask;
                            }
                        });
                }
            },
            address(data) {
                var text = `${(data?.company ?? '')}, ${(data?.buildingNumber ?? '')} ${(data?.address1 ?? '')}, ${(data?.address2 ?? '')}, ${(data?.address3 ?? '')}, ${(data?.city ?? '')}, ${(data?.neighborhood ?? '')}, ${(data?.state ?? '')}, ${(data?.postalCode ?? '')} , ${(data?.country ?? '')}`;
                return text
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',')
                    .replaceAll('  ', ' ').replaceAll(', .,', ',,').replaceAll(', ,', ',,').replaceAll(',,', ',');
            }
        },
        async mounted() {
            this.activity.loading = true;
        }
    }
</script>
