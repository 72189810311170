<template>
    <div class="layout-wrapper">
        <div class="layout-content" style="margin: 0; background-color: var(--surface-b); height: 100vh; ">
            <div class="content-section implementation" style="text-align: center;">
                <div class="card" style="height: 100%; text-align:center;">
                    <img src="../../../assets/_img/_common/404.png" style="max-height: 500px;" />
                    <h3>Sorry, this page is not available.</h3>
                    <p>The link you have followed may be broken, or the page may have been removed.</p>
                    <router-link :to="{ path: '/'}" class="p-button">Go back to Dashboard</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'PageNotFound'
    }
</script>