<template>
    <form-fileadvance id="attachments" label="Attachments" :hideLabel="false" preLabel="Attachments" :required="false" theme="document" :multiple="true" :value="records.data ?? []" @is-sync="onUploadFile($event)" @is-delete="onRemoveUpload($event)" height="2rem" :viewAsFile="true" :maxFileSizeInMB="20" type="advanced" style="width:100% !important;" accept="image/*,application/pdf,application/x-zip-compressed,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,text/*,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></form-fileadvance>
</template>
<script>
    import { mapGetters } from 'vuex';
    import { useVuelidate } from '@vuelidate/core';
    export default {
        name: 'attachmentForm',
        setup: () => ({ v$: useVuelidate() }),
        props: {
            formData: { type: Object }},
        data() {
            return {
                editingRecords: [],
                records: { data: [], total: 0 },
                files: [],
                source: [],
                model: null,
                activity: { loading: true },
                dialog: { delete: false, edit: false },
                page: {
                    current: 0,
                    first: 0,
                    size: 10
                },
                type: -1,
                filter: {},
                columns: [],
                deleteDisplayFields: ["name", "amount"],
                searchFields: ["name"],
                selectedItems: [],
                selectedReset: false,
                modelState: null,
                initialBind: false

            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            dataSource() {
                return (this.records ?? { data: [], total: 0 }).data;
            },
            viewOnly() { return this.formData?.status >= 200; },
            attachmentRequest() {
                let attachment = [];
                for (let i = 0; i < (this.files ?? []).length; i++) {
                    attachment.push({
                        filename: this.files[i]?.filename,
                        contentType: this.files[i]?.contentType,
                        file: this.files[i]?.file
                    })
                }
                return attachment;
            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            'formData.id': {
                handler() {
                    this.$nextTick(() => {
                        this.onRefresh();
                    });
                }, deep: true
            },
            'records.data': {
                handler() {
                    this.$nextTick(() => {
                        //this.$emit("is-sync", { attachments : this.records.data });
                    });
                }, deep: true
            }
        },
        methods: {
            onUploadFile(event) {
                this.files = event;
                this.$nextTick(() => {
                    this.addAttachment();
                });
            },
            onRemoveUpload(event) {
                this.removeAttachment(event);
            },
            onRefresh() {
                this.activity.loading = true;
                this.records = { data: [], total: 0 };
                this.getSource().then((response) => {
                    this.records = response;
                    this.$nextTick(() => { this.activity.loading = false; });
                });
            },
            async removeAttachment(entity) {
                this.$store.dispatch("invoiceattachment/deleteURL", {
                    url: "/services/facility/invoice/" + this.formData.mask + "/attachment/" + entity.mask
                }).then((response) => {
                    if (response.success) {
                        //var data = this.$filters.init(response.result)
                        this.activity.refresh = true;
                        this.$nextTick(() => { this.activity.refresh = false });
                        //this.onRefresh();
                    }
                    else if ((response.errors ?? []).length > 0) {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: response.errors[0]?.message, life: 3000 });
                        this.onRefresh();
                    }
                })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        this.onRefresh();
                    });
            },
            async addAttachment() {
                this.$store.dispatch("invoiceattachment/putURL", {
                    url: "/services/facility/invoice/" + this.formData.mask + "/attachment",
                    payload: this.attachmentRequest
                }).then((response) => {
                    if (response.success) {
                        this.activity.refresh = true;
                        this.$nextTick(() => { this.activity.refresh = false });
                        this.onRefresh();
                    }
                    else if ((response.errors ?? []).length > 0) {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: response.errors[0]?.message, life: 3000 });
                        this.onRefresh();
                    }
                })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        this.onRefresh();
                    });
            },
            async getSource() {
                this.activity.loading = true;
                let dataSource = { total: 0, data: [] };
                if (this.formData?.mask !== undefined && this.formData?.mask !== null) {
                    await this.$store.dispatch("invoiceattachment/getURL", { url: `/services/facility/invoice/` + this.formData?.mask + `/attachment` })
                        .then((response) => {
                            dataSource = { data: response.result, total: response.result.length };
                            this.source = response.result;
                            dataSource = { total: (this.source ?? []).length, data: [...(this.source ?? [])]?.slice(this.page.first) };
                            this.activity.loading = false;
                        })
                        .catch(() => {
                            this.source = [];
                            dataSource = { total: 0, data: [] }
                            this.activity.loading = false;
                        });
                }
                return dataSource;
            }
            
        },
        async created() {
            this.loading = false;
            this.getSource().then((response) => { this.records = response; })
        },
        async mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            this.loading = false;
           
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
</style>