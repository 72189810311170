<template>
    <form-record :model="model" :modelState="v$" @is-reset="resetRecord" :viewOnly="true" :title="`[${model.code}] ${model.name}`" :subTitle="`${model.typeName?.toUpperCase()}`">
        <div class="p-grid">
            <div class="p-col-12 p-md-4">
                <form-input id="code" :disabled="true" :value="model.code" v-uppercase :required="true" />
                <form-input id="name" :disabled="true" :value="model.name" :required="true" />
                <form-lookup id="type" :disabled="true" type="enum" source="integrationtype" :value="model.type" :required="true" />
            </div>
            <div class="p-col-12 p-md-4">
                <form-lookup id="usageType" :disabled="true" type="flag" :source="'integrationservicetype'" :required="true" :value="model.usageType" />
                <form-lookup id="tradeType" type="flag" source="tradetype" :disabled="true" :required="true" :value="model.tradeType" :showButton="false" />
            </div>
            <div class="p-col-12 p-md-4">
                <div style="width: 100%; min-height: 200px; text-align: center; object-position:center;">
                    <img :src="`/_assets/_integration/${model.mask}.png?${ticks}`" style="width: inherit; object-fit: contain; max-height: 5rem; max-width: calc(5rem * 3); object-position: center;" onerror="this.onerror=null; this.src = '/_assets/img/no-logo.png'" />
                </div>
            </div>
        </div>
        <div class="p-grid" style="margin: 0 -1.5rem;">
            <div class="p-col-12">
                <pv-tab :scrollable="true">
                    <pv-tabpanel header="CARRIERS" v-if="model.type === 200">
                        <carrier-grid id="CarrierList" :code="model.code" :provider="model.mask" />
                    </pv-tabpanel>
                    <pv-tabpanel header="PRODUCTS">
                        <product-grid id="ProductList" :code="model.code" :provider="model.mask" />
                    </pv-tabpanel>
                    <pv-tabpanel header="INTEGRATIONS">
                        <integration-grid id="IntegrationList" :code="model.code" :provider="model.mask" />
                    </pv-tabpanel>
                    <pv-tabpanel header="API SUPPORT">
                        <div class="p-grid" style="padding: 2rem 1rem !important;">
                            <div class="p-col-12 p-md-4">
                                <form-lookup id="apiConsignment" label="Consignment" :disabled="true" type="flag" source="apiaction" :validator="v$" :value="model.apiConsignment" />
                                <form-lookup id="apiFreight" label="Freight" :disabled="true" type="flag" source="apiaction" :validator="v$" :value="model.apiFreight" />
                                <form-lookup id="apiCollection" label="Book Collection" :disabled="true" type="flag" source="apiaction" :validator="v$" :value="model.apiCollection" />
                                <form-lookup id="apiPaperlessDocument" label="Paperless Documentation" :disabled="true" type="flag" source="apiaction" :validator="v$" :value="model.apiPaperlessDocument" />
                            </div>
                            <div class="p-col-12 p-md-4">
                                <form-boolean id="apiReturns" label="Supports Return Label" :disabled="true" :validator="v$" textPosition="left" width="120px" :value="model.apiReturns" />
                                <form-boolean id="apiTracking" label="Supports Tracking/Auto POD" :disabled="true" :validator="v$" textPosition="left" width="120px" :value="model.apiTracking" />
                                <form-boolean id="apiLocatorTool" label="Supports Access Point Delivery and Locator" :disabled="true" :validator="v$" width="120px" textPosition="left" :value="model.apiLocatorTool" />
                                <form-boolean id="apiRateTool" label="Supports Rate Capability Tool" :disabled="true" :validator="v$" textPosition="left" width="120px" :value="model.apiRateTool" />
                                <form-boolean id="apiTimeInTransitTool" label="Supports Time-In-Transit Capability Tool" :disabled="true" :validator="v$" width="120px" textPosition="left" :value="model.apiTimeInTransitTool" />
                                <form-boolean id="apiDangerousGoods" label="Supports Dangerous Goods Declaration" :disabled="true" :validator="v$" textPosition="left" width="120px" :value="model.apiDangerousGoods" />
                                <form-boolean id="apiPreNotification" label="Supports Dangerous Goods Pre-Notification" :disabled="true" :validator="v$" textPosition="left" width="120px" :value="model.apiPreNotification" />
                                <form-boolean id="apiMultiParcel" label="Supports Multi Parcel" :disabled="true" :validator="v$" textPosition="left" width="120px" :value="model.apiMultiParcel" />
                                <form-boolean id="supportGenericCollectionDocument" label="Supports Generic Collection Document" :disabled="true" :validator="v$" textPosition="left" width="120px" :value="model.supportGenericCollectionDocument" />
                            </div>
                        </div>
                    </pv-tabpanel>
                    <pv-tabpanel header="COUNTRIES">
                        <tradebloc-grid id="TradeBlocList" :code="model.code" :provider="model.mask" />
                    </pv-tabpanel>
                    <pv-tabpanel header="TIME IN TRANSIT">
                        <timeintransit-grid id="TimeInTransitList" :code="model.code" :provider="model.mask" />
                    </pv-tabpanel>
                    <pv-tabpanel header="SURCHARGES">
                        <surcharge-grid id="SurchargeList" :code="model.code" :provider="model.mask" />
                    </pv-tabpanel>
                </pv-tab>
            </div>
        </div>
    </form-record>
</template>
    
<script>
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    import carriergrid from '@/components/settings/provider/carrier-grid.vue';
    import productgrid from '@/components/settings/provider/product-grid.vue';
    import integrationgrid from '@/components/settings/provider/integration-grid.vue';
    import tradeblocgrid from '@/components/settings/provider/tradebloc-grid.vue';
    import timeintransitgrid from '@/components/settings/provider/timeintransit-grid.vue';
    import surchargegrid from '@/components/settings/provider/surcharge-grid.vue';

    export default {
        name: 'ServiceProviderGrid',
        setup: () => ({ v$: useVuelidate() }),
        mixins: [mixins.RECORD],
        components: {
            'carrier-grid': carriergrid,
            'product-grid': productgrid,
            'integration-grid': integrationgrid,
            'tradebloc-grid': tradeblocgrid,
            'timeintransit-grid': timeintransitgrid,
            'surcharge-grid': surchargegrid
        },
        data() {
            return {
                searchField: ['code', 'name'],
            }
        },
        computed: {
            ticks() { return this.$moment.utc().format("YYYYMMDD"); }
        },
        mounted() { this.loading = false; },
    }
</script>
<style>
    .tradetype { margin-right: 5px !important; padding: 0 !important; }
</style>