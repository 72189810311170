<template>
    <pv-autocomplete v-if="!disabled && objType === 'autocomplete'"
                     :ref="id"
                     :id="id"
                     :class="[{'p-fluid': autoFit }, {'p-invalid': false}]"
                     :placeholder="phText"
                     :disabled="disabled"
                     :dropdown="showButton"
                     :dropdownMode="'current'"
                     :multiple="selectionMode"
                     :forceSelection="true"
                     :completeOnFocus="true"
                     :minLength="minLength"
                     :suggestions="filteredDS"
                     :field="textField"
                     v-model="model.text"
                     @complete="applyFilter"
                     @clear="onSync"
                     @item-select="onSync">
        <template #item="slotProps">
            <div class="country-item" style="max-width: calc(100% - 30px)">
                <span v-if="source === 'country' || isTradeBloc" :class="`flag flag-${slotProps.item.id >= 1000 ? 'tb-' : ''}${slotProps.item.code.toLowerCase()}`" style="width: 30px; height: 20px;"></span>
                <div class="p-ml-2">{{ slotProps.item[textField] }} {{ isTradeBloc && slotProps.item.id >= 1000 ? '[Trade Bloc]' : '' }}</div>
            </div>
        </template>
    </pv-autocomplete>
    <pv-multiselect v-else-if="!disabled && ((objType === 'dropdown' && selection.toLowerCase() !== 'single') || objType === 'flag')"
                    :ref="id"
                    :id="id"
                    :class="[{'p-fluid': autoFit }, {'p-invalid': false}, { 'p-multiselect-custom': true }]"
                    :disabled="disabled"
                    :placeholder="phText"
                    :options="originalDS"
                    :optionLabel="textField"
                    :optionValue="valueField"
                    :dataKey="valueField"
                    :filter="showFilter"
                    :filterMatchMode="filterCondition"
                    :filterFields="filterFieldList"
                    :filterPlaceholder="filterPlaceholderText"
                    :editable="editable"
                    :loading="activity.loading.object"
                    :showClear="(!model.state.required && !disabled && !required) && showClear"
                    v-model="model.record"
                    @change="onSync">
        <template #value="slotProps">
            <slot name="value" data="slotProps" v-if="selectedText && selectedText.length > 0 && objType === 'flag'">
                <div class="selected-item" v-for="option of selectedText" :key="option.id">
                    {{option.text}}
                </div>
            </slot>
            <slot name="value" data="slotProps" v-else-if="selectedText && selectedText.length > 0">
                <div class="selected-item" v-for="option of selectedText" :key="option[valueField]">
                    {{option[textField]}}
                </div>
            </slot>
            <div v-else class="p-multiselect-label p-placeholder" style="width: 100%; margin: 0; padding: 0;">{{ slotProps.placeholder  }}</div>
        </template>
        <template #item="slotProps">
            <slot name="options" data="slotProps">
                <div class="country-item">
                    <div class="p-ml-2">{{ slotProps.item[textField] }}</div>
                </div>
            </slot>
        </template>
    </pv-multiselect>
    <pv-dropdown v-else-if="!disabled || (disabled && disabledType === 'object')"
                 :ref="id"
                 :id="id"
                 :name="id"
                 :class="[{'p-fluid': autoFit }, {'p-invalid': false}, {'p-hidelabel': hideLabel }]"
                 :disabled="disabled"
                 :placeholder="phText"
                 :options="originalDS"
                 :optionLabel="textField"
                 :optionValue="valueField"
                 :dataKey="valueField"
                 :filter="showFilter"
                 :filterMatchMode="filterCondition"
                 :filterFields="filterFieldList"
                 :filterPlaceholder="filterPlaceholderText"
                 :editable="editable"
                 :loading="activity.loading.object"
                 :showClear="(!model.state.required && !disabled && !required) && showClear"
                 v-model="model.value"
                 @change="onSync">
        <template #value="slotProps">
            <slot name="value" :data="slotProps" v-if="selectedText">
                <div class="selected-item" style="margin: 1px 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" v-if="selectedText">
                    <span v-if="source === 'country' && selectedText" :class="`flag flag-${ selectedText.code.toLowerCase() }`" style="width: 30px; height: 20px; margin-right: 5px;"></span>
                    <span v-html="`${selectedText[textField]?.replace('&lsqb;','&lt;b&gt;&lsqb;').replace('&rsqb;','&rsqb;&lt;/b&gt;')}`"></span>
                </div>
            </slot>
            <span v-else class="p-dropdown-label p-placeholder" style="width: 100%;">{{ slotProps.placeholder }}&nbsp;</span>
        </template>
        <template #option="slotProps">
            <slot name="option" :data="slotProps">
                <div class="country-item" style="margin: 1px 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; display: inline-flex; flex: 1 1 auto; max-width: 480px !important; ">
                    <span v-if="source === 'country' && slotProps.option.code" :class="`flag flag-${slotProps.option.code.toLowerCase()}`" style="width: 30px; height: 20px;"></span>
                    <div class="p-ml-2" style="margin: 1px 0; overflow: hidden; white-space: pre-wrap; "><span v-html="`${slotProps.option[textField]?.replace('[','&lt;b&gt;&lsqb;')?.replace(']','&rsqb;&lt;/b&gt;')}`"></span></div>
                </div>
            </slot>
        </template>
        <template #item="slotProps">
            <slot name="item" :data="slotProps">
                <div class="country-item" style="margin: 1px 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                    <span v-if="source === 'country' && slotProps.item.code" :class="`flag flag-${slotProps.item.code.toLowerCase()}`" style="width: 30px; height: 20px;"></span>
                    <div class="p-ml-2"><span v-html="`${slotProps.item[textField]?.replace('&lsqb;','& lt; b & gt;&lsqb;').replace('&rsqb;','&rsqb;&lt;/b&gt;')}`"></span></div>
                </div>
            </slot>
        </template>
    </pv-dropdown>
    <div v-else-if="disabled && (objType === 'flag' || selection.toLowerCase() !== 'single')" :title="`${(model?.value ? model.value : '')}`" class="is-disabled p-component p-inputtext p-input-icon-right" :style="[{ 'border-radius': 0 }, { 'border-right': (postLabel || postIcon) ? ((postLabel || postIcon) ? '0' : '1px solid inherit') : false }, { 'border-top-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-bottom-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-top-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'border-bottom-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'overflow': 'hidden' }, { 'text-overflow': 'ellipsis' }, { 'background': 'var(--surface-c)' }, { 'cursor': 'not-allowed' }, { 'white-space': 'nowrap' }, { 'min-height': '2.858rem'}, {'padding': '5px' }]">
        <span v-if="objType === 'dropdown' && source === 'country' && model.record && model.record.code" :class="`flag flag-${model.record?.code?.toLowerCase()}`" style="width: 30px; height: 20px; margin-right: 5px;"></span>
        <div v-for="item in model.text" :key="item" class="selected-item p-code-text" style="font-size: 0.9rem; font-weight: 600; white-space: nowrap; text-wrap: normal; overflow: hidden; text-overflow: ellipsis; " v-html="`<i class='pi pi-chevron-right' style='font-size: 0.8rem; margin-right: 5px;'></i>${item ?? '&nbsp;'}`"></div>
        <i v-if="showCopy" class="pi pi-copy" style="cursor: pointer;" @click="onCopy"></i>
    </div>
    <div v-else :title="`${(model?.value ? model.value : '')}`" class="p-component p-inputtext p-input-icon-right" :style="[{ 'border-radius': 0 }, { 'border-right': (postLabel || postIcon) ? ((postLabel || postIcon) ? '0' : '1px solid inherit') : false }, { 'border-top-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-bottom-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-top-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'border-bottom-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'overflow': 'hidden' }, { 'text-overflow': 'ellipsis' }, { 'background': 'var(--surface-c)' }, { 'cursor': 'not-allowed' }, { 'white-space': 'nowrap' }, { 'height': '2.858rem'}]">
        <span v-if="objType === 'dropdown' && source === 'country' && model.record && model.record.code" :class="`flag flag-${model.record?.code?.toLowerCase()}`" style="width: 30px; height: 20px; margin-right: 5px;"></span>
        <span class="p-code-text" style="font-size: 0.9rem; font-weight: 600; white-space: nowrap; text-wrap: normal; overflow: hidden; text-overflow: ellipsis; " v-html="`${model?.text ?? '&nbsp;'}`"></span>
        <i v-if="showCopy" class="pi pi-copy" style="cursor: pointer;" @click="onCopy"></i>
    </div>
</template>
<script>
    import config from '@/assets/lib/cosmos/_js/config.js';
    import mixins from '@/assets/lib/cosmos/_js/core-mixins.js';
    export default {
        name: "FormLookupField",
        mixins: [mixins.COMPONENT],
        data() {
            return {
                activity: {
                    loading: { object: false }
                },
                selectedField: null,
                originalDS: [],
                filteredDS: [],
            }
        },
        props: {
            type: { type: String, default: "dropdown", validator: (value) => ['dropdown', 'autocomplete', 'flag', 'enum'].indexOf(value.toLowerCase()) !== -1 },
            value: { type: [Number, String, Array, Object] },
            sourceType: { type: String, default: 'array', validator: (value) => ['array', 'url', 'store'].indexOf(value.toLowerCase()) !== -1 },
            source: { type: [Array, String] },
            textField: { type: String, default: 'text' },
            valueField: { type: String, default: 'id' },
            selection: { type: String, default: 'single', validator: (value) => ['single', 'multiple'].indexOf(value.toLowerCase()) !== -1 },
            filter: { type: String, default: "contains", validator: (value) => ['contains', 'startswith', 'endswith'].indexOf(value.toLowerCase()) !== -1 },
            filterFields: { type: [String, Array] },
            filterPlaceholder: { type: String },
            filterPH: { type: String },
            minLength: { type: Number, default: 2 },
            sorted: { type: Boolean, default: true },
            editable: { type: Boolean, default: false },
            showButton: { type: Boolean, default: false },
            showDirectory: { type: Boolean, default: false },
            showLookup: { type: Boolean, default: false },
            allowedFlags: { type: Number, default: 0 },
            showClear: { type: Boolean, default: true },
            showCopy: { type: Boolean, default: true },
            isTradeBloc: { type: Boolean, default: false },
            disabledType: { type: String, default: 'lock', validator: (value) => ['lock', 'object'].indexOf(value.toLowerCase()) !== -1 }
        },
        computed: {
            dsType() { return this.sourceType.toLowerCase() },
            selectedText() {
                if (this.objType === "flag")
                    return (this.originalDS ?? this.dataSource?.data ?? []) ? (this.originalDS ?? this.dataSource?.data ?? []).filter(x => (this.model?.record ?? []).indexOf(x.id) !== -1) : null;
                else if (this.selection.toLowerCase() === "single") {
                    if (typeof (this.source) === "string" && this.source.toLowerCase() === "country")
                        return this.$vm.$countries.filter(x => x.id == this.model.value)[0];
                    else if (typeof (this.source) === "string" && this.source.toLowerCase() === "currency")
                        return this.$vm.$currencies.filter(x => x.id == this.model.value)[0];
                    else
                        return (this.originalDS ?? this.dataSource?.data ?? this.dataSource) ? (this.originalDS ?? this.dataSource?.data ?? this.dataSource ?? []).filter(x => x[this.valueField] === this.model.value)[0] : null;
                }
                else
                    return (this.originalDS ?? this.dataSource?.data ?? this.dataSource) ? (this.originalDS ?? this.dataSource?.data ?? this.dataSource ?? []).filter(x => (this.model.value ?? []).indexOf(x[this.valueField]) !== -1) : null;
            },
            dataSource() { return this.$store.getters[this.source + "/ds"]; },
            selectionMode() { return this.selection.toLowerCase() !== "single"; },
            filterCondition() {
                if (this.filter.toLowerCase() === "endswith")
                    return "endsWith";
                else if (this.filter.toLowerCase() === "startswith")
                    return "startsWith";
                else
                    return "contains";
            },
            filterFieldList() {
                if (typeof (this.source) === "string" && this.source.toLowerCase() === "country")
                    return ["iso2", "text", "iso3", "code"];
                else if (this.filterFields && Array.isArray(this.filterFields))
                    return this.filterFields;
                else if (this.filterFields && typeof (this.filterFields) === "string")
                    return this.filterFields.split(',');
                else {
                    let keys = new Array();
                    keys.push(this.textField);
                    return keys;
                }
            },
            filterPlaceholderText() {
                if (this.filterPlaceholder)
                    return this.filterPlaceholder;
                return this.filterPH;
            },
            showFilter() { return (this.originalDS ?? []).length > 5; }
        },
        watch: {
            "model.value": {
                handler(e) {
                    this.setDataSource()
                        .then((response) => {
                            this.activity.loading.object = false;
                            this.originalDS = response;
                            this.onSync(this.value);
                        }).catch(() => { this.activity.loading.object = false; });
                    this.onSync(e);
                },
                deep: true
            },
            allowedFlags() {
                this.activity.loading.object = true;
                this.setDataSource()
                    .then((response) => {
                        this.activity.loading.object = false;
                        this.originalDS = response;
                        this.onSync(this.value);
                    }).catch(() => { this.activity.loading.object = false; });
            },
            source() {
                this.activity.loading.object = true;
                this.setDataSource()
                    .then((response) => {
                        this.activity.loading.object = false;
                        this.originalDS = response;
                        this.onSync(this.value);
                    }).catch(() => { this.activity.loading.object = false; });
            }
        },
        methods: {
            onSync(e) {
                if (e !== undefined && e != null) {
                    let data = { value: null, text: null, record: null }
                    if (typeof (e) === "object" && e.value && typeof (e.value) === "object") {
                        if (this.objType === "flag") {
                            let flagSource = this.originalDS.filter(x => e.value.indexOf(x.id) !== -1);
                            data = {
                                value: this.$filters.setFlag(e.value),
                                text: flagSource.map(x => x.text),
                                record: e.value
                            };
                        }
                        else if (this.sourceType.toLowerCase() === "store") {
                            data = {
                                value: e.value[this.valueField],
                                text: e.value[this.textField],
                                record: e.value
                            };
                            this.model.value = data.value;
                            this.model.text = data.text;
                            this.model.record = data.record;
                            this.$emit("is-sync", data);
                        }
                        else if (this.sourceType.toLowerCase() === "url") {
                            data = {
                                value: e.value[this.valueField],
                                text: e.value[this.textField],
                                record: e.value
                            };
                            this.model.value = data.value;
                            this.model.text = data.text;
                            this.model.record = data.record;
                            this.$emit("is-sync", data);
                        }
                        else if (this.objType === "dropdown" && this.selection.toLowerCase() === "multiple") {
                            var filterd = this.originalDS.filter(x => e.value.findIndex(y => y === x[this.valueField]) !== -1);
                            data = {
                                value: e.value,
                                text: filterd.map(x => x[this.textField]),
                                record: filterd
                            };
                        }
                        else {
                            data = {
                                value: e.value[this.valueField],
                                text: e.value[this.textField],
                                record: e.value
                            };
                        }
                    }
                    else if (typeof (e) === "object" && e.value && typeof (e.value) !== "object") {
                        let record = this.$filters.init(this.originalDS.filter(x => x[this.valueField] === e.value)[0]);
                        if (record)
                            data = {
                                value: record[this.valueField],
                                text: record[this.textField],
                                record: record
                            };
                    }
                    else if (typeof (e) !== "object") {
                        if (this.objType === "flag") {
                            var flags = this.$filters.getFlags(e);
                            let flagSource = this.originalDS.filter(x => flags.indexOf(x.id) !== -1);

                            data = {
                                value: e,
                                text: flagSource.map(x => x.text),
                                record: flags
                            };
                        }
                        else if (this.sourceType.toLowerCase() === "store") {
                            this.$store.dispatch(this.source + "/getAll", { sort: null, where: null }).then(() => {
                                let record = this.$filters.init((this.originalDS ?? this.dataSource.data).filter(x => x[this.valueField] === e)[0]);
                                if (record)
                                    data = {
                                        value: record[this.valueField],
                                        text: record[this.textField],
                                        record: record
                                    };
                                this.model.value = data.value;
                                this.model.text = data.text;
                                this.model.record = data.record;
                                this.$emit("is-sync", data);
                            });
                        }
                        else if (this.sourceType.toLowerCase() === "url") {
                            this.setDataSource().then((response) => {
                                let record = this.$filters.init(response.filter(x => x[this.valueField] === e)[0]);
                                if (record)
                                    data = {
                                        value: record[this.valueField],
                                        text: record[this.textField],
                                        record: record
                                    };
                                this.model.value = data.value;
                                this.model.text = data.text;
                                this.model.record = data.record;
                                this.$emit("is-sync", data);
                            });
                        }
                        else {
                            let record = this.$filters.init(this.originalDS.filter(x => x[this.valueField] === e)[0]);
                            if (record)
                                data = {
                                    value: record[this.valueField],
                                    text: record[this.textField],
                                    record: record
                                };
                        }
                    }
                    if (this.sourceType.toLowerCase() !== "store" && this.sourceType.toLowerCase() !== "url") {
                        this.model.value = data.value;
                        this.model.text = data.text;
                        this.model.record = data.record;
                        this.$emit("is-sync", data);
                    }
                }
                else
                    this.$emit("is-sync", { value: null, text: null, record: null })
            },
            async getAll() { return this.$store.dispatch(this.source + "/getAll", { sort: null, where: null }); },
            applyFilter(request) {
                const self = this;
                if (this.originalDS === [])
                    this.filteredDS = [];
                else if (request.query === '.')
                    this.filteredDS = this.originalDS;
                else {
                    switch (self.filter.toLowerCase()) {
                        case "startswith":
                            this.filteredDS = (this.originalDS ?? this.dataSource.data).filter(source => source[self.textField] ? source[self.textField].toLowerCase().startsWith(request.query.toLowerCase()) : false);
                            if (this.filteredDS.length === 0) {
                                for (let i = 0; i < self.filterFieldList.length; i++) {
                                    this.filteredDS = (this.originalDS ?? this.dataSource.data).filter(source => source[self.filterFieldList[i]] ? source[self.filterFieldList[i]].toLowerCase().startsWith(request.query.toLowerCase()) : false);
                                    if (this.filteredDS.length > 0)
                                        i = this.filterFieldList.length;
                                }
                            }
                            break;
                        case "endswith":
                            this.filteredDS = (this.originalDS ?? this.dataSource.data).filter(source => source[self.textField] ? source[self.textField].toLowerCase().endsWith(request.query.toLowerCase(), request.query.length()) : false);
                            if (this.filteredDS.length === 0) {
                                for (let i = 0; i < self.filterFieldList.length; i++) {
                                    this.filteredDS = (this.originalDS ?? this.dataSource.data).filter(source => source[self.filterFieldList[i]] ? source[self.filterFieldList[i]].toLowerCase().endsWith(request.query.toLowerCase()) : false);
                                    if (this.filteredDS.length > 0)
                                        i = this.filterFieldList.length;
                                }
                            }
                            break;
                        default:
                            this.filteredDS = (this.originalDS ?? this.dataSource.data).filter(source => source[self.textField] ? source[self.textField].toLowerCase().indexOf(request.query.toLowerCase()) >= 0 : false);
                            if (this.filteredDS.length === 0) {
                                for (let i = 0; i < self.filterFieldList.length; i++) {
                                    this.filteredDS = (this.originalDS ?? this.dataSource.data).filter(source => source[self.filterFieldList[i]] ? source[self.filterFieldList[i]].toLowerCase().indexOf(request.query.toLowerCase()) >= 0 : false);
                                    if (this.filteredDS.length > 0)
                                        i = this.filterFieldList.length;
                                }
                            }

                            break;
                    }
                    this.filteredDS = this.filteredDS.sort(this.dynamicSort(this.textField));
                }
            },
            dynamicSort(property) {
                let sortOrder = 1;
                if (property[0] === "-") {
                    sortOrder = -1;
                    property = property.substr(1);
                }
                return (a, b) => {
                    let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                    return result * sortOrder;
                }
            },
            async setDataSource() {
                let source = [];
                if (typeof (this.source) === "string" && this.source.toLowerCase() === "currency")
                    source = this.$vm.$currencies;
                else if (typeof (this.source) === "string" && this.source.toLowerCase() === "country")
                    source = this.$vm.$countries;
                else if (this.objType === "enum" || this.objType === "flag") {
                    source = this.$vm.$enums[this.source];
                    if (this.allowedFlags > 0)
                        source = this.$vm.$enums[this.source].filter(x => this.$filters.getFlags(this.allowedFlags).indexOf(x.id) != -1);
                }
                else if (this.dsType === "array")
                    source = this.source;
                else if (this.dsType === "store")
                    await this.getAll().then(() => { source = this.$store.getters[this.source + "/ds"].data; this.originalDS = source ?? [] });
                else if (this.dsType === "url" && typeof (this.source) === "string") {
                    let url = (this.source.toLowerCase().startsWith("http://") || this.source.toLowerCase().startsWith("https://"))
                        ? this.source
                        : `${config.config.endpoint.api}/${('⌐' + this.source).replace('⌐/', '').replace('⌐', '')}`;
                    if (url.indexOf("undefined") !== -1) {
                        source = []
                    }
                    else if (url.indexOf("null") !== -1) {
                        source = []
                    }
                    else {
                        await this.$axios.get(url)
                            .then((response) => { source = response.data.result ? response.data.result : response.data })
                            .catch(() => source = []);
                    }
                }
                this.originalDS = source ?? [];
                return (this.sorted && ((typeof (this.source) === "string") ? (this.source?.toLowerCase() !== "currency" && this.source?.toLowerCase() !== "country") : true)) ? source.sort(this.dynamicSort(this.textField)) : source;
            }
        },
        async created() {
            this.model.value = this.value;
            this.setDataSource()
                .then((response) => {
                    this.activity.loading.object = false;
                    this.originalDS = response;
                    this.onSync(this.value);
                }).catch(() => { this.activity.loading.object = false; });
        }
    }
</script>
<style>
    .p-float-label .p-dropdown.p-hidelabel .p-dropdown-label.p-placeholder, .p-float-label .p-multiselect.p-hidelabel .p-multiselect-label.p-placeholder {
        visibility: unset;
    }
</style>