<template>
    <pv-tab>
        <pv-tabpanel v-if="(tradeType & 1) === 1">
            <template #header>
                <i class="fa fa-arrow-up" style="margin-right: 0.5rem;" />
                <span>Export</span>
            </template>
            <pv-tab :activeIndex="activeTab">
                <pv-tabpanel>
                    <template #header>
                        <span>Base Charge</span>
                    </template>
                    <template-master :template="template" :effectiveDateID="effectiveDateID" :parentID="parentID" :zoneID="zoneID" :tradeType="1" :categoryType="100" :weightUnit="weightUnit" :currencyCode="currencyCode" :countryCode="countryCode" :providerID="providerID" :copyFrom="baseRateID" />
                </pv-tabpanel>
                <pv-tabpanel>
                    <template #header>
                        <span>Excess Charges</span>
                    </template>
                    <template-master :template="template" :effectiveDateID="effectiveDateID" :parentID="parentID" :zoneID="zoneID" :tradeType="1" :categoryType="200" :weightUnit="weightUnit" :currencyCode="currencyCode" :countryCode="countryCode" :providerID="providerID" :copyFrom="excessRateID" />
                </pv-tabpanel>
                <pv-tabpanel>
                    <template #header>
                        <span>Other/Premium Charges</span>
                    </template>
                    <template-master :template="template" :effectiveDateID="effectiveDateID" :parentID="parentID" :zoneID="zoneID" :tradeType="1" :categoryType="300" :weightUnit="weightUnit" :currencyCode="currencyCode" :countryCode="countryCode" :providerID="providerID" :copyFrom="premiumRateID" />
                </pv-tabpanel>
                <pv-tabpanel v-if="template===200 || template===400">
                    <template #header>
                        <span>Minimum Revenue</span>
                    </template>
                    <template-master :template="template" :effectiveDateID="effectiveDateID" :parentID="parentID" :zoneID="zoneID" :tradeType="1" :categoryType="400" :weightUnit="weightUnit" :currencyCode="currencyCode" :countryCode="countryCode" :providerID="providerID" :copyFrom="premiumRateID" />
                </pv-tabpanel>
            </pv-tab>
        </pv-tabpanel>
        <pv-tabpanel v-if="(tradeType & 2) === 2">
            <template #header>
                <i class="fa fa-arrow-down" style="margin-right: 0.5rem;" />
                <span>Import</span>
            </template>
            <pv-tab :activeIndex="activeTab">
                <pv-tabpanel>
                    <template #header>
                        <span>Base Charge</span>
                    </template>
                    <template-master :template="template" :effectiveDateID="effectiveDateID" :parentID="parentID" :zoneID="zoneID" :tradeType="2" :categoryType="100" :weightUnit="weightUnit" :currencyCode="currencyCode" :countryCode="countryCode" :providerID="providerID" :copyFrom="baseRateID" />
                </pv-tabpanel>
                <pv-tabpanel>
                    <template #header>
                        <span>Excess Charges</span>
                    </template>
                    <template-master :template="template" :effectiveDateID="effectiveDateID" :parentID="parentID" :zoneID="zoneID" :tradeType="2" :categoryType="200" :weightUnit="weightUnit" :currencyCode="currencyCode" :countryCode="countryCode" :providerID="providerID" :copyFrom="excessRateID" />
                </pv-tabpanel>
                <pv-tabpanel>
                    <template #header>
                        <span>Other/Premium Charges</span>
                    </template>
                    <template-master :template="template" :effectiveDateID="effectiveDateID" :parentID="parentID" :zoneID="zoneID" :tradeType="2" :categoryType="300" :weightUnit="weightUnit" :currencyCode="currencyCode" :countryCode="countryCode" :providerID="providerID" :copyFrom="premiumRateID" />
                </pv-tabpanel>
                <pv-tabpanel v-if="template===200 || template===400">
                    <template #header>
                        <span>Minimum Revenue</span>
                    </template>
                    <template-master :template="template" :effectiveDateID="effectiveDateID" :parentID="parentID" :zoneID="zoneID" :tradeType="2" :categoryType="400" :weightUnit="weightUnit" :currencyCode="currencyCode" :countryCode="countryCode" :providerID="providerID" :copyFrom="premiumRateID" />
                </pv-tabpanel>
            </pv-tab>
        </pv-tabpanel>
        <pv-tabpanel v-if="(tradeType & 4) === 4">
            <template #header>
                <i class="fa fa-exchange-alt" aria-hidden="true" style="margin-right: 0.5rem;" />
                <span>Third Country</span>
            </template>
            <pv-tab :activeIndex="activeTab">
                <pv-tabpanel>
                    <template #header>
                        <span>Base Charge</span>
                    </template>
                    <template-master :template="template" :effectiveDateID="effectiveDateID" :parentID="parentID" :zoneID="zoneID" :tradeType="4" :categoryType="100" :weightUnit="weightUnit" :currencyCode="currencyCode" :countryCode="countryCode" :providerID="providerID" :copyFrom="baseRateID" />
                </pv-tabpanel>
                <pv-tabpanel>
                    <template #header>
                        <span>Excess Charges</span>
                    </template>
                    <template-master :template="template" :effectiveDateID="effectiveDateID" :parentID="parentID" :zoneID="zoneID" :tradeType="4" :categoryType="200" :weightUnit="weightUnit" :currencyCode="currencyCode" :countryCode="countryCode" :providerID="providerID" :copyFrom="excessRateID" />
                </pv-tabpanel>
                <pv-tabpanel>
                    <template #header>
                        <span>Other/Premium Charges</span>
                    </template>
                    <template-master :template="template" :effectiveDateID="effectiveDateID" :parentID="parentID" :zoneID="zoneID" :tradeType="4" :categoryType="300" :weightUnit="weightUnit" :currencyCode="currencyCode" :countryCode="countryCode" :providerID="providerID" :copyFrom="premiumRateID" />
                </pv-tabpanel>
                <pv-tabpanel v-if="template===200 || template===400">
                    <template #header>
                        <span>Minimum Revenue</span>
                    </template>
                    <template-master :template="template" :effectiveDateID="effectiveDateID" :parentID="parentID" :zoneID="zoneID" :tradeType="4" :categoryType="400" :weightUnit="weightUnit" :currencyCode="currencyCode" :countryCode="countryCode" :providerID="providerID" :copyFrom="premiumRateID" />
                </pv-tabpanel>
            </pv-tab>
        </pv-tabpanel>
    </pv-tab>
</template>
<script>
    import templatemaster from '@/components/facility/rates/contract/templates/template-form.vue';
    export default {
        name: "RateTabTemplates",
        components: {
            'template-master': templatemaster
        },
        props: {
            template: { type: Number },
            parentID: { type: Number },
            effectiveDateID: { type: Number },
            providerID: { type: Number },
            zoneID: { type: Number },
            tradeType: { type: Number },
            weightUnit: { type: Number },
            currencyCode: { type: String },
            countryCode: { type: String },
            baseRateID: { type: Number },
            excessRateID: { type: Number },
            premiumRateID: { type: Number }
        },
        computed: {
            activeTab() {
                return this.baseRateID === null ? 0 : (this.excessRateID === null ? 1 : (this.premiumRateID === null ? 2 : 0));
            }
        }
    }
</script>