<template>
    <h3>Zone Details</h3>
    <div class="card" style="background:var(--surface-c); border-radius: 4px;">
        <div class="p-grid">
            <div class="p-col-fixed" style="width:150px; font-weight: bold;">Code</div>
            <div class="p-col">: <span class="p-code-text">{{record?.code}}</span></div>
        </div>
        <div class="p-grid">
            <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Name</div>
            <div class="p-col">: <span class="p-code-text">{{record?.name}}</span></div>
        </div>
        <div class="p-grid">
            <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Service Provider</div>
            <div class="p-col">: <span class="p-code-text">{{record?.provider?.name}}</span></div>
        </div>
        <div class="p-grid">
            <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Contract Country</div>
            <div class="p-col">: <span class="p-code-text">{{record?.contractCountry?.name ?? $vm.$location.country_name }}</span></div>
        </div>
    </div>
    <h3>Zone Names</h3>
    <div class="card" style="background:var(--surface-c); border-radius: 4px;">
        <pv-grid :value="this.$attrs.formData.zoneNames" showGridlines>
            <pv-grid-column field="code" header="Code" headerStyle="min-width: 10rem; max-width: 10rem; width: 10rem;" style="border-left: 1px solid var(--surface-d); padding: 0 5px;" />
            <pv-grid-column field="name" header="Name" />
            <pv-grid-column field="tradeTypeNames" header="Trade Types" />
        </pv-grid>
    </div>
    <h3>Zone Group</h3>
    <div class="card" style="background:var(--surface-c); border-radius: 4px;">
        <pv-grid :value="this.$attrs.formData.zoneGroups" showGridlines>
            <pv-grid-column field="code" header="Code" headerStyle="min-width: 10rem; max-width: 10rem; width: 10rem;" style="border-left: 1px solid var(--surface-d); padding: 0 5px;" />
            <pv-grid-column field="name" header="Name" />
            <pv-grid-column field="tradeTypeNames" header="Trade Types" />
        </pv-grid>
    </div>
    <h3>Zone Matrix</h3>
    <div class="card" style="background:var(--surface-c); border-radius: 4px;">
        <pv-grid :value="this.$attrs.formData.zoneMatrix" showGridlines>
            <pv-grid-column field="name" header="Zone Group" />
            <pv-grid-column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field">
                <template #body="slotProps">
                    {{ slotProps.data[col.field]?.name }}
                </template>
            </pv-grid-column>
        </pv-grid>
    </div>
    <div class="p-grid p-nogutter p-justify-between">
        <pv-button label="Previous" @click="prevPage()" icon="pi pi-angle-left" />
        <pv-button label="Complete" @click="complete()" icon="pi pi-check" iconPos="right" class="p-button-success" />
    </div>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from '@vuelidate/validators';
    export default {
        name: 'StepCredential',
        emits:["next-page"],
        setup: () => ({ v$: useVuelidate() }),
        props: {
            id: { type: String },
            integrationMask: { type: String }
        },
        data() {
            return {
                credentialFields: [],
                model: {
                    code: null,
                    name: null,
                    contractCountry: { id: 0 },
                    provider: { id: 0},
                    providerCarrier: { id: 0 },
                },
                credential: {},
                activity: {
                    loading: false
                },
                record:  null
            }
        },
        validations() {
            return {
                model: {
                    accountType: { required, $autoDirty: true },
                    tradeType: { $autoDirty: true },
                    code: { required, minLength: minLength(1), maxLength: maxLength(20), $autoDirty: true },
                    name: { required, maxLength: maxLength(50), $autoDirty: true },
                    serviceProviderID: { required, $autoDirty: true },
                    integrationID: { required, $autoDirty: true },
                    contractCountryID: { required, $autoDirty: true },
                }
            }
        },
        watch: {
            '$attrs.formData': {
                handler() { console.log(this.$attrs.formData) }, deep: true 
            }
        },
        computed: {
            recordx() {
                return this.$attrs.formData;
            },
            zoneNames() {
                let data = this.$attrs.formData?.zoneNames ?? [];
                return data;
            },
            zoneGroups() { return this.$attrs.formData.zoneGroups; },
            zoneLookup() { return this.$attrs.formData.zoneNames; },
            nextIcon() {
                if (this.activity.loading)
                    return "pi pi-spin pi-spinner";
                return "pi pi-angle-right";
            },
            nextLabel() {
                if (this.activity.loading)
                    return "Processing...";
                return "Next";
            },
            nextPosition() {
                if (this.activity.loading)
                    return "left";
                return "right";
            },
            blockNext() {
                let block = false;
                if (this.model.code === null) return true;
                if (this.model.name === null) return true;
                if (this.model.serviceProviderID === null) return true;
                if (this.model.integrationID === null) return true;
                if (this.model.contractCountryID === null) return true;
                if (this.model.tradeType === 0) return true;

                this.credentialFields.forEach((credential) => {
                    if(credential.validation.required && this.model[this.$filters.decamelize(credential.field)] === null)
                        block = true;
                });
                return block;
            },
            columns() {
                var columns = []
                for (let i = 0; i < this.zoneGroups.length; i++) {
                    columns.push({
                        field: `zoneName_${this.$filters.padLeft(i + 1, 2, '0')}`,
                        header: this.zoneGroups[i].name,
                        columnPosition: i
                    });
                }
                return columns;
            }
        },
        methods: {
            onSync(event) {
                this.credentialFields = event.fields;
                for (let field in event.model)
                    this.model[field] = event.model[field];
            },
            prevPage() {
                this.$emit('prev-page', { pageIndex: 3 });
            },
            complete() {
                this.$emit('complete', this.$attrs.formData);
            }
        },
        created() {
            this.record = this.$attrs.formData;
        },
        mounted() {
            this.record = this.$attrs.formData;
        },
        updated() {
            this.record = this.$attrs.formData;
        }
    }
</script>


<style>
    .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
        background-color: var(--surface-solid);
    }
</style>