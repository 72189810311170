<template>
    <pv-dialog :header="'Find Address Book'" :visible="showDialog" :modal="true" :closable="false" :closeOnEscape="false" :draggable="true">
        <div class="confirmation-content">
            <slot>
                <div class="p-field p-grid" style="width: 500px;">
                    <div class="p-col-12">
                        <form-lookup id="AddressBookLookupSearchText"  label="Search" type="autocomplete" :source="`/services/admin/addressbook/bycustomer/${customerID ?? 0}`" sourceType="url" :value="Search" text="Search"  @is-sync="onSelect($event)" v-focus />
                    </div>
                </div>
            </slot>
        </div>
        <template #footer>
            <pv-button label="Close" :icon="cancelIcon" @click="Cancel" class="p-button" />
        </template>
    </pv-dialog>
</template>
<script>

    export default {
        name: 'DialogTemplate',
        emits: ['is-selected', 'is-cancel'],
        props: {
            customerID: { type: Number, default: null },
            searchText: { type: String },
        },
        data() {
            return {
                showDialog: true,
                Search: null
            }
        },
        methods: {
            onSelect(e) {
                if (e.value !== undefined && e.value !== null) {
                    this.showDialog = false;
                    this.$emit("is-selected", e);
                    this.$emit("is-cancel");
                }
            },
            Confirm() {
                this.$emit("is-confirm");
            },
            Cancel() {
                this.showDialog = false;
                this.$emit("is-cancel");
            }
        },
        watch: {
            visible() {
                this.showDialog = this.visible;
            }
        },
        async mounted() {
            this.Search = this.searchText;
        },
        updated() {
          //  this.Search = this.searchText;
        },
        unmounted() {
           
        }
    }
</script>
<!--<style>
    .p-dialog .p-dialog-content {
        padding: 2rem 1.25rem !important;
    }
    div.p-dialog-header, div.p-dialog-footer {
        background-color: var(--surface-a) !important;
        padding: 0.75rem 1rem !important;
    }
    div.p-dialog-header {
        border-bottom: 1px solid var(--surface-d) !important;
    }
    div.p-dialog-footer {
        border-top: 1px solid var(--surface-d) !important;
    } 
    div.p-dialog-header > .p-dialog-title {
        font-size: 1rem !important;
    }
</style>-->