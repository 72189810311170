<template>
    <pv-card>
        <template #subtitle>
            Automatically assign the account to the customer.<br />
            This step is <b>optional</b> and can be assigned later.
        </template>
        <template #content>
            <customer-form :serviceProviderID="$attrs.formData.serviceProviderID" :formData="$attrs.formData" @is-sync="onSync($event)" />
        </template>
        <template #footer>
            <div class="p-grid p-nogutter p-justify-between">
                <pv-button label="Back" @click="prevPage()" icon="pi pi-angle-left" />
                <pv-button label="Next" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
            </div>
        </template>
    </pv-card>
</template>
<script>
    import customerform from '@/components/admin/provideraccount/customer-form.vue';
    import { useVuelidate } from '@vuelidate/core';
    export default {
        name: 'StepCustomer',
        emits: ["next-page", "prev-page"],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'customer-form': customerform
        },
        props: {
            id: { type: String },
            integrationMask: { type: String }
        },
        data() { return { model: [] } },
        methods: {
            onSync(event) { this.model = event.model; },
            nextPage() { this.$emit('next-page', { formData: { customers: this.model }, pageIndex: 3 }); },
            prevPage() { this.$emit('prev-page', { formData: { customers: this.model }, pageIndex: 3 }); },
        }
    }
</script>
