<template>
    <div>
        <form-lookup id="Platform" v-model:modelData="platform" v-model="model.platformID" :source="$vm.$platforms" :required="true" :disabled="disabled" disabledType="object" />
        <div v-for="credential in (platform?.credentialFields ?? [])" :key="credential.field">
            <form-input v-if="credential.component === 100"
                        v-model="model[credential.field.toLowerCase()]"
                        :id="credential.field"
                        :label="credential.label"
                        :ph="credential.placeholder"
                        :pattern="credential?.validation?.regExPattern?.replace('(?i)', '')"
                        :pattern-message="credential?.validation?.regExErrorMessage"
                        :max-length="credential?.validation?.maxLength"
                        :required="credential?.validation?.required ?? false"
                        :v$="v$"
                        :disabled="disabled"
                        :showCopy="false"
                        @is-sync="onSetProperty(credential.property, $event)" />
        </div>
    </div>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength, helpers, url, email } from '@vuelidate/validators';
    /** CUSTOM VALIDATOR **/
    const regex = (param) => helpers.withParams({ type: 'regex', value: param }, (value) => value.match(param) != null);
    export default {
        name: "PlatformCredentialForm",
        setup: () => ({ v$: useVuelidate({ $scope: true }) }),
        props: {
            modelValue: { type: Object, default: null },
            modelPlatform: { type: Object, default: null },
            modelInvalid: { type: Boolean, default: false },
            modelPayload: { type: Object, default: () => ({ PlatformID: null, Credential: () => { } }) },
            disabled: { type: Boolean, default: false }
        },
        data() {
            return {
                model: {
                    platformID: 1,
                    credential_01: null,
                    credential_02: null,
                    credential_03: null,
                    credential_04: null,
                    credential_05: null,
                    credential_06: null,
                    credential_07: null,
                    credential_08: null,
                    credential_09: null,
                    credential_10: null,
                    credential_11: null,
                    credential_12: null,
                    credential_13: null,
                    credential_14: null,
                    credential_15: null,
                    tokenIssued: null,
                    tokenExpiry: null
                },
                credentials: {},
                platform: null
            };
        },
        computed: {
            payload() { return { PlatformID: this.platform?.id, Credential: this.credentials } }
        },
        watch: {
            modelValue() { this.model = this.modelValue; },
            modelPlatform() { this.platform = this.modelPlatform; },
            modelCredential() { this.credentials = this.modelCredential; },
            platform() { this.onPlatformSync(); },
            model() { this.onSync(); },
            v$() { this.$emit("update:modelInvalid", this.v$.$error || this.v$.$invalid); }
        },
        validations() {
            /** DYNAMIC VALIDATION **/
            let state = {
                model: {
                    platformID: { $autoDirty: true },
                    credential_01: { $autoDirty: true },
                    credential_02: { $autoDirty: true },
                    credential_03: { $autoDirty: true },
                    credential_04: { $autoDirty: true },
                    credential_05: { $autoDirty: true },
                    credential_06: { $autoDirty: true },
                    credential_07: { $autoDirty: true },
                    credential_08: { $autoDirty: true },
                    credential_09: { $autoDirty: true },
                    credential_10: { $autoDirty: true },
                    credential_11: { $autoDirty: true },
                    credential_12: { $autoDirty: true },
                    credential_13: { $autoDirty: true },
                    credential_14: { $autoDirty: true },
                    credential_15: { $autoDirty: true },
                }
            };

            (this.platform?.credentialFields ?? []).forEach((data) => {
                let field = data.field.toLowerCase();
                let property = state.model[field];
                if (data.validation.required)
                    property.required = required;
                if (data.validation.minLength > 0)
                    property.minLength = minLength(data.validation.minLength);
                if (data.validation.maxLength > 0)
                    property.maxLength = maxLength(data.validation.maxLength);
                if (data.validation.regExPattern && data.validation.regExPattern === "ValidateURL")
                    property.url = url;
                else if (data.validation.regExPattern && data.validation.regExPattern === "ValidateEmail")
                    property.email = email;
                else if (data.validation.regExPattern && data.validation.regExPattern !== "ValidateURL")
                    property.regex = helpers.withMessage(data.validation.regExErrorMessage, regex(data.validation.regExPattern?.replace('(?i)', '')));
            });

            return state;
        },
        methods: {
            onSetProperty(property, e) {
                if (property && !this.disabled)
                    try {
                        this.credentials[property] = e.value;

                    }
                    catch { /* ignore */ }
            },
            onPlatformSync() {
                let model = this.$filters.init(this.model);
                let properties = (this.platform?.credentialFields ?? []).map(x => x.field.toLowerCase());
                for (let i = 1; i <= 15; i++) {
                    let property = "credential_" + i.toString().padStart(2, '0');
                    if (properties.filter(x => x === property).length === 0)
                        model[property] = null;
                }
                this.model = model;
            },
            onSync() {
                this.$emit("update:modelValue", this.model);
                if (!this.disabled)
                    this.$emit("update:modelPayload", this.payload);
                this.$emit("update:modelPlatform", this.platform);
                this.$emit("is-sync", this.model);
            }
        },
        created() {
            if (this.modelValue)
                this.model = this.modelValue;
            if (this.modelPlatform) {
                this.platform = this.modelPlatform;
                this.onPlatformSync();
            }
            if (this.modelPayload)
                this.credentials = this.modelPayload.Credentials;
        }
    }
</script>
