<template>
    <div class="layout-wrapper">
        <div class="layout-sidebar" style="padding: 0;">
            <pv-panelmenu :model="items" />
        </div>
        <div class="layout-content" >
            <div class="content-section header">
                <div class="feature-intro">
                    <h1><span>Shippers</span></h1>
                    <p>These are contracts that we create.</p>
                </div>
            </div>
            <div class="content-section body" style="min-height: calc(100vh - 200px);">
                <div class="card">
                    <is-input id="code"></is-input>
                    <is-input id="name"></is-input>
                    <is-address id="shipToAddress"></is-address>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterMatchMode, FilterOperator } from 'primevue/api';
    export default {
        name: 'Dashboard',

        data() {
            return {
                countries: null,
                selectedCountry2: null,
                filteredCountries: null,
                loading: true,
                records: null,
                flags: null,
                selectedCustomers: null,
                currencyId: 0,
                filters: {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'representative': { value: null, matchMode: FilterMatchMode.IN },
                    'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                    'balance': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'activity': { value: null, matchMode: FilterMatchMode.BETWEEN },
                    'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
                },
                columns: [
                    { field: 'name', header: 'Name' },
                    { field: 'portalURL', header: 'URL' },
                    { field: 'apiurl', header: 'API' },
                    { field: 'dbName', header: 'DB Name' },
                    { field: 'requireSSL', header: 'Require SSL' },
                    { field: 'modules', header: 'Modules' },
                    { field: 'name', header: 'Name' },
                    { field: 'portalURL', header: 'URL' },
                    { field: 'apiurl', header: 'API' },
                    { field: 'dbName', header: 'DB Name' },
                    { field: 'requireSSL', header: 'Require SSL' },
                    { field: 'modules', header: 'Modules' }, { field: 'name', header: 'Name' },
                    { field: 'portalURL', header: 'URL' },
                    { field: 'apiurl', header: 'API' },
                    { field: 'dbName', header: 'DB Name' },
                    { field: 'requireSSL', header: 'Require SSL' },
                    { field: 'modules', header: 'Modules' }, { field: 'name', header: 'Name' },
                    { field: 'portalURL', header: 'URL' },
                    { field: 'apiurl', header: 'API' },
                    { field: 'dbName', header: 'DB Name' },
                    { field: 'requireSSL', header: 'Require SSL' },
                    { field: 'modules', header: 'Modules' },                ],
                config: {
                    toolbar: {
                        toolbarViewerLeft: { findbar: false },
                        toolbarViewerRight: { print: false, openFile: false, viewBookmark: false }
                    }
                },
                pdf: "https://example.com/sample.pdf",
                items: [
                    { label: 'Dashboard', icon: 'pi pi-fw pi-home', command: () => { } },
                    {
                        label: 'Settings',
                        icon: 'fa fa-fw fa-cogs',
                        items: [{ label: 'Integrations', icon: 'pi pi-fw pi-cog', to: '/settings/integrations' },
                        { label: 'Tenants', icon: 'fa fa-fw fa-globe', to: '/settings/tenants' },
                        { label: 'Contracts', icon: 'pi pi-fw pi-power-off', to: '/settings/contracts', items: null }]
                    },
                    {
                        label: 'Consignments',
                        icon: 'fa fa-fw fa-truck',
                        items: [{ label: 'Shipmemts', icon: 'pi pi-fw pi-plus', command: () => { } },
                        {
                            label: 'Collection', icon: 'pi pi-fw pi-trash',
                            items: [{ label: 'Book', icon: 'pi pi-fw pi-plus', command: () => { } },
                            { label: 'Cancel', icon: 'pi pi-fw pi-trash', url: 'http://primetek.com.tr' }]

                        }]
                    },
                    {
                        label: 'Facilities',
                        icon: 'pi pi-fw pi-th-large',
                        items: [{ label: 'Import Facility', icon: 'pi pi-fw pi-cog', to: '/options' },
                        { label: 'Rates Facility', icon: 'pi pi-fw pi-cog', to: '/options' },
                        { label: 'Service Group Facility', icon: 'pi pi-fw pi-cog', to: '/options' },
                        { label: 'Report Facility', icon: 'pi pi-fw pi-cog', to: '/options' },
                        { label: 'Dashboard Facility', icon: 'pi pi-fw pi-cog', to: '/options' },
                        { label: 'Invoice Facility', icon: 'pi pi-fw pi-cog', to: '/options' },
                        { label: 'Printer Facility', icon: 'pi pi-fw pi-cog', to: '/options' }]
                    },
                    {
                        label: 'Users',
                        icon: 'pi pi-fw pi-users',
                        items: [{ label: 'Global Administrators', icon: 'pi pi-fw pi-cog', to: '/options' },
                        { label: 'System Administrators', icon: 'pi pi-fw pi-cog', to: '/options' },
                        { label: 'Administrators', icon: 'pi pi-fw pi-cog', to: '/options' },
                        { label: 'Users', icon: 'pi pi-fw pi-cog', to: '/options' },
                        { label: 'Customers', icon: 'pi pi-fw pi-cog', to: '/options' },
                        { label: 'Retailers', icon: 'pi pi-fw pi-cog', to: '/options' }]
                    },
                    {
                        label: 'Admin',
                        icon: 'pi pi-fw pi-list',
                        items: [{ label: 'New', icon: 'pi pi-fw pi-plus', command: () => { } },
                        { label: 'Delete', icon: 'pi pi-fw pi-trash', url: 'http://primetek.com.tr' }]
                    },
                    {
                        label: 'Integro360 UK Limited',
                        icon: 'pi pi-fw pi-star',
                        items: [{ label: 'Forwarders', icon: 'pi pi-fw pi-cog', to: '/options' },
                        { label: 'Warehouses', icon: 'pi pi-fw pi-cog', to: '/options' },
                        { label: 'Customers', icon: 'pi pi-fw pi-cog', to: '/options' },
                        { label: 'Retailers', icon: 'pi pi-fw pi-cog', to: '/options' },
                        { label: 'Departments', icon: 'pi pi-fw pi-cog', to: '/options' },
                        { label: 'Teams', icon: 'pi pi-fw pi-cog', to: '/options' }]
                    },
                ]
            }
        },
        computed: mapGetters({
            ds: "tenant/ds",
            dsCountry: "country/ds"
        }),
        methods: {
            ...mapActions({
                getAll: "tenant/getAll",
                getAllCountry: "country/getAll"
            }),
            onPage(event){
                console.log(event);
            },
            searchCountry(event) {
                setTimeout(() => {
                    if (!event.query.trim().length) {
                        this.filteredCountries = [...this.countries];
                    }
                    else {
                        this.filteredCountries = this.countries.filter((country) => {
                            return (country.altName ?? country.name).toLowerCase().indexOf(event.query.toLowerCase()) > -1;
                        });
                    }
                }, 250);
            },
        },
        mounted() {
            this.getAllCountry()
                .then(() => {
                    this.countries = this.dsCountry.data;
                    this.loading = false;
                });

            this.getAll()
                .then(() => {
                    this.records = this.ds.data;
                    this.loading = false;
                });

            //if (!this.user)
            //    this.$router.push({ path: '/account/login' });
            //else
            //    this.$emit('is-sync', true);
            //this.$toast.add({ severity: 'info', summary: 'Sticky Message', detail: 'Message Content'/*, life: 3000*/ });
            //this.$toast.add({ severity: 'success', summary: 'Sticky Message', detail: 'Message Content'/*, life: 3000*/ });
            //this.$toast.add({ severity: 'warn', summary: 'Sticky Message', detail: 'Message Content'/*, life: 3000*/ });
            //this.$toast.add({ severity: 'error', summary: 'Sticky Message', detail: 'Message Content'/*, life: 3000*/ });
        },
    }
</script>

<!--<style>
    thead > tr > th {
        background: var(--surface-f) !important;
        text-transform: uppercase;
        background-blend-mode: multiply;
            
    }


</style>-->