<template>
    <form-record :model="model" :modelState="v$" @is-reset="resetRecord" :showCloneButton="true">
        <div class="p-d-flex p-grid">
            <div class="p-col-12">
                <consignment-edit v-if="((model?.id ?? 0) >= 0) && ((model?.status ?? 100) === 100)" :formData="model" @is-allocate="onAllocate($event)"></consignment-edit>
                <consignment-view v-if="((model?.id ?? 0) > 0) && ((model?.status ?? 0) > 100) && (model?.primaryCarrierReference)" :formData="model" @is-deallocate="onDeAllocate($event)"></consignment-view>
            </div>
        </div>
    </form-record>
</template>
<script>
    import { mapGetters } from 'vuex';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import consignmentedit from '@/components/transaction/tms/consignment/edit-form.vue';
    import consignmentview from '@/components/transaction/tms/consignment/view-form.vue';

    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from '@vuelidate/validators';

    const moduleName = 'consignment';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'consignment-edit': consignmentedit,
            'consignment-view': consignmentview,
        },
        data() {
            return {
                model: null
            }
        },
        
        validations() {
            return {
                model: {
                    //    code: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },
                    customerReference: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                    customerReference2: { maxLength: maxLength(35), $autoDirty: true },
                    customerReference3: { maxLength: maxLength(35), $autoDirty: true },
                    customerID: { $autoDirty: true },
                    shipTo_FirstName: { $autoDirty: true },
                    shipTo_LastName: { $autoDirty: true },
                    shipTo_Company: { $autoDirty: true },
                    shipTo_CountryID: { $autoDirty: true },
                    shipTo_Address1: { $autoDirty: true },
                    shipTo_Address2: { $autoDirty: true },
                    shipTo_Address3: { $autoDirty: true },
                    shipTo_City: { $autoDirty: true },
                    shipTo_State: { $autoDirty: true },
                    shipTo_PostalCode: { $autoDirty: true },
                    shipTo_EstablishmentType: { $autoDirty: true },
                    shipTo_Telephone: { $autoDirty: true },
                    shipTo_Mobile: { $autoDirty: true },
                    shipTo_Email: { $autoDirty: true },
                    shipTo_TaxId: { $autoDirty: true },
                    shipTo_EORI: { $autoDirty: true },
                    shipTo_IOSSNumber: { $autoDirty: true },
                    shipTo_IdentityNumber: { $autoDirty: true },
                    shipTo_IdentityNumberType: { $autoDirty: true },
                    shipFrom_FirstName: { $autoDirty: true },
                    shipFrom_LastName: { $autoDirty: true },
                    shipFrom_Company: { $autoDirty: true },
                    shipFrom_CountryID: { $autoDirty: true },
                    shipFrom_Address1: { $autoDirty: true },
                    shipFrom_Address2: { $autoDirty: true },
                    shipFrom_Address3: { $autoDirty: true },
                    shipFrom_City: { $autoDirty: true },
                    shipFrom_State: { $autoDirty: true },
                    shipFrom_PostalCode: { $autoDirty: true },
                    shipFrom_EstablishmentType: 100,
                    shipFrom_Telephone: { $autoDirty: true },
                    shipFrom_Mobile: { $autoDirty: true },
                    shipFrom_Email: { $autoDirty: true },
                    shipFrom_TaxId: { $autoDirty: true },
                    shipFrom_EORI: { $autoDirty: true },
                    shipFrom_IOSSNumber: { $autoDirty: true },
                    shipFrom_IdentityNumber: { $autoDirty: true },
                    shipFrom_IdentityNumberType: { $autoDirty: true },
                    pickupType: { $autoDirty: true },
                    packageType: { $autoDirty: true },
                    paymentMode: { $autoDirty: true },
                    exportReason: { $autoDirty: true },
                    shipmentType: { $autoDirty: true },
                    incoterm: { $autoDirty: true },
                    //currencyId: { $autoDirty: true },
                    weightUnit: { $autoDirty: true },
                    dimensionUnit: { $autoDirty: true },
                    isHazardous: { $autoDirty: true },
                    dG_EmergencyContact: { $autoDirty: true },
                    dG_EmergencyTelephone: { $autoDirty: true },
                    isInsured: { $autoDirty: true },
                    //isPaperlessTrade: { $autoDirty: true },
                    notification: { $autoDirty: true },
                    safePlace: { $autoDirty: true },
                    shippingCost: { $autoDirty: true },
                    deliveryInstruction: { $autoDirty: true },
                    packageContent: { $autoDirty: true },
                    invoiceNumber: { $autoDirty: true },
                    //invoiceDate: { $autoDirty: true },
                    //invoiceDateValue: { $autoDirty: true },
                    invoiceNote: { $autoDirty: true },
                    cpcNumber: { $autoDirty: true }
                }
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),  
        },
        methods: {
            onAllocate(data) {
                this.model = data;
                this.resetModelState();
                setTimeout(() => this.$emit("is-reset"), 100);
            },
            onDeAllocate(data) {
                this.$nextTick(() => {
                    this.model = data;
                    this.resetModelState();
                    setTimeout(() => this.$emit("is-reset"), 100);
                });
            },
        },
        watch: {   
            $route(to, from) {
                if (
                    (to?.params?.allocated ?? from?.params?.allocated ?? false) ||(to?.params?.deallocated ?? from?.params?.deallocated ?? false) || (to?.params?.cloned ?? from?.params?.cloned ?? false)
                    ) {
                    this.$nextTick(() => {
                        this.model = this.init;
                        if (to?.params.mask ?? from?.params.mask)
                            this.get(to?.params.mask ?? from?.params.mask).then((response) => {
                                this.model = response?.result ?? this.record;
                                this.$nextTick(() => { this.v$.$reset(); });
                            });
                        this.activity.submitted = this.$vm.$submitted = false;
                    });
                }
                
            },
            'isModelDirty': {
                handler() {
                    this.$nextTick(() => {
                        this.isModelDirty = (((this.model?.status ?? 0) > 100 && ((this.model?.primaryCarrierReference ?? "").length > 0)) ? true : this.isModelDirty);
                    });
                }, immediate:true
            },
            'model.status': {
                handler() {
                    this.$nextTick(() => {
                        this.isModelDirty = (((this.model?.status ?? 0) > 100 && ((this.model?.primaryCarrierReference ?? "").length > 0)) ? true : this.isModelDirty);
                    });
                }, immediate: true
            }
        }
    }
</script>
<style scoped>
    span.custom-marker {
        display: flex;
        border-radius: 50%;
        height: 2rem;
        width: 2rem;
        z-index: 1;
        justify-content: center;
        -webkit-box-align: center;
        box-shadow: 1px 1px #888888;
    }

        span.custom-marker > i {
            float: right;
        }

    .p-timeline-left .p-timeline-event-opposite {
        text-align: left;
    }

    .responsive-iframe {
        width: 100%;
        height: 450px;
    }
    .picklist-item {
        display: flex;
        align-items: center;
        padding: .2rem;
        width: 100%;
    }
    .picklist-list-detail {
        flex: 1 1 0;
    }
    .picklist-list-action {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .custom-skeleton {
        border: 1px solid var(--surface-d);
        border-radius: 4px;
    }
    .custom-skeleton ul {
        list-style: none;
    }

    .custom-skeleton ul > li {
        padding: 0.75rem;
    }
    .p-picklist-header {
        padding: 0 10px !important;
    }

</style>
<style>
    .p-orderlist-controls,
    .p-picklist-buttons.p-picklist-source-controls,
    .p-picklist-buttons.p-picklist-source-controls, .p-picklist-buttons.p-picklist-target-controls {
        display: none !important;
    }
</style>