<template>
    <p style="color: var(--text-gray-60); margin-top: 10px;"><b>STEP 2: </b> Zone  Name refers to the actual rate zone where the rates will be retrieved.</p>
    <zonename-form :zoneID="this.$props.zoneID" @is-sync="onSync($event)" style="margin-top: 20px;" />
    <div class="p-grid p-nogutter p-justify-between" style="border-top: 2px solid var(--surface-d); margin: 10px -20px -10px -20px; padding: 20px 20px 0 20px; ">
        <pv-button label="Back" @click="prevPage()" icon="pi pi-angle-left" />
        <pv-button label="Next" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" :disabled="activity.loading || blockNext" />
    </div>
</template>
<script>
    import zonenameform from '@/components/settings/zone/zonename-form.vue';
    import { useVuelidate } from '@vuelidate/core';
    export default {
        name: 'StepCredential',
        emits: ["next-page"],
        setup: () => ({ v$: useVuelidate() }),
        components: { 'zonename-form': zonenameform },
        props: {
            id: { type: String },
            zoneID: { type: String }
        },
        data() {
            return {
                credentialFields: [],
                model: {
                    id: 0,
                    code: null,
                    name: null,
                    tradeTypes: 3,
                    columnPosition: 1
                },
                zoneNames: [],
                credential: {},
                activity: {
                    loading: false
                }
            }
        },
        computed: {
            nextIcon() {
                if (this.activity.loading)
                    return "pi pi-spin pi-spinner";
                return "pi pi-angle-right";
            },
            nextLabel() {
                if (this.activity.loading)
                    return "Processing...";
                return "Next";
            },
            nextPosition() {
                if (this.activity.loading)
                    return "left";
                return "right";
            },
            blockNext() {
                if ((this.zoneNames ?? []).length === 0)
                    return true;
                return false;
            }
        },
        methods: {
            onSync(event) { this.zoneNames = event?.zoneNames ?? []; },
            nextPage() {
                let items = [];
                for (let i = 0; i < this.zoneNames.length; i++) {
                    let row = this.zoneNames[i];
                    row.columnPosition = i + 1;
                    items.push(row);
                }
                this.$emit('next-page', { formData: { zoneNames: items ?? this.zoneNames }, pageIndex: 1 });
            },
            prevPage() {
                let items = [];
                for (let i = 0; i < this.zoneNames.length; i++) {
                    let row = this.zoneNames[i];
                    row.columnPosition = i + 1;
                    items.push(row);
                }
                this.$emit('prev-page', { formData: { zoneNames: items ?? this.zoneNames }, pageIndex: 1 });
            }
        }
    }
</script>
