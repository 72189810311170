<template>
    <pv-card>
        <template v-slot:title>
            Confirmation
        </template>
        <template v-slot:subtitle>
            Confirm your service group rule details for Customer {{companyDS.data.filter((x) => x.id == model?.customerId)[0]?.text}}.
        </template>
        <template v-slot:content>
                <div class="card">
                    <div class="p-d-flex p-grid p-col-12">
                        <div class="p-col-12 p-md-12">
                            <h3>Service Group</h3>
                            <div class="card" style="background:var(--surface-c)">
                                <div class="p-grid">
                                    <div class="p-col-fixed" style="width: 200px; font-weight: bold;">Code</div>
                                    <div class="p-col">: <span class="p-code-text">{{ model?.code }}</span></div>
                                </div>
                                <div class="p-grid">
                                    <div class="p-col-fixed" style="width: 200px; font-weight: bold;">Name</div>
                                    <div class="p-col">: <span class="p-code-text">{{ model?.name }}</span></div>
                                </div>
                                <div class="p-grid">
                                    <div class="p-col-fixed" style="width: 200px; font-weight: bold;">Description</div>
                                    <div class="p-col">: <span class="p-code-text">{{ model?.description }}</span></div>
                                </div>
                            </div>
                            <h3>Rules & Conditions</h3>
                            <div class="card" style="background:var(--surface-c)">
                                <pv-grid 
                                         :value="rules"
                                         :totalRecords="rules?.length ?? 0"
                                         autoLayout
                                         showGridlines
                                         stripedRows
                                         >
                                    <pv-grid-column field="code" header="Rule" headerStyle="max-width: 20rem;">
                                        <template #body="slotProps">
                                            <div class="p-grid">
                                                <div class="p-col p-d-flex p-col-12">
                                                    <div>
                                                        <div class="p-grid">
                                                            <div class="p-col-fixed" style="width: 130px; font-weight: bold;">Clause</div>
                                                            <div class="p-col">: <span class="p-code-text"> {{ $vm.$enums['ruleclausetype'].filter((x) => x.id == slotProps.data?.clauseID)[0]?.text }}</span></div>
                                                        </div>
                                                        <div class="p-grid">
                                                            <div class="p-col-fixed" style="width: 130px; font-weight: bold; ">Carrier</div>
                                                            <div class="p-col">: <span class="p-code-text">{{ accountCarrierDS?.filter((x) => x.id == slotProps.data.accountCustomerID)[0]?.text ?? "All" }}</span></div>
                                                        </div>
                                                        <div class="p-grid">
                                                            <div class="p-col-fixed" style="width: 130px; font-weight: bold; ">Service</div>
                                                            <div class="p-col">: <span class="p-code-text">{{ accountCarrierProductDS?.filter((x) => x.id == slotProps.data.accountCustomerProductID)[0]?.text ?? "All" }}</span></div>
                                                        </div>
                                                        <div class="p-grid" v-if="slotProps.data.accountCustomerOptionID">
                                                            <div class="p-col-fixed" style="width: 130px; font-weight: bold; ">Option</div>
                                                            <div class="p-col">: <span class="p-code-text">{{ accountCarrierOptionDS?.filter((x) => x.id == slotProps.data.accountCustomerOptionID)[0]?.text ?? "All" }}</span></div>
                                                        </div>
                                                        <div class="p-grid">
                                                            <div class="p-col-fixed" style="width: 130px; font-weight: bold; ">DC</div>
                                                            <div class="p-col">: <span class="p-code-text">{{ warehouseDS?.filter((x) => x.id == slotProps.data.warehouseID)[0]?.text ?? "All" }}</span></div>
                                                        </div>
                                                        <div class="p-grid">
                                                            <div class="p-col-fixed" style="width: 130px; font-weight: bold; ">Incoterms</div>
                                                            <div class="p-col">: <span class="p-code-text">{{ $vm.$enums['incotermtype'].filter((x) => x.id == slotProps.data.incoTerms)[0]?.text }}</span></div>
                                                        </div>
                                                        <div class="p-grid">
                                                            <div class="p-col-fixed" style="width: 130px; font-weight: bold;">Shipment Type</div>
                                                            <div class="p-col">: <span class="p-code-text">{{ $vm.$enums['parcelcategorytype'].filter((x) => x.id == slotProps.data.shipmentType)[0]?.text }}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </pv-grid-column>
                                    <pv-grid-column field="code" header="Condition">
                                        <template #body="slotProps">
                                            <div class="p-grid" style="text-align: center;">
                                                {{conditionTextDisplay(slotProps.data?.conditions ?? [])}}
                                                <!--{{ slotProps.data.conditions.map(x => x.text).join(" ") }}-->
                                            </div>
                                        </template>
                                    </pv-grid-column>
                                    <template #empty>
                                        No records found.
                                    </template>
                                    <template #loading>
                                        Retrieving records from database. Please wait...
                                    </template>
                                    <!-- Pagination -->
                                    <template #paginatorRight>
                                        <pv-button type="button" icon="pi pi-refresh" class="p-button-rounded p-button-text" @click="onRefresh" />
                                    </template>
                                </pv-grid>
                            </div>
                            <h3>Products</h3>
                            <div class="card" style="background:var(--surface-c)">
                                <pv-orderlist  v-model="products" listStyle="height:342px" dataKey="id" v-model:selection="selection">
                                    <template #item="slotProps">
                                        <div class="picklist-item">
                                            <div class="picklist-list-detail" style="margin: 0; padding: 0;">
                                                <div v-if="products.length == 0" class="p-field p-col-12 p-md-12" style="width: 100%; padding: 0; margin: 0;">
                                                    <div class="custom-skeleton">
                                                        <ul class="p-m-0 p-p-0">
                                                            <li>
                                                                <div class="p-d-flex">
                                                                    <div style="flex: 1">
                                                                        <pv-skeleton width="100%" class="p-mb-2"></pv-skeleton>
                                                                        <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                                        <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                                        <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                                        <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div v-else class="p-d-flex" style="width: 100%; padding: 0;">
                                                    <div style="width: 100%; padding: 0; margin: 0;">
                                                        <div class="p-d-flex p-jc-between" style="width: 100%; z-index: 100;">
                                                            <div><h5 style="margin-bottom: 5px; margin-top: 0; font-size: 1.2rem;"><span>{{ slotProps.item.name }}</span></h5></div>
                                                            <div class="p-as-end"><h5 class="p-code-text">[{{ slotProps.item.code}}]</h5></div>
                                                        </div>
                                                        <div>
                                                            <div class="p-grid" style="z-index: 100; width: 100% !important;">
                                                                <div class="p-col-12 p-md-5">
                                                                    <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Maximum Package: </b>  {{ slotProps.item.maxParcelCount }} per consignment</p>
                                                                    <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Weight Range: </b>  {{ slotProps.item.minWeight?.toFixed(3) }} - {{ slotProps.item.maxWeight?.toFixed(3) }} {{ slotProps.item.weightUnitName?.toLowerCase() }}</p>
                                                                    <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Max Dimension: </b>  {{ slotProps.item.maxLength }}{{ slotProps.item.lengthUnitName?.toLowerCase() }} x {{ slotProps.item.maxWidth }}{{ slotProps.item.lengthUnitName?.toLowerCase() }} x {{ slotProps.item.maxHeight }}{{ slotProps.item.lengthUnitName?.toLowerCase() }}</p>
                                                                </div>
                                                                <div class="p-col-12 p-md-5">
                                                                    <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Maximum Transit Days: </b> {{ slotProps.item.maxDeliveryDay }} {{ $filters.quantifiedDisplay("days", slotProps.item.maxDeliveryDay) }}</p>
                                                                    <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Estimated Time: </b>  {{ slotProps.item.deliveryHourTimeSpan?.hours === 23 ? "Within the day" : $filters.padLeft(slotProps.item.deliveryHourTimeSpan?.hours - ( slotProps.item.deliveryHourTimeSpan?.hours > 12 ? 12 : 0), 2, '0') + ":" + $filters.padLeft(slotProps.item.deliveryHourTimeSpan?.minutes, 2, '0') + (slotProps.item.deliveryHourTimeSpan?.hours >= 12 ? " PM" : " AM") }}</p>
                                                                    <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem">
                                                                        <b class="p-code-text">Delivery Type: </b><span v-for="item in slotProps.item.usageTypeName" :key="item" :class="`p-code-text p-text-indicator definite-${item.toUpperCase()}`">{{ item }}</span>
                                                                    </p>
                                                                </div>
                                                                <div class="p-col-12 p-md-2">
                                                                    <div style="text-align: right; object-position: right">
                                                                        <h5 style="margin-top: 0 !important;"><span :class="`p-code-text p-indicator definite-${slotProps.item.timeDefiniteType}`" :title="slotProps.item?.timeDefiniteTypeName">{{ slotProps.item?.timeDefiniteTypeName?.substring(0,1)}}</span></h5>
                                                                        <h5 style="margin-top: 0 !important;">
                                                                            <span v-for="item in slotProps.item.productContentTypeName" :key="item" :class="`p-code-text p-indicator definite-${item.toUpperCase()}`" :title="item">{{ item?.substring(0,1) }}</span>
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </pv-orderlist>
                            </div>
                        </div>
                    </div>
                </div>
        </template>
        <template v-slot:footer>
            <div class="p-grid p-nogutter p-justify-between">
                <pv-button label="Back" @click="prevPage()" icon="pi pi-angle-left" />
                <pv-button label="Complete" @click="complete()" icon="pi pi-check" iconPos="right" class="p-button-success" />
            </div>
        </template>
    </pv-card>
</template>
<script>
    const config = require('@/assets/lib/cosmos/_js/config.js').default.config;
    import { useVuelidate } from '@vuelidate/core';
    export default {
        name: 'ConfirmationTemplate',
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                model: null,
                accountCarrier: [],
                accountCarrierProduct: [],
                accountCarrierOption: [],
                lookup: {
                    categoryTypes: [],
                    conditionTypes: [],
                    operatorTypes: [],
                    tradeBlocCountries: [],
                    postalBlocs: []
                }

            }
        },
        computed: {
            rules() {
                return this.$attrs.formData?.rules ?? [];
            },
            products() {
                return this.$attrs.formData?.products ?? [];
            },
            productOptions() {
                return this.$attrs.formData?.productOptions ?? [];
            },
            companyDS() { return this.$store.getters["company/ds"]; },
            accountCarrierDS() {
                return this.accountCarrier;
            },
            accountCarrierProductDS() {
                return this.accountCarrierProduct;
            },
            accountCarrierOptionDS() {
                return this.accountCarrierOption;
            },
            categoryTypeDS() {
                return this.lookup.categoryTypes;
            },
            conditionTypeDS() {
                return this.lookup.conditionTypes;
            },
            operatorTypeDS() {
                return this.lookup.operatorTypes;
            },
            tradeblocDS() {
                return this.lookup.tradeBlocCountries;
            },
            postalBlocDS() {
                return this.lookup.postalBlocs;
            },
        },
        watch: {
            'rules': {
                handler() {
                    this.setCarrier();
                    this.setService();
                    this.setOption();
                }, deep: true
            },
            'products': {
                handler() {
                    this.setCarrier();
                    this.setService();
                    this.setOption();
                }, deep: true
            },
        },
        methods: {
            formatCurrency(value) {
                return value.toLocaleString('en-US', { style: 'currency', currency: this.currencyDS.data.filter((x) => x.id === this.model?.currencyID)[0]?.code ?? "USD" });
            },
            formatDecimal(value, decimalPart) {
                return Number(value ?? 0).toFixed(decimalPart ?? 2);
            },
            async getCompanyList() {
                return this.$store.dispatch("company/getAll", {sort: null, where: null });
            },
            async getCountryList() {
                return this.$store.dispatch("country/getAll", { sort: null, where: null });
            },
            async getCurrencyList() {
                return this.$store.dispatch("currency/getAll", { sort: null, where: null });
            },
            async setCarrier() {
                let _self = this;
                let products = this.products;
                products?.forEach((e) => {
                    let index = -1;
                    index = _self.accountCarrier.findIndex(x => x.id === e.accountCustomerID);
                    if (index === -1)
                        _self.accountCarrier.push({ id: e.accountCustomerID, name: e.accountName, text: "[" + e.accountName + "] " + e.serviceProviderName });
                }, this);
            },
            async setService() {
                let _self = this;
                let products = this.products;
                products?.forEach((e) => {
                    _self.accountCarrierProduct.push({ id: e.accountCustomerProductID, accountCustomerID: e.accountCustomerID, code: e.code, name: e.name, text: "[" + e.code + "] " + e.name });
                }, this);
            },
            async setOption() {
                let _self = this;
                let products = this.productOptions;
                products?.forEach((e) => {
                    let index = -1;
                    index = _self.accountCarrierOption.findIndex(x => x.id === e.accountCustomerProductID);
                    if (index === -1)
                        _self.accountCarrierOption.push({ id: e.accountCustomerProductID, accountCustomerID: e.accountCustomerID, code: e.code, name: e.name, text: "[" + e.code + "] " + e.name });
                }, this);
            },
            conditionTextDisplay(conditions) {
                let displayText = "";
                let multiParentCondition = conditions?.filter(y => y.parentID === 0 && y.temporaryParentID === 0).length ?? 0 > 1;
                let index = 0;
                conditions?.filter(y => y.parentID === 0 && y.temporaryParentID === 0)?.forEach((e) => {
                    let parentText = "";
                    let parentLogicText = "";
                    let showTextObj = e.dataType === undefined || (e.dataType !== 30 && e.dataType !== 70 && (e?.referenceType === e || e?.referenceType === undefined || e?.referenceType === 0));
                    let showNumberObj = e.dataType === 30 && (e?.referenceType === null || e?.referenceType === 0 || e?.referenceType === 500);
                    let showDecimalObj = e.dataType === 70;
                    parentLogicText = (index > 0 ? (this.$vm.$enums['logictype'].filter((x) => x.id == e.logicType)[0]?.text ?? "").toLowerCase() + " " : "");
                    parentText += (this.conditionTypeDS.filter(x => x.id === e.conditionTypeID)[0]?.text ?? "") + " " +
                        (this.operatorTypeDS.filter(x => x.id === e.operatorType)[0]?.text ?? "").toLowerCase() + " " +
                        (e.referenceType === 100 && e.currencyID > 0 ? " " + (this.$store.getters["currency/ds"].data.filter((x) => x.id == e.currencyID)[0]?.symbol ?? "") : "") +
                        (showTextObj ? e?.textValue ?? "" : "") +
                        (showNumberObj ? e?.numericValue ?? 0 : "") +
                        (showDecimalObj ? this.formatDecimal(e?.decimalValue ?? 0, 3) : "") +
                        (e.referenceType === 200 && e.weightUnit > 0 ? " " + (this.$vm.$enums['weightunit'].filter((x) => x.id == e.weightUnit)[0]?.text ?? "") : "") +
                        (e.referenceType === 300 && e.countryTradeBlocID > 0 ? " [" + (this.tradeblocDS.filter((x) => x.id == e.countryTradeBlocID)[0]?.code ?? "") + "] " + (this.tradeblocDS.filter((x) => x.id == e.countryTradeBlocID)[0]?.altName ?? this.tradeblocDS.filter((x) => x.id == e.countryTradeBlocID)[0]?.name ?? "") : "") +
                        (e.referenceType === 400 && e.day > 0 ? " " + (this.$vm.$enums['daytype'].filter((x) => x.id == e.day)[0]?.text ?? "") : "") +
                        (e.referenceType === 500 && e.dimensionUnit > 0 ? " " + (this.$vm.$enums['lengthunit'].filter((x) => x.id == e.dimensionUnit)[0]?.text ?? "") : "") +
                        (e.referenceType === 600 && e.postalBlocID > 0 ? " [" + (this.postalBlocDS.filter((x) => x.id == e.postalBlocID)[0]?.code ?? "") + "] " + (this.postalBlocDS.filter((x) => x.id == e.postalBlocID)[0]?.name ?? "") : "") + " ";

                    let subText = ""
                    //conditions?.filter(f => (f.parentID === e.id && f.temporaryParentID === e.temporaryId) || (f.parentID === e.id && f.temporaryParentID === e.id))?.forEach((subE) => {
                    // conditions?.filter(f => f.parentID === e.id && f.temporaryParentID === e.temporaryId) || (e.id > 0 ? f.parentID === e.id && f.temporaryParentID === e.id : false)?.forEach((subE) => {
                    conditions?.filter(f => f.parentID > 0 || f.temporaryParentID > 0)?.forEach((subE) => {

                        if ((subE.parentID === e.id && subE.temporaryParentID === e.temporaryID) || (e.id > 0 ? subE.parentID === e.id && subE.temporaryParentID === e.id : false)) {

                            showTextObj = subE.dataType === undefined || (subE.dataType !== 30 && subE.dataType !== 70 && (subE?.referenceType === e || subE?.referenceType === undefined || subE?.referenceType === 0));
                            showNumberObj = subE.dataType === 30 && (subE?.referenceType === null || subE?.referenceType === 0 || subE?.referenceType === 500);
                            showDecimalObj = subE.dataType === 70;
                            subText += (this.$vm.$enums['logictype'].filter((x) => x.id == subE.logicType)[0]?.text ?? "").toLowerCase() + " " + (this.conditionTypeDS.filter(x => x.id === subE.conditionTypeID)[0]?.text ?? "") + " " +
                                (this.operatorTypeDS.filter(x => x.id === subE.operatorType)[0]?.text ?? "").toLowerCase() + " " +
                                (subE.referenceType === 100 && subE.currencyID > 0 ? " " + (this.$store.getters["currency/ds"].data.filter((x) => x.id == subE.currencyID)[0]?.symbol ?? "") : "") +
                                (showTextObj ? subE?.textValue ?? "" : "") +
                                (showNumberObj ? subE?.numericValue ?? 0 : "") +
                                (showDecimalObj ? this.formatDecimal(subE?.decimalValue ?? 0, 3) : "") +
                                (subE.referenceType === 200 && subE.weightUnit > 0 ? " " + (this.$vm.$enums['weightunit'].filter((x) => x.id == subE.weightUnit)[0]?.text ?? "") : "") +
                                (subE.referenceType === 300 && subE.countryTradeBlocID > 0 ? " [" + (this.tradeblocDS.filter((x) => x.id == subE.countryTradeBlocID)[0]?.code ?? "") + "] " + (this.tradeblocDS.filter((x) => x.id == subE.countryTradeBlocID)[0]?.altName ?? this.tradeblocDS.filter((x) => x.id == subE.countryTradeBlocID)[0]?.name ?? "") : "") +
                                (subE.referenceType === 400 && subE.day > 0 ? " " + (this.$vm.$enums['daytype'].filter((x) => x.id == subE.day)[0]?.text ?? "") : "") +
                                (subE.referenceType === 500 && subE.dimensionUnit > 0 ? " " + (this.$vm.$enums['lengthunit'].filter((x) => x.id == subE.dimensionUnit)[0]?.text ?? "") : "") +
                                (subE.referenceType === 600 && subE.postalBlocID > 0 ? " [" + (this.postalBlocDS.filter((x) => x.id == subE.postalBlocID)[0]?.code ?? "") + "] " + (this.postalBlocDS.filter((x) => x.id == subE.postalBlocID)[0]?.name ?? "") : "") + " ";


                        }

                    }, this);

                    displayText += " " + (parentLogicText + " " + (multiParentCondition && (subText.length > 0) ? "(" : "") + (parentText + " " + subText).trim() + (subText.length > 0 ? ")" : "")).trim();
                    index = index + 1;
                }, this);

                return displayText;
            },
            async getCategoryTypeList() {
                await this.$axios
                    .get(config.endpoint.api + `/services/facility/servicegrouprulecondition/category/`.replaceAll("//", "/"))
                    .then((response) => {
                        this.lookup.categoryTypes = response.data.result;
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            async getConditionTypeList() {
                await this.$axios
                    .get(config.endpoint.api + `/services/facility/servicegrouprulecondition/conditiontype/`.replaceAll("//", "/"))
                    .then((response) => {
                        this.lookup.conditionTypes = response.data.result;
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            async getOperatorTypeList() {
                await this.$axios
                    .get(config.endpoint.api + `/services/facility/servicegrouprulecondition/operatortype/`.replaceAll("//", "/"))
                    .then((response) => {
                        this.lookup.operatorTypes = response.data.result;
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            async getTradeBlocCountriesList() {
                await this.$axios
                    .get(config.endpoint.api + `/services/facility/servicegrouprulecondition/TradeBlocAndCountry/`.replaceAll("//", "/"))
                    .then((response) => {
                        this.lookup.tradeBlocCountries = response.data.result;
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            async getPostalBlocList() {
                await this.$axios
                    .get(config.endpoint.api + `/services/facility/rulepostalbloc/`.replaceAll("//", "/"))
                    .then((response) => {
                        this.lookup.postalBlocs = response.data.result;
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            async getCurrency() { return this.$store.dispatch("currency/getAll"); },
            prevPage() {
                this.$emit('prev-page', { pageIndex: 3 });
            },
            complete() {
                this.$toast.add({ severity: 'info', summary: 'Please wait...', detail: "Service group is currently processing do not refresh the page", life: 3000 });
                this.$emit('complete');
            }
        },
        beforeCreate() { this.model = this.$props.formData },
        created() {
            this.getCountryList();
            this.getCurrencyList();
            this.getCompanyList();
        },
        mounted() {
            this.getCategoryTypeList();
            this.getConditionTypeList();
            this.getOperatorTypeList();
            this.getTradeBlocCountriesList();
            this.getPostalBlocList();
            this.$nextTick(() => {
                this.model = this.$attrs.formData;
            });
        },
        async updated() {
           
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>