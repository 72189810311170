<template>
    <zone-form @is-sync="onSync($event)" style="margin-top: 20px;" />
    <div class="p-grid p-nogutter p-justify-between" style="border-top: 2px solid var(--surface-d); margin: 10px -20px -10px -20px; padding: 20px 20px 0 20px; ">
        <i></i>
        <pv-button :label="nextLabel" @click="nextPage()" :icon="nextIcon" :iconPos="nextPosition" :disabled="activity.loading || blockNext || model.provider.id === 0" />
    </div>
</template>
<script>
    import zoneform from '@/components/settings/zone/zone-form.vue';
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from '@vuelidate/validators';
    export default {
        name: 'StepCredential',
        emits: ["next-page"],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'zone-form': zoneform
        },
        props: {
            id: { type: String },
            integrationMask: { type: String }
        },
        data() {
            return {
                credentialFields: [],
                model: {
                    code: null,
                    name: null,
                    contractCountry: null,
                    provider: null,
                    providerCarrier: null,
                    environmentId: 0,
                    tenantId: null,
                    createUserId: null,
                    createDate: null,
                    updateUserId: null,
                    updateDate: null,
                    disableUserId: null,
                    disableDate: null,
                    deleteUserId: null,
                    deleteDate: null,
                    archiveUserId: null,
                    archiveDate: null,
                    recordStatus: 0,
                    id: 0,
                    id2: null,
                    mask: null
                },
                credential: {},
                activity: {
                    loading: false
                }
            }
        },
        validations() {
            return {
                model: {
                    code: { required, minLength: minLength(1), maxLength: maxLength(20), $autoDirty: true },
                    name: { required, maxLength: maxLength(50), $autoDirty: true },
                    provider: {
                        id: { required, $autoDirty: true }
                    },
                    providerCarrier: {
                        id: { $autoDirty: true }
                    },
                    integrationID: { required, $autoDirty: true },
                    contractCountry: {
                        id: { required, $autoDirty: true }
                    }
                }
            }
        },
        computed: {
            nextIcon() {
                if (this.activity.loading)
                    return "pi pi-spin pi-spinner";
                return "pi pi-angle-right";
            },
            nextLabel() {
                if (this.activity.loading)
                    return "Processing...";
                return "Next";
            },
            nextPosition() {
                if (this.activity.loading)
                    return "left";
                return "right";
            },
            blockNext() {
                let block = false;
                if (this.model.code === null) return true;
                if (this.model.name === null) return true;
                if (this.model.provider === null || this.model.provider.id === 0) return true;
                if (this.model.contractCountry === null || this.model.contractCountry.id === 0) return true;
                return block;
            }
        },
        methods: {
            onSync(event) {
                this.credentialFields = event.fields;
                for (let field in event.model)
                    this.model[field] = event.model[field];
            },
            nextPage() {
                this.activity.loading = true;
                this.$axios.get(this.$config.config.endpoint.api + "/services/settings/serviceproviderzone/byprovider/id/" + this.model.provider.id)
                    .then((response) => {
                    if (response.data.result.length > 0) {
                        if (response.data.result.filter(x => x.code == this.model.code).length > 0)
                            this.$toast.add({ severity: 'error', summary: 'Zone Details', detail: "The zone code is already used!", life: 10000 });
                        else
                            this.$emit('next-page', { formData: this.model, pageIndex: 0 });
                    }
                    else if ((response.data.errors ?? []).length > 0) {
                        let errorDetails = '';
                        response.data.Errors.forEach((x) => { errorDetails += x.Description; })
                        this.$toast.add({ severity: 'error', summary: 'Credential Validation', detail: errorDetails, life: 10000 });
                    }
                    else
                        this.$emit('next-page', { formData: this.model, pageIndex: 0 });
                    this.activity.loading = false;
                })
                .catch(() => {
                    this.activity.loading = false;
                    this.$toast.add({ severity: 'error', summary: 'Request Failed', detail: "An error occured when submitting request. Please ensure that you have an active session.", life: 10000 });
                });
            }
        }
    }
</script>
