<template>
    
    
    <pv-block-ui :blocked="!model" fullScreen>
        <pv-progress-spinner v-if="!model" style="margin-top: calc(50vh - 10rem); left: calc(50% - 5rem); position: absolute; height: 10rem !important; width: 10rem !important ">PLEASE WAIT</pv-progress-spinner>
        <div v-else class="content-section body p-d-flex p-jc-center" style="height: 100vh !important; margin-top: 0;">
            <div class="card" style="min-height: 100vh; width: 100%; margin: 0; position: fixed; top: 50% !important; transform: translateY(-50%); overflow-x: auto; max-height: 100vh; min-height: 100vh; padding-top: 3rem; padding-bottom: 5rem;">
                <div v-if="!model?.Response?.Data && !pending" class="p-d-flex p-jc-center">
                    <div class="p-d-flex p-flex-column">
                        <div :style="`background: url('/_assets/img/BE128A49D753EE0882F74F56000000DC_${theme}.png') no-repeat var(--surface-a); height: 5rem; max-height: 5rem; background-size: contain; background-position: center center; position: relative; `"></div>
                        <div class="p-code-text" style="margin-bottom: 3rem; background: var(--surface-c); padding: 1rem 2rem !important; width: 75rem">
                            <b>ERROR: </b>
                            <br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ model?.Errors[0]?.Message ?? "Unable to provide tracking details" }}.
                            <br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If it still persist, please contact your service provider.
                        </div>
                    </div>
                </div>
                <div class="p-d-flex p-jc-center" v-for="track in (model?.Response?.Data ?? [])" :key="track?.Reference?.Shipping?.CarrierReference">
                    <div v-if="!pending" class="p-d-flex p-flex-column">
                        <div v-if="consignment?.Sender?.Logos[`Customer${$filters.titleize(theme)}`]" :style="`background: url('${(consignment?.Sender?.Logos[`Customer${$filters.titleize(theme)}`])}') no-repeat var(--surface-a); height: 5rem; max-height: 5rem; background-size: contain; background-position: center center; position: relative; `"></div>
                        <div style="margin-bottom: 3rem; background: var(--surface-c); padding: 1rem 2rem !important;">
                            <table cellspacing="0">
                                <tbody>
                                    <tr>
                                        <th style="text-align: left; width: 10rem; font-size: 1.25rem; padding: 0.5rem 1rem; vertical-align: top;">Delivery</th>
                                        <td class="p-code-text" style="padding: 0.5rem 1rem; width: 1rem; vertical-align: top;">:</td>
                                        <td class="p-code-text" style="padding: 0.5rem 1rem; width: 63rem;">{{ ToAddress(consignment?.Recipient) }}</td>
                                    </tr>
                                    <tr>
                                        <th style="text-align: left; width: 10rem; font-size: 1.25rem; padding: 0.5rem 1rem; vertical-align: top;">{{ model?.Status.includes("SUCCESS") ? "Summary" : "Error" }}</th>
                                        <td class="p-code-text" style="padding: 0.5rem 1rem; width: 1rem; vertical-align: top;">:</td>
                                        <td class="p-code-text" style="padding: 0.5rem 1rem; width: 63rem; vertical-align: top;">{{ model?.Status.includes("SUCCESS") ? (consignment?.Summary ?? model?.Errors[0]?.Message) : (model?.Errors[0]?.Message ?? consignment?.Summary) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="p-d-flex p-jc-center" v-for="track in (model?.Response?.Data ?? [])" :key="track.Reference?.Shipping?.CarrierReference">
                    <div class="p-d-flex p-flex-column" v-for="track in (model?.Response?.Data ?? [])" :key="track.Reference?.Shipping?.CarrierReference">
                        <div style="margin-bottom: 3rem; background: var(--surface-c); padding: 1rem 2rem !important;" v-for="details in track.TrackDetails" :key="details.Reference">
                            <h4>Additional Info</h4>
                            <table cellspacing="0" style="border-bottom: 1px solid var(--surface-d) !important;">
                                <tbody>
                                    <tr v-if="details?.Reference?.Shipping?.CarrierReference">
                                        <td rowspan="2" style="vertical-align: middle; border-top: 1px solid var(--surface-d) !important; border-left: 1px solid var(--surface-d) !important; border-right: 1px solid var(--surface-d) !important; text-align: center !important; width: 15rem; font-size: 1.25rem; padding: 0;"><center><div :style="`background: url('${logo}') no-repeat; max-width: 14rem; height: 5rem; background-size: contain; background-position: center center;`"></div></center></td>
                                        <th style="vertical-align: top; background: var(--surface-d); border-right: 1px solid var(--surface-d) !important; text-align: left; width: 15rem; font-size: 1.25rem; padding: 0.5rem 1rem; ">Carrier</th>
                                        <th colspan="2" style="vertical-align: top; background: var(--surface-d); border-right: 1px solid var(--surface-d) !important; text-align: left; width: 45rem; font-size: 1.25rem; padding: 0.5rem 1rem; ">Service</th>
                                    </tr>
                                    <tr v-if="details?.Reference?.Shipping?.CarrierReference">
                                        <td class="p-code-text" style="vertical-align: top; padding: 1rem 1rem; border-right: 1px solid var(--surface-d) !important; ">{{ carrier }}</td>
                                        <td colspan="2" class="p-code-text" style="vertical-align: top; padding: 1rem 1rem; border-right: 1px solid var(--surface-d) !important; text-align: left;">{{ service }}</td>
                                    </tr>
                                    <tr>
                                        <th style="vertical-align: top;background: var(--surface-d); border-right: 1px solid var(--surface-d) !important; text-align: left; width: 15rem; font-size: 1.25rem; padding: 0.5rem 1rem; ">Tracking Number</th>
                                        <th style="vertical-align: top;background: var(--surface-d); border-right: 1px solid var(--surface-d) !important; text-align: left; width: 15rem; font-size: 1.25rem; padding: 0.5rem 1rem; ">Transaction ID</th>
                                        <th style="vertical-align: top;background: var(--surface-d); border-right: 1px solid var(--surface-d) !important; text-align: left; width: 35rem; font-size: 1.25rem; padding: 0.5rem 1rem; ">Customer Reference</th>
                                        <th style="vertical-align: top;background: var(--surface-d); border-right: 1px solid var(--surface-d) !important; text-align: left; width: 10rem; font-size: 1.25rem; padding: 0.5rem 1rem; ">Shipped Date</th>
                                    </tr>
                                    <tr>
                                        <td class="p-code-text" style="vertical-align: top;padding: 1rem 1rem; border-right: 1px solid var(--surface-d) !important; border-left: 1px solid var(--surface-d) !important;"><a class="p-code-text" style="font-weight: 700;" :href="`${details?.Reference?.Shipping?.TrackingURL?.Carrier}`" target="_blank">{{details?.Reference?.Shipping?.CarrierReference}}</a></td>
                                        <td class="p-code-text" style="vertical-align: top;padding: 1rem 1rem; border-right: 1px solid var(--surface-d) !important; ">{{consignment?.TransactionID}}</td>
                                        <td class="p-code-text" style="vertical-align: top;padding: 1rem 1rem; border-right: 1px solid var(--surface-d) !important; ">{{ ToReference(track?.Reference?.Customer) }}</td>
                                        <td class="p-code-text" style="vertical-align: top;padding: 1rem 1rem; border-right: 1px solid var(--surface-d) !important; text-align: left;">{{$filters.toDateDisplay(consignment?.CollectionDate)}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <h4>Where has my parcel been?</h4>
                            <table cellspacing="0" style="border-bottom: 1px solid var(--surface-d) !important;">
                                <thead>
                                    <tr>
                                        <th style="vertical-align: top;background: var(--surface-d); border-left: 1px solid var(--surface-d) !important; border-right: 1px solid var(--surface-d) !important; text-align: left; width: 15rem; font-size: 1.25rem; padding: 0.5rem 1rem;">DATE</th>
                                        <th style="vertical-align: top;background: var(--surface-d); border-right: 1px solid var(--surface-d) !important; text-align: left; width: 20rem; font-size: 1.25rem; padding: 0.5rem 1rem; ">LOCATION</th>
                                        <th style="vertical-align: top;background: var(--surface-d); border-right: 1px solid var(--surface-d) !important; text-align: left; width: 40rem; font-size: 1.25rem; padding: 0.5rem 1rem; ">DETAILS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="activity in details.Activities" :key="activity.Date">
                                        <td class="p-code-text" style="vertical-align: top;padding: 1rem 1rem; border-right: 1px solid var(--surface-d) !important; border-left: 1px solid var(--surface-d) !important;">{{ $filters.toDateFullTimeDisplay(activity.Date) }}</td>
                                        <td class="p-code-text" style="vertical-align: top;padding: 1rem 1rem; border-right: 1px solid var(--surface-d) !important; ">{{ activity.Location }}</td>
                                        <td class="p-code-text" style="vertical-align: top;padding: 1rem 1rem; border-right: 1px solid var(--surface-d) !important; text-align: left;">
                                            {{ activity.Details }}
                                            <div v-if="(activity?.Files ?? []).length > 0" style="margin-top: 1rem;">
                                                <b>Attachments:</b>
                                                <div class="p-code-text" v-for="file in activity.Files" :key="file.DownloadURL">
                                                    <div class="p-d-flex p-jc-between" style="margin-top: 0.5rem;">
                                                        <div style="padding-left: 2rem;" v-tooltip.top="file.PrintedText"><b>{{ $filters.cutText(file.PrintedText, 35) }}</b>: </div>
                                                        <div><a style="cursor: pointer; font-weight: bold; margin-left: 1rem; margin-right: 1rem;" @click="document=file"><i class="fa fa-magnifying-glass fa-fw" style="font-family: var(--fa-style-family, 'Font Awesome 6 Pro') !important;"></i> View </a> <a style="cursor: pointer; font-weight: bold; margin-right: 1rem; white-space: nowrap;" @click="Download(file?.File?.Base64File, file?.File?.ContentType, file?.File?.Filename)"><i class="fa fa-download fa-fw" style="font-family: var(--fa-style-family, 'Font Awesome 6 Pro') !important;"></i> Download</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
            <a href="https://integro360.com" target="_blank"><div :style="`background: url('/_assets/img/BE128A49D753EE0882F74F56000000DC_${theme}.png') no-repeat; height: 5rem; max-height: 5rem; width: 15rem; opacity: 0.15; background-size: contain; position: fixed; bottom: -1rem; right: 1rem;`"><div style="margin-top: -1.5rem; color: var(--text-color)">powered by</div></div></a>
        </div>
        <!-- Image Dialog -->
        <is-dialog :header="document?.PrintedText" :breakpoints="{'2500px': '85vw', '960px': '75vw', '640px': '100vw'}" :visible="document" @is-cancel="document = null" @is-confirm="Download(document?.File?.Base64File, document?.File?.ContentType, document?.File?.Filename)" confirm="Download" confirmIcon="pi pi-download">
            <div class="confirmation-content" style="width: 100% !important;">
                <div style="text-align: center;">
                    <img v-if="document?.File?.ContentType?.includes('image/')" :src="`data:${document?.File?.ContentType};base64, ${document?.File?.Base64File}`" style="width: 50vw; max-width: 40rem; border: none; object-fit: cover; background: #FFFFFF;" />
                    <iframe v-else :src="`data:${document?.File?.ContentType};base64, ${document?.File?.Base64File}#toolbar=0&navpanes=0&scrollbar=1`" style="width: 100% !important; min-height: 40rem; overflow: auto; border: none; object-fit: cover; background: #FFFFFF; overflow: hidden;">
                    </iframe>
                    <div style="margin-top: 1rem;" class="p-code-text" v-if="document.PrintedText"><b>PRINTED NAME: </b> {{ document?.PrintedText }}</div>
                </div>
            </div>
        </is-dialog>
    </pv-block-ui>
</template>
<script>
    export default {
        name: "ConsignmentTrack",
        data() {
            return {
                model: null,
                consignment: null,
                carrierLogo: null,
                carrier: null,
                service: null,
                document: null,
                pending: true
            }
        },
        computed: {
            logo() { return this.carrierLogo; },
            theme() {
                if (sessionStorage.getItem("session-theme"))
                    return sessionStorage.getItem("session-theme");
                return "dark";
            }
        },
        methods: {
            ToAddress(obj) {
                if (obj)
                    return `${obj.Address1}, ${(obj.Address2 ?? '')}, ${(obj.Address3 ?? '')}, ${obj.City}, ${(obj.State ?? '')}, ${(obj.PostalCode ?? '')}, ${obj.Country}`.replaceAll(' ,', ',').replaceAll(', , ', ', ').replaceAll(', ,', ', ').replaceAll(',,', ', ').replaceAll(' ,', ',').replaceAll(',,', ', ');
                return "";
            },
            ToReference(obj) {
                if (obj)
                    return `${obj.Reference1}, ${(obj.Reference2 ?? '')}, ${(obj.Reference3 ?? '')}`.replaceAll(', ,', '').replaceAll(' ,', ',').replaceAll(', , ', ', ').replaceAll(', ,', ', ').replaceAll(',,', ', ').replaceAll(' ,', ',').replaceAll(',,', ', ');
                return "";
            },
            Download(base64File, contentType, filename) {
                this.$config.common.$downloadFile(base64File, contentType, filename);
            }
        },
        created() {
            this.$axios.get(`${this.$config.config.endpoint.api}/api/polaris/consignment/${this.$route.params.mask}/track`).then((response) => {
                this.model = response.data;
                this.consignment = response.data?.Response?.Data[0]?.Consignment;
                this.carrierLogo = response.data?.Response?.Data[0]?.Reference?.Shipping?.CarrierImageURL;
                this.carrier = response.data?.Response?.Data[0]?.Reference?.Shipping?.CarrierName ?? response.data?.Response?.Data[0]?.Reference?.Shipping?.Carrier;
                this.service = response.data?.Response?.Data[0]?.Reference?.Shipping?.Service + " - " + (response.data?.Response?.Data[0]?.Reference?.Shipping?.ServiceName ?? "");
                this.pending = false;
            }).catch((err) => console.log(err));
        }
    }
</script>
<style>
    .loading {
    position: absolute;
    background-color: #00000088;
    z-index: 100000;
    height: 100%;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    top:�0;
    }
</style>