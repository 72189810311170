<template>
    <form-record :model="tempModel" :modelState="v$" :v$="v$" @is-reset="resetRecord" :onBeforeSubmit="validateCredential">
        <credential-form :data="formData" :accountID="model.id" @is-sync="setCredentials" />
        <pv-tab style="margin: 0 -1rem !important;">
            <pv-tabpanel header="Subcribed Products">
                <div style="padding: 0.5rem;">
                    <product-form :serviceProviderID="model.serviceProviderID" :integrationID="model.integrationID" :formData="formData" :accountID="model.id" @is-sync="setProduct" />
                </div>
            </pv-tabpanel>
            <pv-tabpanel header="Posting Locations">
                <div style="padding: 0.5rem;">
                    <postinglocation-form :serviceProviderID="model.serviceProviderID" :formData="formData" :accountID="model.id" @is-sync="setLocation" />
                </div>
            </pv-tabpanel>
            <pv-tabpanel header="Customers">
                <div style="padding: 0.5rem;">
                    <customer-form :serviceProviderID="model.serviceProviderID" :formData="formData" :accountID="model.id" @is-sync="setCustomer" />
                </div>
            </pv-tabpanel>
        </pv-tab>
    </form-record>

</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from '@vuelidate/validators';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import credentialform from '@/components/admin/provideraccount/credential-form.vue';
    import productform from '@/components/admin/provideraccount/product-form.vue';
    import postinglocationfrom from '@/components/admin/provideraccount/postinglocation-form.vue';
    import customerform from '@/components/admin/provideraccount/customer-form.vue';
    export default {
        name: 'ProviderAcccountCreate',
        setup: () => ({ v$: useVuelidate() }),
        mixins: [mixins.RECORD],
        components: {
            'credential-form': credentialform,
            'product-form': productform,
            'postinglocation-form': postinglocationfrom,
            'customer-form': customerform
        },
        watch: {
            "model.id": {
                handler() {
                    if (this.model && this.model.id > 0) {
                        this.model.products = this.bindProducts();
                        this.model.locations = this.bindLocations();
                        this.model.customers = this.bindCustomers();
                        this.$nextTick(() => { this.v$.$reset(); this.modelState.$reset; });
                    }
                }, deep: true
            },
        },
        data() {
            return {
                update: -1
            }
        },
        computed: {
            formData() {
                return this.model;
            },
            tempModel() {
                let model = this.$filters.init(this.model);
                if (model.id > 0) {
                    model.products = this.model.products.map(x => x.providerProductID);
                    model.locations = this.model.locations.map(x => x.companyID);
                    model.customers = this.model.customers.map(x => x.companyID);
                }
                return model;
            },
            credential() {
                let credentials = { AccountType: this.model.accountType === 100 ? "PRODUCTION" : "STAGING" };
                if (this.model.integrationID) {
                    var integrations = this.$vm.$integrations.filter(x => x.id === this.model.integrationID)[0];
                    integrations.credentialFields.forEach((credential) => {
                        if (this.model[this.$filters.decamelize(credential.field)] && credential)
                            credentials[credential.property] = this.model[this.$filters.decamelize(credential.field)];
                    });
                }
                return credentials;
            }
        },
        validations() {
            return {
                model: {
                    accountType: { required, $autoDirty: true },
                    tradeType: { $autoDirty: true },
                    products: { $autoDirty: true },
                    locations: { $autoDirty: true },
                    customers: { $autoDirty: true },
                    code: { required, minLength: minLength(1), maxLength: maxLength(255), $autoDirty: true },
                    name: { required, minLength: minLength(1), maxLength: maxLength(50), $autoDirty: true },
                    serviceProviderID: { required, $autoDirty: true },
                    integrationID: { required, $autoDirty: true },
                    contractCountryID: { required, $autoDirty: true },
                }
            }
        },
        methods: {
            validateCredential() {
                if (this.model.id === 0 || !this.$vm.$submitted)
                    return true;
                else {
                    this.$vm.$submitted = false;
                    return this.$axios.post(this.$config.config.endpoint.api + "/api/polaris/tools/credential/carrier", {
                        Request: {
                            AccountType: this.model.accountType == 200 ? "STAGING" : "PRODUCTION",
                            CarrierCode: this.$vm.$providers.filter(x => x.id === this.model.serviceProviderID)[0].code,
                            IntegrationCode: this.$vm.$integrations.filter(x => x.id === this.model.integrationID)[0].code,
                            Country: this.$vm.$countries.filter(x => x.id === this.model.contractCountryID)[0].code,
                            Currency: this.$vm.$currencies.filter(x => x.id === this.model.contractCurrencyID)[0].code,
                            Credentials: this.credential
                        }
                    })
                        .then((response) => {
                        if (response.data.Status === "SUCCESS") {
                            this.submitted = true;
                            this.activity.loading = false;
                            return true;
                        }
                        else {
                            let errorDetails = '';
                            response.data.Errors.forEach((x) => { errorDetails += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`; })
                            this.$toast.add({ severity: 'error', summary: 'Credential Validation', detail: errorDetails, life: 10000 });
                            this.activity.loading = false;
                            return false;
                        }
                    })
                    .catch(() => {
                        this.activity.loading = false;
                        this.$toast.add({ severity: 'error', summary: 'Request Failed', detail: "An error occured when submitting request. Please ensure that you have an active session.", life: 10000 });
                        return false;
                    });
                }
            },
            setCredentials(event) {
                this.credentialFields = event.fields;
                for (let field in event.model.model)
                    this.model[field] = event.model.model[field];
            },
            setProduct(event) {
                if (event && event.model) {
                    this.model.products = event.model;
                   /* this.$nextTick(() => { this.v$.touch(); });*/
                }
            },
            setLocation(event) {
                if (event && event.model) {
                    this.model.locations = event.model;
                   /* this.$nextTick(() => { this.v$.touch(); });*/
                }
            },
            setCustomer(event) {
                if (event && event.model) {
                    this.model.customers = event.model;
                    /*this.$nextTick(() => { this.v$.touch(); });*/
                }
            },
            bindLocations() {
                let location = [];
                this.$store.dispatch("provideraccountlocation/getURL", { url: `/services/admin/serviceprovideraccountpostinglocation/byaccount/id/${this.model.id}` }).then((response) => {
                    var selectedLocations = response.result ?? [];
                    selectedLocations.forEach((data) => {
                        location.push({
                            accountCompanyID: data.id,
                            companyID: data.warehouseID
                        });
                    });
                });
                return location;
            },
            bindCustomers() {
                let customer = [];
                this.$store.dispatch("provideraccountcustomer/getURL", { url: `/services/admin/serviceprovideraccountcustomer/byaccount/id/${this.model.id}` }).then((response) => {
                    var selectedCustomers = response.result ?? [];
                    selectedCustomers.forEach((data) => {
                        customer.push({
                            accountCompanyID: data.id,
                            companyID: data.customerID
                        });
                    });
                });
                return customer;
            },
            bindProducts() {
                let product = [];
                this.$store.dispatch("provideraccountproduct/getURL", { url: `/services/admin/serviceprovideraccountproduct/byaccount/id/${this.model.id}` }).then((response) => {
                    var selectedProducts = response.result ?? [];
                    selectedProducts.forEach((data) => {
                        product.push({
                            accountProductID: data.id,
                            providerProductID: data.productID
                        });
                    });
                });
                return product;
            }
        },
        mounted() {
            if (this.model && this.model.id > 0) {
                this.model.products = this.bindProducts();
                this.model.locations = this.bindLocations();
                this.model.customers = this.bindCustomers();
                this.$nextTick(() => { this.v$.$reset(); this.modelState.$reset; });
            }
        }

    }
</script>