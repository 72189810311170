<template>
    <cosmos-grid :id="id" :searchFilter="['code', 'name']" :source="sources.timeintransits" sourceType="array" :paged="false" :autoGenerate="false" :deleteDisplayFields="deleteDisplay" :showAdd="false" :showDelete="false" :showExport="false" :showOperation="false" :pageSize="5" groupBy="providerProduct.code">
        <template #groupheader="slotProps">
            <span class="image-text" style="text-transform: uppercase; font-weight: 700 !important">[{{ slotProps.data.providerProduct.code }}] {{ slotProps.data.providerProduct.name}}</span>
        </template>
        <pv-grid-column field="providerProduct.code" header="Code" headerStyle="width: 15rem" />
        <pv-grid-column field="providerCountry.name" header="Name">
            <template #body="slotProps">
                <i :class="`flag flag-${slotProps.data?.providerCountry?.code?.toLowerCase()}`"></i> &nbsp;
                [<strong>{{ slotProps.data.providerCountry?.code}}</strong>] {{ slotProps.data.providerCountry?.name}}
            </template>
        </pv-grid-column>
        <pv-grid-column field="text" header="Transit Days" headerStyle="width: 10rem" style="text-align: right;" />
        <pv-grid-column field="deliveryTime" header="Delivery Time" headerStyle="width: 8rem" style="text-align: right;" />
    </cosmos-grid>
</template>
<script>
    export default {
        name: "TimeInTransitGrid",
        props: {
            id: { type: String },
            code: { type: String, default: null },
            provider: { type: String, default: null }
        },
        data() {
            return {
                sources: {
                    timeintransits: []
                }
            }
        },
        watch: {
            provider() {
                this.getSource();
            }
        },
        methods: {
            getSource() {
                this.sources.timeintransits = [];
                this.$nextTick(() => {
                    if (this.provider) {
                        this.$store.dispatch("providertimeintransit/getURL", { url: `/services/settings/serviceprovidertimeintransit/byprovider/${this.provider}` })
                            .then((response) => {
                                if (response && response.success) {
                                    this.sources.timeintransits = response?.result ?? response ?? [];
                                }
                            });
                    }
                })
            }
        },
        created() { this.getSource(); }
    }
</script>