<template>
    <cosmos-grid id="PrintJobList"
                 :searchFilters="filters"
                 :source="url"
                 sourceType="url"
                 :show-export="false"
                 :show-add="false"
                 :auto-generate="false"
                 :show-header="consignmentID === -1000"
                 :show-operation="false"
                 :forceRefresh="forceRefresh">
        <pv-grid-column field="code" header="Transaction ID" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{ slotProps.data.code }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference1" header="Reference 1" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{ slotProps.data.reference1 }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference2" header="Reference 2" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{ slotProps.data.reference2 }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="reference3" header="Reference 3" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{ slotProps.data.reference2 }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="computerName" header="COMPUTER NAME" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{ slotProps.data.computerName }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="printerName" header="PRINTER NAME" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{ slotProps.data.printerName }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="updateDate" header="Queued Date" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{ $filters.toDateFullTimeDisplay(slotProps.data.updateDate) }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="endDate" header="Print Date" style=" text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{ $filters.toDateFullTimeDisplay(slotProps.data.endDate) }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="type" header="Type" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                {{ slotProps.data.typeText }}
            </template>
        </pv-grid-column>
        <pv-grid-column field="status" header="Status" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-jc-between" style="width: 7rem;">
                    <div>
                        <label :class="`pj-status-${(slotProps.data.status === 400 ? 'danger' : (slotProps.data.status === 300 ? 'success' : (slotProps.data.status === 200 ? 'info' : '')))}`">{{ slotProps.data.statusText }}</label>
                    </div>
                    <div v-if="slotProps.data.status === 400">
                        <i class="fas fa-arrow-up-right-from-square fa-fw pj-status-danger" @click="onErrorPreview(slotProps.data)" style="cursor: pointer;" />
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="action" header="" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <pv-button v-if="slotProps.data.status === 200 || slotProps.data.status === 400" icon="fa fa-print fa-fw" @click="onRequeue(slotProps.data)" style="margin: -0.5rem 0 -0.25rem;" />
            </template>
        </pv-grid-column>
    </cosmos-grid>
    <is-dialog :header="dialog?.data?.code" style="width: 25vw; min-width: 400px;" :visible="dialog.error" :showConfirm="false" @is-cancel="{ dialog.error = false; dialog.data = null }">
        <div>
            <b>Error Processing Job:</b>
            <br />
            <ul>
                <span v-html="`${dialog.data.message}`"></span>
            </ul>
        </div>
    </is-dialog>
</template>
<script>
    import { mapGetters } from 'vuex';
    export default {
        name: 'printjobs',
        props: {
            consignmentID: { type: Number, default: -1000 }
        },
        data() {
            return {
                forceRefresh: false,
                dialog: {
                    error: false,
                    data: null
                }
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            url() { return `/services/facility/printjob/PagedList?type=${this.consignmentID}` },
            filters() { return ['code', 'computerName','printerName','macAddress', 'reference1', 'reference2', 'reference3'] }
        },
        methods: {
            onErrorPreview(data) {
                this.dialog.data = data;
                this.$nextTick(() => { this.dialog.error = true; });
            },
            onRequeue(data) {
                this.$store.dispatch("printjob/patchURL", {
                    url: `/services/facility/printjob/${data.mask}`,
                    payload: [
                        {
                            "op": "replace",
                            "path": "/endDate",
                            "value": null
                        },
                        {
                            "op": "replace",
                            "path": "/updateDate",
                            "value": this.$filters.$dateTime24HUTC(new Date()) + 'Z'
                        },
                        {
                            "op": "replace",
                            "path": "/status",
                            "value": 100
                        },
                        {
                            "op": "replace",
                            "path": "/message",
                            "value": null
                        }
                    ]
                }).then((response) => {
                    if (response.success) {
                        this.$toast.add({ severity: 'success', summary: 'Record Updated Successfully', detail: 'The record was requeued successfully.', life: 3000 });
                        this.onRefresh();
                    }
                }).catch((err) => { console.log(err); });
            },
            onRefresh() {
                this.forceRefresh = false;
                this.$nextTick(() => { this.forceRefresh = true; });
            }
        },
        created() {
            this.activeKeys = sessionStorage.getItem('activekeys');
        }
    }
</script>
<style>
    .pj-status-danger {
        color: var(--text-danger);
        text-transform: uppercase;
        font-weight: bold;
    }
    .pj-status-success {
        color: var(--text-success);
        text-transform: uppercase;
        font-weight: bold;
    }
    .pj-status-info {
        color: var(--text-info);
        text-transform: uppercase;
        font-weight: bold;
    }
    .pj-status- {
        text-transform: uppercase;
        font-weight: bold;
    }
</style>