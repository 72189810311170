var model = {
    model: null,
    modelState: null
};

var browser = {
    isMobile() { return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0, 4)) },
    language() { return navigator.language; },
    touchDevice() { return navigator.maxTouchPoints > 0; },
};

var data = {
    activity: {
        loading: {
            form: true,
            back: false,
            save: false,
            delete: false,
            reset: false,
            clone: false,
            send: false,
            print: false,
            faq: false,
            support: false,
            object: false
        },
        submitted: false
    },
    display: { width: 1000, height: 1000 },
    dialog: {
        form: false,
        delete: false,
        dirty: false
    },
    router: {
        name: null,
        module: null,
        model: null,
        schema: null,
        title: null,
        subTitle: null,
        type: null
    },
    toolbar: {
        visible: true,
        back: true,
        save: true,
        reset: true,
        clone: true,
        delete: true,
        send: false,
        print: false,
        faq: true,
        support: true
    }
};

var computed = {
    ...browser,
    init() { return this.$filters.init(this.$store.getters[this.router.module + "/init"]); },
    record() { return this.$filters.init(this.$store.getters[this.router.module + "/record"]) },
    dataSource() { return this.$store.getters[this.router.module + "/ds"]; },
    customDS(store) { return this.$store.getters[store ?? this.router.module + "/dsGET"].data; },
    errors() { return this.$store.getters[this.router.module + "/errors"]; },
    isDirty() { return this.$props.modelState ? this.$props.modelState && (this.$props.modelState.$isDirty || this.$props.modelState.$anyDirty) : this.modelState && (this.modelState.$isDirty || this.modelState.$anyDirty); }
};

var crud = {
    async getAll(store) { return await this.$store.dispatch(store ?? this.router.module + "/getAll", { sort: null, where: null, fields: null }); },
    async getAllWithType(store, type) { return await this.$store.dispatch(store ?? this.router.module + "/getAllWithType", type); },
    async getPaged(store, page, pageSize) { return await this.$store.dispatch(store ?? this.router.module + "/getPaged", { page, pageSize }); },
    async getPagedWithType(store, page, pageSize, type) { return await this.$store.dispatch(store ?? this.router.module + "/getPaged", { page, pageSize, type }); },
    async getCustom(url, store) { return await this.$store.dispatch(store ?? this.router.module + "/getCustom", url); },
    async get(mask, store) { return await this.$store.dispatch(store ?? this.router.module + "/getRecord", mask); },
    async insert(model, store) { return await this.$store.dispatch(store ?? this.router.module + "/addRecord", model); },
    async update(model, store) { return await this.$store.dispatch(store ?? this.router.module + "/modifyRecord", model); },
    async delete(mask, store) { return await this.$store.dispatch(store ?? this.router.module + "/removeRecord", mask); },
    async send(mask, store) { return await this.$store.dispatch(store ?? this.router.module + "/sendRecord", mask); }
    // Add Clone, Print, Etc.
};

var method = {
    DEVELOPMENT() { this.$toast.add({ severity: 'warn', summary: 'Feature Unavailable', detail: 'The feature is currently under development.', life: 5000 }); },
    onResetModelState() { this.$nextTick(() => { this.modelState.$reset(); }); },
    onResetRecord(store) {
        if (!this.$route.params.mask)
            this.model = this.$filters.init(this.$store.getters[store ?? this.router.module + "/init"]);
        else
            this.get(this.record.mask).then(() => { this.model = this.record; });
        this.onResetModelState();
    },
    onSendRecord() {
        this.send(this.record.mask).then(() => {
            this.get(this.record.mask).then(() => {
                this.model = this.record;
                this.$toast.add({ severity: 'success', summary: 'Record Sent', detail: 'Record Sent Successful', life: 3000 });
            });
        });
        this.onResetModelState();
    },
    onResize() {
        this.display.width = document.documentElement.clientWidth;
        this.display.height = document.documentElement.clientHeight;
    },
    onLoad() {
        const router = this.$route;
        const meta = this.$router.currentRoute.value.meta;
        this.router.name = router.name.replace("-index", "").replace("-add", "").replace("-edit", "");
        this.router.module = meta.module ?? this.router.name;
        this.router.type = meta.type ?? "";
        this.router.model = meta.model ?? "";
        this.router.schema = meta.schema ?? "";
        this.router.title = meta.title ?? "";
        this.router.subTitle = meta.subtitle ?? "";
    }
};

var grid = {
    props: {
        
    }
}

var record = {
    data() { return { ...model, ...data } },
    computed: { ...computed },
    methods: { ...crud, ...method },
    watch: { v$: { handler() { this.modelState = this.v$ }, deep: true }, },
    async created() {
        window.addEventListener("resize", this.onWindowResize);
        this.getRouter();
        this.model = this.init;
        if (this.$route.params.mask)
            this.get(this.$route.params.mask).then(() => {
                this.model = this.record;
                this.$nextTick(() => { this.v$.$reset(); });
            });
        this.activity.submitted = this.$vm.$submitted = false;
    },
    async mounted() {
        try {
            this.activity.loading.form = false;
        } catch {/**/ }

    },
    async updated() {
        this.getRouter();
        this.activity.submitted = this.$vm.$submitted;
    },
    async beforeUnmount() {
        try {
            this.activity.loading.form = true;
        } catch {/**/ }
        window.removeEventListener("resize", this.onWindowResize);
    },
    async unmount() { this.activity.loading = true; }
};

var form = {
    emits: ["is-reset", "is-send", "update", "update:modelValue"],
    props: { ...model },
    data() { return { ...data }; },
    computed: { ...computed },
    methods: {
        ...method,
        ...crud,
        onSubmit(form) {
            form.preventDefault();
            this.modelState.$touch();
            this.$vm.$submitted = this.activity.submitted = true;
            if (!this.modelState.$invalid) {
                if (!this.$route.params.mask) {
                    this.insert(this.model).then(() => {
                        if (this.errors)
                            this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: this.errors[0].message, life: 3000 });
                        else {
                            this.$router.push({ name: this.router.name + '-edit', params: { mask: this.record.mask } });
                            this.$toast.add({ severity: 'success', summary: 'Record Insert Success!', detail: 'The record was added successfully.', life: 3000 });
                            this.onResetModelState();
                            setTimeout(() => this.$emit("is-reset"), 500);
                        }
                    }).catch((err) => {
                        this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: err.xhr, life: 3000 });
                    });
                }
                else {
                    this.update(this.model).then(() => {
                        if (this.errors)
                            this.$toast.add({ severity: 'error', summary: 'Record Update Failed!', detail: this.errors[0].message, life: 3000 });
                        else {
                            this.$toast.add({ severity: 'success', summary: 'Record Update Success!', detail: 'The record was updated successfully.', life: 3000 });
                            this.onResetModelState();
                        }
                    }).catch((err) => {
                        this.$toast.add({ severity: 'error', summary: 'Record Update Failed!', detail: err.xhr, life: 3000 });
                    });
                }
            }
        },
        onResetForm() { this.$emit("is-reset"); },
        onSendForm() { this.$emit("is-send"); }
    }
};

var toolbar = {
    emits: ["is-reset"],
    props: {
        mask: { type: String, default: null },
        back: { type: String, default: 'dashboard' },
        dirty: { type: Boolean, default: false }
    },
    data() { return { ...data } },
    computed: {
        ...computed,
        labelBACK: () => { (this.display.width > 600) ? "Back" : null; },
        labelSAVE: () => { (this.display.width > 600) ? (this.model && this.model.mask ? "Update" : "Save") : null; },
        labelDELETE: () => { (this.display.width > 600) ? "Delete" : null; },
        labelRESET: () => { (this.display.width > 600) ? "Reset" : null; },
        labelCLONE: () => { (this.display.width > 600) ? "Clone" : null; },
        labelPRINT: () => { (this.display.width > 600) ? "Print" : null; },
        labelSEND: () => { (this.display.width > 600) ? "Send" : null; },
        labelFAQ: () => { (this.display.width > 600) ? "FAQ" : null; },
        menuFAQ() { return [{ label: 'Support', icon: 'pi pi-fw pi-ticket', command: () => { this.SUPPORT_onClick(); } }] }
    },
    methods: {
        ...method,
        ...crud,
        // BACK OPERATION
        BACK_onClick() {
            this.activity.loading.back = true;
            if (this.dirty)
                this.dialog.dirty = true;
            else
                this.BACK_onConfirm();
            setTimeout(() => this.activity.loading.back = false, 500, this);
        },
        BACK_onConfirm() { return this.$router.push({ name: this.router.name + '-index' }) },
        // SAVE OPERATION
        SAVE_onClick() {
            this.activity.loading.back = this.activity.loading.delete = this.activity.loading.reset = this.activity.loading.clone = this.activity.loading.send = this.activity.loading.print = this.activity.loading.save = this.activity.submitted = this.$vm.submitted = true;
            setTimeout(() => this.activity.loading.back = this.activity.loading.delete = this.activity.loading.reset = this.activity.loading.clone = this.activity.loading.send = this.activity.loading.print = this.activity.loading.save = false, 500, this);
        },
        // DELETE OPERATION
        DELETE_onClick() {
            this.activity.loading.delete = this.dialog.delete = true;
            setTimeout(() => this.activity.loading.delete = false, 500, this);
        },
        DELETE_onConfirm() {
            this.activity.loading.delete = true;
            this.delete(this.mask).then(() => {
                if (!this.errors) {
                    this.$toast.add({ severity: 'success', summary: 'Record Deleted Successfully', detail: 'The record was successfully deleted', life: 5000 });
                    this.$router.push({ name: this.router.name + '-index' });
                }
                else
                    this.$toast.add({ severity: 'danger', summary: 'Record Delete Failed', detail: this.errors[0].message, life: 3000 });
            }).catch((error) => { this.$toast.add({ severity: 'danger', summary: 'Unhandled Error', detail: error, life: 3000 }); });
            this.dialog.delete = false;
        },
        // RESET OPERATION
        RESET_onClick() {
            this.activity.loading.back = this.activity.loading.delete = this.activity.loading.reset = this.activity.loading.clone = this.activity.loading.send = this.activity.loading.print = this.activity.loading.save = this.activity.submitted = this.$vm.submitted = true;
            this.activity.loading.refresh = true;
            if (this.isDirty)
                this.dialog.refresh = true;
            else
                this.$emit("is-reset");
            setTimeout(() => this.activity.loading.back = this.activity.loading.delete = this.activity.loading.reset = this.activity.loading.clone = this.activity.loading.send = this.activity.loading.print = this.activity.loading.save = this.activity.submitted = this.$vm.submitted = false, 500, this);
        },
        // UNDER DEVELOPMENT
        CLONE_onClick() {
            this.activity.loading.clone = true;
            this.DEVELOPMENT();
            setTimeout(() => this.activity.loading.clone = false, 200, this);
        },
        SEND_onClick() {
            this.activity.loading.send = true;
            this.DEVELOPMENT();
            setTimeout(() => this.activity.loading.send = false, 200, this);
        },
        PRINT_onClick() {
            this.activity.loading.print = true;
            this.DEVELOPMENT();
            setTimeout(() => this.activity.loading.print = false, 200, this);
        },
        FAQ_onClick() {
            this.activity.loading.faq = true;
            this.DEVELOPMENT();
            setTimeout(() => this.activity.loading.faq = false, 200, this);
        },
        SUPPORT_onClick() {
            this.activity.loading.support = true;
            this.DEVELOPMENT();
            setTimeout(() => this.activity.loading.support = false, 200, this);
        },
        // DIALOG ACTION
        DIALOG_onCancel() { this.dialog.refresh = this.dialog.dirty = false; },
        DIALOG_onConfirm(isRefresh) {
            if (isRefresh) {
                this.$emit("is-reset");
                this.DIALOG_onCancel();
            }
            else
                this.BACK_onConfirm();
        }
    },
    async created() { this.onResize(); },
    async updated() { this.onResize(); }
};

var component = {
    emits: ["is-sync", "update", "update:modelValue"],
    props: {
        id: { type: String, required: true, validator: (value) => /^[a-zA-Z0-9_]*$/.test(value) },
        // Display
        autoFit: { type: Boolean, default: true },
        // Labels
        label: { type: String },
        hideLabel: { type: Boolean, default: false },
        floatLabel: { type: Boolean, default: true },
        preLabel: { type: String },
        preIcon: { type: String },
        preCSS: { type: String, default: 'overflow: hidden; white-space: nowrap; width: 100%; text-overflow: ellipsis;' },
        postLabel: { type: String },
        postIcon: { type: String },
        postCSS: { type: String, default: 'min-width: 20px; padding-right: 5px;' },
        // Helpers
        placeholder: { type: String },
        ph: { String },
        tooltip: { type: String },
        // Data
        required: { type: Boolean, default: false },
        conditional: { type: Boolean, default: false },
        nullable: { type: Boolean, default: false },
        v$: { type: Object },
        vID: { type: String },
        vBase: { type: String },
        vError: { type: String },
        // Availability
        disabled: { type: Boolean, default: false }
    },
    data() {
        return {
            model: {
                value: null,
                text: null,
                record: null,
                state: {
                    required: false,
                    conditional: false,
                    maxLength: -1
                }
            }
        }
    },
    computed: {
        ...browser,
        objBlank() {
            if (typeof (this.model?.value) === 'number')
                return (this.model?.value ? this.model.value : 1.00) === 0.00;
            else
                return (this.model?.value ? this.model.value : '&nbsp;') === '&nbsp;'
        },
        objType() {
            if (this.type)
                return this.type.toLowerCase().trim();
            return null;
        },
        phText() {
            if (this.placeholder && this.placeholder.trim().length > 0)
                return this.placeholder;
            return this.ph;
        },
    },
    watch: {
        v$: {
            handler() {
                if (this.v$ && this.v$.model) {
                    let model = this.v$.model[this.vID ?? this.id];
                    if (model) {
                        this.model.state.required = model.required ? true : false;
                        if (model.maxLength)
                            this.model.state.maxLength = model.maxLength.$params?.max ?? 0;
                    }
                }

            },
            deep: true
        },
        modelValue() {
            this.model.value = this.modelValue;
            if (!this.modelValue) {
                this.model.text = null;
                this.model.record = null;
            }
        },
        value() {
            this.model.value = this.value;
            if (!this.value) {
                this.model.text = null;
                this.model.record = null;
            }
        },
        "model.value": {
            handler(e) {
                try {
                    if (this.onSync)
                        if (typeof (this.onSync) === "function")
                            this.onSync(e);
                }
                catch { /* This is catch */ }
            },
            deep: true
        }
    },
    methods: {
        onCopy() { navigator.clipboard.writeText(this.model.text ?? this.model.value ); },
    },
    async created() {
        this.model.value = this.value;
     },
}

export default {
    GRID: grid,
    RECORD: record,
    FORM: form,
    TOOLBAR: toolbar,
    COMPONENT: component
};