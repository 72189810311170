<template>
    <div class="card">
        <div class="p-fluid p-grid">
            <div class="p-col-12 p-md-3">
                <h6>Initial State</h6>
                <form-datetime id="DateTimeInitial" :value="model.blank.dateTime" @is-sync="model.blank.dateTime = $event.value" />
                <form-datetime id="DateInitial" type="date" :value="model.blank.date" @is-sync="model.blank.date = $event.value" />
                <form-datetime id="TimeSpanInitial" type="time" :required="true" :value="model.blank.time" @is-sync="model.blank.timeSpan = $event.record.timeSpan; model.blank.time = $event.value" />
            </div>
            <div class="p-col-12 p-md-3">
                <h6>Initial Disabled State</h6>
                <form-datetime :disabled="true" id="DateTimeInitial" :value="model.blank.dateTime" @is-sync="model.blank.dateTime = $event.value" />
                <form-datetime :disabled="true" id="DateInitial" type="date" :value="model.blank.date" @is-sync="model.blank.date = $event.value" />
                <form-datetime :disabled="true" id="TimeSpanInitial" type="time" :required="true" :value="model.blank.time" @is-sync="model.blank.timeSpan = $event.record.timeSpan; model.blank.time = $event.value" />
            </div>
            <div class="p-col-12 p-md-3">
                <h6>Transaction State</h6>
                <form-datetime id="DateTimeTransaction" :value="model.transaction.dateTime" @is-sync="model.transaction.dateTime = $event.value" />
                <form-datetime id="DateTransaction" type="date" :value="model.transaction.date" @is-sync="model.transaction.date = $event.value" />
                <form-datetime id="TimeSpanTransaction" type="time" :required="true" :value="model.transaction.time" @is-sync="model.transaction.timeSpan = $event.record; model.transaction.time = $event.value" />
            </div>
            <div class="p-col-12 p-md-3">
                <h6>Disabled State</h6>
                <form-datetime :disabled="true" id="DateTimeTransaction" :value="model.transaction.dateTime" @is-sync="model.transaction.dateTime = $event.value" />
                <form-datetime :disabled="true" id="DateTransaction" type="date" :value="model.transaction.date" @is-sync="model.transaction.date = $event.value" />
                <form-datetime :disabled="true" id="TimeSpanTransaction" type="time" :required="true" :value="model.transaction.time" @is-sync="model.transaction.timeSpan = $event.record.timeSpan; model.transaction.time = $event.value" />
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'FormDateTimeComponents',
        data() {
            return {
                model: {
                    blank: {
                        dateTime: null,
                        date: null,
                        time: null,
                        timeSpan: null
                    },
                    transaction: {
                        dateTime: new Date(),
                        date: new Date('2021-01-01'),
                        time: new Date('2021-01-01 11:00 PM'),
                        timeSpan: this.$filters.init({
                            ticks: 863990000000,
                            days: 0,
                            hours: 23,
                            milliseconds: 0,
                            minutes: 0,
                            seconds: 0,
                            totalDays: 0.999988425925926,
                            totalHours: 23.99972222222222,
                            totalMilliseconds: 86399000,
                            totalMinutes: 1439.9833333333333,
                            totalSeconds: 86399
                        })
                    }
                }
            }
        }
    }
</script>

