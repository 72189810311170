<template>
    <pv-card>
        <template v-slot:title>
            Allocation
        </template>
        <template v-slot:subtitle>
            Please select carrier service to use.
        </template>
        <template v-slot:content>
            <allocate-form :formData="model" :recordformData="formModel" @is-sync="onSync($event)"></allocate-form>
        </template>
        <template v-slot:footer>
            <div class="p-grid p-nogutter p-justify-between">
                <div class="p-justify-start">
                    <pv-button label="Previous" @click="prevPage()" icon="pi pi-angle-left" />
                </div>
                <div class="p-justify-end">
                    <pv-button style="margin-right: 0.5rem !important;" :label="buttonAction.label" @click="saveOrder()" :icon="buttonAction.icon" iconPos="right" :class="buttonAction.class" :loading="buttonAction.processing" v-tooltip.top="{value: 'Save Consignment'}" />
                    <pv-button label="Next" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" :disabled="!isValid" />

                </div>
            </div>
        </template>
    </pv-card>
</template>
<script>
    //const config = require("@/assets/_js/cosmos-config.js").default;
    import mixins from '@/assets/lib/cosmos/_js/consignment-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    import allocateForm from '@/components/transaction/tms/consignment/allocate-form.vue';
    
    export default {
        name: 'AllocationTemplate',
        mixins: [mixins.ALLOCATE.STEPPER],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'allocate-form': allocateForm
        },
        data() {
            return {
                formModel: null,
                buttonAction: {
                    processing: false,
                    class: "p-button-success",
                    icon: "pi pi-save",
                    label: "Save"
                }
            }
        },
        computed: {
            isValid() {
                return (this.model?.allocationDetail?.isValid ?? true);  
            }
        },
        watch: {
            'formData': {
                handler() {
                    this.$nextTick(() => {
                        if (this.model.manageProcess === true) {
                            this.buttonAction.processing = false;
                            this.buttonAction.class = "p-button-success";
                            this.buttonAction.icon = "pi pi-save";
                            this.buttonAction.label = "Save";
                        }
                    });
                }, deep: true
            }
        },
        methods: {
            nextPage() {
                if (this.isValid) {
                    this.$nextTick(() => {
                        this.$emit('next-page', { formData: this.model, pageIndex: 3 });
                    })
                }
            },
            prevPage() {
                this.$emit('prev-page', { formData: this.model, pageIndex: 3 });
            },
            saveOrder() {
                if (!this.buttonAction.processing) {
                    this.buttonAction.processing = true;
                    this.buttonAction.class = "p-button-warning";
                    this.buttonAction.icon = "pi pi-spinner";
                    this.buttonAction.label = "Please Wait...";
                    this.$toast.add({ severity: 'info', summary: 'Please wait...', detail: "Consignment is currently saving do not refresh the page", life: 6000 });
                    this.$emit('manage-order');
                }
            }
            
        },
        mounted() {
            let _self = this;
            this.$nextTick(() => {
                this.formModel = _self.$props.formData;
            });
            
        },
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
    .p-orderlist-controls,
    .p-picklist-buttons.p-picklist-source-controls,
    .p-picklist-buttons.p-picklist-source-controls, .p-picklist-buttons.p-picklist-target-controls {
        display: none !important;
    }
</style>


<style scoped>
    .picklist-item {
        display: flex;
        align-items: center;
        padding: .5rem;
        width: 100%;
    }

    img {
        width: 75px;
     /*   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);*/
        margin-right: 1rem;
    }

    .picklist-list-detail {
        flex: 1 1 0;
    }

    .picklist-list-action {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .picklist-category-icon {
        vertical-align: middle;
        margin-right: .5rem;
        font-size: .875rem;
    }

    .picklist-category {
        vertical-align: middle;
        line-height: 1;
        font-size: .875rem;
    }
    .custom-skeleton {
        border: 1px solid var(--surface-d);
        border-radius: 4px;
    }

    .custom-skeleton ul {
        list-style: none;
    }

        .custom-skeleton ul > li {
            padding: 0.75rem;
        }
</style>
