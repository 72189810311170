<template>
    <div class="confirmation-content p-grid">
        <div class="p-col-12 p-md-6">
            <form-lookup id="serviceIntegrationID" :disabled="accountID > 0" :value="model.data.serviceProvider.id ?? -1000" label="Service Provider" :source="sources.serviceProviders" :required="true" @is-sync="setIntegration($event)" />
            <form-lookup id="accountID" :disabled="sources.accounts.length === 0 || (model.key ?? -1) > 0" :value="model.data.accountDetail.id" placeholder="Select Carrier Account..." :required="true" label="Account Number" :source="sources.accounts" @is-sync="setProduct($event)" />
        </div>
        <div class="p-col-12 p-md-6">
            <form-input id="code" :value="code" @is-sync="code = $event.value?.toUpperCase()" :required="true" label="Code" :maxLength="20"></form-input>
            <form-lookup id="countryID" :disabled="true" :value="model.data.contractCountry.id" label="Country of Contract" source="country" />
        </div>
    </div>
    <div class="confirmation-content p-grid" style="margin: 0 -20px;">
        <div class="p-col-12 p-md-12">
            <pv-tab>
                <pv-tabpanel header="Products">
                    <pv-picklist v-model="items" listStyle="height:342px" dataKey="id" @move-to-target="moveToTarget" @move-all-to-target="moveAllToTarget" @move-to-source="moveToSource" @move-all-to-source="moveAllToSource">
                        <template #sourceHeader>
                            <div class="p-d-flex" style="width: 100%;">
                                <div class="p-col-2" style="padding-top: 10px;">
                                    Available
                                </div>
                                <div class="p-col-10" style=" margin: 0; padding: 0;">
                                    <pv-input style="margin: 0 10px; width: 100%;" v-model="filter.source" placeholder="Type your keyword..." />
                                </div>
                            </div>
                        </template>
                        <template #targetHeader>
                            <div class="p-d-flex" style="width: 100%;">
                                <div class="p-col-2" style="padding-top: 10px;">
                                    Selected
                                </div>
                                <div class="p-col-10" style=" margin: 0; padding: 0;">
                                    <pv-input style="margin: 0 10px; width: 100%;" v-model="filter.target" placeholder="Type your keyword..." />
                                </div>
                            </div>
                        </template>
                        <template #item="slotProps">
                            <div class="picklist-item">
                                <div class="picklist-list-detail" style="margin: 0; padding: 0;">
                                    <div v-if="activity.loading" class="p-field p-col-12 p-md-12" style="width: 100%; padding: 0; margin: 0;">
                                        <div class="custom-skeleton">
                                            <ul class="p-m-0 p-p-0">
                                                <li>
                                                    <div class="p-d-flex">
                                                        <div style="flex: 1">
                                                            <pv-skeleton width="100%" class="p-mb-2"></pv-skeleton>
                                                            <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                            <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                            <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                            <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div v-else class="p-d-flex" style="width: 100%; padding: 0;">
                                        <div style="width: 100%; padding: 0; margin: 0;">
                                            <div class="p-d-flex p-jc-between" style="width: 100%; z-index: 100;">
                                                <div><h5 style="margin-bottom: 5px; margin-top: 0; font-size: 1.2rem;"><span>{{ slotProps?.item?.name }}</span></h5></div>
                                                <div class="p-as-end"><h5 class="p-code-text">[{{ slotProps?.item?.code}}]</h5></div>
                                            </div>
                                            <div>
                                                <div class="p-grid" style="z-index: 100; width: 100% !important;">
                                                    <div class="p-col-12 p-md-5">
                                                        <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Maximum Package: </b>  {{ slotProps?.item?.maxParcelCount }} per consignment</p>
                                                        <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Weight Range: </b>  {{ slotProps?.item?.minWeight?.toFixed(3) }} - {{ slotProps?.item?.maxWeight?.toFixed(3) }} {{ slotProps?.item?.weightUnitName?.toLowerCase() }}</p>
                                                        <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Max Dimension: </b>  {{ slotProps?.item?.maxLength }}{{ slotProps?.item?.lengthUnitName?.toLowerCase() }} x {{ slotProps?.item?.maxWidth }}{{ slotProps?.item?.lengthUnitName?.toLowerCase() }} x {{ slotProps.item.maxHeight }}{{ slotProps?.item?.lengthUnitName?.toLowerCase() }}</p>
                                                    </div>
                                                    <div class="p-col-12 p-md-5">
                                                        <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Maximum Transit Days: </b> {{ slotProps?.item?.maxDeliveryDay }} {{ $filters.quantifiedDisplay("days", slotProps?.item?.maxDeliveryDay) }}</p>
                                                        <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Estimated Time: </b>  {{ slotProps?.item?.deliveryHourTimeSpan?.hours === 23 ? "Within the day" : $filters.padLeft(slotProps?.item?.deliveryHourTimeSpan?.hours - ( slotProps?.item?.deliveryHourTimeSpan?.hours > 12 ? 12 : 0), 2, '0') + ":" + $filters.padLeft(slotProps?.item?.deliveryHourTimeSpan?.minutes, 2, '0') + (slotProps?.item?.deliveryHourTimeSpan?.hours >= 12 ? " PM" : " AM") }}</p>
                                                        <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem">
                                                            <b class="p-code-text">Delivery Type: </b><span v-for="item in slotProps?.item?.usageTypeName ?? []" :key="item" :class="`p-code-text p-text-indicator definite-${item?.toUpperCase()}`">{{ item }}</span>
                                                        </p>
                                                    </div>
                                                    <div class="p-col-12 p-md-2">
                                                        <div style="text-align: right; object-position: right">
                                                            <h5 style="margin-top: 0 !important;"><span :class="`p-code-text p-indicator definite-${slotProps?.item?.timeDefiniteType}`" :title="slotProps.item?.timeDefiniteTypeName">{{ slotProps?.item?.timeDefiniteTypeName?.substring(0,1)}}</span></h5>
                                                            <h5 style="margin-top: 0 !important;">
                                                                <span v-for="item in slotProps?.item?.productContentTypeName ?? []" :key="item" :class="`p-code-text p-indicator definite-${item?.toUpperCase()}`" :title="item">{{ item?.substring(0,1) }}</span>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </pv-picklist>
                </pv-tabpanel>
                <pv-tabpanel header="Settings">
                    <div class="p-grid p-col-12" style="margin-top:0.75rem; margin-left:0.05rem;">
                        <div class="p-col-12 p-md-6">
                            <form-lookup id="standardTypes" :allowedFlags="model?.data?.integration?.standardLabelTypes ?? 15" label="Standard Label Type" type="enum" source="labeltype" v-model="model.data.standardLabelType" placeholder="Inherit From Carrier Account..." />
                            <form-lookup id="returnTypes" :allowedFlags="model?.data?.integration?.returnLabelTypes ?? 15" label="Return Label Type" type="enum" source="labeltype" v-model="model.data.returnLabelType" placeholder="Inherit From Carrier Account..." />
                            <form-boolean id="schedule_StrictCutOff"  :value="model?.data?.schedule_StrictCutOff" type="toggle"  label="Strict Cut Off Time?" textPosition="left" @is-sync="model.data.schedule_StrictCutOff = $event.value" :showCopy="false"></form-boolean>
                            <form-datetime-utc v-show="model?.data?.schedule_StrictCutOff" id="schedule_CutOffTime" :stepMinute="5" :value="model?.data?.schedule_CutOffTime" type="time" @is-sync="model.data.schedule_CutOffTime = $event.value" :timeFormat="24" label="CutOff Time" :v$="v$" :showCopy="false"></form-datetime-utc>
                            <form-boolean id="arrangeOutboundCollection" v-if="model?.data?.serviceProvider?.supportArrangeOutboundCollection" :value="model.data.arrangeOutboundCollection" type="toggle" label="With Arrange Outbound Collection?" textPosition="left" @is-sync="model.data.arrangeOutboundCollection = $event.value"></form-boolean>
                            <form-boolean id="excludeArchivedLabel" v-if="model?.data?.serviceProvider?.supportExcludeArchivedLabel" :value="model.data.excludeArchivedLabel" type="toggle" label="Exclude Archived Label?" textPosition="left" @is-sync="model.data.excludeArchivedLabel = $event.value"></form-boolean>
                            <form-boolean id="returnReferenceOnly" :value="model.data.returnReferenceOnly" type="toggle" label="Return Reference Only?<br/><span style='color:darkred !important; font-size:0.7rem;' class='p-code-text'>Activate the setting if labels and documents will not be included in API Allocate/Dispatch response.</span>" textPosition="left" @is-sync="model.data.returnReferenceOnly = $event.value"></form-boolean>
                        </div>
                        <div class="p-col-12 p-md-6">
                            <form-input id="attribute_01" v-if="model?.data?.serviceProvider?.supportAdhocInboundCollection" :value="model.data.attribute_01" label="SFTP Host" @is-sync="model.data.attribute_01 = $event.value" :max-length="1000"></form-input>
                            <form-input id="attribute_02" v-if="model?.data?.serviceProvider?.supportAdhocInboundCollection" :value="model.data.attribute_02" label="SFTP Username" @is-sync="model.data.attribute_02 = $event.value" :max-length="1000"></form-input>
                            <form-input id="attribute_03" v-if="model?.data?.serviceProvider?.supportAdhocInboundCollection" :value="model.data.attribute_03" label="SFTP Path" @is-sync="model.data.attribute_03 = $event.value" :max-length="1000"></form-input>
                            <form-input id="attribute_04" v-if="model?.data?.serviceProvider?.supportAdhocInboundCollection" :value="model.data.attribute_04" type="textarea" rows="4" label="SFTP Private Key" @is-sync="model.data.attribute_04 = $event.value" :max-length="4000"></form-input>
                            <form-input id="attribute_06" v-if="(model?.data?.serviceProvider?.supportPODDownload ?? 0) != 0" :value="model.data.attribute_06" label="FTP Host" @is-sync="model.data.attribute_06 = $event.value" :max-length="1000"></form-input>
                            <form-input id="attribute_07" v-if="(model?.data?.serviceProvider?.supportPODDownload ?? 0) != 0" :value="model.data.attribute_07" label="FTP Username" @is-sync="model.data.attribute_07 = $event.value" :max-length="1000"></form-input>
                            <form-input id="attribute_08" v-if="(model?.data?.serviceProvider?.supportPODDownload ?? 0) != 0" :value="model.data.attribute_08" label="FTP Password" @is-sync="model.data.attribute_08 = $event.value" :max-length="1000"></form-input>
                            <form-input id="attribute_09" v-if="(model?.data?.serviceProvider?.supportPODDownload ?? 0) != 0" :value="model.data.attribute_09" label="FTP Path" @is-sync="model.data.attribute_09 = $event.value" :max-length="1000"></form-input>
                            <form-input id="attribute_10" v-if="(model?.data?.serviceProvider?.supportPODDownload ?? 0) != 0" :value="model.data.attribute_10" label="FTP Port" @is-sync="model.data.attribute_10 = $event.value" :max-length="2"></form-input>
                        </div>
                    </div>
                </pv-tabpanel>
                <pv-tabpanel header="Automation">
                    <div class="p-grid p-col-12" style="margin-top:0.75rem; margin-left:0.05rem;">
                        <div class="p-col-12 p-md-6">
                            <form-boolean id="setting_AutoCollect" :value="model.data.setting_AutoCollect" type="toggle" label="Auto Collect?" textPosition="left" @is-sync="model.data.setting_AutoCollect = $event.value" :showCopy="false"></form-boolean>
                            <form-numeric v-show="model?.data?.setting_AutoCollect" id="setting_DaysToAutoCollect" :value="model.data.setting_DaysToAutoCollect" label="Collect After" postLabel="Day(s)" :v$="v$" @is-sync="model.data.setting_DaysToAutoCollect = $event.value" :showCopy="false"></form-numeric>
                            <form-datetime-utc v-show="model?.data?.setting_AutoCollect" id="setting_TimeToAutoCollect" :stepMinute="5" :value="model.data?.setting_TimeToAutoCollect" type="time" @is-sync="model.data.setting_TimeToAutoCollect = $event.value" :timeFormat="24" label="Collect Around" :v$="v$" :showCopy="false"></form-datetime-utc>
                        </div>
                    </div>

                </pv-tabpanel>
            </pv-tab>
            
        </div>
    </div>
</template>
<script>
    export default {
        name: 'AccountSubscriptionForm',
        emits: ["is-sync"],
        props: {
            customerID: { type: Number, default: -1 }, // This is the customer id where the entry will be assigned
            accountID: { type: Number, default: -1 }, // This is the serviceProviderAccountCustomer.Id that allows the tools to know if the action is edit 
            environmentID: { type: Number, default: -1000 },
            formData: { type: Object }, // This is the data equivalent of the currently selected value
            subscriptions: { type: Object } // This is the list of all active subscription to be used for account code validation.
        },
        data() {
            return {
                structure: {
                    key: -1,
                    data: {
                        id: null,
                        id2: null,
                        code: null,
                        name: null,
                        standardLabelType: null,
                        returnsLabelType: null,
                        integration: null,
                        accountDetail: { id: null, name: null, code: null, id2: null },
                        serviceProvider: { id: null, name: null, code: null, id2: null },
                        contractCountry: { id: null, name: null, code: null, id2: null },
                        contractOwner: { id: null, name: null, code: null, id2: null }
                    },
                    children: [{ data: { id: null, id2: null, name: null, code: null, serviceProvider: { id: null, name: null, code: null, id2: null } } },
                            { data: { id: null, id2: null, name: null, code: null, serviceProvider: { id: null, name: null, code: null, id2: null } } },
                            { data: { id: null, id2: null, name: null, code: null, serviceProvider: { id: null, name: null, code: null, id2: null } } },
                            { data: { id: null, id2: null, name: null, code: null, serviceProvider: { id: null, name: null, code: null, id2: null } } }]
                },
                code: null,
                model: {
                    key: -1,
                    data: {
                        id: null,
                        id2: null,
                        code: null,
                        name: null,
                        standardLabelType: null,
                        returnLabelType: null,
                        integration: null,
                        accountDetail: { id: null, name: null, code: null, id2: null },
                        serviceProvider: { id: null, name: null, code: null, id2: null },
                        contractCountry: { id: null, name: null, code: null, id2: null },
                        contractOwner: { id: null, name: null, code: null, id2: null }
                    },
                    children: [{ data: { id: null, id2: null, name: null, code: null, serviceProvider: { id: null, name: null, code: null, id2: null } } },
                        { data: { id: null, id2: null, name: null, code: null, serviceProvider: { id: null, name: null, code: null, id2: null } } },
                        { data: { id: null, id2: null, name: null, code: null, serviceProvider: { id: null, name: null, code: null, id2: null } } },
                        { data: { id: null, id2: null, name: null, code: null, serviceProvider: { id: null, name: null, code: null, id2: null } } }]
                },
                child: {
                    data: {
                        id: null,
                        id2: null,
                        name: null,
                        code: null,
                        serviceProvider: { id: null, name: null, code: null, id2: null }
                    }
                },
                // Sources
                sources: {
                    customerAccounts: [], // This refers to all exisiting customer subscriptions
                    serviceProviderProducts: [{ id: null, name: null, code: null, id2: null },
                                                { id: null, name: null, code: null, id2: null },
                                                { id: null, name: null, code: null, id2: null },
                                                { id: null, name: null, code: null, id2: null }], // This is the master product list based on the selected service provider
                    accountProducts: [],
                    accounts: [],
                    serviceProviders: []
                },
                // Tools
                filter: { source: null, target: null },
                activity: { loading: true },
                dialog: {
                    account: false,
                    delete: false,
                    deletekey: null
                },
                
            }
        },
        watch: {
            'model': {
                handler() {
                    var model = this.$filters.init(this.model);
                    model.code = this.code;
                    this.$emit('is-sync', this.$filters.init(model));
                }, deep: true
            },
            'code': {
                handler() {
                    var model = this.$filters.init(this.model);
                    model.code = this.code;
                    this.$emit('is-sync', this.$filters.init(model));
                }, deep: true
            },
            'model.children': {
                handler() {
                    this.$emit('is-sync', this.$filters.init(this.model));
                }, deep: true
            },
            'accountID': {
                handler() {
                    if (this.accountID > 0)
                        this.model = this.$filters.init(this.$props.formData);
                    else {
                        this.model.children = [];
                        this.activity.loading = false;
                    }
                }, deep: true
            },
            'formData': {
                handler() {
                    if ((this.accountID ?? -1) > 0)
                        this.model = this.$filters.init(this.$props.formData);
                    else {
                        this.model.children = [];
                        this.activity.loading = false;
                    }
                }, deep: true
            },
            'subscriptions': {
                handler() {
                    this.sources.customerAccounts = this.$props.subscriptions;
                }, deep: true
            },
        },
        computed: {
            sftpKey() {
                console.log(this.model?.data?.attribute_04?.replace(/\u00ACn/g, "\n"));
                return this.model?.data?.attribute_04?.replace(/\u00ACn/g, "\n");
            },
            sftpKeyValue() {
                return this.model?.data?.attribute_04;
            },
            filteredSource() {
                let ids = []
                if (this.model.children.length > 0)
                    ids = (this.model.children).map(x => x?.data?.serviceProvider?.id);
                let source = this.model.data.accountDetail.id > 0 ? this.sources.serviceProviderProducts.filter(x => ids.indexOf(x.id) === -1) : [];
                if (this.model.data.serviceProvider.id && this.filter.source && this.filter.source.trim() !== "")
                    source = source.filter(x => x.code?.toLowerCase().includes(this.filter.source.toLowerCase()) || x.name?.toLowerCase().includes(this.filter.source.toLowerCase()));
                return source;
            },
            filteredTarget() {
                let ids = []
                if (this.model.children.length > 0)
                    ids = (this.model.children).map(x => x?.data?.serviceProvider?.id);
                let target = this.model.data.accountDetail.id > 0 ? this.sources.serviceProviderProducts.filter(x => ids.indexOf(x.id) !== -1) : [];
                if (this.filter.target && this.filter.target.trim() !== "")
                    target = target.filter(x => x.code?.toLowerCase().includes(this.filter.target.toLowerCase()) || x.name?.toLowerCase().includes(this.filter.target.toLowerCase()));
                return target;
            },
            items() {
                return [this.filteredSource, this.filteredTarget];
            }
        },
        methods: {
            rebind() {
                this.sources.serviceProviders = [];
                this.$store.dispatch("provider/getURL", { url: `/services/${(this.accountID > 0 ? 'settings/serviceprovider' : 'admin/serviceprovideraccount')}/byenvironment/id/${this.environmentID}` })
                    .then((response) => {
                        var accountIDs = (this.$props.subscriptions ?? this.subscriptions ?? []).map(x => x.data.accountDetail.id);
                        var accounts = response.result.filter(x => accountIDs.findIndex(y => y === x.id) === -1);
                        if (response && response.success)
                            this.sources.serviceProviders = accounts;
                        if (this.accountID <= 0)
                            this.sources.serviceProviders = this.$vm.$providers.filter(x => accounts.findIndex(y => y.serviceProviderID === x.id) !== -1);
                        if (this.accountID > 0)
                            this.model = this.$filters.init(this.$props.formData);
                        this.code = this.model.data.code;
                    });
            },
            generateCode(code) {
                for (var i = 0; i < 1000; i++) {
                    let tempCode = code + (i === 0 ? "" : i);
                    if (this.sources.customerAccounts.filter(x => x.data.code?.toUpperCase() === tempCode?.toUpperCase())[0] === undefined) {
                        i = 10000;
                        return tempCode;
                    }
                }
                return code;
            },
            async initialize() {
                this.sources.customerAccounts = this.$props.subscriptions;
                if ((this.$props.accountID ?? -1) > 0)
                    this.model = this.$filters.init(this.$props.formData);
                else {
                    this.sources.serviceProviderProducts = [];
                    this.activity.loading = false;
                }
            },
            async setIntegration(event) {
                if (this.accountID === -1) {
                    this.model = this.structure;
                }
                if (event !== null && event.record === null) {
                    if (this.accountDetail === -1) {
                        this.model.data.accountDetail.id = null;
                        this.model.data.accountDetail.name = null;
                    }
                }
                else if (event && event.record) {
                    if (this.$props.accountID === -1) {
                        this.model.data.code = this.code = this.generateCode(event.record.code);
                        this.model.data.accountDetail.id = null;
                        this.model.data.accountDetail.name = null;
                        this.model.data.serviceProvider.id = event.value;
                    }
                    this.model.data.serviceProvider.code = event.record.code;
                    this.model.data.serviceProvider.supportExcludeArchivedLabel = event.record.supportExcludeArchivedLabel;
                    this.model.data.serviceProvider.supportArrangeOutboundCollection = event.record.supportArrangeOutboundCollection;
                    this.model.data.serviceProvider.returnReferenceOnly = event.record.returnReferenceOnly;
                    this.model.data.serviceProvider.supportAdhocInboundCollection = event.record.supportAdhocInboundCollection;
                    this.model.data.serviceProvider.supportPODDownload = event.record.supportPODDownload;

                    if (event.value) {
                        this.$store.dispatch("provideraccount/getURL", { url: `/services/admin/serviceprovideraccount/byprovider/id/${event.value}` }).then((response) => {
                            if (response && response.success) {
                                let accounts = response.result;
                                if (this.$props.accountID > -1) {
                                    this.sources.accounts = accounts.filter(x => x.id === this.model.data.accountDetail.id);
                                    this.sources.accountProducts = this.model.children;
                                }
                                else if (this.subscriptions) {
                                    let ids = [];
                                    this.subscriptions.forEach((x) => ids.push(x.data.accountDetail.id));
                                    this.sources.accounts = accounts.filter(x => ids.indexOf(x.id) === -1);
                                }
                                if (this.model.data.serviceProvider !== null && this.model.data.serviceProvider.id > 0) {
                                    this.$store.dispatch("providerproduct/getURL", { url: `/services/settings/serviceproviderproduct/byprovider/id/${this.model.data.serviceProvider.id}` }).then((response) => {
                                        if (response && response.success) {
                                            let products = response.result;
                                            if (this.model.data.accountDetail !== null && this.model.data.accountDetail.id > 0) {
                                                this.$store.dispatch("provideraccountproduct/getURL", { url: `/services/admin/serviceprovideraccountproduct/byaccount/id/${this.model.data.accountDetail.id}` }).then((response) => {
                                                    let accountProducts = response.result ?? [];
                                                    this.sources.accountProducts = accountProducts;
                                                    let ids = accountProducts.map(y => y.productID);
                                                    this.sources.serviceProviderProducts = products.filter(x => ids.indexOf(x.id) !== -1);
                                                    setTimeout(() => { this.activity.loading = false; }, 500);
                                                });
                                            }
                                            else {
                                                this.sources.serviceProviderProducts = products;
                                                setTimeout(() => { this.activity.loading = false; }, 500);
                                            }
                                        }
                                    });
                                }
                                else
                                    setTimeout(() => { this.activity.loading = false; }, 500);
                            }
                        });
                    }
                    else
                        this.sources.accounts = [];
                }
            },
            async setProduct(event) {
                if (this.$props.accountID === -1)
                    this.model.data.accountDetail.id = event?.value;
                if (event !== null && event.value === null && event.record === null) {
                    if (this.accountID === -1) {
                        this.model.data.contractCountry.id = null;
                        this.model.data.contractCountry.name = null;
                    }
                }
                else if (event && event.record) {
                    this.model.data.contractCountry.id = event.record.contractCountryID;
                    this.model.data.integration = (this.$vm.$integrations ?? []).filter(x => x.id === event.record.integrationID)[0];
                    //this.model.data.standardLabelType = event.record.standardLabelType;
                    //this.model.data.returnLabelType = event.record.returnLabelType;
                    if (event && event.value) {
                        this.$store.dispatch("provideraccountproduct/getURL", { url: `/services/admin/serviceprovideraccountproduct/byaccount/id/${event.value}` }).then((response) => {
                            let products = response.result ?? [];
                            this.sources.accountProducts = products;
                            let ids = [];
                            if (products.length > 0)
                                ids = products.map(y => y.productID);
                            this.sources.serviceProviderProducts = this.sources.serviceProviderProducts.filter(x => ids.indexOf(x.id) !== -1);
                        });
                    }
                }
            },
            async moveToTarget(e) {
                let children = [...this.model.children];
                this.model.children = [];
                e.items.forEach((value) => { children.push({ data: { serviceProvider: { id: value.id, name: value.name, code: value.code, id2: value.id2 } } }); });
                this.model.children = children;
            },
            async moveAllToTarget(e) {
                let children = [...this.model.children];
                this.model.children = [];
                this.activity.loading = true;
                e.items.forEach((value) => { children.push({ data: { serviceProvider: { id: value.id, name: value.name, code: value.code, id2: value.id2 } } }); });
                setTimeout(() => {
                    this.model.children = children;
                    this.activity.loading = false;
                }, 100, this);
            },
            async moveToSource(e) {
                let children = [...this.model.children];
                this.model.children = [];
                e.items.forEach((value) => {
                    const index = children.map(x => x.data.serviceProvider?.id).indexOf(value.id);
                    if (index > -1) children.splice(index, 1);
                });
                this.model.children = children;
            },
            async moveAllToSource(e) {
                let children = [...this.model.children];
                this.model.children = [];
                this.activity.loading = true;
                e.items.forEach((value) => {
                    const index = children.map(x => x.data.serviceProvider?.id).indexOf(value.id);
                    if (index > -1) children.splice(index, 1);
                });
                setTimeout(() => {
                    this.model.children = children;
                    this.activity.loading = false;
                }, 100, this);
            },
        },
        mounted() {
            this.initialize();
            this.rebind();
        }
    }
</script>
<style>
    .p-picklist-header { padding: 0 10px !important; }
    .custom-skeleton { border: 1px solid var(--surface-d); border-radius: 4px; }
    .custom-skeleton ul { list-style: none; }
    .custom-skeleton ul > li { padding: 0.75rem; }
</style>