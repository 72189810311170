<template>
    <form-record :model="model" :modelState="v$" :validation="v$" @is-reset="resetRecord" :showSend="false" :showClone="false" :title="model.id > 0 ? `[${model.code}]  ${model.name}` : `Add New Trade Bloc`" :subTitle="model.id > 0 ? `` : `Create a new trade bloc.`">
        <div class="p-grid-flex p-d-flex">
            <div class="p-col-4">
                <form-input id="code" :v$="v$" :disabled="model?.id > 0 && model?.tenantID == null && model?.environmentID == null" v-focus v-uppercase :value="model.code" @is-sync="model.code = $event.value"></form-input>
                <form-input id="name" label="Name" :disabled="model?.id > 0 && model?.tenantID == null && model?.environmentID == null" :v$="v$" :value="model.name" @is-sync="model.name = $event.value"></form-input>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12">
                <pv-tab style="margin: 0 -1rem !important;">
                    <pv-tabpanel header="Countries">
                        <tradebloccountry-form :parentID="model?.id" :formData="model" @is-sync="countrySync($event)"></tradebloccountry-form>
                    </pv-tabpanel>
                </pv-tab>
            </div>
        </div>
    </form-record>
    
</template>

<script>

    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from '@vuelidate/validators';
    import tradeblocCountryFrom from '@/components/ruleengine/tradebloccountry-form.vue';
    const moduleName = 'RuleTradeBloc';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'tradebloccountry-form': tradeblocCountryFrom
        },
        data() {
            return { test: false}
        },
        validations() {
            return {
                model: {
                 /*   code: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },*/
                    code: { required, minLength: minLength(1), maxLength: maxLength(50), $autoDirty: true },
                    name: { maxLength: maxLength(50), $autoDirty: true },
                    
                }
            }
        },
        computed: {
            companyDS() { return this.$store.getters["company/ds"]; },

            customerDS() {
                return  this.companyDS.data.filter((x) => (x.type === 16))
            },
            ds() { return this.$store.getters["ruletradebloc/ds"]; },
            record() { return this.$store.getters["ruletradebloc/record"]; }
            
        },
        methods: {
            async countrySync(e) {
                this.model.countries = e.model;
            },
            async getCompanyList() {
                return await this.$store.dispatch("company/getAll", {sort : null, where: null});
            }
        },
        created() {
           // this.model = this.init;
           // await this.$store.dispatch("addressbook/getAll");   
        },  
        mounted() {
            this.getCompanyList();
            this.activity.loading = false;
            if (this.model.id === 0)
                this.model.environmentID = this.$vm.$appSession.environment.id;
        }
    }
</script>
