<template>
    <form-object :id="id" :autoFit="autoFit" :label="label" :floatLabel="floatLabel && (!disabled || objBlank)" :hideLabel="hideLabel" :v$="v$" :required="required" :conditional="conditional">
        <template #default="slotProps">
            <pv-calendar v-if="!disabled"
                         :ref="id"
                         :id="id"
                         :name="id"
                         :class="[{'p-fluid': autoFit}, {'p-invalid': slotProps.data}]"
                         :touchUI="touchDevice || isMobile"
                         :showIcon="showIcon && !disabled"
                         :icon="objType === 'time' ? 'pi pi-fw pi-clock' : 'pi pi-fw pi-calendar'"
                         :style="[{ 'border-radius': 0 }, { 'border-right': (postLabel || postIcon) ? ((postLabel || postIcon) ? '0' : '1px solid inherit') : false }, { 'border-top-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-bottom-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-top-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'border-bottom-right-radius': ((postLabel || postIcon) ? '0' : '4px')}]"
                         :showButtonBar="showButton"
                         :dateFormat="'dd/M/yy'"
                         :hourFormat="`${timeFormat}`"
                         :minDate="min"
                         :maxDate="max"
                         :monthNavigator="true"
                         :selectOtherMonths="true"
                         :yearNavigator="true"
                         :yearRange="yearRange"
                         :showTime="showTime"
                         :timeOnly="timeOnly"
                         :selectionMode="mode"
                         :maxDateCount="maxCount"
                         :manualInput="allowInput"
                         :showSeconds="showSeconds"
                         :stepMinute="stepMinute"
                         :hideOnDateTimeSelect="true"
                         :placeholder="phText"
                         :disabled="disabled"
                         :disabledDates="disabledDates"
                         @date-select="selected"
                         @today-click="selected"
                         @clear-click="selected"
                         @hide="selected"
                         v-model="model.value" />
            <div v-else :title="`${(model?.value ? model.value : '')}`" class="p-component p-inputtext p-input-icon-right" :style="[{ 'border-radius': 0 }, { 'border-right': (postLabel || postIcon) ? ((postLabel || postIcon) ? '0' : '1px solid inherit') : false }, { 'border-top-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-bottom-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-top-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'border-bottom-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'overflow': 'hidden' }, { 'text-overflow': 'ellipsis' }, { 'background': 'var(--surface-c)' }, { 'cursor': 'not-allowed' }, { 'white-space': 'nowrap' }]">
                <span class="p-code-text" style="font-size: 0.85rem; font-weight: 300; white-space: nowrap; text-wrap: normal; overflow: hidden; text-overflow: ellipsis;" v-html="`${model?.text ?? '&nbsp;'}`"></span>
                <i v-if="showCopy" class="pi pi-copy" style="cursor: pointer;" @click="onCopy"></i>
            </div>
        </template>
        <template #leftSide="slotProps">
            <slot name="prepend" data="slotProps">
                <span v-if="preIcon || preLabel" :class="[{'is-prepend': true}, {'p-inputgroup-addon': true}, {'p-invalid': slotProps.data}]" :style="[{'background-color': 'var(--surface-f)'}, {'font-weight': 500 }] ">
                    <i v-if="preIcon" :class="preIcon"></i>
                    <span v-if="preLabel" :class="[{'is-label': true}, {'required': model.state.required}, {'optional': model.state.optional}]" :style="preCSS" :title="preLabel ?? label ?? $filters.titleize(id)" v-html="preLabel"></span>
                </span>
            </slot>
        </template>
        <template #rightSide>
            <slot name="append">
                <span v-if="postIcon || postLabel" class="is-append p-inputgroup-addon" :style="[{'background-color': 'var(--surface-f)'}, {'font-weight': 500 }]">
                    <i v-if="postIcon" :class="postIcon"></i>
                    <span v-if="postLabel" class="is-label" :style="postCSS" :title="postLabel" v-html="postLabel"></span>
                </span>
            </slot>
        </template>
    </form-object>
</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/core-mixins.js';
    export default {
        name: "FormDateTimeField",
        mixins: [mixins.COMPONENT],
        props: {
            type: { type: String, default: "datetime", validator: (value) => ['date', 'datetime', 'time'].indexOf(value.toLowerCase()) !== -1 },
            value: { type: [String, Date, Array, Object] },
            min: { type: Date, default: new Date("2018-01-01") },
            max: { type: Date, default: new Date((new Date().getFullYear() + (new Date().getMonth() === 11 ? 1 : 0)) + "-12-31T23:59:59") },
            showIcon: { type: Boolean, default: true },
            showButton: { type: Boolean, default: true },
            showCopy: { type: Boolean, default: true },
            allowInput: { type: Boolean, default: false },
            maxCount: { type: Number, default: 1 },
            mode: { type: String, default: "single", validator: (value) => ['single', 'multiple', 'range'].indexOf(value.toLowerCase()) !== -1 },
            stepMinute: { type: Number, default: 15 },
            showMonths: { type: Number, default: 1, validator: (value) => Number(value) > 0 && Number(value) <= 12 },
            showSeconds: { type: Boolean, default: false },
            timeFormat: { type: Number, default: 12, validator: (value) => Number(value) === 12 || Number(value) === 24 },
            disabledDates: {type: Array, default: null}
        },
        computed: {
            yearRange() { return this.min.getFullYear() + ':' + this.max.getFullYear(); },
            showTime() { return this.objType === "time" || this.objType === "datetime" },
            timeOnly() { return this.objType === "time" },
            dateValue() { return new Date(this.model.value); }
        },
        watch: {
            "model.temp": {
                handler() {
                    this.model.value = this.$filters.formatDate(this.model.temp);
                },
                deep: true
            },
            "model.value": {
                handler() {
                    let dateValue = typeof (this.model.value) === 'string' ? new Date(this.model.value) : this.model.value;
                    let data = { value: dateValue, text: null, record: this.model.record };
                    if (this.objType === "time" && typeof (this.model.value) === 'object' && data.value && data.value.hours) {
                        var date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), data.value.hours, data.value.minutes, this.showSeconds ? data.value.seconds : 0);
                        this.model.text = this.$filters.toTimeDisplay(date);
                        var hrs = date.getHours();
                        var mins = date.getMinutes();
                        var secs = this.showSeconds ? date.getSeconds() : 0;
                        this.model.record = {
                            hours: hrs,
                            minutes: mins,
                            seconds: secs
                        }
                    }
                    this.onSync();
                },
                deep: true
            }
        },
        methods: {
            selected() {
                this.onSync();
            },
            onSync() {
                var date = new Date();
                let data = { value: this.model.value, text: null, record: this.model.record };
                if (this.objType === "time" && typeof (data.value) === 'object' && data.value && data.value.hours) {
                    date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), data.value.hours, data.value.minutes, this.showSeconds ? data.value.seconds : 0);
                    data = {
                        value: date,
                        text: this.$filters.toTimeDisplay(date),
                        record: data.record
                    }
                    this.model.value = data.value;
                    this.model.text = data.text;
                    this.model.record = data.record;
                    this.$emit("is-sync", data);
                }
                else if (data.value && data.value != null) {
                    date = new Date(data.value);
                    var hrs = date.getHours();
                    var mins = date.getMinutes();
                    var secs = this.showSeconds ? date.getSeconds() : 0;
                    date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hrs, mins, secs);
                    switch (this.objType) {
                        case "time":
                            data.text = this.$filters.toTimeDisplay(date);
                            break;
                        case "date":
                            data.text = this.$filters.toDateDisplay(date);
                            break;
                        default:
                            data.text = this.$filters.toDateTimeDisplay(date)
                            break;

                    }
                    data.record = {
                        data: this.$filters.formatDate(date),
                        timeSpan: {
                            hours: hrs,
                            minutes: mins,
                            seconds: secs
                        }
                    }
                    this.model.value = typeof (data.value) === 'string' ? new Date(data.value) : data.value;
                    this.model.text = data.text;
                    this.model.record = data.record;
                    this.$emit("is-sync", data);
                }
                else {
                    data.record = {
                        data: null,
                        timeSpan: {
                            hours: 0,
                            minutes: 0,
                            seconds: 0
                        }
                    }
                    this.model.value = null;
                    this.model.text = null;
                    this.model.record = null;
                    this.$emit("is-sync", data);
                }
            }
        },
        async created() {
            this.model.value = null;
            if (this.value)
                this.model.value = new Date(this.value);
            this.onSync();
        }
    }
</script>