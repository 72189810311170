<template>
    <pv-card>
        <template #subtitle>
            Posting locations are agreed collection points by the service provider and the company.<br />
            Please be aware that consignments that are collected outside this list are considered third-party collections that may incur additional cost.<br />
            Adding locations outside the actual agreed collection may cause issue with billing.<br />
            This step is <b>optional</b> and can be assigned later.
        </template>
        <template #content>
            <postinglocation-form :serviceProviderID="$attrs.formData.serviceProviderID" :formData="$attrs.formData" @is-sync="onSync($event)" />
        </template>
        <template #footer>
            <div class="p-grid p-nogutter p-justify-between">
                <pv-button label="Back" @click="prevPage()" icon="pi pi-angle-left" />
                <pv-button label="Next" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
            </div>
        </template>
    </pv-card>
</template>
<script>
    import postinglocationfrom from '@/components/admin/provideraccount/postinglocation-form.vue';
    import { useVuelidate } from '@vuelidate/core';
    export default {
        name: 'StepPostingLocation',
        emits: ["next-page"],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'postinglocation-form': postinglocationfrom
        },
        props: {
            id: { type: String },
            integrationMask: { type: String }
        },
        data() { return { model: [] } },
        methods: {
            onSync(event) { this.model = event.model; },
            nextPage() { this.$emit('next-page', { formData: { locations: this.model }, pageIndex: 2 }); },
            prevPage() { this.$emit('prev-page', { formData: { locations: this.model }, pageIndex: 2 }); },
        }
    }
</script>
