<template>
    <pv-grid :id="id"
             :ref="id"
             :value="records.data"
             :totalRecords="records.total"
             :alwaysShowPaginator="true"
             :paginator="true"
             :paginatorPosition="'bottom'"
             :pageLinkSize="5"
             :rowsPerPageOptions="[5, 10, 25, 50, 100]"
             :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport'"
             :currentPageReportTemplate="'Showing {first} - {last} of {totalRecords} records'"
             :first="page.first"
             :rows="page.size"
             :lazy="true"
             :loading="activity.loading"
             :editMode="'row'"
             :dataKey="'id'"
             :scrollWidth="'flex'"
             :scrolHeight="'flex'"
             :responsive="'scroll'"
             :columnResizeMode="'expand'"
             :stateStorage="'session'"
             :stateKey="`dt-state-${id}`"
             autoLayout
             showGridlines
             stripedRows
             v-model:editingRows="editingRecords"
             @row-edit-init="onRowEdit"
             @row-edit-save="onRowSave"
             @row-edit-cancel="onRefresh"
             @page="onChangePage">
        <!-- Group Header Template -->
        <pv-grid-column-group type="header">
            <pv-grid-row>
                <pv-grid-column v-if="!copyFrom && this.editingRecords.length > 0" :colspan="2" headerStyle="min-width: 7rem; max-width: 7rem; width: 7rem;" style="border-left: 1px solid var(--surface-d); padding: 0 5px;" :frozen="true" />
                <pv-grid-column v-if="categoryType > 200" header="Particulars" :rowspan="2" headerStyle="min-width: 15rem; max-width: 15rem; " :frozen="true" />
                <pv-grid-column header="WEIGHT BREAK" :colspan="categoryType === 200 ? 3 : 2" :frozen="true" />
                <pv-grid-column header="SHIPPING RATES" :rowspan="2" />
                <!--<pv-grid-column header="MINIMUM CHARGE PER" :colspan="categoryType === 200 ? 3 : 2" :frozen="true" />-->
                <pv-grid-column :colspan="categoryType === 300 ? 3 : 2" field="rate_01" header="COMPUTATION SETTINGS" />
                <pv-grid-column v-if="!copyFrom" header="" :rowspan="2" :colspan="2" headerStyle="min-width: 7rem; max-width: 7rem; width: 7rem;" style="border-left: 1px solid var(--surface-d); padding: 0 5px; " :frozen="true" />
            </pv-grid-row>
            <pv-grid-row>
                <pv-grid-column v-if="!copyFrom && this.editingRecords.length > 0" field="mask" headerStyle="width: 3rem" />
                <pv-grid-column v-if="!copyFrom && this.editingRecords.length > 0" field="mask" headerStyle="width: 3rem" />
                <pv-grid-column v-if="this.categoryType === 200" field="incrementalWeight" header="Incremental" headerStyle="width: 7rem" />
                <pv-grid-column field="minChargePerShipment" header="Consignment" headerStyle="width: 7rem" />
                <pv-grid-column field="minChargePerPiece" header="Piece" headerStyle="width: 7rem" />
                <pv-grid-column v-if="categoryType === 300" field="chargeType" header="Computation" headerStyle="width: 7rem" />
                <pv-grid-column field="parcelNumberType" header="Packages" headerStyle="width: 7rem" />
                <pv-grid-column field="packagingType" header="Packaging" headerStyle="width: 7rem;" />
            </pv-grid-row>
        </pv-grid-column-group>
        <pv-grid-column v-if="!copyFrom && this.editingRecords.length > 0" :rowEditor="true" class="p-operator-save" headerStyle="max-width: 7rem; min-width:3rem" style="max-width: 7rem !important; min-width:3rem !important; width: 3rem; text-align: right; border-right: 0;" :frozen="true" />
        <!-- Operation Column -->
        <pv-grid-column v-if="!copyFrom && this.editingRecords.length > 0" class="p-operator-cancel" :rowEditor="true" headerStyle="min-width: 3rem; max-width: 3rem; width: 3rem" style="width: 3rem; border-left: 0; text-align: center; object-position: center; padding: 0 5px;" :frozen="true">
            <template #body="slotProps">
                <pv-button :disabled="slotProps.data.recordStatus !== 0 || slotProps.data.id < 1000 || (slotProps.data?.viewOnly ?? false)" icon="pi pi-trash" iconPos="right" class="p-button-danger" @click="onDelete(`${slotProps.data.id}`)" />
            </template>
        </pv-grid-column>
        <pv-grid-column v-if="categoryType === 300" field="name" header="Particulars" :frozen="true">
            <template #body="slotProps">
                {{ slotProps.data.name }}
            </template>
            <template #editor="{ data }">
                <div class="p-editor" style="display: block;">
                    <pv-input v-model="data['name']" mode="decimal" :min="0" :max="99995" :minFractionDigits="3" :maxFractionDigits="3" :showButtons="true" :format="true" :allowEmpty="false" autofocus style="min-width: 0 !important; " onfocus="this.select()" />
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="minWeight" header="Min Weight" headerStyle="width: 7rem;" style="width: 7rem; text-align: right;" :frozen="true">
            <template #body="slotProps">
                {{ $filters.formatNumber(slotProps.data.minWeight, weightUnitDecimal, globalization.country) }}{{ weightUnitText }}
            </template>
            <template #editor="{ data }">
                <div class="p-editor" style="display: block;">
                    <pv-numeric v-model="data['minWeight']" mode="decimal" :min="0" :max="weightUnitMax" :minFractionDigits="weightUnitDecimal" :maxFractionDigits="weightUnitDecimal" :showButtons="true" :format="true" :allowEmpty="false" autofocus style="min-width: 0 !important; " onfocus="this.select()" />
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="maxWeight" header="Max Weight" headerStyle="width: 7rem;" style="width: 7rem; text-align: right;" :frozen="true">
            <template #body="slotProps">
                {{ $filters.formatNumber(slotProps.data.maxWeight, weightUnitDecimal, globalization.country) }}{{ weightUnitText }}
            </template>
            <template #editor="{ data }">
                <div class="p-editor" style="display: block;">
                    <pv-numeric v-model="data['maxWeight']" mode="decimal" :min="data['minWeight'] ?? 0" :max="weightUnitMax" :minFractionDigits="weightUnitDecimal" :maxFractionDigits="weightUnitDecimal" :showButtons="true" :format="true" :allowEmpty="false" autofocus style="min-width: 0 !important; " onfocus="this.select()" />
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column v-if="this.categoryType === 200" field="incrementalWeight" header="Incremental" headerStyle="width: 7rem;" style="text-align: right;" :frozen="true">
            <template #body="slotProps">
                {{ $filters.formatNumber(slotProps.data.incrementalWeight, weightUnitDecimal, globalization.country) }}{{ weightUnitText }}
            </template>
            <template #editor="{ data }">
                <div class="p-editor" style="display: block;">
                    <pv-numeric v-model="data['incrementalWeight']" mode="decimal" :min="0" :max="weightUnitMax" :minFractionDigits="weightUnitDecimal" :maxFractionDigits="weightUnitDecimal" :showButtons="true" :format="true" :allowEmpty="false" autofocus style="min-width: 0 !important; " onfocus="this.select()" />
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="rate_01" header="Shipping Rate" style="text-align: right;">
            <template #body="slotProps">
                {{ $filters.formatCurrency(slotProps.data['rate_01'], globalization.currency, globalization.country) }}
            </template>
            <template #editor="{ data }">
                <div class="p-editor-full" style="display: block;">
                    <pv-numeric v-model="data['rate_01']" mode="decimal" :min="0" :minFractionDigits="2" :maxFractionDigits="2" :showButtons="true" :format="true" :allowEmpty="false" autofocus style="width: 100%;" onfocus="this.select()" />
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column v-if="categoryType === 300" field="chargeType">
            <template #body="slotProps">
                {{ sources.chargeTypes.filter(x => x.id === slotProps.data.chargeType)[0].text }}
            </template>
            <template #editor="{ data }">
                <pv-dropdown :options="sources.chargeTypes" v-model="data['chargeType']" optionLabel="text" optionValue="id" />
            </template>
        </pv-grid-column>
        <pv-grid-column field="parcelNumberType">
            <template #body="slotProps">
                {{ sources.parcelNumberTypes.filter(x => x.id === slotProps.data.parcelNumberType)[0].text }}
            </template>
            <template #editor="{ data }">
                <pv-dropdown :options="sources.parcelNumberTypes" v-model="data['parcelNumberType']" optionLabel="text" optionValue="id" />
            </template>
        </pv-grid-column>
        <pv-grid-column field="packagingType" headerStyle="12.5rem" style="width: 12.5rem; text-align: center;">
            <template #body="slotProps">
                <i title="Envelope" class="fas fa-envelope p-mr-2" :style="[{ 'font-size': '1.25rem' }, { 'color': `var(--${((slotProps.data.packagingType & 1) === 1 ? `text-primary` : `gray-standard`)}` }] "></i>
                <i title="Packet" class="fas fa-sheet-plastic p-mr-2" :style="[{ 'font-size': '1.25rem' }, { 'color': `var(--${((slotProps.data.packagingType & 2) === 2 ? `text-primary` : `gray-standard`)}` }] "></i>
                <i title="Package" class="fa fa-box p-mr-2" :style="[{ 'font-size': '1.25rem' }, { 'color': `var(--${((slotProps.data.packagingType & 4) === 4 ? `text-primary` : `gray-standard`)}` }] "></i>
                <i title="Freight" class="fa fa-cart-flatbed-boxes p-mr-2" :style="[{ 'font-size': '1.25rem' }, { 'color': `var(--${((slotProps.data.packagingType & 8) === 8 ? `text-primary` : `gray-standard`)}` }] "></i>
                <i title="Pallet" class="fa-solid fa-pallet-box p-mr-2" :style="[{ 'font-size': '1.25rem' }, { 'color': `var(--${((slotProps.data.packagingType & 16) === 16 ? `text-primary` : `gray-standard`)}` }] "></i>
                <i title="Container" class="fa-solid fa-container-storage" :style="[{ 'font-size': '1.25rem' }, { 'color': `var(--${((slotProps.data.packagingType & 32) === 32 ? `text-primary` : `gray-standard`)}` }] "></i>
            </template>
            <template #editor="{ data }">
                <pv-multiselect :options="sources.packagingTypes" optionLabel="text" optionValue="id" style="width: 12.5rem"
                                :modelValue="sources.packagingTypes.filter(x => (Number(data['packagingType']) & x.id) === x.id).map(x => x.id)"
                                @change="data['packagingType'] = onAssign($event.value)" />
            </template>
        </pv-grid-column>
        <!-- Inline Edit Command -->
        <pv-grid-column v-if="!copyFrom" :rowEditor="true" class="p-operator-save" headerStyle="max-width: 7rem; min-width:3rem" style="max-width: 7rem !important; min-width:3rem !important; width: 3rem; text-align: right; border-right: 0;" :frozen="true" />
        <!-- Operation Column -->
        <pv-grid-column v-if="!copyFrom" class="p-operator-cancel" :rowEditor="true" :headerStyle="`min-width: 3rem; max-width: 3rem; width: 3rem`" style="width: 3rem; border-left: 0; text-align: center; object-position: center; padding: 0 5px; " :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <pv-button :disabled="slotProps.data.recordStatus !== 0 || slotProps.data.id < 0 || (slotProps.data?.viewOnly ?? false)" icon="pi pi-trash" iconPos="right" class="p-button-danger" @click="onDelete(`${slotProps.data.id}`)" />
            </template>
        </pv-grid-column>
        <!-- Table Header Template -->
        <template #header>
            <slot name="toolbar">
                <div class="p-d-flex p-jc-between p-ai-center">
                    <span>
                        <pv-button v-if="!copyFrom" icon="pi pi-fw pi-plus" :label="`Add New ${buttonName} Charge`" style="margin-right: 5px;" @click="onCreate"></pv-button>
                        <span v-else class="is-text-danger">The rate table is linked and not editable</span>
                    </span>
                    <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                        <i class="pi pi-search" />
                        <pv-input placeholder="Keyword Search" style="width: 100%;" @keyup="rei" />
                    </span>
                </div>
            </slot>
        </template>
        <!-- Empty Record Template -->
        <template #empty>
            No records found.
        </template>
        <!-- Loading Template -->
        <template #loading>
            Retrieving records from database. Please wait...
        </template>
        <!-- Pagination -->
        <template #paginatorLeft>
            <pv-button type="button" icon="pi pi-download" class="p-button-text" @click="onDownload" />
        </template>
        <template #paginatorRight>
            <pv-button type="button" icon="pi pi-refresh" class="p-button-text" @click="onRefresh" />
        </template>
    </pv-grid>
    <!-- Dialogs -->
    <keep-alive>
        <is-dialog header="Delete Record?" :visible="dialog.delete" @is-confirm="onConfirmDelete(model.mask)" @is-cancel="onCancel()">
            <div class="confirmation-content p-d-flex">
                <div><i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" /></div>
                <div>
                    You are about to delete this record:
                    <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                        <tr>
                            <th style="text-align:left;">Weight Break</th>
                            <td>:</td>
                            <td> {{ $filters.formatNumber(model?.minWeight, 3, globalization.country) }} - {{ $filters.formatNumber(model?.maxWeight, 3, globalization.country) }}</td>
                        </tr>
                    </table>
                    Please be aware of the following:
                    <ul>
                        <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                        <li>For records that has activity, the record will only be disabled and archived.</li>
                        <li>Also deleting a record may be irreversible action.</li>
                    </ul>
                    Are you sure you still want to proceed?
                </div>
            </div>
        </is-dialog>
    </keep-alive>
    <keep-alive>
        <is-dialog :header="(model?.id ?? 0) === 0 ? 'Add New Record' : 'Edit Record'" :style="{width: '70vw'}" :visible="dialog.edit" @is-confirm="onConfirmEdit(model)" @is-cancel="onCancel()" :confirmDisabled="confirmDisabled">
            <div class="confirmation-content p-grid">
                <div class="p-col p-md-6">
                    <h6>Weight Break</h6>
                    <form-input v-if="model?.categoryType == 300" id="particulars" :value="model.name" @is-sync="model.name = $event.value" :required="true" v-focus />
                    <div class="p-grid p-nogutter">
                        <div class="p-col">
                            <form-numeric id="minWeight" type="decimal" :decimalDigit="3" :max="99995.000" :value="model.minWeight" @is-sync="model.minWeight = $event.value" :required="true" />
                        </div>
                        <div class="p-col">
                            <form-numeric id="maxWeight" type="decimal" :decimalDigit="3" :min="model.minWeight" :max="99995.000" :value="model.maxWeight" @is-sync="model.maxWeight = $event.value" :required="true" />
                        </div>
                    </div>
                    <form-numeric v-if="model?.categoryType === 200" id="incrementalWeight" type="decimal" :decimalDigit="3" :max="99995.000" :required="true" :value="model.incrementalWeight" @is-sync="model.incrementalWeight = $event.value" />
                    <h6>Computation Settings</h6>
                    <div class="p-grid p-nogutter">
                        <div class="p-col">
                            <form-lookup id="tradeType" type="flag" source="tradetype" label="Trade Types" :required="true" :allowedFlags="7" :value="model.tradeType ?? 3" @is-sync="model.tradeType = $event.value" />
                            <form-lookup v-if="categoryType === 300" id="chargeType" type="enum" source="ratechargingtype" label="Rate Charge Per" :required="true" :value="model.chargeType ?? 100" @is-sync="model.chargeType = $event.value" />
                            <form-lookup id="parcelNumberType" :source="sources.parcelNumberTypes" sourceType="array" label="Piece Type" :required="true" :value="model.parcelNumberType ?? 3" @is-sync="model.parcelNumberType = $event.value" />
                            <form-lookup v-if="model?.categoryType === 300" id="rateType" type="enum" source="ratetype" label="Rate Type" :required="true" :value="model.rateType ?? 100" @is-sync="model.rateType = $event.value" />
                        </div>
                        <div class="p-col">
                            <form-lookup id="packagingType" type="flag" source="serviceproviderproductpackagingtype" :required="true" label="Packaging Type" :value="model.packagingType ?? 3" @is-sync="model.packagingType = $event.value" />
                        </div>
                    </div>
                </div>
                <div class="p-col p-md-6">
                    <!--<h6>Minimum Revenue</h6>
                    <div class="p-grid p-nogutter">
                        <div class="p-col">
                            <form-numeric id="minChargePerPiece" type="currency" label="Per Piece" :currency="globalization.currency" :max="9999999999.99" :value="model.minChargePerPiece" @is-sync="model.minChargePerPiece = $event.value" />
                        </div>
                        <div class="p-col">
                            <form-numeric id="minChargePerShipment" type="currency" label="Per Consignment" :currency="globalization.currency" :max="9999999999.99" :value="model.minChargePerShipment" @is-sync="model.minChargePerShipment = $event.value" />
                        </div>
                    </div>-->
                    <h6>Standard Rates</h6>
                    <div class="p-grid p-nogutter">
                        <div class="p-col" style="padding: 0.5rem 1rem;">
                            <b>Base Rate</b>
                        </div>
                        <div class="p-col">
                            <form-numeric id="rate_01" type="currency" :hideLabel="true" :currency="globalization.currency" :max="9999999999.99" :value="model.rate_01" @is-sync="model.rate_01 = $event.value" style="margin-top: -1rem;" />
                        </div>
                    </div>
                </div>
            </div>
        </is-dialog>
    </keep-alive>
</template>
<script>
    export default {
        name: "BasicRateTemplate",
        props: {
            parentID: { type: Number, default: null },
            providerID: { type: Number, default: null },
            effectiveDateID: { type: Number, default: null },
            tradeType: { type: Number, default: null },
            categoryType: { type: Number, default: null },
            weightUnit: { type: Number, default: 100 },
            zoneID: { type: Number, default: null },
            countryCode: { type: String },
            currencyCode: { type: String },
            copyFrom: { type: Number, default: null }
        },
        data() {
            return {
                editingRecords: [],
                records: { data: [], total: 0 },
                source: [],
                model: null,
                activity: { loading: true },
                dialog: { delete: false, edit: false },
                page: {
                    current: 0,
                    first: 0,
                    size: 10
                },
                sources: {
                    packagingTypes: [{ id: 1, text: 'Envelope' }
                        , { id: 2, text: 'Packet' }
                        , { id: 4, text: 'Package' }
                        , { id: 8, text: 'Freight' }
                        , { id: 16, text: 'Pallet' }
                        , { id: 32, text: 'Container' }],
                    chargeTypes: [{ id: 100, text: 'Consignment' }, { id: 200, text: 'Package' }],
                    parcelNumberTypes: [{ id: 3, text: 'General' }, { id: 1, text: 'Single' }, { id: 2, text: 'Multiple' }]
                }
            }
        },
        computed: {
            id() { return `BasicRateTemplate_${this.parentID}_${this.tradeType}${this.categoryType}`; },
            globalization() { return { country: this.countryCode, currency: this.currencyCode } },
            weightUnitText() { return this.$vm.$enums.weightunit.filter(x => x.id === this.weightUnit)[0].text; },
            weightUnitDecimal() { return (this.weightUnit === 200 ? 0: 3); },
            weightUnitMax() { return 99995 * (this.weightUnit === 200 ? 100 : 1); },
            buttonName() { return this.categoryType === 100 ? 'Base' : (this.categoryType === 200 ? 'Excess' : (this.categoryType === 300 ? 'Other/Premium' : 'Minimum Revenue')); },
            confirmDisabled() {
                if (this.model?.minWeight === 0 && this.model?.maxWeight === 0) return true;
                if (!this.model?.rate_01 > 0.00) return true;
                if (!this.model?.tradeType > 0) return true;
                if (!this.model?.parcelNumberType > 0) return true;
                if (!this.model?.packagingType > 0) return true;
                if (this.model?.categoryType == 300 && this.model.name?.trim() === '') return true;
                return false;
            },
            dataSource() {
                return this.records;
            }
        },
        watch: {
            zoneID: {
                handler() {
                    if (this.zoneID)
                        this.getZone()
                },
                deep: true
            }
        },
        methods: {
            // Create Record
            onCreate() {
                this.model = {
                    rateContractProductID: this.parentID,
                    rateContractEffectiveID: this.effectiveDateID,
                    name: this.categoryType == 100 ? "Base Charge" : (this.categoryType == 200 ? "Excess Charge" : null),
                    tradeType: this.tradeType,
                    countryID: null,
                    postalCodeFROM: null,
                    postalCodeTO: null,
                    state: null,
                    city: null,
                    minWeight: 0,
                    maxWeight: 0,
                    incrementalWeight: 0,
                    rateType: 100,
                    categoryType: this.categoryType,
                    chargeType: 100,
                    packagingType: 7,
                    parcelNumberType: 3,
                    rate_01: null,
                    rate_02: null,
                    rate_03: null,
                    rate_04: null,
                    rate_05: null,
                    rate_06: null,
                    rate_07: null,
                    rate_08: null,
                    rate_09: null,
                    rate_10: null,
                    rate_11: null,
                    rate_12: null,
                    rate_13: null,
                    rate_14: null,
                    rate_15: null,
                    rate_16: null,
                    rate_17: null,
                    rate_18: null,
                    rate_19: null,
                    rate_20: null,
                    rate_21: null,
                    rate_22: null,
                    rate_23: null,
                    rate_24: null,
                    rate_25: null,
                    rate_26: null,
                    rate_27: null,
                    rate_28: null,
                    rate_29: null,
                    rate_30: null,
                    rate_31: null,
                    rate_32: null,
                    rate_33: null,
                    rate_34: null,
                    rate_35: null,
                    rate_36: null,
                    rate_37: null,
                    rate_38: null,
                    rate_39: null,
                    rate_40: null,
                    minChargePerShipment: 0.00,
                    minChargePerPiece: 0.00
                }
                this.dialog.edit = true;
                this.onRowEdit();
            },
            onConfirmEdit(e) {
                let index = this.dataSource.data.findIndex(x => x.id === e.id);
                let model = this.$filters.init(e);
                if (index >= 0) {
                    this.$store.dispatch("providercontractproductrate/updateRecord", model).then((response) => {
                        if (response.success) {
                            this.$toast.add({ severity: 'success', summary: 'Product Rate', detail: "The record was updated successfully!", life: 3000 });
                            this.dialog.edit = false;
                            this.sourceHolder[index] = this.$filters.init(response.result);
                            this.records[index] = this.$filters.init(response.result);
                            this.onCancel();
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    })
                        .catch(ex => {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        });
                }
                else {
                    this.$store.dispatch("providercontractproductrate/addRecord", model).then((response) => {
                        if (response.success) {
                            this.records.data.unshift(this.$filters.init(response.result));
                            this.records.total = this.records.data.length;
                            this.$toast.add({ severity: 'success', summary: 'Product Rate', detail: "The record was added successfully!", life: 3000 });
                            this.onCancel();
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    })
                        .catch(ex => {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        });
                }
            },
            onDelete(e) {
                this.model = this.$filters.init(this.records.data.filter(x => Number(x.id) === Number(e))[0]);
                this.dialog.delete = true;
            },
            onConfirmDelete(e) {
                this.$store.dispatch("providercontractproductrate/deleteRecordByMask", e)
                    .then((response) => {
                        if (response.success) {
                            this.records = {
                                data: this.records.data.filter(x => x.mask !== e),
                                total: this.records.data.filter(x => x.mask !== e).length
                            }
                            this.$toast.add({ severity: 'success', summary: 'Product Rate', detail: "The record was deleted successfully!", life: 3000 });
                            this.onCancel();
                        }
                        else if ((response.errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: response.errors[0]?.message, life: 3000 });
                        }
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
            onCancel() {
                this.model = null;
                this.dialog.edit = false;
                this.dialog.delete = false;
            },
            onRefresh() {
                this.activity.loading = true;
                this.records = { data: [], total: 0 };
                this.getSource().then((response) => {
                    this.records = response;
                    this.$nextTick(() => { this.activity.loading = false; });
                });
            },
            onChangePage(e) {
                this.page.current = e.page;
                this.page.first = e.first;
                this.page.size = e.rows;
                this.onRefresh();
            },
            // Row Editting Methods
            onEdit(e) { this.editingRows.push(e); },
            onRowEdit() {
                setTimeout(() => {
                    const kbButtons = document.getElementsByTagName("button");
                    kbButtons.forEach(elem => {
                        elem.setAttribute('tabindex', '-1')
                    });
                }, 100);
            },
            onRowSave(e) {
                let index = e.index;
                let model = e.data;
                this.$store.dispatch("providercontractproductrate/updateRecord", model).then((response) => {
                    if (response.success) {
                        this.$toast.add({ severity: 'success', summary: `${this.buttonName} Rate Updated`, detail: "The record was updated successfully!", life: 3000 });
                        this.source[index] = this.$filters.init(response.result);
                        this.records.data[index] = this.$filters.init(response.result);
                    }
                    else if ((response.errors ?? []).length > 0) {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: response.errors[0]?.message, life: 3000 });
                    }
                })
                .catch(ex => {
                    this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                });
            },
            onAssign(value) {
                let returnValue = 0;
                for (let i = 0; i < value.length; i++)
                    returnValue += value[i];
                return returnValue;
            },
            async getSource() {
                this.activity.loading = true;
                let dataSource = { total: 0, data: [] };
                await this.$store.dispatch("providercontractproductrate/getURL", { url: `/services/facility/providercontractproductrate/bycontractproduct/id/${this.copyFrom ?? this.parentID}` })
                    .then((response) => {
                        dataSource = { data: response.result, total: response.result.length };
                        if ((this.copySource?.data ?? []).length > 0)
                            dataSource = { total: this.copySource.total, data: [...(this.copySource?.data ?? []).filter(x => (x.tradeType === this.tradeType || (x.tradeType & this.tradeType) === this.tradeType) && x.categoryType === this.categoryType)]?.slice(this.page.first) };
                        else if ((this.sourceHolder ?? []).length > 0)
                            dataSource = { total: this.sourceHolder.length, data: [...(this.sourceHolder ?? []).filter(x => (x.tradeType === this.tradeType || (x.tradeType & this.tradeType) === this.tradeType) && x.categoryType === this.categoryType)]?.slice(this.page.first) };
                        else
                            dataSource = { total: (dataSource.data ?? []).filter(x => (x.tradeType === this.tradeType || (x.tradeType & this.tradeType) === this.tradeType) && x.categoryType === this.categoryType).length, data: [...(dataSource.data ?? []).filter(x => (x.tradeType === this.tradeType || (x.tradeType & this.tradeType) === this.tradeType) && x.categoryType === this.categoryType)]?.slice(this.page.first) };
                        this.source = response.result.filter(x => (x.tradeType === this.tradeType || (x.tradeType & this.tradeType) === this.tradeType) && x.categoryType === this.categoryType);
                        dataSource = { total: (this.source ?? []).length, data: [...(this.source ?? [])]?.slice(this.page.first) };
                        this.activity.loading = false;
                    })
                    .catch(() => {
                        this.source = [];
                        dataSource = { total: 0, data: [] }
                        this.activity.loading = false;
                    });
                return dataSource;
            }
        },
        created() {
            this.getSource().then((response) => { this.records = response; })
        }
    }
</script>
<style>
    .p-row-editor-save.p-link { border-radius: 5px !important; background: var(--bg-success) !important; padding: 1.15rem !important; color: var(--surface-b) !important; }
    .p-row-editor-cancel.p-link { border-radius: 5px !important; background: var(--bg-danger) !important; color: var(--surface-b) !important; padding: 1.15rem !important; }
    .p-row-editor-init.p-link { border-radius: 5px !important; background: var(--bg-info) !important; color: var(--surface-b) !important; padding: 1.15rem !important; }
    .p-operator-cancel .p-row-editor-save.p-link { display: none !important; }
    .p-operator-save .p-row-editor-cancel.p-link { display: none !important; }
    .p-operator-save .p-row-editor-save.p-link { margin-right: 0 !important; }
    .p-editor-full .p-inputnumber-input { max-width: 100% !important; width: 100% !important; min-width: 7rem !important; text-align: right; }
    .p-editor .p-inputnumber-input { max-width: 7rem !important; width: 7rem !important; text-align: right; }
</style>