<template>
    <div class="layout-footer p-d-flex p-jc-between">
        <div class="p-d-flex">
            <div class="p-d-none p-d-md-inline-flex p-ripple is-logo" v-ripple><img :src="`/_assets/img/BE128A49D753EE0882F74F56000000DC_${theme}.png`" /></div>
            <div class="is-copyright"><i class="mdi mdi-copyright"></i> 2018 - {{ year }}, COSMOS by Integro360 Limited | <a href="#">v.{{version}}</a></div>
        </div>
        <div class="p-d-none p-d-md-inline-flex">
            <div class="is-button p-ripple" style="overflow-wrap;" v-ripple><span>Privacy &amp; Cookies Policy</span></div>
            <div class="is-time" :title="`${time.utc}+00:00`"></div>
            <div class="is-time" :title="`${time.local}${$vm.$location.utc_offset}`"></div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'CosmosFooterComponent',
        props: {
            theme: { type: String, default: "dark" }
        },
        data() {
            return {
                time: {
                    utc: null,
                    local: null
                },
                year: 2022,
                version: "1.0.0"
            }
        },
        methods: {
            UpdateTime() {
                try {
                    const span = document.getElementsByClassName("is-time");
                    this.time = {
                        utc: this.$filters.todayUTCTime(),
                        local: this.$filters.todayLocalTime()
                    };
                    span[0].innerHTML = "<i class='pi pi-clock is-text-info'></i>" + this.$filters.todayUTCTime();
                    span[1].innerHTML = "<i class='pi pi-clock is-text-secondary'></i>" + this.$filters.todayLocalTime();
                }
                catch { /* No Catch */ }
                setTimeout(() => { this.UpdateTime() }, 1000);
            },
        },
        created() {
            this.year = (new Date()).getFullYear();
            this.time = {
                utc: this.$filters.todayUTCTime(),
                local: this.$filters.todayLocalTime()
            };
            this.version = process.env.VUE_APP_VERSION;
        },
        mounted() {
            this.UpdateTime();
        }
    }
</script>