<template>
    <pv-card>
        <template v-slot:title>
            Confirmation
        </template>
        <template v-slot:subtitle>
            Confirm your consignment details for Customer <!--{{(scopeCustomerDS.filter((x) => x.id == model?.customerID)[0] ?? customer)?.text}}-->.
        </template>
        <template v-slot:content>
            <confirmation-form :formData="model"></confirmation-form>
        </template> 
        <template v-slot:footer>
            <div class="p-grid p-nogutter p-justify-between">
                <pv-button label="Back" @click="prevPage()" icon="pi pi-angle-left" />
                <pv-button :label="buttonAction.label" @click="complete()" :icon="buttonAction.icon" iconPos="right" :class="buttonAction.class" :loading="buttonAction.processing"  />
            </div>
        </template>
    </pv-card>
</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/consignment-mixins.js';
    import confirmationForm from '@/components/transaction/tms/consignment/confirmation-form.vue';
    import { useVuelidate } from '@vuelidate/core';
    export default {
        name: 'ConfirmationTemplate',
        mixins: [mixins.CONFIRMATIONFORM],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'confirmation-form': confirmationForm
        },
        data() {
            return {
                buttonAction: {
                    processing: false,
                    class: "p-button-success",
                    icon: "pi pi-check",
                    label: "Complete"
                }
            }
        },
        methods: {
            prevPage() {
                this.$emit('prev-page', { pageIndex: 4 });
            },
            complete() {
                if (!this.buttonAction.processing) {
                    this.buttonAction.processing = true;
                    this.buttonAction.class = "p-button-warning";
                    this.buttonAction.icon = "pi pi-spinner";
                    this.buttonAction.label = "Please Wait...";
                    this.$toast.add({ severity: 'info', summary: 'Please wait...', detail: "Consignment is currently processing do not refresh the page", life: 6000 });
                    this.$emit('complete');
                }
            },
            isProcessing() { return this.buttonAction.processing; },
        },
        /* beforeCreate() { this.model = this.$attrs.formData },*/
        watch: {
            'formData': {
                handler() {
                    this.$nextTick(() => {
                        if (this.model.manageProcess === true) {
                            this.buttonAction.processing = false;
                            this.buttonAction.class = "p-button-success";
                            this.buttonAction.icon = "pi pi-check";
                            this.buttonAction.label = "Complete";
                        }
                    });
                }, deep: true
            }
        },
        async created() {
        },
        async mounted() {
            let _self = this;
            this.$nextTick(() => {
                this.buttonAction.processing = false;
                this.model = _self.$props.formData;
            });
        },
        async updated() {
           
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>