<template>
    <div style="margin: -0.5rem -1.5rem -2.5rem -1.5rem;">
        <div style="min-height: 10rem; padding: 1rem;">
            <h3 style="margin-bottom: 0;">STEP 3: Basic Info</h3>
            <p>This step allows the customer to configure internal configuration and defaults.</p>
            <div class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-4">
                    <h4 style="margin-bottom: 2rem;">STORE INFO</h4>
                    <basicinfo-form v-model="model.basicInfo"
                                    v-model:modelInvalid="validation.basicInfo" />
                    <notification-form v-model="model.notification"
                                       v-model:modelInvalid="validation.notification" />
                </div>
                <div class="p-col-12 p-md-6 p-lg-8">
                    <defaults-form :customerID="model.customerID"
                                   v-model="model.defaults" 
                                   v-model:modelAccessScope="accessScope"
                                   v-model:modelInvalid="validation.defaults" />
                </div>
            </div>
        </div>
        <div class="p-grid p-nogutter p-justify-between" style="border-top: 2px solid var(--surface-d);padding: 0.5rem 1rem; background: var(--surface-c)">
            <pv-button @click="onPrevPage()" :loading="activity.loading" :iconPos="'right'"><i class="fa fa-chevron-left fa-fw" style="margin-right: 0.5rem"></i> <i :class="`${(activity.loading ? 'pi pi-spinner pi-spin' : 'fa fa-shop fa-fw')}`" style="margin-right: 0.5rem"></i> Shop Info</pv-button>
            <pv-button @click="onNextPage()" :loading="activity.loading" :iconPos="'right'" :disabled="disableNext"><i :class="`${(activity.loading ? 'pi pi-spinner pi-spin' : 'fa fa-list-check fa-fw')}`" style="margin-right: 0.5rem"></i> Summary <i class="fa fa-chevron-right fa-fw" style="margin-left: 0.5rem"></i></pv-button>
        </div>
    </div>
</template>
<script>
    import basicInfo from '@/components/facility/ecommerce/basicinfo-form.vue';
    import defaultForm from '@/components/facility/ecommerce/defaults-form.vue';
    import notificationForm from '@/components/facility/ecommerce/notification-form.vue';
    export default {
        name: "BasicInfoStep",
        emits: ["update", "update:modelValue", "isSync", "nextPage"],
        props: {
            modelValue: { type: Object, default: null },
            modelPlatform: { type: Object, default: null },
            modelAccessScope: { type: Object, default: null },
        },
        components: {
            'basicinfo-form': basicInfo,
            'defaults-form': defaultForm,
            'notification-form': notificationForm
        },
        data() {
            return {
                model: {
                    id: 0,
                    customerID: 0,
                    basicInfo: {
                        code: null,
                        name: null,
                        endSolution: 200,
                        thirdPartySolution: 100,
                        status: 100,
                        verificationDate: null,
                    },
                    defaults: {
                        warehouseID: 0,
                        currencyID: 0,
                        content: null,
                        email: null,
                        telephone: null,
                        packagingType: 4,
                        incoterm: 16,
                        weightUnit: 100,
                        dimensionUnit: 100
                    },
                    credentials: {
                        platformID: 1,
                        credential_01: null,
                        credential_02: null,
                        credential_03: null,
                        credential_04: null,
                        credential_05: null,
                        credential_06: null,
                        credential_07: null,
                        credential_08: null,
                        credential_09: null,
                        credential_10: null,
                        credential_11: null,
                        credential_12: null,
                        credential_13: null,
                        credential_14: null,
                        credential_15: null,
                        tokenIssued: null,
                        tokenExpiry: null
                    },
                    orderAutomation: {
                        type: 100,
                        webhook: 200,
                        fulfillOrder: false,
                        partialFulfillment: false,
                        notifyUser: false,
                        schedule: {
                            hourInterval: 1,
                            lastRunDate: null,
                            nextRunDate: null
                        }
                    },
                    productAutomation: {
                        type: 100,
                        webhook: 200,
                        schedule: {
                            hourInterval: 1,
                            lastRunDate: null,
                            nextRunDate: null
                        }
                    },
                    notification: {
                        failed: null,
                        success: null
                    },
                    shopInfo: {
                        code: null,
                        name: null,
                        address1: null,
                        address2: null,
                        address3: null,
                        city: null,
                        state: null,
                        stateCode: null,
                        postalCode: null,
                        country: null,
                        countryID: null,
                        contact: null,
                        telephone: null,
                        email: null,
                        shopDomain: null,
                        storeDomain: null,
                        timezone: null,
                        ianaTimezone: null,
                        supportedCurrencies: null,
                        defaults: {
                            primaryLocationID: null,
                            currency: null,
                            weightUnit: null
                        },
                        taxConfiguration: {
                            taxInclusive: false,
                            taxShipping: false
                        }
                    },
                    environmentID: null,
                    recordStatus: 0
                },
                platform: null,
                accessScope: null,
                validation: {
                    basicInfo: false,
                    notification: false,
                    defaults: false,
                },
                activity: {
                    loading: false
                }
            }
        },
        computed: {
            disableNext() { return this.validation.basicInfo || this.validation.notification || this.validation.defaults; }
        },
        watch: {
            modelValue() { this.model = this.modelValue; },
            modelPlatform() { this.platform = this.modelPlatform; },
            modelAccessScope() { this.accessScope = this.modelAccessScope; },
            model() { this.onSync(); }
        },
        methods: {
            onPrevPage() {
                this.$emit('prev-page', { formData: this.model, platform: this.platform, pageIndex: 2 });
            },
            onNextPage() {
                this.$emit('next-page', { formData: this.model, platform: this.platform, pageIndex: 2 });
            },
            onSync() {
                this.$emit("update:modelValue", this.model);
                this.$emit("is-sync", this.model);
            },
        },
        created() {
            if (this.modelValue)
                this.model = this.modelValue;
            if (this.modelPlatform)
                this.platform = this.modelPlatform;
            if (this.modelAccessScope)
                this.accessScope = this.modelAccessScope;
        }
    }
</script>