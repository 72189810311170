<template>
    <form-record v-if="model.id" :model="model" :modelState="v$" @is-reset="resetRecord" :title="`[${model?.code}] ${model?.name}`" :subTitle="`${model?.typeName?.toUpperCase() ?? ''}`">
        <div class="p-d-flex">
            <div class="p-col-4">
                <form-input id="code" :validator="v$" :value="model?.code" :required="true" @is-sync="model.code = $event?.value" v-uppercase v-focus />
                <form-input id="name" :validator="v$" :value="model?.name" :required="true" @is-sync="model.name = $event?.value" />
                <form-lookup id="contractCountry" :value="model?.contractCountry?.id" label="Contract Country" source="country" sourceType="enums" :required="true" :v$="v$" @is-sync="model.contractCountry.id = $event?.value"></form-lookup>

            </div>
            <div class="p-col-4">
                <form-lookup id="providerID" :value="model.provider?.id" label="Provider" :source="$vm.$providers" :required="true" sourceType="array" :v$="v$" @is-sync="model.provider = $event.record"></form-lookup>
                <!--<form-lookup id="providerCarrierID" :disabled="(dsCarriers ?? []).length === 0" :value="model?.providerCarrier?.id" label="Carrier" :source="dsCarriers" :required="true" :v$="v$" @is-sync="model.providerCarrier.id = $event?.value"></form-lookup>-->
            </div>
        </div>
        <div class="p-grid" style="margin: 0 -1.5rem;">
            <div class="p-col-12">
                <pv-tab>
                    <pv-tabpanel header="Zone Names">
                        <zonename-form :zoneID="model.id" :formData="model" @is-sync="model.zoneNames = $event?.zoneNames ?? $event"></zonename-form>
                    </pv-tabpanel>
                    <pv-tabpanel header="Zone Groups">
                        <zonegroup-form :zoneID="model.id" :formData="model" @is-sync="model.zoneGroups = $event?.zoneGroups ?? $event"></zonegroup-form>
                    </pv-tabpanel>
                    <pv-tabpanel header="Zone Matrix">
                        <zonematrix-form :zoneID="model.id" :zn="model.zoneNames" :zg="model.zoneGroups" :formData="model" @is-sync="model.zoneMatrix = $event?.zoneMatrix ?? $event"></zonematrix-form>
                    </pv-tabpanel>
                    <pv-tabpanel header="Zone Mapping">
                        <zonemapping-form :zoneID="model.id" :zn="model.zoneNames" :zg="model.zoneGroups" :formData="model" @is-sync="model.zoneMatrix = $event?.zoneMatrix ?? $event"></zonemapping-form>
                    </pv-tabpanel>
                </pv-tab>
            </div>
        </div>
    </form-record>
</template>
<script>
    import zonenameform from '@/components/settings/zone/zonename-form.vue';
    import zonegroupform from '@/components/settings/zone/zonegroup-form.vue';
    import zonematrixform from '@/components/settings/zone/zonematrix-form.vue';
    import zonemappingform from '@/components/settings/zone/zonemapping-form.vue';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from '@vuelidate/validators';

    export default {
        name: "ZoneRecord",
        setup: () => ({ v$: useVuelidate() }),
        mixins: [mixins.RECORD],
        components: {
            'zonename-form': zonenameform,
            'zonegroup-form': zonegroupform,
            'zonematrix-form': zonematrixform,
            'zonemapping-form': zonemappingform
        },
        data() {
            return {
                carriers: [],

            }
        },
        computed: {
            dsCarriers() {
                return this.carriers;
            }
        },
        validations() {
            return {
                model: {
                    provider: {
                        id: { required, $autoDirty: true },
                    },
                    providerCarrier: {
                        id: { $autoDirty: true },
                    },
                    contractCountry: {
                        id: { $autoDirty: true },
                    },
                    code: { required, minLength: minLength(1), maxLength: maxLength(20), $autoDirty: true },
                    name: { required, maxLength: maxLength(50), $autoDirty: true },
                }
            }
        },
        watch: {
            "model.provider.id": {
                handler() {
                    this.carriers = this.bindCarriers(this.model?.provider?.id);
                }, deep: true
            },
        },
        methods: {
            bindCarriers(id) {
                this.carriers = [];
                if (id) {
                    this.$store.dispatch("providerzone/getURL", { url: "/services/settings/serviceprovidercarrier/byprovider/id/" + id })
                        .then((response) => { this.carriers = response.result; });
                }
            }
        }
    }
</script>
