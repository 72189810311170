<template>
    <div v-if="quote">
        <div class="p-grid">
            <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Carrier</div>
            <div class="p-col">: <span>[<b>{{quote.ProviderInfo.CustomerAccount.Code}}</b>] {{quote.ProviderInfo.Provider.Name}}</span></div>
        </div>
        <div class="p-grid">
            <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Product</div>
            <div class="p-col">: <span>[<b>{{quote.ProviderInfo.Product.Code}}</b>] {{quote.ProviderInfo.Product.Name}}</span></div>
        </div>
        <div class="p-grid">
            <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Account Number</div>
            <div class="p-col">: <span>{{quote.ProviderInfo.Account.AccountNumber}}</span></div>
        </div>
        <div class="p-grid">
            <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Route Details</div>
            <div class="p-col">: <span><b>{{quote.Route.Origin}}</b> / <b>{{ quote.Route.Destination }}</b></span></div>
        </div>
        <div class="p-grid">
            <div class="p-col-fixed" style="width: 150px; font-weight: bold;">Billable Weight</div>
            <div class="p-col">: <span class="p-code-text">{{quote.BillableWeight.toFixed(3) }} {{ $filters.quantifiedDisplay(quote.BillableWeightUnit, quote.BillableWeight) }}</span> / (<b>{{quote.PackageCount}}</b> {{ $filters.quantifiedDisplay('package', quote.PackageCount) }})</div>
        </div>
        <div class="p-grid" style="margin: 1rem -1.25rem -1rem; border-top: 1px solid darkgray; border-bottom: 1px solid darkgray;">
            <div class="p-col-fixed" style="width: 100px; font-weight: bold;"><img :src="quote.ProviderInfo.Carrier?.Logo ?? quote.ProviderInfo.Provider.Logo" style="height: 2.5rem; width: 10rem; object-fit: contain;" /></div>
            <div class="p-col" style="text-align: center"><span style="font-size: 2rem; font-weight: bold;">{{ $filters.formatCurrency(NetShippingFee, quote.TargetCurrency) }}</span></div>
        </div>
        <!--<div class="p-d-flex p-nogutter" style="margin: 1rem -1.25rem -1rem; ">
        <div class="p-buttonset" style="width: 100%;">
            <pv-button style="width: 50%;" class="p-button p-button-lg p-button-success" icon="pi pi-save" label="SAVE"></pv-button>
            <pv-button style="width: 50%;" class="p-button p-button-lg p-button-info" icon="pi pi-send" label="SEND"></pv-button>
        </div>
    </div>-->
        <div v-if="resultView === 200" style="background: var(--surface-b); margin: 1rem -1.25rem -1rem; border-top: 1px solid darkgray; padding: 1.25rem;">
            <h5>BREAKDOWN OF FEES</h5>
            <div v-if="(quote.Packages ?? []).length === 0">
                <div v-if="quote.GrossStandardCharge > 0" class="p-grid" style="border-bottom: 1px solid darkgray; margin-bottom: 0.5rem;">
                    <div class="p-col-fixed" style="width: 250px; font-weight: bold;">STANDARD CHARGES</div>
                    <div class="p-col" style="font-weight: bold; text-align: right"><span>{{ $filters.formatCurrency(quote.GrossStandardCharge ?? 0.00, quote.BaseCurrency) }}</span></div>
                </div>
                <div v-if="quote.BaseCharge" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                    <div class="p-col-fixed" style="width: 250px; font-weight: bold; font-size: 0.9rem;"><span v-html="`${quote.BaseCharge.Particular} ${(quote.Zone?.ZoneName ? '(' + quote.Zone?.ZoneName + ')' : '' )}`"></span></div>
                    <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ $filters.formatCurrency((quote.MinimumRevenue?.NetCharge ?? 0.00) > (quote.BaseCharge.NetCharge ?? 0.00) ? (quote.MinimumRevenue?.NetCharge ?? 0.00) : (quote.BaseCharge.NetCharge ?? 0.00), quote.BaseCurrency) }}</span></div>
                </div>
                <div v-if="quote.ExcessCharge" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                    <div class="p-col-fixed" style="width: 250px; font-weight: bold; font-size: 0.9rem;">Excess Charge [{{ `${quote.ExcessCharge.Multiplier} x ${$filters.formatCurrency(quote.ExcessCharge.AppliedRate ?? 0.00, quote.BaseCurrency)}` }}]</div>
                    <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ $filters.formatCurrency(quote.ExcessCharge.NetCharge ?? 0.00, quote.BaseCurrency) }}</span></div>
                </div>
                <div v-if="quote.OtherCharges">
                    <div v-for="otherCharge in quote.OtherCharges" :key="otherCharge.Particular" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                        <div class="p-col-fixed" style="width: 250px; font-weight: bold; font-size: 0.9rem;">{{ otherCharge.Particular }}</div>
                        <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ $filters.formatCurrency(otherCharge.NetCharge ?? 0.00, quote.BaseCurrency) }}</span></div>
                    </div>
                </div>
                <div v-if="quote.GrossSurcharges > 0">
                    <div v-if="quote.GrossSurcharges > 0" class="p-grid" style="border-bottom: 1px solid darkgray; margin-bottom: 0.5rem; margin-top: 0.5rem">
                        <div class="p-col-fixed" style="width: 250px; font-weight: bold;">OTHER SURCHARGES</div>
                        <div class="p-col" style="font-weight: bold; text-align: right"><span>{{ $filters.formatCurrency(quote.GrossSurcharges ?? 0.00, quote.BaseCurrency) }}</span></div>
                    </div>
                    <div v-for="surcharge in quote.Surcharges" :key="surcharge.Code" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                        <div class="p-col-fixed" style="width: 250px; font-weight: bold; font-size: 0.9rem;">{{ surcharge.Particular }}  {{ 2 &lt;= 1 ? '' : `[${surcharge.Multiplier} x ${$filters.formatCurrency(surcharge.AppliedRate ?? 0.00, quote.BaseCurrency)}]` }}</div>
                        <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ $filters.formatCurrency(surcharge.NetCharge ?? 0.00, quote.BaseCurrency) }}</span></div>
                    </div>
                </div>
                <div v-if="quote.FuelSurcharge && quote.FuelSurcharge.NetCharge > 0">
                    <div v-if="quote.FuelSurcharge.NetCharge > 0" class="p-grid" style="border-bottom: 1px solid darkgray; margin-bottom: 0.5rem; margin-top: 0.5rem">
                        <div class="p-col-fixed" style="width: 250px; font-weight: bold; text-transform: uppercase;"><span v-html="`${quote.FuelSurcharge.Particular}`"></span></div>
                        <div class="p-col" style="font-weight: bold; text-align: right"><span>{{ $filters.formatCurrency(quote.FuelSurcharge.NetCharge ?? 0.00, quote.BaseCurrency) }}</span></div>
                    </div>
                    <div v-if="quote.FuelSurcharge.NetCharge > 0" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                        <div class="p-col-fixed" style="width: 250px; font-weight: bold; font-size: 0.9rem;">Gross Charge</div>
                        <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ $filters.formatCurrency(quote.FuelSurcharge.PercentageBaseRate ?? 0.00, quote.BaseCurrency) }}</span></div>
                    </div>
                    <div v-if="quote.FuelSurcharge.NetCharge > 0" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                        <div class="p-col-fixed" style="width: 250px; font-weight: bold; font-size: 0.9rem;">{{ quote.FuelSurcharge.RateType === 100 ? "+" : "x"}} Fuel Surcharge Rate </div>
                        <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ quote.FuelSurcharge.AppliedRateType === 100 ? $filters.formatCurrency(quote.FuelSurcharge.AppliedRate ?? 0.00, quote.BaseCurrency) : $filters.formatNumber(quote.FuelSurcharge.AppliedRate, 2) + "%" }}</span></div>
                    </div>
                </div>
                <div v-if="quote.CustomerMarkup && quote.CustomerMarkup.NetCharge > 0">
                    <div class="p-grid" style="border-bottom: 1px solid darkgray; margin-bottom: 0.5rem; margin-top: 0.5rem">
                        <div class="p-col-fixed" style="width: 250px; font-weight: bold;">CUSTOMER MARKUP <span v-html="`${FixedMarkup}`"></span></div>
                        <div class="p-col" style="font-weight: bold; text-align: right"><span>{{ $filters.formatCurrency((quote.CustomerMarkup.NetCharge ?? 0.00), quote.BaseCurrency) }}</span></div>
                    </div>
                    <div v-if="(quote.CustomerMarkup.AppliedRateType == 200)" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                        <div class="p-col-fixed" style="width: 250px; font-weight: bold; font-size: 0.9rem;">Gross Shipping Fee</div>
                        <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ $filters.formatCurrency(quote.ContractCost ?? 0.00, quote.BaseCurrency) }}</span></div>
                    </div>
                    <div v-if="(quote.CustomerMarkup.AppliedRate ?? 0) > 0" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                        <div class="p-col-fixed" style="width: 160px; font-weight: bold; font-size: 0.9rem;"><span v-html="`${ quote.CustomerMarkup.Particular }`"></span></div>
                        <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ quote.CustomerMarkup.AppliedRateType === 200 ? $filters.formatNumber(quote.CustomerMarkup.AppliedRate, 2) + '%' : $filters.formatCurrency(quote.CustomerMarkup.AppliedRate, quote.BaseCurrency) }}</span></div>
                    </div>
                    <div v-if="(quote.CustomerMarkup.AppliedRateType == 300 && quote.CustomerMarkup.Multiplier > 1)" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                        <div class="p-col-fixed" style="width: 250px; font-weight: bold; font-size: 0.9rem;">Number of Packages</div>
                        <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ quote.CustomerMarkup.Multiplier }}</span></div>
                    </div>
                </div>
                <div v-if="quote.BaseCurrency !== quote.TargetCurrency">
                    <div v-if="(quote.ExchangeRate ?? 0) > 0" class="p-grid" style="border-bottom: 1px solid darkgray; margin-bottom: 0.5rem; margin-top: 0.5rem">
                        <div class="p-col-fixed" style="width: 250px; font-weight: bold;">FOREIGN EXCHANGE</div>
                        <div class="p-col" style="font-weight: bold; text-align: right"><span>{{ $filters.formatCurrency(quote.ConvertedNetCost, quote.TargetCurrency) }}</span></div>
                    </div>
                    <div class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                        <div class="p-col-fixed" style="width: 250px; font-weight: bold; font-size: 0.9rem;">Shipping Fee</div>
                        <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ $filters.formatCurrency(quote.CustomerCost ?? 0.00, quote.BaseCurrency) }}</span></div>
                    </div>
                    <div v-if="(quote.ExchangeRate ?? 0) > 0" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                        <div class="p-col-fixed" style="width: 200px; font-weight: bold; font-size: 0.9rem;">x Exchange Rate {{ $filters.formatCurrency(1, quote.BaseCurrency) }} = </div>
                        <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ $filters.formatCurrency(quote.ExchangeRate, quote.TargetCurrency, null, 7) }}</span></div>
                    </div>
                    <div v-if="quote.exchangeRateDate" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                        <div class="p-col-fixed" style="width: 150px; font-weight: bold; font-size: 0.9rem;">Applied Date <span style="color: var(--text-danger)">*</span></div>
                        <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ $filters.toDateDisplay(quote.exchangeRateDate) }}</span></div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-for="pkgQuote in quote.Packages" :key="pkgQuote.Reference">
                    <div class="p-grid" style="border-bottom-style: double; margin: 0.5rem -1rem;">
                        <div class="p-col-fixed" style="width: 250px; font-weight: bold; font-size: 1.2rem;">PACKAGE: {{ pkgQuote.Reference }} ({{ pkgQuote.BillableWeight.toFixed(3) }} {{ $filters.quantifiedDisplay(pkgQuote.BillableWeightUnit, quote.BillableWeight) }})</div>
                        <div class="p-col" style="font-weight: bold; text-align: right; font-size: 1.2rem; "><span style="color: var(--text-success)">{{ $filters.formatCurrency(pkgQuote.ConvertedNetCost, pkgQuote.TargetCurrency) }}</span></div>
                    </div>
                    <div v-if="pkgQuote.GrossStandardCharge > 0" class="p-grid" style="border-bottom: 1px solid darkgray; margin-bottom: 0.5rem;">
                        <div class="p-col-fixed" style="width: 250px; font-weight: bold;">STANDARD CHARGES</div>
                        <div class="p-col" style="font-weight: bold; text-align: right"><span>{{ $filters.formatCurrency(pkgQuote.GrossStandardCharge ?? 0.00, pkgQuote.BaseCurrency) }}</span></div>
                    </div>
                    <div v-if="pkgQuote.BaseCharge" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                        <div class="p-col-fixed" style="width: 250px; font-weight: bold; font-size: 0.9rem;"><span v-html="`${pkgQuote.BaseCharge.Particular} ${(quote.Zone?.ZoneName ? '(' + quote.Zone?.ZoneName + ')' : '' )}`"></span></div>
                        <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ $filters.formatCurrency((pkgQuote.MinimumRevenue?.NetCharge ?? 0.00) > (pkgQuote.BaseCharge.NetCharge ?? 0.00) ? (pkgQuote.MinimumRevenue?.NetCharge ?? 0.00) : (pkgQuote.BaseCharge.NetCharge ?? 0.00), pkgQuote.BaseCurrency) }}</span></div>
                    </div>
                    <div v-if="pkgQuote.ExcessCharge" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                        <div class="p-col-fixed" style="width: 250px; font-weight: bold; font-size: 0.9rem;">Excess Charge [{{ `${pkgQuote.ExcessCharge.Multiplier} x ${$filters.formatCurrency(pkgQuote.ExcessCharge.AppliedRate ?? 0.00, pkgQuote.BaseCurrency)}` }}]</div>
                        <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ $filters.formatCurrency(pkgQuote.ExcessCharge.NetCharge ?? 0.00, pkgQuote.BaseCurrency) }}</span></div>
                    </div>
                    <div v-if="pkgQuote.OtherCharges">
                        <div v-for="otherCharge in pkgQuote.OtherCharges" :key="otherCharge.Particular" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                            <div class="p-col-fixed" style="width: 250px; font-weight: bold; font-size: 0.9rem;">{{ otherCharge.Particular }}</div>
                            <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ $filters.formatCurrency(otherCharge.NetCharge ?? 0.00, pkgQuote.BaseCurrency) }}</span></div>
                        </div>
                    </div>
                    <div v-if="pkgQuote.GrossSurcharges > 0">
                        <div v-if="pkgQuote.GrossSurcharges > 0" class="p-grid" style="border-bottom: 1px solid darkgray; margin-bottom: 0.5rem; margin-top: 0.5rem">
                            <div class="p-col-fixed" style="width: 250px; font-weight: bold;">OTHER SURCHARGES</div>
                            <div class="p-col" style="font-weight: bold; text-align: right"><span>{{ $filters.formatCurrency(pkgQuote.GrossSurcharges ?? 0.00, pkgQuote.BaseCurrency) }}</span></div>
                        </div>
                        <div v-for="surcharge in pkgQuote.Surcharges" :key="surcharge.Code" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                            <div class="p-col-fixed" style="width: 250px; font-weight: bold; font-size: 0.9rem;">{{ surcharge.Particular }}  {{ 2 &lt;= 1 ? '' : `[${surcharge.Multiplier} x ${$filters.formatCurrency(surcharge.AppliedRate ?? 0.00, pkgQuote.BaseCurrency)}]` }}</div>
                            <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ $filters.formatCurrency(surcharge.NetCharge ?? 0.00, pkgQuote.BaseCurrency) }}</span></div>
                        </div>
                    </div>
                    <div v-if="pkgQuote.FuelSurcharge && pkgQuote.FuelSurcharge.NetCharge > 0">
                        <div v-if="pkgQuote.FuelSurcharge.NetCharge > 0" class="p-grid" style="border-bottom: 1px solid darkgray; margin-bottom: 0.5rem; margin-top: 0.5rem">
                            <div class="p-col-fixed" style="width: 250px; font-weight: bold; text-transform: uppercase;"><span v-html="`${pkgQuote.FuelSurcharge.Particular}`"></span></div>
                            <div class="p-col" style="font-weight: bold; text-align: right"><span>{{ $filters.formatCurrency(pkgQuote.FuelSurcharge.NetCharge ?? 0.00, pkgQuote.BaseCurrency) }}</span></div>
                        </div>
                        <div v-if="pkgQuote.FuelSurcharge.NetCharge > 0" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                            <div class="p-col-fixed" style="width: 250px; font-weight: bold; font-size: 0.9rem;">Gross Charge</div>
                            <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ $filters.formatCurrency(pkgQuote.FuelSurcharge.PercentageBaseRate ?? 0.00, pkgQuote.BaseCurrency) }}</span></div>
                        </div>
                        <div v-if="pkgQuote.FuelSurcharge.NetCharge > 0" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                            <div class="p-col-fixed" style="width: 250px; font-weight: bold; font-size: 0.9rem;">{{ pkgQuote.FuelSurcharge.RateType === 100 ? "+" : "x"}} Fuel Surcharge Rate </div>
                            <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ pkgQuote.FuelSurcharge.AppliedRateType === 100 ? $filters.formatCurrency(pkgQuote.FuelSurcharge.AppliedRate ?? 0.00, pkgQuote.BaseCurrency) : $filters.formatNumber(pkgQuote.FuelSurcharge.AppliedRate, 2) + "%" }}</span></div>
                        </div>
                    </div>
                    <div v-if="pkgQuote.CustomerMarkup && pkgQuote.CustomerMarkup.NetCharge > 0">
                        <div class="p-grid" style="border-bottom: 1px solid darkgray; margin-bottom: 0.5rem; margin-top: 0.5rem">
                            <div class="p-col-fixed" style="width: 250px; font-weight: bold;">CUSTOMER MARKUP</div>
                            <div class="p-col" style="font-weight: bold; text-align: right"><span>{{ $filters.formatCurrency((pkgQuote.CustomerMarkup.NetCharge ?? 0.00) , pkgQuote.BaseCurrency) }}</span></div>
                        </div>
                        <div v-if="(pkgQuote.CustomerMarkup.AppliedRateType > 100)" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                            <div class="p-col-fixed" style="width: 250px; font-weight: bold; font-size: 0.9rem;">Gross Shipping Fee</div>
                            <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ $filters.formatCurrency(pkgQuote.ContractCost ?? 0.00, pkgQuote.BaseCurrency) }}</span></div>
                        </div>
                        <div v-if="(pkgQuote.CustomerMarkup.AppliedRate ?? 0) > 0" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                            <div class="p-col-fixed" style="width: 160px; font-weight: bold; font-size: 0.9rem;"><span v-html="`${ pkgQuote.CustomerMarkup.Particular }`"></span></div>
                            <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ pkgQuote.CustomerMarkup.AppliedRateType === 200 ? $filters.formatNumber(pkgQuote.CustomerMarkup.AppliedRate, 2) + '%' : $filters.formatCurrency(pkgQuote.CustomerMarkup.AppliedRate, pkgQuote.BaseCurrency) }}</span></div>
                        </div>
                    </div>
                    <div v-if="pkgQuote.BaseCurrency !== pkgQuote.TargetCurrency">
                        <div v-if="(pkgQuote.ExchangeRate ?? 0) > 0" class="p-grid" style="border-bottom: 1px solid darkgray; margin-bottom: 0.5rem; margin-top: 0.5rem">
                            <div class="p-col-fixed" style="width: 250px; font-weight: bold;">FOREIGN EXCHANGE</div>
                            <div class="p-col" style="font-weight: bold; text-align: right"><span>{{ $filters.formatCurrency(pkgQuote.ConvertedNetCost, pkgQuote.TargetCurrency) }}</span></div>
                        </div>
                        <div class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                            <div class="p-col-fixed" style="width: 250px; font-weight: bold; font-size: 0.9rem;">Shipping Fee</div>
                            <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ $filters.formatCurrency(pkgQuote.CustomerCost ?? 0.00, pkgQuote.BaseCurrency) }}</span></div>
                        </div>
                        <div v-if="(pkgQuote.ExchangeRate ?? 0) > 0" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                            <div class="p-col-fixed" style="width: 200px; font-weight: bold; font-size: 0.9rem;">x Exchange Rate {{ $filters.formatCurrency(1, pkgQuote.BaseCurrency) }} = </div>
                            <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ $filters.formatCurrency(pkgQuote.ExchangeRate, pkgQuote.TargetCurrency, null, 7) }}</span></div>
                        </div>
                        <div v-if="pkgQuote.exchangeRateDate" class="p-grid" style="margin-left: 1rem; margin-right: 4rem">
                            <div class="p-col-fixed" style="width: 150px; font-weight: bold; font-size: 0.9rem;">Applied Date <span style="color: var(--text-danger)">*</span></div>
                            <div class="p-col" style="font-size: 0.9rem; text-align: right"><span>{{ $filters.toDateDisplay(pkgQuote.exchangeRateDate) }}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="resultView === 200 && quote.Notes && quote.Notes.length > 0" style="margin: 1rem -1rem; padding: 0.25rem 0;">
            <div v-for="note in quote.Notes" :key="note" class="p-code-text" style="font-size: 0.8rem; font-family: var(--font-mono)"><span v-html="`${note}`"></span></div>
        </div>
    </div>
    <div v-else>
        <div style="margin: 1rem 0;">
            <b>ERROR 404:</b> The quotation computation file is not found.
        </div>
    </div>
</template>
<script>
    export default {
        name: 'QuoteResult',
        props: {
            quote: { type: Object },
            resultView: { type: Number }
        },
        computed: {
            NetShippingFee() {
                if ((this.quote.Packages ?? []).length === 0)
                    return this.quote.ConvertedNetCost;
                else {
                    var sum = 0;
                    this.quote.Packages.forEach(x => sum += x.ConvertedNetCost);
                    return sum;
                }
            },
            FixedMarkup() {
                return this.quote.CustomerMarkup.AppliedRateType == 300 ? "<sup style='color: var(--text-danger); font-weight: bold;'>1</sup>" : "";
            }
            
        }
    }
</script>

