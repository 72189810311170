<template>
    <div class="p-col-12" id="divsgpform">
        <pv-picklist v-model="items" listStyle="height:342px" dataKey="accountCustomerProductID" @move-to-target="moveToTarget" @move-all-to-target="moveAllToTarget" @move-to-source="moveToSource" @move-all-to-source="moveAllToSource" @up="up" @reorder="reorder">
            <template #sourceHeader>
                <div class="p-d-flex" style="width: 100%;">
                    <div class="p-col-2" style="padding-top: 10px;">
                        Available
                    </div>
                    <div class="p-col-10" style=" margin: 0; padding: 0;">
                        <pv-input style="margin: 0 10px; width: 100%;" v-model="filter.source" placeholder="Type your keyword..." />
                    </div>
                </div>
            </template>
            <template #targetHeader>
                <div class="p-d-flex" style="width: 100%;">
                    <div class="p-col-2" style="padding-top: 10px;">
                        Selected
                    </div>
                    <div class="p-col-10" style=" margin: 0; padding: 0;">
                        <pv-input style="margin: 0 10px; width: 100%;" v-model="filter.target" placeholder="Type your keyword..." />
                    </div>
                </div>
            </template>
            <template #item="slotProps">
                <div class="picklist-item">
                    <div class="picklist-list-detail" style="margin: 0; padding: 0;">
                        <div v-if="activity.loading" class="p-field p-col-12 p-md-12" style="width: 100%; padding: 0; margin: 0;">
                            <div class="custom-skeleton">
                                <ul class="p-m-0 p-p-0">
                                    <li>
                                        <div class="p-d-flex">
                                            <div style="flex: 1">
                                                <pv-skeleton width="100%" class="p-mb-2"></pv-skeleton>
                                                <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                                <pv-skeleton width="75%" class="p-mb-2"></pv-skeleton>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-else class="p-d-flex" style="width: 100%; padding: 0;">
                            <div style="width: 100%; padding: 0; margin: 0;">
                                <div class="p-d-flex p-jc-between" style="width: 100%; z-index: 100;">
                                    <div><h5 style="margin-bottom: 5px; margin-top: 0; font-size: 1.2rem;"><span style="color:var(--text-danger)">Account # [{{ slotProps.item.accountNo }}]</span><span style="margin-left:5px;"> {{ slotProps.item.name }}</span></h5></div>
                                    <div class="p-as-end"><h5 class="p-code-text"> [{{ slotProps.item.code}}] <img style="width:35px; height:20px; max-height: 20px; max-width: 35px; object-fit: contain; object-position: top;" :title="`${$vm.$providers.filter((x) => x.id == slotProps.item?.serviceProviderID)[0]?.text}`" :src="`/_assets/_integration/${$vm.$providers.filter((x) => x.id == slotProps.item?.serviceProviderID)[0]?.mask}.png`" onerror="this.onerror=null; this.src = '/_assets/img/no-logo.png'" /></h5></div>
                                </div>
                                <div>
                                    <div class="p-grid" style="z-index: 100; width: 100% !important;">
                                        <div class="p-col-12 p-md-5">
                                            <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Maximum Package: </b>  {{ slotProps.item.maxParcelCount }} per consignment</p>
                                            <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Weight Range: </b>  {{ slotProps.item.minWeight?.toFixed(3) }} - {{ slotProps.item.maxWeight?.toFixed(3) }} {{ slotProps.item.weightUnitName?.toLowerCase() }}</p>
                                            <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Max Dimension: </b>  {{ slotProps.item.maxLength }}{{ slotProps.item.lengthUnitName?.toLowerCase() }} x {{ slotProps.item.maxWidth }}{{ slotProps.item.lengthUnitName?.toLowerCase() }} x {{ slotProps.item.maxHeight }}{{ slotProps.item.lengthUnitName?.toLowerCase() }}</p>
                                        </div>
                                        <div class="p-col-12 p-md-5">
                                            <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Maximum Transit Days: </b> {{ slotProps.item.maxDeliveryDay }} {{ $filters.quantifiedDisplay("days", slotProps.item.maxDeliveryDay) }}</p>
                                            <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem"><b class="p-code-text">Estimated Time: </b>  {{ slotProps.item.deliveryHourTimeSpan?.hours === 23 ? "Within the day" : $filters.padLeft(slotProps.item.deliveryHourTimeSpan?.hours - ( slotProps.item.deliveryHourTimeSpan?.hours > 12 ? 12 : 0), 2, '0') + ":" + $filters.padLeft(slotProps.item.deliveryHourTimeSpan?.minutes, 2, '0') + (slotProps.item.deliveryHourTimeSpan?.hours >= 12 ? " PM" : " AM") }}</p>
                                            <p style="margin-bottom: 5px; text-indent:hanging; font-size: 0.9rem">
                                                <b class="p-code-text">Delivery Type: </b><span v-for="item in slotProps.item.usageTypeName" :key="item" :class="`p-code-text p-text-indicator definite-${item.toUpperCase()}`">{{ item }}</span>
                                            </p>
                                        </div>
                                        <div class="p-col-12 p-md-2">
                                            <div style="text-align: right; object-position: right">
                                                <h5 style="margin-top: 0 !important;"><span :class="`p-code-text p-indicator definite-${slotProps.item.timeDefiniteType}`" :title="slotProps.item?.timeDefiniteTypeName">{{ slotProps.item?.timeDefiniteTypeName?.substring(0,1)}}</span></h5>
                                                <h5 style="margin-top: 0 !important;">
                                                    <span v-for="item in slotProps.item.productContentTypeName" :key="item" :class="`p-code-text p-indicator definite-${item.toUpperCase()}`" :title="item">{{ item?.substring(0,1) }}</span>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </pv-picklist>
    </div>
</template>
<script>
    const config = require('@/assets/lib/cosmos/_js/config.js').default.config;
    export default {
        name: 'ProductTemplateForm',
        emits: ["is-sync"],
        props: {
            serviceGroupID: { type: Number, default: -1 },
            formData: { type: Object }
        },
        data() {
            return {
                model: null,
                available: [],
                assigned: [],
                productoption: [],
                //items:null,
                activity: {
                    loading: true
                },
                filter: {
                    source: null,
                    target: null
                },

            }
        },
        computed: {
            companyDS() { return this.$store.getters["company/ds"]; },
            warehouseDS() { return this.companyDS.data.filter((x) => x.type === 8); },
            filteredSource() {
                if (this.filter.source && this.filter.source.trim() !== "") {
                    return this.available.filter(x => x.code?.toLowerCase().includes(this.filter.source.toLowerCase()) || x.name?.toLowerCase().includes(this.filter.source.toLowerCase()));
                }
                return this.available;
            },
            filteredTarget() {
                if (this.filter.target && this.filter.target.trim() !== "") {
                    return this.assigned.filter(x => x.code?.toLowerCase().includes(this.filter.target.toLowerCase()) || x.name?.toLowerCase().includes(this.filter.target.toLowerCase()));
                }
                return this.assigned;
            },
            items() {
                return [this.filteredSource, this.filteredTarget];
                //return [this.available, this.assigned];
            },
            record() {
                return this.$store.getters["servicegroupproduct/record"]
            }
        },
        watch: {
            'assigned': {
                handler() {
                    this.$emit("is-sync", { model: this.assigned });
                }, deep: true
            },
            'productoption': {
                handler() {
                    this.$emit("is-option", { model: this.productoption });
                }, deep: true
            },
            'formData': {
                handler() {
                    if (this.available.length === 0 && this.assigned.length === 0) {
                        this.getAvailable(this.$props?.formData?.customerID, this.$props?.serviceGroupID);
                    }
                    this.getAssigned(this.$props?.serviceGroupID).then(() => {
                        this.activity.loading = false;
                    });
                }, deep: true
            },
            'formData.customerID': {
                handler() {
                    this.getOptions(this.$props?.formData?.customerID);
                }, deep: true
            },
        },
        methods: {
            async postProduct(x) {
                let tempCode = ("SP" + Math.random().toString().replace(".", "")).substring(0, 20);
                this.model = {
                    id: 0,
                    code: tempCode,
                    name: tempCode,
                    serviceGroupID: this.$props?.serviceGroupID,
                    accountCustomerID: x.accountCustomerID,
                    accountCustomerProductID: x.accountCustomerProductID,
                    sortOrder: x.sortOrder
                }
                let _self = this;
                await this.$store.dispatch("servicegroupproduct/addRecord", this.model).then(() => {
                    _self.model = _self.record;
                    x.id = _self.model.id;
                    x.id2 = _self.model.id2;
                    x.mask = _self.model.mask;
                    let index = _self.assigned.findIndex(f => f.accountCustomerProductID === x.accountCustomerProductID);
                    if (index !== -1)
                        _self.assigned[index] = x;

                })
                .catch((ex) => {
                    this.errors = ex.data;
                });
            },
            async putProduct(x) {
                let tempCode = ("SP" + Math.random().toString().replace(".", "")).substring(0, 20);
                this.model = {
                    id: x.id,
                    mask: x.mask,
                    code: tempCode,
                    name: tempCode,
                    serviceGroupID: this.$props?.serviceGroupID,
                    accountCustomerID: x.accountCustomerID,
                    accountCustomerProductID: x.accountCustomerProductID,
                    sortOrder: x.sortOrder
                }
                let _self = this;
                await this.$store.dispatch("servicegroupproduct/modifyRecord", this.model).then(() => {
                    _self.model = _self.record;
                    x.id = _self.model.id;
                    x.id2 = _self.model.id2;
                    x.mask = _self.model.mask;
                    let index = _self.assigned.findIndex(f => f.accountCustomerProductID === x.accountCustomerProductID);
                    if (index !== -1)
                        _self.assigned[index] = x;

                })
                    .catch((ex) => {
                        this.errors = ex.data;
                    });
            },
            async deleteProduct(x) {
                let _self = this;
                await this.$store.dispatch("servicegroupproduct/removeRecord", x.mask).then(() => {
                    x.id = 0;
                    x.id2 = null;
                    x.mask = null;
                    let index = _self.available.findIndex(f => f.accountCustomerProductID === x.accountCustomerProductID);
                    if (index !== -1)
                        _self.available[index] = x;
                    _self.model = null;
                    })
                    .catch((ex) => {
                        _self.errors = ex.data;
                    });
            },
            async moveToTarget(e) {
                e.items.forEach(x => {
                    x.sortOrder = this.assigned.length + 1;
                    this.assigned.push(x);
                    if (this.$props?.serviceGroupID > 0) {
                        this.postProduct(x);   
                    }    
                    let index = this.available.findIndex(f => f.accountCustomerProductID === x.accountCustomerProductID);
                    if (index !== -1) {
                        this.available.splice(index, 1);
                    }
                });
            },
            async moveAllToTarget(e) {
                e.items.forEach(x => {
                    x.sortOrder = this.assigned.length + 1;
                    this.assigned.push(x);
                    if (this.$props?.serviceGroupID > 0) {
                        this.postProduct(x);
                    }
                    let index = this.available.findIndex(f => f.accountCustomerProductID === x.accountCustomerProductID);
                    if (index !== -1) {
                        this.available.splice(index, 1);
                    }
                    
                });
               
            },
            async moveToSource(e) {
                e.items.forEach(x => {
                    x.sortOrder = 0;
                    if (this.$props?.serviceGroupID > 0) {
                        this.deleteProduct(x);
                      
                    }
                    let index = this.available.findIndex(f => f.accountCustomerProductID === x.accountCustomerProductID);
                    if (index === -1)
                        this.available.push(x);
                    else {
                        this.available[index] = x;
                    }
                    this.assigned = this.assigned.filter(f => f.accountCustomerProductID !== x.accountCustomerProductID);
                });
            },
            async moveAllToSource(e) {
                e.items.forEach(x => {
                    x.sortOrder = 0;
                    if (this.$props?.serviceGroupID > 0) {
                        this.deleteProduct(x);
                    }
                    let index = this.available.findIndex(f => f.accountCustomerProductID === x.accountCustomerProductID);
                    if (index === -1)
                        this.available.push(x);
                    else {
                        this.available[index] = x;
                    }
                    this.assigned = this.assigned.filter(f => f.accountCustomerProductID !== x.accountCustomerProductID);
                });
            },
            async reorder(e) {
                let index = 1;
                if ((this.filter?.target?.length ?? 0) === 0) {
                    e.value[1].forEach(x => {
                        if (x.sortOrder !== index) {
                            if (this.$props?.serviceGroupID > 0) {
                                x.sortOrder = index;
                                this.putProduct(x);
                            }
                        }
                        x.sortOrder = index;
                        index++
                    });
                    this.assigned = e.value[1];
                }
            },
            async getAvailable(mask, serviceGroupId) {
                await this.$axios
                    .get(config.endpoint.api + `/services/facility/servicegroupproduct/AvailableByCustomerID/${mask ?? 0}?serviceGroupID=${serviceGroupId ?? 0}`.replaceAll("//", "/"))
                    .then((response) => {
                        this.available = response.data.result;
                       // this.items = [this.available, []];
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            async getAssigned(mask) {
                if (mask) {
                    await this.$axios
                        .get(config.endpoint.api + `/services/facility/servicegroupproduct/AssignedByServiceGroupID/${mask ?? 0}`.replaceAll("//", "/"))
                        .then((response) => {
                            this.assigned = response.data.result;
                        })
                        .catch((ex) => { this.errors = ex.data; });
                }
                
            },
            async getOptions(customerID) {
                await this.$axios
                    .get(config.endpoint.api + `/services/facility/servicegroupproduct/ProductOption/${customerID ?? 0}/customer`.replaceAll("//", "/"))
                    .then((response) => {
                        this.productoption = response.data.result;
                    })
                    .catch((ex) => { this.errors = ex.data; });
            }
        },
        async mounted() {
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }

    #divsgpform > div.p-picklist.p-component > .p-picklist-buttons.p-picklist-target-controls {
        display: inline-flex;
        margin: auto;
    }

    .p-orderlist-controls,
    .p-picklist-buttons.p-picklist-source-controls,
    .p-picklist-buttons.p-picklist-source-controls {
        display: none !important;
    }
    .custom-skeleton {
        border: 1px solid var(--surface-d);
        border-radius: 4px;
    }

    .custom-skeleton ul {
        list-style: none;
    }

    .custom-skeleton ul > li {
        padding: 0.75rem;
    }
</style>