<template>
    <form-wrapper title="E-Commerce Platforms" sub-title="List of integrated e-commerce platform.">
        <cosmos-grid id="DynamicReportTEmplate" sourceType="url" class="is-customized" :sourcePaged="false" source="/services/facility/store" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showExport="false" :showOperation="true" :baseRecordID="0">
            <pv-grid-column field="customerID" header="Customer">
                <template #body="slotProps">
                    <b>
                        {{ customers.filter(x => x.id === slotProps.data.customerID)[0]?.altName ?? customers.filter(x => x.id === slotProps.data.customerID)[0]?.name ?? ''}}
                    </b>
                </template>
            </pv-grid-column>
            <pv-grid-column field="credentials.platformID" header="Platform">
                <template #body="slotProps">
                    <img :src="`/_assets/_platform/${$vm.$platforms.filter(x => x.id === slotProps.data.credentials.platformID)[0]?.mask}.png`" style="max-height: 5rem; object-fit: cover; max-width: 8rem;" />
                </template>
            </pv-grid-column>
            <pv-grid-column field="basicInfo.name" header="Store Details" style="width: 100%;">
                <template #body="slotProps">
                    <div class="p-d-flex p-jc-between" style="position: relative;width: 100%; font-size: 1.1rem; font-weight: bold">
                        <div class="flex-1 flex gap-2" style="font-weight: bold; font-size: 1.1rem !important">
                            {{ slotProps.data.basicInfo.name }}
                        </div>
                        <div class="flex-0 flex gap-2 relative p-code-text" style="font-weight: bold; font-size: 1.1rem !important">
                            [{{ slotProps.data.basicInfo.code }}]
                        </div>
                    </div>
                    <div><a style="font-weight: bold;" :href="`https://${slotProps.data.shopInfo.storeDomain}`" target="_blank">{{slotProps.data.shopInfo.storeDomain }}</a></div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="basicInfo.status" header="Status">
                <template #body="slotProps" style="font-weight: bold; color: var(--text-danger); text-transform: uppercase;">
                    <div v-if="slotProps.data.basicInfo.status === 100">
                        <i class="fa fa-circle fa-fw"></i>
                        {{ $vm.$enums.storestatus.filter(x => x.id ===  slotProps.data.basicInfo.status)[0].text }}
                    </div>
                    <div v-else style="font-weight: bold; color: var(--text-success); text-transform: uppercase; ">
                        <i class="fa fa-circle fa-fw"></i>
                        {{ $vm.$enums.storestatus.filter(x => x.id ===  slotProps.data.basicInfo.status)[0].text }}
                    </div>
                </template>
            </pv-grid-column>
        </cosmos-grid>
    </form-wrapper>
</template>
<script>
    export default {
        name: 'DynamicReportGridTemplate',
        data() {
            return {
                type: -1,
                source: [],
                filter: {},
                columns: [],
                deleteDisplay: ["basicInfo.code", "basicInfo.name"],
                searchFields: ["basicInfo.code", "basicInfo.name"],
                activity: {
                    loading: true
                },
                model: {
                    contact: {
                        id: null,
                        temporaryID: null,
                        person: {
                            firstName: null,
                            lastName: null,
                            email: null,
                            telephone: null,
                            mobile: null
                        },
                        identity: {
                            eori: null,
                            ioss: null,
                            vat: null,
                            idNumber: null,
                            idType: 300
                        },
                        address: {
                            id: null,
                            temporaryID: null,
                            company: null,
                            address1: null,
                            address2: null,
                            address3: null,
                            city: null,
                            state: null,
                            stateCode: null,
                            postalCode: null,
                            countryID: 238,
                            countryData: null,
                            establishmentType: 100
                        }
                    },
                    address: {
                        id: null,
                        temporaryID: null,
                        company: null,
                        address1: null,
                        address2: null,
                        address3: null,
                        city: null,
                        state: null,
                        stateCode: null,
                        postalCode: null,
                        countryID: 238,
                        countryData: null,
                        establishmentType: 100
                    }
                },
                customers: [],
                warehouses: [],
            }
        },
        computed: {
            sessionKeys() { return this.$store.getters["auth/session"]; },
        },
        methods: {
            getSource() {
                this.customers = this.$config.common.$customer(this.sessionKeys.environment);
                this.warehouses = this.$config.common.$warehouse(this.sessionKeys.environment);
            }
        },
        mounted() {
            this.getSource();
        }
    }
</script>