<template>
    <is-dialog header="SERVICE PROVIDER DETAILS" :visible="dialog.allocate.show" @is-confirm="onAllocateConfirm(model)" @is-cancel="{dialog.allocate.show = false;}" :confirmDisabled="action.processing" :cancelDisabled="action.processing" :breakpoints="{'360px': '75vw', '340px': '100vw'}" :style="{width: '35vw'}">
        <div class="confirmation-content p-grid p-d-flex">
            <div v-if="!action.processing" class="p-col-12">
                <div style="text-align:left; padding:0.25rem 0.5rem;" class="p-code-text">You are about to commit this consignment to the following service provider:</div>
                <div class="card" :style="`background:var(--surface-a); width:calc(100%)`">
                    <table style="width: calc(100% + 1rem); padding: 0.25rem 0.5rem; margin-left:-0.5rem;" cellspacing="0">
                        <tr>
                            <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) "><h4 style="margin: -0.25rem -0.5rem">COLLECTION INFO</h4></th>
                        </tr>
                        <!--<tr style="border-bottom:thin; border:0.01rem;">
        <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Type</th>
        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$vm.$enums['pickuptype'].filter((x) => x.id == model?.pickupType)[0]?.text}`"></div></td>
    </tr>-->
                        <tr style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Collection Date</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatDateWithFormat(model?.request_CollectionDate,'DD/MMM/YYYY')}`"></div></td>
                        </tr>
                        <tr style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Ready Time</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatDateWithFormat(model?.request_ReadyTime, 'HH:mm')}`"></div></td>
                        </tr>
                        <tr style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Closing Time</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatDateWithFormat(model?.request_ClosingTime, 'HH:mm')}`"></div></td>
                        </tr>
                        <tr>
                            <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) "><h4 style="margin: -0.25rem -0.5rem">SHIPPING INFO</h4></th>

                        </tr>
                        <!--<tr>
        <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">
            <div class="p-d-flex p-col-12">
                <img style="width: 30%; max-height:4rem; object-fit: contain; object-position: center;" :src="`/_assets/_integration/${$vm.$providers.filter((x) => x.id == model?.serviceProviderID)[0]?.mask}.png`" onerror="this.onerror=null; this.src = '/_assets/img/no-logo.png'" />
            </div>
        </th>
    </tr>-->
                        <tr v-if="source.shippingInfo.serviceGroup.code" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Service Group</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${source.shippingInfo.serviceGroup?.text  ?? ''}`"></div></td>
                        </tr>
                        <tr style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Carrier</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${source.shippingInfo.carrier?.text  ?? ''}`"></div></td>
                        </tr>
                        <tr style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Service</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${source.shippingInfo.product?.text ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="(source.shippingInfo.option?.text)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Option</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${source.shippingInfo.option?.text  ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="(source?.shippingInfo?.product?.configuration?.GenerateCustomsDataFlag ?? false)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Customs Data</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${(source.shippingInfo?.customization?.generateCustomsData ?? false) ? 'Y' : 'N'}`"></div></td>
                        </tr>
                        <tr v-if="source.shippingInfo.additional?.code" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Additional Code</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${source.shippingInfo.additional?.code ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="source.shippingInfo.release?.code" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Release Code</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${source.shippingInfo.release?.code ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="source.shippingInfo.accesspoint?.text && !(this.source?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Access Point Location</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${source.shippingInfo.accesspoint?.text ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="(source?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Alternate Address</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${source.shippingInfo.accesspoint?.alternateDelivery?.address?.companyName ?? ''} ${source.shippingInfo.accesspoint?.alternateDelivery?.address?.fullDescription ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="(model.isReturnable ?? false)">
                            <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) "><h4 style="margin: -0.25rem -0.5rem">RETURN INFO</h4></th>
                        </tr>
                        <!--<tr>
        <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">
            <div class="p-d-flex p-col-12">
                <img style="width: 30%; max-height:4rem; object-fit: contain; object-position: center;" :src="`/_assets/_integration/${$vm.$providers.filter((x) => x.id == model?.returnServiceProviderID)[0]?.mask}.png`" onerror="this.onerror=null; this.src = '/_assets/img/no-logo.png'" />
            </div>
        </th>
    </tr>-->
                        <tr v-if="source.returnInfo.serviceGroup.code && (model.isReturnable ?? false)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Service Group</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${source.returnInfo.serviceGroup?.text ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="(model.isReturnable ?? false) && (source.returnInfo.carrier?.text)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Carrier</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${source.returnInfo.carrier?.text  ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="(model.isReturnable ?? false) && (source.returnInfo.product?.text)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Service</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${source.returnInfo.product?.text  ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="(model.isReturnable ?? false)  && (source.returnInfo.option?.text)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Option</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${source.returnInfo.option?.text  ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="(model.isReturnable ?? false)  && (source.returnInfo.additional?.code)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Additional Code</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${source.returnInfo.additional?.code  ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="(this.source?.returnInfo?.accesspoint?.alternateDelivery?.enable ?? false)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Alternate Address</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${source.returnInfo.accesspoint?.alternateDelivery?.address?.companyName ?? ''} ${source.returnInfo.accesspoint?.alternateDelivery?.address?.fullDescription ?? ''}`"></div></td>
                        </tr>
                        <!--<tr v-if="(model.isReturnable ?? false) && source.returnInfo.release?.code" style="border-bottom:thin; border:0.01rem;">
        <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Release Code</th>
        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
        <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${source.returnInfo.release?.code  ?? ''}`"></div></td>
    </tr>
    <tr v-if="(model.isReturnable ?? false) && source.returnInfo.accesspoint?.text" style="border-bottom:thin; border:0.01rem;">
        <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Access Point Location</th>
        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${source.returnInfo.accesspoint?.text  ?? ''}`"></div></td>
    </tr>-->
                    </table>
                </div>
                <div style="text-align:center; vertical-align:middle; padding: 0.5rem -0.5rem; margin: 1rem;" class="p-code-text">Are you sure you still want to proceed?</div>
            </div>
            <div v-else class="p-d-flex p-grid p-col-12">
                <div class="p-col-12" style="text-align:center;">
                    <pv-progress-spinner style="padding:3rem;">PLEASE WAIT</pv-progress-spinner>
                </div>
                <div class="p-col-12" style="text-align:center;">
                    <span class="p-code-text" style="text-align:center"><b>...Allocating consignment to carrier... Please Wait....</b></span>
                </div>
                
            </div>
        </div>
    </is-dialog>
    <div class="p-fluid p-grid">
        <div :class="`p-col-12 p-md-${columnNo}`">
            <h4 style="padding: 0.25rem"><span>COLLECTION INFO</span></h4>
            <form-lookup v-show="false" id="pickupType" :required="true" type="enum" source="pickuptype" :value="model.pickupType" label="Collection Type" @is-sync="model.pickupType = $event.value" :v$="v$"></form-lookup>
            <form-datetime id="request_CollectionDateValue" :required="true" :value="model?.request_CollectionDateValue ?? new Date()" type="date" @is-sync="onCollectionDate($event)" label="Collection Date" :v$="v$"></form-datetime>
            <form-datetime id="request_ReadyTimeValue" :value="model?.request_ReadyTimeValue ?? new Date(2018, 1, 1, 16, 0, 0)" type="time" @is-sync="onReadyTime($event)" :timeFormat="24" label="Ready Time" :v$="v$"></form-datetime>
            <form-datetime id="request_ClosingTimeValue" :value="model?.request_ClosingTimeValue ?? new Date(2018, 1, 1, 18, 0, 0)" type="time" @is-sync="onClosingTime($event)" :timeFormat="24" label="Closing Time" :v$="v$"></form-datetime>
        </div>
        <div :class="`p-col-12 p-md-${columnNo}`">
            <h4 style="padding: 0.25rem"><span>SHIPPING INFO</span></h4>
            <!--<form-lookup id="serviceGroupID" label="Service Group" :required="false" :source="`services/facility/servicegroup/customer/id/${this.model?.customerID}`" sourceType="url" :value="source?.shippingInfo?.serviceGroup.serviceGroupID" :v$="v$" :textField="'text'" :valueField="'id'" @is-sync="onSelectServiceGroup($event,false)" :sorted="false" />-->
            <form-lookup id="serviceGroupID" label="Service Group" :required="false" :source="shippingInfoServiceGroupDS ?? []" sourceType="array" :value="source?.shippingInfo?.serviceGroup.serviceGroupID" :v$="v$" :textField="'text'" :valueField="'id'" @is-sync="onSelectServiceGroup($event,false)" :sorted="false" />
            <form-lookup id="serviceProviderAccountID" label="Carrier" :required="false" :source="shippingInfoCarrierDS ?? []" sourceType="array" :value="source?.shippingInfo.carrier?.serviceProviderAccountID" :showLoadingWhenEmpty="processing.shippingInfo.provider" :v$="v$" :textField="'Text'" :valueField="'Id'" @is-sync="onSelectCarrier($event,false)" :sorted="false">
                <template #option="slotProps">
                    <div class="p-d-flex" style="width:100%">
                        <div class="picklist-list-detail">
                            <div class="p-d-flex" style="padding: 0;">
                                <div style="padding:0;width:100%">
                                    <div class="p-d-flex" style=" z-index: 100;">
                                        <div style="margin-right:5px">
                                            <img style="width: 35px; height: 20px; max-height: 20px; max-width: 35px; object-fit: contain; object-position: center;" :src="`${slotProps.data.option.Logo}`" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" />
                                        </div>
                                        <div class="p-d-flex p-jc-between" style="width:calc(100%)">
                                            <span><span class="p-code-text" style="font-weight: 600;">[{{ slotProps.data.option.CarrierCode}}] </span>{{ slotProps.data.option.CarrierName }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </form-lookup>
            <form-lookup id="serviceProviderProductAccountID" label="Service" :required="false" :showCopy="false" :disabled="((source?.shippingInfo.carrier?.serviceProviderAccountID ?? 0) == 0)" :source="shippingInfoProductDS ?? []" sourceType="array" :showLoadingWhenEmpty="(source?.shippingInfo.carrier?.serviceProviderAccountID > 0) && processing.shippingInfo.product" :value="source?.shippingInfo?.product?.serviceProviderProductAccountID" :v$="v$" :textField="'Text'" :valueField="'Id'" @is-sync="onSelectProduct($event,false)" :sorted="false">
                <template #option="slotProps">
                    <div class="p-d-flex" style="width:100%">
                        <div class="picklist-list-detail">
                            <div class="p-d-flex" style="padding: 0;">
                                <div style="padding:0;width:100%">
                                    <div class="p-d-flex" style=" z-index: 100;">
                                        <div style="margin-right:5px">
                                            <img style="width: 35px; height: 20px; max-height: 20px; max-width: 35px; object-fit: contain; object-position: center;" :src="`${slotProps.data.option.Logo}`" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" />
                                        </div>
                                        <div class="p-d-flex p-jc-between" style="width:calc(100%)">
                                            <span><span class="p-code-text" style="font-weight: 600;">[{{ slotProps.data.option.Code}}] </span>{{ slotProps.data.option.Name }}</span>
                                            <spam class="p-jc-end">
                                                <span class="p-code-text">
                                                    <span class="product-badge status-instock p-code-text" style="margin-left:0.75rem">{{$filters.formatCurrency(slotProps.data.option?.Quotation?.ShippingCost?.Price?.Amount ?? 0.00,slotProps.data.option?.Quotation?.ShippingCost?.Price?.Currency?.toUpperCase() ?? 'GBP',country?.code?.toUpperCase(),2)}}</span>
                                                    <i class="fa-duotone fa-gas-pump" v-if="slotProps.data.option?.Quotation?.ItemizedCharges?.filter(x => x.Name === 'Fuel Surcharge')[0]?.Name !== null" style="padding-left:0.5rem; font-size: 1rem; --fa-primary-color: gray; --fa-secondary-color: darkred; --fa-secondary-opacity: 1.0;" v-tooltip.top="`With Fuel Surcharge`"></i>
                                                    <i class="fa-duotone fa-gas-pump-slash" v-else style="padding-left: 0.5rem; font-size: 1rem; --fa-primary-color: gray; --fa-secondary-color: darkred; --fa-secondary-opacity: 1.0;" v-tooltip.top="`Without Fuel Surcharge`"></i>
                                                </span>

                                            </spam>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </form-lookup>
            <form-lookup v-if="(shippingInfoOptionDS ?? []).length > 0 || (source?.shippingInfo?.product?.configuration?.RequireServiceOption ?? false)" :required="(source?.shippingInfo?.product?.configuration?.RequireServiceOption ?? false)" id="serviceProviderOptionAccountID" label="Option" :source="shippingInfoOptionDS ?? []" sourceType="array" :value="source?.shippingInfo.option?.serviceProviderOptionAccountID" :v$="v$" :textField="'Text'" :valueField="'Id'" @is-sync="onSelectOption($event,false)" :sorted="false" />
            <form-boolean v-if="(source?.shippingInfo?.product?.configuration?.GenerateCustomsDataFlag ?? false)" id="isShippingGenerateCustomsData" :value="source.shippingInfo.customization.generateCustomsData" type="toggle" label="Customs Data?" textPosition="left" @is-sync="{source.shippingInfo.customization.generateCustomsData = $event.value;}"></form-boolean>
            <form-input v-if="(source?.shippingInfo?.product?.configuration?.RequireAdditionalCode ?? false)" :required="(source?.shippingInfo?.product?.configuration?.RequireAdditionalCode ?? false)" id="serviceProviderAdditionalCodeID" label="Additional Code" :v$="v$" :value="source?.shippingInfo.additional?.code" @is-sync="source.shippingInfo.additional.code = $event.value;" />
            <form-lookup v-if="(source?.shippingInfo?.product?.configuration?.AllowAccessPoint ?? false) && !source.shippingInfo.accesspoint.alternateDelivery.enable" :showLoadingWhenEmpty="(source?.shippingInfo?.product?.configuration?.AllowAccessPoint ?? false) && processing.shippingInfo.accesspoint" :required="(source?.shippingInfo?.product?.configuration?.RequireAccessPoint ?? false)" id="serviceProviderAccessPointAccountID" label="Access Point Location" :source="shipingInfoAccessPointDS ?? []" :value="source?.shippingInfo.accesspoint?.code" :v$="v$" :textField="'FullDescription'" :valueField="'Code'" @is-sync="onSelectAccessPoint($event,false)" :sorted="false" />
            <form-input v-if="(source?.shippingInfo?.product?.configuration?.ReleaseCode ?? false)" id="serviceProviderReleaseCodeID" label="Parcel Release Code" :v$="v$" :value="source?.shippingInfo.release?.code" @is-sync="source.shippingInfo.release.code = $event.value;" />
            <form-boolean v-if="(source?.shippingInfo?.product?.configuration?.AllowAccessPoint ?? false)" id="isShippingAlternateAddress" :value="source.shippingInfo.accesspoint.alternateDelivery.enable" type="toggle" label="Use Alternate Address?" textPosition="left" @is-sync="{source.shippingInfo.accesspoint.alternateDelivery.enable = $event.value; source.shippingInfo.accesspoint.alternateDelivery.address.countryID = this.model?.shipTo_CountryID;}"></form-boolean>
        </div>
        <div :class="`p-col-12 p-md-${(columnNo === '2' ? '3' : columnNo)}`" v-if="source.shippingInfo.accesspoint.alternateDelivery.enable">
            <h4 v-if="source.shippingInfo.accesspoint.alternateDelivery.enable" style="padding: 0.25rem"><span>SHIPPING ALTERNATE ADDRESS</span></h4>
            <form-address id="shippingInfoAlternateAddress" :value="shippingInfoAlternateAddress" :useCompany="true" :showLookup="true" :disabled="!source.shippingInfo.accesspoint.alternateDelivery.enable" @is-sync="onPopulateAlternateAddress($event, false)" disabledView="perline" />
        </div>
        <div v-if="(model?.isReturnable ?? false)" :class="`p-col-12 p-md-${columnNo}`">
            <h4 style="padding: 0.25rem"><span>RETURN INFO</span></h4>
            <!--<form-lookup id="serviceGroupReturnID" label="Service Group" :required="false" :source="`services/facility/servicegroup/customer/id/${this.model?.customerID}`" sourceType="url" :value="source?.returnInfo?.serviceGroup.serviceGroupID" :v$="v$" :textField="'text'" :valueField="'id'" @is-sync="onSelectServiceGroup($event,true)" :sorted="false" />-->
            <form-lookup id="serviceProviderReturnAccountID" label="Carrier" :required="false" :showLoadingWhenEmpty="model.isReturnable && processing.returnInfo.provider" :source="returnInfoCarrierDS ?? []" sourceType="array" :value="source?.returnInfo.carrier?.serviceProviderAccountID" :v$="v$" :textField="'Text'" :valueField="'Id'" @is-sync="onSelectCarrier($event,true)" :sorted="false">
                <template #option="slotProps">
                    <div class="p-d-flex" style="width:100%">
                        <div class="picklist-list-detail">
                            <div class="p-d-flex" style="padding: 0;">
                                <div style="padding:0;width:100%">
                                    <div class="p-d-flex" style=" z-index: 100;">
                                        <div style="margin-right:5px">
                                            <img style="width: 35px; height: 20px; max-height: 20px; max-width: 35px; object-fit: contain; object-position: center;" :src="`${slotProps.data.option.Logo}`" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" />
                                        </div>
                                        <div class="p-d-flex p-jc-between" style="width:calc(100%)">
                                            <span><span class="p-code-text" style="font-weight: 600;">[{{ slotProps.data.option.CarrierCode}}] </span>{{ slotProps.data.option.CarrierName }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </form-lookup>
            <form-lookup id="serviceProviderReturnProductAccountID" label="Service" :showLoadingWhenEmpty="(source?.returnInfo.carrier?.serviceProviderAccountID > 0) && processing.returnInfo.product" :required="false" :source="returnInfoProductDS ?? []" sourceType="array" :value="source?.returnInfo?.product?.serviceProviderProductAccountID" :v$="v$" :textField="'Text'" :valueField="'Id'" @is-sync="onSelectProduct($event,true)" :sorted="false">
                <template #option="slotProps">
                    <div class="p-d-flex" style="width:100%">
                        <div class="picklist-list-detail">
                            <div class="p-d-flex" style="padding: 0;">
                                <div style="padding:0;width:100%">
                                    <div class="p-d-flex" style=" z-index: 100;">
                                        <div style="margin-right:5px">
                                            <img style="width: 35px; height: 20px; max-height: 20px; max-width: 35px; object-fit: contain; object-position: center;" :src="`${slotProps.data.option.Logo}`" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" />
                                        </div>
                                        <div class="p-d-flex p-jc-between" style="width:calc(100%)">
                                            <span><span class="p-code-text" style="font-weight: 600;">[{{ slotProps.data.option.Code}}] </span>{{ slotProps.data.option.Name }}</span>
                                            <spam class="p-jc-end">
                                                <span class="p-code-text">
                                                    <span class="product-badge status-instock p-code-text" style="margin-left:0.75rem">{{$filters.formatCurrency(slotProps.data.option?.Quotation?.ShippingCost?.Price?.Amount ?? 0.00,slotProps.data.option?.Quotation?.ShippingCost?.Price?.Currency?.toUpperCase() ?? 'GBP',country?.code?.toUpperCase(),2)}}</span>
                                                    <i class="fa-duotone fa-gas-pump" v-if="slotProps.data.option?.Quotation?.ItemizedCharges?.filter(x => x.Name === 'Fuel Surcharge')[0]?.Name !== null" style="padding-left:0.5rem; font-size: 1rem; --fa-primary-color: gray; --fa-secondary-color: darkred; --fa-secondary-opacity: 1.0;" v-tooltip.top="`With Fuel Surcharge`"></i>
                                                    <i class="fa-duotone fa-gas-pump-slash" v-else style="padding-left: 0.5rem; font-size: 1rem; --fa-primary-color: gray; --fa-secondary-color: darkred; --fa-secondary-opacity: 1.0;" v-tooltip.top="`Without Fuel Surcharge`"></i>
                                                </span>
                                            </spam>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </form-lookup>
            <form-lookup v-if="(returnInfoOptionDS ?? []).length > 0 || (source?.returnInfo?.product?.configuration?.RequireServiceOption ?? false)" :required="(source?.returnInfo?.product?.configuration?.RequireServiceOption ?? false)" id="serviceProviderReturnOptionAccountID" label="Option" :source="returnInfoOptionDS ?? []" sourceType="array" :value="source?.returnInfo.option?.serviceProviderOptionAccountID" :v$="v$" :textField="'Text'" :valueField="'Id'" @is-sync="onSelectOption($event,true)" :sorted="false" />
            <form-input v-if="(source?.returnInfo?.product?.configuration?.RequireAdditionalCode ?? false)" :required="(source?.returnInfo?.product?.configuration?.RequireAdditionalCode ?? false)" id="serviceProviderReturnAdditionalCodeID" label="Additional Code" :v$="v$" :value="source?.returnInfo.additional?.code" @is-sync="source.returnInfo.additional.code = $event.value;" />
            <!--<form-lookup v-if="(source?.returnInfo?.product?.configuration?.AllowAccessPoint ?? false)" :required="(source?.returnInfo?.product?.configuration?.RequireAccessPoint ?? false)" id="serviceProviderReturnAccessPointAccountID" label="Access Point Location" :source="returnInfoAccessPointDS ?? []" :value="source?.returnInfo.accesspoint?.code" :v$="v$" :textField="'FullDescription'" :valueField="'Code'" @is-sync="onSelectAccessPoint($event,true)" :sorted="false" />
            <form-input v-if="(source?.returnInfo?.product?.configuration?.ReleaseCode ?? false)" id="serviceProviderReturnReleaseCodeID" label="Parcel Release Code" :v$="v$" :value="source?.returnInfo.release?.code" @is-sync="source.returnInfo.release.code = $event.value;" />-->
            <form-boolean v-if="(source?.returnInfo?.product?.configuration?.AllowAccessPoint ?? false)" :showLoadingWhenEmpty="(source?.returnInfo?.product?.configuration?.AllowAccessPoint ?? false) && processing.returnInfo.accesspoint" id="isReturnAlternateAddress" :value="source.returnInfo.accesspoint.alternateDelivery.enable" type="toggle" label="Use Alternate Address?" textPosition="left" @is-sync="{source.returnInfo.accesspoint.alternateDelivery.enable = $event.value; source.returnInfo.accesspoint.alternateDelivery.address.countryID = this.model?.shipTo_CountryID;}"></form-boolean>
        </div>
        <div :class="`p-col-12 p-md-${(columnNo === '2' ? '3' :columnNo)}`" v-if="source.returnInfo.accesspoint.alternateDelivery.enable">
            <h4 v-if="source.returnInfo.accesspoint.alternateDelivery.enable" style="padding: 0.25rem"><span>RETURN ALTERNATE ADDRESS</span></h4>
            <form-address id="returnInfoAlternateAddress" :value="returnInfoAlternateAddress" :useCompany="true" :showLookup="true" :disabled="!source.returnInfo.accesspoint.alternateDelivery.enable" @is-sync="onPopulateAlternateAddress($event, true)" disabledView="perline" />
        </div>
    </div>
    <div>
        <div class="p-d-flex p-grid p-col-12 p-jc-end">
            <!--<pv-button  label="Get Services" icon="pi pi-search" class="p-button" @click="{getConsignmentService(false); getConsignmentService(true);}" /> <span style="width:0.5rem;"></span>-->
            <pv-button label="Allocate" icon="mdi mdi-shuffle-variant" class="p-button" @click="this.dialog.allocate.show = true" :disabled="!isValidAllocate" />
        </div>
    </div>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    import { mapGetters } from 'vuex';
    export default {
        name: 'GuideTemplate',
        props: {
            formData: { type: Object },
            showAllocate: {type:Boolean, default: false}
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                model: {  
                },
                carrier: {
                    code: null,
                    text: null,
                },
                packages: null,
                source: {
                    shippingInfo: {
                        serviceGroup: {
                            serviceGroupID: null,
                            code: null,
                            text: null
                        },
                        carrier: {
                            serviceProviderAccountID: null,
                            code: null,
                            text: null
                        },
                        product: {
                            serviceProviderProductAccountID: null,
                            code: null,
                            text: null,
                            configuration: null
                        },
                        option: {
                            serviceProviderOptionAccountID: null,
                            code: null,
                            text: null,
                        },
                        customization: {
                            generateCustomsData: false
                        },
                        accesspoint: {
                            serviceProviderAccessPointAccountID: null,
                            code: null,
                            text: null,
                            address: null,
                            alternateDelivery: {
                                enable: false,
                                address: {
                                    address1: null,
                                    address2: null,
                                    address3: null,
                                    city: null,
                                    state: null,
                                    postalCode: null,
                                    countryID: null,
                                    companyName: null,
                                    establishmentType: null,
                                    fullDescription: null
                                },
                                contact: {

                                }
                            }
                        },
                        release: {
                            code:null
                        },
                        additional: {
                            code: null
                        },
                        serviceGroupDS: {
                            result: []
                        },
                        providers: {
                            isBinded: false,
                            result:[]
                        },
                        services: {
                            isBinded: false,
                            result: [],
                            
                        },
                        productServices: [],
                        accesspointLocations: [],
                    },
                    returnInfo: {
                        serviceGroup: {
                            serviceGroupID: null,
                            code: null,
                            text: null
                        },
                        carrier: {
                            serviceProviderAccountID: null,
                            code: null,
                            text: null
                        },
                        product: {
                            serviceProviderProductAccountID: null,
                            code: null,
                            text: null,
                            configuration: null
                        },
                        option: {
                            serviceProviderOptionAccountID: null,
                            code: null,
                            text: null,
                        },
                        accesspoint: {
                            serviceProviderAccessPointAccountID: null,
                            code: null,
                            text: null,
                            record: null,
                            alternateDelivery: {
                                enable: false,
                                address: {
                                    address1: null,
                                    address2: null,
                                    address3: null,
                                    city: null,
                                    state: null,
                                    postalCode: null,
                                    countryID: null,
                                    companyName: null,
                                    establishmentType: null,
                                    fullDescription: null
                                },
                                contact: {

                                }
                            }
                            
                        },
                        release: {
                            code: null
                        },
                        additional: {
                            code: null
                        },
                        providers: {
                            isBinded: false,
                            result: []
                        },
                        services: {
                            isBinded: false,
                            result: [],
                        },
                        productServices: [],
                        options: [],
                        accesspointLocations: [],
                    }
                    ,companyInfo: {
                        generalSetting: null,
                        accountCustomer: []
                    }
                },
                dialog: {
                    allocate: {
                        show: false
                    }
                },
                action: {
                    processing: false
                },
                processing: {
                    shipping: false,
                    return: false,
                    shippingInfo: {
                        servicegroup: false,
                        provider: false,
                        product: false,
                        accesspoint: false
                    },
                    returnInfo: {
                        provider: false,
                        product: false,
                        accesspoint: false

                    },
                    companyInfo: {
                        generalSetting: false,
                        accountCustomer: false
                    }

                },

            }
        },
        validations() {
            return {
                model: {
                }
            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            'formData': {
                handler() {
                    this.$nextTick(() => {
                        this.model = this.$props.formData;
                    });
                }, deep: true
            },
            'formData.packages': {
                handler() {
                    this.$nextTick(() => {
                        this.getAccountCustomer();
                        this.getServiceGroup().then(() => {
                            if (this.source.shippingInfo.serviceGroup.serviceGroupID == null) {
                                this.getSetting();
                            }
                        });
                        this.source.shippingInfo.providers.isBinded = false;
                        this.source.returnInfo.providers.isBinded = false;
                        if (((this.model?.packages?.length ?? 0) === 0) && ((this.packages?.length ?? 0) === 0)) {
                            this.getPackages();
                        }
                        this.getConsignmentProvider(false).then(() => {
                            if ((this.model.packages?.length ?? 0) > 0)
                                this.source.shippingInfo.providers.isBinded = true;
                        });

                        if (this.model.isReturnable) {
                            this.getConsignmentProvider(true).then(() => {
                                if ((this.model.packages?.length ?? 0) > 0)
                                    this.source.returnInfo.providers.isBinded = true;
                            });
                        }
                    });
                }, deep: true
            },
            'formData.isReturnable': {
                handler() {
                    this.$nextTick(() => {
                        this.source.shippingInfo.providers.isBinded = false;
                        this.source.returnInfo.providers.isBinded = false;
                            this.getConsignmentProvider(true).then(() => {
                                if ((this.model.packages?.length ?? 0) > 0)
                                    this.source.shippingInfo.providers.isBinded = true;
                            });
                    });
                }, deep: true
            },
            'source.returnInfo.carrier.code': {
                handler() {
                    this.$nextTick(() => {
                        if (this.source.returnInfo.carrier.code != null) {
                            this.getConsignmentService(true).then(() => {
                                this.source.returnInfo.services.isBinded = true;
                            });

                        }
                    });
                }, deep: true
            },
            'source.returnInfo.product.code': {
                handler() {
                    this.$nextTick(() => {
                        this.source.returnInfo.accesspointLocations = [];
                        if (this.source.returnInfo.product.code != null) {
                            this.getAccessPoints(true);
                        }
                    });
                }, deep: true
            },
            'source.shippingInfo.carrier.code': {
                handler() {
                    this.$nextTick(() => {
                        if (this.source.shippingInfo.carrier.code != null) {
                            this.getConsignmentService(false).then(() => {
                                this.source.shippingInfo.services.isBinded = true;
                            });

                        }
                    });
                }, deep: true
            },
            'source.shippingInfo.product.code': {
                handler() {
                    this.$nextTick(() => {
                        this.source.shippingInfo.accesspointLocations = [];
                        if (this.source.shippingInfo.product.code != null) {
                            this.getAccessPoints(false);
                        }
                    });
                }, deep: true
            },
            'model.request_CollectionDateValue': {
                handler() {
                    this.$nextTick(() => {
                        if (this?.model?.request_CollectionDate !== null) {
                            this.$nextTick(() => {
                                this.getConsignmentProvider(false);
                            });
                            this.$nextTick(() => {
                                if ((this.model.isReturnable ?? false))
                                    this.$nextTick(() => {
                                        this.getConsignmentProvider(true);
                                    });
                            });
                        }
                    });
                }, deep: true
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            companySettingRecord() {
                return this.source.companyInfo.generalSetting;
                //return this.$store.getters["companygeneralsetting/record"];
            },
            sessionKeys() { return this.$store.getters["auth/session"]; },
            scopeCustomerDS() {
                return this.$config.common.$customerFiltered(this.user?.environmentID ?? -100, this.$vm.$appSession.warehouse.id ?? this.user.warehouseID, this.user);
            },
            scopeWarehouseDS() {
                return this.$config.common.$warehouse(this.user?.environmentID, (this.session?.warehouseID ?? this.$vm.$appSession?.warehouse?.id), this.user) ?? [];
            },
            warehouse() {
                return this.scopeWarehouseDS.filter(x => x.id == this.model.warehouseID)[0];
            },
            OriginISO2() {
                return this.$vm.$countries.filter(x => x.id === this.model?.shipFrom_CountryID)[0]?.code;
            },
            DestinationISO2() {
                return this.$vm.$countries.filter(x => x.id === this.model?.shipTo_CountryID)[0]?.code;
            },
            isDomestic() {
                return this.$filters.isDomestic(this.OriginISO2, this.model?.shipFrom_PostalCode ?? '', this.DestinationISO2, this.model?.shipTo_PostalCode ?? '');
            },
            columnNo() {
                let isActive = 0;
                if ((this.model?.isReturnable ?? false))
                    isActive++;
                if ((this.source?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false))
                    isActive++;
                if ((this.source?.returnInfo?.accesspoint?.alternateDelivery?.enable ?? false))
                    isActive++;
                return (isActive > 1) ? ((isActive > 2) ? "2" : "3"): "4";
            },
            isValidAllocate() {
                return (
                    (((this.source?.shippingInfo.carrier?.serviceProviderAccountID ?? 0) == 0) ? ((this.source?.shippingInfo?.serviceGroup.serviceGroupID ?? 0) > 0) : false) ||
                    ((this.source?.shippingInfo.carrier?.serviceProviderAccountID ?? 0) > 0 ? (((this.source?.shippingInfo.carrier?.serviceProviderAccountID ?? 0) > 0) && ((this.source?.shippingInfo?.product?.serviceProviderProductAccountID ?? 0) > 0) && ((this.source?.shippingInfo?.product?.configuration?.RequireServiceOption ?? false) ? ((this.source?.shippingInfo.option?.serviceProviderOptionAccountID ?? 0) > 0) : true) && ((this.source?.shippingInfo?.product?.configuration?.RequireAdditionalCode ?? false) ? ((this.source?.shippingInfo.additional?.code ?? "").length > 0) : true)) : false)
                )
                    &&
                (
                    (this.model?.isReturnable ?? false) ?
                        (
                            (this.source?.returnInfo.carrier?.serviceProviderAccountID ?? 0) > 0 ?
                                (((this.source?.returnInfo.carrier?.serviceProviderAccountID ?? 0) > 0) && ((this.source?.returnInfo?.product?.serviceProviderProductAccountID ?? 0) > 0) && ((this.source?.returnInfo?.product?.configuration?.RequireServiceOption ?? false) ? ((this.source?.returnInfo.option?.serviceProviderOptionAccountID ?? 0) > 0) : true) && ((this.source?.returnInfo?.product?.configuration?.RequireAdditionalCode ?? false) ? ((this.source?.returnInfo.additional?.code ?? "").length > 0) : true))
                            : true)
                  : true

                )
                    ;
                //((this.source?.shippingInfo.carrier?.serviceProviderAccountID ?? 0) == 0 ? ((this.source?.shippingInfo?.serviceGroup.serviceGroupID ?? 0) > 0) : false)
                //    ||
                //    (this.source?.shippingInfo.carrier?.serviceProviderAccountID ?? 0) > 0 ?
                //        (((this.source?.shippingInfo.carrier?.serviceProviderAccountID ?? 0) > 0) && ((this.source?.shippingInfo?.product?.serviceProviderProductAccountID ?? 0) > 0)) : false)
                //    ;
            },
            //SHIPPING INFO DROPDOWN OBJECTS
            shippingInfoServiceGroupDS() {
                return this.source.shippingInfo.serviceGroupDS.result ?? [];
            },
            shippingInfoCarrierDS() {
                return this.source.shippingInfo.providers.result ?? [];
            },
            shippingInfoProductDS() {
                if (this.ShippingCarrierFilter !== null)
                    return this.source.shippingInfo.services.result.filter(x => x.CarrierCode === this.ShippingCarrierFilter)[0]?.AvailableServices ?? [];
                let availalbleService = [];
                (this.source.shippingInfo.services.result ?? []).forEach(x => {
                    (x.AvailableServices ?? []).forEach(av => {
                        availalbleService.push(av);
                    })
                });
                return availalbleService;
            },
            shipingInfoAccessPointDS() {
                return this.source.shippingInfo.accesspointLocations ?? [];
            },
            shippingInfoOptionDS() {
                if (this.ShippingCarrierFilter !== null && this.ShippingProductFilter !== null)
                    return this.source.shippingInfo.services.result.filter(x => x.CarrierCode === this.ShippingCarrierFilter)[0]?.AvailableOptions ?? [];
                return [];
            },
            shippingInfoAlternateAddress() {
                return {
                    companyName: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.companyName,
                    address1: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address1,
                    address2: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address2,
                    address3: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address3,
                    city: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.city,
                    state: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address.state ?? "",
                    postalCode: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.postalCode,
                    countryID: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.countryID,
                    establishmentType: 200

                };
               
            },

            // RETURN INFO DROPDWON OBJECTS
            returnInfoCarrierDS() {
                return this.source.returnInfo.providers.result ?? [];
            },
            returnInfoProductDS() {
                if (this.ReturnCarrierFilter !== null)
                    return this.source.returnInfo.services.result.filter(x => x.CarrierCode === this.ReturnCarrierFilter)[0]?.AvailableServices ?? [];
                let availalbleService = [];
                (this.source.returnInfo.services.result ?? []).forEach(x => {
                    (x.AvailableServices ?? []).forEach(av => {
                        availalbleService.push(av);
                    })
                });
                return availalbleService;
            },
            returnInfoAccessPointDS() {
                return this.source.returnInfo.accesspointLocations ?? [];
            },
            returnInfoOptionDS() {
                if (this.ReturnCarrierFilter !== null && this.ReturnProductFilter !== null)
                    return this.source.returnInfo.services.result.filter(x => x.CarrierCode === this.ReturnCarrierFilter)[0]?.AvailableOptions ?? [];
                return []
                
                //let availableOption = [];
                //(this.source.returnInfo.services.result ?? []).forEach(x => {
                //    (x.AvailableOptions ?? []).forEach(av => {
                //        availableOption.push(av);
                //    })
                //});
                //return availableOption;
            },
            returnInfoAlternateAddress() {
                return {
                    companyName: this.source?.returnInfo?.accesspoint?.alternateDelivery?.address?.companyName,
                    address1: this.source?.returnInfo?.accesspoint?.alternateDelivery?.address?.address1,
                    address2: this.source?.returnInfo?.accesspoint?.alternateDelivery?.address?.address2,
                    address3: this.source?.returnInfo?.accesspoint?.alternateDelivery?.address?.address3,
                    city: this.source?.returnInfo?.accesspoint?.alternateDelivery?.address?.city,
                    state: this.source?.returnInfo?.accesspoint?.alternateDelivery?.address.state ?? "",
                    postalCode: this.source?.returnInfo?.accesspoint?.alternateDelivery?.address?.postalCode,
                    countryID: this.source?.returnInfo?.accesspoint?.alternateDelivery?.address?.countryID,
                    establishmentType: 200
                };

            },
            customer() {
                return this.scopeCustomerDS.filter(x => x.id == (this.model?.customerID ?? this.$props?.formData?.customerID ?? this.$props?.recordformData?.customerID))[0];
            },
            country() {
                return this.$vm.$countries.filter(x => x.id === this.$props.formData?.shipFrom_CountryID)[0];
            },
            apiData() {
                let packages = [];
                let _self = this;
                (this?.model?.packages ?? this.packages ?? []).forEach((x) => {
                    packages.push({
                        Weight: {
                            GrossWeight: x.weight
                        },
                        WeightUnit: _self.$vm.$enums['weightunit'].filter((xz) => xz.id == _self.model.weightUnit)[0]?.text?.toUpperCase(),
                        Dimension: {
                            Length: x.length,
                            Height: x.height,
                            Width: x.width
                        },
                        LengthUnit: _self.$vm.$enums['lengthunit'].filter((xz) => xz.id == _self.model?.weightUnit)[0]?.text?.toUpperCase(),
                        CurrencyCode: _self.$vm.$currencies.filter((xz) => xz.id === _self.model.currencyID)[0]?.code?.toUpperCase(),
                        Declaration: {
                            Declared: x.consignmentValue
                        }
                    });

                });
                return {
                    Request: {
                        CustomerCode: this.model?.customerCode,
                        Carrier: ((this?.source.shippingInfo?.carrier?.code?.length ?? 0)) > 0 ? {
                            CarrierCode: this.source.shippingInfo.carrier.code
                        } : null,
                        Order: {
                            ShipFrom: {
                                City: this.model?.shipFrom_City,
                                State: this.model?.shipFrom_State,
                                PostalCode: this.model?.shipFrom_PostalCode,
                                Country: this.$vm.$countries.filter(x => x.id == this.model?.shipFrom_CountryID)[0]?.code
                            },
                            ShipTo: {
                                City: this.model?.shipTo_City,
                                State: this.model?.shipTo_State,
                                PostalCode: this.model?.shipTo_PostalCode,
                                Country: this.$vm.$countries.filter(x => x.id == this.model?.shipTo_CountryID)[0]?.code
                            },
                            IsDocument: false,
                            CollectionDate: this.$filters.formatDateWithFormat(this.model.request_CollectionDate ?? new Date())
                        },
                        Packages: packages
                    }
                }
            },
            apiAccessPoint() {
                return {
                    Request: {
                        CustomerCode: this.model?.customerCode,
                        ProviderCode: this.model?.carrierCode,
                        MaxDistance: 100,
                        MaxResult: 100,
                        DistanceUnit: "MI",
                        Address: {
                            City: this.model?.shipTo_City,
                            State: this.model?.shipTo_State,
                            PostalCode: this.model?.shipTo_PostalCode,
                            Country: this.$vm.$countries.filter(x => x.id === this.model?.shipTo_CountryID)[0]?.code
                        }
                    }
                };
            },
            apiAllocate() {
                return {
                    Request: {
                        TransactionID: this.model.code,
                        AllocationDetail: {
                            ServiceGroupCode: this.source?.shippingInfo?.serviceGroup?.code ?? null,
                            CarrierDetail: (((this.source?.shippingInfo?.carrier?.code ?? null) != null) && ((this.source?.shippingInfo?.product?.code ?? null) != null)) ?  {
                                //CarrierCode: this.model.carrierCode,
                                //ProductCode: this.model.productCode,
                                //ReturnProductCode: this.model.returnProductCode,
                                //CarrierServiceCode: this.model.carrierAPICode
                                CarrierCode: this.source?.shippingInfo?.carrier?.code,
                                ProductCode: this.source?.shippingInfo?.product?.code,
                                //ReturnProductCode: this.model.returnProductCode,
                                //CarrierServiceCode: this.model.carrierAPICode
                            } : null,
                            //AccessPointLocationCode: (this.model.accessPointLocationCode !== null && this.model.accessPointLocationCode !== "" && this.model.accessPointLocationCode !== undefined) ? this.model.accessPointLocationCode : null,
                            //ParcelReleaseCode: (this.model.parcelReleaseCode !== null && this.model.parcelReleaseCode !== "" && this.model.parcelReleaseCode !== undefined) ? this.model.parcelReleaseCode : null,
                            //PushReturnEntry: this?.model?.pushReturns ?? false,
                            CollectionDetail: {
                                CollectionDate: this.$filters.formatDateWithFormat(this.model.request_CollectionDate, "YYYY-MM-DD"),
                                ReadyTime: this.$filters.formatDateWithFormat(this.model.request_ReadyTime, "HH:mm"),
                                CollectionTime: this.$filters.formatDateWithFormat(this.model.request_ReadyTime, "HH:mm"),
                                ClosingTime: this.$filters.formatDateWithFormat(this.model.request_ClosingTime, "HH:mm"),
                                CutOffTime: "15:00"
                            },
                            Document: {
                                Invoice: {
                                    Type: "COMMERCIAL",
                                    Date: this.$filters.formatDateWithFormat((this.model.invoiceDate != null ? this.model.invoiceDate : new Date()), "YYYY-MM-DD"),
                                    Reference: this.model.invoiceNumber,
                                    Notes: this.model.invoiceNote
                                }
                            }
                        }
                    }
                };
               
            },
            consignmentRecord() { return this.$store.getters["consignment/record"]; },
            ReturnCarrierFilter() { return this.source.returnInfo.carrier.code ?? null },
            ReturnProductFilter() { return this.source.returnInfo.product.code ?? null },
            ShippingCarrierFilter() { return this.source.shippingInfo.carrier.code ?? null },
            ShippingProductFilter() { return this.source.shippingInfo.product.code ?? null },
            accespointAddress() {
                let _address = this.source?.shippingInfo?.accesspoint?.record?.Address;
                let _fullAddress = null;
                let _accesspointAddress = [];
                if (_address != null && _address !== undefined) {
                    _fullAddress = (_address?.Address1 ?? "") + " " + (_address?.Address2 ?? "") + (_address?.Address3 ?? "");
                    // Loop through the input string with a step size of 35
                    if (_fullAddress) {
                        for (let i = 0; i < _fullAddress.length; i += 35) {
                            // Use substring to get the next 35 characters and push it to the result array
                            _accesspointAddress.push(_fullAddress.substring(i, i + 35));
                        }
                    }
                }
                return _accesspointAddress;
            },
            apiAllocateConsignment() {
                let _accesspointAddress = this.accespointAddress;
                return {
                    Source: "WEB",
                    Request: {
                        TransactionID: this?.model?.code,
                        ReferenceOnly: true,
                        BookingForm: {
                            ShippingInfo: {
                                PickupInfo: {
                                    Type: this.$vm.$enums['pickuptype'].filter(x => x.id === this.model?.pickupType ?? -100)[0]?.code ?? "DROPOFF",
                                    Date: this.$filters.formatDateWithFormat(this.model.request_CollectionDate, "YYYY-MM-DD"),
                                    ReadyTime: this.$filters.formatDateWithFormat(this.model.request_ReadyTime, "HH:mm"),
                                    ClosingTime: this.$filters.formatDateWithFormat(this.model.request_ClosingTime, "HH:mm"),
                                },
                                ServiceGroup: this.source?.shippingInfo?.serviceGroup?.code,
                                ProviderInfo:  this.source?.shippingInfo?.carrier?.code !== null ? {
                                    Carrier: this.source?.shippingInfo?.carrier?.code,
                                    Service: this.source?.shippingInfo?.product?.code,
                                    Option: this.source?.shippingInfo?.option?.code
                                } : null,
                                AccessPointInfo: (this.source?.shippingInfo?.accesspoint?.code || (this.source?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false)) ? {
                                    Code: !(this.source?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false) ? this.source?.shippingInfo?.accesspoint?.code : null,
                                    ReleaseCode: this.source?.shippingInfo?.release?.code,
                                    Address: !(this.source?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false) ? {
                                        Company: this.source?.shippingInfo?.accesspoint?.record?.CompanyName,
                                        //Address1: this.source?.shippingInfo?.accesspoint?.record?.Address?.Address1,
                                        //Address2: this.source?.shippingInfo?.accesspoint?.record?.Address?.Address2,
                                        //Address3: this.source?.shippingInfo?.accesspoint?.record?.Address?.Address3,
                                        Address1: _accesspointAddress[0],
                                        Address2: _accesspointAddress.length > 1 ? _accesspointAddress[1] : null,
                                        Address3: _accesspointAddress.length > 2 ? _accesspointAddress[2] : null,
                                        City: this.source?.shippingInfo?.accesspoint?.record?.Address?.City,
                                        State: null,
                                        PostalCode: this.source?.shippingInfo?.accesspoint?.record?.Address?.PostalCode,
                                        Country: this.source?.shippingInfo?.accesspoint?.record?.Address?.Country
                                    } : {
                                            Company: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.companyName,
                                            Address1: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address1,
                                            Address2: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address2,
                                            Address3: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address3,
                                            City: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.city,
                                            State: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address.state ?? "",
                                            PostalCode: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.postalCode,
                                            Country: this.$vm.$countries.filter(x => x.id === this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.countryID)[0]?.code
                                        }
                                } : null,
                                GenerateCustomsData: (this.source?.shippingInfo?.product?.configuration?.GenerateCustomsDataFlag ?? false) ? (this.source?.shippingInfo?.customization?.generateCustomsData ? "Y" : "N") : null,
                                AdditionalCode: (this.source?.shippingInfo?.product?.configuration?.RequireAdditionalCode ?? false) ?  this.source?.shippingInfo?.additional?.code : null
                            },
                            IsReturnable: (this.model?.isReturnable ?? false),
                            ReturnsInfo: ((this.model?.isReturnable ?? false) && (this.source?.returnInfo?.carrier?.code !== null)) ? {
                                ProviderInfo: this.source?.returnInfo?.carrier?.code !== null ?{
                                    Carrier: this.source?.returnInfo?.carrier?.code,
                                    Service: this.source?.returnInfo?.product?.code,
                                    Option: this.source?.returnInfo?.option?.code,
                                } : null,
                                Address: null,
                                AccessPointInfo: null,
                                //AccessPointInfo: this.source?.returnInfo?.accesspoint?.code ? {
                                //    Code: this.source?.returnInfo?.accesspoint?.code,
                                //    ReleaseCode: this.source?.returnInfo?.release?.code,
                                //    Address: null
                                //} : null,
                                AdditionalCode: (this.source?.returnInfo?.product?.configuration?.RequireAdditionalCode ?? false) ? this.source?.returnInfo?.additional?.code : null
                            } : null
                        },
                        GenerateForm: !this.isDomestic ? {
                            Invoice: true,
                            Customs: [
                                {
                                    Type: "CN22",
                                    Customs: "CONSIGNMENT"
                                }
                            ]
                        } : null,
                    }
                }
            },
            apiServiceRequest() {
                let packages = [];
                // let _content = [];
                (this?.model?.packages ?? this.packages ?? []).forEach((_package) => {
                    packages.push({
                        Weight: _package?.weight ?? 0.00,
                        Declaration: {
                            DeclaredValue: _package?.consignmentValue ?? 0.00,
                            CustomsValue: _package?.customsValue ?? 0.00,
                            DiscountValue: _package?.discountValue ?? 0.00,
                            InsuranceValue: _package?.insuranceValue ?? 0.00,
                            DutyPaidValue: _package?.dutyPaidValue ?? 0.00,
                            TaxValue: _package?.taxValue ?? 0.00,
                        },
                        Dimension: (((_package?.length ?? 0) > 0) || ((_package?.width ?? 0) > 0) || ((_package?.height ?? 0) > 0)) ? {
                            Length: (_package?.length ?? 0),
                            Width: (_package?.width ?? 0),
                            Height: (_package?.height ?? 0)
                        } : null,
                    });
                });
                var senderCountry = (this.$vm.$countries.filter(x => x.id === this.model?.shipFrom_CountryID ?? -100)[0]);
                var recipientCountry = (this.$vm.$countries.filter(x => x.id === this.model?.shipTo_CountryID ?? -100)[0]);

                return {
                    Source: "WEB",
                    Request: {
                        Customer: this.scopeCustomerDS.filter(x => x.id === this.model?.customerID)[0]?.code,
                        Warehouse: this.warehouse?.code,
                        ShippingType: "SHIPPING",
                        Consignment: {
                            Order: {
                                Sender: {
                                    City: this.model?.shipFrom_City ?? "",
                                    State: (senderCountry?.stateRequired ?? false) ? ((senderCountry?.states ?? []).filter(x => x.name.toLowerCase().replace(/^a-zA-Z0-9 ]/g, '') == (this.model?.shipFrom_State ?? "").toLowerCase().replace(/^a-zA-Z0-9 ]/g, ''))[0]?.code ?? (this.model?.shipFrom_State ?? "")) : (this.model?.shipFrom_State ?? ""),
                                    PostalCode: this.model.shipFrom_PostalCode ?? "",
                                    Country: this.$vm.$countries.filter(x => x.id === this.model?.shipFrom_CountryID ?? -100)[0]?.code ?? "",
                                    EstablishmentType: this.$vm.$enums['establishmenttype'].filter(x => x.id === this.model?.shipFrom_EstablishmentType ?? -100)[0]?.code ?? "BUSINESS"
                                },
                                Recipient: {
                                    City: this.model?.shipTo_City ?? "",
                                    State: (recipientCountry?.stateRequired ?? false) ? ((recipientCountry?.states ?? []).filter(x => x.name.toLowerCase().replace(/^a-zA-Z0-9 ]/g, '') == (this.model?.shipTo_State ?? "").toLowerCase().replace(/^a-zA-Z0-9 ]/g, ''))[0]?.code ?? (this.model?.shipTo_State ?? "")): (this.model?.shipTo_State ?? ""),
                                    PostalCode: this.model.shipTo_PostalCode ?? "",
                                    Country: this.$vm.$countries.filter(x => x.id === this.model?.shipTo_CountryID ?? -100)[0]?.code ?? "",
                                    EstablishmentType: this.$vm.$enums['establishmenttype'].filter(x => x.id === this.model?.shipTo_EstablishmentType ?? -100)[0]?.code ?? "BUSINESS"
                                },
                                PackageType: this.$vm.$enums['serviceproviderproductpackagingtype'].filter(x => x.id === this.model?.packageType ?? -100)[0]?.code ?? "PACKAGE",
                                CollectionDate: this.$filters.formatDateWithFormat(this.model?.request_CollectionDate ?? new Date(), "YYYY-MM-DD"),
                                WeightUnit: this.$vm.$enums['weightunit'].filter(x => x.id === this.model?.weightUnit ?? -100)[0]?.code ?? "KG",
                                DimensionUnit: this.$vm.$enums['lengthunit'].filter(x => x.id === this.model?.dimensionUnit ?? -100)[0]?.code ?? "CM",
                                Currency: this.$vm.$currencies.filter(x => x.id === this.model?.currencyID ?? -100)[0]?.code ?? "GBP",
                            },
                            Packages: packages
                        },
                        Provider: {
                            Carrier: null,
                            Service: null
                        }
                    }
                }
            },
            apiProviderRequest() {
                return {
                    Source: "WEB",
                    Request: {
                        Customer: this.scopeCustomerDS.filter(x => x.id === this.model?.customerID)[0]?.code,
                        Warehouse: this.warehouse?.code,
                        ShippingType: "SHIPPING",
                        TradeType: this.$vm.$enums['tradetype'].filter(x => x.id === this.model?.tradeType ?? -100)[0]?.code ?? "EXPORT"
                    }
                }
            },
            apiAccessPointRequest() {
                var recipientCountry = (this.$vm.$countries.filter(x => x.id === this.model?.shipTo_CountryID ?? -100)[0]);
                return {
                    Request: {
                        Customer: this.scopeCustomerDS.filter(x => x.id === this.model?.customerID)[0]?.code,
                        Carrier: "",
                        Address: {
                            Company: this.model?.shipTo_Company ?? "",
                            Address1: this.model?.shipTo_Address1 ?? "",
                            Address2: this.model?.shipTo_Address2 ?? "",
                            Address3: this.model?.shipTo_Address3 ?? "",
                            City: this.model?.shipTo_City ?? "",
                            State: (recipientCountry?.stateRequired ?? false) ? ((recipientCountry?.states ?? []).filter(x => x.name.toLowerCase().replace(/^a-zA-Z0-9 ]/g, '') == (this.model?.shipTo_State ?? "").toLowerCase().replace(/^a-zA-Z0-9 ]/g, ''))[0]?.code ?? (this.model?.shipTo_State ?? "")) : (this.model?.shipTo_State ?? ""),
                            PostalCode: this.model.shipTo_PostalCode ?? "",
                            Country: this.$vm.$countries.filter(x => x.id === this.model?.shipTo_CountryID ?? -100)[0]?.code ?? ""
                        },
                        MaxDistance: 150,
                        DistanceUnit: "KM",
                        MaxResult: 100
                    }
                }
            }
        },
        methods: {
            getSetting(mask) {
                if ((mask ?? this.customer?.mask) !== undefined && (mask ?? this.customer?.mask) !== null) {
                    let isProcessing = false;
                    isProcessing = (this.processing.companyInfo.generalSetting ?? false);
                    if (!isProcessing) {
                        this.processing.companyInfo.generalSetting = true;
                        this.$nextTick(() => {
                            this.$axios
                                .get(this.$config.config.endpoint.api + `/services/settings/companygeneralsetting/ByCompany/${(mask ?? this?.customer?.mask)}`.replaceAll("//", "/"))
                                .then((response) => {
                                    this.source.companyInfo.generalSetting = response?.data?.result ?? response?.result ?? null;
                                    this.processing.companyInfo.generalSetting = false;
                                    this.manageDefaultSetting();
                                })
                                .catch((ex) => {
                                    this.errors = ex.data;
                                    this.processing.companyInfo.generalSetting = false;
                                });
                        });
                    }
                }   
            },
            getAccountCustomer() {
                let isProcessing = false;
                isProcessing = (this.processing.accountCustomer ?? false);
                if (!isProcessing) {
                    this.$nextTick(() => {
                        if ((this.customer?.id ?? 0) != 0) {
                            this.processing.accountCustomer = true;
                            this.$axios.get(this.$config.config.endpoint.api + `/services/admin/serviceprovideraccountcustomer/handlerbyaccount/id/${this.customer?.id}`.replaceAll("//", "/")).then((result) => {
                                this.source.companyInfo.accountCustomer = result?.data?.result ?? result?.result ?? [];
                                this.processing.accountCustomer = false;
                            });
                        }
                        else {
                            this.processing.accountCustomer = false;
                        }
                    });
                }
            },
            async getEntityRecord( mask) { return await this.$store.dispatch("consignment/getRecord", mask); },
            async allocateOLD() {
                return await this.$axios.post(this.$config.config.endpoint.polaris + `/consignment/` + this.model.code + `/allocate`.replaceAll("//", "/"), this.apiAllocate);
            },
            async allocate() {
                return await this.$axios.post(this.$config.config.endpoint.api + `/api/polaris/consignment/` + this?.model?.code + `/allocate`.replaceAll("//", "/"), this.apiAllocateConsignment)
            },
            async consignmentService(shippingType) {
                if (shippingType === null || shippingType === undefined || shippingType === '')
                    shippingType = "SHIPPING";
                let apiRequest = this.apiServiceRequest;
                apiRequest.Request.ShippingType = shippingType;
                return await this.$axios.post(this.$config.config.endpoint.api + `/api/polaris/consignment/service`.replaceAll("//", "/"), apiRequest)
            },
            onCollectionDate(e) {
                this.model.request_CollectionDateValue = e.value;
                this.model.request_CollectionDate = (e.record.data ?? new Date());
                //this.$nextTick(() => {
                //    this.getConsignmentService(false);
                //    if (!(this.model.isReturn ?? false))
                //        this.getConsignmentService(true);
                //});
            },
            onReadyTime(e) {
                this.model.request_ReadyTimeValue = e.value;
                this.model.request_ReadyTime = (e.record.data ?? new Date());
            },
            onClosingTime(e) {
                this.model.request_ClosingTimeValue = e.value;
                this.model.request_ClosingTime = (e.record.data ?? new Date());
            },

             manageDefaultSetting() {
                this.$nextTick(() => {
                    let _setting = this.companySettingRecord;
                    if (_setting != null) {
                        if (this.source.shippingInfo.serviceGroup.serviceGroupID == null)
                            this.source.shippingInfo.serviceGroup.serviceGroupID = _setting.default_ServiceGroupID;
                        //this.source.shippingInfo.provider.serviceProviderAccountID = _setting.default_AccountCustomerID ?? null;
                        //this.model.serviceGroupID = _setting.default_ServiceGroupID;
                        //this.model.serviceProviderAccountID = _setting.default_AccountCustomerID;
                    }
                });

            },
             getPackages() {
                if ((this.model?.packages ?? this.packages ?? []).length === 0 && (this.$props.formData?.id ?? 0) > 0) {
                    this.$axios.get(this.$config.config.endpoint.api + `/services/tms/package/consignment/id/` + this.$props.formData?.id + ''.replaceAll("//", "/"))
                        .then((response) => {
                            this.packages = (response?.data?.result ?? null);
                        })
                        .catch(() => {
                            //this.activity.serviceloading = false;
                            //this.activity.loading = false;
                        });
                }
            },
            async getServiceGroup() {
                let isProcessing = false;
                isProcessing = (this.processing.shippingInfo.servicegroup ?? false);
                if (!isProcessing) {
                    this.processing.shippingInfo.servicegroup = true;
                    this.$nextTick(() => {
                        if (this.model?.customerID) {
                            this.$axios.get(this.$config.config.endpoint.api + `/services/facility/servicegroup/customer/id/${this.model?.customerID}`.replaceAll("//", "/")).then((result) => {
                                this.source.shippingInfo.serviceGroupDS.result = result?.data?.result ?? [];
                                this.processing.shippingInfo.servicegroup = false;
                                
                                this.$nextTick(() => {
                                    if ((this.companySettingRecord?.id ?? 0) == 0)
                                        this.getSetting()
                                    this.manageDefaultSetting();
                                });
                            });
                        }
                        else {
                            this.processing.shippingInfo.servicegroup = false;
                        }
                    });
                }
            },
            async getConsignmentProvider(isReturn) {
                let isProcessing = false;
                isProcessing = !isReturn ? (this.processing.shippingInfo.provider ?? false) : (this.processing.returnInfo.provider ?? false);
                if (!isProcessing) {
                    if (!isReturn) {
                        this.source.shippingInfo.services.result = [];
                        this.processing.shippingInfo.provider = true;
                    }
                    else {
                        this.source.returnInfo.services.result = [];
                        this.processing.returnInfo.provider = true;
                    }
                    this.$nextTick(() => {
                        if (isReturn === null || isReturn === undefined)
                            isReturn = false
                        let apiRequest = this.apiServiceRequest;
                        if ((apiRequest?.Request?.Consignment?.Packages?.length ?? 0) > 0) {
                            let shippingType = isReturn ? 'RETURNS' : 'SHIPPING';
                            let apiRequest = this.apiProviderRequest;
                            apiRequest.Request.ShippingType = shippingType;
                            this.$axios.post(this.$config.config.endpoint.api + `/api/polaris/consignment/serviceprovider`.replaceAll("//", "/"), apiRequest).then((result) => {
                                var warningErrors = "";
                                if (result.data.Errors !== null && result.data.Errors !== undefined) {
                                    (result.data.Errors ?? []).forEach((x) => {
                                        warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                    });
                                }
                                if (result.data.Faults !== null && result.data.Faults !== undefined) {
                                    (result.data.Faults ?? []).forEach((x) => {
                                        warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                    });
                                }

                                if ((warningErrors ?? "").length > 0) {
                                    this.$toast.add({ severity: 'error', summary: (isReturn ? 'Return ' : '') + 'Carrier Failed!', detail: warningErrors, life: 30000 });
                                }


                                if (!isReturn) {
                                    if ((result?.data?.Response?.Customers ?? []).length > 0) {
                                        let carrierIndex = 0;
                                        let availServiceIndex = 0;
                                        let availOptionIndex = 0;
                                        let customer = result?.data?.Response?.Customers[0];
                                        let serviceResult = customer?.Carriers ?? [];
                                        (serviceResult ?? []).forEach(x => {
                                            carrierIndex++;
                                            x.Id = carrierIndex;
                                            x.CarrierCode = x.Code,
                                            x.CarrierName = x.Name
                                            x.Text = '[' + x.Code + '] ' + x.Name;
                                            (x.AvailableServices ?? []).forEach(av => {
                                                availServiceIndex++;
                                                av.Id = availServiceIndex;
                                                av.Text = '[' + av.Code + '] ' + av.Name;
                                                av.ParentID = x.Id;
                                                av.ParentText = x.Text;
                                                av.ParentCode = x.CarrierCode;
                                                av.ParentText = x.Text;
                                                av.Logo = x.Logo;

                                            });
                                            (x.AvailableOptions ?? []).forEach(av => {
                                                availOptionIndex++;
                                                av.Id = availOptionIndex;
                                                av.Text = '[' + av.Code + '] ' + av.Name;
                                                av.ParentID = x.Id;
                                                av.ParentText = x.Text;
                                                av.ParentCode = x.CarrierCode;
                                                av.ParentText = x.Text;
                                            });
                                        });
                                        this.source.shippingInfo.providers.result = serviceResult;
                                        this.$nextTick(() => {
                                            setTimeout(() => {
                                                this.getSetting();
                                                if ((this.companySettingRecord?.id ?? 0) != 0) {
                                                    let defaultProvider = (this.source.companyInfo.accountCustomer ?? []).filter(x => x.id == this.companySettingRecord.default_AccountCustomerID)[0];
                                                    if (defaultProvider != null && defaultProvider != undefined) {
                                                        this.source.shippingInfo.carrier.serviceProviderAccountID = (this.source.shippingInfo.providers.result ?? []).filter(x => x.CarrierCode == defaultProvider.code)[0]?.Id ?? null;
                                                    }
                                                }
                                            }, 1000);
                                        });
                                    }
                                }
                                else {
                                    if ((result?.data?.Response?.Customers ?? []).length > 0) {
                                        let carrierIndex = 0;
                                        let availServiceIndex = 0;
                                        let availOptionIndex = 0;
                                        let customer = result?.data?.Response?.Customers[0];
                                        let serviceResult = customer?.Carriers ?? [];
                                        (serviceResult ?? []).forEach(x => {

                                            carrierIndex++;
                                            x.Id = carrierIndex;
                                            x.CarrierCode = x.Code,
                                                x.CarrierName = x.Name
                                            x.Text = '[' + x.Code + '] ' + x.Name;
                                            (x.AvailableServices ?? []).forEach(av => {
                                                availServiceIndex++;
                                                av.Id = availServiceIndex;
                                                av.Text = '[' + av.Code + '] ' + av.Name;
                                                av.ParentID = x.Id;
                                                av.ParentText = x.Text;
                                                av.ParentCode = x.CarrierCode;
                                                av.ParentText = x.Text;
                                                av.Logo = x.Logo;
                                            });
                                            (x.AvailableOptions ?? []).forEach(av => {
                                                availOptionIndex++;
                                                av.Id = availOptionIndex;
                                                av.Text = av.Name;
                                                av.ParentID = x.Id;
                                                av.ParentText = x.Text;
                                                av.ParentCode = x.CarrierCode;
                                                av.ParentText = x.Text;
                                            });
                                        });
                                        this.source.returnInfo.providers.result = serviceResult;
                                    }
                                }

                                if (!isReturn) {
                                    this.processing.shippingInfo.provider = false;
                                }
                                else {

                                    this.processing.returnInfo.provider = false;
                                }
                            })
                                .catch(err => {
                                    console.log(err);
                                    if (!isReturn) {
                                        this.processing.shippingInfo.provider = false;
                                    }
                                    else {

                                        this.processing.returnInfo.provider = false;
                                    }
                                });
                        }
                        else {
                            if (!isReturn) {
                                this.processing.shippingInfo.provider = false;
                            }
                            else {

                                this.processing.returnInfo.provider = false;
                            }
                        }
                    });
                }
            },
            async getConsignmentService(isReturn) {
                let isProcessing = false;
                isProcessing = !isReturn ? (this.processing.shippingInfo.product ?? false) : (this.processing.returnInfo.product ?? false);
                if (!isProcessing) {
                    if (!isReturn) {
                        this.processing.shippingInfo.product = true;
                        this.source.shippingInfo.services.result = [];
                    }
                    else {
                        this.source.returnInfo.services.result = [];
                        this.processing.returnInfo.product = true;
                    }
                    this.$nextTick(() => {
                        if (isReturn === null || isReturn === undefined)
                            isReturn = false
                        let apiRequest = this.apiServiceRequest;
                        if ((apiRequest?.Request?.Consignment?.Packages?.length ?? 0) > 0) {
                            let shippingType = isReturn ? 'RETURNS' : 'SHIPPING';
                            let apiRequest = this.apiServiceRequest;
                            apiRequest.Request.ShippingType = shippingType;
                            apiRequest.Request.Provider.Carrier = (isReturn ? this.ReturnCarrierFilter : this.ShippingCarrierFilter) ?? null;
                            if (apiRequest.Request.Provider.Carrier) {
                                this.$axios.post(this.$config.config.endpoint.api + `/api/polaris/consignment/service?additionalinfo=quote`.replaceAll("//", "/"), apiRequest).then((result) => {
                                    var warningErrors = "";
                                    if (result.data.Errors !== null && result.data.Errors !== undefined) {
                                        (result.data.Errors ?? []).forEach((x) => {
                                            warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                        });
                                    }
                                    if (result.data.Faults !== null && result.data.Faults !== undefined) {
                                        (result.data.Faults ?? []).forEach((x) => {
                                            warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                        });
                                    }

                                    if ((warningErrors ?? "").length > 0) {
                                        this.$toast.add({ severity: 'error', summary: (isReturn ? 'Return ' : '') + 'Service Failed!', detail: warningErrors, life: 30000 });
                                    }

                                    if (!isReturn) {
                                        if ((result?.data?.Response?.Data ?? []).length > 0) {
                                            let carrierIndex = 0;
                                            let availServiceIndex = 0;
                                            let availOptionIndex = 0;
                                            let serviceResult = result.data?.Response?.Data ?? [];
                                            //this.source.shippingInfo.services.result = result.data.Response.Data;
                                            (serviceResult ?? []).forEach(x => {
                                                carrierIndex++;
                                                x.Id = carrierIndex;
                                                x.Text = '[' + x.CarrierCode + '] ' + x.CarrierName;
                                                (x.AvailableServices ?? []).forEach(av => {
                                                    availServiceIndex++;
                                                    av.Id = availServiceIndex;
                                                    av.Text = '[' + av.Code + '] ' + av.Name;
                                                    av.ParentID = x.Id;
                                                    av.ParentText = x.Text;
                                                    av.ParentCode = x.CarrierCode;
                                                    av.ParentText = x.Text;
                                                    av.Logo = x.Logo;

                                                });
                                                (x.AvailableOptions ?? []).forEach(av => {
                                                    availOptionIndex++;
                                                    av.Id = availOptionIndex;
                                                    av.Text = '[' + av.Code + '] ' + av.Name;
                                                    av.ParentID = x.Id;
                                                    av.ParentText = x.Text;
                                                    av.ParentCode = x.CarrierCode;
                                                    av.ParentText = x.Text;
                                                });
                                            });
                                            this.source.shippingInfo.services.result = serviceResult;
                                        }
                                    }
                                    else {
                                        if ((result?.data?.Response?.Data ?? []).length > 0) {
                                            let carrierIndex = 0;
                                            let availServiceIndex = 0;
                                            let availOptionIndex = 0;
                                            let serviceResult = result.data?.Response?.Data ?? [];
                                            //this.source.returnInfo.services.result = result.data.Response.Data;
                                            (serviceResult ?? []).forEach(x => {
                                                carrierIndex++;
                                                x.Id = carrierIndex;
                                                x.Text = '[' + x.CarrierCode + '] ' + x.CarrierName;
                                                (x.AvailableServices ?? []).forEach(av => {
                                                    availServiceIndex++;
                                                    av.Id = availServiceIndex;
                                                    av.Text = '[' + av.Code + '] ' + av.Name;
                                                    av.ParentID = x.Id;
                                                    av.ParentText = x.Text;
                                                    av.ParentCode = x.CarrierCode;
                                                    av.ParentText = x.Text;
                                                    av.Logo = x.Logo;

                                                });
                                                (x.AvailableOptions ?? []).forEach(av => {
                                                    availOptionIndex++;
                                                    av.Id = availOptionIndex;
                                                    av.Text = av.Name;
                                                    av.ParentID = x.Id;
                                                    av.ParentText = x.Text;
                                                    av.ParentCode = x.CarrierCode;
                                                    av.ParentText = x.Text;
                                                });
                                            });
                                            this.source.returnInfo.services.result = serviceResult;
                                        }
                                    }
                                    if (!isReturn) {
                                        this.processing.shippingInfo.product = false;
                                    }
                                    else {

                                        this.processing.returnInfo.product = false;
                                    }
                                })
                                    .catch(() => {
                                        if (!isReturn) {
                                            this.processing.shippingInfo.product = false;
                                        }
                                        else {

                                            this.processing.returnInfo.product = false;
                                        }
                                    });
                            }
                            else {
                                if (!isReturn) {
                                    this.processing.shippingInfo.product = false;
                                }
                                else {

                                    this.processing.returnInfo.product = false;
                                }
                            }

                        }
                        else {
                            if (!isReturn) {
                                this.processing.shippingInfo.product = false;
                            }
                            else {

                                this.processing.returnInfo.product = false;
                            }
                        }
                    });
                }
            },
            async getAccessPoints(isReturn) {
                if (isReturn === null || isReturn === undefined)
                    isReturn = false
                let isProcessing = false;
                isProcessing = !isReturn ? (this.processing.shippingInfo.accesspoint ?? false) : (this.processing.returnInfo.accesspoint ?? false);
                if (!isProcessing) {
                    let isAllowAccessPoint = false;
                    if (!isReturn) {
                        this.processing.shippingInfo.accesspoint = true;
                        isAllowAccessPoint = (this.source?.shippingInfo?.product?.configuration?.AllowAccessPoint ?? false);
                    }
                    else {
                        this.processing.returnInfo.accesspoint = true;
                        isAllowAccessPoint = (this.source?.returnInfo?.product?.configuration?.AllowAccessPoint ?? false);
                    }
                    if (isAllowAccessPoint) {
                        this.$nextTick(() => {
                            let apiRequest = this.apiAccessPointRequest;
                            if ((this.apiServiceRequest?.Request?.Consignment?.Packages?.length ?? 0) > 0) {
                                apiRequest.Request.Carrier = (!isReturn ? this.source.shippingInfo?.carrier?.code : this.source.returnInfo?.carrier?.code);
                                if (apiRequest.Request.Carrier) {
                                    this.$axios.post(this.$config.config.endpoint.api + `/api/polaris/tools/accesspoint`.replaceAll("//", "/"), apiRequest).then((result) => {
                                        if (!isReturn) {
                                            if ((result?.data?.Response ?? []).length > 0) {
                                                this.source.shippingInfo.accesspointLocations = result.data?.Response ?? [];
                                            }
                                        }
                                        else {
                                            if ((result?.data?.Response ?? []).length > 0) {
                                                this.source.returnInfo.accesspointLocations = result.data?.Response ?? [];
                                            }
                                        }

                                        if (!isReturn) {
                                            this.processing.shippingInfo.accesspoint = false;
                                        }
                                        else {

                                            this.processing.returnInfo.accesspoint = false;
                                        }

                                    })
                                        .catch(() => {
                                            if (!isReturn) {
                                                this.processing.shippingInfo.accesspoint = false;
                                            }
                                            else {

                                                this.processing.returnInfo.accesspoint = false;
                                            }

                                        });
                                }
                                else {
                                    if (!isReturn) {
                                        this.processing.shippingInfo.accesspoint = false;
                                    }
                                    else {

                                        this.processing.returnInfo.accesspoint = false;
                                    }
                                }
                            }
                            else {
                                if (!isReturn) {
                                    this.processing.shippingInfo.accesspoint = false;
                                }
                                else {

                                    this.processing.returnInfo.accesspoint = false;
                                }

                            }
                        });
                    }
                }
            },
            async onSelectServiceGroup(event, isReturn) {
                if (isReturn === null || isReturn === undefined)
                    isReturn = false

                if (!isReturn) {
                    this.source.shippingInfo.serviceGroup.serviceGroupID = event?.value;
                    if (event?.value) {
                        this.source.shippingInfo.serviceGroup.code = event?.record?.code ?? null;
                        this.source.shippingInfo.serviceGroup.text = event?.record?.code ?? null;
                    }
                    else {
                        this.source.shippingInfo.serviceGroup.code = null;
                        this.source.shippingInfo.serviceGroup.text = null;
                    }
                    
                }
                else {
                    this.source.returnInfo.serviceGroup.serviceGroupID = event?.value;
                    if (event?.value) {
                        this.source.returnInfo.serviceGroup.code = event?.record?.code ?? null;
                        this.source.returnInfo.serviceGroup.text = event?.record?.code ?? null;
                    }
                    else {
                        this.source.returnInfo.serviceGroup.code = null;
                        this.source.returnInfo.serviceGroup.text = null;
                    }
                }
            },
            async onSelectCarrier(event, isReturn) {
                if (isReturn === null || isReturn === undefined)
                    isReturn = false

                if (!isReturn) {
                    this.source.shippingInfo.carrier.serviceProviderAccountID = event?.value;
                    if (event?.value) {
                        this.source.shippingInfo.carrier.code = event?.record?.CarrierCode ?? null;
                        this.source.shippingInfo.carrier.text = event?.record?.Text ?? null;
                    }
                    else {
                        this.source.shippingInfo.carrier.code =  null;
                        this.source.shippingInfo.carrier.text =  null;
                        
                    }
                }
                else {
                    this.source.returnInfo.carrier.serviceProviderAccountID = event?.value;
                    if (event?.value) {
                        this.source.returnInfo.carrier.code = event?.record?.CarrierCode ?? null;
                        this.source.returnInfo.carrier.text = event?.record?.Text ?? null;
                    }
                    else {
                        this.source.returnInfo.carrier.code = null;
                        this.source.returnInfo.carrier.text = null;
                    }
                }
            },
            async onSelectProduct(event, isReturn) {
                if (isReturn === null || isReturn === undefined)
                    isReturn = false
                if (!isReturn) {
                    if (event?.value !== null && event?.value !== undefined) {
                        this.source.shippingInfo.product.serviceProviderProductAccountID = event.value;
                        if (this.source.shippingInfo.carrier.serviceProviderAccountID === null) {
                            this.source.shippingInfo.carrier.serviceProviderAccountID = event.record.ParentID;
                            this.source.shippingInfo.carrier.code = event.record.ParentCode;
                            this.source.shippingInfo.carrier.text = event.record.ParentText;
                        }
                        this.source.shippingInfo.product.code = event.record?.Code ?? null;
                        this.source.shippingInfo.product.text = event.record?.Text ?? null;
                        this.source.shippingInfo.product.configuration = event.record?.Configuration ?? null;
                       // this.source.shippingInfo
                        //this.source.shippingInfo.product.apiCode = event.record.APICode;

                    }
                    else {
                        this.source.shippingInfo.product.serviceProviderProductAccountID = null;
                        if (this.source.shippingInfo.carrier.serviceProviderAccountID == null) {
                            this.source.shippingInfo.carrier.code = null;
                            this.source.shippingInfo.carrier.text = null;
                        }
                        this.source.shippingInfo.product.code = null;
                        this.source.shippingInfo.product.text = null;
                        this.source.shippingInfo.product.configuration = null;
                        
                        //this.source.shippingInfo.product.apiCode = null;

                    }
                }
                else {
                    if (event?.value !== null && event?.value !== undefined) {
                        this.source.returnInfo.product.serviceProviderProductAccountID = event.value;
                        if (this.source.returnInfo.carrier.serviceProviderAccountID === null) {
                            this.source.returnInfo.carrier.serviceProviderAccountID = event.record.ParentID;
                            this.source.returnInfo.carrier.code = event.record.ParentCode;
                            this.source.returnInfo.carrier.text = event.record.ParentText;
                        }
                        this.source.returnInfo.product.code = event.record?.Code ?? null;
                        this.source.returnInfo.product.text = event.record?.Text ?? null;
                        this.source.returnInfo.product.configuration = event.record?.Configuration ?? null;
                        this.source.returnInfo
                        this.source.returnInfo.product.apiCode = event.record.APICode;
                    }
                    else {
                        this.source.returnInfo.product.serviceProviderProductAccountID = null;
                        if (this.source.returnInfo.carrier.serviceProviderAccountID == null) {
                            this.source.returnInfo.carrier.code = null;
                            this.source.returnInfo.carrier.text = null;
                        }
                        this.source.returnInfo.product.code = null;
                        this.source.returnInfo.product.text = null;
                        this.source.returnInfo.product.apiCode = null;
                        this.source.returnInfo.product.configuration = null;
                    }
                }
            },
            async onSelectOption(event, isReturn) {
                if (isReturn === null || isReturn === undefined)
                    isReturn = false
                if (!isReturn) {
                    this.source.shippingInfo.option.serviceProviderOptionAccountID = event?.value;
                    if (event?.value) {
                        this.source.shippingInfo.option.code = event?.record?.Code ?? null;
                        this.source.shippingInfo.option.text = event?.record?.Text ?? null;
                    }
                    else {
                        this.source.shippingInfo.option.code = null;
                        this.source.shippingInfo.option.text = null;
                    }
                }
                else {
                    this.source.returnInfo.option.serviceProviderOptionAccountID = event?.value;
                    if (event?.value) {
                        this.source.returnInfo.option.code = event?.record?.Code ?? null;
                        this.source.returnInfo.option.text = event?.record?.Text ?? null;
                    }
                    else {
                        this.source.returnInfo.option.code = null;
                        this.source.returnInfo.option.text = null;
                    }
                }
            },
            async onSelectAccessPoint(event, isReturn) {
                if (isReturn === null || isReturn === undefined)
                    isReturn = false
                if (!isReturn) {
                    this.source.shippingInfo.accesspoint.code = event?.value;
                    if (event?.value) {
                        this.source.shippingInfo.accesspoint.code = event?.record?.Code ?? null;
                        this.source.shippingInfo.accesspoint.text = event?.record?.FullDescription ?? null;
                        this.source.shippingInfo.accesspoint.record = event?.record ?? null;
                    }
                    else {
                        this.source.shippingInfo.accesspoint.code = null;
                        this.source.shippingInfo.accesspoint.text = null;
                        this.source.shippingInfo.accesspoint.record = null;
                    }
                }
                else {
                    this.source.returnInfo.accesspoint.code = event?.value;
                    if (event?.value) {
                        this.source.returnInfo.accesspoint.code = event?.record?.Code ?? null;
                        this.source.returnInfo.accesspoint.text = event?.record?.FullDescription ?? null;
                        this.source.returnInfo.accesspoint.record = event?.record ?? null;
                    }
                    else {
                        this.source.returnInfo.accesspoint.code = null;
                        this.source.returnInfo.accesspoint.text = null;
                        this.source.returnInfo.accesspoint.record = null;
                    }
                }
            },
            async onPopulateAlternateAddress(event, isReturn) {
                if (isReturn === null || isReturn === undefined)
                    isReturn = false
                if (!isReturn) {
                    this.source.shippingInfo.accesspoint.alternateDelivery.address.address1 = event.address1;
                    this.source.shippingInfo.accesspoint.alternateDelivery.address.address2 = event.address2;
                    this.source.shippingInfo.accesspoint.alternateDelivery.address.address3 = event.address3;
                    this.source.shippingInfo.accesspoint.alternateDelivery.address.city = event.city;
                    this.source.shippingInfo.accesspoint.alternateDelivery.address.state = event.state;
                    this.source.shippingInfo.accesspoint.alternateDelivery.address.postalCode = event.postalCode;
                    this.source.shippingInfo.accesspoint.alternateDelivery.address.countryID = event.countryID;
                    this.source.shippingInfo.accesspoint.alternateDelivery.address.companyName = event.companyName;
                    this.source.shippingInfo.accesspoint.alternateDelivery.address.establishmentType = event.establishmentType;
                    this.source.shippingInfo.accesspoint.alternateDelivery.address.fullDescription = event.address1 + (event.address2 ? (', ' + event.address2) : '') + (event.address3 ? (', ' + event.address3) : '') + (event.city ? (', ' + event.city) : '') + (event.state ? (', ' + event.state) : '') + (event.postalCode ? (', ' + event.postalCode) : '') + (event.countryID ? (', ' + this.$vm.$countries.filter(x => x.id === event.countryID)[0]?.text) : '')
                }
                else {
                    this.source.returnInfo.accesspoint.alternateDelivery.address.address1 = event.address1;
                    this.source.returnInfo.accesspoint.alternateDelivery.address.address2 = event.address2;
                    this.source.returnInfo.accesspoint.alternateDelivery.address.address3 = event.address3;
                    this.source.returnInfo.accesspoint.alternateDelivery.address.city = event.city;
                    this.source.returnInfo.accesspoint.alternateDelivery.address.state = event.state;
                    this.source.returnInfo.accesspoint.alternateDelivery.address.postalCode = event.postalCode;
                    this.source.returnInfo.accesspoint.alternateDelivery.address.countryID = event.countryID;
                    this.source.returnInfo.accesspoint.alternateDelivery.address.companyName = event.companyName;
                    this.source.returnInfo.accesspoint.alternateDelivery.address.establishmentType = event.establishmentType;
                    this.source.returnInfo.accesspoint.alternateDelivery.address.fullDescription = event.address1 + (event.address2 ? (', ' + event.address2) : '') + (event.address3 ? (', ' + event.address3) : '') + (event.city ? (', ' + event.city) : '') + (event.state ? (', ' + event.state) : '') + (event.postalCode ? (', ' + event.postalCode) : '') + (event.countryID ? (', ' + this.$vm.$countries.filter(x => x.id === event.countryID)[0]?.text) : '')
                }
            },
            onAllocateConfirm() {
                let _self = this;
                this.action.processing = true;
                this.allocate().then((allocResponse) => {
                    this.action.processing = false;
                    if (allocResponse.status === 200) {
                        try {
                            var warningErrors = "";
                            if (allocResponse.data.Errors !== null && allocResponse.data.Errors !== undefined) {
                                (allocResponse.data.Errors ?? []).forEach((x) => {
                                    warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`; 
                                });
                            }
                            if (allocResponse.data.Faults !== null && allocResponse.data.Faults !== undefined) {
                                (allocResponse.data.Faults ?? []).forEach((x) => {
                                    warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`; 
                                });
                            }
                            if (warningErrors.length > 0) {
                                _self.$toast.add({ severity: 'error', summary: 'Record Allocate Failed!', detail: warningErrors, life: 10000 });
                            }
                            else if ((allocResponse?.data?.Response?.PackageReferences?.length ?? 0) > 0) {
                                _self.getEntityRecord(this.model?.mask).then(() => {
                                    this.dialog.allocate.show = false;
                                    _self.model = _self.consignmentRecord;
                                    _self.$toast.add({ severity: 'success', summary: 'Record Allocate Success!', detail: 'The record was allocated successfully.', life: 6000 });
                                    _self.$router.push({ name: 'consignment-edit', params: { mask: _self.consignmentRecord.mask, allocated: true } });
                                    //setTimeout(() => _self.$emit("is-allocate", _self.consignmentRecord), 100);
                                })
                            }
                        }
                        catch {
                            this.$toast.add({ severity: 'error', summary: 'Record Allocate Failed!', detail: allocResponse.data.Status, life: 6000 });
                        }
                    }
                }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: 'Record Allocate Failed!', detail: err, life: 10000 });
                });
            },
        },
        async created() {
            //this.model.request_CollectionDate = new Date();
            this.model.request_CollectionDateValue = new Date();
            //this.model.request_ReadyTime = new Date(2018, 1, 1, 16, 0, 0);
            this.model.request_ReadyTimeValue = new Date(2018, 1, 1, 16, 0, 0);
            //this.model.request_ClosingTime = new Date(2018, 1, 1, 18, 0, 0);
            this.model.request_ClosingTimeValue = new Date(2018, 1, 1, 18, 0, 0);

        },
        async mounted() {
            this.$nextTick(() => {
                this.model = this.$props.formData;
                //this.model.request_CollectionDate = new Date();
                this.model.request_CollectionDateValue = new Date();
                //this.model.request_ReadyTime = new Date(2018, 1, 1, 16, 0, 0);
                this.model.request_ReadyTimeValue = new Date(2018, 1, 1, 16, 0, 0);
                //this.model.request_ClosingTime = new Date(2018, 1, 1, 18, 0, 0);
                this.model.request_ClosingTimeValue = new Date(2018, 1, 1, 18, 0, 0);
                this.$nextTick(() => {
                    this.getPackages();
                    //this.getConsignmentService();
                });
            });
           
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>