<template>
    <form-wrapper title="Dynamic Reports" sub-title="List of configured dynamic report templates.">
        <cosmos-grid id="DynamicReportTEmplate" sourceType="url" class="is-customized" :sourcePaged="false" source="/services/facility/dynamicreport/user" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showExport="false" :showOperation="true" :baseRecordID="0">
            <pv-grid-column field="configuration.basicInfo.code" header="Transaction ID" />
            <pv-grid-column field="configuration.basicInfo.name" header="Report Name" style="width: 100%;" />
            <pv-grid-column field="configuration.basicInfo.privacy" header="Availability">
                <template #body="slotProps">
                    <pv-chip :label="slotProps.data.configuration.basicInfo.privacy === 100 ? 'EXCLUSIVE' : 'SHARED'" :icon="slotProps.data.configuration.basicInfo.privacy === 100 ? 'fas fa-lock fa-fw' : 'fas fa-globe fa-fw'" style="border-radius: 4px !important; font-weight: 700; width: 9rem;" />
                </template>
            </pv-grid-column>
            <pv-grid-column field="configuration.basicInfo.status" header="Status">
                <template #body="slotProps">
                    <pv-chip :label="slotProps.data.configuration.basicInfo.status === 100 ? 'DRAFT' : 'PUBLISHED'" :icon="slotProps.data.configuration.basicInfo.status === 100 ? 'fas fa-file-pen fa-fw' : 'far fa-globe fa-fw'" style="border-radius: 4px !important; font-weight: 700; width: 9rem; " />
                </template>
            </pv-grid-column>
        </cosmos-grid>
    </form-wrapper>
</template>
<script>
    export default {
        name: 'DynamicReportGridTemplate',
        data() {
            return {
                type: -1,
                source: [],
                filter: {},
                columns: [],
                deleteDisplay: ["code", "name"],
                searchFields: ["code", "name"],
                activity: {
                    loading: true
                },
                model: {
                    contact: {
                        id: null,
                        temporaryID: null,
                        person: {
                            firstName: null,
                            lastName: null,
                            email: null,
                            telephone: null,
                            mobile: null
                        },
                        identity: {
                            eori: null,
                            ioss: null,
                            vat: null,
                            idNumber: null,
                            idType: 300
                        },
                        address: {
                            id: null,
                            temporaryID: null,
                            company: null,
                            address1: null,
                            address2: null,
                            address3: null,
                            city: null,
                            state: null,
                            stateCode: null,
                            postalCode: null,
                            countryID: 238,
                            countryData: null,
                            establishmentType: 100
                        }
                    },
                    address: {
                        id: null,
                        temporaryID: null,
                        company: null,
                        address1: null,
                        address2: null,
                        address3: null,
                        city: null,
                        state: null,
                        stateCode: null,
                        postalCode: null,
                        countryID: 238,
                        countryData: null,
                        establishmentType: 100
                    }
                }
            }
        },
        computed: {
        },
        methods: {
            getSource() {
                this.$store.dispatch("dynamicreport/getURL", { url: `/services/facility/dynamicreport/user` }).then((response) => {
                    this.companies = response.result;
                    this.source= this.companies;
                    this.activity.loading = false;
                });
            }
        },
        mounted() {
            this.getSource();
        }
    }
</script>